import React from 'react'

function Card(props) {
  return (
    <div className=' rounded-[20px] bg-[#224255] w-full '>
        {props.children}
    </div>
  )
}

export default Card