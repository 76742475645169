import { deleteApiWrapper, getApiWrapper, postApiWrapper, putApiWrapper } from "./token-wrapper-function";


//ecosat
 
const server_url = 'https://ecosat.hiigv3ia7agco.eu-north-1.cs.amazonlightsail.com/'

const base_local = 'http://127.0.0.1:8080'

const base_Url = server_url

export const getPollutants = async(lat, lng, time) => {
  const response = await getApiWrapper(`${base_Url}/get-pollutants?lat=${lat}&lng=${lng}&time=${time}`); // Add the URL to fetch pollutants
  return response;
}


export const getVehicalData = async(time) => {
  const response = await getApiWrapper(`${base_Url}/get-vehical-data?time=${time}`); // Add the URL to fetch vehical data 
  return response;
}

//caching vehical data



//ends here : cahing vehical data


export const getPollutantsWithinPolygon = async(polygon, time) =>{
  const response = await postApiWrapper(`${base_Url}/polygon-pollutant-average?time=${time}`,{polygon})
  return response;
}


export const getVehicalsWithinPolygon = async(polygon, time) =>{
  const response = await postApiWrapper(`${base_Url}/polygon-vehical-aggregate?time=${time}`,{polygon})
  return response;
}

export const getCurrentTimeFromS3 = async() =>{
  const response = await getApiWrapper(`${base_Url}/get-current-time`)
  return response;
}


// export const getAllTiles = () =>{
//   const times = ['0000hrs', '0100hrs', '0200hrs', '0300hrs', '0400hrs', '0500hrs', '0600hrs', '0700hrs', '0800hrs', '0900hrs', '1000hrs', '1100hrs', '1200hrs', '1300hrs', '1400hrs', '1500hrs', '1600hrs', '1700hrs', '1800hrs', '1900hrs', '2000hrs', '2100hrs', '2200hrs', '2300hrs'];
//   times.forEach(async (t)=>{
//     const response = await getApiWrapper(`${base_local}/tiles/CO2/${t}/8/167/109.png`)
//     console.log("async call ", response )
//   })

// }

export const getReverseGeocode = async (lat, long)=>{
  const p =  await fetch(`https://api.mapbox.com/search/geocode/v6/reverse?longitude=${long}&latitude=${lat}&access_token=pk.eyJ1Ijoic2luZ2h2aXNoYW4wMDI5IiwiYSI6ImNsdTVwNDdtajE0OGgya254bW1qcGxuc2oifQ.O5Mw_RmNEyFN6N4eZucRWw`)
  return await p.json();
}

export const getTopPollutantCity = async(time, pollutant) =>{
  const response = await getApiWrapper(`${base_Url}/top-pollutant-places/${pollutant}/${time}`)
  return response;
}



