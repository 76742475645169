export const modelledData = [
    [
        "-180",
        "-80",
        "10.056",
        "-9.995",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-80",
        "10.227",
        "-9.885",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-80",
        "10.396",
        "-9.772",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-80",
        "10.563",
        "-9.655",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-80",
        "10.728",
        "-9.536",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-80",
        "10.891",
        "-9.414",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-80",
        "11.052",
        "-9.290",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-80",
        "11.210",
        "-9.162",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-80",
        "11.367",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-80",
        "11.521",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-80",
        "11.673",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-80",
        "11.822",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-80",
        "11.968",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-80",
        "12.113",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-80",
        "12.256",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-80",
        "12.395",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-80",
        "12.533",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-80",
        "12.666",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-80",
        "12.798",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-80",
        "12.927",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-80",
        "13.053",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-80",
        "13.177",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-80",
        "13.297",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-80",
        "13.415",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-80",
        "13.530",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-80",
        "13.642",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-80",
        "13.751",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-80",
        "13.856",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-80",
        "13.960",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-80",
        "14.060",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-80",
        "14.157",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-80",
        "14.250",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-80",
        "14.341",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-80",
        "14.428",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-80",
        "14.513",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-80",
        "14.593",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-80",
        "14.671",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-80",
        "14.745",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-80",
        "14.817",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-80",
        "14.885",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-80",
        "14.949",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-80",
        "15.011",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-80",
        "15.068",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-80",
        "15.122",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-80",
        "15.173",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-80",
        "15.221",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-80",
        "15.265",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-80",
        "15.305",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-80",
        "15.342",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-80",
        "15.377",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-80",
        "15.407",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-80",
        "15.434",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-80",
        "15.457",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-80",
        "15.476",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-80",
        "15.492",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-80",
        "15.505",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-80",
        "15.514",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-80",
        "15.520",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-80",
        "15.521",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-80",
        "15.519",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-80",
        "15.515",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-80",
        "15.507",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-80",
        "15.495",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-80",
        "15.478",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-80",
        "15.459",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-80",
        "15.437",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-80",
        "15.411",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-80",
        "15.381",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-80",
        "15.348",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-80",
        "15.312",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-80",
        "15.272",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-80",
        "15.228",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-80",
        "15.181",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-80",
        "15.130",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-80",
        "15.077",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-80",
        "15.019",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-80",
        "14.959",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-80",
        "14.895",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-80",
        "14.827",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-80",
        "14.756",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-80",
        "14.683",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-80",
        "14.605",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-80",
        "14.525",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-80",
        "14.441",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-80",
        "14.355",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-80",
        "14.264",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-80",
        "14.171",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-80",
        "14.075",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-80",
        "13.976",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-80",
        "13.872",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-80",
        "13.768",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-80",
        "13.659",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-80",
        "13.547",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-80",
        "13.433",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-80",
        "13.316",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-80",
        "13.196",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-80",
        "13.073",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-80",
        "12.947",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-80",
        "12.818",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-80",
        "12.687",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-80",
        "12.553",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-80",
        "12.416",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-80",
        "12.277",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-80",
        "12.136",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-80",
        "11.991",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-80",
        "11.845",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-80",
        "11.696",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-80",
        "11.544",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-80",
        "11.390",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-80",
        "11.235",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-80",
        "11.076",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-80",
        "10.916",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-80",
        "10.753",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-80",
        "10.589",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-80",
        "10.422",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-80",
        "10.253",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-80",
        "10.083",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-80",
        "9.910",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-80",
        "9.736",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-80",
        "9.560",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-80",
        "9.382",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-80",
        "9.203",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-80",
        "9.022",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-80",
        "8.839",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-80",
        "8.656",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-80",
        "8.470",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-80",
        "8.283",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-80",
        "8.095",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-80",
        "7.905",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-80",
        "7.714",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-80",
        "7.522",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-80",
        "7.330",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-80",
        "7.135",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-80",
        "6.941",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-80",
        "6.744",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-80",
        "6.548",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-80",
        "6.350",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-80",
        "6.152",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-80",
        "5.953",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-80",
        "5.753",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-80",
        "5.554",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-80",
        "5.353",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-80",
        "5.151",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-80",
        "4.951",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-80",
        "4.749",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-80",
        "4.546",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-80",
        "4.345",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-80",
        "4.142",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-80",
        "3.940",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-80",
        "3.737",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-80",
        "3.535",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-80",
        "3.333",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-80",
        "3.131",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-80",
        "2.929",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-80",
        "2.727",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-80",
        "2.526",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-80",
        "2.325",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-80",
        "2.125",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-80",
        "1.925",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-80",
        "1.727",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-80",
        "1.528",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-80",
        "1.330",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-80",
        "1.133",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-80",
        "0.937",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-80",
        "0.742",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-80",
        "0.548",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-80",
        "0.355",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-80",
        "0.163",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-80",
        "-0.028",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-80",
        "-0.218",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-80",
        "-0.406",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-80",
        "-0.593",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-80",
        "-0.779",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-80",
        "-0.963",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-80",
        "-1.146",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-80",
        "-1.327",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-80",
        "-1.507",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-80",
        "-1.685",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-80",
        "-1.862",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-80",
        "-2.036",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-80",
        "-2.209",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-80",
        "-2.380",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-80",
        "-2.549",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-80",
        "-2.716",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-80",
        "-2.881",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-80",
        "-3.043",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-80",
        "-3.204",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-80",
        "-3.363",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-80",
        "-3.519",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-80",
        "-3.673",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-80",
        "-3.824",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-80",
        "-3.974",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-80",
        "-4.121",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-80",
        "-4.266",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-80",
        "-4.408",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-80",
        "-4.547",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-80",
        "-4.684",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-80",
        "-4.819",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-80",
        "-4.950",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-80",
        "-5.079",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-80",
        "-5.205",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-80",
        "-5.329",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-80",
        "-5.450",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-80",
        "-5.568",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-80",
        "-5.682",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-80",
        "-5.794",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-80",
        "-5.903",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-80",
        "-6.009",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-80",
        "-6.112",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-80",
        "-6.212",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-80",
        "-6.309",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-80",
        "-6.403",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-80",
        "-6.493",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-80",
        "-6.580",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-80",
        "-6.664",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-80",
        "-6.746",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-80",
        "-6.824",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-80",
        "-6.898",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-80",
        "-6.969",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-80",
        "-7.036",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-80",
        "-7.102",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-80",
        "-7.162",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-80",
        "-7.221",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-80",
        "-7.275",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-80",
        "-7.326",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-80",
        "-7.373",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-80",
        "-7.417",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-80",
        "-7.458",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-80",
        "-7.495",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-80",
        "-7.528",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-80",
        "-7.558",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-80",
        "-7.585",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-80",
        "-7.608",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-80",
        "-7.628",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-80",
        "-7.644",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-80",
        "-7.657",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-80",
        "-7.666",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-80",
        "-7.671",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-80",
        "-7.674",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-80",
        "-7.672",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-80",
        "-7.668",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-80",
        "-7.658",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-80",
        "-7.646",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-80",
        "-7.631",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-80",
        "-7.612",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-80",
        "-7.589",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-80",
        "-7.563",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-80",
        "-7.533",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-80",
        "-7.501",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-80",
        "-7.463",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-80",
        "-7.423",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-80",
        "-7.380",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-80",
        "-7.333",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-80",
        "-7.283",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-80",
        "-7.228",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-80",
        "-7.171",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-80",
        "-7.111",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-80",
        "-7.047",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-80",
        "-6.980",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-80",
        "-6.909",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-80",
        "-6.835",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-80",
        "-6.758",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-80",
        "-6.678",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-80",
        "-6.594",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-80",
        "-6.507",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-80",
        "-6.417",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-80",
        "-6.323",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-80",
        "-6.228",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-80",
        "-6.128",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-80",
        "-6.025",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-80",
        "-5.920",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-80",
        "-5.811",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-80",
        "-5.700",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-80",
        "-5.586",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-80",
        "-5.468",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-80",
        "-5.348",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-80",
        "-5.225",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-80",
        "-5.099",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-80",
        "-4.971",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-80",
        "-4.839",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-80",
        "-4.705",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-80",
        "-4.569",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-80",
        "-4.430",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-80",
        "-4.288",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-80",
        "-4.144",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-80",
        "-3.997",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-80",
        "-3.848",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-80",
        "-3.697",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-80",
        "-3.543",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-80",
        "-3.387",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-80",
        "-3.229",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-80",
        "-3.068",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-80",
        "-2.906",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-80",
        "-2.741",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-80",
        "-2.574",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-80",
        "-2.406",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-80",
        "-2.235",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-80",
        "-2.063",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-80",
        "-1.888",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-80",
        "-1.712",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-80",
        "-1.535",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-80",
        "-1.355",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-80",
        "-1.175",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-80",
        "-0.992",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-80",
        "-0.807",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-80",
        "-0.623",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-80",
        "-0.436",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-80",
        "-0.247",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-80",
        "-0.058",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-80",
        "0.133",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-80",
        "0.325",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-80",
        "0.518",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-80",
        "0.712",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-80",
        "0.907",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-80",
        "1.103",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-80",
        "1.300",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-80",
        "1.497",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-80",
        "1.696",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-80",
        "1.894",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-80",
        "2.094",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-80",
        "2.295",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-80",
        "2.495",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-80",
        "2.696",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-80",
        "2.898",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-80",
        "3.100",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-80",
        "3.301",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-80",
        "3.504",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-80",
        "3.706",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-80",
        "3.909",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-80",
        "4.110",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-80",
        "4.313",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-80",
        "4.515",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-80",
        "4.718",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-80",
        "4.919",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-80",
        "5.121",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-80",
        "5.322",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-80",
        "5.522",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-80",
        "5.723",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-80",
        "5.922",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-80",
        "6.122",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-80",
        "6.319",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-80",
        "6.517",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-80",
        "6.714",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-80",
        "6.910",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-80",
        "7.105",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-80",
        "7.300",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-80",
        "7.493",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-80",
        "7.685",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-80",
        "7.875",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-80",
        "8.065",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-80",
        "8.253",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-80",
        "8.441",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-80",
        "8.626",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-80",
        "8.811",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-80",
        "8.994",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-80",
        "9.176",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-80",
        "9.354",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-80",
        "9.533",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-80",
        "9.709",
        "-10.206",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-80",
        "9.884",
        "-10.102",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-79",
        "10.424",
        "-9.995",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-79",
        "10.595",
        "-9.885",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-79",
        "10.763",
        "-9.772",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-79",
        "10.929",
        "-9.655",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-79",
        "11.093",
        "-9.536",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-79",
        "11.256",
        "-9.414",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-79",
        "11.416",
        "-9.290",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-79",
        "11.575",
        "-9.162",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-79",
        "11.730",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-79",
        "11.884",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-79",
        "12.035",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-79",
        "12.184",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-79",
        "12.331",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-79",
        "12.475",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-79",
        "12.616",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-79",
        "12.755",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-79",
        "12.892",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-79",
        "13.026",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-79",
        "13.157",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-79",
        "13.285",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-79",
        "13.411",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-79",
        "13.534",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-79",
        "13.655",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-79",
        "13.772",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-79",
        "13.886",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-79",
        "13.999",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-79",
        "14.107",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-79",
        "14.212",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-79",
        "14.316",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-79",
        "14.415",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-79",
        "14.512",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-79",
        "14.605",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-79",
        "14.695",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-79",
        "14.782",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-79",
        "14.866",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-79",
        "14.946",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-79",
        "15.025",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-79",
        "15.099",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-79",
        "15.169",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-79",
        "15.237",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-79",
        "15.301",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-79",
        "15.362",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-79",
        "15.420",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-79",
        "15.474",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-79",
        "15.525",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-79",
        "15.572",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-79",
        "15.616",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-79",
        "15.657",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-79",
        "15.694",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-79",
        "15.727",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-79",
        "15.757",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-79",
        "15.784",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-79",
        "15.807",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-79",
        "15.826",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-79",
        "15.842",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-79",
        "15.855",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-79",
        "15.864",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-79",
        "15.870",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-79",
        "15.871",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-79",
        "15.870",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-79",
        "15.866",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-79",
        "15.857",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-79",
        "15.845",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-79",
        "15.829",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-79",
        "15.810",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-79",
        "15.787",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-79",
        "15.762",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-79",
        "15.732",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-79",
        "15.699",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-79",
        "15.662",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-79",
        "15.622",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-79",
        "15.579",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-79",
        "15.532",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-79",
        "15.482",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-79",
        "15.428",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-79",
        "15.372",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-79",
        "15.311",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-79",
        "15.247",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-79",
        "15.180",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-79",
        "15.109",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-79",
        "15.035",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-79",
        "14.959",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-79",
        "14.878",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-79",
        "14.795",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-79",
        "14.709",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-79",
        "14.619",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-79",
        "14.526",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-79",
        "14.429",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-79",
        "14.331",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-79",
        "14.228",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-79",
        "14.123",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-79",
        "14.016",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-79",
        "13.904",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-79",
        "13.790",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-79",
        "13.673",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-79",
        "13.553",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-79",
        "13.431",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-79",
        "13.305",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-79",
        "13.177",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-79",
        "13.046",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-79",
        "12.913",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-79",
        "12.776",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-79",
        "12.638",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-79",
        "12.497",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-79",
        "12.353",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-79",
        "12.207",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-79",
        "12.059",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-79",
        "11.908",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-79",
        "11.754",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-79",
        "11.598",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-79",
        "11.441",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-79",
        "11.281",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-79",
        "11.119",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-79",
        "10.955",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-79",
        "10.788",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-79",
        "10.621",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-79",
        "10.451",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-79",
        "10.279",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-79",
        "10.105",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-79",
        "9.929",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-79",
        "9.753",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-79",
        "9.574",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-79",
        "9.394",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-79",
        "9.212",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-79",
        "9.027",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-79",
        "8.843",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-79",
        "8.657",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-79",
        "8.469",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-79",
        "8.280",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-79",
        "8.090",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-79",
        "7.899",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-79",
        "7.706",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-79",
        "7.513",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-79",
        "7.319",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-79",
        "7.124",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-79",
        "6.927",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-79",
        "6.730",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-79",
        "6.533",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-79",
        "6.334",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-79",
        "6.136",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-79",
        "5.936",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-79",
        "5.736",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-79",
        "5.536",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-79",
        "5.334",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-79",
        "5.133",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-79",
        "4.933",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-79",
        "4.730",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-79",
        "4.529",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-79",
        "4.327",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-79",
        "4.125",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-79",
        "3.924",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-79",
        "3.722",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-79",
        "3.520",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-79",
        "3.319",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-79",
        "3.119",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-79",
        "2.918",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-79",
        "2.718",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-79",
        "2.518",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-79",
        "2.319",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-79",
        "2.122",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-79",
        "1.923",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-79",
        "1.726",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-79",
        "1.530",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-79",
        "1.334",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-79",
        "1.140",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-79",
        "0.947",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-79",
        "0.754",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-79",
        "0.563",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-79",
        "0.372",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-79",
        "0.184",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-79",
        "-0.004",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-79",
        "-0.191",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-79",
        "-0.376",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-79",
        "-0.559",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-79",
        "-0.742",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-79",
        "-0.923",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-79",
        "-1.101",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-79",
        "-1.279",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-79",
        "-1.455",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-79",
        "-1.629",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-79",
        "-1.801",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-79",
        "-1.971",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-79",
        "-2.140",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-79",
        "-2.306",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-79",
        "-2.471",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-79",
        "-2.633",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-79",
        "-2.793",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-79",
        "-2.951",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-79",
        "-3.107",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-79",
        "-3.261",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-79",
        "-3.412",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-79",
        "-3.561",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-79",
        "-3.708",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-79",
        "-3.852",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-79",
        "-3.994",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-79",
        "-4.132",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-79",
        "-4.269",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-79",
        "-4.402",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-79",
        "-4.534",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-79",
        "-4.663",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-79",
        "-4.789",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-79",
        "-4.911",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-79",
        "-5.032",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-79",
        "-5.149",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-79",
        "-5.264",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-79",
        "-5.375",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-79",
        "-5.484",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-79",
        "-5.590",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-79",
        "-5.692",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-79",
        "-5.791",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-79",
        "-5.888",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-79",
        "-5.981",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-79",
        "-6.072",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-79",
        "-6.158",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-79",
        "-6.242",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-79",
        "-6.324",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-79",
        "-6.401",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-79",
        "-6.475",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-79",
        "-6.546",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-79",
        "-6.614",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-79",
        "-6.678",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-79",
        "-6.739",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-79",
        "-6.796",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-79",
        "-6.850",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-79",
        "-6.901",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-79",
        "-6.949",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-79",
        "-6.993",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-79",
        "-7.033",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-79",
        "-7.070",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-79",
        "-7.104",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-79",
        "-7.134",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-79",
        "-7.161",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-79",
        "-7.184",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-79",
        "-7.203",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-79",
        "-7.220",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-79",
        "-7.232",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-79",
        "-7.241",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-79",
        "-7.246",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-79",
        "-7.249",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-79",
        "-7.247",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-79",
        "-7.242",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-79",
        "-7.233",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-79",
        "-7.221",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-79",
        "-7.206",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-79",
        "-7.186",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-79",
        "-7.164",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-79",
        "-7.138",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-79",
        "-7.109",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-79",
        "-7.076",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-79",
        "-7.039",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-79",
        "-6.999",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-79",
        "-6.956",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-79",
        "-6.909",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-79",
        "-6.859",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-79",
        "-6.805",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-79",
        "-6.748",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-79",
        "-6.687",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-79",
        "-6.623",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-79",
        "-6.557",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-79",
        "-6.487",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-79",
        "-6.413",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-79",
        "-6.335",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-79",
        "-6.256",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-79",
        "-6.172",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-79",
        "-6.085",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-79",
        "-5.996",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-79",
        "-5.903",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-79",
        "-5.807",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-79",
        "-5.707",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-79",
        "-5.605",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-79",
        "-5.500",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-79",
        "-5.392",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-79",
        "-5.281",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-79",
        "-5.167",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-79",
        "-5.049",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-79",
        "-4.930",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-79",
        "-4.807",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-79",
        "-4.682",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-79",
        "-4.554",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-79",
        "-4.423",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-79",
        "-4.290",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-79",
        "-4.154",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-79",
        "-4.015",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-79",
        "-3.874",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-79",
        "-3.730",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-79",
        "-3.584",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-79",
        "-3.435",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-79",
        "-3.284",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-79",
        "-3.131",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-79",
        "-2.976",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-79",
        "-2.817",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-79",
        "-2.658",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-79",
        "-2.496",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-79",
        "-2.331",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-79",
        "-2.166",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-79",
        "-1.997",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-79",
        "-1.827",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-79",
        "-1.656",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-79",
        "-1.482",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-79",
        "-1.307",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-79",
        "-1.129",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-79",
        "-0.950",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-79",
        "-0.770",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-79",
        "-0.588",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-79",
        "-0.405",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-79",
        "-0.219",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-79",
        "-0.033",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-79",
        "0.155",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-79",
        "0.343",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-79",
        "0.533",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-79",
        "0.724",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-79",
        "0.916",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-79",
        "1.110",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-79",
        "1.305",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-79",
        "1.500",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-79",
        "1.696",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-79",
        "1.893",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-79",
        "2.091",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-79",
        "2.289",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-79",
        "2.488",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-79",
        "2.688",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-79",
        "2.888",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-79",
        "3.088",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-79",
        "3.288",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-79",
        "3.489",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-79",
        "3.691",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-79",
        "3.892",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-79",
        "4.095",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-79",
        "4.296",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-79",
        "4.497",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-79",
        "4.699",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-79",
        "4.901",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-79",
        "5.102",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-79",
        "5.303",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-79",
        "5.505",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-79",
        "5.705",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-79",
        "5.905",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-79",
        "6.104",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-79",
        "6.304",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-79",
        "6.502",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-79",
        "6.700",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-79",
        "6.897",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-79",
        "7.094",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-79",
        "7.288",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-79",
        "7.483",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-79",
        "7.677",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-79",
        "7.869",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-79",
        "8.061",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-79",
        "8.251",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-79",
        "8.440",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-79",
        "8.628",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-79",
        "8.814",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-79",
        "9.000",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-79",
        "9.183",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-79",
        "9.366",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-79",
        "9.546",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-79",
        "9.725",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-79",
        "9.903",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-79",
        "10.079",
        "-10.206",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-79",
        "10.252",
        "-10.102",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-78",
        "10.789",
        "-9.995",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-78",
        "10.959",
        "-9.885",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-78",
        "11.127",
        "-9.772",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-78",
        "11.292",
        "-9.655",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-78",
        "11.457",
        "-9.536",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-78",
        "11.618",
        "-9.414",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-78",
        "11.778",
        "-9.290",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-78",
        "11.935",
        "-9.162",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-78",
        "12.091",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-78",
        "12.244",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-78",
        "12.395",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-78",
        "12.543",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-78",
        "12.689",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-78",
        "12.832",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-78",
        "12.974",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-78",
        "13.112",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-78",
        "13.248",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-78",
        "13.381",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-78",
        "13.512",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-78",
        "13.640",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-78",
        "13.766",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-78",
        "13.888",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-78",
        "14.008",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-78",
        "14.125",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-78",
        "14.240",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-78",
        "14.351",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-78",
        "14.458",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-78",
        "14.564",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-78",
        "14.666",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-78",
        "14.765",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-78",
        "14.861",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-78",
        "14.955",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-78",
        "15.045",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-78",
        "15.131",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-78",
        "15.215",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-78",
        "15.296",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-78",
        "15.373",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-78",
        "15.447",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-78",
        "15.518",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-78",
        "15.585",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-78",
        "15.648",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-78",
        "15.709",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-78",
        "15.767",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-78",
        "15.820",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-78",
        "15.872",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-78",
        "15.918",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-78",
        "15.962",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-78",
        "16.003",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-78",
        "16.040",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-78",
        "16.073",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-78",
        "16.103",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-78",
        "16.130",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-78",
        "16.153",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-78",
        "16.172",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-78",
        "16.188",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-78",
        "16.200",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-78",
        "16.210",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-78",
        "16.215",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-78",
        "16.217",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-78",
        "16.215",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-78",
        "16.211",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-78",
        "16.202",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-78",
        "16.191",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-78",
        "16.175",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-78",
        "16.155",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-78",
        "16.134",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-78",
        "16.108",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-78",
        "16.078",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-78",
        "16.045",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-78",
        "16.008",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-78",
        "15.969",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-78",
        "15.925",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-78",
        "15.879",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-78",
        "15.829",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-78",
        "15.775",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-78",
        "15.719",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-78",
        "15.658",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-78",
        "15.595",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-78",
        "15.528",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-78",
        "15.458",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-78",
        "15.384",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-78",
        "15.307",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-78",
        "15.228",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-78",
        "15.144",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-78",
        "15.058",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-78",
        "14.969",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-78",
        "14.876",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-78",
        "14.780",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-78",
        "14.681",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-78",
        "14.580",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-78",
        "14.475",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-78",
        "14.367",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-78",
        "14.256",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-78",
        "14.143",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-78",
        "14.027",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-78",
        "13.907",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-78",
        "13.785",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-78",
        "13.660",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-78",
        "13.533",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-78",
        "13.402",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-78",
        "13.268",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-78",
        "13.133",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-78",
        "12.996",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-78",
        "12.855",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-78",
        "12.711",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-78",
        "12.566",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-78",
        "12.418",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-78",
        "12.267",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-78",
        "12.115",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-78",
        "11.959",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-78",
        "11.803",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-78",
        "11.643",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-78",
        "11.481",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-78",
        "11.318",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-78",
        "11.153",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-78",
        "10.985",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-78",
        "10.815",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-78",
        "10.644",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-78",
        "10.471",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-78",
        "10.296",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-78",
        "10.120",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-78",
        "9.941",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-78",
        "9.762",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-78",
        "9.580",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-78",
        "9.398",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-78",
        "9.213",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-78",
        "9.028",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-78",
        "8.841",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-78",
        "8.653",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-78",
        "8.463",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-78",
        "8.272",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-78",
        "8.081",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-78",
        "7.889",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-78",
        "7.694",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-78",
        "7.499",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-78",
        "7.305",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-78",
        "7.108",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-78",
        "6.912",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-78",
        "6.713",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-78",
        "6.516",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-78",
        "6.317",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-78",
        "6.117",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-78",
        "5.918",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-78",
        "5.717",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-78",
        "5.517",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-78",
        "5.316",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-78",
        "5.116",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-78",
        "4.914",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-78",
        "4.713",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-78",
        "4.512",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-78",
        "4.312",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-78",
        "4.110",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-78",
        "3.910",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-78",
        "3.709",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-78",
        "3.509",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-78",
        "3.310",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-78",
        "3.111",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-78",
        "2.911",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-78",
        "2.713",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-78",
        "2.515",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-78",
        "2.318",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-78",
        "2.122",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-78",
        "1.927",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-78",
        "1.732",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-78",
        "1.538",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-78",
        "1.345",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-78",
        "1.153",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-78",
        "0.963",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-78",
        "0.773",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-78",
        "0.585",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-78",
        "0.397",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-78",
        "0.212",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-78",
        "0.027",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-78",
        "-0.156",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-78",
        "-0.338",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-78",
        "-0.517",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-78",
        "-0.696",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-78",
        "-0.873",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-78",
        "-1.048",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-78",
        "-1.221",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-78",
        "-1.393",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-78",
        "-1.562",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-78",
        "-1.730",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-78",
        "-1.896",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-78",
        "-2.060",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-78",
        "-2.222",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-78",
        "-2.381",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-78",
        "-2.539",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-78",
        "-2.694",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-78",
        "-2.847",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-78",
        "-2.998",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-78",
        "-3.147",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-78",
        "-3.293",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-78",
        "-3.437",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-78",
        "-3.577",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-78",
        "-3.716",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-78",
        "-3.852",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-78",
        "-3.985",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-78",
        "-4.116",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-78",
        "-4.244",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-78",
        "-4.370",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-78",
        "-4.492",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-78",
        "-4.611",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-78",
        "-4.729",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-78",
        "-4.843",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-78",
        "-4.954",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-78",
        "-5.063",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-78",
        "-5.168",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-78",
        "-5.270",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-78",
        "-5.369",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-78",
        "-5.466",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-78",
        "-5.558",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-78",
        "-5.648",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-78",
        "-5.736",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-78",
        "-5.819",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-78",
        "-5.899",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-78",
        "-5.976",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-78",
        "-6.050",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-78",
        "-6.121",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-78",
        "-6.188",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-78",
        "-6.253",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-78",
        "-6.313",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-78",
        "-6.370",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-78",
        "-6.425",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-78",
        "-6.475",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-78",
        "-6.523",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-78",
        "-6.566",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-78",
        "-6.606",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-78",
        "-6.643",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-78",
        "-6.677",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-78",
        "-6.707",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-78",
        "-6.733",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-78",
        "-6.756",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-78",
        "-6.775",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-78",
        "-6.792",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-78",
        "-6.805",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-78",
        "-6.813",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-78",
        "-6.819",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-78",
        "-6.821",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-78",
        "-6.820",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-78",
        "-6.815",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-78",
        "-6.806",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-78",
        "-6.794",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-78",
        "-6.778",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-78",
        "-6.760",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-78",
        "-6.737",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-78",
        "-6.711",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-78",
        "-6.682",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-78",
        "-6.649",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-78",
        "-6.612",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-78",
        "-6.572",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-78",
        "-6.530",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-78",
        "-6.482",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-78",
        "-6.433",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-78",
        "-6.380",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-78",
        "-6.322",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-78",
        "-6.262",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-78",
        "-6.198",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-78",
        "-6.131",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-78",
        "-6.061",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-78",
        "-5.988",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-78",
        "-5.912",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-78",
        "-5.831",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-78",
        "-5.749",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-78",
        "-5.662",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-78",
        "-5.572",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-78",
        "-5.480",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-78",
        "-5.384",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-78",
        "-5.286",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-78",
        "-5.184",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-78",
        "-5.079",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-78",
        "-4.972",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-78",
        "-4.861",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-78",
        "-4.746",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-78",
        "-4.630",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-78",
        "-4.511",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-78",
        "-4.389",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-78",
        "-4.263",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-78",
        "-4.136",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-78",
        "-4.006",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-78",
        "-3.873",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-78",
        "-3.737",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-78",
        "-3.599",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-78",
        "-3.458",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-78",
        "-3.315",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-78",
        "-3.169",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-78",
        "-3.021",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-78",
        "-2.871",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-78",
        "-2.718",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-78",
        "-2.564",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-78",
        "-2.406",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-78",
        "-2.247",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-78",
        "-2.085",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-78",
        "-1.922",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-78",
        "-1.756",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-78",
        "-1.588",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-78",
        "-1.419",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-78",
        "-1.248",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-78",
        "-1.075",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-78",
        "-0.900",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-78",
        "-0.723",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-78",
        "-0.545",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-78",
        "-0.365",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-78",
        "-0.184",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-78",
        "-0.001",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-78",
        "0.183",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-78",
        "0.369",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-78",
        "0.555",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-78",
        "0.744",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-78",
        "0.933",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-78",
        "1.123",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-78",
        "1.315",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-78",
        "1.508",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-78",
        "1.702",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-78",
        "1.896",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-78",
        "2.092",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-78",
        "2.288",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-78",
        "2.485",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-78",
        "2.682",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-78",
        "2.881",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-78",
        "3.079",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-78",
        "3.278",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-78",
        "3.479",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-78",
        "3.678",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-78",
        "3.879",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-78",
        "4.079",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-78",
        "4.281",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-78",
        "4.481",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-78",
        "4.682",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-78",
        "4.883",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-78",
        "5.085",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-78",
        "5.285",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-78",
        "5.486",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-78",
        "5.686",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-78",
        "5.887",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-78",
        "6.087",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-78",
        "6.286",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-78",
        "6.485",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-78",
        "6.683",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-78",
        "6.881",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-78",
        "7.078",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-78",
        "7.274",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-78",
        "7.470",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-78",
        "7.665",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-78",
        "7.859",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-78",
        "8.052",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-78",
        "8.242",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-78",
        "8.434",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-78",
        "8.623",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-78",
        "8.812",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-78",
        "8.998",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-78",
        "9.185",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-78",
        "9.369",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-78",
        "9.552",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-78",
        "9.734",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-78",
        "9.914",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-78",
        "10.092",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-78",
        "10.269",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-78",
        "10.444",
        "-10.206",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-78",
        "10.618",
        "-10.102",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-77",
        "11.150",
        "-9.995",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-77",
        "11.320",
        "-9.885",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-77",
        "11.487",
        "-9.772",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-77",
        "11.652",
        "-9.655",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-77",
        "11.815",
        "-9.536",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-77",
        "11.976",
        "-9.414",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-77",
        "12.135",
        "-9.290",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-77",
        "12.292",
        "-9.162",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-77",
        "12.447",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-77",
        "12.599",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-77",
        "12.750",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-77",
        "12.897",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-77",
        "13.043",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-77",
        "13.186",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-77",
        "13.326",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-77",
        "13.464",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-77",
        "13.600",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-77",
        "13.733",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-77",
        "13.863",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-77",
        "13.991",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-77",
        "14.116",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-77",
        "14.238",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-77",
        "14.358",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-77",
        "14.474",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-77",
        "14.587",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-77",
        "14.698",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-77",
        "14.806",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-77",
        "14.911",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-77",
        "15.013",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-77",
        "15.112",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-77",
        "15.208",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-77",
        "15.301",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-77",
        "15.389",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-77",
        "15.477",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-77",
        "15.559",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-77",
        "15.639",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-77",
        "15.716",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-77",
        "15.790",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-77",
        "15.861",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-77",
        "15.928",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-77",
        "15.991",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-77",
        "16.052",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-77",
        "16.109",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-77",
        "16.163",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-77",
        "16.213",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-77",
        "16.260",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-77",
        "16.304",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-77",
        "16.344",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-77",
        "16.381",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-77",
        "16.414",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-77",
        "16.444",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-77",
        "16.470",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-77",
        "16.493",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-77",
        "16.513",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-77",
        "16.529",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-77",
        "16.541",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-77",
        "16.550",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-77",
        "16.556",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-77",
        "16.557",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-77",
        "16.556",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-77",
        "16.552",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-77",
        "16.543",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-77",
        "16.531",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-77",
        "16.515",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-77",
        "16.496",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-77",
        "16.474",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-77",
        "16.448",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-77",
        "16.419",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-77",
        "16.386",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-77",
        "16.350",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-77",
        "16.310",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-77",
        "16.267",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-77",
        "16.220",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-77",
        "16.171",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-77",
        "16.118",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-77",
        "16.061",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-77",
        "16.001",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-77",
        "15.938",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-77",
        "15.871",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-77",
        "15.801",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-77",
        "15.728",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-77",
        "15.652",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-77",
        "15.572",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-77",
        "15.489",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-77",
        "15.404",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-77",
        "15.314",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-77",
        "15.222",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-77",
        "15.127",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-77",
        "15.028",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-77",
        "14.926",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-77",
        "14.822",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-77",
        "14.715",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-77",
        "14.605",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-77",
        "14.491",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-77",
        "14.376",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-77",
        "14.257",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-77",
        "14.135",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-77",
        "14.010",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-77",
        "13.883",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-77",
        "13.753",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-77",
        "13.620",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-77",
        "13.486",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-77",
        "13.348",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-77",
        "13.208",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-77",
        "13.065",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-77",
        "12.920",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-77",
        "12.773",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-77",
        "12.623",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-77",
        "12.471",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-77",
        "12.316",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-77",
        "12.160",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-77",
        "12.001",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-77",
        "11.840",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-77",
        "11.677",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-77",
        "11.513",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-77",
        "11.346",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-77",
        "11.177",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-77",
        "11.006",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-77",
        "10.834",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-77",
        "10.660",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-77",
        "10.484",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-77",
        "10.306",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-77",
        "10.127",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-77",
        "9.946",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-77",
        "9.764",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-77",
        "9.581",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-77",
        "9.396",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-77",
        "9.210",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-77",
        "9.023",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-77",
        "8.834",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-77",
        "8.643",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-77",
        "8.453",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-77",
        "8.261",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-77",
        "8.068",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-77",
        "7.874",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-77",
        "7.680",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-77",
        "7.484",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-77",
        "7.288",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-77",
        "7.091",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-77",
        "6.894",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-77",
        "6.695",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-77",
        "6.497",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-77",
        "6.298",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-77",
        "6.099",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-77",
        "5.899",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-77",
        "5.699",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-77",
        "5.499",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-77",
        "5.299",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-77",
        "5.099",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-77",
        "4.898",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-77",
        "4.698",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-77",
        "4.499",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-77",
        "4.298",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-77",
        "4.099",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-77",
        "3.899",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-77",
        "3.700",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-77",
        "3.502",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-77",
        "3.303",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-77",
        "3.106",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-77",
        "2.909",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-77",
        "2.712",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-77",
        "2.517",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-77",
        "2.322",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-77",
        "2.128",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-77",
        "1.935",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-77",
        "1.743",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-77",
        "1.552",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-77",
        "1.362",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-77",
        "1.173",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-77",
        "0.985",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-77",
        "0.799",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-77",
        "0.614",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-77",
        "0.430",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-77",
        "0.248",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-77",
        "0.067",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-77",
        "-0.113",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-77",
        "-0.290",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-77",
        "-0.467",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-77",
        "-0.641",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-77",
        "-0.813",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-77",
        "-0.985",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-77",
        "-1.153",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-77",
        "-1.320",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-77",
        "-1.486",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-77",
        "-1.649",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-77",
        "-1.810",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-77",
        "-1.969",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-77",
        "-2.126",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-77",
        "-2.280",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-77",
        "-2.433",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-77",
        "-2.583",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-77",
        "-2.732",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-77",
        "-2.877",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-77",
        "-3.020",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-77",
        "-3.160",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-77",
        "-3.299",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-77",
        "-3.434",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-77",
        "-3.567",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-77",
        "-3.697",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-77",
        "-3.824",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-77",
        "-3.950",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-77",
        "-4.072",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-77",
        "-4.191",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-77",
        "-4.307",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-77",
        "-4.422",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-77",
        "-4.532",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-77",
        "-4.639",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-77",
        "-4.744",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-77",
        "-4.846",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-77",
        "-4.945",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-77",
        "-5.041",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-77",
        "-5.134",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-77",
        "-5.224",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-77",
        "-5.310",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-77",
        "-5.394",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-77",
        "-5.474",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-77",
        "-5.551",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-77",
        "-5.623",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-77",
        "-5.694",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-77",
        "-5.761",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-77",
        "-5.826",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-77",
        "-5.885",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-77",
        "-5.943",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-77",
        "-5.996",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-77",
        "-6.047",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-77",
        "-6.094",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-77",
        "-6.137",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-77",
        "-6.178",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-77",
        "-6.214",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-77",
        "-6.247",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-77",
        "-6.278",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-77",
        "-6.304",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-77",
        "-6.327",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-77",
        "-6.346",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-77",
        "-6.363",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-77",
        "-6.375",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-77",
        "-6.384",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-77",
        "-6.389",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-77",
        "-6.392",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-77",
        "-6.390",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-77",
        "-6.385",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-77",
        "-6.377",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-77",
        "-6.365",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-77",
        "-6.350",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-77",
        "-6.331",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-77",
        "-6.308",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-77",
        "-6.282",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-77",
        "-6.253",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-77",
        "-6.220",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-77",
        "-6.183",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-77",
        "-6.144",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-77",
        "-6.100",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-77",
        "-6.055",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-77",
        "-6.005",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-77",
        "-5.951",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-77",
        "-5.895",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-77",
        "-5.835",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-77",
        "-5.771",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-77",
        "-5.705",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-77",
        "-5.635",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-77",
        "-5.562",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-77",
        "-5.485",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-77",
        "-5.406",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-77",
        "-5.323",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-77",
        "-5.237",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-77",
        "-5.148",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-77",
        "-5.056",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-77",
        "-4.960",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-77",
        "-4.862",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-77",
        "-4.761",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-77",
        "-4.656",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-77",
        "-4.549",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-77",
        "-4.438",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-77",
        "-4.326",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-77",
        "-4.209",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-77",
        "-4.090",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-77",
        "-3.968",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-77",
        "-3.844",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-77",
        "-3.716",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-77",
        "-3.587",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-77",
        "-3.455",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-77",
        "-3.319",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-77",
        "-3.181",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-77",
        "-3.042",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-77",
        "-2.899",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-77",
        "-2.754",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-77",
        "-2.606",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-77",
        "-2.457",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-77",
        "-2.304",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-77",
        "-2.151",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-77",
        "-1.994",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-77",
        "-1.835",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-77",
        "-1.674",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-77",
        "-1.511",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-77",
        "-1.346",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-77",
        "-1.179",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-77",
        "-1.011",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-77",
        "-0.840",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-77",
        "-0.668",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-77",
        "-0.493",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-77",
        "-0.317",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-77",
        "-0.140",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-77",
        "0.039",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-77",
        "0.219",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-77",
        "0.402",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-77",
        "0.585",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-77",
        "0.771",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-77",
        "0.957",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-77",
        "1.144",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-77",
        "1.333",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-77",
        "1.522",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-77",
        "1.713",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-77",
        "1.905",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-77",
        "2.099",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-77",
        "2.292",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-77",
        "2.487",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-77",
        "2.682",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-77",
        "2.879",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-77",
        "3.075",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-77",
        "3.273",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-77",
        "3.471",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-77",
        "3.670",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-77",
        "3.868",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-77",
        "4.068",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-77",
        "4.267",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-77",
        "4.467",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-77",
        "4.667",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-77",
        "4.868",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-77",
        "5.068",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-77",
        "5.268",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-77",
        "5.468",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-77",
        "5.668",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-77",
        "5.868",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-77",
        "6.068",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-77",
        "6.267",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-77",
        "6.466",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-77",
        "6.665",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-77",
        "6.863",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-77",
        "7.061",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-77",
        "7.257",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-77",
        "7.454",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-77",
        "7.649",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-77",
        "7.844",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-77",
        "8.038",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-77",
        "8.231",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-77",
        "8.423",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-77",
        "8.614",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-77",
        "8.804",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-77",
        "8.993",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-77",
        "9.181",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-77",
        "9.367",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-77",
        "9.552",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-77",
        "9.736",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-77",
        "9.919",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-77",
        "10.099",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-77",
        "10.279",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-77",
        "10.457",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-77",
        "10.632",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-77",
        "10.807",
        "-10.206",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-77",
        "10.980",
        "-10.102",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-76",
        "11.508",
        "-9.995",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-76",
        "11.677",
        "-9.885",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-76",
        "11.843",
        "-9.772",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-76",
        "12.008",
        "-9.655",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-76",
        "12.170",
        "-9.536",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-76",
        "12.331",
        "-9.414",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-76",
        "12.489",
        "-9.290",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-76",
        "12.645",
        "-9.162",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-76",
        "12.800",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-76",
        "12.951",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-76",
        "13.101",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-76",
        "13.248",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-76",
        "13.394",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-76",
        "13.535",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-76",
        "13.675",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-76",
        "13.813",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-76",
        "13.948",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-76",
        "14.081",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-76",
        "14.210",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-76",
        "14.337",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-76",
        "14.461",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-76",
        "14.583",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-76",
        "14.702",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-76",
        "14.818",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-76",
        "14.931",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-76",
        "15.042",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-76",
        "15.148",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-76",
        "15.253",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-76",
        "15.355",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-76",
        "15.453",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-76",
        "15.549",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-76",
        "15.641",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-76",
        "15.730",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-76",
        "15.816",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-76",
        "15.899",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-76",
        "15.979",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-76",
        "16.055",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-76",
        "16.129",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-76",
        "16.199",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-76",
        "16.266",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-76",
        "16.329",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-76",
        "16.389",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-76",
        "16.447",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-76",
        "16.500",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-76",
        "16.550",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-76",
        "16.597",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-76",
        "16.641",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-76",
        "16.680",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-76",
        "16.717",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-76",
        "16.751",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-76",
        "16.780",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-76",
        "16.807",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-76",
        "16.829",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-76",
        "16.849",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-76",
        "16.865",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-76",
        "16.877",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-76",
        "16.886",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-76",
        "16.892",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-76",
        "16.893",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-76",
        "16.892",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-76",
        "16.887",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-76",
        "16.878",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-76",
        "16.867",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-76",
        "16.851",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-76",
        "16.832",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-76",
        "16.810",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-76",
        "16.784",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-76",
        "16.755",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-76",
        "16.723",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-76",
        "16.686",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-76",
        "16.647",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-76",
        "16.604",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-76",
        "16.557",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-76",
        "16.508",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-76",
        "16.454",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-76",
        "16.398",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-76",
        "16.339",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-76",
        "16.276",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-76",
        "16.209",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-76",
        "16.140",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-76",
        "16.067",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-76",
        "15.991",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-76",
        "15.912",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-76",
        "15.829",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-76",
        "15.744",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-76",
        "15.655",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-76",
        "15.563",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-76",
        "15.468",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-76",
        "15.370",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-76",
        "15.269",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-76",
        "15.165",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-76",
        "15.058",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-76",
        "14.948",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-76",
        "14.836",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-76",
        "14.720",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-76",
        "14.602",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-76",
        "14.480",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-76",
        "14.357",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-76",
        "14.230",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-76",
        "14.101",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-76",
        "13.968",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-76",
        "13.834",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-76",
        "13.697",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-76",
        "13.558",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-76",
        "13.416",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-76",
        "13.270",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-76",
        "13.124",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-76",
        "12.974",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-76",
        "12.824",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-76",
        "12.670",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-76",
        "12.513",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-76",
        "12.356",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-76",
        "12.195",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-76",
        "12.033",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-76",
        "11.869",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-76",
        "11.703",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-76",
        "11.535",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-76",
        "11.365",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-76",
        "11.193",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-76",
        "11.020",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-76",
        "10.845",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-76",
        "10.668",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-76",
        "10.489",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-76",
        "10.310",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-76",
        "10.129",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-76",
        "9.946",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-76",
        "9.761",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-76",
        "9.576",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-76",
        "9.389",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-76",
        "9.201",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-76",
        "9.013",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-76",
        "8.822",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-76",
        "8.631",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-76",
        "8.439",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-76",
        "8.246",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-76",
        "8.052",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-76",
        "7.858",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-76",
        "7.662",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-76",
        "7.466",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-76",
        "7.269",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-76",
        "7.072",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-76",
        "6.875",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-76",
        "6.676",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-76",
        "6.478",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-76",
        "6.279",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-76",
        "6.080",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-76",
        "5.881",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-76",
        "5.681",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-76",
        "5.482",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-76",
        "5.282",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-76",
        "5.083",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-76",
        "4.884",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-76",
        "4.685",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-76",
        "4.486",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-76",
        "4.288",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-76",
        "4.089",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-76",
        "3.892",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-76",
        "3.695",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-76",
        "3.497",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-76",
        "3.302",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-76",
        "3.106",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-76",
        "2.911",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-76",
        "2.717",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-76",
        "2.524",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-76",
        "2.331",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-76",
        "2.140",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-76",
        "1.951",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-76",
        "1.761",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-76",
        "1.573",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-76",
        "1.386",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-76",
        "1.200",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-76",
        "1.016",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-76",
        "0.833",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-76",
        "0.651",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-76",
        "0.471",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-76",
        "0.292",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-76",
        "0.115",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-76",
        "-0.059",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-76",
        "-0.234",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-76",
        "-0.405",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-76",
        "-0.576",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-76",
        "-0.743",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-76",
        "-0.911",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-76",
        "-1.075",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-76",
        "-1.238",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-76",
        "-1.398",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-76",
        "-1.556",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-76",
        "-1.713",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-76",
        "-1.866",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-76",
        "-2.019",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-76",
        "-2.167",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-76",
        "-2.315",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-76",
        "-2.460",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-76",
        "-2.603",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-76",
        "-2.743",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-76",
        "-2.880",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-76",
        "-3.015",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-76",
        "-3.147",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-76",
        "-3.276",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-76",
        "-3.404",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-76",
        "-3.528",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-76",
        "-3.650",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-76",
        "-3.768",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-76",
        "-3.885",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-76",
        "-3.997",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-76",
        "-4.108",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-76",
        "-4.216",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-76",
        "-4.320",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-76",
        "-4.421",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-76",
        "-4.520",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-76",
        "-4.615",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-76",
        "-4.708",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-76",
        "-4.797",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-76",
        "-4.883",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-76",
        "-4.966",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-76",
        "-5.045",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-76",
        "-5.122",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-76",
        "-5.196",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-76",
        "-5.266",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-76",
        "-5.332",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-76",
        "-5.396",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-76",
        "-5.456",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-76",
        "-5.513",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-76",
        "-5.567",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-76",
        "-5.617",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-76",
        "-5.663",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-76",
        "-5.707",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-76",
        "-5.747",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-76",
        "-5.784",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-76",
        "-5.817",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-76",
        "-5.846",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-76",
        "-5.873",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-76",
        "-5.895",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-76",
        "-5.915",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-76",
        "-5.931",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-76",
        "-5.943",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-76",
        "-5.952",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-76",
        "-5.958",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-76",
        "-5.960",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-76",
        "-5.958",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-76",
        "-5.954",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-76",
        "-5.945",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-76",
        "-5.933",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-76",
        "-5.918",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-76",
        "-5.900",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-76",
        "-5.877",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-76",
        "-5.851",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-76",
        "-5.822",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-76",
        "-5.789",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-76",
        "-5.754",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-76",
        "-5.713",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-76",
        "-5.670",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-76",
        "-5.625",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-76",
        "-5.574",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-76",
        "-5.522",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-76",
        "-5.465",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-76",
        "-5.406",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-76",
        "-5.342",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-76",
        "-5.276",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-76",
        "-5.206",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-76",
        "-5.133",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-76",
        "-5.058",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-76",
        "-4.979",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-76",
        "-4.896",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-76",
        "-4.810",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-76",
        "-4.721",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-76",
        "-4.630",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-76",
        "-4.535",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-76",
        "-4.437",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-76",
        "-4.336",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-76",
        "-4.231",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-76",
        "-4.125",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-76",
        "-4.015",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-76",
        "-3.902",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-76",
        "-3.787",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-76",
        "-3.669",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-76",
        "-3.547",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-76",
        "-3.423",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-76",
        "-3.296",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-76",
        "-3.168",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-76",
        "-3.035",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-76",
        "-2.901",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-76",
        "-2.763",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-76",
        "-2.624",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-76",
        "-2.482",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-76",
        "-2.338",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-76",
        "-2.191",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-76",
        "-2.042",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-76",
        "-1.890",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-76",
        "-1.736",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-76",
        "-1.580",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-76",
        "-1.422",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-76",
        "-1.263",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-76",
        "-1.100",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-76",
        "-0.936",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-76",
        "-0.770",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-76",
        "-0.602",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-76",
        "-0.432",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-76",
        "-0.261",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-76",
        "-0.086",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-76",
        "0.089",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-76",
        "0.265",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-76",
        "0.444",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-76",
        "0.623",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-76",
        "0.805",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-76",
        "0.988",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-76",
        "1.171",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-76",
        "1.357",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-76",
        "1.544",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-76",
        "1.732",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-76",
        "1.921",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-76",
        "2.111",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-76",
        "2.302",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-76",
        "2.494",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-76",
        "2.687",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-76",
        "2.881",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-76",
        "3.076",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-76",
        "3.271",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-76",
        "3.467",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-76",
        "3.664",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-76",
        "3.861",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-76",
        "4.059",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-76",
        "4.256",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-76",
        "4.456",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-76",
        "4.654",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-76",
        "4.854",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-76",
        "5.053",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-76",
        "5.252",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-76",
        "5.451",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-76",
        "5.650",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-76",
        "5.850",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-76",
        "6.049",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-76",
        "6.249",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-76",
        "6.447",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-76",
        "6.646",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-76",
        "6.844",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-76",
        "7.042",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-76",
        "7.239",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-76",
        "7.436",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-76",
        "7.632",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-76",
        "7.827",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-76",
        "8.022",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-76",
        "8.216",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-76",
        "8.409",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-76",
        "8.601",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-76",
        "8.793",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-76",
        "8.983",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-76",
        "9.172",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-76",
        "9.360",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-76",
        "9.548",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-76",
        "9.733",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-76",
        "9.917",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-76",
        "10.100",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-76",
        "10.282",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-76",
        "10.462",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-76",
        "10.640",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-76",
        "10.817",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-76",
        "10.993",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-76",
        "11.167",
        "-10.206",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-76",
        "11.339",
        "-10.102",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-75",
        "11.864",
        "-9.995",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-75",
        "12.031",
        "-9.885",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-75",
        "12.197",
        "-9.772",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-75",
        "12.360",
        "-9.655",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-75",
        "12.523",
        "-9.536",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-75",
        "12.682",
        "-9.414",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-75",
        "12.840",
        "-9.290",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-75",
        "12.996",
        "-9.162",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-75",
        "13.149",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-75",
        "13.300",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-75",
        "13.448",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-75",
        "13.595",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-75",
        "13.739",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-75",
        "13.881",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-75",
        "14.021",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-75",
        "14.158",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-75",
        "14.292",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-75",
        "14.423",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-75",
        "14.552",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-75",
        "14.679",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-75",
        "14.803",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-75",
        "14.924",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-75",
        "15.042",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-75",
        "15.157",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-75",
        "15.270",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-75",
        "15.380",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-75",
        "15.487",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-75",
        "15.591",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-75",
        "15.692",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-75",
        "15.790",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-75",
        "15.885",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-75",
        "15.977",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-75",
        "16.065",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-75",
        "16.152",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-75",
        "16.234",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-75",
        "16.313",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-75",
        "16.389",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-75",
        "16.463",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-75",
        "16.533",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-75",
        "16.599",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-75",
        "16.662",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-75",
        "16.723",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-75",
        "16.779",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-75",
        "16.832",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-75",
        "16.882",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-75",
        "16.928",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-75",
        "16.972",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-75",
        "17.012",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-75",
        "17.048",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-75",
        "17.081",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-75",
        "17.110",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-75",
        "17.137",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-75",
        "17.159",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-75",
        "17.179",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-75",
        "17.195",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-75",
        "17.207",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-75",
        "17.216",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-75",
        "17.222",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-75",
        "17.223",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-75",
        "17.222",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-75",
        "17.217",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-75",
        "17.208",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-75",
        "17.197",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-75",
        "17.182",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-75",
        "17.163",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-75",
        "17.141",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-75",
        "17.115",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-75",
        "17.086",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-75",
        "17.053",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-75",
        "17.018",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-75",
        "16.978",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-75",
        "16.935",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-75",
        "16.890",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-75",
        "16.840",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-75",
        "16.788",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-75",
        "16.731",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-75",
        "16.671",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-75",
        "16.609",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-75",
        "16.543",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-75",
        "16.473",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-75",
        "16.402",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-75",
        "16.326",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-75",
        "16.246",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-75",
        "16.165",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-75",
        "16.079",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-75",
        "15.990",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-75",
        "15.899",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-75",
        "15.805",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-75",
        "15.707",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-75",
        "15.607",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-75",
        "15.504",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-75",
        "15.397",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-75",
        "15.288",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-75",
        "15.175",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-75",
        "15.060",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-75",
        "14.942",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-75",
        "14.822",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-75",
        "14.698",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-75",
        "14.572",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-75",
        "14.444",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-75",
        "14.313",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-75",
        "14.178",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-75",
        "14.042",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-75",
        "13.903",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-75",
        "13.761",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-75",
        "13.618",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-75",
        "13.471",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-75",
        "13.323",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-75",
        "13.172",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-75",
        "13.019",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-75",
        "12.864",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-75",
        "12.706",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-75",
        "12.547",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-75",
        "12.385",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-75",
        "12.222",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-75",
        "12.056",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-75",
        "11.890",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-75",
        "11.720",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-75",
        "11.550",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-75",
        "11.376",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-75",
        "11.202",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-75",
        "11.027",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-75",
        "10.849",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-75",
        "10.669",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-75",
        "10.489",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-75",
        "10.307",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-75",
        "10.124",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-75",
        "9.940",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-75",
        "9.754",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-75",
        "9.566",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-75",
        "9.378",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-75",
        "9.189",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-75",
        "8.998",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-75",
        "8.807",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-75",
        "8.616",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-75",
        "8.422",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-75",
        "8.228",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-75",
        "8.034",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-75",
        "7.839",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-75",
        "7.643",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-75",
        "7.447",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-75",
        "7.250",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-75",
        "7.052",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-75",
        "6.855",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-75",
        "6.657",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-75",
        "6.459",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-75",
        "6.261",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-75",
        "6.062",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-75",
        "5.864",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-75",
        "5.665",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-75",
        "5.467",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-75",
        "5.269",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-75",
        "5.070",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-75",
        "4.872",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-75",
        "4.675",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-75",
        "4.478",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-75",
        "4.280",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-75",
        "4.084",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-75",
        "3.888",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-75",
        "3.693",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-75",
        "3.499",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-75",
        "3.305",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-75",
        "3.112",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-75",
        "2.919",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-75",
        "2.728",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-75",
        "2.537",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-75",
        "2.348",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-75",
        "2.159",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-75",
        "1.972",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-75",
        "1.787",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-75",
        "1.601",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-75",
        "1.418",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-75",
        "1.236",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-75",
        "1.055",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-75",
        "0.876",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-75",
        "0.698",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-75",
        "0.522",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-75",
        "0.347",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-75",
        "0.174",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-75",
        "0.003",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-75",
        "-0.166",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-75",
        "-0.334",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-75",
        "-0.499",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-75",
        "-0.663",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-75",
        "-0.825",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-75",
        "-0.985",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-75",
        "-1.143",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-75",
        "-1.298",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-75",
        "-1.452",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-75",
        "-1.603",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-75",
        "-1.752",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-75",
        "-1.898",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-75",
        "-2.043",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-75",
        "-2.184",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-75",
        "-2.323",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-75",
        "-2.460",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-75",
        "-2.594",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-75",
        "-2.727",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-75",
        "-2.856",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-75",
        "-2.982",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-75",
        "-3.106",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-75",
        "-3.227",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-75",
        "-3.345",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-75",
        "-3.461",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-75",
        "-3.574",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-75",
        "-3.683",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-75",
        "-3.790",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-75",
        "-3.894",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-75",
        "-3.996",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-75",
        "-4.093",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-75",
        "-4.188",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-75",
        "-4.280",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-75",
        "-4.369",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-75",
        "-4.454",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-75",
        "-4.537",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-75",
        "-4.617",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-75",
        "-4.693",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-75",
        "-4.766",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-75",
        "-4.835",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-75",
        "-4.902",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-75",
        "-4.965",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-75",
        "-5.025",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-75",
        "-5.082",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-75",
        "-5.135",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-75",
        "-5.185",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-75",
        "-5.232",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-75",
        "-5.275",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-75",
        "-5.315",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-75",
        "-5.351",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-75",
        "-5.384",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-75",
        "-5.414",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-75",
        "-5.440",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-75",
        "-5.463",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-75",
        "-5.482",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-75",
        "-5.498",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-75",
        "-5.510",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-75",
        "-5.519",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-75",
        "-5.525",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-75",
        "-5.527",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-75",
        "-5.526",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-75",
        "-5.521",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-75",
        "-5.512",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-75",
        "-5.500",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-75",
        "-5.485",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-75",
        "-5.466",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-75",
        "-5.444",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-75",
        "-5.419",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-75",
        "-5.389",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-75",
        "-5.357",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-75",
        "-5.321",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-75",
        "-5.281",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-75",
        "-5.239",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-75",
        "-5.192",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-75",
        "-5.143",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-75",
        "-5.090",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-75",
        "-5.034",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-75",
        "-4.975",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-75",
        "-4.912",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-75",
        "-4.846",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-75",
        "-4.777",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-75",
        "-4.704",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-75",
        "-4.628",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-75",
        "-4.550",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-75",
        "-4.467",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-75",
        "-4.382",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-75",
        "-4.294",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-75",
        "-4.202",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-75",
        "-4.108",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-75",
        "-4.011",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-75",
        "-3.910",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-75",
        "-3.806",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-75",
        "-3.700",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-75",
        "-3.590",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-75",
        "-3.479",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-75",
        "-3.363",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-75",
        "-3.245",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-75",
        "-3.124",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-75",
        "-3.001",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-75",
        "-2.875",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-75",
        "-2.746",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-75",
        "-2.615",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-75",
        "-2.482",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-75",
        "-2.345",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-75",
        "-2.206",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-75",
        "-2.064",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-75",
        "-1.920",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-75",
        "-1.774",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-75",
        "-1.626",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-75",
        "-1.475",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-75",
        "-1.322",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-75",
        "-1.167",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-75",
        "-1.010",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-75",
        "-0.850",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-75",
        "-0.689",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-75",
        "-0.525",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-75",
        "-0.360",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-75",
        "-0.192",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-75",
        "-0.024",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-75",
        "0.148",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-75",
        "0.320",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-75",
        "0.494",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-75",
        "0.671",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-75",
        "0.848",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-75",
        "1.027",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-75",
        "1.208",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-75",
        "1.389",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-75",
        "1.572",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-75",
        "1.758",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-75",
        "1.944",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-75",
        "2.130",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-75",
        "2.318",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-75",
        "2.508",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-75",
        "2.698",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-75",
        "2.889",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-75",
        "3.082",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-75",
        "3.275",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-75",
        "3.468",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-75",
        "3.663",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-75",
        "3.858",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-75",
        "4.054",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-75",
        "4.250",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-75",
        "4.447",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-75",
        "4.644",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-75",
        "4.842",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-75",
        "5.040",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-75",
        "5.237",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-75",
        "5.436",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-75",
        "5.634",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-75",
        "5.833",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-75",
        "6.031",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-75",
        "6.230",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-75",
        "6.429",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-75",
        "6.626",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-75",
        "6.824",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-75",
        "7.022",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-75",
        "7.219",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-75",
        "7.416",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-75",
        "7.612",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-75",
        "7.808",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-75",
        "8.004",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-75",
        "8.199",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-75",
        "8.392",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-75",
        "8.586",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-75",
        "8.777",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-75",
        "8.969",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-75",
        "9.159",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-75",
        "9.349",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-75",
        "9.537",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-75",
        "9.724",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-75",
        "9.911",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-75",
        "10.095",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-75",
        "10.279",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-75",
        "10.461",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-75",
        "10.641",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-75",
        "10.821",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-75",
        "10.999",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-75",
        "11.176",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-75",
        "11.350",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-75",
        "11.523",
        "-10.206",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-75",
        "11.694",
        "-10.102",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-74",
        "12.214",
        "-9.995",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-74",
        "12.382",
        "-9.885",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-74",
        "12.546",
        "-9.772",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-74",
        "12.709",
        "-9.655",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-74",
        "12.870",
        "-9.536",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-74",
        "13.029",
        "-9.414",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-74",
        "13.186",
        "-9.290",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-74",
        "13.341",
        "-9.162",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-74",
        "13.494",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-74",
        "13.644",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-74",
        "13.791",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-74",
        "13.938",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-74",
        "14.081",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-74",
        "14.222",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-74",
        "14.361",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-74",
        "14.497",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-74",
        "14.631",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-74",
        "14.762",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-74",
        "14.890",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-74",
        "15.017",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-74",
        "15.140",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-74",
        "15.260",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-74",
        "15.378",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-74",
        "15.493",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-74",
        "15.605",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-74",
        "15.715",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-74",
        "15.820",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-74",
        "15.924",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-74",
        "16.024",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-74",
        "16.122",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-74",
        "16.217",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-74",
        "16.308",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-74",
        "16.396",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-74",
        "16.482",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-74",
        "16.563",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-74",
        "16.643",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-74",
        "16.719",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-74",
        "16.791",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-74",
        "16.861",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-74",
        "16.927",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-74",
        "16.990",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-74",
        "17.050",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-74",
        "17.106",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-74",
        "17.159",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-74",
        "17.209",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-74",
        "17.255",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-74",
        "17.298",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-74",
        "17.338",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-74",
        "17.374",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-74",
        "17.407",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-74",
        "17.436",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-74",
        "17.462",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-74",
        "17.486",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-74",
        "17.504",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-74",
        "17.520",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-74",
        "17.532",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-74",
        "17.541",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-74",
        "17.546",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-74",
        "17.549",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-74",
        "17.547",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-74",
        "17.542",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-74",
        "17.534",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-74",
        "17.523",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-74",
        "17.507",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-74",
        "17.488",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-74",
        "17.467",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-74",
        "17.441",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-74",
        "17.412",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-74",
        "17.379",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-74",
        "17.344",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-74",
        "17.305",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-74",
        "17.262",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-74",
        "17.216",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-74",
        "17.167",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-74",
        "17.114",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-74",
        "17.059",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-74",
        "16.999",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-74",
        "16.937",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-74",
        "16.871",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-74",
        "16.803",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-74",
        "16.730",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-74",
        "16.654",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-74",
        "16.576",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-74",
        "16.494",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-74",
        "16.410",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-74",
        "16.322",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-74",
        "16.231",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-74",
        "16.136",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-74",
        "16.040",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-74",
        "15.939",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-74",
        "15.837",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-74",
        "15.731",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-74",
        "15.622",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-74",
        "15.511",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-74",
        "15.396",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-74",
        "15.278",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-74",
        "15.158",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-74",
        "15.035",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-74",
        "14.910",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-74",
        "14.782",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-74",
        "14.651",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-74",
        "14.518",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-74",
        "14.382",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-74",
        "14.243",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-74",
        "14.104",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-74",
        "13.960",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-74",
        "13.815",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-74",
        "13.667",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-74",
        "13.517",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-74",
        "13.364",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-74",
        "13.210",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-74",
        "13.054",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-74",
        "12.895",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-74",
        "12.734",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-74",
        "12.572",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-74",
        "12.407",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-74",
        "12.240",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-74",
        "12.072",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-74",
        "11.902",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-74",
        "11.730",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-74",
        "11.556",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-74",
        "11.382",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-74",
        "11.205",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-74",
        "11.026",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-74",
        "10.847",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-74",
        "10.665",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-74",
        "10.484",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-74",
        "10.299",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-74",
        "10.114",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-74",
        "9.928",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-74",
        "9.741",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-74",
        "9.553",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-74",
        "9.363",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-74",
        "9.173",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-74",
        "8.981",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-74",
        "8.790",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-74",
        "8.597",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-74",
        "8.403",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-74",
        "8.209",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-74",
        "8.015",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-74",
        "7.819",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-74",
        "7.623",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-74",
        "7.427",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-74",
        "7.230",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-74",
        "7.034",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-74",
        "6.836",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-74",
        "6.638",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-74",
        "6.441",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-74",
        "6.243",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-74",
        "6.046",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-74",
        "5.849",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-74",
        "5.651",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-74",
        "5.454",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-74",
        "5.257",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-74",
        "5.060",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-74",
        "4.864",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-74",
        "4.668",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-74",
        "4.473",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-74",
        "4.278",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-74",
        "4.083",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-74",
        "3.890",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-74",
        "3.696",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-74",
        "3.505",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-74",
        "3.313",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-74",
        "3.122",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-74",
        "2.933",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-74",
        "2.744",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-74",
        "2.558",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-74",
        "2.371",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-74",
        "2.185",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-74",
        "2.002",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-74",
        "1.820",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-74",
        "1.638",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-74",
        "1.458",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-74",
        "1.279",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-74",
        "1.102",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-74",
        "0.928",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-74",
        "0.754",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-74",
        "0.582",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-74",
        "0.412",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-74",
        "0.242",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-74",
        "0.076",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-74",
        "-0.089",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-74",
        "-0.252",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-74",
        "-0.413",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-74",
        "-0.572",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-74",
        "-0.729",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-74",
        "-0.883",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-74",
        "-1.036",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-74",
        "-1.187",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-74",
        "-1.335",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-74",
        "-1.481",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-74",
        "-1.625",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-74",
        "-1.765",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-74",
        "-1.904",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-74",
        "-2.040",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-74",
        "-2.174",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-74",
        "-2.305",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-74",
        "-2.434",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-74",
        "-2.559",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-74",
        "-2.683",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-74",
        "-2.803",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-74",
        "-2.921",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-74",
        "-3.035",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-74",
        "-3.148",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-74",
        "-3.257",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-74",
        "-3.363",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-74",
        "-3.467",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-74",
        "-3.568",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-74",
        "-3.665",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-74",
        "-3.760",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-74",
        "-3.851",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-74",
        "-3.939",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-74",
        "-4.024",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-74",
        "-4.107",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-74",
        "-4.186",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-74",
        "-4.262",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-74",
        "-4.335",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-74",
        "-4.404",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-74",
        "-4.470",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-74",
        "-4.533",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-74",
        "-4.593",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-74",
        "-4.649",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-74",
        "-4.702",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-74",
        "-4.752",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-74",
        "-4.798",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-74",
        "-4.842",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-74",
        "-4.881",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-74",
        "-4.917",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-74",
        "-4.950",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-74",
        "-4.980",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-74",
        "-5.005",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-74",
        "-5.028",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-74",
        "-5.048",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-74",
        "-5.064",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-74",
        "-5.076",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-74",
        "-5.084",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-74",
        "-5.090",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-74",
        "-5.092",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-74",
        "-5.091",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-74",
        "-5.086",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-74",
        "-5.077",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-74",
        "-5.065",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-74",
        "-5.050",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-74",
        "-5.031",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-74",
        "-5.009",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-74",
        "-4.984",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-74",
        "-4.955",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-74",
        "-4.923",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-74",
        "-4.886",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-74",
        "-4.847",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-74",
        "-4.805",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-74",
        "-4.759",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-74",
        "-4.710",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-74",
        "-4.658",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-74",
        "-4.601",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-74",
        "-4.542",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-74",
        "-4.480",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-74",
        "-4.415",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-74",
        "-4.345",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-74",
        "-4.273",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-74",
        "-4.198",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-74",
        "-4.119",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-74",
        "-4.038",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-74",
        "-3.953",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-74",
        "-3.865",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-74",
        "-3.774",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-74",
        "-3.680",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-74",
        "-3.583",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-74",
        "-3.483",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-74",
        "-3.380",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-74",
        "-3.274",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-74",
        "-3.165",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-74",
        "-3.053",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-74",
        "-2.939",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-74",
        "-2.822",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-74",
        "-2.701",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-74",
        "-2.579",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-74",
        "-2.453",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-74",
        "-2.325",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-74",
        "-2.195",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-74",
        "-2.061",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-74",
        "-1.925",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-74",
        "-1.787",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-74",
        "-1.646",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-74",
        "-1.503",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-74",
        "-1.357",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-74",
        "-1.209",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-74",
        "-1.060",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-74",
        "-0.908",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-74",
        "-0.753",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-74",
        "-0.596",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-74",
        "-0.438",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-74",
        "-0.277",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-74",
        "-0.114",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-74",
        "0.050",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-74",
        "0.216",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-74",
        "0.385",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-74",
        "0.555",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-74",
        "0.727",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-74",
        "0.901",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-74",
        "1.076",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-74",
        "1.253",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-74",
        "1.430",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-74",
        "1.610",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-74",
        "1.791",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-74",
        "1.974",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-74",
        "2.157",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-74",
        "2.342",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-74",
        "2.528",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-74",
        "2.715",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-74",
        "2.904",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-74",
        "3.093",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-74",
        "3.284",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-74",
        "3.475",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-74",
        "3.667",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-74",
        "3.860",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-74",
        "4.054",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-74",
        "4.248",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-74",
        "4.443",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-74",
        "4.638",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-74",
        "4.834",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-74",
        "5.029",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-74",
        "5.226",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-74",
        "5.424",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-74",
        "5.621",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-74",
        "5.818",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-74",
        "6.015",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-74",
        "6.213",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-74",
        "6.410",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-74",
        "6.609",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-74",
        "6.805",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-74",
        "7.003",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-74",
        "7.200",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-74",
        "7.397",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-74",
        "7.593",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-74",
        "7.789",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-74",
        "7.985",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-74",
        "8.179",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-74",
        "8.373",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-74",
        "8.567",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-74",
        "8.760",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-74",
        "8.952",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-74",
        "9.144",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-74",
        "9.334",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-74",
        "9.524",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-74",
        "9.712",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-74",
        "9.899",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-74",
        "10.086",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-74",
        "10.271",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-74",
        "10.455",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-74",
        "10.638",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-74",
        "10.819",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-74",
        "10.999",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-74",
        "11.177",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-74",
        "11.354",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-74",
        "11.530",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-74",
        "11.703",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-74",
        "11.875",
        "-10.206",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-74",
        "12.046",
        "-10.102",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-73",
        "12.561",
        "-9.995",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-73",
        "12.727",
        "-9.885",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-73",
        "12.892",
        "-9.772",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-73",
        "13.054",
        "-9.655",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-73",
        "13.213",
        "-9.536",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-73",
        "13.372",
        "-9.414",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-73",
        "13.528",
        "-9.290",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-73",
        "13.683",
        "-9.162",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-73",
        "13.834",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-73",
        "13.983",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-73",
        "14.131",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-73",
        "14.276",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-73",
        "14.419",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-73",
        "14.559",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-73",
        "14.697",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-73",
        "14.833",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-73",
        "14.965",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-73",
        "15.097",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-73",
        "15.224",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-73",
        "15.349",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-73",
        "15.472",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-73",
        "15.591",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-73",
        "15.709",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-73",
        "15.823",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-73",
        "15.935",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-73",
        "16.044",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-73",
        "16.149",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-73",
        "16.252",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-73",
        "16.352",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-73",
        "16.449",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-73",
        "16.543",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-73",
        "16.634",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-73",
        "16.722",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-73",
        "16.807",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-73",
        "16.889",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-73",
        "16.968",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-73",
        "17.043",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-73",
        "17.115",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-73",
        "17.184",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-73",
        "17.250",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-73",
        "17.312",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-73",
        "17.372",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-73",
        "17.428",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-73",
        "17.480",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-73",
        "17.530",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-73",
        "17.576",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-73",
        "17.619",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-73",
        "17.659",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-73",
        "17.695",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-73",
        "17.728",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-73",
        "17.757",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-73",
        "17.783",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-73",
        "17.805",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-73",
        "17.824",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-73",
        "17.840",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-73",
        "17.853",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-73",
        "17.862",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-73",
        "17.867",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-73",
        "17.868",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-73",
        "17.867",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-73",
        "17.862",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-73",
        "17.854",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-73",
        "17.842",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-73",
        "17.827",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-73",
        "17.808",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-73",
        "17.787",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-73",
        "17.761",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-73",
        "17.732",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-73",
        "17.700",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-73",
        "17.664",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-73",
        "17.625",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-73",
        "17.583",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-73",
        "17.538",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-73",
        "17.489",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-73",
        "17.436",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-73",
        "17.381",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-73",
        "17.322",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-73",
        "17.260",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-73",
        "17.195",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-73",
        "17.126",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-73",
        "17.054",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-73",
        "16.980",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-73",
        "16.901",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-73",
        "16.820",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-73",
        "16.736",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-73",
        "16.648",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-73",
        "16.557",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-73",
        "16.464",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-73",
        "16.367",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-73",
        "16.268",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-73",
        "16.165",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-73",
        "16.060",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-73",
        "15.952",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-73",
        "15.840",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-73",
        "15.727",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-73",
        "15.610",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-73",
        "15.490",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-73",
        "15.368",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-73",
        "15.243",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-73",
        "15.116",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-73",
        "14.986",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-73",
        "14.854",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-73",
        "14.718",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-73",
        "14.580",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-73",
        "14.441",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-73",
        "14.298",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-73",
        "14.153",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-73",
        "14.006",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-73",
        "13.858",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-73",
        "13.706",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-73",
        "13.552",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-73",
        "13.396",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-73",
        "13.238",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-73",
        "13.078",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-73",
        "12.917",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-73",
        "12.753",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-73",
        "12.588",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-73",
        "12.420",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-73",
        "12.251",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-73",
        "12.080",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-73",
        "11.907",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-73",
        "11.733",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-73",
        "11.558",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-73",
        "11.380",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-73",
        "11.201",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-73",
        "11.021",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-73",
        "10.839",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-73",
        "10.657",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-73",
        "10.472",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-73",
        "10.287",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-73",
        "10.101",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-73",
        "9.913",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-73",
        "9.725",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-73",
        "9.536",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-73",
        "9.346",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-73",
        "9.155",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-73",
        "8.963",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-73",
        "8.771",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-73",
        "8.577",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-73",
        "8.384",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-73",
        "8.189",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-73",
        "7.995",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-73",
        "7.799",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-73",
        "7.603",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-73",
        "7.407",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-73",
        "7.211",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-73",
        "7.015",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-73",
        "6.818",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-73",
        "6.621",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-73",
        "6.425",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-73",
        "6.228",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-73",
        "6.033",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-73",
        "5.836",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-73",
        "5.640",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-73",
        "5.445",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-73",
        "5.249",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-73",
        "5.054",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-73",
        "4.860",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-73",
        "4.666",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-73",
        "4.472",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-73",
        "4.280",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-73",
        "4.088",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-73",
        "3.897",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-73",
        "3.706",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-73",
        "3.517",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-73",
        "3.329",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-73",
        "3.141",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-73",
        "2.955",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-73",
        "2.769",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-73",
        "2.585",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-73",
        "2.402",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-73",
        "2.221",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-73",
        "2.040",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-73",
        "1.861",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-73",
        "1.683",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-73",
        "1.508",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-73",
        "1.333",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-73",
        "1.160",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-73",
        "0.988",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-73",
        "0.819",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-73",
        "0.651",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-73",
        "0.485",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-73",
        "0.322",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-73",
        "0.160",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-73",
        "-0.001",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-73",
        "-0.158",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-73",
        "-0.315",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-73",
        "-0.469",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-73",
        "-0.621",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-73",
        "-0.771",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-73",
        "-0.918",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-73",
        "-1.063",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-73",
        "-1.205",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-73",
        "-1.346",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-73",
        "-1.484",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-73",
        "-1.619",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-73",
        "-1.753",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-73",
        "-1.883",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-73",
        "-2.011",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-73",
        "-2.136",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-73",
        "-2.259",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-73",
        "-2.379",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-73",
        "-2.495",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-73",
        "-2.610",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-73",
        "-2.721",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-73",
        "-2.830",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-73",
        "-2.936",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-73",
        "-3.039",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-73",
        "-3.139",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-73",
        "-3.236",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-73",
        "-3.330",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-73",
        "-3.421",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-73",
        "-3.509",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-73",
        "-3.593",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-73",
        "-3.675",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-73",
        "-3.754",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-73",
        "-3.830",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-73",
        "-3.902",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-73",
        "-3.971",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-73",
        "-4.037",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-73",
        "-4.100",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-73",
        "-4.158",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-73",
        "-4.215",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-73",
        "-4.268",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-73",
        "-4.317",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-73",
        "-4.364",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-73",
        "-4.406",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-73",
        "-4.445",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-73",
        "-4.482",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-73",
        "-4.514",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-73",
        "-4.543",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-73",
        "-4.569",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-73",
        "-4.592",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-73",
        "-4.611",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-73",
        "-4.627",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-73",
        "-4.639",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-73",
        "-4.648",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-73",
        "-4.653",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-73",
        "-4.656",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-73",
        "-4.653",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-73",
        "-4.649",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-73",
        "-4.640",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-73",
        "-4.629",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-73",
        "-4.614",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-73",
        "-4.596",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-73",
        "-4.573",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-73",
        "-4.548",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-73",
        "-4.519",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-73",
        "-4.487",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-73",
        "-4.451",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-73",
        "-4.413",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-73",
        "-4.370",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-73",
        "-4.324",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-73",
        "-4.275",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-73",
        "-4.224",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-73",
        "-4.168",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-73",
        "-4.109",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-73",
        "-4.046",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-73",
        "-3.981",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-73",
        "-3.913",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-73",
        "-3.840",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-73",
        "-3.766",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-73",
        "-3.688",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-73",
        "-3.606",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-73",
        "-3.522",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-73",
        "-3.434",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-73",
        "-3.344",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-73",
        "-3.250",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-73",
        "-3.154",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-73",
        "-3.054",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-73",
        "-2.952",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-73",
        "-2.847",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-73",
        "-2.738",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-73",
        "-2.627",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-73",
        "-2.513",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-73",
        "-2.396",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-73",
        "-2.278",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-73",
        "-2.155",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-73",
        "-2.030",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-73",
        "-1.903",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-73",
        "-1.773",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-73",
        "-1.640",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-73",
        "-1.505",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-73",
        "-1.368",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-73",
        "-1.227",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-73",
        "-1.085",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-73",
        "-0.941",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-73",
        "-0.794",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-73",
        "-0.644",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-73",
        "-0.492",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-73",
        "-0.339",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-73",
        "-0.183",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-73",
        "-0.025",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-73",
        "0.135",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-73",
        "0.297",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-73",
        "0.461",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-73",
        "0.626",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-73",
        "0.793",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-73",
        "0.962",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-73",
        "1.133",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-73",
        "1.306",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-73",
        "1.480",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-73",
        "1.656",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-73",
        "1.834",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-73",
        "2.012",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-73",
        "2.192",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-73",
        "2.373",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-73",
        "2.557",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-73",
        "2.740",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-73",
        "2.926",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-73",
        "3.112",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-73",
        "3.299",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-73",
        "3.488",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-73",
        "3.677",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-73",
        "3.868",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-73",
        "4.059",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-73",
        "4.250",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-73",
        "4.443",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-73",
        "4.636",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-73",
        "4.830",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-73",
        "5.024",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-73",
        "5.219",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-73",
        "5.414",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-73",
        "5.610",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-73",
        "5.806",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-73",
        "6.002",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-73",
        "6.198",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-73",
        "6.395",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-73",
        "6.591",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-73",
        "6.788",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-73",
        "6.984",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-73",
        "7.181",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-73",
        "7.377",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-73",
        "7.573",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-73",
        "7.769",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-73",
        "7.964",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-73",
        "8.159",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-73",
        "8.354",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-73",
        "8.547",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-73",
        "8.740",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-73",
        "8.933",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-73",
        "9.126",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-73",
        "9.317",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-73",
        "9.506",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-73",
        "9.696",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-73",
        "9.885",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-73",
        "10.073",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-73",
        "10.259",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-73",
        "10.444",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-73",
        "10.629",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-73",
        "10.811",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-73",
        "10.993",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-73",
        "11.174",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-73",
        "11.353",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-73",
        "11.530",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-73",
        "11.706",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-73",
        "11.880",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-73",
        "12.053",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-73",
        "12.224",
        "-10.206",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-73",
        "12.394",
        "-10.102",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-72",
        "12.905",
        "-9.995",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-72",
        "13.071",
        "-9.885",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-72",
        "13.234",
        "-9.772",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-72",
        "13.394",
        "-9.655",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-72",
        "13.554",
        "-9.536",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-72",
        "13.711",
        "-9.414",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-72",
        "13.866",
        "-9.290",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-72",
        "14.019",
        "-9.162",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-72",
        "14.170",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-72",
        "14.320",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-72",
        "14.466",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-72",
        "14.611",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-72",
        "14.753",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-72",
        "14.892",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-72",
        "15.029",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-72",
        "15.164",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-72",
        "15.296",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-72",
        "15.425",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-72",
        "15.553",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-72",
        "15.677",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-72",
        "15.800",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-72",
        "15.918",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-72",
        "16.035",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-72",
        "16.149",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-72",
        "16.259",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-72",
        "16.368",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-72",
        "16.473",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-72",
        "16.575",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-72",
        "16.675",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-72",
        "16.771",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-72",
        "16.865",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-72",
        "16.955",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-72",
        "17.042",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-72",
        "17.127",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-72",
        "17.208",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-72",
        "17.286",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-72",
        "17.361",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-72",
        "17.434",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-72",
        "17.502",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-72",
        "17.568",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-72",
        "17.630",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-72",
        "17.689",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-72",
        "17.745",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-72",
        "17.797",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-72",
        "17.847",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-72",
        "17.892",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-72",
        "17.935",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-72",
        "17.975",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-72",
        "18.010",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-72",
        "18.043",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-72",
        "18.072",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-72",
        "18.097",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-72",
        "18.120",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-72",
        "18.138",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-72",
        "18.155",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-72",
        "18.166",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-72",
        "18.175",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-72",
        "18.181",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-72",
        "18.182",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-72",
        "18.181",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-72",
        "18.176",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-72",
        "18.168",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-72",
        "18.157",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-72",
        "18.141",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-72",
        "18.123",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-72",
        "18.101",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-72",
        "18.076",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-72",
        "18.047",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-72",
        "18.016",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-72",
        "17.980",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-72",
        "17.941",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-72",
        "17.899",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-72",
        "17.854",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-72",
        "17.805",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-72",
        "17.753",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-72",
        "17.698",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-72",
        "17.639",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-72",
        "17.578",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-72",
        "17.513",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-72",
        "17.444",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-72",
        "17.373",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-72",
        "17.299",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-72",
        "17.221",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-72",
        "17.139",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-72",
        "17.056",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-72",
        "16.969",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-72",
        "16.879",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-72",
        "16.786",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-72",
        "16.690",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-72",
        "16.591",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-72",
        "16.489",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-72",
        "16.384",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-72",
        "16.276",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-72",
        "16.166",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-72",
        "16.052",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-72",
        "15.936",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-72",
        "15.818",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-72",
        "15.697",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-72",
        "15.572",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-72",
        "15.445",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-72",
        "15.316",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-72",
        "15.184",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-72",
        "15.050",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-72",
        "14.913",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-72",
        "14.774",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-72",
        "14.632",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-72",
        "14.488",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-72",
        "14.343",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-72",
        "14.194",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-72",
        "14.043",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-72",
        "13.890",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-72",
        "13.735",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-72",
        "13.578",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-72",
        "13.420",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-72",
        "13.258",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-72",
        "13.096",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-72",
        "12.930",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-72",
        "12.765",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-72",
        "12.596",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-72",
        "12.426",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-72",
        "12.254",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-72",
        "12.081",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-72",
        "11.906",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-72",
        "11.729",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-72",
        "11.552",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-72",
        "11.373",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-72",
        "11.192",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-72",
        "11.011",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-72",
        "10.827",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-72",
        "10.643",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-72",
        "10.458",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-72",
        "10.271",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-72",
        "10.085",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-72",
        "9.896",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-72",
        "9.707",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-72",
        "9.517",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-72",
        "9.327",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-72",
        "9.135",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-72",
        "8.942",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-72",
        "8.749",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-72",
        "8.557",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-72",
        "8.363",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-72",
        "8.169",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-72",
        "7.974",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-72",
        "7.779",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-72",
        "7.584",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-72",
        "7.389",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-72",
        "7.193",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-72",
        "6.998",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-72",
        "6.802",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-72",
        "6.607",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-72",
        "6.412",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-72",
        "6.217",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-72",
        "6.021",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-72",
        "5.827",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-72",
        "5.633",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-72",
        "5.439",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-72",
        "5.246",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-72",
        "5.053",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-72",
        "4.861",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-72",
        "4.668",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-72",
        "4.478",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-72",
        "4.288",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-72",
        "4.098",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-72",
        "3.910",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-72",
        "3.722",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-72",
        "3.536",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-72",
        "3.351",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-72",
        "3.167",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-72",
        "2.983",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-72",
        "2.802",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-72",
        "2.620",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-72",
        "2.441",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-72",
        "2.263",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-72",
        "2.086",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-72",
        "1.911",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-72",
        "1.737",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-72",
        "1.566",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-72",
        "1.396",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-72",
        "1.227",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-72",
        "1.060",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-72",
        "0.896",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-72",
        "0.733",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-72",
        "0.571",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-72",
        "0.412",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-72",
        "0.254",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-72",
        "0.099",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-72",
        "-0.054",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-72",
        "-0.205",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-72",
        "-0.354",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-72",
        "-0.500",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-72",
        "-0.644",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-72",
        "-0.786",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-72",
        "-0.926",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-72",
        "-1.064",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-72",
        "-1.198",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-72",
        "-1.331",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-72",
        "-1.460",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-72",
        "-1.587",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-72",
        "-1.712",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-72",
        "-1.833",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-72",
        "-1.953",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-72",
        "-2.069",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-72",
        "-2.183",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-72",
        "-2.294",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-72",
        "-2.403",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-72",
        "-2.508",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-72",
        "-2.610",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-72",
        "-2.710",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-72",
        "-2.806",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-72",
        "-2.899",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-72",
        "-2.990",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-72",
        "-3.077",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-72",
        "-3.161",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-72",
        "-3.243",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-72",
        "-3.321",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-72",
        "-3.396",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-72",
        "-3.469",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-72",
        "-3.537",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-72",
        "-3.602",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-72",
        "-3.665",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-72",
        "-3.723",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-72",
        "-3.779",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-72",
        "-3.832",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-72",
        "-3.881",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-72",
        "-3.927",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-72",
        "-3.969",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-72",
        "-4.008",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-72",
        "-4.045",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-72",
        "-4.077",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-72",
        "-4.107",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-72",
        "-4.132",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-72",
        "-4.154",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-72",
        "-4.173",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-72",
        "-4.189",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-72",
        "-4.201",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-72",
        "-4.210",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-72",
        "-4.216",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-72",
        "-4.217",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-72",
        "-4.216",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-72",
        "-4.211",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-72",
        "-4.203",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-72",
        "-4.191",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-72",
        "-4.176",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-72",
        "-4.158",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-72",
        "-4.136",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-72",
        "-4.111",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-72",
        "-4.082",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-72",
        "-4.049",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-72",
        "-4.015",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-72",
        "-3.976",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-72",
        "-3.934",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-72",
        "-3.888",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-72",
        "-3.840",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-72",
        "-3.788",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-72",
        "-3.733",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-72",
        "-3.674",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-72",
        "-3.612",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-72",
        "-3.548",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-72",
        "-3.479",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-72",
        "-3.408",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-72",
        "-3.332",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-72",
        "-3.255",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-72",
        "-3.174",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-72",
        "-3.090",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-72",
        "-3.003",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-72",
        "-2.914",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-72",
        "-2.821",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-72",
        "-2.725",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-72",
        "-2.625",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-72",
        "-2.523",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-72",
        "-2.419",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-72",
        "-2.311",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-72",
        "-2.201",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-72",
        "-2.087",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-72",
        "-1.971",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-72",
        "-1.852",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-72",
        "-1.730",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-72",
        "-1.607",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-72",
        "-1.480",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-72",
        "-1.351",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-72",
        "-1.219",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-72",
        "-1.084",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-72",
        "-0.948",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-72",
        "-0.808",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-72",
        "-0.667",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-72",
        "-0.523",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-72",
        "-0.376",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-72",
        "-0.228",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-72",
        "-0.078",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-72",
        "0.075",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-72",
        "0.230",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-72",
        "0.388",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-72",
        "0.547",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-72",
        "0.707",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-72",
        "0.870",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-72",
        "1.035",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-72",
        "1.202",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-72",
        "1.370",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-72",
        "1.540",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-72",
        "1.711",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-72",
        "1.885",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-72",
        "2.060",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-72",
        "2.236",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-72",
        "2.414",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-72",
        "2.593",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-72",
        "2.773",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-72",
        "2.955",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-72",
        "3.138",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-72",
        "3.322",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-72",
        "3.507",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-72",
        "3.694",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-72",
        "3.881",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-72",
        "4.070",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-72",
        "4.258",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-72",
        "4.448",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-72",
        "4.640",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-72",
        "4.831",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-72",
        "5.023",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-72",
        "5.216",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-72",
        "5.409",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-72",
        "5.603",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-72",
        "5.797",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-72",
        "5.992",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-72",
        "6.186",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-72",
        "6.382",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-72",
        "6.576",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-72",
        "6.772",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-72",
        "6.967",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-72",
        "7.163",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-72",
        "7.358",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-72",
        "7.554",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-72",
        "7.749",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-72",
        "7.944",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-72",
        "8.138",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-72",
        "8.333",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-72",
        "8.527",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-72",
        "8.720",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-72",
        "8.913",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-72",
        "9.105",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-72",
        "9.297",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-72",
        "9.487",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-72",
        "9.678",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-72",
        "9.867",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-72",
        "10.056",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-72",
        "10.243",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-72",
        "10.429",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-72",
        "10.615",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-72",
        "10.799",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-72",
        "10.982",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-72",
        "11.165",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-72",
        "11.345",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-72",
        "11.524",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-72",
        "11.703",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-72",
        "11.879",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-72",
        "12.054",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-72",
        "12.228",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-72",
        "12.399",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-72",
        "12.569",
        "-10.206",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-72",
        "12.739",
        "-10.102",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-71",
        "13.245",
        "-9.995",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-71",
        "13.408",
        "-9.885",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-71",
        "13.571",
        "-9.772",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-71",
        "13.731",
        "-9.655",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-71",
        "13.889",
        "-9.536",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-71",
        "14.046",
        "-9.414",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-71",
        "14.201",
        "-9.290",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-71",
        "14.352",
        "-9.162",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-71",
        "14.503",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-71",
        "14.650",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-71",
        "14.797",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-71",
        "14.939",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-71",
        "15.080",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-71",
        "15.219",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-71",
        "15.356",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-71",
        "15.490",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-71",
        "15.622",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-71",
        "15.751",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-71",
        "15.877",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-71",
        "16.001",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-71",
        "16.122",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-71",
        "16.240",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-71",
        "16.356",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-71",
        "16.469",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-71",
        "16.580",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-71",
        "16.688",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-71",
        "16.791",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-71",
        "16.894",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-71",
        "16.992",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-71",
        "17.088",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-71",
        "17.181",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-71",
        "17.271",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-71",
        "17.358",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-71",
        "17.442",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-71",
        "17.523",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-71",
        "17.601",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-71",
        "17.676",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-71",
        "17.747",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-71",
        "17.814",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-71",
        "17.880",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-71",
        "17.942",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-71",
        "18.001",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-71",
        "18.056",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-71",
        "18.108",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-71",
        "18.157",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-71",
        "18.203",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-71",
        "18.246",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-71",
        "18.284",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-71",
        "18.320",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-71",
        "18.352",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-71",
        "18.381",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-71",
        "18.407",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-71",
        "18.429",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-71",
        "18.448",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-71",
        "18.464",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-71",
        "18.476",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-71",
        "18.484",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-71",
        "18.490",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-71",
        "18.492",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-71",
        "18.490",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-71",
        "18.485",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-71",
        "18.477",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-71",
        "18.466",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-71",
        "18.450",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-71",
        "18.432",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-71",
        "18.410",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-71",
        "18.385",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-71",
        "18.357",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-71",
        "18.325",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-71",
        "18.290",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-71",
        "18.252",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-71",
        "18.210",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-71",
        "18.164",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-71",
        "18.117",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-71",
        "18.064",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-71",
        "18.009",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-71",
        "17.951",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-71",
        "17.890",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-71",
        "17.825",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-71",
        "17.757",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-71",
        "17.687",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-71",
        "17.612",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-71",
        "17.535",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-71",
        "17.454",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-71",
        "17.372",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-71",
        "17.285",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-71",
        "17.195",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-71",
        "17.102",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-71",
        "17.007",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-71",
        "16.909",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-71",
        "16.808",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-71",
        "16.703",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-71",
        "16.596",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-71",
        "16.487",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-71",
        "16.374",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-71",
        "16.259",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-71",
        "16.140",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-71",
        "16.020",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-71",
        "15.896",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-71",
        "15.770",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-71",
        "15.642",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-71",
        "15.510",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-71",
        "15.377",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-71",
        "15.241",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-71",
        "15.103",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-71",
        "14.962",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-71",
        "14.819",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-71",
        "14.673",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-71",
        "14.526",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-71",
        "14.376",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-71",
        "14.224",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-71",
        "14.070",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-71",
        "13.914",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-71",
        "13.756",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-71",
        "13.596",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-71",
        "13.434",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-71",
        "13.270",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-71",
        "13.105",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-71",
        "12.937",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-71",
        "12.769",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-71",
        "12.598",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-71",
        "12.425",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-71",
        "12.252",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-71",
        "12.077",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-71",
        "11.899",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-71",
        "11.721",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-71",
        "11.542",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-71",
        "11.362",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-71",
        "11.179",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-71",
        "10.997",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-71",
        "10.811",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-71",
        "10.627",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-71",
        "10.440",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-71",
        "10.253",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-71",
        "10.065",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-71",
        "9.876",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-71",
        "9.686",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-71",
        "9.496",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-71",
        "9.305",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-71",
        "9.114",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-71",
        "8.922",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-71",
        "8.729",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-71",
        "8.536",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-71",
        "8.342",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-71",
        "8.149",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-71",
        "7.955",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-71",
        "7.760",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-71",
        "7.566",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-71",
        "7.371",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-71",
        "7.178",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-71",
        "6.983",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-71",
        "6.789",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-71",
        "6.595",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-71",
        "6.402",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-71",
        "6.208",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-71",
        "6.015",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-71",
        "5.822",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-71",
        "5.630",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-71",
        "5.438",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-71",
        "5.247",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-71",
        "5.056",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-71",
        "4.866",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-71",
        "4.677",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-71",
        "4.489",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-71",
        "4.302",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-71",
        "4.116",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-71",
        "3.930",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-71",
        "3.746",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-71",
        "3.562",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-71",
        "3.380",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-71",
        "3.199",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-71",
        "3.020",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-71",
        "2.841",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-71",
        "2.664",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-71",
        "2.489",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-71",
        "2.315",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-71",
        "2.143",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-71",
        "1.972",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-71",
        "1.803",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-71",
        "1.635",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-71",
        "1.469",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-71",
        "1.305",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-71",
        "1.143",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-71",
        "0.982",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-71",
        "0.824",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-71",
        "0.668",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-71",
        "0.514",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-71",
        "0.361",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-71",
        "0.211",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-71",
        "0.063",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-71",
        "-0.083",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-71",
        "-0.226",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-71",
        "-0.367",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-71",
        "-0.506",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-71",
        "-0.642",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-71",
        "-0.776",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-71",
        "-0.908",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-71",
        "-1.037",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-71",
        "-1.164",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-71",
        "-1.287",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-71",
        "-1.408",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-71",
        "-1.527",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-71",
        "-1.643",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-71",
        "-1.756",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-71",
        "-1.867",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-71",
        "-1.973",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-71",
        "-2.078",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-71",
        "-2.180",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-71",
        "-2.279",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-71",
        "-2.375",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-71",
        "-2.468",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-71",
        "-2.558",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-71",
        "-2.645",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-71",
        "-2.728",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-71",
        "-2.810",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-71",
        "-2.888",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-71",
        "-2.961",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-71",
        "-3.033",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-71",
        "-3.101",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-71",
        "-3.167",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-71",
        "-3.229",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-71",
        "-3.288",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-71",
        "-3.342",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-71",
        "-3.395",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-71",
        "-3.444",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-71",
        "-3.489",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-71",
        "-3.531",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-71",
        "-3.571",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-71",
        "-3.607",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-71",
        "-3.639",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-71",
        "-3.668",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-71",
        "-3.693",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-71",
        "-3.716",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-71",
        "-3.735",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-71",
        "-3.750",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-71",
        "-3.762",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-71",
        "-3.771",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-71",
        "-3.776",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-71",
        "-3.778",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-71",
        "-3.777",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-71",
        "-3.772",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-71",
        "-3.764",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-71",
        "-3.752",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-71",
        "-3.737",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-71",
        "-3.719",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-71",
        "-3.697",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-71",
        "-3.672",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-71",
        "-3.643",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-71",
        "-3.612",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-71",
        "-3.577",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-71",
        "-3.538",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-71",
        "-3.496",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-71",
        "-3.451",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-71",
        "-3.402",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-71",
        "-3.351",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-71",
        "-3.296",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-71",
        "-3.238",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-71",
        "-3.177",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-71",
        "-3.112",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-71",
        "-3.044",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-71",
        "-2.973",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-71",
        "-2.899",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-71",
        "-2.822",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-71",
        "-2.741",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-71",
        "-2.658",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-71",
        "-2.572",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-71",
        "-2.482",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-71",
        "-2.389",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-71",
        "-2.294",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-71",
        "-2.196",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-71",
        "-2.094",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-71",
        "-1.990",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-71",
        "-1.883",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-71",
        "-1.774",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-71",
        "-1.661",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-71",
        "-1.545",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-71",
        "-1.427",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-71",
        "-1.306",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-71",
        "-1.183",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-71",
        "-1.057",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-71",
        "-0.929",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-71",
        "-0.797",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-71",
        "-0.664",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-71",
        "-0.528",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-71",
        "-0.389",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-71",
        "-0.248",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-71",
        "-0.105",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-71",
        "0.040",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-71",
        "0.188",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-71",
        "0.337",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-71",
        "0.489",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-71",
        "0.643",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-71",
        "0.800",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-71",
        "0.958",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-71",
        "1.118",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-71",
        "1.280",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-71",
        "1.443",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-71",
        "1.609",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-71",
        "1.776",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-71",
        "1.946",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-71",
        "2.116",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-71",
        "2.288",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-71",
        "2.462",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-71",
        "2.638",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-71",
        "2.814",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-71",
        "2.992",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-71",
        "3.172",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-71",
        "3.353",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-71",
        "3.534",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-71",
        "3.718",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-71",
        "3.902",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-71",
        "4.087",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-71",
        "4.273",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-71",
        "4.460",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-71",
        "4.649",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-71",
        "4.837",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-71",
        "5.027",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-71",
        "5.217",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-71",
        "5.408",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-71",
        "5.600",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-71",
        "5.792",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-71",
        "5.985",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-71",
        "6.178",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-71",
        "6.371",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-71",
        "6.565",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-71",
        "6.759",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-71",
        "6.953",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-71",
        "7.147",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-71",
        "7.342",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-71",
        "7.536",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-71",
        "7.731",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-71",
        "7.925",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-71",
        "8.118",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-71",
        "8.313",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-71",
        "8.506",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-71",
        "8.698",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-71",
        "8.892",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-71",
        "9.084",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-71",
        "9.276",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-71",
        "9.467",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-71",
        "9.657",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-71",
        "9.847",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-71",
        "10.036",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-71",
        "10.224",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-71",
        "10.412",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-71",
        "10.598",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-71",
        "10.783",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-71",
        "10.967",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-71",
        "11.151",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-71",
        "11.333",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-71",
        "11.514",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-71",
        "11.694",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-71",
        "11.872",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-71",
        "12.049",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-71",
        "12.224",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-71",
        "12.398",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-71",
        "12.571",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-71",
        "12.742",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-71",
        "12.912",
        "-10.206",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-71",
        "13.079",
        "-10.102",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-70",
        "13.580",
        "-9.995",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-70",
        "13.743",
        "-9.885",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-70",
        "13.904",
        "-9.772",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-70",
        "14.063",
        "-9.655",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-70",
        "14.221",
        "-9.536",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-70",
        "14.376",
        "-9.414",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-70",
        "14.530",
        "-9.290",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-70",
        "14.681",
        "-9.162",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-70",
        "14.830",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-70",
        "14.978",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-70",
        "15.122",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-70",
        "15.265",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-70",
        "15.405",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-70",
        "15.543",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-70",
        "15.678",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-70",
        "15.811",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-70",
        "15.943",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-70",
        "16.070",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-70",
        "16.196",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-70",
        "16.319",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-70",
        "16.440",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-70",
        "16.558",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-70",
        "16.673",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-70",
        "16.785",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-70",
        "16.895",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-70",
        "17.002",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-70",
        "17.106",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-70",
        "17.207",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-70",
        "17.305",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-70",
        "17.400",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-70",
        "17.492",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-70",
        "17.582",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-70",
        "17.668",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-70",
        "17.752",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-70",
        "17.832",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-70",
        "17.910",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-70",
        "17.983",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-70",
        "18.055",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-70",
        "18.122",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-70",
        "18.187",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-70",
        "18.248",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-70",
        "18.307",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-70",
        "18.362",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-70",
        "18.414",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-70",
        "18.462",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-70",
        "18.508",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-70",
        "18.550",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-70",
        "18.588",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-70",
        "18.624",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-70",
        "18.656",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-70",
        "18.685",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-70",
        "18.711",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-70",
        "18.733",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-70",
        "18.751",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-70",
        "18.766",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-70",
        "18.779",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-70",
        "18.788",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-70",
        "18.793",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-70",
        "18.795",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-70",
        "18.794",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-70",
        "18.788",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-70",
        "18.781",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-70",
        "18.768",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-70",
        "18.754",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-70",
        "18.736",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-70",
        "18.715",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-70",
        "18.690",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-70",
        "18.661",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-70",
        "18.629",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-70",
        "18.594",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-70",
        "18.556",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-70",
        "18.514",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-70",
        "18.469",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-70",
        "18.421",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-70",
        "18.370",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-70",
        "18.316",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-70",
        "18.258",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-70",
        "18.197",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-70",
        "18.132",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-70",
        "18.065",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-70",
        "17.995",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-70",
        "17.921",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-70",
        "17.844",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-70",
        "17.765",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-70",
        "17.681",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-70",
        "17.596",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-70",
        "17.507",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-70",
        "17.415",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-70",
        "17.320",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-70",
        "17.222",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-70",
        "17.121",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-70",
        "17.018",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-70",
        "16.911",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-70",
        "16.802",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-70",
        "16.691",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-70",
        "16.575",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-70",
        "16.458",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-70",
        "16.338",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-70",
        "16.215",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-70",
        "16.090",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-70",
        "15.962",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-70",
        "15.832",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-70",
        "15.699",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-70",
        "15.564",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-70",
        "15.427",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-70",
        "15.287",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-70",
        "15.144",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-70",
        "14.999",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-70",
        "14.854",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-70",
        "14.705",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-70",
        "14.554",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-70",
        "14.400",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-70",
        "14.245",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-70",
        "14.088",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-70",
        "13.929",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-70",
        "13.768",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-70",
        "13.606",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-70",
        "13.441",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-70",
        "13.275",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-70",
        "13.107",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-70",
        "12.937",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-70",
        "12.766",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-70",
        "12.593",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-70",
        "12.419",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-70",
        "12.243",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-70",
        "12.067",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-70",
        "11.888",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-70",
        "11.708",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-70",
        "11.527",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-70",
        "11.346",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-70",
        "11.163",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-70",
        "10.978",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-70",
        "10.793",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-70",
        "10.608",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-70",
        "10.421",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-70",
        "10.232",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-70",
        "10.044",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-70",
        "9.855",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-70",
        "9.665",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-70",
        "9.475",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-70",
        "9.283",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-70",
        "9.092",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-70",
        "8.900",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-70",
        "8.708",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-70",
        "8.515",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-70",
        "8.322",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-70",
        "8.130",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-70",
        "7.937",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-70",
        "7.743",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-70",
        "7.551",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-70",
        "7.357",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-70",
        "7.164",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-70",
        "6.972",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-70",
        "6.779",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-70",
        "6.587",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-70",
        "6.394",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-70",
        "6.203",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-70",
        "6.012",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-70",
        "5.822",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-70",
        "5.632",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-70",
        "5.443",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-70",
        "5.253",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-70",
        "5.066",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-70",
        "4.879",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-70",
        "4.693",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-70",
        "4.508",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-70",
        "4.323",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-70",
        "4.139",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-70",
        "3.958",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-70",
        "3.777",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-70",
        "3.596",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-70",
        "3.419",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-70",
        "3.241",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-70",
        "3.065",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-70",
        "2.891",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-70",
        "2.718",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-70",
        "2.546",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-70",
        "2.377",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-70",
        "2.208",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-70",
        "2.042",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-70",
        "1.877",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-70",
        "1.714",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-70",
        "1.552",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-70",
        "1.394",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-70",
        "1.236",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-70",
        "1.080",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-70",
        "0.927",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-70",
        "0.776",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-70",
        "0.627",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-70",
        "0.480",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-70",
        "0.334",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-70",
        "0.192",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-70",
        "0.052",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-70",
        "-0.086",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-70",
        "-0.221",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-70",
        "-0.355",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-70",
        "-0.486",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-70",
        "-0.614",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-70",
        "-0.739",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-70",
        "-0.862",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-70",
        "-0.983",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-70",
        "-1.100",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-70",
        "-1.216",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-70",
        "-1.328",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-70",
        "-1.438",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-70",
        "-1.544",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-70",
        "-1.648",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-70",
        "-1.750",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-70",
        "-1.847",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-70",
        "-1.943",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-70",
        "-2.036",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-70",
        "-2.125",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-70",
        "-2.212",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-70",
        "-2.295",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-70",
        "-2.375",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-70",
        "-2.452",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-70",
        "-2.526",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-70",
        "-2.598",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-70",
        "-2.665",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-70",
        "-2.730",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-70",
        "-2.791",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-70",
        "-2.850",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-70",
        "-2.905",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-70",
        "-2.957",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-70",
        "-3.006",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-70",
        "-3.051",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-70",
        "-3.093",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-70",
        "-3.132",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-70",
        "-3.168",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-70",
        "-3.200",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-70",
        "-3.228",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-70",
        "-3.253",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-70",
        "-3.276",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-70",
        "-3.295",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-70",
        "-3.310",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-70",
        "-3.322",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-70",
        "-3.331",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-70",
        "-3.336",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-70",
        "-3.338",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-70",
        "-3.337",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-70",
        "-3.331",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-70",
        "-3.324",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-70",
        "-3.312",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-70",
        "-3.297",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-70",
        "-3.279",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-70",
        "-3.257",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-70",
        "-3.232",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-70",
        "-3.204",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-70",
        "-3.172",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-70",
        "-3.137",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-70",
        "-3.099",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-70",
        "-3.058",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-70",
        "-3.013",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-70",
        "-2.964",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-70",
        "-2.914",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-70",
        "-2.859",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-70",
        "-2.801",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-70",
        "-2.740",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-70",
        "-2.676",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-70",
        "-2.608",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-70",
        "-2.538",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-70",
        "-2.463",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-70",
        "-2.387",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-70",
        "-2.307",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-70",
        "-2.224",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-70",
        "-2.139",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-70",
        "-2.049",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-70",
        "-1.957",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-70",
        "-1.863",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-70",
        "-1.765",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-70",
        "-1.664",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-70",
        "-1.560",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-70",
        "-1.454",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-70",
        "-1.345",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-70",
        "-1.233",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-70",
        "-1.119",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-70",
        "-1.001",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-70",
        "-0.881",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-70",
        "-0.759",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-70",
        "-0.633",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-70",
        "-0.506",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-70",
        "-0.375",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-70",
        "-0.243",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-70",
        "-0.107",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-70",
        "0.030",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-70",
        "0.171",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-70",
        "0.313",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-70",
        "0.457",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-70",
        "0.604",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-70",
        "0.753",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-70",
        "0.904",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-70",
        "1.057",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-70",
        "1.211",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-70",
        "1.369",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-70",
        "1.528",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-70",
        "1.689",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-70",
        "1.852",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-70",
        "2.016",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-70",
        "2.182",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-70",
        "2.351",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-70",
        "2.520",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-70",
        "2.691",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-70",
        "2.864",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-70",
        "3.038",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-70",
        "3.214",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-70",
        "3.391",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-70",
        "3.569",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-70",
        "3.749",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-70",
        "3.930",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-70",
        "4.111",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-70",
        "4.295",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-70",
        "4.479",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-70",
        "4.664",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-70",
        "4.850",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-70",
        "5.037",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-70",
        "5.225",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-70",
        "5.413",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-70",
        "5.602",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-70",
        "5.792",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-70",
        "5.982",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-70",
        "6.174",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-70",
        "6.365",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-70",
        "6.557",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-70",
        "6.749",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-70",
        "6.942",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-70",
        "7.134",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-70",
        "7.327",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-70",
        "7.520",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-70",
        "7.714",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-70",
        "7.907",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-70",
        "8.100",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-70",
        "8.292",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-70",
        "8.486",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-70",
        "8.678",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-70",
        "8.870",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-70",
        "9.063",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-70",
        "9.253",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-70",
        "9.445",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-70",
        "9.635",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-70",
        "9.826",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-70",
        "10.014",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-70",
        "10.203",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-70",
        "10.391",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-70",
        "10.578",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-70",
        "10.764",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-70",
        "10.950",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-70",
        "11.134",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-70",
        "11.317",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-70",
        "11.499",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-70",
        "11.680",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-70",
        "11.860",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-70",
        "12.039",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-70",
        "12.216",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-70",
        "12.392",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-70",
        "12.566",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-70",
        "12.739",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-70",
        "12.910",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-70",
        "13.080",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-70",
        "13.248",
        "-10.206",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-70",
        "13.415",
        "-10.102",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-69",
        "13.911",
        "-9.995",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-69",
        "14.074",
        "-9.885",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-69",
        "14.234",
        "-9.772",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-69",
        "14.392",
        "-9.655",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-69",
        "14.548",
        "-9.536",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-69",
        "14.703",
        "-9.414",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-69",
        "14.855",
        "-9.290",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-69",
        "15.005",
        "-9.162",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-69",
        "15.153",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-69",
        "15.299",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-69",
        "15.444",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-69",
        "15.585",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-69",
        "15.724",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-69",
        "15.862",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-69",
        "15.996",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-69",
        "16.129",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-69",
        "16.258",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-69",
        "16.386",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-69",
        "16.510",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-69",
        "16.633",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-69",
        "16.753",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-69",
        "16.870",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-69",
        "16.983",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-69",
        "17.096",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-69",
        "17.205",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-69",
        "17.311",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-69",
        "17.414",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-69",
        "17.514",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-69",
        "17.611",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-69",
        "17.707",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-69",
        "17.798",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-69",
        "17.887",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-69",
        "17.973",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-69",
        "18.056",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-69",
        "18.136",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-69",
        "18.213",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-69",
        "18.286",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-69",
        "18.356",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-69",
        "18.424",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-69",
        "18.488",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-69",
        "18.550",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-69",
        "18.607",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-69",
        "18.663",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-69",
        "18.714",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-69",
        "18.762",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-69",
        "18.808",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-69",
        "18.848",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-69",
        "18.888",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-69",
        "18.923",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-69",
        "18.955",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-69",
        "18.983",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-69",
        "19.008",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-69",
        "19.030",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-69",
        "19.049",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-69",
        "19.064",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-69",
        "19.076",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-69",
        "19.085",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-69",
        "19.091",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-69",
        "19.092",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-69",
        "19.091",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-69",
        "19.086",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-69",
        "19.078",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-69",
        "19.066",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-69",
        "19.051",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-69",
        "19.033",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-69",
        "19.012",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-69",
        "18.987",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-69",
        "18.959",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-69",
        "18.927",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-69",
        "18.893",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-69",
        "18.855",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-69",
        "18.813",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-69",
        "18.769",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-69",
        "18.721",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-69",
        "18.671",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-69",
        "18.616",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-69",
        "18.558",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-69",
        "18.498",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-69",
        "18.434",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-69",
        "18.367",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-69",
        "18.297",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-69",
        "18.224",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-69",
        "18.148",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-69",
        "18.069",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-69",
        "17.986",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-69",
        "17.901",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-69",
        "17.813",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-69",
        "17.721",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-69",
        "17.627",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-69",
        "17.530",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-69",
        "17.430",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-69",
        "17.327",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-69",
        "17.221",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-69",
        "17.113",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-69",
        "17.002",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-69",
        "16.888",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-69",
        "16.770",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-69",
        "16.652",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-69",
        "16.530",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-69",
        "16.405",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-69",
        "16.279",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-69",
        "16.149",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-69",
        "16.017",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-69",
        "15.883",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-69",
        "15.746",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-69",
        "15.607",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-69",
        "15.465",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-69",
        "15.322",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-69",
        "15.176",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-69",
        "15.028",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-69",
        "14.879",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-69",
        "14.726",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-69",
        "14.572",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-69",
        "14.416",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-69",
        "14.258",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-69",
        "14.099",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-69",
        "13.937",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-69",
        "13.773",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-69",
        "13.608",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-69",
        "13.441",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-69",
        "13.273",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-69",
        "13.102",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-69",
        "12.931",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-69",
        "12.757",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-69",
        "12.583",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-69",
        "12.407",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-69",
        "12.230",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-69",
        "12.052",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-69",
        "11.872",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-69",
        "11.691",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-69",
        "11.510",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-69",
        "11.326",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-69",
        "11.143",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-69",
        "10.958",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-69",
        "10.772",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-69",
        "10.586",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-69",
        "10.398",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-69",
        "10.210",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-69",
        "10.021",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-69",
        "9.833",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-69",
        "9.643",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-69",
        "9.452",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-69",
        "9.262",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-69",
        "9.071",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-69",
        "8.880",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-69",
        "8.688",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-69",
        "8.496",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-69",
        "8.304",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-69",
        "8.113",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-69",
        "7.921",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-69",
        "7.729",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-69",
        "7.538",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-69",
        "7.346",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-69",
        "7.155",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-69",
        "6.964",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-69",
        "6.773",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-69",
        "6.583",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-69",
        "6.392",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-69",
        "6.203",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-69",
        "6.015",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-69",
        "5.827",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-69",
        "5.639",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-69",
        "5.453",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-69",
        "5.267",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-69",
        "5.082",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-69",
        "4.898",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-69",
        "4.715",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-69",
        "4.532",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-69",
        "4.352",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-69",
        "4.172",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-69",
        "3.993",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-69",
        "3.816",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-69",
        "3.639",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-69",
        "3.465",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-69",
        "3.292",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-69",
        "3.119",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-69",
        "2.949",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-69",
        "2.781",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-69",
        "2.614",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-69",
        "2.448",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-69",
        "2.285",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-69",
        "2.122",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-69",
        "1.962",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-69",
        "1.804",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-69",
        "1.647",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-69",
        "1.493",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-69",
        "1.340",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-69",
        "1.190",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-69",
        "1.042",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-69",
        "0.897",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-69",
        "0.752",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-69",
        "0.611",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-69",
        "0.471",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-69",
        "0.334",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-69",
        "0.199",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-69",
        "0.067",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-69",
        "-0.063",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-69",
        "-0.190",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-69",
        "-0.315",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-69",
        "-0.437",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-69",
        "-0.557",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-69",
        "-0.674",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-69",
        "-0.788",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-69",
        "-0.900",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-69",
        "-1.009",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-69",
        "-1.115",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-69",
        "-1.218",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-69",
        "-1.319",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-69",
        "-1.416",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-69",
        "-1.511",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-69",
        "-1.602",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-69",
        "-1.692",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-69",
        "-1.777",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-69",
        "-1.860",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-69",
        "-1.940",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-69",
        "-2.017",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-69",
        "-2.090",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-69",
        "-2.160",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-69",
        "-2.228",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-69",
        "-2.292",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-69",
        "-2.354",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-69",
        "-2.411",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-69",
        "-2.467",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-69",
        "-2.518",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-69",
        "-2.566",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-69",
        "-2.611",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-69",
        "-2.653",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-69",
        "-2.692",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-69",
        "-2.727",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-69",
        "-2.759",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-69",
        "-2.787",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-69",
        "-2.812",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-69",
        "-2.834",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-69",
        "-2.853",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-69",
        "-2.868",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-69",
        "-2.880",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-69",
        "-2.889",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-69",
        "-2.895",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-69",
        "-2.896",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-69",
        "-2.895",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-69",
        "-2.890",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-69",
        "-2.882",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-69",
        "-2.870",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-69",
        "-2.855",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-69",
        "-2.838",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-69",
        "-2.816",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-69",
        "-2.792",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-69",
        "-2.763",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-69",
        "-2.732",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-69",
        "-2.698",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-69",
        "-2.660",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-69",
        "-2.618",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-69",
        "-2.574",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-69",
        "-2.526",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-69",
        "-2.475",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-69",
        "-2.421",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-69",
        "-2.363",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-69",
        "-2.302",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-69",
        "-2.238",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-69",
        "-2.172",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-69",
        "-2.101",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-69",
        "-2.028",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-69",
        "-1.952",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-69",
        "-1.872",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-69",
        "-1.790",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-69",
        "-1.705",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-69",
        "-1.617",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-69",
        "-1.525",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-69",
        "-1.430",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-69",
        "-1.334",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-69",
        "-1.234",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-69",
        "-1.131",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-69",
        "-1.025",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-69",
        "-0.916",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-69",
        "-0.806",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-69",
        "-0.692",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-69",
        "-0.575",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-69",
        "-0.456",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-69",
        "-0.334",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-69",
        "-0.210",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-69",
        "-0.083",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-69",
        "0.047",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-69",
        "0.179",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-69",
        "0.313",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-69",
        "0.450",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-69",
        "0.589",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-69",
        "0.730",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-69",
        "0.874",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-69",
        "1.020",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-69",
        "1.168",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-69",
        "1.317",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-69",
        "1.470",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-69",
        "1.623",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-69",
        "1.779",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-69",
        "1.938",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-69",
        "2.097",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-69",
        "2.259",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-69",
        "2.423",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-69",
        "2.588",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-69",
        "2.755",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-69",
        "2.923",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-69",
        "3.094",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-69",
        "3.265",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-69",
        "3.438",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-69",
        "3.613",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-69",
        "3.789",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-69",
        "3.966",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-69",
        "4.144",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-69",
        "4.324",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-69",
        "4.505",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-69",
        "4.686",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-69",
        "4.869",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-69",
        "5.053",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-69",
        "5.238",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-69",
        "5.424",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-69",
        "5.610",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-69",
        "5.798",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-69",
        "5.986",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-69",
        "6.174",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-69",
        "6.363",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-69",
        "6.554",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-69",
        "6.744",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-69",
        "6.934",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-69",
        "7.125",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-69",
        "7.316",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-69",
        "7.508",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-69",
        "7.699",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-69",
        "7.892",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-69",
        "8.083",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-69",
        "8.275",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-69",
        "8.466",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-69",
        "8.658",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-69",
        "8.850",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-69",
        "9.041",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-69",
        "9.232",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-69",
        "9.423",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-69",
        "9.613",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-69",
        "9.803",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-69",
        "9.993",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-69",
        "10.181",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-69",
        "10.369",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-69",
        "10.556",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-69",
        "10.743",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-69",
        "10.929",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-69",
        "11.114",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-69",
        "11.298",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-69",
        "11.481",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-69",
        "11.664",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-69",
        "11.844",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-69",
        "12.024",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-69",
        "12.202",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-69",
        "12.380",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-69",
        "12.557",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-69",
        "12.730",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-69",
        "12.905",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-69",
        "13.076",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-69",
        "13.247",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-69",
        "13.415",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-69",
        "13.582",
        "-10.206",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-69",
        "13.748",
        "-10.102",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-68",
        "14.238",
        "-9.995",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-68",
        "14.399",
        "-9.885",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-68",
        "14.559",
        "-9.772",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-68",
        "14.716",
        "-9.655",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-68",
        "14.871",
        "-9.536",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-68",
        "15.025",
        "-9.414",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-68",
        "15.176",
        "-9.290",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-68",
        "15.325",
        "-9.162",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-68",
        "15.472",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-68",
        "15.618",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-68",
        "15.761",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-68",
        "15.901",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-68",
        "16.039",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-68",
        "16.176",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-68",
        "16.309",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-68",
        "16.441",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-68",
        "16.570",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-68",
        "16.696",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-68",
        "16.820",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-68",
        "16.942",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-68",
        "17.060",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-68",
        "17.176",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-68",
        "17.289",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-68",
        "17.401",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-68",
        "17.509",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-68",
        "17.614",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-68",
        "17.717",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-68",
        "17.817",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-68",
        "17.914",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-68",
        "18.008",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-68",
        "18.099",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-68",
        "18.187",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-68",
        "18.273",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-68",
        "18.354",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-68",
        "18.434",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-68",
        "18.510",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-68",
        "18.583",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-68",
        "18.654",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-68",
        "18.720",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-68",
        "18.784",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-68",
        "18.845",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-68",
        "18.902",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-68",
        "18.957",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-68",
        "19.008",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-68",
        "19.056",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-68",
        "19.100",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-68",
        "19.142",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-68",
        "19.180",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-68",
        "19.216",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-68",
        "19.247",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-68",
        "19.276",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-68",
        "19.301",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-68",
        "19.322",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-68",
        "19.341",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-68",
        "19.356",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-68",
        "19.368",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-68",
        "19.377",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-68",
        "19.382",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-68",
        "19.384",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-68",
        "19.383",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-68",
        "19.377",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-68",
        "19.370",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-68",
        "19.358",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-68",
        "19.344",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-68",
        "19.325",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-68",
        "19.304",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-68",
        "19.280",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-68",
        "19.252",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-68",
        "19.220",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-68",
        "19.186",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-68",
        "19.149",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-68",
        "19.108",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-68",
        "19.063",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-68",
        "19.016",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-68",
        "18.965",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-68",
        "18.911",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-68",
        "18.854",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-68",
        "18.794",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-68",
        "18.730",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-68",
        "18.664",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-68",
        "18.595",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-68",
        "18.521",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-68",
        "18.446",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-68",
        "18.367",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-68",
        "18.285",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-68",
        "18.201",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-68",
        "18.113",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-68",
        "18.022",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-68",
        "17.928",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-68",
        "17.832",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-68",
        "17.733",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-68",
        "17.630",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-68",
        "17.526",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-68",
        "17.418",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-68",
        "17.307",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-68",
        "17.194",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-68",
        "17.079",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-68",
        "16.960",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-68",
        "16.839",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-68",
        "16.715",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-68",
        "16.589",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-68",
        "16.460",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-68",
        "16.329",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-68",
        "16.196",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-68",
        "16.061",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-68",
        "15.923",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-68",
        "15.782",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-68",
        "15.639",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-68",
        "15.495",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-68",
        "15.348",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-68",
        "15.199",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-68",
        "15.048",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-68",
        "14.895",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-68",
        "14.740",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-68",
        "14.583",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-68",
        "14.424",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-68",
        "14.264",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-68",
        "14.101",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-68",
        "13.937",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-68",
        "13.771",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-68",
        "13.603",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-68",
        "13.435",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-68",
        "13.265",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-68",
        "13.092",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-68",
        "12.919",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-68",
        "12.744",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-68",
        "12.569",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-68",
        "12.392",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-68",
        "12.214",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-68",
        "12.034",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-68",
        "11.853",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-68",
        "11.671",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-68",
        "11.488",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-68",
        "11.305",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-68",
        "11.121",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-68",
        "10.936",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-68",
        "10.749",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-68",
        "10.563",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-68",
        "10.375",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-68",
        "10.188",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-68",
        "9.999",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-68",
        "9.810",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-68",
        "9.621",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-68",
        "9.431",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-68",
        "9.242",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-68",
        "9.051",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-68",
        "8.861",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-68",
        "8.670",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-68",
        "8.480",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-68",
        "8.289",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-68",
        "8.099",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-68",
        "7.908",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-68",
        "7.718",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-68",
        "7.528",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-68",
        "7.338",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-68",
        "7.149",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-68",
        "6.960",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-68",
        "6.772",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-68",
        "6.584",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-68",
        "6.396",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-68",
        "6.209",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-68",
        "6.023",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-68",
        "5.837",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-68",
        "5.653",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-68",
        "5.469",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-68",
        "5.286",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-68",
        "5.105",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-68",
        "4.923",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-68",
        "4.744",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-68",
        "4.565",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-68",
        "4.388",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-68",
        "4.212",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-68",
        "4.037",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-68",
        "3.863",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-68",
        "3.692",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-68",
        "3.521",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-68",
        "3.352",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-68",
        "3.184",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-68",
        "3.018",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-68",
        "2.854",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-68",
        "2.691",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-68",
        "2.530",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-68",
        "2.372",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-68",
        "2.214",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-68",
        "2.059",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-68",
        "1.906",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-68",
        "1.754",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-68",
        "1.605",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-68",
        "1.457",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-68",
        "1.313",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-68",
        "1.170",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-68",
        "1.029",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-68",
        "0.891",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-68",
        "0.755",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-68",
        "0.621",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-68",
        "0.489",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-68",
        "0.361",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-68",
        "0.234",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-68",
        "0.110",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-68",
        "-0.012",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-68",
        "-0.130",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-68",
        "-0.247",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-68",
        "-0.360",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-68",
        "-0.472",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-68",
        "-0.579",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-68",
        "-0.685",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-68",
        "-0.787",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-68",
        "-0.887",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-68",
        "-0.983",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-68",
        "-1.078",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-68",
        "-1.169",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-68",
        "-1.257",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-68",
        "-1.343",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-68",
        "-1.425",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-68",
        "-1.504",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-68",
        "-1.580",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-68",
        "-1.653",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-68",
        "-1.724",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-68",
        "-1.791",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-68",
        "-1.855",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-68",
        "-1.915",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-68",
        "-1.973",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-68",
        "-2.027",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-68",
        "-2.079",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-68",
        "-2.126",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-68",
        "-2.171",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-68",
        "-2.213",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-68",
        "-2.251",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-68",
        "-2.286",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-68",
        "-2.318",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-68",
        "-2.346",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-68",
        "-2.371",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-68",
        "-2.393",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-68",
        "-2.412",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-68",
        "-2.426",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-68",
        "-2.438",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-68",
        "-2.447",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-68",
        "-2.452",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-68",
        "-2.454",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-68",
        "-2.453",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-68",
        "-2.448",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-68",
        "-2.440",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-68",
        "-2.428",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-68",
        "-2.414",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-68",
        "-2.396",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-68",
        "-2.375",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-68",
        "-2.350",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-68",
        "-2.322",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-68",
        "-2.291",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-68",
        "-2.256",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-68",
        "-2.219",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-68",
        "-2.178",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-68",
        "-2.133",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-68",
        "-2.086",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-68",
        "-2.035",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-68",
        "-1.981",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-68",
        "-1.925",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-68",
        "-1.864",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-68",
        "-1.801",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-68",
        "-1.734",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-68",
        "-1.665",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-68",
        "-1.592",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-68",
        "-1.516",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-68",
        "-1.438",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-68",
        "-1.356",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-68",
        "-1.271",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-68",
        "-1.183",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-68",
        "-1.092",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-68",
        "-0.998",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-68",
        "-0.902",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-68",
        "-0.803",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-68",
        "-0.700",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-68",
        "-0.596",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-68",
        "-0.488",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-68",
        "-0.378",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-68",
        "-0.265",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-68",
        "-0.148",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-68",
        "-0.030",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-68",
        "0.091",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-68",
        "0.214",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-68",
        "0.341",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-68",
        "0.469",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-68",
        "0.600",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-68",
        "0.733",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-68",
        "0.869",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-68",
        "1.007",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-68",
        "1.148",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-68",
        "1.291",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-68",
        "1.435",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-68",
        "1.581",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-68",
        "1.731",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-68",
        "1.882",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-68",
        "2.035",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-68",
        "2.190",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-68",
        "2.346",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-68",
        "2.505",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-68",
        "2.666",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-68",
        "2.828",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-68",
        "2.993",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-68",
        "3.158",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-68",
        "3.326",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-68",
        "3.495",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-68",
        "3.665",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-68",
        "3.837",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-68",
        "4.010",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-68",
        "4.185",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-68",
        "4.361",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-68",
        "4.538",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-68",
        "4.716",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-68",
        "4.896",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-68",
        "5.077",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-68",
        "5.258",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-68",
        "5.441",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-68",
        "5.625",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-68",
        "5.809",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-68",
        "5.994",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-68",
        "6.180",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-68",
        "6.367",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-68",
        "6.554",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-68",
        "6.742",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-68",
        "6.931",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-68",
        "7.120",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-68",
        "7.309",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-68",
        "7.499",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-68",
        "7.689",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-68",
        "7.878",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-68",
        "8.069",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-68",
        "8.259",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-68",
        "8.450",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-68",
        "8.641",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-68",
        "8.832",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-68",
        "9.022",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-68",
        "9.212",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-68",
        "9.402",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-68",
        "9.592",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-68",
        "9.781",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-68",
        "9.970",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-68",
        "10.159",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-68",
        "10.346",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-68",
        "10.534",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-68",
        "10.721",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-68",
        "10.907",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-68",
        "11.092",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-68",
        "11.277",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-68",
        "11.461",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-68",
        "11.643",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-68",
        "11.825",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-68",
        "12.006",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-68",
        "12.185",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-68",
        "12.364",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-68",
        "12.542",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-68",
        "12.717",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-68",
        "12.892",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-68",
        "13.066",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-68",
        "13.239",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-68",
        "13.408",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-68",
        "13.578",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-68",
        "13.745",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-68",
        "13.912",
        "-10.206",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-68",
        "14.076",
        "-10.102",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-67",
        "14.562",
        "-9.995",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-67",
        "14.721",
        "-9.885",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-67",
        "14.879",
        "-9.772",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-67",
        "15.035",
        "-9.655",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-67",
        "15.189",
        "-9.536",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-67",
        "15.341",
        "-9.414",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-67",
        "15.492",
        "-9.290",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-67",
        "15.639",
        "-9.162",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-67",
        "15.786",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-67",
        "15.930",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-67",
        "16.072",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-67",
        "16.212",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-67",
        "16.349",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-67",
        "16.484",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-67",
        "16.617",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-67",
        "16.748",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-67",
        "16.875",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-67",
        "17.001",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-67",
        "17.123",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-67",
        "17.244",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-67",
        "17.363",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-67",
        "17.478",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-67",
        "17.591",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-67",
        "17.701",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-67",
        "17.808",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-67",
        "17.913",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-67",
        "18.014",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-67",
        "18.114",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-67",
        "18.210",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-67",
        "18.303",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-67",
        "18.394",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-67",
        "18.481",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-67",
        "18.566",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-67",
        "18.647",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-67",
        "18.727",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-67",
        "18.802",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-67",
        "18.874",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-67",
        "18.944",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-67",
        "19.011",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-67",
        "19.075",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-67",
        "19.135",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-67",
        "19.191",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-67",
        "19.245",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-67",
        "19.296",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-67",
        "19.344",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-67",
        "19.388",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-67",
        "19.430",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-67",
        "19.468",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-67",
        "19.502",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-67",
        "19.534",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-67",
        "19.562",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-67",
        "19.587",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-67",
        "19.608",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-67",
        "19.627",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-67",
        "19.642",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-67",
        "19.654",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-67",
        "19.662",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-67",
        "19.668",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-67",
        "19.670",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-67",
        "19.668",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-67",
        "19.663",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-67",
        "19.656",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-67",
        "19.645",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-67",
        "19.630",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-67",
        "19.612",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-67",
        "19.590",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-67",
        "19.566",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-67",
        "19.538",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-67",
        "19.507",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-67",
        "19.473",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-67",
        "19.435",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-67",
        "19.395",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-67",
        "19.351",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-67",
        "19.304",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-67",
        "19.254",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-67",
        "19.200",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-67",
        "19.143",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-67",
        "19.083",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-67",
        "19.021",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-67",
        "18.955",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-67",
        "18.886",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-67",
        "18.814",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-67",
        "18.738",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-67",
        "18.660",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-67",
        "18.579",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-67",
        "18.494",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-67",
        "18.408",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-67",
        "18.318",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-67",
        "18.224",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-67",
        "18.128",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-67",
        "18.030",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-67",
        "17.929",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-67",
        "17.824",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-67",
        "17.717",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-67",
        "17.608",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-67",
        "17.496",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-67",
        "17.381",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-67",
        "17.262",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-67",
        "17.143",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-67",
        "17.020",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-67",
        "16.895",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-67",
        "16.767",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-67",
        "16.637",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-67",
        "16.505",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-67",
        "16.370",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-67",
        "16.233",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-67",
        "16.094",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-67",
        "15.952",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-67",
        "15.808",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-67",
        "15.662",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-67",
        "15.515",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-67",
        "15.365",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-67",
        "15.213",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-67",
        "15.059",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-67",
        "14.903",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-67",
        "14.746",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-67",
        "14.586",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-67",
        "14.425",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-67",
        "14.262",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-67",
        "14.098",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-67",
        "13.931",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-67",
        "13.764",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-67",
        "13.595",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-67",
        "13.423",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-67",
        "13.252",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-67",
        "13.078",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-67",
        "12.904",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-67",
        "12.727",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-67",
        "12.551",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-67",
        "12.372",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-67",
        "12.193",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-67",
        "12.013",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-67",
        "11.831",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-67",
        "11.649",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-67",
        "11.466",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-67",
        "11.282",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-67",
        "11.097",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-67",
        "10.912",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-67",
        "10.726",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-67",
        "10.539",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-67",
        "10.352",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-67",
        "10.165",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-67",
        "9.977",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-67",
        "9.788",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-67",
        "9.600",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-67",
        "9.411",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-67",
        "9.222",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-67",
        "9.033",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-67",
        "8.844",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-67",
        "8.654",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-67",
        "8.465",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-67",
        "8.277",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-67",
        "8.087",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-67",
        "7.899",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-67",
        "7.710",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-67",
        "7.523",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-67",
        "7.335",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-67",
        "7.148",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-67",
        "6.961",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-67",
        "6.775",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-67",
        "6.590",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-67",
        "6.405",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-67",
        "6.220",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-67",
        "6.038",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-67",
        "5.855",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-67",
        "5.673",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-67",
        "5.493",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-67",
        "5.314",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-67",
        "5.135",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-67",
        "4.958",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-67",
        "4.781",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-67",
        "4.607",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-67",
        "4.434",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-67",
        "4.261",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-67",
        "4.090",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-67",
        "3.921",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-67",
        "3.753",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-67",
        "3.586",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-67",
        "3.421",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-67",
        "3.259",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-67",
        "3.097",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-67",
        "2.937",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-67",
        "2.779",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-67",
        "2.624",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-67",
        "2.469",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-67",
        "2.317",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-67",
        "2.167",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-67",
        "2.019",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-67",
        "1.873",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-67",
        "1.728",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-67",
        "1.587",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-67",
        "1.446",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-67",
        "1.309",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-67",
        "1.175",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-67",
        "1.042",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-67",
        "0.911",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-67",
        "0.783",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-67",
        "0.657",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-67",
        "0.535",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-67",
        "0.414",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-67",
        "0.295",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-67",
        "0.180",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-67",
        "0.068",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-67",
        "-0.043",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-67",
        "-0.150",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-67",
        "-0.255",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-67",
        "-0.356",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-67",
        "-0.455",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-67",
        "-0.551",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-67",
        "-0.645",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-67",
        "-0.736",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-67",
        "-0.823",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-67",
        "-0.908",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-67",
        "-0.989",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-67",
        "-1.068",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-67",
        "-1.143",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-67",
        "-1.216",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-67",
        "-1.286",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-67",
        "-1.352",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-67",
        "-1.415",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-67",
        "-1.476",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-67",
        "-1.533",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-67",
        "-1.587",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-67",
        "-1.638",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-67",
        "-1.686",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-67",
        "-1.730",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-67",
        "-1.771",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-67",
        "-1.810",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-67",
        "-1.844",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-67",
        "-1.875",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-67",
        "-1.903",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-67",
        "-1.928",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-67",
        "-1.950",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-67",
        "-1.969",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-67",
        "-1.983",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-67",
        "-1.995",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-67",
        "-2.004",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-67",
        "-2.010",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-67",
        "-2.011",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-67",
        "-2.010",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-67",
        "-2.005",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-67",
        "-1.997",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-67",
        "-1.985",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-67",
        "-1.971",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-67",
        "-1.954",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-67",
        "-1.932",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-67",
        "-1.908",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-67",
        "-1.880",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-67",
        "-1.849",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-67",
        "-1.815",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-67",
        "-1.777",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-67",
        "-1.736",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-67",
        "-1.692",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-67",
        "-1.645",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-67",
        "-1.596",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-67",
        "-1.542",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-67",
        "-1.485",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-67",
        "-1.425",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-67",
        "-1.363",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-67",
        "-1.297",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-67",
        "-1.227",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-67",
        "-1.155",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-67",
        "-1.080",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-67",
        "-1.002",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-67",
        "-0.920",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-67",
        "-0.836",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-67",
        "-0.750",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-67",
        "-0.659",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-67",
        "-0.566",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-67",
        "-0.470",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-67",
        "-0.372",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-67",
        "-0.271",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-67",
        "-0.166",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-67",
        "-0.059",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-67",
        "0.050",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-67",
        "0.163",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-67",
        "0.278",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-67",
        "0.396",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-67",
        "0.516",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-67",
        "0.638",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-67",
        "0.764",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-67",
        "0.891",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-67",
        "1.021",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-67",
        "1.153",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-67",
        "1.289",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-67",
        "1.425",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-67",
        "1.565",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-67",
        "1.706",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-67",
        "1.850",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-67",
        "1.996",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-67",
        "2.143",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-67",
        "2.293",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-67",
        "2.445",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-67",
        "2.599",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-67",
        "2.755",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-67",
        "2.912",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-67",
        "3.072",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-67",
        "3.234",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-67",
        "3.396",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-67",
        "3.560",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-67",
        "3.727",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-67",
        "3.895",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-67",
        "4.064",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-67",
        "4.235",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-67",
        "4.407",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-67",
        "4.580",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-67",
        "4.755",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-67",
        "4.931",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-67",
        "5.108",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-67",
        "5.286",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-67",
        "5.466",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-67",
        "5.646",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-67",
        "5.827",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-67",
        "6.009",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-67",
        "6.193",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-67",
        "6.376",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-67",
        "6.561",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-67",
        "6.747",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-67",
        "6.933",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-67",
        "7.119",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-67",
        "7.306",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-67",
        "7.493",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-67",
        "7.682",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-67",
        "7.870",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-67",
        "8.059",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-67",
        "8.247",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-67",
        "8.436",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-67",
        "8.625",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-67",
        "8.814",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-67",
        "9.004",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-67",
        "9.193",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-67",
        "9.382",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-67",
        "9.571",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-67",
        "9.759",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-67",
        "9.948",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-67",
        "10.135",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-67",
        "10.324",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-67",
        "10.510",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-67",
        "10.697",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-67",
        "10.883",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-67",
        "11.068",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-67",
        "11.253",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-67",
        "11.438",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-67",
        "11.620",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-67",
        "11.803",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-67",
        "11.985",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-67",
        "12.165",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-67",
        "12.345",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-67",
        "12.523",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-67",
        "12.700",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-67",
        "12.877",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-67",
        "13.052",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-67",
        "13.225",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-67",
        "13.397",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-67",
        "13.568",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-67",
        "13.738",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-67",
        "13.906",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-67",
        "14.072",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-67",
        "14.237",
        "-10.206",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-67",
        "14.400",
        "-10.102",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-66",
        "14.880",
        "-9.995",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-66",
        "15.038",
        "-9.885",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-66",
        "15.195",
        "-9.772",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-66",
        "15.349",
        "-9.655",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-66",
        "15.502",
        "-9.536",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-66",
        "15.654",
        "-9.414",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-66",
        "15.803",
        "-9.290",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-66",
        "15.950",
        "-9.162",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-66",
        "16.095",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-66",
        "16.238",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-66",
        "16.378",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-66",
        "16.517",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-66",
        "16.653",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-66",
        "16.788",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-66",
        "16.920",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-66",
        "17.049",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-66",
        "17.177",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-66",
        "17.301",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-66",
        "17.423",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-66",
        "17.542",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-66",
        "17.660",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-66",
        "17.774",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-66",
        "17.886",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-66",
        "17.995",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-66",
        "18.102",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-66",
        "18.206",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-66",
        "18.307",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-66",
        "18.405",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-66",
        "18.500",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-66",
        "18.594",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-66",
        "18.683",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-66",
        "18.770",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-66",
        "18.854",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-66",
        "18.936",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-66",
        "19.014",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-66",
        "19.088",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-66",
        "19.160",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-66",
        "19.230",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-66",
        "19.296",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-66",
        "19.358",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-66",
        "19.418",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-66",
        "19.475",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-66",
        "19.528",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-66",
        "19.579",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-66",
        "19.626",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-66",
        "19.670",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-66",
        "19.711",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-66",
        "19.748",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-66",
        "19.783",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-66",
        "19.814",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-66",
        "19.843",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-66",
        "19.867",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-66",
        "19.889",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-66",
        "19.907",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-66",
        "19.922",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-66",
        "19.934",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-66",
        "19.942",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-66",
        "19.947",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-66",
        "19.949",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-66",
        "19.947",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-66",
        "19.943",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-66",
        "19.935",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-66",
        "19.924",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-66",
        "19.910",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-66",
        "19.891",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-66",
        "19.870",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-66",
        "19.847",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-66",
        "19.819",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-66",
        "19.788",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-66",
        "19.754",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-66",
        "19.717",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-66",
        "19.677",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-66",
        "19.633",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-66",
        "19.586",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-66",
        "19.537",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-66",
        "19.483",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-66",
        "19.427",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-66",
        "19.368",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-66",
        "19.306",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-66",
        "19.239",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-66",
        "19.171",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-66",
        "19.100",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-66",
        "19.025",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-66",
        "18.947",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-66",
        "18.866",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-66",
        "18.784",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-66",
        "18.697",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-66",
        "18.608",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-66",
        "18.515",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-66",
        "18.420",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-66",
        "18.322",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-66",
        "18.222",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-66",
        "18.118",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-66",
        "18.012",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-66",
        "17.904",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-66",
        "17.791",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-66",
        "17.677",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-66",
        "17.561",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-66",
        "17.441",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-66",
        "17.320",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-66",
        "17.195",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-66",
        "17.069",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-66",
        "16.940",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-66",
        "16.808",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-66",
        "16.675",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-66",
        "16.538",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-66",
        "16.400",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-66",
        "16.260",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-66",
        "16.117",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-66",
        "15.972",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-66",
        "15.825",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-66",
        "15.677",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-66",
        "15.526",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-66",
        "15.373",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-66",
        "15.219",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-66",
        "15.063",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-66",
        "14.904",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-66",
        "14.745",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-66",
        "14.582",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-66",
        "14.419",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-66",
        "14.255",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-66",
        "14.088",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-66",
        "13.920",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-66",
        "13.750",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-66",
        "13.580",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-66",
        "13.408",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-66",
        "13.234",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-66",
        "13.060",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-66",
        "12.884",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-66",
        "12.707",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-66",
        "12.529",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-66",
        "12.350",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-66",
        "12.170",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-66",
        "11.989",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-66",
        "11.808",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-66",
        "11.625",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-66",
        "11.442",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-66",
        "11.258",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-66",
        "11.074",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-66",
        "10.888",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-66",
        "10.702",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-66",
        "10.516",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-66",
        "10.330",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-66",
        "10.143",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-66",
        "9.956",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-66",
        "9.768",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-66",
        "9.581",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-66",
        "9.393",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-66",
        "9.205",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-66",
        "9.018",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-66",
        "8.830",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-66",
        "8.642",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-66",
        "8.455",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-66",
        "8.268",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-66",
        "8.080",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-66",
        "7.894",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-66",
        "7.708",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-66",
        "7.522",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-66",
        "7.337",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-66",
        "7.152",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-66",
        "6.968",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-66",
        "6.785",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-66",
        "6.603",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-66",
        "6.420",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-66",
        "6.239",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-66",
        "6.059",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-66",
        "5.880",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-66",
        "5.702",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-66",
        "5.524",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-66",
        "5.349",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-66",
        "5.174",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-66",
        "5.001",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-66",
        "4.829",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-66",
        "4.657",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-66",
        "4.488",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-66",
        "4.319",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-66",
        "4.153",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-66",
        "3.988",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-66",
        "3.825",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-66",
        "3.662",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-66",
        "3.502",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-66",
        "3.344",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-66",
        "3.187",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-66",
        "3.032",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-66",
        "2.879",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-66",
        "2.728",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-66",
        "2.579",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-66",
        "2.431",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-66",
        "2.287",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-66",
        "2.143",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-66",
        "2.003",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-66",
        "1.864",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-66",
        "1.728",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-66",
        "1.594",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-66",
        "1.462",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-66",
        "1.333",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-66",
        "1.206",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-66",
        "1.081",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-66",
        "0.958",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-66",
        "0.839",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-66",
        "0.722",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-66",
        "0.608",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-66",
        "0.495",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-66",
        "0.386",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-66",
        "0.279",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-66",
        "0.176",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-66",
        "0.075",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-66",
        "-0.023",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-66",
        "-0.119",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-66",
        "-0.212",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-66",
        "-0.302",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-66",
        "-0.389",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-66",
        "-0.472",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-66",
        "-0.553",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-66",
        "-0.631",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-66",
        "-0.707",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-66",
        "-0.779",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-66",
        "-0.847",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-66",
        "-0.914",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-66",
        "-0.977",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-66",
        "-1.036",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-66",
        "-1.093",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-66",
        "-1.147",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-66",
        "-1.197",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-66",
        "-1.244",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-66",
        "-1.289",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-66",
        "-1.329",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-66",
        "-1.367",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-66",
        "-1.402",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-66",
        "-1.433",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-66",
        "-1.461",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-66",
        "-1.485",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-66",
        "-1.507",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-66",
        "-1.525",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-66",
        "-1.541",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-66",
        "-1.552",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-66",
        "-1.560",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-66",
        "-1.566",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-66",
        "-1.567",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-66",
        "-1.566",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-66",
        "-1.562",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-66",
        "-1.554",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-66",
        "-1.542",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-66",
        "-1.528",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-66",
        "-1.510",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-66",
        "-1.489",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-66",
        "-1.465",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-66",
        "-1.437",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-66",
        "-1.406",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-66",
        "-1.372",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-66",
        "-1.336",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-66",
        "-1.295",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-66",
        "-1.252",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-66",
        "-1.205",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-66",
        "-1.154",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-66",
        "-1.102",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-66",
        "-1.046",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-66",
        "-0.986",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-66",
        "-0.924",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-66",
        "-0.858",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-66",
        "-0.789",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-66",
        "-0.718",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-66",
        "-0.643",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-66",
        "-0.566",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-66",
        "-0.485",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-66",
        "-0.401",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-66",
        "-0.315",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-66",
        "-0.226",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-66",
        "-0.133",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-66",
        "-0.038",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-66",
        "0.059",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-66",
        "0.160",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-66",
        "0.263",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-66",
        "0.370",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-66",
        "0.479",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-66",
        "0.590",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-66",
        "0.704",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-66",
        "0.821",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-66",
        "0.940",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-66",
        "1.062",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-66",
        "1.186",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-66",
        "1.313",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-66",
        "1.442",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-66",
        "1.574",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-66",
        "1.707",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-66",
        "1.843",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-66",
        "1.981",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-66",
        "2.121",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-66",
        "2.264",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-66",
        "2.409",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-66",
        "2.556",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-66",
        "2.705",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-66",
        "2.856",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-66",
        "3.009",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-66",
        "3.163",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-66",
        "3.320",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-66",
        "3.477",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-66",
        "3.638",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-66",
        "3.799",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-66",
        "3.962",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-66",
        "4.128",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-66",
        "4.294",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-66",
        "4.462",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-66",
        "4.631",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-66",
        "4.801",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-66",
        "4.974",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-66",
        "5.147",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-66",
        "5.322",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-66",
        "5.497",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-66",
        "5.675",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-66",
        "5.852",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-66",
        "6.032",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-66",
        "6.212",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-66",
        "6.392",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-66",
        "6.575",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-66",
        "6.757",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-66",
        "6.940",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-66",
        "7.124",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-66",
        "7.309",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-66",
        "7.493",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-66",
        "7.679",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-66",
        "7.865",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-66",
        "8.052",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-66",
        "8.238",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-66",
        "8.426",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-66",
        "8.614",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-66",
        "8.800",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-66",
        "8.989",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-66",
        "9.176",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-66",
        "9.364",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-66",
        "9.552",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-66",
        "9.740",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-66",
        "9.927",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-66",
        "10.114",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-66",
        "10.301",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-66",
        "10.487",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-66",
        "10.674",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-66",
        "10.860",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-66",
        "11.044",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-66",
        "11.230",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-66",
        "11.414",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-66",
        "11.596",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-66",
        "11.779",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-66",
        "11.962",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-66",
        "12.142",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-66",
        "12.322",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-66",
        "12.502",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-66",
        "12.680",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-66",
        "12.857",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-66",
        "13.032",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-66",
        "13.208",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-66",
        "13.381",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-66",
        "13.553",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-66",
        "13.725",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-66",
        "13.894",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-66",
        "14.062",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-66",
        "14.229",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-66",
        "14.394",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-66",
        "14.557",
        "-10.206",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-66",
        "14.719",
        "-10.102",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-65",
        "-14.760",
        "27.021",
        "7.372",
        "8.281",
        "-0.107"
    ],
    [
        "-179",
        "-65",
        "-19.634",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-65",
        "-20.102",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-65",
        "-20.574",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-65",
        "-21.047",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-65",
        "-21.522",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-65",
        "-21.999",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-65",
        "-2.663",
        "8.957",
        "7.023",
        "9.116",
        "0.035"
    ],
    [
        "-172",
        "-65",
        "16.399",
        "-9.032",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-65",
        "16.541",
        "-8.898",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-65",
        "16.681",
        "-8.762",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-65",
        "16.818",
        "-8.624",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-65",
        "16.954",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-65",
        "17.087",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-65",
        "17.217",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-65",
        "17.346",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-65",
        "17.472",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-65",
        "17.596",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-65",
        "17.717",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-65",
        "17.835",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-65",
        "17.952",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-65",
        "18.065",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-65",
        "18.176",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-65",
        "18.284",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-65",
        "18.390",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-65",
        "18.493",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-65",
        "18.594",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-65",
        "18.691",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-65",
        "18.786",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-65",
        "18.878",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-65",
        "18.967",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-65",
        "19.053",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-65",
        "19.137",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-65",
        "19.217",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-65",
        "19.294",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-65",
        "19.369",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-65",
        "19.440",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-65",
        "19.508",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-65",
        "19.575",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-65",
        "19.637",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-65",
        "19.696",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-65",
        "19.752",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-65",
        "19.806",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-65",
        "19.855",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-65",
        "19.902",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-65",
        "19.946",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-65",
        "19.986",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-65",
        "20.024",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-65",
        "20.058",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-65",
        "20.089",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-65",
        "20.117",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-65",
        "20.142",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-65",
        "20.163",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-65",
        "20.181",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-65",
        "20.196",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-65",
        "20.208",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-65",
        "20.216",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-65",
        "20.221",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-65",
        "20.223",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-65",
        "20.221",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-65",
        "20.217",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-65",
        "20.209",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-65",
        "20.198",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-65",
        "20.184",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-65",
        "20.166",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-65",
        "20.145",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-65",
        "20.121",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-65",
        "20.093",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-65",
        "20.063",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-65",
        "20.030",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-65",
        "19.993",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-65",
        "19.952",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-65",
        "19.909",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-65",
        "19.862",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-65",
        "19.813",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-65",
        "19.761",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-65",
        "19.704",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-65",
        "19.647",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-65",
        "19.584",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-65",
        "19.519",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-65",
        "19.451",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-65",
        "19.380",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-65",
        "19.306",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-65",
        "19.229",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-65",
        "19.149",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-65",
        "19.066",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-65",
        "18.980",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-65",
        "18.892",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-65",
        "18.800",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-65",
        "18.706",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-65",
        "18.608",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-65",
        "18.509",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-65",
        "18.407",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-65",
        "18.301",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-65",
        "18.193",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-65",
        "18.082",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-65",
        "17.969",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-65",
        "17.853",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-65",
        "17.735",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-65",
        "17.615",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-65",
        "17.491",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-65",
        "17.366",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-65",
        "17.237",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-65",
        "17.107",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-65",
        "16.974",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-65",
        "16.840",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-65",
        "16.702",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-65",
        "16.563",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-65",
        "16.422",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-65",
        "16.277",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-65",
        "16.132",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-65",
        "15.984",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-65",
        "15.835",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-65",
        "15.683",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-65",
        "15.530",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-65",
        "15.374",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-65",
        "15.218",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-65",
        "15.059",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-65",
        "14.899",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-65",
        "14.736",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-65",
        "14.573",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-65",
        "14.408",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-65",
        "14.241",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-65",
        "14.074",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-65",
        "13.904",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-65",
        "13.733",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-65",
        "13.562",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-65",
        "13.388",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-65",
        "13.214",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-65",
        "13.038",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-65",
        "12.862",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-65",
        "12.684",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-65",
        "12.506",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-65",
        "12.326",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-65",
        "12.146",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-65",
        "11.965",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-65",
        "11.783",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-65",
        "11.600",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-65",
        "11.417",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-65",
        "11.234",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-65",
        "11.049",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-65",
        "10.865",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-65",
        "10.679",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-65",
        "10.494",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-65",
        "10.309",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-65",
        "10.123",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-65",
        "9.936",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-65",
        "9.751",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-65",
        "9.564",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-65",
        "9.378",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-65",
        "9.192",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-65",
        "9.005",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-65",
        "8.820",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-65",
        "8.634",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-65",
        "8.448",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-65",
        "8.264",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-65",
        "8.079",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-65",
        "7.894",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-65",
        "7.711",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-65",
        "7.527",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-65",
        "7.344",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-65",
        "7.162",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-65",
        "6.982",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-65",
        "6.801",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-65",
        "6.622",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-65",
        "6.443",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-65",
        "6.265",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-65",
        "6.089",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-65",
        "5.913",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-65",
        "5.738",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-65",
        "5.565",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-65",
        "5.393",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-65",
        "5.222",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-65",
        "5.052",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-65",
        "4.883",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-65",
        "4.717",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-65",
        "4.551",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-65",
        "4.387",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-65",
        "4.226",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-65",
        "4.065",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-65",
        "3.906",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-65",
        "3.749",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-65",
        "3.593",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-65",
        "3.440",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-65",
        "3.288",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-65",
        "3.138",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-65",
        "2.990",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-65",
        "2.844",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-65",
        "2.700",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-65",
        "2.559",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-65",
        "2.419",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-65",
        "2.281",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-65",
        "2.146",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-65",
        "2.013",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-65",
        "1.882",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-65",
        "1.754",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-65",
        "1.627",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-65",
        "1.504",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-65",
        "1.383",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-65",
        "1.264",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-65",
        "1.148",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-65",
        "1.035",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-65",
        "0.923",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-65",
        "0.816",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-65",
        "0.710",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-65",
        "0.606",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-65",
        "0.506",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-65",
        "0.408",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-65",
        "0.314",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-65",
        "0.222",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-65",
        "0.132",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-65",
        "0.046",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-65",
        "-0.036",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-65",
        "-0.117",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-65",
        "-0.195",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-65",
        "-0.269",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-65",
        "-0.341",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-65",
        "-0.409",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-65",
        "-0.475",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-65",
        "-0.537",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-65",
        "-0.596",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-65",
        "-0.653",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-65",
        "-0.706",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-65",
        "-0.756",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-65",
        "-0.802",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-65",
        "-0.846",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-65",
        "-0.887",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-65",
        "-0.924",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-65",
        "-0.958",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-65",
        "-0.990",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-65",
        "-1.018",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-65",
        "-1.042",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-65",
        "-1.063",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-65",
        "-1.082",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-65",
        "-1.097",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-65",
        "-1.108",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-65",
        "-1.117",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-65",
        "-1.121",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-65",
        "-1.123",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-65",
        "-1.122",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-65",
        "-1.117",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-65",
        "-1.110",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-65",
        "-1.098",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-65",
        "-1.084",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-65",
        "-1.066",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-65",
        "-1.046",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-65",
        "-1.022",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-65",
        "-0.994",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-65",
        "-0.964",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-65",
        "-0.931",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-65",
        "-0.894",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-65",
        "-0.853",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-65",
        "-0.810",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-65",
        "-0.763",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-65",
        "-0.714",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-65",
        "-0.662",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-65",
        "-0.605",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-65",
        "-0.547",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-65",
        "-0.485",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-65",
        "-0.420",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-65",
        "-0.352",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-65",
        "-0.281",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-65",
        "-0.206",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-65",
        "-0.129",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-65",
        "-0.049",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-65",
        "0.033",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-65",
        "0.120",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-65",
        "0.208",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-65",
        "0.300",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-65",
        "0.393",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-65",
        "0.491",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-65",
        "0.590",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-65",
        "0.693",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-65",
        "0.798",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-65",
        "0.906",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-65",
        "1.017",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-65",
        "1.130",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-65",
        "1.246",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-65",
        "1.364",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-65",
        "1.485",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-65",
        "1.608",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-65",
        "1.734",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-65",
        "1.862",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-65",
        "1.992",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-65",
        "2.125",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-65",
        "2.260",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-65",
        "2.397",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-65",
        "2.536",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-65",
        "2.678",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-65",
        "2.822",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-65",
        "2.967",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-65",
        "3.115",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-65",
        "3.264",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-65",
        "3.417",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-65",
        "3.569",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-65",
        "3.725",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-65",
        "3.881",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-65",
        "4.040",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-65",
        "4.201",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-65",
        "4.363",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-65",
        "4.526",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-65",
        "4.691",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-65",
        "4.858",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-65",
        "5.026",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-65",
        "5.195",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-65",
        "5.367",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-65",
        "5.539",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-65",
        "5.711",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-65",
        "5.886",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-65",
        "6.061",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-65",
        "6.238",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-65",
        "6.415",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-65",
        "6.594",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-65",
        "6.773",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-65",
        "6.954",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-65",
        "7.135",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-65",
        "7.317",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-65",
        "7.499",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-65",
        "7.683",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-65",
        "7.866",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-65",
        "8.050",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-65",
        "8.235",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-65",
        "8.420",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-65",
        "8.606",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-65",
        "8.790",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-65",
        "8.976",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-65",
        "9.163",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-65",
        "9.349",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-65",
        "9.535",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-65",
        "9.722",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-65",
        "9.908",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-65",
        "10.094",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-65",
        "10.279",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-65",
        "10.466",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-65",
        "10.651",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-65",
        "10.836",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-65",
        "11.021",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-65",
        "11.206",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-65",
        "11.389",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-65",
        "11.572",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-65",
        "11.755",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-65",
        "11.937",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-65",
        "12.118",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-65",
        "12.298",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-65",
        "12.478",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-65",
        "12.656",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-65",
        "12.834",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-65",
        "13.011",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-65",
        "13.187",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-65",
        "13.361",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-65",
        "13.534",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-65",
        "13.707",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-65",
        "13.878",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-65",
        "14.047",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-65",
        "14.216",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-65",
        "14.383",
        "-10.500",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-65",
        "14.548",
        "-10.406",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-65",
        "14.712",
        "-10.307",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-65",
        "9.439",
        "-5.075",
        "5.370",
        "7.184",
        "-0.192"
    ],
    [
        "179",
        "-65",
        "-3.051",
        "10.930",
        "7.566",
        "10.066",
        "-0.045"
    ],
    [
        "-180",
        "-64",
        "-20.267",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-64",
        "-20.729",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-64",
        "-21.194",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-64",
        "-21.661",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-64",
        "-22.131",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-64",
        "-22.602",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-64",
        "-23.075",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-64",
        "-23.550",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-64",
        "-24.026",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-64",
        "-24.504",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-64",
        "-24.983",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-64",
        "-21.341",
        "30.301",
        "1.824",
        "3.121",
        "0.026"
    ],
    [
        "-168",
        "-64",
        "17.248",
        "-8.482",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-64",
        "17.380",
        "-8.339",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-64",
        "17.509",
        "-8.192",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-64",
        "17.637",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-64",
        "17.762",
        "-7.892",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-64",
        "17.885",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-64",
        "18.005",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-64",
        "18.122",
        "-7.423",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-64",
        "18.238",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-64",
        "18.351",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-64",
        "18.460",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-64",
        "18.568",
        "-6.768",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-64",
        "18.673",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-64",
        "18.775",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-64",
        "18.874",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-64",
        "18.971",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-64",
        "19.066",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-64",
        "19.156",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-64",
        "19.245",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-64",
        "19.330",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-64",
        "19.413",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-64",
        "19.492",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-64",
        "19.569",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-64",
        "19.643",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-64",
        "19.715",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-64",
        "19.782",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-64",
        "19.847",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-64",
        "19.909",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-64",
        "19.968",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-64",
        "20.024",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-64",
        "20.076",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-64",
        "20.126",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-64",
        "20.172",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-64",
        "20.216",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-64",
        "20.256",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-64",
        "20.293",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-64",
        "20.327",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-64",
        "20.358",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-64",
        "20.385",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-64",
        "20.409",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-64",
        "20.431",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-64",
        "20.449",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-64",
        "20.463",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-64",
        "20.475",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-64",
        "20.483",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-64",
        "20.489",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-64",
        "20.490",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-64",
        "20.489",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-64",
        "20.484",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-64",
        "20.477",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-64",
        "20.465",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-64",
        "20.451",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-64",
        "20.434",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-64",
        "20.413",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-64",
        "20.389",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-64",
        "20.362",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-64",
        "20.332",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-64",
        "20.298",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-64",
        "20.262",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-64",
        "20.222",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-64",
        "20.179",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-64",
        "20.133",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-64",
        "20.084",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-64",
        "20.032",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-64",
        "19.977",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-64",
        "19.919",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-64",
        "19.857",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-64",
        "19.793",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-64",
        "19.725",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-64",
        "19.655",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-64",
        "19.581",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-64",
        "19.505",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-64",
        "19.425",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-64",
        "19.343",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-64",
        "19.259",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-64",
        "19.170",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-64",
        "19.079",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-64",
        "18.986",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-64",
        "18.890",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-64",
        "18.791",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-64",
        "18.688",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-64",
        "18.584",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-64",
        "18.478",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-64",
        "18.367",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-64",
        "18.256",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-64",
        "18.141",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-64",
        "18.023",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-64",
        "17.903",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-64",
        "17.781",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-64",
        "17.657",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-64",
        "17.529",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-64",
        "17.400",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-64",
        "17.269",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-64",
        "17.135",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-64",
        "16.999",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-64",
        "16.861",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-64",
        "16.720",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-64",
        "16.578",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-64",
        "16.434",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-64",
        "16.287",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-64",
        "16.138",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-64",
        "15.989",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-64",
        "15.837",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-64",
        "15.683",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-64",
        "15.526",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-64",
        "15.369",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-64",
        "15.211",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-64",
        "15.049",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-64",
        "14.887",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-64",
        "14.724",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-64",
        "14.558",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-64",
        "14.392",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-64",
        "14.224",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-64",
        "14.054",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-64",
        "13.884",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-64",
        "13.712",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-64",
        "13.540",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-64",
        "13.365",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-64",
        "13.190",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-64",
        "13.014",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-64",
        "12.837",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-64",
        "12.659",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-64",
        "12.480",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-64",
        "12.300",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-64",
        "12.120",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-64",
        "11.940",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-64",
        "11.758",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-64",
        "11.576",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-64",
        "11.393",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-64",
        "11.210",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-64",
        "11.026",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-64",
        "10.842",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-64",
        "10.659",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-64",
        "10.474",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-64",
        "10.290",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-64",
        "10.104",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-64",
        "9.920",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-64",
        "9.736",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-64",
        "9.551",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-64",
        "9.366",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-64",
        "9.182",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-64",
        "8.997",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-64",
        "8.814",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-64",
        "8.630",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-64",
        "8.446",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-64",
        "8.264",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-64",
        "8.082",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-64",
        "7.901",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-64",
        "7.719",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-64",
        "7.538",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-64",
        "7.359",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-64",
        "7.180",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-64",
        "7.002",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-64",
        "6.825",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-64",
        "6.648",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-64",
        "6.473",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-64",
        "6.299",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-64",
        "6.126",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-64",
        "5.954",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-64",
        "5.783",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-64",
        "5.614",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-64",
        "5.445",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-64",
        "5.279",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-64",
        "5.113",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-64",
        "4.949",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-64",
        "4.787",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-64",
        "4.626",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-64",
        "4.466",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-64",
        "4.309",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-64",
        "4.153",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-64",
        "3.998",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-64",
        "3.847",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-64",
        "3.696",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-64",
        "3.547",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-64",
        "3.400",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-64",
        "3.256",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-64",
        "3.113",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-64",
        "2.972",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-64",
        "2.834",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-64",
        "2.697",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-64",
        "2.563",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-64",
        "2.431",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-64",
        "2.302",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-64",
        "2.174",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-64",
        "2.049",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-64",
        "1.927",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-64",
        "1.807",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-64",
        "1.689",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-64",
        "1.573",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-64",
        "1.461",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-64",
        "1.351",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-64",
        "1.243",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-64",
        "1.139",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-64",
        "1.037",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-64",
        "0.937",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-64",
        "0.840",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-64",
        "0.747",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-64",
        "0.655",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-64",
        "0.566",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-64",
        "0.481",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-64",
        "0.398",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-64",
        "0.319",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-64",
        "0.242",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-64",
        "0.168",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-64",
        "0.098",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-64",
        "0.029",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-64",
        "-0.036",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-64",
        "-0.098",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-64",
        "-0.157",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-64",
        "-0.212",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-64",
        "-0.265",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-64",
        "-0.315",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-64",
        "-0.361",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-64",
        "-0.404",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-64",
        "-0.445",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-64",
        "-0.481",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-64",
        "-0.516",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-64",
        "-0.547",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-64",
        "-0.574",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-64",
        "-0.598",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-64",
        "-0.619",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-64",
        "-0.638",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-64",
        "-0.652",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-64",
        "-0.664",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-64",
        "-0.672",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-64",
        "-0.677",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-64",
        "-0.679",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-64",
        "-0.678",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-64",
        "-0.673",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-64",
        "-0.665",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-64",
        "-0.654",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-64",
        "-0.640",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-64",
        "-0.623",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-64",
        "-0.602",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-64",
        "-0.578",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-64",
        "-0.551",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-64",
        "-0.521",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-64",
        "-0.487",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-64",
        "-0.451",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-64",
        "-0.411",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-64",
        "-0.368",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-64",
        "-0.322",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-64",
        "-0.272",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-64",
        "-0.220",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-64",
        "-0.165",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-64",
        "-0.107",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-64",
        "-0.046",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-64",
        "0.019",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-64",
        "0.086",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-64",
        "0.157",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-64",
        "0.230",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-64",
        "0.307",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-64",
        "0.386",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-64",
        "0.468",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-64",
        "0.554",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-64",
        "0.641",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-64",
        "0.732",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-64",
        "0.825",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-64",
        "0.922",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-64",
        "1.021",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-64",
        "1.123",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-64",
        "1.227",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-64",
        "1.335",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-64",
        "1.444",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-64",
        "1.557",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-64",
        "1.671",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-64",
        "1.788",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-64",
        "1.908",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-64",
        "2.030",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-64",
        "2.155",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-64",
        "2.282",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-64",
        "2.411",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-64",
        "2.542",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-64",
        "2.677",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-64",
        "2.812",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-64",
        "2.950",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-64",
        "3.091",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-64",
        "3.233",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-64",
        "3.378",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-64",
        "3.524",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-64",
        "3.673",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-64",
        "3.822",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-64",
        "3.975",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-64",
        "4.128",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-64",
        "4.285",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-64",
        "4.442",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-64",
        "4.601",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-64",
        "4.762",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-64",
        "4.924",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-64",
        "5.087",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-64",
        "5.253",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-64",
        "5.419",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-64",
        "5.588",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-64",
        "5.757",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-64",
        "5.927",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-64",
        "6.099",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-64",
        "6.272",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-64",
        "6.446",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-64",
        "6.622",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-64",
        "6.797",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-64",
        "6.974",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-64",
        "7.152",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-64",
        "7.331",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-64",
        "7.511",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-64",
        "7.691",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-64",
        "7.872",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-64",
        "8.053",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-64",
        "8.236",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-64",
        "8.418",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-64",
        "8.601",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-64",
        "8.785",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-64",
        "8.969",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-64",
        "9.153",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-64",
        "9.337",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-64",
        "9.522",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-64",
        "9.707",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-64",
        "9.891",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-64",
        "10.076",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-64",
        "10.260",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-64",
        "10.445",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-64",
        "10.630",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-64",
        "10.814",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-64",
        "10.997",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-64",
        "11.181",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-64",
        "11.365",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-64",
        "11.547",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-64",
        "11.729",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-64",
        "11.912",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-64",
        "12.092",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-64",
        "12.273",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-64",
        "12.452",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-64",
        "12.631",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-64",
        "12.809",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-64",
        "12.987",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-64",
        "13.163",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-64",
        "13.338",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-64",
        "13.512",
        "-11.072",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-64",
        "13.685",
        "-11.000",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-64",
        "13.858",
        "-10.925",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-64",
        "14.028",
        "-10.846",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-64",
        "14.197",
        "-10.764",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-64",
        "14.366",
        "-10.680",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-64",
        "14.533",
        "-10.591",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-64",
        "-15.119",
        "26.287",
        "1.932",
        "3.538",
        "-0.309"
    ],
    [
        "176",
        "-64",
        "-18.444",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-64",
        "-18.895",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-64",
        "-19.350",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-64",
        "-19.807",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-63",
        "-21.360",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-63",
        "-21.818",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-63",
        "-22.279",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-63",
        "-22.742",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-63",
        "-23.208",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-63",
        "-23.675",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-63",
        "-24.144",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-63",
        "-24.614",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-63",
        "-25.087",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-63",
        "-25.560",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-63",
        "-26.035",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-63",
        "-26.511",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-63",
        "-26.988",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-63",
        "-27.466",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-63",
        "-16.085",
        "20.678",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-63",
        "17.923",
        "-8.043",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-63",
        "14.981",
        "2.221",
        "5.443",
        "8.306",
        "-0.072"
    ],
    [
        "-163",
        "-63",
        "18.168",
        "-7.738",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-63",
        "18.287",
        "-7.582",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-63",
        "17.158",
        "-4.214",
        "5.133",
        "6.465",
        "0.030"
    ],
    [
        "-160",
        "-63",
        "18.518",
        "-7.263",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-63",
        "18.630",
        "-7.100",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-63",
        "14.617",
        "-2.208",
        "5.279",
        "6.365",
        "0.155"
    ],
    [
        "-157",
        "-63",
        "13.513",
        "-5.002",
        "5.353",
        "6.400",
        "0.180"
    ],
    [
        "-156",
        "-63",
        "18.950",
        "-6.599",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-63",
        "19.051",
        "-6.427",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-63",
        "19.150",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-63",
        "19.246",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-63",
        "19.338",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-63",
        "19.429",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-63",
        "19.517",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-63",
        "19.602",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-63",
        "19.683",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-63",
        "19.762",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-63",
        "19.838",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-63",
        "19.912",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-63",
        "19.982",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-63",
        "20.049",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-63",
        "20.114",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-63",
        "20.175",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-63",
        "20.233",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-63",
        "20.289",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-63",
        "20.341",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-63",
        "20.391",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-63",
        "20.436",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-63",
        "20.480",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-63",
        "20.519",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-63",
        "20.556",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-63",
        "20.589",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-63",
        "20.620",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-63",
        "20.648",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-63",
        "20.672",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-63",
        "20.693",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-63",
        "20.710",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-63",
        "20.725",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-63",
        "20.736",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-63",
        "20.745",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-63",
        "20.750",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-63",
        "20.752",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-63",
        "20.750",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-63",
        "20.745",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-63",
        "20.738",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-63",
        "20.727",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-63",
        "20.713",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-63",
        "20.695",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-63",
        "20.676",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-63",
        "20.651",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-63",
        "20.625",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-63",
        "20.595",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-63",
        "20.561",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-63",
        "20.526",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-63",
        "20.486",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-63",
        "20.444",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-63",
        "20.398",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-63",
        "20.349",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-63",
        "20.297",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-63",
        "20.242",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-63",
        "20.185",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-63",
        "20.123",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-63",
        "20.060",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-63",
        "19.993",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-63",
        "19.923",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-63",
        "19.850",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-63",
        "19.775",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-63",
        "19.696",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-63",
        "19.615",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-63",
        "19.530",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-63",
        "19.443",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-63",
        "19.353",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-63",
        "19.260",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-63",
        "19.165",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-63",
        "19.066",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-63",
        "18.965",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-63",
        "18.862",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-63",
        "18.756",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-63",
        "18.647",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-63",
        "18.535",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-63",
        "18.422",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-63",
        "18.306",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-63",
        "18.187",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-63",
        "18.066",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-63",
        "17.943",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-63",
        "17.817",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-63",
        "17.688",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-63",
        "17.558",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-63",
        "17.425",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-63",
        "17.291",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-63",
        "17.153",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-63",
        "17.015",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-63",
        "16.873",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-63",
        "16.730",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-63",
        "16.585",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-63",
        "16.437",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-63",
        "16.288",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-63",
        "16.138",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-63",
        "15.985",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-63",
        "15.831",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-63",
        "15.675",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-63",
        "15.517",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-63",
        "15.358",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-63",
        "15.197",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-63",
        "15.035",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-63",
        "14.871",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-63",
        "14.706",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-63",
        "14.539",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-63",
        "14.372",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-63",
        "14.202",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-63",
        "14.032",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-63",
        "13.861",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-63",
        "13.688",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-63",
        "13.514",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-63",
        "13.340",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-63",
        "13.164",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-63",
        "12.988",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-63",
        "12.811",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-63",
        "12.633",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-63",
        "12.454",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-63",
        "12.274",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-63",
        "12.095",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-63",
        "11.914",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-63",
        "11.733",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-63",
        "11.551",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-63",
        "11.370",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-63",
        "11.187",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-63",
        "11.004",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-63",
        "10.822",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-63",
        "10.639",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-63",
        "10.456",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-63",
        "10.273",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-63",
        "10.089",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-63",
        "9.907",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-63",
        "9.723",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-63",
        "9.541",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-63",
        "9.358",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-63",
        "9.176",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-63",
        "8.994",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-63",
        "8.812",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-63",
        "8.632",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-63",
        "8.450",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-63",
        "8.271",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-63",
        "8.091",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-63",
        "7.912",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-63",
        "7.733",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-63",
        "7.557",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-63",
        "7.380",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-63",
        "7.204",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-63",
        "7.029",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-63",
        "6.856",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-63",
        "6.683",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-63",
        "6.511",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-63",
        "6.341",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-63",
        "6.171",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-63",
        "6.004",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-63",
        "5.837",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-63",
        "5.671",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-63",
        "5.507",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-63",
        "5.345",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-63",
        "5.184",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-63",
        "5.025",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-63",
        "4.866",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-63",
        "4.710",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-63",
        "4.556",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-63",
        "4.402",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-63",
        "4.251",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-63",
        "4.103",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-63",
        "3.955",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-63",
        "3.810",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-63",
        "3.666",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-63",
        "3.525",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-63",
        "3.385",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-63",
        "3.248",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-63",
        "3.113",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-63",
        "2.980",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-63",
        "2.849",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-63",
        "2.720",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-63",
        "2.594",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-63",
        "2.471",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-63",
        "2.349",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-63",
        "2.230",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-63",
        "2.113",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-63",
        "1.999",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-63",
        "1.887",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-63",
        "1.778",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-63",
        "1.671",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-63",
        "1.567",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-63",
        "1.466",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-63",
        "1.367",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-63",
        "1.271",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-63",
        "1.179",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-63",
        "1.088",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-63",
        "1.001",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-63",
        "0.916",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-63",
        "0.834",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-63",
        "0.755",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-63",
        "0.679",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-63",
        "0.606",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-63",
        "0.535",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-63",
        "0.468",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-63",
        "0.403",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-63",
        "0.342",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-63",
        "0.284",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-63",
        "0.229",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-63",
        "0.176",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-63",
        "0.127",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-63",
        "0.081",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-63",
        "0.037",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-63",
        "-0.002",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-63",
        "-0.039",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-63",
        "-0.072",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-63",
        "-0.103",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-63",
        "-0.130",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-63",
        "-0.155",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-63",
        "-0.176",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-63",
        "-0.193",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-63",
        "-0.208",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-63",
        "-0.219",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-63",
        "-0.227",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-63",
        "-0.233",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-63",
        "-0.234",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-63",
        "-0.233",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-63",
        "-0.228",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-63",
        "-0.221",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-63",
        "-0.210",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-63",
        "-0.196",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-63",
        "-0.178",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-63",
        "-0.157",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-63",
        "-0.134",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-63",
        "-0.108",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-63",
        "-0.078",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-63",
        "-0.044",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-63",
        "-0.008",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-63",
        "0.032",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-63",
        "0.074",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-63",
        "0.120",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-63",
        "0.168",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-63",
        "0.220",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-63",
        "0.275",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-63",
        "0.333",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-63",
        "0.394",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-63",
        "0.458",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-63",
        "0.525",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-63",
        "0.594",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-63",
        "0.667",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-63",
        "0.743",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-63",
        "0.821",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-63",
        "0.903",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-63",
        "0.987",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-63",
        "1.074",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-63",
        "1.164",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-63",
        "1.257",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-63",
        "1.353",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-63",
        "1.451",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-63",
        "1.552",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-63",
        "1.655",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-63",
        "1.762",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-63",
        "1.870",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-63",
        "1.982",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-63",
        "2.095",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-63",
        "2.212",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-63",
        "2.331",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-63",
        "2.451",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-63",
        "2.575",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-63",
        "2.700",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-63",
        "2.829",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-63",
        "2.959",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-63",
        "3.092",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-63",
        "3.227",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-63",
        "3.364",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-63",
        "3.503",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-63",
        "3.644",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-63",
        "3.788",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-63",
        "3.932",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-63",
        "4.080",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-63",
        "4.229",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-63",
        "4.379",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-63",
        "4.532",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-63",
        "4.687",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-63",
        "4.842",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-63",
        "5.000",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-63",
        "5.159",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-63",
        "5.320",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-63",
        "5.482",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-63",
        "5.647",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-63",
        "5.811",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-63",
        "5.978",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-63",
        "6.145",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-63",
        "6.315",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-63",
        "6.485",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-63",
        "6.657",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-63",
        "6.829",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-63",
        "7.003",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-63",
        "7.177",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-63",
        "7.353",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-63",
        "7.529",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-63",
        "7.706",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-63",
        "7.884",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-63",
        "8.064",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-63",
        "8.243",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-63",
        "8.423",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-63",
        "8.603",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-63",
        "8.785",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-63",
        "8.966",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-63",
        "9.147",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-63",
        "9.330",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-63",
        "9.513",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-63",
        "9.696",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-63",
        "9.878",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-63",
        "10.062",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-63",
        "10.245",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-63",
        "10.428",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-63",
        "10.610",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-63",
        "10.794",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-63",
        "10.976",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-63",
        "11.159",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-63",
        "11.341",
        "-11.714",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-63",
        "11.523",
        "-11.691",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-63",
        "11.705",
        "-11.664",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-63",
        "11.887",
        "-11.634",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-63",
        "12.067",
        "-11.601",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-63",
        "12.247",
        "-11.563",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-63",
        "12.427",
        "-11.522",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-63",
        "12.605",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-63",
        "12.784",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-63",
        "12.961",
        "-11.379",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-63",
        "13.137",
        "-11.324",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-63",
        "13.313",
        "-11.266",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-63",
        "13.488",
        "-11.205",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-63",
        "13.661",
        "-11.140",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-63",
        "7.683",
        "-6.806",
        "7.500",
        "9.776",
        "-0.171"
    ],
    [
        "169",
        "-63",
        "0.803",
        "2.163",
        "7.589",
        "11.339",
        "-0.027"
    ],
    [
        "170",
        "-63",
        "-3.460",
        "9.719",
        "7.251",
        "9.723",
        "0.010"
    ],
    [
        "171",
        "-63",
        "-17.364",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-63",
        "-17.128",
        "28.511",
        "5.116",
        "6.273",
        "-0.177"
    ],
    [
        "173",
        "-63",
        "-18.229",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-63",
        "-18.667",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-63",
        "-19.108",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-63",
        "-19.553",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-63",
        "-20.000",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-63",
        "-20.451",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-63",
        "-20.904",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-62",
        "-22.447",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-62",
        "-22.901",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-62",
        "-23.358",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-62",
        "-23.817",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-62",
        "-24.278",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-62",
        "-24.741",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-62",
        "-25.205",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-62",
        "-25.672",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-62",
        "-26.140",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-62",
        "-26.609",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-62",
        "-27.080",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-62",
        "-27.551",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-62",
        "-28.024",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-62",
        "-28.497",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-62",
        "-28.971",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-62",
        "-29.446",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-62",
        "-29.921",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-62",
        "-30.396",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-62",
        "-30.871",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-62",
        "-31.347",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-62",
        "-31.822",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-62",
        "-32.296",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-62",
        "-32.771",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-62",
        "-33.244",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-62",
        "-33.717",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-62",
        "-34.189",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-62",
        "19.419",
        "-6.254",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-62",
        "19.514",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-62",
        "19.607",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-62",
        "19.696",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-62",
        "19.783",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-62",
        "19.867",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-62",
        "19.948",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-62",
        "20.026",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-62",
        "20.102",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-62",
        "20.174",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-62",
        "20.244",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-62",
        "20.311",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-62",
        "20.374",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-62",
        "20.435",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-62",
        "20.493",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-62",
        "20.548",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-62",
        "20.600",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-62",
        "20.649",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-62",
        "20.694",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-62",
        "20.737",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-62",
        "20.777",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-62",
        "20.812",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-62",
        "20.846",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-62",
        "20.877",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-62",
        "20.903",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-62",
        "20.927",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-62",
        "20.948",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-62",
        "20.966",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-62",
        "20.980",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-62",
        "20.992",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-62",
        "21.000",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-62",
        "21.005",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-62",
        "21.006",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-62",
        "21.005",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-62",
        "21.001",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-62",
        "20.993",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-62",
        "20.982",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-62",
        "20.968",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-62",
        "20.951",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-62",
        "20.931",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-62",
        "20.907",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-62",
        "20.881",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-62",
        "20.851",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-62",
        "20.818",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-62",
        "20.782",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-62",
        "20.743",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-62",
        "20.701",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-62",
        "20.656",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-62",
        "20.608",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-62",
        "20.556",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-62",
        "20.502",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-62",
        "20.444",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-62",
        "20.384",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-62",
        "20.321",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-62",
        "20.255",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-62",
        "20.186",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-62",
        "20.113",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-62",
        "20.038",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-62",
        "19.960",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-62",
        "19.880",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-62",
        "19.796",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-62",
        "19.710",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-62",
        "19.620",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-62",
        "19.528",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-62",
        "19.433",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-62",
        "19.337",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-62",
        "19.237",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-62",
        "19.134",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-62",
        "19.029",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-62",
        "18.921",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-62",
        "18.811",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-62",
        "18.698",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-62",
        "18.583",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-62",
        "18.466",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-62",
        "18.345",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-62",
        "18.223",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-62",
        "18.098",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-62",
        "17.971",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-62",
        "17.841",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-62",
        "17.710",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-62",
        "17.576",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-62",
        "17.440",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-62",
        "17.303",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-62",
        "17.163",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-62",
        "17.021",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-62",
        "16.877",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-62",
        "16.732",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-62",
        "16.584",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-62",
        "16.434",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-62",
        "16.283",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-62",
        "16.131",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-62",
        "15.976",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-62",
        "15.819",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-62",
        "15.662",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-62",
        "15.502",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-62",
        "15.341",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-62",
        "15.180",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-62",
        "15.016",
        "10.667",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-62",
        "14.850",
        "10.752",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-62",
        "14.684",
        "10.834",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-62",
        "14.517",
        "10.913",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-62",
        "14.348",
        "10.989",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-62",
        "14.178",
        "11.060",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-62",
        "14.007",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-62",
        "13.835",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-62",
        "13.662",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-62",
        "13.488",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-62",
        "13.313",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-62",
        "13.138",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-62",
        "12.961",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-62",
        "12.785",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-62",
        "12.606",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-62",
        "12.428",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-62",
        "12.248",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-62",
        "12.070",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-62",
        "11.890",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-62",
        "11.709",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-62",
        "11.529",
        "11.730",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-62",
        "11.348",
        "11.747",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-62",
        "11.166",
        "11.760",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-62",
        "10.985",
        "11.769",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-62",
        "10.804",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-62",
        "10.623",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-62",
        "10.441",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-62",
        "10.259",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-62",
        "10.078",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-62",
        "9.897",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-62",
        "9.716",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-62",
        "9.536",
        "11.714",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-62",
        "9.355",
        "11.691",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-62",
        "9.175",
        "11.664",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-62",
        "8.996",
        "11.634",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-62",
        "8.816",
        "11.601",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-62",
        "8.638",
        "11.563",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-62",
        "8.460",
        "11.522",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-62",
        "8.283",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-62",
        "8.106",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-62",
        "7.930",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-62",
        "7.756",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-62",
        "7.582",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-62",
        "7.409",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-62",
        "7.237",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-62",
        "7.065",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-62",
        "6.895",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-62",
        "6.726",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-62",
        "6.559",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-62",
        "6.392",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-62",
        "6.227",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-62",
        "6.063",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-62",
        "5.900",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-62",
        "5.739",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-62",
        "5.579",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-62",
        "5.422",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-62",
        "5.265",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-62",
        "5.111",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-62",
        "4.957",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-62",
        "4.806",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-62",
        "4.655",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-62",
        "4.508",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-62",
        "4.362",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-62",
        "4.218",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-62",
        "4.076",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-62",
        "3.936",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-62",
        "3.797",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-62",
        "3.661",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-62",
        "3.527",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-62",
        "3.396",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-62",
        "3.266",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-62",
        "3.138",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-62",
        "3.014",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-62",
        "2.891",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-62",
        "2.770",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-62",
        "2.652",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-62",
        "2.537",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-62",
        "2.424",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-62",
        "2.312",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-62",
        "2.204",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-62",
        "2.099",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-62",
        "1.996",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-62",
        "1.896",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-62",
        "1.797",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-62",
        "1.703",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-62",
        "1.610",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-62",
        "1.521",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-62",
        "1.434",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-62",
        "1.350",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-62",
        "1.269",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-62",
        "1.191",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-62",
        "1.115",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-62",
        "1.043",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-62",
        "0.973",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-62",
        "0.906",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-62",
        "0.843",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-62",
        "0.782",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-62",
        "0.724",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-62",
        "0.668",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-62",
        "0.617",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-62",
        "0.568",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-62",
        "0.522",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-62",
        "0.480",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-62",
        "0.440",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-62",
        "0.404",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-62",
        "0.370",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-62",
        "0.340",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-62",
        "0.314",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-62",
        "0.290",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-62",
        "0.269",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-62",
        "0.251",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-62",
        "0.237",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-62",
        "0.225",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-62",
        "0.217",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-62",
        "0.212",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-62",
        "0.210",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-62",
        "0.212",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-62",
        "0.216",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-62",
        "0.223",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-62",
        "0.235",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-62",
        "0.249",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-62",
        "0.266",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-62",
        "0.286",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-62",
        "0.310",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-62",
        "0.336",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-62",
        "0.366",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-62",
        "0.399",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-62",
        "0.435",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-62",
        "0.474",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-62",
        "0.516",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-62",
        "0.561",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-62",
        "0.609",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-62",
        "0.661",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-62",
        "0.715",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-62",
        "0.773",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-62",
        "0.833",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-62",
        "0.896",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-62",
        "0.962",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-62",
        "1.031",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-62",
        "1.103",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-62",
        "1.179",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-62",
        "1.257",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-62",
        "1.337",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-62",
        "1.421",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-62",
        "1.507",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-62",
        "1.596",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-62",
        "1.689",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-62",
        "1.783",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-62",
        "1.880",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-62",
        "1.980",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-62",
        "2.083",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-62",
        "2.188",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-62",
        "2.296",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-62",
        "2.407",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-62",
        "2.519",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-62",
        "2.634",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-62",
        "2.751",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-62",
        "2.872",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-62",
        "2.994",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-62",
        "3.119",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-62",
        "3.246",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-62",
        "3.375",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-62",
        "3.507",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-62",
        "3.640",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-62",
        "3.776",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-62",
        "3.914",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-62",
        "4.054",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-62",
        "4.196",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-62",
        "4.340",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-62",
        "4.485",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-62",
        "4.633",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-62",
        "4.783",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-62",
        "4.934",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-62",
        "5.086",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-62",
        "5.242",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-62",
        "5.398",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-62",
        "5.555",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-62",
        "5.715",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-62",
        "5.876",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-62",
        "6.037",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-62",
        "6.201",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-62",
        "6.367",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-62",
        "6.532",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-62",
        "6.700",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-62",
        "6.869",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-62",
        "7.039",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-62",
        "7.210",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-62",
        "7.382",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-62",
        "7.555",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-62",
        "7.729",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-62",
        "7.904",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-62",
        "8.079",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-62",
        "8.256",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-62",
        "8.433",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-62",
        "8.611",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-62",
        "8.789",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-62",
        "8.968",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-62",
        "9.147",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-62",
        "9.327",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-62",
        "9.508",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-62",
        "9.688",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-62",
        "9.869",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-62",
        "10.051",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-62",
        "10.232",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-62",
        "10.413",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-62",
        "10.594",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-62",
        "10.776",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-62",
        "10.957",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-62",
        "11.139",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-62",
        "11.320",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-62",
        "11.501",
        "-11.733",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-62",
        "11.302",
        "-1.621",
        "7.705",
        "9.982",
        "-0.067"
    ],
    [
        "155",
        "-62",
        "-0.522",
        "35.980",
        "5.502",
        "8.324",
        "-0.250"
    ],
    [
        "156",
        "-62",
        "-6.755",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-62",
        "-7.520",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-62",
        "-8.278",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-62",
        "1.519",
        "24.874",
        "7.014",
        "8.245",
        "-0.067"
    ],
    [
        "160",
        "-62",
        "-0.226",
        "11.725",
        "6.517",
        "8.387",
        "0.088"
    ],
    [
        "161",
        "-62",
        "12.933",
        "-11.479",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-62",
        "13.110",
        "-11.431",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-62",
        "4.743",
        "-1.356",
        "5.282",
        "8.287",
        "-0.179"
    ],
    [
        "164",
        "-62",
        "-15.610",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-62",
        "-16.008",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-62",
        "-16.411",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-62",
        "-16.818",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-62",
        "-17.229",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-62",
        "-17.645",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-62",
        "-18.064",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-62",
        "-18.487",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-62",
        "-18.914",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-62",
        "-19.344",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-62",
        "-19.778",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-62",
        "-20.216",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-62",
        "-20.656",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-62",
        "-21.099",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-62",
        "-21.546",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-62",
        "-21.995",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-61",
        "-23.527",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-61",
        "-23.977",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-61",
        "-24.429",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-61",
        "-24.884",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-61",
        "-25.340",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-61",
        "-25.799",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-61",
        "-26.259",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-61",
        "-26.721",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-61",
        "-27.185",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-61",
        "-27.650",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-61",
        "-28.116",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-61",
        "-28.583",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-61",
        "-29.051",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-61",
        "-29.520",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-61",
        "-29.990",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-61",
        "-30.460",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-61",
        "-30.930",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-61",
        "-31.401",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-61",
        "-31.872",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-61",
        "-32.343",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-61",
        "-32.813",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-61",
        "-33.283",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-61",
        "-33.753",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-61",
        "-34.222",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-61",
        "-34.691",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-61",
        "-35.158",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-61",
        "-35.624",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-61",
        "19.777",
        "-6.079",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-61",
        "19.868",
        "-5.902",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-61",
        "19.956",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-61",
        "20.043",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-61",
        "20.126",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-61",
        "20.207",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-61",
        "20.284",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-61",
        "20.359",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-61",
        "20.431",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-61",
        "20.500",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-61",
        "20.566",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-61",
        "20.629",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-61",
        "20.690",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-61",
        "20.747",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-61",
        "20.801",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-61",
        "20.852",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-61",
        "20.901",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-61",
        "20.945",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-61",
        "20.988",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-61",
        "21.027",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-61",
        "21.063",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-61",
        "21.096",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-61",
        "21.126",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-61",
        "21.153",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-61",
        "21.176",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-61",
        "21.198",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-61",
        "21.214",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-61",
        "21.229",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-61",
        "21.240",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-61",
        "21.248",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-61",
        "21.253",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-61",
        "21.255",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-61",
        "21.254",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-61",
        "21.249",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-61",
        "21.242",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-61",
        "21.230",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-61",
        "21.217",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-61",
        "21.200",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-61",
        "21.180",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-61",
        "21.156",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-61",
        "21.131",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-61",
        "21.101",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-61",
        "21.069",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-61",
        "21.033",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-61",
        "20.994",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-61",
        "20.952",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-61",
        "20.907",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-61",
        "20.859",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-61",
        "20.809",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-61",
        "20.755",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-61",
        "20.698",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-61",
        "20.639",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-61",
        "20.576",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-61",
        "20.510",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-61",
        "20.442",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-61",
        "20.370",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-61",
        "20.296",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-61",
        "20.219",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-61",
        "20.138",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-61",
        "20.056",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-61",
        "19.970",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-61",
        "19.882",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-61",
        "19.791",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-61",
        "19.697",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-61",
        "19.601",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-61",
        "19.502",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-61",
        "19.401",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-61",
        "19.296",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-61",
        "19.189",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-61",
        "19.080",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-61",
        "18.968",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-61",
        "18.855",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-61",
        "18.737",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-61",
        "18.619",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-61",
        "18.498",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-61",
        "18.374",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-61",
        "18.248",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-61",
        "18.120",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-61",
        "17.990",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-61",
        "17.857",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-61",
        "17.723",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-61",
        "17.586",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-61",
        "17.448",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-61",
        "17.307",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-61",
        "17.164",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-61",
        "17.020",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-61",
        "16.875",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-61",
        "16.726",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-61",
        "16.577",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-61",
        "16.425",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-61",
        "16.271",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-61",
        "16.118",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-61",
        "15.961",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-61",
        "15.803",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-61",
        "15.644",
        "10.485",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-61",
        "15.483",
        "10.578",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-61",
        "14.721",
        "10.290",
        "2.939",
        "2.722",
        "-0.138"
    ],
    [
        "-56",
        "-61",
        "12.179",
        "12.669",
        "3.698",
        "3.516",
        "-0.274"
    ],
    [
        "-55",
        "-61",
        "11.651",
        "11.493",
        "4.295",
        "3.946",
        "-0.230"
    ],
    [
        "-54",
        "-61",
        "11.248",
        "12.404",
        "5.156",
        "3.682",
        "-0.373"
    ],
    [
        "-53",
        "-61",
        "11.933",
        "12.411",
        "4.311",
        "3.669",
        "-0.347"
    ],
    [
        "-52",
        "-61",
        "13.741",
        "11.690",
        "2.454",
        "2.969",
        "-0.416"
    ],
    [
        "-51",
        "-61",
        "14.322",
        "11.130",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-61",
        "14.151",
        "11.195",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-61",
        "13.980",
        "11.257",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-61",
        "13.808",
        "11.316",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-61",
        "13.634",
        "11.371",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-61",
        "13.461",
        "11.423",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-61",
        "13.286",
        "11.471",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-61",
        "13.110",
        "11.516",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-61",
        "12.934",
        "11.557",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-61",
        "12.758",
        "11.595",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-61",
        "12.580",
        "11.629",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-61",
        "12.402",
        "11.659",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-61",
        "12.224",
        "11.686",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-61",
        "11.775",
        "13.628",
        "1.702",
        "2.531",
        "0.013"
    ],
    [
        "-37",
        "-61",
        "10.786",
        "14.271",
        "3.158",
        "2.738",
        "-0.195"
    ],
    [
        "-36",
        "-61",
        "10.668",
        "14.247",
        "3.642",
        "3.073",
        "-0.249"
    ],
    [
        "-35",
        "-61",
        "10.916",
        "13.143",
        "3.320",
        "3.169",
        "-0.251"
    ],
    [
        "-34",
        "-61",
        "11.104",
        "12.893",
        "1.912",
        "3.097",
        "-0.232"
    ],
    [
        "-33",
        "-61",
        "11.149",
        "11.774",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-61",
        "10.969",
        "11.776",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-61",
        "10.789",
        "11.775",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-61",
        "10.610",
        "11.770",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-61",
        "10.429",
        "11.761",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-61",
        "10.250",
        "11.749",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-61",
        "10.071",
        "11.733",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-61",
        "8.833",
        "11.715",
        "2.692",
        "3.162",
        "-0.413"
    ],
    [
        "-25",
        "-61",
        "8.264",
        "11.856",
        "3.064",
        "3.309",
        "-0.462"
    ],
    [
        "-24",
        "-61",
        "7.875",
        "12.076",
        "3.450",
        "3.471",
        "-0.481"
    ],
    [
        "-23",
        "-61",
        "7.510",
        "12.178",
        "3.683",
        "3.900",
        "-0.391"
    ],
    [
        "-22",
        "-61",
        "7.359",
        "12.119",
        "4.042",
        "4.696",
        "-0.282"
    ],
    [
        "-21",
        "-61",
        "7.506",
        "12.015",
        "4.615",
        "5.131",
        "-0.225"
    ],
    [
        "-20",
        "-61",
        "8.039",
        "11.986",
        "4.269",
        "4.696",
        "-0.209"
    ],
    [
        "-19",
        "-61",
        "8.651",
        "11.479",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-61",
        "8.477",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-61",
        "8.303",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-61",
        "8.129",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-61",
        "7.957",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-61",
        "7.785",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-61",
        "7.614",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-61",
        "7.446",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-61",
        "7.277",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-61",
        "7.110",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-61",
        "6.944",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-61",
        "6.779",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-61",
        "6.615",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-61",
        "6.453",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-61",
        "6.292",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-61",
        "6.132",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-61",
        "5.973",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-61",
        "5.817",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-61",
        "5.662",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-61",
        "5.509",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-61",
        "5.357",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-61",
        "5.207",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-61",
        "5.059",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-61",
        "4.913",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-61",
        "4.767",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-61",
        "4.625",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-61",
        "4.484",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-61",
        "4.345",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-61",
        "4.208",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-61",
        "4.073",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-61",
        "3.940",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-61",
        "3.810",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-61",
        "3.681",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-61",
        "3.555",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-61",
        "3.431",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-61",
        "3.310",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-61",
        "3.190",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-61",
        "3.073",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-61",
        "2.959",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-61",
        "2.848",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-61",
        "2.738",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-61",
        "2.630",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-61",
        "2.526",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-61",
        "2.424",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-61",
        "2.324",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-61",
        "2.228",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-61",
        "2.134",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-61",
        "2.042",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-61",
        "1.953",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-61",
        "1.868",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-61",
        "1.784",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-61",
        "1.703",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-61",
        "1.626",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-61",
        "1.551",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-61",
        "1.480",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-61",
        "1.411",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-61",
        "1.344",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-61",
        "1.281",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-61",
        "1.221",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-61",
        "1.163",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-61",
        "1.109",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-61",
        "1.057",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-61",
        "1.010",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-61",
        "0.964",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-61",
        "0.922",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-61",
        "0.883",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-61",
        "0.847",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-61",
        "0.814",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-61",
        "0.784",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-61",
        "0.757",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-61",
        "0.733",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-61",
        "0.713",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-61",
        "0.696",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-61",
        "0.681",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-61",
        "0.670",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-61",
        "0.662",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-61",
        "0.657",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-61",
        "0.655",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-61",
        "0.656",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-61",
        "0.661",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-61",
        "0.669",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-61",
        "0.679",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-61",
        "0.693",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-61",
        "0.710",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-61",
        "0.731",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-61",
        "0.753",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-61",
        "0.780",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-61",
        "0.809",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-61",
        "0.842",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-61",
        "0.877",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-61",
        "0.916",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-61",
        "0.957",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-61",
        "1.002",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-61",
        "1.050",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-61",
        "1.101",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-61",
        "1.155",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-61",
        "1.211",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-61",
        "1.271",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-61",
        "1.334",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-61",
        "1.400",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-61",
        "1.468",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-61",
        "1.539",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-61",
        "1.614",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-61",
        "1.691",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-61",
        "1.771",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-61",
        "1.854",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-61",
        "1.939",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-61",
        "2.028",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-61",
        "2.119",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-61",
        "2.212",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-61",
        "2.309",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-61",
        "2.408",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-61",
        "2.510",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-61",
        "2.614",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-61",
        "2.721",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-61",
        "2.830",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-61",
        "2.941",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-61",
        "3.056",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-61",
        "3.172",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-61",
        "3.291",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-61",
        "3.413",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-61",
        "3.536",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-61",
        "3.662",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-61",
        "3.790",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-61",
        "3.920",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-61",
        "4.052",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-61",
        "4.187",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-61",
        "4.324",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-61",
        "4.463",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-61",
        "4.603",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-61",
        "4.745",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-61",
        "4.890",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-61",
        "5.036",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-61",
        "5.184",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-61",
        "5.334",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-61",
        "5.485",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-61",
        "5.638",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-61",
        "5.793",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-61",
        "5.950",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-61",
        "6.108",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-61",
        "6.266",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-61",
        "6.427",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-61",
        "6.589",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-61",
        "6.753",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-61",
        "6.918",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-61",
        "7.083",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-61",
        "7.251",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-61",
        "7.419",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-61",
        "7.588",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-61",
        "7.759",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-61",
        "7.930",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-61",
        "8.103",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-61",
        "8.275",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-61",
        "8.450",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-61",
        "8.625",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-61",
        "8.800",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-61",
        "8.977",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-61",
        "9.153",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-61",
        "9.330",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-61",
        "9.508",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-61",
        "9.686",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-61",
        "9.865",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-61",
        "10.043",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-61",
        "10.223",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-61",
        "10.402",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-61",
        "10.582",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-61",
        "10.761",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-61",
        "10.941",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-61",
        "11.121",
        "-11.775",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-61",
        "11.300",
        "-11.770",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-61",
        "11.480",
        "-11.761",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-61",
        "11.659",
        "-11.749",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-61",
        "4.096",
        "13.785",
        "7.806",
        "10.326",
        "-0.212"
    ],
    [
        "154",
        "-61",
        "-3.410",
        "40.781",
        "7.715",
        "11.091",
        "-0.205"
    ],
    [
        "155",
        "-61",
        "-5.156",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-61",
        "-5.922",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-61",
        "-6.681",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-61",
        "-7.431",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-61",
        "-8.175",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-61",
        "-8.909",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-61",
        "-10.467",
        "26.740",
        "3.364",
        "5.149",
        "-0.183"
    ],
    [
        "162",
        "-61",
        "-15.979",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-61",
        "-16.365",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-61",
        "-16.754",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-61",
        "-17.149",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-61",
        "-17.548",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-61",
        "-17.951",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-61",
        "-18.358",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-61",
        "-18.770",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-61",
        "-19.185",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-61",
        "-19.604",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-61",
        "-20.027",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-61",
        "-20.454",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-61",
        "-20.883",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-61",
        "-21.316",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-61",
        "-21.753",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-61",
        "-22.192",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-61",
        "-22.634",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-61",
        "-23.079",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-60",
        "-24.599",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-60",
        "-25.045",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-60",
        "-25.493",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-60",
        "-25.943",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-60",
        "-26.395",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-60",
        "-26.849",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-60",
        "-27.305",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-60",
        "-27.763",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-60",
        "-28.221",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-60",
        "-28.682",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-60",
        "-29.143",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-60",
        "-29.606",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-60",
        "-30.070",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-60",
        "-30.534",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-60",
        "-30.999",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-60",
        "-31.464",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-60",
        "-31.930",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-60",
        "-32.396",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-60",
        "-32.863",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-60",
        "-33.329",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-60",
        "-33.795",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-60",
        "-34.260",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-60",
        "-34.725",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-60",
        "-35.190",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-60",
        "-35.654",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-60",
        "-36.116",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-60",
        "-36.578",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-60",
        "-37.039",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-60",
        "5.185",
        "3.493",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-60",
        "20.211",
        "-5.723",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-60",
        "20.297",
        "-5.542",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-60",
        "20.379",
        "-5.361",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-60",
        "20.459",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-60",
        "20.536",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-60",
        "20.609",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-60",
        "20.681",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-60",
        "20.749",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-60",
        "20.815",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-60",
        "20.877",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-60",
        "20.937",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-60",
        "20.993",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-60",
        "21.047",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-60",
        "21.098",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-60",
        "21.146",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-60",
        "21.191",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-60",
        "21.233",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-60",
        "21.271",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-60",
        "21.307",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-60",
        "21.340",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-60",
        "21.370",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-60",
        "21.396",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-60",
        "21.420",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-60",
        "21.440",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-60",
        "21.457",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-60",
        "21.471",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-60",
        "21.482",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-60",
        "21.490",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-60",
        "21.496",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-60",
        "21.497",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-60",
        "21.496",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-60",
        "21.492",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-60",
        "21.484",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-60",
        "21.473",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-60",
        "21.459",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-60",
        "21.443",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-60",
        "21.422",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-60",
        "21.400",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-60",
        "21.373",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-60",
        "21.344",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-60",
        "21.312",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-60",
        "21.277",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-60",
        "21.238",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-60",
        "21.198",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-60",
        "21.153",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-60",
        "21.106",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-60",
        "21.055",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-60",
        "21.002",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-60",
        "20.946",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-60",
        "20.887",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-60",
        "20.824",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-60",
        "20.760",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-60",
        "20.691",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-60",
        "20.620",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-60",
        "20.548",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-60",
        "20.470",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-60",
        "20.392",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-60",
        "20.309",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-60",
        "20.224",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-60",
        "20.137",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-60",
        "20.048",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-60",
        "19.954",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-60",
        "19.859",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-60",
        "19.761",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-60",
        "19.661",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-60",
        "19.557",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-60",
        "19.451",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-60",
        "19.344",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-60",
        "19.233",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-60",
        "19.119",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-60",
        "19.005",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-60",
        "18.886",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-60",
        "18.767",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-60",
        "18.644",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-60",
        "18.520",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-60",
        "18.392",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-60",
        "18.264",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-60",
        "18.132",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-60",
        "17.999",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-60",
        "17.865",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-60",
        "17.727",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-60",
        "17.588",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-60",
        "17.447",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-60",
        "17.304",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-60",
        "17.159",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-60",
        "17.013",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-60",
        "16.864",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-60",
        "16.714",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-60",
        "16.562",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-60",
        "16.410",
        "10.190",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-60",
        "16.255",
        "10.292",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-60",
        "16.098",
        "10.390",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-60",
        "15.064",
        "10.347",
        "2.011",
        "2.839",
        "0.086"
    ],
    [
        "-58",
        "-60",
        "11.136",
        "12.335",
        "1.931",
        "2.523",
        "0.088"
    ],
    [
        "-57",
        "-60",
        "9.487",
        "12.719",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-60",
        "9.293",
        "13.018",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-60",
        "9.094",
        "13.313",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-60",
        "8.891",
        "13.604",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-60",
        "8.683",
        "13.891",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-60",
        "8.471",
        "14.173",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-60",
        "8.254",
        "14.452",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-60",
        "8.034",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-60",
        "7.810",
        "14.994",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-60",
        "7.581",
        "15.259",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-60",
        "7.348",
        "15.520",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-60",
        "7.112",
        "15.775",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-60",
        "6.871",
        "16.026",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-60",
        "6.627",
        "16.271",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-60",
        "6.379",
        "16.512",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-60",
        "6.128",
        "16.748",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-60",
        "5.873",
        "16.978",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-60",
        "5.614",
        "17.204",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-60",
        "5.353",
        "17.424",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-60",
        "5.088",
        "17.639",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-60",
        "4.820",
        "17.848",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-60",
        "4.548",
        "18.052",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-60",
        "4.274",
        "18.251",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-60",
        "3.996",
        "18.444",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-60",
        "4.235",
        "16.515",
        "2.838",
        "3.801",
        "-0.150"
    ],
    [
        "-32",
        "-60",
        "3.933",
        "15.690",
        "3.521",
        "4.024",
        "-0.080"
    ],
    [
        "-31",
        "-60",
        "3.720",
        "15.084",
        "3.653",
        "3.932",
        "-0.064"
    ],
    [
        "-30",
        "-60",
        "3.259",
        "14.475",
        "3.578",
        "3.603",
        "-0.071"
    ],
    [
        "-29",
        "-60",
        "2.999",
        "13.750",
        "3.302",
        "3.427",
        "-0.137"
    ],
    [
        "-28",
        "-60",
        "3.040",
        "13.289",
        "3.221",
        "3.002",
        "-0.230"
    ],
    [
        "-27",
        "-60",
        "3.278",
        "13.102",
        "3.318",
        "2.894",
        "-0.312"
    ],
    [
        "-26",
        "-60",
        "3.369",
        "13.275",
        "3.235",
        "3.111",
        "-0.336"
    ],
    [
        "-25",
        "-60",
        "2.936",
        "13.519",
        "3.291",
        "3.271",
        "-0.358"
    ],
    [
        "-24",
        "-60",
        "2.041",
        "13.527",
        "3.520",
        "3.463",
        "-0.354"
    ],
    [
        "-23",
        "-60",
        "1.291",
        "13.285",
        "3.685",
        "3.964",
        "-0.293"
    ],
    [
        "-22",
        "-60",
        "0.687",
        "13.133",
        "4.061",
        "4.880",
        "-0.216"
    ],
    [
        "-21",
        "-60",
        "1.162",
        "12.715",
        "5.185",
        "5.503",
        "-0.190"
    ],
    [
        "-20",
        "-60",
        "3.046",
        "12.544",
        "5.397",
        "5.278",
        "-0.151"
    ],
    [
        "-19",
        "-60",
        "6.066",
        "12.464",
        "4.941",
        "4.132",
        "-0.153"
    ],
    [
        "-18",
        "-60",
        "8.844",
        "11.431",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-60",
        "8.672",
        "11.379",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-60",
        "8.500",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-60",
        "8.329",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-60",
        "8.159",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-60",
        "7.991",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-60",
        "7.823",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-60",
        "7.656",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-60",
        "7.491",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-60",
        "7.326",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-60",
        "7.162",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-60",
        "7.001",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-60",
        "6.840",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-60",
        "6.680",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-60",
        "6.522",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-60",
        "6.366",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-60",
        "6.211",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-60",
        "6.057",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-60",
        "5.905",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-60",
        "5.755",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-60",
        "5.607",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-60",
        "5.460",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-60",
        "5.314",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-60",
        "5.171",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-60",
        "5.030",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-60",
        "4.891",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-60",
        "4.754",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-60",
        "4.618",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-60",
        "4.485",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-60",
        "4.353",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-60",
        "4.224",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-60",
        "4.096",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-60",
        "3.971",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-60",
        "3.849",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-60",
        "3.728",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-60",
        "3.611",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-60",
        "3.495",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-60",
        "3.381",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-60",
        "3.270",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-60",
        "3.161",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-60",
        "3.056",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-60",
        "2.952",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-60",
        "2.851",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-60",
        "2.753",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-60",
        "2.656",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-60",
        "2.564",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-60",
        "2.473",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-60",
        "2.385",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-60",
        "2.300",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-60",
        "2.217",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-60",
        "2.138",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-60",
        "2.060",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-60",
        "1.987",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-60",
        "1.916",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-60",
        "1.847",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-60",
        "1.781",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-60",
        "1.720",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-60",
        "1.659",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-60",
        "1.603",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-60",
        "1.549",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-60",
        "1.498",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-60",
        "1.450",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-60",
        "1.406",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-60",
        "1.364",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-60",
        "1.325",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-60",
        "1.289",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-60",
        "1.256",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-60",
        "1.227",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-60",
        "1.201",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-60",
        "1.177",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-60",
        "1.156",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-60",
        "1.140",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-60",
        "1.125",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-60",
        "1.114",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-60",
        "1.106",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-60",
        "1.102",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-60",
        "1.099",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-60",
        "1.101",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-60",
        "1.105",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-60",
        "1.113",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-60",
        "1.124",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-60",
        "1.137",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-60",
        "1.154",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-60",
        "1.174",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-60",
        "1.197",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-60",
        "1.223",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-60",
        "1.252",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-60",
        "1.284",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-60",
        "1.320",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-60",
        "1.358",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-60",
        "1.399",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-60",
        "1.443",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-60",
        "1.491",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-60",
        "1.541",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-60",
        "1.595",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-60",
        "1.651",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-60",
        "1.710",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-60",
        "1.772",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-60",
        "1.837",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-60",
        "1.905",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-60",
        "1.976",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-60",
        "2.049",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-60",
        "2.126",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-60",
        "2.205",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-60",
        "2.287",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-60",
        "2.372",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-60",
        "2.459",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-60",
        "2.549",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-60",
        "2.642",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-60",
        "2.737",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-60",
        "2.835",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-60",
        "2.936",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-60",
        "3.039",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-60",
        "3.145",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-60",
        "3.253",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-60",
        "3.364",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-60",
        "3.477",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-60",
        "3.592",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-60",
        "3.710",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-60",
        "3.830",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-60",
        "3.952",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-60",
        "4.077",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-60",
        "4.204",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-60",
        "4.333",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-60",
        "4.464",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-60",
        "4.597",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-60",
        "4.732",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-60",
        "4.869",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-60",
        "5.009",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-60",
        "5.150",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-60",
        "5.292",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-60",
        "5.437",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-60",
        "5.584",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-60",
        "5.732",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-60",
        "5.882",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-60",
        "6.034",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-60",
        "6.187",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-60",
        "6.341",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-60",
        "6.498",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-60",
        "6.656",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-60",
        "6.815",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-60",
        "6.976",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-60",
        "7.137",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-60",
        "7.301",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-60",
        "7.465",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-60",
        "7.630",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-60",
        "7.797",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-60",
        "7.965",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-60",
        "8.134",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-60",
        "8.304",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-60",
        "8.474",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-60",
        "8.645",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-60",
        "8.818",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-60",
        "8.991",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-60",
        "9.164",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-60",
        "9.339",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-60",
        "9.514",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-60",
        "9.689",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-60",
        "9.866",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-60",
        "10.041",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-60",
        "10.218",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-60",
        "10.396",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-60",
        "10.573",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-60",
        "10.751",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-60",
        "10.929",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-60",
        "11.107",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-60",
        "11.285",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-60",
        "13.180",
        "-7.972",
        "7.705",
        "9.277",
        "-0.029"
    ],
    [
        "150",
        "-60",
        "11.499",
        "9.151",
        "8.785",
        "9.640",
        "-0.019"
    ],
    [
        "151",
        "-60",
        "1.086",
        "45.054",
        "1.835",
        "3.249",
        "-0.147"
    ],
    [
        "152",
        "-60",
        "-2.011",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-60",
        "-2.709",
        "47.294",
        "6.960",
        "9.344",
        "-0.244"
    ],
    [
        "154",
        "-60",
        "-3.564",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-60",
        "-4.330",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-60",
        "-5.088",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-60",
        "-5.839",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-60",
        "-6.583",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-60",
        "-7.319",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-60",
        "-15.201",
        "24.209",
        "0.630",
        "1.316",
        "-0.102"
    ],
    [
        "161",
        "-60",
        "-16.749",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-60",
        "-17.126",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-60",
        "-17.508",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-60",
        "-17.894",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-60",
        "-18.284",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-60",
        "-18.679",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-60",
        "-19.079",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-60",
        "-19.482",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-60",
        "-19.889",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-60",
        "-20.301",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-60",
        "-20.716",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-60",
        "-21.134",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-60",
        "-21.556",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-60",
        "-21.982",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-60",
        "-22.411",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-60",
        "-22.843",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-60",
        "-23.278",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-60",
        "-23.716",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-60",
        "-24.156",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-59",
        "-25.665",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-59",
        "-26.106",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-59",
        "-26.549",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-59",
        "-26.994",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-59",
        "-27.442",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-59",
        "-27.891",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-59",
        "-28.343",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-59",
        "-28.795",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-59",
        "-29.250",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-59",
        "-29.705",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-59",
        "-30.162",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-59",
        "-30.620",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-59",
        "-31.079",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-59",
        "-31.538",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-59",
        "-31.999",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-59",
        "-32.459",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-59",
        "-32.921",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-59",
        "-33.382",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-59",
        "-33.843",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-59",
        "-34.305",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-59",
        "-34.766",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-59",
        "-35.227",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-59",
        "-35.687",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-59",
        "-36.147",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-59",
        "-36.606",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-59",
        "-37.064",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-59",
        "-37.521",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-59",
        "-37.977",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-59",
        "-38.431",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-59",
        "-38.884",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-59",
        "-39.336",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-59",
        "4.963",
        "3.807",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-59",
        "20.705",
        "-5.177",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-59",
        "20.781",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-59",
        "20.854",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-59",
        "20.924",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-59",
        "20.992",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-59",
        "21.057",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-59",
        "21.119",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-59",
        "21.178",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-59",
        "21.234",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-59",
        "21.288",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-59",
        "21.338",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-59",
        "21.385",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-59",
        "21.430",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-59",
        "21.471",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-59",
        "21.509",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-59",
        "21.544",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-59",
        "21.576",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-59",
        "21.607",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-59",
        "21.633",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-59",
        "21.655",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-59",
        "21.676",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-59",
        "21.693",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-59",
        "21.707",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-59",
        "21.718",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-59",
        "21.726",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-59",
        "21.730",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-59",
        "21.732",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-59",
        "21.732",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-59",
        "21.727",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-59",
        "21.719",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-59",
        "21.709",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-59",
        "21.695",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-59",
        "21.679",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-59",
        "21.659",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-59",
        "21.637",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-59",
        "21.610",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-59",
        "21.581",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-59",
        "21.549",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-59",
        "21.514",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-59",
        "21.477",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-59",
        "21.436",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-59",
        "21.392",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-59",
        "21.346",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-59",
        "21.296",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-59",
        "21.242",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-59",
        "21.187",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-59",
        "21.129",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-59",
        "21.066",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-59",
        "21.002",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-59",
        "20.935",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-59",
        "20.865",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-59",
        "20.792",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-59",
        "20.717",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-59",
        "20.638",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-59",
        "20.558",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-59",
        "20.474",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-59",
        "20.387",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-59",
        "20.298",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-59",
        "20.206",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-59",
        "20.111",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-59",
        "20.014",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-59",
        "19.914",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-59",
        "19.813",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-59",
        "19.708",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-59",
        "19.601",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-59",
        "19.491",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-59",
        "19.379",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-59",
        "19.265",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-59",
        "19.149",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-59",
        "19.030",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-59",
        "18.908",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-59",
        "18.785",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-59",
        "18.661",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-59",
        "18.533",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-59",
        "18.403",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-59",
        "18.271",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-59",
        "18.137",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-59",
        "18.002",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-59",
        "17.864",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-59",
        "17.724",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-59",
        "17.583",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-59",
        "17.439",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-59",
        "17.294",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-59",
        "17.147",
        "9.868",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-59",
        "16.999",
        "9.979",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-59",
        "16.848",
        "10.087",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-59",
        "15.793",
        "10.628",
        "1.840",
        "2.762",
        "0.046"
    ],
    [
        "-61",
        "-59",
        "11.581",
        "11.296",
        "1.883",
        "2.961",
        "0.026"
    ],
    [
        "-60",
        "-59",
        "9.721",
        "11.799",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-59",
        "9.542",
        "12.109",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-59",
        "9.359",
        "12.416",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-59",
        "9.171",
        "12.719",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-59",
        "8.978",
        "13.018",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-59",
        "8.782",
        "13.313",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-59",
        "8.581",
        "13.604",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-59",
        "8.374",
        "13.891",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-59",
        "8.164",
        "14.173",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-59",
        "7.951",
        "14.452",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-59",
        "7.732",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-59",
        "7.510",
        "14.994",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-59",
        "7.284",
        "15.259",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-59",
        "7.053",
        "15.520",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-59",
        "6.820",
        "15.775",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-59",
        "6.581",
        "16.026",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-59",
        "6.339",
        "16.271",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-59",
        "6.094",
        "16.512",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-59",
        "5.846",
        "16.748",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-59",
        "5.594",
        "16.978",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-59",
        "5.337",
        "17.204",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-59",
        "5.079",
        "17.424",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-59",
        "4.817",
        "17.639",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-59",
        "4.551",
        "17.848",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-59",
        "4.282",
        "18.052",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-59",
        "4.011",
        "18.251",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-59",
        "3.737",
        "18.444",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-59",
        "3.459",
        "18.631",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-59",
        "3.179",
        "18.813",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-59",
        "2.896",
        "18.989",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-59",
        "2.287",
        "16.915",
        "2.101",
        "2.979",
        "-0.133"
    ],
    [
        "-29",
        "-59",
        "1.620",
        "14.840",
        "2.541",
        "3.178",
        "-0.132"
    ],
    [
        "-28",
        "-59",
        "1.267",
        "13.891",
        "2.683",
        "2.669",
        "-0.174"
    ],
    [
        "-27",
        "-59",
        "1.048",
        "13.459",
        "2.823",
        "2.297",
        "-0.178"
    ],
    [
        "-26",
        "-59",
        "0.753",
        "13.394",
        "2.744",
        "2.306",
        "-0.125"
    ],
    [
        "-25",
        "-59",
        "0.059",
        "13.603",
        "2.682",
        "2.412",
        "-0.091"
    ],
    [
        "-24",
        "-59",
        "-1.165",
        "13.574",
        "2.632",
        "2.518",
        "-0.087"
    ],
    [
        "-23",
        "-59",
        "-2.681",
        "12.510",
        "0.569",
        "0.764",
        "0.015"
    ],
    [
        "-22",
        "-59",
        "-3.004",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-59",
        "-3.189",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-59",
        "-3.374",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-59",
        "-3.558",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-59",
        "-0.044",
        "11.847",
        "4.027",
        "3.065",
        "-0.149"
    ],
    [
        "-17",
        "-59",
        "6.323",
        "12.680",
        "3.456",
        "2.683",
        "0.074"
    ],
    [
        "-16",
        "-59",
        "8.868",
        "11.324",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-59",
        "8.700",
        "11.266",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-59",
        "8.532",
        "11.205",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-59",
        "8.364",
        "11.140",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-59",
        "8.198",
        "11.072",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-59",
        "8.034",
        "11.000",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-59",
        "7.870",
        "10.925",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-59",
        "7.706",
        "10.846",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-59",
        "7.545",
        "10.764",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-59",
        "7.385",
        "10.680",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-59",
        "7.225",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-59",
        "7.067",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-59",
        "6.911",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-59",
        "6.756",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-59",
        "6.603",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-59",
        "6.451",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-59",
        "6.300",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-59",
        "6.151",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-59",
        "6.005",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-59",
        "5.859",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-59",
        "5.716",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-59",
        "5.574",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-59",
        "5.434",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-59",
        "5.296",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-59",
        "5.160",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-59",
        "5.026",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-59",
        "4.894",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-59",
        "4.764",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-59",
        "4.636",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-59",
        "4.510",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-59",
        "4.387",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-59",
        "4.264",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-59",
        "4.145",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-59",
        "4.029",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-59",
        "3.914",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-59",
        "3.802",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-59",
        "3.692",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-59",
        "3.585",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-59",
        "3.480",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-59",
        "3.377",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-59",
        "3.277",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-59",
        "3.180",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-59",
        "3.085",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-59",
        "2.993",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-59",
        "2.903",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-59",
        "2.816",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-59",
        "2.732",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-59",
        "2.650",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-59",
        "2.571",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-59",
        "2.495",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-59",
        "2.422",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-59",
        "2.351",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-59",
        "2.283",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-59",
        "2.219",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-59",
        "2.157",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-59",
        "2.098",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-59",
        "2.042",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-59",
        "1.989",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-59",
        "1.939",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-59",
        "1.891",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-59",
        "1.846",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-59",
        "1.806",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-59",
        "1.768",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-59",
        "1.731",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-59",
        "1.699",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-59",
        "1.670",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-59",
        "1.644",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-59",
        "1.620",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-59",
        "1.600",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-59",
        "1.584",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-59",
        "1.569",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-59",
        "1.558",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-59",
        "1.550",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-59",
        "1.546",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-59",
        "1.543",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-59",
        "1.545",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-59",
        "1.550",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-59",
        "1.557",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-59",
        "1.567",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-59",
        "1.581",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-59",
        "1.598",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-59",
        "1.617",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-59",
        "1.640",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-59",
        "1.666",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-59",
        "1.695",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-59",
        "1.727",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-59",
        "1.761",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-59",
        "1.800",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-59",
        "1.840",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-59",
        "1.884",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-59",
        "1.931",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-59",
        "1.980",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-59",
        "2.033",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-59",
        "2.089",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-59",
        "2.148",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-59",
        "2.209",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-59",
        "2.274",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-59",
        "2.341",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-59",
        "2.411",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-59",
        "2.483",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-59",
        "2.560",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-59",
        "2.638",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-59",
        "2.719",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-59",
        "2.802",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-59",
        "2.889",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-59",
        "2.979",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-59",
        "3.070",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-59",
        "3.165",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-59",
        "3.261",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-59",
        "3.362",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-59",
        "3.464",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-59",
        "3.568",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-59",
        "3.675",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-59",
        "3.785",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-59",
        "3.897",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-59",
        "4.011",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-59",
        "4.128",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-59",
        "4.247",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-59",
        "4.367",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-59",
        "4.490",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-59",
        "4.616",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-59",
        "4.744",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-59",
        "4.873",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-59",
        "5.005",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-59",
        "5.140",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-59",
        "5.275",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-59",
        "5.413",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-59",
        "5.552",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-59",
        "5.694",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-59",
        "5.837",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-59",
        "5.982",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-59",
        "6.129",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-59",
        "6.277",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-59",
        "6.428",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-59",
        "6.579",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-59",
        "6.732",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-59",
        "6.887",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-59",
        "7.044",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-59",
        "7.201",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-59",
        "7.360",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-59",
        "7.519",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-59",
        "7.681",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-59",
        "7.844",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-59",
        "8.008",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-59",
        "8.173",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-59",
        "8.339",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-59",
        "8.506",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-59",
        "8.674",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-59",
        "8.842",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-59",
        "9.012",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-59",
        "9.183",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-59",
        "9.354",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-59",
        "9.526",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-59",
        "9.698",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-59",
        "9.871",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-59",
        "10.045",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-59",
        "10.219",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-59",
        "10.395",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-59",
        "10.569",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-59",
        "10.745",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-59",
        "10.921",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-59",
        "11.097",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-59",
        "11.272",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-59",
        "11.448",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-59",
        "11.624",
        "-11.776",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-59",
        "4.636",
        "34.185",
        "5.160",
        "9.410",
        "-0.237"
    ],
    [
        "150",
        "-59",
        "0.356",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-59",
        "-0.428",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-59",
        "-1.207",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-59",
        "-1.979",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-59",
        "-2.744",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-59",
        "-3.501",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-59",
        "-4.253",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-59",
        "-4.997",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-59",
        "-6.670",
        "48.598",
        "0.443",
        "0.966",
        "0.110"
    ],
    [
        "159",
        "-59",
        "-17.164",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-59",
        "-17.527",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-59",
        "-17.895",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-59",
        "-18.268",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-59",
        "-18.645",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-59",
        "-19.028",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-59",
        "-19.414",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-59",
        "-19.805",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-59",
        "-20.200",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-59",
        "-20.599",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-59",
        "-21.003",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-59",
        "-21.410",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-59",
        "-21.820",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-59",
        "-22.235",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-59",
        "-22.653",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-59",
        "-23.074",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-59",
        "-23.498",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-59",
        "-23.926",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-59",
        "-24.357",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-59",
        "-24.790",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-59",
        "-25.226",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-58",
        "-26.722",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-58",
        "-27.158",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-58",
        "-27.597",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-58",
        "-28.038",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-58",
        "-28.480",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-58",
        "-28.925",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-58",
        "-29.371",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-58",
        "-29.819",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-58",
        "-30.269",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-58",
        "-30.720",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-58",
        "-31.172",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-58",
        "-31.625",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-58",
        "-32.079",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-58",
        "-32.533",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-58",
        "-32.989",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-58",
        "-33.445",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-58",
        "-33.901",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-58",
        "-34.357",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-58",
        "-34.814",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-58",
        "-35.270",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-58",
        "-35.726",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-58",
        "-36.182",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-58",
        "-36.638",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-58",
        "-37.093",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-58",
        "-37.547",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-58",
        "-38.000",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-58",
        "-38.452",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-58",
        "-38.903",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-58",
        "-39.353",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-58",
        "-39.801",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-58",
        "-40.248",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-58",
        "-40.693",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-58",
        "-16.567",
        "13.305",
        "7.056",
        "9.014",
        "0.041"
    ],
    [
        "-147",
        "-58",
        "21.020",
        "-4.991",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-58",
        "21.092",
        "-4.804",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-58",
        "21.162",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-58",
        "21.229",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-58",
        "21.293",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-58",
        "21.355",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-58",
        "21.413",
        "-3.849",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-58",
        "21.469",
        "-3.654",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-58",
        "21.521",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-58",
        "21.571",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-58",
        "21.618",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-58",
        "21.661",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-58",
        "21.702",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-58",
        "21.741",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-58",
        "21.775",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-58",
        "21.807",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-58",
        "21.837",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-58",
        "21.862",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-58",
        "21.885",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-58",
        "21.905",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-58",
        "21.923",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-58",
        "21.936",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-58",
        "21.947",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-58",
        "21.955",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-58",
        "21.960",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-58",
        "21.961",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-58",
        "21.960",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-58",
        "21.956",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-58",
        "21.948",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-58",
        "21.938",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-58",
        "21.925",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-58",
        "21.908",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-58",
        "21.888",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-58",
        "21.866",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-58",
        "21.840",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-58",
        "21.812",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-58",
        "21.781",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-58",
        "21.746",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-58",
        "21.709",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-58",
        "21.668",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-58",
        "21.624",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-58",
        "21.578",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-58",
        "21.529",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-58",
        "21.477",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-58",
        "21.422",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-58",
        "21.364",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-58",
        "21.303",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-58",
        "21.239",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-58",
        "21.173",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-58",
        "21.103",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-58",
        "21.032",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-58",
        "20.957",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-58",
        "20.879",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-58",
        "20.799",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-58",
        "20.716",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-58",
        "20.630",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-58",
        "20.541",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-58",
        "20.451",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-58",
        "20.357",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-58",
        "20.262",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-58",
        "20.163",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-58",
        "20.062",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-58",
        "19.959",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-58",
        "19.853",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-58",
        "19.744",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-58",
        "19.633",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-58",
        "19.521",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-58",
        "19.405",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-58",
        "19.287",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-58",
        "19.168",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-58",
        "19.045",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-58",
        "18.922",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-58",
        "18.795",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-58",
        "18.667",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-58",
        "18.537",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-58",
        "18.404",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-58",
        "18.269",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-58",
        "18.134",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-58",
        "17.996",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-58",
        "17.856",
        "9.518",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-58",
        "17.714",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-58",
        "17.570",
        "9.754",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-58",
        "16.037",
        "10.103",
        "1.772",
        "2.680",
        "0.258"
    ],
    [
        "-64",
        "-58",
        "11.461",
        "10.284",
        "1.793",
        "2.718",
        "0.214"
    ],
    [
        "-63",
        "-58",
        "9.899",
        "10.847",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-58",
        "9.736",
        "11.168",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-58",
        "9.568",
        "11.486",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-58",
        "9.397",
        "11.799",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-58",
        "9.220",
        "12.109",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-58",
        "9.038",
        "12.416",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-58",
        "8.852",
        "12.719",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-58",
        "8.661",
        "13.018",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-58",
        "8.467",
        "13.313",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-58",
        "8.267",
        "13.604",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-58",
        "8.064",
        "13.891",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-58",
        "7.856",
        "14.173",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-58",
        "7.644",
        "14.452",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-58",
        "7.429",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-58",
        "7.208",
        "14.994",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-58",
        "6.984",
        "15.259",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-58",
        "6.757",
        "15.520",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-58",
        "6.525",
        "15.775",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-58",
        "6.290",
        "16.026",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-58",
        "6.050",
        "16.271",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-58",
        "5.808",
        "16.512",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-58",
        "5.562",
        "16.748",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-58",
        "5.312",
        "16.978",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-58",
        "5.060",
        "17.204",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-58",
        "4.804",
        "17.424",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-58",
        "4.543",
        "17.639",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-58",
        "4.280",
        "17.848",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-58",
        "4.015",
        "18.052",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-58",
        "3.746",
        "18.251",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-58",
        "3.475",
        "18.444",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-58",
        "3.201",
        "18.631",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-58",
        "2.923",
        "18.813",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-58",
        "2.643",
        "18.989",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-58",
        "2.467",
        "17.590",
        "1.773",
        "2.715",
        "-0.177"
    ],
    [
        "-29",
        "-58",
        "2.356",
        "15.302",
        "2.088",
        "2.952",
        "-0.128"
    ],
    [
        "-28",
        "-58",
        "2.013",
        "14.193",
        "2.155",
        "2.414",
        "-0.074"
    ],
    [
        "-27",
        "-58",
        "1.422",
        "13.647",
        "2.292",
        "1.983",
        "-0.021"
    ],
    [
        "-26",
        "-58",
        "0.588",
        "13.333",
        "2.292",
        "1.844",
        "0.020"
    ],
    [
        "-25",
        "-58",
        "-0.448",
        "13.145",
        "2.323",
        "1.834",
        "0.060"
    ],
    [
        "-24",
        "-58",
        "-1.581",
        "12.927",
        "2.373",
        "1.813",
        "0.102"
    ],
    [
        "-23",
        "-58",
        "-2.765",
        "12.439",
        "0.524",
        "0.545",
        "0.131"
    ],
    [
        "-22",
        "-58",
        "-3.046",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-58",
        "-3.228",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-58",
        "-3.411",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-58",
        "-3.593",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-58",
        "-3.775",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-58",
        "-3.955",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-58",
        "-4.136",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-58",
        "-4.316",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-58",
        "-1.705",
        "11.108",
        "2.822",
        "2.501",
        "0.030"
    ],
    [
        "-13",
        "-58",
        "0.051",
        "11.354",
        "3.405",
        "2.514",
        "0.089"
    ],
    [
        "-12",
        "-58",
        "1.283",
        "11.448",
        "3.700",
        "2.821",
        "0.064"
    ],
    [
        "-11",
        "-58",
        "2.326",
        "11.809",
        "3.635",
        "2.842",
        "0.066"
    ],
    [
        "-10",
        "-58",
        "3.371",
        "11.517",
        "3.647",
        "2.551",
        "0.120"
    ],
    [
        "-9",
        "-58",
        "4.844",
        "11.715",
        "3.587",
        "2.819",
        "0.062"
    ],
    [
        "-8",
        "-58",
        "6.933",
        "11.695",
        "3.073",
        "2.514",
        "0.085"
    ],
    [
        "-7",
        "-58",
        "8.674",
        "10.549",
        "1.679",
        "2.362",
        "-0.082"
    ],
    [
        "-6",
        "-58",
        "7.608",
        "10.591",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-58",
        "7.452",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-58",
        "7.298",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-58",
        "7.144",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-58",
        "6.993",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-58",
        "6.842",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-58",
        "6.693",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-58",
        "6.546",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-58",
        "6.401",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-58",
        "6.257",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-58",
        "6.115",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-58",
        "5.975",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-58",
        "5.837",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-58",
        "5.700",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-58",
        "5.565",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-58",
        "5.433",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-58",
        "5.302",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-58",
        "5.173",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-58",
        "5.047",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-58",
        "4.922",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-58",
        "4.800",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-58",
        "4.680",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-58",
        "4.562",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-58",
        "4.446",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-58",
        "4.333",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-58",
        "4.221",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-58",
        "4.112",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-58",
        "4.006",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-58",
        "3.903",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-58",
        "3.801",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-58",
        "3.702",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-58",
        "3.606",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-58",
        "3.512",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-58",
        "3.421",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-58",
        "3.332",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-58",
        "3.246",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-58",
        "3.163",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-58",
        "3.082",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-58",
        "3.004",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-58",
        "2.929",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-58",
        "2.856",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-58",
        "2.787",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-58",
        "2.719",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-58",
        "2.655",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-58",
        "2.594",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-58",
        "2.536",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-58",
        "2.480",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-58",
        "2.428",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-58",
        "2.378",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-58",
        "2.331",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-58",
        "2.287",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-58",
        "2.246",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-58",
        "2.208",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-58",
        "2.173",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-58",
        "2.142",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-58",
        "2.112",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-58",
        "2.086",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-58",
        "2.063",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-58",
        "2.043",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-58",
        "2.026",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-58",
        "2.012",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-58",
        "2.002",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-58",
        "1.994",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-58",
        "1.989",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-58",
        "1.988",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-58",
        "1.989",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-58",
        "1.993",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-58",
        "2.000",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-58",
        "2.011",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-58",
        "2.024",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-58",
        "2.041",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-58",
        "2.061",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-58",
        "2.083",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-58",
        "2.109",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-58",
        "2.137",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-58",
        "2.168",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-58",
        "2.203",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-58",
        "2.240",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-58",
        "2.280",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-58",
        "2.324",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-58",
        "2.371",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-58",
        "2.420",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-58",
        "2.472",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-58",
        "2.527",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-58",
        "2.585",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-58",
        "2.646",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-58",
        "2.710",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-58",
        "2.776",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-58",
        "2.846",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-58",
        "2.917",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-58",
        "2.993",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-58",
        "3.070",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-58",
        "3.150",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-58",
        "3.233",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-58",
        "3.318",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-58",
        "3.407",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-58",
        "3.497",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-58",
        "3.592",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-58",
        "3.687",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-58",
        "3.786",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-58",
        "3.887",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-58",
        "3.991",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-58",
        "4.096",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-58",
        "4.204",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-58",
        "4.315",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-58",
        "4.428",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-58",
        "4.543",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-58",
        "4.661",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-58",
        "4.781",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-58",
        "4.903",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-58",
        "5.027",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-58",
        "5.154",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-58",
        "5.282",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-58",
        "5.412",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-58",
        "5.545",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-58",
        "5.679",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-58",
        "5.815",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-58",
        "5.953",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-58",
        "6.093",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-58",
        "6.235",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-58",
        "6.378",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-58",
        "6.524",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-58",
        "6.670",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-58",
        "6.819",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-58",
        "6.969",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-58",
        "7.121",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-58",
        "7.274",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-58",
        "7.429",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-58",
        "7.584",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-58",
        "7.741",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-58",
        "7.900",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-58",
        "8.059",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-58",
        "8.221",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-58",
        "8.383",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-58",
        "8.546",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-58",
        "8.710",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-58",
        "8.875",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-58",
        "9.042",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-58",
        "9.209",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-58",
        "9.376",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-58",
        "9.545",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-58",
        "9.714",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-58",
        "9.885",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-58",
        "10.056",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-58",
        "10.227",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-58",
        "10.399",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-58",
        "10.572",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-58",
        "10.744",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-58",
        "10.917",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-58",
        "11.091",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-58",
        "11.264",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-58",
        "11.439",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-58",
        "11.612",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-58",
        "11.786",
        "-11.774",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-58",
        "7.542",
        "21.106",
        "7.130",
        "10.641",
        "-0.156"
    ],
    [
        "149",
        "-58",
        "1.926",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-58",
        "1.144",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-58",
        "0.368",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-58",
        "-0.403",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-58",
        "-1.166",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-58",
        "-1.923",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-58",
        "-2.673",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-58",
        "-3.416",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-58",
        "-4.152",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-58",
        "-14.789",
        "31.554",
        "1.353",
        "2.354",
        "0.272"
    ],
    [
        "159",
        "-58",
        "-18.312",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-58",
        "-18.671",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-58",
        "-19.035",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-58",
        "-19.404",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-58",
        "-19.777",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-58",
        "-20.156",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-58",
        "-20.538",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-58",
        "-20.925",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-58",
        "-21.316",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-58",
        "-21.711",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-58",
        "-22.110",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-58",
        "-22.512",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-58",
        "-22.919",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-58",
        "-23.329",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-58",
        "-23.742",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-58",
        "-24.159",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-58",
        "-24.579",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-58",
        "-25.002",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-58",
        "-25.428",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-58",
        "-25.857",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-58",
        "-26.288",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-57",
        "-27.771",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-57",
        "-28.203",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-57",
        "-28.636",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-57",
        "-29.072",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-57",
        "-29.510",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-57",
        "-29.950",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-57",
        "-30.391",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-57",
        "-30.834",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-57",
        "-31.279",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-57",
        "-31.725",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-57",
        "-32.172",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-57",
        "-32.620",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-57",
        "-33.069",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-57",
        "-33.518",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-57",
        "-33.969",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-57",
        "-34.419",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-57",
        "-34.871",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-57",
        "-35.322",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-57",
        "-35.773",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-57",
        "-36.225",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-57",
        "-36.676",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-57",
        "-37.127",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-57",
        "-37.577",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-57",
        "-38.027",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-57",
        "-38.476",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-57",
        "-38.924",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-57",
        "-39.372",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-57",
        "-39.818",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-57",
        "-40.262",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-57",
        "-40.706",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-57",
        "-41.147",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-57",
        "-41.587",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-57",
        "-42.026",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-57",
        "-42.462",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-57",
        "4.675",
        "4.112",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-57",
        "21.393",
        "-4.616",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-57",
        "21.459",
        "-4.426",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-57",
        "21.523",
        "-4.236",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-57",
        "21.584",
        "-4.043",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-57",
        "12.572",
        "0.791",
        "7.492",
        "8.317",
        "0.007"
    ],
    [
        "-140",
        "-57",
        "9.488",
        "3.001",
        "7.200",
        "8.426",
        "-0.130"
    ],
    [
        "-139",
        "-57",
        "21.748",
        "-3.458",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-57",
        "21.798",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-57",
        "21.843",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-57",
        "21.887",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-57",
        "21.927",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-57",
        "21.964",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-57",
        "21.999",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-57",
        "22.031",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-57",
        "22.060",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-57",
        "22.085",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-57",
        "22.108",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-57",
        "22.128",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-57",
        "22.144",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-57",
        "22.159",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-57",
        "22.170",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-57",
        "22.177",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-57",
        "22.182",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-57",
        "22.184",
        "-0.016",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-57",
        "22.183",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-57",
        "22.178",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-57",
        "22.171",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-57",
        "22.161",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-57",
        "22.147",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-57",
        "22.131",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-57",
        "22.112",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-57",
        "22.089",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-57",
        "22.064",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-57",
        "22.035",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-57",
        "22.005",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-57",
        "21.970",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-57",
        "21.933",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-57",
        "21.893",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-57",
        "21.851",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-57",
        "21.805",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-57",
        "21.756",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-57",
        "21.704",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-57",
        "21.650",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-57",
        "21.593",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-57",
        "21.532",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-57",
        "21.470",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-57",
        "21.404",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-57",
        "21.335",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-57",
        "21.264",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-57",
        "21.190",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-57",
        "21.113",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-57",
        "21.034",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-57",
        "20.951",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-57",
        "20.867",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-57",
        "20.780",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-57",
        "20.689",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-57",
        "20.598",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-57",
        "20.503",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-57",
        "20.405",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-57",
        "20.305",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-57",
        "20.203",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-57",
        "20.098",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-57",
        "19.991",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-57",
        "19.881",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-57",
        "19.770",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-57",
        "19.656",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-57",
        "19.540",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-57",
        "19.421",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-57",
        "19.300",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-57",
        "19.178",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-57",
        "19.052",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-57",
        "18.926",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-57",
        "18.796",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-57",
        "18.666",
        "9.011",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-57",
        "18.533",
        "9.142",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-57",
        "18.398",
        "9.271",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-57",
        "18.262",
        "9.396",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-57",
        "12.815",
        "9.275",
        "3.728",
        "4.368",
        "0.129"
    ],
    [
        "-67",
        "-57",
        "10.162",
        "9.532",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-57",
        "10.021",
        "9.865",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-57",
        "9.874",
        "10.196",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-57",
        "9.722",
        "10.524",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-57",
        "9.566",
        "10.847",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-57",
        "9.405",
        "11.168",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-57",
        "9.239",
        "11.486",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-57",
        "9.068",
        "11.799",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-57",
        "8.893",
        "12.109",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-57",
        "8.714",
        "12.416",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-57",
        "8.530",
        "12.719",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-57",
        "8.341",
        "13.018",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-57",
        "8.149",
        "13.313",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-57",
        "7.952",
        "13.604",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-57",
        "7.751",
        "13.891",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-57",
        "7.546",
        "14.173",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-57",
        "7.336",
        "14.452",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-57",
        "7.122",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-57",
        "6.905",
        "14.994",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-57",
        "6.683",
        "15.259",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-57",
        "6.458",
        "15.520",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-57",
        "6.229",
        "15.775",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-57",
        "5.996",
        "16.026",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-57",
        "5.760",
        "16.271",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-57",
        "5.520",
        "16.512",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-57",
        "5.276",
        "16.748",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-57",
        "5.029",
        "16.978",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-57",
        "4.780",
        "17.204",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-57",
        "4.526",
        "17.424",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-57",
        "4.269",
        "17.639",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-57",
        "4.009",
        "17.848",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-57",
        "3.747",
        "18.052",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-57",
        "3.481",
        "18.251",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-57",
        "3.213",
        "18.444",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-57",
        "2.941",
        "18.631",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-57",
        "2.667",
        "18.813",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-57",
        "2.384",
        "19.001",
        "0.449",
        "0.962",
        "-0.062"
    ],
    [
        "-30",
        "-57",
        "2.276",
        "17.296",
        "2.253",
        "2.938",
        "-0.100"
    ],
    [
        "-29",
        "-57",
        "2.284",
        "14.944",
        "2.328",
        "2.969",
        "0.031"
    ],
    [
        "-28",
        "-57",
        "2.005",
        "13.979",
        "2.343",
        "2.439",
        "0.154"
    ],
    [
        "-27",
        "-57",
        "1.371",
        "13.501",
        "2.501",
        "2.055",
        "0.229"
    ],
    [
        "-26",
        "-57",
        "0.343",
        "13.109",
        "2.402",
        "1.818",
        "0.189"
    ],
    [
        "-25",
        "-57",
        "-0.931",
        "12.816",
        "2.277",
        "1.598",
        "0.145"
    ],
    [
        "-24",
        "-57",
        "-2.119",
        "12.608",
        "2.136",
        "1.327",
        "0.232"
    ],
    [
        "-23",
        "-57",
        "-2.905",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-57",
        "-3.085",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-57",
        "-3.267",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-57",
        "-3.447",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-57",
        "-3.627",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-57",
        "-3.806",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-57",
        "-3.986",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-57",
        "-4.164",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-57",
        "-4.342",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-57",
        "-4.518",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-57",
        "-4.695",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-57",
        "-4.870",
        "11.951",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-57",
        "-5.045",
        "11.888",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-57",
        "-5.218",
        "11.822",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-57",
        "-5.390",
        "11.752",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-57",
        "-5.562",
        "11.679",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-57",
        "-5.733",
        "11.602",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-57",
        "1.442",
        "11.486",
        "2.260",
        "2.982",
        "-0.091"
    ],
    [
        "-5",
        "-57",
        "7.835",
        "10.500",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-57",
        "7.682",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-57",
        "7.531",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-57",
        "7.380",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-57",
        "7.231",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-57",
        "7.085",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-57",
        "6.939",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-57",
        "6.795",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-57",
        "6.653",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-57",
        "6.513",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-57",
        "6.373",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-57",
        "6.237",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-57",
        "6.102",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-57",
        "5.968",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-57",
        "5.837",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-57",
        "5.708",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-57",
        "5.581",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-57",
        "5.456",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-57",
        "5.333",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-57",
        "5.212",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-57",
        "5.093",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-57",
        "4.976",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-57",
        "4.861",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-57",
        "4.750",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-57",
        "4.640",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-57",
        "4.532",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-57",
        "4.427",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-57",
        "4.325",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-57",
        "4.225",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-57",
        "4.127",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-57",
        "4.031",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-57",
        "3.938",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-57",
        "3.848",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-57",
        "3.761",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-57",
        "3.675",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-57",
        "3.593",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-57",
        "3.513",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-57",
        "3.436",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-57",
        "3.361",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-57",
        "3.290",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-57",
        "3.220",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-57",
        "3.154",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-57",
        "3.091",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-57",
        "3.031",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-57",
        "2.972",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-57",
        "2.918",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-57",
        "2.866",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-57",
        "2.817",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-57",
        "2.770",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-57",
        "2.727",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-57",
        "2.687",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-57",
        "2.649",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-57",
        "2.614",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-57",
        "2.582",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-57",
        "2.554",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-57",
        "2.528",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-57",
        "2.506",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-57",
        "2.486",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-57",
        "2.469",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-57",
        "2.456",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-57",
        "2.445",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-57",
        "2.437",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-57",
        "2.432",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-57",
        "2.430",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-57",
        "2.432",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-57",
        "2.436",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-57",
        "2.443",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-57",
        "2.454",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-57",
        "2.467",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-57",
        "2.483",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-57",
        "2.503",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-57",
        "2.524",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-57",
        "2.550",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-57",
        "2.578",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-57",
        "2.610",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-57",
        "2.643",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-57",
        "2.680",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-57",
        "2.720",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-57",
        "2.764",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-57",
        "2.809",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-57",
        "2.858",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-57",
        "2.909",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-57",
        "2.964",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-57",
        "3.022",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-57",
        "3.081",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-57",
        "3.145",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-57",
        "3.210",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-57",
        "3.279",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-57",
        "3.350",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-57",
        "3.424",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-57",
        "3.501",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-57",
        "3.581",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-57",
        "3.662",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-57",
        "3.747",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-57",
        "3.834",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-57",
        "3.924",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-57",
        "4.017",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-57",
        "4.112",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-57",
        "4.209",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-57",
        "4.309",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-57",
        "4.411",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-57",
        "4.516",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-57",
        "4.623",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-57",
        "4.733",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-57",
        "4.844",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-57",
        "4.958",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-57",
        "5.075",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-57",
        "5.193",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-57",
        "5.314",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-57",
        "5.437",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-57",
        "5.561",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-57",
        "5.689",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-57",
        "5.817",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-57",
        "5.948",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-57",
        "6.081",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-57",
        "6.216",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-57",
        "6.353",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-57",
        "6.491",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-57",
        "6.631",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-57",
        "6.773",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-57",
        "6.917",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-57",
        "7.061",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-57",
        "7.209",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-57",
        "7.357",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-57",
        "7.507",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-57",
        "7.658",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-57",
        "7.811",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-57",
        "7.966",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-57",
        "8.121",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-57",
        "8.278",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-57",
        "8.436",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-57",
        "8.595",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-57",
        "8.755",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-57",
        "8.917",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-57",
        "9.079",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-57",
        "9.242",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-57",
        "9.406",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-57",
        "9.571",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-57",
        "9.738",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-57",
        "9.905",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-57",
        "10.073",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-57",
        "10.240",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-57",
        "10.409",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-57",
        "10.579",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-57",
        "10.749",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-57",
        "10.920",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-57",
        "11.090",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-57",
        "11.261",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-57",
        "11.433",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-57",
        "11.604",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-57",
        "11.777",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-57",
        "11.949",
        "-11.769",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-57",
        "11.048",
        "2.903",
        "7.418",
        "10.822",
        "-0.173"
    ],
    [
        "148",
        "-57",
        "3.304",
        "53.256",
        "1.820",
        "3.836",
        "-0.032"
    ],
    [
        "149",
        "-57",
        "2.704",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-57",
        "1.931",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-57",
        "1.163",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-57",
        "0.402",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-57",
        "-0.353",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-57",
        "-1.102",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-57",
        "-1.844",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-57",
        "-2.578",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-57",
        "-3.306",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-57",
        "-14.288",
        "29.531",
        "1.344",
        "2.362",
        "0.351"
    ],
    [
        "159",
        "-57",
        "-19.454",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-57",
        "-19.809",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-57",
        "-20.169",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-57",
        "-20.534",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-57",
        "-20.904",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-57",
        "-21.277",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-57",
        "-21.656",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-57",
        "-22.038",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-57",
        "-22.425",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-57",
        "-22.815",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-57",
        "-23.210",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-57",
        "-23.608",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-57",
        "-24.010",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-57",
        "-24.416",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-57",
        "-24.824",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-57",
        "-25.237",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-57",
        "-25.652",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-57",
        "-26.070",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-57",
        "-26.491",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-57",
        "-26.915",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-57",
        "-27.342",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-56",
        "-28.812",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-56",
        "-29.239",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-56",
        "-29.667",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-56",
        "-30.098",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-56",
        "-30.531",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-56",
        "-30.966",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-56",
        "-31.402",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-56",
        "-31.840",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-56",
        "-32.279",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-56",
        "-32.720",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-56",
        "-33.162",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-56",
        "-33.605",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-56",
        "-34.049",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-56",
        "-34.493",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-56",
        "-34.938",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-56",
        "-35.384",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-56",
        "-35.830",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-56",
        "-36.276",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-56",
        "-36.722",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-56",
        "-37.169",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-56",
        "-37.615",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-56",
        "-38.060",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-56",
        "-38.506",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-56",
        "-38.950",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-56",
        "-39.394",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-56",
        "-39.837",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-56",
        "-40.279",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-56",
        "-40.720",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-56",
        "-41.160",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-56",
        "-41.598",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-56",
        "-42.035",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-56",
        "-42.470",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-56",
        "-42.903",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-56",
        "-43.334",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-56",
        "-43.763",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-56",
        "-25.303",
        "16.585",
        "7.329",
        "8.755",
        "-0.080"
    ],
    [
        "-144",
        "-56",
        "-23.216",
        "17.663",
        "8.328",
        "8.421",
        "-0.117"
    ],
    [
        "-143",
        "-56",
        "-42.195",
        "28.606",
        "1.927",
        "3.229",
        "0.057"
    ],
    [
        "-142",
        "-56",
        "-45.457",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-56",
        "-45.874",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-56",
        "-46.288",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-56",
        "-28.896",
        "20.070",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-56",
        "22.017",
        "-3.262",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-56",
        "22.063",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-56",
        "22.106",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-56",
        "22.146",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-56",
        "22.183",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-56",
        "22.217",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-56",
        "22.248",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-56",
        "22.276",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-56",
        "22.303",
        "-1.655",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-56",
        "22.325",
        "-1.451",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-56",
        "22.345",
        "-1.247",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-56",
        "22.361",
        "-1.043",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-56",
        "22.374",
        "-0.837",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-56",
        "22.385",
        "-0.633",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-56",
        "22.392",
        "-0.427",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-56",
        "22.397",
        "-0.221",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-56",
        "22.705",
        "2.496",
        "6.823",
        "8.848",
        "-0.047"
    ],
    [
        "-121",
        "-56",
        "22.397",
        "0.190",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-56",
        "22.393",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-56",
        "22.386",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-56",
        "22.376",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-56",
        "22.363",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-56",
        "22.347",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-56",
        "22.328",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-56",
        "22.306",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-56",
        "22.281",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-56",
        "22.253",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-56",
        "22.222",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-56",
        "22.189",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-56",
        "22.152",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-56",
        "22.112",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-56",
        "22.070",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-56",
        "22.024",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-56",
        "21.976",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-56",
        "21.925",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-56",
        "21.872",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-56",
        "21.815",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-56",
        "21.755",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-56",
        "21.693",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-56",
        "21.628",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-56",
        "21.560",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-56",
        "21.490",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-56",
        "21.417",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-56",
        "21.341",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-56",
        "21.262",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-56",
        "21.182",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-56",
        "21.097",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-56",
        "21.011",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-56",
        "20.923",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-56",
        "20.832",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-56",
        "20.737",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-56",
        "20.641",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-56",
        "20.542",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-56",
        "20.441",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-56",
        "20.337",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-56",
        "20.232",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-56",
        "20.123",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-56",
        "20.013",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-56",
        "19.900",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-56",
        "19.785",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-56",
        "19.668",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-56",
        "19.549",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-56",
        "19.427",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-56",
        "19.304",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-56",
        "19.179",
        "8.741",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-56",
        "19.051",
        "8.877",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-56",
        "16.672",
        "8.416",
        "10.167",
        "10.403",
        "0.005"
    ],
    [
        "-71",
        "-56",
        "13.577",
        "8.576",
        "8.730",
        "10.344",
        "0.063"
    ],
    [
        "-70",
        "-56",
        "10.211",
        "8.515",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-56",
        "10.085",
        "8.857",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-56",
        "9.955",
        "9.196",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-56",
        "9.819",
        "9.532",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-56",
        "9.679",
        "9.865",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-56",
        "9.533",
        "10.196",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-56",
        "9.384",
        "10.524",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-56",
        "9.229",
        "10.847",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-56",
        "9.070",
        "11.168",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-56",
        "8.906",
        "11.486",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-56",
        "8.737",
        "11.799",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-56",
        "8.565",
        "12.109",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-56",
        "8.387",
        "12.416",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-56",
        "8.205",
        "12.719",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-56",
        "8.019",
        "13.018",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-56",
        "7.828",
        "13.313",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-56",
        "7.633",
        "13.604",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-56",
        "7.435",
        "13.891",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-56",
        "7.232",
        "14.173",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-56",
        "7.025",
        "14.452",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-56",
        "6.814",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-56",
        "6.599",
        "14.994",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-56",
        "6.379",
        "15.259",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-56",
        "6.157",
        "15.520",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-56",
        "5.930",
        "15.775",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-56",
        "5.700",
        "16.026",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-56",
        "5.467",
        "16.271",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-56",
        "5.230",
        "16.512",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-56",
        "4.990",
        "16.748",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-56",
        "4.745",
        "16.978",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-56",
        "4.498",
        "17.204",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-56",
        "4.247",
        "17.424",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-56",
        "3.994",
        "17.639",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-56",
        "3.737",
        "17.848",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-56",
        "3.477",
        "18.052",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-56",
        "3.214",
        "18.251",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-56",
        "2.949",
        "18.444",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-56",
        "2.681",
        "18.631",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-56",
        "2.410",
        "18.813",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-56",
        "2.136",
        "18.989",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-56",
        "2.126",
        "16.771",
        "2.588",
        "3.241",
        "-0.069"
    ],
    [
        "-29",
        "-56",
        "2.068",
        "14.315",
        "2.921",
        "3.093",
        "0.143"
    ],
    [
        "-28",
        "-56",
        "1.548",
        "13.622",
        "3.063",
        "2.636",
        "0.294"
    ],
    [
        "-27",
        "-56",
        "0.682",
        "13.178",
        "3.301",
        "2.351",
        "0.383"
    ],
    [
        "-26",
        "-56",
        "-0.547",
        "12.792",
        "2.914",
        "2.045",
        "0.303"
    ],
    [
        "-25",
        "-56",
        "-1.908",
        "12.535",
        "2.218",
        "1.467",
        "0.191"
    ],
    [
        "-24",
        "-56",
        "-2.766",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-56",
        "-2.945",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-56",
        "-3.125",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-56",
        "-3.303",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-56",
        "-3.482",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-56",
        "-3.660",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-56",
        "-3.838",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-56",
        "-4.014",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-56",
        "-4.191",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-56",
        "-4.367",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-56",
        "-4.542",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-56",
        "-4.716",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-56",
        "-4.889",
        "11.951",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-56",
        "-5.062",
        "11.888",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-56",
        "-5.233",
        "11.822",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-56",
        "-5.404",
        "11.752",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-56",
        "-5.573",
        "11.679",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-56",
        "-5.742",
        "11.602",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-56",
        "-5.909",
        "11.521",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-56",
        "-3.130",
        "10.570",
        "2.189",
        "2.913",
        "0.008"
    ],
    [
        "-4",
        "-56",
        "8.064",
        "10.406",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-56",
        "7.914",
        "10.307",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-56",
        "7.765",
        "10.206",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-56",
        "7.619",
        "10.102",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-56",
        "7.473",
        "9.995",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-56",
        "7.330",
        "9.885",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-56",
        "7.188",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-56",
        "7.047",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-56",
        "6.907",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-56",
        "6.771",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-56",
        "6.636",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-56",
        "6.501",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-56",
        "6.370",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-56",
        "6.241",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-56",
        "6.112",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-56",
        "5.987",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-56",
        "5.863",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-56",
        "5.741",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-56",
        "5.622",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-56",
        "5.504",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-56",
        "5.389",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-56",
        "5.276",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-56",
        "5.166",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-56",
        "5.057",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-56",
        "4.951",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-56",
        "4.847",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-56",
        "4.745",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-56",
        "4.646",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-56",
        "4.549",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-56",
        "4.455",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-56",
        "4.364",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-56",
        "4.274",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-56",
        "4.187",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-56",
        "4.103",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-56",
        "4.022",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-56",
        "3.943",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-56",
        "3.866",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-56",
        "3.793",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-56",
        "3.723",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-56",
        "3.654",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-56",
        "3.588",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-56",
        "3.525",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-56",
        "3.466",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-56",
        "3.409",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-56",
        "3.355",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-56",
        "3.303",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-56",
        "3.255",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-56",
        "3.209",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-56",
        "3.166",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-56",
        "3.126",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-56",
        "3.089",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-56",
        "3.054",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-56",
        "3.023",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-56",
        "2.995",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-56",
        "2.970",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-56",
        "2.947",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-56",
        "2.928",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-56",
        "2.911",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-56",
        "2.897",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-56",
        "2.887",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-56",
        "2.879",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-56",
        "2.874",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-56",
        "2.873",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-56",
        "2.874",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-56",
        "2.878",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-56",
        "2.885",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-56",
        "2.896",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-56",
        "2.909",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-56",
        "2.925",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-56",
        "2.944",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-56",
        "2.966",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-56",
        "2.991",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-56",
        "3.019",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-56",
        "3.049",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-56",
        "3.084",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-56",
        "3.120",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-56",
        "3.160",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-56",
        "3.202",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-56",
        "3.247",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-56",
        "3.295",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-56",
        "3.346",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-56",
        "3.401",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-56",
        "3.457",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-56",
        "3.516",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-56",
        "3.578",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-56",
        "3.644",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-56",
        "3.711",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-56",
        "3.782",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-56",
        "3.855",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-56",
        "3.930",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-56",
        "4.009",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-56",
        "4.091",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-56",
        "4.174",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-56",
        "4.260",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-56",
        "4.350",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-56",
        "4.441",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-56",
        "4.535",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-56",
        "4.631",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-56",
        "4.729",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-56",
        "4.830",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-56",
        "4.934",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-56",
        "5.040",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-56",
        "5.148",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-56",
        "5.259",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-56",
        "5.371",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-56",
        "5.486",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-56",
        "5.604",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-56",
        "5.723",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-56",
        "5.844",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-56",
        "5.967",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-56",
        "6.094",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-56",
        "6.221",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-56",
        "6.350",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-56",
        "6.482",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-56",
        "6.615",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-56",
        "6.750",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-56",
        "6.887",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-56",
        "7.026",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-56",
        "7.166",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-56",
        "7.307",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-56",
        "7.451",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-56",
        "7.596",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-56",
        "7.743",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-56",
        "7.891",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-56",
        "8.041",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-56",
        "8.191",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-56",
        "8.344",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-56",
        "8.498",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-56",
        "8.653",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-56",
        "8.809",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-56",
        "8.966",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-56",
        "9.124",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-56",
        "9.284",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-56",
        "9.445",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-56",
        "9.607",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-56",
        "9.769",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-56",
        "9.932",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-56",
        "10.096",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-56",
        "10.261",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-56",
        "10.427",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-56",
        "10.593",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-56",
        "10.760",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-56",
        "10.928",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-56",
        "11.096",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-56",
        "11.264",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-56",
        "11.433",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-56",
        "11.602",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-56",
        "11.772",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-56",
        "11.942",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-56",
        "12.112",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-56",
        "12.103",
        "-11.344",
        "1.879",
        "4.193",
        "-0.080"
    ],
    [
        "147",
        "-56",
        "4.946",
        "42.309",
        "7.450",
        "10.120",
        "-0.134"
    ],
    [
        "148",
        "-56",
        "4.252",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-56",
        "3.482",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-56",
        "2.718",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-56",
        "1.959",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-56",
        "1.207",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-56",
        "0.460",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-56",
        "-0.280",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-56",
        "-1.013",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-56",
        "-1.740",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-56",
        "-2.458",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-56",
        "-5.217",
        "45.624",
        "0.460",
        "0.885",
        "0.249"
    ],
    [
        "159",
        "-56",
        "-20.590",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-56",
        "-20.941",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-56",
        "-21.297",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-56",
        "-21.658",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-56",
        "-22.023",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-56",
        "-22.393",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-56",
        "-22.767",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-56",
        "-23.145",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-56",
        "-23.527",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-56",
        "-23.913",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-56",
        "-24.303",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-56",
        "-24.697",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-56",
        "-25.094",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-56",
        "-25.495",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-56",
        "-25.899",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-56",
        "-26.306",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-56",
        "-26.717",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-56",
        "-27.131",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-56",
        "-27.547",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-56",
        "-27.966",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-56",
        "-28.388",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-55",
        "-29.844",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-55",
        "-30.265",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-55",
        "-30.689",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-55",
        "-31.115",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-55",
        "-31.543",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-55",
        "-31.972",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-55",
        "-32.403",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-55",
        "-32.836",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-55",
        "-33.270",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-55",
        "-33.706",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-55",
        "-34.142",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-55",
        "-34.580",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-55",
        "-35.018",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-55",
        "-35.457",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-55",
        "-35.897",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-55",
        "-36.338",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-55",
        "-36.778",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-55",
        "-37.219",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-55",
        "-37.660",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-55",
        "-38.101",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-55",
        "-38.542",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-55",
        "-38.982",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-55",
        "-39.422",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-55",
        "-39.861",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-55",
        "-40.300",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-55",
        "-40.738",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-55",
        "-41.174",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-55",
        "-41.610",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-55",
        "-42.044",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-55",
        "-42.477",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-55",
        "-42.909",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-55",
        "-43.339",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-55",
        "-43.767",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-55",
        "-44.193",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-55",
        "-44.617",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-55",
        "-45.039",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-55",
        "-45.458",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-55",
        "-45.876",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-55",
        "-46.290",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-55",
        "-46.702",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-55",
        "-47.111",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-55",
        "-47.517",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-55",
        "-47.921",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-55",
        "22.276",
        "-3.063",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-55",
        "22.318",
        "-2.864",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-55",
        "22.358",
        "-2.664",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-55",
        "22.394",
        "-2.464",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-55",
        "22.428",
        "-2.262",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-55",
        "22.459",
        "-2.061",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-55",
        "22.486",
        "-1.858",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-55",
        "18.025",
        "-1.941",
        "1.797",
        "3.238",
        "0.087"
    ],
    [
        "-129",
        "-55",
        "-27.134",
        "17.123",
        "7.251",
        "8.276",
        "0.053"
    ],
    [
        "-128",
        "-55",
        "-51.757",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-55",
        "-46.317",
        "21.930",
        "7.630",
        "8.402",
        "-0.137"
    ],
    [
        "-126",
        "-55",
        "-52.048",
        "25.261",
        "7.962",
        "8.936",
        "-0.017"
    ],
    [
        "-125",
        "-55",
        "-52.828",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-55",
        "-53.176",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-55",
        "-53.519",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-55",
        "-53.858",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-55",
        "-0.139",
        "-6.026",
        "4.798",
        "7.493",
        "0.081"
    ],
    [
        "-120",
        "-55",
        "22.602",
        "0.395",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-55",
        "22.595",
        "0.601",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-55",
        "22.585",
        "0.805",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-55",
        "22.572",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-55",
        "22.556",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-55",
        "22.537",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-55",
        "22.515",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-55",
        "22.491",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-55",
        "22.464",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-55",
        "22.432",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-55",
        "22.399",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-55",
        "22.364",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-55",
        "22.324",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-55",
        "22.282",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-55",
        "22.237",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-55",
        "22.190",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-55",
        "22.139",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-55",
        "22.086",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-55",
        "22.030",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-55",
        "21.972",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-55",
        "21.910",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-55",
        "21.845",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-55",
        "21.779",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-55",
        "21.710",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-55",
        "21.637",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-55",
        "21.562",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-55",
        "21.485",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-55",
        "21.404",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-55",
        "21.322",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-55",
        "21.236",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-55",
        "21.148",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-55",
        "21.059",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-55",
        "20.966",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-55",
        "20.871",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-55",
        "20.772",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-55",
        "20.673",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-55",
        "20.570",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-55",
        "20.466",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-55",
        "20.359",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-55",
        "20.249",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-55",
        "20.138",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-55",
        "20.025",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-55",
        "19.909",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-55",
        "19.791",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-55",
        "19.672",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-55",
        "19.550",
        "8.602",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-55",
        "17.952",
        "8.216",
        "8.612",
        "9.294",
        "-0.044"
    ],
    [
        "-73",
        "-55",
        "14.430",
        "7.859",
        "10.911",
        "12.947",
        "-0.057"
    ],
    [
        "-72",
        "-55",
        "10.094",
        "7.824",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-55",
        "9.980",
        "8.171",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-55",
        "9.860",
        "8.515",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-55",
        "9.736",
        "8.857",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-55",
        "9.607",
        "9.196",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-55",
        "9.474",
        "9.532",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-55",
        "9.334",
        "9.865",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-55",
        "9.191",
        "10.196",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-55",
        "9.043",
        "10.524",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-55",
        "8.890",
        "10.847",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-55",
        "8.733",
        "11.168",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-55",
        "8.571",
        "11.486",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-55",
        "8.404",
        "11.799",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-55",
        "8.234",
        "12.109",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-55",
        "8.058",
        "12.416",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-55",
        "7.878",
        "12.719",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-55",
        "7.694",
        "13.018",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-55",
        "7.506",
        "13.313",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-55",
        "7.314",
        "13.604",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-55",
        "7.117",
        "13.891",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-55",
        "6.917",
        "14.173",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-55",
        "6.712",
        "14.452",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-55",
        "6.503",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-55",
        "6.291",
        "14.994",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-55",
        "6.074",
        "15.259",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-55",
        "5.855",
        "15.520",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-55",
        "5.631",
        "15.775",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-55",
        "5.403",
        "16.026",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-55",
        "5.173",
        "16.271",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-55",
        "4.939",
        "16.512",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-55",
        "4.701",
        "16.748",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-55",
        "4.459",
        "16.978",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-55",
        "4.215",
        "17.204",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-55",
        "3.968",
        "17.424",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-55",
        "3.717",
        "17.639",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-55",
        "3.463",
        "17.848",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-55",
        "3.207",
        "18.052",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-55",
        "2.948",
        "18.251",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-55",
        "2.685",
        "18.444",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-55",
        "2.419",
        "18.631",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-55",
        "0.323",
        "15.858",
        "6.108",
        "7.235",
        "0.008"
    ],
    [
        "-31",
        "-55",
        "-0.370",
        "15.729",
        "4.993",
        "5.256",
        "0.043"
    ],
    [
        "-30",
        "-55",
        "0.082",
        "14.033",
        "3.874",
        "3.660",
        "0.080"
    ],
    [
        "-29",
        "-55",
        "0.161",
        "13.253",
        "3.461",
        "2.754",
        "0.300"
    ],
    [
        "-28",
        "-55",
        "-0.375",
        "12.975",
        "3.406",
        "2.478",
        "0.361"
    ],
    [
        "-27",
        "-55",
        "-1.249",
        "12.720",
        "3.508",
        "2.274",
        "0.428"
    ],
    [
        "-26",
        "-55",
        "-2.086",
        "12.599",
        "2.835",
        "2.125",
        "0.363"
    ],
    [
        "-25",
        "-55",
        "-2.631",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-55",
        "-2.808",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-55",
        "-2.986",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-55",
        "-3.163",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-55",
        "-3.339",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-55",
        "-3.516",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-55",
        "-3.692",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-55",
        "-3.867",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-55",
        "-4.042",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-55",
        "-4.216",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-55",
        "-4.390",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-55",
        "-4.563",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-55",
        "-4.734",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-55",
        "-4.906",
        "11.951",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-55",
        "-5.076",
        "11.888",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-55",
        "-5.246",
        "11.822",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-55",
        "-5.415",
        "11.752",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-55",
        "-5.582",
        "11.679",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-55",
        "-5.749",
        "11.602",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-55",
        "-5.914",
        "11.521",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-55",
        "-6.078",
        "11.437",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-55",
        "-6.241",
        "11.351",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-55",
        "-6.402",
        "11.259",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-55",
        "-6.563",
        "11.165",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-55",
        "9.405",
        "16.837",
        "3.762",
        "3.086",
        "0.406"
    ],
    [
        "0",
        "-55",
        "16.564",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-55",
        "9.940",
        "12.334",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-55",
        "7.577",
        "9.772",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-55",
        "7.439",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-55",
        "7.301",
        "9.536",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-55",
        "7.166",
        "9.414",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-55",
        "7.032",
        "9.290",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-55",
        "6.900",
        "9.162",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-55",
        "6.770",
        "9.032",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-55",
        "6.642",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-55",
        "6.516",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-55",
        "6.391",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-55",
        "6.269",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-55",
        "6.149",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-55",
        "6.031",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-55",
        "5.914",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-55",
        "5.801",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-55",
        "5.689",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-55",
        "5.580",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-55",
        "5.473",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-55",
        "5.367",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-55",
        "5.265",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-55",
        "5.165",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-55",
        "5.066",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-55",
        "4.971",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-55",
        "4.877",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-55",
        "4.787",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-55",
        "4.699",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-55",
        "4.613",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-55",
        "4.530",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-55",
        "4.450",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-55",
        "4.371",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-55",
        "4.296",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-55",
        "4.224",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-55",
        "4.154",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-55",
        "4.086",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-55",
        "4.021",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-55",
        "3.959",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-55",
        "3.901",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-55",
        "3.844",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-55",
        "3.790",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-55",
        "3.739",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-55",
        "3.691",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-55",
        "3.646",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-55",
        "3.604",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-55",
        "3.564",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-55",
        "3.528",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-55",
        "3.494",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-55",
        "3.463",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-55",
        "3.435",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-55",
        "3.409",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-55",
        "3.388",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-55",
        "3.369",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-55",
        "3.352",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-55",
        "3.338",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-55",
        "3.328",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-55",
        "3.321",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-55",
        "3.316",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-55",
        "3.314",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-55",
        "3.316",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-55",
        "3.320",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-55",
        "3.327",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-55",
        "3.336",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-55",
        "3.350",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-55",
        "3.365",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-55",
        "3.385",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-55",
        "3.406",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-55",
        "3.431",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-55",
        "3.458",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-55",
        "3.489",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-55",
        "3.522",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-55",
        "3.558",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-55",
        "3.598",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-55",
        "3.639",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-55",
        "3.685",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-55",
        "3.732",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-55",
        "3.783",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-55",
        "3.835",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-55",
        "3.891",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-55",
        "3.950",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-55",
        "4.011",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-55",
        "4.076",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-55",
        "4.143",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-55",
        "4.212",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-55",
        "4.285",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-55",
        "4.360",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-55",
        "4.437",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-55",
        "4.518",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-55",
        "4.600",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-55",
        "4.685",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-55",
        "4.773",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-55",
        "4.864",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-55",
        "4.956",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-55",
        "5.051",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-55",
        "5.149",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-55",
        "5.249",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-55",
        "5.351",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-55",
        "5.456",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-55",
        "5.562",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-55",
        "5.672",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-55",
        "5.783",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-55",
        "5.897",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-55",
        "6.012",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-55",
        "6.131",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-55",
        "6.250",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-55",
        "6.372",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-55",
        "6.496",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-55",
        "6.622",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-55",
        "6.750",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-55",
        "6.880",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-55",
        "7.011",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-55",
        "7.145",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-55",
        "7.280",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-55",
        "7.417",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-55",
        "7.556",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-55",
        "7.696",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-55",
        "7.838",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-55",
        "7.982",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-55",
        "8.126",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-55",
        "8.272",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-55",
        "8.420",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-55",
        "8.570",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-55",
        "8.720",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-55",
        "8.872",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-55",
        "9.026",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-55",
        "9.179",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-55",
        "9.335",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-55",
        "9.492",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-55",
        "9.650",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-55",
        "9.808",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-55",
        "9.968",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-55",
        "10.128",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-55",
        "10.289",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-55",
        "10.452",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-55",
        "10.614",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-55",
        "10.778",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-55",
        "10.942",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-55",
        "11.108",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-55",
        "11.273",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-55",
        "11.438",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-55",
        "11.605",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-55",
        "11.772",
        "-11.686",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-55",
        "11.940",
        "-11.711",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-55",
        "12.107",
        "-11.730",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-55",
        "12.275",
        "-11.747",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-55",
        "12.443",
        "-11.760",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-55",
        "10.472",
        "1.184",
        "1.777",
        "3.992",
        "-0.154"
    ],
    [
        "147",
        "-55",
        "5.786",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-55",
        "5.020",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-55",
        "4.259",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-55",
        "3.504",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-55",
        "2.754",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-55",
        "2.010",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-55",
        "1.273",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-55",
        "0.542",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-55",
        "-0.183",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-55",
        "-0.900",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-55",
        "-1.611",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-55",
        "-3.480",
        "48.175",
        "0.464",
        "0.805",
        "0.111"
    ],
    [
        "159",
        "-55",
        "-16.609",
        "28.375",
        "2.139",
        "2.272",
        "0.079"
    ],
    [
        "160",
        "-55",
        "-21.448",
        "23.939",
        "0.479",
        "0.855",
        "0.114"
    ],
    [
        "161",
        "-55",
        "-22.419",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-55",
        "-22.775",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-55",
        "-23.136",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-55",
        "-23.501",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-55",
        "-23.871",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-55",
        "-24.244",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-55",
        "-24.622",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-55",
        "-25.004",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-55",
        "-25.389",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-55",
        "-25.778",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-55",
        "-26.170",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-55",
        "-26.566",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-55",
        "-26.966",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-55",
        "-27.368",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-55",
        "-27.774",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-55",
        "-28.183",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-55",
        "-28.594",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-55",
        "-29.008",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-55",
        "-29.425",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-54",
        "-30.867",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-54",
        "-31.283",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-54",
        "-31.702",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-54",
        "-32.122",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-54",
        "-32.545",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-54",
        "-32.969",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-54",
        "-33.395",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-54",
        "-33.822",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-54",
        "-34.251",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-54",
        "-34.681",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-54",
        "-35.112",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-54",
        "-35.544",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-54",
        "-35.977",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-54",
        "-36.411",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-54",
        "-36.845",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-54",
        "-37.280",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-54",
        "-37.715",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-54",
        "-38.151",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-54",
        "-38.586",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-54",
        "-39.022",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-54",
        "-39.457",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-54",
        "-39.892",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-54",
        "-40.326",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-54",
        "-40.760",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-54",
        "-41.193",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-54",
        "-41.626",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-54",
        "-42.057",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-54",
        "-42.487",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-54",
        "-42.916",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-54",
        "-43.344",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-54",
        "-43.770",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-54",
        "-44.195",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-54",
        "-44.617",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-54",
        "-45.038",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-54",
        "-45.457",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-54",
        "-45.874",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-54",
        "-46.288",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-54",
        "-46.700",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-54",
        "-47.110",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-54",
        "-47.516",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-54",
        "-47.921",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-54",
        "-48.322",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-54",
        "-48.720",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-54",
        "-49.115",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-54",
        "-49.507",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-54",
        "-49.895",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-54",
        "-42.488",
        "23.619",
        "6.978",
        "8.228",
        "-0.097"
    ],
    [
        "-133",
        "-54",
        "-50.661",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-54",
        "-51.038",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-54",
        "-51.412",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-54",
        "-51.782",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-54",
        "-52.147",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-54",
        "-52.509",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-54",
        "-52.866",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-54",
        "-53.219",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-54",
        "-53.567",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-54",
        "-53.911",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-54",
        "-54.249",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-54",
        "-54.583",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-54",
        "-54.913",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-54",
        "-55.237",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-54",
        "-55.556",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-54",
        "-35.477",
        "16.530",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-54",
        "22.774",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-54",
        "22.758",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-54",
        "22.740",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-54",
        "22.719",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-54",
        "22.694",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-54",
        "22.667",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-54",
        "22.636",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-54",
        "22.604",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-54",
        "22.568",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-54",
        "22.530",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-54",
        "22.488",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-54",
        "22.444",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-54",
        "22.396",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-54",
        "22.347",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-54",
        "22.294",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-54",
        "22.239",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-54",
        "22.181",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-54",
        "22.121",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-54",
        "22.057",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-54",
        "21.991",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-54",
        "21.922",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-54",
        "21.851",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-54",
        "21.777",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-54",
        "21.700",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-54",
        "21.621",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-54",
        "21.540",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-54",
        "21.455",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-54",
        "21.368",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-54",
        "21.279",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-54",
        "21.187",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-54",
        "21.094",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-54",
        "20.997",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-54",
        "20.898",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-54",
        "20.798",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-54",
        "20.694",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-54",
        "20.589",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-54",
        "20.481",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-54",
        "20.371",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-54",
        "20.259",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-54",
        "20.144",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-54",
        "20.028",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-54",
        "19.909",
        "8.460",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-54",
        "17.576",
        "7.743",
        "11.293",
        "10.505",
        "-0.080"
    ],
    [
        "-74",
        "-54",
        "14.403",
        "7.135",
        "11.417",
        "13.003",
        "-0.105"
    ],
    [
        "-73",
        "-54",
        "11.744",
        "7.089",
        "10.826",
        "12.584",
        "-0.029"
    ],
    [
        "-72",
        "-54",
        "10.469",
        "8.942",
        "10.241",
        "11.465",
        "-0.030"
    ],
    [
        "-71",
        "-54",
        "13.876",
        "10.600",
        "8.661",
        "10.449",
        "-0.142"
    ],
    [
        "-70",
        "-54",
        "4.600",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-54",
        "4.468",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-54",
        "4.334",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-54",
        "4.198",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-54",
        "4.060",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-54",
        "3.920",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-54",
        "3.778",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-54",
        "3.635",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-54",
        "3.490",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-54",
        "3.343",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-54",
        "3.194",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-54",
        "3.044",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-54",
        "2.892",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-54",
        "2.739",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-54",
        "2.584",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-54",
        "2.741",
        "11.345",
        "0.958",
        "1.488",
        "0.005"
    ],
    [
        "-54",
        "-54",
        "5.156",
        "12.720",
        "3.895",
        "3.236",
        "0.069"
    ],
    [
        "-53",
        "-54",
        "6.676",
        "13.858",
        "0.751",
        "1.320",
        "-0.088"
    ],
    [
        "-52",
        "-54",
        "6.599",
        "14.173",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-54",
        "6.397",
        "14.452",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-54",
        "6.191",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-54",
        "5.981",
        "14.994",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-54",
        "5.767",
        "15.259",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-54",
        "5.550",
        "15.520",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-54",
        "5.329",
        "15.775",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-54",
        "5.105",
        "16.026",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-54",
        "4.877",
        "16.271",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-54",
        "4.646",
        "16.512",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-54",
        "4.411",
        "16.748",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-54",
        "4.172",
        "16.978",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-54",
        "3.931",
        "17.204",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-54",
        "3.686",
        "17.424",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-54",
        "3.439",
        "17.639",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-54",
        "3.188",
        "17.848",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-54",
        "2.935",
        "18.052",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-54",
        "-0.588",
        "12.700",
        "2.089",
        "2.878",
        "0.009"
    ],
    [
        "-34",
        "-54",
        "-1.095",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-54",
        "-1.270",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-54",
        "-1.445",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-54",
        "-1.621",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-54",
        "-1.796",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-54",
        "-1.972",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-54",
        "-2.147",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-54",
        "-2.324",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-54",
        "-2.499",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-54",
        "-2.675",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-54",
        "-2.850",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-54",
        "-3.026",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-54",
        "-3.201",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-54",
        "-3.375",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-54",
        "-3.549",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-54",
        "-3.722",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-54",
        "-3.896",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-54",
        "-4.068",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-54",
        "-4.240",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-54",
        "-4.412",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-54",
        "-4.583",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-54",
        "-4.753",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-54",
        "-4.922",
        "11.951",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-54",
        "-5.090",
        "11.888",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-54",
        "-5.257",
        "11.822",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-54",
        "-5.423",
        "11.752",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-54",
        "-5.589",
        "11.679",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-54",
        "-5.754",
        "11.602",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-54",
        "-5.917",
        "11.521",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-54",
        "-6.079",
        "11.437",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-54",
        "-6.240",
        "11.351",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-54",
        "-6.400",
        "11.259",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-54",
        "2.862",
        "14.396",
        "3.972",
        "3.361",
        "0.353"
    ],
    [
        "-1",
        "-54",
        "17.146",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-54",
        "16.874",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-54",
        "16.601",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-54",
        "14.159",
        "16.888",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-54",
        "7.828",
        "9.655",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-54",
        "13.682",
        "16.885",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-54",
        "15.504",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-54",
        "15.229",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-54",
        "12.030",
        "15.773",
        "2.291",
        "3.651",
        "0.205"
    ],
    [
        "8",
        "-54",
        "8.685",
        "10.819",
        "1.638",
        "2.424",
        "0.114"
    ],
    [
        "9",
        "-54",
        "7.041",
        "8.898",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-54",
        "6.917",
        "8.762",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-54",
        "6.794",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-54",
        "6.673",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-54",
        "6.555",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-54",
        "6.437",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-54",
        "6.323",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-54",
        "6.210",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-54",
        "6.099",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-54",
        "5.992",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-54",
        "5.885",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-54",
        "5.782",
        "7.263",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-54",
        "5.681",
        "7.100",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-54",
        "5.582",
        "6.935",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-54",
        "5.485",
        "6.768",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-54",
        "5.390",
        "6.599",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-54",
        "5.299",
        "6.427",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-54",
        "5.209",
        "6.254",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-54",
        "5.122",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-54",
        "5.037",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-54",
        "4.956",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-54",
        "4.876",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-54",
        "4.798",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-54",
        "4.724",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-54",
        "4.653",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-54",
        "4.584",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-54",
        "4.516",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-54",
        "4.453",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-54",
        "4.392",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-54",
        "4.333",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-54",
        "4.278",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-54",
        "4.225",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-54",
        "4.174",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-54",
        "4.128",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-54",
        "4.082",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-54",
        "4.040",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-54",
        "4.002",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-54",
        "3.965",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-54",
        "3.932",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-54",
        "3.901",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-54",
        "3.874",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-54",
        "3.850",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-54",
        "3.827",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-54",
        "3.809",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-54",
        "3.792",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-54",
        "3.778",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-54",
        "3.769",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-54",
        "3.761",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-54",
        "3.756",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-54",
        "3.754",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-54",
        "3.755",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-54",
        "3.759",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-54",
        "3.767",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-54",
        "3.777",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-54",
        "3.790",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-54",
        "3.806",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-54",
        "3.824",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-54",
        "3.845",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-54",
        "3.870",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-54",
        "3.897",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-54",
        "3.927",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-54",
        "3.960",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-54",
        "3.996",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-54",
        "4.034",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-54",
        "4.076",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-54",
        "4.120",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-54",
        "4.167",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-54",
        "4.217",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-54",
        "4.270",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-54",
        "4.325",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-54",
        "4.383",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-54",
        "4.444",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-54",
        "4.507",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-54",
        "4.573",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-54",
        "4.641",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-54",
        "4.713",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-54",
        "4.787",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-54",
        "4.864",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-54",
        "4.942",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-54",
        "5.024",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-54",
        "5.109",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-54",
        "5.196",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-54",
        "5.285",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-54",
        "5.376",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-54",
        "5.470",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-54",
        "5.567",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-54",
        "5.665",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-54",
        "5.767",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-54",
        "5.869",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-54",
        "5.975",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-54",
        "6.083",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-54",
        "6.193",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-54",
        "6.306",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-54",
        "6.420",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-54",
        "6.536",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-54",
        "6.655",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-54",
        "6.775",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-54",
        "6.897",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-54",
        "7.021",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-54",
        "7.148",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-54",
        "7.276",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-54",
        "7.407",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-54",
        "7.538",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-54",
        "7.672",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-54",
        "7.806",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-54",
        "7.944",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-54",
        "8.082",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-54",
        "8.222",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-54",
        "8.364",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-54",
        "8.507",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-54",
        "8.652",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-54",
        "8.798",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-54",
        "8.945",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-54",
        "9.094",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-54",
        "9.244",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-54",
        "9.395",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-54",
        "9.548",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-54",
        "9.701",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-54",
        "9.856",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-54",
        "10.011",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-54",
        "10.168",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-54",
        "10.325",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-54",
        "10.484",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-54",
        "10.643",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-54",
        "10.804",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-54",
        "10.965",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-54",
        "11.126",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-54",
        "11.289",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-54",
        "11.451",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-54",
        "11.615",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-54",
        "11.779",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-54",
        "11.943",
        "-11.659",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-54",
        "12.083",
        "15.986",
        "6.565",
        "9.010",
        "-0.033"
    ],
    [
        "142",
        "-54",
        "10.396",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-54",
        "9.617",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-54",
        "8.842",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-54",
        "8.071",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-54",
        "7.305",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-54",
        "6.543",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-54",
        "5.786",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-54",
        "5.035",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-54",
        "4.289",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-54",
        "3.548",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-54",
        "2.814",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-54",
        "2.086",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-54",
        "1.363",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-54",
        "0.493",
        "50.428",
        "0.672",
        "0.768",
        "0.020"
    ],
    [
        "156",
        "-54",
        "-0.655",
        "49.907",
        "1.130",
        "1.149",
        "0.029"
    ],
    [
        "157",
        "-54",
        "-2.290",
        "49.074",
        "1.590",
        "1.718",
        "0.025"
    ],
    [
        "158",
        "-54",
        "-5.218",
        "46.609",
        "2.003",
        "2.355",
        "0.112"
    ],
    [
        "159",
        "-54",
        "-15.038",
        "36.890",
        "2.114",
        "2.356",
        "0.198"
    ],
    [
        "160",
        "-54",
        "-23.186",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-54",
        "-23.534",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-54",
        "-23.886",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-54",
        "-24.242",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-54",
        "-24.603",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-54",
        "-24.968",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-54",
        "-25.337",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-54",
        "-25.710",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-54",
        "-26.086",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-54",
        "-26.467",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-54",
        "-26.851",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-54",
        "-27.239",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-54",
        "-27.630",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-54",
        "-28.024",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-54",
        "-28.422",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-54",
        "-28.823",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-54",
        "-29.226",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-54",
        "-29.632",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-54",
        "-30.041",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-54",
        "-30.453",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-53",
        "-31.880",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-53",
        "-32.291",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-53",
        "-32.704",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-53",
        "-33.120",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-53",
        "-33.537",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-53",
        "-33.955",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-53",
        "-34.376",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-53",
        "-34.798",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-53",
        "-35.221",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-53",
        "-35.645",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-53",
        "-36.071",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-53",
        "-36.498",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-53",
        "-36.925",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-53",
        "-37.353",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-53",
        "-37.782",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-53",
        "-38.211",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-53",
        "-38.641",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-53",
        "-39.071",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-53",
        "-39.501",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-53",
        "-39.931",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-53",
        "-40.360",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-53",
        "-40.790",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-53",
        "-41.219",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-53",
        "-41.647",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-53",
        "-42.075",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-53",
        "-42.501",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-53",
        "-42.927",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-53",
        "-43.352",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-53",
        "-43.775",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-53",
        "-44.197",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-53",
        "-44.618",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-53",
        "-45.037",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-53",
        "-45.455",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-53",
        "-45.870",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-53",
        "-46.283",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-53",
        "-46.695",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-53",
        "-47.104",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-53",
        "-47.511",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-53",
        "-47.915",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-53",
        "-48.316",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-53",
        "-48.715",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-53",
        "-49.111",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-53",
        "-49.504",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-53",
        "-49.894",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-53",
        "-50.281",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-53",
        "-50.664",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-53",
        "-51.044",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-53",
        "-51.420",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-53",
        "-51.793",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-53",
        "-52.162",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-53",
        "-52.527",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-53",
        "-52.888",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-53",
        "-53.245",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-53",
        "-53.597",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-53",
        "-53.945",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-53",
        "-54.289",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-53",
        "-54.628",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-53",
        "-54.963",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-53",
        "-55.293",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-53",
        "-55.618",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-53",
        "-55.938",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-53",
        "-56.252",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-53",
        "-4.035",
        "13.958",
        "6.441",
        "8.756",
        "0.033"
    ],
    [
        "-117",
        "-53",
        "22.969",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-53",
        "22.954",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-53",
        "22.936",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-53",
        "22.914",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-53",
        "22.890",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-53",
        "22.863",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-53",
        "22.834",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-53",
        "22.802",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-53",
        "22.765",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-53",
        "22.728",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-53",
        "22.687",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-53",
        "22.643",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-53",
        "22.597",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-53",
        "22.548",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-53",
        "22.496",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-53",
        "22.441",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-53",
        "22.384",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-53",
        "22.324",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-53",
        "22.261",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-53",
        "22.196",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-53",
        "22.129",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-53",
        "22.058",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-53",
        "21.985",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-53",
        "21.909",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-53",
        "21.831",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-53",
        "21.751",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-53",
        "21.667",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-53",
        "21.581",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-53",
        "21.494",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-53",
        "21.403",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-53",
        "21.310",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-53",
        "21.215",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-53",
        "21.118",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-53",
        "21.018",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-53",
        "20.916",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-53",
        "20.812",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-53",
        "20.705",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-53",
        "20.596",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-53",
        "20.486",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-53",
        "20.373",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-53",
        "20.259",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-53",
        "19.782",
        "8.395",
        "2.226",
        "3.056",
        "-0.147"
    ],
    [
        "-75",
        "-53",
        "16.533",
        "7.559",
        "10.054",
        "9.995",
        "-0.186"
    ],
    [
        "-74",
        "-53",
        "12.789",
        "6.724",
        "9.961",
        "11.508",
        "-0.175"
    ],
    [
        "-73",
        "-53",
        "9.416",
        "6.901",
        "9.069",
        "10.947",
        "-0.179"
    ],
    [
        "-72",
        "-53",
        "6.311",
        "8.413",
        "7.430",
        "8.187",
        "-0.168"
    ],
    [
        "-71",
        "-53",
        "4.592",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-53",
        "4.464",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-53",
        "4.334",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-53",
        "4.201",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-53",
        "4.067",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-53",
        "3.931",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-53",
        "3.792",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-53",
        "3.652",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-53",
        "3.511",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-53",
        "3.368",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-53",
        "3.222",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-53",
        "3.076",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-53",
        "2.928",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-53",
        "2.778",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-53",
        "2.627",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-53",
        "2.474",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-53",
        "2.320",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-53",
        "2.164",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-53",
        "2.136",
        "11.711",
        "3.212",
        "3.134",
        "0.085"
    ],
    [
        "-52",
        "-53",
        "2.502",
        "12.008",
        "3.919",
        "3.237",
        "0.277"
    ],
    [
        "-51",
        "-53",
        "3.180",
        "12.700",
        "4.413",
        "3.383",
        "0.265"
    ],
    [
        "-50",
        "-53",
        "3.980",
        "13.552",
        "4.371",
        "3.758",
        "0.161"
    ],
    [
        "-49",
        "-53",
        "4.619",
        "13.967",
        "4.413",
        "3.768",
        "0.090"
    ],
    [
        "-48",
        "-53",
        "4.893",
        "14.087",
        "4.589",
        "3.346",
        "0.049"
    ],
    [
        "-47",
        "-53",
        "4.857",
        "14.199",
        "4.620",
        "3.202",
        "0.010"
    ],
    [
        "-46",
        "-53",
        "4.576",
        "14.396",
        "4.941",
        "3.752",
        "-0.053"
    ],
    [
        "-45",
        "-53",
        "4.113",
        "14.642",
        "5.942",
        "5.312",
        "-0.010"
    ],
    [
        "-44",
        "-53",
        "3.557",
        "14.839",
        "6.831",
        "7.223",
        "-0.019"
    ],
    [
        "-43",
        "-53",
        "2.977",
        "14.794",
        "7.913",
        "8.860",
        "0.025"
    ],
    [
        "-42",
        "-53",
        "2.401",
        "14.572",
        "9.500",
        "9.603",
        "-0.006"
    ],
    [
        "-41",
        "-53",
        "1.845",
        "14.446",
        "9.116",
        "10.077",
        "-0.008"
    ],
    [
        "-40",
        "-53",
        "1.282",
        "14.170",
        "8.590",
        "9.638",
        "0.014"
    ],
    [
        "-39",
        "-53",
        "0.817",
        "13.985",
        "8.869",
        "9.136",
        "-0.002"
    ],
    [
        "-38",
        "-53",
        "0.446",
        "13.961",
        "8.002",
        "9.410",
        "0.008"
    ],
    [
        "-37",
        "-53",
        "-0.233",
        "13.103",
        "5.980",
        "6.529",
        "0.001"
    ],
    [
        "-36",
        "-53",
        "-0.814",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-53",
        "-0.986",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-53",
        "-1.158",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-53",
        "-1.331",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-53",
        "-1.504",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-53",
        "-1.677",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-53",
        "-1.850",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-53",
        "-2.024",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-53",
        "-2.197",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-53",
        "-2.371",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-53",
        "-2.544",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-53",
        "-2.718",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-53",
        "-2.891",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-53",
        "-3.064",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-53",
        "-3.236",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-53",
        "-3.409",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-53",
        "-3.580",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-53",
        "-3.752",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-53",
        "-3.923",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-53",
        "-4.093",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-53",
        "-4.263",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-53",
        "-4.433",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-53",
        "-4.601",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-53",
        "-4.769",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-53",
        "-4.936",
        "11.951",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-53",
        "-5.102",
        "11.888",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-53",
        "-5.268",
        "11.822",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-53",
        "-5.432",
        "11.752",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-53",
        "-5.595",
        "11.679",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-53",
        "-5.757",
        "11.602",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-53",
        "-5.918",
        "11.521",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-53",
        "-6.078",
        "11.437",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-53",
        "-4.195",
        "9.928",
        "0.595",
        "1.148",
        "0.058"
    ],
    [
        "-3",
        "-53",
        "17.980",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-53",
        "17.714",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-53",
        "17.446",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-53",
        "17.178",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-53",
        "16.910",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-53",
        "16.640",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-53",
        "16.369",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-53",
        "16.098",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-53",
        "15.826",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-53",
        "15.555",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-53",
        "15.283",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-53",
        "15.010",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-53",
        "14.737",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-53",
        "9.169",
        "11.560",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-53",
        "7.194",
        "8.624",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-53",
        "7.074",
        "8.482",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-53",
        "6.957",
        "8.339",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-53",
        "6.842",
        "8.192",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-53",
        "6.730",
        "8.043",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-53",
        "6.618",
        "7.892",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-53",
        "6.509",
        "7.738",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-53",
        "6.402",
        "7.582",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-53",
        "6.298",
        "7.423",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-53",
        "6.380",
        "7.887",
        "0.540",
        "0.999",
        "0.252"
    ],
    [
        "21",
        "-53",
        "7.995",
        "12.026",
        "2.542",
        "3.517",
        "0.466"
    ],
    [
        "22",
        "-53",
        "9.249",
        "14.190",
        "2.488",
        "3.744",
        "0.494"
    ],
    [
        "23",
        "-53",
        "10.433",
        "17.457",
        "1.651",
        "2.864",
        "0.360"
    ],
    [
        "24",
        "-53",
        "10.689",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-53",
        "10.425",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-53",
        "7.142",
        "9.841",
        "1.774",
        "3.075",
        "0.356"
    ],
    [
        "27",
        "-53",
        "5.544",
        "6.079",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-53",
        "5.461",
        "5.902",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-53",
        "5.380",
        "5.723",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-53",
        "5.300",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-53",
        "5.224",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-53",
        "5.152",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-53",
        "5.080",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-53",
        "5.013",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-53",
        "4.947",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-53",
        "4.883",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-53",
        "4.823",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-53",
        "4.765",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-53",
        "4.711",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-53",
        "4.658",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-53",
        "4.608",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-53",
        "4.561",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-53",
        "4.517",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-53",
        "4.476",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-53",
        "4.437",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-53",
        "4.402",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-53",
        "4.369",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-53",
        "4.339",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-53",
        "4.311",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-53",
        "4.287",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-53",
        "4.265",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-53",
        "4.247",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-53",
        "4.230",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-53",
        "4.218",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-53",
        "4.207",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-53",
        "4.200",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-53",
        "4.195",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-53",
        "4.194",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-53",
        "4.195",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-53",
        "4.199",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-53",
        "4.206",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-53",
        "4.216",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-53",
        "4.229",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-53",
        "4.244",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-53",
        "4.263",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-53",
        "4.284",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-53",
        "4.307",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-53",
        "4.334",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-53",
        "4.364",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-53",
        "4.397",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-53",
        "4.432",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-53",
        "4.470",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-53",
        "4.511",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-53",
        "4.554",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-53",
        "4.601",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-53",
        "4.650",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-53",
        "4.702",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-53",
        "4.757",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-53",
        "4.814",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-53",
        "4.874",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-53",
        "4.936",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-53",
        "5.002",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-53",
        "5.069",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-53",
        "5.140",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-53",
        "5.214",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-53",
        "5.289",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-53",
        "5.367",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-53",
        "5.448",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-53",
        "5.530",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-53",
        "5.616",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-53",
        "5.704",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-53",
        "5.794",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-53",
        "5.887",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-53",
        "5.983",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-53",
        "6.080",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-53",
        "6.180",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-53",
        "6.282",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-53",
        "6.386",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-53",
        "6.492",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-53",
        "6.601",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-53",
        "6.712",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-53",
        "6.824",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-53",
        "6.940",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-53",
        "7.056",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-53",
        "7.175",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-53",
        "7.296",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-53",
        "7.419",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-53",
        "7.544",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-53",
        "7.670",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-53",
        "7.799",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-53",
        "7.929",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-53",
        "8.061",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-53",
        "8.194",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-53",
        "8.329",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-53",
        "8.466",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-53",
        "8.604",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-53",
        "8.744",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-53",
        "8.885",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-53",
        "9.028",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-53",
        "9.172",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-53",
        "9.318",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-53",
        "9.465",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-53",
        "9.613",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-53",
        "9.762",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-53",
        "9.912",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-53",
        "10.064",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-53",
        "10.217",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-53",
        "10.370",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-53",
        "10.525",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-53",
        "10.681",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-53",
        "10.837",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-53",
        "10.994",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-53",
        "11.153",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-53",
        "11.311",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-53",
        "11.470",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-53",
        "11.631",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-53",
        "11.792",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-53",
        "11.953",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-53",
        "12.115",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-53",
        "12.472",
        "-5.251",
        "1.839",
        "4.828",
        "-0.010"
    ],
    [
        "141",
        "-53",
        "11.874",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-53",
        "11.102",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-53",
        "10.333",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-53",
        "9.567",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-53",
        "8.806",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-53",
        "8.049",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-53",
        "7.297",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-53",
        "6.550",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-53",
        "5.809",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-53",
        "5.073",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-53",
        "4.342",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-53",
        "3.617",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-53",
        "2.897",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-53",
        "2.184",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-53",
        "1.264",
        "50.509",
        "0.688",
        "0.786",
        "0.005"
    ],
    [
        "156",
        "-53",
        "0.060",
        "50.134",
        "1.175",
        "1.217",
        "0.028"
    ],
    [
        "157",
        "-53",
        "-1.817",
        "49.720",
        "1.704",
        "1.893",
        "0.051"
    ],
    [
        "158",
        "-53",
        "-4.691",
        "48.239",
        "2.278",
        "2.667",
        "0.082"
    ],
    [
        "159",
        "-53",
        "-9.324",
        "42.776",
        "2.793",
        "2.931",
        "0.153"
    ],
    [
        "160",
        "-53",
        "-18.252",
        "31.651",
        "2.378",
        "2.963",
        "0.148"
    ],
    [
        "161",
        "-53",
        "-24.641",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-53",
        "-24.989",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-53",
        "-25.341",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-53",
        "-25.697",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-53",
        "-26.057",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-53",
        "-26.421",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-53",
        "-26.789",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-53",
        "-27.161",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-53",
        "-27.537",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-53",
        "-27.916",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-53",
        "-28.299",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-53",
        "-28.685",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-53",
        "-29.074",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-53",
        "-29.467",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-53",
        "-29.862",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-53",
        "-30.261",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-53",
        "-30.662",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-53",
        "-31.066",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-53",
        "-31.472",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-52",
        "-32.884",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-52",
        "-33.290",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-52",
        "-33.697",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-52",
        "-34.107",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-52",
        "-34.518",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-52",
        "-34.931",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-52",
        "-35.346",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-52",
        "-35.763",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-52",
        "-36.180",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-52",
        "-36.599",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-52",
        "-37.019",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-52",
        "-37.440",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-52",
        "-37.862",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-52",
        "-38.284",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-52",
        "-38.707",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-52",
        "-39.131",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-52",
        "-39.555",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-52",
        "-39.979",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-52",
        "-40.403",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-52",
        "-40.827",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-52",
        "-41.251",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-52",
        "-41.675",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-52",
        "-42.098",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-52",
        "-42.521",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-52",
        "-42.943",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-52",
        "-43.364",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-52",
        "-43.784",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-52",
        "-44.203",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-52",
        "-44.621",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-52",
        "-45.037",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-52",
        "-45.453",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-52",
        "-45.866",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-52",
        "-46.278",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-52",
        "-46.688",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-52",
        "-47.096",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-52",
        "-47.501",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-52",
        "-47.905",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-52",
        "-48.306",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-52",
        "-48.705",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-52",
        "-49.101",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-52",
        "-49.495",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-52",
        "-49.886",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-52",
        "-50.274",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-52",
        "-50.658",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-52",
        "-51.040",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-52",
        "-51.418",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-52",
        "-51.793",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-52",
        "-52.164",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-52",
        "-52.532",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-52",
        "-52.896",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-52",
        "-53.256",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-52",
        "-53.612",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-52",
        "-53.964",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-52",
        "-54.312",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-52",
        "-54.656",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-52",
        "-54.995",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-52",
        "-55.330",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-52",
        "-55.660",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-52",
        "-55.985",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-52",
        "-56.306",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-52",
        "-56.621",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-52",
        "-56.932",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-52",
        "-36.392",
        "16.530",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-52",
        "23.158",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-52",
        "23.142",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-52",
        "23.124",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-52",
        "23.104",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-52",
        "23.080",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-52",
        "23.053",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-52",
        "23.023",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-52",
        "22.992",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-52",
        "22.957",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-52",
        "22.919",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-52",
        "22.879",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-52",
        "22.836",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-52",
        "22.790",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-52",
        "22.741",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-52",
        "22.690",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-52",
        "22.637",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-52",
        "22.580",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-52",
        "22.521",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-52",
        "22.459",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-52",
        "22.395",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-52",
        "22.328",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-52",
        "22.258",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-52",
        "22.186",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-52",
        "22.112",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-52",
        "22.034",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-52",
        "21.955",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-52",
        "21.873",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-52",
        "21.788",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-52",
        "21.701",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-52",
        "21.612",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-52",
        "21.520",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-52",
        "21.427",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-52",
        "21.331",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-52",
        "21.232",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-52",
        "21.132",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-52",
        "21.029",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-52",
        "20.924",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-52",
        "20.817",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-52",
        "20.707",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-52",
        "20.596",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-52",
        "20.482",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-52",
        "18.952",
        "8.215",
        "7.155",
        "6.848",
        "-0.021"
    ],
    [
        "-75",
        "-52",
        "16.727",
        "8.058",
        "9.275",
        "9.365",
        "-0.144"
    ],
    [
        "-74",
        "-52",
        "13.420",
        "7.970",
        "9.311",
        "10.376",
        "-0.155"
    ],
    [
        "-73",
        "-52",
        "9.525",
        "8.239",
        "8.596",
        "9.541",
        "-0.198"
    ],
    [
        "-72",
        "-52",
        "6.054",
        "8.775",
        "7.106",
        "7.060",
        "-0.338"
    ],
    [
        "-71",
        "-52",
        "4.453",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-52",
        "4.326",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-52",
        "4.198",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-52",
        "4.067",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-52",
        "3.935",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-52",
        "3.800",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-52",
        "3.663",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-52",
        "3.526",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-52",
        "3.386",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-52",
        "3.244",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-52",
        "3.102",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-52",
        "2.957",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-52",
        "2.810",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-52",
        "2.663",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-52",
        "2.513",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-52",
        "2.362",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-52",
        "2.211",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-52",
        "2.057",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-52",
        "1.902",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-52",
        "1.746",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-52",
        "1.589",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-52",
        "1.431",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-52",
        "1.271",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-52",
        "1.110",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-52",
        "0.949",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-52",
        "0.786",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-52",
        "0.623",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-52",
        "0.458",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-52",
        "0.294",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-52",
        "0.127",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-52",
        "-0.039",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-52",
        "-0.207",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-52",
        "-0.374",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-52",
        "-0.543",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-52",
        "-0.712",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-52",
        "-0.881",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-52",
        "-1.051",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-52",
        "-1.221",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-52",
        "-1.391",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-52",
        "-1.562",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-52",
        "-1.733",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-52",
        "-1.904",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-52",
        "-2.075",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-52",
        "-2.247",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-52",
        "-2.418",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-52",
        "-2.589",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-52",
        "-2.760",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-52",
        "-2.930",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-52",
        "-3.102",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-52",
        "-3.271",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-52",
        "-3.442",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-52",
        "-3.611",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-52",
        "-3.781",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-52",
        "-3.949",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-52",
        "-4.118",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-52",
        "-4.285",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-52",
        "-4.452",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-52",
        "-4.619",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-52",
        "-4.784",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-52",
        "-4.949",
        "11.951",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-52",
        "-5.113",
        "11.888",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-52",
        "-5.276",
        "11.822",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-52",
        "-5.438",
        "11.752",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-52",
        "-5.599",
        "11.679",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-52",
        "-5.759",
        "11.602",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-52",
        "-5.918",
        "11.521",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-52",
        "12.345",
        "16.974",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-52",
        "18.531",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-52",
        "18.268",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-52",
        "18.006",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-52",
        "17.742",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-52",
        "17.478",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-52",
        "17.212",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-52",
        "16.946",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-52",
        "16.680",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-52",
        "16.412",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-52",
        "16.144",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-52",
        "15.876",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-52",
        "15.607",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-52",
        "15.339",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-52",
        "15.070",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-52",
        "14.801",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-52",
        "14.532",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-52",
        "14.262",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-52",
        "13.994",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-52",
        "13.726",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-52",
        "13.458",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-52",
        "13.191",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-52",
        "12.923",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-52",
        "12.657",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-52",
        "12.391",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-52",
        "12.126",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-52",
        "11.862",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-52",
        "11.598",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-52",
        "11.335",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-52",
        "11.074",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-52",
        "10.814",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-52",
        "10.556",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-52",
        "10.299",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-52",
        "8.591",
        "14.860",
        "1.693",
        "2.681",
        "0.128"
    ],
    [
        "29",
        "-52",
        "5.690",
        "5.409",
        "1.640",
        "2.607",
        "0.037"
    ],
    [
        "30",
        "-52",
        "5.724",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-52",
        "5.649",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-52",
        "5.576",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-52",
        "5.506",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-52",
        "5.439",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-52",
        "5.374",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-52",
        "5.313",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-52",
        "5.253",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-52",
        "5.196",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-52",
        "5.141",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-52",
        "5.090",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-52",
        "5.041",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-52",
        "4.995",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-52",
        "4.951",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-52",
        "4.911",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-52",
        "4.873",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-52",
        "4.838",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-52",
        "4.805",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-52",
        "4.775",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-52",
        "4.748",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-52",
        "4.724",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-52",
        "4.703",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-52",
        "4.684",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-52",
        "4.668",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-52",
        "4.655",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-52",
        "4.646",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-52",
        "4.638",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-52",
        "4.633",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-52",
        "4.632",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-52",
        "4.633",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-52",
        "4.637",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-52",
        "4.644",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-52",
        "4.653",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-52",
        "4.666",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-52",
        "4.682",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-52",
        "4.700",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-52",
        "4.720",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-52",
        "4.744",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-52",
        "4.771",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-52",
        "4.800",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-52",
        "4.832",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-52",
        "4.867",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-52",
        "4.905",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-52",
        "4.945",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-52",
        "4.987",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-52",
        "5.034",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-52",
        "5.082",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-52",
        "5.133",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-52",
        "5.187",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-52",
        "5.243",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-52",
        "5.303",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-52",
        "5.364",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-52",
        "5.429",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-52",
        "5.496",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-52",
        "5.566",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-52",
        "5.637",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-52",
        "5.712",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-52",
        "5.789",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-52",
        "5.869",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-52",
        "5.951",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-52",
        "6.036",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-52",
        "6.122",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-52",
        "6.212",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-52",
        "6.303",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-52",
        "6.397",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-52",
        "6.493",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-52",
        "6.591",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-52",
        "6.692",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-52",
        "6.794",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-52",
        "6.899",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-52",
        "7.007",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-52",
        "7.116",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-52",
        "7.228",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-52",
        "7.341",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-52",
        "7.456",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-52",
        "7.573",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-52",
        "7.693",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-52",
        "7.814",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-52",
        "7.938",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-52",
        "8.062",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-52",
        "8.189",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-52",
        "8.317",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-52",
        "8.447",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-52",
        "8.579",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-52",
        "8.712",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-52",
        "8.847",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-52",
        "8.984",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-52",
        "9.121",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-52",
        "9.261",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-52",
        "9.402",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-52",
        "9.544",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-52",
        "9.688",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-52",
        "9.833",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-52",
        "9.978",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-52",
        "10.126",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-52",
        "10.275",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-52",
        "10.424",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-52",
        "10.575",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-52",
        "10.726",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-52",
        "10.879",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-52",
        "11.032",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-52",
        "11.187",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-52",
        "11.342",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-52",
        "11.498",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-52",
        "11.655",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-52",
        "11.812",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-52",
        "11.970",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-52",
        "12.129",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-52",
        "12.288",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-52",
        "12.448",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-52",
        "13.332",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-52",
        "12.566",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-52",
        "11.803",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-52",
        "11.045",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-52",
        "10.289",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-52",
        "9.538",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-52",
        "8.791",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-52",
        "8.050",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-52",
        "7.313",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-52",
        "6.581",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-52",
        "5.855",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-52",
        "5.133",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-52",
        "4.418",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-52",
        "3.708",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-52",
        "3.005",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-52",
        "2.146",
        "50.558",
        "0.691",
        "0.795",
        "-0.002"
    ],
    [
        "156",
        "-52",
        "1.123",
        "50.327",
        "1.173",
        "1.238",
        "-0.008"
    ],
    [
        "157",
        "-52",
        "-0.128",
        "50.140",
        "1.706",
        "1.929",
        "0.007"
    ],
    [
        "158",
        "-52",
        "-1.907",
        "49.399",
        "2.323",
        "2.720",
        "0.039"
    ],
    [
        "159",
        "-52",
        "-4.985",
        "46.483",
        "2.959",
        "3.029",
        "0.076"
    ],
    [
        "160",
        "-52",
        "-10.913",
        "40.042",
        "2.946",
        "3.299",
        "0.133"
    ],
    [
        "161",
        "-52",
        "-19.563",
        "31.071",
        "2.504",
        "2.871",
        "0.204"
    ],
    [
        "162",
        "-52",
        "-26.084",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-52",
        "-26.431",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-52",
        "-26.783",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-52",
        "-27.138",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-52",
        "-27.498",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-52",
        "-27.861",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-52",
        "-28.228",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-52",
        "-28.599",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-52",
        "-28.973",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-52",
        "-29.350",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-52",
        "-29.731",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-52",
        "-30.116",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-52",
        "-30.503",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-52",
        "-30.893",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-52",
        "-31.286",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-52",
        "-31.682",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-52",
        "-32.080",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-52",
        "-32.481",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-51",
        "-33.878",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-51",
        "-34.278",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-51",
        "-34.680",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-51",
        "-35.084",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-51",
        "-35.490",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-51",
        "-35.897",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-51",
        "-36.306",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-51",
        "-36.717",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-51",
        "-37.129",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-51",
        "-37.542",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-51",
        "-37.956",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-51",
        "-38.371",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-51",
        "-38.787",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-51",
        "-39.204",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-51",
        "-39.621",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-51",
        "-40.039",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-51",
        "-40.457",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-51",
        "-40.875",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-51",
        "-41.293",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-51",
        "-41.712",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-51",
        "-42.130",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-51",
        "-42.548",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-51",
        "-42.965",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-51",
        "-43.382",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-51",
        "-43.798",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-51",
        "-44.213",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-51",
        "-44.627",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-51",
        "-45.041",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-51",
        "-45.453",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-51",
        "-45.864",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-51",
        "-46.273",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-51",
        "-46.681",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-51",
        "-47.087",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-51",
        "-47.491",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-51",
        "-47.893",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-51",
        "-48.294",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-51",
        "-48.692",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-51",
        "-49.088",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-51",
        "-49.481",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-51",
        "-49.872",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-51",
        "-50.260",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-51",
        "-50.645",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-51",
        "-51.028",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-51",
        "-51.407",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-51",
        "-51.783",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-51",
        "-52.156",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-51",
        "-52.526",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-51",
        "-52.892",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-51",
        "-53.255",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-51",
        "-53.614",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-51",
        "-53.969",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-51",
        "-54.320",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-51",
        "-54.667",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-51",
        "-55.010",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-51",
        "-55.349",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-51",
        "-55.684",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-51",
        "-56.014",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-51",
        "-56.339",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-51",
        "-56.660",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-51",
        "-56.976",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-51",
        "-57.288",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-51",
        "-57.594",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-51",
        "-57.896",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-51",
        "23.339",
        "1.010",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-51",
        "23.324",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-51",
        "23.306",
        "1.419",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-51",
        "23.285",
        "1.623",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-51",
        "23.262",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-51",
        "23.235",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-51",
        "23.207",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-51",
        "23.176",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-51",
        "23.140",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-51",
        "23.104",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-51",
        "23.064",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-51",
        "23.022",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-51",
        "22.976",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-51",
        "22.928",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-51",
        "22.878",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-51",
        "22.824",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-51",
        "22.769",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-51",
        "22.711",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-51",
        "22.649",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-51",
        "22.587",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-51",
        "22.520",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-51",
        "22.451",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-51",
        "22.380",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-51",
        "22.307",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-51",
        "22.231",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-51",
        "22.153",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-51",
        "22.072",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-51",
        "21.988",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-51",
        "21.903",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-51",
        "21.815",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-51",
        "21.724",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-51",
        "21.632",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-51",
        "21.538",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-51",
        "21.440",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-51",
        "21.340",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-51",
        "21.239",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-51",
        "21.136",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-51",
        "21.030",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-51",
        "20.923",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-51",
        "20.812",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-51",
        "20.701",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-51",
        "19.313",
        "8.353",
        "8.132",
        "7.363",
        "-0.004"
    ],
    [
        "-75",
        "-51",
        "17.253",
        "8.437",
        "10.457",
        "8.678",
        "-0.052"
    ],
    [
        "-74",
        "-51",
        "14.539",
        "8.717",
        "10.543",
        "9.559",
        "-0.093"
    ],
    [
        "-73",
        "-51",
        "11.141",
        "8.935",
        "10.168",
        "9.013",
        "-0.156"
    ],
    [
        "-72",
        "-51",
        "7.240",
        "8.921",
        "9.206",
        "7.101",
        "-0.366"
    ],
    [
        "-71",
        "-51",
        "4.312",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-51",
        "4.187",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-51",
        "4.060",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-51",
        "3.932",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-51",
        "3.801",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-51",
        "3.668",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-51",
        "3.534",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-51",
        "3.398",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-51",
        "3.260",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-51",
        "3.121",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-51",
        "2.980",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-51",
        "2.837",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-51",
        "2.693",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-51",
        "2.546",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-51",
        "2.400",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-51",
        "2.250",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-51",
        "2.100",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-51",
        "1.949",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-51",
        "1.796",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-51",
        "1.643",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-51",
        "1.487",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-51",
        "1.331",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-51",
        "1.174",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-51",
        "1.016",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-51",
        "0.856",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-51",
        "0.696",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-51",
        "0.535",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-51",
        "0.373",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-51",
        "0.210",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-51",
        "0.046",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-51",
        "-0.119",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-51",
        "-0.283",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-51",
        "-0.449",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-51",
        "-0.615",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-51",
        "-0.781",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-51",
        "-0.948",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-51",
        "-1.116",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-51",
        "-1.284",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-51",
        "-1.452",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-51",
        "-1.621",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-51",
        "-1.789",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-51",
        "-1.957",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-51",
        "-2.126",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-51",
        "-2.295",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-51",
        "-2.464",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-51",
        "-2.632",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-51",
        "-2.801",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-51",
        "-2.970",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-51",
        "-3.138",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-51",
        "-3.306",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-51",
        "-3.473",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-51",
        "-3.641",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-51",
        "-3.808",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-51",
        "-3.974",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-51",
        "-4.140",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-51",
        "-4.306",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-51",
        "-4.470",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-51",
        "-4.634",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-51",
        "-4.798",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-51",
        "-4.960",
        "11.951",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-51",
        "-5.122",
        "11.888",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-51",
        "-5.282",
        "11.822",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-51",
        "-5.442",
        "11.752",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-51",
        "-5.601",
        "11.679",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-51",
        "-5.759",
        "11.602",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-51",
        "-5.916",
        "11.521",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-51",
        "19.067",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-51",
        "18.810",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-51",
        "18.552",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-51",
        "18.293",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-51",
        "18.033",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-51",
        "17.772",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-51",
        "17.510",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-51",
        "17.247",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-51",
        "16.984",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-51",
        "16.720",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-51",
        "16.457",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-51",
        "16.192",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-51",
        "15.927",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-51",
        "15.662",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-51",
        "15.396",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-51",
        "15.131",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-51",
        "14.867",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-51",
        "14.602",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-51",
        "14.336",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-51",
        "14.071",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-51",
        "13.807",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-51",
        "13.543",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-51",
        "13.280",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-51",
        "13.017",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-51",
        "12.755",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-51",
        "12.493",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-51",
        "12.233",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-51",
        "11.973",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-51",
        "11.714",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-51",
        "11.457",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-51",
        "11.201",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-51",
        "10.945",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-51",
        "10.691",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-51",
        "10.438",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-51",
        "9.159",
        "15.158",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-51",
        "6.146",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-51",
        "6.071",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-51",
        "6.000",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-51",
        "5.931",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-51",
        "5.865",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-51",
        "5.800",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-51",
        "5.739",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-51",
        "5.681",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-51",
        "5.624",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-51",
        "5.571",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-51",
        "5.520",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-51",
        "5.472",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-51",
        "5.426",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-51",
        "5.384",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-51",
        "5.343",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-51",
        "5.306",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-51",
        "5.271",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-51",
        "5.239",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-51",
        "5.210",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-51",
        "5.183",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-51",
        "5.160",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-51",
        "5.138",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-51",
        "5.120",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-51",
        "5.104",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-51",
        "5.092",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-51",
        "5.081",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-51",
        "5.074",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-51",
        "5.070",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-51",
        "5.069",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-51",
        "5.070",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-51",
        "5.073",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-51",
        "5.081",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-51",
        "5.090",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-51",
        "5.102",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-51",
        "5.118",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-51",
        "5.135",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-51",
        "5.156",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-51",
        "5.179",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-51",
        "5.205",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-51",
        "5.234",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-51",
        "5.266",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-51",
        "5.300",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-51",
        "5.338",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-51",
        "5.377",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-51",
        "5.420",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-51",
        "5.465",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-51",
        "5.512",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-51",
        "5.563",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-51",
        "5.616",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-51",
        "5.672",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-51",
        "5.731",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-51",
        "5.791",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-51",
        "5.855",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-51",
        "5.920",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-51",
        "5.989",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-51",
        "6.060",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-51",
        "6.134",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-51",
        "6.210",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-51",
        "6.289",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-51",
        "6.370",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-51",
        "6.453",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-51",
        "6.539",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-51",
        "6.627",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-51",
        "6.716",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-51",
        "6.809",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-51",
        "6.904",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-51",
        "7.001",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-51",
        "7.100",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-51",
        "7.201",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-51",
        "7.306",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-51",
        "7.410",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-51",
        "7.519",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-51",
        "7.628",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-51",
        "7.741",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-51",
        "7.854",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-51",
        "7.970",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-51",
        "8.087",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-51",
        "8.207",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-51",
        "8.328",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-51",
        "8.451",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-51",
        "8.576",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-51",
        "8.703",
        "-9.396",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-51",
        "8.831",
        "-9.518",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-51",
        "8.961",
        "-9.638",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-51",
        "9.093",
        "-9.754",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-51",
        "9.225",
        "-9.868",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-51",
        "9.360",
        "-9.979",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-51",
        "9.496",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-51",
        "9.634",
        "-10.190",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-51",
        "9.773",
        "-10.292",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-51",
        "9.913",
        "-10.390",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-51",
        "10.054",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-51",
        "10.198",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-51",
        "10.342",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-51",
        "10.487",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-51",
        "10.634",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-51",
        "10.781",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-51",
        "10.929",
        "-10.989",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-51",
        "11.079",
        "-11.060",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-51",
        "11.230",
        "-11.130",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-51",
        "11.380",
        "-11.195",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-51",
        "11.533",
        "-11.257",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-51",
        "11.686",
        "-11.316",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-51",
        "11.840",
        "-11.371",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-51",
        "11.994",
        "-11.423",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-51",
        "12.150",
        "-11.471",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-51",
        "12.306",
        "-11.516",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-51",
        "12.462",
        "-11.557",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-51",
        "12.619",
        "-11.595",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-51",
        "12.776",
        "-11.629",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-51",
        "14.009",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-51",
        "13.254",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-51",
        "12.501",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-51",
        "11.753",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-51",
        "11.009",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-51",
        "10.267",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-51",
        "9.531",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-51",
        "8.800",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-51",
        "8.073",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-51",
        "7.351",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-51",
        "6.635",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-51",
        "5.923",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-51",
        "5.218",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-51",
        "4.519",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-51",
        "3.824",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-51",
        "3.050",
        "50.543",
        "0.690",
        "0.798",
        "-0.006"
    ],
    [
        "156",
        "-51",
        "2.231",
        "50.259",
        "1.146",
        "1.211",
        "-0.013"
    ],
    [
        "157",
        "-51",
        "1.427",
        "49.980",
        "1.616",
        "1.812",
        "0.009"
    ],
    [
        "158",
        "-51",
        "0.648",
        "49.508",
        "2.173",
        "2.516",
        "0.034"
    ],
    [
        "159",
        "-51",
        "-0.365",
        "48.354",
        "2.821",
        "2.866",
        "0.037"
    ],
    [
        "160",
        "-51",
        "-2.639",
        "46.052",
        "2.954",
        "3.206",
        "0.093"
    ],
    [
        "161",
        "-51",
        "-8.730",
        "41.434",
        "2.924",
        "3.171",
        "0.162"
    ],
    [
        "162",
        "-51",
        "-20.521",
        "31.265",
        "2.450",
        "2.440",
        "0.179"
    ],
    [
        "163",
        "-51",
        "-27.514",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-51",
        "-27.861",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-51",
        "-28.211",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-51",
        "-28.566",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-51",
        "-28.924",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-51",
        "-29.286",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-51",
        "-29.651",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-51",
        "-30.020",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-51",
        "-30.393",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-51",
        "-30.769",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-51",
        "-31.148",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-51",
        "-31.529",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-51",
        "-31.914",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-51",
        "-32.302",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-51",
        "-32.692",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-51",
        "-33.085",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-51",
        "-33.480",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-50",
        "-34.862",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-50",
        "-35.256",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-50",
        "-35.652",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-50",
        "-36.050",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-50",
        "-36.450",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-50",
        "-36.852",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-50",
        "-37.255",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-50",
        "-37.660",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-50",
        "-38.066",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-50",
        "-38.473",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-50",
        "-38.881",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-50",
        "-39.290",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-50",
        "-39.700",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-50",
        "-40.111",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-50",
        "-40.522",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-50",
        "-40.934",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-50",
        "-41.346",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-50",
        "-41.758",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-50",
        "-42.171",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-50",
        "-42.583",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-50",
        "-42.995",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-50",
        "-43.407",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-50",
        "-43.819",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-50",
        "-44.229",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-50",
        "-44.640",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-50",
        "-45.049",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-50",
        "-45.457",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-50",
        "-45.865",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-50",
        "-46.271",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-50",
        "-46.676",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-50",
        "-47.079",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-50",
        "-47.481",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-50",
        "-47.882",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-50",
        "-48.280",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-50",
        "-48.677",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-50",
        "-49.071",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-50",
        "-49.464",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-50",
        "-49.854",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-50",
        "-50.242",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-50",
        "-50.627",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-50",
        "-51.009",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-50",
        "-51.389",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-50",
        "-51.766",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-50",
        "-52.140",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-50",
        "-52.511",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-50",
        "-52.879",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-50",
        "-53.243",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-50",
        "-53.604",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-50",
        "-53.962",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-50",
        "-54.315",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-50",
        "-54.665",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-50",
        "-55.012",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-50",
        "-55.354",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-50",
        "-55.692",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-50",
        "-56.026",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-50",
        "-56.356",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-50",
        "-56.681",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-50",
        "-57.002",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-50",
        "-57.318",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-50",
        "-57.630",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-50",
        "-57.937",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-50",
        "-58.239",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-50",
        "-58.536",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-50",
        "-19.544",
        "5.485",
        "9.056",
        "9.261",
        "-0.157"
    ],
    [
        "-116",
        "-50",
        "23.498",
        "1.216",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-50",
        "22.800",
        "1.864",
        "1.871",
        "3.549",
        "0.043"
    ],
    [
        "-114",
        "-50",
        "24.046",
        "1.485",
        "7.718",
        "8.690",
        "0.021"
    ],
    [
        "-113",
        "-50",
        "23.437",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-50",
        "23.411",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-50",
        "23.382",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-50",
        "23.352",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-50",
        "23.317",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-50",
        "23.281",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-50",
        "23.242",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-50",
        "23.200",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-50",
        "23.156",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-50",
        "23.108",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-50",
        "23.059",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-50",
        "23.006",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-50",
        "22.952",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-50",
        "22.893",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-50",
        "22.834",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-50",
        "22.771",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-50",
        "22.706",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-50",
        "22.638",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-50",
        "22.568",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-50",
        "22.496",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-50",
        "22.421",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-50",
        "22.343",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-50",
        "22.263",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-50",
        "22.181",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-50",
        "22.097",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-50",
        "22.011",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-50",
        "21.922",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-50",
        "21.830",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-50",
        "21.737",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-50",
        "21.641",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-50",
        "21.543",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-50",
        "21.444",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-50",
        "21.342",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-50",
        "21.238",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-50",
        "21.131",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-50",
        "21.023",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-50",
        "20.912",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-50",
        "19.582",
        "8.387",
        "9.943",
        "7.051",
        "0.096"
    ],
    [
        "-75",
        "-50",
        "17.578",
        "8.496",
        "12.372",
        "8.105",
        "0.063"
    ],
    [
        "-74",
        "-50",
        "15.046",
        "8.699",
        "12.097",
        "9.174",
        "-0.002"
    ],
    [
        "-73",
        "-50",
        "12.149",
        "8.869",
        "11.819",
        "9.191",
        "-0.080"
    ],
    [
        "-72",
        "-50",
        "8.786",
        "9.039",
        "11.558",
        "8.194",
        "-0.218"
    ],
    [
        "-71",
        "-50",
        "4.601",
        "9.119",
        "2.496",
        "2.584",
        "-0.284"
    ],
    [
        "-70",
        "-50",
        "4.047",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-50",
        "3.922",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-50",
        "3.794",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-50",
        "3.666",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-50",
        "3.535",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-50",
        "3.403",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-50",
        "3.269",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-50",
        "3.133",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-50",
        "2.995",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-50",
        "2.856",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-50",
        "2.715",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-50",
        "2.574",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-50",
        "2.430",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-50",
        "2.285",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-50",
        "2.138",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-50",
        "1.990",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-50",
        "1.841",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-50",
        "1.690",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-50",
        "1.539",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-50",
        "1.386",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-50",
        "1.232",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-50",
        "1.076",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-50",
        "0.920",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-50",
        "0.763",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-50",
        "0.605",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-50",
        "0.447",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-50",
        "0.287",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-50",
        "0.126",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-50",
        "-0.035",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-50",
        "-0.197",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-50",
        "-0.359",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-50",
        "-0.522",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-50",
        "-0.686",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-50",
        "-0.850",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-50",
        "-1.015",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-50",
        "-1.181",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-50",
        "-1.346",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-50",
        "-1.511",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-50",
        "-1.678",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-50",
        "-1.844",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-50",
        "-2.010",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-50",
        "-2.177",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-50",
        "-2.343",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-50",
        "-2.510",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-50",
        "-2.675",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-50",
        "-2.842",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-50",
        "-3.008",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-50",
        "-3.173",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-50",
        "-3.339",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-50",
        "-3.505",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-50",
        "-3.669",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-50",
        "-3.834",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-50",
        "-3.998",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-50",
        "-4.162",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-50",
        "-4.324",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-50",
        "-4.487",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-50",
        "-4.648",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-50",
        "-4.809",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-50",
        "-4.969",
        "11.951",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-50",
        "-5.129",
        "11.888",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-50",
        "-5.287",
        "11.822",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-50",
        "-5.445",
        "11.752",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-50",
        "-1.170",
        "13.726",
        "3.356",
        "2.552",
        "0.466"
    ],
    [
        "-7",
        "-50",
        "15.462",
        "17.004",
        "3.395",
        "2.711",
        "0.498"
    ],
    [
        "-6",
        "-50",
        "19.590",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-50",
        "19.337",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-50",
        "19.083",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-50",
        "18.829",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-50",
        "18.573",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-50",
        "18.317",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-50",
        "18.060",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-50",
        "17.802",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-50",
        "17.544",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-50",
        "17.284",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-50",
        "17.024",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-50",
        "16.763",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-50",
        "16.503",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-50",
        "16.242",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-50",
        "15.980",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-50",
        "15.719",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-50",
        "15.458",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-50",
        "15.196",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-50",
        "14.935",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-50",
        "14.674",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-50",
        "14.413",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-50",
        "14.153",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-50",
        "13.892",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-50",
        "13.633",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-50",
        "13.373",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-50",
        "13.115",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-50",
        "12.858",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-50",
        "12.601",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-50",
        "12.345",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-50",
        "12.090",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-50",
        "11.836",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-50",
        "11.583",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-50",
        "11.331",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-50",
        "11.081",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-50",
        "10.832",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-50",
        "10.585",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-50",
        "6.565",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-50",
        "6.492",
        "5.361",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-50",
        "6.422",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-50",
        "6.354",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-50",
        "6.288",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-50",
        "6.226",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-50",
        "6.165",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-50",
        "6.107",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-50",
        "6.052",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-50",
        "5.999",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-50",
        "5.949",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-50",
        "5.901",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-50",
        "5.857",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-50",
        "5.814",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-50",
        "5.775",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-50",
        "5.737",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-50",
        "5.703",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-50",
        "5.672",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-50",
        "5.642",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-50",
        "5.616",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-50",
        "5.593",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-50",
        "5.572",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-50",
        "5.555",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-50",
        "5.539",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-50",
        "5.527",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-50",
        "5.516",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-50",
        "5.510",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-50",
        "5.505",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-50",
        "5.504",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-50",
        "5.505",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-50",
        "5.508",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-50",
        "5.516",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-50",
        "5.525",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-50",
        "5.537",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-50",
        "5.552",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-50",
        "5.569",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-50",
        "5.590",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-50",
        "5.613",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-50",
        "5.638",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-50",
        "5.667",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-50",
        "5.698",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-50",
        "5.732",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-50",
        "5.768",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-50",
        "5.807",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-50",
        "5.850",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-50",
        "5.894",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-50",
        "5.942",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-50",
        "5.991",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-50",
        "6.043",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-50",
        "6.098",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-50",
        "6.156",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-50",
        "6.216",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-50",
        "6.278",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-50",
        "6.343",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-50",
        "6.411",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-50",
        "6.481",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-50",
        "6.553",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-50",
        "6.629",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-50",
        "6.706",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-50",
        "6.786",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-50",
        "6.868",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-50",
        "6.952",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-50",
        "7.039",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-50",
        "7.128",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-50",
        "7.219",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-50",
        "7.313",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-50",
        "7.408",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-50",
        "7.507",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-50",
        "7.606",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-50",
        "7.708",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-50",
        "7.812",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-50",
        "7.919",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-50",
        "8.027",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-50",
        "8.137",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-50",
        "8.250",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-50",
        "8.363",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-50",
        "8.479",
        "-8.741",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-50",
        "8.597",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-50",
        "8.716",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-50",
        "8.838",
        "-9.142",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-50",
        "8.961",
        "-9.271",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-50",
        "24.423",
        "19.947",
        "8.224",
        "10.718",
        "0.223"
    ],
    [
        "112",
        "-50",
        "35.964",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-50",
        "35.219",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-50",
        "19.086",
        "22.547",
        "9.556",
        "12.660",
        "0.389"
    ],
    [
        "115",
        "-50",
        "18.232",
        "19.214",
        "10.493",
        "15.187",
        "0.376"
    ],
    [
        "116",
        "-50",
        "14.562",
        "3.094",
        "7.937",
        "13.372",
        "0.619"
    ],
    [
        "117",
        "-50",
        "9.868",
        "-10.087",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-50",
        "8.044",
        "-2.426",
        "6.168",
        "9.483",
        "0.441"
    ],
    [
        "119",
        "-50",
        "11.361",
        "17.593",
        "9.061",
        "10.308",
        "0.433"
    ],
    [
        "120",
        "-50",
        "13.786",
        "22.757",
        "8.096",
        "10.524",
        "0.340"
    ],
    [
        "121",
        "-50",
        "10.418",
        "-10.485",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-50",
        "10.559",
        "-10.578",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-50",
        "10.701",
        "-10.667",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-50",
        "10.845",
        "-10.752",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-50",
        "10.989",
        "-10.834",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-50",
        "11.134",
        "-10.913",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-50",
        "22.450",
        "-8.499",
        "9.119",
        "11.597",
        "-0.273"
    ],
    [
        "128",
        "-50",
        "23.798",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-50",
        "23.031",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-50",
        "22.264",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-50",
        "21.498",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-50",
        "20.733",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-50",
        "19.971",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-50",
        "19.208",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-50",
        "18.448",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-50",
        "17.690",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-50",
        "16.934",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-50",
        "16.181",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-50",
        "15.430",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-50",
        "14.682",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-50",
        "13.937",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-50",
        "13.196",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-50",
        "12.458",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-50",
        "11.724",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-50",
        "10.994",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-50",
        "10.268",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-50",
        "9.547",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-50",
        "8.830",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-50",
        "8.119",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-50",
        "7.413",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-50",
        "6.712",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-50",
        "6.016",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-50",
        "5.326",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-50",
        "4.643",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-50",
        "3.933",
        "50.486",
        "0.633",
        "0.748",
        "-0.001"
    ],
    [
        "156",
        "-50",
        "3.224",
        "50.067",
        "1.021",
        "0.997",
        "0.044"
    ],
    [
        "157",
        "-50",
        "2.549",
        "49.608",
        "1.410",
        "1.404",
        "0.104"
    ],
    [
        "158",
        "-50",
        "1.942",
        "49.074",
        "1.866",
        "1.986",
        "0.113"
    ],
    [
        "159",
        "-50",
        "1.346",
        "48.404",
        "2.438",
        "2.243",
        "0.068"
    ],
    [
        "160",
        "-50",
        "0.515",
        "47.735",
        "2.525",
        "2.671",
        "0.090"
    ],
    [
        "161",
        "-50",
        "-0.833",
        "47.197",
        "2.470",
        "2.694",
        "0.069"
    ],
    [
        "162",
        "-50",
        "-4.250",
        "45.888",
        "2.497",
        "2.320",
        "0.111"
    ],
    [
        "163",
        "-50",
        "-17.526",
        "32.970",
        "2.244",
        "2.661",
        "0.373"
    ],
    [
        "164",
        "-50",
        "-28.930",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-50",
        "-29.276",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-50",
        "-29.625",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-50",
        "-29.978",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-50",
        "-30.335",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-50",
        "-30.695",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-50",
        "-31.059",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-50",
        "-31.426",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-50",
        "-31.797",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-50",
        "-32.170",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-50",
        "-32.546",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-50",
        "-32.926",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-50",
        "-33.308",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-50",
        "-33.693",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-50",
        "-34.080",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-50",
        "-34.470",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-49",
        "-35.834",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-49",
        "-36.223",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-49",
        "-36.613",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-49",
        "-37.005",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-49",
        "-37.399",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-49",
        "-37.795",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-49",
        "-38.192",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-49",
        "-38.591",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-49",
        "-38.991",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-49",
        "-39.392",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-49",
        "-39.794",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-49",
        "-40.198",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-49",
        "-40.602",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-49",
        "-41.006",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-49",
        "-41.411",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-49",
        "-41.817",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-49",
        "-42.223",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-49",
        "-42.629",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-49",
        "-43.036",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-49",
        "-43.442",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-49",
        "-43.848",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-49",
        "-44.254",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-49",
        "-44.659",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-49",
        "-45.064",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-49",
        "-45.468",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-49",
        "-45.871",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-49",
        "-46.274",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-49",
        "-46.675",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-49",
        "-47.075",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-49",
        "-47.474",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-49",
        "-47.872",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-49",
        "-48.268",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-49",
        "-48.662",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-49",
        "-49.054",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-49",
        "-49.445",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-49",
        "-49.834",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-49",
        "-50.220",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-49",
        "-50.605",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-49",
        "-50.987",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-49",
        "-51.366",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-49",
        "-51.743",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-49",
        "-52.117",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-49",
        "-52.489",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-49",
        "-52.857",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-49",
        "-53.223",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-49",
        "-53.585",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-49",
        "-53.944",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-49",
        "-54.300",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-49",
        "-54.652",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-49",
        "-55.000",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-49",
        "-55.345",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-49",
        "-55.686",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-49",
        "-56.024",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-49",
        "-56.357",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-49",
        "-56.686",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-49",
        "-57.011",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-49",
        "-57.331",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-49",
        "-57.647",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-49",
        "-57.959",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-49",
        "-58.266",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-49",
        "-58.568",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-49",
        "-58.866",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-49",
        "-59.159",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-49",
        "-59.446",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-49",
        "-59.729",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-49",
        "-60.007",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-49",
        "-60.279",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-49",
        "23.605",
        "1.826",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-49",
        "23.579",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-49",
        "23.551",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-49",
        "23.521",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-49",
        "23.488",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-49",
        "23.451",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-49",
        "23.413",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-49",
        "23.371",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-49",
        "23.327",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-49",
        "23.281",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-49",
        "23.233",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-49",
        "23.181",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-49",
        "23.126",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-49",
        "23.070",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-49",
        "23.011",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-49",
        "22.949",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-49",
        "22.885",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-49",
        "22.818",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-49",
        "22.749",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-49",
        "22.678",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-49",
        "22.604",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-49",
        "22.527",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-49",
        "22.450",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-49",
        "22.368",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-49",
        "22.285",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-49",
        "22.199",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-49",
        "22.112",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-49",
        "22.022",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-49",
        "21.930",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-49",
        "21.836",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-49",
        "21.739",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-49",
        "21.641",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-49",
        "21.540",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-49",
        "21.438",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-49",
        "21.333",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-49",
        "21.227",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-49",
        "21.118",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-49",
        "20.048",
        "8.457",
        "10.151",
        "6.462",
        "0.200"
    ],
    [
        "-75",
        "-49",
        "18.189",
        "8.636",
        "12.943",
        "7.830",
        "0.173"
    ],
    [
        "-74",
        "-49",
        "15.587",
        "8.774",
        "13.100",
        "9.587",
        "0.071"
    ],
    [
        "-73",
        "-49",
        "12.611",
        "8.837",
        "13.430",
        "10.759",
        "-0.025"
    ],
    [
        "-72",
        "-49",
        "9.166",
        "8.921",
        "13.720",
        "10.739",
        "-0.122"
    ],
    [
        "-71",
        "-49",
        "4.549",
        "9.146",
        "3.044",
        "3.414",
        "-0.146"
    ],
    [
        "-70",
        "-49",
        "3.905",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-49",
        "3.782",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-49",
        "3.657",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-49",
        "3.531",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-49",
        "3.401",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-49",
        "3.271",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-49",
        "3.139",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-49",
        "3.005",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-49",
        "2.869",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-49",
        "2.732",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-49",
        "2.594",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-49",
        "2.453",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-49",
        "2.312",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-49",
        "2.169",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-49",
        "2.025",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-49",
        "1.879",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-49",
        "1.732",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-49",
        "1.584",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-49",
        "1.434",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-49",
        "1.283",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-49",
        "1.132",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-49",
        "0.979",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-49",
        "0.825",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-49",
        "0.671",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-49",
        "0.515",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-49",
        "0.358",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-49",
        "0.201",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-49",
        "0.043",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-49",
        "-0.116",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-49",
        "-0.276",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-49",
        "-0.436",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-49",
        "-0.597",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-49",
        "-0.758",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-49",
        "-0.920",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-49",
        "-1.082",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-49",
        "-1.244",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-49",
        "-1.408",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-49",
        "-1.571",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-49",
        "-1.735",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-49",
        "-1.898",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-49",
        "-2.062",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-49",
        "-2.226",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-49",
        "-2.390",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-49",
        "-2.553",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-49",
        "-2.718",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-49",
        "-2.882",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-49",
        "-3.045",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-49",
        "-3.208",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-49",
        "-3.371",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-49",
        "-3.534",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-49",
        "-3.697",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-49",
        "-3.859",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-49",
        "-4.021",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-49",
        "-4.181",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-49",
        "-4.342",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-49",
        "-4.502",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-49",
        "-4.661",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-49",
        "-4.820",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-49",
        "-4.978",
        "11.951",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-49",
        "-5.135",
        "11.888",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-49",
        "-4.533",
        "12.748",
        "1.284",
        "1.255",
        "0.135"
    ],
    [
        "-9",
        "-49",
        "3.924",
        "16.128",
        "4.501",
        "2.498",
        "0.233"
    ],
    [
        "-8",
        "-49",
        "18.576",
        "17.623",
        "1.138",
        "1.152",
        "0.231"
    ],
    [
        "-7",
        "-49",
        "20.097",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-49",
        "19.850",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-49",
        "19.601",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-49",
        "19.352",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-49",
        "19.101",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-49",
        "18.849",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-49",
        "18.596",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-49",
        "18.344",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-49",
        "18.089",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-49",
        "17.834",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-49",
        "17.579",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-49",
        "17.323",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-49",
        "17.066",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-49",
        "16.809",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-49",
        "16.552",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-49",
        "16.294",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-49",
        "16.037",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-49",
        "15.780",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-49",
        "15.522",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-49",
        "15.265",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-49",
        "15.007",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-49",
        "14.750",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-49",
        "14.493",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-49",
        "14.237",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-49",
        "13.981",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-49",
        "13.726",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-49",
        "13.471",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-49",
        "13.218",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-49",
        "12.965",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-49",
        "12.712",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-49",
        "12.461",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-49",
        "12.211",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-49",
        "11.962",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-49",
        "11.714",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-49",
        "11.467",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-49",
        "11.222",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-49",
        "10.978",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-49",
        "10.736",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-49",
        "7.840",
        "8.696",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-49",
        "6.842",
        "5.177",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-49",
        "6.775",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-49",
        "6.710",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-49",
        "6.648",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-49",
        "6.589",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-49",
        "6.531",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-49",
        "6.477",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-49",
        "6.424",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-49",
        "6.375",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-49",
        "6.329",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-49",
        "6.284",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-49",
        "6.243",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-49",
        "6.204",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-49",
        "6.167",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-49",
        "6.133",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-49",
        "6.103",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-49",
        "6.074",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-49",
        "6.049",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-49",
        "6.025",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-49",
        "6.005",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-49",
        "5.987",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-49",
        "5.972",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-49",
        "5.959",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-49",
        "5.950",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-49",
        "5.942",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-49",
        "5.938",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-49",
        "5.937",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-49",
        "5.938",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-49",
        "5.941",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-49",
        "5.948",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-49",
        "5.958",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-49",
        "5.969",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-49",
        "5.984",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-49",
        "6.002",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-49",
        "6.022",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-49",
        "6.044",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-49",
        "6.070",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-49",
        "6.098",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-49",
        "6.128",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-49",
        "6.162",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-49",
        "6.198",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-49",
        "6.236",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-49",
        "6.278",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-49",
        "6.322",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-49",
        "6.368",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-49",
        "6.418",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-49",
        "6.469",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-49",
        "6.523",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-49",
        "6.579",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-49",
        "6.639",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-49",
        "6.701",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-49",
        "6.764",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-49",
        "6.831",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-49",
        "6.900",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-49",
        "6.972",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-49",
        "7.045",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-49",
        "7.122",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-49",
        "7.201",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-49",
        "7.281",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-49",
        "7.364",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-49",
        "7.450",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-49",
        "7.538",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-49",
        "7.627",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-49",
        "7.719",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-49",
        "7.814",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-49",
        "7.910",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-49",
        "8.009",
        "-7.558",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-49",
        "8.109",
        "-7.714",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-49",
        "8.211",
        "-7.868",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-49",
        "8.316",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-49",
        "8.423",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-49",
        "8.532",
        "-8.316",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-49",
        "8.642",
        "-8.460",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-49",
        "8.754",
        "-8.602",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-49",
        "22.129",
        "16.782",
        "9.249",
        "11.441",
        "-0.069"
    ],
    [
        "107",
        "-49",
        "8.985",
        "-8.877",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-49",
        "9.103",
        "-9.011",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-49",
        "35.907",
        "52.416",
        "2.345",
        "4.932",
        "0.304"
    ],
    [
        "110",
        "-49",
        "37.776",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-49",
        "37.049",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-49",
        "36.317",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-49",
        "35.584",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-49",
        "34.847",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-49",
        "34.107",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-49",
        "33.364",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-49",
        "32.619",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-49",
        "31.872",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-49",
        "31.123",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-49",
        "30.372",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-49",
        "31.244",
        "40.486",
        "5.530",
        "8.894",
        "0.278"
    ],
    [
        "122",
        "-49",
        "28.866",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-49",
        "28.112",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-49",
        "24.685",
        "33.741",
        "9.555",
        "19.654",
        "0.200"
    ],
    [
        "125",
        "-49",
        "22.276",
        "26.490",
        "11.579",
        "18.829",
        "-0.007"
    ],
    [
        "126",
        "-49",
        "23.560",
        "32.092",
        "13.619",
        "16.749",
        "-0.117"
    ],
    [
        "127",
        "-49",
        "21.411",
        "32.940",
        "12.365",
        "15.164",
        "-0.162"
    ],
    [
        "128",
        "-49",
        "24.332",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-49",
        "23.576",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-49",
        "22.820",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-49",
        "22.066",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-49",
        "21.313",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-49",
        "20.561",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-49",
        "19.810",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-49",
        "19.061",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-49",
        "18.314",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-49",
        "17.570",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-49",
        "16.827",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-49",
        "16.087",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-49",
        "15.351",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-49",
        "14.617",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-49",
        "13.886",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-49",
        "13.160",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-49",
        "12.436",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-49",
        "11.718",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-49",
        "11.002",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-49",
        "10.292",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-49",
        "9.586",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-49",
        "8.885",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-49",
        "8.189",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-49",
        "7.498",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-49",
        "6.813",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-49",
        "6.133",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-49",
        "5.460",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-49",
        "4.792",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-49",
        "4.132",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-49",
        "3.476",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-49",
        "2.829",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-49",
        "2.187",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-49",
        "1.553",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-49",
        "0.926",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-49",
        "0.307",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-49",
        "-0.304",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-49",
        "-17.756",
        "33.158",
        "2.261",
        "2.580",
        "0.255"
    ],
    [
        "165",
        "-49",
        "-30.331",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-49",
        "-30.675",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-49",
        "-31.023",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-49",
        "-31.375",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-49",
        "-31.730",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-49",
        "-32.088",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-49",
        "-32.450",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-49",
        "-32.815",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-49",
        "-33.183",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-49",
        "-33.554",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-49",
        "-33.927",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-49",
        "-34.304",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-49",
        "-34.683",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-49",
        "-35.064",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-49",
        "-35.448",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-48",
        "-36.796",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-48",
        "-37.179",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-48",
        "-37.563",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-48",
        "-37.949",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-48",
        "-38.337",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-48",
        "-38.727",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-48",
        "-39.118",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-48",
        "-39.511",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-48",
        "-39.905",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-48",
        "-40.300",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-48",
        "-40.696",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-48",
        "-41.093",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-48",
        "-41.490",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-48",
        "-41.889",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-48",
        "-42.288",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-48",
        "-42.687",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-48",
        "-43.087",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-48",
        "-43.487",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-48",
        "-43.887",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-48",
        "-44.287",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-48",
        "-44.687",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-48",
        "-45.087",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-48",
        "-45.486",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-48",
        "-45.884",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-48",
        "-46.282",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-48",
        "-46.679",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-48",
        "-47.075",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-48",
        "-47.471",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-48",
        "-47.865",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-48",
        "-48.258",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-48",
        "-48.649",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-48",
        "-49.039",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-48",
        "-49.427",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-48",
        "-49.814",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-48",
        "-50.199",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-48",
        "-50.581",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-48",
        "-50.962",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-48",
        "-51.340",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-48",
        "-51.717",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-48",
        "-52.090",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-48",
        "-52.461",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-48",
        "-52.830",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-48",
        "-53.196",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-48",
        "-53.558",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-48",
        "-53.918",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-48",
        "-54.275",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-48",
        "-54.629",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-48",
        "-54.979",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-48",
        "-55.325",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-48",
        "-55.669",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-48",
        "-56.008",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-48",
        "-56.344",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-48",
        "-56.676",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-48",
        "-57.004",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-48",
        "-57.328",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-48",
        "-57.648",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-48",
        "-57.964",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-48",
        "-58.275",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-48",
        "-58.582",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-48",
        "-58.884",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-48",
        "-59.182",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-48",
        "-59.475",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-48",
        "-59.763",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-48",
        "-60.046",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-48",
        "-60.325",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-48",
        "-60.598",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-48",
        "-60.866",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-48",
        "17.429",
        "3.256",
        "4.074",
        "7.021",
        "-0.057"
    ],
    [
        "-112",
        "-48",
        "23.741",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-48",
        "23.713",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-48",
        "23.683",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-48",
        "23.650",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-48",
        "23.614",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-48",
        "23.576",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-48",
        "23.536",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-48",
        "23.493",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-48",
        "23.447",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-48",
        "23.399",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-48",
        "23.348",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-48",
        "23.294",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-48",
        "23.239",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-48",
        "23.181",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-48",
        "23.120",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-48",
        "23.056",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-48",
        "22.991",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-48",
        "22.923",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-48",
        "22.853",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-48",
        "22.780",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-48",
        "22.705",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-48",
        "22.627",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-48",
        "22.548",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-48",
        "22.467",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-48",
        "22.382",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-48",
        "22.296",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-48",
        "22.207",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-48",
        "22.117",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-48",
        "22.024",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-48",
        "21.929",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-48",
        "21.832",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-48",
        "21.733",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-48",
        "21.631",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-48",
        "21.529",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-48",
        "21.423",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-48",
        "21.317",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-48",
        "20.523",
        "8.492",
        "9.199",
        "5.717",
        "0.240"
    ],
    [
        "-75",
        "-48",
        "18.664",
        "9.081",
        "12.977",
        "8.242",
        "0.184"
    ],
    [
        "-74",
        "-48",
        "16.109",
        "9.250",
        "13.753",
        "10.986",
        "0.073"
    ],
    [
        "-73",
        "-48",
        "12.994",
        "9.149",
        "14.547",
        "13.318",
        "0.003"
    ],
    [
        "-72",
        "-48",
        "9.133",
        "9.080",
        "15.127",
        "13.763",
        "-0.041"
    ],
    [
        "-71",
        "-48",
        "4.379",
        "9.143",
        "3.360",
        "4.151",
        "-0.053"
    ],
    [
        "-70",
        "-48",
        "3.763",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-48",
        "3.641",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-48",
        "3.518",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-48",
        "3.394",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-48",
        "3.267",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-48",
        "3.138",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-48",
        "3.008",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-48",
        "2.876",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-48",
        "2.743",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-48",
        "2.607",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-48",
        "2.471",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-48",
        "2.333",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-48",
        "2.193",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-48",
        "2.052",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-48",
        "1.911",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-48",
        "1.767",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-48",
        "1.622",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-48",
        "1.476",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-48",
        "1.329",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-48",
        "1.181",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-48",
        "1.031",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-48",
        "0.881",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-48",
        "0.730",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-48",
        "0.578",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-48",
        "0.424",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-48",
        "0.269",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-48",
        "0.115",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-48",
        "-0.041",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-48",
        "-0.197",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-48",
        "-0.355",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-48",
        "-0.512",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-48",
        "-0.670",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-48",
        "-0.829",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-48",
        "-0.989",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-48",
        "-1.148",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-48",
        "-1.308",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-48",
        "-1.469",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-48",
        "-1.630",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-48",
        "-1.790",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-48",
        "-1.952",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-48",
        "-2.113",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-48",
        "-2.275",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-48",
        "-2.436",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-48",
        "-2.598",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-48",
        "-2.759",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-48",
        "-2.921",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-48",
        "-3.082",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-48",
        "-3.242",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-48",
        "-3.403",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-48",
        "-3.563",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-48",
        "-3.723",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-48",
        "-3.883",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-48",
        "-4.041",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-48",
        "-4.200",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-48",
        "-4.359",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-48",
        "-4.516",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-48",
        "-4.673",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-48",
        "-4.829",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-48",
        "-4.984",
        "11.951",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-48",
        "-5.138",
        "11.888",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-48",
        "12.171",
        "15.394",
        "4.054",
        "3.054",
        "0.162"
    ],
    [
        "-9",
        "-48",
        "20.832",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-48",
        "20.590",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-48",
        "20.348",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-48",
        "20.104",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-48",
        "19.859",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-48",
        "19.614",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-48",
        "19.367",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-48",
        "19.119",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-48",
        "18.870",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-48",
        "18.620",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-48",
        "18.371",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-48",
        "18.119",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-48",
        "17.867",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-48",
        "17.615",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-48",
        "17.363",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-48",
        "17.110",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-48",
        "16.857",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-48",
        "16.603",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-48",
        "16.350",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-48",
        "16.097",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-48",
        "15.843",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-48",
        "15.589",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-48",
        "15.336",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-48",
        "15.083",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-48",
        "14.830",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-48",
        "14.577",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-48",
        "14.326",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-48",
        "14.074",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-48",
        "13.824",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-48",
        "13.574",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-48",
        "13.324",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-48",
        "13.077",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-48",
        "12.829",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-48",
        "12.582",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-48",
        "12.337",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-48",
        "12.093",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-48",
        "11.851",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-48",
        "11.609",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-48",
        "11.368",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-48",
        "11.130",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-48",
        "10.893",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-48",
        "8.363",
        "8.971",
        "1.596",
        "2.426",
        "0.074"
    ],
    [
        "33",
        "-48",
        "7.194",
        "4.991",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-48",
        "7.129",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-48",
        "7.069",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-48",
        "7.010",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-48",
        "6.954",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-48",
        "6.900",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-48",
        "6.849",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-48",
        "6.800",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-48",
        "6.754",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-48",
        "6.710",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-48",
        "6.669",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-48",
        "6.631",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-48",
        "6.595",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-48",
        "6.562",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-48",
        "6.532",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-48",
        "6.503",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-48",
        "6.478",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-48",
        "6.455",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-48",
        "6.435",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-48",
        "6.418",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-48",
        "6.403",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-48",
        "6.390",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-48",
        "6.381",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-48",
        "6.375",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-48",
        "6.370",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-48",
        "6.369",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-48",
        "6.369",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-48",
        "6.374",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-48",
        "6.380",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-48",
        "6.389",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-48",
        "6.400",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-48",
        "6.415",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-48",
        "6.432",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-48",
        "6.452",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-48",
        "6.474",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-48",
        "6.500",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-48",
        "6.527",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-48",
        "6.557",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-48",
        "6.590",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-48",
        "6.626",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-48",
        "6.664",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-48",
        "6.704",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-48",
        "6.747",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-48",
        "6.793",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-48",
        "6.841",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-48",
        "6.892",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-48",
        "6.945",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-48",
        "7.001",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-48",
        "7.060",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-48",
        "7.120",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-48",
        "7.184",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-48",
        "7.249",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-48",
        "7.317",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-48",
        "7.387",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-48",
        "7.460",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-48",
        "7.535",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-48",
        "7.612",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-48",
        "7.692",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-48",
        "7.774",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-48",
        "7.858",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-48",
        "7.944",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-48",
        "8.033",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-48",
        "8.123",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-48",
        "8.216",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-48",
        "8.311",
        "-7.399",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-48",
        "12.489",
        "-2.229",
        "1.922",
        "3.345",
        "0.054"
    ],
    [
        "99",
        "-48",
        "25.240",
        "19.942",
        "7.922",
        "8.038",
        "-0.093"
    ],
    [
        "100",
        "-48",
        "10.988",
        "-4.129",
        "1.947",
        "3.375",
        "0.067"
    ],
    [
        "101",
        "-48",
        "8.712",
        "-8.020",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-48",
        "8.816",
        "-8.169",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-48",
        "39.559",
        "47.517",
        "3.938",
        "6.511",
        "0.039"
    ],
    [
        "104",
        "-48",
        "42.307",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-48",
        "41.617",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-48",
        "40.922",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-48",
        "40.221",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-48",
        "39.517",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-48",
        "38.809",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-48",
        "38.096",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-48",
        "37.380",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-48",
        "36.661",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-48",
        "35.937",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-48",
        "35.211",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-48",
        "34.483",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-48",
        "33.752",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-48",
        "33.018",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-48",
        "32.282",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-48",
        "31.545",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-48",
        "30.806",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-48",
        "30.065",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-48",
        "29.322",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-48",
        "28.580",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-48",
        "27.836",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-48",
        "27.092",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-48",
        "26.347",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-48",
        "25.603",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-48",
        "24.858",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-48",
        "24.114",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-48",
        "23.370",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-48",
        "22.627",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-48",
        "21.885",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-48",
        "21.144",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-48",
        "20.405",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-48",
        "19.667",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-48",
        "18.932",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-48",
        "18.199",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-48",
        "17.468",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-48",
        "16.740",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-48",
        "16.014",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-48",
        "15.292",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-48",
        "14.573",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-48",
        "13.857",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-48",
        "13.144",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-48",
        "12.437",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-48",
        "11.732",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-48",
        "11.033",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-48",
        "10.338",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-48",
        "9.647",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-48",
        "8.962",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-48",
        "8.282",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-48",
        "7.607",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-48",
        "6.938",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-48",
        "6.276",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-48",
        "5.617",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-48",
        "4.967",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-48",
        "4.322",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-48",
        "3.684",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-48",
        "3.053",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-48",
        "2.429",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-48",
        "1.812",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-48",
        "1.201",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-48",
        "0.600",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-48",
        "-0.838",
        "45.734",
        "0.878",
        "1.105",
        "-0.027"
    ],
    [
        "165",
        "-48",
        "-21.192",
        "31.874",
        "4.000",
        "3.096",
        "-0.176"
    ],
    [
        "166",
        "-48",
        "-31.716",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-48",
        "-32.059",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-48",
        "-32.405",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-48",
        "-32.755",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-48",
        "-33.108",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-48",
        "-33.464",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-48",
        "-33.823",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-48",
        "-34.185",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-48",
        "-34.550",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-48",
        "-34.918",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-48",
        "-35.289",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-48",
        "-35.662",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-48",
        "-36.038",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-48",
        "-36.416",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-47",
        "-37.747",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-47",
        "-38.123",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-47",
        "-38.502",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-47",
        "-38.882",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-47",
        "-39.264",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-47",
        "-39.647",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-47",
        "-40.032",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-47",
        "-40.418",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-47",
        "-40.806",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-47",
        "-41.195",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-47",
        "-41.585",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-47",
        "-41.975",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-47",
        "-42.367",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-47",
        "-42.759",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-47",
        "-43.152",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-47",
        "-43.545",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-47",
        "-43.938",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-47",
        "-44.332",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-47",
        "-44.725",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-47",
        "-45.119",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-47",
        "-45.513",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-47",
        "-45.906",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-47",
        "-46.298",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-47",
        "-46.691",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-47",
        "-47.082",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-47",
        "-47.473",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-47",
        "-47.863",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-47",
        "-48.252",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-47",
        "-48.640",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-47",
        "-49.026",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-47",
        "-49.412",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-47",
        "-49.795",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-47",
        "-50.178",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-47",
        "-50.558",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-47",
        "-50.937",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-47",
        "-51.313",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-47",
        "-51.688",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-47",
        "-52.060",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-47",
        "-52.431",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-47",
        "-52.798",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-47",
        "-53.164",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-47",
        "-53.526",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-47",
        "-53.886",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-47",
        "-54.243",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-47",
        "-54.597",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-47",
        "-54.948",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-47",
        "-55.296",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-47",
        "-55.641",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-47",
        "-55.982",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-47",
        "-56.320",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-47",
        "-56.654",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-47",
        "-56.985",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-47",
        "-57.311",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-47",
        "-57.634",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-47",
        "-57.953",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-47",
        "-58.268",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-47",
        "-58.579",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-47",
        "-58.885",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-47",
        "-59.187",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-47",
        "-59.484",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-47",
        "-59.777",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-47",
        "-60.066",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-47",
        "-60.349",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-47",
        "-60.628",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-47",
        "-60.902",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-47",
        "-61.171",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-47",
        "-61.435",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-47",
        "1.723",
        "6.556",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-47",
        "23.895",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-47",
        "23.867",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-47",
        "23.838",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-47",
        "23.805",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-47",
        "23.770",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-47",
        "23.733",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-47",
        "23.693",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-47",
        "23.650",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-47",
        "23.606",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-47",
        "23.558",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-47",
        "23.508",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-47",
        "23.456",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-47",
        "23.401",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-47",
        "23.343",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-47",
        "23.284",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-47",
        "23.221",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-47",
        "23.158",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-47",
        "23.090",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-47",
        "23.021",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-47",
        "22.949",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-47",
        "22.875",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-47",
        "22.799",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-47",
        "22.721",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-47",
        "22.640",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-47",
        "22.557",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-47",
        "22.473",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-47",
        "22.386",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-47",
        "22.297",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-47",
        "22.205",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-47",
        "22.112",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-47",
        "22.016",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-47",
        "21.918",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-47",
        "21.819",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-47",
        "21.717",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-47",
        "21.615",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-47",
        "21.510",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-47",
        "21.288",
        "8.598",
        "2.504",
        "2.832",
        "0.147"
    ],
    [
        "-75",
        "-47",
        "19.834",
        "10.083",
        "12.152",
        "9.235",
        "0.153"
    ],
    [
        "-74",
        "-47",
        "17.976",
        "10.243",
        "13.513",
        "12.478",
        "0.075"
    ],
    [
        "-73",
        "-47",
        "14.896",
        "10.035",
        "14.702",
        "15.193",
        "0.030"
    ],
    [
        "-72",
        "-47",
        "10.305",
        "9.730",
        "15.623",
        "15.599",
        "0.005"
    ],
    [
        "-71",
        "-47",
        "4.350",
        "9.135",
        "3.478",
        "4.523",
        "-0.011"
    ],
    [
        "-70",
        "-47",
        "3.618",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-47",
        "3.499",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-47",
        "3.378",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-47",
        "3.255",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-47",
        "3.130",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-47",
        "3.004",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-47",
        "2.876",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-47",
        "2.746",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-47",
        "2.615",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-47",
        "2.482",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-47",
        "2.347",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-47",
        "2.212",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-47",
        "2.075",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-47",
        "1.936",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-47",
        "1.796",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-47",
        "1.655",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-47",
        "1.513",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-47",
        "1.369",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-47",
        "1.224",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-47",
        "1.078",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-47",
        "0.931",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-47",
        "0.783",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-47",
        "0.633",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-47",
        "0.484",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-47",
        "0.333",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-47",
        "0.182",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-47",
        "0.029",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-47",
        "-0.125",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-47",
        "-0.279",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-47",
        "-0.433",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-47",
        "-0.588",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-47",
        "-0.744",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-47",
        "-0.901",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-47",
        "-1.057",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-47",
        "-1.215",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-47",
        "-1.372",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-47",
        "-1.530",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-47",
        "-1.688",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-47",
        "-1.846",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-47",
        "-2.006",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-47",
        "-2.164",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-47",
        "-2.323",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-47",
        "-2.482",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-47",
        "-2.641",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-47",
        "-2.800",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-47",
        "-2.958",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-47",
        "-3.116",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-47",
        "-3.275",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-47",
        "-3.433",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-47",
        "-3.591",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-47",
        "-3.748",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-47",
        "-3.905",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-47",
        "-4.062",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-47",
        "-4.218",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-47",
        "-4.373",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-47",
        "-4.529",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-47",
        "-4.683",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-47",
        "22.001",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-47",
        "21.771",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-47",
        "21.538",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-47",
        "20.976",
        "17.309",
        "3.438",
        "2.964",
        "0.120"
    ],
    [
        "-9",
        "-47",
        "21.068",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-47",
        "20.831",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-47",
        "20.593",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-47",
        "20.353",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-47",
        "20.112",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-47",
        "19.870",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-47",
        "19.627",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-47",
        "19.383",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-47",
        "19.138",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-47",
        "18.892",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-47",
        "18.646",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-47",
        "18.399",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-47",
        "18.151",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-47",
        "17.903",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-47",
        "17.654",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-47",
        "17.406",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-47",
        "17.157",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-47",
        "16.907",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-47",
        "16.658",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-47",
        "16.408",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-47",
        "16.158",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-47",
        "15.909",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-47",
        "15.660",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-47",
        "15.411",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-47",
        "15.162",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-47",
        "14.913",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-47",
        "14.666",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-47",
        "14.419",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-47",
        "14.171",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-47",
        "13.926",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-47",
        "13.680",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-47",
        "13.436",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-47",
        "13.193",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-47",
        "12.950",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-47",
        "12.708",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-47",
        "12.468",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-47",
        "12.229",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-47",
        "11.992",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-47",
        "11.756",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-47",
        "11.521",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-47",
        "11.287",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-47",
        "10.955",
        "18.099",
        "0.546",
        "1.125",
        "-0.016"
    ],
    [
        "33",
        "-47",
        "8.501",
        "8.458",
        "2.221",
        "2.924",
        "0.095"
    ],
    [
        "34",
        "-47",
        "7.547",
        "4.804",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "-47",
        "7.488",
        "4.616",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "-47",
        "7.429",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-47",
        "7.375",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-47",
        "7.322",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-47",
        "7.272",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-47",
        "7.223",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-47",
        "7.177",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-47",
        "7.135",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-47",
        "7.094",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-47",
        "7.057",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-47",
        "7.022",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-47",
        "6.989",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-47",
        "6.958",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-47",
        "6.930",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-47",
        "6.906",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-47",
        "6.883",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-47",
        "6.864",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-47",
        "6.847",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-47",
        "6.832",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-47",
        "6.819",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-47",
        "6.810",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-47",
        "6.804",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-47",
        "6.799",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-47",
        "6.798",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-47",
        "6.799",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-47",
        "6.803",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-47",
        "6.809",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-47",
        "6.819",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-47",
        "6.830",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-47",
        "6.844",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-47",
        "6.860",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-47",
        "6.880",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-47",
        "6.902",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-47",
        "6.927",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-47",
        "6.954",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-47",
        "6.984",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-47",
        "7.016",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-47",
        "7.051",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-47",
        "7.088",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-47",
        "7.128",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-47",
        "7.171",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-47",
        "7.216",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-47",
        "7.263",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-47",
        "7.314",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-47",
        "7.366",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-47",
        "7.421",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-47",
        "7.478",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-47",
        "7.538",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-47",
        "7.600",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-47",
        "7.665",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-47",
        "7.731",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-47",
        "7.801",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-47",
        "7.872",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-47",
        "7.946",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-47",
        "8.022",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-47",
        "8.100",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-47",
        "8.181",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-47",
        "8.264",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-47",
        "8.349",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-47",
        "8.436",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-47",
        "8.525",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-47",
        "8.617",
        "-7.238",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-47",
        "43.680",
        "45.267",
        "1.986",
        "3.512",
        "-0.012"
    ],
    [
        "98",
        "-47",
        "46.519",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-47",
        "45.871",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-47",
        "45.218",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-47",
        "44.559",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-47",
        "43.895",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-47",
        "43.225",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-47",
        "42.550",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-47",
        "41.870",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-47",
        "41.185",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-47",
        "40.496",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-47",
        "39.803",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-47",
        "39.106",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-47",
        "38.405",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-47",
        "37.700",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-47",
        "36.991",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-47",
        "36.280",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-47",
        "35.566",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-47",
        "34.849",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-47",
        "34.129",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-47",
        "33.407",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-47",
        "32.684",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-47",
        "31.957",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-47",
        "31.230",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-47",
        "30.501",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-47",
        "29.770",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-47",
        "29.039",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-47",
        "28.307",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-47",
        "27.575",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-47",
        "26.842",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-47",
        "26.109",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-47",
        "25.376",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-47",
        "24.643",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-47",
        "23.912",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-47",
        "23.181",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-47",
        "22.451",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-47",
        "21.722",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-47",
        "20.995",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-47",
        "20.269",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-47",
        "19.544",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-47",
        "18.823",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-47",
        "18.104",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-47",
        "17.387",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-47",
        "16.673",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-47",
        "15.962",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-47",
        "15.254",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-47",
        "14.550",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-47",
        "13.849",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-47",
        "13.152",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-47",
        "12.459",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-47",
        "11.771",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-47",
        "11.087",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-47",
        "10.408",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-47",
        "9.733",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-47",
        "9.064",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-47",
        "8.400",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-47",
        "7.741",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-47",
        "7.088",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-47",
        "6.442",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-47",
        "5.801",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-47",
        "5.167",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-47",
        "4.538",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-47",
        "3.917",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-47",
        "3.303",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-47",
        "2.696",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-47",
        "2.096",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-47",
        "1.503",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-47",
        "0.918",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-47",
        "-4.252",
        "43.141",
        "5.693",
        "4.395",
        "-0.506"
    ],
    [
        "166",
        "-47",
        "-21.008",
        "33.838",
        "5.772",
        "4.792",
        "-0.248"
    ],
    [
        "167",
        "-47",
        "-33.085",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-47",
        "-33.425",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-47",
        "-33.769",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-47",
        "-34.117",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-47",
        "-34.467",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-47",
        "-34.821",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-47",
        "-35.177",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-47",
        "-35.537",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-47",
        "-35.899",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-47",
        "-36.264",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-47",
        "-36.631",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-47",
        "-37.001",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-47",
        "-37.373",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-46",
        "-38.686",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-46",
        "-39.056",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-46",
        "-39.429",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-46",
        "-39.802",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-46",
        "-40.178",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-46",
        "-40.555",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-46",
        "-40.934",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-46",
        "-41.314",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-46",
        "-41.695",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-46",
        "-42.077",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-46",
        "-42.461",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-46",
        "-42.845",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-46",
        "-43.230",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-46",
        "-43.616",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-46",
        "-44.002",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-46",
        "-44.389",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-46",
        "-44.776",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-46",
        "-45.163",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-46",
        "-45.550",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-46",
        "-45.937",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-46",
        "-46.324",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-46",
        "-46.711",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-46",
        "-47.097",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-46",
        "-47.483",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-46",
        "-47.868",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-46",
        "-48.253",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-46",
        "-48.636",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-46",
        "-49.019",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-46",
        "-49.400",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-46",
        "-49.780",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-46",
        "-50.159",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-46",
        "-50.537",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-46",
        "-50.913",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-46",
        "-51.287",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-46",
        "-51.659",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-46",
        "-52.030",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-46",
        "-52.398",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-46",
        "-52.765",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-46",
        "-53.129",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-46",
        "-53.490",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-46",
        "-53.850",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-46",
        "-54.206",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-46",
        "-54.560",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-46",
        "-54.911",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-46",
        "-55.260",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-46",
        "-55.605",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-46",
        "-55.947",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-46",
        "-56.286",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-46",
        "-56.622",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-46",
        "-56.954",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-46",
        "-57.283",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-46",
        "-57.608",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-46",
        "-57.929",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-46",
        "-58.247",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-46",
        "-58.560",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-46",
        "-58.870",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-46",
        "-59.176",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-46",
        "-59.477",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-46",
        "-59.774",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-46",
        "-60.067",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-46",
        "-60.355",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-46",
        "-60.638",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-46",
        "-60.917",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-46",
        "-61.192",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-46",
        "-61.461",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-46",
        "-61.726",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-46",
        "-61.985",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-46",
        "-37.571",
        "14.966",
        "19.302",
        "21.534",
        "0.029"
    ],
    [
        "-112",
        "-46",
        "24.042",
        "2.029",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-46",
        "24.015",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-46",
        "23.986",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-46",
        "23.953",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-46",
        "23.919",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-46",
        "23.883",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-46",
        "23.844",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-46",
        "23.801",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-46",
        "23.757",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-46",
        "23.710",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-46",
        "23.661",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-46",
        "23.610",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-46",
        "23.556",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-46",
        "23.499",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-46",
        "23.440",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-46",
        "23.379",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-46",
        "23.316",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-46",
        "23.250",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-46",
        "23.182",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-46",
        "23.112",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-46",
        "23.039",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-46",
        "22.964",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-46",
        "22.887",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-46",
        "22.807",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-46",
        "22.726",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-46",
        "22.643",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-46",
        "22.557",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-46",
        "22.469",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-46",
        "22.379",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-46",
        "22.288",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-46",
        "22.194",
        "7.558",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-46",
        "22.098",
        "7.714",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-46",
        "22.000",
        "7.868",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-46",
        "21.900",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-46",
        "21.798",
        "8.169",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-46",
        "21.695",
        "8.316",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-46",
        "23.171",
        "9.903",
        "9.624",
        "7.594",
        "0.226"
    ],
    [
        "-75",
        "-46",
        "25.431",
        "11.316",
        "12.166",
        "9.798",
        "0.195"
    ],
    [
        "-74",
        "-46",
        "24.480",
        "11.734",
        "13.256",
        "12.873",
        "0.108"
    ],
    [
        "-73",
        "-46",
        "20.163",
        "11.869",
        "14.649",
        "15.670",
        "0.066"
    ],
    [
        "-72",
        "-46",
        "14.185",
        "11.826",
        "16.433",
        "16.565",
        "0.050"
    ],
    [
        "-71",
        "-46",
        "8.215",
        "10.769",
        "14.314",
        "16.283",
        "-0.004"
    ],
    [
        "-70",
        "-46",
        "4.700",
        "9.714",
        "13.048",
        "15.579",
        "-0.010"
    ],
    [
        "-69",
        "-46",
        "3.356",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-46",
        "3.237",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-46",
        "3.116",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-46",
        "2.993",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-46",
        "2.869",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-46",
        "2.743",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-46",
        "2.615",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-46",
        "2.487",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-46",
        "2.356",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-46",
        "2.224",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-46",
        "2.090",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-46",
        "1.955",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-46",
        "1.819",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-46",
        "1.681",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-46",
        "1.542",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-46",
        "1.402",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-46",
        "1.260",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-46",
        "1.118",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-46",
        "0.975",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-46",
        "0.830",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-46",
        "0.685",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-46",
        "0.538",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-46",
        "0.391",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-46",
        "0.242",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-46",
        "0.093",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-46",
        "-0.058",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-46",
        "-0.208",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-46",
        "-0.359",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-46",
        "-0.511",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-46",
        "-0.665",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-46",
        "-0.817",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-46",
        "-0.971",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-46",
        "-1.126",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-46",
        "-1.281",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-46",
        "-1.435",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-46",
        "-1.590",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-46",
        "-1.746",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-46",
        "-1.901",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-46",
        "-2.058",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-46",
        "-2.214",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-46",
        "-2.371",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-46",
        "-2.526",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-46",
        "-2.683",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-46",
        "-2.839",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-46",
        "-2.995",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-46",
        "-3.151",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-46",
        "-3.307",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-46",
        "-3.462",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-46",
        "-3.618",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-46",
        "-3.772",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-46",
        "-3.927",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-46",
        "-4.080",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-46",
        "-4.234",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-46",
        "-4.388",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-46",
        "-4.539",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-46",
        "15.408",
        "16.388",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-46",
        "22.216",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-46",
        "21.989",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-46",
        "21.761",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-46",
        "21.530",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-46",
        "21.298",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-46",
        "21.065",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-46",
        "20.831",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-46",
        "20.595",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-46",
        "20.358",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-46",
        "20.120",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-46",
        "19.881",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-46",
        "19.641",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-46",
        "19.400",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-46",
        "19.159",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-46",
        "18.916",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-46",
        "18.673",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-46",
        "18.430",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-46",
        "18.186",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-46",
        "17.941",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-46",
        "17.697",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-46",
        "17.451",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-46",
        "17.206",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-46",
        "16.960",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-46",
        "16.714",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-46",
        "16.470",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-46",
        "16.224",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-46",
        "15.978",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-46",
        "15.734",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-46",
        "15.489",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-46",
        "15.245",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-46",
        "15.002",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-46",
        "14.758",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-46",
        "14.515",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-46",
        "14.273",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-46",
        "14.032",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-46",
        "13.792",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-46",
        "13.552",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-46",
        "13.314",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-46",
        "13.076",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-46",
        "12.840",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-46",
        "12.605",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-46",
        "12.371",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-46",
        "12.139",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-46",
        "11.908",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-46",
        "11.678",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-46",
        "11.450",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-46",
        "10.563",
        "15.568",
        "1.928",
        "2.611",
        "0.114"
    ],
    [
        "34",
        "-46",
        "8.779",
        "8.940",
        "2.227",
        "2.680",
        "-0.028"
    ],
    [
        "35",
        "-46",
        "7.944",
        "5.108",
        "1.499",
        "2.110",
        "0.028"
    ],
    [
        "36",
        "-46",
        "7.847",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-46",
        "7.792",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-46",
        "7.740",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-46",
        "7.691",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-46",
        "7.643",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-46",
        "7.599",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-46",
        "7.557",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-46",
        "7.517",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-46",
        "7.480",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-46",
        "7.445",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-46",
        "7.413",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-46",
        "7.383",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-46",
        "7.357",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-46",
        "7.332",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-46",
        "7.310",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-46",
        "7.290",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-46",
        "7.273",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-46",
        "7.259",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-46",
        "7.247",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-46",
        "7.238",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-46",
        "7.231",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-46",
        "7.227",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-46",
        "7.226",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-46",
        "7.226",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-46",
        "7.231",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-46",
        "7.236",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-46",
        "7.245",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-46",
        "7.256",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-46",
        "7.271",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-46",
        "7.287",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-46",
        "7.307",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-46",
        "7.328",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-46",
        "7.353",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-46",
        "7.379",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-46",
        "7.408",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-46",
        "7.440",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-46",
        "7.474",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-46",
        "7.511",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-46",
        "7.550",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-46",
        "7.593",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-46",
        "7.636",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-46",
        "7.683",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-46",
        "7.732",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-46",
        "7.785",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-46",
        "7.838",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-46",
        "7.894",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-46",
        "7.954",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-46",
        "8.015",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-46",
        "8.078",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-46",
        "8.144",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-46",
        "8.211",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-46",
        "8.282",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-46",
        "8.355",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-46",
        "8.429",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-46",
        "8.507",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-46",
        "8.586",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-46",
        "8.668",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-46",
        "8.751",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-46",
        "8.837",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-46",
        "8.925",
        "-7.075",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-46",
        "27.614",
        "20.766",
        "7.926",
        "9.359",
        "0.099"
    ],
    [
        "97",
        "-46",
        "47.313",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-46",
        "46.682",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-46",
        "46.045",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-46",
        "45.403",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-46",
        "44.755",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-46",
        "44.101",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-46",
        "43.442",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-46",
        "42.779",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-46",
        "42.109",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-46",
        "41.437",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-46",
        "40.759",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-46",
        "40.077",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-46",
        "39.390",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-46",
        "38.702",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-46",
        "38.008",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-46",
        "37.311",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-46",
        "36.612",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-46",
        "35.909",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-46",
        "35.204",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-46",
        "34.497",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-46",
        "33.787",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-46",
        "33.074",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-46",
        "32.360",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-46",
        "31.645",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-46",
        "30.927",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-46",
        "30.209",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-46",
        "29.491",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-46",
        "28.770",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-46",
        "28.050",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-46",
        "27.329",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-46",
        "26.608",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-46",
        "25.887",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-46",
        "25.166",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-46",
        "24.447",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-46",
        "23.728",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-46",
        "23.010",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-46",
        "22.293",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-46",
        "21.577",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-46",
        "20.864",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-46",
        "20.151",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-46",
        "19.442",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-46",
        "18.734",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-46",
        "18.029",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-46",
        "17.326",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-46",
        "16.627",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-46",
        "15.931",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-46",
        "15.238",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-46",
        "14.549",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-46",
        "13.864",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-46",
        "13.183",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-46",
        "12.506",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-46",
        "11.832",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-46",
        "11.165",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-46",
        "10.501",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-46",
        "9.842",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-46",
        "9.190",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-46",
        "8.541",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-46",
        "7.899",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-46",
        "7.263",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-46",
        "6.633",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-46",
        "6.009",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-46",
        "5.392",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-46",
        "4.781",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-46",
        "4.177",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-46",
        "3.579",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-46",
        "2.989",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-46",
        "2.406",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-46",
        "1.830",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-46",
        "1.263",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-46",
        "-10.718",
        "41.221",
        "6.653",
        "5.097",
        "-0.217"
    ],
    [
        "167",
        "-46",
        "-27.909",
        "35.276",
        "4.035",
        "4.885",
        "-0.431"
    ],
    [
        "168",
        "-46",
        "-33.926",
        "31.327",
        "6.611",
        "4.437",
        "-0.270"
    ],
    [
        "169",
        "-46",
        "-34.774",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-46",
        "-35.116",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-46",
        "-35.460",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-46",
        "-35.808",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-46",
        "-36.159",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-46",
        "-36.512",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-46",
        "-36.869",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-46",
        "-37.227",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-46",
        "-37.589",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-46",
        "-37.952",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-46",
        "-38.318",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-45",
        "-39.614",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-45",
        "-39.978",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-45",
        "-40.343",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-45",
        "-40.711",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-45",
        "-41.080",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-45",
        "-41.451",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-45",
        "-41.823",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-45",
        "-42.197",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-45",
        "-42.571",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-45",
        "-42.947",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-45",
        "-43.324",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-45",
        "-43.702",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-45",
        "-44.080",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-45",
        "-44.459",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-45",
        "-44.839",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-45",
        "-45.219",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-45",
        "-45.600",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-45",
        "-45.980",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-45",
        "-46.361",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-45",
        "-46.741",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-45",
        "-47.122",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-45",
        "-47.502",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-45",
        "-47.882",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-45",
        "-48.261",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-45",
        "-48.640",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-45",
        "-49.017",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-45",
        "-49.394",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-45",
        "-49.771",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-45",
        "-50.145",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-45",
        "-50.519",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-45",
        "-50.892",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-45",
        "-51.263",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-45",
        "-51.632",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-45",
        "-52.000",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-45",
        "-52.366",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-45",
        "-52.730",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-45",
        "-53.092",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-45",
        "-53.453",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-45",
        "-53.810",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-45",
        "-54.166",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-45",
        "-54.519",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-45",
        "-54.870",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-45",
        "-55.218",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-45",
        "-55.563",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-45",
        "-55.905",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-45",
        "-56.245",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-45",
        "-56.581",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-45",
        "-56.914",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-45",
        "-57.244",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-45",
        "-57.571",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-45",
        "-57.894",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-45",
        "-58.214",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-45",
        "-58.530",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-45",
        "-58.842",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-45",
        "-59.150",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-45",
        "-59.454",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-45",
        "-59.755",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-45",
        "-60.051",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-45",
        "-60.343",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-45",
        "-60.631",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-45",
        "-60.914",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-45",
        "-61.193",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-45",
        "-61.467",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-45",
        "-61.736",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-45",
        "-62.001",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-45",
        "-62.261",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-45",
        "-62.516",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-45",
        "-62.767",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-45",
        "-0.324",
        "6.798",
        "19.688",
        "21.871",
        "0.023"
    ],
    [
        "-111",
        "-45",
        "24.154",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-45",
        "24.125",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-45",
        "24.095",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-45",
        "24.061",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-45",
        "24.025",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-45",
        "23.985",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-45",
        "23.945",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-45",
        "23.901",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-45",
        "23.856",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-45",
        "23.807",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-45",
        "23.756",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-45",
        "23.704",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-45",
        "23.647",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-45",
        "23.590",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-45",
        "23.530",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-45",
        "23.467",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-45",
        "23.403",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-45",
        "23.336",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-45",
        "23.267",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-45",
        "23.195",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-45",
        "23.122",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-45",
        "23.046",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-45",
        "22.968",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-45",
        "22.888",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-45",
        "22.806",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-45",
        "22.721",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-45",
        "22.635",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-45",
        "22.547",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-45",
        "22.457",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-45",
        "26.592",
        "9.181",
        "13.185",
        "9.482",
        "0.244"
    ],
    [
        "-81",
        "-45",
        "32.618",
        "8.790",
        "14.709",
        "8.501",
        "0.269"
    ],
    [
        "-80",
        "-45",
        "23.738",
        "7.235",
        "2.890",
        "4.283",
        "0.073"
    ],
    [
        "-79",
        "-45",
        "22.076",
        "8.020",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-45",
        "69.367",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-45",
        "69.151",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-45",
        "55.652",
        "15.562",
        "10.274",
        "6.848",
        "0.244"
    ],
    [
        "-75",
        "-45",
        "43.249",
        "14.932",
        "12.701",
        "9.104",
        "0.193"
    ],
    [
        "-74",
        "-45",
        "31.558",
        "15.152",
        "13.344",
        "11.705",
        "0.100"
    ],
    [
        "-73",
        "-45",
        "22.078",
        "15.556",
        "14.513",
        "14.456",
        "0.070"
    ],
    [
        "-72",
        "-45",
        "14.770",
        "15.145",
        "16.482",
        "15.881",
        "0.044"
    ],
    [
        "-71",
        "-45",
        "9.425",
        "13.601",
        "15.700",
        "17.117",
        "0.018"
    ],
    [
        "-70",
        "-45",
        "5.668",
        "11.366",
        "14.238",
        "16.249",
        "0.007"
    ],
    [
        "-69",
        "-45",
        "3.212",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-45",
        "3.095",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-45",
        "2.977",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-45",
        "2.856",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-45",
        "2.734",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-45",
        "2.609",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-45",
        "2.484",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-45",
        "2.357",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-45",
        "2.228",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-45",
        "2.099",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-45",
        "1.967",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-45",
        "1.834",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-45",
        "1.700",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-45",
        "1.566",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-45",
        "1.428",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-45",
        "1.292",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-45",
        "1.152",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-45",
        "1.013",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-45",
        "0.871",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-45",
        "0.729",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-45",
        "0.586",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-45",
        "0.442",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-45",
        "0.297",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-45",
        "0.150",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-45",
        "0.005",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-45",
        "-0.143",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-45",
        "-0.291",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-45",
        "-0.440",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-45",
        "-0.590",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-45",
        "-0.740",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-45",
        "-0.891",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-45",
        "-1.041",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-45",
        "-1.194",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-45",
        "-1.345",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-45",
        "-1.498",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-45",
        "-1.650",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-45",
        "-1.804",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-45",
        "-1.957",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-45",
        "-2.110",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-45",
        "-2.263",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-45",
        "-2.417",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-45",
        "-2.571",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-45",
        "-2.724",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-45",
        "-2.878",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-45",
        "-3.031",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-45",
        "-3.184",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-45",
        "-3.338",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-45",
        "-3.490",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-45",
        "-3.643",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-45",
        "-3.795",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-45",
        "-3.947",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-45",
        "-4.098",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-45",
        "-4.250",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-45",
        "-4.399",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-45",
        "22.867",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-45",
        "22.646",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-45",
        "22.425",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-45",
        "22.201",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-45",
        "21.976",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-45",
        "21.750",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-45",
        "21.522",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-45",
        "21.292",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-45",
        "21.062",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-45",
        "20.831",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-45",
        "20.598",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-45",
        "20.364",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-45",
        "20.129",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-45",
        "19.893",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-45",
        "19.656",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-45",
        "19.419",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-45",
        "19.181",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-45",
        "18.942",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-45",
        "18.703",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-45",
        "18.463",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-45",
        "18.222",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-45",
        "17.982",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-45",
        "17.740",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-45",
        "17.499",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-45",
        "17.258",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-45",
        "17.017",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-45",
        "16.776",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-45",
        "16.534",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-45",
        "16.293",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-45",
        "16.052",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-45",
        "15.812",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-45",
        "15.571",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-45",
        "15.332",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-45",
        "15.093",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-45",
        "14.854",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-45",
        "14.617",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-45",
        "14.380",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-45",
        "14.144",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-45",
        "13.908",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-45",
        "13.673",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-45",
        "13.440",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-45",
        "13.207",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-45",
        "12.976",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-45",
        "12.747",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-45",
        "12.518",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-45",
        "12.292",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-45",
        "12.066",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-45",
        "11.841",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-45",
        "11.492",
        "17.388",
        "1.383",
        "1.778",
        "0.103"
    ],
    [
        "34",
        "-45",
        "10.382",
        "13.994",
        "1.803",
        "2.429",
        "0.040"
    ],
    [
        "35",
        "-45",
        "9.363",
        "9.943",
        "1.905",
        "2.705",
        "0.031"
    ],
    [
        "36",
        "-45",
        "8.261",
        "4.426",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "-45",
        "8.208",
        "4.236",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "-45",
        "8.157",
        "4.043",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-45",
        "8.108",
        "3.849",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-45",
        "8.062",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-45",
        "8.018",
        "3.458",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-45",
        "7.977",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-45",
        "7.938",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-45",
        "7.901",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-45",
        "7.867",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-45",
        "7.835",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-45",
        "7.806",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-45",
        "7.779",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-45",
        "7.755",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-45",
        "7.734",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-45",
        "7.714",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-45",
        "7.697",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-45",
        "7.684",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-45",
        "7.672",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-45",
        "7.663",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-45",
        "7.657",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-45",
        "7.653",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-45",
        "7.651",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-45",
        "7.652",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-45",
        "7.656",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-45",
        "7.661",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-45",
        "7.671",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-45",
        "7.681",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-45",
        "7.695",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-45",
        "7.712",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-45",
        "7.730",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-45",
        "7.751",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-45",
        "7.776",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-45",
        "7.802",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-45",
        "7.830",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-45",
        "7.862",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-45",
        "7.895",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-45",
        "7.932",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-45",
        "7.970",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-45",
        "8.011",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-45",
        "8.055",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-45",
        "8.101",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-45",
        "8.149",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-45",
        "8.200",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-45",
        "8.253",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-45",
        "8.308",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-45",
        "8.366",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-45",
        "8.426",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-45",
        "8.489",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-45",
        "8.553",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-45",
        "8.620",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-45",
        "8.689",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-45",
        "8.761",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-45",
        "8.834",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-45",
        "8.911",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-45",
        "8.988",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-45",
        "9.068",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-45",
        "9.150",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-45",
        "9.235",
        "-6.909",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-45",
        "11.948",
        "-2.892",
        "2.365",
        "5.632",
        "-0.004"
    ],
    [
        "96",
        "-45",
        "48.066",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-45",
        "47.452",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-45",
        "46.832",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-45",
        "46.206",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-45",
        "45.574",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-45",
        "44.936",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-45",
        "44.294",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-45",
        "43.646",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-45",
        "42.994",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-45",
        "42.336",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-45",
        "41.675",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-45",
        "41.008",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-45",
        "40.339",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-45",
        "39.664",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-45",
        "38.986",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-45",
        "38.305",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-45",
        "37.620",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-45",
        "36.932",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-45",
        "36.242",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-45",
        "35.548",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-45",
        "34.853",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-45",
        "34.155",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-45",
        "33.454",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-45",
        "32.753",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-45",
        "32.050",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-45",
        "31.345",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-45",
        "30.638",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-45",
        "29.931",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-45",
        "29.224",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-45",
        "28.515",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-45",
        "27.807",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-45",
        "27.099",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-45",
        "26.390",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-45",
        "25.682",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-45",
        "24.974",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-45",
        "24.267",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-45",
        "23.561",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-45",
        "22.857",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-45",
        "22.153",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-45",
        "21.452",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-45",
        "20.752",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-45",
        "20.054",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-45",
        "19.359",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-45",
        "18.666",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-45",
        "17.975",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-45",
        "17.288",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-45",
        "16.603",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-45",
        "15.922",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-45",
        "15.245",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-45",
        "14.572",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-45",
        "13.902",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-45",
        "13.236",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-45",
        "12.574",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-45",
        "11.917",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-45",
        "11.266",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-45",
        "10.619",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-45",
        "9.977",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-45",
        "9.340",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-45",
        "8.709",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-45",
        "8.083",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-45",
        "7.463",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-45",
        "6.850",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-45",
        "6.243",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-45",
        "5.643",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-45",
        "5.048",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-45",
        "4.462",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-45",
        "3.881",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-45",
        "3.308",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-45",
        "2.743",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-45",
        "2.184",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-45",
        "1.635",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-45",
        "-10.273",
        "40.272",
        "6.082",
        "5.030",
        "-0.289"
    ],
    [
        "168",
        "-45",
        "-26.712",
        "36.750",
        "6.693",
        "3.806",
        "-0.140"
    ],
    [
        "169",
        "-45",
        "-28.554",
        "30.432",
        "5.702",
        "4.324",
        "-0.029"
    ],
    [
        "170",
        "-45",
        "-36.104",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-45",
        "-36.443",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-45",
        "-36.785",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-45",
        "-37.129",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-45",
        "-37.477",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-45",
        "-37.827",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-45",
        "-38.180",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-45",
        "-38.535",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-45",
        "-38.892",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-45",
        "-39.252",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-44",
        "-40.529",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-44",
        "-40.887",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-44",
        "-41.246",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-44",
        "-41.607",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-44",
        "-41.970",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-44",
        "-42.334",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-44",
        "-42.700",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-44",
        "-43.066",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-44",
        "-43.435",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-44",
        "-43.804",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-44",
        "-44.174",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-44",
        "-44.545",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-44",
        "-44.917",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-44",
        "-45.289",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-44",
        "-45.662",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-44",
        "-46.036",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-44",
        "-46.410",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-44",
        "-46.783",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-44",
        "-47.157",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-44",
        "-47.531",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-44",
        "-47.905",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-44",
        "-48.278",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-44",
        "-48.652",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-44",
        "-49.024",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-44",
        "-49.396",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-44",
        "-49.767",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-44",
        "-50.138",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-44",
        "-50.507",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-44",
        "-50.875",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-44",
        "-51.243",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-44",
        "-51.609",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-44",
        "-51.973",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-44",
        "-52.336",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-44",
        "-52.697",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-44",
        "-53.057",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-44",
        "-53.415",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-44",
        "-53.771",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-44",
        "-54.124",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-44",
        "-54.476",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-44",
        "-54.825",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-44",
        "-55.172",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-44",
        "-55.517",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-44",
        "-55.858",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-44",
        "-56.198",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-44",
        "-56.534",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-44",
        "-56.867",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-44",
        "-57.198",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-44",
        "-57.525",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-44",
        "-57.849",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-44",
        "-58.170",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-44",
        "-58.488",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-44",
        "-58.802",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-44",
        "-59.112",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-44",
        "-59.419",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-44",
        "-59.721",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-44",
        "-60.020",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-44",
        "-60.315",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-44",
        "-60.606",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-44",
        "-60.893",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-44",
        "-61.176",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-44",
        "-61.454",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-44",
        "-61.728",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-44",
        "-61.997",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-44",
        "-62.262",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-44",
        "-62.523",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-44",
        "-62.778",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-44",
        "-63.029",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-44",
        "-63.274",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-44",
        "-40.745",
        "15.086",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-44",
        "24.287",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-44",
        "24.258",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-44",
        "24.228",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-44",
        "24.195",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-44",
        "24.160",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-44",
        "24.121",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-44",
        "24.081",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-44",
        "24.038",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-44",
        "23.993",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-44",
        "23.946",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-44",
        "23.896",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-44",
        "23.844",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-44",
        "23.789",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-44",
        "23.733",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-44",
        "23.673",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-44",
        "23.612",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-44",
        "23.549",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-44",
        "23.482",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-44",
        "23.415",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-44",
        "23.344",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-44",
        "23.272",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-44",
        "23.198",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-44",
        "23.121",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-44",
        "23.042",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-44",
        "22.962",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-44",
        "22.879",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-44",
        "22.794",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-44",
        "22.708",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-44",
        "22.619",
        "7.399",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-44",
        "70.159",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-44",
        "69.986",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-44",
        "69.804",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-44",
        "69.612",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-44",
        "69.409",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-44",
        "69.197",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-44",
        "65.609",
        "18.258",
        "2.866",
        "2.571",
        "0.043"
    ],
    [
        "-75",
        "-44",
        "38.064",
        "18.060",
        "12.443",
        "7.565",
        "0.015"
    ],
    [
        "-74",
        "-44",
        "23.092",
        "18.974",
        "12.243",
        "9.418",
        "-0.026"
    ],
    [
        "-73",
        "-44",
        "14.660",
        "19.186",
        "12.793",
        "11.506",
        "0.014"
    ],
    [
        "-72",
        "-44",
        "9.536",
        "17.893",
        "14.140",
        "12.875",
        "0.026"
    ],
    [
        "-71",
        "-44",
        "6.215",
        "15.417",
        "13.356",
        "14.273",
        "0.010"
    ],
    [
        "-70",
        "-44",
        "4.117",
        "12.360",
        "12.113",
        "13.719",
        "0.018"
    ],
    [
        "-69",
        "-44",
        "3.067",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-44",
        "2.952",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-44",
        "2.835",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-44",
        "2.717",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-44",
        "2.597",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-44",
        "2.475",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-44",
        "2.351",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-44",
        "2.227",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-44",
        "2.100",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-44",
        "1.974",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-44",
        "1.844",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-44",
        "1.714",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-44",
        "1.582",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-44",
        "1.450",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-44",
        "1.315",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-44",
        "1.180",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-44",
        "1.044",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-44",
        "0.906",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-44",
        "0.767",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-44",
        "0.628",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-44",
        "0.488",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-44",
        "0.345",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-44",
        "0.203",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-44",
        "0.060",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-44",
        "-0.085",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-44",
        "-0.229",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-44",
        "-0.375",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-44",
        "-0.522",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-44",
        "-0.667",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-44",
        "-0.816",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-44",
        "-0.964",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-44",
        "-1.112",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-44",
        "-1.261",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-44",
        "-1.410",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-44",
        "-1.560",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-44",
        "-1.709",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-44",
        "-1.861",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-44",
        "-2.011",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-44",
        "-2.161",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-44",
        "-2.312",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-44",
        "-2.463",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-44",
        "-2.614",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-44",
        "-2.765",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-44",
        "-2.915",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-44",
        "-3.066",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-44",
        "-3.217",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-44",
        "-3.367",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-44",
        "-3.517",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-44",
        "-3.667",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-44",
        "-3.816",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-44",
        "-3.966",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-44",
        "-4.115",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-44",
        "-4.263",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-44",
        "10.173",
        "14.306",
        "2.886",
        "2.004",
        "0.296"
    ],
    [
        "-15",
        "-44",
        "23.060",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-44",
        "22.844",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-44",
        "22.626",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-44",
        "22.407",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-44",
        "22.185",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-44",
        "21.963",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-44",
        "21.739",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-44",
        "21.514",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-44",
        "21.288",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-44",
        "21.060",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-44",
        "20.831",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-44",
        "20.601",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-44",
        "20.370",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-44",
        "20.140",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-44",
        "19.907",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-44",
        "19.673",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-44",
        "19.439",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-44",
        "19.204",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-44",
        "18.969",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-44",
        "18.734",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-44",
        "18.497",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-44",
        "18.261",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-44",
        "18.025",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-44",
        "17.788",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-44",
        "17.550",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-44",
        "17.313",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-44",
        "17.076",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-44",
        "16.839",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-44",
        "16.603",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-44",
        "16.366",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-44",
        "16.130",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-44",
        "15.894",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-44",
        "15.659",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-44",
        "15.424",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-44",
        "15.189",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-44",
        "14.955",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-44",
        "14.723",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-44",
        "14.491",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-44",
        "14.259",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-44",
        "14.029",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-44",
        "13.799",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-44",
        "13.572",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-44",
        "13.344",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-44",
        "13.119",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-44",
        "12.894",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-44",
        "12.671",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-44",
        "12.449",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-44",
        "12.229",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-44",
        "11.869",
        "17.741",
        "0.972",
        "1.161",
        "0.066"
    ],
    [
        "34",
        "-44",
        "11.184",
        "16.371",
        "1.361",
        "1.729",
        "0.004"
    ],
    [
        "35",
        "-44",
        "10.302",
        "14.098",
        "1.691",
        "2.179",
        "-0.034"
    ],
    [
        "36",
        "-44",
        "9.587",
        "11.614",
        "2.017",
        "2.530",
        "-0.098"
    ],
    [
        "37",
        "-44",
        "9.352",
        "9.620",
        "2.099",
        "2.867",
        "-0.206"
    ],
    [
        "38",
        "-44",
        "9.179",
        "8.238",
        "2.239",
        "3.245",
        "-0.224"
    ],
    [
        "39",
        "-44",
        "8.973",
        "5.738",
        "2.179",
        "2.950",
        "-0.110"
    ],
    [
        "40",
        "-44",
        "8.477",
        "3.654",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-44",
        "8.552",
        "3.803",
        "2.408",
        "2.920",
        "-0.058"
    ],
    [
        "42",
        "-44",
        "8.394",
        "3.262",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-44",
        "8.356",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-44",
        "8.320",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-44",
        "8.287",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-44",
        "8.255",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-44",
        "8.226",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-44",
        "8.201",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-44",
        "8.176",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-44",
        "8.155",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-44",
        "8.137",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-44",
        "8.120",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-44",
        "8.106",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-44",
        "8.095",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-44",
        "8.086",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-44",
        "8.080",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-44",
        "8.076",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-44",
        "8.074",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-44",
        "8.075",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-44",
        "8.078",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-44",
        "8.084",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-44",
        "8.093",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-44",
        "8.104",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-44",
        "8.118",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-44",
        "8.133",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-44",
        "8.152",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-44",
        "8.173",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-44",
        "8.196",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-44",
        "8.222",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-44",
        "8.251",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-44",
        "8.281",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-44",
        "8.315",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-44",
        "8.350",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-44",
        "8.388",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-44",
        "8.428",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-44",
        "8.471",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-44",
        "8.516",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-44",
        "8.564",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-44",
        "8.613",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-44",
        "8.666",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-44",
        "8.720",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-44",
        "8.776",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-44",
        "8.836",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-44",
        "8.897",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-44",
        "8.960",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-44",
        "9.027",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-44",
        "9.094",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-44",
        "9.165",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-44",
        "9.237",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-44",
        "9.311",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-44",
        "9.388",
        "-6.401",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-44",
        "9.467",
        "-6.572",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-44",
        "9.547",
        "-6.742",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-44",
        "45.507",
        "45.890",
        "2.222",
        "5.833",
        "0.018"
    ],
    [
        "95",
        "-44",
        "48.776",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-44",
        "48.179",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-44",
        "47.576",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-44",
        "46.967",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-44",
        "46.352",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-44",
        "45.731",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-44",
        "45.104",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-44",
        "44.474",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-44",
        "43.837",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-44",
        "43.196",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-44",
        "42.551",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-44",
        "41.901",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-44",
        "41.246",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-44",
        "40.588",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-44",
        "39.926",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-44",
        "39.259",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-44",
        "38.590",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-44",
        "37.918",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-44",
        "37.242",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-44",
        "36.563",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-44",
        "35.882",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-44",
        "35.199",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-44",
        "34.513",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-44",
        "33.826",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-44",
        "33.136",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-44",
        "32.445",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-44",
        "31.752",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-44",
        "31.059",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-44",
        "30.364",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-44",
        "29.669",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-44",
        "28.974",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-44",
        "28.278",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-44",
        "27.581",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-44",
        "26.885",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-44",
        "26.190",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-44",
        "25.494",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-44",
        "24.800",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-44",
        "24.106",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-44",
        "23.414",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-44",
        "22.723",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-44",
        "22.034",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-44",
        "21.346",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-44",
        "20.661",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-44",
        "19.978",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-44",
        "19.297",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-44",
        "18.618",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-44",
        "17.943",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-44",
        "17.271",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-44",
        "16.602",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-44",
        "15.936",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-44",
        "15.274",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-44",
        "14.617",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-44",
        "13.963",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-44",
        "13.313",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-44",
        "12.667",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-44",
        "12.027",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-44",
        "11.391",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-44",
        "10.760",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-44",
        "10.135",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-44",
        "9.515",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-44",
        "8.901",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-44",
        "8.292",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-44",
        "7.689",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-44",
        "7.093",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-44",
        "6.503",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-44",
        "5.920",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-44",
        "5.342",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-44",
        "4.772",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-44",
        "4.209",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-44",
        "3.654",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-44",
        "3.106",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-44",
        "2.565",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-44",
        "2.032",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-44",
        "1.508",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-44",
        "-16.501",
        "38.890",
        "3.228",
        "2.001",
        "-0.122"
    ],
    [
        "170",
        "-44",
        "-28.846",
        "32.625",
        "6.352",
        "4.703",
        "0.033"
    ],
    [
        "171",
        "-44",
        "-34.499",
        "31.446",
        "5.034",
        "4.434",
        "-0.121"
    ],
    [
        "172",
        "-44",
        "-37.750",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-44",
        "-38.088",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-44",
        "-38.430",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-44",
        "-38.774",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-44",
        "-39.120",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-44",
        "-39.469",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-44",
        "-39.820",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-44",
        "-40.174",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-43",
        "-41.432",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-43",
        "-41.783",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-43",
        "-42.136",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-43",
        "-42.490",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-43",
        "-42.846",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-43",
        "-43.204",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-43",
        "-43.563",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-43",
        "-43.923",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-43",
        "-44.285",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-43",
        "-44.647",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-43",
        "-45.011",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-43",
        "-45.375",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-43",
        "-45.740",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-43",
        "-46.106",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-43",
        "-46.472",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-43",
        "-46.839",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-43",
        "-47.205",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-43",
        "-47.572",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-43",
        "-47.940",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-43",
        "-48.307",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-43",
        "-48.674",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-43",
        "-49.040",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-43",
        "-49.407",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-43",
        "-49.772",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-43",
        "-50.137",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-43",
        "-50.502",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-43",
        "-50.866",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-43",
        "-51.228",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-43",
        "-51.590",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-43",
        "-51.950",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-43",
        "-52.310",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-43",
        "-52.667",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-43",
        "-53.024",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-43",
        "-53.379",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-43",
        "-53.732",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-43",
        "-54.083",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-43",
        "-54.432",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-43",
        "-54.780",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-43",
        "-55.125",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-43",
        "-55.468",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-43",
        "-55.808",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-43",
        "-56.147",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-43",
        "-56.482",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-43",
        "-56.815",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-43",
        "-57.145",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-43",
        "-57.473",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-43",
        "-57.797",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-43",
        "-58.119",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-43",
        "-58.437",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-43",
        "-58.752",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-43",
        "-59.063",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-43",
        "-59.372",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-43",
        "-59.676",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-43",
        "-59.977",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-43",
        "-60.275",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-43",
        "-60.568",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-43",
        "-60.858",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-43",
        "-61.144",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-43",
        "-61.425",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-43",
        "-61.703",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-43",
        "-61.976",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-43",
        "-62.245",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-43",
        "-62.509",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-43",
        "-62.769",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-43",
        "-63.025",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-43",
        "-63.276",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-43",
        "-63.522",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-43",
        "-63.763",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-43",
        "-56.478",
        "18.028",
        "6.930",
        "9.796",
        "0.037"
    ],
    [
        "-111",
        "-43",
        "24.412",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-43",
        "24.385",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-43",
        "24.354",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-43",
        "24.322",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-43",
        "24.287",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-43",
        "24.249",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-43",
        "24.210",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-43",
        "24.168",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-43",
        "24.123",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-43",
        "24.077",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-43",
        "24.029",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-43",
        "23.977",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-43",
        "23.923",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-43",
        "23.868",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-43",
        "23.809",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-43",
        "23.749",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-43",
        "23.688",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-43",
        "23.622",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-43",
        "23.556",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-43",
        "23.487",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-43",
        "23.416",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-43",
        "23.343",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-43",
        "23.268",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-43",
        "23.190",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-43",
        "23.111",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-43",
        "23.031",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-43",
        "22.947",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-43",
        "22.861",
        "7.238",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-43",
        "48.597",
        "8.887",
        "11.886",
        "8.835",
        "0.270"
    ],
    [
        "-82",
        "-43",
        "70.166",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-43",
        "69.996",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-43",
        "69.817",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-43",
        "69.628",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-43",
        "69.429",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-43",
        "69.221",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-43",
        "64.346",
        "18.821",
        "2.703",
        "2.263",
        "-0.197"
    ],
    [
        "-75",
        "-43",
        "29.771",
        "20.649",
        "11.285",
        "6.455",
        "-0.271"
    ],
    [
        "-74",
        "-43",
        "14.839",
        "21.121",
        "9.892",
        "7.158",
        "-0.231"
    ],
    [
        "-73",
        "-43",
        "8.432",
        "20.473",
        "9.664",
        "7.866",
        "-0.117"
    ],
    [
        "-72",
        "-43",
        "5.381",
        "18.423",
        "10.031",
        "8.463",
        "-0.026"
    ],
    [
        "-71",
        "-43",
        "3.683",
        "15.561",
        "9.220",
        "9.405",
        "-0.006"
    ],
    [
        "-70",
        "-43",
        "2.845",
        "12.450",
        "8.183",
        "9.045",
        "0.036"
    ],
    [
        "-69",
        "-43",
        "2.921",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-43",
        "2.808",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-43",
        "2.693",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-43",
        "2.577",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-43",
        "2.459",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-43",
        "2.340",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-43",
        "2.218",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-43",
        "2.097",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-43",
        "1.972",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-43",
        "1.847",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-43",
        "1.721",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-43",
        "1.592",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-43",
        "1.464",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-43",
        "1.333",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-43",
        "1.201",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-43",
        "1.068",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-43",
        "0.935",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-43",
        "0.799",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-43",
        "0.663",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-43",
        "0.526",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-43",
        "0.388",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-43",
        "0.249",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-43",
        "0.109",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-43",
        "-0.031",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-43",
        "-0.173",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-43",
        "-0.315",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-43",
        "-0.458",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-43",
        "-0.602",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-43",
        "-0.746",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-43",
        "-0.890",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-43",
        "-1.036",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-43",
        "-1.182",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-43",
        "-1.328",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-43",
        "-1.474",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-43",
        "-1.621",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-43",
        "-1.768",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-43",
        "-1.916",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-43",
        "-2.064",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-43",
        "-2.212",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-43",
        "-2.360",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-43",
        "-2.508",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-43",
        "-2.656",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-43",
        "-2.804",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-43",
        "-2.952",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-43",
        "-3.100",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-43",
        "-3.249",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-43",
        "-3.396",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-43",
        "-3.543",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-43",
        "-3.690",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-43",
        "-3.837",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-43",
        "-3.983",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-43",
        "-4.130",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-43",
        "-4.275",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-43",
        "14.441",
        "15.743",
        "3.340",
        "2.186",
        "0.411"
    ],
    [
        "-15",
        "-43",
        "23.247",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-43",
        "23.034",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-43",
        "22.821",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-43",
        "22.605",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-43",
        "22.388",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-43",
        "22.170",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-43",
        "21.950",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-43",
        "21.729",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-43",
        "21.507",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-43",
        "21.283",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-43",
        "21.059",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-43",
        "20.833",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-43",
        "20.607",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-43",
        "20.378",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-43",
        "20.150",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-43",
        "19.921",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-43",
        "19.692",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-43",
        "19.461",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-43",
        "19.230",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-43",
        "19.000",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-43",
        "18.767",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-43",
        "18.535",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-43",
        "18.302",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-43",
        "18.070",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-43",
        "17.837",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-43",
        "17.605",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-43",
        "17.372",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-43",
        "17.139",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-43",
        "16.907",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-43",
        "16.674",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-43",
        "16.443",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-43",
        "16.211",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-43",
        "15.980",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-43",
        "15.749",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-43",
        "15.519",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-43",
        "15.290",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-43",
        "15.061",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-43",
        "14.834",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-43",
        "14.606",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-43",
        "14.380",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-43",
        "14.155",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-43",
        "13.931",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-43",
        "13.708",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-43",
        "13.486",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-43",
        "13.267",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-43",
        "13.047",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-43",
        "12.830",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-43",
        "12.613",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-43",
        "12.226",
        "17.853",
        "0.748",
        "0.787",
        "0.015"
    ],
    [
        "34",
        "-43",
        "11.675",
        "17.178",
        "0.995",
        "1.186",
        "-0.011"
    ],
    [
        "35",
        "-43",
        "11.074",
        "16.026",
        "1.218",
        "1.479",
        "-0.005"
    ],
    [
        "36",
        "-43",
        "10.589",
        "14.805",
        "1.471",
        "1.722",
        "-0.042"
    ],
    [
        "37",
        "-43",
        "10.266",
        "13.550",
        "1.647",
        "2.055",
        "-0.126"
    ],
    [
        "38",
        "-43",
        "10.068",
        "12.338",
        "1.933",
        "2.479",
        "-0.141"
    ],
    [
        "39",
        "-43",
        "9.833",
        "10.436",
        "2.379",
        "2.849",
        "-0.114"
    ],
    [
        "40",
        "-43",
        "9.326",
        "7.184",
        "2.535",
        "3.033",
        "-0.108"
    ],
    [
        "41",
        "-43",
        "8.961",
        "5.189",
        "2.588",
        "3.531",
        "0.020"
    ],
    [
        "42",
        "-43",
        "8.690",
        "3.627",
        "2.378",
        "3.390",
        "0.110"
    ],
    [
        "43",
        "-43",
        "8.771",
        "3.063",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-43",
        "8.735",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-43",
        "8.703",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-43",
        "8.672",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-43",
        "8.645",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-43",
        "8.619",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-43",
        "8.595",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-43",
        "8.574",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-43",
        "8.556",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-43",
        "8.539",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-43",
        "8.526",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-43",
        "8.515",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-43",
        "8.506",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-43",
        "8.500",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-43",
        "8.496",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-43",
        "8.494",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-43",
        "8.496",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-43",
        "8.499",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-43",
        "8.505",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-43",
        "8.513",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-43",
        "8.524",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-43",
        "8.538",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-43",
        "8.553",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-43",
        "8.571",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-43",
        "8.592",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-43",
        "8.614",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-43",
        "8.640",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-43",
        "8.667",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-43",
        "8.698",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-43",
        "8.730",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-43",
        "8.765",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-43",
        "8.803",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-43",
        "8.842",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-43",
        "8.884",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-43",
        "8.929",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-43",
        "8.975",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-43",
        "9.025",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-43",
        "9.075",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-43",
        "9.129",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-43",
        "9.184",
        "-4.776",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-43",
        "9.243",
        "-4.963",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-43",
        "9.303",
        "-5.149",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-43",
        "9.365",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-43",
        "9.430",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-43",
        "9.497",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-43",
        "9.565",
        "-5.875",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-43",
        "9.636",
        "-6.052",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-43",
        "9.709",
        "-6.227",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-43",
        "40.422",
        "32.990",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-43",
        "50.585",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-43",
        "50.017",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-43",
        "49.444",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-43",
        "48.864",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-43",
        "48.278",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-43",
        "47.685",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-43",
        "47.088",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-43",
        "46.484",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-43",
        "45.874",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-43",
        "45.260",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-43",
        "44.640",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-43",
        "44.015",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-43",
        "43.386",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-43",
        "42.752",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-43",
        "42.113",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-43",
        "41.471",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-43",
        "40.825",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-43",
        "40.174",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-43",
        "39.521",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-43",
        "38.863",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-43",
        "38.203",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-43",
        "37.540",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-43",
        "36.874",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-43",
        "36.205",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-43",
        "35.534",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-43",
        "34.861",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-43",
        "34.185",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-43",
        "33.509",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-43",
        "32.830",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-43",
        "32.150",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-43",
        "31.470",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-43",
        "30.787",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-43",
        "30.105",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-43",
        "29.422",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-43",
        "28.738",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-43",
        "28.055",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-43",
        "27.372",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-43",
        "26.689",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-43",
        "26.006",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-43",
        "25.324",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-43",
        "24.643",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-43",
        "23.964",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-43",
        "23.285",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-43",
        "22.609",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-43",
        "21.933",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-43",
        "21.261",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-43",
        "20.590",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-43",
        "19.921",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-43",
        "19.256",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-43",
        "18.593",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-43",
        "17.933",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-43",
        "17.276",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-43",
        "16.623",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-43",
        "15.972",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-43",
        "15.327",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-43",
        "14.685",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-43",
        "14.046",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-43",
        "13.413",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-43",
        "12.784",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-43",
        "12.160",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-43",
        "11.541",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-43",
        "10.927",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-43",
        "10.318",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-43",
        "9.715",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-43",
        "9.118",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-43",
        "8.526",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-43",
        "7.940",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-43",
        "7.361",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-43",
        "6.788",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-43",
        "6.222",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-43",
        "5.662",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-43",
        "5.109",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-43",
        "4.564",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-43",
        "4.026",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-43",
        "3.495",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-43",
        "2.972",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-43",
        "2.457",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-43",
        "1.949",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-43",
        "1.449",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-43",
        "-14.243",
        "37.176",
        "2.483",
        "2.923",
        "0.117"
    ],
    [
        "172",
        "-43",
        "-36.451",
        "27.537",
        "1.303",
        "2.403",
        "-0.165"
    ],
    [
        "173",
        "-43",
        "-39.036",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-43",
        "-39.371",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-43",
        "-39.709",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-43",
        "-40.049",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-43",
        "-40.392",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-43",
        "-40.736",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-43",
        "-41.083",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-42",
        "-42.323",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-42",
        "-42.667",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-42",
        "-43.013",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-42",
        "-43.361",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-42",
        "-43.710",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-42",
        "-44.061",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-42",
        "-44.413",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-42",
        "-44.767",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-42",
        "-45.121",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-42",
        "-45.477",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-42",
        "-45.834",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-42",
        "-46.191",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-42",
        "-46.549",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-42",
        "-46.908",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-42",
        "-47.267",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-42",
        "-47.627",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-42",
        "-47.987",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-42",
        "-48.347",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-42",
        "-48.707",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-42",
        "-49.067",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-42",
        "-49.427",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-42",
        "-49.787",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-42",
        "-50.147",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-42",
        "-50.505",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-42",
        "-50.864",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-42",
        "-51.221",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-42",
        "-51.578",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-42",
        "-51.934",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-42",
        "-52.289",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-42",
        "-52.642",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-42",
        "-52.995",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-42",
        "-53.346",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-42",
        "-53.696",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-42",
        "-54.044",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-42",
        "-54.390",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-42",
        "-54.735",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-42",
        "-55.077",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-42",
        "-55.418",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-42",
        "-55.757",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-42",
        "-56.093",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-42",
        "-56.428",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-42",
        "-56.759",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-42",
        "-57.089",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-42",
        "-57.415",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-42",
        "-57.739",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-42",
        "-58.060",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-42",
        "-58.379",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-42",
        "-58.694",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-42",
        "-59.006",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-42",
        "-59.315",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-42",
        "-59.621",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-42",
        "-59.924",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-42",
        "-60.222",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-42",
        "-60.518",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-42",
        "-60.810",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-42",
        "-61.098",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-42",
        "-61.382",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-42",
        "-61.662",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-42",
        "-61.938",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-42",
        "-62.211",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-42",
        "-62.479",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-42",
        "-62.743",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-42",
        "-63.002",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-42",
        "-63.257",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-42",
        "-63.508",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-42",
        "-63.754",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-42",
        "-63.995",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-42",
        "-64.232",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-42",
        "-64.464",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-42",
        "24.530",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-42",
        "24.503",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-42",
        "24.472",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-42",
        "24.441",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-42",
        "24.407",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-42",
        "24.370",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-42",
        "24.332",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-42",
        "24.290",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-42",
        "24.246",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-42",
        "24.201",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-42",
        "24.153",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-42",
        "24.103",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-42",
        "24.050",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-42",
        "23.996",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-42",
        "23.939",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-42",
        "23.880",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-42",
        "23.818",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-42",
        "23.755",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-42",
        "23.689",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-42",
        "23.622",
        "5.875",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-42",
        "23.553",
        "6.052",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-42",
        "23.481",
        "6.227",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-42",
        "23.407",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-42",
        "23.331",
        "6.572",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-42",
        "23.254",
        "6.742",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-42",
        "23.174",
        "6.909",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-42",
        "23.092",
        "7.075",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-42",
        "50.176",
        "9.802",
        "13.113",
        "8.631",
        "0.367"
    ],
    [
        "-83",
        "-42",
        "70.306",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-42",
        "70.150",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-42",
        "69.984",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-42",
        "69.809",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-42",
        "69.623",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-42",
        "69.428",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-42",
        "69.223",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-42",
        "65.482",
        "19.165",
        "2.450",
        "2.151",
        "-0.360"
    ],
    [
        "-75",
        "-42",
        "32.846",
        "22.120",
        "10.252",
        "6.074",
        "-0.456"
    ],
    [
        "-74",
        "-42",
        "14.322",
        "21.927",
        "8.044",
        "5.940",
        "-0.364"
    ],
    [
        "-73",
        "-42",
        "7.157",
        "20.425",
        "6.895",
        "5.455",
        "-0.274"
    ],
    [
        "-72",
        "-42",
        "4.874",
        "17.888",
        "6.355",
        "5.077",
        "-0.133"
    ],
    [
        "-71",
        "-42",
        "3.792",
        "15.068",
        "5.575",
        "5.268",
        "-0.032"
    ],
    [
        "-70",
        "-42",
        "3.142",
        "12.475",
        "4.988",
        "5.187",
        "0.046"
    ],
    [
        "-69",
        "-42",
        "2.766",
        "10.506",
        "4.244",
        "4.246",
        "0.004"
    ],
    [
        "-68",
        "-42",
        "2.655",
        "9.590",
        "0.844",
        "1.099",
        "0.077"
    ],
    [
        "-67",
        "-42",
        "2.551",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-42",
        "2.437",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-42",
        "2.321",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-42",
        "2.204",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-42",
        "2.086",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-42",
        "1.966",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-42",
        "1.844",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-42",
        "1.721",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-42",
        "1.597",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-42",
        "1.471",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-42",
        "1.344",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-42",
        "1.217",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-42",
        "1.087",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-42",
        "0.956",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-42",
        "0.825",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-42",
        "0.692",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-42",
        "0.559",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-42",
        "0.424",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-42",
        "0.289",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-42",
        "0.153",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-42",
        "0.015",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-42",
        "-0.122",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-42",
        "-0.261",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-42",
        "-0.401",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-42",
        "-0.542",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-42",
        "-0.682",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-42",
        "-0.824",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-42",
        "-0.966",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-42",
        "-1.108",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-42",
        "-1.251",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-42",
        "-1.395",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-42",
        "-1.539",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-42",
        "-1.682",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-42",
        "-1.827",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-42",
        "-1.972",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-42",
        "-2.117",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-42",
        "-2.262",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-42",
        "-2.407",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-42",
        "-2.553",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-42",
        "-2.698",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-42",
        "-2.843",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-42",
        "-2.988",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-42",
        "-3.134",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-42",
        "-3.279",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-42",
        "-3.423",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-42",
        "-3.569",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-42",
        "-3.712",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-42",
        "-3.857",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-42",
        "-4.000",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-42",
        "-4.143",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-42",
        "-4.287",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-42",
        "20.246",
        "17.108",
        "2.986",
        "2.160",
        "0.317"
    ],
    [
        "-15",
        "-42",
        "23.427",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-42",
        "23.218",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-42",
        "23.008",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-42",
        "22.797",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-42",
        "22.584",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-42",
        "22.369",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-42",
        "22.154",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-42",
        "21.938",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-42",
        "21.719",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-42",
        "21.500",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-42",
        "21.279",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-42",
        "21.058",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-42",
        "20.836",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-42",
        "20.612",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-42",
        "20.388",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-42",
        "20.164",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-42",
        "19.938",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-42",
        "19.712",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-42",
        "19.486",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-42",
        "19.258",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-42",
        "19.031",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-42",
        "18.803",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-42",
        "18.576",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-42",
        "18.347",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-42",
        "18.119",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-42",
        "17.891",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-42",
        "17.662",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-42",
        "17.434",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-42",
        "17.206",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-42",
        "16.978",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-42",
        "16.750",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-42",
        "16.523",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-42",
        "16.296",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-42",
        "16.070",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-42",
        "15.845",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-42",
        "15.620",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-42",
        "15.395",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-42",
        "15.172",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-42",
        "14.949",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-42",
        "14.727",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-42",
        "14.506",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-42",
        "14.286",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-42",
        "14.068",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-42",
        "13.850",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-42",
        "13.634",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-42",
        "13.419",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-42",
        "13.205",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-42",
        "12.994",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-42",
        "12.649",
        "17.877",
        "0.651",
        "0.647",
        "-0.010"
    ],
    [
        "34",
        "-42",
        "12.212",
        "17.387",
        "0.791",
        "0.914",
        "-0.026"
    ],
    [
        "35",
        "-42",
        "11.780",
        "16.678",
        "0.922",
        "1.072",
        "-0.001"
    ],
    [
        "36",
        "-42",
        "11.407",
        "15.876",
        "1.085",
        "1.189",
        "0.003"
    ],
    [
        "37",
        "-42",
        "11.131",
        "15.060",
        "1.201",
        "1.388",
        "-0.029"
    ],
    [
        "38",
        "-42",
        "10.942",
        "14.202",
        "1.394",
        "1.648",
        "-0.037"
    ],
    [
        "39",
        "-42",
        "10.762",
        "13.018",
        "1.695",
        "1.986",
        "-0.016"
    ],
    [
        "40",
        "-42",
        "10.362",
        "10.676",
        "1.894",
        "2.610",
        "0.022"
    ],
    [
        "41",
        "-42",
        "9.834",
        "8.083",
        "2.106",
        "3.435",
        "0.093"
    ],
    [
        "42",
        "-42",
        "9.420",
        "5.806",
        "2.363",
        "4.113",
        "0.145"
    ],
    [
        "43",
        "-42",
        "9.143",
        "3.090",
        "0.526",
        "1.331",
        "0.120"
    ],
    [
        "44",
        "-42",
        "9.149",
        "2.864",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-42",
        "9.117",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-42",
        "9.087",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-42",
        "9.059",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-42",
        "9.034",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-42",
        "9.012",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-42",
        "8.990",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-42",
        "8.973",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-42",
        "8.957",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-42",
        "8.943",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-42",
        "8.933",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-42",
        "8.924",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-42",
        "8.918",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-42",
        "8.914",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-42",
        "8.913",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-42",
        "8.914",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-42",
        "8.917",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-42",
        "8.923",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-42",
        "8.931",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-42",
        "8.941",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-42",
        "8.955",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-42",
        "8.970",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-42",
        "8.987",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-42",
        "9.008",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-42",
        "9.030",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-42",
        "9.055",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-42",
        "9.082",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-42",
        "9.112",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-42",
        "9.144",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-42",
        "9.178",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-42",
        "9.215",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-42",
        "9.253",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-42",
        "9.295",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-42",
        "9.339",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-42",
        "9.384",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-42",
        "9.433",
        "-4.205",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-42",
        "9.482",
        "-4.397",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-42",
        "9.535",
        "-4.587",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-42",
        "22.267",
        "3.537",
        "8.192",
        "10.427",
        "-0.467"
    ],
    [
        "83",
        "-42",
        "55.313",
        "41.687",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-42",
        "54.822",
        "42.370",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-42",
        "9.767",
        "-5.332",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-42",
        "9.830",
        "-5.515",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-42",
        "9.896",
        "-5.695",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-42",
        "31.272",
        "17.219",
        "6.826",
        "8.918",
        "-0.046"
    ],
    [
        "89",
        "-42",
        "40.012",
        "32.221",
        "5.573",
        "7.173",
        "0.034"
    ],
    [
        "90",
        "-42",
        "51.717",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-42",
        "51.174",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-42",
        "50.625",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-42",
        "50.069",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-42",
        "49.505",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-42",
        "48.937",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-42",
        "48.361",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-42",
        "47.781",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-42",
        "47.194",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-42",
        "46.602",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-42",
        "46.003",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-42",
        "45.401",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-42",
        "44.792",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-42",
        "44.180",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-42",
        "43.562",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-42",
        "42.940",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-42",
        "42.314",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-42",
        "41.683",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-42",
        "41.050",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-42",
        "40.411",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-42",
        "39.770",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-42",
        "39.125",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-42",
        "38.477",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-42",
        "37.826",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-42",
        "37.173",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-42",
        "36.517",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-42",
        "35.858",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-42",
        "35.198",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-42",
        "34.536",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-42",
        "33.871",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-42",
        "33.206",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-42",
        "32.539",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-42",
        "31.871",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-42",
        "31.201",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-42",
        "30.532",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-42",
        "29.862",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-42",
        "29.191",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-42",
        "28.520",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-42",
        "27.850",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-42",
        "27.180",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-42",
        "26.511",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-42",
        "25.842",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-42",
        "25.174",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-42",
        "24.507",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-42",
        "23.841",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-42",
        "23.177",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-42",
        "22.515",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-42",
        "21.855",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-42",
        "21.196",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-42",
        "20.540",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-42",
        "19.888",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-42",
        "19.237",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-42",
        "18.589",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-42",
        "17.945",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-42",
        "17.304",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-42",
        "16.666",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-42",
        "16.032",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-42",
        "15.402",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-42",
        "14.777",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-42",
        "14.155",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-42",
        "13.538",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-42",
        "12.925",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-42",
        "12.318",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-42",
        "11.716",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-42",
        "11.118",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-42",
        "10.526",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-42",
        "9.940",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-42",
        "9.359",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-42",
        "8.785",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-42",
        "8.217",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-42",
        "7.655",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-42",
        "7.099",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-42",
        "6.550",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-42",
        "6.008",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-42",
        "5.473",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-42",
        "4.945",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-42",
        "4.424",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-42",
        "3.911",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-42",
        "3.405",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-42",
        "2.908",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-42",
        "2.417",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-42",
        "1.935",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-42",
        "-4.987",
        "38.049",
        "4.392",
        "5.782",
        "-0.257"
    ],
    [
        "173",
        "-42",
        "-19.676",
        "37.610",
        "5.293",
        "6.278",
        "0.007"
    ],
    [
        "174",
        "-42",
        "-32.279",
        "28.864",
        "4.351",
        "5.051",
        "-0.362"
    ],
    [
        "175",
        "-42",
        "-38.179",
        "26.598",
        "3.990",
        "5.676",
        "-0.200"
    ],
    [
        "176",
        "-42",
        "-40.774",
        "27.752",
        "2.463",
        "3.697",
        "-0.411"
    ],
    [
        "177",
        "-42",
        "-41.302",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-42",
        "-41.640",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-42",
        "-41.980",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-41",
        "-43.200",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-41",
        "-43.538",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-41",
        "-43.877",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-41",
        "-44.218",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-41",
        "-44.561",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-41",
        "-44.905",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-41",
        "-45.250",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-41",
        "-45.596",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-41",
        "-45.944",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-41",
        "-46.293",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-41",
        "-46.643",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-41",
        "-46.993",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-41",
        "-47.344",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-41",
        "-47.696",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-41",
        "-48.048",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-41",
        "-48.401",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-41",
        "-48.754",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-41",
        "-49.107",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-41",
        "-49.460",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-41",
        "-49.813",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-41",
        "-50.166",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-41",
        "-50.519",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-41",
        "-50.871",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-41",
        "-51.223",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-41",
        "-51.574",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-41",
        "-51.925",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-41",
        "-52.275",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-41",
        "-52.624",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-41",
        "-52.972",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-41",
        "-53.318",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-41",
        "-53.664",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-41",
        "-54.008",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-41",
        "-54.351",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-41",
        "-54.692",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-41",
        "-55.032",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-41",
        "-55.370",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-41",
        "-55.706",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-41",
        "-56.040",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-41",
        "-56.372",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-41",
        "-56.702",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-41",
        "-57.030",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-41",
        "-57.355",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-41",
        "-57.678",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-41",
        "-57.998",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-41",
        "-58.316",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-41",
        "-58.631",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-41",
        "-58.943",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-41",
        "-59.252",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-41",
        "-59.558",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-41",
        "-59.861",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-41",
        "-60.161",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-41",
        "-60.457",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-41",
        "-60.750",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-41",
        "-61.040",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-41",
        "-61.326",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-41",
        "-61.608",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-41",
        "-61.887",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-41",
        "-62.162",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-41",
        "-62.433",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-41",
        "-62.700",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-41",
        "-62.962",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-41",
        "-63.221",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-41",
        "-63.476",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-41",
        "-63.726",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-41",
        "-63.971",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-41",
        "-64.213",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-41",
        "-64.449",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-41",
        "-64.682",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-41",
        "-58.193",
        "20.222",
        "6.957",
        "9.919",
        "-0.029"
    ],
    [
        "-111",
        "-41",
        "24.640",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-41",
        "24.613",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-41",
        "24.584",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-41",
        "24.552",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-41",
        "24.519",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-41",
        "24.483",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-41",
        "24.446",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-41",
        "24.405",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-41",
        "24.363",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-41",
        "24.318",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-41",
        "24.271",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-41",
        "24.222",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-41",
        "24.170",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-41",
        "24.116",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-41",
        "24.061",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-41",
        "24.003",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-41",
        "23.943",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-41",
        "23.880",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-41",
        "23.816",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-41",
        "26.740",
        "6.169",
        "6.545",
        "8.698",
        "0.177"
    ],
    [
        "-91",
        "-41",
        "43.609",
        "6.696",
        "9.618",
        "7.552",
        "0.036"
    ],
    [
        "-90",
        "-41",
        "71.067",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-41",
        "70.982",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-41",
        "70.887",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-41",
        "70.783",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-41",
        "70.668",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-41",
        "70.544",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-41",
        "70.410",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-41",
        "70.267",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-41",
        "70.114",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-41",
        "69.951",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-41",
        "69.779",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-41",
        "69.597",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-41",
        "69.406",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-41",
        "69.205",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-41",
        "67.035",
        "18.862",
        "2.299",
        "2.189",
        "-0.382"
    ],
    [
        "-75",
        "-41",
        "42.625",
        "21.242",
        "9.844",
        "6.121",
        "-0.507"
    ],
    [
        "-74",
        "-41",
        "21.045",
        "21.543",
        "7.567",
        "5.643",
        "-0.391"
    ],
    [
        "-73",
        "-41",
        "10.087",
        "20.071",
        "5.782",
        "4.732",
        "-0.331"
    ],
    [
        "-72",
        "-41",
        "6.679",
        "17.427",
        "4.532",
        "3.895",
        "-0.188"
    ],
    [
        "-71",
        "-41",
        "5.496",
        "14.672",
        "3.809",
        "3.597",
        "-0.048"
    ],
    [
        "-70",
        "-41",
        "4.599",
        "12.388",
        "3.442",
        "3.579",
        "0.026"
    ],
    [
        "-69",
        "-41",
        "3.641",
        "10.635",
        "3.135",
        "3.224",
        "0.023"
    ],
    [
        "-68",
        "-41",
        "2.610",
        "9.589",
        "0.654",
        "0.881",
        "0.032"
    ],
    [
        "-67",
        "-41",
        "2.407",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-41",
        "2.296",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-41",
        "2.182",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-41",
        "2.067",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-41",
        "1.951",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-41",
        "1.833",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-41",
        "1.714",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-41",
        "1.593",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-41",
        "1.472",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-41",
        "1.349",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-41",
        "1.225",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-41",
        "1.099",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-41",
        "0.972",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-41",
        "0.844",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-41",
        "0.715",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-41",
        "0.586",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-41",
        "0.455",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-41",
        "0.322",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-41",
        "0.190",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-41",
        "0.056",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-41",
        "-0.078",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-41",
        "-0.214",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-41",
        "-0.350",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-41",
        "-0.487",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-41",
        "-0.624",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-41",
        "-0.762",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-41",
        "-0.901",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-41",
        "-1.040",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-41",
        "-1.180",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-41",
        "-1.320",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-41",
        "-1.461",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-41",
        "-1.602",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-41",
        "-1.744",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-41",
        "-1.885",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-41",
        "-2.027",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-41",
        "-2.169",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-41",
        "-2.311",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-41",
        "-2.454",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-41",
        "-2.596",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-41",
        "-2.739",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-41",
        "-2.881",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-41",
        "-3.024",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-41",
        "-3.166",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-41",
        "-3.308",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-41",
        "-3.451",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-41",
        "-3.592",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-41",
        "-3.733",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-41",
        "-3.874",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-41",
        "-4.016",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-41",
        "-4.156",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-41",
        "-0.147",
        "11.135",
        "2.949",
        "2.153",
        "0.366"
    ],
    [
        "-16",
        "-41",
        "23.801",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-41",
        "23.599",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-41",
        "23.394",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-41",
        "23.189",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-41",
        "22.981",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-41",
        "22.773",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-41",
        "22.562",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-41",
        "22.351",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-41",
        "22.139",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-41",
        "21.925",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-41",
        "21.710",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-41",
        "21.494",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-41",
        "21.276",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-41",
        "21.059",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-41",
        "20.840",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-41",
        "20.620",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-41",
        "20.400",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-41",
        "20.179",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-41",
        "19.957",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-41",
        "19.735",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-41",
        "19.512",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-41",
        "19.289",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-41",
        "19.067",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-41",
        "18.842",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-41",
        "18.619",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-41",
        "18.395",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-41",
        "18.171",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-41",
        "17.948",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-41",
        "17.724",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-41",
        "17.500",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-41",
        "17.276",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-41",
        "17.054",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-41",
        "16.831",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-41",
        "16.608",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-41",
        "16.386",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-41",
        "16.165",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-41",
        "15.944",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-41",
        "15.725",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-41",
        "15.505",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-41",
        "15.287",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-41",
        "15.069",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-41",
        "14.853",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-41",
        "14.638",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-41",
        "14.422",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-41",
        "14.209",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-41",
        "13.997",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-41",
        "13.787",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-41",
        "13.578",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-41",
        "13.370",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-41",
        "13.122",
        "17.903",
        "0.613",
        "0.608",
        "-0.021"
    ],
    [
        "34",
        "-41",
        "12.815",
        "17.456",
        "0.706",
        "0.816",
        "0.006"
    ],
    [
        "35",
        "-41",
        "12.529",
        "16.872",
        "0.808",
        "0.935",
        "-0.009"
    ],
    [
        "36",
        "-41",
        "12.269",
        "16.234",
        "0.924",
        "1.007",
        "-0.019"
    ],
    [
        "37",
        "-41",
        "12.050",
        "15.588",
        "0.973",
        "1.121",
        "-0.034"
    ],
    [
        "38",
        "-41",
        "11.894",
        "14.928",
        "1.080",
        "1.242",
        "-0.021"
    ],
    [
        "39",
        "-41",
        "11.790",
        "14.181",
        "1.263",
        "1.415",
        "0.002"
    ],
    [
        "40",
        "-41",
        "11.593",
        "13.036",
        "1.416",
        "1.916",
        "0.016"
    ],
    [
        "41",
        "-41",
        "11.246",
        "11.502",
        "1.654",
        "2.750",
        "0.066"
    ],
    [
        "42",
        "-41",
        "10.891",
        "9.895",
        "2.072",
        "3.593",
        "0.102"
    ],
    [
        "43",
        "-41",
        "10.304",
        "7.918",
        "2.206",
        "4.180",
        "-0.003"
    ],
    [
        "44",
        "-41",
        "9.628",
        "5.105",
        "2.173",
        "4.042",
        "-0.137"
    ],
    [
        "45",
        "-41",
        "9.528",
        "2.664",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-41",
        "9.498",
        "2.464",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-41",
        "9.472",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-41",
        "9.447",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-41",
        "9.425",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-41",
        "9.405",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-41",
        "9.387",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-41",
        "9.371",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-41",
        "9.358",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-41",
        "9.348",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-41",
        "9.339",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-41",
        "9.333",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-41",
        "9.330",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-41",
        "9.328",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-41",
        "9.328",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-41",
        "9.332",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-41",
        "9.338",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-41",
        "9.346",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-41",
        "9.356",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-41",
        "9.369",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-41",
        "9.384",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-41",
        "9.401",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-41",
        "9.421",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-41",
        "9.444",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-41",
        "9.467",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-41",
        "9.494",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-41",
        "9.523",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-41",
        "9.554",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-41",
        "9.588",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-41",
        "9.624",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-41",
        "9.662",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-41",
        "9.703",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-41",
        "9.745",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-41",
        "9.790",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-41",
        "53.251",
        "36.959",
        "2.138",
        "3.905",
        "-0.029"
    ],
    [
        "80",
        "-41",
        "56.643",
        "39.564",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-41",
        "56.186",
        "40.284",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-41",
        "55.720",
        "40.991",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-41",
        "55.247",
        "41.687",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-41",
        "54.765",
        "42.370",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-41",
        "54.277",
        "43.041",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-41",
        "49.808",
        "32.944",
        "7.233",
        "8.137",
        "-0.196"
    ],
    [
        "87",
        "-41",
        "53.276",
        "44.341",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-41",
        "52.765",
        "44.970",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-41",
        "52.246",
        "45.587",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-41",
        "51.721",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-41",
        "51.189",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-41",
        "50.650",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-41",
        "50.104",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-41",
        "49.553",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-41",
        "48.995",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-41",
        "48.431",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-41",
        "47.861",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-41",
        "47.286",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-41",
        "46.705",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-41",
        "46.119",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-41",
        "45.528",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-41",
        "44.931",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-41",
        "44.330",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-41",
        "43.725",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-41",
        "43.116",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-41",
        "42.502",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-41",
        "41.883",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-41",
        "41.262",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-41",
        "40.636",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-41",
        "40.006",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-41",
        "39.375",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-41",
        "38.739",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-41",
        "38.101",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-41",
        "37.461",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-41",
        "36.817",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-41",
        "36.172",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-41",
        "35.524",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-41",
        "34.874",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-41",
        "34.224",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-41",
        "33.571",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-41",
        "32.917",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-41",
        "32.262",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-41",
        "31.606",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-41",
        "30.949",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-41",
        "30.292",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-41",
        "29.635",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-41",
        "28.977",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-41",
        "28.320",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-41",
        "27.663",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-41",
        "27.006",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-41",
        "26.351",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-41",
        "25.696",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-41",
        "25.042",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-41",
        "24.390",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-41",
        "23.738",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-41",
        "23.089",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-41",
        "22.442",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-41",
        "21.797",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-41",
        "21.153",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-41",
        "20.513",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-41",
        "19.875",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-41",
        "19.240",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-41",
        "18.608",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-41",
        "17.980",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-41",
        "17.354",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-41",
        "16.733",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-41",
        "16.116",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-41",
        "15.502",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-41",
        "14.893",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-41",
        "14.287",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-41",
        "13.686",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-41",
        "13.092",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-41",
        "12.500",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-41",
        "11.915",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-41",
        "11.335",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-41",
        "10.760",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-41",
        "10.190",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-41",
        "9.627",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-41",
        "9.070",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-41",
        "8.519",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-41",
        "7.974",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-41",
        "7.436",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-41",
        "6.905",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-41",
        "6.380",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-41",
        "5.862",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-41",
        "5.352",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-41",
        "4.848",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-41",
        "4.353",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-41",
        "3.864",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-41",
        "3.383",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-41",
        "2.911",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-41",
        "-0.011",
        "40.765",
        "3.480",
        "5.058",
        "-0.369"
    ],
    [
        "173",
        "-41",
        "-6.329",
        "47.865",
        "4.685",
        "5.518",
        "-0.007"
    ],
    [
        "174",
        "-41",
        "-13.927",
        "37.239",
        "5.505",
        "6.470",
        "-0.270"
    ],
    [
        "175",
        "-41",
        "-24.877",
        "29.131",
        "3.758",
        "4.634",
        "-0.352"
    ],
    [
        "176",
        "-41",
        "-31.893",
        "28.306",
        "2.872",
        "4.382",
        "-0.447"
    ],
    [
        "177",
        "-41",
        "-38.606",
        "26.098",
        "3.099",
        "4.073",
        "-0.418"
    ],
    [
        "178",
        "-41",
        "-41.352",
        "27.432",
        "2.006",
        "2.687",
        "-0.426"
    ],
    [
        "179",
        "-41",
        "-42.864",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-40",
        "-44.065",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-40",
        "-44.395",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-40",
        "-44.728",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-40",
        "-45.062",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-40",
        "-45.397",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-40",
        "-45.734",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-40",
        "-46.073",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-40",
        "-46.412",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-40",
        "-46.753",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-40",
        "-47.095",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-40",
        "-47.437",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-40",
        "-47.781",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-40",
        "-48.125",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-40",
        "-48.469",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-40",
        "-48.815",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-40",
        "-49.160",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-40",
        "-49.506",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-40",
        "-49.852",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-40",
        "-50.198",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-40",
        "-50.544",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-40",
        "-50.890",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-40",
        "-51.235",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-40",
        "-51.580",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-40",
        "-51.925",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-40",
        "-52.269",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-40",
        "-52.613",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-40",
        "-52.956",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-40",
        "-53.297",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-40",
        "-53.638",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-40",
        "-53.978",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-40",
        "-54.317",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-40",
        "-54.654",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-40",
        "-54.990",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-40",
        "-55.324",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-40",
        "-55.657",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-40",
        "-55.988",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-40",
        "-56.317",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-40",
        "-56.645",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-40",
        "-56.970",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-40",
        "-57.293",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-40",
        "-57.614",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-40",
        "-57.933",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-40",
        "-58.249",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-40",
        "-58.563",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-40",
        "-58.874",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-40",
        "-59.183",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-40",
        "-59.489",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-40",
        "-59.791",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-40",
        "-60.091",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-40",
        "-60.388",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-40",
        "-60.682",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-40",
        "-60.973",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-40",
        "-61.260",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-40",
        "-61.543",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-40",
        "-61.824",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-40",
        "-62.100",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-40",
        "-62.373",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-40",
        "-62.643",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-40",
        "-62.908",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-40",
        "-63.170",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-40",
        "-63.427",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-40",
        "-63.681",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-40",
        "-63.930",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-40",
        "-64.175",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-40",
        "-64.416",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-40",
        "-64.652",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-40",
        "-64.884",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-40",
        "-65.111",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-40",
        "-57.373",
        "18.438",
        "6.839",
        "9.573",
        "-0.006"
    ],
    [
        "-111",
        "-40",
        "24.743",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-40",
        "24.716",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-40",
        "24.688",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-40",
        "24.658",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-40",
        "24.625",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-40",
        "24.590",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-40",
        "24.552",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-40",
        "24.512",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-40",
        "24.470",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-40",
        "24.427",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-40",
        "24.381",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-40",
        "24.333",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-40",
        "24.282",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-40",
        "24.230",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-40",
        "24.175",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-40",
        "24.118",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-40",
        "24.059",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-40",
        "23.998",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-40",
        "23.936",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-40",
        "39.089",
        "5.401",
        "7.392",
        "10.447",
        "0.147"
    ],
    [
        "-91",
        "-40",
        "71.063",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-40",
        "70.990",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-40",
        "70.906",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-40",
        "70.814",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-40",
        "70.711",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-40",
        "70.599",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-40",
        "70.478",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-40",
        "70.346",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-40",
        "70.206",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-40",
        "70.056",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-40",
        "69.897",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-40",
        "69.728",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-40",
        "69.550",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-40",
        "69.362",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-40",
        "69.166",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-40",
        "68.530",
        "18.649",
        "1.989",
        "2.164",
        "-0.251"
    ],
    [
        "-75",
        "-40",
        "53.421",
        "19.932",
        "10.061",
        "6.184",
        "-0.476"
    ],
    [
        "-74",
        "-40",
        "32.528",
        "20.835",
        "8.116",
        "5.867",
        "-0.340"
    ],
    [
        "-73",
        "-40",
        "16.587",
        "19.769",
        "6.178",
        "4.735",
        "-0.306"
    ],
    [
        "-72",
        "-40",
        "9.614",
        "17.129",
        "4.429",
        "3.623",
        "-0.166"
    ],
    [
        "-71",
        "-40",
        "7.251",
        "14.342",
        "3.360",
        "3.139",
        "-0.031"
    ],
    [
        "-70",
        "-40",
        "5.949",
        "12.235",
        "3.035",
        "3.096",
        "0.015"
    ],
    [
        "-69",
        "-40",
        "4.479",
        "10.633",
        "2.843",
        "2.912",
        "0.026"
    ],
    [
        "-68",
        "-40",
        "2.566",
        "9.596",
        "0.610",
        "0.838",
        "0.013"
    ],
    [
        "-67",
        "-40",
        "2.263",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-40",
        "2.154",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-40",
        "2.043",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-40",
        "1.930",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-40",
        "1.816",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-40",
        "1.700",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-40",
        "1.584",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-40",
        "1.466",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-40",
        "1.347",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-40",
        "1.226",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-40",
        "1.104",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-40",
        "0.982",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-40",
        "0.857",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-40",
        "0.732",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-40",
        "0.606",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-40",
        "0.479",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-40",
        "0.350",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-40",
        "0.221",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-40",
        "0.091",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-40",
        "-0.040",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-40",
        "-0.172",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-40",
        "-0.305",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-40",
        "-0.438",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-40",
        "-0.572",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-40",
        "-0.707",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-40",
        "-0.843",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-40",
        "-0.978",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-40",
        "-1.115",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-40",
        "-1.251",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-40",
        "-1.389",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-40",
        "-1.527",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-40",
        "-1.665",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-40",
        "-1.803",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-40",
        "-1.943",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-40",
        "-2.081",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-40",
        "-2.220",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-40",
        "-2.360",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-40",
        "-2.500",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-40",
        "-2.639",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-40",
        "-2.779",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-40",
        "-2.919",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-40",
        "-3.058",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-40",
        "-3.198",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-40",
        "-3.337",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-40",
        "-3.476",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-40",
        "-3.615",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-40",
        "-3.754",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-40",
        "-3.891",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-40",
        "-4.029",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-40",
        "-4.168",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-40",
        "-4.305",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-40",
        "12.715",
        "15.893",
        "2.725",
        "2.040",
        "0.243"
    ],
    [
        "-15",
        "-40",
        "23.764",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-40",
        "23.564",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-40",
        "23.362",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-40",
        "23.159",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-40",
        "22.955",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-40",
        "22.749",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-40",
        "22.541",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-40",
        "22.333",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-40",
        "22.124",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-40",
        "21.913",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-40",
        "21.702",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-40",
        "21.489",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-40",
        "21.275",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-40",
        "21.061",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-40",
        "20.846",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-40",
        "20.630",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-40",
        "20.414",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-40",
        "20.196",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-40",
        "19.978",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-40",
        "19.761",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-40",
        "19.542",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-40",
        "19.323",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-40",
        "19.104",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-40",
        "18.884",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-40",
        "18.665",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-40",
        "18.446",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-40",
        "18.227",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-40",
        "18.007",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-40",
        "17.789",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-40",
        "17.570",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-40",
        "17.350",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-40",
        "17.132",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-40",
        "16.915",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-40",
        "16.697",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-40",
        "16.480",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-40",
        "16.264",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-40",
        "16.049",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-40",
        "15.834",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-40",
        "15.620",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-40",
        "15.407",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-40",
        "15.195",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-40",
        "14.984",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-40",
        "14.774",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-40",
        "14.565",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-40",
        "14.357",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-40",
        "14.150",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-40",
        "13.945",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-40",
        "13.741",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-40",
        "13.540",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-40",
        "13.334",
        "17.527",
        "0.657",
        "0.768",
        "-0.002"
    ],
    [
        "35",
        "-40",
        "13.139",
        "16.992",
        "0.773",
        "0.894",
        "-0.010"
    ],
    [
        "36",
        "-40",
        "12.961",
        "16.434",
        "0.881",
        "0.948",
        "-0.021"
    ],
    [
        "37",
        "-40",
        "12.804",
        "15.875",
        "0.898",
        "1.030",
        "-0.033"
    ],
    [
        "38",
        "-40",
        "12.679",
        "15.337",
        "0.957",
        "1.091",
        "-0.040"
    ],
    [
        "39",
        "-40",
        "12.582",
        "14.859",
        "1.073",
        "1.169",
        "-0.043"
    ],
    [
        "40",
        "-40",
        "12.442",
        "14.390",
        "1.167",
        "1.437",
        "-0.072"
    ],
    [
        "41",
        "-40",
        "12.195",
        "13.777",
        "1.338",
        "1.934",
        "-0.066"
    ],
    [
        "42",
        "-40",
        "11.899",
        "13.001",
        "1.667",
        "2.518",
        "-0.029"
    ],
    [
        "43",
        "-40",
        "11.572",
        "12.088",
        "1.891",
        "3.216",
        "-0.016"
    ],
    [
        "44",
        "-40",
        "11.187",
        "10.900",
        "2.128",
        "3.847",
        "-0.001"
    ],
    [
        "45",
        "-40",
        "10.721",
        "8.857",
        "2.331",
        "4.137",
        "0.001"
    ],
    [
        "46",
        "-40",
        "9.978",
        "3.062",
        "0.521",
        "1.394",
        "0.049"
    ],
    [
        "47",
        "-40",
        "9.881",
        "2.262",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-40",
        "9.857",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-40",
        "9.835",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-40",
        "9.815",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-40",
        "9.798",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-40",
        "9.783",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-40",
        "9.770",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-40",
        "9.759",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-40",
        "9.751",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-40",
        "9.745",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-40",
        "9.741",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-40",
        "9.740",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-40",
        "9.741",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-40",
        "9.745",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-40",
        "9.750",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-40",
        "9.758",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-40",
        "9.768",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-40",
        "9.781",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-40",
        "9.795",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-40",
        "9.812",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-40",
        "9.832",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-40",
        "9.854",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-40",
        "9.878",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-40",
        "9.903",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-40",
        "9.932",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-40",
        "9.963",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-40",
        "9.996",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-40",
        "10.031",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-40",
        "10.068",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-40",
        "10.107",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-40",
        "21.543",
        "8.716",
        "7.469",
        "9.560",
        "0.118"
    ],
    [
        "78",
        "-40",
        "34.657",
        "15.041",
        "8.384",
        "8.243",
        "-0.072"
    ],
    [
        "79",
        "-40",
        "56.971",
        "38.831",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-40",
        "56.531",
        "39.564",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-40",
        "56.083",
        "40.284",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-40",
        "55.627",
        "40.991",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-40",
        "55.163",
        "41.687",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-40",
        "54.692",
        "42.370",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-40",
        "54.213",
        "43.041",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-40",
        "53.726",
        "43.697",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-40",
        "53.233",
        "44.341",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-40",
        "52.732",
        "44.970",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-40",
        "52.224",
        "45.587",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-40",
        "51.708",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-40",
        "51.187",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-40",
        "50.659",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-40",
        "50.125",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-40",
        "49.584",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-40",
        "49.038",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-40",
        "48.485",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-40",
        "47.927",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-40",
        "47.363",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-40",
        "46.794",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-40",
        "46.220",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-40",
        "45.640",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-40",
        "45.057",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-40",
        "44.468",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-40",
        "43.874",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-40",
        "43.277",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-40",
        "42.676",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-40",
        "42.070",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-40",
        "41.461",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-40",
        "40.848",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-40",
        "40.232",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-40",
        "39.612",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-40",
        "38.990",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-40",
        "38.365",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-40",
        "37.737",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-40",
        "37.107",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-40",
        "36.474",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-40",
        "35.840",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-40",
        "35.203",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-40",
        "34.566",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-40",
        "33.926",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-40",
        "33.285",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-40",
        "32.644",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-40",
        "32.001",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-40",
        "31.358",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-40",
        "30.713",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-40",
        "30.070",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-40",
        "29.426",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-40",
        "28.781",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-40",
        "28.137",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-40",
        "27.494",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-40",
        "26.852",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-40",
        "26.210",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-40",
        "25.570",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-40",
        "24.930",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-40",
        "24.292",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-40",
        "23.656",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-40",
        "23.022",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-40",
        "22.389",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-40",
        "21.759",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-40",
        "21.132",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-40",
        "20.507",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-40",
        "19.885",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-40",
        "19.266",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-40",
        "18.650",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-40",
        "18.038",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-40",
        "17.429",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-40",
        "16.824",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-40",
        "16.223",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-40",
        "15.625",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-40",
        "15.032",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-40",
        "14.444",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-40",
        "13.860",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-40",
        "13.282",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-40",
        "12.709",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-40",
        "12.140",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-40",
        "11.576",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-40",
        "11.019",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-40",
        "10.467",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-40",
        "9.921",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-40",
        "9.381",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-40",
        "8.848",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-40",
        "8.320",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-40",
        "7.799",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-40",
        "7.285",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-40",
        "6.778",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-40",
        "6.277",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-40",
        "5.784",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-40",
        "5.299",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-40",
        "4.820",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-40",
        "4.349",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-40",
        "3.886",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-40",
        "3.431",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-40",
        "2.984",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-40",
        "2.545",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-40",
        "2.115",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-40",
        "-14.922",
        "29.419",
        "3.314",
        "4.836",
        "-0.455"
    ],
    [
        "177",
        "-40",
        "-27.256",
        "24.330",
        "3.845",
        "4.125",
        "-0.460"
    ],
    [
        "178",
        "-40",
        "-35.028",
        "24.188",
        "3.027",
        "3.428",
        "-0.404"
    ],
    [
        "179",
        "-40",
        "-41.962",
        "28.239",
        "1.696",
        "1.880",
        "-0.467"
    ],
    [
        "-180",
        "-39",
        "-40.976",
        "28.589",
        "2.412",
        "1.740",
        "-0.436"
    ],
    [
        "-179",
        "-39",
        "-45.175",
        "29.515",
        "0.414",
        "0.589",
        "-0.326"
    ],
    [
        "-178",
        "-39",
        "-45.565",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-39",
        "-45.892",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-39",
        "-46.220",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-39",
        "-46.550",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-39",
        "-46.882",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-39",
        "-47.214",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-39",
        "-47.548",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-39",
        "-47.882",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-39",
        "-48.218",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-39",
        "-48.554",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-39",
        "-48.891",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-39",
        "-49.228",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-39",
        "-49.566",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-39",
        "-49.904",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-39",
        "-50.243",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-39",
        "-50.581",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-39",
        "-50.920",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-39",
        "-51.259",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-39",
        "-51.598",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-39",
        "-51.936",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-39",
        "-52.274",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-39",
        "-52.611",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-39",
        "-52.948",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-39",
        "-53.285",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-39",
        "-53.620",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-39",
        "-53.955",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-39",
        "-54.289",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-39",
        "-54.621",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-39",
        "-54.953",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-39",
        "-55.283",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-39",
        "-55.612",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-39",
        "-55.939",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-39",
        "-56.265",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-39",
        "-56.589",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-39",
        "-56.911",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-39",
        "-57.232",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-39",
        "-57.550",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-39",
        "-57.867",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-39",
        "-58.181",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-39",
        "-58.493",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-39",
        "-58.803",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-39",
        "-59.110",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-39",
        "-59.415",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-39",
        "-59.717",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-39",
        "-60.016",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-39",
        "-60.313",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-39",
        "-60.607",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-39",
        "-60.897",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-39",
        "-61.185",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-39",
        "-61.469",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-39",
        "-61.750",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-39",
        "-62.028",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-39",
        "-62.303",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-39",
        "-62.573",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-39",
        "-62.841",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-39",
        "-63.104",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-39",
        "-63.364",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-39",
        "-63.620",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-39",
        "-63.872",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-39",
        "-64.120",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-39",
        "-64.365",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-39",
        "-64.604",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-39",
        "-64.840",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-39",
        "-65.072",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-39",
        "-65.299",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-39",
        "-65.521",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-39",
        "-65.740",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-39",
        "24.838",
        "2.232",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-39",
        "24.812",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-39",
        "24.785",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-39",
        "24.754",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-39",
        "24.722",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-39",
        "24.687",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-39",
        "24.651",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-39",
        "24.612",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-39",
        "24.572",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-39",
        "24.529",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-39",
        "24.483",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-39",
        "24.436",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-39",
        "24.387",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-39",
        "24.335",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-39",
        "24.282",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-39",
        "24.226",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-39",
        "24.168",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-39",
        "24.109",
        "5.515",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-39",
        "24.048",
        "5.695",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-39",
        "58.234",
        "5.319",
        "13.812",
        "9.142",
        "0.060"
    ],
    [
        "-91",
        "-39",
        "70.963",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-39",
        "70.891",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-39",
        "70.810",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-39",
        "70.719",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-39",
        "70.619",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-39",
        "70.510",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-39",
        "70.390",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-39",
        "70.262",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-39",
        "70.124",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-39",
        "69.977",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-39",
        "69.821",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-39",
        "69.656",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-39",
        "69.481",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-39",
        "69.298",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-39",
        "69.105",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-39",
        "68.904",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-39",
        "62.981",
        "19.708",
        "9.574",
        "6.363",
        "-0.388"
    ],
    [
        "-74",
        "-39",
        "43.622",
        "20.999",
        "9.194",
        "6.563",
        "-0.206"
    ],
    [
        "-73",
        "-39",
        "25.057",
        "20.038",
        "7.264",
        "5.087",
        "-0.198"
    ],
    [
        "-72",
        "-39",
        "13.688",
        "16.889",
        "5.147",
        "3.578",
        "-0.123"
    ],
    [
        "-71",
        "-39",
        "8.819",
        "14.064",
        "3.402",
        "2.932",
        "-0.001"
    ],
    [
        "-70",
        "-39",
        "6.686",
        "12.070",
        "2.971",
        "2.878",
        "0.027"
    ],
    [
        "-69",
        "-39",
        "4.877",
        "10.578",
        "2.762",
        "2.782",
        "0.042"
    ],
    [
        "-68",
        "-39",
        "2.475",
        "9.599",
        "0.596",
        "0.828",
        "0.018"
    ],
    [
        "-67",
        "-39",
        "2.118",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-39",
        "2.011",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-39",
        "1.902",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-39",
        "1.793",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-39",
        "1.681",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-39",
        "1.567",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-39",
        "1.454",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-39",
        "1.337",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-39",
        "1.221",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-39",
        "1.103",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-39",
        "0.984",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-39",
        "0.864",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-39",
        "0.742",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-39",
        "0.619",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-39",
        "0.495",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-39",
        "0.370",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-39",
        "0.245",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-39",
        "0.119",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-39",
        "-0.008",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-39",
        "-0.137",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-39",
        "-0.266",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-39",
        "-0.395",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-39",
        "-0.526",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-39",
        "-0.657",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-39",
        "-0.790",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-39",
        "-0.922",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-39",
        "-1.055",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-39",
        "-1.189",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-39",
        "-1.323",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-39",
        "-1.457",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-39",
        "-1.592",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-39",
        "-1.727",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-39",
        "-1.863",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-39",
        "-1.999",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-39",
        "-2.135",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-39",
        "-2.271",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-39",
        "-2.408",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-39",
        "-2.544",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-39",
        "-2.681",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-39",
        "-2.818",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-39",
        "-2.955",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-39",
        "-3.091",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-39",
        "-3.228",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-39",
        "-3.364",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-39",
        "-3.500",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-39",
        "-3.636",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-39",
        "-3.772",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-39",
        "-3.907",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-39",
        "-4.043",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-39",
        "-4.177",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-39",
        "-4.312",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-39",
        "2.959",
        "13.577",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-39",
        "23.922",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-39",
        "23.726",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-39",
        "23.528",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-39",
        "23.329",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-39",
        "23.129",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-39",
        "22.928",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-39",
        "22.725",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-39",
        "22.521",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-39",
        "22.316",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-39",
        "22.110",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-39",
        "21.903",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-39",
        "21.695",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-39",
        "21.486",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-39",
        "21.276",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-39",
        "21.065",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-39",
        "20.853",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-39",
        "20.641",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-39",
        "20.428",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-39",
        "20.216",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-39",
        "20.002",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-39",
        "19.788",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-39",
        "19.574",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-39",
        "19.360",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-39",
        "19.145",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-39",
        "18.931",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-39",
        "18.715",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-39",
        "18.500",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-39",
        "18.286",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-39",
        "18.072",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-39",
        "17.858",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-39",
        "17.643",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-39",
        "17.429",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-39",
        "17.216",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-39",
        "17.004",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-39",
        "16.792",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-39",
        "16.579",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-39",
        "16.369",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-39",
        "16.158",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-39",
        "15.949",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-39",
        "15.740",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-39",
        "15.532",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-39",
        "15.326",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-39",
        "15.120",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-39",
        "14.915",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-39",
        "14.712",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-39",
        "14.510",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-39",
        "14.309",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-39",
        "14.109",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-39",
        "13.912",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-39",
        "13.778",
        "17.568",
        "0.654",
        "0.760",
        "-0.005"
    ],
    [
        "35",
        "-39",
        "13.643",
        "17.086",
        "0.764",
        "0.878",
        "-0.012"
    ],
    [
        "36",
        "-39",
        "13.511",
        "16.590",
        "0.867",
        "0.924",
        "-0.020"
    ],
    [
        "37",
        "-39",
        "13.382",
        "16.102",
        "0.874",
        "0.996",
        "-0.029"
    ],
    [
        "38",
        "-39",
        "13.255",
        "15.649",
        "0.914",
        "1.040",
        "-0.037"
    ],
    [
        "39",
        "-39",
        "13.125",
        "15.279",
        "0.995",
        "1.079",
        "-0.047"
    ],
    [
        "40",
        "-39",
        "12.979",
        "15.001",
        "1.028",
        "1.202",
        "-0.070"
    ],
    [
        "41",
        "-39",
        "12.804",
        "14.727",
        "1.116",
        "1.390",
        "-0.080"
    ],
    [
        "42",
        "-39",
        "12.598",
        "14.375",
        "1.295",
        "1.620",
        "-0.068"
    ],
    [
        "43",
        "-39",
        "12.374",
        "13.935",
        "1.450",
        "1.990",
        "-0.055"
    ],
    [
        "44",
        "-39",
        "12.155",
        "13.439",
        "1.711",
        "2.506",
        "-0.033"
    ],
    [
        "45",
        "-39",
        "11.918",
        "12.625",
        "2.128",
        "3.007",
        "-0.038"
    ],
    [
        "46",
        "-39",
        "11.453",
        "10.154",
        "2.295",
        "3.517",
        "-0.068"
    ],
    [
        "47",
        "-39",
        "10.654",
        "4.837",
        "2.290",
        "3.158",
        "-0.149"
    ],
    [
        "48",
        "-39",
        "10.264",
        "2.061",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-39",
        "10.243",
        "1.858",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-39",
        "10.223",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-39",
        "10.206",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-39",
        "10.191",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-39",
        "10.179",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-39",
        "10.168",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-39",
        "10.160",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-39",
        "10.154",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-39",
        "10.151",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-39",
        "10.150",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-39",
        "10.151",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-39",
        "10.154",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-39",
        "10.159",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-39",
        "10.167",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-39",
        "10.177",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-39",
        "10.189",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-39",
        "10.203",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-39",
        "10.220",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-39",
        "10.239",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-39",
        "10.260",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-39",
        "10.284",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-39",
        "10.310",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-39",
        "10.337",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-39",
        "10.368",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-39",
        "10.399",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-39",
        "10.434",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-39",
        "10.470",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-39",
        "10.509",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-39",
        "12.252",
        "-3.630",
        "2.145",
        "3.450",
        "0.036"
    ],
    [
        "78",
        "-39",
        "44.701",
        "27.017",
        "8.133",
        "7.804",
        "0.144"
    ],
    [
        "79",
        "-39",
        "56.833",
        "38.831",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-39",
        "56.402",
        "39.564",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-39",
        "55.963",
        "40.284",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-39",
        "55.517",
        "40.991",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-39",
        "55.063",
        "41.687",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-39",
        "54.601",
        "42.370",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-39",
        "54.132",
        "43.041",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-39",
        "53.657",
        "43.697",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-39",
        "53.173",
        "44.341",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-39",
        "52.682",
        "44.970",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-39",
        "52.185",
        "45.587",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-39",
        "51.681",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-39",
        "51.170",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-39",
        "50.653",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-39",
        "50.131",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-39",
        "49.601",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-39",
        "49.066",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-39",
        "48.525",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-39",
        "47.978",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-39",
        "47.426",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-39",
        "46.870",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-39",
        "46.307",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-39",
        "45.740",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-39",
        "45.168",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-39",
        "44.592",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-39",
        "44.011",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-39",
        "43.426",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-39",
        "42.837",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-39",
        "42.245",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-39",
        "41.648",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-39",
        "41.048",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-39",
        "40.444",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-39",
        "39.838",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-39",
        "39.228",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-39",
        "38.616",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-39",
        "38.002",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-39",
        "37.385",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-39",
        "36.766",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-39",
        "36.144",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-39",
        "35.521",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-39",
        "34.897",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-39",
        "34.271",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-39",
        "33.643",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-39",
        "33.015",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-39",
        "32.386",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-39",
        "31.756",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-39",
        "31.125",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-39",
        "30.495",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-39",
        "29.865",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-39",
        "29.234",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-39",
        "28.603",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-39",
        "27.974",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-39",
        "27.345",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-39",
        "26.717",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-39",
        "26.089",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-39",
        "25.463",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-39",
        "24.839",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-39",
        "24.216",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-39",
        "23.595",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-39",
        "22.976",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-39",
        "22.359",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-39",
        "21.744",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-39",
        "21.133",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-39",
        "20.524",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-39",
        "19.917",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-39",
        "19.315",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-39",
        "18.715",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-39",
        "18.119",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-39",
        "17.526",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-39",
        "16.938",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-39",
        "16.353",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-39",
        "15.773",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-39",
        "15.197",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-39",
        "14.625",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-39",
        "14.059",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-39",
        "13.498",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-39",
        "12.940",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-39",
        "12.389",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-39",
        "11.844",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-39",
        "11.304",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-39",
        "10.769",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-39",
        "10.240",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-39",
        "9.718",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-39",
        "9.201",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-39",
        "8.691",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-39",
        "8.188",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-39",
        "7.691",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-39",
        "7.201",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-39",
        "6.718",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-39",
        "6.243",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-39",
        "5.775",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-39",
        "5.314",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-39",
        "4.861",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-39",
        "4.415",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-39",
        "3.978",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-39",
        "3.547",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-39",
        "3.126",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-39",
        "2.408",
        "36.865",
        "0.875",
        "1.616",
        "-0.378"
    ],
    [
        "177",
        "-39",
        "-3.454",
        "25.089",
        "3.740",
        "3.764",
        "-0.479"
    ],
    [
        "178",
        "-39",
        "-15.028",
        "18.941",
        "3.407",
        "3.176",
        "-0.338"
    ],
    [
        "179",
        "-39",
        "-30.901",
        "25.245",
        "3.151",
        "2.799",
        "-0.416"
    ],
    [
        "-180",
        "-38",
        "-30.446",
        "26.342",
        "3.537",
        "2.741",
        "-0.458"
    ],
    [
        "-179",
        "-38",
        "-44.987",
        "29.353",
        "0.695",
        "0.897",
        "-0.376"
    ],
    [
        "-178",
        "-38",
        "-46.388",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-38",
        "-46.708",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-38",
        "-47.029",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-38",
        "-47.352",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-38",
        "-47.676",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-38",
        "-48.002",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-38",
        "-48.328",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-38",
        "-48.655",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-38",
        "-48.983",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-38",
        "-49.312",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-38",
        "-49.642",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-38",
        "-49.972",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-38",
        "-50.302",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-38",
        "-50.633",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-38",
        "-50.964",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-38",
        "-51.296",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-38",
        "-51.627",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-38",
        "-51.959",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-38",
        "-52.290",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-38",
        "-52.621",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-38",
        "-52.951",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-38",
        "-53.282",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-38",
        "-53.611",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-38",
        "-53.940",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-38",
        "-54.269",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-38",
        "-54.596",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-38",
        "-54.922",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-38",
        "-55.248",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-38",
        "-55.572",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-38",
        "-55.895",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-38",
        "-56.217",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-38",
        "-56.537",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-38",
        "-56.856",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-38",
        "-57.173",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-38",
        "-57.488",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-38",
        "-57.802",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-38",
        "-58.113",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-38",
        "-58.423",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-38",
        "-58.731",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-38",
        "-59.036",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-38",
        "-59.339",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-38",
        "-59.639",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-38",
        "-59.937",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-38",
        "-60.233",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-38",
        "-60.526",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-38",
        "-60.816",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-38",
        "-61.103",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-38",
        "-61.388",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-38",
        "-61.669",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-38",
        "-61.947",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-38",
        "-62.222",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-38",
        "-62.494",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-38",
        "-62.762",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-38",
        "-63.027",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-38",
        "-63.289",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-38",
        "-63.547",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-38",
        "-63.801",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-38",
        "-64.052",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-38",
        "-64.298",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-38",
        "-64.541",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-38",
        "-64.780",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-38",
        "-65.014",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-38",
        "-65.245",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-38",
        "-65.471",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-38",
        "-65.694",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-38",
        "-65.911",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-38",
        "-66.125",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-38",
        "-18.968",
        "8.505",
        "18.870",
        "19.699",
        "0.027"
    ],
    [
        "-110",
        "-38",
        "24.901",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-38",
        "24.873",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-38",
        "24.844",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-38",
        "24.812",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-38",
        "24.778",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-38",
        "24.742",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-38",
        "24.704",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-38",
        "24.665",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-38",
        "24.623",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-38",
        "24.579",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-38",
        "24.533",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-38",
        "24.484",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-38",
        "24.434",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-38",
        "24.382",
        "4.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-38",
        "24.327",
        "5.149",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-38",
        "24.270",
        "5.332",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-38",
        "27.131",
        "6.881",
        "4.620",
        "4.207",
        "0.027"
    ],
    [
        "-93",
        "-38",
        "70.954",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-38",
        "70.903",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-38",
        "70.841",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-38",
        "70.771",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-38",
        "70.692",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-38",
        "70.603",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-38",
        "70.505",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-38",
        "70.397",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-38",
        "70.281",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-38",
        "70.156",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-38",
        "70.021",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-38",
        "69.877",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-38",
        "69.724",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-38",
        "69.563",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-38",
        "69.392",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-38",
        "69.213",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-38",
        "69.024",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-38",
        "68.828",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-38",
        "68.622",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-38",
        "49.986",
        "21.404",
        "10.298",
        "7.321",
        "-0.079"
    ],
    [
        "-73",
        "-38",
        "31.277",
        "20.460",
        "8.359",
        "5.307",
        "-0.042"
    ],
    [
        "-72",
        "-38",
        "19.213",
        "15.912",
        "5.565",
        "3.290",
        "-0.081"
    ],
    [
        "-71",
        "-38",
        "10.610",
        "13.849",
        "3.457",
        "2.780",
        "0.029"
    ],
    [
        "-70",
        "-38",
        "7.424",
        "11.853",
        "2.973",
        "2.732",
        "0.029"
    ],
    [
        "-69",
        "-38",
        "5.232",
        "10.508",
        "2.759",
        "2.710",
        "0.054"
    ],
    [
        "-68",
        "-38",
        "2.377",
        "9.590",
        "0.598",
        "0.828",
        "0.018"
    ],
    [
        "-67",
        "-38",
        "1.973",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-38",
        "1.868",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-38",
        "1.762",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-38",
        "1.653",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-38",
        "1.545",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-38",
        "1.434",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-38",
        "1.323",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-38",
        "1.209",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-38",
        "1.095",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-38",
        "0.980",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-38",
        "0.863",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-38",
        "0.745",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-38",
        "0.626",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-38",
        "0.506",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-38",
        "0.385",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-38",
        "0.263",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-38",
        "0.141",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-38",
        "0.016",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-38",
        "-0.108",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-38",
        "-0.233",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-38",
        "-0.360",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-38",
        "-0.487",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-38",
        "-0.614",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-38",
        "-0.743",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-38",
        "-0.872",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-38",
        "-1.001",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-38",
        "-1.131",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-38",
        "-1.262",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-38",
        "-1.393",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-38",
        "-1.525",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-38",
        "-1.657",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-38",
        "-1.789",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-38",
        "-1.922",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-38",
        "-2.055",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-38",
        "-2.188",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-38",
        "-2.322",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-38",
        "-2.455",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-38",
        "-2.588",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-38",
        "-2.723",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-38",
        "-2.856",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-38",
        "-2.990",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-38",
        "-3.124",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-38",
        "-3.257",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-38",
        "-3.391",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-38",
        "-3.524",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-38",
        "-3.656",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-38",
        "-3.790",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-38",
        "-3.922",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-38",
        "-4.054",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-38",
        "-4.186",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-38",
        "24.451",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-38",
        "24.262",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-38",
        "24.072",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-38",
        "23.881",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-38",
        "23.688",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-38",
        "23.493",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-38",
        "23.297",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-38",
        "23.100",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-38",
        "22.902",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-38",
        "22.702",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-38",
        "22.501",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-38",
        "22.300",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-38",
        "22.097",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-38",
        "21.894",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-38",
        "21.689",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-38",
        "21.483",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-38",
        "21.277",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-38",
        "21.070",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-38",
        "20.863",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-38",
        "20.656",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-38",
        "20.447",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-38",
        "20.238",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-38",
        "20.029",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-38",
        "19.819",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-38",
        "19.609",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-38",
        "19.400",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-38",
        "19.190",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-38",
        "18.979",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-38",
        "18.769",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-38",
        "18.559",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-38",
        "18.349",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-38",
        "18.140",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-38",
        "17.930",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-38",
        "17.721",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-38",
        "17.512",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-38",
        "17.304",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-38",
        "17.096",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-38",
        "16.890",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-38",
        "16.684",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-38",
        "16.477",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-38",
        "16.272",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-38",
        "16.068",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-38",
        "15.865",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-38",
        "15.663",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-38",
        "15.462",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-38",
        "15.261",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-38",
        "15.063",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-38",
        "14.865",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-38",
        "14.668",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-38",
        "14.474",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-38",
        "14.280",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-38",
        "14.185",
        "17.598",
        "0.654",
        "0.759",
        "-0.004"
    ],
    [
        "35",
        "-38",
        "14.088",
        "17.163",
        "0.761",
        "0.872",
        "-0.011"
    ],
    [
        "36",
        "-38",
        "13.984",
        "16.721",
        "0.860",
        "0.912",
        "-0.018"
    ],
    [
        "37",
        "-38",
        "13.876",
        "16.291",
        "0.861",
        "0.979",
        "-0.026"
    ],
    [
        "38",
        "-38",
        "13.757",
        "15.892",
        "0.893",
        "1.018",
        "-0.035"
    ],
    [
        "39",
        "-38",
        "13.631",
        "15.552",
        "0.958",
        "1.038",
        "-0.044"
    ],
    [
        "40",
        "-38",
        "13.500",
        "15.273",
        "0.952",
        "1.106",
        "-0.058"
    ],
    [
        "41",
        "-38",
        "13.372",
        "15.022",
        "0.991",
        "1.171",
        "-0.067"
    ],
    [
        "42",
        "-38",
        "13.244",
        "14.752",
        "1.088",
        "1.230",
        "-0.068"
    ],
    [
        "43",
        "-38",
        "13.118",
        "14.438",
        "1.172",
        "1.372",
        "-0.075"
    ],
    [
        "44",
        "-38",
        "13.006",
        "14.106",
        "1.363",
        "1.636",
        "-0.068"
    ],
    [
        "45",
        "-38",
        "12.911",
        "13.722",
        "1.689",
        "1.988",
        "-0.074"
    ],
    [
        "46",
        "-38",
        "12.743",
        "12.808",
        "1.914",
        "2.545",
        "-0.100"
    ],
    [
        "47",
        "-38",
        "12.263",
        "10.358",
        "2.099",
        "3.185",
        "-0.065"
    ],
    [
        "48",
        "-38",
        "11.710",
        "7.584",
        "2.282",
        "3.761",
        "-0.022"
    ],
    [
        "49",
        "-38",
        "11.345",
        "4.875",
        "2.018",
        "3.758",
        "-0.013"
    ],
    [
        "50",
        "-38",
        "10.628",
        "1.655",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-38",
        "10.611",
        "1.451",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-38",
        "10.597",
        "1.247",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-38",
        "10.585",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-38",
        "10.574",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-38",
        "10.566",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-38",
        "10.561",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-38",
        "10.558",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-38",
        "10.556",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-38",
        "10.557",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-38",
        "10.560",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-38",
        "10.566",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-38",
        "10.573",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-38",
        "10.583",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-38",
        "10.595",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-38",
        "10.609",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-38",
        "10.625",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-38",
        "10.644",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-38",
        "10.665",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-38",
        "10.688",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-38",
        "10.712",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-38",
        "10.740",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-38",
        "10.769",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-38",
        "10.801",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-38",
        "10.834",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-38",
        "10.870",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-38",
        "10.907",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-38",
        "10.948",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-38",
        "21.225",
        "4.451",
        "7.266",
        "7.062",
        "0.192"
    ],
    [
        "79",
        "-38",
        "53.113",
        "35.669",
        "5.480",
        "6.912",
        "0.122"
    ],
    [
        "80",
        "-38",
        "56.256",
        "39.564",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-38",
        "55.827",
        "40.284",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-38",
        "55.391",
        "40.991",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-38",
        "54.946",
        "41.687",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-38",
        "54.494",
        "42.370",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-38",
        "54.035",
        "43.041",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-38",
        "53.570",
        "43.697",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-38",
        "53.097",
        "44.341",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-38",
        "52.617",
        "44.970",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-38",
        "52.130",
        "45.587",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-38",
        "51.637",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-38",
        "51.138",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-38",
        "50.632",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-38",
        "50.120",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-38",
        "49.603",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-38",
        "49.079",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-38",
        "48.550",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-38",
        "48.015",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-38",
        "47.475",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-38",
        "46.930",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-38",
        "46.380",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-38",
        "45.826",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-38",
        "45.266",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-38",
        "44.702",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-38",
        "44.134",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-38",
        "43.562",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-38",
        "42.985",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-38",
        "42.405",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-38",
        "41.822",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-38",
        "41.235",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-38",
        "40.645",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-38",
        "40.052",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-38",
        "39.455",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-38",
        "38.857",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-38",
        "38.256",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-38",
        "37.652",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-38",
        "37.046",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-38",
        "36.438",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-38",
        "35.829",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-38",
        "35.217",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-38",
        "34.605",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-38",
        "33.992",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-38",
        "33.377",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-38",
        "32.761",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-38",
        "32.145",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-38",
        "31.529",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-38",
        "30.912",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-38",
        "30.294",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-38",
        "29.677",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-38",
        "29.061",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-38",
        "28.444",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-38",
        "27.829",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-38",
        "27.215",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-38",
        "26.602",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-38",
        "25.989",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-38",
        "25.378",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-38",
        "24.769",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-38",
        "24.161",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-38",
        "23.555",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-38",
        "22.952",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-38",
        "22.351",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-38",
        "21.753",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-38",
        "21.157",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-38",
        "20.563",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-38",
        "19.974",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-38",
        "19.387",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-38",
        "18.804",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-38",
        "18.224",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-38",
        "17.648",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-38",
        "17.076",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-38",
        "16.509",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-38",
        "15.945",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-38",
        "15.386",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-38",
        "14.832",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-38",
        "14.282",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-38",
        "13.738",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-38",
        "13.198",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-38",
        "12.665",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-38",
        "12.136",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-38",
        "11.613",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-38",
        "11.096",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-38",
        "10.585",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-38",
        "10.080",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-38",
        "9.581",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-38",
        "9.088",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-38",
        "8.603",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-38",
        "8.123",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-38",
        "7.651",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-38",
        "7.185",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-38",
        "6.728",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-38",
        "6.276",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-38",
        "5.833",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-38",
        "5.398",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-38",
        "4.969",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-38",
        "4.549",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-38",
        "4.136",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-38",
        "3.761",
        "36.203",
        "0.660",
        "1.245",
        "-0.450"
    ],
    [
        "177",
        "-38",
        "3.388",
        "31.279",
        "3.970",
        "4.044",
        "-0.468"
    ],
    [
        "178",
        "-38",
        "9.734",
        "16.541",
        "2.643",
        "2.866",
        "-0.262"
    ],
    [
        "179",
        "-38",
        "-8.536",
        "21.038",
        "3.505",
        "3.347",
        "-0.369"
    ],
    [
        "-180",
        "-37",
        "-19.356",
        "24.532",
        "4.933",
        "3.697",
        "-0.452"
    ],
    [
        "-179",
        "-37",
        "-44.155",
        "29.139",
        "1.068",
        "1.291",
        "-0.350"
    ],
    [
        "-178",
        "-37",
        "-47.197",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-37",
        "-47.510",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-37",
        "-47.824",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-37",
        "-48.140",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-37",
        "-48.456",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-37",
        "-48.774",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-37",
        "-49.093",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-37",
        "-49.413",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-37",
        "-49.734",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-37",
        "-50.055",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-37",
        "-50.377",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-37",
        "-50.700",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-37",
        "-51.023",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-37",
        "-51.347",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-37",
        "-51.671",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-37",
        "-51.994",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-37",
        "-52.318",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-37",
        "-52.642",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-37",
        "-52.966",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-37",
        "-53.290",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-37",
        "-53.613",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-37",
        "-53.936",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-37",
        "-54.258",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-37",
        "-54.579",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-37",
        "-54.900",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-37",
        "-55.220",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-37",
        "-55.540",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-37",
        "-55.858",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-37",
        "-56.175",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-37",
        "-56.490",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-37",
        "-56.805",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-37",
        "-57.118",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-37",
        "-57.430",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-37",
        "-57.739",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-37",
        "-58.048",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-37",
        "-58.354",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-37",
        "-58.659",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-37",
        "-58.961",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-37",
        "-59.262",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-37",
        "-59.560",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-37",
        "-59.857",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-37",
        "-60.150",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-37",
        "-60.442",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-37",
        "-60.731",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-37",
        "-61.017",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-37",
        "-61.301",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-37",
        "-61.581",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-37",
        "-61.859",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-37",
        "-62.134",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-37",
        "-62.406",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-37",
        "-62.675",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-37",
        "-62.941",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-37",
        "-63.203",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-37",
        "-63.462",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-37",
        "-63.718",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-37",
        "-63.970",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-37",
        "-64.218",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-37",
        "-64.463",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-37",
        "-64.704",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-37",
        "-64.942",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-37",
        "-65.175",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-37",
        "-65.405",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-37",
        "-65.630",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-37",
        "-65.851",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-37",
        "-66.068",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-37",
        "-66.281",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-37",
        "-66.490",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-37",
        "-66.694",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-37",
        "24.981",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-37",
        "24.955",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-37",
        "24.926",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-37",
        "24.894",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-37",
        "24.862",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-37",
        "24.827",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-37",
        "24.790",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-37",
        "24.751",
        "3.819",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-37",
        "24.709",
        "4.013",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-37",
        "24.667",
        "4.205",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-37",
        "24.621",
        "4.397",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-37",
        "24.574",
        "4.587",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-37",
        "24.525",
        "4.776",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-37",
        "26.770",
        "4.715",
        "3.502",
        "4.359",
        "0.058"
    ],
    [
        "-96",
        "-37",
        "70.904",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-37",
        "70.881",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-37",
        "70.850",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-37",
        "70.808",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-37",
        "70.758",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-37",
        "70.698",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-37",
        "70.629",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-37",
        "70.552",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-37",
        "70.465",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-37",
        "70.369",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-37",
        "70.264",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-37",
        "70.151",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-37",
        "70.028",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-37",
        "69.895",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-37",
        "69.756",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-37",
        "69.606",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-37",
        "69.448",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-37",
        "69.281",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-37",
        "69.105",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-37",
        "68.922",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-37",
        "68.730",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-37",
        "68.528",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-37",
        "54.930",
        "21.350",
        "7.754",
        "7.298",
        "0.162"
    ],
    [
        "-73",
        "-37",
        "33.700",
        "22.705",
        "3.177",
        "1.886",
        "0.038"
    ],
    [
        "-72",
        "-37",
        "23.952",
        "14.259",
        "4.433",
        "2.618",
        "0.009"
    ],
    [
        "-71",
        "-37",
        "13.491",
        "13.508",
        "3.531",
        "2.674",
        "-0.058"
    ],
    [
        "-70",
        "-37",
        "8.737",
        "11.562",
        "3.001",
        "2.543",
        "0.009"
    ],
    [
        "-69",
        "-37",
        "5.984",
        "10.540",
        "2.841",
        "2.593",
        "0.040"
    ],
    [
        "-68",
        "-37",
        "3.551",
        "9.711",
        "2.343",
        "2.600",
        "-0.008"
    ],
    [
        "-67",
        "-37",
        "2.164",
        "9.747",
        "2.248",
        "2.640",
        "0.015"
    ],
    [
        "-66",
        "-37",
        "1.776",
        "9.852",
        "2.437",
        "2.437",
        "-0.009"
    ],
    [
        "-65",
        "-37",
        "1.620",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-37",
        "1.515",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-37",
        "1.409",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-37",
        "1.301",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-37",
        "1.191",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-37",
        "1.081",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-37",
        "0.969",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-37",
        "0.856",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-37",
        "0.741",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-37",
        "0.626",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-37",
        "0.510",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-37",
        "0.393",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-37",
        "0.275",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-37",
        "0.156",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-37",
        "0.036",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-37",
        "-0.085",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-37",
        "-0.207",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-37",
        "-0.330",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-37",
        "-0.453",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-37",
        "-0.578",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-37",
        "-0.702",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-37",
        "-0.827",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-37",
        "-0.954",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-37",
        "-1.080",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-37",
        "-1.208",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-37",
        "-1.336",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-37",
        "-1.464",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-37",
        "-1.592",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-37",
        "-1.722",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-37",
        "-1.851",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-37",
        "-1.980",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-37",
        "-2.110",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-37",
        "-2.240",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-37",
        "-2.371",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-37",
        "-2.501",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-37",
        "-2.632",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-37",
        "-2.763",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-37",
        "-2.894",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-37",
        "-3.024",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-37",
        "-3.154",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-37",
        "-3.286",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-37",
        "-3.415",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-37",
        "-3.546",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-37",
        "-3.676",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-37",
        "-3.806",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-37",
        "-3.936",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-37",
        "-4.065",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-37",
        "-4.194",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-37",
        "23.070",
        "17.553",
        "1.421",
        "1.403",
        "0.141"
    ],
    [
        "-16",
        "-37",
        "24.402",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-37",
        "24.216",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-37",
        "24.028",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-37",
        "23.840",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-37",
        "23.650",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-37",
        "23.458",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-37",
        "23.266",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-37",
        "23.071",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-37",
        "22.876",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-37",
        "22.681",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-37",
        "22.484",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-37",
        "22.285",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-37",
        "22.086",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-37",
        "21.886",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-37",
        "21.685",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-37",
        "21.483",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-37",
        "21.281",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-37",
        "21.079",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-37",
        "20.875",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-37",
        "20.672",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-37",
        "20.467",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-37",
        "20.263",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-37",
        "20.059",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-37",
        "19.853",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-37",
        "19.647",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-37",
        "19.442",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-37",
        "19.237",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-37",
        "19.032",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-37",
        "18.827",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-37",
        "18.621",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-37",
        "18.416",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-37",
        "18.212",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-37",
        "18.008",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-37",
        "17.804",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-37",
        "17.600",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-37",
        "17.397",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-37",
        "17.194",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-37",
        "16.993",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-37",
        "16.791",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-37",
        "16.591",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-37",
        "16.392",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-37",
        "16.193",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-37",
        "15.996",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-37",
        "15.799",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-37",
        "15.603",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-37",
        "15.409",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-37",
        "15.215",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-37",
        "15.024",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-37",
        "14.832",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-37",
        "14.643",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-37",
        "14.575",
        "17.624",
        "0.654",
        "0.759",
        "-0.003"
    ],
    [
        "35",
        "-37",
        "14.505",
        "17.227",
        "0.760",
        "0.869",
        "-0.009"
    ],
    [
        "36",
        "-37",
        "14.427",
        "16.827",
        "0.857",
        "0.906",
        "-0.016"
    ],
    [
        "37",
        "-37",
        "14.343",
        "16.438",
        "0.854",
        "0.970",
        "-0.024"
    ],
    [
        "38",
        "-37",
        "14.251",
        "16.073",
        "0.882",
        "1.004",
        "-0.033"
    ],
    [
        "39",
        "-37",
        "14.156",
        "15.744",
        "0.942",
        "1.016",
        "-0.043"
    ],
    [
        "40",
        "-37",
        "14.062",
        "15.450",
        "0.924",
        "1.066",
        "-0.055"
    ],
    [
        "41",
        "-37",
        "13.977",
        "15.174",
        "0.944",
        "1.098",
        "-0.068"
    ],
    [
        "42",
        "-37",
        "13.902",
        "14.895",
        "1.004",
        "1.114",
        "-0.080"
    ],
    [
        "43",
        "-37",
        "13.838",
        "14.598",
        "1.019",
        "1.183",
        "-0.098"
    ],
    [
        "44",
        "-37",
        "13.785",
        "14.295",
        "1.112",
        "1.283",
        "-0.096"
    ],
    [
        "45",
        "-37",
        "13.745",
        "14.009",
        "1.303",
        "1.408",
        "-0.079"
    ],
    [
        "46",
        "-37",
        "13.706",
        "13.656",
        "1.429",
        "1.680",
        "-0.097"
    ],
    [
        "47",
        "-37",
        "13.614",
        "12.929",
        "1.581",
        "2.141",
        "-0.070"
    ],
    [
        "48",
        "-37",
        "13.483",
        "11.808",
        "1.817",
        "2.748",
        "-0.018"
    ],
    [
        "49",
        "-37",
        "13.575",
        "11.305",
        "1.781",
        "3.276",
        "-0.030"
    ],
    [
        "50",
        "-37",
        "13.931",
        "12.545",
        "1.198",
        "2.390",
        "0.104"
    ],
    [
        "51",
        "-37",
        "14.098",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-37",
        "13.096",
        "9.267",
        "1.662",
        "4.189",
        "0.042"
    ],
    [
        "53",
        "-37",
        "10.987",
        "1.043",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-37",
        "10.977",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-37",
        "10.969",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-37",
        "10.964",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-37",
        "10.960",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-37",
        "10.959",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-37",
        "10.961",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-37",
        "10.964",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-37",
        "10.968",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-37",
        "10.975",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-37",
        "10.986",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-37",
        "10.997",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-37",
        "11.010",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-37",
        "11.027",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-37",
        "11.045",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-37",
        "11.065",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-37",
        "11.087",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-37",
        "11.112",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-37",
        "11.139",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-37",
        "11.168",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-37",
        "11.198",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-37",
        "11.231",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-37",
        "11.266",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-37",
        "11.303",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-37",
        "11.343",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-37",
        "11.383",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-37",
        "35.243",
        "18.747",
        "5.913",
        "7.230",
        "0.248"
    ],
    [
        "80",
        "-37",
        "53.781",
        "37.540",
        "3.844",
        "4.075",
        "0.093"
    ],
    [
        "81",
        "-37",
        "55.674",
        "40.284",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-37",
        "55.247",
        "40.991",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-37",
        "54.812",
        "41.687",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-37",
        "54.371",
        "42.370",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-37",
        "53.923",
        "43.041",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-37",
        "53.467",
        "43.697",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-37",
        "53.004",
        "44.341",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-37",
        "52.535",
        "44.970",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-37",
        "52.060",
        "45.587",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-37",
        "51.578",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-37",
        "51.089",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-37",
        "50.595",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-37",
        "50.095",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-37",
        "49.589",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-37",
        "49.078",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-37",
        "48.559",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-37",
        "48.038",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-37",
        "47.510",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-37",
        "46.977",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-37",
        "46.439",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-37",
        "45.896",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-37",
        "45.350",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-37",
        "44.798",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-37",
        "44.244",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-37",
        "43.684",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-37",
        "43.121",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-37",
        "42.554",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-37",
        "41.984",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-37",
        "41.409",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-37",
        "40.833",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-37",
        "40.253",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-37",
        "39.670",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-37",
        "39.084",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-37",
        "38.497",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-37",
        "37.907",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-37",
        "37.314",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-37",
        "36.720",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-37",
        "36.125",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-37",
        "35.527",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-37",
        "34.928",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-37",
        "34.329",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-37",
        "33.728",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-37",
        "33.127",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-37",
        "32.524",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-37",
        "31.921",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-37",
        "31.318",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-37",
        "30.715",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-37",
        "30.112",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-37",
        "29.509",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-37",
        "28.907",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-37",
        "28.305",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-37",
        "27.705",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-37",
        "27.104",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-37",
        "26.506",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-37",
        "25.909",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-37",
        "25.314",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-37",
        "24.719",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-37",
        "24.128",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-37",
        "23.538",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-37",
        "22.950",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-37",
        "22.365",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-37",
        "21.783",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-37",
        "21.203",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-37",
        "20.627",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-37",
        "20.053",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-37",
        "19.483",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-37",
        "18.917",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-37",
        "18.354",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-37",
        "17.795",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-37",
        "17.239",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-37",
        "16.689",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-37",
        "16.142",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-37",
        "15.600",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-37",
        "15.064",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-37",
        "14.531",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-37",
        "14.004",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-37",
        "13.482",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-37",
        "12.965",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-37",
        "12.454",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-37",
        "11.948",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-37",
        "11.449",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-37",
        "10.955",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-37",
        "10.467",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-37",
        "9.986",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-37",
        "9.511",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-37",
        "9.043",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-37",
        "8.581",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-37",
        "8.126",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-37",
        "7.678",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-37",
        "7.237",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-37",
        "6.804",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-37",
        "6.378",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-37",
        "5.959",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-37",
        "5.549",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-37",
        "5.146",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-37",
        "4.749",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-37",
        "3.785",
        "34.879",
        "3.545",
        "3.121",
        "-0.417"
    ],
    [
        "178",
        "-37",
        "2.142",
        "26.659",
        "4.404",
        "3.432",
        "-0.336"
    ],
    [
        "179",
        "-37",
        "-3.807",
        "20.568",
        "4.822",
        "3.798",
        "-0.340"
    ],
    [
        "-180",
        "-36",
        "-16.093",
        "25.027",
        "6.487",
        "4.437",
        "-0.427"
    ],
    [
        "-179",
        "-36",
        "-43.781",
        "28.912",
        "1.557",
        "1.711",
        "-0.371"
    ],
    [
        "-178",
        "-36",
        "-47.992",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-36",
        "-48.297",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-36",
        "-48.604",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-36",
        "-48.912",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-36",
        "-49.222",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-36",
        "-49.532",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-36",
        "-49.844",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-36",
        "-50.156",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-36",
        "-50.469",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-36",
        "-50.783",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-36",
        "-51.098",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-36",
        "-51.413",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-36",
        "-51.729",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-36",
        "-52.045",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-36",
        "-52.361",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-36",
        "-52.677",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-36",
        "-52.994",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-36",
        "-53.310",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-36",
        "-53.626",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-36",
        "-53.942",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-36",
        "-54.258",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-36",
        "-54.573",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-36",
        "-54.888",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-36",
        "-55.202",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-36",
        "-55.515",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-36",
        "-55.828",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-36",
        "-56.140",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-36",
        "-56.450",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-36",
        "-56.760",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-36",
        "-57.068",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-36",
        "-57.376",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-36",
        "-57.681",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-36",
        "-57.986",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-36",
        "-58.288",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-36",
        "-58.589",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-36",
        "-58.889",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-36",
        "-59.186",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-36",
        "-59.482",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-36",
        "-59.775",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-36",
        "-60.067",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-36",
        "-60.356",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-36",
        "-60.643",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-36",
        "-60.928",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-36",
        "-61.210",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-36",
        "-61.489",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-36",
        "-61.766",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-36",
        "-62.041",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-36",
        "-62.312",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-36",
        "-62.581",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-36",
        "-62.846",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-36",
        "-63.109",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-36",
        "-63.369",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-36",
        "-63.625",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-36",
        "-63.878",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-36",
        "-64.127",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-36",
        "-64.374",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-36",
        "-64.616",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-36",
        "-64.855",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-36",
        "-65.091",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-36",
        "-65.323",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-36",
        "-65.551",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-36",
        "-65.775",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-36",
        "-65.995",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-36",
        "-66.211",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-36",
        "-66.423",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-36",
        "-66.631",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-36",
        "-66.835",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-36",
        "-67.034",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-36",
        "25.053",
        "2.433",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-36",
        "25.028",
        "2.634",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-36",
        "24.999",
        "2.834",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-36",
        "24.969",
        "3.032",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-36",
        "24.938",
        "3.230",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-36",
        "24.903",
        "3.428",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-36",
        "24.867",
        "3.624",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-36",
        "25.742",
        "4.154",
        "2.179",
        "4.128",
        "0.042"
    ],
    [
        "-102",
        "-36",
        "33.984",
        "1.963",
        "11.417",
        "7.833",
        "-0.076"
    ],
    [
        "-101",
        "-36",
        "43.337",
        "1.332",
        "11.904",
        "7.503",
        "-0.135"
    ],
    [
        "-100",
        "-36",
        "50.307",
        "1.129",
        "12.169",
        "7.471",
        "-0.118"
    ],
    [
        "-99",
        "-36",
        "57.246",
        "1.447",
        "12.550",
        "7.887",
        "-0.061"
    ],
    [
        "-98",
        "-36",
        "64.860",
        "2.423",
        "10.790",
        "8.362",
        "-0.067"
    ],
    [
        "-97",
        "-36",
        "70.749",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-36",
        "70.736",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-36",
        "70.713",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-36",
        "70.682",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-36",
        "70.641",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-36",
        "70.591",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-36",
        "70.534",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-36",
        "70.466",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-36",
        "70.390",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-36",
        "70.306",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-36",
        "70.211",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-36",
        "70.109",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-36",
        "69.999",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-36",
        "69.878",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-36",
        "69.749",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-36",
        "69.613",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-36",
        "69.467",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-36",
        "69.312",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-36",
        "69.149",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-36",
        "68.978",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-36",
        "68.799",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-36",
        "68.610",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-36",
        "68.414",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-36",
        "55.074",
        "21.829",
        "11.202",
        "7.346",
        "-0.015"
    ],
    [
        "-73",
        "-36",
        "39.281",
        "19.875",
        "6.377",
        "3.884",
        "-0.095"
    ],
    [
        "-72",
        "-36",
        "31.383",
        "16.377",
        "1.782",
        "1.194",
        "-0.057"
    ],
    [
        "-71",
        "-36",
        "22.145",
        "15.754",
        "2.433",
        "1.589",
        "-0.082"
    ],
    [
        "-70",
        "-36",
        "9.584",
        "12.212",
        "2.828",
        "2.170",
        "-0.129"
    ],
    [
        "-69",
        "-36",
        "6.511",
        "10.856",
        "2.569",
        "2.232",
        "0.078"
    ],
    [
        "-68",
        "-36",
        "4.286",
        "10.102",
        "2.126",
        "2.256",
        "-0.037"
    ],
    [
        "-67",
        "-36",
        "2.322",
        "9.995",
        "2.370",
        "2.678",
        "-0.040"
    ],
    [
        "-66",
        "-36",
        "1.867",
        "9.841",
        "2.574",
        "2.513",
        "-0.003"
    ],
    [
        "-65",
        "-36",
        "1.479",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-36",
        "1.375",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-36",
        "1.271",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-36",
        "1.166",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-36",
        "1.060",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-36",
        "0.951",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-36",
        "0.842",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-36",
        "0.732",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-36",
        "0.620",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-36",
        "0.508",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-36",
        "0.395",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-36",
        "0.280",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-36",
        "0.165",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-36",
        "0.049",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-36",
        "-0.070",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-36",
        "-0.187",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-36",
        "-0.306",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-36",
        "-0.426",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-36",
        "-0.547",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-36",
        "-0.668",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-36",
        "-0.789",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-36",
        "-0.912",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-36",
        "-1.035",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-36",
        "-1.160",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-36",
        "-1.283",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-36",
        "-1.409",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-36",
        "-1.534",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-36",
        "-1.659",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-36",
        "-1.785",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-36",
        "-1.912",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-36",
        "-2.039",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-36",
        "-2.165",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-36",
        "-2.293",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-36",
        "-2.420",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-36",
        "-2.547",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-36",
        "-2.675",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-36",
        "-2.803",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-36",
        "-2.930",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-36",
        "-3.058",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-36",
        "-3.185",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-36",
        "-3.312",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-36",
        "-3.441",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-36",
        "-3.567",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-36",
        "-3.694",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-36",
        "-3.821",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-36",
        "-3.947",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-36",
        "-4.074",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-36",
        "-4.200",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-36",
        "21.581",
        "17.387",
        "1.182",
        "1.449",
        "0.065"
    ],
    [
        "-16",
        "-36",
        "24.534",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-36",
        "24.352",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-36",
        "24.169",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-36",
        "23.985",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-36",
        "23.798",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-36",
        "23.612",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-36",
        "23.424",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-36",
        "23.234",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-36",
        "23.044",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-36",
        "22.852",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-36",
        "22.659",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-36",
        "22.466",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-36",
        "22.272",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-36",
        "22.076",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-36",
        "21.880",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-36",
        "21.683",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-36",
        "21.486",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-36",
        "21.288",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-36",
        "21.089",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-36",
        "20.891",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-36",
        "20.691",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-36",
        "20.491",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-36",
        "20.291",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-36",
        "20.091",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-36",
        "19.890",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-36",
        "19.690",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-36",
        "19.489",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-36",
        "19.289",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-36",
        "19.088",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-36",
        "18.888",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-36",
        "18.687",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-36",
        "18.487",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-36",
        "18.288",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-36",
        "18.089",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-36",
        "17.890",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-36",
        "17.692",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-36",
        "17.494",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-36",
        "17.297",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-36",
        "17.100",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-36",
        "16.905",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-36",
        "16.710",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-36",
        "16.517",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-36",
        "16.323",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-36",
        "16.131",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-36",
        "15.940",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-36",
        "15.750",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-36",
        "15.561",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-36",
        "15.374",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-36",
        "15.187",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-36",
        "15.002",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-36",
        "14.957",
        "17.646",
        "0.655",
        "0.759",
        "-0.003"
    ],
    [
        "35",
        "-36",
        "14.912",
        "17.277",
        "0.760",
        "0.869",
        "-0.008"
    ],
    [
        "36",
        "-36",
        "14.861",
        "16.907",
        "0.856",
        "0.903",
        "-0.014"
    ],
    [
        "37",
        "-36",
        "14.804",
        "16.547",
        "0.851",
        "0.965",
        "-0.023"
    ],
    [
        "38",
        "-36",
        "14.745",
        "16.203",
        "0.877",
        "0.996",
        "-0.031"
    ],
    [
        "39",
        "-36",
        "14.683",
        "15.882",
        "0.934",
        "1.002",
        "-0.041"
    ],
    [
        "40",
        "-36",
        "14.626",
        "15.583",
        "0.911",
        "1.045",
        "-0.053"
    ],
    [
        "41",
        "-36",
        "14.576",
        "15.298",
        "0.923",
        "1.065",
        "-0.065"
    ],
    [
        "42",
        "-36",
        "14.538",
        "15.017",
        "0.971",
        "1.064",
        "-0.077"
    ],
    [
        "43",
        "-36",
        "14.509",
        "14.731",
        "0.948",
        "1.101",
        "-0.095"
    ],
    [
        "44",
        "-36",
        "14.490",
        "14.445",
        "0.968",
        "1.125",
        "-0.104"
    ],
    [
        "45",
        "-36",
        "14.482",
        "14.171",
        "1.043",
        "1.134",
        "-0.091"
    ],
    [
        "46",
        "-36",
        "14.484",
        "13.913",
        "1.060",
        "1.215",
        "-0.079"
    ],
    [
        "47",
        "-36",
        "14.491",
        "13.650",
        "1.110",
        "1.350",
        "-0.044"
    ],
    [
        "48",
        "-36",
        "14.499",
        "13.386",
        "1.196",
        "1.547",
        "-0.011"
    ],
    [
        "49",
        "-36",
        "14.548",
        "13.306",
        "1.094",
        "1.777",
        "-0.016"
    ],
    [
        "50",
        "-36",
        "14.652",
        "13.562",
        "0.718",
        "1.256",
        "0.134"
    ],
    [
        "51",
        "-36",
        "14.598",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-36",
        "14.460",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-36",
        "13.059",
        "8.036",
        "2.523",
        "4.480",
        "-0.068"
    ],
    [
        "54",
        "-36",
        "11.376",
        "0.837",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-36",
        "11.369",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-36",
        "11.364",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-36",
        "11.360",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-36",
        "11.359",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-36",
        "11.360",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-36",
        "11.362",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-36",
        "11.368",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-36",
        "11.375",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-36",
        "11.384",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-36",
        "11.396",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-36",
        "11.410",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-36",
        "11.425",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-36",
        "11.443",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-36",
        "11.462",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-36",
        "11.485",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-36",
        "11.508",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-36",
        "11.534",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-36",
        "11.562",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-36",
        "11.592",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-36",
        "11.625",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-36",
        "11.658",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-36",
        "11.695",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-36",
        "11.733",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-36",
        "11.773",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-36",
        "20.309",
        "4.366",
        "4.868",
        "5.558",
        "0.031"
    ],
    [
        "80",
        "-36",
        "44.626",
        "29.645",
        "5.211",
        "5.814",
        "0.121"
    ],
    [
        "81",
        "-36",
        "53.985",
        "38.782",
        "3.119",
        "3.114",
        "0.134"
    ],
    [
        "82",
        "-36",
        "55.086",
        "40.991",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-36",
        "54.662",
        "41.687",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-36",
        "54.231",
        "42.370",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-36",
        "53.793",
        "43.041",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-36",
        "53.348",
        "43.697",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-36",
        "52.897",
        "44.341",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-36",
        "52.438",
        "44.970",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-36",
        "51.974",
        "45.587",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-36",
        "51.503",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-36",
        "51.026",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-36",
        "50.543",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-36",
        "50.054",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-36",
        "49.560",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-36",
        "49.060",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-36",
        "48.555",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-36",
        "48.045",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-36",
        "47.530",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-36",
        "47.009",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-36",
        "46.484",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-36",
        "45.954",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-36",
        "45.420",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-36",
        "44.882",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-36",
        "44.339",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-36",
        "43.793",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-36",
        "43.243",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-36",
        "42.689",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-36",
        "42.131",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-36",
        "41.571",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-36",
        "41.008",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-36",
        "40.442",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-36",
        "39.872",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-36",
        "39.301",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-36",
        "38.727",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-36",
        "38.151",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-36",
        "37.572",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-36",
        "36.992",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-36",
        "36.410",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-36",
        "35.827",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-36",
        "35.241",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-36",
        "34.656",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-36",
        "34.069",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-36",
        "33.481",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-36",
        "32.894",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-36",
        "32.304",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-36",
        "31.715",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-36",
        "31.127",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-36",
        "30.537",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-36",
        "29.949",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-36",
        "29.361",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-36",
        "28.773",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-36",
        "28.187",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-36",
        "27.600",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-36",
        "27.016",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-36",
        "26.433",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-36",
        "25.851",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-36",
        "25.271",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-36",
        "24.693",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-36",
        "24.116",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-36",
        "23.542",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-36",
        "22.971",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-36",
        "22.403",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-36",
        "21.836",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-36",
        "21.273",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-36",
        "20.713",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-36",
        "20.156",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-36",
        "19.603",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-36",
        "19.053",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-36",
        "18.507",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-36",
        "17.965",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-36",
        "17.427",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-36",
        "16.894",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-36",
        "16.364",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-36",
        "15.840",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-36",
        "15.320",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-36",
        "14.805",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-36",
        "14.295",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-36",
        "13.791",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-36",
        "13.291",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-36",
        "12.797",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-36",
        "12.310",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-36",
        "11.827",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-36",
        "11.351",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-36",
        "10.881",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-36",
        "10.417",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-36",
        "9.959",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-36",
        "9.508",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-36",
        "9.064",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-36",
        "8.627",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-36",
        "8.196",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-36",
        "7.773",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-36",
        "7.357",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-36",
        "6.948",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-36",
        "6.546",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-36",
        "6.153",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-36",
        "5.767",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-36",
        "5.389",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-36",
        "5.019",
        "35.706",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-36",
        "-1.253",
        "23.829",
        "5.273",
        "4.203",
        "-0.325"
    ],
    [
        "-180",
        "-35",
        "-11.132",
        "26.436",
        "7.187",
        "4.719",
        "-0.385"
    ],
    [
        "-179",
        "-35",
        "-27.937",
        "27.725",
        "6.851",
        "5.254",
        "-0.433"
    ],
    [
        "-178",
        "-35",
        "-45.532",
        "28.506",
        "4.736",
        "4.054",
        "-0.539"
    ],
    [
        "-177",
        "-35",
        "-49.070",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-35",
        "-49.369",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-35",
        "-49.670",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-35",
        "-49.972",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-35",
        "-50.275",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-35",
        "-50.579",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-35",
        "-50.884",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-35",
        "-51.190",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-35",
        "-51.496",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-35",
        "-51.803",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-35",
        "-52.111",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-35",
        "-52.418",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-35",
        "-52.727",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-35",
        "-53.035",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-35",
        "-53.344",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-35",
        "-53.653",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-35",
        "-53.962",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-35",
        "-54.270",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-35",
        "-54.578",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-35",
        "-54.887",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-35",
        "-55.194",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-35",
        "-55.501",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-35",
        "-55.808",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-35",
        "-56.114",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-35",
        "-56.419",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-35",
        "-56.723",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-35",
        "-57.026",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-35",
        "-57.328",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-35",
        "-57.629",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-35",
        "-57.929",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-35",
        "-58.227",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-35",
        "-58.524",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-35",
        "-58.820",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-35",
        "-59.113",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-35",
        "-59.405",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-35",
        "-59.696",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-35",
        "-59.984",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-35",
        "-60.271",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-35",
        "-60.555",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-35",
        "-60.837",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-35",
        "-61.117",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-35",
        "-61.395",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-35",
        "-61.670",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-35",
        "-61.943",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-35",
        "-62.214",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-35",
        "-62.481",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-35",
        "-62.746",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-35",
        "-63.008",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-35",
        "-63.267",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-35",
        "-63.524",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-35",
        "-63.777",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-35",
        "-64.027",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-35",
        "-64.274",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-35",
        "-64.517",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-35",
        "-64.758",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-35",
        "-64.995",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-35",
        "-65.228",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-35",
        "-65.458",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-35",
        "-65.684",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-35",
        "-65.906",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-35",
        "-66.125",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-35",
        "-66.340",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-35",
        "-66.551",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-35",
        "-66.758",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-35",
        "-66.961",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-35",
        "-67.159",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-35",
        "-25.587",
        "3.670",
        "9.134",
        "8.785",
        "0.011"
    ],
    [
        "-110",
        "-35",
        "-10.639",
        "13.104",
        "11.183",
        "9.643",
        "-0.178"
    ],
    [
        "-109",
        "-35",
        "3.100",
        "7.310",
        "12.491",
        "9.921",
        "-0.164"
    ],
    [
        "-108",
        "-35",
        "20.480",
        "2.648",
        "13.500",
        "9.251",
        "-0.106"
    ],
    [
        "-107",
        "-35",
        "41.600",
        "-1.309",
        "11.902",
        "8.315",
        "-0.136"
    ],
    [
        "-106",
        "-35",
        "64.607",
        "-4.765",
        "6.797",
        "7.638",
        "0.059"
    ],
    [
        "-105",
        "-35",
        "70.350",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-35",
        "70.406",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-35",
        "70.454",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-35",
        "70.493",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-35",
        "70.524",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-35",
        "70.545",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-35",
        "70.558",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-35",
        "70.562",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-35",
        "70.557",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-35",
        "70.544",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-35",
        "70.522",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-35",
        "70.492",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-35",
        "70.452",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-35",
        "70.404",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-35",
        "70.347",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-35",
        "70.282",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-35",
        "70.207",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-35",
        "70.124",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-35",
        "70.033",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-35",
        "69.933",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-35",
        "69.825",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-35",
        "69.708",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-35",
        "69.582",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-35",
        "69.449",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-35",
        "69.306",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-35",
        "69.156",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-35",
        "68.996",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-35",
        "68.830",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-35",
        "68.654",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-35",
        "68.470",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-35",
        "68.279",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-35",
        "64.144",
        "21.429",
        "8.650",
        "5.347",
        "-0.097"
    ],
    [
        "-73",
        "-35",
        "45.788",
        "20.016",
        "9.761",
        "5.879",
        "0.061"
    ],
    [
        "-72",
        "-35",
        "33.943",
        "19.198",
        "6.857",
        "3.428",
        "-0.110"
    ],
    [
        "-71",
        "-35",
        "25.490",
        "17.077",
        "1.663",
        "2.052",
        "0.123"
    ],
    [
        "-70",
        "-35",
        "10.074",
        "14.593",
        "2.521",
        "1.778",
        "0.013"
    ],
    [
        "-69",
        "-35",
        "6.583",
        "12.130",
        "2.512",
        "1.929",
        "-0.153"
    ],
    [
        "-68",
        "-35",
        "4.306",
        "11.083",
        "2.276",
        "2.369",
        "0.044"
    ],
    [
        "-67",
        "-35",
        "3.399",
        "10.387",
        "2.649",
        "2.778",
        "0.043"
    ],
    [
        "-66",
        "-35",
        "2.538",
        "9.821",
        "2.716",
        "2.646",
        "0.024"
    ],
    [
        "-65",
        "-35",
        "1.858",
        "10.779",
        "0.576",
        "0.775",
        "-0.026"
    ],
    [
        "-64",
        "-35",
        "1.236",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-35",
        "1.135",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-35",
        "1.032",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-35",
        "0.927",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-35",
        "0.822",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-35",
        "0.716",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-35",
        "0.608",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-35",
        "0.499",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-35",
        "0.389",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-35",
        "0.279",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-35",
        "0.167",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-35",
        "0.054",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-35",
        "-0.059",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-35",
        "-0.174",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-35",
        "-0.290",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-35",
        "-0.405",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-35",
        "-0.522",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-35",
        "-0.640",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-35",
        "-0.758",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-35",
        "-0.878",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-35",
        "-0.997",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-35",
        "-1.117",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-35",
        "-1.238",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-35",
        "-1.359",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-35",
        "-1.481",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-35",
        "-1.603",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-35",
        "-1.725",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-35",
        "-1.848",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-35",
        "-1.972",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-35",
        "-2.096",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-35",
        "-2.219",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-35",
        "-2.343",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-35",
        "-2.467",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-35",
        "-2.592",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-35",
        "-2.717",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-35",
        "-2.841",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-35",
        "-2.965",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-35",
        "-3.090",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-35",
        "-3.215",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-35",
        "-3.339",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-35",
        "-3.464",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-35",
        "-3.587",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-35",
        "-3.712",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-35",
        "-3.835",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-35",
        "-3.959",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-35",
        "-4.082",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-35",
        "-4.205",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-35",
        "-4.327",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-35",
        "-4.449",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-35",
        "24.480",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-35",
        "24.302",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-35",
        "24.122",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-35",
        "23.941",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-35",
        "23.758",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-35",
        "23.575",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-35",
        "23.390",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-35",
        "23.204",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-35",
        "23.017",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-35",
        "22.828",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-35",
        "22.640",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-35",
        "22.451",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-35",
        "22.260",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-35",
        "22.069",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-35",
        "21.876",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-35",
        "21.684",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-35",
        "21.491",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-35",
        "21.297",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-35",
        "21.103",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-35",
        "20.908",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-35",
        "20.713",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-35",
        "20.518",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-35",
        "20.322",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-35",
        "20.127",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-35",
        "19.931",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-35",
        "19.735",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-35",
        "19.540",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-35",
        "19.344",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-35",
        "19.148",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-35",
        "18.953",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-35",
        "18.758",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-35",
        "18.563",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-35",
        "18.369",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-35",
        "18.175",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-35",
        "17.981",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-35",
        "17.788",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-35",
        "17.596",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-35",
        "17.404",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-35",
        "17.213",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-35",
        "17.023",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-35",
        "16.834",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-35",
        "16.646",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-35",
        "16.459",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-35",
        "16.272",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-35",
        "16.086",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-35",
        "15.902",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-35",
        "15.719",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-35",
        "15.538",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-35",
        "15.357",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-35",
        "15.334",
        "17.662",
        "0.657",
        "0.761",
        "-0.002"
    ],
    [
        "35",
        "-35",
        "15.313",
        "17.314",
        "0.760",
        "0.870",
        "-0.007"
    ],
    [
        "36",
        "-35",
        "15.289",
        "16.965",
        "0.856",
        "0.902",
        "-0.013"
    ],
    [
        "37",
        "-35",
        "15.261",
        "16.623",
        "0.850",
        "0.963",
        "-0.021"
    ],
    [
        "38",
        "-35",
        "15.232",
        "16.295",
        "0.874",
        "0.992",
        "-0.030"
    ],
    [
        "39",
        "-35",
        "15.201",
        "15.982",
        "0.929",
        "0.993",
        "-0.038"
    ],
    [
        "40",
        "-35",
        "15.174",
        "15.686",
        "0.902",
        "1.031",
        "-0.049"
    ],
    [
        "41",
        "-35",
        "15.152",
        "15.401",
        "0.910",
        "1.044",
        "-0.058"
    ],
    [
        "42",
        "-35",
        "15.137",
        "15.125",
        "0.951",
        "1.032",
        "-0.068"
    ],
    [
        "43",
        "-35",
        "15.130",
        "14.853",
        "0.913",
        "1.054",
        "-0.079"
    ],
    [
        "44",
        "-35",
        "15.128",
        "14.588",
        "0.910",
        "1.050",
        "-0.084"
    ],
    [
        "45",
        "-35",
        "15.127",
        "14.336",
        "0.941",
        "1.019",
        "-0.080"
    ],
    [
        "46",
        "-35",
        "15.129",
        "14.102",
        "0.895",
        "1.031",
        "-0.070"
    ],
    [
        "47",
        "-35",
        "15.133",
        "13.898",
        "0.882",
        "1.035",
        "-0.042"
    ],
    [
        "48",
        "-35",
        "15.140",
        "13.749",
        "0.900",
        "1.017",
        "-0.013"
    ],
    [
        "49",
        "-35",
        "15.154",
        "13.677",
        "0.779",
        "1.018",
        "-0.009"
    ],
    [
        "50",
        "-35",
        "15.175",
        "13.684",
        "0.501",
        "0.643",
        "0.061"
    ],
    [
        "51",
        "-35",
        "15.094",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-35",
        "14.960",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-35",
        "14.829",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-35",
        "13.282",
        "9.478",
        "2.333",
        "5.095",
        "-0.009"
    ],
    [
        "55",
        "-35",
        "11.765",
        "0.633",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-35",
        "11.759",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-35",
        "11.757",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-35",
        "11.755",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-35",
        "11.756",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-35",
        "11.760",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-35",
        "11.764",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-35",
        "11.771",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-35",
        "11.780",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-35",
        "11.792",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-35",
        "11.805",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-35",
        "11.820",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-35",
        "11.838",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-35",
        "11.856",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-35",
        "11.878",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-35",
        "11.901",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-35",
        "11.927",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-35",
        "11.954",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-35",
        "11.983",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-35",
        "12.014",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-35",
        "12.048",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-35",
        "12.083",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-35",
        "12.120",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-35",
        "12.160",
        "-4.013",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-35",
        "33.049",
        "17.220",
        "7.524",
        "8.436",
        "0.158"
    ],
    [
        "80",
        "-35",
        "44.423",
        "29.476",
        "5.563",
        "5.788",
        "0.097"
    ],
    [
        "81",
        "-35",
        "52.065",
        "37.051",
        "3.287",
        "3.191",
        "0.075"
    ],
    [
        "82",
        "-35",
        "54.446",
        "40.183",
        "1.647",
        "2.007",
        "0.074"
    ],
    [
        "83",
        "-35",
        "54.495",
        "41.687",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-35",
        "54.074",
        "42.370",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-35",
        "53.647",
        "43.041",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-35",
        "53.212",
        "43.697",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-35",
        "52.772",
        "44.341",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-35",
        "52.325",
        "44.970",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-35",
        "51.871",
        "45.587",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-35",
        "51.412",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-35",
        "50.947",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-35",
        "50.475",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-35",
        "49.999",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-35",
        "49.516",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-35",
        "49.029",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-35",
        "48.536",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-35",
        "48.038",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-35",
        "47.535",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-35",
        "47.027",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-35",
        "46.514",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-35",
        "45.998",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-35",
        "45.476",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-35",
        "44.951",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-35",
        "44.422",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-35",
        "43.888",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-35",
        "43.352",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-35",
        "42.811",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-35",
        "42.268",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-35",
        "41.721",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-35",
        "41.171",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-35",
        "40.618",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-35",
        "40.063",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-35",
        "39.505",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-35",
        "38.945",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-35",
        "38.383",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-35",
        "37.818",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-35",
        "37.252",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-35",
        "36.684",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-35",
        "36.115",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-35",
        "35.544",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-35",
        "34.972",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-35",
        "34.400",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-35",
        "33.827",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-35",
        "33.252",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-35",
        "32.678",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-35",
        "32.103",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-35",
        "31.528",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-35",
        "30.954",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-35",
        "30.379",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-35",
        "29.805",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-35",
        "29.231",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-35",
        "28.659",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-35",
        "28.087",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-35",
        "27.517",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-35",
        "26.947",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-35",
        "26.380",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-35",
        "25.814",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-35",
        "25.250",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-35",
        "24.688",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-35",
        "24.128",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-35",
        "23.570",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-35",
        "23.015",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-35",
        "22.462",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-35",
        "21.913",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-35",
        "21.367",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-35",
        "20.823",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-35",
        "20.283",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-35",
        "19.747",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-35",
        "19.214",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-35",
        "18.685",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-35",
        "18.160",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-35",
        "17.639",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-35",
        "17.123",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-35",
        "16.610",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-35",
        "16.104",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-35",
        "15.601",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-35",
        "15.103",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-35",
        "14.611",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-35",
        "14.124",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-35",
        "13.642",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-35",
        "13.166",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-35",
        "12.696",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-35",
        "12.231",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-35",
        "11.772",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-35",
        "11.319",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-35",
        "10.873",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-35",
        "10.433",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-35",
        "9.999",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-35",
        "9.573",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-35",
        "9.152",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-35",
        "8.739",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-35",
        "8.333",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-35",
        "7.934",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-35",
        "7.542",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-35",
        "7.158",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-35",
        "6.781",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-35",
        "6.412",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-35",
        "4.810",
        "35.515",
        "3.578",
        "3.106",
        "-0.161"
    ],
    [
        "179",
        "-35",
        "-0.313",
        "28.556",
        "5.732",
        "4.244",
        "-0.227"
    ],
    [
        "-180",
        "-34",
        "-6.546",
        "28.096",
        "7.263",
        "4.603",
        "-0.319"
    ],
    [
        "-179",
        "-34",
        "-20.927",
        "27.894",
        "7.238",
        "5.301",
        "-0.401"
    ],
    [
        "-178",
        "-34",
        "-37.629",
        "29.213",
        "6.753",
        "5.585",
        "-0.433"
    ],
    [
        "-177",
        "-34",
        "-48.845",
        "29.277",
        "6.260",
        "5.187",
        "-0.664"
    ],
    [
        "-176",
        "-34",
        "-50.120",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-34",
        "-50.413",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-34",
        "-50.707",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-34",
        "-51.002",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-34",
        "-51.299",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-34",
        "-51.596",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-34",
        "-51.894",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-34",
        "-52.193",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-34",
        "-52.492",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-34",
        "-52.792",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-34",
        "-53.092",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-34",
        "-53.393",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-34",
        "-53.694",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-34",
        "-53.995",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-34",
        "-54.296",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-34",
        "-54.597",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-34",
        "-54.897",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-34",
        "-55.198",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-34",
        "-55.498",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-34",
        "-55.798",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-34",
        "-56.098",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-34",
        "-56.397",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-34",
        "-56.695",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-34",
        "-56.992",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-34",
        "-57.289",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-34",
        "-57.584",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-34",
        "-57.879",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-34",
        "-58.172",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-34",
        "-58.464",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-34",
        "-58.755",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-34",
        "-59.045",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-34",
        "-59.333",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-34",
        "-59.619",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-34",
        "-59.904",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-34",
        "-60.187",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-34",
        "-60.468",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-34",
        "-60.747",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-34",
        "-61.025",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-34",
        "-61.300",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-34",
        "-61.573",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-34",
        "-61.844",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-34",
        "-62.112",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-34",
        "-62.378",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-34",
        "-62.642",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-34",
        "-62.903",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-34",
        "-63.161",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-34",
        "-63.416",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-34",
        "-63.669",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-34",
        "-63.919",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-34",
        "-64.166",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-34",
        "-64.410",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-34",
        "-64.650",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-34",
        "-64.888",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-34",
        "-65.122",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-34",
        "-65.353",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-34",
        "-65.580",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-34",
        "-65.804",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-34",
        "-66.025",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-34",
        "-66.242",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-34",
        "-66.455",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-34",
        "-66.664",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-34",
        "-66.870",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-34",
        "-67.072",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-34",
        "-61.079",
        "20.180",
        "6.903",
        "9.416",
        "0.147"
    ],
    [
        "-112",
        "-34",
        "-47.367",
        "20.741",
        "11.003",
        "12.620",
        "-0.003"
    ],
    [
        "-111",
        "-34",
        "-36.018",
        "10.177",
        "13.486",
        "11.756",
        "-0.038"
    ],
    [
        "-110",
        "-34",
        "-6.553",
        "7.973",
        "13.841",
        "12.618",
        "-0.044"
    ],
    [
        "-109",
        "-34",
        "47.366",
        "-5.450",
        "9.329",
        "10.132",
        "0.109"
    ],
    [
        "-108",
        "-34",
        "69.926",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-34",
        "70.007",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-34",
        "70.078",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-34",
        "70.142",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-34",
        "70.197",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-34",
        "70.244",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-34",
        "70.282",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-34",
        "70.311",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-34",
        "70.333",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-34",
        "70.345",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-34",
        "70.349",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-34",
        "70.344",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-34",
        "70.332",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-34",
        "70.310",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-34",
        "70.280",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-34",
        "70.242",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-34",
        "70.195",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-34",
        "70.139",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-34",
        "70.076",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-34",
        "70.003",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-34",
        "69.923",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-34",
        "69.833",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-34",
        "69.736",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-34",
        "69.630",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-34",
        "69.516",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-34",
        "69.394",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-34",
        "69.263",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-34",
        "69.125",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-34",
        "68.977",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-34",
        "68.823",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-34",
        "68.660",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-34",
        "68.489",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-34",
        "68.310",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-34",
        "68.123",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-34",
        "67.929",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-34",
        "53.408",
        "20.330",
        "9.650",
        "5.569",
        "0.073"
    ],
    [
        "-72",
        "-34",
        "28.089",
        "19.903",
        "2.488",
        "2.338",
        "-0.053"
    ],
    [
        "-71",
        "-34",
        "21.236",
        "18.196",
        "2.691",
        "2.374",
        "0.066"
    ],
    [
        "-70",
        "-34",
        "14.459",
        "14.703",
        "2.901",
        "2.130",
        "-0.185"
    ],
    [
        "-69",
        "-34",
        "7.253",
        "12.336",
        "2.315",
        "1.667",
        "-0.081"
    ],
    [
        "-68",
        "-34",
        "4.798",
        "11.355",
        "2.216",
        "1.963",
        "0.006"
    ],
    [
        "-67",
        "-34",
        "3.691",
        "10.300",
        "2.477",
        "2.421",
        "0.043"
    ],
    [
        "-66",
        "-34",
        "2.871",
        "10.240",
        "2.680",
        "2.478",
        "0.022"
    ],
    [
        "-65",
        "-34",
        "1.436",
        "10.223",
        "0.587",
        "0.775",
        "0.078"
    ],
    [
        "-64",
        "-34",
        "1.096",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-34",
        "0.997",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-34",
        "0.896",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-34",
        "0.795",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-34",
        "0.692",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-34",
        "0.588",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-34",
        "0.484",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-34",
        "0.377",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-34",
        "0.271",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-34",
        "0.162",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-34",
        "0.054",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-34",
        "-0.057",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-34",
        "-0.167",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-34",
        "-0.279",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-34",
        "-0.391",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-34",
        "-0.504",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-34",
        "-0.618",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-34",
        "-0.732",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-34",
        "-0.848",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-34",
        "-0.964",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-34",
        "-1.081",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-34",
        "-1.198",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-34",
        "-1.316",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-34",
        "-1.434",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-34",
        "-1.552",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-34",
        "-1.672",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-34",
        "-1.791",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-34",
        "-1.911",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-34",
        "-2.031",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-34",
        "-2.152",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-34",
        "-2.272",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-34",
        "-2.393",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-34",
        "-2.515",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-34",
        "-2.636",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-34",
        "-2.757",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-34",
        "-2.879",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-34",
        "-3.000",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-34",
        "-3.122",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-34",
        "-3.244",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-34",
        "-3.365",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-34",
        "-3.485",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-34",
        "-3.607",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-34",
        "-3.727",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-34",
        "-3.849",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-34",
        "-3.969",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-34",
        "-4.089",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-34",
        "-4.208",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-34",
        "-4.328",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-34",
        "-4.447",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-34",
        "-4.566",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-34",
        "24.427",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-34",
        "24.252",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-34",
        "24.076",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-34",
        "23.898",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-34",
        "23.718",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-34",
        "23.538",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-34",
        "23.357",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-34",
        "23.174",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-34",
        "22.991",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-34",
        "22.807",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-34",
        "22.622",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-34",
        "22.437",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-34",
        "22.250",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-34",
        "22.063",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-34",
        "21.875",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-34",
        "21.687",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-34",
        "21.498",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-34",
        "21.309",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-34",
        "21.119",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-34",
        "20.929",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-34",
        "20.738",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-34",
        "20.548",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-34",
        "20.357",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-34",
        "20.166",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-34",
        "19.975",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-34",
        "19.785",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-34",
        "19.594",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-34",
        "19.403",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-34",
        "19.212",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-34",
        "19.023",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-34",
        "18.833",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-34",
        "18.643",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-34",
        "18.454",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-34",
        "18.266",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-34",
        "18.077",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-34",
        "17.890",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-34",
        "17.702",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-34",
        "17.516",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-34",
        "17.332",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-34",
        "17.147",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-34",
        "16.963",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-34",
        "16.780",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-34",
        "16.599",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-34",
        "16.418",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-34",
        "16.239",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-34",
        "16.060",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-34",
        "15.882",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-34",
        "15.706",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-34",
        "15.709",
        "17.672",
        "0.658",
        "0.763",
        "-0.002"
    ],
    [
        "35",
        "-34",
        "15.714",
        "17.336",
        "0.761",
        "0.872",
        "-0.007"
    ],
    [
        "36",
        "-34",
        "15.717",
        "17.001",
        "0.858",
        "0.904",
        "-0.013"
    ],
    [
        "37",
        "-34",
        "15.717",
        "16.674",
        "0.850",
        "0.964",
        "-0.021"
    ],
    [
        "38",
        "-34",
        "15.713",
        "16.357",
        "0.873",
        "0.990",
        "-0.028"
    ],
    [
        "39",
        "-34",
        "15.707",
        "16.054",
        "0.926",
        "0.987",
        "-0.035"
    ],
    [
        "40",
        "-34",
        "15.702",
        "15.764",
        "0.897",
        "1.022",
        "-0.043"
    ],
    [
        "41",
        "-34",
        "15.702",
        "15.485",
        "0.900",
        "1.029",
        "-0.050"
    ],
    [
        "42",
        "-34",
        "15.706",
        "15.218",
        "0.937",
        "1.009",
        "-0.057"
    ],
    [
        "43",
        "-34",
        "15.715",
        "14.960",
        "0.892",
        "1.023",
        "-0.064"
    ],
    [
        "44",
        "-34",
        "15.727",
        "14.717",
        "0.879",
        "1.006",
        "-0.068"
    ],
    [
        "45",
        "-34",
        "15.736",
        "14.491",
        "0.901",
        "0.956",
        "-0.066"
    ],
    [
        "46",
        "-34",
        "15.735",
        "14.281",
        "0.839",
        "0.941",
        "-0.056"
    ],
    [
        "47",
        "-34",
        "15.720",
        "14.087",
        "0.811",
        "0.907",
        "-0.030"
    ],
    [
        "48",
        "-34",
        "15.696",
        "13.919",
        "0.817",
        "0.854",
        "-0.001"
    ],
    [
        "49",
        "-34",
        "15.674",
        "13.790",
        "0.704",
        "0.828",
        "0.013"
    ],
    [
        "50",
        "-34",
        "15.670",
        "13.710",
        "0.457",
        "0.496",
        "0.011"
    ],
    [
        "51",
        "-34",
        "15.585",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-34",
        "15.455",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-34",
        "15.327",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-34",
        "15.202",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-34",
        "15.078",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-34",
        "12.153",
        "0.427",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-34",
        "12.149",
        "0.221",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-34",
        "12.148",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-34",
        "12.149",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-34",
        "12.151",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-34",
        "12.157",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-34",
        "12.164",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-34",
        "12.172",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-34",
        "12.183",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-34",
        "12.196",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-34",
        "12.211",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-34",
        "12.228",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-34",
        "12.247",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-34",
        "12.267",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-34",
        "12.290",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-34",
        "12.315",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-34",
        "12.342",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-34",
        "12.370",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-34",
        "12.401",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-34",
        "12.433",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-34",
        "12.468",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-34",
        "12.504",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-34",
        "26.046",
        "8.137",
        "9.798",
        "7.624",
        "0.301"
    ],
    [
        "79",
        "-34",
        "41.079",
        "24.154",
        "7.911",
        "7.791",
        "0.067"
    ],
    [
        "80",
        "-34",
        "49.863",
        "33.615",
        "5.366",
        "4.958",
        "0.125"
    ],
    [
        "81",
        "-34",
        "53.015",
        "37.314",
        "3.038",
        "2.813",
        "0.039"
    ],
    [
        "82",
        "-34",
        "54.241",
        "39.713",
        "1.537",
        "1.780",
        "-0.036"
    ],
    [
        "83",
        "-34",
        "54.312",
        "41.687",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-34",
        "53.901",
        "42.370",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-34",
        "53.484",
        "43.041",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-34",
        "53.061",
        "43.697",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-34",
        "52.631",
        "44.341",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-34",
        "52.195",
        "44.970",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-34",
        "51.754",
        "45.587",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-34",
        "51.306",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-34",
        "50.852",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-34",
        "50.393",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-34",
        "49.928",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-34",
        "49.458",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-34",
        "48.982",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-34",
        "48.501",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-34",
        "48.016",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-34",
        "47.526",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-34",
        "47.030",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-34",
        "46.531",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-34",
        "46.027",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-34",
        "45.519",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-34",
        "45.006",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-34",
        "44.491",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-34",
        "43.971",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-34",
        "43.447",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-34",
        "42.921",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-34",
        "42.390",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-34",
        "41.858",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-34",
        "41.322",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-34",
        "40.782",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-34",
        "40.241",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-34",
        "39.697",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-34",
        "39.151",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-34",
        "38.603",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-34",
        "38.052",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-34",
        "37.501",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-34",
        "36.947",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-34",
        "36.392",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-34",
        "35.836",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-34",
        "35.279",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-34",
        "34.720",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-34",
        "34.161",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-34",
        "33.601",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-34",
        "33.041",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-34",
        "32.481",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-34",
        "31.921",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-34",
        "31.360",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-34",
        "30.800",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-34",
        "30.240",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-34",
        "29.681",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-34",
        "29.123",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-34",
        "28.565",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-34",
        "28.010",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-34",
        "27.455",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-34",
        "26.902",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-34",
        "26.349",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-34",
        "25.799",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-34",
        "25.252",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-34",
        "24.705",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-34",
        "24.162",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-34",
        "23.621",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-34",
        "23.082",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-34",
        "22.546",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-34",
        "22.013",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-34",
        "21.483",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-34",
        "20.957",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-34",
        "20.434",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-34",
        "19.915",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-34",
        "19.399",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-34",
        "18.887",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-34",
        "18.380",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-34",
        "17.877",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-34",
        "17.378",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-34",
        "16.883",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-34",
        "16.392",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-34",
        "15.908",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-34",
        "15.427",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-34",
        "14.953",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-34",
        "14.483",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-34",
        "14.019",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-34",
        "13.559",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-34",
        "13.107",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-34",
        "12.660",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-34",
        "12.219",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-34",
        "11.783",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-34",
        "11.354",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-34",
        "10.931",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-34",
        "10.515",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-34",
        "10.106",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-34",
        "9.703",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-34",
        "9.307",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-34",
        "8.918",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-34",
        "8.536",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-34",
        "8.161",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-34",
        "7.794",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-34",
        "7.434",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-34",
        "6.266",
        "35.628",
        "3.552",
        "2.890",
        "-0.031"
    ],
    [
        "179",
        "-34",
        "2.266",
        "31.000",
        "5.666",
        "4.214",
        "-0.146"
    ],
    [
        "-180",
        "-33",
        "-1.800",
        "29.845",
        "6.951",
        "4.351",
        "-0.254"
    ],
    [
        "-179",
        "-33",
        "-14.607",
        "28.648",
        "7.136",
        "4.909",
        "-0.336"
    ],
    [
        "-178",
        "-33",
        "-30.876",
        "28.914",
        "7.114",
        "5.295",
        "-0.421"
    ],
    [
        "-177",
        "-33",
        "-45.884",
        "29.488",
        "6.815",
        "5.065",
        "-0.606"
    ],
    [
        "-176",
        "-33",
        "-50.854",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-33",
        "-51.140",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-33",
        "-51.427",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-33",
        "-51.714",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-33",
        "-52.003",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-33",
        "-52.293",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-33",
        "-52.583",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-33",
        "-52.874",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-33",
        "-53.165",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-33",
        "-53.457",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-33",
        "-53.750",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-33",
        "-54.043",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-33",
        "-54.336",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-33",
        "-54.629",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-33",
        "-54.922",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-33",
        "-55.215",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-33",
        "-55.508",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-33",
        "-55.801",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-33",
        "-56.093",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-33",
        "-56.385",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-33",
        "-56.677",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-33",
        "-56.968",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-33",
        "-57.259",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-33",
        "-57.548",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-33",
        "-57.837",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-33",
        "-58.125",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-33",
        "-58.412",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-33",
        "-58.698",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-33",
        "-58.982",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-33",
        "-59.265",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-33",
        "-59.547",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-33",
        "-59.828",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-33",
        "-60.107",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-33",
        "-60.384",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-33",
        "-60.660",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-33",
        "-60.934",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-33",
        "-61.206",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-33",
        "-61.476",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-33",
        "-61.744",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-33",
        "-62.010",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-33",
        "-62.273",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-33",
        "-62.535",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-33",
        "-62.794",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-33",
        "-63.051",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-33",
        "-63.305",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-33",
        "-63.556",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-33",
        "-63.805",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-33",
        "-64.051",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-33",
        "-64.295",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-33",
        "-64.535",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-33",
        "-64.773",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-33",
        "-65.007",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-33",
        "-65.238",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-33",
        "-65.466",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-33",
        "-65.691",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-33",
        "-65.913",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-33",
        "-66.131",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-33",
        "-66.346",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-33",
        "-66.557",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-33",
        "-66.765",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-33",
        "-66.969",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-33",
        "-67.169",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-33",
        "-67.366",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-33",
        "-67.558",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-33",
        "-54.992",
        "22.235",
        "10.754",
        "14.564",
        "0.076"
    ],
    [
        "-111",
        "-33",
        "-34.377",
        "8.836",
        "14.937",
        "13.620",
        "0.133"
    ],
    [
        "-110",
        "-33",
        "15.426",
        "-7.867",
        "14.523",
        "13.827",
        "0.157"
    ],
    [
        "-109",
        "-33",
        "62.822",
        "-9.559",
        "9.947",
        "9.870",
        "0.212"
    ],
    [
        "-108",
        "-33",
        "69.703",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-33",
        "69.781",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-33",
        "69.851",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-33",
        "69.913",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-33",
        "69.966",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-33",
        "70.012",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-33",
        "70.049",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-33",
        "70.078",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-33",
        "70.098",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-33",
        "70.110",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-33",
        "70.115",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-33",
        "70.110",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-33",
        "70.098",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-33",
        "70.077",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-33",
        "70.047",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-33",
        "70.010",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-33",
        "69.964",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-33",
        "69.910",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-33",
        "69.848",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-33",
        "69.778",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-33",
        "69.699",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-33",
        "69.612",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-33",
        "69.517",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-33",
        "69.415",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-33",
        "69.303",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-33",
        "69.184",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-33",
        "69.057",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-33",
        "68.922",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-33",
        "68.779",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-33",
        "68.628",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-33",
        "68.470",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-33",
        "68.303",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-33",
        "68.129",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-33",
        "67.947",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-33",
        "67.757",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-33",
        "63.103",
        "20.801",
        "2.742",
        "2.056",
        "0.038"
    ],
    [
        "-72",
        "-33",
        "33.605",
        "20.902",
        "7.920",
        "3.821",
        "0.052"
    ],
    [
        "-71",
        "-33",
        "23.586",
        "16.948",
        "1.779",
        "2.409",
        "0.186"
    ],
    [
        "-70",
        "-33",
        "15.380",
        "10.421",
        "2.101",
        "1.655",
        "0.026"
    ],
    [
        "-69",
        "-33",
        "7.227",
        "12.311",
        "1.345",
        "1.069",
        "-0.019"
    ],
    [
        "-68",
        "-33",
        "5.390",
        "10.908",
        "2.025",
        "1.667",
        "-0.054"
    ],
    [
        "-67",
        "-33",
        "3.512",
        "9.711",
        "1.744",
        "1.546",
        "0.020"
    ],
    [
        "-66",
        "-33",
        "2.645",
        "10.232",
        "2.422",
        "2.091",
        "0.113"
    ],
    [
        "-65",
        "-33",
        "2.075",
        "10.591",
        "2.198",
        "2.366",
        "-0.080"
    ],
    [
        "-64",
        "-33",
        "1.297",
        "10.170",
        "1.796",
        "1.885",
        "0.005"
    ],
    [
        "-63",
        "-33",
        "0.859",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-33",
        "0.761",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-33",
        "0.663",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-33",
        "0.562",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-33",
        "0.462",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-33",
        "0.359",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-33",
        "0.255",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-33",
        "0.152",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-33",
        "0.046",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-33",
        "-0.060",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-33",
        "-0.167",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-33",
        "-0.275",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-33",
        "-0.383",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-33",
        "-0.493",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-33",
        "-0.603",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-33",
        "-0.714",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-33",
        "-0.825",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-33",
        "-0.938",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-33",
        "-1.051",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-33",
        "-1.164",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-33",
        "-1.279",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-33",
        "-1.393",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-33",
        "-1.509",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-33",
        "-1.624",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-33",
        "-1.741",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-33",
        "-1.857",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-33",
        "-1.973",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-33",
        "-2.090",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-33",
        "-2.208",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-33",
        "-2.325",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-33",
        "-2.443",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-33",
        "-2.561",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-33",
        "-2.679",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-33",
        "-2.797",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-33",
        "-2.915",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-33",
        "-3.034",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-33",
        "-3.152",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-33",
        "-3.271",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-33",
        "-3.388",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-33",
        "-3.507",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-33",
        "-3.625",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-33",
        "-3.743",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-33",
        "-3.860",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-33",
        "-3.977",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-33",
        "-4.094",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-33",
        "-4.211",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-33",
        "-4.327",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-33",
        "-4.443",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-33",
        "-4.558",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-33",
        "21.503",
        "17.417",
        "0.935",
        "1.325",
        "-0.111"
    ],
    [
        "-13",
        "-33",
        "24.375",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-33",
        "24.203",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-33",
        "24.029",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-33",
        "23.855",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-33",
        "23.680",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-33",
        "23.503",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-33",
        "23.326",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-33",
        "23.147",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-33",
        "22.968",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-33",
        "22.788",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-33",
        "22.606",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-33",
        "22.424",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-33",
        "22.243",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-33",
        "22.059",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-33",
        "21.876",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-33",
        "21.692",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-33",
        "21.507",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-33",
        "21.323",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-33",
        "21.138",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-33",
        "20.953",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-33",
        "20.767",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-33",
        "20.581",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-33",
        "20.396",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-33",
        "20.209",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-33",
        "20.024",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-33",
        "19.837",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-33",
        "19.652",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-33",
        "19.467",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-33",
        "19.282",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-33",
        "19.097",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-33",
        "18.912",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-33",
        "18.728",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-33",
        "18.544",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-33",
        "18.361",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-33",
        "18.178",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-33",
        "17.996",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-33",
        "17.815",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-33",
        "17.634",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-33",
        "17.454",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-33",
        "17.276",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-33",
        "17.097",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-33",
        "16.920",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-33",
        "16.745",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-33",
        "16.570",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-33",
        "16.396",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-33",
        "16.223",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-33",
        "16.052",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-33",
        "16.082",
        "17.675",
        "0.661",
        "0.766",
        "-0.003"
    ],
    [
        "35",
        "-33",
        "16.119",
        "17.343",
        "0.765",
        "0.877",
        "-0.009"
    ],
    [
        "36",
        "-33",
        "16.150",
        "17.017",
        "0.862",
        "0.909",
        "-0.015"
    ],
    [
        "37",
        "-33",
        "16.173",
        "16.702",
        "0.854",
        "0.967",
        "-0.022"
    ],
    [
        "38",
        "-33",
        "16.190",
        "16.398",
        "0.876",
        "0.991",
        "-0.028"
    ],
    [
        "39",
        "-33",
        "16.203",
        "16.105",
        "0.928",
        "0.985",
        "-0.032"
    ],
    [
        "40",
        "-33",
        "16.215",
        "15.823",
        "0.895",
        "1.016",
        "-0.038"
    ],
    [
        "41",
        "-33",
        "16.230",
        "15.552",
        "0.896",
        "1.019",
        "-0.042"
    ],
    [
        "42",
        "-33",
        "16.249",
        "15.295",
        "0.928",
        "0.994",
        "-0.046"
    ],
    [
        "43",
        "-33",
        "16.273",
        "15.050",
        "0.876",
        "1.002",
        "-0.051"
    ],
    [
        "44",
        "-33",
        "16.302",
        "14.825",
        "0.854",
        "0.976",
        "-0.056"
    ],
    [
        "45",
        "-33",
        "16.333",
        "14.624",
        "0.863",
        "0.909",
        "-0.061"
    ],
    [
        "46",
        "-33",
        "16.356",
        "14.449",
        "0.776",
        "0.862",
        "-0.064"
    ],
    [
        "47",
        "-33",
        "16.348",
        "14.269",
        "0.723",
        "0.785",
        "-0.022"
    ],
    [
        "48",
        "-33",
        "16.294",
        "14.069",
        "0.744",
        "0.736",
        "0.007"
    ],
    [
        "49",
        "-33",
        "16.226",
        "13.882",
        "0.657",
        "0.741",
        "0.020"
    ],
    [
        "50",
        "-33",
        "16.171",
        "13.724",
        "0.449",
        "0.481",
        "0.015"
    ],
    [
        "51",
        "-33",
        "16.072",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-33",
        "15.945",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-33",
        "15.820",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-33",
        "15.699",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-33",
        "15.579",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-33",
        "15.461",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-33",
        "13.715",
        "5.542",
        "2.259",
        "3.931",
        "-0.107"
    ],
    [
        "58",
        "-33",
        "12.537",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-33",
        "12.538",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-33",
        "12.541",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-33",
        "12.546",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-33",
        "12.552",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-33",
        "12.561",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-33",
        "12.572",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-33",
        "12.584",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-33",
        "12.599",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-33",
        "12.614",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-33",
        "12.633",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-33",
        "12.653",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-33",
        "12.676",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-33",
        "12.700",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-33",
        "12.725",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-33",
        "12.753",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-33",
        "12.783",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-33",
        "12.815",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-33",
        "12.848",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-33",
        "12.884",
        "-3.819",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-33",
        "32.650",
        "12.569",
        "11.625",
        "7.867",
        "-0.066"
    ],
    [
        "79",
        "-33",
        "46.844",
        "28.898",
        "7.233",
        "6.439",
        "-0.063"
    ],
    [
        "80",
        "-33",
        "52.013",
        "34.718",
        "4.530",
        "3.805",
        "0.055"
    ],
    [
        "81",
        "-33",
        "53.606",
        "37.162",
        "2.619",
        "2.401",
        "0.072"
    ],
    [
        "82",
        "-33",
        "53.984",
        "39.195",
        "1.553",
        "1.706",
        "0.035"
    ],
    [
        "83",
        "-33",
        "53.947",
        "41.204",
        "1.141",
        "1.141",
        "-0.097"
    ],
    [
        "84",
        "-33",
        "53.631",
        "42.215",
        "1.095",
        "1.006",
        "-0.165"
    ],
    [
        "85",
        "-33",
        "53.270",
        "42.975",
        "1.007",
        "1.097",
        "-0.115"
    ],
    [
        "86",
        "-33",
        "52.887",
        "43.690",
        "0.781",
        "0.897",
        "-0.080"
    ],
    [
        "87",
        "-33",
        "52.475",
        "44.341",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-33",
        "52.050",
        "44.970",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-33",
        "51.620",
        "45.587",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-33",
        "51.184",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-33",
        "50.742",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-33",
        "50.294",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-33",
        "49.842",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-33",
        "49.384",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-33",
        "48.921",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-33",
        "48.453",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-33",
        "47.979",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-33",
        "47.502",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-33",
        "47.020",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-33",
        "46.533",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-33",
        "46.042",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-33",
        "45.548",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-33",
        "45.049",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-33",
        "44.547",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-33",
        "44.039",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-33",
        "43.530",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-33",
        "43.017",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-33",
        "42.501",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-33",
        "41.981",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-33",
        "41.460",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-33",
        "40.935",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-33",
        "40.407",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-33",
        "39.878",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-33",
        "39.346",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-33",
        "38.811",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-33",
        "38.276",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-33",
        "37.737",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-33",
        "37.199",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-33",
        "36.659",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-33",
        "36.116",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-33",
        "35.574",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-33",
        "35.030",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-33",
        "34.486",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-33",
        "33.940",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-33",
        "33.395",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-33",
        "32.849",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-33",
        "32.303",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-33",
        "31.757",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-33",
        "31.211",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-33",
        "30.667",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-33",
        "30.122",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-33",
        "29.579",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-33",
        "29.036",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-33",
        "28.494",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-33",
        "27.954",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-33",
        "27.415",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-33",
        "26.877",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-33",
        "26.342",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-33",
        "25.808",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-33",
        "25.276",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-33",
        "24.747",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-33",
        "24.219",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-33",
        "23.694",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-33",
        "23.172",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-33",
        "22.654",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-33",
        "22.138",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-33",
        "21.625",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-33",
        "21.116",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-33",
        "20.610",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-33",
        "20.108",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-33",
        "19.610",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-33",
        "19.115",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-33",
        "18.624",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-33",
        "18.139",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-33",
        "17.657",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-33",
        "17.179",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-33",
        "16.707",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-33",
        "16.240",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-33",
        "15.777",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-33",
        "15.319",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-33",
        "14.867",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-33",
        "14.421",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-33",
        "13.979",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-33",
        "13.543",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-33",
        "13.113",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-33",
        "12.690",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-33",
        "12.272",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-33",
        "11.860",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-33",
        "11.455",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-33",
        "11.056",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-33",
        "10.663",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-33",
        "10.278",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-33",
        "9.900",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-33",
        "9.527",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-33",
        "9.162",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-33",
        "8.805",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-33",
        "8.454",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-33",
        "8.111",
        "35.706",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-33",
        "5.345",
        "32.848",
        "4.984",
        "3.917",
        "-0.146"
    ],
    [
        "-180",
        "-32",
        "2.184",
        "31.604",
        "6.417",
        "4.013",
        "-0.175"
    ],
    [
        "-179",
        "-32",
        "-8.577",
        "29.551",
        "6.886",
        "4.524",
        "-0.254"
    ],
    [
        "-178",
        "-32",
        "-23.677",
        "28.669",
        "7.053",
        "4.696",
        "-0.355"
    ],
    [
        "-177",
        "-32",
        "-40.145",
        "28.954",
        "7.128",
        "4.531",
        "-0.537"
    ],
    [
        "-176",
        "-32",
        "-51.574",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-32",
        "-51.852",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-32",
        "-52.131",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-32",
        "-52.411",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-32",
        "-52.692",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-32",
        "-52.973",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-32",
        "-53.256",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-32",
        "-53.539",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-32",
        "-53.822",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-32",
        "-54.106",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-32",
        "-54.391",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-32",
        "-54.676",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-32",
        "-54.961",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-32",
        "-55.246",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-32",
        "-55.531",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-32",
        "-55.817",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-32",
        "-56.102",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-32",
        "-56.387",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-32",
        "-56.671",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-32",
        "-56.955",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-32",
        "-57.239",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-32",
        "-57.522",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-32",
        "-57.805",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-32",
        "-58.087",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-32",
        "-58.368",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-32",
        "-58.648",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-32",
        "-58.927",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-32",
        "-59.205",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-32",
        "-59.482",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-32",
        "-59.758",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-32",
        "-60.032",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-32",
        "-60.305",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-32",
        "-60.576",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-32",
        "-60.846",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-32",
        "-61.114",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-32",
        "-61.381",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-32",
        "-61.645",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-32",
        "-61.908",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-32",
        "-62.169",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-32",
        "-62.428",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-32",
        "-62.684",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-32",
        "-62.939",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-32",
        "-63.191",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-32",
        "-63.440",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-32",
        "-63.688",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-32",
        "-63.933",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-32",
        "-64.175",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-32",
        "-64.414",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-32",
        "-64.651",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-32",
        "-64.885",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-32",
        "-65.116",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-32",
        "-65.344",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-32",
        "-65.569",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-32",
        "-65.791",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-32",
        "-66.010",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-32",
        "-66.225",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-32",
        "-66.438",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-32",
        "-66.647",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-32",
        "-66.852",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-32",
        "-67.054",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-32",
        "-67.253",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-32",
        "-67.448",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-32",
        "-67.639",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-32",
        "-67.826",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-32",
        "-48.974",
        "35.000",
        "10.602",
        "14.218",
        "0.032"
    ],
    [
        "-111",
        "-32",
        "3.335",
        "-5.603",
        "14.118",
        "12.883",
        "0.142"
    ],
    [
        "-110",
        "-32",
        "48.922",
        "-22.623",
        "12.823",
        "12.314",
        "0.441"
    ],
    [
        "-109",
        "-32",
        "69.374",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-32",
        "69.458",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-32",
        "69.534",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-32",
        "69.602",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-32",
        "69.662",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-32",
        "69.715",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-32",
        "69.759",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-32",
        "69.795",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-32",
        "69.823",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-32",
        "69.843",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-32",
        "69.855",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-32",
        "69.858",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-32",
        "69.854",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-32",
        "69.842",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-32",
        "69.822",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-32",
        "69.793",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-32",
        "69.757",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-32",
        "69.712",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-32",
        "69.660",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-32",
        "69.599",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-32",
        "69.530",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-32",
        "69.454",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-32",
        "69.370",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-32",
        "69.278",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-32",
        "69.177",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-32",
        "69.069",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-32",
        "68.953",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-32",
        "68.829",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-32",
        "68.698",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-32",
        "68.559",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-32",
        "68.412",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-32",
        "68.258",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-32",
        "68.095",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-32",
        "67.926",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-32",
        "67.750",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-32",
        "67.565",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-32",
        "63.548",
        "21.031",
        "2.614",
        "1.923",
        "0.019"
    ],
    [
        "-72",
        "-32",
        "33.524",
        "19.059",
        "8.220",
        "3.867",
        "-0.001"
    ],
    [
        "-71",
        "-32",
        "24.605",
        "16.590",
        "2.513",
        "2.761",
        "-0.139"
    ],
    [
        "-70",
        "-32",
        "14.392",
        "14.444",
        "3.445",
        "2.018",
        "-0.010"
    ],
    [
        "-69",
        "-32",
        "10.385",
        "12.760",
        "1.924",
        "1.257",
        "-0.118"
    ],
    [
        "-68",
        "-32",
        "6.037",
        "10.617",
        "1.518",
        "1.266",
        "0.093"
    ],
    [
        "-67",
        "-32",
        "3.503",
        "10.473",
        "1.827",
        "1.607",
        "0.080"
    ],
    [
        "-66",
        "-32",
        "2.474",
        "10.300",
        "2.464",
        "1.976",
        "0.084"
    ],
    [
        "-65",
        "-32",
        "1.631",
        "10.239",
        "2.118",
        "2.361",
        "-0.017"
    ],
    [
        "-64",
        "-32",
        "1.508",
        "10.484",
        "1.341",
        "1.696",
        "0.049"
    ],
    [
        "-63",
        "-32",
        "0.721",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-32",
        "0.626",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-32",
        "0.530",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-32",
        "0.433",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-32",
        "0.333",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-32",
        "0.234",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-32",
        "0.134",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-32",
        "0.033",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-32",
        "-0.069",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-32",
        "-0.173",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-32",
        "-0.277",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-32",
        "-0.382",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-32",
        "-0.487",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-32",
        "-0.594",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-32",
        "-0.702",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-32",
        "-0.809",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-32",
        "-0.918",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-32",
        "-1.027",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-32",
        "-1.137",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-32",
        "-1.248",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-32",
        "-1.359",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-32",
        "-1.471",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-32",
        "-1.582",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-32",
        "-1.695",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-32",
        "-1.808",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-32",
        "-1.921",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-32",
        "-2.035",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-32",
        "-2.149",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-32",
        "-2.263",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-32",
        "-2.377",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-32",
        "-2.492",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-32",
        "-2.607",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-32",
        "-2.722",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-32",
        "-2.837",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-32",
        "-2.952",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-32",
        "-3.067",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-32",
        "-3.182",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-32",
        "-3.297",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-32",
        "-3.412",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-32",
        "-3.527",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-32",
        "-3.641",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-32",
        "-3.756",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-32",
        "-3.871",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-32",
        "-3.985",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-32",
        "-4.099",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-32",
        "-4.212",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-32",
        "-4.325",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-32",
        "-4.437",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-32",
        "-4.550",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-32",
        "-4.662",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-32",
        "24.491",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-32",
        "24.322",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-32",
        "24.154",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-32",
        "23.984",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-32",
        "23.813",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-32",
        "23.641",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-32",
        "23.469",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-32",
        "23.295",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-32",
        "23.121",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-32",
        "22.946",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-32",
        "22.769",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-32",
        "22.593",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-32",
        "22.416",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-32",
        "22.238",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-32",
        "22.059",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-32",
        "21.879",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-32",
        "21.701",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-32",
        "21.521",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-32",
        "21.340",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-32",
        "21.160",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-32",
        "20.979",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-32",
        "20.799",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-32",
        "20.618",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-32",
        "20.437",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-32",
        "20.256",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-32",
        "20.076",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-32",
        "19.895",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-32",
        "19.715",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-32",
        "19.534",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-32",
        "19.354",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-32",
        "19.175",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-32",
        "18.995",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-32",
        "18.817",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-32",
        "18.638",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-32",
        "18.461",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-32",
        "18.284",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-32",
        "18.107",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-32",
        "17.932",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-32",
        "17.757",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-32",
        "17.582",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-32",
        "17.409",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-32",
        "17.237",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-32",
        "17.066",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-32",
        "16.896",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-32",
        "16.727",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-32",
        "16.558",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-32",
        "16.392",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-32",
        "16.468",
        "17.662",
        "0.670",
        "0.779",
        "-0.006"
    ],
    [
        "35",
        "-32",
        "16.542",
        "17.329",
        "0.777",
        "0.892",
        "-0.017"
    ],
    [
        "36",
        "-32",
        "16.598",
        "17.014",
        "0.875",
        "0.921",
        "-0.022"
    ],
    [
        "37",
        "-32",
        "16.637",
        "16.714",
        "0.865",
        "0.977",
        "-0.026"
    ],
    [
        "38",
        "-32",
        "16.666",
        "16.423",
        "0.885",
        "0.998",
        "-0.029"
    ],
    [
        "39",
        "-32",
        "16.690",
        "16.141",
        "0.936",
        "0.989",
        "-0.030"
    ],
    [
        "40",
        "-32",
        "16.714",
        "15.869",
        "0.901",
        "1.018",
        "-0.032"
    ],
    [
        "41",
        "-32",
        "16.739",
        "15.606",
        "0.898",
        "1.017",
        "-0.034"
    ],
    [
        "42",
        "-32",
        "16.768",
        "15.357",
        "0.928",
        "0.987",
        "-0.035"
    ],
    [
        "43",
        "-32",
        "16.803",
        "15.121",
        "0.869",
        "0.992",
        "-0.036"
    ],
    [
        "44",
        "-32",
        "16.847",
        "14.906",
        "0.838",
        "0.961",
        "-0.038"
    ],
    [
        "45",
        "-32",
        "16.903",
        "14.718",
        "0.827",
        "0.879",
        "-0.039"
    ],
    [
        "46",
        "-32",
        "16.978",
        "14.576",
        "0.666",
        "0.774",
        "-0.036"
    ],
    [
        "47",
        "-32",
        "17.071",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-32",
        "16.938",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-32",
        "16.808",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-32",
        "16.680",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-32",
        "16.554",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-32",
        "16.430",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-32",
        "16.309",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-32",
        "16.190",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-32",
        "16.074",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-32",
        "15.960",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-32",
        "15.454",
        "9.849",
        "2.243",
        "3.766",
        "-0.118"
    ],
    [
        "58",
        "-32",
        "12.922",
        "0.016",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-32",
        "12.923",
        "-0.190",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-32",
        "12.926",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-32",
        "12.930",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-32",
        "12.937",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-32",
        "12.946",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-32",
        "12.955",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-32",
        "12.968",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-32",
        "12.982",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-32",
        "12.998",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-32",
        "13.016",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-32",
        "13.035",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-32",
        "13.057",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-32",
        "13.080",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-32",
        "13.105",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-32",
        "13.132",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-32",
        "13.161",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-32",
        "13.193",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-32",
        "13.226",
        "-3.624",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-32",
        "21.283",
        "5.626",
        "13.022",
        "12.606",
        "-0.224"
    ],
    [
        "78",
        "-32",
        "34.613",
        "18.843",
        "11.826",
        "9.025",
        "-0.242"
    ],
    [
        "79",
        "-32",
        "48.121",
        "30.360",
        "6.295",
        "5.647",
        "-0.192"
    ],
    [
        "80",
        "-32",
        "52.389",
        "34.608",
        "3.696",
        "3.131",
        "-0.019"
    ],
    [
        "81",
        "-32",
        "53.266",
        "36.812",
        "2.347",
        "2.176",
        "0.065"
    ],
    [
        "82",
        "-32",
        "53.302",
        "38.764",
        "1.785",
        "1.770",
        "0.040"
    ],
    [
        "83",
        "-32",
        "53.197",
        "40.545",
        "1.581",
        "1.490",
        "-0.045"
    ],
    [
        "84",
        "-32",
        "53.073",
        "41.819",
        "1.519",
        "1.359",
        "-0.105"
    ],
    [
        "85",
        "-32",
        "52.898",
        "42.778",
        "1.327",
        "1.414",
        "-0.089"
    ],
    [
        "86",
        "-32",
        "52.664",
        "43.661",
        "0.886",
        "0.956",
        "-0.070"
    ],
    [
        "87",
        "-32",
        "52.303",
        "44.341",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-32",
        "51.890",
        "44.970",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-32",
        "51.471",
        "45.587",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-32",
        "51.046",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-32",
        "50.616",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-32",
        "50.181",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-32",
        "49.741",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-32",
        "49.295",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-32",
        "48.845",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-32",
        "48.388",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-32",
        "47.928",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-32",
        "47.464",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-32",
        "46.994",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-32",
        "46.521",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-32",
        "46.044",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-32",
        "45.562",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-32",
        "45.077",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-32",
        "44.588",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-32",
        "44.095",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-32",
        "43.599",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-32",
        "43.100",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-32",
        "42.597",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-32",
        "42.093",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-32",
        "41.584",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-32",
        "41.074",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-32",
        "40.560",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-32",
        "40.045",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-32",
        "39.528",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-32",
        "39.008",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-32",
        "38.487",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-32",
        "37.964",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-32",
        "37.439",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-32",
        "36.913",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-32",
        "36.386",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-32",
        "35.858",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-32",
        "35.329",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-32",
        "34.799",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-32",
        "34.269",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-32",
        "33.738",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-32",
        "33.206",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-32",
        "32.676",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-32",
        "32.145",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-32",
        "31.614",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-32",
        "31.083",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-32",
        "30.553",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-32",
        "30.025",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-32",
        "29.497",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-32",
        "28.970",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-32",
        "28.444",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-32",
        "27.919",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-32",
        "27.397",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-32",
        "26.875",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-32",
        "26.356",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-32",
        "25.839",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-32",
        "25.323",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-32",
        "24.811",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-32",
        "24.300",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-32",
        "23.792",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-32",
        "23.287",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-32",
        "22.786",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-32",
        "22.287",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-32",
        "21.791",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-32",
        "21.299",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-32",
        "20.810",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-32",
        "20.325",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-32",
        "19.844",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-32",
        "19.367",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-32",
        "18.894",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-32",
        "18.426",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-32",
        "17.961",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-32",
        "17.501",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-32",
        "17.047",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-32",
        "16.596",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-32",
        "16.151",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-32",
        "15.711",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-32",
        "15.276",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-32",
        "14.847",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-32",
        "14.423",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-32",
        "14.005",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-32",
        "13.592",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-32",
        "13.186",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-32",
        "12.785",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-32",
        "12.391",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-32",
        "12.003",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-32",
        "11.621",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-32",
        "11.246",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-32",
        "10.878",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-32",
        "10.515",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-32",
        "10.161",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-32",
        "9.813",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-32",
        "9.472",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-32",
        "9.138",
        "35.706",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-32",
        "8.360",
        "34.654",
        "1.331",
        "1.648",
        "-0.107"
    ],
    [
        "-180",
        "-31",
        "5.323",
        "32.844",
        "5.946",
        "3.600",
        "-0.090"
    ],
    [
        "-179",
        "-31",
        "-3.335",
        "30.194",
        "6.576",
        "4.236",
        "-0.155"
    ],
    [
        "-178",
        "-31",
        "-16.692",
        "28.117",
        "6.879",
        "4.316",
        "-0.286"
    ],
    [
        "-177",
        "-31",
        "-32.647",
        "27.863",
        "7.271",
        "4.169",
        "-0.457"
    ],
    [
        "-176",
        "-31",
        "-50.656",
        "29.630",
        "1.588",
        "1.440",
        "-0.353"
    ],
    [
        "-175",
        "-31",
        "-52.548",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-31",
        "-52.819",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-31",
        "-53.091",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-31",
        "-53.364",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-31",
        "-53.638",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-31",
        "-53.912",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-31",
        "-54.187",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-31",
        "-54.463",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-31",
        "-54.739",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-31",
        "-55.016",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-31",
        "-55.292",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-31",
        "-55.570",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-31",
        "-55.847",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-31",
        "-56.124",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-31",
        "-56.401",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-31",
        "-56.678",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-31",
        "-56.955",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-31",
        "-57.232",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-31",
        "-57.508",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-31",
        "-57.784",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-31",
        "-58.059",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-31",
        "-58.334",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-31",
        "-58.608",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-31",
        "-58.881",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-31",
        "-59.153",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-31",
        "-59.424",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-31",
        "-59.694",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-31",
        "-59.964",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-31",
        "-60.231",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-31",
        "-60.498",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-31",
        "-60.763",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-31",
        "-61.027",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-31",
        "-61.289",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-31",
        "-61.550",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-31",
        "-61.809",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-31",
        "-62.066",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-31",
        "-62.322",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-31",
        "-62.575",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-31",
        "-62.827",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-31",
        "-63.076",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-31",
        "-63.323",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-31",
        "-63.568",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-31",
        "-63.811",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-31",
        "-64.051",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-31",
        "-64.289",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-31",
        "-64.525",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-31",
        "-64.757",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-31",
        "-64.987",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-31",
        "-65.215",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-31",
        "-65.439",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-31",
        "-65.661",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-31",
        "-65.880",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-31",
        "-66.096",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-31",
        "-66.308",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-31",
        "-66.518",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-31",
        "-66.724",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-31",
        "-66.927",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-31",
        "-67.127",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-31",
        "-67.323",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-31",
        "-67.516",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-31",
        "-67.706",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-31",
        "-67.891",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-31",
        "-68.074",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-31",
        "17.760",
        "20.557",
        "7.040",
        "10.665",
        "-0.066"
    ],
    [
        "-111",
        "-31",
        "68.924",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-31",
        "69.021",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-31",
        "69.111",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-31",
        "69.192",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-31",
        "69.266",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-31",
        "69.333",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-31",
        "69.390",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-31",
        "69.441",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-31",
        "69.484",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-31",
        "69.519",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-31",
        "69.546",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-31",
        "69.566",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-31",
        "69.577",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-31",
        "69.581",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-31",
        "69.577",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-31",
        "69.566",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-31",
        "69.546",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-31",
        "69.518",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-31",
        "69.483",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-31",
        "69.440",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-31",
        "69.388",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-31",
        "69.330",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-31",
        "69.262",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-31",
        "69.188",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-31",
        "69.106",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-31",
        "69.017",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-31",
        "68.919",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-31",
        "68.814",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-31",
        "68.702",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-31",
        "68.581",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-31",
        "68.453",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-31",
        "68.318",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-31",
        "68.176",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-31",
        "68.026",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-31",
        "67.868",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-31",
        "67.703",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-31",
        "67.531",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-31",
        "67.352",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-31",
        "64.091",
        "21.054",
        "2.611",
        "1.910",
        "0.034"
    ],
    [
        "-72",
        "-31",
        "32.353",
        "17.228",
        "7.915",
        "3.894",
        "0.047"
    ],
    [
        "-71",
        "-31",
        "22.058",
        "17.282",
        "1.602",
        "1.425",
        "0.047"
    ],
    [
        "-70",
        "-31",
        "14.777",
        "14.727",
        "2.772",
        "1.925",
        "0.077"
    ],
    [
        "-69",
        "-31",
        "10.016",
        "12.968",
        "1.498",
        "1.101",
        "-0.100"
    ],
    [
        "-68",
        "-31",
        "6.735",
        "11.883",
        "1.221",
        "1.119",
        "-0.063"
    ],
    [
        "-67",
        "-31",
        "3.554",
        "10.646",
        "1.568",
        "1.402",
        "-0.004"
    ],
    [
        "-66",
        "-31",
        "2.605",
        "10.862",
        "1.718",
        "1.519",
        "-0.007"
    ],
    [
        "-65",
        "-31",
        "1.148",
        "10.651",
        "2.305",
        "2.357",
        "0.040"
    ],
    [
        "-64",
        "-31",
        "0.555",
        "10.162",
        "1.719",
        "1.771",
        "-0.003"
    ],
    [
        "-63",
        "-31",
        "0.583",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-31",
        "0.490",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-31",
        "0.397",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-31",
        "0.302",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-31",
        "0.206",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-31",
        "0.110",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-31",
        "0.012",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-31",
        "-0.086",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-31",
        "-0.185",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-31",
        "-0.286",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-31",
        "-0.387",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-31",
        "-0.490",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-31",
        "-0.592",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-31",
        "-0.695",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-31",
        "-0.800",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-31",
        "-0.905",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-31",
        "-1.010",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-31",
        "-1.117",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-31",
        "-1.224",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-31",
        "-1.331",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-31",
        "-1.439",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-31",
        "-1.548",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-31",
        "-1.656",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-31",
        "-1.766",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-31",
        "-1.875",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-31",
        "-1.985",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-31",
        "-2.096",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-31",
        "-2.206",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-31",
        "-2.318",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-31",
        "-2.428",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-31",
        "-2.540",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-31",
        "-2.651",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-31",
        "-2.764",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-31",
        "-2.876",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-31",
        "-2.987",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-31",
        "-3.099",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-31",
        "-3.211",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-31",
        "-3.323",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-31",
        "-3.434",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-31",
        "-3.546",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-31",
        "-3.658",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-31",
        "-3.768",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-31",
        "-3.880",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-31",
        "-3.991",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-31",
        "-4.102",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-31",
        "-4.212",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-31",
        "-4.322",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-31",
        "-4.431",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-31",
        "-4.540",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-31",
        "-4.649",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-31",
        "24.598",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-31",
        "24.435",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-31",
        "24.271",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-31",
        "24.106",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-31",
        "23.941",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-31",
        "23.774",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-31",
        "23.606",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-31",
        "23.437",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-31",
        "23.267",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-31",
        "23.097",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-31",
        "22.926",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-31",
        "22.754",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-31",
        "22.581",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-31",
        "22.408",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-31",
        "22.235",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-31",
        "22.061",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-31",
        "21.887",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-31",
        "21.711",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-31",
        "21.537",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-31",
        "21.362",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-31",
        "21.186",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-31",
        "21.011",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-31",
        "20.834",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-31",
        "20.659",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-31",
        "20.483",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-31",
        "20.308",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-31",
        "20.132",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-31",
        "19.956",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-31",
        "19.781",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-31",
        "19.606",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-31",
        "19.432",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-31",
        "19.258",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-31",
        "19.084",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-31",
        "18.910",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-31",
        "18.738",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-31",
        "18.566",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-31",
        "18.395",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-31",
        "18.224",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-31",
        "18.054",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-31",
        "17.884",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-31",
        "17.716",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-31",
        "17.548",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-31",
        "17.383",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "-31",
        "17.217",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "-31",
        "17.052",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "-31",
        "16.889",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "-31",
        "16.727",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "-31",
        "16.922",
        "17.605",
        "0.709",
        "0.818",
        "-0.038"
    ],
    [
        "35",
        "-31",
        "17.012",
        "17.292",
        "0.810",
        "0.919",
        "-0.035"
    ],
    [
        "36",
        "-31",
        "17.068",
        "17.000",
        "0.902",
        "0.941",
        "-0.031"
    ],
    [
        "37",
        "-31",
        "17.108",
        "16.717",
        "0.887",
        "0.994",
        "-0.030"
    ],
    [
        "38",
        "-31",
        "17.141",
        "16.439",
        "0.904",
        "1.012",
        "-0.029"
    ],
    [
        "39",
        "-31",
        "17.170",
        "16.168",
        "0.953",
        "0.999",
        "-0.028"
    ],
    [
        "40",
        "-31",
        "17.198",
        "15.905",
        "0.914",
        "1.026",
        "-0.027"
    ],
    [
        "41",
        "-31",
        "17.230",
        "15.650",
        "0.909",
        "1.023",
        "-0.027"
    ],
    [
        "42",
        "-31",
        "17.265",
        "15.407",
        "0.938",
        "0.989",
        "-0.025"
    ],
    [
        "43",
        "-31",
        "17.305",
        "15.176",
        "0.874",
        "0.992",
        "-0.022"
    ],
    [
        "44",
        "-31",
        "17.355",
        "14.964",
        "0.838",
        "0.958",
        "-0.019"
    ],
    [
        "45",
        "-31",
        "17.415",
        "14.777",
        "0.823",
        "0.869",
        "-0.012"
    ],
    [
        "46",
        "-31",
        "17.489",
        "14.628",
        "0.651",
        "0.755",
        "-0.002"
    ],
    [
        "47",
        "-31",
        "17.534",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-31",
        "17.405",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-31",
        "17.278",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-31",
        "17.153",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-31",
        "17.031",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-31",
        "16.910",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-31",
        "16.793",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-31",
        "16.677",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-31",
        "16.564",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-31",
        "16.454",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-31",
        "16.345",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-31",
        "16.240",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-31",
        "15.789",
        "10.302",
        "0.628",
        "1.660",
        "-0.146"
    ],
    [
        "60",
        "-31",
        "13.307",
        "-0.395",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-31",
        "13.312",
        "-0.601",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-31",
        "13.318",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-31",
        "13.326",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-31",
        "13.336",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-31",
        "13.348",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-31",
        "13.361",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-31",
        "13.377",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-31",
        "13.394",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-31",
        "13.414",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-31",
        "13.435",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-31",
        "13.457",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-31",
        "13.482",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-31",
        "13.509",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-31",
        "13.536",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-31",
        "13.566",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-31",
        "17.928",
        "0.028",
        "11.285",
        "12.878",
        "-0.037"
    ],
    [
        "77",
        "-31",
        "30.919",
        "16.133",
        "13.475",
        "14.358",
        "-0.090"
    ],
    [
        "78",
        "-31",
        "42.041",
        "27.022",
        "9.933",
        "8.972",
        "-0.177"
    ],
    [
        "79",
        "-31",
        "49.164",
        "32.176",
        "4.839",
        "4.791",
        "-0.202"
    ],
    [
        "80",
        "-31",
        "51.876",
        "34.692",
        "2.918",
        "2.647",
        "-0.030"
    ],
    [
        "81",
        "-31",
        "52.396",
        "36.623",
        "2.201",
        "2.015",
        "0.055"
    ],
    [
        "82",
        "-31",
        "52.475",
        "38.418",
        "1.868",
        "1.830",
        "0.047"
    ],
    [
        "83",
        "-31",
        "52.514",
        "39.995",
        "1.724",
        "1.697",
        "0.001"
    ],
    [
        "84",
        "-31",
        "52.543",
        "41.303",
        "1.686",
        "1.580",
        "-0.035"
    ],
    [
        "85",
        "-31",
        "52.530",
        "42.451",
        "1.446",
        "1.581",
        "-0.036"
    ],
    [
        "86",
        "-31",
        "52.432",
        "43.604",
        "0.943",
        "1.008",
        "-0.026"
    ],
    [
        "87",
        "-31",
        "52.114",
        "44.341",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-31",
        "51.712",
        "44.970",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-31",
        "51.306",
        "45.587",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-31",
        "50.893",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-31",
        "50.475",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-31",
        "50.052",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-31",
        "49.624",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-31",
        "49.191",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-31",
        "48.753",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-31",
        "48.310",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-31",
        "47.863",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-31",
        "47.411",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-31",
        "46.956",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-31",
        "46.496",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-31",
        "46.031",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-31",
        "45.563",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-31",
        "45.091",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-31",
        "44.616",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-31",
        "44.138",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-31",
        "43.656",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-31",
        "43.170",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-31",
        "42.681",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-31",
        "42.191",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-31",
        "41.697",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-31",
        "41.201",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-31",
        "40.702",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-31",
        "40.201",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-31",
        "39.699",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-31",
        "39.193",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-31",
        "38.686",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-31",
        "38.178",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-31",
        "37.668",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-31",
        "37.157",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-31",
        "36.645",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-31",
        "36.131",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-31",
        "35.617",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-31",
        "35.102",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-31",
        "34.587",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-31",
        "34.070",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-31",
        "33.555",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-31",
        "33.038",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-31",
        "32.523",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-31",
        "32.007",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-31",
        "31.491",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-31",
        "30.976",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-31",
        "30.462",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-31",
        "29.949",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-31",
        "29.436",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-31",
        "28.926",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-31",
        "28.416",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-31",
        "27.908",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-31",
        "27.401",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-31",
        "26.896",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-31",
        "26.394",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-31",
        "25.893",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-31",
        "25.394",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-31",
        "24.898",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-31",
        "24.405",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-31",
        "23.914",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-31",
        "23.426",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-31",
        "22.941",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-31",
        "22.459",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-31",
        "21.981",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-31",
        "21.506",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-31",
        "21.035",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-31",
        "20.567",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-31",
        "20.104",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-31",
        "19.644",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-31",
        "19.188",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-31",
        "18.737",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-31",
        "18.290",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-31",
        "17.848",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-31",
        "17.410",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-31",
        "16.978",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-31",
        "16.550",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-31",
        "16.128",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-31",
        "15.710",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-31",
        "15.298",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-31",
        "14.892",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-31",
        "14.491",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-31",
        "14.096",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-31",
        "13.707",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-31",
        "13.323",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-31",
        "12.946",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-31",
        "12.575",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-31",
        "12.210",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-31",
        "11.852",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-31",
        "11.501",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-31",
        "11.156",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-31",
        "10.817",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-31",
        "10.486",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-31",
        "10.162",
        "35.706",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-31",
        "9.547",
        "34.904",
        "1.272",
        "1.424",
        "0.007"
    ],
    [
        "-180",
        "-30",
        "8.226",
        "33.641",
        "5.429",
        "2.760",
        "0.000"
    ],
    [
        "-179",
        "-30",
        "1.240",
        "30.572",
        "6.133",
        "3.986",
        "-0.053"
    ],
    [
        "-178",
        "-30",
        "-10.118",
        "27.538",
        "6.674",
        "4.127",
        "-0.203"
    ],
    [
        "-177",
        "-30",
        "-25.528",
        "26.327",
        "7.279",
        "4.120",
        "-0.410"
    ],
    [
        "-176",
        "-30",
        "-43.842",
        "28.302",
        "5.397",
        "3.493",
        "-0.371"
    ],
    [
        "-175",
        "-30",
        "-53.228",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-30",
        "-53.491",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-30",
        "-53.755",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-30",
        "-54.020",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-30",
        "-54.286",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-30",
        "-54.552",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-30",
        "-54.819",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-30",
        "-55.087",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-30",
        "-55.355",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-30",
        "-55.624",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-30",
        "-55.892",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-30",
        "-56.161",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-30",
        "-56.430",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-30",
        "-56.700",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-30",
        "-56.969",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-30",
        "-57.238",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-30",
        "-57.506",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-30",
        "-57.775",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-30",
        "-58.043",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-30",
        "-58.311",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-30",
        "-58.578",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-30",
        "-58.845",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-30",
        "-59.111",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-30",
        "-59.376",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-30",
        "-59.640",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-30",
        "-59.903",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-30",
        "-60.166",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-30",
        "-60.427",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-30",
        "-60.687",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-30",
        "-60.946",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-30",
        "-61.203",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-30",
        "-61.460",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-30",
        "-61.714",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-30",
        "-61.967",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-30",
        "-62.219",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-30",
        "-62.468",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-30",
        "-62.716",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-30",
        "-62.962",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-30",
        "-63.206",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-30",
        "-63.449",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-30",
        "-63.689",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-30",
        "-63.926",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-30",
        "-64.162",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-30",
        "-64.395",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-30",
        "-64.626",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-30",
        "-64.855",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-30",
        "-65.081",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-30",
        "-65.304",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-30",
        "-65.525",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-30",
        "-65.743",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-30",
        "-65.958",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-30",
        "-66.170",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-30",
        "-66.380",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-30",
        "-66.586",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-30",
        "-66.790",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-30",
        "-66.990",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-30",
        "-67.187",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-30",
        "-67.381",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-30",
        "-67.572",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-30",
        "-67.759",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-30",
        "-67.943",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-30",
        "-68.123",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-30",
        "-68.300",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-30",
        "26.232",
        "-9.271",
        "6.779",
        "10.480",
        "0.021"
    ],
    [
        "-111",
        "-30",
        "68.645",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-30",
        "68.739",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-30",
        "68.825",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-30",
        "68.905",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-30",
        "68.977",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-30",
        "69.041",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-30",
        "69.098",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-30",
        "69.147",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-30",
        "69.188",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-30",
        "69.222",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-30",
        "69.249",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-30",
        "69.268",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-30",
        "69.279",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-30",
        "69.283",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-30",
        "69.279",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-30",
        "69.267",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-30",
        "69.248",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-30",
        "69.221",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-30",
        "69.187",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-30",
        "69.145",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-30",
        "69.096",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-30",
        "69.038",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-30",
        "68.974",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-30",
        "68.901",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-30",
        "68.822",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-30",
        "68.735",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-30",
        "68.640",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-30",
        "68.538",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-30",
        "68.429",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-30",
        "68.313",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-30",
        "68.188",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-30",
        "68.057",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-30",
        "67.918",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-30",
        "67.772",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-30",
        "67.619",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-30",
        "67.460",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-30",
        "67.293",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-30",
        "67.119",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-30",
        "64.836",
        "21.301",
        "2.563",
        "1.931",
        "0.013"
    ],
    [
        "-72",
        "-30",
        "35.980",
        "17.580",
        "8.685",
        "4.022",
        "0.098"
    ],
    [
        "-71",
        "-30",
        "19.510",
        "16.557",
        "2.246",
        "2.286",
        "0.074"
    ],
    [
        "-70",
        "-30",
        "16.595",
        "14.472",
        "1.572",
        "1.408",
        "0.029"
    ],
    [
        "-69",
        "-30",
        "10.514",
        "13.627",
        "1.630",
        "1.149",
        "-0.076"
    ],
    [
        "-68",
        "-30",
        "5.507",
        "11.943",
        "1.275",
        "1.064",
        "-0.094"
    ],
    [
        "-67",
        "-30",
        "4.944",
        "11.683",
        "1.875",
        "1.516",
        "-0.082"
    ],
    [
        "-66",
        "-30",
        "2.922",
        "11.233",
        "2.109",
        "1.667",
        "-0.135"
    ],
    [
        "-65",
        "-30",
        "1.442",
        "11.412",
        "2.019",
        "2.161",
        "-0.005"
    ],
    [
        "-64",
        "-30",
        "0.573",
        "9.977",
        "1.549",
        "1.667",
        "0.001"
    ],
    [
        "-63",
        "-30",
        "0.444",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-30",
        "0.355",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-30",
        "0.264",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-30",
        "0.171",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-30",
        "0.078",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-30",
        "-0.015",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-30",
        "-0.110",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-30",
        "-0.205",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-30",
        "-0.302",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-30",
        "-0.399",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-30",
        "-0.498",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-30",
        "-0.597",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-30",
        "-0.696",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-30",
        "-0.796",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-30",
        "-0.898",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-30",
        "-1.000",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-30",
        "-1.102",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-30",
        "-1.206",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-30",
        "-1.310",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-30",
        "-1.414",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-30",
        "-1.519",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-30",
        "-1.624",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-30",
        "-1.729",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-30",
        "-1.836",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-30",
        "-1.942",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-30",
        "-2.049",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-30",
        "-2.157",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-30",
        "-2.264",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-30",
        "-2.371",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-30",
        "-2.480",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-30",
        "-2.588",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-30",
        "-2.696",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-30",
        "-2.805",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-30",
        "-2.913",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-30",
        "-3.022",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-30",
        "-3.130",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-30",
        "-3.239",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-30",
        "-3.347",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-30",
        "-3.456",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-30",
        "-3.564",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-30",
        "-3.673",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-30",
        "-3.781",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-30",
        "-3.888",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-30",
        "-3.996",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-30",
        "-4.103",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-30",
        "-4.211",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-30",
        "-4.317",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-30",
        "-4.424",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-30",
        "-4.529",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-30",
        "-2.668",
        "11.312",
        "3.340",
        "2.281",
        "0.296"
    ],
    [
        "-13",
        "-30",
        "24.698",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-30",
        "24.540",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-30",
        "24.382",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-30",
        "24.221",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-30",
        "24.060",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-30",
        "23.898",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-30",
        "23.735",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-30",
        "23.571",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-30",
        "23.406",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-30",
        "23.241",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-30",
        "23.075",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-30",
        "22.908",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-30",
        "22.741",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-30",
        "22.573",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-30",
        "22.404",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-30",
        "22.236",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-30",
        "22.066",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-30",
        "21.897",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-30",
        "21.727",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-30",
        "21.556",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-30",
        "21.386",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-30",
        "21.215",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-30",
        "21.045",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-30",
        "20.874",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-30",
        "20.703",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-30",
        "20.533",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-30",
        "20.362",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-30",
        "20.193",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-30",
        "20.022",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-30",
        "19.852",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-30",
        "19.683",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-30",
        "19.514",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-30",
        "19.345",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-30",
        "19.177",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-30",
        "19.010",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-30",
        "18.842",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-30",
        "18.676",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-30",
        "18.510",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-30",
        "18.345",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-30",
        "18.181",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-30",
        "18.018",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-30",
        "17.883",
        "18.580",
        "0.564",
        "0.642",
        "0.021"
    ],
    [
        "29",
        "-30",
        "17.766",
        "18.471",
        "0.666",
        "0.718",
        "0.009"
    ],
    [
        "30",
        "-30",
        "17.660",
        "18.346",
        "0.763",
        "0.724",
        "0.012"
    ],
    [
        "31",
        "-30",
        "17.566",
        "18.203",
        "0.728",
        "0.771",
        "0.002"
    ],
    [
        "32",
        "-30",
        "17.497",
        "18.025",
        "0.742",
        "0.784",
        "-0.003"
    ],
    [
        "33",
        "-30",
        "17.471",
        "17.803",
        "0.817",
        "0.783",
        "-0.024"
    ],
    [
        "34",
        "-30",
        "17.497",
        "17.530",
        "0.821",
        "0.893",
        "-0.051"
    ],
    [
        "35",
        "-30",
        "17.525",
        "17.258",
        "0.863",
        "0.952",
        "-0.043"
    ],
    [
        "36",
        "-30",
        "17.554",
        "16.987",
        "0.937",
        "0.964",
        "-0.035"
    ],
    [
        "37",
        "-30",
        "17.582",
        "16.720",
        "0.913",
        "1.012",
        "-0.031"
    ],
    [
        "38",
        "-30",
        "17.610",
        "16.454",
        "0.925",
        "1.026",
        "-0.028"
    ],
    [
        "39",
        "-30",
        "17.639",
        "16.193",
        "0.971",
        "1.009",
        "-0.025"
    ],
    [
        "40",
        "-30",
        "17.670",
        "15.937",
        "0.929",
        "1.035",
        "-0.023"
    ],
    [
        "41",
        "-30",
        "17.703",
        "15.687",
        "0.922",
        "1.030",
        "-0.021"
    ],
    [
        "42",
        "-30",
        "17.742",
        "15.449",
        "0.950",
        "0.994",
        "-0.017"
    ],
    [
        "43",
        "-30",
        "17.785",
        "15.221",
        "0.885",
        "0.997",
        "-0.013"
    ],
    [
        "44",
        "-30",
        "17.834",
        "15.010",
        "0.848",
        "0.961",
        "-0.009"
    ],
    [
        "45",
        "-30",
        "17.892",
        "14.819",
        "0.835",
        "0.873",
        "-0.003"
    ],
    [
        "46",
        "-30",
        "17.957",
        "14.653",
        "0.663",
        "0.766",
        "0.000"
    ],
    [
        "47",
        "-30",
        "17.990",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-30",
        "17.866",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-30",
        "17.742",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-30",
        "17.621",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-30",
        "17.502",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-30",
        "17.385",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-30",
        "17.271",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-30",
        "17.159",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-30",
        "17.049",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-30",
        "16.942",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-30",
        "16.837",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-30",
        "16.734",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-30",
        "16.634",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-30",
        "16.536",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-30",
        "14.402",
        "2.346",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-30",
        "13.694",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-30",
        "13.703",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-30",
        "13.712",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-30",
        "13.724",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-30",
        "13.737",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-30",
        "13.752",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-30",
        "13.769",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-30",
        "13.787",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-30",
        "13.808",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-30",
        "13.830",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-30",
        "13.854",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-30",
        "13.879",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-30",
        "13.907",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-30",
        "13.936",
        "-3.428",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-30",
        "27.086",
        "11.797",
        "12.731",
        "15.968",
        "0.135"
    ],
    [
        "77",
        "-30",
        "40.101",
        "24.928",
        "10.745",
        "12.381",
        "0.066"
    ],
    [
        "78",
        "-30",
        "47.754",
        "31.841",
        "6.739",
        "7.077",
        "-0.046"
    ],
    [
        "79",
        "-30",
        "50.602",
        "34.341",
        "3.278",
        "3.548",
        "-0.093"
    ],
    [
        "80",
        "-30",
        "51.406",
        "35.337",
        "2.301",
        "2.175",
        "0.010"
    ],
    [
        "81",
        "-30",
        "51.650",
        "36.652",
        "2.040",
        "1.866",
        "0.053"
    ],
    [
        "82",
        "-30",
        "51.813",
        "38.116",
        "1.839",
        "1.837",
        "0.048"
    ],
    [
        "83",
        "-30",
        "51.950",
        "39.466",
        "1.744",
        "1.780",
        "0.023"
    ],
    [
        "84",
        "-30",
        "52.066",
        "40.706",
        "1.730",
        "1.670",
        "0.004"
    ],
    [
        "85",
        "-30",
        "52.164",
        "41.982",
        "1.477",
        "1.642",
        "-0.003"
    ],
    [
        "86",
        "-30",
        "52.189",
        "43.515",
        "0.957",
        "1.024",
        "-0.002"
    ],
    [
        "87",
        "-30",
        "51.910",
        "44.341",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-30",
        "51.520",
        "44.970",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-30",
        "51.124",
        "45.587",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-30",
        "50.724",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-30",
        "50.319",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-30",
        "49.908",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-30",
        "49.493",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-30",
        "49.072",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-30",
        "48.646",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-30",
        "48.217",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-30",
        "47.782",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-30",
        "47.345",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-30",
        "46.901",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-30",
        "46.455",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-30",
        "46.004",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-30",
        "45.550",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-30",
        "45.092",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-30",
        "44.631",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-30",
        "44.165",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-30",
        "43.698",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-30",
        "43.227",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-30",
        "42.753",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-30",
        "42.277",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-30",
        "41.797",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-30",
        "41.315",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-30",
        "40.831",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-30",
        "40.345",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-30",
        "39.857",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-30",
        "39.366",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-30",
        "38.874",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-30",
        "38.381",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-30",
        "37.885",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-30",
        "37.389",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-30",
        "36.892",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-30",
        "36.394",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-30",
        "35.894",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-30",
        "35.395",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-30",
        "34.894",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-30",
        "34.393",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-30",
        "33.892",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-30",
        "33.391",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-30",
        "32.890",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-30",
        "32.389",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-30",
        "31.889",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-30",
        "31.389",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-30",
        "30.890",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-30",
        "30.391",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-30",
        "29.894",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-30",
        "29.398",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-30",
        "28.903",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-30",
        "28.410",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-30",
        "27.918",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-30",
        "27.428",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-30",
        "26.940",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-30",
        "26.454",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-30",
        "25.970",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-30",
        "25.489",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-30",
        "25.009",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-30",
        "24.533",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-30",
        "24.059",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-30",
        "23.589",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-30",
        "23.121",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-30",
        "22.657",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-30",
        "22.195",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-30",
        "21.738",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-30",
        "21.284",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-30",
        "20.833",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-30",
        "20.387",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-30",
        "19.945",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-30",
        "19.507",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-30",
        "19.074",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-30",
        "18.644",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-30",
        "18.219",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-30",
        "17.799",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-30",
        "17.384",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-30",
        "16.974",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-30",
        "16.569",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-30",
        "16.169",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-30",
        "15.774",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-30",
        "15.385",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-30",
        "15.002",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-30",
        "14.624",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-30",
        "14.252",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-30",
        "13.885",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-30",
        "13.526",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-30",
        "13.171",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-30",
        "12.823",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-30",
        "12.482",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-30",
        "12.147",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-30",
        "11.818",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-30",
        "11.497",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-30",
        "11.182",
        "35.706",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-30",
        "10.874",
        "34.916",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-29",
        "11.608",
        "34.115",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-29",
        "5.205",
        "31.028",
        "5.616",
        "3.688",
        "0.038"
    ],
    [
        "-178",
        "-29",
        "-3.805",
        "27.021",
        "6.443",
        "4.100",
        "-0.122"
    ],
    [
        "-177",
        "-29",
        "-17.983",
        "24.758",
        "7.339",
        "4.157",
        "-0.336"
    ],
    [
        "-176",
        "-29",
        "-38.126",
        "26.161",
        "5.986",
        "3.778",
        "-0.410"
    ],
    [
        "-175",
        "-29",
        "-53.891",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-29",
        "-54.147",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-29",
        "-54.403",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-29",
        "-54.660",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-29",
        "-54.917",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-29",
        "-55.176",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-29",
        "-55.435",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-29",
        "-55.694",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-29",
        "-55.954",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-29",
        "-56.214",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-29",
        "-56.475",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-29",
        "-56.736",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-29",
        "-56.997",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-29",
        "-57.258",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-29",
        "-57.519",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-29",
        "-57.780",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-29",
        "-58.040",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-29",
        "-58.301",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-29",
        "-58.561",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-29",
        "-58.820",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-29",
        "-59.079",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-29",
        "-59.338",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-29",
        "-59.596",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-29",
        "-59.853",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-29",
        "-60.109",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-29",
        "-60.364",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-29",
        "-60.619",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-29",
        "-60.872",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-29",
        "-61.124",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-29",
        "-61.375",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-29",
        "-61.625",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-29",
        "-61.873",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-29",
        "-62.120",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-29",
        "-62.365",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-29",
        "-62.609",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-29",
        "-62.851",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-29",
        "-63.092",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-29",
        "-63.330",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-29",
        "-63.567",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-29",
        "-63.802",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-29",
        "-64.035",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-29",
        "-64.265",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-29",
        "-64.494",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-29",
        "-64.720",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-29",
        "-64.944",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-29",
        "-65.165",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-29",
        "-65.384",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-29",
        "-65.601",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-29",
        "-65.815",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-29",
        "-66.026",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-29",
        "-66.235",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-29",
        "-66.441",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-29",
        "-66.644",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-29",
        "-66.844",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-29",
        "-67.041",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-29",
        "-67.236",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-29",
        "-67.427",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-29",
        "-67.615",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-29",
        "-67.800",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-29",
        "-67.981",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-29",
        "-68.160",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-29",
        "-68.334",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-29",
        "-14.930",
        "8.959",
        "10.389",
        "13.155",
        "-0.149"
    ],
    [
        "-112",
        "-29",
        "50.226",
        "-26.823",
        "7.300",
        "10.018",
        "-0.048"
    ],
    [
        "-111",
        "-29",
        "68.344",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-29",
        "68.436",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-29",
        "68.520",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-29",
        "68.597",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-29",
        "68.666",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-29",
        "68.729",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-29",
        "68.784",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-29",
        "68.832",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-29",
        "68.872",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-29",
        "68.905",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-29",
        "68.930",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-29",
        "68.949",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-29",
        "68.960",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-29",
        "68.964",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-29",
        "68.959",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-29",
        "68.949",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-29",
        "68.929",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-29",
        "68.904",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-29",
        "68.870",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-29",
        "68.830",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-29",
        "68.781",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-29",
        "68.726",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-29",
        "68.663",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-29",
        "68.594",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-29",
        "68.516",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-29",
        "68.432",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-29",
        "68.340",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-29",
        "68.242",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-29",
        "68.136",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-29",
        "68.022",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-29",
        "67.902",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-29",
        "67.775",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-29",
        "67.641",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-29",
        "67.499",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-29",
        "67.351",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-29",
        "67.195",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-29",
        "67.034",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-29",
        "66.865",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-29",
        "66.145",
        "21.564",
        "1.962",
        "1.956",
        "-0.043"
    ],
    [
        "-72",
        "-29",
        "42.947",
        "19.085",
        "8.896",
        "4.121",
        "0.116"
    ],
    [
        "-71",
        "-29",
        "20.077",
        "15.804",
        "2.899",
        "2.702",
        "0.396"
    ],
    [
        "-70",
        "-29",
        "17.958",
        "15.937",
        "1.561",
        "1.661",
        "-0.099"
    ],
    [
        "-69",
        "-29",
        "9.984",
        "13.605",
        "2.717",
        "1.569",
        "0.024"
    ],
    [
        "-68",
        "-29",
        "6.324",
        "12.497",
        "1.812",
        "1.391",
        "-0.225"
    ],
    [
        "-67",
        "-29",
        "5.009",
        "11.951",
        "1.738",
        "1.444",
        "-0.111"
    ],
    [
        "-66",
        "-29",
        "3.687",
        "11.682",
        "1.879",
        "1.650",
        "-0.152"
    ],
    [
        "-65",
        "-29",
        "2.632",
        "12.293",
        "1.457",
        "1.602",
        "-0.120"
    ],
    [
        "-64",
        "-29",
        "0.891",
        "10.380",
        "1.188",
        "1.417",
        "-0.046"
    ],
    [
        "-63",
        "-29",
        "0.305",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-29",
        "0.218",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-29",
        "0.130",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-29",
        "0.041",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-29",
        "-0.048",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-29",
        "-0.140",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-29",
        "-0.232",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-29",
        "-0.324",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-29",
        "-0.418",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-29",
        "-0.512",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-29",
        "-0.607",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-29",
        "-0.703",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-29",
        "-0.800",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-29",
        "-0.898",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-29",
        "-0.996",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-29",
        "-1.095",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-29",
        "-1.195",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-29",
        "-1.295",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-29",
        "-1.395",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-29",
        "-1.495",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-29",
        "-1.597",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-29",
        "-1.699",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-29",
        "-1.802",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-29",
        "-1.905",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-29",
        "-2.008",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-29",
        "-2.112",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-29",
        "-2.215",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-29",
        "-2.320",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-29",
        "-2.425",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-29",
        "-2.529",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-29",
        "-2.634",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-29",
        "-2.739",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-29",
        "-2.844",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-29",
        "-2.950",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-29",
        "-3.055",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-29",
        "-3.160",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-29",
        "-3.266",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-29",
        "-3.370",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-29",
        "-3.476",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-29",
        "-3.581",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-29",
        "-3.686",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-29",
        "-3.791",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-29",
        "-3.895",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-29",
        "-4.000",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-29",
        "-4.104",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-29",
        "-4.208",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-29",
        "-4.311",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-29",
        "-4.414",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-29",
        "-4.517",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-29",
        "-4.619",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-29",
        "23.511",
        "18.336",
        "3.147",
        "2.752",
        "0.288"
    ],
    [
        "-12",
        "-29",
        "24.638",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-29",
        "24.483",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-29",
        "24.328",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-29",
        "24.172",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-29",
        "24.015",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-29",
        "23.856",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-29",
        "23.698",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-29",
        "23.539",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-29",
        "23.378",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-29",
        "23.217",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-29",
        "23.055",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-29",
        "22.893",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-29",
        "22.730",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-29",
        "22.566",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-29",
        "22.403",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-29",
        "22.239",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-29",
        "22.074",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-29",
        "21.909",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-29",
        "21.745",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-29",
        "21.580",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-29",
        "21.414",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-29",
        "21.248",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-29",
        "21.083",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-29",
        "20.918",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-29",
        "20.752",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-29",
        "20.587",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-29",
        "20.421",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-29",
        "20.257",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-29",
        "20.093",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-29",
        "19.928",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-29",
        "19.764",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-29",
        "19.601",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-29",
        "19.438",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-29",
        "19.275",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-29",
        "19.113",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-29",
        "18.952",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-29",
        "18.791",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-29",
        "18.631",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-29",
        "18.472",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-29",
        "18.314",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-29",
        "18.237",
        "18.583",
        "0.675",
        "0.733",
        "0.019"
    ],
    [
        "29",
        "-29",
        "18.167",
        "18.464",
        "0.764",
        "0.814",
        "0.009"
    ],
    [
        "30",
        "-29",
        "18.114",
        "18.322",
        "0.853",
        "0.822",
        "0.008"
    ],
    [
        "31",
        "-29",
        "18.096",
        "18.151",
        "0.823",
        "0.866",
        "0.001"
    ],
    [
        "32",
        "-29",
        "18.154",
        "17.900",
        "0.804",
        "0.794",
        "-0.006"
    ],
    [
        "33",
        "-29",
        "18.068",
        "17.728",
        "0.901",
        "0.875",
        "-0.016"
    ],
    [
        "34",
        "-29",
        "18.027",
        "17.498",
        "0.885",
        "0.947",
        "-0.033"
    ],
    [
        "35",
        "-29",
        "18.020",
        "17.244",
        "0.906",
        "0.982",
        "-0.036"
    ],
    [
        "36",
        "-29",
        "18.027",
        "16.986",
        "0.965",
        "0.982",
        "-0.032"
    ],
    [
        "37",
        "-29",
        "18.045",
        "16.728",
        "0.934",
        "1.025",
        "-0.029"
    ],
    [
        "38",
        "-29",
        "18.068",
        "16.470",
        "0.940",
        "1.036",
        "-0.026"
    ],
    [
        "39",
        "-29",
        "18.095",
        "16.215",
        "0.984",
        "1.016",
        "-0.022"
    ],
    [
        "40",
        "-29",
        "18.126",
        "15.965",
        "0.939",
        "1.041",
        "-0.020"
    ],
    [
        "41",
        "-29",
        "18.162",
        "15.720",
        "0.930",
        "1.034",
        "-0.016"
    ],
    [
        "42",
        "-29",
        "18.201",
        "15.484",
        "0.958",
        "0.996",
        "-0.013"
    ],
    [
        "43",
        "-29",
        "18.246",
        "15.258",
        "0.891",
        "0.999",
        "-0.009"
    ],
    [
        "44",
        "-29",
        "18.296",
        "15.045",
        "0.854",
        "0.964",
        "-0.005"
    ],
    [
        "45",
        "-29",
        "18.351",
        "14.848",
        "0.842",
        "0.877",
        "-0.002"
    ],
    [
        "46",
        "-29",
        "18.413",
        "14.668",
        "0.669",
        "0.772",
        "0.000"
    ],
    [
        "47",
        "-29",
        "18.443",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-29",
        "18.321",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-29",
        "18.202",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-29",
        "18.084",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-29",
        "17.968",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-29",
        "17.856",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-29",
        "17.745",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-29",
        "17.636",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-29",
        "17.529",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-29",
        "17.425",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-29",
        "17.323",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-29",
        "17.224",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-29",
        "17.126",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-29",
        "17.032",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-29",
        "16.347",
        "8.850",
        "1.776",
        "3.632",
        "-0.171"
    ],
    [
        "62",
        "-29",
        "14.068",
        "-0.805",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-29",
        "14.075",
        "-1.010",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-29",
        "14.084",
        "-1.216",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-29",
        "14.096",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-29",
        "14.108",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-29",
        "14.123",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-29",
        "14.139",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-29",
        "14.158",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-29",
        "14.178",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-29",
        "14.199",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-29",
        "14.222",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-29",
        "14.247",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-29",
        "14.273",
        "-3.230",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-29",
        "23.383",
        "10.355",
        "10.278",
        "11.715",
        "0.341"
    ],
    [
        "76",
        "-29",
        "36.178",
        "23.687",
        "9.465",
        "11.848",
        "0.237"
    ],
    [
        "77",
        "-29",
        "45.862",
        "30.680",
        "7.009",
        "8.193",
        "0.234"
    ],
    [
        "78",
        "-29",
        "50.245",
        "34.235",
        "4.111",
        "4.522",
        "0.099"
    ],
    [
        "79",
        "-29",
        "51.327",
        "35.492",
        "2.312",
        "2.396",
        "0.010"
    ],
    [
        "80",
        "-29",
        "51.457",
        "35.977",
        "1.905",
        "1.816",
        "0.028"
    ],
    [
        "81",
        "-29",
        "51.471",
        "36.810",
        "1.862",
        "1.722",
        "0.039"
    ],
    [
        "82",
        "-29",
        "51.535",
        "37.905",
        "1.731",
        "1.775",
        "0.041"
    ],
    [
        "83",
        "-29",
        "51.607",
        "39.008",
        "1.674",
        "1.747",
        "0.029"
    ],
    [
        "84",
        "-29",
        "51.663",
        "40.092",
        "1.679",
        "1.634",
        "0.018"
    ],
    [
        "85",
        "-29",
        "51.723",
        "41.332",
        "1.452",
        "1.607",
        "0.019"
    ],
    [
        "86",
        "-29",
        "51.862",
        "43.230",
        "0.949",
        "1.049",
        "0.001"
    ],
    [
        "87",
        "-29",
        "51.690",
        "44.341",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-29",
        "51.311",
        "44.970",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-29",
        "50.929",
        "45.587",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-29",
        "50.540",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-29",
        "50.147",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-29",
        "49.749",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-29",
        "49.346",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-29",
        "48.938",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-29",
        "48.525",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-29",
        "48.109",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-29",
        "47.688",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-29",
        "47.263",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-29",
        "46.833",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-29",
        "46.401",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-29",
        "45.963",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-29",
        "45.523",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-29",
        "45.079",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-29",
        "44.632",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-29",
        "44.181",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-29",
        "43.727",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-29",
        "43.270",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-29",
        "42.811",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-29",
        "42.349",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-29",
        "41.884",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-29",
        "41.417",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-29",
        "40.948",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-29",
        "40.476",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-29",
        "40.003",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-29",
        "39.527",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-29",
        "39.050",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-29",
        "38.572",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-29",
        "38.092",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-29",
        "37.611",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-29",
        "37.128",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-29",
        "36.644",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-29",
        "36.161",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-29",
        "35.676",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-29",
        "35.191",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-29",
        "34.705",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-29",
        "34.220",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-29",
        "33.733",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-29",
        "33.247",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-29",
        "32.762",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-29",
        "32.277",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-29",
        "31.792",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-29",
        "31.309",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-29",
        "30.826",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-29",
        "30.343",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-29",
        "29.862",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-29",
        "29.382",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-29",
        "28.904",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-29",
        "28.427",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-29",
        "27.952",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-29",
        "27.478",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-29",
        "27.007",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-29",
        "26.538",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-29",
        "26.071",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-29",
        "25.606",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-29",
        "25.145",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-29",
        "24.685",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-29",
        "24.229",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-29",
        "23.776",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-29",
        "23.325",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-29",
        "22.878",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-29",
        "22.434",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-29",
        "21.994",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-29",
        "21.558",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-29",
        "21.125",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-29",
        "20.696",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-29",
        "20.271",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-29",
        "19.851",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-29",
        "19.435",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-29",
        "19.023",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-29",
        "18.616",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-29",
        "18.213",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-29",
        "17.816",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-29",
        "17.422",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-29",
        "17.035",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-29",
        "16.652",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-29",
        "16.274",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-29",
        "15.903",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-29",
        "15.536",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-29",
        "15.176",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-29",
        "14.821",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-29",
        "14.471",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-29",
        "14.128",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-29",
        "13.791",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-29",
        "13.460",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-29",
        "13.135",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-29",
        "12.816",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-29",
        "12.505",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-29",
        "12.199",
        "35.706",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-29",
        "11.900",
        "34.916",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-28",
        "12.641",
        "34.115",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-28",
        "9.045",
        "31.672",
        "5.070",
        "3.177",
        "0.027"
    ],
    [
        "-178",
        "-28",
        "2.052",
        "26.665",
        "6.184",
        "4.238",
        "-0.064"
    ],
    [
        "-177",
        "-28",
        "-10.879",
        "23.484",
        "7.596",
        "4.394",
        "-0.247"
    ],
    [
        "-176",
        "-28",
        "-30.608",
        "24.374",
        "7.140",
        "4.184",
        "-0.332"
    ],
    [
        "-175",
        "-28",
        "-54.539",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-28",
        "-54.786",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-28",
        "-55.034",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-28",
        "-55.283",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-28",
        "-55.532",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-28",
        "-55.782",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-28",
        "-56.033",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-28",
        "-56.284",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-28",
        "-56.536",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-28",
        "-56.788",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-28",
        "-57.041",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-28",
        "-57.293",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-28",
        "-57.546",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-28",
        "-57.799",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-28",
        "-58.051",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-28",
        "-58.304",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-28",
        "-58.556",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-28",
        "-58.808",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-28",
        "-59.060",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-28",
        "-59.312",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-28",
        "-59.562",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-28",
        "-59.813",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-28",
        "-60.062",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-28",
        "-60.311",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-28",
        "-60.559",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-28",
        "-60.807",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-28",
        "-61.053",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-28",
        "-61.298",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-28",
        "-61.543",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-28",
        "-61.786",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-28",
        "-62.027",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-28",
        "-62.268",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-28",
        "-62.507",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-28",
        "-62.745",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-28",
        "-62.981",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-28",
        "-63.215",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-28",
        "-63.448",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-28",
        "-63.679",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-28",
        "-63.908",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-28",
        "-64.136",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-28",
        "-64.361",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-28",
        "-64.584",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-28",
        "-64.805",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-28",
        "-65.025",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-28",
        "-65.241",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-28",
        "-65.456",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-28",
        "-65.668",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-28",
        "-65.878",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-28",
        "-66.085",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-28",
        "-66.290",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-28",
        "-66.492",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-28",
        "-66.691",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-28",
        "-66.888",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-28",
        "-67.082",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-28",
        "-67.273",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-28",
        "-67.461",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-28",
        "-67.646",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-28",
        "-67.828",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-28",
        "-68.007",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-28",
        "-68.183",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-28",
        "-68.355",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-28",
        "-33.278",
        "27.625",
        "11.549",
        "15.498",
        "0.367"
    ],
    [
        "-113",
        "-28",
        "23.453",
        "-0.599",
        "10.762",
        "15.052",
        "0.176"
    ],
    [
        "-112",
        "-28",
        "67.928",
        "-12.070",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-28",
        "68.024",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-28",
        "68.112",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-28",
        "68.194",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-28",
        "68.268",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-28",
        "68.335",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-28",
        "68.396",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-28",
        "68.449",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-28",
        "68.496",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-28",
        "68.534",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-28",
        "68.566",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-28",
        "68.592",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-28",
        "68.609",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-28",
        "68.619",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-28",
        "68.623",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-28",
        "68.619",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-28",
        "68.608",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-28",
        "68.590",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-28",
        "68.565",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-28",
        "68.533",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-28",
        "68.493",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-28",
        "68.446",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-28",
        "68.393",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-28",
        "68.333",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-28",
        "68.265",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-28",
        "68.190",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-28",
        "68.108",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-28",
        "68.019",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-28",
        "67.924",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-28",
        "67.821",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-28",
        "67.711",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-28",
        "67.595",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-28",
        "67.472",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-28",
        "67.341",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-28",
        "67.205",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-28",
        "67.061",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-28",
        "66.911",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-28",
        "66.755",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-28",
        "66.590",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-28",
        "66.421",
        "21.137",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-28",
        "50.327",
        "21.356",
        "8.412",
        "4.176",
        "0.102"
    ],
    [
        "-71",
        "-28",
        "22.964",
        "17.800",
        "3.116",
        "2.256",
        "0.022"
    ],
    [
        "-70",
        "-28",
        "19.188",
        "15.598",
        "3.148",
        "2.099",
        "0.085"
    ],
    [
        "-69",
        "-28",
        "11.734",
        "14.715",
        "3.099",
        "1.737",
        "-0.006"
    ],
    [
        "-68",
        "-28",
        "5.881",
        "12.587",
        "1.813",
        "1.451",
        "-0.060"
    ],
    [
        "-67",
        "-28",
        "3.479",
        "12.038",
        "1.483",
        "1.358",
        "-0.196"
    ],
    [
        "-66",
        "-28",
        "2.180",
        "11.543",
        "1.567",
        "1.520",
        "-0.263"
    ],
    [
        "-65",
        "-28",
        "1.100",
        "11.174",
        "1.106",
        "1.363",
        "-0.166"
    ],
    [
        "-64",
        "-28",
        "1.068",
        "10.847",
        "0.696",
        "0.867",
        "-0.094"
    ],
    [
        "-63",
        "-28",
        "0.167",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-28",
        "0.082",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-28",
        "-0.003",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-28",
        "-0.089",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-28",
        "-0.176",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-28",
        "-0.265",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-28",
        "-0.354",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-28",
        "-0.443",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-28",
        "-0.534",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-28",
        "-0.625",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-28",
        "-0.717",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-28",
        "-0.810",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-28",
        "-0.904",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-28",
        "-0.999",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-28",
        "-1.093",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-28",
        "-1.189",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-28",
        "-1.286",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-28",
        "-1.382",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-28",
        "-1.480",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-28",
        "-1.577",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-28",
        "-1.676",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-28",
        "-1.775",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-28",
        "-1.874",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-28",
        "-1.974",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-28",
        "-2.074",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-28",
        "-2.175",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-28",
        "-2.275",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-28",
        "-2.376",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-28",
        "-2.477",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-28",
        "-2.579",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-28",
        "-2.680",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-28",
        "-2.782",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-28",
        "-2.883",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-28",
        "-2.986",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-28",
        "-3.087",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-28",
        "-3.189",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-28",
        "-3.291",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-28",
        "-3.393",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-28",
        "-3.495",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-28",
        "-3.597",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-28",
        "-3.699",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-28",
        "-3.799",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-28",
        "-3.901",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-28",
        "-4.002",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-28",
        "-4.103",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-28",
        "-4.203",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-28",
        "-4.304",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-28",
        "-4.403",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-28",
        "-4.503",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-28",
        "-4.603",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-28",
        "24.876",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-28",
        "24.728",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-28",
        "24.578",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-28",
        "24.428",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-28",
        "24.277",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-28",
        "24.124",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-28",
        "23.972",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-28",
        "23.818",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-28",
        "23.663",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-28",
        "23.507",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-28",
        "23.352",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-28",
        "23.196",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-28",
        "23.038",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-28",
        "22.881",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-28",
        "22.722",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-28",
        "22.564",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-28",
        "22.405",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-28",
        "22.245",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-28",
        "22.086",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-28",
        "21.926",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-28",
        "21.766",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-28",
        "21.606",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-28",
        "21.446",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-28",
        "21.286",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-28",
        "21.126",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-28",
        "20.965",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-28",
        "20.805",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-28",
        "20.645",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-28",
        "20.485",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-28",
        "20.326",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-28",
        "20.167",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-28",
        "20.008",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-28",
        "19.850",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-28",
        "19.692",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-28",
        "19.535",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-28",
        "19.378",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-28",
        "19.222",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-28",
        "19.066",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-28",
        "18.911",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-28",
        "18.756",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-28",
        "18.644",
        "18.685",
        "0.628",
        "0.597",
        "0.028"
    ],
    [
        "28",
        "-28",
        "18.586",
        "18.581",
        "0.721",
        "0.784",
        "-0.010"
    ],
    [
        "29",
        "-28",
        "18.545",
        "18.450",
        "0.801",
        "0.861",
        "0.005"
    ],
    [
        "30",
        "-28",
        "18.520",
        "18.291",
        "0.889",
        "0.872",
        "0.008"
    ],
    [
        "31",
        "-28",
        "18.515",
        "18.104",
        "0.866",
        "0.918",
        "0.008"
    ],
    [
        "32",
        "-28",
        "18.510",
        "17.902",
        "0.881",
        "0.917",
        "-0.003"
    ],
    [
        "33",
        "-28",
        "18.488",
        "17.713",
        "0.942",
        "0.922",
        "-0.022"
    ],
    [
        "34",
        "-28",
        "18.470",
        "17.486",
        "0.917",
        "0.979",
        "-0.026"
    ],
    [
        "35",
        "-28",
        "18.465",
        "17.242",
        "0.930",
        "1.003",
        "-0.027"
    ],
    [
        "36",
        "-28",
        "18.472",
        "16.992",
        "0.982",
        "0.995",
        "-0.026"
    ],
    [
        "37",
        "-28",
        "18.487",
        "16.740",
        "0.947",
        "1.034",
        "-0.025"
    ],
    [
        "38",
        "-28",
        "18.509",
        "16.488",
        "0.950",
        "1.042",
        "-0.022"
    ],
    [
        "39",
        "-28",
        "18.536",
        "16.237",
        "0.991",
        "1.020",
        "-0.019"
    ],
    [
        "40",
        "-28",
        "18.569",
        "15.991",
        "0.944",
        "1.043",
        "-0.016"
    ],
    [
        "41",
        "-28",
        "18.606",
        "15.748",
        "0.934",
        "1.035",
        "-0.013"
    ],
    [
        "42",
        "-28",
        "18.647",
        "15.514",
        "0.961",
        "0.995",
        "-0.010"
    ],
    [
        "43",
        "-28",
        "18.693",
        "15.288",
        "0.894",
        "0.998",
        "-0.007"
    ],
    [
        "44",
        "-28",
        "18.743",
        "15.072",
        "0.856",
        "0.962",
        "-0.004"
    ],
    [
        "45",
        "-28",
        "18.800",
        "14.870",
        "0.843",
        "0.876",
        "-0.001"
    ],
    [
        "46",
        "-28",
        "18.861",
        "14.678",
        "0.670",
        "0.772",
        "0.000"
    ],
    [
        "47",
        "-28",
        "18.888",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-28",
        "18.770",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-28",
        "18.655",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-28",
        "18.542",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-28",
        "18.430",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-28",
        "18.320",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-28",
        "18.213",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-28",
        "18.107",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-28",
        "18.004",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-28",
        "17.903",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-28",
        "17.804",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-28",
        "17.708",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-28",
        "17.614",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-28",
        "17.522",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-28",
        "17.433",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-28",
        "17.346",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-28",
        "17.262",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-28",
        "14.728",
        "-0.483",
        "0.573",
        "1.364",
        "-0.131"
    ],
    [
        "65",
        "-28",
        "14.463",
        "-1.419",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-28",
        "14.475",
        "-1.623",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-28",
        "14.490",
        "-1.826",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-28",
        "14.505",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-28",
        "14.523",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-28",
        "14.543",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-28",
        "14.563",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-28",
        "14.585",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-28",
        "14.609",
        "-3.032",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-28",
        "17.746",
        "2.240",
        "1.943",
        "3.715",
        "0.248"
    ],
    [
        "75",
        "-28",
        "38.974",
        "26.858",
        "8.207",
        "8.781",
        "0.440"
    ],
    [
        "76",
        "-28",
        "47.395",
        "32.092",
        "6.062",
        "6.819",
        "0.352"
    ],
    [
        "77",
        "-28",
        "50.861",
        "34.291",
        "4.014",
        "4.345",
        "0.372"
    ],
    [
        "78",
        "-28",
        "51.884",
        "35.392",
        "2.445",
        "2.438",
        "0.210"
    ],
    [
        "79",
        "-28",
        "51.984",
        "35.974",
        "1.653",
        "1.620",
        "0.034"
    ],
    [
        "80",
        "-28",
        "51.846",
        "36.369",
        "1.496",
        "1.517",
        "0.003"
    ],
    [
        "81",
        "-28",
        "51.675",
        "36.995",
        "1.590",
        "1.500",
        "0.012"
    ],
    [
        "82",
        "-28",
        "51.555",
        "37.799",
        "1.522",
        "1.617",
        "0.042"
    ],
    [
        "83",
        "-28",
        "51.473",
        "38.679",
        "1.531",
        "1.641",
        "0.029"
    ],
    [
        "84",
        "-28",
        "51.390",
        "39.576",
        "1.565",
        "1.512",
        "0.013"
    ],
    [
        "85",
        "-28",
        "51.309",
        "40.623",
        "1.437",
        "1.489",
        "0.004"
    ],
    [
        "86",
        "-28",
        "51.297",
        "42.052",
        "1.385",
        "1.444",
        "0.011"
    ],
    [
        "87",
        "-28",
        "51.294",
        "43.533",
        "1.449",
        "1.386",
        "-0.012"
    ],
    [
        "88",
        "-28",
        "51.092",
        "44.718",
        "1.268",
        "1.424",
        "-0.015"
    ],
    [
        "89",
        "-28",
        "50.717",
        "45.587",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "-28",
        "50.341",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-28",
        "49.960",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-28",
        "49.574",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-28",
        "49.184",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-28",
        "48.789",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-28",
        "48.390",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-28",
        "47.987",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-28",
        "47.579",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-28",
        "47.167",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-28",
        "46.751",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-28",
        "46.332",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-28",
        "45.909",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-28",
        "45.482",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-28",
        "45.052",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-28",
        "44.619",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-28",
        "44.183",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-28",
        "43.743",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-28",
        "43.301",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-28",
        "42.856",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-28",
        "42.408",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-28",
        "41.958",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-28",
        "41.506",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-28",
        "41.051",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-28",
        "40.594",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-28",
        "40.136",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-28",
        "39.676",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-28",
        "39.214",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-28",
        "38.751",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-28",
        "38.286",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-28",
        "37.820",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-28",
        "37.353",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-28",
        "36.885",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-28",
        "36.416",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-28",
        "35.946",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-28",
        "35.477",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-28",
        "35.007",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-28",
        "34.536",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-28",
        "34.066",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-28",
        "33.595",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-28",
        "33.125",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-28",
        "32.655",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-28",
        "32.186",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-28",
        "31.718",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-28",
        "31.249",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-28",
        "30.783",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-28",
        "30.316",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-28",
        "29.852",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-28",
        "29.389",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-28",
        "28.927",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-28",
        "28.467",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-28",
        "28.008",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-28",
        "27.552",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-28",
        "27.098",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-28",
        "26.646",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-28",
        "26.195",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-28",
        "25.749",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-28",
        "25.303",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-28",
        "24.862",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-28",
        "24.422",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-28",
        "23.987",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-28",
        "23.553",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-28",
        "23.124",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-28",
        "22.698",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-28",
        "22.275",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-28",
        "21.856",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-28",
        "21.440",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-28",
        "21.030",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-28",
        "20.622",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-28",
        "20.219",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-28",
        "19.821",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-28",
        "19.426",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-28",
        "19.036",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-28",
        "18.651",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-28",
        "18.270",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-28",
        "17.895",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-28",
        "17.524",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-28",
        "17.159",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-28",
        "16.799",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-28",
        "16.444",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-28",
        "16.095",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-28",
        "15.751",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-28",
        "15.413",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-28",
        "15.080",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-28",
        "14.755",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-28",
        "14.434",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-28",
        "14.119",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-28",
        "13.810",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-28",
        "13.508",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-28",
        "13.213",
        "35.706",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-28",
        "12.923",
        "34.916",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-27",
        "13.669",
        "34.115",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-27",
        "12.392",
        "32.155",
        "3.556",
        "2.860",
        "-0.013"
    ],
    [
        "-178",
        "-27",
        "7.286",
        "26.668",
        "6.047",
        "4.495",
        "-0.055"
    ],
    [
        "-177",
        "-27",
        "-3.866",
        "22.653",
        "8.502",
        "4.982",
        "-0.221"
    ],
    [
        "-176",
        "-27",
        "-24.014",
        "22.907",
        "8.724",
        "5.170",
        "-0.292"
    ],
    [
        "-175",
        "-27",
        "-49.557",
        "28.919",
        "5.837",
        "4.133",
        "-0.388"
    ],
    [
        "-174",
        "-27",
        "-55.408",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-27",
        "-55.648",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-27",
        "-55.889",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-27",
        "-56.130",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-27",
        "-56.372",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-27",
        "-56.615",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-27",
        "-56.858",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-27",
        "-57.101",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-27",
        "-57.345",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-27",
        "-57.589",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-27",
        "-57.833",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-27",
        "-58.077",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-27",
        "-58.322",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-27",
        "-58.566",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-27",
        "-58.810",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-27",
        "-59.054",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-27",
        "-59.298",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-27",
        "-59.542",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-27",
        "-59.785",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-27",
        "-60.027",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-27",
        "-60.269",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-27",
        "-60.511",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-27",
        "-60.752",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-27",
        "-60.992",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-27",
        "-61.231",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-27",
        "-61.469",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-27",
        "-61.706",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-27",
        "-61.942",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-27",
        "-62.177",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-27",
        "-62.411",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-27",
        "-62.644",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-27",
        "-62.875",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-27",
        "-63.105",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-27",
        "-63.333",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-27",
        "-63.560",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-27",
        "-63.785",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-27",
        "-64.008",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-27",
        "-64.230",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-27",
        "-64.450",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-27",
        "-64.668",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-27",
        "-64.884",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-27",
        "-65.098",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-27",
        "-65.309",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-27",
        "-65.519",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-27",
        "-65.727",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-27",
        "-65.932",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-27",
        "-66.135",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-27",
        "-66.335",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-27",
        "-66.533",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-27",
        "-66.728",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-27",
        "-66.921",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-27",
        "-67.111",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-27",
        "-67.299",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-27",
        "-67.483",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-27",
        "-67.665",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-27",
        "-67.844",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-27",
        "-68.020",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-27",
        "-68.194",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-27",
        "-68.364",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-27",
        "-62.976",
        "23.395",
        "3.061",
        "5.624",
        "0.246"
    ],
    [
        "-114",
        "-27",
        "-7.662",
        "24.889",
        "14.102",
        "15.492",
        "0.309"
    ],
    [
        "-113",
        "-27",
        "38.719",
        "9.067",
        "12.055",
        "15.908",
        "0.201"
    ],
    [
        "-112",
        "-27",
        "67.590",
        "-12.070",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-27",
        "67.683",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-27",
        "67.768",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-27",
        "67.846",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-27",
        "67.918",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-27",
        "67.984",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-27",
        "68.042",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-27",
        "68.094",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-27",
        "68.138",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-27",
        "68.176",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-27",
        "68.207",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-27",
        "68.231",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-27",
        "68.248",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-27",
        "68.258",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-27",
        "68.261",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-27",
        "68.258",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-27",
        "68.247",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-27",
        "68.230",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-27",
        "68.205",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-27",
        "68.174",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-27",
        "68.136",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-27",
        "68.091",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-27",
        "68.039",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-27",
        "67.981",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-27",
        "67.915",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-27",
        "67.843",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-27",
        "67.764",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-27",
        "67.678",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-27",
        "67.585",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-27",
        "67.486",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-27",
        "67.380",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-27",
        "67.268",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-27",
        "67.148",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-27",
        "67.022",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-27",
        "66.890",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-27",
        "66.752",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-27",
        "66.606",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-27",
        "66.454",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-27",
        "66.296",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-27",
        "66.132",
        "21.137",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-27",
        "65.961",
        "21.924",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-27",
        "28.093",
        "18.934",
        "2.680",
        "1.991",
        "0.178"
    ],
    [
        "-70",
        "-27",
        "19.706",
        "15.104",
        "2.378",
        "2.143",
        "-0.036"
    ],
    [
        "-69",
        "-27",
        "12.951",
        "16.145",
        "2.555",
        "1.548",
        "-0.020"
    ],
    [
        "-68",
        "-27",
        "7.043",
        "13.226",
        "1.941",
        "1.613",
        "-0.077"
    ],
    [
        "-67",
        "-27",
        "2.677",
        "12.162",
        "1.594",
        "1.398",
        "-0.198"
    ],
    [
        "-66",
        "-27",
        "1.810",
        "11.295",
        "1.245",
        "1.275",
        "-0.292"
    ],
    [
        "-65",
        "-27",
        "0.840",
        "9.934",
        "0.901",
        "1.005",
        "-0.238"
    ],
    [
        "-64",
        "-27",
        "0.108",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-27",
        "0.028",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-27",
        "-0.054",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-27",
        "-0.136",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-27",
        "-0.219",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-27",
        "-0.304",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-27",
        "-0.389",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-27",
        "-0.475",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-27",
        "-0.562",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-27",
        "-0.650",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-27",
        "-0.738",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-27",
        "-0.828",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-27",
        "-0.917",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-27",
        "-1.007",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-27",
        "-1.099",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-27",
        "-1.191",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-27",
        "-1.284",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-27",
        "-1.377",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-27",
        "-1.470",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-27",
        "-1.564",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-27",
        "-1.659",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-27",
        "-1.754",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-27",
        "-1.850",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-27",
        "-1.946",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-27",
        "-2.042",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-27",
        "-2.139",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-27",
        "-2.235",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-27",
        "-2.333",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-27",
        "-2.431",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-27",
        "-2.528",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-27",
        "-2.626",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-27",
        "-2.725",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-27",
        "-2.823",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-27",
        "-2.922",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-27",
        "-3.021",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-27",
        "-3.118",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-27",
        "-3.218",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-27",
        "-3.316",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-27",
        "-3.415",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-27",
        "-3.513",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-27",
        "-3.612",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-27",
        "-3.710",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-27",
        "-3.808",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-27",
        "-3.906",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-27",
        "-4.004",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-27",
        "-4.101",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-27",
        "-4.198",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-27",
        "-4.295",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-27",
        "-4.392",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-27",
        "-4.488",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-27",
        "-4.584",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-27",
        "24.954",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-27",
        "24.811",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-27",
        "24.666",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-27",
        "24.521",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-27",
        "24.374",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-27",
        "24.227",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-27",
        "24.079",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-27",
        "23.930",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-27",
        "23.781",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-27",
        "23.631",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-27",
        "23.480",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-27",
        "23.328",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-27",
        "23.176",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-27",
        "23.024",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-27",
        "22.871",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-27",
        "22.718",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-27",
        "22.564",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-27",
        "22.410",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-27",
        "22.256",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-27",
        "22.101",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-27",
        "21.947",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-27",
        "21.791",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-27",
        "21.637",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-27",
        "21.481",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-27",
        "21.327",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-27",
        "21.172",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-27",
        "21.017",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-27",
        "20.862",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-27",
        "20.708",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-27",
        "20.554",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-27",
        "20.400",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-27",
        "20.246",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-27",
        "20.094",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-27",
        "19.941",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-27",
        "19.789",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-27",
        "19.637",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-27",
        "19.486",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-27",
        "19.335",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-27",
        "19.185",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-27",
        "19.036",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-27",
        "18.966",
        "18.696",
        "0.674",
        "0.633",
        "0.004"
    ],
    [
        "28",
        "-27",
        "18.932",
        "18.594",
        "0.749",
        "0.812",
        "0.008"
    ],
    [
        "29",
        "-27",
        "18.900",
        "18.442",
        "0.817",
        "0.887",
        "0.012"
    ],
    [
        "30",
        "-27",
        "18.879",
        "18.274",
        "0.906",
        "0.901",
        "0.009"
    ],
    [
        "31",
        "-27",
        "18.867",
        "18.091",
        "0.889",
        "0.951",
        "0.006"
    ],
    [
        "32",
        "-27",
        "18.861",
        "17.898",
        "0.909",
        "0.966",
        "-0.002"
    ],
    [
        "33",
        "-27",
        "18.859",
        "17.696",
        "0.967",
        "0.957",
        "-0.012"
    ],
    [
        "34",
        "-27",
        "18.862",
        "17.476",
        "0.936",
        "1.003",
        "-0.018"
    ],
    [
        "35",
        "-27",
        "18.871",
        "17.242",
        "0.946",
        "1.020",
        "-0.020"
    ],
    [
        "36",
        "-27",
        "18.885",
        "16.998",
        "0.995",
        "1.008",
        "-0.020"
    ],
    [
        "37",
        "-27",
        "18.905",
        "16.753",
        "0.957",
        "1.042",
        "-0.020"
    ],
    [
        "38",
        "-27",
        "18.931",
        "16.505",
        "0.958",
        "1.048",
        "-0.018"
    ],
    [
        "39",
        "-27",
        "18.961",
        "16.258",
        "0.997",
        "1.024",
        "-0.016"
    ],
    [
        "40",
        "-27",
        "18.997",
        "16.014",
        "0.949",
        "1.045",
        "-0.014"
    ],
    [
        "41",
        "-27",
        "19.036",
        "15.773",
        "0.938",
        "1.036",
        "-0.011"
    ],
    [
        "42",
        "-27",
        "19.080",
        "15.539",
        "0.964",
        "0.994",
        "-0.008"
    ],
    [
        "43",
        "-27",
        "19.127",
        "15.311",
        "0.895",
        "0.996",
        "-0.005"
    ],
    [
        "44",
        "-27",
        "19.180",
        "15.093",
        "0.857",
        "0.960",
        "-0.002"
    ],
    [
        "45",
        "-27",
        "19.238",
        "14.885",
        "0.844",
        "0.873",
        "0.000"
    ],
    [
        "46",
        "-27",
        "19.300",
        "14.685",
        "0.671",
        "0.771",
        "0.000"
    ],
    [
        "47",
        "-27",
        "19.328",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-27",
        "19.214",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-27",
        "19.103",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-27",
        "18.993",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-27",
        "18.884",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-27",
        "18.779",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-27",
        "18.674",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-27",
        "18.573",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-27",
        "18.474",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-27",
        "18.376",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-27",
        "18.281",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-27",
        "18.187",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-27",
        "18.096",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-27",
        "18.008",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-27",
        "17.921",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-27",
        "17.837",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-27",
        "17.755",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-27",
        "17.676",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-27",
        "17.598",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-27",
        "17.524",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-27",
        "16.509",
        "5.649",
        "1.844",
        "3.307",
        "0.220"
    ],
    [
        "68",
        "-27",
        "14.867",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-27",
        "14.884",
        "-2.232",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-27",
        "14.903",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-27",
        "14.923",
        "-2.634",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-27",
        "14.944",
        "-2.834",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-27",
        "54.180",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-27",
        "53.919",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-27",
        "53.653",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-27",
        "53.383",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-27",
        "53.107",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-27",
        "52.827",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-27",
        "52.543",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-27",
        "52.254",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-27",
        "51.962",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-27",
        "51.652",
        "37.767",
        "0.936",
        "1.051",
        "-0.008"
    ],
    [
        "83",
        "-27",
        "51.415",
        "38.472",
        "1.360",
        "1.519",
        "0.011"
    ],
    [
        "84",
        "-27",
        "51.226",
        "39.207",
        "1.436",
        "1.370",
        "-0.006"
    ],
    [
        "85",
        "-27",
        "51.053",
        "40.031",
        "1.347",
        "1.327",
        "-0.037"
    ],
    [
        "86",
        "-27",
        "50.950",
        "41.181",
        "1.332",
        "1.352",
        "-0.038"
    ],
    [
        "87",
        "-27",
        "50.922",
        "42.782",
        "1.396",
        "1.373",
        "-0.041"
    ],
    [
        "88",
        "-27",
        "50.767",
        "44.585",
        "1.158",
        "1.301",
        "-0.017"
    ],
    [
        "89",
        "-27",
        "50.478",
        "45.566",
        "0.850",
        "0.967",
        "-0.037"
    ],
    [
        "90",
        "-27",
        "50.126",
        "46.190",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "-27",
        "49.758",
        "46.777",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "-27",
        "49.385",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-27",
        "49.007",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-27",
        "48.626",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-27",
        "48.239",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-27",
        "47.849",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-27",
        "47.455",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-27",
        "47.057",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-27",
        "46.655",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-27",
        "46.249",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-27",
        "45.840",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-27",
        "45.428",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-27",
        "45.012",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-27",
        "44.593",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-27",
        "44.171",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-27",
        "43.746",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-27",
        "43.318",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-27",
        "42.888",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-27",
        "42.456",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-27",
        "42.020",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-27",
        "41.583",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-27",
        "41.143",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-27",
        "40.701",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-27",
        "40.258",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-27",
        "39.813",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-27",
        "39.366",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-27",
        "38.918",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-27",
        "38.468",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-27",
        "38.018",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-27",
        "37.567",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-27",
        "37.114",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-27",
        "36.660",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-27",
        "36.206",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-27",
        "35.752",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-27",
        "35.297",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-27",
        "34.842",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-27",
        "34.388",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-27",
        "33.933",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-27",
        "33.478",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-27",
        "33.023",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-27",
        "32.570",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-27",
        "32.116",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-27",
        "31.664",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-27",
        "31.212",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-27",
        "30.762",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-27",
        "30.312",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-27",
        "29.864",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-27",
        "29.418",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-27",
        "28.973",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-27",
        "28.530",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-27",
        "28.089",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-27",
        "27.650",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-27",
        "27.212",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-27",
        "26.777",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-27",
        "26.345",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-27",
        "25.915",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-27",
        "25.487",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-27",
        "25.062",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-27",
        "24.641",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-27",
        "24.222",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-27",
        "23.807",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-27",
        "23.394",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-27",
        "22.986",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-27",
        "22.580",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-27",
        "22.179",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-27",
        "21.781",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-27",
        "21.387",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-27",
        "20.998",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-27",
        "20.612",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-27",
        "20.231",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-27",
        "19.854",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-27",
        "19.482",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-27",
        "19.114",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-27",
        "18.750",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-27",
        "18.392",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-27",
        "18.038",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-27",
        "17.690",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-27",
        "17.347",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-27",
        "17.009",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-27",
        "16.677",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-27",
        "16.350",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-27",
        "16.029",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-27",
        "15.712",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-27",
        "15.403",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-27",
        "15.098",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-27",
        "14.801",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-27",
        "14.508",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-27",
        "14.222",
        "35.706",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-27",
        "13.942",
        "34.916",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-26",
        "14.694",
        "34.115",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-26",
        "14.131",
        "32.447",
        "3.674",
        "2.776",
        "-0.075"
    ],
    [
        "-178",
        "-26",
        "12.273",
        "26.956",
        "6.804",
        "4.908",
        "-0.127"
    ],
    [
        "-177",
        "-26",
        "4.907",
        "21.753",
        "10.869",
        "6.313",
        "-0.300"
    ],
    [
        "-176",
        "-26",
        "-14.257",
        "20.392",
        "12.377",
        "7.315",
        "-0.329"
    ],
    [
        "-175",
        "-26",
        "-45.193",
        "27.069",
        "9.275",
        "6.828",
        "-0.465"
    ],
    [
        "-174",
        "-26",
        "-56.014",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-26",
        "-56.246",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-26",
        "-56.478",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-26",
        "-56.711",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-26",
        "-56.945",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-26",
        "-57.179",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-26",
        "-57.413",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-26",
        "-57.648",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-26",
        "-57.884",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-26",
        "-58.119",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-26",
        "-58.355",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-26",
        "-58.591",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-26",
        "-58.827",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-26",
        "-59.063",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-26",
        "-59.299",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-26",
        "-59.535",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-26",
        "-59.770",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-26",
        "-60.005",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-26",
        "-60.240",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-26",
        "-60.474",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-26",
        "-60.708",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-26",
        "-60.941",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-26",
        "-61.173",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-26",
        "-61.405",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-26",
        "-61.636",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-26",
        "-61.866",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-26",
        "-62.095",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-26",
        "-62.323",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-26",
        "-62.550",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-26",
        "-62.776",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-26",
        "-63.000",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-26",
        "-63.224",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-26",
        "-63.446",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-26",
        "-63.666",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-26",
        "-63.885",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-26",
        "-64.102",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-26",
        "-64.318",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-26",
        "-64.532",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-26",
        "-64.744",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-26",
        "-64.955",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-26",
        "-65.163",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-26",
        "-65.370",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-26",
        "-65.574",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-26",
        "-65.777",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-26",
        "-65.977",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-26",
        "-66.175",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-26",
        "-66.371",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-26",
        "-66.565",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-26",
        "-66.756",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-26",
        "-66.945",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-26",
        "-67.131",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-26",
        "-67.314",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-26",
        "-67.495",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-26",
        "-67.674",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-26",
        "-67.849",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-26",
        "-68.022",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-26",
        "-68.192",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-26",
        "-68.359",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-26",
        "-68.523",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-26",
        "-32.315",
        "21.543",
        "13.033",
        "12.845",
        "0.162"
    ],
    [
        "-114",
        "-26",
        "3.851",
        "23.742",
        "15.549",
        "14.316",
        "0.278"
    ],
    [
        "-113",
        "-26",
        "36.553",
        "10.694",
        "12.994",
        "15.140",
        "0.206"
    ],
    [
        "-112",
        "-26",
        "67.231",
        "-12.070",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-26",
        "67.320",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-26",
        "67.403",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-26",
        "67.478",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-26",
        "67.548",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-26",
        "67.611",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-26",
        "67.667",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-26",
        "67.718",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-26",
        "67.760",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-26",
        "67.796",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-26",
        "67.827",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-26",
        "67.849",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-26",
        "67.866",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-26",
        "67.876",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-26",
        "67.879",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-26",
        "67.876",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-26",
        "67.866",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-26",
        "67.849",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-26",
        "67.825",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-26",
        "67.795",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-26",
        "67.758",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-26",
        "67.715",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-26",
        "67.665",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-26",
        "67.608",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-26",
        "67.545",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-26",
        "67.476",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-26",
        "67.399",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-26",
        "67.316",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-26",
        "67.226",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-26",
        "67.131",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-26",
        "67.029",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-26",
        "66.919",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-26",
        "66.804",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-26",
        "66.683",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-26",
        "66.555",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-26",
        "66.421",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-26",
        "66.281",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-26",
        "66.134",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-26",
        "65.982",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-26",
        "65.823",
        "21.137",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-26",
        "65.659",
        "21.924",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-26",
        "35.617",
        "18.869",
        "4.901",
        "4.262",
        "0.316"
    ],
    [
        "-70",
        "-26",
        "18.230",
        "16.228",
        "5.283",
        "3.123",
        "0.169"
    ],
    [
        "-69",
        "-26",
        "14.275",
        "15.585",
        "4.055",
        "2.199",
        "0.015"
    ],
    [
        "-68",
        "-26",
        "6.306",
        "14.238",
        "2.399",
        "1.802",
        "-0.129"
    ],
    [
        "-67",
        "-26",
        "2.436",
        "12.387",
        "1.873",
        "1.584",
        "-0.172"
    ],
    [
        "-66",
        "-26",
        "1.487",
        "11.215",
        "1.567",
        "1.425",
        "-0.232"
    ],
    [
        "-65",
        "-26",
        "0.775",
        "10.283",
        "1.145",
        "1.289",
        "-0.250"
    ],
    [
        "-64",
        "-26",
        "-0.033",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-26",
        "-0.111",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-26",
        "-0.190",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-26",
        "-0.269",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-26",
        "-0.350",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-26",
        "-0.431",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-26",
        "-0.513",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-26",
        "-0.596",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-26",
        "-0.681",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-26",
        "-0.765",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-26",
        "-0.851",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-26",
        "-0.936",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-26",
        "-1.024",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-26",
        "-1.110",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-26",
        "-1.198",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-26",
        "-1.288",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-26",
        "-1.377",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-26",
        "-1.467",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-26",
        "-1.557",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-26",
        "-1.649",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-26",
        "-1.739",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-26",
        "-1.831",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-26",
        "-1.923",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-26",
        "-2.016",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-26",
        "-2.109",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-26",
        "-2.203",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-26",
        "-2.297",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-26",
        "-2.391",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-26",
        "-2.485",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-26",
        "-2.579",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-26",
        "-2.673",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-26",
        "-2.769",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-26",
        "-2.864",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-26",
        "-2.958",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-26",
        "-3.054",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-26",
        "-3.149",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-26",
        "-3.244",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-26",
        "-3.340",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-26",
        "-3.435",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-26",
        "-3.530",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-26",
        "-3.625",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-26",
        "-3.720",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-26",
        "-3.814",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-26",
        "-3.909",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-26",
        "-4.004",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-26",
        "-4.097",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-26",
        "-4.192",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-26",
        "-4.286",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-26",
        "-4.378",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-26",
        "-4.471",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-26",
        "2.641",
        "17.537",
        "2.755",
        "2.698",
        "-0.175"
    ],
    [
        "-13",
        "-26",
        "25.024",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-26",
        "24.886",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-26",
        "24.746",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-26",
        "24.605",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-26",
        "24.464",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-26",
        "24.322",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-26",
        "24.179",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-26",
        "24.035",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-26",
        "23.891",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-26",
        "23.746",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-26",
        "23.600",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-26",
        "23.455",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-26",
        "23.308",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-26",
        "23.160",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-26",
        "23.013",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-26",
        "22.864",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-26",
        "22.716",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-26",
        "22.567",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-26",
        "22.419",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-26",
        "22.270",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-26",
        "22.120",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-26",
        "21.971",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-26",
        "21.821",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-26",
        "21.671",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-26",
        "21.521",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-26",
        "21.372",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-26",
        "21.223",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-26",
        "21.073",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-26",
        "20.924",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-26",
        "20.775",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-26",
        "20.627",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-26",
        "20.479",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-26",
        "20.331",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-26",
        "20.184",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-26",
        "20.036",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-26",
        "19.890",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-26",
        "19.744",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-26",
        "19.599",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-26",
        "19.454",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-26",
        "19.310",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-26",
        "19.275",
        "18.702",
        "0.656",
        "0.633",
        "-0.008"
    ],
    [
        "28",
        "-26",
        "19.313",
        "18.655",
        "0.653",
        "0.700",
        "0.027"
    ],
    [
        "29",
        "-26",
        "19.235",
        "18.446",
        "0.807",
        "0.897",
        "0.012"
    ],
    [
        "30",
        "-26",
        "19.210",
        "18.269",
        "0.909",
        "0.917",
        "0.009"
    ],
    [
        "31",
        "-26",
        "19.202",
        "18.086",
        "0.896",
        "0.971",
        "0.005"
    ],
    [
        "32",
        "-26",
        "19.205",
        "17.891",
        "0.918",
        "0.989",
        "0.000"
    ],
    [
        "33",
        "-26",
        "19.215",
        "17.686",
        "0.977",
        "0.978",
        "-0.006"
    ],
    [
        "34",
        "-26",
        "19.231",
        "17.470",
        "0.946",
        "1.019",
        "-0.011"
    ],
    [
        "35",
        "-26",
        "19.252",
        "17.242",
        "0.954",
        "1.032",
        "-0.013"
    ],
    [
        "36",
        "-26",
        "19.276",
        "17.005",
        "1.001",
        "1.017",
        "-0.014"
    ],
    [
        "37",
        "-26",
        "19.304",
        "16.764",
        "0.962",
        "1.049",
        "-0.015"
    ],
    [
        "38",
        "-26",
        "19.336",
        "16.520",
        "0.962",
        "1.052",
        "-0.014"
    ],
    [
        "39",
        "-26",
        "19.373",
        "16.277",
        "1.000",
        "1.027",
        "-0.012"
    ],
    [
        "40",
        "-26",
        "19.412",
        "16.034",
        "0.951",
        "1.047",
        "-0.011"
    ],
    [
        "41",
        "-26",
        "19.455",
        "15.794",
        "0.939",
        "1.036",
        "-0.008"
    ],
    [
        "42",
        "-26",
        "19.502",
        "15.559",
        "0.964",
        "0.992",
        "-0.005"
    ],
    [
        "43",
        "-26",
        "19.552",
        "15.330",
        "0.893",
        "0.990",
        "-0.002"
    ],
    [
        "44",
        "-26",
        "19.606",
        "15.109",
        "0.854",
        "0.951",
        "0.002"
    ],
    [
        "45",
        "-26",
        "19.667",
        "14.897",
        "0.840",
        "0.863",
        "0.005"
    ],
    [
        "46",
        "-26",
        "19.731",
        "14.690",
        "0.667",
        "0.762",
        "0.004"
    ],
    [
        "47",
        "-26",
        "19.763",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-26",
        "19.652",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-26",
        "19.545",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-26",
        "19.438",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-26",
        "19.334",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-26",
        "19.232",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-26",
        "19.131",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-26",
        "19.034",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-26",
        "18.937",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-26",
        "18.842",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-26",
        "18.751",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-26",
        "18.661",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-26",
        "18.572",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-26",
        "18.487",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-26",
        "18.404",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-26",
        "18.322",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-26",
        "18.243",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-26",
        "18.166",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-26",
        "18.092",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-26",
        "18.020",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-26",
        "17.950",
        "8.970",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-26",
        "17.883",
        "8.660",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-26",
        "16.683",
        "3.154",
        "4.640",
        "6.227",
        "0.426"
    ],
    [
        "70",
        "-26",
        "15.259",
        "-2.433",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-26",
        "38.757",
        "16.287",
        "5.780",
        "7.252",
        "0.405"
    ],
    [
        "72",
        "-26",
        "54.222",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-26",
        "53.974",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-26",
        "53.721",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-26",
        "53.465",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-26",
        "53.203",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-26",
        "52.937",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-26",
        "52.667",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-26",
        "52.392",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-26",
        "52.114",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-26",
        "51.831",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-26",
        "51.548",
        "37.739",
        "0.896",
        "0.992",
        "-0.006"
    ],
    [
        "83",
        "-26",
        "51.287",
        "38.333",
        "1.261",
        "1.378",
        "-0.018"
    ],
    [
        "84",
        "-26",
        "51.049",
        "38.935",
        "1.305",
        "1.200",
        "-0.028"
    ],
    [
        "85",
        "-26",
        "50.822",
        "39.571",
        "1.184",
        "1.133",
        "-0.075"
    ],
    [
        "86",
        "-26",
        "50.633",
        "40.387",
        "1.162",
        "1.138",
        "-0.096"
    ],
    [
        "87",
        "-26",
        "50.538",
        "42.010",
        "1.252",
        "1.251",
        "-0.085"
    ],
    [
        "88",
        "-26",
        "50.434",
        "43.983",
        "1.101",
        "1.227",
        "-0.126"
    ],
    [
        "89",
        "-26",
        "50.187",
        "45.207",
        "1.044",
        "1.201",
        "-0.074"
    ],
    [
        "90",
        "-26",
        "49.875",
        "45.998",
        "1.185",
        "1.193",
        "-0.074"
    ],
    [
        "91",
        "-26",
        "49.540",
        "46.760",
        "0.302",
        "0.462",
        "-0.045"
    ],
    [
        "92",
        "-26",
        "49.180",
        "47.351",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "-26",
        "48.816",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-26",
        "48.447",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-26",
        "48.074",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-26",
        "47.697",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-26",
        "47.317",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-26",
        "46.932",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-26",
        "46.544",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-26",
        "46.153",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-26",
        "45.758",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-26",
        "45.359",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-26",
        "44.958",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-26",
        "44.554",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-26",
        "44.145",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-26",
        "43.736",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-26",
        "43.322",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-26",
        "42.907",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-26",
        "42.489",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-26",
        "42.069",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-26",
        "41.646",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-26",
        "41.222",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-26",
        "40.796",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-26",
        "40.368",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-26",
        "39.937",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-26",
        "39.506",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-26",
        "39.073",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-26",
        "38.640",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-26",
        "38.204",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-26",
        "37.768",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-26",
        "37.332",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-26",
        "36.894",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-26",
        "36.455",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-26",
        "36.016",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-26",
        "35.578",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-26",
        "35.138",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-26",
        "34.699",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-26",
        "34.259",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-26",
        "33.821",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-26",
        "33.382",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-26",
        "32.943",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-26",
        "32.506",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-26",
        "32.069",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-26",
        "31.633",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-26",
        "31.198",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-26",
        "30.765",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-26",
        "30.332",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-26",
        "29.900",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-26",
        "29.471",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-26",
        "29.043",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-26",
        "28.617",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-26",
        "28.192",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-26",
        "27.770",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-26",
        "27.350",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-26",
        "26.932",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-26",
        "26.517",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-26",
        "26.105",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-26",
        "25.695",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-26",
        "25.287",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-26",
        "24.883",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-26",
        "24.482",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-26",
        "24.084",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-26",
        "23.690",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-26",
        "23.298",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-26",
        "22.911",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-26",
        "22.527",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-26",
        "22.146",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-26",
        "21.769",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-26",
        "21.397",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-26",
        "21.029",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-26",
        "20.665",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-26",
        "20.305",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-26",
        "19.950",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-26",
        "19.600",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-26",
        "19.253",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-26",
        "18.912",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-26",
        "18.576",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-26",
        "18.245",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-26",
        "17.919",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-26",
        "17.598",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-26",
        "17.282",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-26",
        "16.972",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-26",
        "16.667",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-26",
        "16.367",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-26",
        "16.073",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-26",
        "15.786",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-26",
        "15.504",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-26",
        "15.227",
        "35.706",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-26",
        "14.957",
        "34.916",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-25",
        "15.713",
        "34.115",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-25",
        "15.588",
        "32.765",
        "3.970",
        "2.773",
        "-0.122"
    ],
    [
        "-178",
        "-25",
        "16.748",
        "27.731",
        "7.954",
        "5.496",
        "-0.204"
    ],
    [
        "-177",
        "-25",
        "13.956",
        "21.550",
        "14.093",
        "8.378",
        "-0.374"
    ],
    [
        "-176",
        "-25",
        "-1.766",
        "17.985",
        "17.496",
        "10.570",
        "-0.346"
    ],
    [
        "-175",
        "-25",
        "-39.756",
        "24.402",
        "14.036",
        "10.625",
        "-0.490"
    ],
    [
        "-174",
        "-25",
        "-56.603",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-25",
        "-56.826",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-25",
        "-57.050",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-25",
        "-57.274",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-25",
        "-57.500",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-25",
        "-57.725",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-25",
        "-57.952",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-25",
        "-58.178",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-25",
        "-58.405",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-25",
        "-58.632",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-25",
        "-58.860",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-25",
        "-59.087",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-25",
        "-59.315",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-25",
        "-59.542",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-25",
        "-59.770",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-25",
        "-59.997",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-25",
        "-60.224",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-25",
        "-60.450",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-25",
        "-60.677",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-25",
        "-60.902",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-25",
        "-61.128",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-25",
        "-61.353",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-25",
        "-61.577",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-25",
        "-61.800",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-25",
        "-62.023",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-25",
        "-62.244",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-25",
        "-62.465",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-25",
        "-62.685",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-25",
        "-62.904",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-25",
        "-63.122",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-25",
        "-63.338",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-25",
        "-63.553",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-25",
        "-63.767",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-25",
        "-63.980",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-25",
        "-64.191",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-25",
        "-64.400",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-25",
        "-64.608",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-25",
        "-64.815",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-25",
        "-65.019",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-25",
        "-65.222",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-25",
        "-65.423",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-25",
        "-65.622",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-25",
        "-65.820",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-25",
        "-66.015",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-25",
        "-66.208",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-25",
        "-66.399",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-25",
        "-66.588",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-25",
        "-66.774",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-25",
        "-66.958",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-25",
        "-67.140",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-25",
        "-67.320",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-25",
        "-67.497",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-25",
        "-67.671",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-25",
        "-67.843",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-25",
        "-68.013",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-25",
        "-68.179",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-25",
        "-68.343",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-25",
        "-68.504",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-25",
        "-57.364",
        "21.433",
        "8.755",
        "9.716",
        "0.246"
    ],
    [
        "-115",
        "-25",
        "-32.314",
        "21.822",
        "13.410",
        "13.202",
        "0.277"
    ],
    [
        "-114",
        "-25",
        "-11.411",
        "18.292",
        "15.016",
        "12.486",
        "0.275"
    ],
    [
        "-113",
        "-25",
        "14.462",
        "4.507",
        "14.243",
        "13.441",
        "0.236"
    ],
    [
        "-112",
        "-25",
        "50.700",
        "-9.657",
        "9.770",
        "9.279",
        "0.092"
    ],
    [
        "-111",
        "-25",
        "66.937",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-25",
        "67.017",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-25",
        "67.090",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-25",
        "67.157",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-25",
        "67.218",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-25",
        "67.272",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-25",
        "67.320",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-25",
        "67.362",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-25",
        "67.397",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-25",
        "67.426",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-25",
        "67.448",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-25",
        "67.464",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-25",
        "67.473",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-25",
        "67.476",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-25",
        "67.473",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-25",
        "67.463",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-25",
        "67.447",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-25",
        "67.424",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-25",
        "67.395",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-25",
        "67.360",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-25",
        "67.318",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-25",
        "67.270",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-25",
        "67.215",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-25",
        "67.154",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-25",
        "67.087",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-25",
        "67.013",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-25",
        "66.933",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-25",
        "66.847",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-25",
        "66.755",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-25",
        "66.656",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-25",
        "66.551",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-25",
        "66.441",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-25",
        "66.324",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-25",
        "66.199",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-25",
        "66.070",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-25",
        "65.935",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-25",
        "65.795",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-25",
        "65.647",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-25",
        "65.494",
        "21.137",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-25",
        "65.336",
        "21.924",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-25",
        "35.728",
        "18.824",
        "8.103",
        "4.833",
        "0.156"
    ],
    [
        "-70",
        "-25",
        "18.123",
        "15.951",
        "6.613",
        "3.791",
        "0.085"
    ],
    [
        "-69",
        "-25",
        "11.557",
        "14.780",
        "4.872",
        "2.599",
        "0.034"
    ],
    [
        "-68",
        "-25",
        "5.324",
        "14.044",
        "2.699",
        "2.020",
        "-0.060"
    ],
    [
        "-67",
        "-25",
        "2.486",
        "12.576",
        "2.063",
        "1.754",
        "-0.112"
    ],
    [
        "-66",
        "-25",
        "1.333",
        "11.252",
        "1.738",
        "1.558",
        "-0.176"
    ],
    [
        "-65",
        "-25",
        "0.491",
        "10.362",
        "1.248",
        "1.350",
        "-0.198"
    ],
    [
        "-64",
        "-25",
        "-0.174",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-25",
        "-0.249",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-25",
        "-0.325",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-25",
        "-0.402",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-25",
        "-0.480",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-25",
        "-0.558",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-25",
        "-0.638",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-25",
        "-0.718",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-25",
        "-0.799",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-25",
        "-0.880",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-25",
        "-0.963",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-25",
        "-1.046",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-25",
        "-1.129",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-25",
        "-1.213",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-25",
        "-1.298",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-25",
        "-1.384",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-25",
        "-1.470",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-25",
        "-1.557",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-25",
        "-1.644",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-25",
        "-1.731",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-25",
        "-1.820",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-25",
        "-1.909",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-25",
        "-1.997",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-25",
        "-2.087",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-25",
        "-2.176",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-25",
        "-2.267",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-25",
        "-2.357",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-25",
        "-2.448",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-25",
        "-2.538",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-25",
        "-2.630",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-25",
        "-2.721",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-25",
        "-2.812",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-25",
        "-2.904",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-25",
        "-2.995",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-25",
        "-3.087",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-25",
        "-3.179",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-25",
        "-3.270",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-25",
        "-3.363",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-25",
        "-3.454",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-25",
        "-3.546",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-25",
        "-3.637",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-25",
        "-3.729",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-25",
        "-3.820",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-25",
        "-3.911",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-25",
        "-4.002",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-25",
        "-4.093",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-25",
        "-4.184",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-25",
        "-4.274",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-25",
        "-4.363",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-25",
        "-4.454",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-25",
        "2.486",
        "16.330",
        "2.454",
        "2.548",
        "-0.155"
    ],
    [
        "-13",
        "-25",
        "25.087",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-25",
        "24.953",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-25",
        "24.818",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-25",
        "24.683",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-25",
        "24.547",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-25",
        "24.410",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-25",
        "24.272",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-25",
        "24.134",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-25",
        "23.994",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-25",
        "23.855",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-25",
        "23.714",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-25",
        "23.574",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-25",
        "23.432",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-25",
        "23.290",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-25",
        "23.147",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-25",
        "23.005",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-25",
        "22.862",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-25",
        "22.718",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-25",
        "22.575",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-25",
        "22.430",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-25",
        "22.287",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-25",
        "22.143",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-25",
        "21.998",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-25",
        "21.854",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-25",
        "21.710",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-25",
        "21.565",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-25",
        "21.422",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-25",
        "21.278",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-25",
        "21.134",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-25",
        "20.990",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-25",
        "20.847",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-25",
        "20.704",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-25",
        "20.562",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-25",
        "20.419",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-25",
        "20.277",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-25",
        "20.136",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-25",
        "19.996",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-25",
        "19.856",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-25",
        "19.716",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-25",
        "19.578",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-25",
        "19.439",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-25",
        "19.493",
        "18.601",
        "0.706",
        "0.793",
        "0.008"
    ],
    [
        "29",
        "-25",
        "19.503",
        "18.445",
        "0.805",
        "0.899",
        "0.007"
    ],
    [
        "30",
        "-25",
        "19.503",
        "18.267",
        "0.907",
        "0.924",
        "0.009"
    ],
    [
        "31",
        "-25",
        "19.514",
        "18.080",
        "0.896",
        "0.982",
        "0.006"
    ],
    [
        "32",
        "-25",
        "19.532",
        "17.884",
        "0.920",
        "1.002",
        "0.002"
    ],
    [
        "33",
        "-25",
        "19.556",
        "17.679",
        "0.980",
        "0.991",
        "-0.002"
    ],
    [
        "34",
        "-25",
        "19.584",
        "17.465",
        "0.950",
        "1.030",
        "-0.006"
    ],
    [
        "35",
        "-25",
        "19.616",
        "17.242",
        "0.959",
        "1.042",
        "-0.008"
    ],
    [
        "36",
        "-25",
        "19.651",
        "17.010",
        "1.005",
        "1.025",
        "-0.010"
    ],
    [
        "37",
        "-25",
        "19.688",
        "16.774",
        "0.966",
        "1.056",
        "-0.011"
    ],
    [
        "38",
        "-25",
        "19.728",
        "16.534",
        "0.965",
        "1.058",
        "-0.011"
    ],
    [
        "39",
        "-25",
        "19.770",
        "16.293",
        "1.003",
        "1.030",
        "-0.010"
    ],
    [
        "40",
        "-25",
        "19.815",
        "16.052",
        "0.953",
        "1.049",
        "-0.008"
    ],
    [
        "41",
        "-25",
        "19.864",
        "15.812",
        "0.939",
        "1.036",
        "-0.005"
    ],
    [
        "42",
        "-25",
        "19.914",
        "15.576",
        "0.961",
        "0.988",
        "0.000"
    ],
    [
        "43",
        "-25",
        "19.967",
        "15.345",
        "0.888",
        "0.981",
        "0.005"
    ],
    [
        "44",
        "-25",
        "20.024",
        "15.122",
        "0.847",
        "0.936",
        "0.011"
    ],
    [
        "45",
        "-25",
        "20.087",
        "14.906",
        "0.832",
        "0.846",
        "0.014"
    ],
    [
        "46",
        "-25",
        "20.155",
        "14.694",
        "0.661",
        "0.743",
        "0.011"
    ],
    [
        "47",
        "-25",
        "20.190",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-25",
        "20.084",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-25",
        "19.980",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-25",
        "19.879",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-25",
        "19.778",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-25",
        "19.679",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-25",
        "19.582",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-25",
        "19.487",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-25",
        "19.395",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-25",
        "19.304",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-25",
        "19.216",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-25",
        "19.128",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-25",
        "19.044",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-25",
        "18.961",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-25",
        "18.881",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-25",
        "18.802",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-25",
        "18.726",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-25",
        "18.652",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-25",
        "18.581",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-25",
        "18.511",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-25",
        "18.444",
        "8.970",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-25",
        "18.378",
        "8.660",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-25",
        "18.316",
        "8.347",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-25",
        "45.069",
        "24.920",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-25",
        "54.224",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-25",
        "53.989",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-25",
        "53.751",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-25",
        "53.508",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-25",
        "53.260",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-25",
        "53.008",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-25",
        "52.751",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-25",
        "52.491",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-25",
        "52.226",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-25",
        "51.958",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-25",
        "51.686",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-25",
        "51.409",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-25",
        "51.129",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-25",
        "50.845",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-25",
        "50.557",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-25",
        "50.267",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-25",
        "50.111",
        "41.266",
        "1.056",
        "1.126",
        "-0.158"
    ],
    [
        "88",
        "-25",
        "49.911",
        "43.188",
        "1.184",
        "1.323",
        "-0.130"
    ],
    [
        "89",
        "-25",
        "49.695",
        "44.565",
        "1.280",
        "1.382",
        "-0.114"
    ],
    [
        "90",
        "-25",
        "49.486",
        "45.590",
        "1.434",
        "1.422",
        "-0.086"
    ],
    [
        "91",
        "-25",
        "49.255",
        "46.495",
        "1.299",
        "1.467",
        "-0.030"
    ],
    [
        "92",
        "-25",
        "48.963",
        "47.317",
        "0.908",
        "1.019",
        "-0.015"
    ],
    [
        "93",
        "-25",
        "48.609",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-25",
        "48.254",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-25",
        "47.895",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-25",
        "47.531",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-25",
        "47.164",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-25",
        "46.793",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-25",
        "46.419",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-25",
        "46.042",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-25",
        "45.661",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-25",
        "45.277",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-25",
        "44.889",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-25",
        "44.500",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-25",
        "44.107",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-25",
        "43.711",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-25",
        "43.314",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-25",
        "42.913",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-25",
        "42.510",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-25",
        "42.105",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-25",
        "41.697",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-25",
        "41.289",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-25",
        "40.877",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-25",
        "40.465",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-25",
        "40.050",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-25",
        "39.634",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-25",
        "39.217",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-25",
        "38.799",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-25",
        "38.380",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-25",
        "37.959",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-25",
        "37.538",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-25",
        "37.115",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-25",
        "36.693",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-25",
        "36.270",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-25",
        "35.847",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-25",
        "35.423",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-25",
        "35.000",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-25",
        "34.576",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-25",
        "34.153",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-25",
        "33.730",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-25",
        "33.307",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-25",
        "32.886",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-25",
        "32.464",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-25",
        "32.044",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-25",
        "31.625",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-25",
        "31.207",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-25",
        "30.790",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-25",
        "30.374",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-25",
        "29.959",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-25",
        "29.547",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-25",
        "29.136",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-25",
        "28.728",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-25",
        "28.320",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-25",
        "27.915",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-25",
        "27.512",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-25",
        "27.112",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-25",
        "26.715",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-25",
        "26.319",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-25",
        "25.927",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-25",
        "25.537",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-25",
        "25.150",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-25",
        "24.766",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-25",
        "24.385",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-25",
        "24.009",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-25",
        "23.635",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-25",
        "23.264",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-25",
        "22.898",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-25",
        "22.536",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-25",
        "22.176",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-25",
        "21.821",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-25",
        "21.470",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-25",
        "21.124",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-25",
        "20.781",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-25",
        "20.443",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-25",
        "20.109",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-25",
        "19.781",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-25",
        "19.456",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-25",
        "19.137",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-25",
        "18.822",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-25",
        "18.513",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-25",
        "18.208",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-25",
        "17.910",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-25",
        "17.616",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-25",
        "17.327",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-25",
        "17.044",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-25",
        "16.766",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-25",
        "16.494",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-25",
        "16.228",
        "35.706",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-25",
        "15.967",
        "34.916",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-24",
        "16.728",
        "34.115",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-24",
        "16.489",
        "33.303",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-24",
        "19.694",
        "29.030",
        "8.662",
        "5.878",
        "-0.296"
    ],
    [
        "-177",
        "-24",
        "21.471",
        "22.803",
        "16.298",
        "9.942",
        "-0.419"
    ],
    [
        "-176",
        "-24",
        "10.552",
        "17.273",
        "21.252",
        "13.236",
        "-0.369"
    ],
    [
        "-175",
        "-24",
        "-24.275",
        "21.846",
        "22.468",
        "14.419",
        "-0.378"
    ],
    [
        "-174",
        "-24",
        "-57.174",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-24",
        "-57.389",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-24",
        "-57.604",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-24",
        "-57.821",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-24",
        "-58.037",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-24",
        "-58.255",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-24",
        "-58.472",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-24",
        "-58.690",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-24",
        "-58.909",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-24",
        "-59.127",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-24",
        "-59.346",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-24",
        "-59.565",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-24",
        "-59.784",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-24",
        "-60.003",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-24",
        "-60.222",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-24",
        "-60.441",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-24",
        "-60.659",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-24",
        "-60.877",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-24",
        "-61.095",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-24",
        "-61.312",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-24",
        "-61.529",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-24",
        "-61.745",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-24",
        "-61.961",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-24",
        "-62.176",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-24",
        "-62.390",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-24",
        "-62.604",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-24",
        "-62.816",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-24",
        "-63.028",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-24",
        "-63.238",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-24",
        "-63.448",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-24",
        "-63.656",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-24",
        "-63.863",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-24",
        "-64.069",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-24",
        "-64.274",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-24",
        "-64.477",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-24",
        "-64.679",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-24",
        "-64.879",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-24",
        "-65.077",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-24",
        "-65.274",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-24",
        "-65.470",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-24",
        "-65.663",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-24",
        "-65.855",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-24",
        "-66.044",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-24",
        "-66.232",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-24",
        "-66.418",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-24",
        "-66.602",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-24",
        "-66.784",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-24",
        "-66.963",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-24",
        "-67.141",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-24",
        "-67.316",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-24",
        "-67.488",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-24",
        "-67.659",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-24",
        "-67.827",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-24",
        "-67.992",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-24",
        "-68.155",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-24",
        "-68.316",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-24",
        "-68.473",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-24",
        "-68.628",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-24",
        "-68.781",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-24",
        "-47.039",
        "18.658",
        "11.326",
        "11.567",
        "0.304"
    ],
    [
        "-114",
        "-24",
        "-24.167",
        "13.807",
        "14.555",
        "11.712",
        "0.242"
    ],
    [
        "-113",
        "-24",
        "5.976",
        "1.622",
        "14.619",
        "12.732",
        "0.192"
    ],
    [
        "-112",
        "-24",
        "45.260",
        "-16.599",
        "13.307",
        "11.237",
        "0.235"
    ],
    [
        "-111",
        "-24",
        "66.534",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-24",
        "66.611",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-24",
        "66.681",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-24",
        "66.746",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-24",
        "66.804",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-24",
        "66.856",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-24",
        "66.903",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-24",
        "66.942",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-24",
        "66.976",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-24",
        "67.005",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-24",
        "67.025",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-24",
        "67.041",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-24",
        "67.050",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-24",
        "67.053",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-24",
        "67.050",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-24",
        "67.041",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-24",
        "67.025",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-24",
        "67.003",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-24",
        "66.975",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-24",
        "66.941",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-24",
        "66.901",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-24",
        "66.854",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-24",
        "66.802",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-24",
        "66.743",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-24",
        "66.678",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-24",
        "66.608",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-24",
        "66.530",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-24",
        "66.447",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-24",
        "66.358",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-24",
        "66.264",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-24",
        "66.162",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-24",
        "66.055",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-24",
        "65.943",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-24",
        "65.824",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-24",
        "65.701",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-24",
        "65.570",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-24",
        "65.434",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-24",
        "65.292",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-24",
        "65.145",
        "21.137",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-24",
        "64.993",
        "21.924",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-24",
        "35.675",
        "18.999",
        "8.775",
        "4.987",
        "0.028"
    ],
    [
        "-70",
        "-24",
        "18.455",
        "16.092",
        "7.168",
        "4.007",
        "0.020"
    ],
    [
        "-69",
        "-24",
        "10.058",
        "14.820",
        "5.171",
        "2.733",
        "-0.011"
    ],
    [
        "-68",
        "-24",
        "5.208",
        "14.054",
        "2.830",
        "2.132",
        "-0.018"
    ],
    [
        "-67",
        "-24",
        "2.981",
        "12.771",
        "2.178",
        "1.846",
        "-0.045"
    ],
    [
        "-66",
        "-24",
        "1.695",
        "11.426",
        "1.840",
        "1.600",
        "-0.093"
    ],
    [
        "-65",
        "-24",
        "0.509",
        "10.399",
        "1.286",
        "1.312",
        "-0.112"
    ],
    [
        "-64",
        "-24",
        "-0.316",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-24",
        "-0.388",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-24",
        "-0.461",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-24",
        "-0.535",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-24",
        "-0.610",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-24",
        "-0.686",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-24",
        "-0.762",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-24",
        "-0.839",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-24",
        "-0.917",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-24",
        "-0.995",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-24",
        "-1.075",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-24",
        "-1.155",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-24",
        "-1.235",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-24",
        "-1.316",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-24",
        "-1.397",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-24",
        "-1.480",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-24",
        "-1.563",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-24",
        "-1.646",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-24",
        "-1.730",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-24",
        "-1.814",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-24",
        "-1.900",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-24",
        "-1.985",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-24",
        "-2.071",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-24",
        "-2.156",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-24",
        "-2.243",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-24",
        "-2.330",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-24",
        "-2.417",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-24",
        "-2.503",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-24",
        "-2.591",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-24",
        "-2.678",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-24",
        "-2.767",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-24",
        "-2.854",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-24",
        "-2.943",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-24",
        "-3.030",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-24",
        "-3.119",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-24",
        "-3.207",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-24",
        "-3.295",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-24",
        "-3.384",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-24",
        "-3.472",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-24",
        "-3.561",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-24",
        "-3.649",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-24",
        "-3.737",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-24",
        "-3.825",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-24",
        "-3.913",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-24",
        "-4.000",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-24",
        "-4.088",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-24",
        "-4.175",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-24",
        "-4.262",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-24",
        "-4.348",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-24",
        "-4.434",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-24",
        "-4.520",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-24",
        "25.141",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-24",
        "25.013",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-24",
        "24.883",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-24",
        "24.753",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-24",
        "24.622",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-24",
        "24.490",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-24",
        "24.358",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-24",
        "24.224",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-24",
        "24.090",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-24",
        "23.956",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-24",
        "23.820",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-24",
        "23.685",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-24",
        "23.549",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-24",
        "23.412",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-24",
        "23.275",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-24",
        "23.138",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-24",
        "23.000",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-24",
        "22.862",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-24",
        "22.724",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-24",
        "22.586",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-24",
        "22.447",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-24",
        "22.308",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-24",
        "22.169",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-24",
        "22.030",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-24",
        "21.891",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-24",
        "21.753",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-24",
        "21.614",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-24",
        "21.476",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-24",
        "21.337",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-24",
        "21.199",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-24",
        "21.061",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-24",
        "20.924",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-24",
        "20.787",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-24",
        "20.650",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-24",
        "20.513",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-24",
        "20.378",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-24",
        "20.242",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-24",
        "20.108",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-24",
        "19.973",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-24",
        "19.839",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-24",
        "19.706",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-24",
        "19.696",
        "18.565",
        "0.648",
        "0.743",
        "-0.012"
    ],
    [
        "29",
        "-24",
        "19.736",
        "18.427",
        "0.788",
        "0.891",
        "0.020"
    ],
    [
        "30",
        "-24",
        "19.772",
        "18.256",
        "0.900",
        "0.925",
        "0.011"
    ],
    [
        "31",
        "-24",
        "19.808",
        "18.072",
        "0.893",
        "0.987",
        "0.006"
    ],
    [
        "32",
        "-24",
        "19.843",
        "17.876",
        "0.919",
        "1.007",
        "0.002"
    ],
    [
        "33",
        "-24",
        "19.882",
        "17.673",
        "0.980",
        "0.997",
        "0.000"
    ],
    [
        "34",
        "-24",
        "19.923",
        "17.461",
        "0.951",
        "1.035",
        "-0.002"
    ],
    [
        "35",
        "-24",
        "19.967",
        "17.242",
        "0.961",
        "1.047",
        "-0.004"
    ],
    [
        "36",
        "-24",
        "20.012",
        "17.014",
        "1.008",
        "1.031",
        "-0.006"
    ],
    [
        "37",
        "-24",
        "20.058",
        "16.782",
        "0.969",
        "1.062",
        "-0.008"
    ],
    [
        "38",
        "-24",
        "20.107",
        "16.546",
        "0.968",
        "1.062",
        "-0.008"
    ],
    [
        "39",
        "-24",
        "20.158",
        "16.307",
        "1.005",
        "1.034",
        "-0.008"
    ],
    [
        "40",
        "-24",
        "20.211",
        "16.068",
        "0.954",
        "1.051",
        "-0.005"
    ],
    [
        "41",
        "-24",
        "20.264",
        "15.828",
        "0.939",
        "1.035",
        "-0.001"
    ],
    [
        "42",
        "-24",
        "20.321",
        "15.591",
        "0.958",
        "0.984",
        "0.007"
    ],
    [
        "43",
        "-24",
        "20.377",
        "15.358",
        "0.882",
        "0.972",
        "0.014"
    ],
    [
        "44",
        "-24",
        "20.436",
        "15.133",
        "0.840",
        "0.923",
        "0.021"
    ],
    [
        "45",
        "-24",
        "20.501",
        "14.914",
        "0.823",
        "0.831",
        "0.026"
    ],
    [
        "46",
        "-24",
        "20.573",
        "14.697",
        "0.653",
        "0.724",
        "0.022"
    ],
    [
        "47",
        "-24",
        "20.613",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-24",
        "20.510",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-24",
        "20.410",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-24",
        "20.312",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-24",
        "20.215",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-24",
        "20.121",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-24",
        "20.027",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-24",
        "19.937",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-24",
        "19.847",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-24",
        "19.759",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-24",
        "19.674",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-24",
        "19.591",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-24",
        "19.509",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-24",
        "19.429",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-24",
        "19.351",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-24",
        "19.276",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-24",
        "19.203",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-24",
        "19.132",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-24",
        "19.063",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-24",
        "18.996",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-24",
        "18.931",
        "8.970",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-24",
        "18.869",
        "8.660",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-24",
        "18.808",
        "8.347",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-24",
        "50.743",
        "29.894",
        "2.306",
        "3.497",
        "0.320"
    ],
    [
        "71",
        "-24",
        "53.967",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-24",
        "53.741",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-24",
        "53.511",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-24",
        "53.277",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-24",
        "53.038",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-24",
        "52.795",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-24",
        "52.550",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-24",
        "52.298",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-24",
        "52.044",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-24",
        "51.786",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-24",
        "51.524",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-24",
        "51.257",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-24",
        "50.988",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-24",
        "50.714",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-24",
        "50.438",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-24",
        "50.158",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-24",
        "49.864",
        "40.756",
        "1.115",
        "1.109",
        "-0.129"
    ],
    [
        "88",
        "-24",
        "49.579",
        "42.605",
        "1.247",
        "1.412",
        "-0.116"
    ],
    [
        "89",
        "-24",
        "49.356",
        "44.033",
        "1.354",
        "1.496",
        "-0.112"
    ],
    [
        "90",
        "-24",
        "49.167",
        "45.185",
        "1.512",
        "1.533",
        "-0.083"
    ],
    [
        "91",
        "-24",
        "48.974",
        "46.229",
        "1.403",
        "1.595",
        "-0.038"
    ],
    [
        "92",
        "-24",
        "48.719",
        "47.265",
        "0.959",
        "1.033",
        "-0.016"
    ],
    [
        "93",
        "-24",
        "48.388",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-24",
        "48.046",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-24",
        "47.700",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-24",
        "47.351",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-24",
        "46.997",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-24",
        "46.640",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-24",
        "46.281",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-24",
        "45.917",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-24",
        "45.551",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-24",
        "45.181",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-24",
        "44.808",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-24",
        "44.433",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-24",
        "44.055",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-24",
        "43.675",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-24",
        "43.291",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-24",
        "42.905",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-24",
        "42.518",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-24",
        "42.127",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-24",
        "41.736",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-24",
        "41.342",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-24",
        "40.947",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-24",
        "40.549",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-24",
        "40.151",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-24",
        "39.751",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-24",
        "39.349",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-24",
        "38.946",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-24",
        "38.543",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-24",
        "38.137",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-24",
        "37.733",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-24",
        "37.326",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-24",
        "36.920",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-24",
        "36.513",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-24",
        "36.105",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-24",
        "35.698",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-24",
        "35.290",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-24",
        "34.882",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-24",
        "34.475",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-24",
        "34.068",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-24",
        "33.661",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-24",
        "33.255",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-24",
        "32.850",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-24",
        "32.445",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-24",
        "32.042",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-24",
        "31.640",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-24",
        "31.238",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-24",
        "30.838",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-24",
        "30.439",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-24",
        "30.043",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-24",
        "29.647",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-24",
        "29.253",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-24",
        "28.861",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-24",
        "28.472",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-24",
        "28.084",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-24",
        "27.699",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-24",
        "27.316",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-24",
        "26.936",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-24",
        "26.557",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-24",
        "26.182",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-24",
        "25.810",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-24",
        "25.441",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-24",
        "25.075",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-24",
        "24.711",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-24",
        "24.352",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-24",
        "23.996",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-24",
        "23.643",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-24",
        "23.293",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-24",
        "22.949",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-24",
        "22.606",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-24",
        "22.269",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-24",
        "21.935",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-24",
        "21.605",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-24",
        "21.280",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-24",
        "20.959",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-24",
        "20.642",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-24",
        "20.331",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-24",
        "20.023",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-24",
        "19.721",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-24",
        "19.423",
        "41.617",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-24",
        "19.130",
        "40.920",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-24",
        "18.841",
        "40.211",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-24",
        "18.559",
        "39.489",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-24",
        "18.281",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-24",
        "18.009",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-24",
        "17.742",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-24",
        "17.479",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-24",
        "17.224",
        "35.706",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-24",
        "16.973",
        "34.916",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-23",
        "17.738",
        "34.115",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-23",
        "17.508",
        "33.303",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-23",
        "20.111",
        "30.136",
        "8.088",
        "6.003",
        "-0.355"
    ],
    [
        "-177",
        "-23",
        "25.915",
        "24.973",
        "16.461",
        "10.464",
        "-0.457"
    ],
    [
        "-176",
        "-23",
        "24.972",
        "17.952",
        "22.118",
        "14.296",
        "-0.384"
    ],
    [
        "-175",
        "-23",
        "-6.217",
        "17.742",
        "25.421",
        "16.158",
        "-0.407"
    ],
    [
        "-174",
        "-23",
        "-57.728",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-23",
        "-57.934",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-23",
        "-58.141",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-23",
        "-58.349",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-23",
        "-58.557",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-23",
        "-58.766",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-23",
        "-58.975",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-23",
        "-59.185",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-23",
        "-59.394",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-23",
        "-59.605",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-23",
        "-59.815",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-23",
        "-60.025",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-23",
        "-60.235",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-23",
        "-60.446",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-23",
        "-60.656",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-23",
        "-60.866",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-23",
        "-61.076",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-23",
        "-61.285",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-23",
        "-61.495",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-23",
        "-61.703",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-23",
        "-61.912",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-23",
        "-62.119",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-23",
        "-62.327",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-23",
        "-62.533",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-23",
        "-62.739",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-23",
        "-62.944",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-23",
        "-63.148",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-23",
        "-63.351",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-23",
        "-63.554",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-23",
        "-63.755",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-23",
        "-63.955",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-23",
        "-64.154",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-23",
        "-64.352",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-23",
        "-64.548",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-23",
        "-64.744",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-23",
        "-64.937",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-23",
        "-65.130",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-23",
        "-65.320",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-23",
        "-65.509",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-23",
        "-65.697",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-23",
        "-65.883",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-23",
        "-66.067",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-23",
        "-66.249",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-23",
        "-66.430",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-23",
        "-66.608",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-23",
        "-66.785",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-23",
        "-66.960",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-23",
        "-67.132",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-23",
        "-67.302",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-23",
        "-67.471",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-23",
        "-67.637",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-23",
        "-67.800",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-23",
        "-67.962",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-23",
        "-68.120",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-23",
        "-68.277",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-23",
        "-68.431",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-23",
        "-68.583",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-23",
        "-68.732",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-23",
        "-68.878",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-23",
        "-65.115",
        "19.503",
        "2.615",
        "4.276",
        "0.036"
    ],
    [
        "-114",
        "-23",
        "-30.894",
        "16.762",
        "13.215",
        "11.341",
        "0.149"
    ],
    [
        "-113",
        "-23",
        "16.498",
        "-7.336",
        "13.664",
        "12.610",
        "0.136"
    ],
    [
        "-112",
        "-23",
        "56.135",
        "-16.196",
        "10.314",
        "8.573",
        "0.184"
    ],
    [
        "-111",
        "-23",
        "66.110",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-23",
        "66.184",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-23",
        "66.252",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-23",
        "66.314",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-23",
        "66.370",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-23",
        "66.421",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-23",
        "66.465",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-23",
        "66.503",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-23",
        "66.536",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-23",
        "66.562",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-23",
        "66.583",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-23",
        "66.598",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-23",
        "66.606",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-23",
        "66.610",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-23",
        "66.606",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-23",
        "66.597",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-23",
        "66.582",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-23",
        "66.561",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-23",
        "66.534",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-23",
        "66.502",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-23",
        "66.463",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-23",
        "66.418",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-23",
        "66.368",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-23",
        "66.312",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-23",
        "66.249",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-23",
        "66.181",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-23",
        "66.107",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-23",
        "66.027",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-23",
        "65.942",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-23",
        "65.851",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-23",
        "65.753",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-23",
        "65.651",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-23",
        "65.543",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-23",
        "65.429",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-23",
        "65.310",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-23",
        "65.185",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-23",
        "65.054",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-23",
        "64.918",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-23",
        "64.777",
        "21.137",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-23",
        "64.630",
        "21.924",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-23",
        "38.673",
        "19.660",
        "8.586",
        "4.939",
        "-0.103"
    ],
    [
        "-70",
        "-23",
        "20.200",
        "17.031",
        "6.667",
        "3.805",
        "0.008"
    ],
    [
        "-69",
        "-23",
        "10.797",
        "15.359",
        "5.043",
        "2.662",
        "-0.094"
    ],
    [
        "-68",
        "-23",
        "6.150",
        "14.270",
        "2.826",
        "2.102",
        "0.020"
    ],
    [
        "-67",
        "-23",
        "4.070",
        "13.076",
        "2.234",
        "1.873",
        "0.017"
    ],
    [
        "-66",
        "-23",
        "2.736",
        "11.852",
        "1.957",
        "1.648",
        "-0.003"
    ],
    [
        "-65",
        "-23",
        "1.251",
        "10.737",
        "1.489",
        "1.399",
        "0.039"
    ],
    [
        "-64",
        "-23",
        "-0.457",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-23",
        "-0.527",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-23",
        "-0.597",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-23",
        "-0.668",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-23",
        "-0.740",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-23",
        "-0.812",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-23",
        "-0.886",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-23",
        "-0.960",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-23",
        "-1.035",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-23",
        "-1.110",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-23",
        "-1.186",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-23",
        "-1.263",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-23",
        "-1.340",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-23",
        "-1.418",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-23",
        "-1.497",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-23",
        "-1.576",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-23",
        "-1.656",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-23",
        "-1.736",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-23",
        "-1.816",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-23",
        "-1.897",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-23",
        "-1.978",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-23",
        "-2.061",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-23",
        "-2.143",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-23",
        "-2.226",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-23",
        "-2.309",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-23",
        "-2.391",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-23",
        "-2.475",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-23",
        "-2.559",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-23",
        "-2.643",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-23",
        "-2.727",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-23",
        "-2.812",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-23",
        "-2.896",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-23",
        "-2.981",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-23",
        "-3.065",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-23",
        "-3.150",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-23",
        "-3.235",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-23",
        "-3.319",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-23",
        "-3.405",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-23",
        "-3.490",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-23",
        "-3.574",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-23",
        "-3.659",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-23",
        "-3.744",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-23",
        "-3.828",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-23",
        "-3.912",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-23",
        "-3.997",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-23",
        "-4.080",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-23",
        "-4.164",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-23",
        "-4.247",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-23",
        "-4.331",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-23",
        "-4.414",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-23",
        "-1.488",
        "12.684",
        "2.366",
        "2.454",
        "-0.073"
    ],
    [
        "-13",
        "-23",
        "25.189",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-23",
        "25.065",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-23",
        "24.940",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-23",
        "24.815",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-23",
        "24.689",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-23",
        "24.563",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-23",
        "24.436",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-23",
        "24.308",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-23",
        "24.179",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-23",
        "24.049",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-23",
        "23.919",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-23",
        "23.790",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-23",
        "23.659",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-23",
        "23.528",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-23",
        "23.396",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-23",
        "23.264",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-23",
        "23.132",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-23",
        "22.999",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-23",
        "22.866",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-23",
        "22.733",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-23",
        "22.600",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-23",
        "22.467",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-23",
        "22.333",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-23",
        "22.200",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-23",
        "22.066",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-23",
        "21.934",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-23",
        "21.800",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-23",
        "21.667",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-23",
        "21.534",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-23",
        "21.401",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-23",
        "21.269",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-23",
        "21.137",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-23",
        "21.005",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-23",
        "20.873",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-23",
        "20.742",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-23",
        "20.612",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-23",
        "20.482",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-23",
        "20.353",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-23",
        "20.224",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-23",
        "20.095",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-23",
        "19.968",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-23",
        "19.908",
        "18.561",
        "0.492",
        "0.528",
        "0.002"
    ],
    [
        "29",
        "-23",
        "19.979",
        "18.410",
        "0.763",
        "0.880",
        "0.009"
    ],
    [
        "30",
        "-23",
        "20.038",
        "18.241",
        "0.892",
        "0.924",
        "0.007"
    ],
    [
        "31",
        "-23",
        "20.092",
        "18.059",
        "0.888",
        "0.988",
        "0.004"
    ],
    [
        "32",
        "-23",
        "20.146",
        "17.866",
        "0.915",
        "1.007",
        "0.002"
    ],
    [
        "33",
        "-23",
        "20.199",
        "17.665",
        "0.976",
        "0.996",
        "0.001"
    ],
    [
        "34",
        "-23",
        "20.253",
        "17.457",
        "0.949",
        "1.035",
        "0.000"
    ],
    [
        "35",
        "-23",
        "20.308",
        "17.242",
        "0.960",
        "1.050",
        "-0.001"
    ],
    [
        "36",
        "-23",
        "20.363",
        "17.018",
        "1.009",
        "1.035",
        "-0.003"
    ],
    [
        "37",
        "-23",
        "20.420",
        "16.789",
        "0.971",
        "1.067",
        "-0.005"
    ],
    [
        "38",
        "-23",
        "20.477",
        "16.556",
        "0.970",
        "1.067",
        "-0.007"
    ],
    [
        "39",
        "-23",
        "20.536",
        "16.320",
        "1.007",
        "1.038",
        "-0.006"
    ],
    [
        "40",
        "-23",
        "20.596",
        "16.082",
        "0.955",
        "1.054",
        "-0.004"
    ],
    [
        "41",
        "-23",
        "20.658",
        "15.842",
        "0.939",
        "1.036",
        "0.001"
    ],
    [
        "42",
        "-23",
        "20.718",
        "15.605",
        "0.957",
        "0.983",
        "0.011"
    ],
    [
        "43",
        "-23",
        "20.779",
        "15.371",
        "0.882",
        "0.970",
        "0.019"
    ],
    [
        "44",
        "-23",
        "20.841",
        "15.143",
        "0.839",
        "0.921",
        "0.028"
    ],
    [
        "45",
        "-23",
        "20.909",
        "14.920",
        "0.822",
        "0.830",
        "0.035"
    ],
    [
        "46",
        "-23",
        "20.985",
        "14.699",
        "0.652",
        "0.723",
        "0.028"
    ],
    [
        "47",
        "-23",
        "21.028",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-23",
        "20.931",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-23",
        "20.834",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-23",
        "20.740",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-23",
        "20.646",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-23",
        "20.556",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-23",
        "20.466",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-23",
        "20.379",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-23",
        "20.293",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-23",
        "20.209",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-23",
        "20.127",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-23",
        "20.046",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-23",
        "19.968",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-23",
        "19.891",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-23",
        "19.817",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-23",
        "19.745",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-23",
        "19.674",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-23",
        "19.606",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-23",
        "19.540",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-23",
        "19.475",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-23",
        "19.413",
        "8.970",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-23",
        "19.353",
        "8.660",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-23",
        "19.295",
        "8.347",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-23",
        "53.905",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-23",
        "53.693",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-23",
        "53.476",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-23",
        "53.256",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-23",
        "53.031",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-23",
        "52.801",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-23",
        "52.568",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-23",
        "52.331",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-23",
        "52.090",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-23",
        "51.846",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-23",
        "51.598",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-23",
        "51.345",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-23",
        "51.090",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-23",
        "50.831",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-23",
        "50.569",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-23",
        "50.302",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-23",
        "50.034",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-23",
        "49.699",
        "40.565",
        "1.262",
        "1.229",
        "-0.057"
    ],
    [
        "88",
        "-23",
        "49.400",
        "42.136",
        "1.386",
        "1.554",
        "-0.054"
    ],
    [
        "89",
        "-23",
        "49.167",
        "43.549",
        "1.479",
        "1.638",
        "-0.061"
    ],
    [
        "90",
        "-23",
        "48.960",
        "44.786",
        "1.608",
        "1.638",
        "-0.047"
    ],
    [
        "91",
        "-23",
        "48.748",
        "45.965",
        "1.448",
        "1.662",
        "-0.024"
    ],
    [
        "92",
        "-23",
        "48.479",
        "47.217",
        "0.972",
        "1.045",
        "-0.009"
    ],
    [
        "93",
        "-23",
        "48.151",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-23",
        "47.824",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-23",
        "47.491",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-23",
        "47.155",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-23",
        "46.816",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-23",
        "46.474",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-23",
        "46.127",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-23",
        "45.778",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-23",
        "45.426",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-23",
        "45.071",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-23",
        "44.713",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-23",
        "44.352",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-23",
        "43.990",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-23",
        "43.623",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-23",
        "43.255",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-23",
        "42.886",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-23",
        "42.512",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-23",
        "42.138",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-23",
        "41.762",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-23",
        "41.383",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-23",
        "41.003",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-23",
        "40.622",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-23",
        "40.238",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-23",
        "39.855",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-23",
        "39.468",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-23",
        "39.082",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-23",
        "38.694",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-23",
        "38.306",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-23",
        "37.916",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-23",
        "37.525",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-23",
        "37.135",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-23",
        "36.744",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-23",
        "36.352",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-23",
        "35.961",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-23",
        "35.569",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-23",
        "35.178",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-23",
        "34.787",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-23",
        "34.395",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-23",
        "34.005",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-23",
        "33.615",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-23",
        "33.225",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-23",
        "32.836",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-23",
        "32.449",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-23",
        "32.063",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-23",
        "31.677",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-23",
        "31.292",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-23",
        "30.909",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-23",
        "30.528",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-23",
        "30.148",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-23",
        "29.770",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-23",
        "29.394",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-23",
        "29.020",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-23",
        "28.647",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-23",
        "28.277",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-23",
        "27.909",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-23",
        "27.543",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-23",
        "27.180",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-23",
        "26.820",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-23",
        "26.463",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-23",
        "26.108",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-23",
        "25.756",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-23",
        "25.407",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-23",
        "25.062",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-23",
        "24.720",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-23",
        "24.381",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-23",
        "24.045",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-23",
        "23.713",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-23",
        "23.385",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-23",
        "23.061",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-23",
        "22.740",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-23",
        "22.423",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-23",
        "22.111",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-23",
        "21.802",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-23",
        "21.499",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-23",
        "21.199",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-23",
        "20.904",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-23",
        "20.613",
        "42.301",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-23",
        "18.068",
        "34.361",
        "7.224",
        "13.108",
        "0.275"
    ],
    [
        "171",
        "-23",
        "13.087",
        "25.224",
        "8.039",
        "12.512",
        "0.233"
    ],
    [
        "172",
        "-23",
        "17.725",
        "26.336",
        "7.125",
        "11.424",
        "0.239"
    ],
    [
        "173",
        "-23",
        "20.161",
        "37.719",
        "4.337",
        "7.312",
        "0.256"
    ],
    [
        "174",
        "-23",
        "19.230",
        "38.756",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-23",
        "18.968",
        "38.011",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-23",
        "18.711",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-23",
        "18.460",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-23",
        "18.214",
        "35.706",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-23",
        "17.973",
        "34.916",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-22",
        "18.742",
        "34.115",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-22",
        "18.521",
        "33.303",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-22",
        "20.150",
        "30.921",
        "8.156",
        "6.589",
        "-0.361"
    ],
    [
        "-177",
        "-22",
        "25.071",
        "27.162",
        "16.190",
        "10.909",
        "-0.478"
    ],
    [
        "-176",
        "-22",
        "37.091",
        "20.630",
        "20.459",
        "14.767",
        "-0.420"
    ],
    [
        "-175",
        "-22",
        "20.686",
        "15.023",
        "25.567",
        "16.149",
        "-0.316"
    ],
    [
        "-174",
        "-22",
        "-38.695",
        "27.921",
        "24.442",
        "14.849",
        "-0.356"
    ],
    [
        "-173",
        "-22",
        "-58.462",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-22",
        "-58.661",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-22",
        "-58.860",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-22",
        "-59.059",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-22",
        "-59.260",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-22",
        "-59.460",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-22",
        "-59.661",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-22",
        "-59.862",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-22",
        "-60.063",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-22",
        "-60.265",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-22",
        "-60.467",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-22",
        "-60.668",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-22",
        "-60.870",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-22",
        "-61.071",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-22",
        "-61.273",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-22",
        "-61.474",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-22",
        "-61.675",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-22",
        "-61.875",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-22",
        "-62.076",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-22",
        "-62.275",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-22",
        "-62.475",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-22",
        "-62.673",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-22",
        "-62.871",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-22",
        "-63.069",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-22",
        "-63.265",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-22",
        "-63.461",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-22",
        "-63.656",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-22",
        "-63.850",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-22",
        "-64.043",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-22",
        "-64.235",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-22",
        "-64.425",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-22",
        "-64.615",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-22",
        "-64.803",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-22",
        "-64.990",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-22",
        "-65.176",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-22",
        "-65.360",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-22",
        "-65.543",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-22",
        "-65.725",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-22",
        "-65.905",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-22",
        "-66.083",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-22",
        "-66.259",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-22",
        "-66.434",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-22",
        "-66.607",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-22",
        "-66.778",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-22",
        "-66.948",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-22",
        "-67.115",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-22",
        "-67.280",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-22",
        "-67.444",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-22",
        "-67.605",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-22",
        "-67.764",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-22",
        "-67.921",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-22",
        "-68.076",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-22",
        "-68.228",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-22",
        "-68.378",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-22",
        "-68.526",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-22",
        "-68.671",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-22",
        "-68.814",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-22",
        "-68.954",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-22",
        "-69.092",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-22",
        "-23.559",
        "24.628",
        "11.230",
        "12.128",
        "-0.071"
    ],
    [
        "-113",
        "-22",
        "56.337",
        "-14.626",
        "3.107",
        "4.895",
        "0.008"
    ],
    [
        "-112",
        "-22",
        "65.591",
        "-12.070",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-22",
        "65.667",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-22",
        "65.738",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-22",
        "65.802",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-22",
        "65.862",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-22",
        "65.916",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-22",
        "65.964",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-22",
        "66.007",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-22",
        "66.043",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-22",
        "66.074",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-22",
        "66.100",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-22",
        "66.120",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-22",
        "66.134",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-22",
        "66.142",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-22",
        "66.145",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-22",
        "66.142",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-22",
        "66.134",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-22",
        "66.119",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-22",
        "66.099",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-22",
        "66.073",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-22",
        "66.042",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-22",
        "66.005",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-22",
        "65.961",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-22",
        "65.913",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-22",
        "65.859",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-22",
        "65.800",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-22",
        "65.735",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-22",
        "65.664",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-22",
        "65.587",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-22",
        "65.505",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-22",
        "65.418",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-22",
        "65.325",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-22",
        "65.226",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-22",
        "65.123",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-22",
        "65.013",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-22",
        "64.899",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-22",
        "64.779",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-22",
        "64.654",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-22",
        "64.524",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-22",
        "64.388",
        "21.137",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-22",
        "64.247",
        "21.924",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-22",
        "43.226",
        "20.398",
        "8.483",
        "4.867",
        "-0.115"
    ],
    [
        "-70",
        "-22",
        "24.197",
        "18.432",
        "4.625",
        "3.225",
        "-0.059"
    ],
    [
        "-69",
        "-22",
        "13.582",
        "16.087",
        "4.604",
        "2.447",
        "-0.128"
    ],
    [
        "-68",
        "-22",
        "8.097",
        "14.519",
        "2.585",
        "1.864",
        "0.058"
    ],
    [
        "-67",
        "-22",
        "5.488",
        "13.489",
        "2.214",
        "1.879",
        "0.051"
    ],
    [
        "-66",
        "-22",
        "3.970",
        "12.312",
        "2.038",
        "1.730",
        "0.029"
    ],
    [
        "-65",
        "-22",
        "2.561",
        "11.072",
        "1.645",
        "1.504",
        "0.030"
    ],
    [
        "-64",
        "-22",
        "0.757",
        "10.316",
        "1.454",
        "1.421",
        "-0.002"
    ],
    [
        "-63",
        "-22",
        "-0.665",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-22",
        "-0.733",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-22",
        "-0.801",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-22",
        "-0.869",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-22",
        "-0.939",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-22",
        "-1.009",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-22",
        "-1.081",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-22",
        "-1.152",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-22",
        "-1.225",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-22",
        "-1.298",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-22",
        "-1.371",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-22",
        "-1.445",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-22",
        "-1.520",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-22",
        "-1.595",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-22",
        "-1.671",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-22",
        "-1.747",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-22",
        "-1.824",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-22",
        "-1.901",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-22",
        "-1.979",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-22",
        "-2.057",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-22",
        "-2.136",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-22",
        "-2.214",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-22",
        "-2.294",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-22",
        "-2.373",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-22",
        "-2.453",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-22",
        "-2.533",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-22",
        "-2.614",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-22",
        "-2.693",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-22",
        "-2.774",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-22",
        "-2.855",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-22",
        "-2.937",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-22",
        "-3.017",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-22",
        "-3.099",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-22",
        "-3.181",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-22",
        "-3.261",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-22",
        "-3.343",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-22",
        "-3.424",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-22",
        "-3.505",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-22",
        "-3.587",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-22",
        "-3.668",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-22",
        "-3.749",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-22",
        "-3.830",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-22",
        "-3.911",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-22",
        "-3.992",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-22",
        "-4.072",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-22",
        "-4.152",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-22",
        "-4.232",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-22",
        "-4.312",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-22",
        "-4.391",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-22",
        "-4.470",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-22",
        "-4.549",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-22",
        "17.525",
        "17.071",
        "5.201",
        "2.597",
        "0.130"
    ],
    [
        "-11",
        "-22",
        "24.990",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-22",
        "24.870",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-22",
        "24.749",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-22",
        "24.629",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-22",
        "24.506",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-22",
        "24.383",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-22",
        "24.260",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-22",
        "24.136",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-22",
        "24.012",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-22",
        "23.886",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-22",
        "23.761",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-22",
        "23.635",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-22",
        "23.509",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-22",
        "23.383",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-22",
        "23.256",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-22",
        "23.128",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-22",
        "23.002",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-22",
        "22.874",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-22",
        "22.747",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-22",
        "22.618",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-22",
        "22.490",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-22",
        "22.363",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-22",
        "22.235",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-22",
        "22.108",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-22",
        "21.979",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-22",
        "21.852",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-22",
        "21.724",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-22",
        "21.597",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-22",
        "21.470",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-22",
        "21.344",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-22",
        "21.217",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-22",
        "21.091",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-22",
        "20.965",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-22",
        "20.840",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-22",
        "20.715",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-22",
        "20.592",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-22",
        "20.468",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-22",
        "20.345",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-22",
        "20.222",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-22",
        "20.166",
        "18.560",
        "0.493",
        "0.529",
        "0.000"
    ],
    [
        "29",
        "-22",
        "20.240",
        "18.399",
        "0.760",
        "0.876",
        "0.002"
    ],
    [
        "30",
        "-22",
        "20.310",
        "18.228",
        "0.890",
        "0.923",
        "0.003"
    ],
    [
        "31",
        "-22",
        "20.379",
        "18.047",
        "0.886",
        "0.988",
        "0.002"
    ],
    [
        "32",
        "-22",
        "20.445",
        "17.855",
        "0.913",
        "1.004",
        "0.001"
    ],
    [
        "33",
        "-22",
        "20.511",
        "17.657",
        "0.973",
        "0.992",
        "0.001"
    ],
    [
        "34",
        "-22",
        "20.577",
        "17.453",
        "0.946",
        "1.031",
        "0.000"
    ],
    [
        "35",
        "-22",
        "20.643",
        "17.241",
        "0.960",
        "1.049",
        "-0.001"
    ],
    [
        "36",
        "-22",
        "20.709",
        "17.020",
        "1.011",
        "1.040",
        "-0.003"
    ],
    [
        "37",
        "-22",
        "20.775",
        "16.795",
        "0.974",
        "1.072",
        "-0.005"
    ],
    [
        "38",
        "-22",
        "20.840",
        "16.565",
        "0.974",
        "1.074",
        "-0.007"
    ],
    [
        "39",
        "-22",
        "20.906",
        "16.331",
        "1.011",
        "1.043",
        "-0.007"
    ],
    [
        "40",
        "-22",
        "20.973",
        "16.094",
        "0.959",
        "1.057",
        "-0.006"
    ],
    [
        "41",
        "-22",
        "21.040",
        "15.856",
        "0.943",
        "1.039",
        "0.000"
    ],
    [
        "42",
        "-22",
        "21.106",
        "15.620",
        "0.964",
        "0.986",
        "0.008"
    ],
    [
        "43",
        "-22",
        "21.170",
        "15.384",
        "0.890",
        "0.975",
        "0.017"
    ],
    [
        "44",
        "-22",
        "21.235",
        "15.152",
        "0.850",
        "0.930",
        "0.026"
    ],
    [
        "45",
        "-22",
        "21.302",
        "14.923",
        "0.838",
        "0.845",
        "0.038"
    ],
    [
        "46",
        "-22",
        "21.376",
        "14.697",
        "0.677",
        "0.758",
        "0.038"
    ],
    [
        "47",
        "-22",
        "21.438",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-22",
        "21.344",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-22",
        "21.252",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-22",
        "21.161",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-22",
        "21.072",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-22",
        "20.984",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-22",
        "20.899",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-22",
        "20.814",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-22",
        "20.732",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-22",
        "20.651",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-22",
        "20.573",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-22",
        "20.496",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-22",
        "20.421",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-22",
        "20.348",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-22",
        "20.276",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-22",
        "20.208",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-22",
        "20.139",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-22",
        "20.074",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-22",
        "20.010",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-22",
        "19.949",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-22",
        "19.889",
        "8.970",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-22",
        "19.831",
        "8.660",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-22",
        "40.009",
        "22.333",
        "13.935",
        "10.620",
        "0.621"
    ],
    [
        "70",
        "-22",
        "53.607",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-22",
        "53.403",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-22",
        "53.195",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-22",
        "52.983",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-22",
        "52.768",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-22",
        "52.548",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-22",
        "52.324",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-22",
        "52.097",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-22",
        "51.866",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-22",
        "51.631",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-22",
        "51.393",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-22",
        "51.152",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-22",
        "50.907",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-22",
        "50.658",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-22",
        "50.408",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-22",
        "50.153",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-22",
        "49.895",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-22",
        "49.563",
        "40.375",
        "1.271",
        "1.253",
        "-0.030"
    ],
    [
        "88",
        "-22",
        "49.292",
        "41.749",
        "1.433",
        "1.627",
        "0.007"
    ],
    [
        "89",
        "-22",
        "49.024",
        "43.137",
        "1.558",
        "1.742",
        "-0.011"
    ],
    [
        "90",
        "-22",
        "48.773",
        "44.415",
        "1.678",
        "1.716",
        "-0.009"
    ],
    [
        "91",
        "-22",
        "48.517",
        "45.682",
        "1.487",
        "1.714",
        "0.001"
    ],
    [
        "92",
        "-22",
        "48.221",
        "47.151",
        "0.983",
        "1.056",
        "-0.001"
    ],
    [
        "93",
        "-22",
        "47.901",
        "47.911",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "-22",
        "47.586",
        "48.456",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "-22",
        "47.267",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-22",
        "46.946",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-22",
        "46.621",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-22",
        "46.292",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-22",
        "45.960",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-22",
        "45.625",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-22",
        "45.288",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-22",
        "44.947",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-22",
        "44.604",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-22",
        "44.259",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-22",
        "43.911",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-22",
        "43.560",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-22",
        "43.207",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-22",
        "42.852",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-22",
        "42.495",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-22",
        "42.136",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-22",
        "41.775",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-22",
        "41.412",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-22",
        "41.048",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-22",
        "40.682",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-22",
        "40.315",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-22",
        "39.946",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-22",
        "39.576",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-22",
        "39.205",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-22",
        "38.833",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-22",
        "38.460",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-22",
        "38.087",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-22",
        "37.713",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-22",
        "37.339",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-22",
        "36.964",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-22",
        "36.589",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-22",
        "36.213",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-22",
        "35.838",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-22",
        "35.462",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-22",
        "35.087",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-22",
        "34.713",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-22",
        "34.338",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-22",
        "33.964",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-22",
        "33.591",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-22",
        "33.218",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-22",
        "32.846",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-22",
        "32.476",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-22",
        "32.105",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-22",
        "31.738",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-22",
        "31.370",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-22",
        "31.005",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-22",
        "30.640",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-22",
        "30.278",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-22",
        "29.917",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-22",
        "29.558",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-22",
        "29.201",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-22",
        "28.846",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-22",
        "28.493",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-22",
        "28.143",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-22",
        "27.795",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-22",
        "27.450",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-22",
        "27.107",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-22",
        "26.767",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-22",
        "26.430",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-22",
        "26.095",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-22",
        "25.764",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-22",
        "25.436",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-22",
        "25.111",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-22",
        "24.790",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-22",
        "24.471",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-22",
        "24.156",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-22",
        "23.846",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-22",
        "23.538",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-22",
        "23.235",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-22",
        "22.935",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-22",
        "22.639",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-22",
        "22.348",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-22",
        "22.060",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-22",
        "21.777",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-22",
        "18.254",
        "38.185",
        "6.797",
        "9.086",
        "0.330"
    ],
    [
        "170",
        "-22",
        "10.080",
        "23.471",
        "9.530",
        "12.341",
        "0.283"
    ],
    [
        "171",
        "-22",
        "5.703",
        "8.749",
        "8.363",
        "9.899",
        "0.330"
    ],
    [
        "172",
        "-22",
        "6.139",
        "15.743",
        "6.732",
        "9.369",
        "0.174"
    ],
    [
        "173",
        "-22",
        "11.951",
        "29.206",
        "5.457",
        "7.903",
        "0.247"
    ],
    [
        "174",
        "-22",
        "17.481",
        "34.771",
        "3.904",
        "5.013",
        "0.330"
    ],
    [
        "175",
        "-22",
        "19.932",
        "37.359",
        "2.001",
        "3.122",
        "0.167"
    ],
    [
        "176",
        "-22",
        "19.676",
        "37.253",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-22",
        "19.435",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-22",
        "19.198",
        "35.706",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-22",
        "18.968",
        "34.916",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-21",
        "19.741",
        "34.115",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-21",
        "19.529",
        "33.303",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-21",
        "20.366",
        "31.856",
        "9.071",
        "7.947",
        "-0.358"
    ],
    [
        "-177",
        "-21",
        "22.514",
        "29.280",
        "17.248",
        "12.213",
        "-0.476"
    ],
    [
        "-176",
        "-21",
        "31.286",
        "24.630",
        "22.171",
        "16.216",
        "-0.426"
    ],
    [
        "-175",
        "-21",
        "50.301",
        "17.089",
        "16.406",
        "15.834",
        "-0.232"
    ],
    [
        "-174",
        "-21",
        "-3.665",
        "22.123",
        "31.597",
        "18.371",
        "-0.467"
    ],
    [
        "-173",
        "-21",
        "-55.563",
        "29.053",
        "6.746",
        "6.838",
        "-0.337"
    ],
    [
        "-172",
        "-21",
        "-59.162",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-21",
        "-59.353",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-21",
        "-59.544",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-21",
        "-59.735",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-21",
        "-59.927",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-21",
        "-60.119",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-21",
        "-60.311",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-21",
        "-60.504",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-21",
        "-60.697",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-21",
        "-60.890",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-21",
        "-61.083",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-21",
        "-61.276",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-21",
        "-61.468",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-21",
        "-61.661",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-21",
        "-61.853",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-21",
        "-62.046",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-21",
        "-62.238",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-21",
        "-62.429",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-21",
        "-62.620",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-21",
        "-62.811",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-21",
        "-63.001",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-21",
        "-63.190",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-21",
        "-63.379",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-21",
        "-63.567",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-21",
        "-63.754",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-21",
        "-63.941",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-21",
        "-64.126",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-21",
        "-64.311",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-21",
        "-64.494",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-21",
        "-64.677",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-21",
        "-64.858",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-21",
        "-65.038",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-21",
        "-65.217",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-21",
        "-65.395",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-21",
        "-65.571",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-21",
        "-65.746",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-21",
        "-65.920",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-21",
        "-66.092",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-21",
        "-66.262",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-21",
        "-66.431",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-21",
        "-66.599",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-21",
        "-66.764",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-21",
        "-66.928",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-21",
        "-67.090",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-21",
        "-67.250",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-21",
        "-67.408",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-21",
        "-67.564",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-21",
        "-67.719",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-21",
        "-67.871",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-21",
        "-68.021",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-21",
        "-68.169",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-21",
        "-68.315",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-21",
        "-68.458",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-21",
        "-68.600",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-21",
        "-68.739",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-21",
        "-68.875",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-21",
        "-69.009",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-21",
        "-69.141",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-21",
        "-7.053",
        "-1.646",
        "10.366",
        "12.950",
        "-0.078"
    ],
    [
        "-113",
        "-21",
        "65.053",
        "-12.914",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-21",
        "65.131",
        "-12.070",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-21",
        "65.204",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-21",
        "65.271",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-21",
        "65.334",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-21",
        "65.390",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-21",
        "65.441",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-21",
        "65.488",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-21",
        "65.529",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-21",
        "65.563",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-21",
        "65.593",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-21",
        "65.618",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-21",
        "65.637",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-21",
        "65.650",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-21",
        "65.658",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-21",
        "65.660",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-21",
        "65.658",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-21",
        "65.650",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-21",
        "65.636",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-21",
        "65.617",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-21",
        "65.592",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-21",
        "65.562",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-21",
        "65.527",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-21",
        "65.486",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-21",
        "65.439",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-21",
        "65.388",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-21",
        "65.331",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-21",
        "65.268",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-21",
        "65.200",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-21",
        "65.127",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-21",
        "65.048",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-21",
        "64.966",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-21",
        "64.876",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-21",
        "64.782",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-21",
        "64.683",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-21",
        "64.579",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-21",
        "64.469",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-21",
        "64.354",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-21",
        "64.234",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-21",
        "64.109",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-21",
        "63.980",
        "21.137",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-21",
        "63.845",
        "21.924",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-21",
        "44.280",
        "20.440",
        "8.589",
        "5.089",
        "0.032"
    ],
    [
        "-70",
        "-21",
        "27.421",
        "18.970",
        "4.186",
        "2.618",
        "0.008"
    ],
    [
        "-69",
        "-21",
        "17.741",
        "16.828",
        "3.501",
        "2.178",
        "-0.168"
    ],
    [
        "-68",
        "-21",
        "9.727",
        "14.628",
        "2.288",
        "1.672",
        "0.003"
    ],
    [
        "-67",
        "-21",
        "6.732",
        "14.242",
        "2.109",
        "1.801",
        "0.026"
    ],
    [
        "-66",
        "-21",
        "4.826",
        "12.766",
        "2.084",
        "1.784",
        "0.044"
    ],
    [
        "-65",
        "-21",
        "3.040",
        "11.332",
        "1.821",
        "1.736",
        "0.020"
    ],
    [
        "-64",
        "-21",
        "1.256",
        "10.581",
        "1.595",
        "1.568",
        "-0.015"
    ],
    [
        "-63",
        "-21",
        "-0.803",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-21",
        "-0.868",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-21",
        "-0.933",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-21",
        "-0.999",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-21",
        "-1.066",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-21",
        "-1.132",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-21",
        "-1.201",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-21",
        "-1.269",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-21",
        "-1.339",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-21",
        "-1.408",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-21",
        "-1.479",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-21",
        "-1.549",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-21",
        "-1.621",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-21",
        "-1.693",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-21",
        "-1.765",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-21",
        "-1.838",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-21",
        "-1.912",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-21",
        "-1.985",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-21",
        "-2.060",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-21",
        "-2.135",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-21",
        "-2.210",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-21",
        "-2.285",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-21",
        "-2.361",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-21",
        "-2.437",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-21",
        "-2.514",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-21",
        "-2.590",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-21",
        "-2.668",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-21",
        "-2.744",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-21",
        "-2.822",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-21",
        "-2.899",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-21",
        "-2.977",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-21",
        "-3.054",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-21",
        "-3.132",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-21",
        "-3.210",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-21",
        "-3.288",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-21",
        "-3.365",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-21",
        "-3.443",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-21",
        "-3.521",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-21",
        "-3.598",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-21",
        "-3.676",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-21",
        "-3.754",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-21",
        "-3.831",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-21",
        "-3.908",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-21",
        "-3.985",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-21",
        "-4.063",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-21",
        "-4.140",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-21",
        "-4.216",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-21",
        "-4.292",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-21",
        "-4.368",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-21",
        "-4.444",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-21",
        "-4.519",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-21",
        "8.054",
        "15.308",
        "6.133",
        "3.029",
        "0.213"
    ],
    [
        "-11",
        "-21",
        "25.033",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-21",
        "24.918",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-21",
        "24.802",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-21",
        "24.686",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-21",
        "24.569",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-21",
        "24.452",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-21",
        "24.333",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-21",
        "24.215",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-21",
        "24.096",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-21",
        "23.976",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-21",
        "23.856",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-21",
        "23.736",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-21",
        "23.615",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-21",
        "23.495",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-21",
        "23.373",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-21",
        "23.252",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-21",
        "23.130",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-21",
        "23.007",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-21",
        "22.886",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-21",
        "22.764",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-21",
        "22.641",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-21",
        "22.519",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-21",
        "22.397",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-21",
        "22.274",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-21",
        "22.152",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-21",
        "22.030",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-21",
        "21.908",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-21",
        "21.786",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-21",
        "21.665",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-21",
        "21.544",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-21",
        "21.423",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-21",
        "21.302",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-21",
        "21.183",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-21",
        "21.062",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-21",
        "20.943",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-21",
        "20.824",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-21",
        "20.706",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-21",
        "20.589",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-21",
        "20.471",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-21",
        "20.421",
        "18.560",
        "0.493",
        "0.528",
        "0.000"
    ],
    [
        "29",
        "-21",
        "20.505",
        "18.392",
        "0.760",
        "0.877",
        "0.001"
    ],
    [
        "30",
        "-21",
        "20.585",
        "18.218",
        "0.890",
        "0.926",
        "0.002"
    ],
    [
        "31",
        "-21",
        "20.666",
        "18.036",
        "0.886",
        "0.992",
        "0.002"
    ],
    [
        "32",
        "-21",
        "20.744",
        "17.845",
        "0.912",
        "1.007",
        "0.001"
    ],
    [
        "33",
        "-21",
        "20.821",
        "17.648",
        "0.972",
        "0.994",
        "0.000"
    ],
    [
        "34",
        "-21",
        "20.898",
        "17.447",
        "0.946",
        "1.031",
        "-0.002"
    ],
    [
        "35",
        "-21",
        "20.974",
        "17.238",
        "0.960",
        "1.051",
        "-0.003"
    ],
    [
        "36",
        "-21",
        "21.049",
        "17.022",
        "1.013",
        "1.044",
        "-0.004"
    ],
    [
        "37",
        "-21",
        "21.123",
        "16.800",
        "0.978",
        "1.079",
        "-0.006"
    ],
    [
        "38",
        "-21",
        "21.197",
        "16.573",
        "0.977",
        "1.080",
        "-0.008"
    ],
    [
        "39",
        "-21",
        "21.270",
        "16.341",
        "1.014",
        "1.048",
        "-0.009"
    ],
    [
        "40",
        "-21",
        "21.343",
        "16.107",
        "0.963",
        "1.061",
        "-0.008"
    ],
    [
        "41",
        "-21",
        "21.415",
        "15.870",
        "0.949",
        "1.043",
        "-0.006"
    ],
    [
        "42",
        "-21",
        "21.485",
        "15.634",
        "0.972",
        "0.989",
        "0.000"
    ],
    [
        "43",
        "-21",
        "21.552",
        "15.397",
        "0.901",
        "0.981",
        "0.007"
    ],
    [
        "44",
        "-21",
        "21.616",
        "15.161",
        "0.866",
        "0.939",
        "0.016"
    ],
    [
        "45",
        "-21",
        "21.680",
        "14.926",
        "0.864",
        "0.861",
        "0.028"
    ],
    [
        "46",
        "-21",
        "21.740",
        "14.697",
        "0.734",
        "0.812",
        "0.029"
    ],
    [
        "47",
        "-21",
        "21.796",
        "14.476",
        "0.481",
        "0.506",
        "-0.002"
    ],
    [
        "48",
        "-21",
        "21.751",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-21",
        "21.663",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-21",
        "21.575",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-21",
        "21.490",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-21",
        "21.406",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-21",
        "21.325",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-21",
        "21.245",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-21",
        "21.166",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-21",
        "21.088",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-21",
        "21.013",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-21",
        "20.939",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-21",
        "20.868",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-21",
        "20.798",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-21",
        "20.730",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-21",
        "20.663",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-21",
        "20.599",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-21",
        "20.536",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-21",
        "20.475",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-21",
        "20.416",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-21",
        "20.359",
        "8.970",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-21",
        "20.304",
        "8.660",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-21",
        "53.482",
        "30.190",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-21",
        "53.291",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-21",
        "53.096",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-21",
        "52.898",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-21",
        "52.695",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-21",
        "52.489",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-21",
        "52.278",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-21",
        "52.065",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-21",
        "51.847",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-21",
        "51.627",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-21",
        "51.402",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-21",
        "51.174",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-21",
        "50.943",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-21",
        "50.709",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-21",
        "50.471",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-21",
        "50.231",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-21",
        "49.987",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-21",
        "49.740",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-21",
        "49.490",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-21",
        "49.152",
        "41.454",
        "1.401",
        "1.618",
        "0.005"
    ],
    [
        "89",
        "-21",
        "48.853",
        "42.808",
        "1.578",
        "1.780",
        "0.007"
    ],
    [
        "90",
        "-21",
        "48.565",
        "44.078",
        "1.716",
        "1.754",
        "0.013"
    ],
    [
        "91",
        "-21",
        "48.263",
        "45.345",
        "1.565",
        "1.765",
        "0.030"
    ],
    [
        "92",
        "-21",
        "47.986",
        "46.691",
        "1.344",
        "1.519",
        "0.016"
    ],
    [
        "93",
        "-21",
        "47.712",
        "47.686",
        "1.294",
        "1.314",
        "-0.010"
    ],
    [
        "94",
        "-21",
        "47.346",
        "48.443",
        "0.318",
        "0.480",
        "0.004"
    ],
    [
        "95",
        "-21",
        "47.030",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-21",
        "46.722",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-21",
        "46.410",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-21",
        "46.096",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-21",
        "45.779",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-21",
        "45.459",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-21",
        "45.135",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-21",
        "44.810",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-21",
        "44.482",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-21",
        "44.152",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-21",
        "43.818",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-21",
        "43.483",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-21",
        "43.145",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-21",
        "42.805",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-21",
        "42.464",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-21",
        "42.121",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-21",
        "41.775",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-21",
        "41.428",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-21",
        "41.079",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-21",
        "40.729",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-21",
        "40.378",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-21",
        "40.026",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-21",
        "39.672",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-21",
        "39.317",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-21",
        "38.961",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-21",
        "38.604",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-21",
        "38.247",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-21",
        "37.890",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-21",
        "37.531",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-21",
        "37.172",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-21",
        "36.814",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-21",
        "36.455",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-21",
        "36.095",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-21",
        "35.737",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-21",
        "35.378",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-21",
        "35.019",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-21",
        "34.661",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-21",
        "34.303",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-21",
        "33.945",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-21",
        "33.589",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-21",
        "33.234",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-21",
        "32.879",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-21",
        "32.525",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-21",
        "32.173",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-21",
        "31.822",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-21",
        "31.472",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-21",
        "31.123",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-21",
        "30.776",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-21",
        "30.431",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-21",
        "30.088",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-21",
        "29.746",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-21",
        "29.408",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-21",
        "29.069",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-21",
        "28.735",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-21",
        "28.401",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-21",
        "28.071",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-21",
        "27.743",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-21",
        "27.418",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-21",
        "27.095",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-21",
        "26.776",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-21",
        "26.459",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-21",
        "26.145",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-21",
        "25.833",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-21",
        "25.526",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-21",
        "25.222",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-21",
        "24.920",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-21",
        "24.623",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-21",
        "24.329",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-21",
        "24.038",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-21",
        "23.752",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-21",
        "23.469",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-21",
        "23.191",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-21",
        "22.915",
        "43.631",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-21",
        "22.644",
        "42.972",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-21",
        "-5.516",
        "21.365",
        "11.383",
        "9.626",
        "0.298"
    ],
    [
        "170",
        "-21",
        "-16.000",
        "15.336",
        "10.558",
        "8.647",
        "0.320"
    ],
    [
        "171",
        "-21",
        "-8.579",
        "16.244",
        "8.974",
        "7.814",
        "0.345"
    ],
    [
        "172",
        "-21",
        "-0.031",
        "15.804",
        "5.886",
        "6.761",
        "0.243"
    ],
    [
        "173",
        "-21",
        "6.884",
        "21.134",
        "4.175",
        "5.164",
        "0.263"
    ],
    [
        "174",
        "-21",
        "11.957",
        "27.018",
        "3.036",
        "3.560",
        "0.267"
    ],
    [
        "175",
        "-21",
        "15.970",
        "32.680",
        "1.950",
        "2.500",
        "0.372"
    ],
    [
        "176",
        "-21",
        "19.156",
        "36.646",
        "1.200",
        "1.531",
        "0.240"
    ],
    [
        "177",
        "-21",
        "20.404",
        "36.485",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-21",
        "20.177",
        "35.706",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-21",
        "19.956",
        "34.916",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-20",
        "20.733",
        "34.115",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-20",
        "20.532",
        "33.303",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-20",
        "19.471",
        "33.293",
        "9.655",
        "9.599",
        "-0.317"
    ],
    [
        "-177",
        "-20",
        "16.543",
        "34.159",
        "18.703",
        "14.281",
        "-0.463"
    ],
    [
        "-176",
        "-20",
        "22.445",
        "30.000",
        "23.811",
        "18.784",
        "-0.451"
    ],
    [
        "-175",
        "-20",
        "38.807",
        "22.482",
        "26.132",
        "20.243",
        "-0.411"
    ],
    [
        "-174",
        "-20",
        "27.669",
        "16.883",
        "31.769",
        "19.737",
        "-0.438"
    ],
    [
        "-173",
        "-20",
        "-33.124",
        "25.519",
        "23.095",
        "15.314",
        "-0.260"
    ],
    [
        "-172",
        "-20",
        "-59.646",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-20",
        "-59.827",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-20",
        "-60.010",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-20",
        "-60.192",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-20",
        "-60.375",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-20",
        "-60.559",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-20",
        "-60.742",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-20",
        "-60.926",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-20",
        "-61.110",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-20",
        "-61.294",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-20",
        "-61.478",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-20",
        "-61.663",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-20",
        "-61.847",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-20",
        "-62.030",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-20",
        "-62.214",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-20",
        "-62.398",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-20",
        "-62.581",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-20",
        "-62.763",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-20",
        "-62.946",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-20",
        "-63.128",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-20",
        "-63.309",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-20",
        "-63.490",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-20",
        "-63.670",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-20",
        "-63.849",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-20",
        "-64.028",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-20",
        "-64.206",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-20",
        "-64.383",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-20",
        "-64.559",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-20",
        "-64.734",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-20",
        "-64.909",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-20",
        "-65.082",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-20",
        "-65.254",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-20",
        "-65.425",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-20",
        "-65.594",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-20",
        "-65.762",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-20",
        "-65.929",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-20",
        "-66.095",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-20",
        "-66.259",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-20",
        "-66.422",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-20",
        "-66.583",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-20",
        "-66.743",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-20",
        "-66.901",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-20",
        "-67.057",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-20",
        "-67.212",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-20",
        "-67.364",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-20",
        "-67.515",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-20",
        "-67.664",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-20",
        "-67.812",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-20",
        "-67.957",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-20",
        "-68.100",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-20",
        "-68.241",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-20",
        "-68.381",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-20",
        "-68.518",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-20",
        "-68.652",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-20",
        "-68.785",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-20",
        "-68.915",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-20",
        "-69.044",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-20",
        "-69.169",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-20",
        "-69.293",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-20",
        "64.576",
        "-12.914",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-20",
        "64.651",
        "-12.070",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-20",
        "64.720",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-20",
        "64.784",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-20",
        "64.844",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-20",
        "64.899",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-20",
        "64.948",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-20",
        "64.991",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-20",
        "65.030",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-20",
        "65.063",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-20",
        "65.092",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-20",
        "65.116",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-20",
        "65.133",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-20",
        "65.147",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-20",
        "65.154",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-20",
        "65.157",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-20",
        "65.154",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-20",
        "65.145",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-20",
        "65.132",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-20",
        "65.114",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-20",
        "65.091",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-20",
        "65.063",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-20",
        "65.028",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-20",
        "64.989",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-20",
        "64.945",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-20",
        "64.896",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-20",
        "64.841",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-20",
        "64.781",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-20",
        "64.717",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-20",
        "64.647",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-20",
        "64.573",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-20",
        "64.492",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-20",
        "64.407",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-20",
        "64.318",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-20",
        "64.224",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-20",
        "64.123",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-20",
        "64.019",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-20",
        "63.910",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-20",
        "63.796",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-20",
        "63.676",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-20",
        "63.552",
        "21.137",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-20",
        "63.424",
        "21.924",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-20",
        "42.302",
        "20.102",
        "8.929",
        "5.706",
        "0.239"
    ],
    [
        "-70",
        "-20",
        "20.173",
        "13.461",
        "3.565",
        "2.334",
        "-0.002"
    ],
    [
        "-69",
        "-20",
        "15.545",
        "16.050",
        "3.792",
        "2.274",
        "0.169"
    ],
    [
        "-68",
        "-20",
        "9.323",
        "14.710",
        "2.188",
        "1.757",
        "0.056"
    ],
    [
        "-67",
        "-20",
        "7.054",
        "15.419",
        "1.896",
        "1.445",
        "0.006"
    ],
    [
        "-66",
        "-20",
        "5.251",
        "13.419",
        "1.938",
        "1.560",
        "0.020"
    ],
    [
        "-65",
        "-20",
        "3.198",
        "11.652",
        "1.769",
        "1.655",
        "0.029"
    ],
    [
        "-64",
        "-20",
        "1.214",
        "10.604",
        "1.594",
        "1.488",
        "0.032"
    ],
    [
        "-63",
        "-20",
        "-0.508",
        "10.250",
        "1.346",
        "1.101",
        "0.003"
    ],
    [
        "-62",
        "-20",
        "-1.003",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-20",
        "-1.065",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-20",
        "-1.128",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-20",
        "-1.192",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-20",
        "-1.255",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-20",
        "-1.320",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-20",
        "-1.385",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-20",
        "-1.452",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-20",
        "-1.518",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-20",
        "-1.586",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-20",
        "-1.653",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-20",
        "-1.721",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-20",
        "-1.791",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-20",
        "-1.860",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-20",
        "-1.929",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-20",
        "-2.000",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-20",
        "-2.070",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-20",
        "-2.141",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-20",
        "-2.212",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-20",
        "-2.284",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-20",
        "-2.356",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-20",
        "-2.428",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-20",
        "-2.501",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-20",
        "-2.574",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-20",
        "-2.646",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-20",
        "-2.720",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-20",
        "-2.794",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-20",
        "-2.867",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-20",
        "-2.941",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-20",
        "-3.016",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-20",
        "-3.090",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-20",
        "-3.163",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-20",
        "-3.238",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-20",
        "-3.312",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-20",
        "-3.386",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-20",
        "-3.461",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-20",
        "-3.535",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-20",
        "-3.609",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-20",
        "-3.684",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-20",
        "-3.757",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-20",
        "-3.831",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-20",
        "-3.905",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-20",
        "-3.978",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-20",
        "-4.052",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-20",
        "-4.126",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-20",
        "-4.198",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-20",
        "-4.271",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-20",
        "-4.343",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-20",
        "-4.415",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-20",
        "-4.488",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-20",
        "13.496",
        "16.242",
        "5.492",
        "2.683",
        "0.369"
    ],
    [
        "-11",
        "-20",
        "25.067",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-20",
        "24.957",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-20",
        "24.847",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-20",
        "24.736",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-20",
        "24.625",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-20",
        "24.512",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-20",
        "24.399",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-20",
        "24.287",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-20",
        "24.173",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-20",
        "24.059",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-20",
        "23.945",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-20",
        "23.830",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-20",
        "23.715",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-20",
        "23.599",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-20",
        "23.483",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-20",
        "23.367",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-20",
        "23.251",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-20",
        "23.134",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-20",
        "23.018",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-20",
        "22.901",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-20",
        "22.785",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-20",
        "22.667",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-20",
        "22.551",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-20",
        "22.434",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-20",
        "22.318",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-20",
        "22.201",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-20",
        "22.085",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-20",
        "21.969",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-20",
        "21.853",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-20",
        "21.737",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-20",
        "21.622",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-20",
        "21.507",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-20",
        "21.392",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-20",
        "21.278",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-20",
        "21.164",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-20",
        "21.050",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-20",
        "20.938",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-20",
        "20.826",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-20",
        "20.713",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-20",
        "20.673",
        "18.559",
        "0.494",
        "0.530",
        "-0.001"
    ],
    [
        "29",
        "-20",
        "20.771",
        "18.385",
        "0.763",
        "0.881",
        "-0.002"
    ],
    [
        "30",
        "-20",
        "20.862",
        "18.209",
        "0.893",
        "0.933",
        "0.000"
    ],
    [
        "31",
        "-20",
        "20.950",
        "18.027",
        "0.890",
        "1.002",
        "0.002"
    ],
    [
        "32",
        "-20",
        "21.039",
        "17.836",
        "0.918",
        "1.020",
        "0.000"
    ],
    [
        "33",
        "-20",
        "21.127",
        "17.640",
        "0.978",
        "1.005",
        "-0.002"
    ],
    [
        "34",
        "-20",
        "21.215",
        "17.440",
        "0.951",
        "1.039",
        "-0.005"
    ],
    [
        "35",
        "-20",
        "21.300",
        "17.235",
        "0.965",
        "1.057",
        "-0.006"
    ],
    [
        "36",
        "-20",
        "21.384",
        "17.023",
        "1.018",
        "1.051",
        "-0.006"
    ],
    [
        "37",
        "-20",
        "21.467",
        "16.805",
        "0.983",
        "1.086",
        "-0.008"
    ],
    [
        "38",
        "-20",
        "21.549",
        "16.580",
        "0.983",
        "1.088",
        "-0.010"
    ],
    [
        "39",
        "-20",
        "21.630",
        "16.351",
        "1.020",
        "1.055",
        "-0.011"
    ],
    [
        "40",
        "-20",
        "21.710",
        "16.118",
        "0.968",
        "1.066",
        "-0.013"
    ],
    [
        "41",
        "-20",
        "21.789",
        "15.883",
        "0.954",
        "1.046",
        "-0.012"
    ],
    [
        "42",
        "-20",
        "21.864",
        "15.648",
        "0.977",
        "0.990",
        "-0.009"
    ],
    [
        "43",
        "-20",
        "21.934",
        "15.410",
        "0.907",
        "0.980",
        "-0.005"
    ],
    [
        "44",
        "-20",
        "21.999",
        "15.171",
        "0.874",
        "0.935",
        "0.005"
    ],
    [
        "45",
        "-20",
        "22.058",
        "14.931",
        "0.877",
        "0.860",
        "0.020"
    ],
    [
        "46",
        "-20",
        "22.114",
        "14.694",
        "0.757",
        "0.827",
        "0.033"
    ],
    [
        "47",
        "-20",
        "22.177",
        "14.472",
        "0.497",
        "0.526",
        "0.012"
    ],
    [
        "48",
        "-20",
        "22.150",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-20",
        "22.066",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-20",
        "21.984",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-20",
        "21.902",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-20",
        "21.823",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-20",
        "21.744",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-20",
        "21.668",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-20",
        "21.592",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-20",
        "21.519",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-20",
        "21.447",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-20",
        "21.377",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-20",
        "21.308",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-20",
        "21.241",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-20",
        "21.176",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-20",
        "21.113",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-20",
        "21.051",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-20",
        "20.991",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-20",
        "20.933",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-20",
        "20.878",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-20",
        "32.397",
        "16.098",
        "9.246",
        "9.403",
        "0.414"
    ],
    [
        "68",
        "-20",
        "37.318",
        "20.308",
        "10.685",
        "10.928",
        "0.463"
    ],
    [
        "69",
        "-20",
        "53.141",
        "30.190",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-20",
        "52.960",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-20",
        "52.774",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-20",
        "52.584",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-20",
        "52.390",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-20",
        "52.193",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-20",
        "51.993",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-20",
        "51.789",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-20",
        "51.582",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-20",
        "51.371",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-20",
        "51.157",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-20",
        "50.939",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-20",
        "50.719",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-20",
        "50.495",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-20",
        "50.268",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-20",
        "50.038",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-20",
        "49.806",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-20",
        "49.571",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-20",
        "49.333",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-20",
        "48.979",
        "41.272",
        "1.394",
        "1.602",
        "-0.001"
    ],
    [
        "89",
        "-20",
        "48.667",
        "42.535",
        "1.577",
        "1.774",
        "0.007"
    ],
    [
        "90",
        "-20",
        "48.367",
        "43.762",
        "1.734",
        "1.761",
        "0.014"
    ],
    [
        "91",
        "-20",
        "48.064",
        "44.969",
        "1.631",
        "1.803",
        "0.025"
    ],
    [
        "92",
        "-20",
        "47.776",
        "46.152",
        "1.576",
        "1.729",
        "0.026"
    ],
    [
        "93",
        "-20",
        "47.491",
        "47.260",
        "1.544",
        "1.543",
        "0.003"
    ],
    [
        "94",
        "-20",
        "47.087",
        "48.426",
        "0.338",
        "0.471",
        "-0.002"
    ],
    [
        "95",
        "-20",
        "46.777",
        "48.986",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "-20",
        "46.484",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-20",
        "46.186",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-20",
        "45.886",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-20",
        "45.584",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-20",
        "45.278",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-20",
        "44.970",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-20",
        "44.660",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-20",
        "44.346",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-20",
        "44.030",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-20",
        "43.712",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-20",
        "43.393",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-20",
        "43.070",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-20",
        "42.746",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-20",
        "42.420",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-20",
        "42.093",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-20",
        "41.762",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-20",
        "41.432",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-20",
        "41.099",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-20",
        "40.765",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-20",
        "40.430",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-20",
        "40.092",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-20",
        "39.755",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-20",
        "39.416",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-20",
        "39.077",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-20",
        "38.736",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-20",
        "38.396",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-20",
        "38.054",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-20",
        "37.713",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-20",
        "37.370",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-20",
        "37.028",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-20",
        "36.685",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-20",
        "36.342",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-20",
        "36.000",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-20",
        "35.657",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-20",
        "35.314",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-20",
        "34.973",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-20",
        "34.631",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-20",
        "34.290",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-20",
        "33.950",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-20",
        "33.611",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-20",
        "33.273",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-20",
        "32.935",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-20",
        "32.599",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-20",
        "32.263",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-20",
        "31.929",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-20",
        "31.597",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-20",
        "31.266",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-20",
        "30.937",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-20",
        "30.609",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-20",
        "30.283",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-20",
        "29.959",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-20",
        "29.636",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-20",
        "29.317",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-20",
        "29.000",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-20",
        "28.684",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-20",
        "28.370",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-20",
        "28.061",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-20",
        "27.753",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-20",
        "27.447",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-20",
        "27.145",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-20",
        "26.845",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-20",
        "26.549",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-20",
        "26.255",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-20",
        "25.964",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-20",
        "25.677",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-20",
        "25.393",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-20",
        "25.112",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-20",
        "24.835",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-20",
        "24.561",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-20",
        "24.292",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-20",
        "24.025",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-20",
        "21.233",
        "42.540",
        "3.097",
        "3.030",
        "0.249"
    ],
    [
        "168",
        "-20",
        "-13.917",
        "22.772",
        "13.441",
        "7.857",
        "0.371"
    ],
    [
        "169",
        "-20",
        "-58.065",
        "3.433",
        "10.124",
        "7.736",
        "0.255"
    ],
    [
        "170",
        "-20",
        "-77.514",
        "2.760",
        "4.518",
        "4.589",
        "0.055"
    ],
    [
        "171",
        "-20",
        "-23.228",
        "11.671",
        "7.357",
        "5.677",
        "0.281"
    ],
    [
        "172",
        "-20",
        "-4.704",
        "12.327",
        "4.434",
        "4.415",
        "0.240"
    ],
    [
        "173",
        "-20",
        "0.843",
        "15.829",
        "3.119",
        "3.216",
        "0.298"
    ],
    [
        "174",
        "-20",
        "4.262",
        "21.415",
        "2.505",
        "2.545",
        "0.407"
    ],
    [
        "175",
        "-20",
        "7.892",
        "27.897",
        "2.108",
        "2.271",
        "0.469"
    ],
    [
        "176",
        "-20",
        "12.269",
        "33.889",
        "1.806",
        "1.854",
        "0.423"
    ],
    [
        "177",
        "-20",
        "17.708",
        "36.831",
        "1.390",
        "1.292",
        "0.114"
    ],
    [
        "178",
        "-20",
        "21.150",
        "35.706",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-20",
        "20.939",
        "34.916",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-19",
        "18.006",
        "34.324",
        "6.262",
        "4.797",
        "-0.107"
    ],
    [
        "-179",
        "-19",
        "16.738",
        "35.655",
        "9.025",
        "8.459",
        "-0.211"
    ],
    [
        "-178",
        "-19",
        "7.826",
        "42.447",
        "12.796",
        "13.820",
        "-0.378"
    ],
    [
        "-177",
        "-19",
        "7.167",
        "41.665",
        "20.481",
        "17.343",
        "-0.447"
    ],
    [
        "-176",
        "-19",
        "15.820",
        "35.448",
        "25.056",
        "21.674",
        "-0.444"
    ],
    [
        "-175",
        "-19",
        "32.595",
        "25.090",
        "27.930",
        "22.428",
        "-0.410"
    ],
    [
        "-174",
        "-19",
        "61.144",
        "7.287",
        "25.586",
        "18.343",
        "-0.343"
    ],
    [
        "-173",
        "-19",
        "0.078",
        "19.459",
        "30.502",
        "18.332",
        "-0.293"
    ],
    [
        "-172",
        "-19",
        "-60.111",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-19",
        "-60.284",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-19",
        "-60.457",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-19",
        "-60.631",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-19",
        "-60.805",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-19",
        "-60.980",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-19",
        "-61.155",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-19",
        "-61.330",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-19",
        "-61.505",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-19",
        "-61.680",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-19",
        "-61.855",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-19",
        "-62.031",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-19",
        "-62.206",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-19",
        "-62.381",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-19",
        "-62.556",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-19",
        "-62.730",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-19",
        "-62.905",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-19",
        "-63.079",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-19",
        "-63.252",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-19",
        "-63.425",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-19",
        "-63.598",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-19",
        "-63.770",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-19",
        "-63.942",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-19",
        "-64.112",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-19",
        "-64.283",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-19",
        "-64.452",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-19",
        "-64.620",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-19",
        "-64.788",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-19",
        "-64.955",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-19",
        "-65.121",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-19",
        "-65.286",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-19",
        "-65.449",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-19",
        "-65.612",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-19",
        "-65.773",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-19",
        "-65.933",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-19",
        "-66.092",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-19",
        "-66.250",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-19",
        "-66.406",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-19",
        "-66.561",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-19",
        "-66.715",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-19",
        "-66.867",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-19",
        "-67.017",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-19",
        "-67.166",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-19",
        "-67.313",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-19",
        "-67.458",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-19",
        "-67.602",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-19",
        "-67.744",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-19",
        "-67.884",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-19",
        "-68.022",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-19",
        "-68.159",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-19",
        "-68.293",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-19",
        "-68.426",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-19",
        "-68.556",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-19",
        "-68.684",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-19",
        "-68.811",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-19",
        "-68.935",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-19",
        "-69.057",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-19",
        "-69.176",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-19",
        "-69.294",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-19",
        "52.754",
        "-13.250",
        "6.030",
        "9.616",
        "0.013"
    ],
    [
        "-112",
        "-19",
        "64.151",
        "-12.070",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-19",
        "64.217",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-19",
        "64.278",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-19",
        "64.335",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-19",
        "64.386",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-19",
        "64.433",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-19",
        "64.475",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-19",
        "64.512",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-19",
        "64.544",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-19",
        "64.571",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-19",
        "64.593",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-19",
        "64.610",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-19",
        "64.623",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-19",
        "64.630",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-19",
        "64.633",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-19",
        "64.629",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-19",
        "64.622",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-19",
        "64.610",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-19",
        "64.592",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-19",
        "64.570",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-19",
        "64.543",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-19",
        "64.511",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-19",
        "64.473",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-19",
        "64.431",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-19",
        "64.384",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-19",
        "64.332",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-19",
        "64.276",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-19",
        "64.214",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-19",
        "64.147",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-19",
        "64.077",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-19",
        "64.000",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-19",
        "63.919",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-19",
        "63.835",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-19",
        "63.744",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-19",
        "63.649",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-19",
        "63.550",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-19",
        "63.445",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-19",
        "63.337",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-19",
        "63.223",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-19",
        "63.105",
        "21.137",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-19",
        "55.552",
        "20.691",
        "10.168",
        "5.581",
        "0.463"
    ],
    [
        "-71",
        "-19",
        "35.940",
        "18.641",
        "8.343",
        "5.659",
        "0.367"
    ],
    [
        "-70",
        "-19",
        "20.380",
        "16.451",
        "5.244",
        "3.322",
        "0.319"
    ],
    [
        "-69",
        "-19",
        "12.258",
        "15.245",
        "3.228",
        "2.156",
        "0.278"
    ],
    [
        "-68",
        "-19",
        "7.641",
        "14.337",
        "1.999",
        "1.671",
        "0.091"
    ],
    [
        "-67",
        "-19",
        "5.854",
        "14.203",
        "1.798",
        "1.535",
        "0.065"
    ],
    [
        "-66",
        "-19",
        "4.441",
        "12.904",
        "1.779",
        "1.413",
        "0.081"
    ],
    [
        "-65",
        "-19",
        "3.007",
        "11.961",
        "1.552",
        "1.231",
        "0.035"
    ],
    [
        "-64",
        "-19",
        "0.931",
        "10.539",
        "1.456",
        "1.263",
        "0.073"
    ],
    [
        "-63",
        "-19",
        "-1.079",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-19",
        "-1.137",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-19",
        "-1.197",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-19",
        "-1.257",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-19",
        "-1.317",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-19",
        "-1.379",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-19",
        "-1.439",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-19",
        "-1.502",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-19",
        "-1.564",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-19",
        "-1.629",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-19",
        "-1.693",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-19",
        "-1.757",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-19",
        "-1.822",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-19",
        "-1.887",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-19",
        "-1.953",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-19",
        "-2.020",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-19",
        "-2.086",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-19",
        "-2.153",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-19",
        "-2.221",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-19",
        "-2.289",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-19",
        "-2.357",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-19",
        "-2.426",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-19",
        "-2.494",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-19",
        "-2.563",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-19",
        "-2.633",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-19",
        "-2.702",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-19",
        "-2.772",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-19",
        "-2.842",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-19",
        "-2.912",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-19",
        "-2.982",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-19",
        "-3.053",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-19",
        "-3.124",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-19",
        "-3.194",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-19",
        "-3.265",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-19",
        "-3.336",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-19",
        "-3.406",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-19",
        "-3.477",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-19",
        "-3.548",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-19",
        "-3.619",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-19",
        "-3.688",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-19",
        "-3.759",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-19",
        "-3.829",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-19",
        "-3.900",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-19",
        "-3.970",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-19",
        "-4.040",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-19",
        "-4.110",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-19",
        "-4.180",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-19",
        "-4.248",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-19",
        "-4.318",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-19",
        "-4.386",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-19",
        "-4.455",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-19",
        "25.198",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-19",
        "25.094",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-19",
        "24.989",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-19",
        "24.884",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-19",
        "24.779",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-19",
        "24.673",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-19",
        "24.566",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-19",
        "24.459",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-19",
        "24.351",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-19",
        "24.243",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-19",
        "24.134",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-19",
        "24.026",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-19",
        "23.916",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-19",
        "23.806",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-19",
        "23.696",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-19",
        "23.587",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-19",
        "23.476",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-19",
        "23.365",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-19",
        "23.255",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-19",
        "23.144",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-19",
        "23.032",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-19",
        "22.921",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-19",
        "22.810",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-19",
        "22.699",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-19",
        "22.588",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-19",
        "22.476",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-19",
        "22.366",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-19",
        "22.255",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-19",
        "22.144",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-19",
        "22.035",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-19",
        "21.924",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-19",
        "21.814",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-19",
        "21.705",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-19",
        "21.595",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-19",
        "21.487",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-19",
        "21.379",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-19",
        "21.271",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-19",
        "21.163",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-19",
        "21.057",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-19",
        "20.950",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-19",
        "20.930",
        "18.558",
        "0.504",
        "0.549",
        "0.006"
    ],
    [
        "29",
        "-19",
        "21.042",
        "18.378",
        "0.772",
        "0.888",
        "-0.014"
    ],
    [
        "30",
        "-19",
        "21.137",
        "18.204",
        "0.901",
        "0.943",
        "-0.003"
    ],
    [
        "31",
        "-19",
        "21.233",
        "18.021",
        "0.899",
        "1.018",
        "0.001"
    ],
    [
        "32",
        "-19",
        "21.331",
        "17.828",
        "0.929",
        "1.040",
        "0.000"
    ],
    [
        "33",
        "-19",
        "21.429",
        "17.630",
        "0.990",
        "1.025",
        "-0.004"
    ],
    [
        "34",
        "-19",
        "21.527",
        "17.432",
        "0.962",
        "1.055",
        "-0.007"
    ],
    [
        "35",
        "-19",
        "21.622",
        "17.230",
        "0.975",
        "1.068",
        "-0.008"
    ],
    [
        "36",
        "-19",
        "21.715",
        "17.023",
        "1.027",
        "1.062",
        "-0.008"
    ],
    [
        "37",
        "-19",
        "21.806",
        "16.810",
        "0.993",
        "1.098",
        "-0.009"
    ],
    [
        "38",
        "-19",
        "21.896",
        "16.587",
        "0.994",
        "1.100",
        "-0.011"
    ],
    [
        "39",
        "-19",
        "21.987",
        "16.359",
        "1.029",
        "1.065",
        "-0.014"
    ],
    [
        "40",
        "-19",
        "22.075",
        "16.128",
        "0.976",
        "1.072",
        "-0.016"
    ],
    [
        "41",
        "-19",
        "22.164",
        "15.894",
        "0.958",
        "1.048",
        "-0.016"
    ],
    [
        "42",
        "-19",
        "22.248",
        "15.661",
        "0.977",
        "0.988",
        "-0.014"
    ],
    [
        "43",
        "-19",
        "22.327",
        "15.425",
        "0.902",
        "0.970",
        "-0.014"
    ],
    [
        "44",
        "-19",
        "22.396",
        "15.184",
        "0.867",
        "0.915",
        "-0.008"
    ],
    [
        "45",
        "-19",
        "22.455",
        "14.938",
        "0.874",
        "0.835",
        "0.012"
    ],
    [
        "46",
        "-19",
        "22.499",
        "14.692",
        "0.778",
        "0.816",
        "0.035"
    ],
    [
        "47",
        "-19",
        "22.520",
        "14.459",
        "0.716",
        "0.747",
        "0.041"
    ],
    [
        "48",
        "-19",
        "22.506",
        "14.234",
        "0.706",
        "0.655",
        "0.015"
    ],
    [
        "49",
        "-19",
        "22.461",
        "13.993",
        "0.161",
        "0.220",
        "0.006"
    ],
    [
        "50",
        "-19",
        "22.385",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-19",
        "22.308",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-19",
        "22.232",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-19",
        "22.157",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-19",
        "22.084",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-19",
        "22.013",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-19",
        "21.943",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-19",
        "21.875",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-19",
        "21.808",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-19",
        "21.743",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-19",
        "21.678",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-19",
        "21.617",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-19",
        "21.556",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-19",
        "21.498",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-19",
        "21.441",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-19",
        "21.386",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-19",
        "53.283",
        "28.217",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-19",
        "53.121",
        "28.884",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-19",
        "52.955",
        "29.542",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-19",
        "52.785",
        "30.190",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-19",
        "52.612",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-19",
        "52.435",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-19",
        "52.254",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-19",
        "52.071",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-19",
        "51.883",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-19",
        "51.692",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-19",
        "51.498",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-19",
        "51.300",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-19",
        "51.100",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-19",
        "50.895",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-19",
        "50.689",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-19",
        "50.479",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-19",
        "50.266",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-19",
        "50.050",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-19",
        "49.832",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-19",
        "49.610",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-19",
        "49.386",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-19",
        "49.159",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-19",
        "48.812",
        "41.137",
        "1.353",
        "1.549",
        "-0.010"
    ],
    [
        "89",
        "-19",
        "48.496",
        "42.298",
        "1.541",
        "1.725",
        "0.000"
    ],
    [
        "90",
        "-19",
        "48.190",
        "43.468",
        "1.709",
        "1.733",
        "0.005"
    ],
    [
        "91",
        "-19",
        "47.883",
        "44.614",
        "1.624",
        "1.796",
        "0.012"
    ],
    [
        "92",
        "-19",
        "47.573",
        "45.726",
        "1.590",
        "1.754",
        "0.022"
    ],
    [
        "93",
        "-19",
        "47.246",
        "46.841",
        "1.595",
        "1.604",
        "0.030"
    ],
    [
        "94",
        "-19",
        "46.915",
        "48.010",
        "1.238",
        "1.426",
        "-0.004"
    ],
    [
        "95",
        "-19",
        "46.555",
        "48.914",
        "0.893",
        "1.026",
        "-0.001"
    ],
    [
        "96",
        "-19",
        "46.231",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-19",
        "45.949",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-19",
        "45.663",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-19",
        "45.375",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-19",
        "45.084",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-19",
        "44.790",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-19",
        "44.495",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-19",
        "44.197",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-19",
        "43.896",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-19",
        "43.594",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-19",
        "43.289",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-19",
        "42.982",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-19",
        "42.674",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-19",
        "42.363",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-19",
        "42.051",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-19",
        "41.737",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-19",
        "41.422",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-19",
        "41.105",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-19",
        "40.787",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-19",
        "40.468",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-19",
        "40.148",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-19",
        "39.827",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-19",
        "39.505",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-19",
        "39.181",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-19",
        "38.857",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-19",
        "38.533",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-19",
        "38.208",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-19",
        "37.882",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-19",
        "37.557",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-19",
        "37.230",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-19",
        "36.904",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-19",
        "36.578",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-19",
        "36.251",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-19",
        "35.926",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-19",
        "35.599",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-19",
        "35.274",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-19",
        "34.949",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-19",
        "34.624",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-19",
        "34.301",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-19",
        "33.978",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-19",
        "33.656",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-19",
        "33.335",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-19",
        "33.014",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-19",
        "32.695",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-19",
        "32.377",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-19",
        "32.061",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-19",
        "31.746",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-19",
        "31.432",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-19",
        "31.120",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-19",
        "30.809",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-19",
        "30.502",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-19",
        "30.195",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-19",
        "29.890",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-19",
        "29.588",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-19",
        "29.287",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-19",
        "28.990",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-19",
        "28.695",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-19",
        "28.401",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-19",
        "28.111",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-19",
        "27.823",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-19",
        "27.537",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-19",
        "27.255",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-19",
        "26.976",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-19",
        "26.699",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-19",
        "26.426",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-19",
        "26.155",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-19",
        "25.888",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-19",
        "25.624",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-19",
        "25.364",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-19",
        "25.107",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-19",
        "24.853",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-19",
        "19.680",
        "41.905",
        "3.128",
        "2.944",
        "0.216"
    ],
    [
        "168",
        "-19",
        "-31.498",
        "15.055",
        "12.477",
        "6.804",
        "0.310"
    ],
    [
        "169",
        "-19",
        "-83.918",
        "-4.446",
        "4.226",
        "2.299",
        "0.136"
    ],
    [
        "170",
        "-19",
        "-57.445",
        "14.518",
        "6.002",
        "4.332",
        "0.236"
    ],
    [
        "171",
        "-19",
        "-24.996",
        "10.547",
        "5.804",
        "4.450",
        "0.227"
    ],
    [
        "172",
        "-19",
        "-11.283",
        "10.954",
        "3.513",
        "3.412",
        "0.253"
    ],
    [
        "173",
        "-19",
        "-7.009",
        "13.954",
        "2.693",
        "2.646",
        "0.369"
    ],
    [
        "174",
        "-19",
        "-4.766",
        "19.589",
        "2.436",
        "2.334",
        "0.444"
    ],
    [
        "175",
        "-19",
        "-2.242",
        "25.990",
        "2.344",
        "2.226",
        "0.466"
    ],
    [
        "176",
        "-19",
        "1.342",
        "31.596",
        "2.372",
        "2.103",
        "0.425"
    ],
    [
        "177",
        "-19",
        "6.291",
        "33.632",
        "2.579",
        "2.015",
        "0.238"
    ],
    [
        "178",
        "-19",
        "11.257",
        "33.453",
        "3.016",
        "2.384",
        "0.074"
    ],
    [
        "179",
        "-19",
        "16.303",
        "34.679",
        "3.915",
        "3.716",
        "-0.038"
    ],
    [
        "-180",
        "-18",
        "-3.597",
        "34.223",
        "12.042",
        "11.327",
        "-0.068"
    ],
    [
        "-179",
        "-18",
        "-17.548",
        "42.965",
        "11.360",
        "15.030",
        "-0.111"
    ],
    [
        "-178",
        "-18",
        "-9.409",
        "42.768",
        "17.075",
        "21.806",
        "-0.228"
    ],
    [
        "-177",
        "-18",
        "4.519",
        "39.627",
        "23.656",
        "22.653",
        "-0.349"
    ],
    [
        "-176",
        "-18",
        "15.465",
        "34.284",
        "26.554",
        "25.499",
        "-0.344"
    ],
    [
        "-175",
        "-18",
        "32.582",
        "24.617",
        "29.090",
        "24.604",
        "-0.310"
    ],
    [
        "-174",
        "-18",
        "59.922",
        "10.154",
        "30.263",
        "20.668",
        "-0.260"
    ],
    [
        "-173",
        "-18",
        "18.709",
        "16.229",
        "31.922",
        "20.089",
        "-0.232"
    ],
    [
        "-172",
        "-18",
        "-42.986",
        "26.456",
        "23.845",
        "15.192",
        "-0.209"
    ],
    [
        "-171",
        "-18",
        "-60.722",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-18",
        "-60.887",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-18",
        "-61.052",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-18",
        "-61.217",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-18",
        "-61.383",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-18",
        "-61.549",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-18",
        "-61.715",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-18",
        "-61.881",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-18",
        "-62.047",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-18",
        "-62.214",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-18",
        "-62.380",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-18",
        "-62.546",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-18",
        "-62.712",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-18",
        "-62.878",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-18",
        "-63.044",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-18",
        "-63.210",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-18",
        "-63.375",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-18",
        "-63.539",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-18",
        "-63.704",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-18",
        "-63.868",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-18",
        "-64.031",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-18",
        "-64.194",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-18",
        "-64.356",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-18",
        "-64.517",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-18",
        "-64.678",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-18",
        "-64.838",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-18",
        "-64.997",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-18",
        "-65.156",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-18",
        "-65.313",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-18",
        "-65.469",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-18",
        "-65.625",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-18",
        "-65.779",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-18",
        "-65.932",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-18",
        "-66.084",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-18",
        "-66.235",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-18",
        "-66.385",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-18",
        "-66.533",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-18",
        "-66.680",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-18",
        "-66.826",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-18",
        "-66.970",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-18",
        "-67.113",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-18",
        "-67.254",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-18",
        "-67.394",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-18",
        "-67.532",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-18",
        "-67.668",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-18",
        "-67.803",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-18",
        "-67.936",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-18",
        "-68.067",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-18",
        "-68.197",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-18",
        "-68.324",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-18",
        "-68.450",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-18",
        "-68.574",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-18",
        "-68.695",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-18",
        "-68.815",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-18",
        "-68.933",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-18",
        "-69.049",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-18",
        "-69.162",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-18",
        "-69.274",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-18",
        "48.236",
        "-9.351",
        "6.706",
        "10.286",
        "-0.033"
    ],
    [
        "-112",
        "-18",
        "63.632",
        "-12.070",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-18",
        "63.695",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-18",
        "63.752",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-18",
        "63.806",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-18",
        "63.855",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-18",
        "63.899",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-18",
        "63.939",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-18",
        "63.974",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-18",
        "64.005",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-18",
        "64.031",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-18",
        "64.051",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-18",
        "64.067",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-18",
        "64.079",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-18",
        "64.086",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-18",
        "64.088",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-18",
        "64.086",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-18",
        "64.079",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-18",
        "64.068",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-18",
        "64.051",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-18",
        "64.029",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-18",
        "64.003",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-18",
        "63.973",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-18",
        "63.938",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-18",
        "63.897",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-18",
        "63.853",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-18",
        "63.804",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-18",
        "63.750",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-18",
        "63.691",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-18",
        "63.629",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-18",
        "63.561",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-18",
        "63.488",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-18",
        "63.412",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-18",
        "63.331",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-18",
        "63.245",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-18",
        "63.156",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-18",
        "63.061",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-18",
        "62.962",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-18",
        "62.859",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-18",
        "62.751",
        "20.343",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-18",
        "60.800",
        "20.564",
        "2.005",
        "2.424",
        "0.192"
    ],
    [
        "-72",
        "-18",
        "40.440",
        "18.272",
        "8.545",
        "6.212",
        "0.453"
    ],
    [
        "-71",
        "-18",
        "21.773",
        "15.726",
        "3.968",
        "3.858",
        "0.221"
    ],
    [
        "-70",
        "-18",
        "9.420",
        "14.271",
        "3.457",
        "2.376",
        "0.305"
    ],
    [
        "-69",
        "-18",
        "6.639",
        "13.623",
        "2.453",
        "1.923",
        "0.274"
    ],
    [
        "-68",
        "-18",
        "4.876",
        "13.731",
        "1.741",
        "1.547",
        "0.133"
    ],
    [
        "-67",
        "-18",
        "3.908",
        "13.951",
        "1.549",
        "1.384",
        "0.098"
    ],
    [
        "-66",
        "-18",
        "2.574",
        "12.230",
        "1.505",
        "1.175",
        "0.134"
    ],
    [
        "-65",
        "-18",
        "1.183",
        "11.056",
        "1.375",
        "1.142",
        "0.103"
    ],
    [
        "-64",
        "-18",
        "-0.179",
        "10.434",
        "1.217",
        "1.053",
        "0.122"
    ],
    [
        "-63",
        "-18",
        "-1.217",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-18",
        "-1.272",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-18",
        "-1.328",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-18",
        "-1.385",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-18",
        "-1.442",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-18",
        "-1.500",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-18",
        "-1.559",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-18",
        "-1.618",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-18",
        "-1.677",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-18",
        "-1.738",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-18",
        "-1.798",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-18",
        "-1.859",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-18",
        "-1.921",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-18",
        "-1.983",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-18",
        "-2.046",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-18",
        "-2.109",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-18",
        "-2.173",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-18",
        "-2.236",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-18",
        "-2.300",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-18",
        "-2.365",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-18",
        "-2.430",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-18",
        "-2.495",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-18",
        "-2.560",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-18",
        "-2.625",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-18",
        "-2.691",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-18",
        "-2.757",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-18",
        "-2.824",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-18",
        "-2.890",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-18",
        "-2.956",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-18",
        "-3.023",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-18",
        "-3.090",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-18",
        "-3.157",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-18",
        "-3.224",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-18",
        "-3.291",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-18",
        "-3.359",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-18",
        "-3.425",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-18",
        "-3.493",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-18",
        "-3.559",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-18",
        "-3.627",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-18",
        "-3.693",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-18",
        "-3.760",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-18",
        "-3.827",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-18",
        "-3.894",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-18",
        "-3.961",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-18",
        "-4.027",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-18",
        "-4.093",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-18",
        "-4.159",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-18",
        "-4.225",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-18",
        "-4.290",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-18",
        "2.229",
        "13.761",
        "3.652",
        "2.485",
        "0.249"
    ],
    [
        "-13",
        "-18",
        "19.456",
        "16.206",
        "3.619",
        "2.510",
        "0.260"
    ],
    [
        "-12",
        "-18",
        "25.211",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-18",
        "25.113",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-18",
        "25.014",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-18",
        "24.914",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-18",
        "24.814",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-18",
        "24.713",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-18",
        "24.612",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-18",
        "24.510",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-18",
        "24.408",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-18",
        "24.305",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-18",
        "24.203",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-18",
        "24.099",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-18",
        "23.995",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-18",
        "23.891",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-18",
        "23.787",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-18",
        "23.682",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-18",
        "23.578",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-18",
        "23.472",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-18",
        "23.367",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-18",
        "23.262",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-18",
        "23.156",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-18",
        "23.051",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-18",
        "22.945",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-18",
        "22.840",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-18",
        "22.735",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-18",
        "22.629",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-18",
        "22.523",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-18",
        "22.418",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-18",
        "22.314",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-18",
        "22.209",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-18",
        "22.104",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-18",
        "22.001",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-18",
        "21.896",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-18",
        "21.793",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-18",
        "21.690",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-18",
        "21.587",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-18",
        "21.484",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-18",
        "21.382",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-18",
        "21.281",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-18",
        "21.215",
        "18.673",
        "0.626",
        "0.613",
        "-0.023"
    ],
    [
        "28",
        "-18",
        "21.239",
        "18.540",
        "0.701",
        "0.775",
        "-0.015"
    ],
    [
        "29",
        "-18",
        "21.312",
        "18.382",
        "0.796",
        "0.896",
        "-0.012"
    ],
    [
        "30",
        "-18",
        "21.405",
        "18.206",
        "0.910",
        "0.959",
        "-0.004"
    ],
    [
        "31",
        "-18",
        "21.511",
        "18.018",
        "0.915",
        "1.044",
        "-0.001"
    ],
    [
        "32",
        "-18",
        "21.620",
        "17.820",
        "0.949",
        "1.072",
        "-0.003"
    ],
    [
        "33",
        "-18",
        "21.729",
        "17.620",
        "1.014",
        "1.056",
        "-0.005"
    ],
    [
        "34",
        "-18",
        "21.835",
        "17.421",
        "0.987",
        "1.082",
        "-0.007"
    ],
    [
        "35",
        "-18",
        "21.938",
        "17.225",
        "0.999",
        "1.091",
        "-0.008"
    ],
    [
        "36",
        "-18",
        "22.039",
        "17.025",
        "1.049",
        "1.084",
        "-0.007"
    ],
    [
        "37",
        "-18",
        "22.140",
        "16.816",
        "1.015",
        "1.121",
        "-0.008"
    ],
    [
        "38",
        "-18",
        "22.240",
        "16.594",
        "1.015",
        "1.122",
        "-0.011"
    ],
    [
        "39",
        "-18",
        "22.340",
        "16.365",
        "1.048",
        "1.082",
        "-0.014"
    ],
    [
        "40",
        "-18",
        "22.441",
        "16.134",
        "0.991",
        "1.081",
        "-0.015"
    ],
    [
        "41",
        "-18",
        "22.540",
        "15.902",
        "0.968",
        "1.050",
        "-0.014"
    ],
    [
        "42",
        "-18",
        "22.638",
        "15.672",
        "0.978",
        "0.988",
        "-0.011"
    ],
    [
        "43",
        "-18",
        "22.734",
        "15.438",
        "0.893",
        "0.963",
        "-0.017"
    ],
    [
        "44",
        "-18",
        "22.820",
        "15.198",
        "0.845",
        "0.891",
        "-0.027"
    ],
    [
        "45",
        "-18",
        "22.887",
        "14.946",
        "0.845",
        "0.787",
        "-0.006"
    ],
    [
        "46",
        "-18",
        "22.923",
        "14.693",
        "0.754",
        "0.774",
        "0.018"
    ],
    [
        "47",
        "-18",
        "22.926",
        "14.458",
        "0.701",
        "0.737",
        "0.011"
    ],
    [
        "48",
        "-18",
        "22.905",
        "14.231",
        "0.636",
        "0.608",
        "0.027"
    ],
    [
        "49",
        "-18",
        "22.853",
        "13.992",
        "0.152",
        "0.225",
        "0.013"
    ],
    [
        "50",
        "-18",
        "22.780",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-18",
        "22.706",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-18",
        "22.634",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-18",
        "22.564",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-18",
        "22.494",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-18",
        "22.426",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-18",
        "22.361",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-18",
        "22.295",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-18",
        "22.232",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-18",
        "22.169",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-18",
        "22.109",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-18",
        "22.051",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-18",
        "21.993",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-18",
        "21.938",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-18",
        "21.883",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-18",
        "29.921",
        "14.238",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-18",
        "52.886",
        "28.217",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-18",
        "52.731",
        "28.884",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-18",
        "52.574",
        "29.542",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-18",
        "52.413",
        "30.190",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-18",
        "52.249",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-18",
        "52.080",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-18",
        "51.909",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-18",
        "51.735",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-18",
        "51.556",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-18",
        "51.375",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-18",
        "51.191",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-18",
        "51.003",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-18",
        "50.813",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-18",
        "50.619",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-18",
        "50.422",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-18",
        "50.223",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-18",
        "50.021",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-18",
        "49.817",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-18",
        "49.609",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-18",
        "49.399",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-18",
        "49.187",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-18",
        "48.972",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-18",
        "48.644",
        "41.018",
        "1.242",
        "1.427",
        "-0.032"
    ],
    [
        "89",
        "-18",
        "48.329",
        "42.094",
        "1.436",
        "1.617",
        "-0.019"
    ],
    [
        "90",
        "-18",
        "48.016",
        "43.225",
        "1.612",
        "1.660",
        "-0.019"
    ],
    [
        "91",
        "-18",
        "47.697",
        "44.332",
        "1.543",
        "1.731",
        "-0.006"
    ],
    [
        "92",
        "-18",
        "47.368",
        "45.402",
        "1.530",
        "1.692",
        "0.012"
    ],
    [
        "93",
        "-18",
        "47.027",
        "46.474",
        "1.572",
        "1.582",
        "0.015"
    ],
    [
        "94",
        "-18",
        "46.683",
        "47.571",
        "1.402",
        "1.559",
        "0.019"
    ],
    [
        "95",
        "-18",
        "46.308",
        "48.765",
        "0.948",
        "1.048",
        "-0.025"
    ],
    [
        "96",
        "-18",
        "45.965",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-18",
        "45.697",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-18",
        "45.426",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-18",
        "45.152",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-18",
        "44.876",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-18",
        "44.597",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-18",
        "44.317",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-18",
        "44.034",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-18",
        "43.749",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-18",
        "43.462",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-18",
        "43.172",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-18",
        "42.881",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-18",
        "42.588",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-18",
        "42.294",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-18",
        "41.997",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-18",
        "41.700",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-18",
        "41.400",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-18",
        "41.100",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-18",
        "40.798",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-18",
        "40.495",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-18",
        "40.191",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-18",
        "39.886",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-18",
        "39.580",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-18",
        "39.273",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-18",
        "38.966",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-18",
        "38.658",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-18",
        "38.350",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-18",
        "38.040",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-18",
        "37.731",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-18",
        "37.422",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-18",
        "37.112",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-18",
        "36.802",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-18",
        "36.492",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-18",
        "36.183",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-18",
        "35.874",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-18",
        "35.565",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-18",
        "35.257",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-18",
        "34.948",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-18",
        "34.641",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-18",
        "34.334",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-18",
        "34.029",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-18",
        "33.723",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-18",
        "33.419",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-18",
        "33.117",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-18",
        "32.815",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-18",
        "32.515",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-18",
        "32.216",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-18",
        "31.919",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-18",
        "31.622",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-18",
        "31.327",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-18",
        "31.035",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-18",
        "30.743",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-18",
        "30.455",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-18",
        "30.168",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-18",
        "29.883",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-18",
        "29.600",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-18",
        "29.319",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-18",
        "29.042",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-18",
        "28.765",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-18",
        "28.493",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-18",
        "28.221",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-18",
        "27.954",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-18",
        "27.688",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-18",
        "27.425",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-18",
        "27.165",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-18",
        "26.910",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-18",
        "26.655",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-18",
        "26.405",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-18",
        "26.158",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-18",
        "25.914",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-18",
        "25.674",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-18",
        "21.593",
        "42.743",
        "2.584",
        "2.450",
        "0.171"
    ],
    [
        "168",
        "-18",
        "-42.320",
        "14.133",
        "6.533",
        "4.090",
        "0.100"
    ],
    [
        "169",
        "-18",
        "-55.162",
        "6.172",
        "6.265",
        "4.441",
        "0.306"
    ],
    [
        "170",
        "-18",
        "-41.189",
        "10.686",
        "6.256",
        "4.241",
        "0.249"
    ],
    [
        "171",
        "-18",
        "-26.168",
        "8.972",
        "4.356",
        "3.468",
        "0.205"
    ],
    [
        "172",
        "-18",
        "-19.609",
        "9.706",
        "2.912",
        "2.929",
        "0.282"
    ],
    [
        "173",
        "-18",
        "-16.979",
        "13.778",
        "2.568",
        "2.550",
        "0.371"
    ],
    [
        "174",
        "-18",
        "-15.504",
        "19.627",
        "2.523",
        "2.352",
        "0.381"
    ],
    [
        "175",
        "-18",
        "-13.980",
        "25.528",
        "2.554",
        "2.292",
        "0.369"
    ],
    [
        "176",
        "-18",
        "-12.086",
        "30.854",
        "2.767",
        "2.371",
        "0.293"
    ],
    [
        "177",
        "-18",
        "-9.693",
        "32.415",
        "3.547",
        "2.928",
        "0.070"
    ],
    [
        "178",
        "-18",
        "-6.821",
        "31.778",
        "5.214",
        "4.850",
        "0.005"
    ],
    [
        "179",
        "-18",
        "-3.971",
        "31.998",
        "7.906",
        "8.352",
        "-0.022"
    ],
    [
        "-180",
        "-17",
        "-17.793",
        "37.736",
        "19.624",
        "18.088",
        "0.019"
    ],
    [
        "-179",
        "-17",
        "-14.050",
        "47.769",
        "23.466",
        "23.031",
        "0.044"
    ],
    [
        "-178",
        "-17",
        "-5.986",
        "35.904",
        "26.059",
        "29.172",
        "-0.061"
    ],
    [
        "-177",
        "-17",
        "0.470",
        "31.798",
        "29.039",
        "28.186",
        "-0.114"
    ],
    [
        "-176",
        "-17",
        "12.780",
        "28.707",
        "30.476",
        "29.996",
        "-0.126"
    ],
    [
        "-175",
        "-17",
        "30.400",
        "23.225",
        "32.959",
        "27.382",
        "-0.157"
    ],
    [
        "-174",
        "-17",
        "52.105",
        "15.774",
        "38.035",
        "22.663",
        "-0.194"
    ],
    [
        "-173",
        "-17",
        "28.656",
        "11.724",
        "35.024",
        "21.872",
        "-0.137"
    ],
    [
        "-172",
        "-17",
        "-31.827",
        "24.432",
        "33.031",
        "18.924",
        "-0.108"
    ],
    [
        "-171",
        "-17",
        "-61.142",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-17",
        "-61.297",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-17",
        "-61.454",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-17",
        "-61.610",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-17",
        "-61.767",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-17",
        "-61.924",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-17",
        "-62.081",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-17",
        "-62.238",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-17",
        "-62.396",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-17",
        "-62.553",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-17",
        "-62.710",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-17",
        "-62.868",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-17",
        "-63.025",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-17",
        "-63.182",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-17",
        "-63.339",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-17",
        "-63.495",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-17",
        "-63.651",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-17",
        "-63.807",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-17",
        "-63.963",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-17",
        "-64.118",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-17",
        "-64.272",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-17",
        "-64.426",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-17",
        "-64.580",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-17",
        "-64.733",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-17",
        "-64.885",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-17",
        "-65.036",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-17",
        "-65.187",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-17",
        "-65.336",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-17",
        "-65.485",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-17",
        "-65.633",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-17",
        "-65.780",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-17",
        "-65.926",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-17",
        "-66.071",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-17",
        "-66.215",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-17",
        "-66.358",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-17",
        "-66.499",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-17",
        "-66.640",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-17",
        "-66.779",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-17",
        "-66.917",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-17",
        "-67.053",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-17",
        "-67.188",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-17",
        "-67.322",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-17",
        "-67.454",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-17",
        "-67.584",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-17",
        "-67.714",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-17",
        "-67.841",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-17",
        "-67.967",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-17",
        "-68.091",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-17",
        "-68.214",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-17",
        "-68.334",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-17",
        "-68.453",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-17",
        "-68.570",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-17",
        "-68.686",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-17",
        "-68.799",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-17",
        "-68.910",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-17",
        "-69.020",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-17",
        "-69.127",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-17",
        "-69.233",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-17",
        "28.712",
        "-4.363",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-17",
        "63.092",
        "-12.070",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-17",
        "63.152",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-17",
        "63.207",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-17",
        "63.258",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-17",
        "63.304",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-17",
        "63.347",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-17",
        "63.384",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-17",
        "63.417",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-17",
        "63.446",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-17",
        "63.470",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-17",
        "63.490",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-17",
        "63.505",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-17",
        "63.516",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-17",
        "63.524",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-17",
        "63.525",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-17",
        "63.523",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-17",
        "63.516",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-17",
        "63.505",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-17",
        "63.489",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-17",
        "63.469",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-17",
        "63.445",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-17",
        "63.415",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-17",
        "63.382",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-17",
        "63.344",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-17",
        "63.302",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-17",
        "63.256",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-17",
        "63.205",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-17",
        "63.150",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-17",
        "63.090",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-17",
        "63.026",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-17",
        "62.958",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-17",
        "62.885",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-17",
        "62.809",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-17",
        "62.727",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-17",
        "62.643",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-17",
        "62.553",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-17",
        "62.459",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-17",
        "62.362",
        "19.543",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-17",
        "55.132",
        "17.136",
        "4.460",
        "4.710",
        "0.400"
    ],
    [
        "-73",
        "-17",
        "34.168",
        "15.942",
        "6.263",
        "4.681",
        "0.399"
    ],
    [
        "-72",
        "-17",
        "16.714",
        "12.479",
        "5.120",
        "4.745",
        "0.427"
    ],
    [
        "-71",
        "-17",
        "8.612",
        "12.478",
        "3.519",
        "3.183",
        "0.297"
    ],
    [
        "-70",
        "-17",
        "4.072",
        "12.166",
        "2.213",
        "2.140",
        "0.240"
    ],
    [
        "-69",
        "-17",
        "3.330",
        "12.405",
        "2.063",
        "1.817",
        "0.259"
    ],
    [
        "-68",
        "-17",
        "2.337",
        "12.898",
        "1.555",
        "1.497",
        "0.145"
    ],
    [
        "-67",
        "-17",
        "1.331",
        "12.784",
        "1.384",
        "1.355",
        "0.165"
    ],
    [
        "-66",
        "-17",
        "0.016",
        "11.261",
        "1.223",
        "0.961",
        "0.221"
    ],
    [
        "-65",
        "-17",
        "-1.236",
        "10.189",
        "0.251",
        "0.387",
        "0.127"
    ],
    [
        "-64",
        "-17",
        "-1.301",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-17",
        "-1.353",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-17",
        "-1.406",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-17",
        "-1.459",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-17",
        "-1.513",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-17",
        "-1.567",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-17",
        "-1.622",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-17",
        "-1.677",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-17",
        "-1.733",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-17",
        "-1.789",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-17",
        "-1.847",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-17",
        "-1.904",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-17",
        "-1.962",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-17",
        "-2.020",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-17",
        "-2.079",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-17",
        "-2.138",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-17",
        "-2.198",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-17",
        "-2.258",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-17",
        "-2.318",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-17",
        "-2.379",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-17",
        "-2.439",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-17",
        "-2.501",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-17",
        "-2.563",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-17",
        "-2.624",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-17",
        "-2.687",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-17",
        "-2.748",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-17",
        "-2.811",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-17",
        "-2.874",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-17",
        "-2.937",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-17",
        "-3.000",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-17",
        "-3.063",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-17",
        "-3.126",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-17",
        "-3.189",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-17",
        "-3.253",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-17",
        "-3.317",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-17",
        "-3.380",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-17",
        "-3.444",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-17",
        "-3.506",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-17",
        "-3.570",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-17",
        "-3.633",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-17",
        "-3.698",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-17",
        "-3.760",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-17",
        "-3.824",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-17",
        "-3.886",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-17",
        "-3.950",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-17",
        "-4.012",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-17",
        "-4.075",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-17",
        "-4.137",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-17",
        "-4.200",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-17",
        "-4.262",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-17",
        "21.931",
        "17.511",
        "1.476",
        "1.419",
        "0.215"
    ],
    [
        "-13",
        "-17",
        "25.309",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-17",
        "25.217",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-17",
        "25.124",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-17",
        "25.031",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-17",
        "24.937",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-17",
        "24.841",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-17",
        "24.747",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-17",
        "24.650",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-17",
        "24.554",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-17",
        "24.457",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-17",
        "24.361",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-17",
        "24.263",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-17",
        "24.165",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-17",
        "24.067",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-17",
        "23.968",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-17",
        "23.870",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-17",
        "23.771",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-17",
        "23.671",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-17",
        "23.572",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-17",
        "23.473",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-17",
        "23.373",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-17",
        "23.273",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-17",
        "23.173",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-17",
        "23.073",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-17",
        "22.973",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-17",
        "22.874",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-17",
        "22.775",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-17",
        "22.675",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-17",
        "22.576",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-17",
        "22.476",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-17",
        "22.377",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-17",
        "22.278",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-17",
        "22.179",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-17",
        "22.081",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-17",
        "21.984",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-17",
        "21.886",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-17",
        "21.788",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-17",
        "21.691",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-17",
        "21.594",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-17",
        "21.499",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-17",
        "21.404",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-17",
        "21.433",
        "18.543",
        "0.641",
        "0.726",
        "-0.056"
    ],
    [
        "29",
        "-17",
        "21.534",
        "18.399",
        "0.803",
        "0.918",
        "-0.005"
    ],
    [
        "30",
        "-17",
        "21.657",
        "18.214",
        "0.948",
        "1.010",
        "-0.012"
    ],
    [
        "31",
        "-17",
        "21.788",
        "18.017",
        "0.967",
        "1.108",
        "-0.006"
    ],
    [
        "32",
        "-17",
        "21.915",
        "17.812",
        "1.009",
        "1.136",
        "-0.008"
    ],
    [
        "33",
        "-17",
        "22.034",
        "17.606",
        "1.076",
        "1.112",
        "-0.001"
    ],
    [
        "34",
        "-17",
        "22.144",
        "17.410",
        "1.046",
        "1.131",
        "0.002"
    ],
    [
        "35",
        "-17",
        "22.248",
        "17.220",
        "1.056",
        "1.137",
        "0.002"
    ],
    [
        "36",
        "-17",
        "22.355",
        "17.028",
        "1.108",
        "1.133",
        "0.004"
    ],
    [
        "37",
        "-17",
        "22.468",
        "16.823",
        "1.074",
        "1.177",
        "0.003"
    ],
    [
        "38",
        "-17",
        "22.583",
        "16.601",
        "1.074",
        "1.175",
        "-0.006"
    ],
    [
        "39",
        "-17",
        "22.697",
        "16.370",
        "1.104",
        "1.121",
        "-0.004"
    ],
    [
        "40",
        "-17",
        "22.809",
        "16.137",
        "1.038",
        "1.105",
        "-0.004"
    ],
    [
        "41",
        "-17",
        "22.920",
        "15.906",
        "1.006",
        "1.062",
        "0.000"
    ],
    [
        "42",
        "-17",
        "23.033",
        "15.677",
        "1.010",
        "1.003",
        "0.010"
    ],
    [
        "43",
        "-17",
        "23.152",
        "15.444",
        "0.910",
        "0.987",
        "0.003"
    ],
    [
        "44",
        "-17",
        "23.278",
        "15.206",
        "0.815",
        "0.894",
        "-0.034"
    ],
    [
        "45",
        "-17",
        "23.404",
        "14.946",
        "0.691",
        "0.669",
        "0.003"
    ],
    [
        "46",
        "-17",
        "23.408",
        "14.696",
        "0.564",
        "0.646",
        "0.016"
    ],
    [
        "47",
        "-17",
        "23.361",
        "14.463",
        "0.543",
        "0.629",
        "0.022"
    ],
    [
        "48",
        "-17",
        "23.311",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-17",
        "23.239",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-17",
        "23.167",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-17",
        "23.099",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-17",
        "23.030",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-17",
        "22.963",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-17",
        "22.898",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-17",
        "22.834",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-17",
        "22.771",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-17",
        "22.709",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-17",
        "22.649",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-17",
        "22.591",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-17",
        "22.533",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-17",
        "22.477",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-17",
        "22.424",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-17",
        "22.371",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-17",
        "22.320",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-17",
        "22.270",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-17",
        "22.222",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-17",
        "50.446",
        "28.907",
        "2.820",
        "4.450",
        "0.204"
    ],
    [
        "68",
        "-17",
        "52.177",
        "29.542",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-17",
        "52.025",
        "30.190",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-17",
        "51.869",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-17",
        "51.710",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-17",
        "51.547",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-17",
        "51.382",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-17",
        "51.214",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-17",
        "51.042",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-17",
        "50.868",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-17",
        "50.691",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-17",
        "50.511",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-17",
        "50.327",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-17",
        "50.142",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-17",
        "49.953",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-17",
        "49.761",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-17",
        "49.568",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-17",
        "49.371",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-17",
        "49.173",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-17",
        "48.971",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-17",
        "48.768",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-17",
        "48.464",
        "40.904",
        "1.075",
        "1.261",
        "-0.070"
    ],
    [
        "89",
        "-17",
        "48.150",
        "41.931",
        "1.274",
        "1.477",
        "-0.053"
    ],
    [
        "90",
        "-17",
        "47.819",
        "43.054",
        "1.456",
        "1.566",
        "-0.056"
    ],
    [
        "91",
        "-17",
        "47.480",
        "44.152",
        "1.420",
        "1.644",
        "-0.033"
    ],
    [
        "92",
        "-17",
        "47.128",
        "45.210",
        "1.428",
        "1.609",
        "-0.010"
    ],
    [
        "93",
        "-17",
        "46.769",
        "46.248",
        "1.487",
        "1.530",
        "-0.009"
    ],
    [
        "94",
        "-17",
        "46.409",
        "47.293",
        "1.402",
        "1.544",
        "0.016"
    ],
    [
        "95",
        "-17",
        "46.058",
        "48.378",
        "1.279",
        "1.432",
        "0.025"
    ],
    [
        "96",
        "-17",
        "45.685",
        "49.502",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "-17",
        "45.430",
        "50.002",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "-17",
        "45.174",
        "50.487",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "-17",
        "44.916",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-17",
        "44.654",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-17",
        "44.390",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-17",
        "44.125",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-17",
        "43.857",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-17",
        "43.587",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-17",
        "43.316",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-17",
        "43.043",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-17",
        "42.767",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-17",
        "42.490",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-17",
        "42.211",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-17",
        "41.931",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-17",
        "41.649",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-17",
        "41.365",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-17",
        "41.082",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-17",
        "40.796",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-17",
        "40.509",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-17",
        "40.221",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-17",
        "39.933",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-17",
        "39.644",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-17",
        "39.353",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-17",
        "39.062",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-17",
        "38.771",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-17",
        "38.479",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-17",
        "38.187",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-17",
        "37.894",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-17",
        "37.601",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-17",
        "37.309",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-17",
        "37.016",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-17",
        "36.722",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-17",
        "36.430",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-17",
        "36.137",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-17",
        "35.845",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-17",
        "35.553",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-17",
        "35.262",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-17",
        "34.971",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-17",
        "34.681",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-17",
        "34.391",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-17",
        "34.103",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-17",
        "33.816",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-17",
        "33.529",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-17",
        "33.244",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-17",
        "32.959",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-17",
        "32.676",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-17",
        "32.394",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-17",
        "32.114",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-17",
        "31.835",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-17",
        "31.559",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-17",
        "31.284",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-17",
        "31.010",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-17",
        "30.739",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-17",
        "30.469",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-17",
        "30.201",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-17",
        "29.935",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-17",
        "29.672",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-17",
        "29.411",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-17",
        "29.153",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-17",
        "28.896",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-17",
        "28.643",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-17",
        "28.392",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-17",
        "28.144",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-17",
        "27.898",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-17",
        "27.656",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-17",
        "27.416",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-17",
        "27.179",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-17",
        "26.945",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-17",
        "26.714",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-17",
        "26.486",
        "44.276",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-17",
        "20.910",
        "42.015",
        "1.760",
        "1.905",
        "0.053"
    ],
    [
        "168",
        "-17",
        "-21.241",
        "14.646",
        "4.144",
        "3.247",
        "0.190"
    ],
    [
        "169",
        "-17",
        "-46.061",
        "8.578",
        "5.289",
        "3.812",
        "0.215"
    ],
    [
        "170",
        "-17",
        "-37.053",
        "8.816",
        "4.980",
        "3.433",
        "0.199"
    ],
    [
        "171",
        "-17",
        "-30.923",
        "7.618",
        "3.395",
        "2.871",
        "0.198"
    ],
    [
        "172",
        "-17",
        "-28.213",
        "10.071",
        "2.589",
        "2.713",
        "0.253"
    ],
    [
        "173",
        "-17",
        "-27.323",
        "15.227",
        "2.505",
        "2.622",
        "0.272"
    ],
    [
        "174",
        "-17",
        "-26.874",
        "20.793",
        "2.577",
        "2.503",
        "0.260"
    ],
    [
        "175",
        "-17",
        "-26.418",
        "26.211",
        "2.678",
        "2.466",
        "0.240"
    ],
    [
        "176",
        "-17",
        "-25.662",
        "31.202",
        "2.996",
        "2.668",
        "0.185"
    ],
    [
        "177",
        "-17",
        "-24.070",
        "32.398",
        "4.201",
        "4.024",
        "0.011"
    ],
    [
        "178",
        "-17",
        "-22.090",
        "31.295",
        "7.410",
        "7.937",
        "-0.024"
    ],
    [
        "179",
        "-17",
        "-19.428",
        "31.104",
        "12.507",
        "14.269",
        "-0.008"
    ],
    [
        "-180",
        "-16",
        "-31.193",
        "36.106",
        "23.826",
        "23.415",
        "-0.003"
    ],
    [
        "-179",
        "-16",
        "-22.947",
        "38.715",
        "29.630",
        "30.021",
        "0.074"
    ],
    [
        "-178",
        "-16",
        "-11.708",
        "32.425",
        "33.714",
        "35.262",
        "0.048"
    ],
    [
        "-177",
        "-16",
        "-2.318",
        "28.250",
        "36.833",
        "32.556",
        "0.026"
    ],
    [
        "-176",
        "-16",
        "8.517",
        "24.072",
        "37.439",
        "33.027",
        "-0.015"
    ],
    [
        "-175",
        "-16",
        "27.002",
        "20.184",
        "38.604",
        "29.058",
        "-0.073"
    ],
    [
        "-174",
        "-16",
        "106.255",
        "10.871",
        "25.269",
        "19.736",
        "-0.108"
    ],
    [
        "-173",
        "-16",
        "29.869",
        "11.734",
        "38.071",
        "23.217",
        "-0.080"
    ],
    [
        "-172",
        "-16",
        "-35.522",
        "25.282",
        "37.764",
        "21.219",
        "-0.117"
    ],
    [
        "-171",
        "-16",
        "-61.543",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-16",
        "-61.689",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-16",
        "-61.837",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-16",
        "-61.984",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-16",
        "-62.132",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-16",
        "-62.280",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-16",
        "-62.428",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-16",
        "-62.576",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-16",
        "-62.725",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-16",
        "-62.873",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-16",
        "-63.022",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-16",
        "-63.170",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-16",
        "-63.318",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-16",
        "-63.466",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-16",
        "-63.614",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-16",
        "-63.762",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-16",
        "-63.909",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-16",
        "-64.056",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-16",
        "-64.202",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-16",
        "-64.349",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-16",
        "-64.494",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-16",
        "-64.639",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-16",
        "-64.784",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-16",
        "-64.928",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-16",
        "-65.071",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-16",
        "-65.214",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-16",
        "-65.356",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-16",
        "-65.497",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-16",
        "-65.638",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-16",
        "-65.777",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-16",
        "-65.916",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-16",
        "-66.053",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-16",
        "-66.190",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-16",
        "-66.326",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-16",
        "-66.460",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-16",
        "-66.594",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-16",
        "-66.726",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-16",
        "-66.857",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-16",
        "-66.987",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-16",
        "-67.116",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-16",
        "-67.243",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-16",
        "-67.369",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-16",
        "-67.494",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-16",
        "-67.617",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-16",
        "-67.738",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-16",
        "-67.859",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-16",
        "-67.977",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-16",
        "-68.094",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-16",
        "-68.210",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-16",
        "-68.324",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-16",
        "-68.436",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-16",
        "-68.546",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-16",
        "-68.655",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-16",
        "-68.762",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-16",
        "-68.867",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-16",
        "-68.970",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-16",
        "-69.071",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-16",
        "-69.171",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-16",
        "28.319",
        "-4.363",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-16",
        "62.535",
        "-12.070",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-16",
        "62.591",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-16",
        "62.643",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-16",
        "62.690",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-16",
        "62.734",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-16",
        "62.774",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-16",
        "62.810",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-16",
        "62.841",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-16",
        "62.868",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-16",
        "62.890",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-16",
        "62.910",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-16",
        "62.924",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-16",
        "62.935",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-16",
        "62.940",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-16",
        "62.943",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-16",
        "62.940",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-16",
        "62.934",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-16",
        "62.923",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-16",
        "62.908",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-16",
        "62.890",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-16",
        "62.866",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-16",
        "62.840",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-16",
        "62.807",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-16",
        "62.772",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-16",
        "62.733",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-16",
        "62.688",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-16",
        "62.640",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-16",
        "62.588",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-16",
        "62.532",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-16",
        "62.472",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-16",
        "62.408",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-16",
        "62.339",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-16",
        "62.266",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-16",
        "62.190",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-16",
        "62.110",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-16",
        "62.026",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-16",
        "61.938",
        "18.738",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-16",
        "47.608",
        "16.004",
        "6.257",
        "4.696",
        "0.492"
    ],
    [
        "-74",
        "-16",
        "23.818",
        "10.076",
        "4.202",
        "4.453",
        "0.343"
    ],
    [
        "-73",
        "-16",
        "15.590",
        "11.516",
        "4.285",
        "3.303",
        "0.365"
    ],
    [
        "-72",
        "-16",
        "9.731",
        "10.655",
        "3.395",
        "3.171",
        "0.436"
    ],
    [
        "-71",
        "-16",
        "5.578",
        "10.951",
        "2.597",
        "2.528",
        "0.310"
    ],
    [
        "-70",
        "-16",
        "3.123",
        "11.511",
        "2.022",
        "1.993",
        "0.301"
    ],
    [
        "-69",
        "-16",
        "1.601",
        "11.684",
        "1.764",
        "1.670",
        "0.226"
    ],
    [
        "-68",
        "-16",
        "0.705",
        "11.927",
        "1.385",
        "1.496",
        "0.106"
    ],
    [
        "-67",
        "-16",
        "-0.462",
        "10.970",
        "1.147",
        "1.246",
        "0.162"
    ],
    [
        "-66",
        "-16",
        "-1.345",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-16",
        "-1.393",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-16",
        "-1.441",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-16",
        "-1.490",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-16",
        "-1.539",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-16",
        "-1.589",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-16",
        "-1.640",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-16",
        "-1.691",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-16",
        "-1.743",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-16",
        "-1.795",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-16",
        "-1.848",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-16",
        "-1.902",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-16",
        "-1.955",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-16",
        "-2.009",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-16",
        "-2.063",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-16",
        "-2.118",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-16",
        "-2.174",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-16",
        "-2.230",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-16",
        "-2.286",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-16",
        "-2.343",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-16",
        "-2.400",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-16",
        "-2.457",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-16",
        "-2.514",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-16",
        "-2.572",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-16",
        "-2.630",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-16",
        "-2.688",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-16",
        "-2.747",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-16",
        "-2.805",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-16",
        "-2.865",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-16",
        "-2.924",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-16",
        "-2.983",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-16",
        "-3.042",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-16",
        "-3.101",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-16",
        "-3.161",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-16",
        "-3.221",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-16",
        "-3.281",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-16",
        "-3.341",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-16",
        "-3.401",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-16",
        "-3.461",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-16",
        "-3.520",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-16",
        "-3.580",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-16",
        "-3.640",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-16",
        "-3.700",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-16",
        "-3.759",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-16",
        "-3.819",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-16",
        "-3.878",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-16",
        "-3.938",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-16",
        "-3.997",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-16",
        "-4.055",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-16",
        "-4.115",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-16",
        "-4.173",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-16",
        "-4.232",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-16",
        "-4.290",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-16",
        "24.904",
        "18.507",
        "3.973",
        "2.380",
        "0.287"
    ],
    [
        "-12",
        "-16",
        "25.215",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-16",
        "25.128",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-16",
        "25.040",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-16",
        "24.951",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-16",
        "24.861",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-16",
        "24.772",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-16",
        "24.681",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-16",
        "24.591",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-16",
        "24.499",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-16",
        "24.408",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-16",
        "24.316",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-16",
        "24.224",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-16",
        "24.131",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-16",
        "24.038",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-16",
        "23.946",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-16",
        "23.852",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-16",
        "23.759",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-16",
        "23.664",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-16",
        "23.571",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-16",
        "23.477",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-16",
        "23.383",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-16",
        "23.289",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-16",
        "23.194",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-16",
        "23.101",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-16",
        "23.007",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-16",
        "22.913",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-16",
        "22.819",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-16",
        "22.725",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-16",
        "22.631",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-16",
        "22.538",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-16",
        "22.445",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-16",
        "22.352",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-16",
        "22.259",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-16",
        "22.167",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-16",
        "22.075",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-16",
        "21.983",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-16",
        "21.891",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-16",
        "21.801",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-16",
        "21.710",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-16",
        "21.620",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-16",
        "21.530",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-16",
        "21.715",
        "18.414",
        "0.888",
        "1.037",
        "-0.051"
    ],
    [
        "30",
        "-16",
        "21.906",
        "18.214",
        "1.120",
        "1.170",
        "-0.032"
    ],
    [
        "31",
        "-16",
        "22.078",
        "18.009",
        "1.138",
        "1.275",
        "-0.008"
    ],
    [
        "32",
        "-16",
        "22.232",
        "17.800",
        "1.175",
        "1.284",
        "-0.004"
    ],
    [
        "33",
        "-16",
        "22.362",
        "17.594",
        "1.234",
        "1.226",
        "0.030"
    ],
    [
        "34",
        "-16",
        "22.468",
        "17.403",
        "1.179",
        "1.225",
        "0.040"
    ],
    [
        "35",
        "-16",
        "22.566",
        "17.224",
        "1.186",
        "1.227",
        "0.037"
    ],
    [
        "36",
        "-16",
        "22.672",
        "17.040",
        "1.259",
        "1.241",
        "0.039"
    ],
    [
        "37",
        "-16",
        "22.796",
        "16.836",
        "1.230",
        "1.312",
        "0.028"
    ],
    [
        "38",
        "-16",
        "22.931",
        "16.610",
        "1.232",
        "1.309",
        "0.008"
    ],
    [
        "39",
        "-16",
        "23.066",
        "16.374",
        "1.266",
        "1.222",
        "0.023"
    ],
    [
        "40",
        "-16",
        "23.193",
        "16.140",
        "1.167",
        "1.174",
        "0.028"
    ],
    [
        "41",
        "-16",
        "23.316",
        "15.909",
        "1.123",
        "1.106",
        "0.028"
    ],
    [
        "42",
        "-16",
        "23.441",
        "15.678",
        "1.138",
        "1.059",
        "0.051"
    ],
    [
        "43",
        "-16",
        "23.575",
        "15.439",
        "1.033",
        "1.085",
        "0.047"
    ],
    [
        "44",
        "-16",
        "23.729",
        "15.188",
        "0.912",
        "1.014",
        "0.019"
    ],
    [
        "45",
        "-16",
        "23.894",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-16",
        "23.822",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-16",
        "23.752",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-16",
        "23.683",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-16",
        "23.615",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-16",
        "23.549",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-16",
        "23.484",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-16",
        "23.419",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-16",
        "23.355",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-16",
        "23.294",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-16",
        "23.233",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-16",
        "23.174",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-16",
        "23.116",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-16",
        "23.060",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-16",
        "23.005",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-16",
        "22.950",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-16",
        "22.898",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-16",
        "22.847",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-16",
        "22.797",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-16",
        "22.749",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-16",
        "22.702",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-16",
        "22.657",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-16",
        "34.462",
        "17.870",
        "9.653",
        "9.392",
        "0.412"
    ],
    [
        "68",
        "-16",
        "51.764",
        "29.542",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-16",
        "51.620",
        "30.190",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-16",
        "51.473",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-16",
        "51.323",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-16",
        "51.171",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-16",
        "51.015",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-16",
        "50.856",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-16",
        "50.694",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-16",
        "50.530",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-16",
        "50.363",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-16",
        "50.193",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-16",
        "50.020",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-16",
        "49.845",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-16",
        "49.668",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-16",
        "49.487",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-16",
        "49.304",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-16",
        "49.120",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-16",
        "48.932",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-16",
        "48.743",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-16",
        "48.550",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-16",
        "48.273",
        "40.770",
        "0.948",
        "1.071",
        "-0.087"
    ],
    [
        "89",
        "-16",
        "47.955",
        "41.794",
        "1.147",
        "1.376",
        "-0.079"
    ],
    [
        "90",
        "-16",
        "47.604",
        "42.932",
        "1.343",
        "1.514",
        "-0.082"
    ],
    [
        "91",
        "-16",
        "47.238",
        "44.042",
        "1.345",
        "1.604",
        "-0.047"
    ],
    [
        "92",
        "-16",
        "46.865",
        "45.108",
        "1.376",
        "1.581",
        "-0.028"
    ],
    [
        "93",
        "-16",
        "46.498",
        "46.123",
        "1.449",
        "1.519",
        "-0.030"
    ],
    [
        "94",
        "-16",
        "46.141",
        "47.106",
        "1.397",
        "1.543",
        "-0.005"
    ],
    [
        "95",
        "-16",
        "45.797",
        "48.084",
        "1.363",
        "1.481",
        "0.019"
    ],
    [
        "96",
        "-16",
        "45.503",
        "48.991",
        "1.349",
        "1.258",
        "0.019"
    ],
    [
        "97",
        "-16",
        "45.259",
        "49.697",
        "1.118",
        "1.178",
        "0.012"
    ],
    [
        "98",
        "-16",
        "44.996",
        "50.334",
        "0.971",
        "1.024",
        "-0.028"
    ],
    [
        "99",
        "-16",
        "44.666",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-16",
        "44.420",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-16",
        "44.171",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-16",
        "43.921",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-16",
        "43.668",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-16",
        "43.413",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-16",
        "43.157",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-16",
        "42.899",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-16",
        "42.640",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-16",
        "42.379",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-16",
        "42.115",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-16",
        "41.852",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-16",
        "41.586",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-16",
        "41.319",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-16",
        "41.051",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-16",
        "40.782",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-16",
        "40.511",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-16",
        "40.240",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-16",
        "39.968",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-16",
        "39.695",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-16",
        "39.421",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-16",
        "39.147",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-16",
        "38.872",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-16",
        "38.598",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-16",
        "38.321",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-16",
        "38.046",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-16",
        "37.770",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-16",
        "37.494",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-16",
        "37.217",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-16",
        "36.941",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-16",
        "36.665",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-16",
        "36.389",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-16",
        "36.113",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-16",
        "35.838",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-16",
        "35.564",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-16",
        "35.290",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-16",
        "35.016",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-16",
        "34.743",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-16",
        "34.471",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-16",
        "34.201",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-16",
        "33.930",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-16",
        "33.661",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-16",
        "33.393",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-16",
        "33.126",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-16",
        "32.861",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-16",
        "32.597",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-16",
        "32.334",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-16",
        "32.073",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-16",
        "31.813",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-16",
        "31.556",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-16",
        "31.300",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-16",
        "31.046",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-16",
        "30.794",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-16",
        "30.543",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-16",
        "30.295",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-16",
        "30.048",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-16",
        "29.805",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-16",
        "29.564",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-16",
        "29.324",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-16",
        "29.088",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-16",
        "28.853",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-16",
        "28.622",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-16",
        "28.393",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-16",
        "28.167",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-16",
        "27.944",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-16",
        "27.723",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-16",
        "27.506",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-16",
        "28.669",
        "43.148",
        "4.023",
        "3.541",
        "0.183"
    ],
    [
        "167",
        "-16",
        "5.881",
        "32.626",
        "2.589",
        "2.526",
        "0.278"
    ],
    [
        "168",
        "-16",
        "-11.301",
        "27.116",
        "4.640",
        "2.643",
        "0.118"
    ],
    [
        "169",
        "-16",
        "-42.541",
        "11.617",
        "5.147",
        "3.455",
        "0.182"
    ],
    [
        "170",
        "-16",
        "-35.611",
        "10.138",
        "4.312",
        "2.967",
        "0.185"
    ],
    [
        "171",
        "-16",
        "-35.193",
        "9.316",
        "3.038",
        "2.612",
        "0.152"
    ],
    [
        "172",
        "-16",
        "-36.448",
        "12.180",
        "2.442",
        "2.674",
        "0.122"
    ],
    [
        "173",
        "-16",
        "-37.172",
        "17.183",
        "2.409",
        "2.721",
        "0.132"
    ],
    [
        "174",
        "-16",
        "-37.550",
        "22.414",
        "2.518",
        "2.649",
        "0.141"
    ],
    [
        "175",
        "-16",
        "-37.676",
        "27.416",
        "2.648",
        "2.665",
        "0.144"
    ],
    [
        "176",
        "-16",
        "-37.295",
        "31.785",
        "3.035",
        "2.947",
        "0.136"
    ],
    [
        "177",
        "-16",
        "-35.941",
        "32.588",
        "4.592",
        "4.855",
        "-0.005"
    ],
    [
        "178",
        "-16",
        "-34.024",
        "31.572",
        "8.919",
        "9.943",
        "-0.019"
    ],
    [
        "179",
        "-16",
        "-32.554",
        "33.029",
        "15.380",
        "17.681",
        "0.051"
    ],
    [
        "-180",
        "-15",
        "-41.647",
        "33.273",
        "26.458",
        "20.352",
        "0.143"
    ],
    [
        "-179",
        "-15",
        "-34.556",
        "33.431",
        "33.275",
        "29.724",
        "0.164"
    ],
    [
        "-178",
        "-15",
        "-25.853",
        "30.077",
        "37.493",
        "35.801",
        "0.109"
    ],
    [
        "-177",
        "-15",
        "-17.593",
        "26.261",
        "40.694",
        "32.563",
        "0.065"
    ],
    [
        "-176",
        "-15",
        "-10.883",
        "22.921",
        "40.138",
        "31.321",
        "-0.041"
    ],
    [
        "-175",
        "-15",
        "-5.386",
        "20.761",
        "40.079",
        "27.127",
        "-0.105"
    ],
    [
        "-174",
        "-15",
        "-7.106",
        "23.439",
        "43.898",
        "23.124",
        "-0.094"
    ],
    [
        "-173",
        "-15",
        "-32.735",
        "26.949",
        "38.181",
        "20.870",
        "-0.162"
    ],
    [
        "-172",
        "-15",
        "-54.525",
        "29.506",
        "32.337",
        "18.578",
        "-0.139"
    ],
    [
        "-171",
        "-15",
        "-61.925",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-15",
        "-62.063",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-15",
        "-62.201",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-15",
        "-62.340",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-15",
        "-62.478",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-15",
        "-62.617",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-15",
        "-62.756",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-15",
        "-62.896",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-15",
        "-63.035",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-15",
        "-63.174",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-15",
        "-63.314",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-15",
        "-63.453",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-15",
        "-63.592",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-15",
        "-63.731",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-15",
        "-63.870",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-15",
        "-64.008",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-15",
        "-64.147",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-15",
        "-64.285",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-15",
        "-64.422",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-15",
        "-64.560",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-15",
        "-64.696",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-15",
        "-64.833",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-15",
        "-64.969",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-15",
        "-65.104",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-15",
        "-65.238",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-15",
        "-65.372",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-15",
        "-65.506",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-15",
        "-65.638",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-15",
        "-65.770",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-15",
        "-65.901",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-15",
        "-66.031",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-15",
        "-66.160",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-15",
        "-66.289",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-15",
        "-66.416",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-15",
        "-66.543",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-15",
        "-66.668",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-15",
        "-66.792",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-15",
        "-66.915",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-15",
        "-67.037",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-15",
        "-67.158",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-15",
        "-67.277",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-15",
        "-67.396",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-15",
        "-67.513",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-15",
        "-67.628",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-15",
        "-67.743",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-15",
        "-67.855",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-15",
        "-67.967",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-15",
        "-68.077",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-15",
        "-68.185",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-15",
        "-68.292",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-15",
        "-68.397",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-15",
        "-68.501",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-15",
        "-68.603",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-15",
        "-68.703",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-15",
        "-68.802",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-15",
        "-68.899",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-15",
        "-68.994",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-15",
        "-69.088",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-15",
        "-28.863",
        "11.593",
        "14.396",
        "17.185",
        "-0.009"
    ],
    [
        "-112",
        "-15",
        "61.958",
        "-12.070",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-15",
        "62.010",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-15",
        "62.059",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-15",
        "62.104",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-15",
        "62.145",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-15",
        "62.182",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-15",
        "62.216",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-15",
        "62.245",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-15",
        "62.271",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-15",
        "62.292",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-15",
        "62.310",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-15",
        "62.323",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-15",
        "62.333",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-15",
        "62.339",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-15",
        "62.341",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-15",
        "62.339",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-15",
        "62.333",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-15",
        "62.322",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-15",
        "62.309",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-15",
        "62.291",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-15",
        "62.269",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-15",
        "62.243",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-15",
        "62.214",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-15",
        "62.180",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-15",
        "62.144",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-15",
        "62.102",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-15",
        "62.057",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-15",
        "62.008",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-15",
        "61.956",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-15",
        "61.899",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-15",
        "61.838",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-15",
        "61.774",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-15",
        "61.706",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-15",
        "61.635",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-15",
        "61.559",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-15",
        "61.479",
        "17.927",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "-15",
        "45.630",
        "14.019",
        "5.516",
        "4.358",
        "0.372"
    ],
    [
        "-75",
        "-15",
        "19.685",
        "8.763",
        "5.317",
        "4.562",
        "0.461"
    ],
    [
        "-74",
        "-15",
        "14.039",
        "9.745",
        "4.369",
        "3.496",
        "0.370"
    ],
    [
        "-73",
        "-15",
        "9.853",
        "9.405",
        "2.974",
        "2.396",
        "0.332"
    ],
    [
        "-72",
        "-15",
        "4.472",
        "9.411",
        "2.401",
        "2.322",
        "0.386"
    ],
    [
        "-71",
        "-15",
        "2.296",
        "10.265",
        "1.739",
        "1.982",
        "0.269"
    ],
    [
        "-70",
        "-15",
        "1.216",
        "10.963",
        "1.558",
        "1.739",
        "0.214"
    ],
    [
        "-69",
        "-15",
        "0.312",
        "11.002",
        "1.656",
        "1.702",
        "0.173"
    ],
    [
        "-68",
        "-15",
        "-0.649",
        "10.635",
        "1.411",
        "1.610",
        "0.103"
    ],
    [
        "-67",
        "-15",
        "-1.446",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-15",
        "-1.490",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-15",
        "-1.534",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-15",
        "-1.580",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-15",
        "-1.626",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-15",
        "-1.673",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-15",
        "-1.719",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-15",
        "-1.767",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-15",
        "-1.816",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-15",
        "-1.864",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-15",
        "-1.913",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-15",
        "-1.962",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-15",
        "-2.012",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-15",
        "-2.063",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-15",
        "-2.113",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-15",
        "-2.165",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-15",
        "-2.216",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-15",
        "-2.268",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-15",
        "-2.321",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-15",
        "-2.374",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-15",
        "-2.427",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-15",
        "-2.480",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-15",
        "-2.534",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-15",
        "-2.588",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-15",
        "-2.642",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-15",
        "-2.697",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-15",
        "-2.751",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-15",
        "-2.806",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-15",
        "-2.861",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-15",
        "-2.916",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-15",
        "-2.972",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-15",
        "-3.028",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-15",
        "-3.083",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-15",
        "-3.140",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-15",
        "-3.195",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-15",
        "-3.252",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-15",
        "-3.307",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-15",
        "-3.363",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-15",
        "-3.420",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-15",
        "-3.476",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-15",
        "-3.533",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-15",
        "-3.588",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-15",
        "-3.645",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-15",
        "-3.701",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-15",
        "-3.757",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-15",
        "-3.813",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-15",
        "-3.869",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-15",
        "-3.924",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-15",
        "-3.980",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-15",
        "-4.035",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-15",
        "-4.090",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-15",
        "-4.146",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-15",
        "-4.200",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-15",
        "-4.255",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-15",
        "25.288",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-15",
        "25.207",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-15",
        "25.124",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-15",
        "25.041",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-15",
        "24.958",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-15",
        "24.875",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-15",
        "24.790",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-15",
        "24.705",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-15",
        "24.620",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-15",
        "24.534",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-15",
        "24.448",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-15",
        "24.362",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-15",
        "24.275",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-15",
        "24.188",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-15",
        "24.101",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-15",
        "24.013",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-15",
        "23.926",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-15",
        "23.838",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-15",
        "23.751",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-15",
        "23.662",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-15",
        "23.573",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-15",
        "23.486",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-15",
        "23.397",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-15",
        "23.309",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-15",
        "23.220",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-15",
        "23.132",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-15",
        "23.044",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-15",
        "22.956",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-15",
        "22.868",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-15",
        "22.780",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-15",
        "22.692",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-15",
        "22.605",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-15",
        "22.518",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-15",
        "22.430",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-15",
        "22.343",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-15",
        "22.257",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-15",
        "22.171",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-15",
        "22.085",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-15",
        "22.000",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-15",
        "21.915",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-15",
        "21.831",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-15",
        "21.746",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-15",
        "21.979",
        "18.385",
        "1.255",
        "1.402",
        "-0.063"
    ],
    [
        "30",
        "-15",
        "22.198",
        "18.187",
        "1.489",
        "1.486",
        "-0.037"
    ],
    [
        "31",
        "-15",
        "22.399",
        "17.987",
        "1.452",
        "1.581",
        "0.002"
    ],
    [
        "32",
        "-15",
        "22.576",
        "17.783",
        "1.455",
        "1.536",
        "0.023"
    ],
    [
        "33",
        "-15",
        "22.718",
        "17.588",
        "1.488",
        "1.407",
        "0.087"
    ],
    [
        "34",
        "-15",
        "22.821",
        "17.410",
        "1.386",
        "1.358",
        "0.100"
    ],
    [
        "35",
        "-15",
        "22.907",
        "17.242",
        "1.395",
        "1.359",
        "0.094"
    ],
    [
        "36",
        "-15",
        "23.006",
        "17.064",
        "1.512",
        "1.417",
        "0.090"
    ],
    [
        "37",
        "-15",
        "23.135",
        "16.857",
        "1.495",
        "1.546",
        "0.055"
    ],
    [
        "38",
        "-15",
        "23.290",
        "16.622",
        "1.503",
        "1.557",
        "0.027"
    ],
    [
        "39",
        "-15",
        "23.450",
        "16.379",
        "1.544",
        "1.412",
        "0.050"
    ],
    [
        "40",
        "-15",
        "23.601",
        "16.142",
        "1.381",
        "1.305",
        "0.060"
    ],
    [
        "41",
        "-15",
        "23.740",
        "15.910",
        "1.315",
        "1.190",
        "0.058"
    ],
    [
        "42",
        "-15",
        "23.874",
        "15.674",
        "1.359",
        "1.159",
        "0.088"
    ],
    [
        "43",
        "-15",
        "24.005",
        "15.426",
        "1.262",
        "1.256",
        "0.066"
    ],
    [
        "44",
        "-15",
        "24.132",
        "15.173",
        "1.163",
        "1.268",
        "0.022"
    ],
    [
        "45",
        "-15",
        "24.247",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-15",
        "24.180",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-15",
        "24.114",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-15",
        "24.048",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-15",
        "23.985",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-15",
        "23.922",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-15",
        "23.860",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-15",
        "23.800",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-15",
        "23.741",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-15",
        "23.683",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-15",
        "23.626",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-15",
        "23.570",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-15",
        "23.516",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-15",
        "23.462",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-15",
        "23.411",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-15",
        "23.360",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-15",
        "23.312",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-15",
        "23.263",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-15",
        "23.217",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-15",
        "23.172",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-15",
        "23.128",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-15",
        "23.085",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-15",
        "44.098",
        "23.721",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-15",
        "51.334",
        "29.542",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-15",
        "51.200",
        "30.190",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-15",
        "51.062",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-15",
        "50.921",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-15",
        "50.778",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-15",
        "50.631",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-15",
        "50.482",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-15",
        "50.331",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-15",
        "50.177",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-15",
        "50.019",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-15",
        "49.860",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-15",
        "49.698",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-15",
        "49.534",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-15",
        "49.366",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-15",
        "49.197",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-15",
        "49.025",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-15",
        "48.852",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-15",
        "48.676",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-15",
        "48.497",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-15",
        "48.318",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-15",
        "48.135",
        "40.569",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-15",
        "47.768",
        "41.651",
        "1.063",
        "1.358",
        "-0.082"
    ],
    [
        "90",
        "-15",
        "47.381",
        "42.804",
        "1.367",
        "1.530",
        "-0.067"
    ],
    [
        "91",
        "-15",
        "46.984",
        "43.918",
        "1.399",
        "1.642",
        "-0.034"
    ],
    [
        "92",
        "-15",
        "46.595",
        "44.980",
        "1.444",
        "1.637",
        "-0.023"
    ],
    [
        "93",
        "-15",
        "46.228",
        "45.973",
        "1.526",
        "1.573",
        "-0.030"
    ],
    [
        "94",
        "-15",
        "45.884",
        "46.912",
        "1.439",
        "1.587",
        "-0.024"
    ],
    [
        "95",
        "-15",
        "45.565",
        "47.820",
        "1.390",
        "1.508",
        "-0.026"
    ],
    [
        "96",
        "-15",
        "45.278",
        "48.670",
        "1.383",
        "1.327",
        "-0.044"
    ],
    [
        "97",
        "-15",
        "45.015",
        "49.451",
        "1.174",
        "1.233",
        "-0.029"
    ],
    [
        "98",
        "-15",
        "44.731",
        "50.224",
        "0.997",
        "1.078",
        "-0.053"
    ],
    [
        "99",
        "-15",
        "44.402",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-15",
        "44.171",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-15",
        "43.937",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-15",
        "43.702",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-15",
        "43.465",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-15",
        "43.226",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-15",
        "42.985",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-15",
        "42.743",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-15",
        "42.499",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-15",
        "42.254",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-15",
        "42.007",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-15",
        "41.759",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-15",
        "41.509",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-15",
        "41.259",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-15",
        "41.007",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-15",
        "40.755",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-15",
        "40.501",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-15",
        "40.246",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-15",
        "39.991",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-15",
        "39.734",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-15",
        "39.478",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-15",
        "39.220",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-15",
        "38.962",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-15",
        "38.703",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-15",
        "38.445",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-15",
        "38.186",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-15",
        "37.927",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-15",
        "37.667",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-15",
        "37.408",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-15",
        "37.148",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-15",
        "36.890",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-15",
        "36.631",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-15",
        "36.371",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-15",
        "36.113",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-15",
        "35.855",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-15",
        "35.597",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-15",
        "35.341",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-15",
        "35.085",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-15",
        "34.830",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-15",
        "34.575",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-15",
        "34.321",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-15",
        "34.068",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-15",
        "33.816",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-15",
        "33.567",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-15",
        "33.317",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-15",
        "33.069",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-15",
        "32.823",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-15",
        "32.577",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-15",
        "32.334",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-15",
        "32.092",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-15",
        "31.851",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-15",
        "31.613",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-15",
        "31.376",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-15",
        "31.140",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-15",
        "30.908",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-15",
        "30.677",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-15",
        "30.448",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-15",
        "30.221",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-15",
        "29.997",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-15",
        "29.774",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-15",
        "29.554",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-15",
        "29.337",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-15",
        "29.122",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-15",
        "28.910",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-15",
        "28.700",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-15",
        "28.493",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-15",
        "28.289",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-15",
        "21.565",
        "40.965",
        "4.441",
        "3.999",
        "0.431"
    ],
    [
        "167",
        "-15",
        "-9.658",
        "32.160",
        "2.893",
        "2.005",
        "0.023"
    ],
    [
        "168",
        "-15",
        "-22.964",
        "27.019",
        "1.777",
        "1.731",
        "0.204"
    ],
    [
        "169",
        "-15",
        "-39.333",
        "15.508",
        "5.062",
        "3.345",
        "0.169"
    ],
    [
        "170",
        "-15",
        "-37.629",
        "13.591",
        "3.953",
        "2.728",
        "0.153"
    ],
    [
        "171",
        "-15",
        "-40.979",
        "13.450",
        "2.898",
        "2.474",
        "0.070"
    ],
    [
        "172",
        "-15",
        "-44.206",
        "15.353",
        "2.313",
        "2.621",
        "0.003"
    ],
    [
        "173",
        "-15",
        "-46.103",
        "19.161",
        "2.186",
        "2.695",
        "0.017"
    ],
    [
        "174",
        "-15",
        "-47.133",
        "23.824",
        "2.237",
        "2.657",
        "0.050"
    ],
    [
        "175",
        "-15",
        "-47.590",
        "28.462",
        "2.329",
        "2.734",
        "0.088"
    ],
    [
        "176",
        "-15",
        "-47.344",
        "32.206",
        "2.764",
        "3.062",
        "0.119"
    ],
    [
        "177",
        "-15",
        "-46.219",
        "32.195",
        "4.381",
        "4.800",
        "0.001"
    ],
    [
        "178",
        "-15",
        "-45.839",
        "31.286",
        "8.509",
        "9.119",
        "0.015"
    ],
    [
        "179",
        "-15",
        "-45.432",
        "33.088",
        "15.112",
        "15.836",
        "0.122"
    ],
    [
        "-180",
        "-14",
        "-61.148",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-14",
        "-61.272",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-14",
        "-61.397",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-14",
        "-61.523",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-14",
        "-61.649",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-14",
        "-61.776",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-14",
        "-61.904",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-14",
        "-62.031",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-14",
        "-62.160",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-14",
        "-62.288",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-14",
        "-62.417",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-14",
        "-62.546",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-14",
        "-62.676",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-14",
        "-62.806",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-14",
        "-62.935",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-14",
        "-63.066",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-14",
        "-63.196",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-14",
        "-63.326",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-14",
        "-63.456",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-14",
        "-63.586",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-14",
        "-63.716",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-14",
        "-63.847",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-14",
        "-63.976",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-14",
        "-64.106",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-14",
        "-64.236",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-14",
        "-64.365",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-14",
        "-64.494",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-14",
        "-64.623",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-14",
        "-64.751",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-14",
        "-64.879",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-14",
        "-65.006",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-14",
        "-65.133",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-14",
        "-65.260",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-14",
        "-65.385",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-14",
        "-65.511",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-14",
        "-65.635",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-14",
        "-65.759",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-14",
        "-65.882",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-14",
        "-66.005",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-14",
        "-66.127",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-14",
        "-66.247",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-14",
        "-66.367",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-14",
        "-66.486",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-14",
        "-66.604",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-14",
        "-66.722",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-14",
        "-66.838",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-14",
        "-66.953",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-14",
        "-67.067",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-14",
        "-67.180",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-14",
        "-67.291",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-14",
        "-67.402",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-14",
        "-67.511",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-14",
        "-67.619",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-14",
        "-67.726",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-14",
        "-67.832",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-14",
        "-67.936",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-14",
        "-68.039",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-14",
        "-68.140",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-14",
        "-68.240",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-14",
        "-68.338",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-14",
        "-68.435",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-14",
        "-68.531",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-14",
        "-68.624",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-14",
        "-68.717",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-14",
        "-68.807",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-14",
        "-68.896",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-14",
        "-68.983",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-14",
        "-69.069",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-14",
        "61.362",
        "-12.070",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-14",
        "61.411",
        "-11.221",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-14",
        "61.457",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-14",
        "61.499",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-14",
        "61.537",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-14",
        "61.572",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-14",
        "61.603",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-14",
        "61.630",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-14",
        "61.654",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-14",
        "61.674",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-14",
        "61.691",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-14",
        "61.703",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-14",
        "61.713",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-14",
        "61.718",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-14",
        "61.719",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-14",
        "61.718",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-14",
        "61.712",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-14",
        "61.703",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-14",
        "61.690",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-14",
        "61.673",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-14",
        "61.653",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-14",
        "61.629",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-14",
        "61.601",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-14",
        "61.570",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-14",
        "61.535",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-14",
        "61.497",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-14",
        "61.454",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-14",
        "61.409",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-14",
        "61.360",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-14",
        "61.307",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-14",
        "61.250",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-14",
        "61.190",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-14",
        "61.127",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-14",
        "61.060",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-14",
        "60.989",
        "17.110",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "-14",
        "48.057",
        "15.276",
        "5.645",
        "4.218",
        "0.261"
    ],
    [
        "-76",
        "-14",
        "21.775",
        "6.985",
        "3.733",
        "3.662",
        "0.217"
    ],
    [
        "-75",
        "-14",
        "14.858",
        "9.192",
        "4.816",
        "3.637",
        "0.489"
    ],
    [
        "-74",
        "-14",
        "9.079",
        "9.507",
        "2.366",
        "1.856",
        "0.164"
    ],
    [
        "-73",
        "-14",
        "3.489",
        "8.647",
        "2.154",
        "2.070",
        "0.326"
    ],
    [
        "-72",
        "-14",
        "1.456",
        "9.253",
        "1.690",
        "1.745",
        "0.269"
    ],
    [
        "-71",
        "-14",
        "0.495",
        "10.102",
        "1.455",
        "1.731",
        "0.264"
    ],
    [
        "-70",
        "-14",
        "-0.098",
        "10.412",
        "1.436",
        "1.727",
        "0.204"
    ],
    [
        "-69",
        "-14",
        "-0.681",
        "10.196",
        "1.512",
        "1.621",
        "0.112"
    ],
    [
        "-68",
        "-14",
        "-1.460",
        "9.675",
        "0.360",
        "0.569",
        "0.050"
    ],
    [
        "-67",
        "-14",
        "-1.593",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-14",
        "-1.635",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-14",
        "-1.676",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-14",
        "-1.719",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-14",
        "-1.762",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-14",
        "-1.805",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-14",
        "-1.849",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-14",
        "-1.893",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-14",
        "-1.938",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-14",
        "-1.984",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-14",
        "-2.029",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-14",
        "-2.076",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-14",
        "-2.123",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-14",
        "-2.170",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-14",
        "-2.217",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-14",
        "-2.265",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-14",
        "-2.314",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-14",
        "-2.362",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-14",
        "-2.411",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-14",
        "-2.461",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-14",
        "-2.510",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-14",
        "-2.560",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-14",
        "-2.610",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-14",
        "-2.661",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-14",
        "-2.711",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-14",
        "-2.762",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-14",
        "-2.813",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-14",
        "-2.864",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-14",
        "-2.917",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-14",
        "-2.968",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-14",
        "-3.019",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-14",
        "-3.072",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-14",
        "-3.124",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-14",
        "-3.176",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-14",
        "-3.229",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-14",
        "-3.281",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-14",
        "-3.333",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-14",
        "-3.386",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-14",
        "-3.438",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-14",
        "-3.491",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-14",
        "-3.543",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-14",
        "-3.596",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-14",
        "-3.649",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-14",
        "-3.701",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-14",
        "-3.754",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-14",
        "-3.806",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-14",
        "-3.858",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-14",
        "-3.910",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-14",
        "-3.962",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-14",
        "-4.013",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-14",
        "-4.065",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-14",
        "-4.117",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-14",
        "-4.168",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-14",
        "20.594",
        "16.341",
        "1.190",
        "1.315",
        "0.245"
    ],
    [
        "-13",
        "-14",
        "25.266",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-14",
        "25.190",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-14",
        "25.113",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-14",
        "25.035",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-14",
        "24.957",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-14",
        "24.879",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-14",
        "24.800",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-14",
        "24.721",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-14",
        "24.642",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-14",
        "24.561",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-14",
        "24.481",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-14",
        "24.400",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-14",
        "24.319",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-14",
        "24.238",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-14",
        "24.157",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-14",
        "24.075",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-14",
        "23.992",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-14",
        "23.911",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-14",
        "23.829",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-14",
        "23.746",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-14",
        "23.664",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-14",
        "23.581",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-14",
        "23.499",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-14",
        "23.416",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-14",
        "23.334",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-14",
        "23.251",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-14",
        "23.169",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-14",
        "23.086",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-14",
        "23.004",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-14",
        "22.922",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-14",
        "22.840",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-14",
        "22.757",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-14",
        "22.677",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-14",
        "22.595",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-14",
        "22.514",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-14",
        "22.433",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-14",
        "22.353",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-14",
        "22.272",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-14",
        "22.192",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-14",
        "22.113",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-14",
        "22.109",
        "18.674",
        "1.576",
        "1.511",
        "-0.001"
    ],
    [
        "28",
        "-14",
        "22.189",
        "18.524",
        "1.604",
        "1.742",
        "-0.010"
    ],
    [
        "29",
        "-14",
        "22.346",
        "18.328",
        "1.692",
        "1.802",
        "-0.042"
    ],
    [
        "30",
        "-14",
        "22.538",
        "18.139",
        "1.858",
        "1.802",
        "-0.022"
    ],
    [
        "31",
        "-14",
        "22.743",
        "17.951",
        "1.754",
        "1.879",
        "0.013"
    ],
    [
        "32",
        "-14",
        "22.932",
        "17.763",
        "1.715",
        "1.778",
        "0.052"
    ],
    [
        "33",
        "-14",
        "23.079",
        "17.587",
        "1.715",
        "1.577",
        "0.128"
    ],
    [
        "34",
        "-14",
        "23.178",
        "17.427",
        "1.581",
        "1.482",
        "0.143"
    ],
    [
        "35",
        "-14",
        "23.257",
        "17.270",
        "1.593",
        "1.485",
        "0.134"
    ],
    [
        "36",
        "-14",
        "23.350",
        "17.093",
        "1.741",
        "1.588",
        "0.122"
    ],
    [
        "37",
        "-14",
        "23.485",
        "16.881",
        "1.738",
        "1.771",
        "0.067"
    ],
    [
        "38",
        "-14",
        "23.659",
        "16.634",
        "1.751",
        "1.794",
        "0.039"
    ],
    [
        "39",
        "-14",
        "23.847",
        "16.379",
        "1.794",
        "1.594",
        "0.058"
    ],
    [
        "40",
        "-14",
        "24.025",
        "16.137",
        "1.571",
        "1.427",
        "0.066"
    ],
    [
        "41",
        "-14",
        "24.188",
        "15.903",
        "1.479",
        "1.261",
        "0.069"
    ],
    [
        "42",
        "-14",
        "24.330",
        "15.660",
        "1.530",
        "1.225",
        "0.109"
    ],
    [
        "43",
        "-14",
        "24.445",
        "15.402",
        "1.412",
        "1.357",
        "0.080"
    ],
    [
        "44",
        "-14",
        "24.531",
        "15.156",
        "1.307",
        "1.404",
        "0.031"
    ],
    [
        "45",
        "-14",
        "24.591",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-14",
        "24.529",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-14",
        "24.467",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-14",
        "24.407",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-14",
        "24.347",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-14",
        "24.288",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-14",
        "24.231",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-14",
        "24.175",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-14",
        "24.120",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-14",
        "24.065",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-14",
        "24.012",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-14",
        "23.960",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-14",
        "23.909",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-14",
        "23.859",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-14",
        "23.811",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-14",
        "23.763",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-14",
        "23.718",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-14",
        "23.673",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-14",
        "23.629",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-14",
        "23.586",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-14",
        "23.545",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-14",
        "35.051",
        "16.165",
        "12.944",
        "9.346",
        "0.409"
    ],
    [
        "67",
        "-14",
        "51.013",
        "28.884",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-14",
        "50.890",
        "29.542",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-14",
        "50.764",
        "30.190",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-14",
        "50.636",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-14",
        "50.504",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-14",
        "50.370",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-14",
        "50.233",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-14",
        "50.094",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-14",
        "49.952",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-14",
        "49.808",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-14",
        "49.661",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-14",
        "49.512",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-14",
        "49.360",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-14",
        "49.206",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-14",
        "49.051",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-14",
        "48.893",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-14",
        "48.732",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-14",
        "48.569",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-14",
        "48.405",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-14",
        "48.238",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-14",
        "48.070",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-14",
        "47.900",
        "40.569",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-14",
        "47.531",
        "41.498",
        "1.188",
        "1.416",
        "-0.045"
    ],
    [
        "90",
        "-14",
        "47.115",
        "42.635",
        "1.511",
        "1.595",
        "-0.038"
    ],
    [
        "91",
        "-14",
        "46.703",
        "43.711",
        "1.527",
        "1.723",
        "-0.020"
    ],
    [
        "92",
        "-14",
        "46.311",
        "44.735",
        "1.570",
        "1.730",
        "-0.015"
    ],
    [
        "93",
        "-14",
        "45.945",
        "45.704",
        "1.654",
        "1.656",
        "-0.022"
    ],
    [
        "94",
        "-14",
        "45.599",
        "46.634",
        "1.519",
        "1.653",
        "-0.033"
    ],
    [
        "95",
        "-14",
        "45.272",
        "47.541",
        "1.433",
        "1.532",
        "-0.056"
    ],
    [
        "96",
        "-14",
        "44.981",
        "48.420",
        "1.388",
        "1.326",
        "-0.092"
    ],
    [
        "97",
        "-14",
        "44.717",
        "49.273",
        "1.170",
        "1.201",
        "-0.060"
    ],
    [
        "98",
        "-14",
        "44.430",
        "50.147",
        "0.988",
        "1.082",
        "-0.106"
    ],
    [
        "99",
        "-14",
        "44.124",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-14",
        "43.908",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-14",
        "43.690",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-14",
        "43.470",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-14",
        "43.249",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-14",
        "43.025",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-14",
        "42.800",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-14",
        "42.574",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-14",
        "42.346",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-14",
        "42.117",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-14",
        "41.887",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-14",
        "41.654",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-14",
        "41.422",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-14",
        "41.187",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-14",
        "40.952",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-14",
        "40.715",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-14",
        "40.479",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-14",
        "40.241",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-14",
        "40.001",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-14",
        "39.762",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-14",
        "39.521",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-14",
        "39.281",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-14",
        "39.039",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-14",
        "38.798",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-14",
        "38.557",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-14",
        "38.314",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-14",
        "38.072",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-14",
        "37.830",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-14",
        "37.587",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-14",
        "37.344",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-14",
        "37.102",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-14",
        "36.860",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-14",
        "36.619",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-14",
        "36.377",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-14",
        "36.136",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-14",
        "35.895",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-14",
        "35.655",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-14",
        "35.416",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-14",
        "35.177",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-14",
        "34.939",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-14",
        "34.702",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-14",
        "34.466",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-14",
        "34.231",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-14",
        "33.996",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-14",
        "33.764",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-14",
        "33.531",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-14",
        "33.301",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-14",
        "33.072",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-14",
        "32.844",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-14",
        "32.618",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-14",
        "32.393",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-14",
        "32.170",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-14",
        "31.949",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-14",
        "31.729",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-14",
        "31.512",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-14",
        "31.295",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-14",
        "31.081",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-14",
        "30.869",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-14",
        "30.659",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-14",
        "30.451",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-14",
        "30.246",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-14",
        "30.043",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-14",
        "29.842",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-14",
        "29.644",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-14",
        "29.448",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-14",
        "29.254",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-14",
        "29.063",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-14",
        "15.293",
        "38.779",
        "5.140",
        "4.061",
        "0.422"
    ],
    [
        "167",
        "-14",
        "-18.255",
        "29.240",
        "5.887",
        "4.124",
        "0.196"
    ],
    [
        "168",
        "-14",
        "-39.012",
        "28.986",
        "5.115",
        "3.433",
        "0.327"
    ],
    [
        "169",
        "-14",
        "-39.862",
        "19.188",
        "4.822",
        "3.187",
        "0.156"
    ],
    [
        "170",
        "-14",
        "-43.785",
        "17.669",
        "3.588",
        "2.549",
        "0.109"
    ],
    [
        "171",
        "-14",
        "-47.914",
        "18.073",
        "2.685",
        "2.314",
        "0.015"
    ],
    [
        "172",
        "-14",
        "-51.011",
        "19.252",
        "2.131",
        "2.498",
        "-0.049"
    ],
    [
        "173",
        "-14",
        "-53.182",
        "21.645",
        "1.891",
        "2.583",
        "-0.039"
    ],
    [
        "174",
        "-14",
        "-54.655",
        "24.992",
        "1.786",
        "2.520",
        "-0.012"
    ],
    [
        "175",
        "-14",
        "-55.562",
        "28.652",
        "1.703",
        "2.537",
        "0.034"
    ],
    [
        "176",
        "-14",
        "-55.968",
        "31.436",
        "2.000",
        "2.706",
        "0.101"
    ],
    [
        "177",
        "-14",
        "-55.878",
        "30.687",
        "3.186",
        "3.659",
        "-0.017"
    ],
    [
        "178",
        "-14",
        "-59.952",
        "29.246",
        "1.043",
        "2.251",
        "0.100"
    ],
    [
        "179",
        "-14",
        "-61.024",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-13",
        "-61.572",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-13",
        "-61.688",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-13",
        "-61.804",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-13",
        "-61.921",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-13",
        "-62.039",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-13",
        "-62.157",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-13",
        "-62.275",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-13",
        "-62.394",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-13",
        "-62.513",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-13",
        "-62.633",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-13",
        "-62.753",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-13",
        "-62.873",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-13",
        "-62.993",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-13",
        "-63.114",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-13",
        "-63.235",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-13",
        "-63.355",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-13",
        "-63.476",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-13",
        "-63.598",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-13",
        "-63.719",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-13",
        "-63.840",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-13",
        "-63.961",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-13",
        "-64.082",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-13",
        "-64.202",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-13",
        "-64.323",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-13",
        "-64.444",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-13",
        "-64.564",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-13",
        "-64.684",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-13",
        "-64.803",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-13",
        "-64.923",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-13",
        "-65.042",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-13",
        "-65.160",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-13",
        "-65.278",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-13",
        "-65.396",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-13",
        "-65.513",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-13",
        "-65.629",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-13",
        "-65.745",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-13",
        "-65.860",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-13",
        "-65.975",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-13",
        "-66.089",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-13",
        "-66.202",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-13",
        "-66.314",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-13",
        "-66.426",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-13",
        "-66.536",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-13",
        "-66.646",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-13",
        "-66.755",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-13",
        "-66.863",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-13",
        "-66.970",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-13",
        "-67.076",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-13",
        "-67.181",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-13",
        "-67.285",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-13",
        "-67.388",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-13",
        "-67.489",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-13",
        "-67.590",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-13",
        "-67.689",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-13",
        "-67.787",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-13",
        "-67.884",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-13",
        "-67.980",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-13",
        "-68.074",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-13",
        "-68.167",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-13",
        "-68.258",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-13",
        "-68.348",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-13",
        "-68.437",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-13",
        "-68.524",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-13",
        "-68.610",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-13",
        "-68.694",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-13",
        "-68.777",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-13",
        "-68.858",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-13",
        "-68.938",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-13",
        "-69.016",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-13",
        "-2.735",
        "8.179",
        "8.134",
        "10.267",
        "0.020"
    ],
    [
        "-110",
        "-13",
        "60.835",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-13",
        "60.874",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-13",
        "60.910",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-13",
        "60.942",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-13",
        "60.971",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-13",
        "60.997",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-13",
        "61.019",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-13",
        "61.037",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-13",
        "61.054",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-13",
        "61.065",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-13",
        "61.074",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-13",
        "61.079",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-13",
        "61.080",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-13",
        "61.078",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-13",
        "61.074",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-13",
        "61.065",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-13",
        "61.053",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-13",
        "61.037",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-13",
        "61.018",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-13",
        "60.996",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-13",
        "60.970",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-13",
        "60.942",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-13",
        "60.909",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-13",
        "60.872",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-13",
        "60.834",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-13",
        "60.792",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-13",
        "60.745",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-13",
        "60.696",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-13",
        "60.644",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-13",
        "60.588",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-13",
        "60.529",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-13",
        "60.467",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "-13",
        "49.670",
        "15.853",
        "8.025",
        "5.067",
        "0.227"
    ],
    [
        "-77",
        "-13",
        "28.508",
        "10.176",
        "5.599",
        "4.471",
        "0.224"
    ],
    [
        "-76",
        "-13",
        "15.975",
        "6.591",
        "3.717",
        "3.436",
        "0.326"
    ],
    [
        "-75",
        "-13",
        "8.353",
        "9.099",
        "3.456",
        "2.565",
        "0.417"
    ],
    [
        "-74",
        "-13",
        "3.965",
        "8.751",
        "2.220",
        "1.931",
        "0.304"
    ],
    [
        "-73",
        "-13",
        "1.335",
        "8.724",
        "1.746",
        "1.763",
        "0.301"
    ],
    [
        "-72",
        "-13",
        "0.087",
        "9.587",
        "1.386",
        "1.506",
        "0.228"
    ],
    [
        "-71",
        "-13",
        "-0.522",
        "10.254",
        "1.044",
        "1.392",
        "0.241"
    ],
    [
        "-70",
        "-13",
        "-1.243",
        "9.588",
        "1.102",
        "1.495",
        "0.173"
    ],
    [
        "-69",
        "-13",
        "-1.676",
        "9.363",
        "1.301",
        "1.389",
        "0.094"
    ],
    [
        "-68",
        "-13",
        "-1.703",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-13",
        "-1.740",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-13",
        "-1.779",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-13",
        "-1.817",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-13",
        "-1.857",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-13",
        "-1.897",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-13",
        "-1.937",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-13",
        "-1.978",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-13",
        "-2.019",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-13",
        "-2.061",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-13",
        "-2.103",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-13",
        "-2.146",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-13",
        "-2.189",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-13",
        "-2.232",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-13",
        "-2.276",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-13",
        "-2.320",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-13",
        "-2.365",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-13",
        "-2.410",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-13",
        "-2.455",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-13",
        "-2.500",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-13",
        "-2.546",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-13",
        "-2.592",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-13",
        "-2.639",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-13",
        "-2.685",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-13",
        "-2.733",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-13",
        "-2.780",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-13",
        "-2.827",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-13",
        "-2.875",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-13",
        "-2.923",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-13",
        "-2.970",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-13",
        "-3.018",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-13",
        "-3.067",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-13",
        "-3.115",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-13",
        "-3.163",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-13",
        "-3.212",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-13",
        "-3.261",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-13",
        "-3.309",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-13",
        "-3.358",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-13",
        "-3.407",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-13",
        "-3.455",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-13",
        "-3.504",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-13",
        "-3.554",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-13",
        "-3.602",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-13",
        "-3.652",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-13",
        "-3.700",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-13",
        "-3.748",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-13",
        "-3.798",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-13",
        "-3.846",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-13",
        "-3.895",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-13",
        "-3.943",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-13",
        "-3.991",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-13",
        "-4.039",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-13",
        "-4.087",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-13",
        "-4.134",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-13",
        "24.457",
        "17.565",
        "1.334",
        "1.261",
        "0.188"
    ],
    [
        "-13",
        "-13",
        "25.236",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-13",
        "25.166",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-13",
        "25.094",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-13",
        "25.021",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-13",
        "24.949",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-13",
        "24.876",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-13",
        "24.803",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-13",
        "24.729",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-13",
        "24.656",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-13",
        "24.581",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-13",
        "24.506",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-13",
        "24.432",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-13",
        "24.356",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-13",
        "24.280",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-13",
        "24.205",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-13",
        "24.128",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-13",
        "24.052",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-13",
        "23.976",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-13",
        "23.899",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-13",
        "23.823",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-13",
        "23.747",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-13",
        "23.670",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-13",
        "23.592",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-13",
        "23.516",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-13",
        "23.440",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-13",
        "23.363",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-13",
        "23.286",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-13",
        "23.209",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-13",
        "23.132",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-13",
        "23.056",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-13",
        "22.980",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-13",
        "22.904",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-13",
        "22.829",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-13",
        "22.753",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-13",
        "22.677",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-13",
        "22.602",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-13",
        "22.527",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-13",
        "22.452",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-13",
        "22.379",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-13",
        "22.305",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-13",
        "22.352",
        "18.644",
        "1.583",
        "1.495",
        "-0.053"
    ],
    [
        "28",
        "-13",
        "22.495",
        "18.458",
        "1.737",
        "1.844",
        "-0.044"
    ],
    [
        "29",
        "-13",
        "22.662",
        "18.267",
        "1.856",
        "1.938",
        "-0.033"
    ],
    [
        "30",
        "-13",
        "22.855",
        "18.085",
        "2.026",
        "1.942",
        "-0.017"
    ],
    [
        "31",
        "-13",
        "23.068",
        "17.912",
        "1.904",
        "2.024",
        "0.019"
    ],
    [
        "32",
        "-13",
        "23.268",
        "17.746",
        "1.847",
        "1.910",
        "0.064"
    ],
    [
        "33",
        "-13",
        "23.422",
        "17.592",
        "1.835",
        "1.680",
        "0.136"
    ],
    [
        "34",
        "-13",
        "23.522",
        "17.448",
        "1.701",
        "1.571",
        "0.152"
    ],
    [
        "35",
        "-13",
        "23.597",
        "17.299",
        "1.713",
        "1.583",
        "0.138"
    ],
    [
        "36",
        "-13",
        "23.692",
        "17.121",
        "1.860",
        "1.700",
        "0.120"
    ],
    [
        "37",
        "-13",
        "23.841",
        "16.900",
        "1.858",
        "1.899",
        "0.063"
    ],
    [
        "38",
        "-13",
        "24.039",
        "16.640",
        "1.867",
        "1.908",
        "0.039"
    ],
    [
        "39",
        "-13",
        "24.258",
        "16.372",
        "1.904",
        "1.665",
        "0.055"
    ],
    [
        "40",
        "-13",
        "24.466",
        "16.121",
        "1.646",
        "1.456",
        "0.057"
    ],
    [
        "41",
        "-13",
        "24.653",
        "15.882",
        "1.517",
        "1.251",
        "0.063"
    ],
    [
        "42",
        "-13",
        "24.804",
        "15.634",
        "1.521",
        "1.163",
        "0.127"
    ],
    [
        "43",
        "-13",
        "24.894",
        "15.376",
        "1.341",
        "1.261",
        "0.117"
    ],
    [
        "44",
        "-13",
        "24.924",
        "15.147",
        "1.211",
        "1.255",
        "0.083"
    ],
    [
        "45",
        "-13",
        "24.929",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-13",
        "24.871",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-13",
        "24.814",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-13",
        "24.757",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-13",
        "24.702",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-13",
        "24.648",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-13",
        "24.594",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-13",
        "24.542",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-13",
        "24.490",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-13",
        "24.439",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-13",
        "24.390",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-13",
        "24.341",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-13",
        "24.295",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-13",
        "24.249",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-13",
        "24.203",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-13",
        "24.159",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-13",
        "24.116",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-13",
        "24.074",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-13",
        "24.034",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-13",
        "23.994",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-13",
        "23.957",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-13",
        "23.919",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-13",
        "50.546",
        "28.884",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-13",
        "50.431",
        "29.542",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-13",
        "50.313",
        "30.190",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-13",
        "50.194",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-13",
        "50.071",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-13",
        "49.946",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-13",
        "49.820",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-13",
        "49.690",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-13",
        "49.558",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-13",
        "49.424",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-13",
        "49.287",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-13",
        "49.148",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-13",
        "49.008",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-13",
        "48.865",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-13",
        "48.719",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-13",
        "48.573",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-13",
        "48.424",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-13",
        "48.272",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-13",
        "48.120",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-13",
        "47.964",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-13",
        "47.808",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-13",
        "47.649",
        "40.569",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-13",
        "47.254",
        "41.397",
        "1.296",
        "1.496",
        "-0.019"
    ],
    [
        "90",
        "-13",
        "46.822",
        "42.443",
        "1.612",
        "1.647",
        "-0.025"
    ],
    [
        "91",
        "-13",
        "46.409",
        "43.441",
        "1.599",
        "1.759",
        "-0.026"
    ],
    [
        "92",
        "-13",
        "46.018",
        "44.399",
        "1.633",
        "1.758",
        "-0.023"
    ],
    [
        "93",
        "-13",
        "45.644",
        "45.342",
        "1.719",
        "1.684",
        "-0.030"
    ],
    [
        "94",
        "-13",
        "45.274",
        "46.289",
        "1.580",
        "1.683",
        "-0.035"
    ],
    [
        "95",
        "-13",
        "44.890",
        "47.235",
        "1.477",
        "1.561",
        "-0.061"
    ],
    [
        "96",
        "-13",
        "44.518",
        "48.156",
        "1.398",
        "1.330",
        "-0.121"
    ],
    [
        "97",
        "-13",
        "44.279",
        "49.070",
        "1.203",
        "1.134",
        "-0.023"
    ],
    [
        "98",
        "-13",
        "44.065",
        "50.052",
        "1.072",
        "1.143",
        "-0.115"
    ],
    [
        "99",
        "-13",
        "43.833",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-13",
        "43.632",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-13",
        "43.429",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-13",
        "43.225",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-13",
        "43.019",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-13",
        "42.812",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-13",
        "42.602",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-13",
        "42.392",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-13",
        "42.180",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-13",
        "41.967",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-13",
        "41.752",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-13",
        "41.537",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-13",
        "41.320",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-13",
        "41.103",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-13",
        "40.883",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-13",
        "40.664",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-13",
        "40.443",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-13",
        "40.222",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-13",
        "40.000",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-13",
        "39.777",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-13",
        "39.554",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-13",
        "39.330",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-13",
        "39.106",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-13",
        "38.881",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-13",
        "38.656",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-13",
        "38.432",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-13",
        "38.205",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-13",
        "37.980",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-13",
        "37.755",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-13",
        "37.530",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-13",
        "37.304",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-13",
        "37.079",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-13",
        "36.854",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-13",
        "36.630",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-13",
        "36.405",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-13",
        "36.182",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-13",
        "35.958",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-13",
        "35.736",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-13",
        "35.514",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-13",
        "35.293",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-13",
        "35.072",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-13",
        "34.852",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-13",
        "34.634",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-13",
        "34.416",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-13",
        "34.200",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-13",
        "33.984",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-13",
        "33.770",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-13",
        "33.557",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-13",
        "33.345",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-13",
        "33.134",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-13",
        "32.926",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-13",
        "32.718",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-13",
        "32.512",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-13",
        "32.307",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-13",
        "32.105",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-13",
        "31.905",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-13",
        "31.705",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-13",
        "31.509",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-13",
        "31.313",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-13",
        "31.121",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-13",
        "30.929",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-13",
        "30.740",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-13",
        "30.554",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-13",
        "30.369",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-13",
        "30.187",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-13",
        "30.007",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-13",
        "29.829",
        "44.907",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-13",
        "-0.722",
        "39.043",
        "6.655",
        "4.713",
        "0.215"
    ],
    [
        "167",
        "-13",
        "-36.251",
        "28.722",
        "5.754",
        "4.178",
        "0.127"
    ],
    [
        "168",
        "-13",
        "-35.134",
        "28.096",
        "6.184",
        "3.609",
        "0.212"
    ],
    [
        "169",
        "-13",
        "-45.669",
        "21.849",
        "4.302",
        "3.082",
        "0.145"
    ],
    [
        "170",
        "-13",
        "-51.703",
        "21.023",
        "3.102",
        "2.394",
        "0.071"
    ],
    [
        "171",
        "-13",
        "-54.620",
        "21.957",
        "2.381",
        "2.117",
        "-0.037"
    ],
    [
        "172",
        "-13",
        "-56.571",
        "23.041",
        "1.878",
        "2.310",
        "-0.081"
    ],
    [
        "173",
        "-13",
        "-58.073",
        "24.633",
        "1.595",
        "2.351",
        "-0.054"
    ],
    [
        "174",
        "-13",
        "-59.232",
        "26.623",
        "1.393",
        "2.214",
        "0.010"
    ],
    [
        "175",
        "-13",
        "-60.132",
        "28.321",
        "1.072",
        "1.968",
        "-0.029"
    ],
    [
        "176",
        "-13",
        "-61.116",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-13",
        "-61.229",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-13",
        "-61.343",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-13",
        "-61.457",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-12",
        "-61.978",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-12",
        "-62.085",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-12",
        "-62.192",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-12",
        "-62.300",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-12",
        "-62.409",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-12",
        "-62.518",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-12",
        "-62.627",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-12",
        "-62.737",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-12",
        "-62.847",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-12",
        "-62.958",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-12",
        "-63.069",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-12",
        "-63.180",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-12",
        "-63.291",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-12",
        "-63.403",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-12",
        "-63.514",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-12",
        "-63.626",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-12",
        "-63.738",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-12",
        "-63.850",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-12",
        "-63.962",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-12",
        "-64.074",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-12",
        "-64.185",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-12",
        "-64.297",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-12",
        "-64.409",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-12",
        "-64.520",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-12",
        "-64.632",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-12",
        "-64.743",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-12",
        "-64.854",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-12",
        "-64.964",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-12",
        "-65.075",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-12",
        "-65.184",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-12",
        "-65.294",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-12",
        "-65.403",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-12",
        "-65.512",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-12",
        "-65.620",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-12",
        "-65.727",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-12",
        "-65.835",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-12",
        "-65.941",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-12",
        "-66.047",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-12",
        "-66.152",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-12",
        "-66.257",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-12",
        "-66.361",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-12",
        "-66.464",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-12",
        "-66.566",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-12",
        "-66.667",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-12",
        "-66.768",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-12",
        "-66.868",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-12",
        "-66.967",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-12",
        "-67.065",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-12",
        "-67.162",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-12",
        "-67.258",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-12",
        "-67.353",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-12",
        "-67.447",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-12",
        "-67.540",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-12",
        "-67.631",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-12",
        "-67.722",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-12",
        "-67.812",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-12",
        "-67.900",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-12",
        "-67.987",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-12",
        "-68.073",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-12",
        "-68.157",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-12",
        "-68.241",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-12",
        "-68.323",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-12",
        "-68.403",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-12",
        "-68.483",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-12",
        "-68.560",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-12",
        "-68.637",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-12",
        "-68.712",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-12",
        "-68.786",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-12",
        "-68.858",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-12",
        "8.920",
        "7.646",
        "8.246",
        "9.056",
        "-0.015"
    ],
    [
        "-110",
        "-12",
        "60.196",
        "-10.370",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-12",
        "60.232",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-12",
        "60.264",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-12",
        "60.295",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-12",
        "60.321",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-12",
        "60.345",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-12",
        "60.366",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-12",
        "60.383",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-12",
        "60.397",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-12",
        "60.408",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-12",
        "60.416",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-12",
        "60.421",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-12",
        "60.421",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-12",
        "60.420",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-12",
        "60.415",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-12",
        "60.407",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-12",
        "60.396",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-12",
        "60.382",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-12",
        "60.365",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-12",
        "60.344",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-12",
        "60.320",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-12",
        "60.293",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-12",
        "60.263",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-12",
        "60.230",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-12",
        "60.194",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-12",
        "60.155",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-12",
        "60.112",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-12",
        "60.067",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-12",
        "60.018",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-12",
        "59.966",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-12",
        "59.913",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-12",
        "57.147",
        "15.317",
        "2.233",
        "2.668",
        "0.070"
    ],
    [
        "-78",
        "-12",
        "32.566",
        "12.384",
        "8.548",
        "6.180",
        "0.102"
    ],
    [
        "-77",
        "-12",
        "15.155",
        "7.996",
        "3.562",
        "2.311",
        "0.046"
    ],
    [
        "-76",
        "-12",
        "7.567",
        "6.616",
        "3.782",
        "2.986",
        "0.334"
    ],
    [
        "-75",
        "-12",
        "5.174",
        "8.963",
        "2.268",
        "1.797",
        "0.322"
    ],
    [
        "-74",
        "-12",
        "1.452",
        "8.647",
        "1.964",
        "1.723",
        "0.352"
    ],
    [
        "-73",
        "-12",
        "-0.256",
        "8.831",
        "1.504",
        "1.527",
        "0.313"
    ],
    [
        "-72",
        "-12",
        "-1.485",
        "9.100",
        "1.150",
        "1.304",
        "0.349"
    ],
    [
        "-71",
        "-12",
        "-1.750",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-12",
        "-1.783",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-12",
        "-1.817",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-12",
        "-1.852",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-12",
        "-1.887",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-12",
        "-1.923",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-12",
        "-1.958",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-12",
        "-1.995",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-12",
        "-2.031",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-12",
        "-2.069",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-12",
        "-2.106",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-12",
        "-2.145",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-12",
        "-2.183",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-12",
        "-2.222",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-12",
        "-2.261",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-12",
        "-2.301",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-12",
        "-2.342",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-12",
        "-2.382",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-12",
        "-2.423",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-12",
        "-2.464",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-12",
        "-2.505",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-12",
        "-2.547",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-12",
        "-2.590",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-12",
        "-2.631",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-12",
        "-2.674",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-12",
        "-2.717",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-12",
        "-2.761",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-12",
        "-2.803",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-12",
        "-2.847",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-12",
        "-2.891",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-12",
        "-2.935",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-12",
        "-2.979",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-12",
        "-3.024",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-12",
        "-3.068",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-12",
        "-3.112",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-12",
        "-3.157",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-12",
        "-3.202",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-12",
        "-3.247",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-12",
        "-3.292",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-12",
        "-3.337",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-12",
        "-3.382",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-12",
        "-3.427",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-12",
        "-3.473",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-12",
        "-3.518",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-12",
        "-3.563",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-12",
        "-3.607",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-12",
        "-3.653",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-12",
        "-3.698",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-12",
        "-3.743",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-12",
        "-3.788",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-12",
        "-3.833",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-12",
        "-3.878",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-12",
        "-3.923",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-12",
        "-3.967",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-12",
        "-4.011",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-12",
        "-4.055",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-12",
        "0.228",
        "14.398",
        "5.269",
        "2.635",
        "0.344"
    ],
    [
        "-14",
        "-12",
        "25.264",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-12",
        "25.199",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-12",
        "25.133",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-12",
        "25.067",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-12",
        "25.001",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-12",
        "24.934",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-12",
        "24.866",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-12",
        "24.799",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-12",
        "24.731",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-12",
        "24.662",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-12",
        "24.593",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-12",
        "24.524",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-12",
        "24.455",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-12",
        "24.385",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-12",
        "24.315",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-12",
        "24.245",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-12",
        "24.175",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-12",
        "24.105",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-12",
        "24.034",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-12",
        "23.963",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-12",
        "23.893",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-12",
        "23.821",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-12",
        "23.751",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-12",
        "23.680",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-12",
        "23.609",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-12",
        "23.538",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-12",
        "23.467",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-12",
        "23.396",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-12",
        "23.325",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-12",
        "23.254",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-12",
        "23.184",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-12",
        "23.114",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-12",
        "23.043",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-12",
        "22.973",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-12",
        "22.903",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-12",
        "22.833",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-12",
        "22.764",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-12",
        "22.695",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-12",
        "22.626",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-12",
        "22.557",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-12",
        "22.489",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-12",
        "22.559",
        "18.621",
        "1.444",
        "1.314",
        "-0.155"
    ],
    [
        "28",
        "-12",
        "22.722",
        "18.412",
        "1.637",
        "1.655",
        "-0.122"
    ],
    [
        "29",
        "-12",
        "22.899",
        "18.215",
        "1.800",
        "1.829",
        "-0.074"
    ],
    [
        "30",
        "-12",
        "23.104",
        "18.036",
        "2.012",
        "1.903",
        "-0.040"
    ],
    [
        "31",
        "-12",
        "23.333",
        "17.877",
        "1.924",
        "2.026",
        "0.010"
    ],
    [
        "32",
        "-12",
        "23.554",
        "17.732",
        "1.882",
        "1.942",
        "0.059"
    ],
    [
        "33",
        "-12",
        "23.722",
        "17.599",
        "1.874",
        "1.723",
        "0.120"
    ],
    [
        "34",
        "-12",
        "23.829",
        "17.469",
        "1.749",
        "1.629",
        "0.136"
    ],
    [
        "35",
        "-12",
        "23.910",
        "17.323",
        "1.757",
        "1.658",
        "0.124"
    ],
    [
        "36",
        "-12",
        "24.022",
        "17.141",
        "1.896",
        "1.774",
        "0.099"
    ],
    [
        "37",
        "-12",
        "24.203",
        "16.910",
        "1.890",
        "1.962",
        "0.051"
    ],
    [
        "38",
        "-12",
        "24.440",
        "16.637",
        "1.890",
        "1.935",
        "0.033"
    ],
    [
        "39",
        "-12",
        "24.692",
        "16.357",
        "1.912",
        "1.656",
        "0.052"
    ],
    [
        "40",
        "-12",
        "24.925",
        "16.095",
        "1.626",
        "1.415",
        "0.056"
    ],
    [
        "41",
        "-12",
        "25.131",
        "15.849",
        "1.433",
        "1.160",
        "0.069"
    ],
    [
        "42",
        "-12",
        "25.313",
        "15.607",
        "1.192",
        "0.911",
        "0.180"
    ],
    [
        "43",
        "-12",
        "25.333",
        "15.373",
        "1.022",
        "0.975",
        "0.204"
    ],
    [
        "44",
        "-12",
        "25.313",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-12",
        "25.259",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-12",
        "25.205",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-12",
        "25.152",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-12",
        "25.100",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-12",
        "25.049",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-12",
        "24.999",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-12",
        "24.949",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-12",
        "24.901",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-12",
        "24.853",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-12",
        "24.806",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-12",
        "24.761",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-12",
        "24.716",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-12",
        "24.672",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-12",
        "24.630",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-12",
        "24.588",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-12",
        "24.548",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-12",
        "24.508",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-12",
        "24.470",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-12",
        "24.432",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-12",
        "24.396",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-12",
        "24.360",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-12",
        "29.833",
        "11.389",
        "14.801",
        "10.173",
        "0.526"
    ],
    [
        "67",
        "-12",
        "50.062",
        "28.884",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-12",
        "49.955",
        "29.542",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-12",
        "49.846",
        "30.190",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-12",
        "49.736",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-12",
        "49.623",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-12",
        "49.508",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-12",
        "49.390",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-12",
        "49.271",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-12",
        "49.149",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-12",
        "49.024",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-12",
        "48.898",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-12",
        "48.770",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-12",
        "48.640",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-12",
        "48.508",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-12",
        "48.374",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-12",
        "48.238",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-12",
        "48.100",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-12",
        "47.961",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-12",
        "47.820",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-12",
        "47.676",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-12",
        "47.531",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-12",
        "47.385",
        "40.569",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-12",
        "46.974",
        "41.324",
        "1.325",
        "1.518",
        "-0.016"
    ],
    [
        "90",
        "-12",
        "46.527",
        "42.263",
        "1.617",
        "1.640",
        "-0.038"
    ],
    [
        "91",
        "-12",
        "46.111",
        "43.175",
        "1.579",
        "1.710",
        "-0.052"
    ],
    [
        "92",
        "-12",
        "45.715",
        "44.070",
        "1.605",
        "1.684",
        "-0.050"
    ],
    [
        "93",
        "-12",
        "45.324",
        "44.984",
        "1.695",
        "1.627",
        "-0.056"
    ],
    [
        "94",
        "-12",
        "44.930",
        "45.943",
        "1.603",
        "1.658",
        "-0.034"
    ],
    [
        "95",
        "-12",
        "44.522",
        "46.928",
        "1.548",
        "1.607",
        "-0.028"
    ],
    [
        "96",
        "-12",
        "44.093",
        "47.882",
        "1.489",
        "1.407",
        "-0.049"
    ],
    [
        "97",
        "-12",
        "43.617",
        "48.697",
        "0.984",
        "0.839",
        "-0.050"
    ],
    [
        "98",
        "-12",
        "43.561",
        "49.819",
        "1.215",
        "1.319",
        "-0.072"
    ],
    [
        "99",
        "-12",
        "43.529",
        "50.957",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "-12",
        "43.344",
        "51.412",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "-12",
        "43.156",
        "51.850",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "-12",
        "42.967",
        "52.273",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-12",
        "42.777",
        "52.679",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-12",
        "42.585",
        "53.070",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-12",
        "42.391",
        "53.445",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-12",
        "42.197",
        "53.803",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-12",
        "42.001",
        "54.145",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-12",
        "41.804",
        "54.470",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-12",
        "41.606",
        "54.779",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-12",
        "41.406",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-12",
        "41.206",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-12",
        "41.005",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-12",
        "40.802",
        "55.846",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-12",
        "40.600",
        "56.072",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-12",
        "40.396",
        "56.279",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-12",
        "40.191",
        "56.469",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-12",
        "39.985",
        "56.642",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-12",
        "39.780",
        "56.799",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-12",
        "39.574",
        "56.937",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-12",
        "39.367",
        "57.058",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-12",
        "39.160",
        "57.162",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-12",
        "38.952",
        "57.248",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "-12",
        "38.744",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-12",
        "38.537",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-12",
        "38.328",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-12",
        "38.120",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-12",
        "37.911",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-12",
        "37.703",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-12",
        "37.494",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-12",
        "37.287",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-12",
        "37.079",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-12",
        "36.872",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-12",
        "36.663",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-12",
        "36.458",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-12",
        "36.251",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-12",
        "36.046",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-12",
        "35.839",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-12",
        "35.636",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-12",
        "35.432",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-12",
        "35.229",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-12",
        "35.026",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-12",
        "34.826",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-12",
        "34.625",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-12",
        "34.426",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-12",
        "34.228",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-12",
        "34.031",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-12",
        "33.835",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-12",
        "33.641",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-12",
        "33.448",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-12",
        "33.256",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-12",
        "33.066",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-12",
        "32.877",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-12",
        "32.689",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-12",
        "32.504",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-12",
        "32.320",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-12",
        "32.138",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-12",
        "31.958",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-12",
        "31.779",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-12",
        "31.602",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-12",
        "31.428",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-12",
        "31.255",
        "47.293",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-12",
        "31.084",
        "46.718",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-12",
        "30.916",
        "46.128",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-12",
        "30.750",
        "45.525",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-12",
        "18.994",
        "42.346",
        "8.518",
        "5.094",
        "0.163"
    ],
    [
        "166",
        "-12",
        "-15.132",
        "38.291",
        "8.200",
        "6.026",
        "0.146"
    ],
    [
        "167",
        "-12",
        "-35.992",
        "28.021",
        "7.220",
        "5.074",
        "0.179"
    ],
    [
        "168",
        "-12",
        "-43.948",
        "26.798",
        "5.918",
        "3.947",
        "0.272"
    ],
    [
        "169",
        "-12",
        "-53.220",
        "23.693",
        "3.678",
        "3.052",
        "0.139"
    ],
    [
        "170",
        "-12",
        "-57.524",
        "23.305",
        "2.626",
        "2.245",
        "0.028"
    ],
    [
        "171",
        "-12",
        "-59.077",
        "24.703",
        "2.070",
        "1.844",
        "-0.101"
    ],
    [
        "172",
        "-12",
        "-60.139",
        "26.005",
        "1.519",
        "1.946",
        "-0.129"
    ],
    [
        "173",
        "-12",
        "-60.967",
        "27.589",
        "0.961",
        "1.464",
        "-0.132"
    ],
    [
        "174",
        "-12",
        "-61.350",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-12",
        "-61.452",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-12",
        "-61.556",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-12",
        "-61.661",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-12",
        "-61.766",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-12",
        "-61.871",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-11",
        "-62.365",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-11",
        "-62.463",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-11",
        "-62.562",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-11",
        "-62.661",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-11",
        "-62.760",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-11",
        "-62.860",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-11",
        "-62.961",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-11",
        "-63.062",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-11",
        "-63.163",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-11",
        "-63.264",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-11",
        "-63.366",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-11",
        "-63.468",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-11",
        "-63.570",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-11",
        "-63.672",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-11",
        "-63.775",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-11",
        "-63.877",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-11",
        "-63.980",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-11",
        "-64.083",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-11",
        "-64.185",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-11",
        "-64.288",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-11",
        "-64.391",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-11",
        "-64.493",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-11",
        "-64.596",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-11",
        "-64.698",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-11",
        "-64.800",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-11",
        "-64.902",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-11",
        "-65.004",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-11",
        "-65.105",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-11",
        "-65.207",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-11",
        "-65.307",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-11",
        "-65.408",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-11",
        "-65.508",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-11",
        "-65.608",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-11",
        "-65.707",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-11",
        "-65.806",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-11",
        "-65.904",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-11",
        "-66.002",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-11",
        "-66.099",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-11",
        "-66.196",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-11",
        "-66.292",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-11",
        "-66.387",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-11",
        "-66.481",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-11",
        "-66.575",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-11",
        "-66.668",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-11",
        "-66.761",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-11",
        "-66.852",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-11",
        "-66.943",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-11",
        "-67.033",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-11",
        "-67.122",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-11",
        "-67.210",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-11",
        "-67.297",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-11",
        "-67.384",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-11",
        "-67.469",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-11",
        "-67.553",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-11",
        "-67.636",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-11",
        "-67.719",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-11",
        "-67.800",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-11",
        "-67.879",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-11",
        "-67.958",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-11",
        "-68.036",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-11",
        "-68.112",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-11",
        "-68.188",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-11",
        "-68.262",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-11",
        "-68.334",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-11",
        "-68.406",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-11",
        "-68.476",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-11",
        "-68.545",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-11",
        "-68.612",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-11",
        "-68.678",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-11",
        "-68.743",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-11",
        "47.837",
        "-17.686",
        "2.187",
        "4.576",
        "-0.013"
    ],
    [
        "-109",
        "-11",
        "59.570",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-11",
        "59.601",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-11",
        "59.629",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-11",
        "59.653",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-11",
        "59.674",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-11",
        "59.694",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-11",
        "59.710",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-11",
        "59.722",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-11",
        "59.732",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-11",
        "59.740",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-11",
        "59.744",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-11",
        "59.745",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-11",
        "59.743",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-11",
        "59.739",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-11",
        "59.732",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-11",
        "59.721",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-11",
        "59.709",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-11",
        "59.692",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-11",
        "59.674",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-11",
        "59.652",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-11",
        "59.628",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-11",
        "59.600",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-11",
        "59.569",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-11",
        "59.536",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-11",
        "59.500",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-11",
        "59.462",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-11",
        "59.420",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-11",
        "59.375",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-11",
        "59.328",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-11",
        "59.277",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "-11",
        "39.994",
        "14.015",
        "8.183",
        "7.963",
        "-0.187"
    ],
    [
        "-78",
        "-11",
        "20.511",
        "10.802",
        "7.637",
        "6.638",
        "-0.132"
    ],
    [
        "-77",
        "-11",
        "8.377",
        "8.048",
        "4.744",
        "4.125",
        "-0.110"
    ],
    [
        "-76",
        "-11",
        "3.880",
        "7.412",
        "3.153",
        "2.566",
        "0.190"
    ],
    [
        "-75",
        "-11",
        "1.629",
        "8.476",
        "2.306",
        "1.782",
        "0.359"
    ],
    [
        "-74",
        "-11",
        "-0.126",
        "8.636",
        "1.721",
        "1.589",
        "0.387"
    ],
    [
        "-73",
        "-11",
        "-1.495",
        "8.861",
        "1.011",
        "1.076",
        "0.382"
    ],
    [
        "-72",
        "-11",
        "-1.876",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-11",
        "-1.907",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-11",
        "-1.937",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-11",
        "-1.969",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-11",
        "-2.000",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-11",
        "-2.032",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-11",
        "-2.065",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-11",
        "-2.098",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-11",
        "-2.131",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-11",
        "-2.165",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-11",
        "-2.200",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-11",
        "-2.234",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-11",
        "-2.269",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-11",
        "-2.305",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-11",
        "-2.340",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-11",
        "-2.377",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-11",
        "-2.413",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-11",
        "-2.450",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-11",
        "-2.487",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-11",
        "-2.525",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-11",
        "-2.562",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-11",
        "-2.600",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-11",
        "-2.639",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-11",
        "-2.677",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-11",
        "-2.716",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-11",
        "-2.755",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-11",
        "-2.794",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-11",
        "-2.835",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-11",
        "-2.874",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-11",
        "-2.914",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-11",
        "-2.954",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-11",
        "-2.995",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-11",
        "-3.035",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-11",
        "-3.076",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-11",
        "-3.117",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-11",
        "-3.158",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-11",
        "-3.199",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-11",
        "-3.240",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-11",
        "-3.281",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-11",
        "-3.322",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-11",
        "-3.363",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-11",
        "-3.405",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-11",
        "-3.447",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-11",
        "-3.488",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-11",
        "-3.529",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-11",
        "-3.571",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-11",
        "-3.612",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-11",
        "-3.653",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-11",
        "-3.695",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-11",
        "-3.736",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-11",
        "-3.777",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-11",
        "-3.818",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-11",
        "-3.859",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-11",
        "-3.900",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-11",
        "-3.941",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-11",
        "-3.982",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-11",
        "-4.022",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-11",
        "-4.063",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-11",
        "-4.103",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-11",
        "20.066",
        "18.789",
        "4.037",
        "2.876",
        "0.105"
    ],
    [
        "-12",
        "-11",
        "25.094",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-11",
        "25.032",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-11",
        "24.972",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-11",
        "24.910",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-11",
        "24.849",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-11",
        "24.786",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-11",
        "24.723",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-11",
        "24.661",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-11",
        "24.598",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-11",
        "24.534",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-11",
        "24.470",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-11",
        "24.406",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-11",
        "24.343",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-11",
        "24.278",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-11",
        "24.214",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-11",
        "24.149",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-11",
        "24.085",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-11",
        "24.020",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-11",
        "23.955",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-11",
        "23.890",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-11",
        "23.824",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-11",
        "23.760",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-11",
        "23.694",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-11",
        "23.629",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-11",
        "23.564",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-11",
        "23.500",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-11",
        "23.434",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-11",
        "23.370",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-11",
        "23.304",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-11",
        "23.240",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-11",
        "23.176",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-11",
        "23.111",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-11",
        "23.047",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-11",
        "22.983",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-11",
        "22.919",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-11",
        "22.855",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-11",
        "22.793",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-11",
        "22.730",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-11",
        "22.666",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-11",
        "22.718",
        "18.629",
        "1.217",
        "1.054",
        "-0.272"
    ],
    [
        "28",
        "-11",
        "22.885",
        "18.406",
        "1.455",
        "1.385",
        "-0.212"
    ],
    [
        "29",
        "-11",
        "23.071",
        "18.195",
        "1.653",
        "1.621",
        "-0.139"
    ],
    [
        "30",
        "-11",
        "23.288",
        "18.008",
        "1.909",
        "1.762",
        "-0.076"
    ],
    [
        "31",
        "-11",
        "23.534",
        "17.852",
        "1.870",
        "1.921",
        "0.001"
    ],
    [
        "32",
        "-11",
        "23.772",
        "17.722",
        "1.857",
        "1.877",
        "0.062"
    ],
    [
        "33",
        "-11",
        "23.957",
        "17.606",
        "1.868",
        "1.701",
        "0.120"
    ],
    [
        "34",
        "-11",
        "24.085",
        "17.487",
        "1.753",
        "1.647",
        "0.132"
    ],
    [
        "35",
        "-11",
        "24.199",
        "17.343",
        "1.763",
        "1.707",
        "0.119"
    ],
    [
        "36",
        "-11",
        "24.357",
        "17.153",
        "1.908",
        "1.823",
        "0.086"
    ],
    [
        "37",
        "-11",
        "24.593",
        "16.909",
        "1.890",
        "1.994",
        "0.039"
    ],
    [
        "38",
        "-11",
        "24.879",
        "16.626",
        "1.871",
        "1.928",
        "0.021"
    ],
    [
        "39",
        "-11",
        "25.162",
        "16.338",
        "1.872",
        "1.610",
        "0.047"
    ],
    [
        "40",
        "-11",
        "25.406",
        "16.067",
        "1.546",
        "1.348",
        "0.068"
    ],
    [
        "41",
        "-11",
        "25.597",
        "15.815",
        "1.296",
        "1.035",
        "0.126"
    ],
    [
        "42",
        "-11",
        "25.734",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-11",
        "25.682",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-11",
        "25.631",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-11",
        "25.581",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-11",
        "25.532",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-11",
        "25.484",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-11",
        "25.436",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-11",
        "25.389",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-11",
        "25.342",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-11",
        "25.297",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-11",
        "25.252",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-11",
        "25.209",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-11",
        "25.166",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-11",
        "25.124",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-11",
        "25.083",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-11",
        "25.043",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-11",
        "25.004",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-11",
        "24.965",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-11",
        "24.929",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-11",
        "24.892",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-11",
        "24.857",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-11",
        "24.822",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-11",
        "24.789",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-11",
        "24.756",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-11",
        "24.725",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-11",
        "48.510",
        "29.491",
        "3.566",
        "5.027",
        "0.334"
    ],
    [
        "68",
        "-11",
        "49.465",
        "29.542",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-11",
        "49.366",
        "30.190",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-11",
        "49.264",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-11",
        "49.160",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-11",
        "49.054",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-11",
        "48.947",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-11",
        "48.836",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-11",
        "48.724",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-11",
        "48.611",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-11",
        "48.495",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-11",
        "48.377",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-11",
        "48.258",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-11",
        "48.137",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-11",
        "48.013",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-11",
        "47.889",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-11",
        "47.762",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-11",
        "47.634",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-11",
        "47.504",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-11",
        "47.373",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-11",
        "47.240",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-11",
        "47.106",
        "40.569",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-11",
        "46.683",
        "41.260",
        "1.310",
        "1.499",
        "-0.025"
    ],
    [
        "90",
        "-11",
        "46.223",
        "42.104",
        "1.573",
        "1.601",
        "-0.059"
    ],
    [
        "91",
        "-11",
        "45.803",
        "42.949",
        "1.517",
        "1.632",
        "-0.076"
    ],
    [
        "92",
        "-11",
        "45.402",
        "43.805",
        "1.537",
        "1.588",
        "-0.076"
    ],
    [
        "93",
        "-11",
        "45.002",
        "44.696",
        "1.626",
        "1.558",
        "-0.082"
    ],
    [
        "94",
        "-11",
        "44.597",
        "45.632",
        "1.593",
        "1.625",
        "-0.038"
    ],
    [
        "95",
        "-11",
        "44.197",
        "46.602",
        "1.607",
        "1.664",
        "-0.007"
    ],
    [
        "96",
        "-11",
        "43.815",
        "47.553",
        "1.687",
        "1.565",
        "0.014"
    ],
    [
        "97",
        "-11",
        "43.451",
        "48.464",
        "1.563",
        "1.558",
        "0.012"
    ],
    [
        "98",
        "-11",
        "43.157",
        "49.406",
        "1.531",
        "1.585",
        "0.003"
    ],
    [
        "99",
        "-11",
        "42.973",
        "50.309",
        "1.590",
        "1.480",
        "0.017"
    ],
    [
        "100",
        "-11",
        "42.780",
        "50.976",
        "1.495",
        "1.596",
        "0.022"
    ],
    [
        "101",
        "-11",
        "42.527",
        "51.527",
        "1.528",
        "1.658",
        "0.035"
    ],
    [
        "102",
        "-11",
        "42.253",
        "52.007",
        "1.703",
        "1.669",
        "0.055"
    ],
    [
        "103",
        "-11",
        "42.041",
        "52.417",
        "1.740",
        "1.977",
        "0.103"
    ],
    [
        "104",
        "-11",
        "42.106",
        "52.809",
        "1.947",
        "2.623",
        "0.091"
    ],
    [
        "105",
        "-11",
        "42.446",
        "52.536",
        "2.511",
        "3.516",
        "-0.026"
    ],
    [
        "106",
        "-11",
        "42.672",
        "50.428",
        "2.847",
        "4.485",
        "0.146"
    ],
    [
        "107",
        "-11",
        "41.753",
        "50.627",
        "3.159",
        "6.155",
        "-0.028"
    ],
    [
        "108",
        "-11",
        "41.615",
        "52.000",
        "3.976",
        "7.056",
        "-0.066"
    ],
    [
        "109",
        "-11",
        "41.860",
        "52.914",
        "3.599",
        "7.067",
        "-0.030"
    ],
    [
        "110",
        "-11",
        "41.264",
        "55.071",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-11",
        "41.079",
        "55.347",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-11",
        "40.895",
        "55.605",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-11",
        "40.108",
        "45.196",
        "4.671",
        "9.270",
        "-0.169"
    ],
    [
        "114",
        "-11",
        "38.552",
        "40.839",
        "5.608",
        "10.110",
        "-0.168"
    ],
    [
        "115",
        "-11",
        "37.190",
        "39.050",
        "5.887",
        "10.959",
        "-0.186"
    ],
    [
        "116",
        "-11",
        "36.952",
        "37.229",
        "6.526",
        "11.363",
        "-0.256"
    ],
    [
        "117",
        "-11",
        "38.046",
        "37.710",
        "8.029",
        "10.885",
        "-0.382"
    ],
    [
        "118",
        "-11",
        "39.059",
        "41.506",
        "6.885",
        "10.933",
        "-0.460"
    ],
    [
        "119",
        "-11",
        "38.503",
        "45.769",
        "6.584",
        "10.588",
        "-0.494"
    ],
    [
        "120",
        "-11",
        "38.637",
        "51.067",
        "6.950",
        "10.183",
        "-0.480"
    ],
    [
        "121",
        "-11",
        "38.663",
        "52.574",
        "6.621",
        "9.892",
        "-0.342"
    ],
    [
        "122",
        "-11",
        "38.525",
        "52.807",
        "6.663",
        "9.391",
        "-0.414"
    ],
    [
        "123",
        "-11",
        "38.820",
        "57.317",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "-11",
        "38.629",
        "57.369",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "-11",
        "38.438",
        "57.403",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "-11",
        "38.247",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-11",
        "38.055",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-11",
        "37.864",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-11",
        "37.674",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-11",
        "37.482",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-11",
        "37.291",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-11",
        "37.102",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-11",
        "36.912",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-11",
        "36.722",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-11",
        "36.532",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-11",
        "36.343",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-11",
        "36.155",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-11",
        "35.967",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-11",
        "35.781",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-11",
        "35.594",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-11",
        "35.408",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-11",
        "35.224",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-11",
        "35.040",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-11",
        "34.857",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-11",
        "34.675",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-11",
        "34.494",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-11",
        "34.315",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-11",
        "34.137",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-11",
        "33.960",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-11",
        "33.783",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-11",
        "33.609",
        "52.231",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-11",
        "33.436",
        "51.805",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-11",
        "33.263",
        "51.366",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-11",
        "33.094",
        "50.910",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-11",
        "32.925",
        "50.439",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-11",
        "32.757",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-11",
        "32.592",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-11",
        "32.428",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-11",
        "32.266",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-11",
        "32.106",
        "47.855",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-11",
        "30.955",
        "46.758",
        "2.156",
        "3.203",
        "0.230"
    ],
    [
        "162",
        "-11",
        "18.001",
        "39.204",
        "9.341",
        "7.328",
        "0.365"
    ],
    [
        "163",
        "-11",
        "6.093",
        "40.493",
        "9.285",
        "7.337",
        "0.266"
    ],
    [
        "164",
        "-11",
        "-1.814",
        "40.191",
        "9.411",
        "6.989",
        "0.213"
    ],
    [
        "165",
        "-11",
        "-17.162",
        "42.433",
        "10.318",
        "6.864",
        "0.138"
    ],
    [
        "166",
        "-11",
        "-31.484",
        "36.716",
        "8.560",
        "6.774",
        "0.126"
    ],
    [
        "167",
        "-11",
        "-44.206",
        "28.899",
        "6.973",
        "5.512",
        "0.235"
    ],
    [
        "168",
        "-11",
        "-52.703",
        "26.619",
        "5.319",
        "3.988",
        "0.305"
    ],
    [
        "169",
        "-11",
        "-57.815",
        "25.061",
        "3.193",
        "2.992",
        "0.148"
    ],
    [
        "170",
        "-11",
        "-60.215",
        "25.190",
        "2.218",
        "2.003",
        "0.027"
    ],
    [
        "171",
        "-11",
        "-61.130",
        "26.587",
        "1.662",
        "1.446",
        "-0.107"
    ],
    [
        "172",
        "-11",
        "-61.601",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-11",
        "-61.694",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-11",
        "-61.788",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-11",
        "-61.882",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-11",
        "-61.978",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-11",
        "-62.074",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-11",
        "-62.170",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-11",
        "-62.267",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-10",
        "-62.732",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-10",
        "-62.822",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-10",
        "-62.912",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-10",
        "-63.002",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-10",
        "-63.093",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-10",
        "-63.184",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-10",
        "-63.275",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-10",
        "-63.367",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-10",
        "-63.459",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-10",
        "-63.551",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-10",
        "-63.644",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-10",
        "-63.736",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-10",
        "-63.829",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-10",
        "-63.922",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-10",
        "-64.016",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-10",
        "-64.109",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-10",
        "-64.202",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-10",
        "-64.296",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-10",
        "-64.389",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-10",
        "-64.483",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-10",
        "-64.576",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-10",
        "-64.670",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-10",
        "-64.763",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-10",
        "-64.856",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-10",
        "-64.949",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-10",
        "-65.042",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-10",
        "-65.134",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-10",
        "-65.227",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-10",
        "-65.319",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-10",
        "-65.411",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-10",
        "-65.502",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-10",
        "-65.593",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-10",
        "-65.684",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-10",
        "-65.774",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-10",
        "-65.864",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-10",
        "-65.954",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-10",
        "-66.042",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-10",
        "-66.131",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-10",
        "-66.219",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-10",
        "-66.306",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-10",
        "-66.393",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-10",
        "-66.479",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-10",
        "-66.564",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-10",
        "-66.649",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-10",
        "-66.733",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-10",
        "-66.817",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-10",
        "-66.899",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-10",
        "-66.981",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-10",
        "-67.062",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-10",
        "-67.142",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-10",
        "-67.222",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-10",
        "-67.300",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-10",
        "-67.378",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-10",
        "-67.454",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-10",
        "-67.530",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-10",
        "-67.605",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-10",
        "-67.679",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-10",
        "-67.751",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-10",
        "-67.823",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-10",
        "-67.894",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-10",
        "-67.963",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-10",
        "-68.032",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-10",
        "-68.099",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-10",
        "-68.165",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-10",
        "-68.230",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-10",
        "-68.294",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-10",
        "-68.357",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-10",
        "-68.418",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-10",
        "-68.478",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-10",
        "-68.537",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-10",
        "25.817",
        "-2.803",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-10",
        "58.892",
        "-9.515",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-10",
        "58.920",
        "-8.657",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-10",
        "58.944",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-10",
        "58.966",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-10",
        "58.986",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-10",
        "59.004",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-10",
        "59.017",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-10",
        "59.030",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-10",
        "59.039",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-10",
        "59.045",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-10",
        "59.050",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-10",
        "59.050",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-10",
        "59.049",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-10",
        "59.045",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-10",
        "59.038",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-10",
        "59.029",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-10",
        "59.017",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-10",
        "59.002",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-10",
        "58.985",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-10",
        "58.965",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-10",
        "58.943",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-10",
        "58.918",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-10",
        "58.891",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-10",
        "58.860",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-10",
        "58.827",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-10",
        "58.792",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-10",
        "58.754",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-10",
        "58.713",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-10",
        "58.670",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-10",
        "50.042",
        "11.443",
        "5.305",
        "8.681",
        "0.029"
    ],
    [
        "-79",
        "-10",
        "29.000",
        "9.801",
        "7.148",
        "9.328",
        "-0.093"
    ],
    [
        "-78",
        "-10",
        "14.714",
        "8.651",
        "6.055",
        "6.744",
        "-0.140"
    ],
    [
        "-77",
        "-10",
        "6.435",
        "7.883",
        "3.959",
        "4.068",
        "-0.092"
    ],
    [
        "-76",
        "-10",
        "2.421",
        "7.840",
        "2.771",
        "2.282",
        "0.142"
    ],
    [
        "-75",
        "-10",
        "0.341",
        "8.356",
        "2.122",
        "1.656",
        "0.344"
    ],
    [
        "-74",
        "-10",
        "-1.002",
        "8.565",
        "1.594",
        "1.522",
        "0.337"
    ],
    [
        "-73",
        "-10",
        "-1.823",
        "8.809",
        "1.001",
        "1.048",
        "0.275"
    ],
    [
        "-72",
        "-10",
        "-2.036",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-10",
        "-2.063",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-10",
        "-2.091",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-10",
        "-2.120",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-10",
        "-2.148",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-10",
        "-2.177",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-10",
        "-2.208",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-10",
        "-2.237",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-10",
        "-2.267",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-10",
        "-2.298",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-10",
        "-2.330",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-10",
        "-2.361",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-10",
        "-2.393",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-10",
        "-2.425",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-10",
        "-2.458",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-10",
        "-2.490",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-10",
        "-2.524",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-10",
        "-2.558",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-10",
        "-2.591",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-10",
        "-2.626",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-10",
        "-2.660",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-10",
        "-2.694",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-10",
        "-2.730",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-10",
        "-2.764",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-10",
        "-2.800",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-10",
        "-2.836",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-10",
        "-2.871",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-10",
        "-2.908",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-10",
        "-2.944",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-10",
        "-2.980",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-10",
        "-3.016",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-10",
        "-3.053",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-10",
        "-3.090",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-10",
        "-3.127",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-10",
        "-3.164",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-10",
        "-3.201",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-10",
        "-3.239",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-10",
        "-3.276",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-10",
        "-3.314",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-10",
        "-3.351",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-10",
        "-3.389",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-10",
        "-3.426",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-10",
        "-3.465",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-10",
        "-3.502",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-10",
        "-3.539",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-10",
        "-3.577",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-10",
        "-3.615",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-10",
        "-3.653",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-10",
        "-3.690",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-10",
        "-3.728",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-10",
        "-3.765",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-10",
        "-3.803",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-10",
        "-3.840",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-10",
        "-3.878",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-10",
        "-3.915",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-10",
        "-3.952",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-10",
        "-3.989",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-10",
        "-4.026",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-10",
        "-4.062",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-10",
        "16.040",
        "16.503",
        "4.298",
        "2.728",
        "0.175"
    ],
    [
        "-12",
        "-10",
        "25.046",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-10",
        "24.991",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-10",
        "24.935",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-10",
        "24.879",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-10",
        "24.822",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-10",
        "24.766",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-10",
        "24.709",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-10",
        "24.652",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-10",
        "24.595",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-10",
        "24.537",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-10",
        "24.479",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-10",
        "24.421",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-10",
        "24.362",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-10",
        "24.305",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-10",
        "24.245",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-10",
        "24.187",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-10",
        "24.128",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-10",
        "24.068",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-10",
        "24.010",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-10",
        "23.950",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-10",
        "23.892",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-10",
        "23.832",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-10",
        "23.772",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-10",
        "23.713",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-10",
        "23.654",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-10",
        "23.595",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-10",
        "23.536",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-10",
        "23.477",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-10",
        "23.418",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-10",
        "23.359",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-10",
        "23.300",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-10",
        "23.242",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-10",
        "23.183",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-10",
        "23.125",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-10",
        "23.068",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-10",
        "23.010",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-10",
        "22.952",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-10",
        "22.895",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-10",
        "22.837",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-10",
        "22.780",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-10",
        "23.004",
        "18.431",
        "1.274",
        "1.173",
        "-0.287"
    ],
    [
        "29",
        "-10",
        "23.212",
        "18.203",
        "1.508",
        "1.432",
        "-0.188"
    ],
    [
        "30",
        "-10",
        "23.456",
        "18.005",
        "1.784",
        "1.576",
        "-0.087"
    ],
    [
        "31",
        "-10",
        "23.722",
        "17.849",
        "1.769",
        "1.734",
        "0.014"
    ],
    [
        "32",
        "-10",
        "23.975",
        "17.729",
        "1.780",
        "1.724",
        "0.093"
    ],
    [
        "33",
        "-10",
        "24.180",
        "17.625",
        "1.821",
        "1.626",
        "0.155"
    ],
    [
        "34",
        "-10",
        "24.347",
        "17.511",
        "1.737",
        "1.632",
        "0.147"
    ],
    [
        "35",
        "-10",
        "24.520",
        "17.364",
        "1.766",
        "1.737",
        "0.120"
    ],
    [
        "36",
        "-10",
        "24.747",
        "17.160",
        "1.929",
        "1.848",
        "0.081"
    ],
    [
        "37",
        "-10",
        "25.045",
        "16.897",
        "1.878",
        "2.002",
        "0.029"
    ],
    [
        "38",
        "-10",
        "25.384",
        "16.605",
        "1.809",
        "1.899",
        "0.002"
    ],
    [
        "39",
        "-10",
        "25.715",
        "16.306",
        "1.725",
        "1.493",
        "0.029"
    ],
    [
        "40",
        "-10",
        "25.920",
        "16.035",
        "1.350",
        "1.229",
        "0.064"
    ],
    [
        "41",
        "-10",
        "26.017",
        "15.804",
        "1.104",
        "0.905",
        "0.118"
    ],
    [
        "42",
        "-10",
        "26.035",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-10",
        "25.988",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-10",
        "25.941",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-10",
        "25.895",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-10",
        "25.851",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-10",
        "25.807",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-10",
        "25.763",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-10",
        "25.720",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-10",
        "25.678",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-10",
        "25.637",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-10",
        "25.596",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-10",
        "25.557",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-10",
        "25.518",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-10",
        "25.480",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-10",
        "25.442",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-10",
        "25.406",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-10",
        "25.371",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-10",
        "25.335",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-10",
        "25.302",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-10",
        "25.268",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-10",
        "25.237",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-10",
        "25.205",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-10",
        "25.175",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-10",
        "25.145",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-10",
        "25.117",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-10",
        "42.836",
        "23.721",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-10",
        "48.959",
        "29.542",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-10",
        "48.868",
        "30.190",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-10",
        "48.776",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-10",
        "48.682",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-10",
        "48.585",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-10",
        "48.487",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-10",
        "48.387",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-10",
        "48.285",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-10",
        "48.182",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-10",
        "48.076",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-10",
        "47.969",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-10",
        "47.861",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-10",
        "47.751",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-10",
        "47.638",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-10",
        "47.525",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-10",
        "47.410",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-10",
        "47.293",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-10",
        "47.175",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-10",
        "47.056",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-10",
        "46.935",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-10",
        "46.813",
        "40.569",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-10",
        "46.380",
        "41.201",
        "1.293",
        "1.484",
        "-0.029"
    ],
    [
        "90",
        "-10",
        "45.911",
        "41.963",
        "1.536",
        "1.579",
        "-0.064"
    ],
    [
        "91",
        "-10",
        "45.492",
        "42.758",
        "1.472",
        "1.599",
        "-0.073"
    ],
    [
        "92",
        "-10",
        "45.100",
        "43.594",
        "1.488",
        "1.554",
        "-0.077"
    ],
    [
        "93",
        "-10",
        "44.711",
        "44.462",
        "1.570",
        "1.538",
        "-0.082"
    ],
    [
        "94",
        "-10",
        "44.318",
        "45.355",
        "1.568",
        "1.629",
        "-0.037"
    ],
    [
        "95",
        "-10",
        "43.926",
        "46.263",
        "1.625",
        "1.724",
        "-0.001"
    ],
    [
        "96",
        "-10",
        "43.539",
        "47.171",
        "1.776",
        "1.718",
        "0.024"
    ],
    [
        "97",
        "-10",
        "43.155",
        "48.070",
        "1.727",
        "1.790",
        "0.037"
    ],
    [
        "98",
        "-10",
        "42.789",
        "48.961",
        "1.744",
        "1.807",
        "0.043"
    ],
    [
        "99",
        "-10",
        "42.450",
        "49.812",
        "1.862",
        "1.781",
        "0.056"
    ],
    [
        "100",
        "-10",
        "42.101",
        "50.571",
        "1.838",
        "1.944",
        "0.086"
    ],
    [
        "101",
        "-10",
        "41.694",
        "51.186",
        "1.972",
        "2.157",
        "0.118"
    ],
    [
        "102",
        "-10",
        "41.212",
        "51.535",
        "2.323",
        "2.456",
        "0.171"
    ],
    [
        "103",
        "-10",
        "40.689",
        "51.332",
        "2.577",
        "3.232",
        "0.208"
    ],
    [
        "104",
        "-10",
        "40.523",
        "50.032",
        "3.000",
        "4.480",
        "0.126"
    ],
    [
        "105",
        "-10",
        "41.783",
        "45.319",
        "3.761",
        "5.187",
        "0.115"
    ],
    [
        "106",
        "-10",
        "43.371",
        "44.322",
        "4.134",
        "7.166",
        "-0.078"
    ],
    [
        "107",
        "-10",
        "41.436",
        "43.175",
        "4.790",
        "9.052",
        "-0.091"
    ],
    [
        "108",
        "-10",
        "39.566",
        "41.721",
        "5.688",
        "9.800",
        "-0.025"
    ],
    [
        "109",
        "-10",
        "38.255",
        "41.518",
        "5.556",
        "9.728",
        "0.133"
    ],
    [
        "110",
        "-10",
        "38.075",
        "38.639",
        "5.572",
        "10.130",
        "0.179"
    ],
    [
        "111",
        "-10",
        "39.078",
        "32.614",
        "5.868",
        "10.688",
        "-0.052"
    ],
    [
        "112",
        "-10",
        "40.804",
        "21.411",
        "5.008",
        "10.442",
        "-0.085"
    ],
    [
        "113",
        "-10",
        "40.109",
        "15.310",
        "5.338",
        "9.870",
        "-0.195"
    ],
    [
        "114",
        "-10",
        "36.543",
        "13.185",
        "6.258",
        "9.936",
        "-0.174"
    ],
    [
        "115",
        "-10",
        "33.673",
        "10.715",
        "6.783",
        "10.586",
        "-0.151"
    ],
    [
        "116",
        "-10",
        "32.702",
        "7.341",
        "7.425",
        "10.661",
        "-0.160"
    ],
    [
        "117",
        "-10",
        "32.861",
        "6.563",
        "9.013",
        "10.053",
        "-0.280"
    ],
    [
        "118",
        "-10",
        "34.268",
        "11.703",
        "8.066",
        "10.601",
        "-0.341"
    ],
    [
        "119",
        "-10",
        "35.189",
        "19.503",
        "7.933",
        "10.602",
        "-0.330"
    ],
    [
        "120",
        "-10",
        "33.911",
        "24.491",
        "7.685",
        "9.187",
        "-0.446"
    ],
    [
        "121",
        "-10",
        "38.066",
        "39.975",
        "7.292",
        "10.501",
        "-0.307"
    ],
    [
        "122",
        "-10",
        "38.953",
        "44.795",
        "8.197",
        "11.034",
        "-0.326"
    ],
    [
        "123",
        "-10",
        "37.938",
        "46.626",
        "8.102",
        "9.425",
        "-0.294"
    ],
    [
        "124",
        "-10",
        "40.123",
        "40.827",
        "3.924",
        "4.777",
        "-0.501"
    ],
    [
        "125",
        "-10",
        "40.599",
        "55.547",
        "4.170",
        "6.236",
        "-0.158"
    ],
    [
        "126",
        "-10",
        "38.363",
        "57.420",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "-10",
        "38.189",
        "57.418",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "-10",
        "38.015",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-10",
        "37.841",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-10",
        "37.667",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-10",
        "37.494",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-10",
        "37.320",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-10",
        "37.148",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-10",
        "36.975",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-10",
        "36.803",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-10",
        "36.631",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-10",
        "36.459",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-10",
        "36.288",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-10",
        "36.118",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-10",
        "35.949",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-10",
        "35.780",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-10",
        "35.612",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-10",
        "35.444",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-10",
        "35.278",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-10",
        "35.112",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-10",
        "34.948",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-10",
        "34.785",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-10",
        "34.622",
        "53.407",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "-10",
        "34.461",
        "53.031",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "-10",
        "34.300",
        "52.638",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "-10",
        "34.738",
        "55.219",
        "2.939",
        "5.684",
        "0.017"
    ],
    [
        "152",
        "-10",
        "33.158",
        "59.647",
        "3.472",
        "6.547",
        "-0.160"
    ],
    [
        "153",
        "-10",
        "30.685",
        "62.383",
        "4.235",
        "6.641",
        "-0.118"
    ],
    [
        "154",
        "-10",
        "29.854",
        "55.023",
        "4.241",
        "7.020",
        "-0.153"
    ],
    [
        "155",
        "-10",
        "32.327",
        "51.224",
        "3.004",
        "5.771",
        "-0.097"
    ],
    [
        "156",
        "-10",
        "33.367",
        "49.952",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-10",
        "33.217",
        "49.451",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-10",
        "33.067",
        "48.933",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-10",
        "32.920",
        "48.402",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-10",
        "10.290",
        "43.015",
        "8.403",
        "5.576",
        "0.299"
    ],
    [
        "161",
        "-10",
        "-20.553",
        "34.517",
        "9.019",
        "7.916",
        "0.382"
    ],
    [
        "162",
        "-10",
        "-40.899",
        "26.235",
        "9.655",
        "6.637",
        "0.324"
    ],
    [
        "163",
        "-10",
        "-47.836",
        "29.749",
        "9.234",
        "8.192",
        "0.221"
    ],
    [
        "164",
        "-10",
        "-50.028",
        "32.134",
        "9.459",
        "8.127",
        "0.153"
    ],
    [
        "165",
        "-10",
        "-49.803",
        "34.926",
        "9.236",
        "6.750",
        "0.141"
    ],
    [
        "166",
        "-10",
        "-50.068",
        "32.018",
        "7.248",
        "6.219",
        "0.096"
    ],
    [
        "167",
        "-10",
        "-53.842",
        "28.443",
        "5.744",
        "4.899",
        "0.230"
    ],
    [
        "168",
        "-10",
        "-57.976",
        "26.835",
        "4.404",
        "3.548",
        "0.311"
    ],
    [
        "169",
        "-10",
        "-60.485",
        "26.239",
        "2.725",
        "2.738",
        "0.190"
    ],
    [
        "170",
        "-10",
        "-61.871",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-10",
        "-61.954",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-10",
        "-62.038",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-10",
        "-62.122",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-10",
        "-62.208",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-10",
        "-62.294",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-10",
        "-62.380",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-10",
        "-62.467",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-10",
        "-62.555",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-10",
        "-62.644",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-9",
        "-63.081",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-9",
        "-63.162",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-9",
        "-63.243",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-9",
        "-63.324",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-9",
        "-63.406",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-9",
        "-63.488",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-9",
        "-63.570",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-9",
        "-63.653",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-9",
        "-63.735",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-9",
        "-63.819",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-9",
        "-63.902",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-9",
        "-63.986",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-9",
        "-64.069",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-9",
        "-64.153",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-9",
        "-64.237",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-9",
        "-64.321",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-9",
        "-64.405",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-9",
        "-64.490",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-9",
        "-64.574",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-9",
        "-64.658",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-9",
        "-64.742",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-9",
        "-64.826",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-9",
        "-64.910",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-9",
        "-64.994",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-9",
        "-65.078",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-9",
        "-65.162",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-9",
        "-65.245",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-9",
        "-65.328",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-9",
        "-65.411",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-9",
        "-65.494",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-9",
        "-65.576",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-9",
        "-65.658",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-9",
        "-65.740",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-9",
        "-65.821",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-9",
        "-65.902",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-9",
        "-65.983",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-9",
        "-66.063",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-9",
        "-66.143",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-9",
        "-66.222",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-9",
        "-66.301",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-9",
        "-66.379",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-9",
        "-66.456",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-9",
        "-66.533",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-9",
        "-66.610",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-9",
        "-66.685",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-9",
        "-66.760",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-9",
        "-66.835",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-9",
        "-66.909",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-9",
        "-66.982",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-9",
        "-67.054",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-9",
        "-67.125",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-9",
        "-67.196",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-9",
        "-67.266",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-9",
        "-67.335",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-9",
        "-67.403",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-9",
        "-67.471",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-9",
        "-67.537",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-9",
        "-67.602",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-9",
        "-67.667",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-9",
        "-67.731",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-9",
        "-67.793",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-9",
        "-67.855",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-9",
        "-67.916",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-9",
        "-67.975",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-9",
        "-68.034",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-9",
        "-68.091",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-9",
        "-68.148",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-9",
        "-68.203",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-9",
        "-68.258",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-9",
        "-68.311",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-9",
        "-48.852",
        "7.978",
        "6.663",
        "8.114",
        "-0.021"
    ],
    [
        "-109",
        "-9",
        "-11.467",
        "2.543",
        "10.778",
        "7.164",
        "-0.024"
    ],
    [
        "-108",
        "-9",
        "32.571",
        "-0.079",
        "9.632",
        "8.324",
        "-0.021"
    ],
    [
        "-107",
        "-9",
        "58.242",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-9",
        "58.262",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-9",
        "58.279",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-9",
        "58.295",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-9",
        "58.308",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-9",
        "58.319",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-9",
        "58.327",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-9",
        "58.333",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-9",
        "58.336",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-9",
        "58.338",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-9",
        "58.336",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-9",
        "58.333",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-9",
        "58.327",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-9",
        "58.318",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-9",
        "58.308",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-9",
        "58.294",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-9",
        "58.278",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-9",
        "58.261",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-9",
        "58.241",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-9",
        "58.218",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-9",
        "58.193",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-9",
        "58.166",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-9",
        "58.137",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-9",
        "58.105",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-9",
        "58.071",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-9",
        "58.034",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-9",
        "57.995",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "-9",
        "30.968",
        "6.332",
        "7.795",
        "10.478",
        "0.147"
    ],
    [
        "-79",
        "-9",
        "16.137",
        "5.413",
        "6.644",
        "8.607",
        "0.103"
    ],
    [
        "-78",
        "-9",
        "8.691",
        "6.280",
        "5.031",
        "5.806",
        "0.037"
    ],
    [
        "-77",
        "-9",
        "4.581",
        "7.423",
        "3.423",
        "3.418",
        "0.035"
    ],
    [
        "-76",
        "-9",
        "1.607",
        "8.091",
        "2.483",
        "1.952",
        "0.182"
    ],
    [
        "-75",
        "-9",
        "-0.370",
        "8.347",
        "1.991",
        "1.479",
        "0.278"
    ],
    [
        "-74",
        "-9",
        "-1.456",
        "8.553",
        "1.539",
        "1.459",
        "0.224"
    ],
    [
        "-73",
        "-9",
        "-2.064",
        "8.823",
        "1.056",
        "1.092",
        "0.168"
    ],
    [
        "-72",
        "-9",
        "-2.194",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-9",
        "-2.219",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-9",
        "-2.244",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-9",
        "-2.270",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-9",
        "-2.296",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-9",
        "-2.322",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-9",
        "-2.349",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-9",
        "-2.376",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-9",
        "-2.403",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-9",
        "-2.431",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-9",
        "-2.459",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-9",
        "-2.487",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-9",
        "-2.517",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-9",
        "-2.545",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-9",
        "-2.575",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-9",
        "-2.604",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-9",
        "-2.634",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-9",
        "-2.664",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-9",
        "-2.694",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-9",
        "-2.725",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-9",
        "-2.756",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-9",
        "-2.787",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-9",
        "-2.819",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-9",
        "-2.851",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-9",
        "-2.883",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-9",
        "-2.915",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-9",
        "-2.947",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-9",
        "-2.980",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-9",
        "-3.012",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-9",
        "-3.045",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-9",
        "-3.078",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-9",
        "-3.111",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-9",
        "-3.144",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-9",
        "-3.177",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-9",
        "-3.211",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-9",
        "-3.245",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-9",
        "-3.278",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-9",
        "-3.312",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-9",
        "-3.345",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-9",
        "-3.379",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-9",
        "-3.413",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-9",
        "-3.447",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-9",
        "-3.481",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-9",
        "-3.516",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-9",
        "-3.549",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-9",
        "-3.583",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-9",
        "-3.617",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-9",
        "-3.651",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-9",
        "-3.685",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-9",
        "-3.719",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-9",
        "-3.752",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-9",
        "-3.786",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-9",
        "-3.820",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-9",
        "-3.853",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-9",
        "-3.887",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-9",
        "-3.921",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-9",
        "-3.954",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-9",
        "-3.987",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-9",
        "-4.020",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-9",
        "20.821",
        "16.477",
        "3.771",
        "2.437",
        "0.237"
    ],
    [
        "-12",
        "-9",
        "24.991",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-9",
        "24.942",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-9",
        "24.891",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-9",
        "24.841",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-9",
        "24.790",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-9",
        "24.739",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-9",
        "24.688",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-9",
        "24.636",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-9",
        "24.584",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-9",
        "24.533",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-9",
        "24.480",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-9",
        "24.427",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-9",
        "24.375",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-9",
        "24.323",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-9",
        "24.270",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-9",
        "24.217",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-9",
        "24.164",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-9",
        "24.111",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-9",
        "24.057",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-9",
        "24.004",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-9",
        "23.951",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-9",
        "23.897",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-9",
        "23.844",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-9",
        "23.791",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-9",
        "23.738",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-9",
        "23.683",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-9",
        "23.630",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-9",
        "23.578",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-9",
        "23.525",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-9",
        "23.471",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-9",
        "23.418",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-9",
        "23.365",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-9",
        "23.312",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-9",
        "23.261",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-9",
        "23.209",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-9",
        "23.156",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-9",
        "23.104",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-9",
        "23.053",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-9",
        "23.001",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-9",
        "22.950",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-9",
        "23.121",
        "18.465",
        "1.158",
        "1.045",
        "-0.287"
    ],
    [
        "29",
        "-9",
        "23.368",
        "18.225",
        "1.412",
        "1.299",
        "-0.199"
    ],
    [
        "30",
        "-9",
        "23.652",
        "18.022",
        "1.678",
        "1.409",
        "-0.070"
    ],
    [
        "31",
        "-9",
        "23.951",
        "17.871",
        "1.664",
        "1.544",
        "0.041"
    ],
    [
        "32",
        "-9",
        "24.224",
        "17.758",
        "1.696",
        "1.578",
        "0.124"
    ],
    [
        "33",
        "-9",
        "24.457",
        "17.659",
        "1.781",
        "1.569",
        "0.176"
    ],
    [
        "34",
        "-9",
        "24.671",
        "17.542",
        "1.746",
        "1.643",
        "0.139"
    ],
    [
        "35",
        "-9",
        "24.907",
        "17.381",
        "1.794",
        "1.775",
        "0.091"
    ],
    [
        "36",
        "-9",
        "25.197",
        "17.155",
        "1.952",
        "1.850",
        "0.054"
    ],
    [
        "37",
        "-9",
        "25.544",
        "16.868",
        "1.848",
        "1.973",
        "0.013"
    ],
    [
        "38",
        "-9",
        "25.942",
        "16.545",
        "1.682",
        "1.846",
        "0.004"
    ],
    [
        "39",
        "-9",
        "26.458",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-9",
        "26.414",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-9",
        "26.371",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-9",
        "26.328",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-9",
        "26.285",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-9",
        "26.244",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-9",
        "26.203",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-9",
        "26.162",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-9",
        "26.122",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-9",
        "26.083",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-9",
        "26.044",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-9",
        "26.007",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-9",
        "25.969",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-9",
        "25.933",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-9",
        "25.897",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-9",
        "25.862",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-9",
        "25.828",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-9",
        "25.794",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-9",
        "25.761",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-9",
        "25.729",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-9",
        "25.698",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-9",
        "25.667",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-9",
        "25.637",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-9",
        "25.608",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-9",
        "25.580",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-9",
        "25.552",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-9",
        "25.526",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-9",
        "25.500",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-9",
        "29.043",
        "10.230",
        "9.005",
        "9.834",
        "0.426"
    ],
    [
        "68",
        "-9",
        "48.439",
        "29.542",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-9",
        "48.357",
        "30.190",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-9",
        "48.274",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-9",
        "48.189",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-9",
        "48.102",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-9",
        "48.014",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-9",
        "47.924",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-9",
        "47.832",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-9",
        "47.739",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-9",
        "47.643",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-9",
        "47.547",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-9",
        "47.449",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-9",
        "47.350",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-9",
        "47.249",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-9",
        "47.146",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-9",
        "47.043",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-9",
        "46.938",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-9",
        "46.831",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-9",
        "46.724",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-9",
        "46.615",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-9",
        "46.504",
        "40.569",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-9",
        "46.076",
        "41.146",
        "1.294",
        "1.491",
        "-0.022"
    ],
    [
        "90",
        "-9",
        "45.609",
        "41.832",
        "1.539",
        "1.593",
        "-0.046"
    ],
    [
        "91",
        "-9",
        "45.195",
        "42.585",
        "1.472",
        "1.624",
        "-0.048"
    ],
    [
        "92",
        "-9",
        "44.817",
        "43.404",
        "1.484",
        "1.581",
        "-0.053"
    ],
    [
        "93",
        "-9",
        "44.458",
        "44.251",
        "1.552",
        "1.563",
        "-0.052"
    ],
    [
        "94",
        "-9",
        "44.092",
        "45.096",
        "1.556",
        "1.667",
        "-0.017"
    ],
    [
        "95",
        "-9",
        "43.699",
        "45.939",
        "1.628",
        "1.779",
        "0.012"
    ],
    [
        "96",
        "-9",
        "43.271",
        "46.801",
        "1.806",
        "1.810",
        "0.034"
    ],
    [
        "97",
        "-9",
        "42.825",
        "47.682",
        "1.798",
        "1.906",
        "0.053"
    ],
    [
        "98",
        "-9",
        "42.371",
        "48.569",
        "1.859",
        "1.949",
        "0.073"
    ],
    [
        "99",
        "-9",
        "41.896",
        "49.419",
        "2.040",
        "2.018",
        "0.108"
    ],
    [
        "100",
        "-9",
        "41.354",
        "50.139",
        "2.192",
        "2.405",
        "0.190"
    ],
    [
        "101",
        "-9",
        "40.658",
        "50.529",
        "2.591",
        "3.107",
        "0.268"
    ],
    [
        "102",
        "-9",
        "39.699",
        "50.245",
        "3.339",
        "4.047",
        "0.332"
    ],
    [
        "103",
        "-9",
        "38.424",
        "48.246",
        "3.905",
        "5.412",
        "0.309"
    ],
    [
        "104",
        "-9",
        "37.567",
        "42.143",
        "4.461",
        "6.855",
        "0.217"
    ],
    [
        "105",
        "-9",
        "37.434",
        "34.788",
        "5.243",
        "7.992",
        "0.095"
    ],
    [
        "106",
        "-9",
        "37.425",
        "29.714",
        "5.312",
        "9.471",
        "0.002"
    ],
    [
        "107",
        "-9",
        "37.359",
        "25.301",
        "5.368",
        "9.916",
        "0.023"
    ],
    [
        "108",
        "-9",
        "36.375",
        "21.550",
        "5.638",
        "9.803",
        "0.076"
    ],
    [
        "109",
        "-9",
        "34.931",
        "17.169",
        "5.277",
        "9.853",
        "0.147"
    ],
    [
        "110",
        "-9",
        "34.437",
        "10.839",
        "5.285",
        "9.630",
        "0.139"
    ],
    [
        "111",
        "-9",
        "34.922",
        "3.393",
        "5.488",
        "9.192",
        "-0.004"
    ],
    [
        "112",
        "-9",
        "35.659",
        "-2.402",
        "4.829",
        "8.319",
        "-0.051"
    ],
    [
        "113",
        "-9",
        "35.384",
        "-4.783",
        "4.561",
        "7.362",
        "-0.129"
    ],
    [
        "114",
        "-9",
        "33.491",
        "-5.606",
        "4.897",
        "6.748",
        "-0.177"
    ],
    [
        "115",
        "-9",
        "33.443",
        "-7.975",
        "3.834",
        "4.498",
        "-0.322"
    ],
    [
        "116",
        "-9",
        "29.396",
        "-8.005",
        "5.087",
        "6.841",
        "-0.009"
    ],
    [
        "117",
        "-9",
        "28.408",
        "-7.421",
        "6.559",
        "6.691",
        "-0.249"
    ],
    [
        "118",
        "-9",
        "27.666",
        "-5.804",
        "6.062",
        "7.271",
        "-0.389"
    ],
    [
        "119",
        "-9",
        "27.294",
        "2.597",
        "5.936",
        "8.671",
        "-0.240"
    ],
    [
        "120",
        "-9",
        "30.026",
        "9.829",
        "7.088",
        "9.826",
        "-0.288"
    ],
    [
        "121",
        "-9",
        "31.482",
        "13.002",
        "6.971",
        "9.189",
        "-0.357"
    ],
    [
        "122",
        "-9",
        "37.463",
        "44.656",
        "6.077",
        "8.312",
        "-0.336"
    ],
    [
        "123",
        "-9",
        "33.540",
        "39.282",
        "7.931",
        "10.821",
        "-0.242"
    ],
    [
        "124",
        "-9",
        "32.863",
        "43.739",
        "7.524",
        "9.564",
        "-0.356"
    ],
    [
        "125",
        "-9",
        "34.076",
        "45.556",
        "7.101",
        "9.094",
        "-0.416"
    ],
    [
        "126",
        "-9",
        "38.646",
        "52.758",
        "6.679",
        "9.525",
        "-0.153"
    ],
    [
        "127",
        "-9",
        "39.586",
        "53.331",
        "5.918",
        "9.380",
        "-0.314"
    ],
    [
        "128",
        "-9",
        "38.154",
        "57.401",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "-9",
        "37.997",
        "57.364",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "-9",
        "37.840",
        "57.311",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "-9",
        "37.684",
        "57.241",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "-9",
        "37.528",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-9",
        "37.372",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-9",
        "37.216",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-9",
        "37.061",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-9",
        "36.907",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-9",
        "36.752",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-9",
        "36.598",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-9",
        "36.445",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-9",
        "36.292",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-9",
        "36.140",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-9",
        "35.988",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-9",
        "35.838",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-9",
        "35.688",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-9",
        "35.539",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-9",
        "35.390",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-9",
        "35.244",
        "53.767",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "-9",
        "34.346",
        "52.650",
        "2.282",
        "5.034",
        "0.076"
    ],
    [
        "149",
        "-9",
        "32.886",
        "51.993",
        "3.492",
        "7.555",
        "0.053"
    ],
    [
        "150",
        "-9",
        "32.781",
        "54.436",
        "4.020",
        "7.540",
        "-0.018"
    ],
    [
        "151",
        "-9",
        "30.208",
        "60.531",
        "3.989",
        "7.596",
        "-0.010"
    ],
    [
        "152",
        "-9",
        "26.212",
        "67.750",
        "4.385",
        "7.776",
        "0.116"
    ],
    [
        "153",
        "-9",
        "27.987",
        "82.046",
        "4.793",
        "4.663",
        "-0.010"
    ],
    [
        "154",
        "-9",
        "26.640",
        "56.930",
        "5.884",
        "8.842",
        "0.011"
    ],
    [
        "155",
        "-9",
        "24.433",
        "51.798",
        "6.468",
        "8.359",
        "0.055"
    ],
    [
        "156",
        "-9",
        "22.151",
        "50.807",
        "7.248",
        "7.216",
        "0.151"
    ],
    [
        "157",
        "-9",
        "15.998",
        "46.333",
        "8.040",
        "6.044",
        "0.254"
    ],
    [
        "158",
        "-9",
        "0.967",
        "40.861",
        "9.129",
        "6.770",
        "0.293"
    ],
    [
        "159",
        "-9",
        "-17.892",
        "36.428",
        "9.451",
        "7.434",
        "0.364"
    ],
    [
        "160",
        "-9",
        "-40.352",
        "31.038",
        "8.461",
        "5.806",
        "0.289"
    ],
    [
        "161",
        "-9",
        "-58.261",
        "24.953",
        "5.314",
        "6.367",
        "0.323"
    ],
    [
        "162",
        "-9",
        "-61.731",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-9",
        "-61.800",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-9",
        "-61.329",
        "27.442",
        "6.492",
        "6.094",
        "0.116"
    ],
    [
        "165",
        "-9",
        "-60.189",
        "29.062",
        "6.351",
        "5.290",
        "0.134"
    ],
    [
        "166",
        "-9",
        "-59.541",
        "28.167",
        "4.955",
        "4.613",
        "0.037"
    ],
    [
        "167",
        "-9",
        "-60.306",
        "27.076",
        "3.572",
        "3.269",
        "0.183"
    ],
    [
        "168",
        "-9",
        "-61.435",
        "26.902",
        "2.956",
        "2.583",
        "0.316"
    ],
    [
        "169",
        "-9",
        "-62.230",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-9",
        "-62.305",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-9",
        "-62.380",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-9",
        "-62.455",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-9",
        "-62.531",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-9",
        "-62.608",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-9",
        "-62.686",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-9",
        "-62.764",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-9",
        "-62.842",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-9",
        "-62.922",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-9",
        "-63.001",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-8",
        "-63.411",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-8",
        "-63.482",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-8",
        "-63.554",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-8",
        "-63.627",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-8",
        "-63.699",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-8",
        "-63.772",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-8",
        "-63.845",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-8",
        "-63.919",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-8",
        "-63.993",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-8",
        "-64.067",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-8",
        "-64.141",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-8",
        "-64.215",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-8",
        "-64.290",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-8",
        "-64.364",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-8",
        "-64.439",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-8",
        "-64.514",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-8",
        "-64.589",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-8",
        "-64.664",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-8",
        "-64.739",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-8",
        "-64.813",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-8",
        "-64.888",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-8",
        "-64.963",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-8",
        "-65.038",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-8",
        "-65.113",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-8",
        "-65.187",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-8",
        "-65.261",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-8",
        "-65.336",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-8",
        "-65.410",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-8",
        "-65.483",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-8",
        "-65.557",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-8",
        "-65.630",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-8",
        "-65.703",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-8",
        "-65.776",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-8",
        "-65.848",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-8",
        "-65.921",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-8",
        "-65.992",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-8",
        "-66.063",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-8",
        "-66.134",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-8",
        "-66.205",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-8",
        "-66.275",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-8",
        "-66.344",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-8",
        "-66.413",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-8",
        "-66.482",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-8",
        "-66.550",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-8",
        "-66.617",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-8",
        "-66.684",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-8",
        "-66.750",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-8",
        "-66.816",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-8",
        "-66.881",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-8",
        "-66.945",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-8",
        "-67.009",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-8",
        "-67.071",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-8",
        "-67.134",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-8",
        "-67.195",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-8",
        "-67.256",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-8",
        "-67.316",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-8",
        "-67.375",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-8",
        "-67.433",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-8",
        "-67.491",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-8",
        "-67.547",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-8",
        "-67.603",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-8",
        "-67.658",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-8",
        "-67.712",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-8",
        "-67.765",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-8",
        "-67.817",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-8",
        "-67.868",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-8",
        "-67.918",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-8",
        "-67.968",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-8",
        "-68.016",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-8",
        "-68.063",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-8",
        "-68.109",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-8",
        "-68.154",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-8",
        "6.392",
        "11.064",
        "8.578",
        "8.525",
        "-0.031"
    ],
    [
        "-107",
        "-8",
        "57.521",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-8",
        "57.539",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-8",
        "57.555",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-8",
        "57.570",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-8",
        "57.581",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-8",
        "57.590",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-8",
        "57.598",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-8",
        "57.603",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-8",
        "57.606",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-8",
        "57.607",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-8",
        "57.606",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-8",
        "57.602",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-8",
        "57.597",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-8",
        "57.590",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-8",
        "57.581",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-8",
        "57.569",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-8",
        "57.555",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-8",
        "57.539",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-8",
        "57.521",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-8",
        "57.500",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-8",
        "57.479",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-8",
        "57.455",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-8",
        "57.428",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-8",
        "57.400",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-8",
        "57.370",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-8",
        "57.337",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-8",
        "40.386",
        "10.135",
        "10.850",
        "7.984",
        "0.342"
    ],
    [
        "-80",
        "-8",
        "18.290",
        "6.017",
        "8.819",
        "8.627",
        "0.252"
    ],
    [
        "-79",
        "-8",
        "7.735",
        "5.322",
        "6.464",
        "6.467",
        "0.230"
    ],
    [
        "-78",
        "-8",
        "3.853",
        "6.219",
        "4.476",
        "4.132",
        "0.180"
    ],
    [
        "-77",
        "-8",
        "1.990",
        "7.416",
        "2.988",
        "2.532",
        "0.167"
    ],
    [
        "-76",
        "-8",
        "0.283",
        "8.212",
        "2.173",
        "1.646",
        "0.250"
    ],
    [
        "-75",
        "-8",
        "-1.404",
        "8.427",
        "1.562",
        "1.161",
        "0.241"
    ],
    [
        "-74",
        "-8",
        "-2.261",
        "8.714",
        "0.371",
        "0.516",
        "0.141"
    ],
    [
        "-73",
        "-8",
        "-2.330",
        "8.836",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-8",
        "-2.352",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-8",
        "-2.374",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-8",
        "-2.397",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-8",
        "-2.419",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-8",
        "-2.443",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-8",
        "-2.465",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-8",
        "-2.490",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-8",
        "-2.514",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-8",
        "-2.538",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-8",
        "-2.563",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-8",
        "-2.588",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-8",
        "-2.613",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-8",
        "-2.638",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-8",
        "-2.664",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-8",
        "-2.690",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-8",
        "-2.717",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-8",
        "-2.744",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-8",
        "-2.770",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-8",
        "-2.798",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-8",
        "-2.825",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-8",
        "-2.852",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-8",
        "-2.880",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-8",
        "-2.908",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-8",
        "-2.936",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-8",
        "-2.965",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-8",
        "-2.993",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-8",
        "-3.022",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-8",
        "-3.051",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-8",
        "-3.080",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-8",
        "-3.109",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-8",
        "-3.138",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-8",
        "-3.167",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-8",
        "-3.197",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-8",
        "-3.227",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-8",
        "-3.256",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-8",
        "-3.286",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-8",
        "-3.317",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-8",
        "-3.347",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-8",
        "-3.377",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-8",
        "-3.406",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-8",
        "-3.436",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-8",
        "-3.466",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-8",
        "-3.497",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-8",
        "-3.527",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-8",
        "-3.557",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-8",
        "-3.587",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-8",
        "-3.618",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-8",
        "-3.648",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-8",
        "-3.678",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-8",
        "-3.709",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-8",
        "-3.739",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-8",
        "-3.768",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-8",
        "-3.798",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-8",
        "-3.829",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-8",
        "-3.858",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-8",
        "-3.888",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-8",
        "-3.918",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-8",
        "-3.947",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-8",
        "-3.977",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-8",
        "24.973",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-8",
        "24.928",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-8",
        "24.884",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-8",
        "24.839",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-8",
        "24.795",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-8",
        "24.749",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-8",
        "24.704",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-8",
        "24.658",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-8",
        "24.613",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-8",
        "24.567",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-8",
        "24.520",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-8",
        "24.474",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-8",
        "24.428",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-8",
        "24.381",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-8",
        "24.333",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-8",
        "24.286",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-8",
        "24.240",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-8",
        "24.192",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-8",
        "24.145",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-8",
        "24.098",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-8",
        "24.050",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-8",
        "24.003",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-8",
        "23.955",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-8",
        "23.908",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-8",
        "23.860",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-8",
        "23.813",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-8",
        "23.766",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-8",
        "23.718",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-8",
        "23.671",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-8",
        "23.623",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-8",
        "23.576",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-8",
        "23.529",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-8",
        "23.483",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-8",
        "23.436",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-8",
        "23.388",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-8",
        "23.342",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-8",
        "23.296",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-8",
        "23.250",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-8",
        "23.204",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-8",
        "23.158",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-8",
        "23.113",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-8",
        "23.213",
        "18.511",
        "0.891",
        "0.826",
        "-0.283"
    ],
    [
        "29",
        "-8",
        "23.538",
        "18.251",
        "1.354",
        "1.233",
        "-0.198"
    ],
    [
        "30",
        "-8",
        "23.876",
        "18.048",
        "1.620",
        "1.316",
        "-0.063"
    ],
    [
        "31",
        "-8",
        "24.213",
        "17.902",
        "1.613",
        "1.443",
        "0.050"
    ],
    [
        "32",
        "-8",
        "24.509",
        "17.794",
        "1.678",
        "1.526",
        "0.112"
    ],
    [
        "33",
        "-8",
        "24.764",
        "17.689",
        "1.822",
        "1.582",
        "0.145"
    ],
    [
        "34",
        "-8",
        "25.016",
        "17.553",
        "1.802",
        "1.704",
        "0.091"
    ],
    [
        "35",
        "-8",
        "25.305",
        "17.366",
        "1.830",
        "1.804",
        "0.041"
    ],
    [
        "36",
        "-8",
        "25.653",
        "17.120",
        "1.939",
        "1.787",
        "0.011"
    ],
    [
        "37",
        "-8",
        "26.048",
        "16.822",
        "1.753",
        "1.834",
        "-0.021"
    ],
    [
        "38",
        "-8",
        "26.444",
        "16.460",
        "1.568",
        "1.723",
        "0.021"
    ],
    [
        "39",
        "-8",
        "26.728",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-8",
        "26.689",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-8",
        "26.650",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-8",
        "26.612",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-8",
        "26.575",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-8",
        "26.537",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-8",
        "26.501",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-8",
        "26.465",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-8",
        "26.430",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-8",
        "26.395",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-8",
        "26.360",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-8",
        "26.327",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-8",
        "26.294",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-8",
        "26.262",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-8",
        "26.229",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-8",
        "26.198",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-8",
        "26.168",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-8",
        "26.138",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-8",
        "26.108",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-8",
        "26.080",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-8",
        "26.052",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-8",
        "26.025",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-8",
        "25.998",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-8",
        "25.973",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-8",
        "25.948",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-8",
        "25.923",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-8",
        "25.899",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-8",
        "25.876",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-8",
        "25.855",
        "8.970",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-8",
        "39.473",
        "22.713",
        "9.780",
        "9.151",
        "0.564"
    ],
    [
        "69",
        "-8",
        "47.831",
        "30.190",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-8",
        "47.756",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-8",
        "47.681",
        "31.461",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-8",
        "47.604",
        "32.082",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-8",
        "47.525",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "-8",
        "47.445",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-8",
        "47.364",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-8",
        "47.281",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-8",
        "47.196",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-8",
        "47.110",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-8",
        "47.023",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-8",
        "46.934",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-8",
        "46.845",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-8",
        "46.754",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-8",
        "46.661",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-8",
        "46.568",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-8",
        "46.473",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-8",
        "46.377",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-8",
        "46.280",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-8",
        "46.183",
        "40.569",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-8",
        "45.779",
        "41.085",
        "1.307",
        "1.505",
        "-0.010"
    ],
    [
        "90",
        "-8",
        "45.338",
        "41.690",
        "1.574",
        "1.624",
        "-0.020"
    ],
    [
        "91",
        "-8",
        "44.935",
        "42.400",
        "1.518",
        "1.685",
        "-0.016"
    ],
    [
        "92",
        "-8",
        "44.562",
        "43.205",
        "1.535",
        "1.651",
        "-0.018"
    ],
    [
        "93",
        "-8",
        "44.212",
        "44.035",
        "1.601",
        "1.623",
        "-0.011"
    ],
    [
        "94",
        "-8",
        "43.854",
        "44.839",
        "1.598",
        "1.730",
        "0.013"
    ],
    [
        "95",
        "-8",
        "43.444",
        "45.632",
        "1.667",
        "1.838",
        "0.032"
    ],
    [
        "96",
        "-8",
        "42.971",
        "46.466",
        "1.838",
        "1.870",
        "0.050"
    ],
    [
        "97",
        "-8",
        "42.468",
        "47.359",
        "1.846",
        "1.966",
        "0.072"
    ],
    [
        "98",
        "-8",
        "41.960",
        "48.271",
        "1.950",
        "2.067",
        "0.108"
    ],
    [
        "99",
        "-8",
        "41.415",
        "49.077",
        "2.236",
        "2.367",
        "0.188"
    ],
    [
        "100",
        "-8",
        "40.717",
        "49.571",
        "2.664",
        "3.281",
        "0.321"
    ],
    [
        "101",
        "-8",
        "39.627",
        "49.386",
        "3.428",
        "4.723",
        "0.373"
    ],
    [
        "102",
        "-8",
        "37.848",
        "47.547",
        "4.719",
        "6.330",
        "0.395"
    ],
    [
        "103",
        "-8",
        "35.352",
        "41.743",
        "5.507",
        "7.945",
        "0.353"
    ],
    [
        "104",
        "-8",
        "32.797",
        "31.212",
        "5.954",
        "9.039",
        "0.264"
    ],
    [
        "105",
        "-8",
        "30.731",
        "20.791",
        "6.154",
        "9.289",
        "0.124"
    ],
    [
        "106",
        "-8",
        "30.637",
        "10.781",
        "5.154",
        "9.086",
        "0.031"
    ],
    [
        "107",
        "-8",
        "31.517",
        "3.816",
        "4.474",
        "7.930",
        "0.103"
    ],
    [
        "108",
        "-8",
        "31.193",
        "1.739",
        "4.084",
        "7.047",
        "0.175"
    ],
    [
        "109",
        "-8",
        "31.217",
        "-0.221",
        "3.289",
        "6.476",
        "0.177"
    ],
    [
        "110",
        "-8",
        "31.642",
        "-4.339",
        "3.059",
        "5.491",
        "0.162"
    ],
    [
        "111",
        "-8",
        "30.782",
        "-7.414",
        "3.515",
        "5.338",
        "0.042"
    ],
    [
        "112",
        "-8",
        "30.687",
        "-9.345",
        "3.197",
        "4.928",
        "-0.017"
    ],
    [
        "113",
        "-8",
        "30.669",
        "-10.866",
        "3.018",
        "4.441",
        "-0.066"
    ],
    [
        "114",
        "-8",
        "30.265",
        "-11.483",
        "2.997",
        "3.746",
        "-0.027"
    ],
    [
        "115",
        "-8",
        "28.787",
        "-10.700",
        "2.253",
        "2.758",
        "-0.196"
    ],
    [
        "116",
        "-8",
        "26.853",
        "-11.178",
        "2.551",
        "3.909",
        "-0.140"
    ],
    [
        "117",
        "-8",
        "26.565",
        "-11.981",
        "3.337",
        "3.679",
        "-0.329"
    ],
    [
        "118",
        "-8",
        "25.658",
        "-10.831",
        "3.342",
        "4.546",
        "-0.424"
    ],
    [
        "119",
        "-8",
        "25.122",
        "-11.780",
        "4.153",
        "6.008",
        "-0.525"
    ],
    [
        "120",
        "-8",
        "26.849",
        "-6.329",
        "5.968",
        "8.652",
        "-0.467"
    ],
    [
        "121",
        "-8",
        "26.499",
        "-0.281",
        "6.430",
        "10.203",
        "-0.439"
    ],
    [
        "122",
        "-8",
        "28.943",
        "10.409",
        "7.090",
        "9.998",
        "-0.448"
    ],
    [
        "123",
        "-8",
        "30.868",
        "37.252",
        "7.631",
        "9.778",
        "-0.286"
    ],
    [
        "124",
        "-8",
        "35.403",
        "32.254",
        "5.657",
        "10.232",
        "-0.115"
    ],
    [
        "125",
        "-8",
        "41.175",
        "32.657",
        "3.869",
        "8.742",
        "-0.135"
    ],
    [
        "126",
        "-8",
        "44.066",
        "42.628",
        "3.223",
        "4.938",
        "-0.199"
    ],
    [
        "127",
        "-8",
        "40.110",
        "46.349",
        "6.031",
        "9.901",
        "-0.068"
    ],
    [
        "128",
        "-8",
        "38.308",
        "50.186",
        "6.497",
        "9.636",
        "-0.193"
    ],
    [
        "129",
        "-8",
        "38.294",
        "52.812",
        "5.539",
        "6.874",
        "-0.208"
    ],
    [
        "130",
        "-8",
        "38.670",
        "54.484",
        "3.442",
        "5.113",
        "-0.035"
    ],
    [
        "131",
        "-8",
        "40.499",
        "52.167",
        "2.118",
        "2.380",
        "-0.099"
    ],
    [
        "132",
        "-8",
        "37.724",
        "57.152",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "-8",
        "37.585",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-8",
        "37.447",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-8",
        "37.309",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-8",
        "37.171",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-8",
        "37.033",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-8",
        "36.897",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-8",
        "36.761",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-8",
        "36.625",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-8",
        "36.489",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-8",
        "36.355",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-8",
        "36.221",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-8",
        "36.088",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-8",
        "35.955",
        "54.438",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "-8",
        "35.823",
        "54.111",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "-8",
        "34.091",
        "55.055",
        "3.191",
        "6.544",
        "0.091"
    ],
    [
        "148",
        "-8",
        "31.080",
        "54.924",
        "3.460",
        "6.694",
        "0.145"
    ],
    [
        "149",
        "-8",
        "27.867",
        "43.743",
        "4.128",
        "8.726",
        "0.094"
    ],
    [
        "150",
        "-8",
        "24.784",
        "43.408",
        "4.734",
        "8.812",
        "0.095"
    ],
    [
        "151",
        "-8",
        "23.198",
        "48.506",
        "4.844",
        "8.777",
        "0.060"
    ],
    [
        "152",
        "-8",
        "23.251",
        "50.855",
        "5.462",
        "9.196",
        "-0.020"
    ],
    [
        "153",
        "-8",
        "20.610",
        "50.729",
        "6.899",
        "9.281",
        "0.081"
    ],
    [
        "154",
        "-8",
        "13.297",
        "49.552",
        "7.380",
        "9.261",
        "0.129"
    ],
    [
        "155",
        "-8",
        "2.689",
        "48.027",
        "8.101",
        "8.548",
        "0.229"
    ],
    [
        "156",
        "-8",
        "-8.477",
        "44.506",
        "9.105",
        "7.662",
        "0.353"
    ],
    [
        "157",
        "-8",
        "-21.786",
        "37.255",
        "9.325",
        "7.334",
        "0.330"
    ],
    [
        "158",
        "-8",
        "-36.346",
        "31.575",
        "9.435",
        "7.189",
        "0.300"
    ],
    [
        "159",
        "-8",
        "-50.179",
        "28.405",
        "9.325",
        "7.346",
        "0.391"
    ],
    [
        "160",
        "-8",
        "-61.244",
        "24.990",
        "1.931",
        "2.946",
        "0.195"
    ],
    [
        "161",
        "-8",
        "-62.149",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-8",
        "-62.210",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-8",
        "-62.271",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-8",
        "-62.333",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-8",
        "-62.396",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-8",
        "-62.459",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-8",
        "-62.523",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-8",
        "-62.588",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-8",
        "-62.654",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-8",
        "-62.720",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-8",
        "-62.786",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-8",
        "-62.854",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-8",
        "-62.922",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-8",
        "-62.990",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-8",
        "-63.059",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-8",
        "-63.128",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-8",
        "-63.198",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-8",
        "-63.269",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-8",
        "-63.339",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-7",
        "-63.721",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-7",
        "-63.783",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-7",
        "-63.847",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-7",
        "-63.910",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-7",
        "-63.973",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-7",
        "-64.037",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-7",
        "-64.102",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-7",
        "-64.166",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-7",
        "-64.230",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-7",
        "-64.295",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-7",
        "-64.360",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-7",
        "-64.425",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-7",
        "-64.491",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-7",
        "-64.556",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-7",
        "-64.621",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-7",
        "-64.687",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-7",
        "-64.752",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-7",
        "-64.818",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-7",
        "-64.884",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-7",
        "-64.949",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-7",
        "-65.015",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-7",
        "-65.080",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-7",
        "-65.146",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-7",
        "-65.211",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-7",
        "-65.276",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-7",
        "-65.341",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-7",
        "-65.406",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-7",
        "-65.471",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-7",
        "-65.536",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-7",
        "-65.600",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-7",
        "-65.665",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-7",
        "-65.728",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-7",
        "-65.792",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-7",
        "-65.856",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-7",
        "-65.919",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-7",
        "-65.981",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-7",
        "-66.044",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-7",
        "-66.106",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-7",
        "-66.168",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-7",
        "-66.229",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-7",
        "-66.290",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-7",
        "-66.350",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-7",
        "-66.410",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-7",
        "-66.470",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-7",
        "-66.529",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-7",
        "-66.587",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-7",
        "-66.645",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-7",
        "-66.703",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-7",
        "-66.759",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-7",
        "-66.816",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-7",
        "-66.871",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-7",
        "-66.926",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-7",
        "-66.981",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-7",
        "-67.035",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-7",
        "-67.088",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-7",
        "-67.140",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-7",
        "-67.192",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-7",
        "-67.243",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-7",
        "-67.293",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-7",
        "-67.343",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-7",
        "-67.392",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-7",
        "-67.440",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-7",
        "-67.487",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-7",
        "-67.534",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-7",
        "-67.579",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-7",
        "-67.624",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-7",
        "-67.668",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-7",
        "-67.711",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-7",
        "-67.753",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-7",
        "-67.795",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-7",
        "-67.835",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-7",
        "-67.875",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-7",
        "-67.913",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-7",
        "56.784",
        "-7.798",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-7",
        "56.800",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-7",
        "56.813",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-7",
        "56.825",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-7",
        "56.836",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-7",
        "56.845",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-7",
        "56.851",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-7",
        "56.855",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-7",
        "56.858",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-7",
        "56.859",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-7",
        "56.858",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-7",
        "56.856",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-7",
        "56.851",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-7",
        "56.844",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-7",
        "56.836",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-7",
        "56.825",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-7",
        "56.814",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-7",
        "56.799",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-7",
        "56.784",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-7",
        "56.766",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-7",
        "56.747",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-7",
        "56.726",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-7",
        "56.702",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-7",
        "56.677",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-7",
        "56.650",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-7",
        "55.824",
        "13.556",
        "2.804",
        "2.646",
        "0.199"
    ],
    [
        "-81",
        "-7",
        "36.200",
        "11.578",
        "12.063",
        "7.446",
        "0.382"
    ],
    [
        "-80",
        "-7",
        "16.327",
        "7.824",
        "8.627",
        "6.966",
        "0.309"
    ],
    [
        "-79",
        "-7",
        "5.859",
        "6.397",
        "5.929",
        "4.806",
        "0.281"
    ],
    [
        "-78",
        "-7",
        "1.964",
        "6.635",
        "3.819",
        "2.928",
        "0.235"
    ],
    [
        "-77",
        "-7",
        "0.336",
        "7.518",
        "2.483",
        "1.941",
        "0.251"
    ],
    [
        "-76",
        "-7",
        "-1.040",
        "8.282",
        "1.806",
        "1.422",
        "0.341"
    ],
    [
        "-75",
        "-7",
        "-2.454",
        "8.525",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-7",
        "-2.472",
        "8.682",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-7",
        "-2.490",
        "8.836",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-7",
        "-2.510",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-7",
        "-2.528",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-7",
        "-2.548",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-7",
        "-2.568",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-7",
        "-2.588",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-7",
        "-2.609",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-7",
        "-2.630",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-7",
        "-2.650",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-7",
        "-2.672",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-7",
        "-2.694",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-7",
        "-2.716",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-7",
        "-2.737",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-7",
        "-2.760",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-7",
        "-2.783",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-7",
        "-2.805",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-7",
        "-2.829",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-7",
        "-2.852",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-7",
        "-2.875",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-7",
        "-2.899",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-7",
        "-2.923",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-7",
        "-2.948",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-7",
        "-2.971",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-7",
        "-2.996",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-7",
        "-3.021",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-7",
        "-3.046",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-7",
        "-3.071",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-7",
        "-3.095",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-7",
        "-3.121",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-7",
        "-3.147",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-7",
        "-3.172",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-7",
        "-3.198",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-7",
        "-3.224",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-7",
        "-3.249",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-7",
        "-3.276",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-7",
        "-3.301",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-7",
        "-3.327",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-7",
        "-3.354",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-7",
        "-3.379",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-7",
        "-3.406",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-7",
        "-3.433",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-7",
        "-3.459",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-7",
        "-3.485",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-7",
        "-3.512",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-7",
        "-3.538",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-7",
        "-3.565",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-7",
        "-3.592",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-7",
        "-3.618",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-7",
        "-3.645",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-7",
        "-3.671",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-7",
        "-3.697",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-7",
        "-3.723",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-7",
        "-3.749",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-7",
        "-3.776",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-7",
        "-3.802",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-7",
        "-3.828",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-7",
        "-3.854",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-7",
        "-3.880",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-7",
        "-3.905",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-7",
        "-3.932",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-7",
        "-1.683",
        "11.462",
        "1.050",
        "1.286",
        "0.140"
    ],
    [
        "-12",
        "-7",
        "17.570",
        "17.063",
        "4.259",
        "2.334",
        "0.285"
    ],
    [
        "-11",
        "-7",
        "24.819",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-7",
        "24.780",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-7",
        "24.741",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-7",
        "24.702",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-7",
        "24.662",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-7",
        "24.622",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-7",
        "24.581",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-7",
        "24.541",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-7",
        "24.501",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-7",
        "24.460",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-7",
        "24.419",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-7",
        "24.378",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-7",
        "24.337",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-7",
        "24.296",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-7",
        "24.255",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-7",
        "24.214",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-7",
        "24.172",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-7",
        "24.131",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-7",
        "24.089",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-7",
        "24.048",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-7",
        "24.006",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-7",
        "23.965",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-7",
        "23.923",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-7",
        "23.881",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-7",
        "23.839",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-7",
        "23.798",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-7",
        "23.756",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-7",
        "23.715",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-7",
        "23.674",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-7",
        "23.633",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-7",
        "23.592",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-7",
        "23.550",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-7",
        "23.510",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-7",
        "23.469",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-7",
        "23.429",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-7",
        "23.388",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-7",
        "23.348",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-7",
        "23.308",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-7",
        "23.268",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-7",
        "23.351",
        "18.535",
        "0.828",
        "0.807",
        "-0.238"
    ],
    [
        "29",
        "-7",
        "23.697",
        "18.271",
        "1.320",
        "1.216",
        "-0.209"
    ],
    [
        "30",
        "-7",
        "24.070",
        "18.067",
        "1.605",
        "1.292",
        "-0.093"
    ],
    [
        "31",
        "-7",
        "24.443",
        "17.923",
        "1.626",
        "1.437",
        "0.024"
    ],
    [
        "32",
        "-7",
        "24.767",
        "17.816",
        "1.726",
        "1.570",
        "0.066"
    ],
    [
        "33",
        "-7",
        "25.050",
        "17.693",
        "1.901",
        "1.641",
        "0.088"
    ],
    [
        "34",
        "-7",
        "25.335",
        "17.522",
        "1.844",
        "1.747",
        "0.044"
    ],
    [
        "35",
        "-7",
        "25.662",
        "17.301",
        "1.820",
        "1.740",
        "0.012"
    ],
    [
        "36",
        "-7",
        "26.054",
        "17.044",
        "1.853",
        "1.609",
        "-0.008"
    ],
    [
        "37",
        "-7",
        "26.522",
        "16.748",
        "1.541",
        "1.519",
        "-0.039"
    ],
    [
        "38",
        "-7",
        "27.025",
        "16.415",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-7",
        "26.990",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-7",
        "26.956",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-7",
        "26.923",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-7",
        "26.890",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-7",
        "26.856",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-7",
        "26.824",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-7",
        "26.792",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-7",
        "26.761",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-7",
        "26.729",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-7",
        "26.699",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-7",
        "26.669",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-7",
        "26.639",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-7",
        "26.610",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-7",
        "26.581",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-7",
        "26.554",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-7",
        "26.526",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-7",
        "26.500",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-7",
        "26.473",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-7",
        "26.448",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-7",
        "26.423",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-7",
        "26.398",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-7",
        "26.374",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-7",
        "26.351",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-7",
        "26.329",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-7",
        "26.307",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-7",
        "26.286",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-7",
        "26.265",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-7",
        "26.244",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-7",
        "26.226",
        "8.970",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-7",
        "31.590",
        "13.763",
        "9.266",
        "9.233",
        "0.570"
    ],
    [
        "69",
        "-7",
        "44.742",
        "26.205",
        "9.290",
        "7.482",
        "0.616"
    ],
    [
        "70",
        "-7",
        "47.107",
        "30.400",
        "4.314",
        "4.213",
        "0.476"
    ],
    [
        "71",
        "-7",
        "47.217",
        "31.540",
        "2.217",
        "2.049",
        "0.416"
    ],
    [
        "72",
        "-7",
        "47.249",
        "32.179",
        "1.042",
        "1.217",
        "0.213"
    ],
    [
        "73",
        "-7",
        "47.079",
        "32.820",
        "0.726",
        "1.084",
        "-0.001"
    ],
    [
        "74",
        "-7",
        "46.952",
        "33.295",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "-7",
        "46.881",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-7",
        "46.808",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-7",
        "46.734",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-7",
        "46.659",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-7",
        "46.583",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-7",
        "46.506",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-7",
        "46.426",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-7",
        "46.347",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-7",
        "46.266",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-7",
        "46.184",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-7",
        "46.102",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-7",
        "46.017",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-7",
        "45.933",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-7",
        "45.846",
        "40.569",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-7",
        "45.493",
        "40.991",
        "1.284",
        "1.487",
        "0.001"
    ],
    [
        "90",
        "-7",
        "45.111",
        "41.493",
        "1.586",
        "1.638",
        "0.002"
    ],
    [
        "91",
        "-7",
        "44.740",
        "42.160",
        "1.571",
        "1.751",
        "0.008"
    ],
    [
        "92",
        "-7",
        "44.369",
        "42.942",
        "1.617",
        "1.754",
        "0.011"
    ],
    [
        "93",
        "-7",
        "43.999",
        "43.751",
        "1.717",
        "1.726",
        "0.021"
    ],
    [
        "94",
        "-7",
        "43.608",
        "44.524",
        "1.702",
        "1.833",
        "0.038"
    ],
    [
        "95",
        "-7",
        "43.156",
        "45.294",
        "1.764",
        "1.921",
        "0.051"
    ],
    [
        "96",
        "-7",
        "42.637",
        "46.141",
        "1.911",
        "1.934",
        "0.068"
    ],
    [
        "97",
        "-7",
        "42.106",
        "47.089",
        "1.927",
        "2.032",
        "0.094"
    ],
    [
        "98",
        "-7",
        "41.601",
        "48.034",
        "2.093",
        "2.264",
        "0.148"
    ],
    [
        "99",
        "-7",
        "41.019",
        "48.715",
        "2.590",
        "3.070",
        "0.298"
    ],
    [
        "100",
        "-7",
        "40.049",
        "48.692",
        "3.423",
        "4.729",
        "0.416"
    ],
    [
        "101",
        "-7",
        "38.281",
        "47.055",
        "4.592",
        "6.818",
        "0.429"
    ],
    [
        "102",
        "-7",
        "35.516",
        "42.035",
        "6.175",
        "8.611",
        "0.433"
    ],
    [
        "103",
        "-7",
        "32.181",
        "31.697",
        "6.642",
        "9.524",
        "0.394"
    ],
    [
        "104",
        "-7",
        "28.714",
        "19.847",
        "6.528",
        "9.743",
        "0.299"
    ],
    [
        "105",
        "-7",
        "26.747",
        "7.235",
        "5.901",
        "8.672",
        "0.156"
    ],
    [
        "106",
        "-7",
        "27.974",
        "-5.236",
        "3.911",
        "6.205",
        "0.190"
    ],
    [
        "107",
        "-7",
        "27.240",
        "-7.394",
        "2.205",
        "3.599",
        "0.034"
    ],
    [
        "108",
        "-7",
        "26.762",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-7",
        "26.781",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-7",
        "26.802",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-7",
        "26.823",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-7",
        "26.846",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-7",
        "26.870",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-7",
        "26.895",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-7",
        "26.921",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-7",
        "26.948",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-7",
        "26.976",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-7",
        "27.005",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-7",
        "27.035",
        "-14.380",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-7",
        "27.066",
        "-14.854",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-7",
        "27.099",
        "-15.323",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-7",
        "27.826",
        "-12.902",
        "1.300",
        "2.813",
        "-0.195"
    ],
    [
        "123",
        "-7",
        "32.457",
        "12.645",
        "6.095",
        "8.423",
        "-0.282"
    ],
    [
        "124",
        "-7",
        "33.314",
        "23.145",
        "6.177",
        "9.189",
        "-0.243"
    ],
    [
        "125",
        "-7",
        "34.312",
        "27.343",
        "6.189",
        "9.419",
        "-0.239"
    ],
    [
        "126",
        "-7",
        "35.425",
        "33.470",
        "6.578",
        "9.319",
        "-0.265"
    ],
    [
        "127",
        "-7",
        "36.979",
        "40.837",
        "6.155",
        "9.137",
        "-0.278"
    ],
    [
        "128",
        "-7",
        "37.116",
        "45.132",
        "5.897",
        "8.294",
        "-0.089"
    ],
    [
        "129",
        "-7",
        "37.234",
        "47.480",
        "5.477",
        "6.510",
        "0.082"
    ],
    [
        "130",
        "-7",
        "38.972",
        "48.416",
        "3.956",
        "5.362",
        "0.212"
    ],
    [
        "131",
        "-7",
        "39.145",
        "51.223",
        "3.053",
        "3.877",
        "0.155"
    ],
    [
        "132",
        "-7",
        "38.034",
        "55.630",
        "2.377",
        "2.622",
        "-0.095"
    ],
    [
        "133",
        "-7",
        "37.787",
        "57.046",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "-7",
        "37.666",
        "56.923",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "-7",
        "37.545",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-7",
        "37.424",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-7",
        "37.305",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-7",
        "37.184",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-7",
        "37.065",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-7",
        "36.946",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-7",
        "36.828",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-7",
        "36.709",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-7",
        "36.592",
        "55.042",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "-7",
        "36.475",
        "54.749",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "-7",
        "34.308",
        "50.720",
        "2.313",
        "4.067",
        "0.310"
    ],
    [
        "146",
        "-7",
        "31.953",
        "49.290",
        "2.805",
        "5.540",
        "0.223"
    ],
    [
        "147",
        "-7",
        "26.852",
        "55.953",
        "3.454",
        "5.665",
        "0.211"
    ],
    [
        "148",
        "-7",
        "23.986",
        "26.758",
        "3.938",
        "6.735",
        "0.184"
    ],
    [
        "149",
        "-7",
        "22.225",
        "19.213",
        "4.483",
        "8.768",
        "0.082"
    ],
    [
        "150",
        "-7",
        "19.837",
        "13.850",
        "5.270",
        "9.185",
        "-0.001"
    ],
    [
        "151",
        "-7",
        "17.091",
        "12.960",
        "5.669",
        "9.329",
        "-0.055"
    ],
    [
        "152",
        "-7",
        "13.731",
        "19.438",
        "6.386",
        "9.496",
        "-0.034"
    ],
    [
        "153",
        "-7",
        "5.609",
        "29.180",
        "7.730",
        "9.043",
        "0.064"
    ],
    [
        "154",
        "-7",
        "-8.623",
        "36.452",
        "7.782",
        "8.558",
        "0.180"
    ],
    [
        "155",
        "-7",
        "-25.873",
        "38.984",
        "7.881",
        "7.580",
        "0.352"
    ],
    [
        "156",
        "-7",
        "-43.888",
        "31.755",
        "7.795",
        "6.070",
        "0.471"
    ],
    [
        "157",
        "-7",
        "-61.147",
        "24.984",
        "1.562",
        "2.693",
        "0.306"
    ],
    [
        "158",
        "-7",
        "-62.461",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-7",
        "-62.512",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-7",
        "-62.564",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-7",
        "-62.616",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-7",
        "-62.669",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-7",
        "-62.723",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-7",
        "-62.777",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-7",
        "-62.832",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-7",
        "-62.888",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-7",
        "-62.944",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-7",
        "-63.001",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-7",
        "-63.058",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-7",
        "-63.116",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-7",
        "-63.174",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-7",
        "-63.233",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-7",
        "-63.293",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-7",
        "-63.352",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-7",
        "-63.413",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-7",
        "-63.474",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-7",
        "-63.535",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-7",
        "-63.596",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-7",
        "-63.658",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-6",
        "-64.012",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-6",
        "-64.065",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-6",
        "-64.119",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-6",
        "-64.174",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-6",
        "-64.228",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-6",
        "-64.283",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-6",
        "-64.338",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-6",
        "-64.393",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-6",
        "-64.449",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-6",
        "-64.504",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-6",
        "-64.560",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-6",
        "-64.616",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-6",
        "-64.672",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-6",
        "-64.728",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-6",
        "-64.784",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-6",
        "-64.840",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-6",
        "-64.896",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-6",
        "-64.953",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-6",
        "-65.009",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-6",
        "-65.065",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-6",
        "-65.121",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-6",
        "-65.178",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-6",
        "-65.234",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-6",
        "-65.290",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-6",
        "-65.346",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-6",
        "-65.402",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-6",
        "-65.457",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-6",
        "-65.513",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-6",
        "-65.568",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-6",
        "-65.624",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-6",
        "-65.679",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-6",
        "-65.734",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-6",
        "-65.788",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-6",
        "-65.843",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-6",
        "-65.897",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-6",
        "-65.950",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-6",
        "-66.004",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-6",
        "-66.057",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-6",
        "-66.110",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-6",
        "-66.163",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-6",
        "-66.215",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-6",
        "-66.267",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-6",
        "-66.318",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-6",
        "-66.369",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-6",
        "-66.420",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-6",
        "-66.470",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-6",
        "-66.520",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-6",
        "-66.569",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-6",
        "-66.618",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-6",
        "-66.666",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-6",
        "-66.714",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-6",
        "-66.761",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-6",
        "-66.808",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-6",
        "-66.854",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-6",
        "-66.899",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-6",
        "-66.944",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-6",
        "-66.989",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-6",
        "-67.033",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-6",
        "-67.076",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-6",
        "-67.118",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-6",
        "-67.160",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-6",
        "-67.201",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-6",
        "-67.242",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-6",
        "-67.282",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-6",
        "-67.321",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-6",
        "-67.359",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-6",
        "-67.397",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-6",
        "-67.434",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-6",
        "-67.470",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-6",
        "-67.506",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-6",
        "-67.541",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-6",
        "-67.574",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-6",
        "-67.608",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-6",
        "-32.777",
        "12.574",
        "6.204",
        "7.940",
        "-0.071"
    ],
    [
        "-106",
        "-6",
        "56.043",
        "-6.935",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-6",
        "56.055",
        "-6.070",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-6",
        "56.065",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-6",
        "56.074",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-6",
        "56.081",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-6",
        "56.086",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-6",
        "56.090",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-6",
        "56.092",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-6",
        "56.093",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-6",
        "56.093",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-6",
        "56.091",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-6",
        "56.087",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-6",
        "56.080",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-6",
        "56.073",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-6",
        "56.065",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-6",
        "56.054",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-6",
        "56.042",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-6",
        "56.028",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-6",
        "56.014",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-6",
        "55.997",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-6",
        "55.978",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-6",
        "55.959",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-6",
        "55.938",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-6",
        "55.915",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-6",
        "54.462",
        "13.803",
        "2.787",
        "2.555",
        "0.235"
    ],
    [
        "-81",
        "-6",
        "35.255",
        "12.461",
        "11.021",
        "6.959",
        "0.436"
    ],
    [
        "-80",
        "-6",
        "16.108",
        "8.930",
        "7.652",
        "6.085",
        "0.370"
    ],
    [
        "-79",
        "-6",
        "5.033",
        "6.938",
        "5.179",
        "4.019",
        "0.355"
    ],
    [
        "-78",
        "-6",
        "0.759",
        "6.804",
        "3.220",
        "2.417",
        "0.302"
    ],
    [
        "-77",
        "-6",
        "-0.941",
        "7.545",
        "2.043",
        "1.634",
        "0.300"
    ],
    [
        "-76",
        "-6",
        "-1.997",
        "8.263",
        "1.509",
        "1.259",
        "0.377"
    ],
    [
        "-75",
        "-6",
        "-2.618",
        "8.525",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-6",
        "-2.634",
        "8.682",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-6",
        "-2.650",
        "8.836",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-6",
        "-2.666",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-6",
        "-2.682",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-6",
        "-2.699",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-6",
        "-2.716",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-6",
        "-2.733",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-6",
        "-2.752",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-6",
        "-2.769",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-6",
        "-2.787",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-6",
        "-2.805",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-6",
        "-2.823",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-6",
        "-2.843",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-6",
        "-2.861",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-6",
        "-2.881",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-6",
        "-2.900",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-6",
        "-2.919",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-6",
        "-2.940",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-6",
        "-2.960",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-6",
        "-2.980",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-6",
        "-3.000",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-6",
        "-3.021",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-6",
        "-3.041",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-6",
        "-3.062",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-6",
        "-3.083",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-6",
        "-3.105",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-6",
        "-3.126",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-6",
        "-3.147",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-6",
        "-3.168",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-6",
        "-3.190",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-6",
        "-3.213",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-6",
        "-3.234",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-6",
        "-3.256",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-6",
        "-3.278",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-6",
        "-3.301",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-6",
        "-3.323",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-6",
        "-3.345",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-6",
        "-3.368",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-6",
        "-3.390",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-6",
        "-3.413",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-6",
        "-3.435",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-6",
        "-3.458",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-6",
        "-3.481",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-6",
        "-3.503",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-6",
        "-3.525",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-6",
        "-3.548",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-6",
        "-3.571",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-6",
        "-3.593",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-6",
        "-3.617",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-6",
        "-3.639",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-6",
        "-3.662",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-6",
        "-3.685",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-6",
        "-3.707",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-6",
        "-3.730",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-6",
        "-3.752",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-6",
        "-3.775",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-6",
        "-3.797",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-6",
        "-3.819",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-6",
        "-3.842",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-6",
        "-3.863",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-6",
        "-3.886",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-6",
        "-3.908",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-6",
        "-3.930",
        "11.951",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-6",
        "22.072",
        "17.916",
        "1.392",
        "1.501",
        "0.075"
    ],
    [
        "-10",
        "-6",
        "24.713",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-6",
        "24.680",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-6",
        "24.646",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-6",
        "24.611",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-6",
        "24.578",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-6",
        "24.543",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-6",
        "24.509",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-6",
        "24.473",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-6",
        "24.439",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-6",
        "24.404",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-6",
        "24.369",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-6",
        "24.334",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-6",
        "24.299",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-6",
        "24.263",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-6",
        "24.228",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-6",
        "24.192",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-6",
        "24.156",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-6",
        "24.121",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-6",
        "24.085",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-6",
        "24.050",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-6",
        "24.013",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-6",
        "23.978",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-6",
        "23.942",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-6",
        "23.907",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-6",
        "23.871",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-6",
        "23.835",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-6",
        "23.800",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-6",
        "23.765",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-6",
        "23.729",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-6",
        "23.694",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-6",
        "23.659",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-6",
        "23.624",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-6",
        "23.589",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-6",
        "23.554",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-6",
        "23.519",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-6",
        "23.485",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-6",
        "23.452",
        "18.772",
        "0.246",
        "0.334",
        "-0.118"
    ],
    [
        "27",
        "-6",
        "23.452",
        "18.678",
        "1.009",
        "0.759",
        "-0.249"
    ],
    [
        "28",
        "-6",
        "23.574",
        "18.527",
        "1.123",
        "0.968",
        "-0.219"
    ],
    [
        "29",
        "-6",
        "23.846",
        "18.283",
        "1.329",
        "1.207",
        "-0.208"
    ],
    [
        "30",
        "-6",
        "24.211",
        "18.077",
        "1.604",
        "1.300",
        "-0.135"
    ],
    [
        "31",
        "-6",
        "24.610",
        "17.932",
        "1.652",
        "1.489",
        "-0.022"
    ],
    [
        "32",
        "-6",
        "24.983",
        "17.818",
        "1.767",
        "1.660",
        "0.019"
    ],
    [
        "33",
        "-6",
        "25.318",
        "17.671",
        "1.934",
        "1.694",
        "0.051"
    ],
    [
        "34",
        "-6",
        "25.636",
        "17.465",
        "1.821",
        "1.727",
        "0.037"
    ],
    [
        "35",
        "-6",
        "25.981",
        "17.216",
        "1.748",
        "1.603",
        "0.034"
    ],
    [
        "36",
        "-6",
        "26.390",
        "16.948",
        "1.736",
        "1.413",
        "0.037"
    ],
    [
        "37",
        "-6",
        "26.876",
        "16.665",
        "1.382",
        "1.248",
        "0.020"
    ],
    [
        "38",
        "-6",
        "27.274",
        "16.415",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-6",
        "27.245",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-6",
        "27.215",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-6",
        "27.186",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-6",
        "27.157",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-6",
        "27.129",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-6",
        "27.102",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-6",
        "27.074",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-6",
        "27.047",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-6",
        "27.020",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-6",
        "26.994",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-6",
        "26.968",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-6",
        "26.943",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-6",
        "26.918",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-6",
        "26.894",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-6",
        "26.870",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-6",
        "26.847",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-6",
        "26.824",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-6",
        "26.801",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-6",
        "26.779",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-6",
        "26.758",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-6",
        "26.737",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-6",
        "26.716",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-6",
        "26.697",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-6",
        "26.677",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-6",
        "26.658",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-6",
        "26.640",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-6",
        "26.623",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-6",
        "26.605",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-6",
        "26.588",
        "8.970",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-6",
        "28.218",
        "9.797",
        "2.643",
        "2.911",
        "0.448"
    ],
    [
        "69",
        "-6",
        "41.579",
        "23.986",
        "9.399",
        "7.105",
        "0.622"
    ],
    [
        "70",
        "-6",
        "45.966",
        "30.146",
        "4.749",
        "4.285",
        "0.499"
    ],
    [
        "71",
        "-6",
        "46.623",
        "31.711",
        "2.436",
        "2.197",
        "0.403"
    ],
    [
        "72",
        "-6",
        "46.553",
        "32.453",
        "1.340",
        "1.510",
        "0.196"
    ],
    [
        "73",
        "-6",
        "46.485",
        "33.001",
        "0.989",
        "1.343",
        "0.083"
    ],
    [
        "74",
        "-6",
        "46.436",
        "33.363",
        "0.629",
        "0.848",
        "-0.030"
    ],
    [
        "75",
        "-6",
        "46.383",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-6",
        "46.321",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-6",
        "46.258",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-6",
        "46.194",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-6",
        "46.128",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-6",
        "46.061",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-6",
        "45.994",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-6",
        "45.926",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-6",
        "45.857",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-6",
        "45.787",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-6",
        "45.716",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-6",
        "45.644",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-6",
        "45.571",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-6",
        "45.497",
        "40.569",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-6",
        "45.222",
        "40.834",
        "1.178",
        "1.412",
        "0.014"
    ],
    [
        "90",
        "-6",
        "44.925",
        "41.216",
        "1.510",
        "1.606",
        "0.016"
    ],
    [
        "91",
        "-6",
        "44.609",
        "41.838",
        "1.561",
        "1.781",
        "0.022"
    ],
    [
        "92",
        "-6",
        "44.256",
        "42.582",
        "1.656",
        "1.848",
        "0.030"
    ],
    [
        "93",
        "-6",
        "43.870",
        "43.349",
        "1.812",
        "1.844",
        "0.041"
    ],
    [
        "94",
        "-6",
        "43.435",
        "44.097",
        "1.798",
        "1.956",
        "0.057"
    ],
    [
        "95",
        "-6",
        "42.928",
        "44.871",
        "1.864",
        "2.022",
        "0.069"
    ],
    [
        "96",
        "-6",
        "42.357",
        "45.770",
        "2.013",
        "2.030",
        "0.082"
    ],
    [
        "97",
        "-6",
        "41.767",
        "46.769",
        "2.080",
        "2.192",
        "0.113"
    ],
    [
        "98",
        "-6",
        "41.120",
        "47.592",
        "2.407",
        "2.758",
        "0.205"
    ],
    [
        "99",
        "-6",
        "40.166",
        "47.753",
        "3.372",
        "4.289",
        "0.381"
    ],
    [
        "100",
        "-6",
        "38.422",
        "46.322",
        "4.643",
        "6.573",
        "0.471"
    ],
    [
        "101",
        "-6",
        "35.533",
        "41.830",
        "5.928",
        "8.763",
        "0.479"
    ],
    [
        "102",
        "-6",
        "32.232",
        "32.851",
        "7.208",
        "9.765",
        "0.494"
    ],
    [
        "103",
        "-6",
        "28.861",
        "19.952",
        "6.836",
        "8.928",
        "0.439"
    ],
    [
        "104",
        "-6",
        "27.393",
        "9.396",
        "6.053",
        "8.748",
        "0.359"
    ],
    [
        "105",
        "-6",
        "28.150",
        "-4.515",
        "4.787",
        "6.184",
        "0.324"
    ],
    [
        "106",
        "-6",
        "27.520",
        "-7.731",
        "0.926",
        "1.867",
        "0.165"
    ],
    [
        "107",
        "-6",
        "27.319",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-6",
        "27.335",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-6",
        "27.351",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-6",
        "27.369",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-6",
        "27.387",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-6",
        "27.408",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-6",
        "27.428",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-6",
        "27.449",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-6",
        "27.471",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-6",
        "27.494",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-6",
        "27.519",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-6",
        "27.543",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-6",
        "27.569",
        "-14.380",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "-6",
        "27.596",
        "-14.854",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-6",
        "27.623",
        "-15.323",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-6",
        "28.221",
        "-14.828",
        "1.244",
        "1.795",
        "-0.190"
    ],
    [
        "123",
        "-6",
        "31.196",
        "-1.243",
        "5.343",
        "5.901",
        "-0.212"
    ],
    [
        "124",
        "-6",
        "31.140",
        "12.656",
        "4.990",
        "6.627",
        "-0.153"
    ],
    [
        "125",
        "-6",
        "30.901",
        "20.923",
        "4.911",
        "7.156",
        "-0.065"
    ],
    [
        "126",
        "-6",
        "30.881",
        "27.723",
        "5.080",
        "7.165",
        "-0.037"
    ],
    [
        "127",
        "-6",
        "31.380",
        "34.239",
        "5.021",
        "7.293",
        "0.036"
    ],
    [
        "128",
        "-6",
        "32.696",
        "39.830",
        "5.000",
        "6.772",
        "0.148"
    ],
    [
        "129",
        "-6",
        "34.497",
        "42.454",
        "5.062",
        "5.851",
        "0.298"
    ],
    [
        "130",
        "-6",
        "35.072",
        "44.968",
        "4.478",
        "5.491",
        "0.393"
    ],
    [
        "131",
        "-6",
        "34.246",
        "49.478",
        "4.008",
        "4.490",
        "0.360"
    ],
    [
        "132",
        "-6",
        "33.427",
        "54.112",
        "3.563",
        "3.373",
        "0.300"
    ],
    [
        "133",
        "-6",
        "33.984",
        "56.828",
        "2.482",
        "2.172",
        "0.379"
    ],
    [
        "134",
        "-6",
        "37.717",
        "59.435",
        "1.421",
        "1.323",
        "0.032"
    ],
    [
        "135",
        "-6",
        "37.770",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-6",
        "37.667",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-6",
        "37.564",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-6",
        "37.461",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-6",
        "37.359",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-6",
        "37.256",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-6",
        "37.154",
        "55.580",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-6",
        "37.053",
        "55.319",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-6",
        "34.627",
        "51.988",
        "2.396",
        "3.100",
        "0.410"
    ],
    [
        "144",
        "-6",
        "32.364",
        "47.224",
        "2.993",
        "4.055",
        "0.335"
    ],
    [
        "145",
        "-6",
        "30.773",
        "43.854",
        "3.305",
        "5.114",
        "0.215"
    ],
    [
        "146",
        "-6",
        "29.053",
        "43.214",
        "3.767",
        "5.535",
        "0.233"
    ],
    [
        "147",
        "-6",
        "27.092",
        "32.371",
        "4.498",
        "6.758",
        "0.210"
    ],
    [
        "148",
        "-6",
        "26.427",
        "16.014",
        "4.776",
        "8.125",
        "0.160"
    ],
    [
        "149",
        "-6",
        "22.006",
        "0.848",
        "5.163",
        "8.754",
        "0.049"
    ],
    [
        "150",
        "-6",
        "19.727",
        "-13.449",
        "5.971",
        "8.530",
        "-0.043"
    ],
    [
        "151",
        "-6",
        "17.286",
        "-26.247",
        "5.531",
        "7.616",
        "-0.179"
    ],
    [
        "152",
        "-6",
        "13.369",
        "-12.423",
        "5.917",
        "7.915",
        "-0.081"
    ],
    [
        "153",
        "-6",
        "-1.930",
        "11.021",
        "8.096",
        "7.784",
        "-0.029"
    ],
    [
        "154",
        "-6",
        "-26.504",
        "24.324",
        "7.434",
        "7.219",
        "0.132"
    ],
    [
        "155",
        "-6",
        "-53.633",
        "27.074",
        "5.140",
        "5.115",
        "0.312"
    ],
    [
        "156",
        "-6",
        "-62.846",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-6",
        "-62.888",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-6",
        "-62.931",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-6",
        "-62.975",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-6",
        "-63.019",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-6",
        "-63.064",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-6",
        "-63.110",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-6",
        "-63.156",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-6",
        "-63.202",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-6",
        "-63.249",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-6",
        "-63.297",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-6",
        "-63.345",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-6",
        "-63.394",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-6",
        "-63.443",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-6",
        "-63.493",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-6",
        "-63.543",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-6",
        "-63.593",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-6",
        "-63.644",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-6",
        "-63.696",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-6",
        "-63.747",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-6",
        "-63.800",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-6",
        "-63.852",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-6",
        "-63.905",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-6",
        "-63.958",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-5",
        "-64.283",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-5",
        "-64.328",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-5",
        "-64.373",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-5",
        "-64.418",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-5",
        "-64.463",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-5",
        "-64.509",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-5",
        "-64.555",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-5",
        "-64.601",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-5",
        "-64.647",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-5",
        "-64.694",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-5",
        "-64.740",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-5",
        "-64.787",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-5",
        "-64.833",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-5",
        "-64.880",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-5",
        "-64.927",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-5",
        "-64.974",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-5",
        "-65.021",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-5",
        "-65.067",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-5",
        "-65.114",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-5",
        "-65.161",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-5",
        "-65.208",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-5",
        "-65.255",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-5",
        "-65.302",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-5",
        "-65.349",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-5",
        "-65.395",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-5",
        "-65.442",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-5",
        "-65.488",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-5",
        "-65.535",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-5",
        "-65.581",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-5",
        "-65.627",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-5",
        "-65.673",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-5",
        "-65.719",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-5",
        "-65.764",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-5",
        "-65.809",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-5",
        "-65.855",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-5",
        "-65.899",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-5",
        "-65.944",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-5",
        "-65.988",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-5",
        "-66.033",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-5",
        "-66.076",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-5",
        "-66.120",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-5",
        "-66.163",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-5",
        "-66.206",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-5",
        "-66.249",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-5",
        "-66.291",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-5",
        "-66.333",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-5",
        "-66.374",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-5",
        "-66.415",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-5",
        "-66.456",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-5",
        "-66.496",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-5",
        "-66.536",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-5",
        "-66.575",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-5",
        "-66.614",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-5",
        "-66.653",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-5",
        "-66.691",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-5",
        "-66.728",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-5",
        "-66.765",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-5",
        "-66.802",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-5",
        "-66.838",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-5",
        "-66.873",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-5",
        "-66.908",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-5",
        "-66.942",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-5",
        "-66.976",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-5",
        "-67.010",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-5",
        "-67.042",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-5",
        "-67.074",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-5",
        "-67.106",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-5",
        "-67.137",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-5",
        "-67.167",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-5",
        "-67.196",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-5",
        "-67.225",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-5",
        "-67.254",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-5",
        "-67.281",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-5",
        "-67.308",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-5",
        "-24.845",
        "9.005",
        "9.224",
        "9.595",
        "-0.083"
    ],
    [
        "-105",
        "-5",
        "22.755",
        "4.367",
        "9.372",
        "9.676",
        "-0.054"
    ],
    [
        "-104",
        "-5",
        "55.287",
        "-5.204",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-5",
        "55.295",
        "-4.336",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "-5",
        "55.300",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-5",
        "55.304",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-5",
        "55.308",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-5",
        "55.310",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-5",
        "55.310",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-5",
        "55.310",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-5",
        "55.308",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-5",
        "55.305",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-5",
        "55.300",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-5",
        "55.294",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-5",
        "55.286",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-5",
        "55.278",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-5",
        "55.268",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-5",
        "55.257",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-5",
        "55.245",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-5",
        "55.230",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-5",
        "55.215",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-5",
        "55.198",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-5",
        "55.181",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-5",
        "55.161",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-5",
        "53.392",
        "13.803",
        "2.409",
        "2.419",
        "0.289"
    ],
    [
        "-81",
        "-5",
        "33.674",
        "12.367",
        "9.537",
        "6.870",
        "0.481"
    ],
    [
        "-80",
        "-5",
        "15.101",
        "9.026",
        "6.719",
        "5.752",
        "0.430"
    ],
    [
        "-79",
        "-5",
        "4.107",
        "7.006",
        "4.590",
        "3.723",
        "0.408"
    ],
    [
        "-78",
        "-5",
        "-0.176",
        "6.763",
        "2.863",
        "2.224",
        "0.329"
    ],
    [
        "-77",
        "-5",
        "-1.949",
        "7.598",
        "1.706",
        "1.420",
        "0.286"
    ],
    [
        "-76",
        "-5",
        "-2.591",
        "8.302",
        "1.298",
        "1.099",
        "0.341"
    ],
    [
        "-75",
        "-5",
        "-2.782",
        "8.525",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-5",
        "-2.794",
        "8.682",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-5",
        "-2.808",
        "8.836",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-5",
        "-2.821",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-5",
        "-2.836",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-5",
        "-2.849",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-5",
        "-2.864",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-5",
        "-2.877",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-5",
        "-2.892",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-5",
        "-2.908",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-5",
        "-2.923",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-5",
        "-2.937",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-5",
        "-2.953",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-5",
        "-2.969",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-5",
        "-2.984",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-5",
        "-3.001",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-5",
        "-3.017",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-5",
        "-3.033",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-5",
        "-3.050",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-5",
        "-3.067",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-5",
        "-3.083",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-5",
        "-3.100",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-5",
        "-3.118",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-5",
        "-3.135",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-5",
        "-3.152",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-5",
        "-3.169",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-5",
        "-3.187",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-5",
        "-3.205",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-5",
        "-3.222",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-5",
        "-3.241",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-5",
        "-3.259",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-5",
        "-3.277",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-5",
        "-3.295",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-5",
        "-3.314",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-5",
        "-3.332",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-5",
        "-3.351",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-5",
        "-3.369",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-5",
        "-3.388",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-5",
        "-3.406",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-5",
        "-3.426",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-5",
        "-3.443",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-5",
        "-3.463",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-5",
        "-3.481",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-5",
        "-3.500",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-5",
        "-3.519",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-5",
        "-3.538",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-5",
        "-3.557",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-5",
        "-3.577",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-5",
        "-3.595",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-5",
        "-3.614",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-5",
        "-3.633",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-5",
        "-3.652",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-5",
        "-3.670",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-5",
        "-3.689",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-5",
        "-3.708",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-5",
        "-3.727",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-5",
        "-3.746",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-5",
        "-3.765",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-5",
        "-3.783",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-5",
        "-3.801",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-5",
        "-3.820",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-5",
        "-3.838",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-5",
        "-3.857",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-5",
        "3.546",
        "14.494",
        "4.839",
        "2.464",
        "0.235"
    ],
    [
        "-11",
        "-5",
        "23.453",
        "17.529",
        "1.269",
        "1.272",
        "0.175"
    ],
    [
        "-10",
        "-5",
        "24.639",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-5",
        "24.611",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-5",
        "24.583",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-5",
        "24.554",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-5",
        "24.526",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-5",
        "24.497",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-5",
        "24.469",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-5",
        "24.439",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-5",
        "24.410",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-5",
        "24.381",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-5",
        "24.351",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-5",
        "24.322",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-5",
        "24.293",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-5",
        "24.264",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-5",
        "24.234",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-5",
        "24.204",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-5",
        "24.175",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-5",
        "24.145",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-5",
        "24.115",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-5",
        "24.085",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-5",
        "24.056",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-5",
        "24.026",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-5",
        "23.996",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-5",
        "23.967",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-5",
        "23.936",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-5",
        "23.907",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-5",
        "23.877",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-5",
        "23.848",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-5",
        "23.819",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-5",
        "23.789",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-5",
        "23.760",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-5",
        "23.731",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-5",
        "23.701",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-5",
        "23.672",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-5",
        "23.644",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-5",
        "23.614",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-5",
        "23.586",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-5",
        "23.557",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-5",
        "23.657",
        "18.530",
        "0.902",
        "0.813",
        "-0.283"
    ],
    [
        "29",
        "-5",
        "23.976",
        "18.295",
        "1.346",
        "1.184",
        "-0.197"
    ],
    [
        "30",
        "-5",
        "24.341",
        "18.091",
        "1.617",
        "1.303",
        "-0.148"
    ],
    [
        "31",
        "-5",
        "24.764",
        "17.942",
        "1.659",
        "1.538",
        "-0.051"
    ],
    [
        "32",
        "-5",
        "25.196",
        "17.815",
        "1.757",
        "1.723",
        "-0.001"
    ],
    [
        "33",
        "-5",
        "25.589",
        "17.648",
        "1.882",
        "1.711",
        "0.048"
    ],
    [
        "34",
        "-5",
        "25.941",
        "17.422",
        "1.726",
        "1.680",
        "0.067"
    ],
    [
        "35",
        "-5",
        "26.294",
        "17.158",
        "1.633",
        "1.504",
        "0.084"
    ],
    [
        "36",
        "-5",
        "26.692",
        "16.883",
        "1.630",
        "1.324",
        "0.095"
    ],
    [
        "37",
        "-5",
        "27.130",
        "16.616",
        "1.375",
        "1.236",
        "0.101"
    ],
    [
        "38",
        "-5",
        "27.515",
        "16.415",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-5",
        "27.491",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-5",
        "27.467",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-5",
        "27.442",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-5",
        "27.418",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-5",
        "27.394",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-5",
        "27.371",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-5",
        "27.348",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-5",
        "27.326",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-5",
        "27.304",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-5",
        "27.281",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-5",
        "27.261",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-5",
        "27.240",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-5",
        "27.219",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-5",
        "27.198",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-5",
        "27.178",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-5",
        "27.158",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-5",
        "27.140",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-5",
        "27.121",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-5",
        "27.102",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-5",
        "27.084",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-5",
        "27.067",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-5",
        "27.050",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-5",
        "27.033",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-5",
        "27.017",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-5",
        "27.001",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-5",
        "26.987",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-5",
        "26.972",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-5",
        "26.957",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-5",
        "26.943",
        "8.970",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-5",
        "28.059",
        "9.516",
        "2.582",
        "2.881",
        "0.435"
    ],
    [
        "69",
        "-5",
        "39.494",
        "23.211",
        "9.455",
        "7.260",
        "0.626"
    ],
    [
        "70",
        "-5",
        "44.728",
        "30.174",
        "4.970",
        "4.546",
        "0.522"
    ],
    [
        "71",
        "-5",
        "45.858",
        "32.039",
        "2.607",
        "2.353",
        "0.400"
    ],
    [
        "72",
        "-5",
        "45.938",
        "32.818",
        "1.567",
        "1.608",
        "0.188"
    ],
    [
        "73",
        "-5",
        "45.927",
        "33.273",
        "1.246",
        "1.483",
        "0.100"
    ],
    [
        "74",
        "-5",
        "45.899",
        "33.563",
        "1.030",
        "1.325",
        "0.017"
    ],
    [
        "75",
        "-5",
        "45.873",
        "33.886",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "-5",
        "45.821",
        "34.467",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "-5",
        "45.768",
        "35.037",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "-5",
        "45.714",
        "35.596",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "-5",
        "45.659",
        "36.145",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "-5",
        "45.604",
        "36.683",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "-5",
        "45.548",
        "37.209",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "-5",
        "45.491",
        "37.724",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "-5",
        "45.433",
        "38.228",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "-5",
        "45.374",
        "38.720",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "-5",
        "45.316",
        "39.201",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "-5",
        "45.255",
        "39.668",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "-5",
        "45.194",
        "40.125",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "-5",
        "45.133",
        "40.569",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "-5",
        "44.958",
        "40.610",
        "1.018",
        "1.325",
        "0.031"
    ],
    [
        "90",
        "-5",
        "44.770",
        "40.902",
        "1.365",
        "1.543",
        "0.028"
    ],
    [
        "91",
        "-5",
        "44.537",
        "41.475",
        "1.478",
        "1.756",
        "0.029"
    ],
    [
        "92",
        "-5",
        "44.236",
        "42.164",
        "1.616",
        "1.881",
        "0.043"
    ],
    [
        "93",
        "-5",
        "43.859",
        "42.874",
        "1.822",
        "1.918",
        "0.058"
    ],
    [
        "94",
        "-5",
        "43.396",
        "43.579",
        "1.836",
        "2.051",
        "0.076"
    ],
    [
        "95",
        "-5",
        "42.835",
        "44.345",
        "1.935",
        "2.132",
        "0.090"
    ],
    [
        "96",
        "-5",
        "42.181",
        "45.239",
        "2.142",
        "2.224",
        "0.089"
    ],
    [
        "97",
        "-5",
        "41.403",
        "46.027",
        "2.351",
        "2.656",
        "0.132"
    ],
    [
        "98",
        "-5",
        "40.369",
        "46.309",
        "2.960",
        "3.835",
        "0.230"
    ],
    [
        "99",
        "-5",
        "38.731",
        "45.323",
        "4.409",
        "5.895",
        "0.375"
    ],
    [
        "100",
        "-5",
        "35.958",
        "41.575",
        "5.665",
        "8.117",
        "0.454"
    ],
    [
        "101",
        "-5",
        "31.876",
        "33.420",
        "6.349",
        "9.241",
        "0.454"
    ],
    [
        "102",
        "-5",
        "27.531",
        "19.199",
        "6.207",
        "5.709",
        "0.457"
    ],
    [
        "103",
        "-5",
        "27.048",
        "12.334",
        "5.712",
        "7.851",
        "0.476"
    ],
    [
        "104",
        "-5",
        "27.458",
        "-2.189",
        "4.578",
        "6.608",
        "0.458"
    ],
    [
        "105",
        "-5",
        "27.862",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-5",
        "27.874",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-5",
        "27.886",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-5",
        "27.899",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-5",
        "27.913",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-5",
        "27.928",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-5",
        "27.943",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-5",
        "27.960",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-5",
        "27.977",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-5",
        "27.994",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-5",
        "28.014",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-5",
        "28.032",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-5",
        "28.053",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-5",
        "28.073",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-5",
        "27.454",
        "-14.634",
        "0.564",
        "1.241",
        "0.415"
    ],
    [
        "120",
        "-5",
        "28.117",
        "-14.854",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "-5",
        "28.140",
        "-15.323",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "-5",
        "28.160",
        "-14.291",
        "1.444",
        "1.568",
        "-0.209"
    ],
    [
        "123",
        "-5",
        "22.800",
        "-2.643",
        "5.105",
        "4.401",
        "-0.134"
    ],
    [
        "124",
        "-5",
        "22.294",
        "8.822",
        "4.200",
        "5.007",
        "0.041"
    ],
    [
        "125",
        "-5",
        "22.738",
        "18.245",
        "4.190",
        "5.599",
        "0.084"
    ],
    [
        "126",
        "-5",
        "23.439",
        "25.057",
        "4.389",
        "5.995",
        "0.098"
    ],
    [
        "127",
        "-5",
        "24.233",
        "30.806",
        "4.569",
        "6.455",
        "0.150"
    ],
    [
        "128",
        "-5",
        "25.314",
        "34.428",
        "4.810",
        "6.446",
        "0.235"
    ],
    [
        "129",
        "-5",
        "26.434",
        "35.345",
        "5.304",
        "5.837",
        "0.422"
    ],
    [
        "130",
        "-5",
        "26.085",
        "38.814",
        "5.248",
        "5.188",
        "0.509"
    ],
    [
        "131",
        "-5",
        "25.067",
        "47.286",
        "5.038",
        "5.550",
        "0.451"
    ],
    [
        "132",
        "-5",
        "24.167",
        "50.533",
        "4.549",
        "4.332",
        "0.467"
    ],
    [
        "133",
        "-5",
        "23.621",
        "58.556",
        "2.582",
        "1.650",
        "0.433"
    ],
    [
        "134",
        "-5",
        "32.642",
        "57.454",
        "1.753",
        "1.563",
        "0.142"
    ],
    [
        "135",
        "-5",
        "37.983",
        "56.783",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "-5",
        "37.897",
        "56.625",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "-5",
        "37.812",
        "56.450",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "-5",
        "37.725",
        "56.259",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "-5",
        "37.640",
        "56.050",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "-5",
        "37.555",
        "55.823",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-5",
        "34.984",
        "54.035",
        "3.572",
        "3.948",
        "0.381"
    ],
    [
        "142",
        "-5",
        "31.884",
        "51.742",
        "4.033",
        "4.145",
        "0.334"
    ],
    [
        "143",
        "-5",
        "29.013",
        "46.927",
        "4.252",
        "4.594",
        "0.272"
    ],
    [
        "144",
        "-5",
        "27.102",
        "41.669",
        "4.384",
        "4.886",
        "0.214"
    ],
    [
        "145",
        "-5",
        "26.451",
        "38.719",
        "4.100",
        "5.280",
        "0.121"
    ],
    [
        "146",
        "-5",
        "26.461",
        "33.880",
        "3.575",
        "4.713",
        "-0.002"
    ],
    [
        "147",
        "-5",
        "27.893",
        "25.491",
        "4.900",
        "6.919",
        "0.083"
    ],
    [
        "148",
        "-5",
        "28.040",
        "12.786",
        "5.464",
        "8.080",
        "0.118"
    ],
    [
        "149",
        "-5",
        "27.743",
        "-7.299",
        "5.427",
        "7.061",
        "0.010"
    ],
    [
        "150",
        "-5",
        "24.189",
        "-22.525",
        "6.197",
        "7.086",
        "0.056"
    ],
    [
        "151",
        "-5",
        "11.420",
        "-35.063",
        "6.436",
        "7.180",
        "-0.155"
    ],
    [
        "152",
        "-5",
        "-2.729",
        "-39.003",
        "6.676",
        "6.299",
        "-0.346"
    ],
    [
        "153",
        "-5",
        "0.709",
        "-4.030",
        "7.935",
        "6.239",
        "-0.123"
    ],
    [
        "154",
        "-5",
        "-43.498",
        "17.933",
        "6.269",
        "5.492",
        "0.009"
    ],
    [
        "155",
        "-5",
        "-63.276",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-5",
        "-63.311",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-5",
        "-63.346",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-5",
        "-63.382",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-5",
        "-63.418",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-5",
        "-63.455",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-5",
        "-63.493",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-5",
        "-63.531",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-5",
        "-63.569",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-5",
        "-63.608",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-5",
        "-63.647",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-5",
        "-63.687",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-5",
        "-63.727",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-5",
        "-63.768",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-5",
        "-63.809",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-5",
        "-63.850",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-5",
        "-63.892",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-5",
        "-63.934",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-5",
        "-63.977",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-5",
        "-64.019",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-5",
        "-64.063",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-5",
        "-64.106",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-5",
        "-64.150",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-5",
        "-64.194",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-5",
        "-64.238",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-4",
        "-64.534",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-4",
        "-64.570",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-4",
        "-64.606",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-4",
        "-64.643",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-4",
        "-64.679",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-4",
        "-64.716",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-4",
        "-64.752",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-4",
        "-64.789",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-4",
        "-64.826",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-4",
        "-64.863",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-4",
        "-64.900",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-4",
        "-64.938",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-4",
        "-64.975",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-4",
        "-65.012",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-4",
        "-65.050",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-4",
        "-65.087",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-4",
        "-65.125",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-4",
        "-65.162",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-4",
        "-65.200",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-4",
        "-65.238",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-4",
        "-65.275",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-4",
        "-65.313",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-4",
        "-65.350",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-4",
        "-65.387",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-4",
        "-65.425",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-4",
        "-65.462",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-4",
        "-65.499",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-4",
        "-65.536",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-4",
        "-65.573",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-4",
        "-65.610",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-4",
        "-65.647",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-4",
        "-65.684",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-4",
        "-65.720",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-4",
        "-65.756",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-4",
        "-65.792",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-4",
        "-65.828",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-4",
        "-65.864",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-4",
        "-65.900",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-4",
        "-65.935",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-4",
        "-65.970",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-4",
        "-66.005",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-4",
        "-66.039",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-4",
        "-66.074",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-4",
        "-66.108",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-4",
        "-66.142",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-4",
        "-66.175",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-4",
        "-66.208",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-4",
        "-66.241",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-4",
        "-66.274",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-4",
        "-66.306",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-4",
        "-66.338",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-4",
        "-66.369",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-4",
        "-66.400",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-4",
        "-66.431",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-4",
        "-66.462",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-4",
        "-66.492",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-4",
        "-66.521",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-4",
        "-66.551",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-4",
        "-66.579",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-4",
        "-66.608",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-4",
        "-66.636",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-4",
        "-66.663",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-4",
        "-66.690",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-4",
        "-66.717",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-4",
        "-66.743",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-4",
        "-66.769",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-4",
        "-66.794",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-4",
        "-66.818",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-4",
        "-66.843",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-4",
        "-66.866",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-4",
        "-66.889",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-4",
        "-66.912",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-4",
        "-66.934",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-4",
        "-66.956",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-4",
        "-66.977",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-4",
        "-66.997",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-4",
        "-18.038",
        "7.076",
        "11.034",
        "8.953",
        "-0.050"
    ],
    [
        "-103",
        "-4",
        "31.368",
        "14.716",
        "7.964",
        "10.708",
        "0.125"
    ],
    [
        "-102",
        "-4",
        "54.503",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-4",
        "54.506",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-4",
        "54.510",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-4",
        "54.510",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-4",
        "54.511",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-4",
        "54.511",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-4",
        "54.510",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-4",
        "54.507",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-4",
        "54.503",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-4",
        "54.498",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-4",
        "54.492",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-4",
        "54.485",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-4",
        "54.477",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-4",
        "54.468",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-4",
        "54.458",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-4",
        "54.447",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-4",
        "54.435",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-4",
        "54.422",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-4",
        "54.408",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-4",
        "54.392",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-4",
        "52.740",
        "14.104",
        "2.006",
        "2.373",
        "0.298"
    ],
    [
        "-81",
        "-4",
        "32.933",
        "11.405",
        "8.107",
        "6.810",
        "0.517"
    ],
    [
        "-80",
        "-4",
        "13.571",
        "8.043",
        "6.009",
        "5.635",
        "0.447"
    ],
    [
        "-79",
        "-4",
        "3.387",
        "6.526",
        "4.170",
        "3.648",
        "0.385"
    ],
    [
        "-78",
        "-4",
        "-0.557",
        "6.536",
        "2.627",
        "2.112",
        "0.247"
    ],
    [
        "-77",
        "-4",
        "-2.744",
        "8.062",
        "0.486",
        "0.529",
        "0.064"
    ],
    [
        "-76",
        "-4",
        "-2.934",
        "8.366",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-4",
        "-2.944",
        "8.525",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-4",
        "-2.954",
        "8.682",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-4",
        "-2.965",
        "8.836",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-4",
        "-2.976",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-4",
        "-2.988",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-4",
        "-2.998",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-4",
        "-3.009",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-4",
        "-3.021",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-4",
        "-3.033",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-4",
        "-3.045",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-4",
        "-3.057",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-4",
        "-3.069",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-4",
        "-3.081",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-4",
        "-3.094",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-4",
        "-3.107",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-4",
        "-3.120",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-4",
        "-3.133",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-4",
        "-3.145",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-4",
        "-3.159",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-4",
        "-3.173",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-4",
        "-3.186",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-4",
        "-3.199",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-4",
        "-3.213",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-4",
        "-3.227",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-4",
        "-3.241",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-4",
        "-3.255",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-4",
        "-3.269",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-4",
        "-3.283",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-4",
        "-3.297",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-4",
        "-3.312",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-4",
        "-3.327",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-4",
        "-3.341",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-4",
        "-3.355",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-4",
        "-3.370",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-4",
        "-3.385",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-4",
        "-3.400",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-4",
        "-3.415",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-4",
        "-3.430",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-4",
        "-3.444",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-4",
        "-3.459",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-4",
        "-3.475",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-4",
        "-3.490",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-4",
        "-3.505",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-4",
        "-3.520",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-4",
        "-3.534",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-4",
        "-3.550",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-4",
        "-3.565",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-4",
        "-3.581",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-4",
        "-3.595",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-4",
        "-3.610",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-4",
        "-3.626",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-4",
        "-3.641",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-4",
        "-3.656",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-4",
        "-3.671",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-4",
        "-3.686",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-4",
        "-3.701",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-4",
        "-3.716",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-4",
        "-3.731",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-4",
        "-3.746",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-4",
        "-3.761",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-4",
        "-3.776",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-4",
        "-3.790",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-4",
        "-3.805",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-4",
        "6.573",
        "14.087",
        "5.490",
        "2.732",
        "0.350"
    ],
    [
        "-11",
        "-4",
        "24.580",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-4",
        "24.557",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-4",
        "24.534",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-4",
        "24.512",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-4",
        "24.489",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-4",
        "24.466",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-4",
        "24.444",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-4",
        "24.421",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-4",
        "24.398",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-4",
        "24.374",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-4",
        "24.350",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-4",
        "24.327",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-4",
        "24.304",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-4",
        "24.280",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-4",
        "24.256",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-4",
        "24.233",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-4",
        "24.209",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-4",
        "24.185",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-4",
        "24.162",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-4",
        "24.138",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-4",
        "24.114",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-4",
        "24.090",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-4",
        "24.066",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-4",
        "24.043",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-4",
        "24.019",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-4",
        "23.996",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-4",
        "23.971",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-4",
        "23.948",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-4",
        "23.924",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-4",
        "23.901",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-4",
        "23.877",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-4",
        "23.854",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-4",
        "23.830",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-4",
        "23.807",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-4",
        "23.783",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-4",
        "23.761",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-4",
        "23.738",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-4",
        "23.715",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-4",
        "23.692",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-4",
        "23.766",
        "18.540",
        "0.847",
        "0.746",
        "-0.256"
    ],
    [
        "29",
        "-4",
        "24.082",
        "18.312",
        "1.308",
        "1.139",
        "-0.209"
    ],
    [
        "30",
        "-4",
        "24.469",
        "18.115",
        "1.611",
        "1.272",
        "-0.146"
    ],
    [
        "31",
        "-4",
        "24.932",
        "17.958",
        "1.638",
        "1.520",
        "-0.051"
    ],
    [
        "32",
        "-4",
        "25.424",
        "17.817",
        "1.699",
        "1.700",
        "0.007"
    ],
    [
        "33",
        "-4",
        "25.863",
        "17.642",
        "1.773",
        "1.679",
        "0.056"
    ],
    [
        "34",
        "-4",
        "26.237",
        "17.414",
        "1.604",
        "1.640",
        "0.091"
    ],
    [
        "35",
        "-4",
        "26.596",
        "17.154",
        "1.511",
        "1.469",
        "0.105"
    ],
    [
        "36",
        "-4",
        "26.976",
        "16.886",
        "1.515",
        "1.314",
        "0.097"
    ],
    [
        "37",
        "-4",
        "27.357",
        "16.619",
        "1.367",
        "1.309",
        "0.070"
    ],
    [
        "38",
        "-4",
        "27.641",
        "16.411",
        "1.239",
        "1.309",
        "0.024"
    ],
    [
        "39",
        "-4",
        "27.728",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-4",
        "27.709",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-4",
        "27.689",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-4",
        "27.670",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-4",
        "27.652",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-4",
        "27.632",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-4",
        "27.614",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-4",
        "27.596",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-4",
        "27.579",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-4",
        "27.561",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-4",
        "27.544",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-4",
        "27.526",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-4",
        "27.510",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-4",
        "27.494",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-4",
        "27.478",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-4",
        "27.462",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-4",
        "27.447",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-4",
        "27.432",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-4",
        "27.417",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-4",
        "27.403",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-4",
        "27.389",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-4",
        "27.375",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-4",
        "27.362",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-4",
        "27.349",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-4",
        "27.337",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-4",
        "27.325",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-4",
        "27.312",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-4",
        "27.301",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-4",
        "27.290",
        "8.970",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-4",
        "28.361",
        "9.615",
        "2.421",
        "2.996",
        "0.418"
    ],
    [
        "69",
        "-4",
        "38.979",
        "24.429",
        "8.997",
        "7.769",
        "0.631"
    ],
    [
        "70",
        "-4",
        "43.950",
        "30.720",
        "4.884",
        "4.803",
        "0.534"
    ],
    [
        "71",
        "-4",
        "45.222",
        "32.482",
        "2.659",
        "2.470",
        "0.412"
    ],
    [
        "72",
        "-4",
        "45.385",
        "33.220",
        "1.713",
        "1.594",
        "0.168"
    ],
    [
        "73",
        "-4",
        "45.393",
        "33.564",
        "1.409",
        "1.494",
        "0.074"
    ],
    [
        "74",
        "-4",
        "45.378",
        "33.789",
        "1.276",
        "1.448",
        "0.053"
    ],
    [
        "75",
        "-4",
        "45.325",
        "34.023",
        "1.222",
        "1.219",
        "-0.007"
    ],
    [
        "76",
        "-4",
        "45.294",
        "34.466",
        "1.125",
        "1.302",
        "-0.019"
    ],
    [
        "77",
        "-4",
        "45.260",
        "34.962",
        "1.150",
        "1.361",
        "0.004"
    ],
    [
        "78",
        "-4",
        "45.218",
        "35.454",
        "1.304",
        "1.310",
        "0.001"
    ],
    [
        "79",
        "-4",
        "45.166",
        "35.933",
        "1.163",
        "1.389",
        "-0.007"
    ],
    [
        "80",
        "-4",
        "45.111",
        "36.393",
        "1.138",
        "1.371",
        "-0.007"
    ],
    [
        "81",
        "-4",
        "45.062",
        "36.849",
        "1.179",
        "1.248",
        "-0.034"
    ],
    [
        "82",
        "-4",
        "45.031",
        "37.343",
        "0.918",
        "1.234",
        "-0.084"
    ],
    [
        "83",
        "-4",
        "45.013",
        "37.882",
        "0.810",
        "1.145",
        "-0.094"
    ],
    [
        "84",
        "-4",
        "44.994",
        "38.390",
        "0.794",
        "1.056",
        "-0.090"
    ],
    [
        "85",
        "-4",
        "44.975",
        "38.848",
        "0.705",
        "1.095",
        "-0.098"
    ],
    [
        "86",
        "-4",
        "44.961",
        "39.294",
        "0.733",
        "1.080",
        "-0.103"
    ],
    [
        "87",
        "-4",
        "44.954",
        "39.763",
        "0.867",
        "0.978",
        "-0.099"
    ],
    [
        "88",
        "-4",
        "44.948",
        "40.212",
        "0.924",
        "0.983",
        "-0.133"
    ],
    [
        "89",
        "-4",
        "44.872",
        "40.317",
        "1.104",
        "1.279",
        "-0.028"
    ],
    [
        "90",
        "-4",
        "44.723",
        "40.599",
        "1.334",
        "1.485",
        "0.023"
    ],
    [
        "91",
        "-4",
        "44.539",
        "41.129",
        "1.419",
        "1.699",
        "0.033"
    ],
    [
        "92",
        "-4",
        "44.302",
        "41.761",
        "1.547",
        "1.849",
        "0.055"
    ],
    [
        "93",
        "-4",
        "43.975",
        "42.407",
        "1.766",
        "1.934",
        "0.079"
    ],
    [
        "94",
        "-4",
        "43.524",
        "43.040",
        "1.831",
        "2.111",
        "0.099"
    ],
    [
        "95",
        "-4",
        "42.928",
        "43.709",
        "2.005",
        "2.299",
        "0.107"
    ],
    [
        "96",
        "-4",
        "42.144",
        "44.336",
        "2.393",
        "2.692",
        "0.077"
    ],
    [
        "97",
        "-4",
        "41.140",
        "44.554",
        "2.860",
        "3.715",
        "0.127"
    ],
    [
        "98",
        "-4",
        "39.763",
        "43.970",
        "3.727",
        "5.508",
        "0.195"
    ],
    [
        "99",
        "-4",
        "37.696",
        "41.617",
        "5.353",
        "7.606",
        "0.320"
    ],
    [
        "100",
        "-4",
        "34.758",
        "36.203",
        "6.140",
        "9.012",
        "0.420"
    ],
    [
        "101",
        "-4",
        "31.840",
        "27.794",
        "6.193",
        "8.539",
        "0.491"
    ],
    [
        "102",
        "-4",
        "29.264",
        "18.071",
        "4.186",
        "5.908",
        "0.442"
    ],
    [
        "103",
        "-4",
        "28.204",
        "1.903",
        "3.699",
        "5.778",
        "0.441"
    ],
    [
        "104",
        "-4",
        "28.417",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-4",
        "28.426",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-4",
        "28.435",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-4",
        "28.445",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-4",
        "28.456",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-4",
        "28.466",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-4",
        "28.478",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-4",
        "28.491",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-4",
        "28.503",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-4",
        "28.517",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-4",
        "28.531",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-4",
        "28.547",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-4",
        "28.562",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-4",
        "28.578",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-4",
        "28.594",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-4",
        "27.154",
        "-13.314",
        "4.285",
        "4.078",
        "-0.283"
    ],
    [
        "120",
        "-4",
        "29.624",
        "-11.128",
        "6.711",
        "5.035",
        "-0.437"
    ],
    [
        "121",
        "-4",
        "22.263",
        "-10.318",
        "5.657",
        "5.980",
        "-0.102"
    ],
    [
        "122",
        "-4",
        "23.018",
        "-4.347",
        "5.606",
        "5.261",
        "-0.355"
    ],
    [
        "123",
        "-4",
        "17.205",
        "4.888",
        "4.483",
        "3.166",
        "-0.238"
    ],
    [
        "124",
        "-4",
        "11.536",
        "8.134",
        "4.492",
        "4.761",
        "-0.011"
    ],
    [
        "125",
        "-4",
        "11.940",
        "15.637",
        "4.524",
        "5.482",
        "0.049"
    ],
    [
        "126",
        "-4",
        "12.855",
        "21.321",
        "4.693",
        "5.957",
        "0.080"
    ],
    [
        "127",
        "-4",
        "14.110",
        "26.011",
        "4.441",
        "6.594",
        "0.111"
    ],
    [
        "128",
        "-4",
        "17.679",
        "23.496",
        "3.419",
        "3.729",
        "0.075"
    ],
    [
        "129",
        "-4",
        "16.208",
        "31.271",
        "5.796",
        "6.745",
        "0.380"
    ],
    [
        "130",
        "-4",
        "14.201",
        "37.646",
        "6.150",
        "7.023",
        "0.464"
    ],
    [
        "131",
        "-4",
        "12.134",
        "42.978",
        "6.026",
        "6.489",
        "0.509"
    ],
    [
        "132",
        "-4",
        "9.524",
        "45.292",
        "5.369",
        "5.029",
        "0.557"
    ],
    [
        "133",
        "-4",
        "5.897",
        "45.243",
        "3.628",
        "3.295",
        "0.454"
    ],
    [
        "134",
        "-4",
        "8.996",
        "50.088",
        "2.572",
        "1.885",
        "0.463"
    ],
    [
        "135",
        "-4",
        "21.079",
        "49.759",
        "2.442",
        "1.977",
        "0.395"
    ],
    [
        "136",
        "-4",
        "27.163",
        "51.929",
        "2.394",
        "2.416",
        "0.386"
    ],
    [
        "137",
        "-4",
        "28.144",
        "51.952",
        "2.929",
        "3.157",
        "0.405"
    ],
    [
        "138",
        "-4",
        "28.180",
        "52.555",
        "3.700",
        "4.596",
        "0.439"
    ],
    [
        "139",
        "-4",
        "30.119",
        "52.225",
        "1.566",
        "1.305",
        "0.184"
    ],
    [
        "140",
        "-4",
        "24.710",
        "51.280",
        "5.288",
        "6.641",
        "0.339"
    ],
    [
        "141",
        "-4",
        "20.428",
        "47.729",
        "6.438",
        "6.751",
        "0.326"
    ],
    [
        "142",
        "-4",
        "16.865",
        "44.947",
        "6.382",
        "6.499",
        "0.251"
    ],
    [
        "143",
        "-4",
        "13.867",
        "40.015",
        "6.248",
        "5.912",
        "0.222"
    ],
    [
        "144",
        "-4",
        "12.130",
        "35.302",
        "6.082",
        "5.343",
        "0.217"
    ],
    [
        "145",
        "-4",
        "12.344",
        "32.620",
        "5.698",
        "5.052",
        "0.190"
    ],
    [
        "146",
        "-4",
        "14.070",
        "29.250",
        "5.674",
        "5.464",
        "0.116"
    ],
    [
        "147",
        "-4",
        "15.804",
        "21.769",
        "5.980",
        "5.816",
        "0.073"
    ],
    [
        "148",
        "-4",
        "15.170",
        "10.994",
        "5.975",
        "6.616",
        "0.064"
    ],
    [
        "149",
        "-4",
        "9.156",
        "-1.620",
        "6.120",
        "6.252",
        "0.002"
    ],
    [
        "150",
        "-4",
        "-1.148",
        "-5.675",
        "6.882",
        "5.661",
        "-0.125"
    ],
    [
        "151",
        "-4",
        "-8.904",
        "-14.247",
        "6.489",
        "5.853",
        "-0.239"
    ],
    [
        "152",
        "-4",
        "2.004",
        "-65.271",
        "4.771",
        "3.880",
        "-0.104"
    ],
    [
        "153",
        "-4",
        "-35.161",
        "0.504",
        "7.136",
        "4.551",
        "-0.205"
    ],
    [
        "154",
        "-4",
        "-62.009",
        "21.813",
        "1.508",
        "1.831",
        "-0.027"
    ],
    [
        "155",
        "-4",
        "-63.729",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-4",
        "-63.756",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-4",
        "-63.785",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-4",
        "-63.813",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-4",
        "-63.843",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-4",
        "-63.872",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-4",
        "-63.902",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-4",
        "-63.932",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-4",
        "-63.963",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-4",
        "-63.994",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-4",
        "-64.026",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-4",
        "-64.058",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-4",
        "-64.090",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-4",
        "-64.122",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-4",
        "-64.155",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-4",
        "-64.188",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-4",
        "-64.222",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-4",
        "-64.255",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-4",
        "-64.289",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-4",
        "-64.324",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-4",
        "-64.358",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-4",
        "-64.393",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-4",
        "-64.428",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-4",
        "-64.463",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-4",
        "-64.499",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-3",
        "-64.766",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-3",
        "-64.793",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-3",
        "-64.820",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-3",
        "-64.848",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-3",
        "-64.875",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-3",
        "-64.902",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-3",
        "-64.930",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-3",
        "-64.958",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-3",
        "-64.985",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-3",
        "-65.013",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-3",
        "-65.041",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-3",
        "-65.069",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-3",
        "-65.097",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-3",
        "-65.125",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-3",
        "-65.153",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-3",
        "-65.181",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-3",
        "-65.209",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-3",
        "-65.238",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-3",
        "-65.266",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-3",
        "-65.294",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-3",
        "-65.322",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-3",
        "-65.350",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-3",
        "-65.378",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-3",
        "-65.406",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-3",
        "-65.434",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-3",
        "-65.462",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-3",
        "-65.490",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-3",
        "-65.518",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-3",
        "-65.546",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-3",
        "-65.574",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-3",
        "-65.601",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-3",
        "-65.629",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-3",
        "-65.656",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-3",
        "-65.683",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-3",
        "-65.710",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-3",
        "-65.737",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-3",
        "-65.764",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-3",
        "-65.791",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-3",
        "-65.817",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-3",
        "-65.844",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-3",
        "-65.870",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-3",
        "-65.896",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-3",
        "-65.921",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-3",
        "-65.947",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-3",
        "-65.972",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-3",
        "-65.997",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-3",
        "-66.022",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-3",
        "-66.047",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-3",
        "-66.071",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-3",
        "-66.096",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-3",
        "-66.119",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-3",
        "-66.143",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-3",
        "-66.166",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-3",
        "-66.190",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-3",
        "-66.212",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-3",
        "-66.235",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-3",
        "-66.257",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-3",
        "-66.279",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-3",
        "-66.301",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-3",
        "-66.322",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-3",
        "-66.343",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-3",
        "-66.364",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-3",
        "-66.384",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-3",
        "-66.404",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-3",
        "-66.423",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-3",
        "-66.443",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-3",
        "-66.462",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-3",
        "-66.480",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-3",
        "-66.498",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-3",
        "-66.516",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-3",
        "-66.533",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-3",
        "-66.550",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-3",
        "-66.567",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-3",
        "-66.583",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-3",
        "-66.599",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-3",
        "-66.614",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-3",
        "-66.629",
        "16.157",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-3",
        "-55.096",
        "12.628",
        "2.989",
        "6.797",
        "0.052"
    ],
    [
        "-102",
        "-3",
        "53.689",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-3",
        "53.691",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-3",
        "53.694",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-3",
        "53.695",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-3",
        "53.695",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-3",
        "53.695",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-3",
        "53.693",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-3",
        "53.691",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-3",
        "53.689",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-3",
        "53.686",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-3",
        "53.681",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-3",
        "53.676",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-3",
        "53.670",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-3",
        "53.663",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-3",
        "53.656",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-3",
        "53.648",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-3",
        "53.638",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-3",
        "53.628",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-3",
        "53.617",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-3",
        "53.606",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-3",
        "53.594",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-3",
        "32.339",
        "9.995",
        "7.730",
        "6.083",
        "0.472"
    ],
    [
        "-80",
        "-3",
        "11.071",
        "6.343",
        "6.014",
        "5.588",
        "0.418"
    ],
    [
        "-79",
        "-3",
        "2.638",
        "5.627",
        "4.164",
        "3.553",
        "0.320"
    ],
    [
        "-78",
        "-3",
        "-0.704",
        "6.079",
        "2.641",
        "1.979",
        "0.194"
    ],
    [
        "-77",
        "-3",
        "-2.944",
        "8.160",
        "0.494",
        "0.523",
        "-0.041"
    ],
    [
        "-76",
        "-3",
        "-3.098",
        "8.366",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-3",
        "-3.106",
        "8.525",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-3",
        "-3.114",
        "8.682",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-3",
        "-3.121",
        "8.836",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-3",
        "-3.129",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-3",
        "-3.138",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-3",
        "-3.147",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-3",
        "-3.156",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-3",
        "-3.164",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-3",
        "-3.172",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-3",
        "-3.181",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-3",
        "-3.191",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-3",
        "-3.200",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-3",
        "-3.209",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-3",
        "-3.219",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-3",
        "-3.228",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-3",
        "-3.238",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-3",
        "-3.247",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-3",
        "-3.257",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-3",
        "-3.267",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-3",
        "-3.277",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-3",
        "-3.288",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-3",
        "-3.297",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-3",
        "-3.308",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-3",
        "-3.318",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-3",
        "-3.328",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-3",
        "-3.339",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-3",
        "-3.350",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-3",
        "-3.360",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-3",
        "-3.371",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-3",
        "-3.382",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-3",
        "-3.392",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-3",
        "-3.403",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-3",
        "-3.414",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-3",
        "-3.425",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-3",
        "-3.437",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-3",
        "-3.448",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-3",
        "-3.459",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-3",
        "-3.470",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-3",
        "-3.481",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-3",
        "-3.492",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-3",
        "-3.503",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-3",
        "-3.515",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-3",
        "-3.526",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-3",
        "-3.537",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-3",
        "-3.549",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-3",
        "-3.560",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-3",
        "-3.572",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-3",
        "-3.583",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-3",
        "-3.595",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-3",
        "-3.606",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-3",
        "-3.618",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-3",
        "-3.629",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-3",
        "-3.640",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-3",
        "-3.651",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-3",
        "-3.662",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-3",
        "-3.674",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-3",
        "-3.686",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-3",
        "-3.696",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-3",
        "-3.708",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-3",
        "-3.718",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-3",
        "-3.730",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-3",
        "-3.741",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-3",
        "-3.752",
        "12.009",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "-3",
        "10.561",
        "14.892",
        "5.508",
        "2.974",
        "0.427"
    ],
    [
        "-11",
        "-3",
        "24.484",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-3",
        "24.468",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-3",
        "24.451",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-3",
        "24.434",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-3",
        "24.417",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-3",
        "24.400",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-3",
        "24.383",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-3",
        "24.366",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-3",
        "24.348",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-3",
        "24.330",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-3",
        "24.313",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-3",
        "24.296",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-3",
        "24.278",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-3",
        "24.260",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-3",
        "24.242",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-3",
        "24.224",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-3",
        "24.207",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-3",
        "24.189",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-3",
        "24.171",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-3",
        "24.154",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-3",
        "24.136",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-3",
        "24.117",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-3",
        "24.099",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-3",
        "24.081",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-3",
        "24.065",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-3",
        "24.047",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-3",
        "24.029",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-3",
        "24.011",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-3",
        "23.993",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-3",
        "23.975",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-3",
        "23.957",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-3",
        "23.940",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-3",
        "23.923",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-3",
        "23.905",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-3",
        "23.888",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-3",
        "23.870",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-3",
        "23.853",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-3",
        "23.836",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-3",
        "23.819",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-3",
        "23.870",
        "18.551",
        "0.799",
        "0.717",
        "-0.266"
    ],
    [
        "29",
        "-3",
        "24.160",
        "18.354",
        "1.250",
        "1.079",
        "-0.210"
    ],
    [
        "30",
        "-3",
        "24.556",
        "18.149",
        "1.566",
        "1.204",
        "-0.144"
    ],
    [
        "31",
        "-3",
        "25.062",
        "17.953",
        "1.595",
        "1.439",
        "-0.037"
    ],
    [
        "32",
        "-3",
        "25.603",
        "17.786",
        "1.643",
        "1.622",
        "0.021"
    ],
    [
        "33",
        "-3",
        "26.082",
        "17.623",
        "1.689",
        "1.628",
        "0.049"
    ],
    [
        "34",
        "-3",
        "26.486",
        "17.421",
        "1.521",
        "1.630",
        "0.085"
    ],
    [
        "35",
        "-3",
        "26.867",
        "17.192",
        "1.428",
        "1.494",
        "0.086"
    ],
    [
        "36",
        "-3",
        "27.264",
        "16.937",
        "1.400",
        "1.322",
        "0.066"
    ],
    [
        "37",
        "-3",
        "27.721",
        "16.637",
        "1.084",
        "1.199",
        "0.080"
    ],
    [
        "38",
        "-3",
        "27.940",
        "16.401",
        "0.874",
        "0.947",
        "0.053"
    ],
    [
        "39",
        "-3",
        "27.957",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-3",
        "27.942",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-3",
        "27.928",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-3",
        "27.914",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-3",
        "27.899",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-3",
        "27.886",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-3",
        "27.872",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-3",
        "27.858",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-3",
        "27.844",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-3",
        "27.832",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-3",
        "27.819",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-3",
        "27.806",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-3",
        "27.794",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-3",
        "27.782",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-3",
        "27.769",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-3",
        "27.758",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-3",
        "27.746",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-3",
        "27.735",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-3",
        "27.725",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-3",
        "27.713",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-3",
        "27.702",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-3",
        "27.692",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-3",
        "27.683",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-3",
        "27.673",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-3",
        "27.663",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-3",
        "27.654",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-3",
        "27.645",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-3",
        "27.637",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-3",
        "27.629",
        "8.970",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-3",
        "29.149",
        "10.511",
        "2.201",
        "3.333",
        "0.451"
    ],
    [
        "69",
        "-3",
        "40.622",
        "27.925",
        "8.154",
        "8.088",
        "0.619"
    ],
    [
        "70",
        "-3",
        "44.143",
        "31.877",
        "4.491",
        "4.764",
        "0.493"
    ],
    [
        "71",
        "-3",
        "44.883",
        "33.083",
        "2.553",
        "2.402",
        "0.394"
    ],
    [
        "72",
        "-3",
        "44.964",
        "33.610",
        "1.668",
        "1.460",
        "0.147"
    ],
    [
        "73",
        "-3",
        "44.951",
        "33.815",
        "1.379",
        "1.382",
        "0.046"
    ],
    [
        "74",
        "-3",
        "44.924",
        "33.965",
        "1.323",
        "1.394",
        "0.028"
    ],
    [
        "75",
        "-3",
        "44.890",
        "34.154",
        "1.425",
        "1.367",
        "0.025"
    ],
    [
        "76",
        "-3",
        "44.869",
        "34.450",
        "1.422",
        "1.508",
        "0.006"
    ],
    [
        "77",
        "-3",
        "44.849",
        "34.810",
        "1.493",
        "1.605",
        "0.010"
    ],
    [
        "78",
        "-3",
        "44.810",
        "35.174",
        "1.649",
        "1.583",
        "0.005"
    ],
    [
        "79",
        "-3",
        "44.751",
        "35.518",
        "1.546",
        "1.658",
        "-0.003"
    ],
    [
        "80",
        "-3",
        "44.683",
        "35.832",
        "1.500",
        "1.624",
        "-0.014"
    ],
    [
        "81",
        "-3",
        "44.629",
        "36.155",
        "1.479",
        "1.486",
        "-0.029"
    ],
    [
        "82",
        "-3",
        "44.624",
        "36.580",
        "1.214",
        "1.451",
        "-0.050"
    ],
    [
        "83",
        "-3",
        "44.674",
        "37.116",
        "1.087",
        "1.361",
        "-0.059"
    ],
    [
        "84",
        "-3",
        "44.748",
        "37.627",
        "1.054",
        "1.282",
        "-0.050"
    ],
    [
        "85",
        "-3",
        "44.814",
        "38.064",
        "0.974",
        "1.314",
        "-0.061"
    ],
    [
        "86",
        "-3",
        "44.873",
        "38.497",
        "1.007",
        "1.305",
        "-0.090"
    ],
    [
        "87",
        "-3",
        "44.925",
        "39.007",
        "1.135",
        "1.224",
        "-0.097"
    ],
    [
        "88",
        "-3",
        "44.938",
        "39.553",
        "1.173",
        "1.223",
        "-0.112"
    ],
    [
        "89",
        "-3",
        "44.857",
        "39.946",
        "1.256",
        "1.297",
        "-0.059"
    ],
    [
        "90",
        "-3",
        "44.706",
        "40.307",
        "1.384",
        "1.448",
        "0.017"
    ],
    [
        "91",
        "-3",
        "44.554",
        "40.816",
        "1.420",
        "1.645",
        "0.040"
    ],
    [
        "92",
        "-3",
        "44.382",
        "41.405",
        "1.515",
        "1.798",
        "0.066"
    ],
    [
        "93",
        "-3",
        "44.145",
        "41.997",
        "1.717",
        "1.923",
        "0.095"
    ],
    [
        "94",
        "-3",
        "43.789",
        "42.539",
        "1.845",
        "2.172",
        "0.107"
    ],
    [
        "95",
        "-3",
        "43.243",
        "42.998",
        "2.161",
        "2.607",
        "0.098"
    ],
    [
        "96",
        "-3",
        "42.401",
        "43.141",
        "2.914",
        "3.616",
        "0.048"
    ],
    [
        "97",
        "-3",
        "41.215",
        "42.561",
        "3.719",
        "5.378",
        "0.106"
    ],
    [
        "98",
        "-3",
        "39.512",
        "40.822",
        "4.610",
        "7.401",
        "0.157"
    ],
    [
        "99",
        "-3",
        "37.480",
        "37.882",
        "5.646",
        "8.698",
        "0.253"
    ],
    [
        "100",
        "-3",
        "36.580",
        "36.317",
        "4.103",
        "6.380",
        "0.245"
    ],
    [
        "101",
        "-3",
        "33.067",
        "23.549",
        "4.760",
        "7.143",
        "0.355"
    ],
    [
        "102",
        "-3",
        "30.151",
        "8.833",
        "4.359",
        "5.877",
        "0.524"
    ],
    [
        "103",
        "-3",
        "28.961",
        "-6.306",
        "0.870",
        "1.527",
        "0.360"
    ],
    [
        "104",
        "-3",
        "28.974",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-3",
        "28.980",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-3",
        "28.987",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-3",
        "28.995",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-3",
        "29.003",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-3",
        "29.011",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-3",
        "29.020",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-3",
        "29.029",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-3",
        "29.039",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-3",
        "29.050",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-3",
        "29.060",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-3",
        "29.071",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-3",
        "29.083",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-3",
        "29.094",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-3",
        "29.107",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-3",
        "28.152",
        "-13.614",
        "4.620",
        "5.564",
        "-0.120"
    ],
    [
        "120",
        "-3",
        "24.232",
        "-9.489",
        "6.464",
        "6.380",
        "-0.070"
    ],
    [
        "121",
        "-3",
        "21.523",
        "-5.580",
        "5.589",
        "6.975",
        "-0.173"
    ],
    [
        "122",
        "-3",
        "11.099",
        "-0.979",
        "5.883",
        "6.702",
        "-0.056"
    ],
    [
        "123",
        "-3",
        "5.028",
        "5.042",
        "5.385",
        "5.064",
        "-0.125"
    ],
    [
        "124",
        "-3",
        "2.171",
        "8.924",
        "4.822",
        "4.902",
        "-0.080"
    ],
    [
        "125",
        "-3",
        "1.265",
        "12.585",
        "5.131",
        "5.756",
        "0.032"
    ],
    [
        "126",
        "-3",
        "1.136",
        "17.209",
        "5.724",
        "5.660",
        "0.154"
    ],
    [
        "127",
        "-3",
        "1.034",
        "19.945",
        "5.993",
        "7.190",
        "0.197"
    ],
    [
        "128",
        "-3",
        "1.656",
        "17.936",
        "6.318",
        "6.430",
        "0.338"
    ],
    [
        "129",
        "-3",
        "-0.333",
        "26.988",
        "6.920",
        "7.302",
        "0.381"
    ],
    [
        "130",
        "-3",
        "-2.489",
        "34.282",
        "6.613",
        "7.404",
        "0.443"
    ],
    [
        "131",
        "-3",
        "-6.699",
        "36.367",
        "5.897",
        "6.399",
        "0.499"
    ],
    [
        "132",
        "-3",
        "-15.681",
        "31.464",
        "3.524",
        "3.537",
        "0.367"
    ],
    [
        "133",
        "-3",
        "-10.251",
        "42.686",
        "3.320",
        "3.451",
        "0.390"
    ],
    [
        "134",
        "-3",
        "-2.977",
        "43.484",
        "3.438",
        "2.941",
        "0.362"
    ],
    [
        "135",
        "-3",
        "1.954",
        "42.781",
        "3.553",
        "2.914",
        "0.256"
    ],
    [
        "136",
        "-3",
        "5.623",
        "42.409",
        "3.818",
        "3.667",
        "0.293"
    ],
    [
        "137",
        "-3",
        "6.118",
        "40.714",
        "4.541",
        "5.003",
        "0.333"
    ],
    [
        "138",
        "-3",
        "6.178",
        "43.818",
        "5.910",
        "6.622",
        "0.445"
    ],
    [
        "139",
        "-3",
        "6.912",
        "44.542",
        "6.759",
        "8.061",
        "0.407"
    ],
    [
        "140",
        "-3",
        "6.552",
        "44.002",
        "7.312",
        "8.968",
        "0.322"
    ],
    [
        "141",
        "-3",
        "5.664",
        "40.532",
        "7.436",
        "8.118",
        "0.255"
    ],
    [
        "142",
        "-3",
        "-1.296",
        "35.988",
        "6.854",
        "7.750",
        "0.180"
    ],
    [
        "143",
        "-3",
        "-9.784",
        "31.758",
        "6.475",
        "6.316",
        "0.174"
    ],
    [
        "144",
        "-3",
        "-15.432",
        "28.151",
        "6.044",
        "4.969",
        "0.191"
    ],
    [
        "145",
        "-3",
        "-17.468",
        "26.379",
        "5.485",
        "4.252",
        "0.167"
    ],
    [
        "146",
        "-3",
        "-16.972",
        "24.046",
        "5.372",
        "4.245",
        "0.104"
    ],
    [
        "147",
        "-3",
        "-16.993",
        "20.131",
        "5.522",
        "4.129",
        "0.025"
    ],
    [
        "148",
        "-3",
        "-20.853",
        "14.576",
        "5.387",
        "4.658",
        "-0.032"
    ],
    [
        "149",
        "-3",
        "-28.545",
        "10.605",
        "5.205",
        "4.466",
        "-0.110"
    ],
    [
        "150",
        "-3",
        "-36.783",
        "10.294",
        "5.226",
        "3.997",
        "-0.154"
    ],
    [
        "151",
        "-3",
        "-46.800",
        "16.069",
        "4.175",
        "3.752",
        "-0.215"
    ],
    [
        "152",
        "-3",
        "-60.138",
        "17.737",
        "4.271",
        "3.702",
        "-0.289"
    ],
    [
        "153",
        "-3",
        "-67.823",
        "13.854",
        "4.451",
        "3.028",
        "-0.130"
    ],
    [
        "154",
        "-3",
        "-64.141",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-3",
        "-64.162",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-3",
        "-64.183",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-3",
        "-64.204",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-3",
        "-64.226",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-3",
        "-64.247",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-3",
        "-64.270",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-3",
        "-64.292",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-3",
        "-64.315",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-3",
        "-64.338",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-3",
        "-64.361",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-3",
        "-64.385",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-3",
        "-64.409",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-3",
        "-64.433",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-3",
        "-64.457",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-3",
        "-64.482",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-3",
        "-64.507",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-3",
        "-64.532",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-3",
        "-64.557",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-3",
        "-64.583",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-3",
        "-64.608",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-3",
        "-64.634",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-3",
        "-64.660",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-3",
        "-64.687",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-3",
        "-64.713",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-3",
        "-64.740",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-2",
        "-64.979",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-2",
        "-64.997",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-2",
        "-65.015",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-2",
        "-65.033",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-2",
        "-65.051",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-2",
        "-65.069",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-2",
        "-65.088",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-2",
        "-65.106",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-2",
        "-65.125",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-2",
        "-65.143",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-2",
        "-65.162",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-2",
        "-65.180",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-2",
        "-65.199",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-2",
        "-65.218",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-2",
        "-65.237",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-2",
        "-65.255",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-2",
        "-65.274",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-2",
        "-65.293",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-2",
        "-65.312",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-2",
        "-65.330",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-2",
        "-65.349",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-2",
        "-65.368",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-2",
        "-65.387",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-2",
        "-65.405",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-2",
        "-65.424",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-2",
        "-65.443",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-2",
        "-65.461",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-2",
        "-65.480",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-2",
        "-65.499",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-2",
        "-65.517",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-2",
        "-65.535",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-2",
        "-65.554",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-2",
        "-65.572",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-2",
        "-65.590",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-2",
        "-65.608",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-2",
        "-65.626",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-2",
        "-65.644",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-2",
        "-65.662",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-2",
        "-65.679",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-2",
        "-65.697",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-2",
        "-65.714",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-2",
        "-65.732",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-2",
        "-65.749",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-2",
        "-65.766",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-2",
        "-65.783",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-2",
        "-65.800",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-2",
        "-65.816",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-2",
        "-65.833",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-2",
        "-65.849",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-2",
        "-65.865",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-2",
        "-65.881",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-2",
        "-65.897",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-2",
        "-65.912",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-2",
        "-65.928",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-2",
        "-65.943",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-2",
        "-65.958",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-2",
        "-65.973",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-2",
        "-65.987",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-2",
        "-66.002",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-2",
        "-66.016",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-2",
        "-66.030",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-2",
        "-66.044",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-2",
        "-66.057",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-2",
        "-66.071",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-2",
        "-66.084",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-2",
        "-66.096",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-2",
        "-66.109",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-2",
        "-66.121",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-2",
        "-66.134",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-2",
        "-66.145",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-2",
        "-66.157",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-2",
        "-66.168",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-2",
        "-66.179",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-2",
        "-66.190",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-2",
        "-66.201",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-2",
        "-66.211",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-2",
        "-66.221",
        "16.157",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-2",
        "-56.044",
        "6.699",
        "3.614",
        "6.693",
        "0.025"
    ],
    [
        "-102",
        "-2",
        "52.859",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-2",
        "52.861",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-2",
        "52.862",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-2",
        "52.862",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-2",
        "52.863",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-2",
        "52.862",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-2",
        "52.862",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-2",
        "52.860",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-2",
        "52.859",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-2",
        "52.856",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-2",
        "52.853",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-2",
        "52.850",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-2",
        "52.846",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-2",
        "52.841",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-2",
        "52.836",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-2",
        "52.831",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-2",
        "52.825",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-2",
        "52.818",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-2",
        "52.811",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-2",
        "52.803",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-2",
        "52.796",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-2",
        "31.097",
        "10.267",
        "8.902",
        "5.788",
        "0.376"
    ],
    [
        "-80",
        "-2",
        "10.721",
        "7.382",
        "6.620",
        "5.242",
        "0.359"
    ],
    [
        "-79",
        "-2",
        "1.824",
        "3.696",
        "4.182",
        "3.008",
        "0.171"
    ],
    [
        "-78",
        "-2",
        "-1.756",
        "6.317",
        "2.800",
        "1.752",
        "0.166"
    ],
    [
        "-77",
        "-2",
        "-3.214",
        "8.196",
        "0.536",
        "0.531",
        "-0.060"
    ],
    [
        "-76",
        "-2",
        "-3.261",
        "8.366",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-2",
        "-3.266",
        "8.525",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-2",
        "-3.272",
        "8.682",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-2",
        "-3.277",
        "8.836",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-2",
        "-3.282",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-2",
        "-3.288",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-2",
        "-3.293",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-2",
        "-3.300",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-2",
        "-3.305",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-2",
        "-3.312",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-2",
        "-3.317",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-2",
        "-3.323",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-2",
        "-3.329",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-2",
        "-3.336",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-2",
        "-3.342",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-2",
        "-3.348",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-2",
        "-3.354",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-2",
        "-3.361",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-2",
        "-3.368",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-2",
        "-3.374",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-2",
        "-3.381",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-2",
        "-3.387",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-2",
        "-3.394",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-2",
        "-3.401",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-2",
        "-3.408",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-2",
        "-3.415",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-2",
        "-3.422",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-2",
        "-3.429",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-2",
        "-3.436",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-2",
        "-3.443",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-2",
        "-3.451",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-2",
        "-3.458",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-2",
        "-3.465",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-2",
        "-3.473",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-2",
        "-3.480",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-2",
        "-3.488",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-2",
        "-3.495",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-2",
        "-3.503",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-2",
        "-3.509",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-2",
        "-3.517",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-2",
        "-3.524",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-2",
        "-3.532",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-2",
        "-3.540",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-2",
        "-3.547",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-2",
        "-3.555",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-2",
        "-3.562",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-2",
        "-3.570",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-2",
        "-3.577",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-2",
        "-3.585",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-2",
        "-3.593",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-2",
        "-3.600",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-2",
        "-3.608",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "-2",
        "-3.616",
        "12.413",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "-2",
        "-3.622",
        "12.395",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "-2",
        "-3.630",
        "12.374",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "-2",
        "-3.637",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-2",
        "-3.646",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-2",
        "-3.653",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-2",
        "-3.660",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-2",
        "-3.668",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-2",
        "-3.676",
        "12.164",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "-2",
        "-3.683",
        "12.116",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "-2",
        "-3.690",
        "12.065",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "-2",
        "-1.864",
        "12.963",
        "1.417",
        "1.383",
        "0.241"
    ],
    [
        "-12",
        "-2",
        "24.393",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-2",
        "24.383",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-2",
        "24.371",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-2",
        "24.360",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-2",
        "24.349",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-2",
        "24.337",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-2",
        "24.326",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-2",
        "24.314",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-2",
        "24.303",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-2",
        "24.291",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-2",
        "24.279",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-2",
        "24.268",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-2",
        "24.256",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-2",
        "24.245",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-2",
        "24.233",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-2",
        "24.221",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-2",
        "24.209",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-2",
        "24.197",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-2",
        "24.185",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-2",
        "24.173",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-2",
        "24.162",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-2",
        "24.149",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-2",
        "24.138",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-2",
        "24.125",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-2",
        "24.114",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-2",
        "24.102",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-2",
        "24.090",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-2",
        "24.078",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-2",
        "24.066",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-2",
        "24.055",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-2",
        "24.043",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-2",
        "24.030",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-2",
        "24.019",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-2",
        "24.007",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-2",
        "23.996",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-2",
        "23.984",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-2",
        "23.972",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-2",
        "23.961",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-2",
        "23.949",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-2",
        "23.938",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-2",
        "23.926",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-2",
        "24.174",
        "18.408",
        "1.157",
        "0.985",
        "-0.177"
    ],
    [
        "30",
        "-2",
        "24.582",
        "18.177",
        "1.501",
        "1.121",
        "-0.131"
    ],
    [
        "31",
        "-2",
        "25.149",
        "17.894",
        "1.553",
        "1.355",
        "-0.015"
    ],
    [
        "32",
        "-2",
        "25.694",
        "17.703",
        "1.640",
        "1.579",
        "0.026"
    ],
    [
        "33",
        "-2",
        "26.188",
        "17.553",
        "1.713",
        "1.630",
        "0.040"
    ],
    [
        "34",
        "-2",
        "26.645",
        "17.387",
        "1.536",
        "1.682",
        "0.072"
    ],
    [
        "35",
        "-2",
        "27.083",
        "17.204",
        "1.434",
        "1.586",
        "0.069"
    ],
    [
        "36",
        "-2",
        "27.553",
        "16.959",
        "1.365",
        "1.355",
        "0.078"
    ],
    [
        "37",
        "-2",
        "28.133",
        "16.614",
        "0.297",
        "0.393",
        "0.026"
    ],
    [
        "38",
        "-2",
        "28.187",
        "16.415",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-2",
        "28.178",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-2",
        "28.167",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-2",
        "28.158",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-2",
        "28.149",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-2",
        "28.139",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-2",
        "28.130",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-2",
        "28.120",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-2",
        "28.111",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-2",
        "28.103",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-2",
        "28.094",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-2",
        "28.085",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-2",
        "28.077",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-2",
        "28.069",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-2",
        "28.060",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-2",
        "28.053",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-2",
        "28.045",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-2",
        "28.037",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-2",
        "28.030",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-2",
        "28.022",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-2",
        "28.015",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-2",
        "28.008",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-2",
        "28.001",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-2",
        "27.995",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-2",
        "27.988",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-2",
        "27.982",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-2",
        "27.976",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-2",
        "27.969",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-2",
        "27.964",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-2",
        "27.958",
        "8.970",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-2",
        "30.975",
        "13.818",
        "2.188",
        "3.553",
        "0.433"
    ],
    [
        "69",
        "-2",
        "43.445",
        "31.293",
        "7.240",
        "7.161",
        "0.594"
    ],
    [
        "70",
        "-2",
        "44.575",
        "33.297",
        "3.694",
        "4.050",
        "0.425"
    ],
    [
        "71",
        "-2",
        "44.636",
        "33.735",
        "2.076",
        "1.973",
        "0.392"
    ],
    [
        "72",
        "-2",
        "44.644",
        "33.962",
        "1.237",
        "1.134",
        "0.135"
    ],
    [
        "73",
        "-2",
        "44.629",
        "34.021",
        "1.028",
        "1.173",
        "0.030"
    ],
    [
        "74",
        "-2",
        "44.623",
        "34.081",
        "1.145",
        "1.285",
        "0.034"
    ],
    [
        "75",
        "-2",
        "44.615",
        "34.212",
        "1.321",
        "1.322",
        "0.030"
    ],
    [
        "76",
        "-2",
        "44.610",
        "34.412",
        "1.386",
        "1.504",
        "0.010"
    ],
    [
        "77",
        "-2",
        "44.595",
        "34.657",
        "1.499",
        "1.635",
        "0.013"
    ],
    [
        "78",
        "-2",
        "44.555",
        "34.905",
        "1.681",
        "1.633",
        "0.007"
    ],
    [
        "79",
        "-2",
        "44.490",
        "35.127",
        "1.592",
        "1.704",
        "-0.005"
    ],
    [
        "80",
        "-2",
        "44.408",
        "35.311",
        "1.544",
        "1.649",
        "-0.020"
    ],
    [
        "81",
        "-2",
        "44.339",
        "35.502",
        "1.520",
        "1.487",
        "-0.034"
    ],
    [
        "82",
        "-2",
        "44.342",
        "35.824",
        "1.284",
        "1.449",
        "-0.046"
    ],
    [
        "83",
        "-2",
        "44.446",
        "36.316",
        "1.184",
        "1.385",
        "-0.032"
    ],
    [
        "84",
        "-2",
        "44.595",
        "36.826",
        "1.175",
        "1.328",
        "-0.004"
    ],
    [
        "85",
        "-2",
        "44.716",
        "37.286",
        "1.105",
        "1.377",
        "-0.019"
    ],
    [
        "86",
        "-2",
        "44.792",
        "37.763",
        "1.135",
        "1.377",
        "-0.051"
    ],
    [
        "87",
        "-2",
        "44.834",
        "38.333",
        "1.241",
        "1.310",
        "-0.059"
    ],
    [
        "88",
        "-2",
        "44.835",
        "38.966",
        "1.254",
        "1.313",
        "-0.082"
    ],
    [
        "89",
        "-2",
        "44.769",
        "39.526",
        "1.319",
        "1.338",
        "-0.051"
    ],
    [
        "90",
        "-2",
        "44.647",
        "39.991",
        "1.433",
        "1.440",
        "0.015"
    ],
    [
        "91",
        "-2",
        "44.522",
        "40.509",
        "1.464",
        "1.624",
        "0.037"
    ],
    [
        "92",
        "-2",
        "44.398",
        "41.080",
        "1.557",
        "1.781",
        "0.056"
    ],
    [
        "93",
        "-2",
        "44.247",
        "41.645",
        "1.756",
        "1.940",
        "0.081"
    ],
    [
        "94",
        "-2",
        "44.017",
        "42.111",
        "1.944",
        "2.296",
        "0.092"
    ],
    [
        "95",
        "-2",
        "43.581",
        "42.302",
        "2.435",
        "3.118",
        "0.108"
    ],
    [
        "96",
        "-2",
        "42.787",
        "41.833",
        "3.660",
        "4.834",
        "0.093"
    ],
    [
        "97",
        "-2",
        "41.496",
        "40.130",
        "4.803",
        "7.136",
        "0.139"
    ],
    [
        "98",
        "-2",
        "39.498",
        "36.672",
        "5.387",
        "8.770",
        "0.159"
    ],
    [
        "99",
        "-2",
        "36.679",
        "32.599",
        "4.615",
        "7.363",
        "0.246"
    ],
    [
        "100",
        "-2",
        "30.089",
        "20.862",
        "3.526",
        "5.601",
        "0.221"
    ],
    [
        "101",
        "-2",
        "29.815",
        "13.678",
        "3.784",
        "5.930",
        "0.417"
    ],
    [
        "102",
        "-2",
        "29.622",
        "-2.741",
        "2.882",
        "3.894",
        "0.456"
    ],
    [
        "103",
        "-2",
        "29.518",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-2",
        "29.522",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-2",
        "29.526",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-2",
        "29.531",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-2",
        "29.536",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-2",
        "29.541",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-2",
        "29.547",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-2",
        "29.552",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-2",
        "29.559",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-2",
        "29.565",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-2",
        "29.573",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-2",
        "29.580",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-2",
        "29.586",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-2",
        "29.595",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-2",
        "29.603",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-2",
        "29.611",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-2",
        "29.559",
        "-13.594",
        "1.442",
        "3.007",
        "-0.054"
    ],
    [
        "120",
        "-2",
        "22.747",
        "-3.071",
        "6.856",
        "7.302",
        "-0.071"
    ],
    [
        "121",
        "-2",
        "10.929",
        "4.854",
        "5.349",
        "5.216",
        "-0.149"
    ],
    [
        "122",
        "-2",
        "3.388",
        "8.425",
        "5.830",
        "7.265",
        "-0.053"
    ],
    [
        "123",
        "-2",
        "-2.018",
        "8.156",
        "5.503",
        "5.705",
        "-0.012"
    ],
    [
        "124",
        "-2",
        "-5.129",
        "8.317",
        "5.131",
        "5.334",
        "-0.082"
    ],
    [
        "125",
        "-2",
        "-8.970",
        "9.652",
        "5.051",
        "5.795",
        "-0.037"
    ],
    [
        "126",
        "-2",
        "-9.346",
        "15.059",
        "1.797",
        "1.092",
        "-0.038"
    ],
    [
        "127",
        "-2",
        "-17.133",
        "14.501",
        "5.228",
        "6.352",
        "0.069"
    ],
    [
        "128",
        "-2",
        "-18.117",
        "17.945",
        "5.796",
        "5.559",
        "0.226"
    ],
    [
        "129",
        "-2",
        "-19.449",
        "24.788",
        "6.156",
        "6.443",
        "0.272"
    ],
    [
        "130",
        "-2",
        "-20.973",
        "29.895",
        "5.629",
        "6.244",
        "0.355"
    ],
    [
        "131",
        "-2",
        "-22.200",
        "31.562",
        "5.132",
        "5.384",
        "0.431"
    ],
    [
        "132",
        "-2",
        "-21.492",
        "33.281",
        "4.882",
        "4.002",
        "0.499"
    ],
    [
        "133",
        "-2",
        "-17.915",
        "43.126",
        "4.494",
        "3.994",
        "0.396"
    ],
    [
        "134",
        "-2",
        "-13.823",
        "39.684",
        "4.576",
        "3.898",
        "0.227"
    ],
    [
        "135",
        "-2",
        "-8.875",
        "39.668",
        "4.826",
        "4.326",
        "0.144"
    ],
    [
        "136",
        "-2",
        "-1.436",
        "31.895",
        "3.588",
        "4.166",
        "0.050"
    ],
    [
        "137",
        "-2",
        "-3.494",
        "39.014",
        "5.129",
        "6.867",
        "0.228"
    ],
    [
        "138",
        "-2",
        "-9.958",
        "38.781",
        "6.894",
        "8.057",
        "0.396"
    ],
    [
        "139",
        "-2",
        "-17.747",
        "39.095",
        "7.279",
        "8.931",
        "0.375"
    ],
    [
        "140",
        "-2",
        "-29.775",
        "33.230",
        "7.492",
        "7.437",
        "0.288"
    ],
    [
        "141",
        "-2",
        "-42.988",
        "25.031",
        "7.110",
        "7.061",
        "0.224"
    ],
    [
        "142",
        "-2",
        "-48.912",
        "24.678",
        "5.786",
        "6.496",
        "0.118"
    ],
    [
        "143",
        "-2",
        "-51.768",
        "23.172",
        "5.059",
        "5.016",
        "0.131"
    ],
    [
        "144",
        "-2",
        "-53.501",
        "21.672",
        "4.371",
        "3.574",
        "0.148"
    ],
    [
        "145",
        "-2",
        "-54.220",
        "21.073",
        "3.689",
        "2.918",
        "0.129"
    ],
    [
        "146",
        "-2",
        "-54.588",
        "20.576",
        "3.386",
        "2.729",
        "0.087"
    ],
    [
        "147",
        "-2",
        "-57.355",
        "20.645",
        "2.922",
        "2.485",
        "0.033"
    ],
    [
        "148",
        "-2",
        "-57.864",
        "18.019",
        "2.976",
        "2.872",
        "-0.060"
    ],
    [
        "149",
        "-2",
        "-59.082",
        "18.497",
        "3.096",
        "2.750",
        "-0.100"
    ],
    [
        "150",
        "-2",
        "-63.803",
        "20.353",
        "2.868",
        "2.318",
        "-0.095"
    ],
    [
        "151",
        "-2",
        "-62.778",
        "23.598",
        "2.603",
        "2.160",
        "-0.336"
    ],
    [
        "152",
        "-2",
        "-64.811",
        "21.228",
        "1.797",
        "1.733",
        "-0.048"
    ],
    [
        "153",
        "-2",
        "-64.548",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-2",
        "-64.562",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-2",
        "-64.576",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-2",
        "-64.589",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-2",
        "-64.604",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-2",
        "-64.618",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-2",
        "-64.633",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-2",
        "-64.647",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-2",
        "-64.662",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-2",
        "-64.678",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-2",
        "-64.693",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-2",
        "-64.708",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-2",
        "-64.724",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-2",
        "-64.740",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-2",
        "-64.756",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-2",
        "-64.772",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-2",
        "-64.789",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-2",
        "-64.805",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-2",
        "-64.822",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-2",
        "-64.839",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-2",
        "-64.856",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-2",
        "-64.873",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-2",
        "-64.891",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-2",
        "-64.908",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-2",
        "-64.925",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-2",
        "-64.943",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-2",
        "-64.961",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "-1",
        "-65.171",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "-1",
        "-65.180",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "-1",
        "-65.189",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "-1",
        "-65.198",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "-1",
        "-65.207",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "-1",
        "-65.217",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "-1",
        "-65.226",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "-1",
        "-65.235",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "-1",
        "-65.244",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "-1",
        "-65.253",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "-1",
        "-65.263",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "-1",
        "-65.272",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "-1",
        "-65.281",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "-1",
        "-65.291",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "-1",
        "-65.300",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "-1",
        "-65.310",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "-1",
        "-65.319",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "-1",
        "-65.328",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "-1",
        "-65.338",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "-1",
        "-65.347",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "-1",
        "-65.357",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "-1",
        "-65.366",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "-1",
        "-65.375",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "-1",
        "-65.385",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "-1",
        "-65.394",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "-1",
        "-65.403",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "-1",
        "-65.413",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "-1",
        "-65.422",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "-1",
        "-65.431",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "-1",
        "-65.440",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "-1",
        "-65.450",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "-1",
        "-65.459",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "-1",
        "-65.468",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "-1",
        "-65.477",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "-1",
        "-65.486",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "-1",
        "-65.495",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "-1",
        "-65.504",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "-1",
        "-65.513",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "-1",
        "-65.522",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "-1",
        "-65.530",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "-1",
        "-65.539",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "-1",
        "-65.548",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "-1",
        "-65.556",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "-1",
        "-65.565",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "-1",
        "-65.573",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "-1",
        "-65.582",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "-1",
        "-65.590",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "-1",
        "-65.598",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "-1",
        "-65.606",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "-1",
        "-65.614",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "-1",
        "-65.622",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "-1",
        "-65.630",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "-1",
        "-65.638",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "-1",
        "-65.646",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "-1",
        "-65.653",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "-1",
        "-65.661",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "-1",
        "-65.668",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "-1",
        "-65.676",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "-1",
        "-65.683",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "-1",
        "-65.690",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "-1",
        "-65.697",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "-1",
        "-65.704",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "-1",
        "-65.711",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "-1",
        "-65.717",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "-1",
        "-65.724",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "-1",
        "-65.730",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "-1",
        "-65.736",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "-1",
        "-65.743",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "-1",
        "-65.749",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "-1",
        "-65.755",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "-1",
        "-65.760",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "-1",
        "-65.766",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "-1",
        "-65.772",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "-1",
        "-65.777",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "-1",
        "-65.782",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "-1",
        "-65.787",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "-1",
        "-65.792",
        "16.157",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "-1",
        "-59.594",
        "8.767",
        "3.899",
        "5.627",
        "-0.060"
    ],
    [
        "-102",
        "-1",
        "52.012",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "-1",
        "52.013",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "-1",
        "52.014",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "-1",
        "52.015",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "-1",
        "52.015",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "-1",
        "52.015",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "-1",
        "52.014",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "-1",
        "52.013",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "-1",
        "52.013",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "-1",
        "52.011",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "-1",
        "52.010",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "-1",
        "52.008",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "-1",
        "52.006",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "-1",
        "52.003",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "-1",
        "52.002",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "-1",
        "51.998",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "-1",
        "51.995",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "-1",
        "51.992",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "-1",
        "51.989",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "-1",
        "51.985",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "-1",
        "51.980",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "-1",
        "30.622",
        "11.801",
        "8.576",
        "4.600",
        "0.125"
    ],
    [
        "-80",
        "-1",
        "12.274",
        "8.229",
        "7.086",
        "4.888",
        "0.222"
    ],
    [
        "-79",
        "-1",
        "5.379",
        "7.535",
        "4.610",
        "2.508",
        "0.153"
    ],
    [
        "-78",
        "-1",
        "-2.054",
        "8.194",
        "3.118",
        "1.759",
        "0.063"
    ],
    [
        "-77",
        "-1",
        "-3.408",
        "8.192",
        "0.606",
        "0.552",
        "-0.076"
    ],
    [
        "-76",
        "-1",
        "-3.424",
        "8.366",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "-1",
        "-3.427",
        "8.525",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "-1",
        "-3.429",
        "8.682",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "-1",
        "-3.432",
        "8.836",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "-1",
        "-3.434",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "-1",
        "-3.437",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "-1",
        "-3.441",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "-1",
        "-3.443",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "-1",
        "-3.446",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "-1",
        "-3.449",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "-1",
        "-3.452",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "-1",
        "-3.455",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "-1",
        "-3.458",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "-1",
        "-3.461",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "-1",
        "-3.464",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "-1",
        "-3.468",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "-1",
        "-3.471",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "-1",
        "-3.473",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "-1",
        "-3.477",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "-1",
        "-3.481",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "-1",
        "-3.483",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "-1",
        "-3.488",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "-1",
        "-3.491",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "-1",
        "-3.494",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "-1",
        "-3.497",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "-1",
        "-3.501",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "-1",
        "-3.505",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "-1",
        "-3.508",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "-1",
        "-3.511",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "-1",
        "-3.515",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "-1",
        "-3.519",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "-1",
        "-3.522",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "-1",
        "-3.526",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "-1",
        "-3.530",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "-1",
        "-3.533",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "-1",
        "-3.537",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "-1",
        "-3.540",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "-1",
        "-3.544",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "-1",
        "-3.548",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "-1",
        "-3.552",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "-1",
        "-3.555",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "-1",
        "-3.559",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "-1",
        "-3.563",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "-1",
        "-3.567",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "-1",
        "-3.571",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "-1",
        "-3.575",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "-1",
        "-3.578",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "-1",
        "-3.582",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "-1",
        "-3.585",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "-1",
        "-3.590",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "-1",
        "-3.593",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "-1",
        "-3.240",
        "11.992",
        "0.807",
        "1.291",
        "0.079"
    ],
    [
        "-24",
        "-1",
        "4.392",
        "13.186",
        "3.841",
        "2.365",
        "0.196"
    ],
    [
        "-23",
        "-1",
        "7.317",
        "14.009",
        "3.724",
        "2.407",
        "0.223"
    ],
    [
        "-22",
        "-1",
        "3.598",
        "12.943",
        "3.315",
        "2.374",
        "0.219"
    ],
    [
        "-21",
        "-1",
        "-3.613",
        "12.348",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "-1",
        "-3.616",
        "12.318",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "-1",
        "-3.620",
        "12.286",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "-1",
        "-3.623",
        "12.249",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "-1",
        "-3.627",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "-1",
        "-2.528",
        "12.757",
        "0.947",
        "1.534",
        "0.191"
    ],
    [
        "-15",
        "-1",
        "7.023",
        "14.234",
        "4.277",
        "2.700",
        "0.308"
    ],
    [
        "-14",
        "-1",
        "10.461",
        "15.149",
        "4.443",
        "2.562",
        "0.376"
    ],
    [
        "-13",
        "-1",
        "19.664",
        "16.529",
        "3.371",
        "2.524",
        "0.298"
    ],
    [
        "-12",
        "-1",
        "24.278",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "-1",
        "24.273",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "-1",
        "24.267",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "-1",
        "24.261",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "-1",
        "24.256",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "-1",
        "24.250",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "-1",
        "24.245",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "-1",
        "24.238",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "-1",
        "24.233",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "-1",
        "24.227",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "-1",
        "24.222",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "-1",
        "24.216",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "-1",
        "24.209",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "-1",
        "24.203",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "-1",
        "24.197",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "-1",
        "24.192",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "-1",
        "24.186",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "-1",
        "24.180",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "-1",
        "24.174",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "-1",
        "24.168",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "-1",
        "24.162",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "-1",
        "24.156",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "-1",
        "24.150",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "-1",
        "24.144",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "-1",
        "24.139",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "-1",
        "24.132",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "-1",
        "24.126",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "-1",
        "24.121",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "-1",
        "24.114",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "-1",
        "24.109",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "-1",
        "24.103",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "-1",
        "24.096",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "-1",
        "24.091",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "-1",
        "24.085",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "-1",
        "24.079",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "-1",
        "24.074",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "-1",
        "24.067",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "-1",
        "24.062",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "-1",
        "24.056",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "-1",
        "24.050",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "-1",
        "24.044",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "-1",
        "24.085",
        "18.442",
        "0.315",
        "0.359",
        "-0.152"
    ],
    [
        "30",
        "-1",
        "24.582",
        "18.178",
        "1.394",
        "1.011",
        "-0.107"
    ],
    [
        "31",
        "-1",
        "25.251",
        "17.721",
        "1.459",
        "1.240",
        "0.003"
    ],
    [
        "32",
        "-1",
        "25.700",
        "17.656",
        "1.660",
        "1.586",
        "0.001"
    ],
    [
        "33",
        "-1",
        "26.201",
        "17.481",
        "1.808",
        "1.694",
        "0.022"
    ],
    [
        "34",
        "-1",
        "26.703",
        "17.308",
        "1.650",
        "1.799",
        "0.063"
    ],
    [
        "35",
        "-1",
        "27.207",
        "17.138",
        "1.552",
        "1.741",
        "0.069"
    ],
    [
        "36",
        "-1",
        "27.748",
        "16.922",
        "1.488",
        "1.521",
        "0.079"
    ],
    [
        "37",
        "-1",
        "28.340",
        "16.617",
        "0.326",
        "0.470",
        "-0.009"
    ],
    [
        "38",
        "-1",
        "28.395",
        "16.415",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "-1",
        "28.390",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "-1",
        "28.384",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "-1",
        "28.380",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "-1",
        "28.375",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "-1",
        "28.370",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "-1",
        "28.366",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "-1",
        "28.361",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "-1",
        "28.356",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "-1",
        "28.352",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "-1",
        "28.348",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "-1",
        "28.344",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "-1",
        "28.340",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "-1",
        "28.335",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "-1",
        "28.331",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "-1",
        "28.327",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "-1",
        "28.323",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "-1",
        "28.319",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "-1",
        "28.316",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "-1",
        "28.312",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "-1",
        "28.308",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "-1",
        "28.305",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "-1",
        "28.301",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "-1",
        "28.298",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "-1",
        "28.295",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "-1",
        "28.291",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "-1",
        "28.289",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "-1",
        "28.285",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "-1",
        "28.283",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "-1",
        "28.280",
        "8.970",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "-1",
        "44.563",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "-1",
        "44.552",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "-1",
        "44.542",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "-1",
        "44.531",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "-1",
        "44.521",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "-1",
        "44.514",
        "34.149",
        "0.650",
        "0.801",
        "0.043"
    ],
    [
        "74",
        "-1",
        "44.504",
        "34.150",
        "1.008",
        "1.158",
        "-0.018"
    ],
    [
        "75",
        "-1",
        "44.495",
        "34.231",
        "1.214",
        "1.211",
        "-0.009"
    ],
    [
        "76",
        "-1",
        "44.488",
        "34.355",
        "1.284",
        "1.407",
        "-0.012"
    ],
    [
        "77",
        "-1",
        "44.473",
        "34.505",
        "1.408",
        "1.554",
        "0.003"
    ],
    [
        "78",
        "-1",
        "44.440",
        "34.650",
        "1.609",
        "1.559",
        "0.003"
    ],
    [
        "79",
        "-1",
        "44.382",
        "34.769",
        "1.519",
        "1.627",
        "-0.009"
    ],
    [
        "80",
        "-1",
        "44.301",
        "34.854",
        "1.467",
        "1.549",
        "-0.029"
    ],
    [
        "81",
        "-1",
        "44.221",
        "34.929",
        "1.440",
        "1.357",
        "-0.048"
    ],
    [
        "82",
        "-1",
        "44.220",
        "35.127",
        "1.231",
        "1.317",
        "-0.060"
    ],
    [
        "83",
        "-1",
        "44.347",
        "35.558",
        "1.168",
        "1.307",
        "-0.007"
    ],
    [
        "84",
        "-1",
        "44.524",
        "36.098",
        "1.210",
        "1.297",
        "0.046"
    ],
    [
        "85",
        "-1",
        "44.645",
        "36.637",
        "1.172",
        "1.383",
        "0.022"
    ],
    [
        "86",
        "-1",
        "44.697",
        "37.180",
        "1.215",
        "1.399",
        "-0.012"
    ],
    [
        "87",
        "-1",
        "44.710",
        "37.783",
        "1.320",
        "1.350",
        "-0.022"
    ],
    [
        "88",
        "-1",
        "44.700",
        "38.442",
        "1.328",
        "1.372",
        "-0.050"
    ],
    [
        "89",
        "-1",
        "44.650",
        "39.068",
        "1.394",
        "1.399",
        "-0.039"
    ],
    [
        "90",
        "-1",
        "44.556",
        "39.606",
        "1.526",
        "1.476",
        "0.008"
    ],
    [
        "91",
        "-1",
        "44.444",
        "40.149",
        "1.568",
        "1.661",
        "0.022"
    ],
    [
        "92",
        "-1",
        "44.331",
        "40.731",
        "1.671",
        "1.828",
        "0.033"
    ],
    [
        "93",
        "-1",
        "44.205",
        "41.299",
        "1.880",
        "2.038",
        "0.058"
    ],
    [
        "94",
        "-1",
        "44.008",
        "41.667",
        "2.141",
        "2.605",
        "0.106"
    ],
    [
        "95",
        "-1",
        "43.556",
        "41.460",
        "2.821",
        "3.972",
        "0.192"
    ],
    [
        "96",
        "-1",
        "42.558",
        "40.148",
        "4.469",
        "6.213",
        "0.242"
    ],
    [
        "97",
        "-1",
        "40.727",
        "37.321",
        "5.709",
        "8.526",
        "0.257"
    ],
    [
        "98",
        "-1",
        "38.280",
        "32.318",
        "5.824",
        "9.088",
        "0.254"
    ],
    [
        "99",
        "-1",
        "34.634",
        "24.029",
        "2.832",
        "3.296",
        "0.124"
    ],
    [
        "100",
        "-1",
        "29.162",
        "17.351",
        "2.049",
        "4.702",
        "0.141"
    ],
    [
        "101",
        "-1",
        "31.542",
        "0.944",
        "2.194",
        "3.338",
        "0.330"
    ],
    [
        "102",
        "-1",
        "30.058",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "-1",
        "30.060",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "-1",
        "30.061",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "-1",
        "30.064",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "-1",
        "30.066",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "-1",
        "30.068",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "-1",
        "30.070",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "-1",
        "30.073",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "-1",
        "30.077",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "-1",
        "30.079",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "-1",
        "30.083",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "-1",
        "30.086",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "-1",
        "30.090",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "-1",
        "30.094",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "-1",
        "30.098",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "-1",
        "30.101",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "-1",
        "30.106",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "-1",
        "29.785",
        "-14.513",
        "1.378",
        "3.763",
        "-0.255"
    ],
    [
        "120",
        "-1",
        "21.680",
        "20.252",
        "7.080",
        "6.785",
        "-0.143"
    ],
    [
        "121",
        "-1",
        "4.803",
        "15.373",
        "3.901",
        "9.357",
        "0.013"
    ],
    [
        "122",
        "-1",
        "1.831",
        "12.764",
        "4.593",
        "7.830",
        "-0.061"
    ],
    [
        "123",
        "-1",
        "1.550",
        "9.747",
        "5.672",
        "5.569",
        "-0.112"
    ],
    [
        "124",
        "-1",
        "-3.632",
        "6.256",
        "5.849",
        "6.228",
        "-0.152"
    ],
    [
        "125",
        "-1",
        "-15.191",
        "6.114",
        "6.191",
        "5.896",
        "-0.146"
    ],
    [
        "126",
        "-1",
        "-30.851",
        "8.617",
        "6.698",
        "4.884",
        "-0.104"
    ],
    [
        "127",
        "-1",
        "-42.261",
        "9.526",
        "5.484",
        "5.120",
        "-0.073"
    ],
    [
        "128",
        "-1",
        "-48.062",
        "13.007",
        "4.208",
        "4.630",
        "0.014"
    ],
    [
        "129",
        "-1",
        "-44.918",
        "21.078",
        "4.517",
        "4.825",
        "0.071"
    ],
    [
        "130",
        "-1",
        "-43.371",
        "25.962",
        "3.915",
        "4.709",
        "0.219"
    ],
    [
        "131",
        "-1",
        "-43.770",
        "24.293",
        "2.980",
        "3.117",
        "0.240"
    ],
    [
        "132",
        "-1",
        "-43.613",
        "32.041",
        "3.762",
        "4.242",
        "0.348"
    ],
    [
        "133",
        "-1",
        "-43.067",
        "35.555",
        "4.008",
        "4.845",
        "0.237"
    ],
    [
        "134",
        "-1",
        "-42.342",
        "29.256",
        "3.093",
        "1.621",
        "0.136"
    ],
    [
        "135",
        "-1",
        "-43.951",
        "32.672",
        "5.326",
        "5.982",
        "0.108"
    ],
    [
        "136",
        "-1",
        "-49.507",
        "31.456",
        "4.285",
        "2.440",
        "0.133"
    ],
    [
        "137",
        "-1",
        "-51.847",
        "26.173",
        "6.765",
        "7.599",
        "0.225"
    ],
    [
        "138",
        "-1",
        "-58.260",
        "23.083",
        "7.544",
        "7.417",
        "0.379"
    ],
    [
        "139",
        "-1",
        "-66.221",
        "20.706",
        "1.544",
        "3.219",
        "0.282"
    ],
    [
        "140",
        "-1",
        "-66.893",
        "19.988",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "-1",
        "-66.900",
        "19.970",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "-1",
        "-66.906",
        "19.945",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "-1",
        "-66.122",
        "20.409",
        "2.595",
        "2.735",
        "0.111"
    ],
    [
        "144",
        "-1",
        "-65.742",
        "19.987",
        "2.366",
        "1.812",
        "0.117"
    ],
    [
        "145",
        "-1",
        "-65.575",
        "19.858",
        "1.896",
        "1.631",
        "0.100"
    ],
    [
        "146",
        "-1",
        "-65.335",
        "19.887",
        "1.818",
        "1.497",
        "0.097"
    ],
    [
        "147",
        "-1",
        "-65.235",
        "20.083",
        "1.864",
        "1.419",
        "0.034"
    ],
    [
        "148",
        "-1",
        "-65.248",
        "19.593",
        "1.725",
        "1.559",
        "-0.054"
    ],
    [
        "149",
        "-1",
        "-65.451",
        "20.069",
        "1.594",
        "1.588",
        "-0.017"
    ],
    [
        "150",
        "-1",
        "-65.184",
        "20.869",
        "1.371",
        "1.359",
        "0.057"
    ],
    [
        "151",
        "-1",
        "-64.943",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "-1",
        "-64.949",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "-1",
        "-64.956",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "-1",
        "-64.963",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "-1",
        "-64.970",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "-1",
        "-64.977",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "-1",
        "-64.984",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "-1",
        "-64.991",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "-1",
        "-64.998",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "-1",
        "-65.006",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "-1",
        "-65.013",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "-1",
        "-65.021",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "-1",
        "-65.028",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "-1",
        "-65.036",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "-1",
        "-65.044",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "-1",
        "-65.052",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "-1",
        "-65.060",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "-1",
        "-65.068",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "-1",
        "-65.076",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "-1",
        "-65.085",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "-1",
        "-65.093",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "-1",
        "-65.101",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "-1",
        "-65.110",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "-1",
        "-65.118",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "-1",
        "-65.127",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "-1",
        "-65.136",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "-1",
        "-65.145",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "-1",
        "-65.153",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "-1",
        "-65.162",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "0",
        "-65.344",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "0",
        "-65.344",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "0",
        "-65.344",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "0",
        "-65.344",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "0",
        "-65.344",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "0",
        "-65.344",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "0",
        "-65.344",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "0",
        "-65.344",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "0",
        "-65.344",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "0",
        "-65.344",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "0",
        "-65.344",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "0",
        "-65.344",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "0",
        "-65.344",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "0",
        "-65.344",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "0",
        "-65.344",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "0",
        "-65.344",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "0",
        "-65.344",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "0",
        "-65.344",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "0",
        "-65.344",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "0",
        "-65.344",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "0",
        "-65.344",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "0",
        "-65.344",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "0",
        "-65.344",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "0",
        "-65.344",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "0",
        "-65.344",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "0",
        "-65.344",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "0",
        "-65.344",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "0",
        "-65.344",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "0",
        "-65.344",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "0",
        "-65.344",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "0",
        "-65.344",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "0",
        "-65.344",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "0",
        "-65.344",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "0",
        "-65.344",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "0",
        "-65.344",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "0",
        "-65.344",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "0",
        "-65.344",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "0",
        "-65.344",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "0",
        "-65.344",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "0",
        "-65.344",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "0",
        "-65.344",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "0",
        "-65.344",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "0",
        "-65.344",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "0",
        "-65.344",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "0",
        "-65.344",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "0",
        "-65.344",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "0",
        "-65.344",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "0",
        "-65.344",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "0",
        "-65.344",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "0",
        "-65.344",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "0",
        "-65.344",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "0",
        "-65.344",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "0",
        "-65.344",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "0",
        "-65.344",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "0",
        "-65.344",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "0",
        "-65.344",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "0",
        "-65.344",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "0",
        "-65.344",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "0",
        "-65.344",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "0",
        "-65.344",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "0",
        "-65.344",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "0",
        "-65.344",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "0",
        "-65.344",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "0",
        "-65.344",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "0",
        "-65.344",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "0",
        "-65.344",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "0",
        "-65.344",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "0",
        "-65.344",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "0",
        "-65.344",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "0",
        "-65.344",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "0",
        "-65.344",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "0",
        "-65.344",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "0",
        "-65.344",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "0",
        "-65.344",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "0",
        "-65.344",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "0",
        "-65.344",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "0",
        "-65.344",
        "16.157",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "0",
        "-65.344",
        "15.696",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "0",
        "51.150",
        "-3.465",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "0",
        "51.150",
        "-2.595",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "0",
        "51.150",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "0",
        "51.150",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "0",
        "51.150",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "0",
        "51.150",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "0",
        "51.150",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "0",
        "51.150",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "0",
        "51.150",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "0",
        "51.150",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "0",
        "51.150",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "0",
        "51.150",
        "6.110",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "0",
        "51.150",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "0",
        "51.150",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "0",
        "51.150",
        "8.697",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "0",
        "51.150",
        "9.555",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "0",
        "51.150",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "0",
        "51.150",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "0",
        "51.150",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "0",
        "51.150",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "0",
        "51.150",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "0",
        "42.542",
        "13.796",
        "8.460",
        "4.453",
        "0.103"
    ],
    [
        "-80",
        "0",
        "15.981",
        "9.748",
        "7.188",
        "4.591",
        "0.128"
    ],
    [
        "-79",
        "0",
        "5.278",
        "9.547",
        "6.158",
        "3.537",
        "0.077"
    ],
    [
        "-78",
        "0",
        "0.290",
        "10.299",
        "3.666",
        "2.000",
        "-0.081"
    ],
    [
        "-77",
        "0",
        "-3.368",
        "8.372",
        "0.786",
        "0.716",
        "-0.105"
    ],
    [
        "-76",
        "0",
        "-3.586",
        "8.366",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "0",
        "-3.586",
        "8.525",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "0",
        "-3.586",
        "8.682",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "0",
        "-3.586",
        "8.836",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "0",
        "-3.586",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "0",
        "-3.586",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "0",
        "-3.586",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "0",
        "-3.586",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "0",
        "-3.586",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "0",
        "-3.586",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "0",
        "-3.586",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "0",
        "-3.586",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "0",
        "-3.586",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "0",
        "-3.586",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "0",
        "-3.586",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "0",
        "-3.586",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "0",
        "-3.586",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "0",
        "-3.586",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "0",
        "-3.586",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "0",
        "-3.586",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "0",
        "-3.586",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "0",
        "-3.586",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "0",
        "-3.586",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "0",
        "-3.586",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "0",
        "-3.586",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "0",
        "-3.586",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "0",
        "-3.586",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "0",
        "-3.586",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "0",
        "-3.586",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "0",
        "-3.586",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "0",
        "-3.586",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "0",
        "-3.586",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "0",
        "-3.586",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "0",
        "-3.586",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "0",
        "-3.586",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "0",
        "-3.586",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "0",
        "-3.586",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "0",
        "-3.586",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "0",
        "-3.586",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "0",
        "-3.586",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "0",
        "-3.586",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "0",
        "-3.586",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "0",
        "-3.586",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "0",
        "-3.586",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "0",
        "-3.586",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "0",
        "-3.586",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "0",
        "-3.586",
        "12.441",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "0",
        "-3.586",
        "12.445",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "0",
        "-3.586",
        "12.447",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "0",
        "-3.586",
        "12.444",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "0",
        "-3.586",
        "12.438",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "0",
        "3.606",
        "13.372",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "0",
        "24.155",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "0",
        "24.155",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "0",
        "24.155",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "0",
        "24.155",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "0",
        "24.155",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "0",
        "16.464",
        "15.522",
        "3.253",
        "2.551",
        "0.219"
    ],
    [
        "-18",
        "0",
        "3.743",
        "13.613",
        "3.701",
        "2.339",
        "0.239"
    ],
    [
        "-17",
        "0",
        "-3.586",
        "12.208",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "0",
        "16.809",
        "15.869",
        "2.661",
        "2.692",
        "0.191"
    ],
    [
        "-15",
        "0",
        "24.155",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "0",
        "24.155",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "0",
        "24.155",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "0",
        "24.155",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "0",
        "24.155",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "0",
        "24.155",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "0",
        "24.155",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "0",
        "24.155",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "0",
        "24.155",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "0",
        "24.155",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "0",
        "24.155",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "0",
        "24.155",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "0",
        "24.155",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "0",
        "24.155",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "0",
        "24.155",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "0",
        "24.155",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "0",
        "24.155",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "0",
        "24.155",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "0",
        "24.155",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "0",
        "24.155",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "0",
        "24.155",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "0",
        "24.155",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "0",
        "24.155",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "0",
        "24.155",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "0",
        "24.155",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "0",
        "24.155",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "0",
        "24.155",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "0",
        "24.155",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "0",
        "24.155",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "0",
        "24.155",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "0",
        "24.155",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "0",
        "24.155",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "0",
        "24.155",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "0",
        "24.155",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "0",
        "24.155",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "0",
        "24.155",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "0",
        "24.155",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "0",
        "24.155",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "0",
        "24.155",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "0",
        "24.155",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "0",
        "24.155",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "0",
        "24.155",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "0",
        "24.155",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "0",
        "24.155",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "0",
        "24.155",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "0",
        "24.613",
        "18.147",
        "1.328",
        "0.925",
        "-0.125"
    ],
    [
        "31",
        "0",
        "25.152",
        "17.815",
        "1.431",
        "1.225",
        "-0.096"
    ],
    [
        "32",
        "0",
        "25.602",
        "17.667",
        "1.647",
        "1.577",
        "-0.063"
    ],
    [
        "33",
        "0",
        "26.118",
        "17.464",
        "1.867",
        "1.753",
        "-0.021"
    ],
    [
        "34",
        "0",
        "26.646",
        "17.278",
        "1.777",
        "1.929",
        "0.031"
    ],
    [
        "35",
        "0",
        "27.195",
        "17.099",
        "1.733",
        "1.932",
        "0.048"
    ],
    [
        "36",
        "0",
        "27.781",
        "16.891",
        "1.726",
        "1.751",
        "0.054"
    ],
    [
        "37",
        "0",
        "28.505",
        "16.628",
        "0.388",
        "0.562",
        "-0.029"
    ],
    [
        "38",
        "0",
        "28.593",
        "16.415",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "0",
        "28.593",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "0",
        "28.593",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "0",
        "28.593",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "0",
        "28.593",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "0",
        "28.593",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "0",
        "28.593",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "0",
        "28.593",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "0",
        "28.593",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "0",
        "28.593",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "0",
        "28.593",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "0",
        "28.593",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "0",
        "28.593",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "0",
        "28.593",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "0",
        "28.593",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "0",
        "28.593",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "0",
        "28.593",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "0",
        "28.593",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "0",
        "28.593",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "0",
        "28.593",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "0",
        "28.593",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "0",
        "28.593",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "0",
        "28.593",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "0",
        "28.593",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "0",
        "28.593",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "0",
        "28.593",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "0",
        "28.593",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "0",
        "28.593",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "0",
        "28.593",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "0",
        "34.763",
        "19.752",
        "7.029",
        "7.821",
        "0.275"
    ],
    [
        "68",
        "0",
        "44.470",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "0",
        "44.470",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "0",
        "44.470",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "0",
        "44.470",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "0",
        "44.470",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "0",
        "44.472",
        "34.171",
        "0.595",
        "0.713",
        "-0.032"
    ],
    [
        "74",
        "0",
        "44.465",
        "34.200",
        "0.801",
        "0.925",
        "-0.147"
    ],
    [
        "75",
        "0",
        "44.457",
        "34.242",
        "0.948",
        "0.945",
        "-0.073"
    ],
    [
        "76",
        "0",
        "44.456",
        "34.296",
        "0.966",
        "1.140",
        "-0.052"
    ],
    [
        "77",
        "0",
        "44.454",
        "34.354",
        "1.055",
        "1.279",
        "-0.032"
    ],
    [
        "78",
        "0",
        "44.446",
        "34.403",
        "1.262",
        "1.276",
        "-0.005"
    ],
    [
        "79",
        "0",
        "44.426",
        "34.436",
        "1.137",
        "1.349",
        "-0.008"
    ],
    [
        "80",
        "0",
        "44.392",
        "34.448",
        "1.101",
        "1.266",
        "-0.045"
    ],
    [
        "81",
        "0",
        "44.348",
        "34.445",
        "1.113",
        "1.068",
        "-0.062"
    ],
    [
        "82",
        "0",
        "44.324",
        "34.483",
        "0.918",
        "1.011",
        "-0.107"
    ],
    [
        "83",
        "0",
        "44.403",
        "34.858",
        "0.986",
        "1.162",
        "0.045"
    ],
    [
        "84",
        "0",
        "44.493",
        "35.431",
        "1.194",
        "1.239",
        "0.055"
    ],
    [
        "85",
        "0",
        "44.581",
        "36.090",
        "1.214",
        "1.400",
        "0.041"
    ],
    [
        "86",
        "0",
        "44.587",
        "36.695",
        "1.287",
        "1.441",
        "0.005"
    ],
    [
        "87",
        "0",
        "44.562",
        "37.299",
        "1.414",
        "1.405",
        "-0.005"
    ],
    [
        "88",
        "0",
        "44.534",
        "37.918",
        "1.430",
        "1.453",
        "-0.031"
    ],
    [
        "89",
        "0",
        "44.485",
        "38.518",
        "1.508",
        "1.498",
        "-0.028"
    ],
    [
        "90",
        "0",
        "44.403",
        "39.075",
        "1.662",
        "1.561",
        "0.005"
    ],
    [
        "91",
        "0",
        "44.293",
        "39.663",
        "1.694",
        "1.753",
        "0.020"
    ],
    [
        "92",
        "0",
        "44.166",
        "40.291",
        "1.802",
        "1.955",
        "0.036"
    ],
    [
        "93",
        "0",
        "44.019",
        "40.792",
        "2.040",
        "2.335",
        "0.074"
    ],
    [
        "94",
        "0",
        "43.743",
        "40.859",
        "2.447",
        "3.384",
        "0.178"
    ],
    [
        "95",
        "0",
        "42.954",
        "39.935",
        "3.365",
        "5.388",
        "0.285"
    ],
    [
        "96",
        "0",
        "40.881",
        "37.080",
        "5.232",
        "7.894",
        "0.389"
    ],
    [
        "97",
        "0",
        "36.500",
        "31.855",
        "5.987",
        "9.481",
        "0.388"
    ],
    [
        "98",
        "0",
        "29.971",
        "23.396",
        "3.690",
        "5.310",
        "0.317"
    ],
    [
        "99",
        "0",
        "31.364",
        "4.972",
        "2.940",
        "2.515",
        "0.174"
    ],
    [
        "100",
        "0",
        "28.014",
        "9.653",
        "2.305",
        "3.112",
        "0.303"
    ],
    [
        "101",
        "0",
        "30.264",
        "-5.058",
        "1.800",
        "2.598",
        "0.329"
    ],
    [
        "102",
        "0",
        "30.591",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "0",
        "30.591",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "0",
        "30.591",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "0",
        "30.591",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "0",
        "30.591",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "0",
        "30.591",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "0",
        "30.591",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "0",
        "30.591",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "0",
        "30.591",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "0",
        "30.591",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "0",
        "30.591",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "0",
        "30.591",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "0",
        "30.591",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "0",
        "30.591",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "0",
        "30.591",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "0",
        "30.591",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "0",
        "30.591",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "0",
        "29.711",
        "-13.281",
        "1.557",
        "3.956",
        "-0.168"
    ],
    [
        "120",
        "0",
        "22.468",
        "14.316",
        "7.600",
        "10.492",
        "-0.168"
    ],
    [
        "121",
        "0",
        "23.318",
        "20.805",
        "7.152",
        "11.233",
        "-0.057"
    ],
    [
        "122",
        "0",
        "18.284",
        "15.145",
        "6.518",
        "10.018",
        "-0.029"
    ],
    [
        "123",
        "0",
        "13.120",
        "7.953",
        "6.433",
        "8.786",
        "-0.115"
    ],
    [
        "124",
        "0",
        "6.920",
        "1.969",
        "6.200",
        "7.919",
        "-0.151"
    ],
    [
        "125",
        "0",
        "-6.240",
        "-1.078",
        "6.604",
        "6.786",
        "-0.152"
    ],
    [
        "126",
        "0",
        "-28.666",
        "3.341",
        "7.271",
        "5.692",
        "-0.327"
    ],
    [
        "127",
        "0",
        "-47.305",
        "8.543",
        "5.431",
        "4.428",
        "-0.186"
    ],
    [
        "128",
        "0",
        "-54.783",
        "13.143",
        "4.849",
        "3.995",
        "-0.237"
    ],
    [
        "129",
        "0",
        "-61.635",
        "21.493",
        "3.873",
        "3.612",
        "-0.208"
    ],
    [
        "130",
        "0",
        "-63.240",
        "25.514",
        "2.935",
        "3.239",
        "0.129"
    ],
    [
        "131",
        "0",
        "-64.064",
        "24.520",
        "3.089",
        "3.241",
        "0.323"
    ],
    [
        "132",
        "0",
        "-64.076",
        "27.415",
        "3.918",
        "3.819",
        "0.386"
    ],
    [
        "133",
        "0",
        "-63.636",
        "27.119",
        "4.604",
        "5.605",
        "0.237"
    ],
    [
        "134",
        "0",
        "-63.202",
        "23.419",
        "5.489",
        "5.968",
        "0.174"
    ],
    [
        "135",
        "0",
        "-63.840",
        "24.212",
        "6.553",
        "5.865",
        "0.148"
    ],
    [
        "136",
        "0",
        "-66.435",
        "21.291",
        "1.452",
        "2.925",
        "0.084"
    ],
    [
        "137",
        "0",
        "-66.889",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "0",
        "-66.889",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "0",
        "-66.889",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "0",
        "-66.889",
        "19.988",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "0",
        "-66.889",
        "19.970",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "0",
        "-66.889",
        "19.945",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "0",
        "-66.889",
        "19.914",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "0",
        "-66.889",
        "19.878",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "0",
        "-66.889",
        "19.835",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "0",
        "-66.889",
        "19.786",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "0",
        "-66.889",
        "19.731",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "0",
        "-66.889",
        "19.671",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "0",
        "-66.710",
        "19.665",
        "0.215",
        "0.359",
        "-0.050"
    ],
    [
        "150",
        "0",
        "-65.344",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "0",
        "-65.344",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "0",
        "-65.344",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "0",
        "-65.344",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "0",
        "-65.344",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "0",
        "-65.344",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "0",
        "-65.344",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "0",
        "-65.344",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "0",
        "-65.344",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "0",
        "-65.344",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "0",
        "-65.344",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "0",
        "-65.344",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "0",
        "-65.344",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "0",
        "-65.344",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "0",
        "-65.344",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "0",
        "-65.344",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "0",
        "-65.344",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "0",
        "-65.344",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "0",
        "-65.344",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "0",
        "-65.344",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "0",
        "-65.344",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "0",
        "-65.344",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "0",
        "-65.344",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "0",
        "-65.344",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "0",
        "-65.344",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "0",
        "-65.344",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "0",
        "-65.344",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "0",
        "-65.344",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "0",
        "-65.344",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "0",
        "-65.344",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "1",
        "-65.497",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "1",
        "-65.488",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "1",
        "-65.479",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "1",
        "-65.469",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "1",
        "-65.460",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "1",
        "-65.451",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "1",
        "-65.442",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "1",
        "-65.433",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "1",
        "-65.424",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "1",
        "-65.414",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "1",
        "-65.405",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "1",
        "-65.396",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "1",
        "-65.386",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "1",
        "-65.377",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "1",
        "-65.368",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "1",
        "-65.358",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "1",
        "-65.349",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "1",
        "-65.339",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "1",
        "-65.330",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "1",
        "-65.321",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "1",
        "-65.311",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "1",
        "-65.302",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "1",
        "-65.293",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "1",
        "-65.283",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "1",
        "-65.274",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "1",
        "-65.264",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "1",
        "-65.255",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "1",
        "-65.246",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "1",
        "-65.237",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "1",
        "-65.227",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "1",
        "-65.218",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "1",
        "-65.209",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "1",
        "-65.200",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "1",
        "-65.191",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "1",
        "-65.182",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "1",
        "-65.173",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "1",
        "-65.164",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "1",
        "-65.155",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "1",
        "-65.146",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "1",
        "-65.137",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "1",
        "-65.129",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "1",
        "-65.120",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "1",
        "-65.111",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "1",
        "-65.103",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "1",
        "-65.094",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "1",
        "-65.086",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "1",
        "-65.078",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "1",
        "-65.070",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "1",
        "-65.061",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "1",
        "-65.053",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "1",
        "-65.045",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "1",
        "-65.037",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "1",
        "-65.030",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "1",
        "-65.022",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "1",
        "-65.014",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "1",
        "-65.007",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "1",
        "-64.999",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "1",
        "-64.992",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "1",
        "-64.985",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "1",
        "-64.978",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "1",
        "-64.971",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "1",
        "-64.964",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "1",
        "-64.957",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "1",
        "-64.951",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "1",
        "-64.944",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "1",
        "-64.938",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "1",
        "-64.931",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "1",
        "-64.925",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "1",
        "-64.919",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "1",
        "-64.913",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "1",
        "-64.907",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "1",
        "-64.902",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "1",
        "-64.896",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "1",
        "-64.891",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "1",
        "-64.885",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "1",
        "-64.880",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "1",
        "-64.875",
        "16.157",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "1",
        "-64.871",
        "15.696",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "1",
        "-8.073",
        "16.304",
        "19.614",
        "13.576",
        "-0.300"
    ],
    [
        "-101",
        "1",
        "47.604",
        "-0.288",
        "4.665",
        "5.545",
        "-0.217"
    ],
    [
        "-100",
        "1",
        "50.271",
        "-1.724",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "1",
        "50.270",
        "-0.852",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "1",
        "50.271",
        "0.021",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "1",
        "50.271",
        "0.892",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "1",
        "50.270",
        "1.764",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "1",
        "50.271",
        "2.635",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "1",
        "50.273",
        "3.506",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "1",
        "50.274",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "1",
        "50.275",
        "5.244",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "1",
        "54.933",
        "18.585",
        "9.516",
        "10.052",
        "0.005"
    ],
    [
        "-90",
        "1",
        "50.279",
        "6.974",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "1",
        "50.281",
        "7.837",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "1",
        "51.134",
        "15.301",
        "2.927",
        "6.449",
        "-0.139"
    ],
    [
        "-87",
        "1",
        "47.851",
        "34.791",
        "11.842",
        "10.862",
        "-0.026"
    ],
    [
        "-86",
        "1",
        "50.289",
        "10.409",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "1",
        "50.292",
        "11.261",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "1",
        "50.296",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "1",
        "50.300",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "1",
        "50.304",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "1",
        "50.308",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "1",
        "23.448",
        "12.811",
        "5.231",
        "4.624",
        "0.116"
    ],
    [
        "-79",
        "1",
        "7.907",
        "10.625",
        "7.421",
        "4.654",
        "-0.084"
    ],
    [
        "-78",
        "1",
        "1.260",
        "11.535",
        "5.516",
        "3.249",
        "-0.216"
    ],
    [
        "-77",
        "1",
        "-2.157",
        "10.965",
        "2.495",
        "1.843",
        "-0.085"
    ],
    [
        "-76",
        "1",
        "-3.746",
        "8.366",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "1",
        "-3.743",
        "8.525",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "1",
        "-3.741",
        "8.682",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "1",
        "-3.738",
        "8.836",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "1",
        "-3.736",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "1",
        "-3.733",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "1",
        "-3.730",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "1",
        "-3.727",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "1",
        "-3.724",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "1",
        "-3.721",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "1",
        "-3.718",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "1",
        "-3.715",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "1",
        "-3.712",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "1",
        "-3.709",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "1",
        "-3.705",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "1",
        "-3.702",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "1",
        "-3.699",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "1",
        "-3.696",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "1",
        "-3.693",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "1",
        "-3.690",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "1",
        "-3.687",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "1",
        "-3.683",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "1",
        "-3.679",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "1",
        "-3.676",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "1",
        "-3.672",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "1",
        "-3.669",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "1",
        "-3.665",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "1",
        "-3.662",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "1",
        "-3.659",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "1",
        "-3.655",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "1",
        "-3.651",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "1",
        "-3.648",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "1",
        "-3.644",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "1",
        "-3.640",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "1",
        "-3.637",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "1",
        "-3.632",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "1",
        "-3.629",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "1",
        "-3.626",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "1",
        "-3.622",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "1",
        "-3.618",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "1",
        "-3.615",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "1",
        "-3.610",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "1",
        "-3.607",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "1",
        "-3.603",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "1",
        "-3.599",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "1",
        "-3.595",
        "12.432",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "1",
        "5.353",
        "13.204",
        "4.168",
        "2.660",
        "0.113"
    ],
    [
        "-29",
        "1",
        "10.811",
        "13.641",
        "3.980",
        "2.398",
        "0.103"
    ],
    [
        "-28",
        "1",
        "11.110",
        "14.023",
        "3.857",
        "2.275",
        "0.143"
    ],
    [
        "-27",
        "1",
        "12.165",
        "14.366",
        "3.974",
        "2.326",
        "0.113"
    ],
    [
        "-26",
        "1",
        "16.513",
        "15.386",
        "3.461",
        "2.551",
        "0.131"
    ],
    [
        "-25",
        "1",
        "23.957",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "1",
        "23.962",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "1",
        "23.967",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "1",
        "23.972",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "1",
        "23.977",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "1",
        "23.982",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "1",
        "23.988",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "1",
        "23.993",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "1",
        "23.998",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "1",
        "24.004",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "1",
        "24.009",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "1",
        "24.014",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "1",
        "24.020",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "1",
        "24.025",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "1",
        "24.031",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "1",
        "24.036",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "1",
        "24.042",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "1",
        "24.048",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "1",
        "24.053",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "1",
        "24.060",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "1",
        "24.065",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "1",
        "24.071",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "1",
        "24.076",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "1",
        "24.083",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "1",
        "24.088",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "1",
        "24.094",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "1",
        "24.100",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "1",
        "24.106",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "1",
        "24.112",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "1",
        "24.118",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "1",
        "24.123",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "1",
        "24.129",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "1",
        "24.135",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "1",
        "24.142",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "1",
        "24.148",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "1",
        "24.153",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "1",
        "24.159",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "1",
        "24.166",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "1",
        "24.171",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "1",
        "24.177",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "1",
        "24.183",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "1",
        "24.189",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "1",
        "24.195",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "1",
        "24.201",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "1",
        "24.207",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "1",
        "24.212",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "1",
        "24.218",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "1",
        "24.225",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "1",
        "24.230",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "1",
        "24.236",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "1",
        "24.242",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "1",
        "24.247",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "1",
        "24.253",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "1",
        "24.259",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "1",
        "24.265",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "1",
        "24.474",
        "18.234",
        "1.118",
        "0.827",
        "-0.228"
    ],
    [
        "31",
        "1",
        "24.943",
        "17.972",
        "1.358",
        "1.164",
        "-0.162"
    ],
    [
        "32",
        "1",
        "25.408",
        "17.729",
        "1.573",
        "1.542",
        "-0.123"
    ],
    [
        "33",
        "1",
        "25.899",
        "17.515",
        "1.830",
        "1.772",
        "-0.073"
    ],
    [
        "34",
        "1",
        "26.447",
        "17.332",
        "1.825",
        "2.005",
        "-0.010"
    ],
    [
        "35",
        "1",
        "27.048",
        "17.154",
        "1.852",
        "2.059",
        "0.015"
    ],
    [
        "36",
        "1",
        "27.661",
        "16.941",
        "1.939",
        "1.933",
        "0.025"
    ],
    [
        "37",
        "1",
        "28.222",
        "16.697",
        "1.680",
        "1.866",
        "0.013"
    ],
    [
        "38",
        "1",
        "28.656",
        "16.443",
        "1.168",
        "1.262",
        "-0.015"
    ],
    [
        "39",
        "1",
        "28.788",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "1",
        "28.792",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "1",
        "28.797",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "1",
        "28.802",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "1",
        "28.806",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "1",
        "28.812",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "1",
        "28.815",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "1",
        "28.820",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "1",
        "28.825",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "1",
        "28.829",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "1",
        "28.834",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "1",
        "28.838",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "1",
        "28.842",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "1",
        "28.846",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "1",
        "28.850",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "1",
        "28.854",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "1",
        "28.857",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "1",
        "28.862",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "1",
        "28.865",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "1",
        "28.868",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "1",
        "28.872",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "1",
        "28.875",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "1",
        "28.879",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "1",
        "28.882",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "1",
        "28.885",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "1",
        "28.888",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "1",
        "28.891",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "1",
        "28.895",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "1",
        "40.347",
        "27.293",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "1",
        "44.365",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "1",
        "44.375",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "1",
        "44.386",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "1",
        "44.396",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "1",
        "44.406",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "1",
        "44.417",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "1",
        "44.427",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "1",
        "44.438",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "1",
        "44.448",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "1",
        "44.459",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "1",
        "44.469",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "1",
        "44.479",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "1",
        "44.490",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "1",
        "44.500",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "1",
        "44.511",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "1",
        "44.443",
        "34.335",
        "0.843",
        "0.944",
        "-0.121"
    ],
    [
        "84",
        "1",
        "44.467",
        "34.669",
        "1.060",
        "1.071",
        "-0.023"
    ],
    [
        "85",
        "1",
        "44.534",
        "35.513",
        "1.220",
        "1.439",
        "0.058"
    ],
    [
        "86",
        "1",
        "44.447",
        "36.197",
        "1.369",
        "1.526",
        "0.006"
    ],
    [
        "87",
        "1",
        "44.367",
        "36.759",
        "1.518",
        "1.491",
        "0.000"
    ],
    [
        "88",
        "1",
        "44.308",
        "37.271",
        "1.527",
        "1.558",
        "-0.013"
    ],
    [
        "89",
        "1",
        "44.244",
        "37.767",
        "1.606",
        "1.610",
        "-0.002"
    ],
    [
        "90",
        "1",
        "44.162",
        "38.317",
        "1.765",
        "1.660",
        "0.030"
    ],
    [
        "91",
        "1",
        "44.061",
        "38.995",
        "1.787",
        "1.883",
        "0.056"
    ],
    [
        "92",
        "1",
        "43.951",
        "39.586",
        "1.924",
        "2.260",
        "0.084"
    ],
    [
        "93",
        "1",
        "43.778",
        "39.672",
        "2.296",
        "3.131",
        "0.151"
    ],
    [
        "94",
        "1",
        "43.313",
        "39.066",
        "2.949",
        "4.885",
        "0.243"
    ],
    [
        "95",
        "1",
        "41.992",
        "37.132",
        "4.067",
        "7.331",
        "0.311"
    ],
    [
        "96",
        "1",
        "39.261",
        "32.929",
        "5.793",
        "9.539",
        "0.430"
    ],
    [
        "97",
        "1",
        "35.839",
        "28.370",
        "5.509",
        "9.284",
        "0.393"
    ],
    [
        "98",
        "1",
        "33.053",
        "21.737",
        "1.270",
        "1.213",
        "0.068"
    ],
    [
        "99",
        "1",
        "32.036",
        "15.559",
        "4.139",
        "4.320",
        "0.462"
    ],
    [
        "100",
        "1",
        "30.970",
        "0.121",
        "2.801",
        "3.637",
        "0.455"
    ],
    [
        "101",
        "1",
        "31.117",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "1",
        "31.115",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "1",
        "31.114",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "1",
        "31.112",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "1",
        "31.109",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "1",
        "31.107",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "1",
        "31.105",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "1",
        "31.103",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "1",
        "31.100",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "1",
        "31.096",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "1",
        "31.094",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "1",
        "31.090",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "1",
        "31.087",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "1",
        "31.083",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "1",
        "31.079",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "1",
        "31.076",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "1",
        "31.072",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "1",
        "31.068",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "1",
        "28.943",
        "-11.368",
        "5.045",
        "9.578",
        "-0.161"
    ],
    [
        "120",
        "1",
        "27.444",
        "-0.001",
        "6.859",
        "11.151",
        "-0.120"
    ],
    [
        "121",
        "1",
        "28.634",
        "6.573",
        "6.748",
        "12.012",
        "0.001"
    ],
    [
        "122",
        "1",
        "28.212",
        "5.799",
        "6.495",
        "11.251",
        "0.078"
    ],
    [
        "123",
        "1",
        "24.073",
        "1.753",
        "6.822",
        "10.088",
        "0.006"
    ],
    [
        "124",
        "1",
        "18.803",
        "-0.910",
        "6.446",
        "8.903",
        "-0.121"
    ],
    [
        "125",
        "1",
        "13.959",
        "-7.418",
        "5.138",
        "6.202",
        "0.027"
    ],
    [
        "126",
        "1",
        "-18.004",
        "0.721",
        "8.158",
        "6.364",
        "-0.354"
    ],
    [
        "127",
        "1",
        "-43.649",
        "12.246",
        "4.301",
        "3.789",
        "-0.070"
    ],
    [
        "128",
        "1",
        "-58.361",
        "14.904",
        "4.549",
        "3.849",
        "-0.406"
    ],
    [
        "129",
        "1",
        "-66.748",
        "23.670",
        "3.663",
        "2.954",
        "-0.410"
    ],
    [
        "130",
        "1",
        "-70.729",
        "26.374",
        "0.677",
        "0.887",
        "-0.146"
    ],
    [
        "131",
        "1",
        "-71.016",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "1",
        "-71.008",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "1",
        "-67.374",
        "20.842",
        "0.700",
        "1.837",
        "0.189"
    ],
    [
        "134",
        "1",
        "-66.900",
        "19.970",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "1",
        "-66.894",
        "19.989",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "1",
        "-66.887",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "1",
        "-66.882",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "1",
        "-66.875",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "1",
        "-66.869",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "1",
        "-66.863",
        "19.988",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "1",
        "-66.857",
        "19.970",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "1",
        "-66.851",
        "19.945",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "1",
        "-66.845",
        "19.914",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "1",
        "-66.839",
        "19.878",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "1",
        "-66.832",
        "19.835",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "1",
        "-66.827",
        "19.786",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "1",
        "-66.821",
        "19.731",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "1",
        "-66.815",
        "19.671",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "1",
        "-66.715",
        "19.661",
        "0.208",
        "0.323",
        "-0.006"
    ],
    [
        "150",
        "1",
        "-65.731",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "1",
        "-65.725",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "1",
        "-65.718",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "1",
        "-65.712",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "1",
        "-65.705",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "1",
        "-65.698",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "1",
        "-65.691",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "1",
        "-65.684",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "1",
        "-65.677",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "1",
        "-65.670",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "1",
        "-65.662",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "1",
        "-65.655",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "1",
        "-65.647",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "1",
        "-65.639",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "1",
        "-65.632",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "1",
        "-65.624",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "1",
        "-65.616",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "1",
        "-65.608",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "1",
        "-65.600",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "1",
        "-65.591",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "1",
        "-65.583",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "1",
        "-65.575",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "1",
        "-65.566",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "1",
        "-65.558",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "1",
        "-65.549",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "1",
        "-65.541",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "1",
        "-65.532",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "1",
        "-65.523",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "1",
        "-65.514",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "1",
        "-65.505",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "2",
        "-65.629",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "2",
        "-65.611",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "2",
        "-65.593",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "2",
        "-65.575",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "2",
        "-65.557",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "2",
        "-65.539",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "2",
        "-65.520",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "2",
        "-65.502",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "2",
        "-65.483",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "2",
        "-65.465",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "2",
        "-65.446",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "2",
        "-65.428",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "2",
        "-65.409",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "2",
        "-65.390",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "2",
        "-65.371",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "2",
        "-65.353",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "2",
        "-65.334",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "2",
        "-65.315",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "2",
        "-65.296",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "2",
        "-65.278",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "2",
        "-65.259",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "2",
        "-65.240",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "2",
        "-65.221",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "2",
        "-65.203",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "2",
        "-65.184",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "2",
        "-65.165",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "2",
        "-65.147",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "2",
        "-65.128",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "2",
        "-65.110",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "2",
        "-65.091",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "2",
        "-65.073",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "2",
        "-65.054",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "2",
        "-65.036",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "2",
        "-65.018",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "2",
        "-65.000",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "2",
        "-64.982",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "2",
        "-64.964",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "2",
        "-64.946",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "2",
        "-64.929",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "2",
        "-64.911",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "2",
        "-64.894",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "2",
        "-64.876",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "2",
        "-64.859",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "2",
        "-64.842",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "2",
        "-64.825",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "2",
        "-64.809",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "2",
        "-64.792",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "2",
        "-64.775",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "2",
        "-64.759",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "2",
        "-64.743",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "2",
        "-64.727",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "2",
        "-64.711",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "2",
        "-64.696",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "2",
        "-64.680",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "2",
        "-64.665",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "2",
        "-64.650",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "2",
        "-64.635",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "2",
        "-64.621",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "2",
        "-64.606",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "2",
        "-64.592",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "2",
        "-64.578",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "2",
        "-64.564",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "2",
        "-64.551",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "2",
        "-64.537",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "2",
        "-64.524",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "2",
        "-64.512",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "2",
        "-64.499",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "2",
        "-64.487",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "2",
        "-64.475",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "2",
        "-64.463",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "2",
        "-64.451",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "2",
        "-64.440",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "2",
        "-64.429",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "2",
        "-64.418",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "2",
        "-64.407",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "2",
        "-64.397",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "2",
        "-64.387",
        "16.157",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "2",
        "-64.377",
        "15.696",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "2",
        "-7.115",
        "27.046",
        "17.861",
        "15.910",
        "-0.243"
    ],
    [
        "-101",
        "2",
        "47.918",
        "26.554",
        "15.459",
        "14.851",
        "-0.264"
    ],
    [
        "-100",
        "2",
        "58.769",
        "21.410",
        "13.190",
        "13.259",
        "-0.365"
    ],
    [
        "-99",
        "2",
        "57.428",
        "22.973",
        "10.811",
        "11.789",
        "-0.222"
    ],
    [
        "-98",
        "2",
        "58.336",
        "25.000",
        "8.484",
        "13.229",
        "-0.241"
    ],
    [
        "-97",
        "2",
        "58.325",
        "26.576",
        "8.699",
        "13.409",
        "-0.304"
    ],
    [
        "-96",
        "2",
        "55.311",
        "25.260",
        "9.847",
        "12.464",
        "-0.340"
    ],
    [
        "-95",
        "2",
        "55.416",
        "18.998",
        "9.685",
        "13.508",
        "-0.327"
    ],
    [
        "-94",
        "2",
        "59.082",
        "17.513",
        "10.675",
        "15.960",
        "-0.224"
    ],
    [
        "-93",
        "2",
        "62.383",
        "29.164",
        "12.132",
        "15.457",
        "-0.114"
    ],
    [
        "-92",
        "2",
        "63.566",
        "30.625",
        "11.515",
        "13.236",
        "-0.057"
    ],
    [
        "-91",
        "2",
        "65.615",
        "49.171",
        "7.163",
        "10.924",
        "-0.003"
    ],
    [
        "-90",
        "2",
        "67.433",
        "67.952",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "2",
        "67.476",
        "70.303",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "2",
        "67.519",
        "72.632",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "2",
        "67.564",
        "74.939",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "2",
        "67.610",
        "77.222",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "2",
        "65.114",
        "68.558",
        "2.211",
        "6.663",
        "0.016"
    ],
    [
        "-84",
        "2",
        "49.427",
        "12.108",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "2",
        "49.435",
        "12.953",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "2",
        "49.442",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "2",
        "49.451",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "2",
        "38.333",
        "16.099",
        "8.055",
        "5.827",
        "-0.164"
    ],
    [
        "-79",
        "2",
        "14.804",
        "11.678",
        "7.427",
        "4.603",
        "-0.241"
    ],
    [
        "-78",
        "2",
        "1.744",
        "13.663",
        "6.666",
        "4.589",
        "-0.382"
    ],
    [
        "-77",
        "2",
        "-2.064",
        "13.389",
        "3.523",
        "2.735",
        "-0.324"
    ],
    [
        "-76",
        "2",
        "-3.322",
        "9.848",
        "2.209",
        "2.021",
        "-0.222"
    ],
    [
        "-75",
        "2",
        "-3.900",
        "8.525",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "2",
        "-3.895",
        "8.682",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "2",
        "-3.890",
        "8.836",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "2",
        "-3.884",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "2",
        "-3.878",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "2",
        "-3.873",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "2",
        "-3.867",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "2",
        "-3.861",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "2",
        "-3.855",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "2",
        "-3.849",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "2",
        "-3.844",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "2",
        "-3.837",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "2",
        "-3.831",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "2",
        "-3.824",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "2",
        "-3.819",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "2",
        "-3.812",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "2",
        "-3.806",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "2",
        "-3.798",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "2",
        "-3.792",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "2",
        "-3.785",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "2",
        "-3.779",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "2",
        "-3.772",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "2",
        "-3.765",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "2",
        "-3.758",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "2",
        "-3.752",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "2",
        "-3.744",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "2",
        "-3.737",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "2",
        "-3.730",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "2",
        "-3.723",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "2",
        "-3.716",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "2",
        "-3.708",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "2",
        "-3.701",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "2",
        "-3.694",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "2",
        "-3.687",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "2",
        "-3.679",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "2",
        "-3.672",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "2",
        "-3.664",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "2",
        "-3.657",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "2",
        "-3.650",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "2",
        "-3.642",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "2",
        "-3.634",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "2",
        "-3.627",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "2",
        "-3.619",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "2",
        "-3.611",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "2",
        "3.473",
        "13.050",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "2",
        "23.705",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "2",
        "23.714",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "2",
        "23.723",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "2",
        "23.733",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "2",
        "23.742",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "2",
        "23.751",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "2",
        "23.762",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "2",
        "23.771",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "2",
        "23.782",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "2",
        "23.792",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "2",
        "23.803",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "2",
        "23.813",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "2",
        "23.823",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "2",
        "23.834",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "2",
        "23.845",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "2",
        "23.855",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "2",
        "23.866",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "2",
        "23.877",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "2",
        "23.888",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "2",
        "23.899",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "2",
        "23.911",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "2",
        "23.922",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "2",
        "23.933",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "2",
        "23.944",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "2",
        "23.956",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "2",
        "23.967",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "2",
        "23.979",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "2",
        "23.991",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "2",
        "24.002",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "2",
        "24.013",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "2",
        "24.025",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "2",
        "24.037",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "2",
        "24.049",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "2",
        "24.061",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "2",
        "24.073",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "2",
        "24.085",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "2",
        "24.096",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "2",
        "24.108",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "2",
        "24.120",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "2",
        "24.132",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "2",
        "24.144",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "2",
        "24.156",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "2",
        "24.168",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "2",
        "24.179",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "2",
        "24.191",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "2",
        "24.204",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "2",
        "24.215",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "2",
        "24.227",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "2",
        "24.239",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "2",
        "24.251",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "2",
        "24.263",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "2",
        "24.274",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "2",
        "24.285",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "2",
        "24.298",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "2",
        "24.309",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "2",
        "24.320",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "2",
        "24.332",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "2",
        "24.343",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "2",
        "24.354",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "2",
        "24.366",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "2",
        "24.377",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "2",
        "24.573",
        "18.123",
        "0.867",
        "0.876",
        "-0.235"
    ],
    [
        "32",
        "2",
        "25.062",
        "17.838",
        "1.351",
        "1.497",
        "-0.160"
    ],
    [
        "33",
        "2",
        "25.563",
        "17.604",
        "1.687",
        "1.762",
        "-0.101"
    ],
    [
        "34",
        "2",
        "26.162",
        "17.422",
        "1.761",
        "2.005",
        "-0.039"
    ],
    [
        "35",
        "2",
        "26.861",
        "17.248",
        "1.841",
        "2.071",
        "-0.015"
    ],
    [
        "36",
        "2",
        "27.591",
        "17.033",
        "1.958",
        "1.954",
        "-0.017"
    ],
    [
        "37",
        "2",
        "28.292",
        "16.754",
        "1.662",
        "1.876",
        "-0.029"
    ],
    [
        "38",
        "2",
        "28.855",
        "16.411",
        "1.112",
        "1.255",
        "0.001"
    ],
    [
        "39",
        "2",
        "28.973",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "2",
        "28.983",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "2",
        "28.992",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "2",
        "29.002",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "2",
        "29.011",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "2",
        "29.021",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "2",
        "29.030",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "2",
        "29.039",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "2",
        "29.048",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "2",
        "29.057",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "2",
        "29.065",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "2",
        "29.074",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "2",
        "29.082",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "2",
        "29.090",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "2",
        "29.098",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "2",
        "29.106",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "2",
        "29.114",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "2",
        "29.121",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "2",
        "29.129",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "2",
        "29.135",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "2",
        "29.143",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "2",
        "29.149",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "2",
        "29.156",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "2",
        "29.163",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "2",
        "29.168",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "2",
        "29.175",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "2",
        "29.181",
        "9.581",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "2",
        "29.187",
        "9.277",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "2",
        "44.226",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "2",
        "44.245",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "2",
        "44.267",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "2",
        "44.287",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "2",
        "44.308",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "2",
        "44.329",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "2",
        "44.349",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "2",
        "44.370",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "2",
        "44.391",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "2",
        "44.412",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "2",
        "44.432",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "2",
        "44.454",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "2",
        "44.475",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "2",
        "44.495",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "2",
        "44.516",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "2",
        "44.537",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "2",
        "44.558",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "2",
        "44.579",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "2",
        "44.381",
        "34.969",
        "1.270",
        "1.491",
        "0.013"
    ],
    [
        "86",
        "2",
        "44.227",
        "35.658",
        "1.423",
        "1.602",
        "0.005"
    ],
    [
        "87",
        "2",
        "44.107",
        "36.114",
        "1.548",
        "1.551",
        "0.012"
    ],
    [
        "88",
        "2",
        "44.018",
        "36.468",
        "1.538",
        "1.611",
        "0.018"
    ],
    [
        "89",
        "2",
        "43.933",
        "36.828",
        "1.614",
        "1.658",
        "0.042"
    ],
    [
        "90",
        "2",
        "43.845",
        "37.362",
        "1.783",
        "1.730",
        "0.083"
    ],
    [
        "91",
        "2",
        "43.797",
        "38.010",
        "1.874",
        "2.103",
        "0.130"
    ],
    [
        "92",
        "2",
        "43.765",
        "38.076",
        "2.167",
        "3.007",
        "0.173"
    ],
    [
        "93",
        "2",
        "43.511",
        "37.221",
        "2.971",
        "4.648",
        "0.219"
    ],
    [
        "94",
        "2",
        "42.725",
        "35.542",
        "3.877",
        "7.014",
        "0.258"
    ],
    [
        "95",
        "2",
        "41.097",
        "32.753",
        "4.885",
        "9.270",
        "0.295"
    ],
    [
        "96",
        "2",
        "38.556",
        "28.824",
        "5.952",
        "10.289",
        "0.403"
    ],
    [
        "97",
        "2",
        "35.737",
        "25.797",
        "4.679",
        "7.741",
        "0.362"
    ],
    [
        "98",
        "2",
        "31.564",
        "16.047",
        "3.811",
        "5.627",
        "0.365"
    ],
    [
        "99",
        "2",
        "31.212",
        "10.632",
        "1.911",
        "1.791",
        "0.192"
    ],
    [
        "100",
        "2",
        "31.615",
        "-4.479",
        "0.719",
        "1.233",
        "0.313"
    ],
    [
        "101",
        "2",
        "31.634",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "2",
        "31.631",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "2",
        "31.627",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "2",
        "31.623",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "2",
        "31.619",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "2",
        "31.614",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "2",
        "31.610",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "2",
        "31.604",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "2",
        "31.599",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "2",
        "31.593",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "2",
        "31.586",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "2",
        "31.580",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "2",
        "31.573",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "2",
        "31.566",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "2",
        "31.559",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "2",
        "31.551",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "2",
        "31.543",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "2",
        "30.861",
        "-14.025",
        "2.587",
        "4.919",
        "-0.084"
    ],
    [
        "119",
        "2",
        "29.678",
        "-13.271",
        "3.796",
        "7.325",
        "0.001"
    ],
    [
        "120",
        "2",
        "28.837",
        "-11.073",
        "5.064",
        "8.254",
        "0.024"
    ],
    [
        "121",
        "2",
        "29.252",
        "-9.627",
        "5.158",
        "9.144",
        "0.122"
    ],
    [
        "122",
        "2",
        "29.122",
        "-9.871",
        "5.235",
        "9.102",
        "0.219"
    ],
    [
        "123",
        "2",
        "26.759",
        "-11.053",
        "5.857",
        "8.600",
        "0.120"
    ],
    [
        "124",
        "2",
        "22.047",
        "-12.730",
        "6.152",
        "7.871",
        "0.030"
    ],
    [
        "125",
        "2",
        "16.778",
        "-12.927",
        "6.204",
        "6.751",
        "0.040"
    ],
    [
        "126",
        "2",
        "-2.791",
        "-2.710",
        "7.609",
        "6.210",
        "-0.366"
    ],
    [
        "127",
        "2",
        "-33.001",
        "7.874",
        "6.811",
        "4.684",
        "-0.282"
    ],
    [
        "128",
        "2",
        "-54.871",
        "17.258",
        "5.126",
        "3.749",
        "-0.358"
    ],
    [
        "129",
        "2",
        "-67.357",
        "25.030",
        "3.375",
        "2.485",
        "-0.429"
    ],
    [
        "130",
        "2",
        "-69.969",
        "26.390",
        "0.619",
        "0.695",
        "-0.187"
    ],
    [
        "131",
        "2",
        "-70.025",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "2",
        "-70.009",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "2",
        "-67.227",
        "20.253",
        "0.504",
        "1.071",
        "0.229"
    ],
    [
        "134",
        "2",
        "-66.890",
        "19.970",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "2",
        "-66.878",
        "19.989",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "2",
        "-66.866",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "2",
        "-66.854",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "2",
        "-66.841",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "2",
        "-66.830",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "2",
        "-66.817",
        "19.988",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "2",
        "-66.805",
        "19.970",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "2",
        "-66.793",
        "19.945",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "2",
        "-66.780",
        "19.914",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "2",
        "-66.768",
        "19.878",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "2",
        "-66.756",
        "19.835",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "2",
        "-66.745",
        "19.786",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "2",
        "-66.733",
        "19.731",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "2",
        "-66.721",
        "19.671",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "2",
        "-66.480",
        "20.003",
        "0.819",
        "0.870",
        "0.083"
    ],
    [
        "150",
        "2",
        "-66.099",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "2",
        "-66.086",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "2",
        "-66.073",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "2",
        "-66.060",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "2",
        "-66.046",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "2",
        "-66.033",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "2",
        "-66.019",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "2",
        "-66.004",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "2",
        "-65.990",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "2",
        "-65.975",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "2",
        "-65.961",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "2",
        "-65.946",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "2",
        "-65.930",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "2",
        "-65.915",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "2",
        "-65.900",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "2",
        "-65.884",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "2",
        "-65.868",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "2",
        "-65.852",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "2",
        "-65.836",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "2",
        "-65.819",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "2",
        "-65.803",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "2",
        "-65.786",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "2",
        "-65.769",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "2",
        "-65.752",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "2",
        "-65.735",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "2",
        "-65.718",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "2",
        "-65.700",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "2",
        "-65.683",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "2",
        "-65.665",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "2",
        "-65.647",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "3",
        "-65.742",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "3",
        "-65.715",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "3",
        "-65.688",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "3",
        "-65.661",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "3",
        "-65.634",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "3",
        "-65.606",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "3",
        "-65.579",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "3",
        "-65.551",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "3",
        "-65.523",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "3",
        "-65.495",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "3",
        "-65.468",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "3",
        "-65.440",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "3",
        "-65.412",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "3",
        "-65.383",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "3",
        "-65.355",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "3",
        "-65.327",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "3",
        "-65.299",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "3",
        "-65.271",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "3",
        "-65.243",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "3",
        "-65.215",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "3",
        "-65.186",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "3",
        "-65.158",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "3",
        "-65.130",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "3",
        "-65.102",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "3",
        "-65.074",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "3",
        "-65.046",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "3",
        "-65.018",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "3",
        "-64.990",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "3",
        "-64.963",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "3",
        "-64.935",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "3",
        "-64.907",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "3",
        "-64.880",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "3",
        "-64.853",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "3",
        "-64.825",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "3",
        "-64.798",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "3",
        "-64.771",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "3",
        "-64.745",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "3",
        "-64.718",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "3",
        "-64.691",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "3",
        "-64.665",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "3",
        "-64.639",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "3",
        "-64.613",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "3",
        "-64.587",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "3",
        "-64.562",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "3",
        "-64.536",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "3",
        "-64.511",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "3",
        "-64.486",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "3",
        "-64.462",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "3",
        "-64.437",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "3",
        "-64.413",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "3",
        "-64.389",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "3",
        "-64.365",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "3",
        "-64.342",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "3",
        "-64.319",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "3",
        "-64.296",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "3",
        "-64.274",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "3",
        "-64.251",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "3",
        "-64.229",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "3",
        "-64.208",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "3",
        "-64.187",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "3",
        "-64.166",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "3",
        "-64.145",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "3",
        "-64.125",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "3",
        "-64.105",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "3",
        "-64.085",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "3",
        "-64.066",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "3",
        "-64.047",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "3",
        "-64.028",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "3",
        "-64.010",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "3",
        "-63.993",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "3",
        "-63.975",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "3",
        "-63.958",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "3",
        "-63.942",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "3",
        "-63.925",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "3",
        "-63.910",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "3",
        "-63.894",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "3",
        "-63.879",
        "16.157",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "3",
        "-63.865",
        "15.696",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "3",
        "64.435",
        "38.347",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "3",
        "64.471",
        "40.896",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "3",
        "64.509",
        "43.433",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "3",
        "64.550",
        "45.958",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "3",
        "64.594",
        "48.468",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "3",
        "64.640",
        "50.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "3",
        "64.687",
        "53.443",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "3",
        "64.737",
        "55.906",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "3",
        "64.789",
        "58.353",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "3",
        "64.843",
        "60.781",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "3",
        "64.899",
        "63.191",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "3",
        "64.959",
        "65.582",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "3",
        "65.019",
        "67.952",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "3",
        "65.083",
        "70.303",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "3",
        "65.149",
        "72.632",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "3",
        "65.216",
        "74.939",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "3",
        "65.285",
        "77.222",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "3",
        "64.811",
        "79.993",
        "2.201",
        "6.487",
        "-0.032"
    ],
    [
        "-84",
        "3",
        "57.790",
        "42.320",
        "10.542",
        "18.073",
        "-0.078"
    ],
    [
        "-83",
        "3",
        "51.592",
        "35.267",
        "9.361",
        "18.960",
        "-0.031"
    ],
    [
        "-82",
        "3",
        "48.566",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "3",
        "48.580",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "3",
        "48.593",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "3",
        "27.408",
        "16.414",
        "8.587",
        "6.012",
        "-0.374"
    ],
    [
        "-78",
        "3",
        "5.154",
        "16.269",
        "7.323",
        "5.539",
        "-0.483"
    ],
    [
        "-77",
        "3",
        "-2.136",
        "14.682",
        "3.965",
        "3.373",
        "-0.360"
    ],
    [
        "-76",
        "3",
        "-3.012",
        "12.464",
        "2.671",
        "2.474",
        "-0.256"
    ],
    [
        "-75",
        "3",
        "-3.413",
        "9.842",
        "2.092",
        "1.938",
        "-0.064"
    ],
    [
        "-74",
        "3",
        "-3.950",
        "8.798",
        "1.707",
        "1.876",
        "-0.093"
    ],
    [
        "-73",
        "3",
        "-4.039",
        "8.836",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "3",
        "-4.031",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "3",
        "-4.023",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "3",
        "-4.014",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "3",
        "-4.006",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "3",
        "-3.997",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "3",
        "-3.988",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "3",
        "-3.979",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "3",
        "-3.971",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "3",
        "-3.961",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "3",
        "-3.952",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "3",
        "-3.943",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "3",
        "-3.933",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "3",
        "-3.924",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "3",
        "-3.914",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "3",
        "-3.904",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "3",
        "-3.894",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "3",
        "-3.884",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "3",
        "-3.874",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "3",
        "-3.864",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "3",
        "-3.854",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "3",
        "-3.843",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "3",
        "-3.832",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "3",
        "-3.822",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "3",
        "-3.812",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "3",
        "-3.801",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "3",
        "-3.790",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "3",
        "-3.780",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "3",
        "-3.768",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "3",
        "-3.757",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "3",
        "-3.747",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "3",
        "-3.735",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "3",
        "-3.725",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "3",
        "-3.713",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "3",
        "-3.702",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "3",
        "-3.692",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "3",
        "-3.680",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "3",
        "-3.668",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "3",
        "-3.657",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "3",
        "-3.646",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "3",
        "-3.635",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "3",
        "-3.623",
        "12.420",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "3",
        "23.454",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "3",
        "23.469",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "3",
        "23.482",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "3",
        "23.496",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "3",
        "23.510",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "3",
        "23.525",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "3",
        "23.539",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "3",
        "23.554",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "3",
        "23.569",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "3",
        "23.584",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "3",
        "23.600",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "3",
        "23.614",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "3",
        "23.630",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "3",
        "23.646",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "3",
        "23.662",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "3",
        "23.678",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "3",
        "23.694",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "3",
        "23.710",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "3",
        "23.727",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "3",
        "23.743",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "3",
        "23.760",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "3",
        "23.776",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "3",
        "23.793",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "3",
        "23.811",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "3",
        "23.827",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "3",
        "23.845",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "3",
        "23.862",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "3",
        "23.880",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "3",
        "23.896",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "3",
        "23.914",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "3",
        "23.931",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "3",
        "23.950",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "3",
        "23.967",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "3",
        "23.985",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "3",
        "24.002",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "3",
        "24.020",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "3",
        "24.038",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "3",
        "24.056",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "3",
        "24.073",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "3",
        "24.092",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "3",
        "24.109",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "3",
        "24.127",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "3",
        "24.145",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "3",
        "24.163",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "3",
        "24.181",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "3",
        "24.199",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "3",
        "24.217",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "3",
        "24.234",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "3",
        "24.251",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "3",
        "24.269",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "3",
        "24.287",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "3",
        "24.305",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "3",
        "24.323",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "3",
        "24.340",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "3",
        "24.358",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "3",
        "24.375",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "3",
        "24.392",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "3",
        "24.409",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "3",
        "24.426",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "3",
        "24.443",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "3",
        "24.460",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "3",
        "24.477",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "3",
        "24.493",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "3",
        "24.796",
        "17.923",
        "1.074",
        "1.426",
        "-0.160"
    ],
    [
        "33",
        "3",
        "25.262",
        "17.673",
        "1.471",
        "1.702",
        "-0.105"
    ],
    [
        "34",
        "3",
        "25.891",
        "17.485",
        "1.592",
        "1.917",
        "-0.062"
    ],
    [
        "35",
        "3",
        "26.695",
        "17.313",
        "1.698",
        "1.955",
        "-0.048"
    ],
    [
        "36",
        "3",
        "27.589",
        "17.090",
        "1.817",
        "1.814",
        "-0.066"
    ],
    [
        "37",
        "3",
        "28.470",
        "16.757",
        "1.495",
        "1.674",
        "-0.067"
    ],
    [
        "38",
        "3",
        "29.135",
        "16.415",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "3",
        "29.150",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "3",
        "29.164",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "3",
        "29.179",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "3",
        "29.194",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "3",
        "29.208",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "3",
        "29.222",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "3",
        "29.236",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "3",
        "29.249",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "3",
        "29.262",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "3",
        "29.276",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "3",
        "29.288",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "3",
        "29.301",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "3",
        "29.314",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "3",
        "29.326",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "3",
        "29.337",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "3",
        "29.350",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "3",
        "29.361",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "3",
        "29.372",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "3",
        "29.383",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "3",
        "29.394",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "3",
        "29.404",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "3",
        "29.415",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "3",
        "29.424",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "3",
        "29.434",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "3",
        "29.443",
        "10.181",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "3",
        "29.453",
        "9.882",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "3",
        "31.295",
        "13.044",
        "2.631",
        "4.867",
        "0.318"
    ],
    [
        "66",
        "3",
        "44.051",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "3",
        "44.082",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "3",
        "44.113",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "3",
        "44.144",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "3",
        "44.175",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "3",
        "44.207",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "3",
        "44.237",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "3",
        "44.269",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "3",
        "44.300",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "3",
        "44.331",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "3",
        "44.363",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "3",
        "44.393",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "3",
        "44.425",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "3",
        "44.456",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "3",
        "44.487",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "3",
        "44.519",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "3",
        "44.550",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "3",
        "44.581",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "3",
        "44.612",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "3",
        "44.289",
        "34.615",
        "1.305",
        "1.527",
        "0.004"
    ],
    [
        "86",
        "3",
        "44.067",
        "35.142",
        "1.417",
        "1.611",
        "0.012"
    ],
    [
        "87",
        "3",
        "43.898",
        "35.458",
        "1.503",
        "1.543",
        "0.025"
    ],
    [
        "88",
        "3",
        "43.775",
        "35.659",
        "1.476",
        "1.586",
        "0.043"
    ],
    [
        "89",
        "3",
        "43.669",
        "35.898",
        "1.560",
        "1.642",
        "0.078"
    ],
    [
        "90",
        "3",
        "43.602",
        "36.305",
        "1.772",
        "1.802",
        "0.133"
    ],
    [
        "91",
        "3",
        "43.690",
        "36.433",
        "2.055",
        "2.503",
        "0.179"
    ],
    [
        "92",
        "3",
        "43.637",
        "35.361",
        "2.755",
        "4.191",
        "0.168"
    ],
    [
        "93",
        "3",
        "42.918",
        "32.945",
        "4.331",
        "6.498",
        "0.193"
    ],
    [
        "94",
        "3",
        "41.335",
        "29.526",
        "5.241",
        "9.090",
        "0.236"
    ],
    [
        "95",
        "3",
        "39.159",
        "25.635",
        "5.743",
        "10.369",
        "0.275"
    ],
    [
        "96",
        "3",
        "36.871",
        "21.142",
        "5.893",
        "9.822",
        "0.365"
    ],
    [
        "97",
        "3",
        "34.846",
        "14.958",
        "4.647",
        "8.034",
        "0.388"
    ],
    [
        "98",
        "3",
        "33.017",
        "7.014",
        "3.436",
        "5.282",
        "0.439"
    ],
    [
        "99",
        "3",
        "32.150",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "3",
        "32.146",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "3",
        "32.141",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "3",
        "32.136",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "3",
        "32.131",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "3",
        "32.125",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "3",
        "32.118",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "3",
        "32.111",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "3",
        "32.104",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "3",
        "32.096",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "3",
        "32.088",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "3",
        "32.079",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "3",
        "32.069",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "3",
        "32.059",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "3",
        "32.050",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "3",
        "32.039",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "3",
        "32.028",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "3",
        "32.016",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "3",
        "32.004",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "3",
        "30.872",
        "-14.507",
        "1.608",
        "2.727",
        "0.101"
    ],
    [
        "119",
        "3",
        "30.231",
        "-15.151",
        "2.199",
        "3.913",
        "0.141"
    ],
    [
        "120",
        "3",
        "29.697",
        "-15.717",
        "2.943",
        "4.433",
        "0.145"
    ],
    [
        "121",
        "3",
        "29.130",
        "-16.258",
        "3.130",
        "5.008",
        "0.223"
    ],
    [
        "122",
        "3",
        "28.470",
        "-17.014",
        "3.374",
        "5.262",
        "0.334"
    ],
    [
        "123",
        "3",
        "27.111",
        "-17.761",
        "4.029",
        "5.294",
        "0.226"
    ],
    [
        "124",
        "3",
        "23.765",
        "-17.746",
        "4.815",
        "5.399",
        "0.108"
    ],
    [
        "125",
        "3",
        "16.001",
        "-15.134",
        "5.959",
        "5.459",
        "-0.006"
    ],
    [
        "126",
        "3",
        "-1.032",
        "-6.560",
        "7.683",
        "5.330",
        "-0.267"
    ],
    [
        "127",
        "3",
        "-28.525",
        "5.877",
        "6.976",
        "4.761",
        "-0.300"
    ],
    [
        "128",
        "3",
        "-53.634",
        "18.310",
        "5.657",
        "3.673",
        "-0.346"
    ],
    [
        "129",
        "3",
        "-67.323",
        "26.086",
        "3.368",
        "2.103",
        "-0.324"
    ],
    [
        "130",
        "3",
        "-69.037",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "3",
        "-69.013",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "3",
        "-68.990",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "3",
        "-67.099",
        "20.098",
        "0.409",
        "0.677",
        "0.122"
    ],
    [
        "134",
        "3",
        "-66.860",
        "19.970",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "3",
        "-66.842",
        "19.989",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "3",
        "-66.824",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "3",
        "-66.805",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "3",
        "-66.787",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "3",
        "-66.769",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "3",
        "-66.751",
        "19.988",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "3",
        "-66.733",
        "19.970",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "3",
        "-66.714",
        "19.945",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "3",
        "-66.696",
        "19.914",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "3",
        "-66.678",
        "19.878",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "3",
        "-66.659",
        "19.835",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "3",
        "-66.642",
        "19.786",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "3",
        "-66.623",
        "19.731",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "3",
        "-66.605",
        "19.671",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "3",
        "-66.527",
        "20.180",
        "0.835",
        "0.836",
        "0.100"
    ],
    [
        "150",
        "3",
        "-66.446",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "3",
        "-66.427",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "3",
        "-66.407",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "3",
        "-66.388",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "3",
        "-66.367",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "3",
        "-66.347",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "3",
        "-66.326",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "3",
        "-66.305",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "3",
        "-66.283",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "3",
        "-66.261",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "3",
        "-66.239",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "3",
        "-66.217",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "3",
        "-66.194",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "3",
        "-66.171",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "3",
        "-66.147",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "3",
        "-66.124",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "3",
        "-66.100",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "3",
        "-66.076",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "3",
        "-66.051",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "3",
        "-66.027",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "3",
        "-66.002",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "3",
        "-65.977",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "3",
        "-65.952",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "3",
        "-65.926",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "3",
        "-65.900",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "3",
        "-65.874",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "3",
        "-65.848",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "3",
        "-65.822",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "3",
        "-65.796",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "3",
        "-65.769",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "4",
        "-65.835",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "4",
        "-65.799",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "4",
        "-65.763",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "4",
        "-65.727",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "4",
        "-65.690",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "4",
        "-65.654",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "4",
        "-65.617",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "4",
        "-65.580",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "4",
        "-65.543",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "4",
        "-65.506",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "4",
        "-65.469",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "4",
        "-65.432",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "4",
        "-65.394",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "4",
        "-65.357",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "4",
        "-65.319",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "4",
        "-65.282",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "4",
        "-65.244",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "4",
        "-65.207",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "4",
        "-65.169",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "4",
        "-65.132",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "4",
        "-65.094",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "4",
        "-65.057",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "4",
        "-65.019",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "4",
        "-64.982",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "4",
        "-64.944",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "4",
        "-64.907",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "4",
        "-64.870",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "4",
        "-64.833",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "4",
        "-64.796",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "4",
        "-64.759",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "4",
        "-64.722",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "4",
        "-64.686",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "4",
        "-64.649",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "4",
        "-64.613",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "4",
        "-64.577",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "4",
        "-64.541",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "4",
        "-64.505",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "4",
        "-64.470",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "4",
        "-64.434",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "4",
        "-64.399",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "4",
        "-64.364",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "4",
        "-64.330",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "4",
        "-64.296",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "4",
        "-64.261",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "4",
        "-64.228",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "4",
        "-64.194",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "4",
        "-64.161",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "4",
        "-64.128",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "4",
        "-64.096",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "4",
        "-64.063",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "4",
        "-64.032",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "4",
        "-64.000",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "4",
        "-63.969",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "4",
        "-63.938",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "4",
        "-63.908",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "4",
        "-63.878",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "4",
        "-63.848",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "4",
        "-63.819",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "4",
        "-63.790",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "4",
        "-63.762",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "4",
        "-63.734",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "4",
        "-63.706",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "4",
        "-63.679",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "4",
        "-63.652",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "4",
        "-63.626",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "4",
        "-63.601",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "4",
        "-63.576",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "4",
        "-63.551",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "4",
        "-63.527",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "4",
        "-63.503",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "4",
        "-63.480",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "4",
        "-63.457",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "4",
        "-63.435",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "4",
        "-63.413",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "4",
        "-63.392",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "4",
        "-63.372",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "4",
        "-63.352",
        "16.157",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "4",
        "-63.333",
        "15.696",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "4",
        "61.807",
        "38.347",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "4",
        "61.855",
        "40.896",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "4",
        "61.907",
        "43.433",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "4",
        "61.961",
        "45.958",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "4",
        "62.019",
        "48.468",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "4",
        "62.079",
        "50.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "4",
        "62.143",
        "53.443",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "4",
        "62.210",
        "55.906",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "4",
        "62.279",
        "58.353",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "4",
        "62.351",
        "60.781",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "4",
        "62.427",
        "63.191",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "4",
        "62.506",
        "65.582",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "4",
        "62.586",
        "67.952",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "4",
        "62.670",
        "70.303",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "4",
        "62.758",
        "72.632",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "4",
        "62.847",
        "74.939",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "4",
        "62.941",
        "77.222",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "4",
        "63.035",
        "79.483",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "4",
        "63.134",
        "81.719",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "4",
        "63.234",
        "83.930",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "4",
        "47.675",
        "13.793",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "4",
        "47.693",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "4",
        "47.711",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "4",
        "45.366",
        "16.945",
        "1.878",
        "2.215",
        "-0.221"
    ],
    [
        "-78",
        "4",
        "18.406",
        "17.242",
        "7.237",
        "5.962",
        "-0.458"
    ],
    [
        "-77",
        "4",
        "3.469",
        "14.269",
        "3.179",
        "1.917",
        "-0.153"
    ],
    [
        "-76",
        "4",
        "-0.120",
        "13.894",
        "2.717",
        "2.405",
        "-0.266"
    ],
    [
        "-75",
        "4",
        "-0.887",
        "11.105",
        "2.249",
        "2.021",
        "-0.137"
    ],
    [
        "-74",
        "4",
        "-2.747",
        "9.647",
        "1.765",
        "1.740",
        "-0.071"
    ],
    [
        "-73",
        "4",
        "-4.188",
        "8.836",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "4",
        "-4.177",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "4",
        "-4.167",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "4",
        "-4.155",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "4",
        "-4.144",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "4",
        "-4.133",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "4",
        "-4.121",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "4",
        "-4.109",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "4",
        "-4.096",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "4",
        "-4.084",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "4",
        "-4.072",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "4",
        "-4.060",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "4",
        "-4.047",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "4",
        "-4.033",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "4",
        "-4.021",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "4",
        "-4.008",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "4",
        "-3.994",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "4",
        "-3.981",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "4",
        "-3.968",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "4",
        "-3.954",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "4",
        "-3.940",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "4",
        "-3.926",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "4",
        "-3.913",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "4",
        "-3.899",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "4",
        "-3.884",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "4",
        "-3.870",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "4",
        "-3.856",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "4",
        "-3.842",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "4",
        "-3.828",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "4",
        "-3.812",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "4",
        "-3.798",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "4",
        "-3.783",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "4",
        "-3.768",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "4",
        "-3.754",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "4",
        "-3.739",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "4",
        "-3.724",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "4",
        "-3.709",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "4",
        "-3.694",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "4",
        "-3.679",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "4",
        "-3.664",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "4",
        "-3.649",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "4",
        "8.188",
        "13.547",
        "2.999",
        "2.085",
        "-0.037"
    ],
    [
        "-31",
        "4",
        "23.206",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "4",
        "23.225",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "4",
        "23.243",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "4",
        "23.261",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "4",
        "23.281",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "4",
        "23.300",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "4",
        "23.319",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "4",
        "23.339",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "4",
        "23.359",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "4",
        "23.380",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "4",
        "23.399",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "4",
        "23.420",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "4",
        "23.441",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "4",
        "23.462",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "4",
        "23.483",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "4",
        "23.505",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "4",
        "23.526",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "4",
        "23.547",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "4",
        "23.569",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "4",
        "23.592",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "4",
        "23.614",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "4",
        "23.636",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "4",
        "23.658",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "4",
        "23.681",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "4",
        "23.704",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "4",
        "23.726",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "4",
        "23.750",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "4",
        "23.773",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "4",
        "23.796",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "4",
        "23.819",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "4",
        "23.842",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "4",
        "23.866",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "4",
        "23.890",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "4",
        "23.913",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "4",
        "23.936",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "4",
        "23.960",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "4",
        "23.984",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "4",
        "24.008",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "4",
        "24.032",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "4",
        "24.055",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "4",
        "24.079",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "4",
        "24.103",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "4",
        "24.126",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "4",
        "24.151",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "4",
        "24.175",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "4",
        "24.198",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "4",
        "24.222",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "4",
        "24.245",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "4",
        "24.270",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "4",
        "24.293",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "4",
        "24.316",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "4",
        "24.340",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "4",
        "24.363",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "4",
        "24.386",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "4",
        "24.410",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "4",
        "24.433",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "4",
        "24.456",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "4",
        "24.479",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "4",
        "24.502",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "4",
        "24.524",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "4",
        "24.547",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "4",
        "24.569",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "4",
        "24.591",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "4",
        "24.784",
        "17.962",
        "0.918",
        "1.342",
        "-0.133"
    ],
    [
        "33",
        "4",
        "25.113",
        "17.698",
        "1.246",
        "1.575",
        "-0.112"
    ],
    [
        "34",
        "4",
        "25.641",
        "17.504",
        "1.352",
        "1.735",
        "-0.108"
    ],
    [
        "35",
        "4",
        "26.441",
        "17.341",
        "1.457",
        "1.720",
        "-0.111"
    ],
    [
        "36",
        "4",
        "27.473",
        "17.141",
        "1.577",
        "1.550",
        "-0.160"
    ],
    [
        "37",
        "4",
        "28.548",
        "16.804",
        "1.291",
        "1.390",
        "-0.165"
    ],
    [
        "38",
        "4",
        "29.298",
        "16.415",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "4",
        "29.318",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "4",
        "29.338",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "4",
        "29.358",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "4",
        "29.376",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "4",
        "29.395",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "4",
        "29.413",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "4",
        "29.432",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "4",
        "29.450",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "4",
        "29.468",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "4",
        "29.485",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "4",
        "29.502",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "4",
        "29.519",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "4",
        "29.536",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "4",
        "29.553",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "4",
        "29.568",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "4",
        "29.583",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "4",
        "29.599",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "4",
        "29.614",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "4",
        "29.628",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "4",
        "29.643",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "4",
        "29.657",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "4",
        "29.670",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "4",
        "29.684",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "4",
        "29.697",
        "10.476",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "4",
        "34.667",
        "18.160",
        "9.021",
        "9.813",
        "0.526"
    ],
    [
        "64",
        "4",
        "43.804",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "4",
        "43.844",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "4",
        "43.885",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "4",
        "43.927",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "4",
        "43.967",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "4",
        "44.009",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "4",
        "44.050",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "4",
        "44.091",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "4",
        "44.133",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "4",
        "44.174",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "4",
        "44.216",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "4",
        "44.258",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "4",
        "44.300",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "4",
        "44.341",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "4",
        "44.382",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "4",
        "44.424",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "4",
        "44.467",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "4",
        "44.507",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "4",
        "44.550",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "4",
        "44.591",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "4",
        "44.633",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "4",
        "44.388",
        "34.333",
        "1.216",
        "1.425",
        "-0.003"
    ],
    [
        "86",
        "4",
        "44.142",
        "34.691",
        "1.374",
        "1.563",
        "0.017"
    ],
    [
        "87",
        "4",
        "43.946",
        "34.859",
        "1.453",
        "1.475",
        "0.025"
    ],
    [
        "88",
        "4",
        "43.783",
        "34.932",
        "1.402",
        "1.512",
        "0.047"
    ],
    [
        "89",
        "4",
        "43.655",
        "35.056",
        "1.511",
        "1.625",
        "0.096"
    ],
    [
        "90",
        "4",
        "43.654",
        "35.175",
        "1.811",
        "1.933",
        "0.137"
    ],
    [
        "91",
        "4",
        "43.809",
        "34.532",
        "2.376",
        "3.036",
        "0.105"
    ],
    [
        "92",
        "4",
        "43.402",
        "32.002",
        "3.548",
        "5.349",
        "0.055"
    ],
    [
        "93",
        "4",
        "41.642",
        "27.175",
        "5.664",
        "7.642",
        "0.090"
    ],
    [
        "94",
        "4",
        "38.722",
        "21.306",
        "6.275",
        "9.819",
        "0.144"
    ],
    [
        "95",
        "4",
        "36.020",
        "15.450",
        "6.051",
        "9.831",
        "0.184"
    ],
    [
        "96",
        "4",
        "34.414",
        "9.187",
        "5.144",
        "8.116",
        "0.251"
    ],
    [
        "97",
        "4",
        "33.832",
        "3.370",
        "3.307",
        "6.062",
        "0.367"
    ],
    [
        "98",
        "4",
        "33.307",
        "-2.164",
        "1.862",
        "2.879",
        "0.417"
    ],
    [
        "99",
        "4",
        "32.650",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "4",
        "32.645",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "4",
        "32.638",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "4",
        "32.632",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "4",
        "32.625",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "4",
        "32.616",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "4",
        "32.608",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "4",
        "32.599",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "4",
        "32.589",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "4",
        "32.578",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "4",
        "32.567",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "4",
        "32.556",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "4",
        "32.543",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "4",
        "32.530",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "4",
        "32.516",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "4",
        "32.502",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "4",
        "32.487",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "4",
        "32.472",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "4",
        "32.456",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "4",
        "29.322",
        "-19.342",
        "0.922",
        "0.936",
        "0.024"
    ],
    [
        "119",
        "4",
        "30.571",
        "-16.240",
        "1.314",
        "1.815",
        "0.104"
    ],
    [
        "120",
        "4",
        "30.206",
        "-16.340",
        "1.664",
        "2.001",
        "0.148"
    ],
    [
        "121",
        "4",
        "29.791",
        "-17.043",
        "1.822",
        "2.276",
        "0.264"
    ],
    [
        "122",
        "4",
        "29.279",
        "-18.068",
        "2.087",
        "2.572",
        "0.382"
    ],
    [
        "123",
        "4",
        "27.898",
        "-19.190",
        "2.765",
        "2.925",
        "0.317"
    ],
    [
        "124",
        "4",
        "23.839",
        "-19.153",
        "3.979",
        "3.598",
        "0.151"
    ],
    [
        "125",
        "4",
        "14.826",
        "-16.081",
        "5.669",
        "4.497",
        "-0.018"
    ],
    [
        "126",
        "4",
        "-3.899",
        "-7.576",
        "8.081",
        "4.836",
        "-0.219"
    ],
    [
        "127",
        "4",
        "-31.458",
        "5.458",
        "7.369",
        "4.823",
        "-0.272"
    ],
    [
        "128",
        "4",
        "-58.047",
        "20.788",
        "5.749",
        "3.696",
        "-0.276"
    ],
    [
        "129",
        "4",
        "-68.044",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "4",
        "-68.011",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "4",
        "-67.979",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "4",
        "-67.949",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "4",
        "-67.116",
        "20.867",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "4",
        "-66.811",
        "19.970",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "4",
        "-66.786",
        "19.989",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "4",
        "-66.761",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "4",
        "-66.737",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "4",
        "-66.713",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "4",
        "-66.689",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "4",
        "-66.664",
        "19.988",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "4",
        "-66.640",
        "19.970",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "4",
        "-66.616",
        "19.945",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "4",
        "-66.591",
        "19.914",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "4",
        "-66.567",
        "19.878",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "4",
        "-66.543",
        "19.835",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "4",
        "-66.519",
        "19.786",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "4",
        "-66.495",
        "19.731",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "4",
        "-66.559",
        "19.750",
        "0.620",
        "0.644",
        "0.166"
    ],
    [
        "149",
        "4",
        "-66.798",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "4",
        "-66.773",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "4",
        "-66.748",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "4",
        "-66.722",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "4",
        "-66.695",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "4",
        "-66.668",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "4",
        "-66.641",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "4",
        "-66.613",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "4",
        "-66.585",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "4",
        "-66.556",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "4",
        "-66.527",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "4",
        "-66.497",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "4",
        "-66.467",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "4",
        "-66.437",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "4",
        "-66.406",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "4",
        "-66.375",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "4",
        "-66.344",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "4",
        "-66.312",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "4",
        "-66.280",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "4",
        "-66.247",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "4",
        "-66.214",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "4",
        "-66.181",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "4",
        "-66.148",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "4",
        "-66.114",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "4",
        "-66.080",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "4",
        "-66.046",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "4",
        "-66.011",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "4",
        "-65.976",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "4",
        "-65.941",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "4",
        "-65.906",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "4",
        "-65.871",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "5",
        "-65.908",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "5",
        "-65.863",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "5",
        "-65.818",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "5",
        "-65.772",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "5",
        "-65.727",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "5",
        "-65.681",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "5",
        "-65.635",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "5",
        "-65.589",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "5",
        "-65.543",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "5",
        "-65.497",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "5",
        "-65.450",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "5",
        "-65.404",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "5",
        "-65.357",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "5",
        "-65.310",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "5",
        "-65.264",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "5",
        "-65.217",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "5",
        "-65.170",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "5",
        "-65.123",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "5",
        "-65.076",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "5",
        "-65.029",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "5",
        "-64.982",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "5",
        "-64.935",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "5",
        "-64.889",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "5",
        "-64.842",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "5",
        "-64.795",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "5",
        "-64.749",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "5",
        "-64.702",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "5",
        "-64.656",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "5",
        "-64.609",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "5",
        "-64.563",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "5",
        "-64.518",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "5",
        "-64.472",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "5",
        "-64.426",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "5",
        "-64.381",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "5",
        "-64.336",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "5",
        "-64.291",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "5",
        "-64.246",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "5",
        "-64.202",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "5",
        "-64.158",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "5",
        "-64.114",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "5",
        "-64.070",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "5",
        "-64.027",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "5",
        "-63.984",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "5",
        "-63.942",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "5",
        "-63.900",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "5",
        "-63.858",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "5",
        "-63.816",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "5",
        "-63.775",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "5",
        "-63.735",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "5",
        "-63.694",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "5",
        "-63.654",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "5",
        "-63.615",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "5",
        "-63.576",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "5",
        "-63.538",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "5",
        "-63.500",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "5",
        "-63.462",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "5",
        "-63.425",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "5",
        "-63.389",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "5",
        "-63.353",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "5",
        "-63.317",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "5",
        "-63.282",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "5",
        "-63.248",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "5",
        "-63.214",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "5",
        "-63.181",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "5",
        "-63.148",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "5",
        "-63.116",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "5",
        "-63.085",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "5",
        "-63.054",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "5",
        "-63.024",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "5",
        "-62.994",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "5",
        "-62.965",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "5",
        "-62.937",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "5",
        "-62.909",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "5",
        "-62.882",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "5",
        "-62.856",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "5",
        "-62.830",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "5",
        "-62.805",
        "16.157",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "5",
        "-62.781",
        "15.696",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "5",
        "59.160",
        "38.347",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "5",
        "59.221",
        "40.896",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "5",
        "59.285",
        "43.433",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "5",
        "59.353",
        "45.958",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "5",
        "59.425",
        "48.468",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "5",
        "59.500",
        "50.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "5",
        "59.580",
        "53.443",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "5",
        "59.663",
        "55.906",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "5",
        "59.750",
        "58.353",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "5",
        "59.840",
        "60.781",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "5",
        "59.934",
        "63.191",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "5",
        "60.033",
        "65.582",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "5",
        "60.134",
        "67.952",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "5",
        "60.239",
        "70.303",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "5",
        "60.348",
        "72.632",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "5",
        "60.460",
        "74.939",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "5",
        "60.576",
        "77.222",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "5",
        "60.695",
        "79.483",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "5",
        "60.818",
        "81.719",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "5",
        "60.944",
        "83.930",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "5",
        "54.759",
        "14.250",
        "8.839",
        "10.099",
        "-0.019"
    ],
    [
        "-81",
        "5",
        "46.791",
        "14.629",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "5",
        "46.814",
        "15.461",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "5",
        "46.838",
        "16.288",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "5",
        "31.527",
        "13.381",
        "6.302",
        "6.094",
        "-0.110"
    ],
    [
        "-77",
        "5",
        "13.655",
        "12.242",
        "4.282",
        "4.342",
        "-0.199"
    ],
    [
        "-76",
        "5",
        "7.182",
        "12.741",
        "2.710",
        "2.573",
        "-0.160"
    ],
    [
        "-75",
        "5",
        "4.096",
        "11.820",
        "2.079",
        "1.810",
        "-0.076"
    ],
    [
        "-74",
        "5",
        "0.836",
        "10.825",
        "1.421",
        "1.068",
        "-0.023"
    ],
    [
        "-73",
        "5",
        "-3.186",
        "8.895",
        "1.152",
        "1.022",
        "-0.072"
    ],
    [
        "-72",
        "5",
        "-4.322",
        "8.988",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "5",
        "-4.309",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "5",
        "-4.294",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "5",
        "-4.280",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "5",
        "-4.266",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "5",
        "-4.251",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "5",
        "-4.236",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "5",
        "-4.221",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "5",
        "-4.206",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "5",
        "-4.190",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "5",
        "-4.175",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "5",
        "-4.159",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "5",
        "-4.143",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "5",
        "-4.127",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "5",
        "-4.111",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "5",
        "-4.094",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "5",
        "-4.078",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "5",
        "-4.060",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "5",
        "-4.044",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "5",
        "-4.027",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "5",
        "-4.009",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "5",
        "-3.992",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "5",
        "-3.974",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "5",
        "-3.956",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "5",
        "-3.938",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "5",
        "-3.921",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "5",
        "-3.903",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "5",
        "-3.885",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "5",
        "-3.867",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "5",
        "-3.848",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "5",
        "-3.830",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "5",
        "-3.812",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "5",
        "-3.793",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "5",
        "-3.774",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "5",
        "-3.756",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "5",
        "-3.737",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "5",
        "-3.719",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "5",
        "-3.700",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "5",
        "-3.681",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "5",
        "-3.662",
        "12.404",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "5",
        "22.929",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "5",
        "22.951",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "5",
        "22.974",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "5",
        "22.997",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "5",
        "23.020",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "5",
        "23.044",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "5",
        "23.068",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "5",
        "23.092",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "5",
        "23.117",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "5",
        "23.142",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "5",
        "23.167",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "5",
        "23.193",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "5",
        "23.218",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "5",
        "23.244",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "5",
        "23.270",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "5",
        "23.297",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "5",
        "23.323",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "5",
        "23.351",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "5",
        "23.377",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "5",
        "23.405",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "5",
        "23.432",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "5",
        "23.460",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "5",
        "23.488",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "5",
        "23.516",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "5",
        "23.544",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "5",
        "23.572",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "5",
        "23.602",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "5",
        "23.629",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "5",
        "23.659",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "5",
        "23.688",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "5",
        "23.717",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "5",
        "23.746",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "5",
        "23.775",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "5",
        "23.804",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "5",
        "23.834",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "5",
        "23.864",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "5",
        "23.894",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "5",
        "23.923",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "5",
        "23.953",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "5",
        "23.982",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "5",
        "24.012",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "5",
        "24.041",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "5",
        "24.071",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "5",
        "24.101",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "5",
        "24.131",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "5",
        "24.161",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "5",
        "24.190",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "5",
        "24.220",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "5",
        "24.249",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "5",
        "24.279",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "5",
        "24.309",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "5",
        "24.338",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "5",
        "24.367",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "5",
        "24.396",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "5",
        "24.425",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "5",
        "24.454",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "5",
        "24.484",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "5",
        "24.512",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "5",
        "24.541",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "5",
        "24.569",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "5",
        "24.598",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "5",
        "24.626",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "5",
        "24.654",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "5",
        "24.681",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "5",
        "24.820",
        "17.989",
        "0.813",
        "1.236",
        "-0.136"
    ],
    [
        "33",
        "5",
        "25.009",
        "17.739",
        "1.065",
        "1.414",
        "-0.164"
    ],
    [
        "34",
        "5",
        "25.300",
        "17.549",
        "1.119",
        "1.504",
        "-0.185"
    ],
    [
        "35",
        "5",
        "25.836",
        "17.395",
        "1.205",
        "1.429",
        "-0.191"
    ],
    [
        "36",
        "5",
        "26.816",
        "17.241",
        "1.339",
        "1.258",
        "-0.256"
    ],
    [
        "37",
        "5",
        "28.313",
        "16.954",
        "1.112",
        "1.123",
        "-0.258"
    ],
    [
        "38",
        "5",
        "29.452",
        "16.415",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "5",
        "29.478",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "5",
        "29.502",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "5",
        "29.526",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "5",
        "29.550",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "5",
        "29.573",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "5",
        "29.596",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "5",
        "29.619",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "5",
        "29.642",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "5",
        "29.664",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "5",
        "29.686",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "5",
        "29.708",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "5",
        "29.729",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "5",
        "29.750",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "5",
        "29.770",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "5",
        "29.789",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "5",
        "29.809",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "5",
        "29.829",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "5",
        "29.847",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "5",
        "29.865",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "5",
        "29.883",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "5",
        "29.901",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "5",
        "29.918",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "5",
        "29.935",
        "10.768",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "5",
        "33.464",
        "16.324",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "5",
        "43.552",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "5",
        "43.604",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "5",
        "43.654",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "5",
        "43.705",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "5",
        "43.756",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "5",
        "43.807",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "5",
        "43.860",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "5",
        "43.911",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "5",
        "43.962",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "5",
        "44.015",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "5",
        "44.067",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "5",
        "44.119",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "5",
        "44.171",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "5",
        "44.223",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "5",
        "44.275",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "5",
        "44.327",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "5",
        "44.379",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "5",
        "44.431",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "5",
        "44.483",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "5",
        "44.535",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "5",
        "44.587",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "5",
        "44.639",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "5",
        "44.620",
        "34.071",
        "0.884",
        "1.038",
        "0.003"
    ],
    [
        "86",
        "5",
        "44.532",
        "34.202",
        "1.096",
        "1.347",
        "0.002"
    ],
    [
        "87",
        "5",
        "44.482",
        "34.202",
        "1.185",
        "1.238",
        "0.016"
    ],
    [
        "88",
        "5",
        "44.366",
        "34.160",
        "1.066",
        "1.259",
        "0.015"
    ],
    [
        "89",
        "5",
        "44.089",
        "34.250",
        "1.355",
        "1.596",
        "0.125"
    ],
    [
        "90",
        "5",
        "43.866",
        "34.147",
        "1.906",
        "2.155",
        "0.100"
    ],
    [
        "91",
        "5",
        "43.693",
        "33.022",
        "2.739",
        "3.609",
        "-0.001"
    ],
    [
        "92",
        "5",
        "42.655",
        "29.438",
        "4.083",
        "6.027",
        "-0.046"
    ],
    [
        "93",
        "5",
        "39.923",
        "22.642",
        "6.055",
        "7.629",
        "-0.054"
    ],
    [
        "94",
        "5",
        "36.484",
        "14.320",
        "6.163",
        "8.972",
        "-0.026"
    ],
    [
        "95",
        "5",
        "34.312",
        "6.073",
        "5.300",
        "7.819",
        "-0.014"
    ],
    [
        "96",
        "5",
        "33.905",
        "0.152",
        "4.032",
        "5.356",
        "0.212"
    ],
    [
        "97",
        "5",
        "33.267",
        "-3.040",
        "0.791",
        "1.423",
        "0.186"
    ],
    [
        "98",
        "5",
        "33.146",
        "-3.638",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "5",
        "33.140",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "5",
        "33.133",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "5",
        "33.125",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "5",
        "33.117",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "5",
        "33.108",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "5",
        "33.098",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "5",
        "33.087",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "5",
        "33.076",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "5",
        "33.064",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "5",
        "33.051",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "5",
        "33.036",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "5",
        "33.022",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "5",
        "33.006",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "5",
        "32.990",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "5",
        "32.973",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "5",
        "32.955",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "5",
        "32.937",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "5",
        "32.918",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "5",
        "32.898",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "5",
        "31.712",
        "-14.992",
        "0.884",
        "0.967",
        "-0.088"
    ],
    [
        "119",
        "5",
        "30.886",
        "-15.890",
        "1.007",
        "1.094",
        "-0.052"
    ],
    [
        "120",
        "5",
        "30.882",
        "-16.182",
        "1.204",
        "1.152",
        "0.130"
    ],
    [
        "121",
        "5",
        "30.565",
        "-17.178",
        "1.335",
        "1.423",
        "0.252"
    ],
    [
        "122",
        "5",
        "30.122",
        "-17.980",
        "1.680",
        "1.923",
        "0.309"
    ],
    [
        "123",
        "5",
        "28.231",
        "-18.500",
        "2.692",
        "2.474",
        "0.264"
    ],
    [
        "124",
        "5",
        "22.672",
        "-18.659",
        "4.159",
        "3.265",
        "0.039"
    ],
    [
        "125",
        "5",
        "12.094",
        "-15.378",
        "6.076",
        "4.411",
        "-0.103"
    ],
    [
        "126",
        "5",
        "-8.308",
        "-5.991",
        "8.776",
        "4.832",
        "-0.201"
    ],
    [
        "127",
        "5",
        "-35.911",
        "7.758",
        "7.890",
        "5.179",
        "-0.288"
    ],
    [
        "128",
        "5",
        "-65.110",
        "26.338",
        "4.552",
        "3.263",
        "-0.156"
    ],
    [
        "129",
        "5",
        "-67.006",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "5",
        "-66.964",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "5",
        "-66.925",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "5",
        "-66.887",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "5",
        "-66.758",
        "23.112",
        "1.042",
        "1.185",
        "-0.033"
    ],
    [
        "134",
        "5",
        "-66.740",
        "19.970",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "5",
        "-66.710",
        "19.989",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "5",
        "-66.679",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "5",
        "-66.649",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "5",
        "-66.618",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "5",
        "-66.587",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "5",
        "-66.558",
        "19.988",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "5",
        "-66.527",
        "19.970",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "5",
        "-66.496",
        "19.945",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "5",
        "-66.466",
        "19.914",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "5",
        "-66.436",
        "19.878",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "5",
        "-66.405",
        "19.835",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "5",
        "-66.376",
        "19.786",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "5",
        "-66.345",
        "19.731",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "5",
        "-66.612",
        "19.811",
        "0.591",
        "0.683",
        "0.229"
    ],
    [
        "149",
        "5",
        "-67.111",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "5",
        "-67.080",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "5",
        "-67.048",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "5",
        "-67.016",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "5",
        "-66.982",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "5",
        "-66.949",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "5",
        "-66.914",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "5",
        "-66.880",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "5",
        "-66.844",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "5",
        "-66.808",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "5",
        "-66.772",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "5",
        "-66.735",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "5",
        "-66.698",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "5",
        "-66.660",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "5",
        "-66.621",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "5",
        "-66.582",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "5",
        "-66.543",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "5",
        "-66.503",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "5",
        "-66.463",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "5",
        "-66.423",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "5",
        "-66.382",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "5",
        "-66.340",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "5",
        "-66.298",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "5",
        "-66.256",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "5",
        "-66.214",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "5",
        "-66.171",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "5",
        "-66.128",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "5",
        "-66.084",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "5",
        "-66.041",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "5",
        "-65.997",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "5",
        "-65.952",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "6",
        "-65.960",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "6",
        "-65.906",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "6",
        "-65.852",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "6",
        "-65.798",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "6",
        "-65.743",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "6",
        "-65.689",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "6",
        "-65.634",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "6",
        "-65.578",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "6",
        "-65.523",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "6",
        "-65.467",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "6",
        "-65.412",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "6",
        "-65.356",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "6",
        "-65.300",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "6",
        "-65.244",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "6",
        "-65.188",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "6",
        "-65.132",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "6",
        "-65.075",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "6",
        "-65.019",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "6",
        "-64.963",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "6",
        "-64.907",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "6",
        "-64.850",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "6",
        "-64.794",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "6",
        "-64.738",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "6",
        "-64.682",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "6",
        "-64.626",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "6",
        "-64.570",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "6",
        "-64.514",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "6",
        "-64.459",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "6",
        "-64.403",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "6",
        "-64.348",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "6",
        "-64.293",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "6",
        "-64.238",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "6",
        "-64.184",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "6",
        "-64.129",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "6",
        "-64.075",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "6",
        "-64.021",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "6",
        "-63.968",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "6",
        "-63.915",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "6",
        "-63.862",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "6",
        "-63.809",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "6",
        "-63.757",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "6",
        "-63.705",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "6",
        "-63.654",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "6",
        "-63.603",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "6",
        "-63.552",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "6",
        "-63.502",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "6",
        "-63.452",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "6",
        "-63.403",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "6",
        "-63.354",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "6",
        "-63.306",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "6",
        "-63.258",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "6",
        "-63.211",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "6",
        "-63.164",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "6",
        "-63.118",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "6",
        "-63.072",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "6",
        "-63.027",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "6",
        "-62.983",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "6",
        "-62.939",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "6",
        "-62.896",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "6",
        "-62.853",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "6",
        "-62.812",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "6",
        "-62.770",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "6",
        "-62.730",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "6",
        "-62.690",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "6",
        "-62.651",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "6",
        "-62.612",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "6",
        "-62.575",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "6",
        "-62.538",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "6",
        "-62.501",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "6",
        "-62.466",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "6",
        "-62.431",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "6",
        "-62.397",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "6",
        "-62.364",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "6",
        "-62.332",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "6",
        "-62.300",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "6",
        "-62.270",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "6",
        "-62.240",
        "16.157",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "6",
        "-51.316",
        "17.787",
        "5.789",
        "9.279",
        "-0.043"
    ],
    [
        "-102",
        "6",
        "56.496",
        "38.347",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "6",
        "56.568",
        "40.896",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "6",
        "56.645",
        "43.433",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "6",
        "56.727",
        "45.958",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "6",
        "56.813",
        "48.468",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "6",
        "56.904",
        "50.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "6",
        "56.999",
        "53.443",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "6",
        "57.099",
        "55.906",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "6",
        "57.203",
        "58.353",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "6",
        "57.311",
        "60.781",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "6",
        "57.424",
        "63.191",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "6",
        "57.542",
        "65.582",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "6",
        "57.663",
        "67.952",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "6",
        "57.790",
        "70.303",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "6",
        "57.920",
        "72.632",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "6",
        "58.055",
        "74.939",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "6",
        "58.194",
        "77.222",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "6",
        "58.337",
        "79.483",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "6",
        "58.483",
        "81.719",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "6",
        "58.635",
        "83.930",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "6",
        "52.955",
        "16.222",
        "9.697",
        "11.968",
        "0.011"
    ],
    [
        "-81",
        "6",
        "47.007",
        "12.378",
        "12.383",
        "10.398",
        "0.005"
    ],
    [
        "-80",
        "6",
        "39.779",
        "14.428",
        "12.128",
        "10.383",
        "0.009"
    ],
    [
        "-79",
        "6",
        "28.234",
        "16.344",
        "11.003",
        "7.464",
        "-0.026"
    ],
    [
        "-78",
        "6",
        "16.216",
        "11.084",
        "7.843",
        "7.116",
        "0.063"
    ],
    [
        "-77",
        "6",
        "8.665",
        "11.675",
        "3.941",
        "4.718",
        "-0.023"
    ],
    [
        "-76",
        "6",
        "6.464",
        "12.241",
        "3.054",
        "2.884",
        "-0.058"
    ],
    [
        "-75",
        "6",
        "5.212",
        "12.323",
        "2.471",
        "1.845",
        "-0.061"
    ],
    [
        "-74",
        "6",
        "3.097",
        "11.386",
        "2.083",
        "1.631",
        "-0.045"
    ],
    [
        "-73",
        "6",
        "-0.408",
        "9.959",
        "1.800",
        "1.348",
        "0.030"
    ],
    [
        "-72",
        "6",
        "-3.711",
        "8.983",
        "1.562",
        "1.008",
        "-0.039"
    ],
    [
        "-71",
        "6",
        "-4.449",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "6",
        "-4.433",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "6",
        "-4.416",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "6",
        "-4.398",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "6",
        "-4.380",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "6",
        "-4.363",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "6",
        "-4.345",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "6",
        "-4.327",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "6",
        "-4.308",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "6",
        "-4.289",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "6",
        "-4.270",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "6",
        "-4.251",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "6",
        "-4.232",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "6",
        "-4.212",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "6",
        "-4.192",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "6",
        "-4.172",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "6",
        "-4.152",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "6",
        "-4.132",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "6",
        "-4.111",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "6",
        "-4.090",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "6",
        "-4.069",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "6",
        "-4.048",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "6",
        "-4.027",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "6",
        "-4.006",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "6",
        "-3.985",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "6",
        "-3.963",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "6",
        "-3.941",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "6",
        "-3.919",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "6",
        "-3.897",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "6",
        "-3.875",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "6",
        "-3.853",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "6",
        "-3.831",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "6",
        "-3.809",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "6",
        "-3.787",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "6",
        "-3.764",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "6",
        "-3.742",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "6",
        "-3.719",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "6",
        "-3.697",
        "12.384",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "6",
        "22.636",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "6",
        "22.662",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "6",
        "22.689",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "6",
        "22.717",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "6",
        "22.744",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "6",
        "22.772",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "6",
        "22.801",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "6",
        "22.829",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "6",
        "22.858",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "6",
        "22.888",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "6",
        "22.918",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "6",
        "22.948",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "6",
        "22.978",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "6",
        "23.009",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "6",
        "23.040",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "6",
        "23.071",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "6",
        "23.103",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "6",
        "23.136",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "6",
        "23.168",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "6",
        "23.200",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "6",
        "23.233",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "6",
        "23.266",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "6",
        "23.299",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "6",
        "23.332",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "6",
        "23.366",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "6",
        "23.401",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "6",
        "23.435",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "6",
        "23.468",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "6",
        "23.504",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "6",
        "23.537",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "6",
        "23.572",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "6",
        "23.607",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "6",
        "23.642",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "6",
        "23.677",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "6",
        "23.713",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "6",
        "23.748",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "6",
        "23.783",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "6",
        "23.819",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "6",
        "23.854",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "6",
        "23.890",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "6",
        "23.926",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "6",
        "23.961",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "6",
        "23.997",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "6",
        "24.033",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "6",
        "24.068",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "6",
        "24.104",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "6",
        "24.139",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "6",
        "24.175",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "6",
        "24.211",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "6",
        "24.247",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "6",
        "24.281",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "6",
        "24.317",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "6",
        "24.352",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "6",
        "24.388",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "6",
        "24.422",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "6",
        "24.457",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "6",
        "24.492",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "6",
        "24.527",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "6",
        "24.561",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "6",
        "24.596",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "6",
        "24.630",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "6",
        "24.664",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "6",
        "24.697",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "6",
        "24.731",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "6",
        "24.764",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "6",
        "24.844",
        "18.049",
        "0.722",
        "1.128",
        "-0.244"
    ],
    [
        "33",
        "6",
        "24.905",
        "17.878",
        "0.857",
        "1.182",
        "-0.231"
    ],
    [
        "34",
        "6",
        "24.976",
        "17.722",
        "0.810",
        "1.211",
        "-0.220"
    ],
    [
        "35",
        "6",
        "25.102",
        "17.574",
        "0.844",
        "1.082",
        "-0.218"
    ],
    [
        "36",
        "6",
        "25.397",
        "17.442",
        "1.002",
        "0.925",
        "-0.279"
    ],
    [
        "37",
        "6",
        "26.690",
        "17.171",
        "1.040",
        "0.913",
        "-0.267"
    ],
    [
        "38",
        "6",
        "29.598",
        "16.415",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "6",
        "29.627",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "6",
        "29.657",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "6",
        "29.686",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "6",
        "29.715",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "6",
        "29.743",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "6",
        "29.770",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "6",
        "29.798",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "6",
        "29.825",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "6",
        "29.852",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "6",
        "29.878",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "6",
        "29.904",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "6",
        "29.929",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "6",
        "29.954",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "6",
        "29.978",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "6",
        "30.002",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "6",
        "30.026",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "6",
        "30.049",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "6",
        "30.071",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "6",
        "30.093",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "6",
        "30.114",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "6",
        "30.136",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "6",
        "30.156",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "6",
        "36.611",
        "21.489",
        "6.893",
        "9.450",
        "0.483"
    ],
    [
        "62",
        "6",
        "43.269",
        "33.033",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "6",
        "43.329",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "6",
        "43.390",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "6",
        "43.451",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "6",
        "43.512",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "6",
        "43.574",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "6",
        "43.635",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "6",
        "43.697",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "6",
        "43.758",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "6",
        "43.821",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "6",
        "43.883",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "6",
        "43.945",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "6",
        "44.008",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "6",
        "44.070",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "6",
        "44.133",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "6",
        "44.195",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "6",
        "44.258",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "6",
        "44.320",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "6",
        "44.383",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "6",
        "44.445",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "6",
        "44.507",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "6",
        "44.570",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "6",
        "44.632",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "6",
        "44.695",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "6",
        "44.756",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "6",
        "44.818",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "6",
        "44.880",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "6",
        "44.419",
        "33.862",
        "1.300",
        "1.584",
        "0.049"
    ],
    [
        "90",
        "6",
        "43.885",
        "33.658",
        "2.055",
        "2.381",
        "0.026"
    ],
    [
        "91",
        "6",
        "43.023",
        "32.423",
        "2.987",
        "4.004",
        "-0.052"
    ],
    [
        "92",
        "6",
        "41.052",
        "28.654",
        "4.210",
        "6.142",
        "-0.090"
    ],
    [
        "93",
        "6",
        "38.058",
        "20.810",
        "5.660",
        "7.161",
        "-0.165"
    ],
    [
        "94",
        "6",
        "35.533",
        "10.039",
        "5.216",
        "7.592",
        "-0.178"
    ],
    [
        "95",
        "6",
        "34.918",
        "0.610",
        "4.039",
        "5.683",
        "-0.109"
    ],
    [
        "96",
        "6",
        "33.639",
        "-2.565",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "6",
        "33.633",
        "-3.102",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "6",
        "33.627",
        "-3.638",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "6",
        "33.620",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "6",
        "33.612",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "6",
        "33.603",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "6",
        "33.593",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "6",
        "33.582",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "6",
        "33.570",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "6",
        "33.557",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "6",
        "33.543",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "6",
        "33.528",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "6",
        "33.512",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "6",
        "33.496",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "6",
        "33.478",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "6",
        "33.460",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "6",
        "33.441",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "6",
        "33.419",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "6",
        "33.398",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "6",
        "33.376",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "6",
        "33.353",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "6",
        "33.321",
        "-13.426",
        "0.742",
        "0.692",
        "-0.124"
    ],
    [
        "118",
        "6",
        "33.171",
        "-14.030",
        "0.703",
        "0.800",
        "-0.133"
    ],
    [
        "119",
        "6",
        "32.791",
        "-14.772",
        "0.714",
        "0.803",
        "-0.041"
    ],
    [
        "120",
        "6",
        "32.470",
        "-15.533",
        "0.941",
        "0.841",
        "0.109"
    ],
    [
        "121",
        "6",
        "31.334",
        "-17.066",
        "1.359",
        "1.289",
        "0.218"
    ],
    [
        "122",
        "6",
        "29.785",
        "-17.667",
        "1.918",
        "1.853",
        "0.110"
    ],
    [
        "123",
        "6",
        "25.808",
        "-16.727",
        "3.227",
        "2.496",
        "0.149"
    ],
    [
        "124",
        "6",
        "21.068",
        "-17.048",
        "4.881",
        "3.574",
        "-0.107"
    ],
    [
        "125",
        "6",
        "6.561",
        "-13.912",
        "6.253",
        "4.642",
        "-0.228"
    ],
    [
        "126",
        "6",
        "-12.180",
        "-2.741",
        "8.909",
        "4.906",
        "-0.146"
    ],
    [
        "127",
        "6",
        "-39.429",
        "11.658",
        "8.349",
        "5.804",
        "-0.262"
    ],
    [
        "128",
        "6",
        "-65.997",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "6",
        "-65.947",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "6",
        "-65.898",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "6",
        "-65.851",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "6",
        "-65.806",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "6",
        "-65.762",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "6",
        "-66.414",
        "20.850",
        "0.890",
        "1.053",
        "-0.144"
    ],
    [
        "135",
        "6",
        "-66.612",
        "19.989",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "6",
        "-66.576",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "6",
        "-66.539",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "6",
        "-66.503",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "6",
        "-66.466",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "6",
        "-66.430",
        "19.988",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "6",
        "-66.393",
        "19.970",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "6",
        "-66.357",
        "19.945",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "6",
        "-66.321",
        "19.914",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "6",
        "-66.494",
        "19.508",
        "0.863",
        "0.817",
        "0.039"
    ],
    [
        "145",
        "6",
        "-66.581",
        "19.503",
        "0.787",
        "0.778",
        "0.023"
    ],
    [
        "146",
        "6",
        "-66.661",
        "19.575",
        "0.768",
        "0.741",
        "0.090"
    ],
    [
        "147",
        "6",
        "-66.919",
        "19.672",
        "0.847",
        "0.737",
        "0.215"
    ],
    [
        "148",
        "6",
        "-67.383",
        "20.010",
        "0.204",
        "0.321",
        "0.177"
    ],
    [
        "149",
        "6",
        "-67.404",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "6",
        "-67.366",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "6",
        "-67.328",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "6",
        "-67.289",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "6",
        "-67.249",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "6",
        "-67.209",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "6",
        "-67.168",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "6",
        "-67.126",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "6",
        "-67.084",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "6",
        "-67.041",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "6",
        "-66.997",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "6",
        "-66.953",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "6",
        "-66.908",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "6",
        "-66.862",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "6",
        "-66.816",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "6",
        "-66.770",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "6",
        "-66.722",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "6",
        "-66.675",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "6",
        "-66.627",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "6",
        "-66.578",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "6",
        "-66.529",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "6",
        "-66.479",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "6",
        "-66.429",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "6",
        "-66.378",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "6",
        "-66.328",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "6",
        "-66.276",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "6",
        "-66.224",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "6",
        "-66.172",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "6",
        "-66.120",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "6",
        "-66.067",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "6",
        "-66.014",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "7",
        "-65.993",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "7",
        "-65.930",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "7",
        "-65.867",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "7",
        "-65.804",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "7",
        "-65.740",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "7",
        "-65.676",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "7",
        "-65.612",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "7",
        "-65.548",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "7",
        "-65.483",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "7",
        "-65.418",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "7",
        "-65.353",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "7",
        "-65.288",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "7",
        "-65.223",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "7",
        "-65.158",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "7",
        "-65.092",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "7",
        "-65.027",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "7",
        "-64.961",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "7",
        "-64.896",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "7",
        "-64.830",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "7",
        "-64.764",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "7",
        "-64.699",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "7",
        "-64.633",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "7",
        "-64.568",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "7",
        "-64.502",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "7",
        "-64.437",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "7",
        "-64.372",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "7",
        "-64.307",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "7",
        "-64.242",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "7",
        "-64.178",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "7",
        "-64.113",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "7",
        "-64.049",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "7",
        "-63.985",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "7",
        "-63.921",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "7",
        "-63.858",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "7",
        "-63.795",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "7",
        "-63.732",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "7",
        "-63.670",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "7",
        "-63.608",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "7",
        "-63.546",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "7",
        "-63.485",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "7",
        "-63.424",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "7",
        "-63.363",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "7",
        "-63.303",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "7",
        "-63.244",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "7",
        "-63.185",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "7",
        "-63.126",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "7",
        "-63.068",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "7",
        "-63.011",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "7",
        "-62.954",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "7",
        "-62.898",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "7",
        "-62.842",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "7",
        "-62.787",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "7",
        "-62.733",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "7",
        "-62.679",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "7",
        "-62.626",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "7",
        "-62.573",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "7",
        "-62.522",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "7",
        "-62.470",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "7",
        "-62.420",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "7",
        "-62.371",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "7",
        "-62.322",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "7",
        "-62.274",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "7",
        "-62.226",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "7",
        "-62.180",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "7",
        "-62.134",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "7",
        "-62.089",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "7",
        "-62.046",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "7",
        "-62.002",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "7",
        "-61.960",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "7",
        "-61.919",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "7",
        "-61.878",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "7",
        "-61.839",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "7",
        "-61.800",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "7",
        "-61.762",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "7",
        "-61.726",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "7",
        "-61.690",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "7",
        "-61.655",
        "16.157",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "7",
        "-49.152",
        "19.932",
        "4.069",
        "7.474",
        "-0.075"
    ],
    [
        "-102",
        "7",
        "53.814",
        "38.347",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "7",
        "53.899",
        "40.896",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "7",
        "53.989",
        "43.433",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "7",
        "54.083",
        "45.958",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "7",
        "54.183",
        "48.468",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "7",
        "54.289",
        "50.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "7",
        "54.400",
        "53.443",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "7",
        "54.517",
        "55.906",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "7",
        "54.638",
        "58.353",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "7",
        "54.765",
        "60.781",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "7",
        "54.896",
        "63.191",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "7",
        "55.034",
        "65.582",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "7",
        "55.175",
        "67.952",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "7",
        "55.323",
        "70.303",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "7",
        "55.475",
        "72.632",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "7",
        "55.632",
        "74.939",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "7",
        "55.794",
        "77.222",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "7",
        "55.961",
        "79.483",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "7",
        "56.131",
        "81.719",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "7",
        "56.308",
        "83.930",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "7",
        "50.190",
        "15.576",
        "8.604",
        "10.994",
        "0.035"
    ],
    [
        "-81",
        "7",
        "44.263",
        "11.004",
        "11.604",
        "10.548",
        "0.027"
    ],
    [
        "-80",
        "7",
        "34.911",
        "12.778",
        "12.216",
        "10.771",
        "0.015"
    ],
    [
        "-79",
        "7",
        "23.565",
        "13.495",
        "11.458",
        "9.783",
        "0.015"
    ],
    [
        "-78",
        "7",
        "13.891",
        "11.501",
        "9.309",
        "7.548",
        "0.003"
    ],
    [
        "-77",
        "7",
        "8.757",
        "11.261",
        "5.672",
        "5.430",
        "-0.045"
    ],
    [
        "-76",
        "7",
        "6.704",
        "11.793",
        "4.062",
        "3.580",
        "-0.058"
    ],
    [
        "-75",
        "7",
        "5.802",
        "12.394",
        "3.277",
        "2.328",
        "-0.052"
    ],
    [
        "-74",
        "7",
        "4.570",
        "12.039",
        "2.544",
        "2.058",
        "0.011"
    ],
    [
        "-73",
        "7",
        "2.088",
        "11.324",
        "1.719",
        "1.355",
        "0.134"
    ],
    [
        "-72",
        "7",
        "-2.796",
        "9.530",
        "2.030",
        "1.416",
        "-0.050"
    ],
    [
        "-71",
        "7",
        "-4.589",
        "9.137",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "7",
        "-4.569",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "7",
        "-4.550",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "7",
        "-4.529",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "7",
        "-4.509",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "7",
        "-4.487",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "7",
        "-4.466",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "7",
        "-4.445",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "7",
        "-4.423",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "7",
        "-4.402",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "7",
        "-4.380",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "7",
        "-4.358",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "7",
        "-4.334",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "7",
        "-4.312",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "7",
        "-4.289",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "7",
        "-4.265",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "7",
        "-4.242",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "7",
        "-4.218",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "7",
        "-4.195",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "7",
        "-4.170",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "7",
        "-4.146",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "7",
        "-4.122",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "7",
        "-4.096",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "7",
        "-4.072",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "7",
        "-4.047",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "7",
        "-4.022",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "7",
        "-3.997",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "7",
        "-3.971",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "7",
        "-3.945",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "7",
        "-3.920",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "7",
        "-3.894",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "7",
        "-3.868",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "7",
        "-3.842",
        "12.227",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "7",
        "-3.817",
        "12.267",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "7",
        "-3.790",
        "12.301",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "7",
        "-3.764",
        "12.332",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "7",
        "-3.738",
        "12.360",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "7",
        "-0.866",
        "12.917",
        "1.234",
        "1.480",
        "0.133"
    ],
    [
        "-33",
        "7",
        "22.358",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "7",
        "22.389",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "7",
        "22.420",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "7",
        "22.452",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "7",
        "22.484",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "7",
        "22.516",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "7",
        "22.550",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "7",
        "22.583",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "7",
        "22.617",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "7",
        "22.651",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "7",
        "22.687",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "7",
        "22.722",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "7",
        "22.758",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "7",
        "22.793",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "7",
        "22.830",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "7",
        "22.866",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "7",
        "22.903",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "7",
        "22.941",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "7",
        "22.978",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "7",
        "23.016",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "7",
        "23.054",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "7",
        "23.092",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "7",
        "23.132",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "7",
        "23.170",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "7",
        "23.210",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "7",
        "23.250",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "7",
        "23.289",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "7",
        "23.330",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "7",
        "23.369",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "7",
        "23.409",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "7",
        "23.450",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "7",
        "23.491",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "7",
        "23.532",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "7",
        "23.572",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "7",
        "23.614",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "7",
        "23.654",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "7",
        "23.696",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "7",
        "23.738",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "7",
        "23.779",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "7",
        "23.820",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "7",
        "23.862",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "7",
        "23.904",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "7",
        "23.945",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "7",
        "23.987",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "7",
        "24.028",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "7",
        "24.069",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "7",
        "24.111",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "7",
        "24.153",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "7",
        "24.194",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "7",
        "24.236",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "7",
        "24.277",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "7",
        "24.318",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "7",
        "24.359",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "7",
        "24.400",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "7",
        "24.441",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "7",
        "24.481",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "7",
        "24.522",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "7",
        "24.563",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "7",
        "24.603",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "7",
        "24.643",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "7",
        "24.683",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "7",
        "24.722",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "7",
        "24.762",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "7",
        "24.800",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "7",
        "24.840",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "7",
        "24.878",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "7",
        "24.916",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "7",
        "24.954",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "7",
        "24.993",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "7",
        "25.030",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "7",
        "25.067",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "7",
        "25.790",
        "17.019",
        "0.275",
        "0.351",
        "-0.121"
    ],
    [
        "39",
        "7",
        "29.768",
        "16.218",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "7",
        "29.802",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "7",
        "29.837",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "7",
        "29.870",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "7",
        "29.903",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "7",
        "29.935",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "7",
        "29.968",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "7",
        "29.999",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "7",
        "30.030",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "7",
        "30.061",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "7",
        "30.090",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "7",
        "30.120",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "7",
        "30.150",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "7",
        "30.177",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "7",
        "30.205",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "7",
        "30.233",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "7",
        "30.260",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "7",
        "30.286",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "7",
        "30.311",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "7",
        "30.337",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "7",
        "30.361",
        "11.342",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "7",
        "30.384",
        "11.057",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "7",
        "42.952",
        "32.867",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "7",
        "43.022",
        "33.033",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "7",
        "43.092",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "7",
        "43.163",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "7",
        "43.234",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "7",
        "43.306",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "7",
        "43.377",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "7",
        "43.449",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "7",
        "43.521",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "7",
        "43.593",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "7",
        "43.665",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "7",
        "43.739",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "7",
        "43.811",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "7",
        "43.883",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "7",
        "43.956",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "7",
        "44.029",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "7",
        "44.103",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "7",
        "44.175",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "7",
        "44.248",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "7",
        "44.321",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "7",
        "44.394",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "7",
        "44.466",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "7",
        "44.539",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "7",
        "44.612",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "7",
        "44.684",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "7",
        "44.756",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "7",
        "44.829",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "7",
        "44.900",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "7",
        "44.600",
        "33.757",
        "1.417",
        "1.653",
        "0.027"
    ],
    [
        "90",
        "7",
        "44.079",
        "33.649",
        "2.310",
        "2.505",
        "0.013"
    ],
    [
        "91",
        "7",
        "42.836",
        "32.775",
        "3.251",
        "4.130",
        "-0.030"
    ],
    [
        "92",
        "7",
        "40.269",
        "29.039",
        "4.266",
        "6.021",
        "-0.098"
    ],
    [
        "93",
        "7",
        "37.220",
        "19.629",
        "5.159",
        "6.843",
        "-0.228"
    ],
    [
        "94",
        "7",
        "35.695",
        "6.450",
        "4.194",
        "6.253",
        "-0.243"
    ],
    [
        "95",
        "7",
        "35.249",
        "-1.549",
        "2.410",
        "3.072",
        "-0.046"
    ],
    [
        "96",
        "7",
        "34.112",
        "-2.565",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "7",
        "34.105",
        "-3.102",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "7",
        "34.098",
        "-3.638",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "7",
        "34.091",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "7",
        "34.080",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "7",
        "34.070",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "7",
        "34.058",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "7",
        "34.045",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "7",
        "34.032",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "7",
        "34.016",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "7",
        "34.001",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "7",
        "33.983",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "7",
        "33.964",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "7",
        "33.946",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "7",
        "33.925",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "7",
        "33.903",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "7",
        "33.881",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "7",
        "33.856",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "7",
        "33.832",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "7",
        "33.806",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "7",
        "33.779",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "7",
        "33.751",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "7",
        "33.721",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "7",
        "33.692",
        "-14.380",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "7",
        "33.660",
        "-14.854",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "7",
        "32.897",
        "-15.686",
        "1.311",
        "1.178",
        "0.117"
    ],
    [
        "122",
        "7",
        "24.671",
        "-13.613",
        "1.295",
        "0.979",
        "0.070"
    ],
    [
        "123",
        "7",
        "25.907",
        "-15.912",
        "4.326",
        "3.062",
        "-0.109"
    ],
    [
        "124",
        "7",
        "19.536",
        "-13.704",
        "5.847",
        "4.327",
        "-0.196"
    ],
    [
        "125",
        "7",
        "-0.099",
        "-9.763",
        "5.788",
        "5.034",
        "-0.238"
    ],
    [
        "126",
        "7",
        "-19.430",
        "0.145",
        "8.752",
        "5.240",
        "-0.266"
    ],
    [
        "127",
        "7",
        "-42.657",
        "16.630",
        "8.429",
        "6.219",
        "-0.220"
    ],
    [
        "128",
        "7",
        "-64.928",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "7",
        "-64.868",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "7",
        "-64.812",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "7",
        "-64.756",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "7",
        "-64.704",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "7",
        "-64.652",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "7",
        "-64.914",
        "22.327",
        "0.906",
        "1.050",
        "-0.281"
    ],
    [
        "135",
        "7",
        "-65.732",
        "20.624",
        "0.965",
        "1.085",
        "-0.334"
    ],
    [
        "136",
        "7",
        "-66.453",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "7",
        "-66.410",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "7",
        "-66.368",
        "20.007",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "7",
        "-66.325",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "7",
        "-66.370",
        "19.893",
        "0.242",
        "0.363",
        "0.020"
    ],
    [
        "141",
        "7",
        "-66.904",
        "18.740",
        "1.032",
        "1.008",
        "-0.038"
    ],
    [
        "142",
        "7",
        "-67.181",
        "18.485",
        "0.912",
        "1.030",
        "-0.075"
    ],
    [
        "143",
        "7",
        "-67.398",
        "18.584",
        "0.815",
        "0.914",
        "-0.026"
    ],
    [
        "144",
        "7",
        "-67.504",
        "18.766",
        "0.884",
        "0.806",
        "0.029"
    ],
    [
        "145",
        "7",
        "-67.789",
        "18.836",
        "0.210",
        "0.349",
        "0.040"
    ],
    [
        "146",
        "7",
        "-67.802",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "7",
        "-67.761",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "7",
        "-67.719",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "7",
        "-67.676",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "7",
        "-67.632",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "7",
        "-67.587",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "7",
        "-67.542",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "7",
        "-67.496",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "7",
        "-67.449",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "7",
        "-67.401",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "7",
        "-67.352",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "7",
        "-67.302",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "7",
        "-67.252",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "7",
        "-67.201",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "7",
        "-67.150",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "7",
        "-67.097",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "7",
        "-67.044",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "7",
        "-66.991",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "7",
        "-66.936",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "7",
        "-66.881",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "7",
        "-66.826",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "7",
        "-66.770",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "7",
        "-66.713",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "7",
        "-66.656",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "7",
        "-66.598",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "7",
        "-66.539",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "7",
        "-66.480",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "7",
        "-66.421",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "7",
        "-66.361",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "7",
        "-66.301",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "7",
        "-66.240",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "7",
        "-66.179",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "7",
        "-66.117",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "7",
        "-66.055",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "8",
        "-66.005",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "8",
        "-65.934",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "8",
        "-65.862",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "8",
        "-65.789",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "8",
        "-65.717",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "8",
        "-65.644",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "8",
        "-65.570",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "8",
        "-65.497",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "8",
        "-65.423",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "8",
        "-65.349",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "8",
        "-65.275",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "8",
        "-65.201",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "8",
        "-65.126",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "8",
        "-65.051",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "8",
        "-64.977",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "8",
        "-64.902",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "8",
        "-64.827",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "8",
        "-64.752",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "8",
        "-64.677",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "8",
        "-64.602",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "8",
        "-64.527",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "8",
        "-64.453",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "8",
        "-64.378",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "8",
        "-64.303",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "8",
        "-64.229",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "8",
        "-64.154",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "8",
        "-64.080",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "8",
        "-64.006",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "8",
        "-63.932",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "8",
        "-63.859",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "8",
        "-63.785",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "8",
        "-63.712",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "8",
        "-63.640",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "8",
        "-63.567",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "8",
        "-63.495",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "8",
        "-63.424",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "8",
        "-63.352",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "8",
        "-63.281",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "8",
        "-63.211",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "8",
        "-63.141",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "8",
        "-63.072",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "8",
        "-63.003",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "8",
        "-62.934",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "8",
        "-62.866",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "8",
        "-62.799",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "8",
        "-62.732",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "8",
        "-62.666",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "8",
        "-62.600",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "8",
        "-62.535",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "8",
        "-62.471",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "8",
        "-62.407",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "8",
        "-62.344",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "8",
        "-62.282",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "8",
        "-62.221",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "8",
        "-62.160",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "8",
        "-62.100",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "8",
        "-62.041",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "8",
        "-61.983",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "8",
        "-61.925",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "8",
        "-61.869",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "8",
        "-61.813",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "8",
        "-61.758",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "8",
        "-61.704",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "8",
        "-61.651",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "8",
        "-61.599",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "8",
        "-61.548",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "8",
        "-61.498",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "8",
        "-61.448",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "8",
        "-61.400",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "8",
        "-61.353",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "8",
        "-61.307",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "8",
        "-61.261",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "8",
        "-61.217",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "8",
        "-61.174",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "8",
        "-61.132",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "8",
        "-61.091",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "8",
        "-61.051",
        "16.157",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "8",
        "20.502",
        "30.867",
        "7.093",
        "10.214",
        "0.024"
    ],
    [
        "-102",
        "8",
        "51.116",
        "38.347",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "8",
        "51.212",
        "40.896",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "8",
        "51.314",
        "43.433",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "8",
        "51.424",
        "45.958",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "8",
        "51.538",
        "48.468",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "8",
        "51.658",
        "50.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "8",
        "51.786",
        "53.443",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "8",
        "51.918",
        "55.906",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "8",
        "52.057",
        "58.353",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "8",
        "52.201",
        "60.781",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "8",
        "52.352",
        "63.191",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "8",
        "52.509",
        "65.582",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "8",
        "52.671",
        "67.952",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "8",
        "52.839",
        "70.303",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "8",
        "53.013",
        "72.632",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "8",
        "53.191",
        "74.939",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "8",
        "53.376",
        "77.222",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "8",
        "53.566",
        "79.483",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "8",
        "53.762",
        "81.719",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "8",
        "53.087",
        "63.585",
        "4.038",
        "5.687",
        "0.030"
    ],
    [
        "-82",
        "8",
        "43.569",
        "13.399",
        "7.033",
        "8.837",
        "0.069"
    ],
    [
        "-81",
        "8",
        "34.758",
        "8.624",
        "7.990",
        "8.918",
        "0.056"
    ],
    [
        "-80",
        "8",
        "26.590",
        "9.811",
        "7.489",
        "9.246",
        "0.005"
    ],
    [
        "-79",
        "8",
        "22.916",
        "10.826",
        "8.975",
        "9.441",
        "0.037"
    ],
    [
        "-78",
        "8",
        "18.984",
        "12.670",
        "6.927",
        "4.414",
        "-0.024"
    ],
    [
        "-77",
        "8",
        "11.993",
        "9.881",
        "5.844",
        "6.671",
        "-0.080"
    ],
    [
        "-76",
        "8",
        "9.002",
        "10.807",
        "4.789",
        "4.674",
        "-0.106"
    ],
    [
        "-75",
        "8",
        "7.337",
        "12.207",
        "4.072",
        "3.321",
        "-0.007"
    ],
    [
        "-74",
        "8",
        "5.544",
        "12.639",
        "3.190",
        "2.789",
        "0.123"
    ],
    [
        "-73",
        "8",
        "3.134",
        "12.778",
        "2.727",
        "2.336",
        "0.075"
    ],
    [
        "-72",
        "8",
        "-0.019",
        "10.996",
        "2.431",
        "1.718",
        "0.161"
    ],
    [
        "-71",
        "8",
        "-4.411",
        "9.252",
        "0.558",
        "0.799",
        "0.051"
    ],
    [
        "-70",
        "8",
        "-4.704",
        "9.283",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "8",
        "-4.682",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "8",
        "-4.659",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "8",
        "-4.635",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "8",
        "-4.611",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "8",
        "-4.587",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "8",
        "-4.564",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "8",
        "-4.538",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "8",
        "-4.513",
        "10.346",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "8",
        "-4.488",
        "10.466",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "8",
        "-4.463",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "8",
        "-4.436",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "8",
        "-4.410",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "8",
        "-4.385",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "8",
        "-4.357",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "8",
        "-4.331",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "8",
        "-4.304",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "8",
        "-4.276",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "8",
        "-4.249",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "8",
        "-4.221",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "8",
        "-4.194",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "8",
        "-4.165",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "8",
        "-4.136",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "8",
        "-4.108",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "8",
        "-4.079",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "8",
        "-4.050",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "8",
        "-4.021",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "8",
        "-3.993",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "8",
        "-3.963",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "8",
        "-3.933",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "8",
        "-3.904",
        "12.185",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "8",
        "-1.729",
        "12.081",
        "3.346",
        "2.466",
        "0.246"
    ],
    [
        "-38",
        "8",
        "12.205",
        "13.144",
        "3.488",
        "2.231",
        "0.020"
    ],
    [
        "-37",
        "8",
        "21.939",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "8",
        "21.972",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "8",
        "22.005",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "8",
        "22.039",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "8",
        "22.074",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "8",
        "22.109",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "8",
        "22.145",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "8",
        "22.180",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "8",
        "22.217",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "8",
        "22.255",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "8",
        "22.293",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "8",
        "22.331",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "8",
        "22.369",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "8",
        "22.409",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "8",
        "22.449",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "8",
        "22.489",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "8",
        "22.530",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "8",
        "22.571",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "8",
        "22.612",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "8",
        "22.653",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "8",
        "22.696",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "8",
        "22.739",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "8",
        "22.781",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "8",
        "22.825",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "8",
        "22.869",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "8",
        "22.913",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "8",
        "22.956",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "8",
        "23.001",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "8",
        "23.047",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "8",
        "23.091",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "8",
        "23.136",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "8",
        "23.182",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "8",
        "23.228",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "8",
        "23.274",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "8",
        "23.321",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "8",
        "23.366",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "8",
        "23.414",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "8",
        "23.460",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "8",
        "23.507",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "8",
        "23.554",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "8",
        "23.601",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "8",
        "23.649",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "8",
        "23.696",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "8",
        "23.743",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "8",
        "23.790",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "8",
        "23.838",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "8",
        "23.885",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "8",
        "23.933",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "8",
        "23.981",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "8",
        "24.028",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "8",
        "24.076",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "8",
        "24.123",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "8",
        "24.170",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "8",
        "24.217",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "8",
        "24.264",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "8",
        "24.311",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "8",
        "24.359",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "8",
        "24.406",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "8",
        "24.452",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "8",
        "24.498",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "8",
        "24.544",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "8",
        "24.591",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "8",
        "24.637",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "8",
        "24.682",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "8",
        "24.728",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "8",
        "24.774",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "8",
        "24.818",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "8",
        "24.863",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "8",
        "24.908",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "8",
        "24.952",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "8",
        "24.996",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "8",
        "25.038",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "8",
        "25.082",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "8",
        "25.125",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "8",
        "25.167",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "8",
        "25.388",
        "17.155",
        "0.303",
        "0.396",
        "-0.007"
    ],
    [
        "39",
        "8",
        "28.226",
        "16.590",
        "1.279",
        "0.955",
        "-0.079"
    ],
    [
        "40",
        "8",
        "29.940",
        "16.017",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "8",
        "29.979",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "8",
        "30.016",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "8",
        "30.054",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "8",
        "30.091",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "8",
        "30.128",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "8",
        "30.164",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "8",
        "30.199",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "8",
        "30.234",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "8",
        "30.268",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "8",
        "30.302",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "8",
        "30.336",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "8",
        "30.367",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "8",
        "30.400",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "8",
        "30.430",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "8",
        "30.461",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "8",
        "30.491",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "8",
        "30.520",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "8",
        "30.549",
        "11.624",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "8",
        "33.674",
        "16.829",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "8",
        "42.602",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "8",
        "42.682",
        "32.867",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "8",
        "42.762",
        "33.033",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "8",
        "42.842",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "8",
        "42.923",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "8",
        "43.005",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "8",
        "43.086",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "8",
        "43.167",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "8",
        "43.250",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "8",
        "43.332",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "8",
        "43.415",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "8",
        "43.497",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "8",
        "43.579",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "8",
        "43.662",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "8",
        "43.746",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "8",
        "43.829",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "8",
        "43.913",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "8",
        "43.996",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "8",
        "44.078",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "8",
        "44.162",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "8",
        "44.246",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "8",
        "44.329",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "8",
        "44.412",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "8",
        "44.495",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "8",
        "44.577",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "8",
        "44.660",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "8",
        "44.743",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "8",
        "44.825",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "8",
        "44.907",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "8",
        "44.825",
        "33.682",
        "1.470",
        "1.595",
        "0.026"
    ],
    [
        "90",
        "8",
        "44.537",
        "33.668",
        "2.874",
        "2.549",
        "0.119"
    ],
    [
        "91",
        "8",
        "43.521",
        "33.052",
        "4.337",
        "4.247",
        "0.121"
    ],
    [
        "92",
        "8",
        "40.929",
        "28.793",
        "5.488",
        "6.433",
        "0.025"
    ],
    [
        "93",
        "8",
        "37.669",
        "17.932",
        "5.841",
        "6.858",
        "-0.101"
    ],
    [
        "94",
        "8",
        "36.550",
        "2.926",
        "3.990",
        "4.962",
        "-0.011"
    ],
    [
        "95",
        "8",
        "34.580",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "8",
        "34.574",
        "-2.565",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "8",
        "34.568",
        "-3.102",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "8",
        "34.560",
        "-3.638",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "8",
        "34.550",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "8",
        "34.539",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "8",
        "34.527",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "8",
        "34.513",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "8",
        "34.498",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "8",
        "34.483",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "8",
        "34.466",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "8",
        "34.447",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "8",
        "34.428",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "8",
        "34.407",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "8",
        "34.384",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "8",
        "34.360",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "8",
        "34.336",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "8",
        "34.310",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "8",
        "34.283",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "8",
        "34.254",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "8",
        "34.224",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "8",
        "34.194",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "8",
        "34.162",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "8",
        "34.128",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "8",
        "34.094",
        "-14.380",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "8",
        "34.059",
        "-14.854",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "8",
        "34.023",
        "-15.323",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "8",
        "31.439",
        "-15.217",
        "3.015",
        "2.822",
        "-0.070"
    ],
    [
        "123",
        "8",
        "25.445",
        "-14.244",
        "5.426",
        "3.778",
        "-0.281"
    ],
    [
        "124",
        "8",
        "16.563",
        "-8.970",
        "6.584",
        "4.989",
        "-0.229"
    ],
    [
        "125",
        "8",
        "1.823",
        "-1.188",
        "7.122",
        "5.139",
        "-0.010"
    ],
    [
        "126",
        "8",
        "-23.179",
        "5.788",
        "8.971",
        "5.618",
        "-0.226"
    ],
    [
        "127",
        "8",
        "-46.232",
        "20.530",
        "8.829",
        "6.362",
        "-0.159"
    ],
    [
        "128",
        "8",
        "-63.838",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "8",
        "-63.770",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "8",
        "-63.705",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "8",
        "-63.642",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "8",
        "-63.582",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "8",
        "-63.523",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "8",
        "-63.468",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "8",
        "-63.668",
        "21.508",
        "0.882",
        "0.959",
        "-0.344"
    ],
    [
        "136",
        "8",
        "-64.036",
        "20.269",
        "0.917",
        "1.055",
        "-0.356"
    ],
    [
        "137",
        "8",
        "-64.515",
        "19.511",
        "1.047",
        "1.237",
        "-0.340"
    ],
    [
        "138",
        "8",
        "-65.431",
        "19.471",
        "1.276",
        "1.230",
        "-0.244"
    ],
    [
        "139",
        "8",
        "-66.163",
        "20.001",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "8",
        "-66.248",
        "19.701",
        "0.281",
        "0.471",
        "0.068"
    ],
    [
        "141",
        "8",
        "-67.244",
        "17.771",
        "1.094",
        "1.037",
        "-0.109"
    ],
    [
        "142",
        "8",
        "-68.140",
        "17.523",
        "0.235",
        "0.368",
        "-0.064"
    ],
    [
        "143",
        "8",
        "-68.206",
        "17.871",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "8",
        "-68.163",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "8",
        "-68.118",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "8",
        "-68.072",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "8",
        "-68.024",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "8",
        "-67.976",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "8",
        "-67.927",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "8",
        "-67.877",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "8",
        "-67.826",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "8",
        "-67.774",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "8",
        "-67.721",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "8",
        "-67.668",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "8",
        "-67.613",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "8",
        "-67.557",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "8",
        "-67.501",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "8",
        "-67.444",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "8",
        "-67.385",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "8",
        "-67.326",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "8",
        "-67.267",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "8",
        "-67.206",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "8",
        "-67.145",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "8",
        "-67.083",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "8",
        "-67.020",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "8",
        "-66.957",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "8",
        "-66.892",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "8",
        "-66.828",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "8",
        "-66.762",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "8",
        "-66.696",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "8",
        "-66.629",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "8",
        "-66.562",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "8",
        "-66.494",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "8",
        "-66.426",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "8",
        "-66.357",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "8",
        "-66.287",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "8",
        "-66.218",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "8",
        "-66.147",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "8",
        "-66.076",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "9",
        "-65.998",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "9",
        "-65.917",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "9",
        "-65.836",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "9",
        "-65.755",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "9",
        "-65.673",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "9",
        "-65.591",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "9",
        "-65.509",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "9",
        "-65.426",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "9",
        "-65.343",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "9",
        "-65.260",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "9",
        "-65.177",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "9",
        "-65.093",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "9",
        "-65.009",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "9",
        "-64.926",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "9",
        "-64.842",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "9",
        "-64.757",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "9",
        "-64.673",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "9",
        "-64.589",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "9",
        "-64.505",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "9",
        "-64.421",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "9",
        "-64.337",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "9",
        "-64.252",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "9",
        "-64.168",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "9",
        "-64.085",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "9",
        "-64.001",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "9",
        "-63.917",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "9",
        "-63.834",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "9",
        "-63.751",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "9",
        "-63.668",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "9",
        "-63.585",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "9",
        "-63.503",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "9",
        "-63.420",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "9",
        "-63.339",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "9",
        "-63.257",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "9",
        "-63.176",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "9",
        "-63.096",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "9",
        "-63.016",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "9",
        "-62.936",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "9",
        "-62.857",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "9",
        "-62.778",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "9",
        "-62.700",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "9",
        "-62.622",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "9",
        "-62.545",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "9",
        "-62.469",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "9",
        "-62.393",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "9",
        "-62.318",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "9",
        "-62.244",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "9",
        "-62.170",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "9",
        "-62.097",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "9",
        "-62.025",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "9",
        "-61.953",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "9",
        "-61.883",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "9",
        "-61.813",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "9",
        "-61.744",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "9",
        "-61.676",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "9",
        "-61.608",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "9",
        "-61.542",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "9",
        "-61.476",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "9",
        "-61.412",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "9",
        "-61.348",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "9",
        "-61.285",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "9",
        "-61.224",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "9",
        "-61.163",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "9",
        "-61.103",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "9",
        "-61.045",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "9",
        "-60.987",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "9",
        "-60.931",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "9",
        "-60.875",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "9",
        "-60.821",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "9",
        "-60.768",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "9",
        "-60.716",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "9",
        "-60.665",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "9",
        "-60.616",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "9",
        "-60.567",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "9",
        "-60.520",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "9",
        "-60.474",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "9",
        "-2.041",
        "27.659",
        "9.074",
        "11.314",
        "-0.018"
    ],
    [
        "-103",
        "9",
        "48.301",
        "35.785",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "9",
        "48.402",
        "38.347",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "9",
        "48.510",
        "40.896",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "9",
        "48.626",
        "43.433",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "9",
        "48.747",
        "45.958",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "9",
        "48.876",
        "48.468",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "9",
        "49.012",
        "50.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "9",
        "49.154",
        "53.443",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "9",
        "49.304",
        "55.906",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "9",
        "49.460",
        "58.353",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "9",
        "49.622",
        "60.781",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "9",
        "49.792",
        "63.191",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "9",
        "49.968",
        "65.582",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "9",
        "50.150",
        "67.952",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "9",
        "50.339",
        "70.303",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "9",
        "50.534",
        "72.632",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "9",
        "50.735",
        "74.939",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "9",
        "50.942",
        "77.222",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "9",
        "46.920",
        "60.607",
        "4.157",
        "4.998",
        "0.172"
    ],
    [
        "-84",
        "9",
        "42.066",
        "50.159",
        "5.120",
        "5.089",
        "0.235"
    ],
    [
        "-83",
        "9",
        "36.519",
        "29.535",
        "4.830",
        "4.522",
        "0.168"
    ],
    [
        "-82",
        "9",
        "30.685",
        "9.607",
        "5.949",
        "6.007",
        "0.219"
    ],
    [
        "-81",
        "9",
        "23.701",
        "6.778",
        "6.868",
        "6.392",
        "0.158"
    ],
    [
        "-80",
        "9",
        "18.671",
        "7.167",
        "4.176",
        "7.195",
        "0.046"
    ],
    [
        "-79",
        "9",
        "16.962",
        "8.354",
        "5.071",
        "7.860",
        "-0.004"
    ],
    [
        "-78",
        "9",
        "15.827",
        "8.880",
        "6.444",
        "6.814",
        "-0.123"
    ],
    [
        "-77",
        "9",
        "14.653",
        "8.245",
        "5.414",
        "6.608",
        "-0.020"
    ],
    [
        "-76",
        "9",
        "12.117",
        "8.589",
        "4.042",
        "4.337",
        "-0.039"
    ],
    [
        "-75",
        "9",
        "8.895",
        "11.939",
        "4.599",
        "4.557",
        "-0.029"
    ],
    [
        "-74",
        "9",
        "6.388",
        "13.370",
        "3.929",
        "3.956",
        "0.088"
    ],
    [
        "-73",
        "9",
        "4.291",
        "13.585",
        "3.491",
        "3.278",
        "0.124"
    ],
    [
        "-72",
        "9",
        "2.371",
        "12.555",
        "3.273",
        "2.670",
        "0.126"
    ],
    [
        "-71",
        "9",
        "-0.292",
        "12.869",
        "2.315",
        "1.689",
        "0.141"
    ],
    [
        "-70",
        "9",
        "-3.984",
        "9.549",
        "1.821",
        "1.961",
        "-0.012"
    ],
    [
        "-69",
        "9",
        "-4.813",
        "9.426",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "9",
        "-4.787",
        "9.567",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "9",
        "-4.761",
        "9.705",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "9",
        "-4.734",
        "9.839",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "9",
        "-4.707",
        "9.971",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "9",
        "-4.679",
        "10.099",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "9",
        "-4.651",
        "10.225",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "9",
        "-4.523",
        "10.785",
        "1.425",
        "2.220",
        "0.130"
    ],
    [
        "-61",
        "9",
        "-4.354",
        "10.823",
        "1.901",
        "2.689",
        "-0.018"
    ],
    [
        "-60",
        "9",
        "-4.566",
        "10.582",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "9",
        "-4.538",
        "10.694",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "9",
        "-4.508",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "9",
        "-4.479",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "9",
        "-4.449",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "9",
        "-4.418",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "9",
        "-4.388",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "9",
        "-4.358",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "9",
        "-4.326",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "9",
        "-4.295",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "9",
        "-4.264",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "9",
        "-4.232",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "9",
        "-4.200",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "9",
        "-4.168",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "9",
        "-4.136",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "9",
        "-4.103",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "9",
        "-4.070",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "9",
        "-4.038",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "9",
        "-4.005",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "9",
        "-3.971",
        "12.139",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "9",
        "13.502",
        "12.326",
        "2.371",
        "2.329",
        "0.167"
    ],
    [
        "-39",
        "9",
        "21.558",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "9",
        "21.594",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "9",
        "21.630",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "9",
        "21.668",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "9",
        "21.705",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "9",
        "21.743",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "9",
        "21.782",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "9",
        "21.822",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "9",
        "21.861",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "9",
        "21.902",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "9",
        "21.944",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "9",
        "21.985",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "9",
        "22.028",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "9",
        "22.071",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "9",
        "22.115",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "9",
        "22.159",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "9",
        "22.204",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "9",
        "22.249",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "9",
        "22.294",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "9",
        "22.341",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "9",
        "22.387",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "9",
        "22.435",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "9",
        "22.482",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "9",
        "22.530",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "9",
        "22.578",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "9",
        "22.626",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "9",
        "22.676",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "9",
        "22.725",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "9",
        "22.775",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "9",
        "22.825",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "9",
        "22.875",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "9",
        "22.926",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "9",
        "22.978",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "9",
        "23.029",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "9",
        "23.080",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "9",
        "23.132",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "9",
        "23.184",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "9",
        "23.235",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "9",
        "23.288",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "9",
        "23.341",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "9",
        "23.393",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "9",
        "23.446",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "9",
        "23.499",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "9",
        "23.552",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "9",
        "23.605",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "9",
        "23.659",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "9",
        "23.712",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "9",
        "23.766",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "9",
        "23.818",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "9",
        "23.872",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "9",
        "23.925",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "9",
        "23.979",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "9",
        "24.032",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "9",
        "24.086",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "9",
        "24.139",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "9",
        "24.192",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "9",
        "24.244",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "9",
        "24.298",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "9",
        "24.350",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "9",
        "24.403",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "9",
        "24.456",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "9",
        "24.508",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "9",
        "24.560",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "9",
        "24.612",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "9",
        "24.663",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "9",
        "24.715",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "9",
        "24.766",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "9",
        "24.817",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "9",
        "24.867",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "9",
        "24.918",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "9",
        "24.968",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "9",
        "25.017",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "9",
        "25.067",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "9",
        "25.116",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "9",
        "25.164",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "9",
        "25.212",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "9",
        "25.259",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "9",
        "25.307",
        "17.224",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "9",
        "25.353",
        "17.060",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "9",
        "28.857",
        "16.243",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "9",
        "30.111",
        "15.810",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "9",
        "30.154",
        "15.600",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "9",
        "30.196",
        "15.383",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "9",
        "30.238",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "9",
        "30.279",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "9",
        "30.319",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "9",
        "30.359",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "9",
        "30.398",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "9",
        "30.437",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "9",
        "30.475",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "9",
        "30.512",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "9",
        "30.548",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "9",
        "30.584",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "9",
        "30.620",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "9",
        "30.653",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "9",
        "30.687",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "9",
        "30.721",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "9",
        "34.140",
        "16.925",
        "6.975",
        "10.711",
        "0.409"
    ],
    [
        "59",
        "9",
        "42.220",
        "32.507",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "9",
        "42.310",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "9",
        "42.399",
        "32.867",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "9",
        "42.489",
        "33.033",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "9",
        "42.580",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "9",
        "42.670",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "9",
        "42.762",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "9",
        "42.853",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "9",
        "42.945",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "9",
        "43.037",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "9",
        "43.129",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "9",
        "43.222",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "9",
        "43.316",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "9",
        "43.408",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "9",
        "43.502",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "9",
        "43.595",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "9",
        "43.688",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "9",
        "43.782",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "9",
        "43.875",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "9",
        "43.969",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "9",
        "44.063",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "9",
        "44.156",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "9",
        "44.250",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "9",
        "44.343",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "9",
        "44.436",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "9",
        "44.529",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "9",
        "44.622",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "9",
        "44.715",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "9",
        "44.807",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "9",
        "44.900",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "9",
        "44.975",
        "33.604",
        "0.578",
        "0.723",
        "0.133"
    ],
    [
        "90",
        "9",
        "44.918",
        "33.569",
        "3.201",
        "2.249",
        "0.202"
    ],
    [
        "91",
        "9",
        "44.686",
        "33.250",
        "5.221",
        "3.843",
        "0.197"
    ],
    [
        "92",
        "9",
        "42.411",
        "28.558",
        "7.171",
        "7.279",
        "0.152"
    ],
    [
        "93",
        "9",
        "38.597",
        "17.141",
        "7.636",
        "7.265",
        "0.077"
    ],
    [
        "94",
        "9",
        "35.314",
        "-0.513",
        "1.514",
        "2.433",
        "0.002"
    ],
    [
        "95",
        "9",
        "35.033",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "9",
        "35.026",
        "-2.565",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "9",
        "35.018",
        "-3.102",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "9",
        "35.009",
        "-3.638",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "9",
        "34.999",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "9",
        "34.986",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "9",
        "34.973",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "9",
        "34.958",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "9",
        "34.941",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "9",
        "34.923",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "9",
        "34.904",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "9",
        "34.884",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "9",
        "34.861",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "9",
        "34.838",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "9",
        "34.812",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "9",
        "34.786",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "9",
        "34.758",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "9",
        "34.729",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "9",
        "34.698",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "9",
        "34.667",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "9",
        "34.633",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "9",
        "34.599",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "9",
        "34.563",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "9",
        "34.525",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "9",
        "34.487",
        "-14.380",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "9",
        "34.446",
        "-14.854",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "9",
        "34.405",
        "-15.323",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "9",
        "33.753",
        "-15.167",
        "1.245",
        "1.551",
        "-0.206"
    ],
    [
        "123",
        "9",
        "24.790",
        "-9.611",
        "6.164",
        "4.220",
        "-0.250"
    ],
    [
        "124",
        "9",
        "12.319",
        "-4.052",
        "6.933",
        "5.124",
        "-0.161"
    ],
    [
        "125",
        "9",
        "0.558",
        "4.152",
        "7.096",
        "5.333",
        "-0.226"
    ],
    [
        "126",
        "9",
        "-21.796",
        "13.237",
        "9.435",
        "5.167",
        "0.039"
    ],
    [
        "127",
        "9",
        "-49.214",
        "23.820",
        "9.287",
        "6.148",
        "-0.107"
    ],
    [
        "128",
        "9",
        "-62.728",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "9",
        "-62.652",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "9",
        "-62.580",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "9",
        "-62.509",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "9",
        "-62.441",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "9",
        "-62.376",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "9",
        "-62.313",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "9",
        "-62.253",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "9",
        "-62.195",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "9",
        "-62.335",
        "19.587",
        "0.446",
        "0.626",
        "0.015"
    ],
    [
        "138",
        "9",
        "-63.905",
        "18.883",
        "2.066",
        "1.895",
        "-0.032"
    ],
    [
        "139",
        "9",
        "-65.523",
        "19.796",
        "2.127",
        "2.072",
        "-0.099"
    ],
    [
        "140",
        "9",
        "-67.659",
        "18.770",
        "1.418",
        "1.765",
        "-0.055"
    ],
    [
        "141",
        "9",
        "-68.567",
        "16.983",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "9",
        "-68.520",
        "17.429",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "9",
        "-68.472",
        "17.871",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "9",
        "-68.422",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "9",
        "-68.372",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "9",
        "-68.320",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "9",
        "-68.267",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "9",
        "-68.213",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "9",
        "-68.158",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "9",
        "-68.102",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "9",
        "-68.045",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "9",
        "-67.986",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "9",
        "-67.927",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "9",
        "-67.866",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "9",
        "-67.805",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "9",
        "-67.742",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "9",
        "-67.679",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "9",
        "-67.614",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "9",
        "-67.549",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "9",
        "-67.483",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "9",
        "-67.415",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "9",
        "-67.347",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "9",
        "-67.279",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "9",
        "-67.209",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "9",
        "-67.138",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "9",
        "-67.067",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "9",
        "-66.995",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "9",
        "-66.922",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "9",
        "-66.848",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "9",
        "-66.774",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "9",
        "-66.699",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "9",
        "-66.623",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "9",
        "-66.547",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "9",
        "-66.470",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "9",
        "-66.393",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "9",
        "-66.315",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "9",
        "-66.236",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "9",
        "-66.157",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "9",
        "-66.078",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "10",
        "-65.970",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "10",
        "-65.880",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "10",
        "-65.791",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "10",
        "-65.700",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "10",
        "-65.610",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "10",
        "-65.519",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "10",
        "-65.427",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "10",
        "-65.336",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "10",
        "-65.243",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "10",
        "-65.151",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "10",
        "-65.059",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "10",
        "-64.966",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "10",
        "-64.873",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "10",
        "-64.780",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "10",
        "-64.687",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "10",
        "-64.593",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "10",
        "-64.500",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "10",
        "-64.406",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "10",
        "-64.313",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "10",
        "-64.219",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "10",
        "-64.126",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "10",
        "-64.033",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "10",
        "-63.939",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "10",
        "-63.846",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "10",
        "-63.753",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "10",
        "-63.660",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "10",
        "-63.568",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "10",
        "-63.476",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "10",
        "-63.383",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "10",
        "-63.292",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "10",
        "-63.200",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "10",
        "-63.109",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "10",
        "-63.018",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "10",
        "-62.928",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "10",
        "-62.838",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "10",
        "-62.749",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "10",
        "-62.660",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "10",
        "-62.571",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "10",
        "-62.483",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "10",
        "-62.396",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "10",
        "-62.309",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "10",
        "-62.223",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "10",
        "-62.138",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "10",
        "-62.053",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "10",
        "-61.969",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "10",
        "-61.886",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "10",
        "-61.803",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "10",
        "-61.721",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "10",
        "-61.640",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "10",
        "-61.560",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "10",
        "-61.481",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "10",
        "-61.402",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "10",
        "-61.325",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "10",
        "-61.248",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "10",
        "-61.172",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "10",
        "-61.097",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "10",
        "-61.024",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "10",
        "-60.951",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "10",
        "-60.879",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "10",
        "-60.809",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "10",
        "-60.739",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "10",
        "-60.671",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "10",
        "-60.603",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "10",
        "-60.537",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "10",
        "-60.472",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "10",
        "-60.408",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "10",
        "-60.345",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "10",
        "-60.284",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "10",
        "-60.224",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "10",
        "-60.165",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "10",
        "-60.107",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "10",
        "-60.051",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "10",
        "-59.996",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "10",
        "-59.942",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "10",
        "-59.890",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "10",
        "-59.839",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "10",
        "-0.056",
        "24.137",
        "9.367",
        "11.838",
        "-0.026"
    ],
    [
        "-103",
        "10",
        "45.561",
        "35.785",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "10",
        "45.673",
        "38.347",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "10",
        "45.794",
        "40.896",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "10",
        "45.922",
        "43.433",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "10",
        "46.057",
        "45.958",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "10",
        "46.200",
        "48.468",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "10",
        "46.351",
        "50.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "10",
        "46.509",
        "53.443",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "10",
        "46.675",
        "55.906",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "10",
        "46.848",
        "58.353",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "10",
        "47.029",
        "60.781",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "10",
        "47.216",
        "63.191",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "10",
        "47.411",
        "65.582",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "10",
        "47.613",
        "67.952",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "10",
        "47.823",
        "70.303",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "10",
        "48.040",
        "72.632",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "10",
        "48.263",
        "74.939",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "10",
        "26.126",
        "40.348",
        "2.788",
        "4.098",
        "0.106"
    ],
    [
        "-85",
        "10",
        "22.334",
        "20.308",
        "2.144",
        "1.960",
        "0.001"
    ],
    [
        "-84",
        "10",
        "17.298",
        "13.276",
        "2.099",
        "2.049",
        "0.086"
    ],
    [
        "-83",
        "10",
        "21.821",
        "10.332",
        "1.926",
        "1.350",
        "0.077"
    ],
    [
        "-82",
        "10",
        "14.452",
        "4.207",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "10",
        "14.466",
        "4.654",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "10",
        "14.496",
        "5.704",
        "5.456",
        "4.733",
        "0.236"
    ],
    [
        "-79",
        "10",
        "14.701",
        "6.184",
        "5.191",
        "5.372",
        "0.088"
    ],
    [
        "-78",
        "10",
        "14.514",
        "5.988",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "10",
        "14.533",
        "6.429",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "10",
        "12.309",
        "7.892",
        "5.177",
        "6.372",
        "-0.089"
    ],
    [
        "-75",
        "10",
        "9.323",
        "10.600",
        "5.849",
        "5.700",
        "0.042"
    ],
    [
        "-74",
        "10",
        "7.163",
        "13.030",
        "4.743",
        "5.375",
        "0.020"
    ],
    [
        "-73",
        "10",
        "5.832",
        "14.040",
        "3.900",
        "4.031",
        "0.101"
    ],
    [
        "-72",
        "10",
        "5.071",
        "12.928",
        "3.795",
        "3.392",
        "0.038"
    ],
    [
        "-71",
        "10",
        "4.400",
        "12.477",
        "3.198",
        "3.175",
        "0.089"
    ],
    [
        "-70",
        "10",
        "2.545",
        "13.427",
        "2.657",
        "2.613",
        "0.026"
    ],
    [
        "-69",
        "10",
        "-1.314",
        "10.849",
        "2.927",
        "2.845",
        "-0.128"
    ],
    [
        "-68",
        "10",
        "-2.788",
        "9.706",
        "3.007",
        "3.440",
        "-0.071"
    ],
    [
        "-67",
        "10",
        "-4.994",
        "10.154",
        "2.019",
        "1.595",
        "-0.009"
    ],
    [
        "-66",
        "10",
        "-2.388",
        "10.636",
        "3.374",
        "3.209",
        "0.082"
    ],
    [
        "-65",
        "10",
        "-1.549",
        "9.929",
        "3.180",
        "3.041",
        "0.197"
    ],
    [
        "-64",
        "10",
        "-1.667",
        "10.576",
        "3.065",
        "2.925",
        "0.176"
    ],
    [
        "-63",
        "10",
        "-1.686",
        "10.760",
        "2.910",
        "2.913",
        "0.174"
    ],
    [
        "-62",
        "10",
        "-1.751",
        "12.124",
        "1.954",
        "2.912",
        "0.044"
    ],
    [
        "-61",
        "10",
        "-1.678",
        "11.175",
        "2.082",
        "3.322",
        "-0.054"
    ],
    [
        "-60",
        "10",
        "-2.170",
        "10.141",
        "2.393",
        "2.944",
        "-0.143"
    ],
    [
        "-59",
        "10",
        "-3.539",
        "10.283",
        "2.053",
        "2.650",
        "-0.095"
    ],
    [
        "-58",
        "10",
        "-4.604",
        "10.804",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "10",
        "-4.572",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "10",
        "-4.538",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "10",
        "-4.505",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "10",
        "-4.470",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "10",
        "-4.437",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "10",
        "-4.402",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "10",
        "-4.368",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "10",
        "-4.333",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "10",
        "-4.297",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "10",
        "-4.262",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "10",
        "-4.226",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "10",
        "-4.190",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "10",
        "-4.154",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "10",
        "-4.118",
        "11.978",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "10",
        "-4.082",
        "12.036",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "10",
        "-4.045",
        "12.089",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "10",
        "3.654",
        "11.652",
        "2.316",
        "2.780",
        "0.152"
    ],
    [
        "-40",
        "10",
        "21.198",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "10",
        "21.236",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "10",
        "21.276",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "10",
        "21.316",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "10",
        "21.356",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "10",
        "21.398",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "10",
        "21.441",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "10",
        "21.484",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "10",
        "21.528",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "10",
        "21.573",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "10",
        "21.617",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "10",
        "21.664",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "10",
        "21.710",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "10",
        "21.757",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "10",
        "21.805",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "10",
        "21.854",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "10",
        "21.902",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "10",
        "21.952",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "10",
        "22.002",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "10",
        "22.053",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "10",
        "22.104",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "10",
        "22.156",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "10",
        "22.208",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "10",
        "22.260",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "10",
        "22.314",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "10",
        "22.368",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "10",
        "22.422",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "10",
        "22.477",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "10",
        "22.531",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "10",
        "22.586",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "10",
        "22.641",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "10",
        "22.697",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "10",
        "22.754",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "10",
        "22.811",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "10",
        "22.867",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "10",
        "22.924",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "10",
        "22.982",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "10",
        "23.040",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "10",
        "23.098",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "10",
        "23.155",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "10",
        "23.215",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "10",
        "23.273",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "10",
        "23.331",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "10",
        "23.390",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "10",
        "23.449",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "10",
        "23.508",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "10",
        "23.567",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "10",
        "23.626",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "10",
        "23.685",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "10",
        "23.745",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "10",
        "23.804",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "10",
        "23.864",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "10",
        "23.923",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "10",
        "23.982",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "10",
        "24.041",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "10",
        "24.100",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "10",
        "24.159",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "10",
        "24.218",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "10",
        "24.277",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "10",
        "24.335",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "10",
        "24.394",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "10",
        "24.451",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "10",
        "24.510",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "10",
        "24.568",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "10",
        "24.625",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "10",
        "24.682",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "10",
        "24.740",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "10",
        "24.796",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "10",
        "24.852",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "10",
        "24.908",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "10",
        "24.965",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "10",
        "25.020",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "10",
        "25.075",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "10",
        "25.130",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "10",
        "25.184",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "10",
        "25.237",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "10",
        "25.292",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "10",
        "25.344",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "10",
        "25.396",
        "17.224",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "10",
        "25.449",
        "17.060",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "10",
        "27.062",
        "16.647",
        "2.089",
        "2.413",
        "0.428"
    ],
    [
        "41",
        "10",
        "29.392",
        "17.095",
        "2.583",
        "3.561",
        "0.276"
    ],
    [
        "42",
        "10",
        "29.833",
        "16.655",
        "2.817",
        "3.884",
        "0.166"
    ],
    [
        "43",
        "10",
        "30.053",
        "16.006",
        "2.169",
        "3.996",
        "-0.078"
    ],
    [
        "44",
        "10",
        "30.376",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "10",
        "30.420",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "10",
        "30.466",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "10",
        "30.510",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "10",
        "30.554",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "10",
        "30.597",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "10",
        "30.638",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "10",
        "30.680",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "10",
        "30.720",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "10",
        "30.760",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "10",
        "30.799",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "10",
        "30.837",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "10",
        "30.874",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "10",
        "32.370",
        "14.725",
        "9.465",
        "14.395",
        "0.425"
    ],
    [
        "58",
        "10",
        "41.807",
        "32.313",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "10",
        "41.905",
        "32.507",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "10",
        "42.004",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "10",
        "42.103",
        "32.867",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "10",
        "42.203",
        "33.033",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "10",
        "42.304",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "10",
        "42.405",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "10",
        "42.506",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "10",
        "42.607",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "10",
        "42.710",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "10",
        "42.812",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "10",
        "42.915",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "10",
        "43.017",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "10",
        "43.121",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "10",
        "43.224",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "10",
        "43.327",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "10",
        "43.430",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "10",
        "43.534",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "10",
        "43.639",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "10",
        "43.742",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "10",
        "43.847",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "10",
        "43.950",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "10",
        "44.054",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "10",
        "44.158",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "10",
        "44.261",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "10",
        "44.365",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "10",
        "44.468",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "10",
        "44.571",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "10",
        "44.674",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "10",
        "44.776",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "10",
        "44.879",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "10",
        "44.980",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "10",
        "45.082",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "10",
        "45.184",
        "33.334",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "10",
        "43.931",
        "29.258",
        "8.573",
        "7.983",
        "0.152"
    ],
    [
        "93",
        "10",
        "40.669",
        "19.176",
        "9.017",
        "8.017",
        "0.094"
    ],
    [
        "94",
        "10",
        "36.638",
        "4.691",
        "5.907",
        "6.371",
        "-0.111"
    ],
    [
        "95",
        "10",
        "35.475",
        "-2.029",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "10",
        "35.467",
        "-2.565",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "10",
        "35.459",
        "-3.102",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "10",
        "35.449",
        "-3.638",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "10",
        "35.437",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "10",
        "35.423",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "10",
        "35.408",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "10",
        "35.391",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "10",
        "35.373",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "10",
        "35.354",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "10",
        "35.331",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "10",
        "35.309",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "10",
        "35.284",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "10",
        "35.258",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "10",
        "35.231",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "10",
        "35.201",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "10",
        "35.170",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "10",
        "35.138",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "10",
        "35.104",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "10",
        "35.068",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "10",
        "35.032",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "10",
        "34.993",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "10",
        "34.953",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "10",
        "34.911",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "10",
        "34.869",
        "-14.380",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "10",
        "34.824",
        "-14.854",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "10",
        "34.779",
        "-15.323",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "10",
        "33.591",
        "-11.845",
        "3.818",
        "3.581",
        "-0.195"
    ],
    [
        "123",
        "10",
        "23.083",
        "-4.294",
        "5.744",
        "3.958",
        "-0.123"
    ],
    [
        "124",
        "10",
        "10.161",
        "0.565",
        "6.906",
        "5.112",
        "-0.101"
    ],
    [
        "125",
        "10",
        "-5.213",
        "7.540",
        "7.365",
        "5.223",
        "0.042"
    ],
    [
        "126",
        "10",
        "-24.843",
        "18.518",
        "9.366",
        "5.062",
        "-0.266"
    ],
    [
        "127",
        "10",
        "-52.365",
        "26.340",
        "8.601",
        "5.637",
        "-0.134"
    ],
    [
        "128",
        "10",
        "-61.601",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "10",
        "-61.516",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "10",
        "-61.435",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "10",
        "-61.356",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "10",
        "-61.280",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "10",
        "-61.208",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "10",
        "-61.138",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "10",
        "-61.072",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "10",
        "-61.008",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "10",
        "-60.947",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "10",
        "-62.437",
        "18.968",
        "2.819",
        "2.386",
        "-0.139"
    ],
    [
        "139",
        "10",
        "-64.118",
        "19.593",
        "3.142",
        "3.469",
        "-0.050"
    ],
    [
        "140",
        "10",
        "-67.150",
        "18.653",
        "2.359",
        "3.432",
        "0.003"
    ],
    [
        "141",
        "10",
        "-68.822",
        "16.983",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "10",
        "-68.770",
        "17.429",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "10",
        "-68.716",
        "17.871",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "10",
        "-68.662",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "10",
        "-68.605",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "10",
        "-68.548",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "10",
        "-68.489",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "10",
        "-68.429",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "10",
        "-68.368",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "10",
        "-68.306",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "10",
        "-68.242",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "10",
        "-68.177",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "10",
        "-68.111",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "10",
        "-68.044",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "10",
        "-67.976",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "10",
        "-67.906",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "10",
        "-67.836",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "10",
        "-67.764",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "10",
        "-67.692",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "10",
        "-67.618",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "10",
        "-67.544",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "10",
        "-67.468",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "10",
        "-67.392",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "10",
        "-67.314",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "10",
        "-67.236",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "10",
        "-67.157",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "10",
        "-67.077",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "10",
        "-66.996",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "10",
        "-66.914",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "10",
        "-66.832",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "10",
        "-66.749",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "10",
        "-66.665",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "10",
        "-66.580",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "10",
        "-66.495",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "10",
        "-66.409",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "10",
        "-66.322",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "10",
        "-66.235",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "10",
        "-66.147",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "10",
        "-66.059",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "11",
        "-65.922",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "11",
        "-65.824",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "11",
        "-65.725",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "11",
        "-65.626",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "11",
        "-65.526",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "11",
        "-65.426",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "11",
        "-65.326",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "11",
        "-65.225",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "11",
        "-65.124",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "11",
        "-65.022",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "11",
        "-64.921",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "11",
        "-64.819",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "11",
        "-64.717",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "11",
        "-64.614",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "11",
        "-64.512",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "11",
        "-64.409",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "11",
        "-64.307",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "11",
        "-64.204",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "11",
        "-64.101",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "11",
        "-63.999",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "11",
        "-63.896",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "11",
        "-63.793",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "11",
        "-63.691",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "11",
        "-63.589",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "11",
        "-63.486",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "11",
        "-63.384",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "11",
        "-63.283",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "11",
        "-63.181",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "11",
        "-63.080",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "11",
        "-62.979",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "11",
        "-62.879",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "11",
        "-62.779",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "11",
        "-62.679",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "11",
        "-62.580",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "11",
        "-62.481",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "11",
        "-62.382",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "11",
        "-62.285",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "11",
        "-62.188",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "11",
        "-62.091",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "11",
        "-61.995",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "11",
        "-61.900",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "11",
        "-61.805",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "11",
        "-61.711",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "11",
        "-61.618",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "11",
        "-61.526",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "11",
        "-61.434",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "11",
        "-61.343",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "11",
        "-61.253",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "11",
        "-61.164",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "11",
        "-61.076",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "11",
        "-60.989",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "11",
        "-60.903",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "11",
        "-60.818",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "11",
        "-60.733",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "11",
        "-60.650",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "11",
        "-60.568",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "11",
        "-60.487",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "11",
        "-60.407",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "11",
        "-60.328",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "11",
        "-60.251",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "11",
        "-60.174",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "11",
        "-60.099",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "11",
        "-60.025",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "11",
        "-59.952",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "11",
        "-59.881",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "11",
        "-59.811",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "11",
        "-59.742",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "11",
        "-59.674",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "11",
        "-59.608",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "11",
        "-59.543",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "11",
        "-59.480",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "11",
        "-59.418",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "11",
        "-59.358",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "11",
        "-59.298",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "11",
        "-59.241",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "11",
        "-59.185",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "11",
        "-31.042",
        "16.308",
        "9.095",
        "13.602",
        "-0.053"
    ],
    [
        "-103",
        "11",
        "42.808",
        "35.785",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "11",
        "42.931",
        "38.347",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "11",
        "43.063",
        "40.896",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "11",
        "43.204",
        "43.433",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "11",
        "43.352",
        "45.958",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "11",
        "43.510",
        "48.468",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "11",
        "43.675",
        "50.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "11",
        "43.849",
        "53.443",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "11",
        "44.031",
        "55.906",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "11",
        "44.221",
        "58.353",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "11",
        "44.420",
        "60.781",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "11",
        "44.626",
        "63.191",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "11",
        "44.841",
        "65.582",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "11",
        "45.063",
        "67.952",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "11",
        "45.293",
        "70.303",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "11",
        "33.098",
        "61.908",
        "5.209",
        "7.493",
        "0.450"
    ],
    [
        "-87",
        "11",
        "24.498",
        "51.461",
        "5.468",
        "7.844",
        "0.160"
    ],
    [
        "-86",
        "11",
        "15.776",
        "23.177",
        "3.196",
        "6.221",
        "0.115"
    ],
    [
        "-85",
        "11",
        "13.915",
        "2.857",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "11",
        "13.925",
        "3.307",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "11",
        "13.938",
        "3.758",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "11",
        "13.951",
        "4.207",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "11",
        "13.965",
        "4.654",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "11",
        "13.981",
        "5.100",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "11",
        "13.999",
        "5.545",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "11",
        "14.018",
        "5.988",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "11",
        "14.039",
        "6.429",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "11",
        "14.061",
        "6.869",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "11",
        "11.687",
        "8.781",
        "6.429",
        "6.362",
        "-0.021"
    ],
    [
        "-74",
        "11",
        "9.495",
        "11.485",
        "5.899",
        "6.763",
        "0.082"
    ],
    [
        "-73",
        "11",
        "9.055",
        "12.442",
        "5.164",
        "5.536",
        "0.005"
    ],
    [
        "-72",
        "11",
        "10.754",
        "11.243",
        "4.235",
        "4.121",
        "0.069"
    ],
    [
        "-71",
        "11",
        "8.779",
        "13.027",
        "3.427",
        "4.134",
        "-0.054"
    ],
    [
        "-70",
        "11",
        "6.272",
        "12.505",
        "2.773",
        "2.804",
        "-0.019"
    ],
    [
        "-69",
        "11",
        "5.337",
        "12.894",
        "3.552",
        "3.653",
        "-0.104"
    ],
    [
        "-68",
        "11",
        "3.649",
        "11.709",
        "3.913",
        "4.436",
        "-0.033"
    ],
    [
        "-67",
        "11",
        "2.263",
        "11.838",
        "4.007",
        "4.232",
        "0.045"
    ],
    [
        "-66",
        "11",
        "5.143",
        "10.448",
        "4.335",
        "4.367",
        "0.001"
    ],
    [
        "-65",
        "11",
        "10.887",
        "11.926",
        "2.840",
        "2.887",
        "0.043"
    ],
    [
        "-64",
        "11",
        "6.960",
        "11.983",
        "3.818",
        "3.902",
        "0.133"
    ],
    [
        "-63",
        "11",
        "6.775",
        "12.013",
        "3.645",
        "3.520",
        "0.184"
    ],
    [
        "-62",
        "11",
        "7.297",
        "14.021",
        "3.037",
        "3.551",
        "0.075"
    ],
    [
        "-61",
        "11",
        "6.569",
        "10.622",
        "2.832",
        "3.536",
        "0.003"
    ],
    [
        "-60",
        "11",
        "3.252",
        "9.471",
        "3.096",
        "3.588",
        "-0.123"
    ],
    [
        "-59",
        "11",
        "0.313",
        "9.692",
        "2.961",
        "3.394",
        "-0.109"
    ],
    [
        "-58",
        "11",
        "-2.365",
        "10.318",
        "2.697",
        "2.953",
        "-0.065"
    ],
    [
        "-57",
        "11",
        "-4.663",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "11",
        "-4.626",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "11",
        "-4.590",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "11",
        "-4.552",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "11",
        "-4.515",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "11",
        "-4.477",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "11",
        "-4.439",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "11",
        "-4.401",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "11",
        "-4.362",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "11",
        "-4.323",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "11",
        "-4.283",
        "11.785",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "11",
        "-4.244",
        "11.853",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "11",
        "-4.205",
        "11.918",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "11",
        "-2.822",
        "11.287",
        "3.158",
        "2.929",
        "0.089"
    ],
    [
        "-43",
        "11",
        "5.131",
        "11.938",
        "4.254",
        "2.523",
        "-0.026"
    ],
    [
        "-42",
        "11",
        "14.429",
        "12.296",
        "3.655",
        "2.279",
        "0.054"
    ],
    [
        "-41",
        "11",
        "20.823",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "11",
        "20.864",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "11",
        "20.906",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "11",
        "20.950",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "11",
        "20.994",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "11",
        "21.039",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "11",
        "21.085",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "11",
        "21.132",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "11",
        "21.180",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "11",
        "21.228",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "11",
        "21.277",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "11",
        "21.326",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "11",
        "21.377",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "11",
        "21.428",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "11",
        "21.479",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "11",
        "21.533",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "11",
        "21.586",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "11",
        "21.639",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "11",
        "21.694",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "11",
        "21.749",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "11",
        "21.805",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "11",
        "21.861",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "11",
        "21.918",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "11",
        "21.975",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "11",
        "22.033",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "11",
        "22.092",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "11",
        "22.151",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "11",
        "22.210",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "11",
        "22.270",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "11",
        "22.330",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "11",
        "22.390",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "11",
        "22.451",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "11",
        "22.513",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "11",
        "22.575",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "11",
        "22.637",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "11",
        "22.700",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "11",
        "22.763",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "11",
        "22.825",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "11",
        "22.889",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "11",
        "22.953",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "11",
        "23.017",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "11",
        "23.080",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "11",
        "23.145",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "11",
        "23.209",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "11",
        "23.274",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "11",
        "23.339",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "11",
        "23.404",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "11",
        "23.468",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "11",
        "23.533",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "11",
        "23.598",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "11",
        "23.664",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "11",
        "23.729",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "11",
        "23.794",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "11",
        "23.859",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "11",
        "23.924",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "11",
        "23.989",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "11",
        "24.054",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "11",
        "24.119",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "11",
        "24.183",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "11",
        "24.248",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "11",
        "24.312",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "11",
        "24.376",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "11",
        "24.441",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "11",
        "24.504",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "11",
        "24.568",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "11",
        "24.631",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "11",
        "24.694",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "11",
        "24.757",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "11",
        "24.819",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "11",
        "24.881",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "11",
        "24.943",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "11",
        "25.004",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "11",
        "25.065",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "11",
        "25.125",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "11",
        "25.185",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "11",
        "25.245",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "11",
        "25.304",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "11",
        "25.363",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "11",
        "25.421",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "11",
        "25.478",
        "17.224",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "11",
        "25.535",
        "17.060",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "11",
        "26.606",
        "17.120",
        "2.378",
        "2.578",
        "0.469"
    ],
    [
        "41",
        "11",
        "29.110",
        "18.198",
        "3.406",
        "4.084",
        "0.418"
    ],
    [
        "42",
        "11",
        "30.096",
        "18.026",
        "3.452",
        "4.362",
        "0.281"
    ],
    [
        "43",
        "11",
        "30.174",
        "16.613",
        "2.611",
        "4.291",
        "0.099"
    ],
    [
        "44",
        "11",
        "30.503",
        "15.163",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "11",
        "30.554",
        "14.938",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "11",
        "30.603",
        "14.708",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "11",
        "30.652",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "11",
        "30.699",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "11",
        "30.747",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "11",
        "30.793",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "11",
        "30.838",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "11",
        "30.882",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "11",
        "30.926",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "11",
        "30.969",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "11",
        "31.010",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "11",
        "31.051",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "11",
        "31.092",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "11",
        "41.470",
        "32.313",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "11",
        "41.577",
        "32.507",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "11",
        "41.686",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "11",
        "41.795",
        "32.867",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "11",
        "41.904",
        "33.033",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "11",
        "42.015",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "11",
        "42.126",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "11",
        "42.237",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "11",
        "42.348",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "11",
        "42.461",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "11",
        "42.574",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "11",
        "42.686",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "11",
        "42.798",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "11",
        "42.912",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "11",
        "43.026",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "11",
        "43.140",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "11",
        "43.253",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "11",
        "43.367",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "11",
        "43.482",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "11",
        "43.596",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "11",
        "43.710",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "11",
        "43.824",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "11",
        "43.938",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "11",
        "44.052",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "11",
        "44.166",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "11",
        "44.279",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "11",
        "44.393",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "11",
        "44.506",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "11",
        "44.619",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "11",
        "44.733",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "11",
        "44.844",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "11",
        "44.957",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "11",
        "45.069",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "11",
        "45.180",
        "33.334",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "11",
        "44.686",
        "31.109",
        "8.705",
        "8.002",
        "0.132"
    ],
    [
        "93",
        "11",
        "39.112",
        "23.167",
        "9.103",
        "8.001",
        "0.043"
    ],
    [
        "94",
        "11",
        "36.460",
        "13.459",
        "6.283",
        "7.158",
        "-0.055"
    ],
    [
        "95",
        "11",
        "35.673",
        "-0.837",
        "3.740",
        "3.914",
        "-0.162"
    ],
    [
        "96",
        "11",
        "35.898",
        "-2.565",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "11",
        "35.889",
        "-3.102",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "11",
        "35.877",
        "-3.638",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "11",
        "35.865",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "11",
        "35.849",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "11",
        "35.833",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "11",
        "35.815",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "11",
        "35.794",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "11",
        "35.773",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "11",
        "35.750",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "11",
        "35.724",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "11",
        "35.697",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "11",
        "35.668",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "11",
        "35.637",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "11",
        "35.605",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "11",
        "35.571",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "11",
        "35.536",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "11",
        "35.499",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "11",
        "35.459",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "11",
        "35.419",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "11",
        "35.377",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "11",
        "35.333",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "11",
        "35.287",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "11",
        "35.240",
        "-14.380",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "11",
        "35.191",
        "-14.854",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "11",
        "35.141",
        "-15.323",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "11",
        "32.531",
        "-9.492",
        "3.169",
        "3.632",
        "-0.107"
    ],
    [
        "123",
        "11",
        "22.813",
        "2.710",
        "2.963",
        "2.950",
        "-0.124"
    ],
    [
        "124",
        "11",
        "6.954",
        "5.847",
        "6.681",
        "5.244",
        "-0.060"
    ],
    [
        "125",
        "11",
        "-13.355",
        "12.367",
        "7.572",
        "5.570",
        "0.014"
    ],
    [
        "126",
        "11",
        "-34.023",
        "21.434",
        "10.025",
        "5.401",
        "-0.189"
    ],
    [
        "127",
        "11",
        "-58.352",
        "29.132",
        "2.605",
        "2.249",
        "-0.058"
    ],
    [
        "128",
        "11",
        "-60.453",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "11",
        "-60.360",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "11",
        "-60.271",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "11",
        "-60.185",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "11",
        "-60.101",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "11",
        "-60.022",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "11",
        "-59.945",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "11",
        "-59.872",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "11",
        "-59.802",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "11",
        "-59.735",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "11",
        "-59.672",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "11",
        "-60.375",
        "18.291",
        "2.802",
        "3.963",
        "0.056"
    ],
    [
        "140",
        "11",
        "-62.732",
        "18.392",
        "4.466",
        "5.348",
        "-0.093"
    ],
    [
        "141",
        "11",
        "-65.304",
        "16.766",
        "4.948",
        "5.583",
        "-0.040"
    ],
    [
        "142",
        "11",
        "-68.491",
        "17.265",
        "1.180",
        "2.189",
        "-0.004"
    ],
    [
        "143",
        "11",
        "-68.940",
        "17.871",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "11",
        "-68.880",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "11",
        "-68.818",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "11",
        "-68.755",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "11",
        "-68.690",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "11",
        "-68.624",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "11",
        "-68.557",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "11",
        "-68.489",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "11",
        "-68.419",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "11",
        "-68.347",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "11",
        "-68.275",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "11",
        "-68.201",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "11",
        "-68.126",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "11",
        "-68.050",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "11",
        "-67.972",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "11",
        "-67.894",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "11",
        "-67.814",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "11",
        "-67.733",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "11",
        "-67.651",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "11",
        "-67.568",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "11",
        "-67.484",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "11",
        "-67.399",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "11",
        "-67.313",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "11",
        "-67.226",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "11",
        "-67.138",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "11",
        "-67.049",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "11",
        "-66.960",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "11",
        "-66.869",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "11",
        "-66.778",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "11",
        "-66.685",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "11",
        "-66.592",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "11",
        "-66.499",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "11",
        "-66.404",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "11",
        "-66.309",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "11",
        "-66.213",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "11",
        "-66.117",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "11",
        "-66.020",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "12",
        "-65.854",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "12",
        "-65.747",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "12",
        "-65.639",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "12",
        "-65.531",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "12",
        "-65.423",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "12",
        "-65.314",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "12",
        "-65.204",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "12",
        "-65.095",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "12",
        "-64.984",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "12",
        "-64.874",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "12",
        "-64.763",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "12",
        "-64.652",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "12",
        "-64.541",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "12",
        "-64.429",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "12",
        "-64.318",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "12",
        "-64.206",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "12",
        "-64.094",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "12",
        "-63.982",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "12",
        "-63.870",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "12",
        "-63.758",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "12",
        "-63.646",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "12",
        "-63.535",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "12",
        "-63.423",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "12",
        "-63.311",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "12",
        "-63.200",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "12",
        "-63.089",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "12",
        "-62.978",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "12",
        "-62.868",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "12",
        "-62.757",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "12",
        "-62.647",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "12",
        "-62.538",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "12",
        "-62.429",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "12",
        "-62.320",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "12",
        "-62.212",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "12",
        "-62.104",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "12",
        "-61.997",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "12",
        "-61.891",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "12",
        "-61.785",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "12",
        "-61.680",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "12",
        "-61.575",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "12",
        "-61.471",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "12",
        "-61.368",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "12",
        "-61.266",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "12",
        "-61.164",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "12",
        "-61.064",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "12",
        "-60.964",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "12",
        "-60.865",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "12",
        "-60.767",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "12",
        "-60.670",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "12",
        "-60.574",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "12",
        "-60.479",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "12",
        "-60.385",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "12",
        "-60.292",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "12",
        "-60.200",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "12",
        "-60.110",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "12",
        "-60.020",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "12",
        "-59.932",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "12",
        "-59.845",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "12",
        "-59.759",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "12",
        "-59.674",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "12",
        "-59.591",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "12",
        "-59.509",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "12",
        "-59.428",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "12",
        "-59.349",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "12",
        "-59.271",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "12",
        "-59.195",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "12",
        "-59.120",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "12",
        "-59.046",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "12",
        "-58.974",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "12",
        "-58.904",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "12",
        "-58.835",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "12",
        "-58.767",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "12",
        "-58.701",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "12",
        "-58.637",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "12",
        "-58.574",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "12",
        "-58.513",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "12",
        "-30.051",
        "20.078",
        "13.443",
        "17.013",
        "-0.006"
    ],
    [
        "-103",
        "12",
        "40.041",
        "35.785",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "12",
        "40.175",
        "38.347",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "12",
        "40.320",
        "40.896",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "12",
        "40.472",
        "43.433",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "12",
        "40.634",
        "45.958",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "12",
        "40.806",
        "48.468",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "12",
        "40.986",
        "50.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "12",
        "41.176",
        "53.443",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "12",
        "41.374",
        "55.906",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "12",
        "41.582",
        "58.353",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "12",
        "41.798",
        "60.781",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "12",
        "42.023",
        "63.191",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "12",
        "42.256",
        "65.582",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "12",
        "42.499",
        "67.952",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "12",
        "35.703",
        "61.713",
        "4.553",
        "6.987",
        "0.360"
    ],
    [
        "-88",
        "12",
        "22.661",
        "45.017",
        "5.876",
        "9.387",
        "0.139"
    ],
    [
        "-87",
        "12",
        "16.933",
        "25.963",
        "5.394",
        "8.151",
        "0.161"
    ],
    [
        "-86",
        "12",
        "13.808",
        "3.994",
        "1.071",
        "2.397",
        "0.150"
    ],
    [
        "-85",
        "12",
        "13.406",
        "2.857",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "12",
        "13.417",
        "3.307",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "12",
        "13.430",
        "3.758",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "12",
        "13.444",
        "4.207",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "12",
        "13.461",
        "4.654",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "12",
        "13.478",
        "5.100",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "12",
        "13.497",
        "5.545",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "12",
        "13.518",
        "5.988",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "12",
        "13.541",
        "6.429",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "12",
        "13.565",
        "6.869",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "12",
        "13.590",
        "7.305",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "12",
        "12.692",
        "8.816",
        "4.891",
        "5.650",
        "0.014"
    ],
    [
        "-73",
        "12",
        "11.275",
        "10.447",
        "6.570",
        "7.246",
        "0.033"
    ],
    [
        "-72",
        "12",
        "10.196",
        "10.891",
        "6.768",
        "6.327",
        "0.042"
    ],
    [
        "-71",
        "12",
        "9.344",
        "11.734",
        "5.441",
        "5.897",
        "0.027"
    ],
    [
        "-70",
        "12",
        "8.916",
        "10.809",
        "4.217",
        "3.931",
        "-0.155"
    ],
    [
        "-69",
        "12",
        "8.580",
        "12.653",
        "5.648",
        "5.313",
        "-0.078"
    ],
    [
        "-68",
        "12",
        "8.673",
        "12.278",
        "5.453",
        "5.994",
        "-0.035"
    ],
    [
        "-67",
        "12",
        "11.731",
        "11.705",
        "4.257",
        "4.654",
        "-0.011"
    ],
    [
        "-66",
        "12",
        "11.793",
        "10.559",
        "5.442",
        "5.377",
        "0.037"
    ],
    [
        "-65",
        "12",
        "11.652",
        "12.069",
        "3.747",
        "4.847",
        "0.167"
    ],
    [
        "-64",
        "12",
        "13.108",
        "11.811",
        "2.973",
        "3.927",
        "0.024"
    ],
    [
        "-63",
        "12",
        "14.019",
        "12.340",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "12",
        "11.841",
        "16.529",
        "1.257",
        "1.961",
        "-0.005"
    ],
    [
        "-61",
        "12",
        "9.232",
        "10.611",
        "3.201",
        "4.008",
        "-0.092"
    ],
    [
        "-60",
        "12",
        "5.954",
        "9.545",
        "3.597",
        "3.707",
        "-0.156"
    ],
    [
        "-59",
        "12",
        "2.426",
        "9.393",
        "3.409",
        "3.551",
        "-0.090"
    ],
    [
        "-58",
        "12",
        "-0.946",
        "9.833",
        "3.193",
        "3.587",
        "-0.070"
    ],
    [
        "-57",
        "12",
        "-4.753",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "12",
        "-4.712",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "12",
        "-4.673",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "12",
        "-4.632",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "12",
        "-4.592",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "12",
        "-4.550",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "12",
        "-4.509",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "12",
        "-4.466",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "12",
        "-4.425",
        "11.638",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "12",
        "-4.382",
        "11.713",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "12",
        "-4.214",
        "11.766",
        "1.462",
        "1.215",
        "0.131"
    ],
    [
        "-46",
        "12",
        "-3.074",
        "11.602",
        "2.576",
        "1.756",
        "0.060"
    ],
    [
        "-45",
        "12",
        "0.148",
        "10.872",
        "4.149",
        "2.272",
        "0.176"
    ],
    [
        "-44",
        "12",
        "13.090",
        "11.889",
        "4.421",
        "2.654",
        "0.166"
    ],
    [
        "-43",
        "12",
        "20.391",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "12",
        "20.436",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "12",
        "20.480",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "12",
        "20.525",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "12",
        "20.571",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "12",
        "20.619",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "12",
        "20.667",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "12",
        "20.716",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "12",
        "20.766",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "12",
        "20.817",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "12",
        "20.869",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "12",
        "20.921",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "12",
        "20.975",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "12",
        "21.029",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "12",
        "21.084",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "12",
        "21.139",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "12",
        "21.196",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "12",
        "21.253",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "12",
        "21.311",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "12",
        "21.370",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "12",
        "21.429",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "12",
        "21.489",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "12",
        "21.550",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "12",
        "21.611",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "12",
        "21.673",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "12",
        "21.735",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "12",
        "21.799",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "12",
        "21.862",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "12",
        "21.926",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "12",
        "21.991",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "12",
        "22.057",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "12",
        "22.122",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "12",
        "22.188",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "12",
        "22.254",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "12",
        "22.322",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "12",
        "22.389",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "12",
        "22.457",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "12",
        "22.524",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "12",
        "22.594",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "12",
        "22.662",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "12",
        "22.731",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "12",
        "22.800",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "12",
        "22.871",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "12",
        "22.940",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "12",
        "23.010",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "12",
        "23.081",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "12",
        "23.151",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "12",
        "23.221",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "12",
        "23.292",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "12",
        "23.363",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "12",
        "23.434",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "12",
        "23.505",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "12",
        "23.575",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "12",
        "23.646",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "12",
        "23.717",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "12",
        "23.788",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "12",
        "23.859",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "12",
        "23.930",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "12",
        "24.001",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "12",
        "24.071",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "12",
        "24.141",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "12",
        "24.212",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "12",
        "24.282",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "12",
        "24.353",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "12",
        "24.422",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "12",
        "24.491",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "12",
        "24.561",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "12",
        "24.629",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "12",
        "24.698",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "12",
        "24.766",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "12",
        "24.834",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "12",
        "24.901",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "12",
        "24.969",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "12",
        "25.036",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "12",
        "25.102",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "12",
        "25.168",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "12",
        "25.233",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "12",
        "25.299",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "12",
        "25.362",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "12",
        "25.427",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "12",
        "25.490",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "12",
        "25.553",
        "17.224",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "12",
        "25.615",
        "17.060",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "12",
        "26.819",
        "17.341",
        "2.466",
        "2.419",
        "0.479"
    ],
    [
        "41",
        "12",
        "30.040",
        "19.330",
        "3.709",
        "4.186",
        "0.448"
    ],
    [
        "42",
        "12",
        "32.128",
        "20.335",
        "3.997",
        "4.682",
        "0.401"
    ],
    [
        "43",
        "12",
        "33.209",
        "20.267",
        "3.679",
        "5.232",
        "0.415"
    ],
    [
        "44",
        "12",
        "33.758",
        "20.938",
        "3.754",
        "7.496",
        "0.357"
    ],
    [
        "45",
        "12",
        "34.045",
        "19.843",
        "5.461",
        "10.614",
        "0.328"
    ],
    [
        "46",
        "12",
        "34.017",
        "18.821",
        "6.395",
        "12.988",
        "0.331"
    ],
    [
        "47",
        "12",
        "30.783",
        "14.474",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "12",
        "30.836",
        "14.236",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "12",
        "30.887",
        "13.992",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "12",
        "30.937",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "12",
        "30.986",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "12",
        "31.035",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "12",
        "31.083",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "12",
        "31.129",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "12",
        "31.175",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "12",
        "31.220",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "12",
        "31.263",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "12",
        "38.575",
        "26.949",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "12",
        "41.237",
        "32.507",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "12",
        "41.354",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "12",
        "41.473",
        "32.867",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "12",
        "41.594",
        "33.033",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "12",
        "41.713",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "12",
        "41.835",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "12",
        "41.955",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "12",
        "42.077",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "12",
        "42.199",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "12",
        "42.321",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "12",
        "42.445",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "12",
        "42.568",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "12",
        "42.691",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "12",
        "42.814",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "12",
        "42.939",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "12",
        "43.062",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "12",
        "43.187",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "12",
        "43.312",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "12",
        "43.436",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "12",
        "43.560",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "12",
        "43.684",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "12",
        "43.809",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "12",
        "43.933",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "12",
        "44.057",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "12",
        "44.181",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "12",
        "44.304",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "12",
        "44.428",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "12",
        "44.551",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "12",
        "44.674",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "12",
        "44.797",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "12",
        "44.919",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "12",
        "45.040",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "12",
        "45.161",
        "33.334",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "12",
        "41.552",
        "33.447",
        "7.036",
        "6.536",
        "0.025"
    ],
    [
        "93",
        "12",
        "39.358",
        "27.765",
        "8.770",
        "6.013",
        "-0.005"
    ],
    [
        "94",
        "12",
        "38.183",
        "19.907",
        "7.465",
        "6.622",
        "-0.094"
    ],
    [
        "95",
        "12",
        "35.890",
        "7.628",
        "5.732",
        "5.319",
        "-0.156"
    ],
    [
        "96",
        "12",
        "36.317",
        "-2.565",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "12",
        "36.308",
        "-3.102",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "12",
        "36.295",
        "-3.638",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "12",
        "36.280",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "12",
        "36.265",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "12",
        "36.247",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "12",
        "36.227",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "12",
        "36.205",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "12",
        "36.181",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "12",
        "36.155",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "12",
        "36.128",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "12",
        "36.099",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "12",
        "36.067",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "12",
        "36.033",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "12",
        "35.998",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "12",
        "35.962",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "12",
        "35.922",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "12",
        "35.882",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "12",
        "35.839",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "12",
        "35.796",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "12",
        "35.749",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "12",
        "35.702",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "12",
        "35.652",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "12",
        "35.601",
        "-14.380",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "12",
        "35.547",
        "-14.854",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "12",
        "32.851",
        "-14.427",
        "2.788",
        "2.748",
        "-0.197"
    ],
    [
        "122",
        "12",
        "24.077",
        "-4.496",
        "3.691",
        "3.625",
        "-0.254"
    ],
    [
        "123",
        "12",
        "16.472",
        "8.484",
        "5.307",
        "3.933",
        "-0.204"
    ],
    [
        "124",
        "12",
        "-1.644",
        "15.079",
        "6.636",
        "5.403",
        "-0.064"
    ],
    [
        "125",
        "12",
        "-24.874",
        "18.864",
        "7.923",
        "6.316",
        "-0.032"
    ],
    [
        "126",
        "12",
        "-44.599",
        "25.322",
        "9.914",
        "5.591",
        "-0.060"
    ],
    [
        "127",
        "12",
        "-59.392",
        "29.199",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "12",
        "-59.288",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "12",
        "-59.187",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "12",
        "-59.089",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "12",
        "-58.996",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "12",
        "-58.904",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "12",
        "-58.817",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "12",
        "-58.734",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "12",
        "-58.655",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "12",
        "-58.578",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "12",
        "-58.505",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "12",
        "-58.436",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "12",
        "-58.371",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "12",
        "-58.539",
        "16.648",
        "1.056",
        "1.987",
        "-0.052"
    ],
    [
        "141",
        "12",
        "-60.080",
        "15.851",
        "4.799",
        "5.577",
        "-0.175"
    ],
    [
        "142",
        "12",
        "-61.167",
        "14.595",
        "4.927",
        "6.252",
        "-0.174"
    ],
    [
        "143",
        "12",
        "-62.004",
        "14.718",
        "5.440",
        "5.897",
        "-0.216"
    ],
    [
        "144",
        "12",
        "-63.547",
        "15.601",
        "5.984",
        "5.093",
        "-0.258"
    ],
    [
        "145",
        "12",
        "-68.199",
        "17.297",
        "1.395",
        "2.095",
        "-0.175"
    ],
    [
        "146",
        "12",
        "-68.941",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "12",
        "-68.871",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "12",
        "-68.799",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "12",
        "-68.725",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "12",
        "-68.651",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "12",
        "-68.575",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "12",
        "-68.497",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "12",
        "-68.418",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "12",
        "-68.337",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "12",
        "-68.256",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "12",
        "-68.173",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "12",
        "-68.088",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "12",
        "-68.003",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "12",
        "-67.916",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "12",
        "-67.828",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "12",
        "-67.739",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "12",
        "-67.648",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "12",
        "-67.556",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "12",
        "-67.464",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "12",
        "-67.370",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "12",
        "-67.275",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "12",
        "-67.179",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "12",
        "-67.083",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "12",
        "-66.985",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "12",
        "-66.886",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "12",
        "-66.786",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "12",
        "-66.686",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "12",
        "-66.584",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "12",
        "-66.482",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "12",
        "-66.379",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "12",
        "-66.276",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "12",
        "-66.171",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "12",
        "-66.066",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "12",
        "-65.960",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "13",
        "-65.766",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "13",
        "-65.650",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "13",
        "-65.534",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "13",
        "-65.417",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "13",
        "-65.299",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "13",
        "-65.182",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "13",
        "-65.063",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "13",
        "-64.944",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "13",
        "-64.825",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "13",
        "-64.706",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "13",
        "-64.586",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "13",
        "-64.465",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "13",
        "-64.345",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "13",
        "-64.224",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "13",
        "-64.104",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "13",
        "-63.983",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "13",
        "-63.862",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "13",
        "-63.741",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "13",
        "-63.620",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "13",
        "-63.498",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "13",
        "-63.377",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "13",
        "-63.257",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "13",
        "-63.136",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "13",
        "-63.015",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "13",
        "-62.895",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "13",
        "-62.774",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "13",
        "-62.654",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "13",
        "-62.535",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "13",
        "-62.416",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "13",
        "-62.297",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "13",
        "-62.178",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "13",
        "-62.060",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "13",
        "-61.943",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "13",
        "-61.826",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "13",
        "-61.709",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "13",
        "-61.593",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "13",
        "-61.478",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "13",
        "-61.363",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "13",
        "-61.250",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "13",
        "-61.136",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "13",
        "-61.024",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "13",
        "-60.913",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "13",
        "-60.802",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "13",
        "-60.692",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "13",
        "-60.583",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "13",
        "-60.475",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "13",
        "-60.368",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "13",
        "-60.262",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "13",
        "-60.157",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "13",
        "-60.053",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "13",
        "-59.950",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "13",
        "-59.849",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "13",
        "-59.748",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "13",
        "-59.649",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "13",
        "-59.551",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "13",
        "-59.454",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "13",
        "-59.359",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "13",
        "-59.264",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "13",
        "-59.171",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "13",
        "-59.080",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "13",
        "-58.990",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "13",
        "-58.901",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "13",
        "-58.814",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "13",
        "-58.728",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "13",
        "-58.644",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "13",
        "-58.561",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "13",
        "-58.480",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "13",
        "-58.400",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "13",
        "-58.322",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "13",
        "-58.246",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "13",
        "-58.171",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "13",
        "-58.098",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "13",
        "-58.027",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "13",
        "-57.957",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "13",
        "-57.889",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "13",
        "-57.823",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "13",
        "12.527",
        "28.791",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "13",
        "37.262",
        "35.785",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "13",
        "37.408",
        "38.347",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "13",
        "37.563",
        "40.896",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "13",
        "37.729",
        "43.433",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "13",
        "37.904",
        "45.958",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "13",
        "38.090",
        "48.468",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "13",
        "38.285",
        "50.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "13",
        "38.490",
        "53.443",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "13",
        "38.705",
        "55.906",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "13",
        "38.929",
        "58.353",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "13",
        "39.163",
        "60.781",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "13",
        "37.813",
        "60.864",
        "4.016",
        "6.922",
        "0.351"
    ],
    [
        "-91",
        "13",
        "33.030",
        "51.959",
        "6.360",
        "11.668",
        "0.476"
    ],
    [
        "-90",
        "13",
        "27.780",
        "42.496",
        "7.492",
        "12.348",
        "0.393"
    ],
    [
        "-89",
        "13",
        "21.149",
        "30.937",
        "6.856",
        "11.835",
        "0.231"
    ],
    [
        "-88",
        "13",
        "16.291",
        "16.869",
        "5.643",
        "9.681",
        "0.057"
    ],
    [
        "-87",
        "13",
        "12.873",
        "1.952",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "13",
        "12.882",
        "2.405",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "13",
        "12.892",
        "2.857",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "13",
        "12.905",
        "3.307",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "13",
        "12.918",
        "3.758",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "13",
        "12.934",
        "4.207",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "13",
        "12.951",
        "4.654",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "13",
        "12.970",
        "5.100",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "13",
        "12.991",
        "5.545",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "13",
        "13.014",
        "5.988",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "13",
        "13.039",
        "6.429",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "13",
        "13.065",
        "6.869",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "13",
        "13.092",
        "7.305",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "13",
        "13.121",
        "7.740",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "13",
        "12.998",
        "8.413",
        "1.819",
        "2.832",
        "0.015"
    ],
    [
        "-72",
        "13",
        "11.981",
        "9.676",
        "7.342",
        "7.181",
        "-0.002"
    ],
    [
        "-71",
        "13",
        "11.285",
        "10.465",
        "7.245",
        "7.785",
        "0.021"
    ],
    [
        "-70",
        "13",
        "10.861",
        "10.794",
        "7.148",
        "7.391",
        "-0.027"
    ],
    [
        "-69",
        "13",
        "11.171",
        "11.309",
        "7.181",
        "6.844",
        "-0.035"
    ],
    [
        "-68",
        "13",
        "12.168",
        "10.939",
        "5.795",
        "6.815",
        "0.013"
    ],
    [
        "-67",
        "13",
        "13.376",
        "10.712",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "13",
        "13.419",
        "11.123",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "13",
        "13.463",
        "11.533",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "13",
        "13.510",
        "11.939",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "13",
        "13.557",
        "12.340",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "13",
        "13.606",
        "12.738",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "13",
        "11.702",
        "10.439",
        "3.537",
        "4.133",
        "-0.160"
    ],
    [
        "-60",
        "13",
        "11.409",
        "10.882",
        "2.852",
        "3.151",
        "-0.220"
    ],
    [
        "-59",
        "13",
        "6.213",
        "8.477",
        "2.932",
        "3.578",
        "-0.079"
    ],
    [
        "-58",
        "13",
        "0.087",
        "9.637",
        "3.177",
        "3.609",
        "-0.061"
    ],
    [
        "-57",
        "13",
        "-4.841",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "13",
        "-4.798",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "13",
        "-4.755",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "13",
        "-4.711",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "13",
        "-4.667",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "13",
        "-4.622",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "13",
        "-4.578",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "13",
        "-4.532",
        "11.559",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "13",
        "-4.480",
        "11.650",
        "0.224",
        "0.310",
        "0.050"
    ],
    [
        "-48",
        "13",
        "-4.268",
        "11.806",
        "1.128",
        "0.893",
        "0.032"
    ],
    [
        "-47",
        "13",
        "-2.615",
        "12.033",
        "2.115",
        "1.423",
        "0.161"
    ],
    [
        "-46",
        "13",
        "2.262",
        "11.781",
        "3.218",
        "2.215",
        "0.084"
    ],
    [
        "-45",
        "13",
        "11.552",
        "10.976",
        "4.422",
        "2.630",
        "0.208"
    ],
    [
        "-44",
        "13",
        "19.990",
        "11.567",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "13",
        "20.035",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "13",
        "20.083",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "13",
        "20.130",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "13",
        "20.179",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "13",
        "20.230",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "13",
        "20.281",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "13",
        "20.333",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "13",
        "20.386",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "13",
        "20.440",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "13",
        "20.495",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "13",
        "20.551",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "13",
        "20.608",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "13",
        "20.666",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "13",
        "20.724",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "13",
        "20.783",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "13",
        "20.844",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "13",
        "20.905",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "13",
        "20.967",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "13",
        "21.029",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "13",
        "21.094",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "13",
        "21.157",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "13",
        "21.223",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "13",
        "21.288",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "13",
        "21.354",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "13",
        "21.422",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "13",
        "21.489",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "13",
        "21.557",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "13",
        "21.627",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "13",
        "21.695",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "13",
        "21.766",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "13",
        "21.836",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "13",
        "21.907",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "13",
        "21.979",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "13",
        "22.050",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "13",
        "22.124",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "13",
        "22.196",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "13",
        "22.269",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "13",
        "22.344",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "13",
        "22.417",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "13",
        "22.492",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "13",
        "22.566",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "13",
        "22.641",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "13",
        "22.717",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "13",
        "22.793",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "13",
        "22.868",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "13",
        "22.944",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "13",
        "23.021",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "13",
        "23.096",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "13",
        "23.173",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "13",
        "23.250",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "13",
        "23.326",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "13",
        "23.403",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "13",
        "23.479",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "13",
        "23.556",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "13",
        "23.633",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "13",
        "23.710",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "13",
        "23.787",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "13",
        "23.863",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "13",
        "23.940",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "13",
        "24.017",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "13",
        "24.092",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "13",
        "24.168",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "13",
        "24.244",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "13",
        "24.320",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "13",
        "24.395",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "13",
        "24.471",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "13",
        "24.546",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "13",
        "24.620",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "13",
        "24.694",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "13",
        "24.768",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "13",
        "24.842",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "13",
        "24.915",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "13",
        "24.987",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "13",
        "25.060",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "13",
        "25.132",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "13",
        "25.203",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "13",
        "25.273",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "13",
        "25.344",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "13",
        "25.414",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "13",
        "25.483",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "13",
        "25.551",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "13",
        "25.619",
        "17.224",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "13",
        "25.686",
        "17.060",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "13",
        "27.022",
        "17.755",
        "2.762",
        "2.907",
        "0.434"
    ],
    [
        "41",
        "13",
        "30.740",
        "20.668",
        "4.203",
        "4.746",
        "0.386"
    ],
    [
        "42",
        "13",
        "33.279",
        "22.407",
        "4.491",
        "5.040",
        "0.387"
    ],
    [
        "43",
        "13",
        "34.783",
        "24.254",
        "4.104",
        "5.762",
        "0.278"
    ],
    [
        "44",
        "13",
        "36.326",
        "25.835",
        "3.792",
        "6.206",
        "0.268"
    ],
    [
        "45",
        "13",
        "39.011",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "13",
        "39.121",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "13",
        "39.233",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "13",
        "39.346",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "13",
        "36.857",
        "25.302",
        "5.254",
        "9.305",
        "0.057"
    ],
    [
        "50",
        "13",
        "31.072",
        "13.746",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "13",
        "31.126",
        "13.494",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "13",
        "31.178",
        "13.239",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "13",
        "31.230",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "13",
        "31.281",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "13",
        "31.330",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "13",
        "31.378",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "13",
        "31.425",
        "11.902",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "13",
        "35.334",
        "20.260",
        "7.983",
        "14.027",
        "0.356"
    ],
    [
        "59",
        "13",
        "40.883",
        "32.507",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "13",
        "41.011",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "13",
        "41.140",
        "32.867",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "13",
        "41.269",
        "33.033",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "13",
        "41.399",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "13",
        "41.530",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "13",
        "41.661",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "13",
        "41.793",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "13",
        "41.924",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "13",
        "42.057",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "13",
        "42.190",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "13",
        "42.323",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "13",
        "42.457",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "13",
        "42.591",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "13",
        "42.725",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "13",
        "42.859",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "13",
        "42.993",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "13",
        "43.128",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "13",
        "43.262",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "13",
        "43.397",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "13",
        "43.532",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "13",
        "43.666",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "13",
        "43.801",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "13",
        "43.935",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "13",
        "44.069",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "13",
        "44.203",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "13",
        "44.336",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "13",
        "44.470",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "13",
        "44.603",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "13",
        "44.735",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "13",
        "44.867",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "13",
        "44.999",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "13",
        "45.130",
        "33.334",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "13",
        "44.859",
        "33.095",
        "0.349",
        "0.388",
        "-0.191"
    ],
    [
        "93",
        "13",
        "40.311",
        "31.015",
        "2.050",
        "1.797",
        "0.251"
    ],
    [
        "94",
        "13",
        "32.662",
        "25.391",
        "3.393",
        "2.338",
        "-0.043"
    ],
    [
        "95",
        "13",
        "32.866",
        "16.736",
        "4.404",
        "4.049",
        "0.157"
    ],
    [
        "96",
        "13",
        "36.726",
        "-2.565",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "13",
        "36.715",
        "-3.102",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "13",
        "36.702",
        "-3.638",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "13",
        "36.687",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "13",
        "36.669",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "13",
        "36.650",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "13",
        "36.628",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "13",
        "36.604",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "13",
        "36.578",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "13",
        "36.551",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "13",
        "36.520",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "13",
        "36.489",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "13",
        "36.455",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "13",
        "36.419",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "13",
        "36.381",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "13",
        "36.341",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "13",
        "36.299",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "13",
        "36.255",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "13",
        "36.209",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "13",
        "36.162",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "13",
        "36.111",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "13",
        "36.060",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "13",
        "36.006",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "13",
        "35.951",
        "-14.380",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "13",
        "31.607",
        "-15.569",
        "5.494",
        "4.639",
        "0.053"
    ],
    [
        "121",
        "13",
        "21.308",
        "-7.251",
        "4.884",
        "5.238",
        "-0.160"
    ],
    [
        "122",
        "13",
        "8.840",
        "2.565",
        "4.727",
        "4.410",
        "-0.143"
    ],
    [
        "123",
        "13",
        "-2.363",
        "15.171",
        "6.004",
        "4.511",
        "-0.144"
    ],
    [
        "124",
        "13",
        "-13.762",
        "30.704",
        "5.885",
        "4.863",
        "-0.037"
    ],
    [
        "125",
        "13",
        "-33.959",
        "24.698",
        "7.598",
        "7.067",
        "0.127"
    ],
    [
        "126",
        "13",
        "-58.333",
        "30.119",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "13",
        "-58.217",
        "29.199",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "13",
        "-58.104",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "13",
        "-57.995",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "13",
        "-57.889",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "13",
        "-57.787",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "13",
        "-57.690",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "13",
        "-57.595",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "13",
        "-57.505",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "13",
        "-57.418",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "13",
        "-57.336",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "13",
        "-57.257",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "13",
        "-57.183",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "13",
        "-57.112",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "13",
        "-57.045",
        "16.528",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "13",
        "-56.982",
        "15.509",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "13",
        "-56.922",
        "14.231",
        "2.881",
        "4.388",
        "-0.069"
    ],
    [
        "143",
        "13",
        "-56.868",
        "13.458",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "13",
        "-56.817",
        "12.427",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "13",
        "-45.694",
        "8.147",
        "5.348",
        "4.063",
        "-0.200"
    ],
    [
        "146",
        "13",
        "-64.137",
        "18.536",
        "4.116",
        "3.256",
        "-0.244"
    ],
    [
        "147",
        "13",
        "-69.030",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "13",
        "-68.952",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "13",
        "-68.873",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "13",
        "-68.792",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "13",
        "-68.709",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "13",
        "-68.625",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "13",
        "-68.540",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "13",
        "-68.453",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "13",
        "-68.365",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "13",
        "-68.275",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "13",
        "-68.183",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "13",
        "-68.091",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "13",
        "-67.997",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "13",
        "-67.902",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "13",
        "-67.805",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "13",
        "-67.707",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "13",
        "-67.608",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "13",
        "-67.508",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "13",
        "-67.406",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "13",
        "-67.304",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "13",
        "-67.200",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "13",
        "-67.095",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "13",
        "-66.989",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "13",
        "-66.883",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "13",
        "-66.775",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "13",
        "-66.666",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "13",
        "-66.556",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "13",
        "-66.446",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "13",
        "-66.334",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "13",
        "-66.222",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "13",
        "-66.109",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "13",
        "-65.995",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "13",
        "-65.881",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "14",
        "-65.658",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "14",
        "-65.533",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "14",
        "-65.408",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "14",
        "-65.283",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "14",
        "-65.156",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "14",
        "-65.029",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "14",
        "-64.902",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "14",
        "-64.774",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "14",
        "-64.646",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "14",
        "-64.517",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "14",
        "-64.389",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "14",
        "-64.259",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "14",
        "-64.130",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "14",
        "-64.000",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "14",
        "-63.870",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "14",
        "-63.740",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "14",
        "-63.610",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "14",
        "-63.480",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "14",
        "-63.350",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "14",
        "-63.219",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "14",
        "-63.089",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "14",
        "-62.959",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "14",
        "-62.829",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "14",
        "-62.699",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "14",
        "-62.570",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "14",
        "-62.441",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "14",
        "-62.312",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "14",
        "-62.183",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "14",
        "-62.055",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "14",
        "-61.927",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "14",
        "-61.799",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "14",
        "-61.672",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "14",
        "-61.546",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "14",
        "-61.420",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "14",
        "-61.295",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "14",
        "-61.170",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "14",
        "-61.046",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "14",
        "-60.923",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "14",
        "-60.801",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "14",
        "-60.679",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "14",
        "-60.558",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "14",
        "-60.438",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "14",
        "-60.319",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "14",
        "-60.201",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "14",
        "-60.084",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "14",
        "-59.968",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "14",
        "-59.853",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "14",
        "-59.739",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "14",
        "-59.626",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "14",
        "-59.514",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "14",
        "-59.404",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "14",
        "-59.294",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "14",
        "-59.186",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "14",
        "-59.079",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "14",
        "-58.974",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "14",
        "-58.870",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "14",
        "-58.767",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "14",
        "-58.666",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "14",
        "-58.566",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "14",
        "-58.467",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "14",
        "-58.371",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "14",
        "-58.275",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "14",
        "-58.181",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "14",
        "-58.089",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "14",
        "-57.999",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "14",
        "-57.910",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "14",
        "-57.822",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "14",
        "-57.737",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "14",
        "-57.653",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "14",
        "-57.571",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "14",
        "-57.490",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "14",
        "-57.412",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "14",
        "-57.335",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "14",
        "-57.260",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "14",
        "-57.187",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "14",
        "-57.116",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "14",
        "25.516",
        "32.159",
        "4.497",
        "7.067",
        "0.125"
    ],
    [
        "-103",
        "14",
        "34.472",
        "35.785",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "14",
        "34.629",
        "38.347",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "14",
        "34.796",
        "40.896",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "14",
        "34.974",
        "43.433",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "14",
        "35.163",
        "45.958",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "14",
        "35.362",
        "48.468",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "14",
        "35.572",
        "50.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "14",
        "35.792",
        "53.443",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "14",
        "36.023",
        "55.906",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "14",
        "35.438",
        "57.248",
        "1.526",
        "3.174",
        "0.318"
    ],
    [
        "-93",
        "14",
        "29.835",
        "48.905",
        "6.806",
        "10.830",
        "0.503"
    ],
    [
        "-92",
        "14",
        "27.037",
        "41.680",
        "6.819",
        "11.660",
        "0.430"
    ],
    [
        "-91",
        "14",
        "23.906",
        "32.462",
        "7.441",
        "12.169",
        "0.365"
    ],
    [
        "-90",
        "14",
        "20.650",
        "22.436",
        "8.433",
        "12.400",
        "0.234"
    ],
    [
        "-89",
        "14",
        "17.569",
        "12.687",
        "7.462",
        "11.696",
        "0.120"
    ],
    [
        "-88",
        "14",
        "14.183",
        "3.695",
        "4.682",
        "7.185",
        "-0.009"
    ],
    [
        "-87",
        "14",
        "12.355",
        "1.952",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "14",
        "12.364",
        "2.405",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "14",
        "12.375",
        "2.857",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "14",
        "12.388",
        "3.307",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "14",
        "12.403",
        "3.758",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "14",
        "12.420",
        "4.207",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "14",
        "12.438",
        "4.654",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "14",
        "12.459",
        "5.100",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "14",
        "12.481",
        "5.545",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "14",
        "12.506",
        "5.988",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "14",
        "12.532",
        "6.429",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "14",
        "12.560",
        "6.869",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "14",
        "12.590",
        "7.305",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "14",
        "12.622",
        "7.740",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "14",
        "12.655",
        "8.173",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "14",
        "12.691",
        "8.603",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "14",
        "12.729",
        "9.031",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "14",
        "12.767",
        "9.455",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "14",
        "12.808",
        "9.877",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "14",
        "12.851",
        "10.296",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "14",
        "12.895",
        "10.712",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "14",
        "12.941",
        "11.123",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "14",
        "12.989",
        "11.533",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "14",
        "13.039",
        "11.939",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "14",
        "13.089",
        "12.340",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "14",
        "13.143",
        "12.738",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "14",
        "12.443",
        "12.518",
        "0.812",
        "1.059",
        "-0.153"
    ],
    [
        "-60",
        "14",
        "6.391",
        "8.629",
        "3.618",
        "3.421",
        "-0.103"
    ],
    [
        "-59",
        "14",
        "2.968",
        "8.760",
        "3.024",
        "3.491",
        "-0.139"
    ],
    [
        "-58",
        "14",
        "-2.323",
        "10.129",
        "1.983",
        "2.489",
        "-0.097"
    ],
    [
        "-57",
        "14",
        "-4.928",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "14",
        "-4.882",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "14",
        "-4.835",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "14",
        "-4.788",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "14",
        "-4.740",
        "11.302",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "14",
        "-4.693",
        "11.391",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "14",
        "-4.644",
        "11.477",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "14",
        "-4.607",
        "11.589",
        "0.570",
        "0.609",
        "0.048"
    ],
    [
        "-49",
        "14",
        "-4.542",
        "11.779",
        "0.947",
        "0.907",
        "-0.042"
    ],
    [
        "-48",
        "14",
        "-4.093",
        "12.041",
        "1.613",
        "1.118",
        "0.030"
    ],
    [
        "-47",
        "14",
        "-1.429",
        "12.656",
        "2.482",
        "1.615",
        "0.038"
    ],
    [
        "-46",
        "14",
        "6.301",
        "12.704",
        "3.257",
        "2.245",
        "-0.035"
    ],
    [
        "-45",
        "14",
        "15.790",
        "11.677",
        "4.055",
        "2.422",
        "0.009"
    ],
    [
        "-44",
        "14",
        "19.623",
        "11.567",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "14",
        "19.673",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "14",
        "19.723",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "14",
        "19.775",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "14",
        "19.828",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "14",
        "19.882",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "14",
        "19.937",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "14",
        "19.993",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "14",
        "20.050",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "14",
        "20.108",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "14",
        "20.167",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "14",
        "20.227",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "14",
        "20.289",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "14",
        "20.350",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "14",
        "20.413",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "14",
        "20.478",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "14",
        "20.542",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "14",
        "20.609",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "14",
        "20.675",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "14",
        "20.742",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "14",
        "20.810",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "14",
        "20.879",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "14",
        "20.949",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "14",
        "21.020",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "14",
        "21.091",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "14",
        "21.163",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "14",
        "21.236",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "14",
        "21.309",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "14",
        "21.383",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "14",
        "21.458",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "14",
        "21.534",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "14",
        "21.609",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "14",
        "21.686",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "14",
        "21.763",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "14",
        "21.840",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "14",
        "21.918",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "14",
        "21.997",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "14",
        "22.075",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "14",
        "22.155",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "14",
        "22.235",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "14",
        "22.315",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "14",
        "22.395",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "14",
        "22.476",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "14",
        "22.557",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "14",
        "22.638",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "14",
        "22.720",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "14",
        "22.801",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "14",
        "22.883",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "14",
        "22.966",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "14",
        "23.048",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "14",
        "23.129",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "14",
        "23.213",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "14",
        "23.294",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "14",
        "23.377",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "14",
        "23.460",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "14",
        "23.543",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "14",
        "23.625",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "14",
        "23.707",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "14",
        "23.790",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "14",
        "23.871",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "14",
        "23.954",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "14",
        "24.036",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "14",
        "24.118",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "14",
        "24.200",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "14",
        "24.281",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "14",
        "24.362",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "14",
        "24.443",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "14",
        "24.524",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "14",
        "24.603",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "14",
        "24.683",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "14",
        "24.763",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "14",
        "24.842",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "14",
        "24.921",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "14",
        "24.999",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "14",
        "25.077",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "14",
        "25.154",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "14",
        "25.230",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "14",
        "25.307",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "14",
        "25.382",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "14",
        "25.457",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "14",
        "25.532",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "14",
        "25.606",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "14",
        "25.678",
        "17.224",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "14",
        "25.750",
        "17.060",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "14",
        "27.314",
        "18.281",
        "3.678",
        "4.404",
        "0.342"
    ],
    [
        "41",
        "14",
        "31.220",
        "21.938",
        "5.488",
        "6.037",
        "0.236"
    ],
    [
        "42",
        "14",
        "33.814",
        "24.144",
        "5.466",
        "5.582",
        "0.282"
    ],
    [
        "43",
        "14",
        "35.577",
        "25.749",
        "4.435",
        "5.416",
        "0.267"
    ],
    [
        "44",
        "14",
        "37.033",
        "26.677",
        "3.595",
        "4.638",
        "0.396"
    ],
    [
        "45",
        "14",
        "38.422",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "14",
        "38.541",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "14",
        "38.661",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "14",
        "38.782",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "14",
        "38.906",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "14",
        "39.031",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "14",
        "39.158",
        "30.137",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "14",
        "33.380",
        "17.713",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "14",
        "31.368",
        "12.980",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "14",
        "31.422",
        "12.715",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "14",
        "31.475",
        "12.449",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "14",
        "31.527",
        "12.177",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "14",
        "34.003",
        "16.940",
        "10.778",
        "18.687",
        "0.281"
    ],
    [
        "58",
        "14",
        "36.800",
        "24.841",
        "10.513",
        "20.147",
        "0.275"
    ],
    [
        "59",
        "14",
        "40.517",
        "32.507",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "14",
        "40.655",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "14",
        "40.793",
        "32.867",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "14",
        "40.933",
        "33.033",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "14",
        "41.072",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "14",
        "41.213",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "14",
        "41.354",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "14",
        "41.496",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "14",
        "41.637",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "14",
        "41.780",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "14",
        "41.923",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "14",
        "42.066",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "14",
        "42.210",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "14",
        "42.353",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "14",
        "42.498",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "14",
        "42.643",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "14",
        "42.786",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "14",
        "42.931",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "14",
        "43.076",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "14",
        "43.221",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "14",
        "43.365",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "14",
        "43.510",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "14",
        "43.655",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "14",
        "43.799",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "14",
        "43.943",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "14",
        "44.087",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "14",
        "44.231",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "14",
        "44.374",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "14",
        "44.517",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "14",
        "44.659",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "14",
        "44.802",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "14",
        "44.943",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "14",
        "45.084",
        "33.334",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "14",
        "45.078",
        "33.249",
        "0.251",
        "0.338",
        "-0.012"
    ],
    [
        "93",
        "14",
        "41.006",
        "30.917",
        "1.241",
        "1.037",
        "0.090"
    ],
    [
        "94",
        "14",
        "35.789",
        "26.209",
        "1.391",
        "1.372",
        "0.111"
    ],
    [
        "95",
        "14",
        "32.080",
        "20.527",
        "1.637",
        "1.788",
        "0.090"
    ],
    [
        "96",
        "14",
        "30.028",
        "3.388",
        "2.439",
        "1.640",
        "0.001"
    ],
    [
        "97",
        "14",
        "37.112",
        "-3.102",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "14",
        "37.097",
        "-3.638",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "14",
        "37.080",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "14",
        "37.062",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "14",
        "37.041",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "14",
        "37.018",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "14",
        "36.992",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "14",
        "36.964",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "14",
        "36.935",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "14",
        "36.902",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "14",
        "36.868",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "14",
        "36.832",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "14",
        "36.793",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "14",
        "36.752",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "14",
        "36.710",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "14",
        "36.664",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "14",
        "36.617",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "14",
        "36.567",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "14",
        "36.516",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "14",
        "36.463",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "14",
        "36.407",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "14",
        "36.349",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "14",
        "33.880",
        "-16.074",
        "2.454",
        "3.843",
        "0.057"
    ],
    [
        "120",
        "14",
        "14.362",
        "-15.710",
        "8.938",
        "7.526",
        "-0.072"
    ],
    [
        "121",
        "14",
        "0.276",
        "3.662",
        "6.469",
        "7.132",
        "0.100"
    ],
    [
        "122",
        "14",
        "-12.509",
        "13.084",
        "5.648",
        "5.552",
        "-0.098"
    ],
    [
        "123",
        "14",
        "-25.561",
        "22.176",
        "6.557",
        "5.211",
        "0.107"
    ],
    [
        "124",
        "14",
        "-29.176",
        "30.219",
        "5.932",
        "5.087",
        "0.032"
    ],
    [
        "125",
        "14",
        "-45.972",
        "34.764",
        "6.812",
        "6.195",
        "0.031"
    ],
    [
        "126",
        "14",
        "-57.150",
        "30.119",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "14",
        "-57.024",
        "29.199",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "14",
        "-56.903",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "14",
        "-56.786",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "14",
        "-56.672",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "14",
        "-56.562",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "14",
        "-56.458",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "14",
        "-56.356",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "14",
        "-56.259",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "14",
        "-56.166",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "14",
        "-56.077",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "14",
        "-55.992",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "14",
        "-55.912",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "14",
        "-55.835",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "14",
        "-55.763",
        "16.528",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "14",
        "-55.696",
        "15.509",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "14",
        "-55.633",
        "14.485",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "14",
        "-52.677",
        "13.078",
        "3.156",
        "4.075",
        "-0.125"
    ],
    [
        "144",
        "14",
        "-45.562",
        "11.633",
        "4.425",
        "4.089",
        "-0.164"
    ],
    [
        "145",
        "14",
        "-24.394",
        "4.127",
        "4.014",
        "3.515",
        "-0.086"
    ],
    [
        "146",
        "14",
        "-42.493",
        "18.367",
        "5.109",
        "4.046",
        "-0.179"
    ],
    [
        "147",
        "14",
        "-65.007",
        "20.636",
        "4.775",
        "3.444",
        "-0.229"
    ],
    [
        "148",
        "14",
        "-69.084",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "14",
        "-68.999",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "14",
        "-68.912",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "14",
        "-68.823",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "14",
        "-68.733",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "14",
        "-68.641",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "14",
        "-68.548",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "14",
        "-68.453",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "14",
        "-68.356",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "14",
        "-68.258",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "14",
        "-68.158",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "14",
        "-68.057",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "14",
        "-67.955",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "14",
        "-67.851",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "14",
        "-67.745",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "14",
        "-67.639",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "14",
        "-67.531",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "14",
        "-67.422",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "14",
        "-67.312",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "14",
        "-67.200",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "14",
        "-67.087",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "14",
        "-66.974",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "14",
        "-66.859",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "14",
        "-66.743",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "14",
        "-66.626",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "14",
        "-66.508",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "14",
        "-66.389",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "14",
        "-66.269",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "14",
        "-66.149",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "14",
        "-66.027",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "14",
        "-65.905",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "14",
        "-65.782",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "15",
        "-65.530",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "15",
        "-65.397",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "15",
        "-65.263",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "15",
        "-65.128",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "15",
        "-64.993",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "15",
        "-64.857",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "15",
        "-64.721",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "15",
        "-64.585",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "15",
        "-64.447",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "15",
        "-64.310",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "15",
        "-64.172",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "15",
        "-64.034",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "15",
        "-63.895",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "15",
        "-63.756",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "15",
        "-63.617",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "15",
        "-63.478",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "15",
        "-63.339",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "15",
        "-63.200",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "15",
        "-63.060",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "15",
        "-62.921",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "15",
        "-62.782",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "15",
        "-62.643",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "15",
        "-62.504",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "15",
        "-62.365",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "15",
        "-62.226",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "15",
        "-62.088",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "15",
        "-61.950",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "15",
        "-61.812",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "15",
        "-61.675",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "15",
        "-61.538",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "15",
        "-61.402",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "15",
        "-61.266",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "15",
        "-61.131",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "15",
        "-60.996",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "15",
        "-60.862",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "15",
        "-60.729",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "15",
        "-60.596",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "15",
        "-60.465",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "15",
        "-60.334",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "15",
        "-60.203",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "15",
        "-60.074",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "15",
        "-59.946",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "15",
        "-59.818",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "15",
        "-59.692",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "15",
        "-59.567",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "15",
        "-59.442",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "15",
        "-59.319",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "15",
        "-59.197",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "15",
        "-59.077",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "15",
        "-58.957",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "15",
        "-58.839",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "15",
        "-58.722",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "15",
        "-58.606",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "15",
        "-58.492",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "15",
        "-58.379",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "15",
        "-58.268",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "15",
        "-58.158",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "15",
        "-58.049",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "15",
        "-57.943",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "15",
        "-57.837",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "15",
        "-57.734",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "15",
        "-57.631",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "15",
        "-57.531",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "15",
        "-57.432",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "15",
        "-57.335",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "15",
        "-57.240",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "15",
        "-57.147",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "15",
        "-57.055",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "15",
        "-56.966",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "15",
        "-56.878",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "15",
        "-56.792",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "15",
        "-56.708",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "15",
        "-56.626",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "15",
        "-56.546",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "15",
        "-56.468",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "15",
        "-56.391",
        "16.613",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "15",
        "31.516",
        "33.213",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "15",
        "31.671",
        "35.785",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "15",
        "31.839",
        "38.347",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "15",
        "32.018",
        "40.896",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "15",
        "32.209",
        "43.433",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "15",
        "32.410",
        "45.958",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "15",
        "32.623",
        "48.468",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "15",
        "32.848",
        "50.963",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "15",
        "33.084",
        "53.443",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "15",
        "27.729",
        "48.516",
        "4.261",
        "7.156",
        "0.237"
    ],
    [
        "-94",
        "15",
        "19.846",
        "36.833",
        "6.018",
        "10.778",
        "0.256"
    ],
    [
        "-93",
        "15",
        "15.034",
        "27.400",
        "6.613",
        "10.609",
        "0.388"
    ],
    [
        "-92",
        "15",
        "12.422",
        "19.943",
        "6.629",
        "10.110",
        "0.381"
    ],
    [
        "-91",
        "15",
        "10.928",
        "12.854",
        "7.265",
        "10.177",
        "0.311"
    ],
    [
        "-90",
        "15",
        "10.707",
        "7.332",
        "8.379",
        "10.353",
        "0.130"
    ],
    [
        "-89",
        "15",
        "11.592",
        "4.163",
        "7.312",
        "9.541",
        "0.057"
    ],
    [
        "-88",
        "15",
        "12.184",
        "2.418",
        "4.475",
        "6.066",
        "-0.095"
    ],
    [
        "-87",
        "15",
        "11.832",
        "1.952",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "15",
        "11.842",
        "2.405",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "15",
        "11.855",
        "2.857",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "15",
        "11.868",
        "3.307",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "15",
        "11.884",
        "3.758",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "15",
        "11.902",
        "4.207",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "15",
        "11.922",
        "4.654",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "15",
        "11.944",
        "5.100",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "15",
        "11.968",
        "5.545",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "15",
        "11.995",
        "5.988",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "15",
        "12.022",
        "6.429",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "15",
        "12.052",
        "6.869",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "15",
        "12.084",
        "7.305",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "15",
        "12.118",
        "7.740",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "15",
        "12.154",
        "8.173",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "15",
        "12.192",
        "8.603",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "15",
        "12.232",
        "9.031",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "15",
        "12.273",
        "9.455",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "15",
        "12.317",
        "9.877",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "15",
        "12.363",
        "10.296",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "15",
        "12.410",
        "10.712",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "15",
        "12.460",
        "11.123",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "15",
        "12.511",
        "11.533",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "15",
        "12.564",
        "11.939",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "15",
        "12.618",
        "12.340",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "15",
        "12.675",
        "12.738",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "15",
        "11.942",
        "12.565",
        "0.675",
        "1.137",
        "-0.083"
    ],
    [
        "-60",
        "15",
        "4.786",
        "9.429",
        "2.946",
        "3.070",
        "-0.044"
    ],
    [
        "-59",
        "15",
        "-0.053",
        "9.034",
        "2.464",
        "3.061",
        "-0.048"
    ],
    [
        "-58",
        "15",
        "-3.887",
        "10.449",
        "1.425",
        "1.791",
        "0.029"
    ],
    [
        "-57",
        "15",
        "-5.013",
        "10.911",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "15",
        "-4.965",
        "11.013",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "15",
        "-4.914",
        "11.113",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "15",
        "-4.864",
        "11.209",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "15",
        "-4.860",
        "11.268",
        "0.538",
        "0.628",
        "0.014"
    ],
    [
        "-52",
        "15",
        "-4.869",
        "11.384",
        "0.681",
        "0.799",
        "-0.015"
    ],
    [
        "-51",
        "15",
        "-4.877",
        "11.526",
        "0.845",
        "0.790",
        "-0.038"
    ],
    [
        "-50",
        "15",
        "-4.868",
        "11.707",
        "0.928",
        "0.882",
        "-0.077"
    ],
    [
        "-49",
        "15",
        "-4.803",
        "11.918",
        "1.160",
        "1.010",
        "-0.043"
    ],
    [
        "-48",
        "15",
        "-4.336",
        "12.204",
        "2.024",
        "1.241",
        "0.047"
    ],
    [
        "-47",
        "15",
        "-1.003",
        "12.889",
        "2.879",
        "1.734",
        "0.024"
    ],
    [
        "-46",
        "15",
        "11.233",
        "12.454",
        "3.093",
        "2.029",
        "-0.108"
    ],
    [
        "-45",
        "15",
        "18.303",
        "11.318",
        "3.487",
        "2.141",
        "-0.080"
    ],
    [
        "-44",
        "15",
        "19.251",
        "11.567",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "15",
        "19.304",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "15",
        "19.358",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "15",
        "19.414",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "15",
        "19.470",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "15",
        "19.527",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "15",
        "19.587",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "15",
        "19.647",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "15",
        "19.707",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "15",
        "19.770",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "15",
        "19.833",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "15",
        "19.898",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "15",
        "19.963",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "15",
        "20.030",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "15",
        "20.097",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "15",
        "20.165",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "15",
        "20.234",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "15",
        "20.305",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "15",
        "20.376",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "15",
        "20.448",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "15",
        "20.522",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "15",
        "20.596",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "15",
        "20.670",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "15",
        "20.746",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "15",
        "20.822",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "15",
        "20.899",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "15",
        "20.977",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "15",
        "21.056",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "15",
        "21.134",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "15",
        "21.215",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "15",
        "21.296",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "15",
        "21.376",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "15",
        "21.458",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "15",
        "21.540",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "15",
        "21.623",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "15",
        "21.707",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "15",
        "21.791",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "15",
        "21.875",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "15",
        "21.959",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "15",
        "22.045",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "15",
        "22.130",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "15",
        "22.216",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "15",
        "22.303",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "15",
        "22.390",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "15",
        "22.476",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "15",
        "22.564",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "15",
        "22.651",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "15",
        "22.739",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "15",
        "22.827",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "15",
        "22.915",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "15",
        "23.003",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "15",
        "23.091",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "15",
        "23.179",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "15",
        "23.267",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "15",
        "23.355",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "15",
        "23.444",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "15",
        "23.532",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "15",
        "23.621",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "15",
        "23.709",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "15",
        "23.797",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "15",
        "23.884",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "15",
        "23.972",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "15",
        "24.060",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "15",
        "24.147",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "15",
        "24.234",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "15",
        "24.321",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "15",
        "24.407",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "15",
        "24.493",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "15",
        "24.580",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "15",
        "24.665",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "15",
        "24.750",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "15",
        "24.835",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "15",
        "24.918",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "15",
        "25.003",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "15",
        "25.085",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "15",
        "25.167",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "15",
        "25.250",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "15",
        "25.332",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "15",
        "25.413",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "15",
        "25.492",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "15",
        "25.572",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "15",
        "25.651",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "15",
        "25.729",
        "17.224",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "15",
        "25.807",
        "17.060",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "15",
        "27.778",
        "18.309",
        "5.179",
        "6.393",
        "0.169"
    ],
    [
        "41",
        "15",
        "32.141",
        "22.788",
        "7.584",
        "7.720",
        "0.045"
    ],
    [
        "42",
        "15",
        "34.953",
        "25.677",
        "6.820",
        "5.482",
        "0.097"
    ],
    [
        "43",
        "15",
        "36.801",
        "26.652",
        "4.307",
        "4.342",
        "0.130"
    ],
    [
        "44",
        "15",
        "37.527",
        "27.281",
        "2.951",
        "3.273",
        "0.347"
    ],
    [
        "45",
        "15",
        "37.822",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "15",
        "37.949",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "15",
        "38.077",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "15",
        "38.207",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "15",
        "38.340",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "15",
        "38.473",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "15",
        "38.608",
        "30.137",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "15",
        "38.746",
        "30.495",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "15",
        "38.883",
        "30.843",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "15",
        "39.024",
        "31.180",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "15",
        "35.958",
        "22.405",
        "8.469",
        "17.181",
        "0.178"
    ],
    [
        "56",
        "15",
        "36.365",
        "23.437",
        "8.722",
        "18.223",
        "0.190"
    ],
    [
        "57",
        "15",
        "39.453",
        "32.138",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "15",
        "39.599",
        "32.439",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "15",
        "39.848",
        "32.671",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "15",
        "40.286",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "15",
        "40.434",
        "32.867",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "15",
        "40.584",
        "33.033",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "15",
        "40.733",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "15",
        "40.883",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "15",
        "41.034",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "15",
        "41.185",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "15",
        "41.338",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "15",
        "41.490",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "15",
        "41.643",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "15",
        "41.796",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "15",
        "41.950",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "15",
        "42.104",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "15",
        "42.258",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "15",
        "42.413",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "15",
        "42.567",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "15",
        "42.722",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "15",
        "42.877",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "15",
        "43.032",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "15",
        "43.186",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "15",
        "43.341",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "15",
        "43.496",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "15",
        "43.650",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "15",
        "43.805",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "15",
        "43.959",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "15",
        "44.112",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "15",
        "44.266",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "15",
        "44.419",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "15",
        "44.571",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "15",
        "44.723",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "15",
        "44.874",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "15",
        "45.025",
        "33.334",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "15",
        "45.176",
        "33.189",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "15",
        "44.021",
        "32.067",
        "1.065",
        "0.884",
        "0.020"
    ],
    [
        "94",
        "15",
        "38.980",
        "27.327",
        "1.256",
        "1.181",
        "0.098"
    ],
    [
        "95",
        "15",
        "37.567",
        "20.522",
        "1.261",
        "1.351",
        "0.069"
    ],
    [
        "96",
        "15",
        "37.907",
        "7.124",
        "1.129",
        "1.133",
        "0.035"
    ],
    [
        "97",
        "15",
        "37.497",
        "-3.102",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "15",
        "37.481",
        "-3.638",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "15",
        "37.463",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "15",
        "37.444",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "15",
        "37.422",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "15",
        "37.397",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "15",
        "37.369",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "15",
        "37.340",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "15",
        "37.307",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "15",
        "37.273",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "15",
        "37.236",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "15",
        "37.197",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "15",
        "37.156",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "15",
        "37.112",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "15",
        "37.066",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "15",
        "37.018",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "15",
        "36.967",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "15",
        "36.915",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "15",
        "36.860",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "15",
        "36.803",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "15",
        "36.742",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "15",
        "36.681",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "15",
        "18.632",
        "-22.499",
        "10.088",
        "10.230",
        "0.216"
    ],
    [
        "120",
        "15",
        "-15.382",
        "-2.061",
        "5.219",
        "5.823",
        "0.264"
    ],
    [
        "121",
        "15",
        "-30.728",
        "-1.951",
        "3.279",
        "1.835",
        "-0.026"
    ],
    [
        "122",
        "15",
        "-32.921",
        "21.598",
        "5.814",
        "7.067",
        "0.151"
    ],
    [
        "123",
        "15",
        "-41.562",
        "27.494",
        "7.426",
        "5.583",
        "-0.093"
    ],
    [
        "124",
        "15",
        "-55.158",
        "32.709",
        "1.713",
        "2.331",
        "0.020"
    ],
    [
        "125",
        "15",
        "-56.086",
        "31.031",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "15",
        "-55.948",
        "30.119",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "15",
        "-55.813",
        "29.199",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "15",
        "-55.684",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "15",
        "-55.558",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "15",
        "-55.437",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "15",
        "-55.320",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "15",
        "-55.208",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "15",
        "-55.099",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "15",
        "-54.995",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "15",
        "-54.896",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "15",
        "-54.801",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "15",
        "-54.710",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "15",
        "-54.624",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "15",
        "-54.542",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "15",
        "-54.466",
        "16.528",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "15",
        "-54.393",
        "15.509",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "15",
        "-54.325",
        "14.485",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "15",
        "-54.053",
        "13.414",
        "0.643",
        "0.942",
        "-0.156"
    ],
    [
        "144",
        "15",
        "-50.660",
        "11.506",
        "3.411",
        "3.266",
        "-0.125"
    ],
    [
        "145",
        "15",
        "-42.051",
        "10.542",
        "4.085",
        "3.929",
        "-0.157"
    ],
    [
        "146",
        "15",
        "-39.124",
        "16.531",
        "4.033",
        "3.609",
        "-0.016"
    ],
    [
        "147",
        "15",
        "-54.942",
        "20.135",
        "5.836",
        "4.044",
        "-0.305"
    ],
    [
        "148",
        "15",
        "-68.399",
        "19.899",
        "1.260",
        "1.410",
        "-0.279"
    ],
    [
        "149",
        "15",
        "-69.104",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "15",
        "-69.011",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "15",
        "-68.917",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "15",
        "-68.820",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "15",
        "-68.722",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "15",
        "-68.621",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "15",
        "-68.520",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "15",
        "-68.416",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "15",
        "-68.311",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "15",
        "-68.205",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "15",
        "-68.097",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "15",
        "-67.987",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "15",
        "-67.876",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "15",
        "-67.763",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "15",
        "-67.649",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "15",
        "-67.534",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "15",
        "-67.417",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "15",
        "-67.299",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "15",
        "-67.180",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "15",
        "-67.059",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "15",
        "-66.938",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "15",
        "-66.815",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "15",
        "-66.691",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "15",
        "-66.565",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "15",
        "-66.439",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "15",
        "-66.312",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "15",
        "-66.184",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "15",
        "-66.055",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "15",
        "-65.925",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "15",
        "-65.794",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "15",
        "-65.662",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "16",
        "-65.382",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "16",
        "-65.240",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "16",
        "-65.097",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "16",
        "-64.954",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "16",
        "-64.810",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "16",
        "-64.666",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "16",
        "-64.521",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "16",
        "-64.375",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "16",
        "-64.229",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "16",
        "-64.082",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "16",
        "-63.936",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "16",
        "-63.788",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "16",
        "-63.641",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "16",
        "-63.493",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "16",
        "-63.345",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "16",
        "-63.197",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "16",
        "-63.049",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "16",
        "-62.900",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "16",
        "-62.752",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "16",
        "-62.603",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "16",
        "-62.455",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "16",
        "-62.307",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "16",
        "-62.159",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "16",
        "-62.011",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "16",
        "-61.864",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "16",
        "-61.716",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "16",
        "-61.569",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "16",
        "-61.423",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "16",
        "-61.277",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "16",
        "-61.131",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "16",
        "-60.986",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "16",
        "-60.841",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "16",
        "-60.697",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "16",
        "-60.554",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "16",
        "-60.411",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "16",
        "-60.269",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "16",
        "-60.128",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "16",
        "-59.987",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "16",
        "-59.848",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "16",
        "-59.709",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "16",
        "-59.572",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "16",
        "-59.435",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "16",
        "-59.299",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "16",
        "-59.165",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "16",
        "-59.031",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "16",
        "-58.899",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "16",
        "-58.768",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "16",
        "-58.638",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "16",
        "-58.509",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "16",
        "-58.382",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "16",
        "-58.256",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "16",
        "-58.131",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "16",
        "-58.008",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "16",
        "-57.887",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "16",
        "-57.766",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "16",
        "-57.648",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "16",
        "-57.531",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "16",
        "-57.415",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "16",
        "-57.301",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "16",
        "-57.189",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "16",
        "-57.079",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "16",
        "-56.970",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "16",
        "-56.863",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "16",
        "-56.758",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "16",
        "-56.655",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "16",
        "-56.554",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "16",
        "-56.454",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "16",
        "-56.357",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "16",
        "-56.261",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "16",
        "-56.168",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "16",
        "-56.076",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "16",
        "-55.987",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "16",
        "-55.899",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "16",
        "-55.814",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "16",
        "-55.731",
        "17.064",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "16",
        "28.541",
        "30.630",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "16",
        "28.695",
        "33.213",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "16",
        "28.861",
        "35.785",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "16",
        "29.039",
        "38.347",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "16",
        "29.230",
        "40.896",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "16",
        "29.433",
        "43.433",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "16",
        "24.836",
        "37.060",
        "4.721",
        "8.738",
        "0.242"
    ],
    [
        "-98",
        "16",
        "20.915",
        "28.000",
        "4.862",
        "11.233",
        "0.225"
    ],
    [
        "-97",
        "16",
        "17.590",
        "26.725",
        "5.184",
        "10.939",
        "0.095"
    ],
    [
        "-96",
        "16",
        "10.451",
        "22.667",
        "5.999",
        "10.257",
        "0.099"
    ],
    [
        "-95",
        "16",
        "6.447",
        "19.718",
        "5.302",
        "9.450",
        "0.105"
    ],
    [
        "-94",
        "16",
        "3.521",
        "13.586",
        "4.964",
        "8.547",
        "0.164"
    ],
    [
        "-93",
        "16",
        "-0.104",
        "6.597",
        "4.520",
        "7.296",
        "0.282"
    ],
    [
        "-92",
        "16",
        "-3.141",
        "1.176",
        "3.688",
        "6.265",
        "0.215"
    ],
    [
        "-91",
        "16",
        "-6.433",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "16",
        "-6.450",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "16",
        "-6.465",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "16",
        "-0.059",
        "-0.935",
        "4.139",
        "4.328",
        "0.020"
    ],
    [
        "-87",
        "16",
        "8.514",
        "1.697",
        "2.996",
        "2.549",
        "-0.025"
    ],
    [
        "-86",
        "16",
        "11.317",
        "2.405",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "16",
        "11.330",
        "2.857",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "16",
        "11.345",
        "3.307",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "16",
        "11.361",
        "3.758",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "16",
        "11.380",
        "4.207",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "16",
        "11.402",
        "4.654",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "16",
        "11.426",
        "5.100",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "16",
        "11.451",
        "5.545",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "16",
        "11.479",
        "5.988",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "16",
        "11.509",
        "6.429",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "16",
        "11.540",
        "6.869",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "16",
        "11.574",
        "7.305",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "16",
        "11.611",
        "7.740",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "16",
        "11.649",
        "8.173",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "16",
        "11.689",
        "8.603",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "16",
        "11.731",
        "9.031",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "16",
        "11.777",
        "9.455",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "16",
        "11.823",
        "9.877",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "16",
        "11.871",
        "10.296",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "16",
        "11.922",
        "10.712",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "16",
        "11.974",
        "11.123",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "16",
        "12.029",
        "11.533",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "16",
        "12.086",
        "11.939",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "16",
        "12.144",
        "12.340",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "16",
        "12.204",
        "12.738",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "16",
        "7.058",
        "12.067",
        "2.222",
        "2.369",
        "-0.010"
    ],
    [
        "-60",
        "16",
        "1.623",
        "10.550",
        "2.584",
        "2.578",
        "0.030"
    ],
    [
        "-59",
        "16",
        "-2.348",
        "9.928",
        "2.090",
        "2.467",
        "0.000"
    ],
    [
        "-58",
        "16",
        "-4.360",
        "10.342",
        "1.650",
        "1.762",
        "0.066"
    ],
    [
        "-57",
        "16",
        "-5.062",
        "10.408",
        "1.276",
        "1.177",
        "0.067"
    ],
    [
        "-56",
        "16",
        "-5.275",
        "10.539",
        "0.915",
        "0.978",
        "0.062"
    ],
    [
        "-55",
        "16",
        "-5.306",
        "10.768",
        "0.800",
        "0.868",
        "0.027"
    ],
    [
        "-54",
        "16",
        "-5.293",
        "10.991",
        "0.846",
        "0.822",
        "0.004"
    ],
    [
        "-53",
        "16",
        "-5.289",
        "11.187",
        "0.829",
        "0.898",
        "-0.018"
    ],
    [
        "-52",
        "16",
        "-5.295",
        "11.370",
        "0.873",
        "0.931",
        "-0.017"
    ],
    [
        "-51",
        "16",
        "-5.289",
        "11.576",
        "0.964",
        "0.897",
        "-0.040"
    ],
    [
        "-50",
        "16",
        "-5.269",
        "11.812",
        "0.990",
        "0.926",
        "-0.068"
    ],
    [
        "-49",
        "16",
        "-5.246",
        "12.089",
        "1.145",
        "0.992",
        "-0.047"
    ],
    [
        "-48",
        "16",
        "-4.892",
        "12.310",
        "2.126",
        "1.226",
        "0.033"
    ],
    [
        "-47",
        "16",
        "-2.208",
        "12.565",
        "2.843",
        "1.689",
        "0.010"
    ],
    [
        "-46",
        "16",
        "18.765",
        "11.010",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "16",
        "18.819",
        "11.291",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "16",
        "18.873",
        "11.567",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "16",
        "18.929",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "16",
        "18.988",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "16",
        "19.046",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "16",
        "19.107",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "16",
        "19.168",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "16",
        "19.230",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "16",
        "19.295",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "16",
        "19.360",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "16",
        "19.425",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "16",
        "19.493",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "16",
        "19.562",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "16",
        "19.632",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "16",
        "19.702",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "16",
        "19.774",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "16",
        "19.847",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "16",
        "19.921",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "16",
        "19.996",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "16",
        "20.071",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "16",
        "20.148",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "16",
        "20.226",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "16",
        "20.305",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "16",
        "20.385",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "16",
        "20.464",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "16",
        "20.547",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "16",
        "20.628",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "16",
        "20.711",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "16",
        "20.795",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "16",
        "20.879",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "16",
        "20.964",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "16",
        "21.050",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "16",
        "21.136",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "16",
        "21.224",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "16",
        "21.311",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "16",
        "21.399",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "16",
        "21.488",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "16",
        "21.578",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "16",
        "21.667",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "16",
        "21.758",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "16",
        "21.848",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "16",
        "21.940",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "16",
        "22.031",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "16",
        "22.123",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "16",
        "22.216",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "16",
        "22.308",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "16",
        "22.401",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "16",
        "22.494",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "16",
        "22.587",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "16",
        "22.681",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "16",
        "22.775",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "16",
        "22.869",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "16",
        "22.962",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "16",
        "23.056",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "16",
        "23.150",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "16",
        "23.245",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "16",
        "23.338",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "16",
        "23.433",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "16",
        "23.527",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "16",
        "23.621",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "16",
        "23.714",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "16",
        "23.808",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "16",
        "23.901",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "16",
        "23.995",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "16",
        "24.087",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "16",
        "24.180",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "16",
        "24.273",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "16",
        "24.365",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "16",
        "24.456",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "16",
        "24.547",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "16",
        "24.639",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "16",
        "24.729",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "16",
        "24.820",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "16",
        "24.909",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "16",
        "24.998",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "16",
        "25.087",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "16",
        "25.175",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "16",
        "25.262",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "16",
        "25.349",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "16",
        "25.435",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "16",
        "25.520",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "16",
        "25.605",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "16",
        "25.689",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "16",
        "25.772",
        "17.224",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "16",
        "25.854",
        "17.060",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "16",
        "27.826",
        "18.284",
        "6.155",
        "8.687",
        "0.183"
    ],
    [
        "41",
        "16",
        "33.301",
        "24.089",
        "8.482",
        "8.748",
        "0.001"
    ],
    [
        "42",
        "16",
        "36.818",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "16",
        "36.947",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "16",
        "37.077",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "16",
        "37.210",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "16",
        "37.345",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "16",
        "37.482",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "16",
        "37.620",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "16",
        "37.761",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "16",
        "37.903",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "16",
        "38.047",
        "30.137",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "16",
        "38.193",
        "30.495",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "16",
        "38.341",
        "30.843",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "16",
        "38.489",
        "31.180",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "16",
        "38.641",
        "31.510",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "16",
        "38.793",
        "31.829",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "16",
        "38.947",
        "32.138",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "16",
        "39.102",
        "32.439",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "16",
        "39.259",
        "32.728",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "16",
        "39.905",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "16",
        "40.063",
        "32.867",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "16",
        "40.221",
        "33.033",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "16",
        "40.381",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "16",
        "40.541",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "16",
        "40.702",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "16",
        "40.863",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "16",
        "41.025",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "16",
        "41.187",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "16",
        "41.351",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "16",
        "41.513",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "16",
        "41.677",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "16",
        "41.841",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "16",
        "42.006",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "16",
        "42.170",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "16",
        "42.335",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "16",
        "42.500",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "16",
        "42.664",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "16",
        "42.829",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "16",
        "42.994",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "16",
        "43.159",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "16",
        "43.323",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "16",
        "43.488",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "16",
        "43.652",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "16",
        "43.816",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "16",
        "43.980",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "16",
        "44.143",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "16",
        "44.306",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "16",
        "44.469",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "16",
        "44.631",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "16",
        "44.791",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "16",
        "44.953",
        "33.334",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "16",
        "45.113",
        "33.189",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "16",
        "45.272",
        "33.035",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "16",
        "42.029",
        "29.085",
        "1.115",
        "1.047",
        "0.105"
    ],
    [
        "95",
        "16",
        "40.008",
        "22.091",
        "1.281",
        "1.282",
        "0.064"
    ],
    [
        "96",
        "16",
        "39.353",
        "11.375",
        "1.285",
        "1.261",
        "0.027"
    ],
    [
        "97",
        "16",
        "37.959",
        "-2.323",
        "0.286",
        "0.489",
        "-0.020"
    ],
    [
        "98",
        "16",
        "37.854",
        "-3.638",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "16",
        "37.835",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "16",
        "37.814",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "16",
        "37.790",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "16",
        "37.763",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "16",
        "37.734",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "16",
        "37.703",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "16",
        "37.669",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "16",
        "37.632",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "16",
        "37.593",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "16",
        "37.552",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "16",
        "37.507",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "16",
        "37.461",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "16",
        "37.412",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "16",
        "37.361",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "16",
        "37.307",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "16",
        "37.250",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "16",
        "37.192",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "16",
        "37.131",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "16",
        "37.067",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "16",
        "37.002",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "16",
        "31.524",
        "-14.029",
        "2.572",
        "4.487",
        "0.014"
    ],
    [
        "120",
        "16",
        "-20.354",
        "-2.489",
        "3.406",
        "4.536",
        "-0.012"
    ],
    [
        "121",
        "16",
        "-31.939",
        "23.595",
        "2.351",
        "1.759",
        "0.106"
    ],
    [
        "122",
        "16",
        "-44.351",
        "24.950",
        "5.930",
        "7.226",
        "-0.135"
    ],
    [
        "123",
        "16",
        "-55.183",
        "32.825",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "16",
        "-55.028",
        "31.933",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "16",
        "-54.877",
        "31.031",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "16",
        "-54.729",
        "30.119",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "16",
        "-54.587",
        "29.199",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "16",
        "-54.448",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "16",
        "-54.314",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "16",
        "-54.185",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "16",
        "-54.061",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "16",
        "-53.941",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "16",
        "-53.826",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "16",
        "-53.715",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "16",
        "-53.609",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "16",
        "-53.507",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "16",
        "-53.412",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "16",
        "-53.320",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "16",
        "-53.232",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "16",
        "-53.151",
        "16.528",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "16",
        "-53.073",
        "15.509",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "16",
        "-53.002",
        "14.485",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "16",
        "-52.849",
        "13.466",
        "0.574",
        "0.673",
        "-0.038"
    ],
    [
        "144",
        "16",
        "-51.335",
        "12.684",
        "3.126",
        "2.669",
        "-0.096"
    ],
    [
        "145",
        "16",
        "-48.259",
        "13.677",
        "3.999",
        "3.581",
        "-0.139"
    ],
    [
        "146",
        "16",
        "-45.855",
        "16.856",
        "4.603",
        "3.977",
        "-0.111"
    ],
    [
        "147",
        "16",
        "-52.610",
        "19.109",
        "6.166",
        "4.105",
        "-0.308"
    ],
    [
        "148",
        "16",
        "-67.525",
        "19.871",
        "1.485",
        "1.460",
        "-0.242"
    ],
    [
        "149",
        "16",
        "-69.189",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "16",
        "-69.090",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "16",
        "-68.989",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "16",
        "-68.886",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "16",
        "-68.781",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "16",
        "-68.674",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "16",
        "-68.566",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "16",
        "-68.456",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "16",
        "-68.344",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "16",
        "-68.231",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "16",
        "-68.115",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "16",
        "-67.999",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "16",
        "-67.880",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "16",
        "-67.760",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "16",
        "-67.639",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "16",
        "-67.516",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "16",
        "-67.392",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "16",
        "-67.266",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "16",
        "-67.139",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "16",
        "-67.011",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "16",
        "-66.881",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "16",
        "-66.750",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "16",
        "-66.618",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "16",
        "-66.485",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "16",
        "-66.350",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "16",
        "-66.215",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "16",
        "-66.078",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "16",
        "-65.941",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "16",
        "-65.802",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "16",
        "-65.663",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "16",
        "-65.523",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "17",
        "-65.214",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "17",
        "-65.064",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "17",
        "-64.912",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "17",
        "-64.760",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "17",
        "-64.608",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "17",
        "-64.454",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "17",
        "-64.300",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "17",
        "-64.146",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "17",
        "-63.991",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "17",
        "-63.836",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "17",
        "-63.680",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "17",
        "-63.524",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "17",
        "-63.367",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "17",
        "-63.210",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "17",
        "-63.053",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "17",
        "-62.896",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "17",
        "-62.739",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "17",
        "-62.582",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "17",
        "-62.424",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "17",
        "-62.267",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "17",
        "-62.110",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "17",
        "-61.952",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "17",
        "-61.795",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "17",
        "-61.639",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "17",
        "-61.482",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "17",
        "-61.326",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "17",
        "-61.170",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "17",
        "-61.014",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "17",
        "-60.859",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "17",
        "-60.705",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "17",
        "-60.551",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "17",
        "-60.397",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "17",
        "-60.245",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "17",
        "-60.093",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "17",
        "-59.941",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "17",
        "-59.791",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "17",
        "-59.641",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "17",
        "-59.492",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "17",
        "-59.344",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "17",
        "-59.197",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "17",
        "-59.051",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "17",
        "-58.906",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "17",
        "-58.762",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "17",
        "-58.619",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "17",
        "-58.478",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "17",
        "-58.337",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "17",
        "-58.198",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "17",
        "-58.061",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "17",
        "-57.924",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "17",
        "-57.789",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "17",
        "-57.656",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "17",
        "-57.523",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "17",
        "-57.393",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "17",
        "-57.264",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "17",
        "-57.136",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "17",
        "-57.010",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "17",
        "-56.886",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "17",
        "-56.764",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "17",
        "-56.643",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "17",
        "-56.524",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "17",
        "-56.407",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "17",
        "-56.292",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "17",
        "-56.178",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "17",
        "-56.067",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "17",
        "-55.957",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "17",
        "-55.850",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "17",
        "-55.744",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "17",
        "-55.641",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "17",
        "-55.539",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "17",
        "-55.440",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "17",
        "-55.343",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "17",
        "-55.248",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "17",
        "-55.156",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "17",
        "-55.065",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "17",
        "-47.365",
        "9.600",
        "1.108",
        "2.739",
        "0.153"
    ],
    [
        "-105",
        "17",
        "25.703",
        "30.630",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "17",
        "25.866",
        "33.213",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "17",
        "26.042",
        "35.785",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "17",
        "26.231",
        "38.347",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "17",
        "17.878",
        "23.328",
        "4.850",
        "9.706",
        "0.414"
    ],
    [
        "-100",
        "17",
        "14.320",
        "15.056",
        "4.860",
        "10.633",
        "0.322"
    ],
    [
        "-99",
        "17",
        "10.274",
        "11.429",
        "5.119",
        "10.242",
        "0.275"
    ],
    [
        "-98",
        "17",
        "6.184",
        "8.351",
        "4.670",
        "9.656",
        "0.208"
    ],
    [
        "-97",
        "17",
        "-0.980",
        "1.967",
        "3.285",
        "5.029",
        "0.057"
    ],
    [
        "-96",
        "17",
        "-2.257",
        "3.451",
        "4.595",
        "7.136",
        "0.180"
    ],
    [
        "-95",
        "17",
        "-4.073",
        "1.087",
        "3.787",
        "6.335",
        "-0.078"
    ],
    [
        "-94",
        "17",
        "-4.983",
        "-0.458",
        "2.997",
        "4.996",
        "0.108"
    ],
    [
        "-93",
        "17",
        "-5.630",
        "-2.441",
        "2.502",
        "3.640",
        "0.347"
    ],
    [
        "-92",
        "17",
        "-6.788",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "17",
        "-6.807",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "17",
        "-6.826",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "17",
        "-6.842",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "17",
        "-6.856",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "17",
        "-6.868",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "17",
        "-0.644",
        "-0.456",
        "1.885",
        "1.486",
        "0.237"
    ],
    [
        "-85",
        "17",
        "5.993",
        "1.777",
        "1.673",
        "1.322",
        "0.268"
    ],
    [
        "-84",
        "17",
        "10.817",
        "3.307",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "17",
        "10.835",
        "3.758",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "17",
        "10.855",
        "4.207",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "17",
        "10.878",
        "4.654",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "17",
        "10.903",
        "5.100",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "17",
        "10.929",
        "5.545",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "17",
        "10.959",
        "5.988",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "17",
        "10.991",
        "6.429",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "17",
        "11.025",
        "6.869",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "17",
        "11.061",
        "7.305",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "17",
        "11.100",
        "7.740",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "17",
        "11.141",
        "8.173",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "17",
        "11.183",
        "8.603",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "17",
        "11.228",
        "9.031",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "17",
        "11.275",
        "9.455",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "17",
        "11.324",
        "9.877",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "17",
        "11.376",
        "10.296",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "17",
        "11.430",
        "10.712",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "17",
        "11.486",
        "11.123",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "17",
        "11.543",
        "11.533",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "17",
        "11.603",
        "11.939",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "17",
        "11.665",
        "12.340",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "17",
        "8.495",
        "11.826",
        "2.044",
        "1.775",
        "0.085"
    ],
    [
        "-61",
        "17",
        "3.083",
        "11.247",
        "2.361",
        "2.111",
        "0.078"
    ],
    [
        "-60",
        "17",
        "-2.232",
        "9.800",
        "2.321",
        "2.077",
        "0.077"
    ],
    [
        "-59",
        "17",
        "-4.990",
        "9.509",
        "1.732",
        "1.912",
        "-0.051"
    ],
    [
        "-58",
        "17",
        "-5.824",
        "9.711",
        "1.369",
        "1.481",
        "-0.036"
    ],
    [
        "-57",
        "17",
        "-5.957",
        "9.901",
        "1.086",
        "1.052",
        "-0.027"
    ],
    [
        "-56",
        "17",
        "-5.947",
        "10.163",
        "0.823",
        "0.909",
        "0.006"
    ],
    [
        "-55",
        "17",
        "-5.913",
        "10.473",
        "0.756",
        "0.839",
        "0.029"
    ],
    [
        "-54",
        "17",
        "-5.872",
        "10.775",
        "0.811",
        "0.801",
        "0.017"
    ],
    [
        "-53",
        "17",
        "-5.836",
        "11.057",
        "0.782",
        "0.861",
        "0.008"
    ],
    [
        "-52",
        "17",
        "-5.808",
        "11.325",
        "0.810",
        "0.884",
        "0.001"
    ],
    [
        "-51",
        "17",
        "-5.784",
        "11.600",
        "0.892",
        "0.852",
        "-0.013"
    ],
    [
        "-50",
        "17",
        "-5.768",
        "11.901",
        "0.860",
        "0.861",
        "-0.028"
    ],
    [
        "-49",
        "17",
        "-5.851",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "17",
        "-5.788",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "17",
        "-5.722",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "17",
        "18.375",
        "11.010",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "17",
        "18.432",
        "11.291",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "17",
        "18.490",
        "11.567",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "17",
        "18.550",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "17",
        "18.611",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "17",
        "18.674",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "17",
        "18.738",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "17",
        "18.802",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "17",
        "18.869",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "17",
        "18.936",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "17",
        "19.006",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "17",
        "19.075",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "17",
        "19.148",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "17",
        "19.220",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "17",
        "19.294",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "17",
        "19.369",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "17",
        "19.445",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "17",
        "19.522",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "17",
        "19.600",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "17",
        "19.680",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "17",
        "19.761",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "17",
        "19.842",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "17",
        "19.924",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "17",
        "20.008",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "17",
        "20.092",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "17",
        "20.178",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "17",
        "20.264",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "17",
        "20.351",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "17",
        "20.439",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "17",
        "20.528",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "17",
        "20.618",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "17",
        "20.708",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "17",
        "20.798",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "17",
        "20.891",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "17",
        "20.982",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "17",
        "21.076",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "17",
        "21.169",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "17",
        "21.264",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "17",
        "21.359",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "17",
        "21.454",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "17",
        "21.549",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "17",
        "21.646",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "17",
        "21.743",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "17",
        "21.840",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "17",
        "21.937",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "17",
        "22.035",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "17",
        "22.133",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "17",
        "22.231",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "17",
        "22.330",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "17",
        "22.429",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "17",
        "22.528",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "17",
        "22.628",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "17",
        "22.727",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "17",
        "22.827",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "17",
        "22.927",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "17",
        "23.026",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "17",
        "23.127",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "17",
        "23.226",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "17",
        "23.326",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "17",
        "23.425",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "17",
        "23.525",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "17",
        "23.624",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "17",
        "23.724",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "17",
        "23.822",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "17",
        "23.922",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "17",
        "24.021",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "17",
        "24.118",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "17",
        "24.217",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "17",
        "24.314",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "17",
        "24.411",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "17",
        "24.509",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "17",
        "24.606",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "17",
        "24.702",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "17",
        "24.797",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "17",
        "24.892",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "17",
        "24.987",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "17",
        "25.081",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "17",
        "25.173",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "17",
        "25.266",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "17",
        "25.358",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "17",
        "25.450",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "17",
        "25.540",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "17",
        "25.630",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "17",
        "25.719",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "17",
        "25.808",
        "17.224",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "17",
        "25.895",
        "17.060",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "17",
        "28.429",
        "18.623",
        "6.852",
        "10.071",
        "0.225"
    ],
    [
        "41",
        "17",
        "36.037",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "17",
        "36.171",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "17",
        "36.307",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "17",
        "36.447",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "17",
        "36.587",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "17",
        "36.730",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "17",
        "36.875",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "17",
        "37.022",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "17",
        "37.171",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "17",
        "37.322",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "17",
        "37.475",
        "30.137",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "17",
        "37.630",
        "30.495",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "17",
        "37.786",
        "30.843",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "17",
        "37.944",
        "31.180",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "17",
        "38.104",
        "31.510",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "17",
        "38.266",
        "31.829",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "17",
        "38.429",
        "32.138",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "17",
        "38.594",
        "32.439",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "17",
        "38.760",
        "32.728",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "17",
        "39.513",
        "32.693",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "17",
        "39.679",
        "32.867",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "17",
        "39.848",
        "33.033",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "17",
        "40.017",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "17",
        "40.186",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "17",
        "40.357",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "17",
        "40.528",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "17",
        "40.700",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "17",
        "40.872",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "17",
        "41.045",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "17",
        "41.218",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "17",
        "41.392",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "17",
        "41.565",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "17",
        "41.740",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "17",
        "41.915",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "17",
        "42.089",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "17",
        "42.264",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "17",
        "42.439",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "17",
        "42.614",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "17",
        "42.788",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "17",
        "42.963",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "17",
        "43.139",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "17",
        "43.312",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "17",
        "43.487",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "17",
        "43.660",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "17",
        "43.835",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "17",
        "44.007",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "17",
        "44.181",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "17",
        "44.353",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "17",
        "44.524",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "17",
        "44.695",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "17",
        "44.866",
        "33.334",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "17",
        "45.035",
        "33.189",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "17",
        "45.205",
        "33.035",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "17",
        "43.651",
        "31.102",
        "0.882",
        "0.806",
        "-0.038"
    ],
    [
        "95",
        "17",
        "40.500",
        "23.858",
        "1.237",
        "1.221",
        "0.064"
    ],
    [
        "96",
        "17",
        "39.375",
        "13.585",
        "1.361",
        "1.335",
        "0.013"
    ],
    [
        "97",
        "17",
        "38.550",
        "-0.106",
        "1.103",
        "1.183",
        "-0.017"
    ],
    [
        "98",
        "17",
        "38.216",
        "-3.638",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "17",
        "38.196",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "17",
        "38.173",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "17",
        "38.147",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "17",
        "38.120",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "17",
        "38.089",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "17",
        "38.055",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "17",
        "38.019",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "17",
        "37.980",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "17",
        "37.939",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "17",
        "37.895",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "17",
        "37.847",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "17",
        "37.799",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "17",
        "37.747",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "17",
        "37.692",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "17",
        "37.635",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "17",
        "37.575",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "17",
        "37.513",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "17",
        "37.449",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "17",
        "37.381",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "17",
        "37.311",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "17",
        "32.298",
        "-14.264",
        "2.635",
        "5.183",
        "-0.034"
    ],
    [
        "120",
        "17",
        "-10.329",
        "0.382",
        "10.240",
        "9.424",
        "0.048"
    ],
    [
        "121",
        "17",
        "-33.518",
        "26.807",
        "3.214",
        "1.924",
        "0.096"
    ],
    [
        "122",
        "17",
        "-43.324",
        "30.926",
        "6.731",
        "7.248",
        "-0.106"
    ],
    [
        "123",
        "17",
        "-53.976",
        "32.825",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "17",
        "-53.811",
        "31.933",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "17",
        "-53.650",
        "31.031",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "17",
        "-53.494",
        "30.119",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "17",
        "-53.343",
        "29.199",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "17",
        "-53.196",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "17",
        "-53.054",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "17",
        "-52.917",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "17",
        "-52.785",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "17",
        "-52.657",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "17",
        "-52.535",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "17",
        "-52.418",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "17",
        "-52.306",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "17",
        "-52.198",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "17",
        "-52.096",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "17",
        "-51.999",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "17",
        "-51.907",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "17",
        "-51.820",
        "16.528",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "17",
        "-51.738",
        "15.509",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "17",
        "-51.661",
        "14.485",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "17",
        "-51.586",
        "13.468",
        "0.633",
        "0.634",
        "0.049"
    ],
    [
        "144",
        "17",
        "-51.429",
        "13.007",
        "3.362",
        "2.380",
        "-0.034"
    ],
    [
        "145",
        "17",
        "-51.398",
        "14.747",
        "4.490",
        "3.303",
        "-0.106"
    ],
    [
        "146",
        "17",
        "-51.227",
        "19.376",
        "4.856",
        "3.548",
        "-0.165"
    ],
    [
        "147",
        "17",
        "-56.019",
        "19.398",
        "6.621",
        "4.005",
        "-0.270"
    ],
    [
        "148",
        "17",
        "-67.770",
        "20.069",
        "1.610",
        "1.460",
        "-0.200"
    ],
    [
        "149",
        "17",
        "-69.252",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "17",
        "-69.147",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "17",
        "-69.040",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "17",
        "-68.931",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "17",
        "-68.819",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "17",
        "-68.706",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "17",
        "-68.591",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "17",
        "-68.475",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "17",
        "-68.356",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "17",
        "-68.236",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "17",
        "-68.113",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "17",
        "-67.990",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "17",
        "-67.864",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "17",
        "-67.737",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "17",
        "-67.608",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "17",
        "-67.478",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "17",
        "-67.346",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "17",
        "-67.213",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "17",
        "-67.078",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "17",
        "-66.942",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "17",
        "-66.804",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "17",
        "-66.665",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "17",
        "-66.525",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "17",
        "-66.384",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "17",
        "-66.241",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "17",
        "-66.098",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "17",
        "-65.953",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "17",
        "-65.807",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "17",
        "-65.660",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "17",
        "-65.512",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "17",
        "-65.364",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "18",
        "-65.026",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "18",
        "-64.867",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "18",
        "-64.707",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "18",
        "-64.547",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "18",
        "-64.385",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "18",
        "-64.223",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "18",
        "-64.061",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "18",
        "-63.897",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "18",
        "-63.734",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "18",
        "-63.569",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "18",
        "-63.405",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "18",
        "-63.240",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "18",
        "-63.074",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "18",
        "-62.909",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "18",
        "-62.743",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "18",
        "-62.577",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "18",
        "-62.410",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "18",
        "-62.244",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "18",
        "-62.078",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "18",
        "-61.911",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "18",
        "-61.745",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "18",
        "-61.579",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "18",
        "-61.413",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "18",
        "-61.247",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "18",
        "-61.082",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "18",
        "-60.917",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "18",
        "-60.752",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "18",
        "-60.588",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "18",
        "-60.424",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "18",
        "-60.260",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "18",
        "-60.098",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "18",
        "-59.935",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "18",
        "-59.774",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "18",
        "-59.613",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "18",
        "-59.453",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "18",
        "-59.294",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "18",
        "-59.136",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "18",
        "-58.978",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "18",
        "-58.822",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "18",
        "-58.667",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "18",
        "-58.512",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "18",
        "-58.359",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "18",
        "-58.207",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "18",
        "-58.056",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "18",
        "-57.906",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "18",
        "-57.758",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "18",
        "-57.611",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "18",
        "-57.466",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "18",
        "-57.321",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "18",
        "-57.179",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "18",
        "-57.037",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "18",
        "-56.898",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "18",
        "-56.760",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "18",
        "-56.623",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "18",
        "-56.489",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "18",
        "-56.356",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "18",
        "-56.224",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "18",
        "-56.095",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "18",
        "-55.967",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "18",
        "-55.842",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "18",
        "-55.718",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "18",
        "-55.596",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "18",
        "-55.476",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "18",
        "-55.358",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "18",
        "-55.243",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "18",
        "-55.129",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "18",
        "-55.017",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "18",
        "-54.908",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "18",
        "-54.801",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "18",
        "-54.696",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "18",
        "-54.593",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "18",
        "-54.493",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "18",
        "-54.395",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "18",
        "-54.299",
        "17.510",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "18",
        "-23.063",
        "7.264",
        "5.519",
        "8.062",
        "0.028"
    ],
    [
        "-105",
        "18",
        "-1.849",
        "18.404",
        "6.889",
        "11.083",
        "0.171"
    ],
    [
        "-104",
        "18",
        "9.789",
        "20.685",
        "6.386",
        "12.544",
        "0.196"
    ],
    [
        "-103",
        "18",
        "8.671",
        "10.263",
        "6.279",
        "12.446",
        "0.339"
    ],
    [
        "-102",
        "18",
        "5.047",
        "1.510",
        "5.798",
        "11.109",
        "0.394"
    ],
    [
        "-101",
        "18",
        "1.183",
        "-2.282",
        "4.638",
        "9.691",
        "0.397"
    ],
    [
        "-100",
        "18",
        "-1.108",
        "-2.331",
        "4.069",
        "8.030",
        "0.480"
    ],
    [
        "-99",
        "18",
        "-2.597",
        "-2.226",
        "3.717",
        "6.886",
        "0.440"
    ],
    [
        "-98",
        "18",
        "-3.728",
        "-1.634",
        "3.090",
        "6.405",
        "0.407"
    ],
    [
        "-97",
        "18",
        "-4.914",
        "-2.290",
        "2.808",
        "5.853",
        "0.304"
    ],
    [
        "-96",
        "18",
        "-6.121",
        "-3.481",
        "2.780",
        "4.641",
        "0.205"
    ],
    [
        "-95",
        "18",
        "-6.471",
        "-4.426",
        "2.041",
        "3.507",
        "0.171"
    ],
    [
        "-94",
        "18",
        "-7.111",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "18",
        "-7.136",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "18",
        "-7.159",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "18",
        "-7.181",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "18",
        "-7.200",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "18",
        "-7.217",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "18",
        "-7.232",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "18",
        "-7.245",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "18",
        "-7.255",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "18",
        "-7.100",
        "-1.550",
        "0.359",
        "0.620",
        "0.009"
    ],
    [
        "-84",
        "18",
        "-4.655",
        "-0.522",
        "1.708",
        "1.247",
        "0.146"
    ],
    [
        "-83",
        "18",
        "-1.123",
        "0.842",
        "1.844",
        "1.372",
        "0.228"
    ],
    [
        "-82",
        "18",
        "3.188",
        "2.291",
        "1.788",
        "1.302",
        "0.297"
    ],
    [
        "-81",
        "18",
        "6.737",
        "3.693",
        "1.762",
        "1.268",
        "0.136"
    ],
    [
        "-80",
        "18",
        "8.248",
        "4.644",
        "1.724",
        "1.355",
        "0.245"
    ],
    [
        "-79",
        "18",
        "8.897",
        "5.250",
        "1.873",
        "1.392",
        "0.315"
    ],
    [
        "-78",
        "18",
        "9.232",
        "5.767",
        "2.189",
        "1.401",
        "0.368"
    ],
    [
        "-77",
        "18",
        "9.420",
        "6.076",
        "2.020",
        "1.493",
        "0.379"
    ],
    [
        "-76",
        "18",
        "9.609",
        "6.466",
        "1.853",
        "1.412",
        "0.353"
    ],
    [
        "-75",
        "18",
        "10.545",
        "7.305",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "18",
        "10.585",
        "7.740",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "18",
        "10.628",
        "8.173",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "18",
        "10.673",
        "8.603",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "18",
        "7.805",
        "8.091",
        "1.532",
        "1.575",
        "0.303"
    ],
    [
        "-70",
        "18",
        "8.099",
        "8.468",
        "1.799",
        "1.522",
        "0.233"
    ],
    [
        "-69",
        "18",
        "7.409",
        "8.513",
        "2.122",
        "1.560",
        "0.120"
    ],
    [
        "-68",
        "18",
        "8.981",
        "9.557",
        "1.907",
        "1.570",
        "0.012"
    ],
    [
        "-67",
        "18",
        "10.580",
        "10.829",
        "1.104",
        "0.819",
        "0.016"
    ],
    [
        "-66",
        "18",
        "10.756",
        "10.984",
        "1.955",
        "1.501",
        "0.111"
    ],
    [
        "-65",
        "18",
        "11.024",
        "11.478",
        "0.456",
        "0.720",
        "0.080"
    ],
    [
        "-64",
        "18",
        "11.117",
        "11.939",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "18",
        "8.178",
        "11.527",
        "2.144",
        "1.620",
        "0.263"
    ],
    [
        "-62",
        "18",
        "2.881",
        "10.541",
        "2.348",
        "1.908",
        "0.202"
    ],
    [
        "-61",
        "18",
        "-2.167",
        "9.606",
        "2.156",
        "1.781",
        "0.229"
    ],
    [
        "-60",
        "18",
        "-6.784",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "18",
        "-6.738",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "18",
        "-6.690",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "18",
        "-6.640",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "18",
        "-6.588",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "18",
        "-6.534",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "18",
        "-6.478",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "18",
        "-6.420",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "18",
        "-6.360",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "18",
        "-6.298",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "18",
        "-6.235",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "18",
        "-6.170",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "18",
        "-6.102",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "18",
        "-6.034",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "18",
        "17.979",
        "11.010",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "18",
        "18.039",
        "11.291",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "18",
        "18.101",
        "11.567",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "18",
        "18.164",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "18",
        "18.228",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "18",
        "18.295",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "18",
        "18.362",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "18",
        "18.431",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "18",
        "18.501",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "18",
        "18.573",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "18",
        "18.646",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "18",
        "18.720",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "18",
        "18.796",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "18",
        "18.872",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "18",
        "18.951",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "18",
        "19.030",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "18",
        "19.111",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "18",
        "19.192",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "18",
        "19.275",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "18",
        "19.359",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "18",
        "19.444",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "18",
        "19.530",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "18",
        "19.617",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "18",
        "19.706",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "18",
        "19.795",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "18",
        "19.884",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "18",
        "19.975",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "18",
        "20.068",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "18",
        "20.160",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "18",
        "20.254",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "18",
        "20.349",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "18",
        "20.444",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "18",
        "20.541",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "18",
        "20.638",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "18",
        "20.735",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "18",
        "20.833",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "18",
        "20.933",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "18",
        "21.033",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "18",
        "21.132",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "18",
        "21.233",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "18",
        "21.335",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "18",
        "21.436",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "18",
        "21.539",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "18",
        "21.641",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "18",
        "21.744",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "18",
        "21.848",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "18",
        "21.951",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "18",
        "22.056",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "18",
        "22.160",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "18",
        "22.264",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "18",
        "22.369",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "18",
        "22.474",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "18",
        "22.579",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "18",
        "22.685",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "18",
        "22.790",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "18",
        "22.895",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "18",
        "23.001",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "18",
        "23.106",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "18",
        "23.212",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "18",
        "23.317",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "18",
        "23.422",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "18",
        "23.527",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "18",
        "23.633",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "18",
        "23.738",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "18",
        "23.841",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "18",
        "23.946",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "18",
        "24.050",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "18",
        "24.154",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "18",
        "24.257",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "18",
        "24.360",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "18",
        "24.462",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "18",
        "24.564",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "18",
        "24.666",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "18",
        "24.766",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "18",
        "24.867",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "18",
        "24.967",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "18",
        "25.066",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "18",
        "25.165",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "18",
        "25.263",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "18",
        "25.360",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "18",
        "25.457",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "18",
        "25.553",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "18",
        "25.647",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "18",
        "25.741",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "18",
        "25.835",
        "17.224",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "18",
        "25.927",
        "17.060",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "18",
        "32.843",
        "23.364",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "18",
        "35.371",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "18",
        "35.513",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "18",
        "35.657",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "18",
        "35.804",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "18",
        "35.953",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "18",
        "36.104",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "18",
        "36.257",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "18",
        "36.412",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "18",
        "36.570",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "18",
        "36.729",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "18",
        "36.891",
        "30.137",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "18",
        "37.054",
        "30.495",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "18",
        "37.220",
        "30.843",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "18",
        "37.387",
        "31.180",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "18",
        "37.556",
        "31.510",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "18",
        "37.727",
        "31.829",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "18",
        "37.899",
        "32.138",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "18",
        "38.074",
        "32.439",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "18",
        "38.249",
        "32.728",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "18",
        "38.426",
        "33.009",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "18",
        "39.285",
        "32.867",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "18",
        "39.462",
        "33.033",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "18",
        "39.641",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "18",
        "39.820",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "18",
        "40.000",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "18",
        "40.181",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "18",
        "40.363",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "18",
        "40.545",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "18",
        "40.727",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "18",
        "40.910",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "18",
        "41.094",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "18",
        "41.278",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "18",
        "41.462",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "18",
        "41.646",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "18",
        "41.831",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "18",
        "42.016",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "18",
        "42.201",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "18",
        "42.385",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "18",
        "42.570",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "18",
        "42.755",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "18",
        "42.939",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "18",
        "43.124",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "18",
        "43.308",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "18",
        "43.492",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "18",
        "43.676",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "18",
        "43.859",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "18",
        "44.041",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "18",
        "44.223",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "18",
        "44.405",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "18",
        "44.586",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "18",
        "44.766",
        "33.334",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "18",
        "44.945",
        "33.189",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "18",
        "45.124",
        "33.035",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "18",
        "42.425",
        "29.644",
        "1.023",
        "1.012",
        "0.058"
    ],
    [
        "95",
        "18",
        "39.940",
        "24.451",
        "1.142",
        "1.197",
        "-0.029"
    ],
    [
        "96",
        "18",
        "39.087",
        "13.960",
        "1.393",
        "1.349",
        "0.002"
    ],
    [
        "97",
        "18",
        "39.268",
        "0.992",
        "1.207",
        "1.305",
        "0.026"
    ],
    [
        "98",
        "18",
        "38.830",
        "-3.274",
        "0.975",
        "1.047",
        "0.063"
    ],
    [
        "99",
        "18",
        "38.544",
        "-4.171",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "18",
        "38.520",
        "-4.704",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "18",
        "38.493",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "18",
        "38.464",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "18",
        "38.431",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "18",
        "38.396",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "18",
        "38.357",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "18",
        "38.317",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "18",
        "38.273",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "18",
        "38.225",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "18",
        "38.176",
        "-9.419",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "18",
        "38.125",
        "-9.931",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "18",
        "38.069",
        "-10.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "18",
        "38.011",
        "-10.944",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "18",
        "37.952",
        "-11.446",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "18",
        "37.889",
        "-11.944",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "18",
        "37.823",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "18",
        "37.754",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "18",
        "37.683",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "18",
        "37.609",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "18",
        "32.352",
        "-13.544",
        "2.530",
        "5.079",
        "-0.050"
    ],
    [
        "120",
        "18",
        "-11.311",
        "-5.344",
        "10.594",
        "9.739",
        "-0.007"
    ],
    [
        "121",
        "18",
        "-36.007",
        "18.441",
        "8.115",
        "9.127",
        "-0.078"
    ],
    [
        "122",
        "18",
        "-52.296",
        "29.985",
        "3.687",
        "3.393",
        "-0.029"
    ],
    [
        "123",
        "18",
        "-52.752",
        "32.825",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "18",
        "-52.578",
        "31.933",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "18",
        "-52.407",
        "31.031",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "18",
        "-52.243",
        "30.119",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "18",
        "-52.083",
        "29.199",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "18",
        "-51.927",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "18",
        "-51.778",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "18",
        "-51.633",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "18",
        "-51.493",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "18",
        "-51.359",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "18",
        "-51.230",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "18",
        "-51.105",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "18",
        "-50.987",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "18",
        "-50.873",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "18",
        "-50.765",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "18",
        "-50.662",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "18",
        "-50.565",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "18",
        "-50.473",
        "16.528",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "18",
        "-50.387",
        "15.509",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "18",
        "-50.306",
        "14.485",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "18",
        "-50.300",
        "13.452",
        "0.687",
        "0.625",
        "0.088"
    ],
    [
        "144",
        "18",
        "-51.231",
        "12.920",
        "3.616",
        "2.297",
        "0.025"
    ],
    [
        "145",
        "18",
        "-53.759",
        "14.853",
        "4.815",
        "3.222",
        "-0.071"
    ],
    [
        "146",
        "18",
        "-58.988",
        "20.288",
        "5.200",
        "3.402",
        "-0.157"
    ],
    [
        "147",
        "18",
        "-60.174",
        "20.123",
        "6.752",
        "3.900",
        "-0.201"
    ],
    [
        "148",
        "18",
        "-68.386",
        "20.198",
        "1.633",
        "1.458",
        "-0.140"
    ],
    [
        "149",
        "18",
        "-69.294",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "18",
        "-69.183",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "18",
        "-69.070",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "18",
        "-68.954",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "18",
        "-68.837",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "18",
        "-68.717",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "18",
        "-68.596",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "18",
        "-68.472",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "18",
        "-68.347",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "18",
        "-68.220",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "18",
        "-68.091",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "18",
        "-67.960",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "18",
        "-67.827",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "18",
        "-67.693",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "18",
        "-67.557",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "18",
        "-67.419",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "18",
        "-67.279",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "18",
        "-67.139",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "18",
        "-66.996",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "18",
        "-66.852",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "18",
        "-66.707",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "18",
        "-66.560",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "18",
        "-66.412",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "18",
        "-66.263",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "18",
        "-66.112",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "18",
        "-65.960",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "18",
        "-65.807",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "18",
        "-65.653",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "18",
        "-65.498",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "18",
        "-65.341",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "18",
        "-65.184",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "19",
        "-64.819",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "19",
        "-64.651",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "19",
        "-64.483",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "19",
        "-64.313",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "19",
        "-64.143",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "19",
        "-63.973",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "19",
        "-63.801",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "19",
        "-63.629",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "19",
        "-63.457",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "19",
        "-63.284",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "19",
        "-63.110",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "19",
        "-62.936",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "19",
        "-62.762",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "19",
        "-62.588",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "19",
        "-62.413",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "19",
        "-62.238",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "19",
        "-62.063",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "19",
        "-61.887",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "19",
        "-61.712",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "19",
        "-61.537",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "19",
        "-61.362",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "19",
        "-61.187",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "19",
        "-61.012",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "19",
        "-60.837",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "19",
        "-60.663",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "19",
        "-60.489",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "19",
        "-60.315",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "19",
        "-60.142",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "19",
        "-59.970",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "19",
        "-59.797",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "19",
        "-59.626",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "19",
        "-59.455",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "19",
        "-59.285",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "19",
        "-59.116",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "19",
        "-58.947",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "19",
        "-58.779",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "19",
        "-58.613",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "19",
        "-58.447",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "19",
        "-58.282",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "19",
        "-58.118",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "19",
        "-57.956",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "19",
        "-57.794",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "19",
        "-57.634",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "19",
        "-57.475",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "19",
        "-57.318",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "19",
        "-57.161",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "19",
        "-57.006",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "19",
        "-56.853",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "19",
        "-56.701",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "19",
        "-56.551",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "19",
        "-56.402",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "19",
        "-56.255",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "19",
        "-56.109",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "19",
        "-55.966",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "19",
        "-55.824",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "19",
        "-55.684",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "19",
        "-55.545",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "19",
        "-55.409",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "19",
        "-55.274",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "19",
        "-55.142",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "19",
        "-55.012",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "19",
        "-54.883",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "19",
        "-54.757",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "19",
        "-54.633",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "19",
        "-54.511",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "19",
        "-54.391",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "19",
        "-54.274",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "19",
        "-54.158",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "19",
        "-54.046",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "19",
        "-53.935",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "19",
        "-53.827",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "19",
        "-53.721",
        "18.386",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "19",
        "-53.618",
        "17.951",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "19",
        "8.256",
        "-3.384",
        "1.126",
        "2.309",
        "0.015"
    ],
    [
        "-106",
        "19",
        "11.684",
        "4.323",
        "1.468",
        "3.767",
        "0.184"
    ],
    [
        "-105",
        "19",
        "4.519",
        "-3.072",
        "6.721",
        "12.046",
        "0.207"
    ],
    [
        "-104",
        "19",
        "2.518",
        "1.939",
        "5.122",
        "8.980",
        "-0.009"
    ],
    [
        "-103",
        "19",
        "-1.571",
        "-4.206",
        "4.737",
        "10.417",
        "0.353"
    ],
    [
        "-102",
        "19",
        "-5.260",
        "-7.713",
        "4.239",
        "7.741",
        "0.378"
    ],
    [
        "-101",
        "19",
        "-7.229",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "19",
        "-7.271",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "19",
        "-7.311",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "19",
        "-7.349",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "19",
        "-7.384",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "19",
        "-7.417",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "19",
        "-7.449",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "19",
        "-7.478",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "19",
        "-7.505",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "19",
        "-7.530",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "19",
        "-7.552",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "19",
        "-7.573",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "19",
        "-7.590",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "19",
        "-7.606",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "19",
        "-7.619",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "19",
        "-7.630",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "19",
        "-7.640",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "19",
        "-7.646",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "19",
        "-7.650",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "19",
        "-7.653",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "19",
        "-6.473",
        "0.400",
        "1.512",
        "1.260",
        "0.013"
    ],
    [
        "-80",
        "19",
        "-4.052",
        "1.543",
        "1.719",
        "1.338",
        "0.252"
    ],
    [
        "-79",
        "19",
        "-1.910",
        "2.570",
        "1.918",
        "1.409",
        "0.305"
    ],
    [
        "-78",
        "19",
        "0.289",
        "3.598",
        "2.396",
        "1.422",
        "0.393"
    ],
    [
        "-77",
        "19",
        "2.375",
        "4.250",
        "2.416",
        "1.801",
        "0.361"
    ],
    [
        "-76",
        "19",
        "4.082",
        "4.954",
        "2.342",
        "1.771",
        "0.374"
    ],
    [
        "-75",
        "19",
        "4.549",
        "5.650",
        "2.291",
        "1.651",
        "0.385"
    ],
    [
        "-74",
        "19",
        "3.321",
        "5.653",
        "2.038",
        "1.661",
        "0.238"
    ],
    [
        "-73",
        "19",
        "1.945",
        "5.824",
        "2.018",
        "1.761",
        "0.225"
    ],
    [
        "-72",
        "19",
        "2.310",
        "5.893",
        "1.979",
        "1.746",
        "0.314"
    ],
    [
        "-71",
        "19",
        "3.074",
        "4.645",
        "1.396",
        "1.287",
        "0.153"
    ],
    [
        "-70",
        "19",
        "-0.278",
        "5.854",
        "1.622",
        "1.241",
        "0.200"
    ],
    [
        "-69",
        "19",
        "-1.657",
        "7.216",
        "1.943",
        "1.435",
        "0.106"
    ],
    [
        "-68",
        "19",
        "-0.156",
        "7.546",
        "2.100",
        "1.769",
        "0.086"
    ],
    [
        "-67",
        "19",
        "1.689",
        "8.702",
        "2.156",
        "1.641",
        "0.152"
    ],
    [
        "-66",
        "19",
        "3.111",
        "9.214",
        "2.272",
        "1.786",
        "0.201"
    ],
    [
        "-65",
        "19",
        "3.083",
        "9.690",
        "2.250",
        "1.907",
        "0.260"
    ],
    [
        "-64",
        "19",
        "1.608",
        "10.018",
        "2.322",
        "1.845",
        "0.256"
    ],
    [
        "-63",
        "19",
        "-0.934",
        "9.850",
        "2.486",
        "1.844",
        "0.307"
    ],
    [
        "-62",
        "19",
        "-4.658",
        "8.491",
        "1.973",
        "1.509",
        "0.231"
    ],
    [
        "-61",
        "19",
        "-7.181",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "19",
        "-7.135",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "19",
        "-7.086",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "19",
        "-7.035",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "19",
        "-6.982",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "19",
        "-6.927",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "19",
        "-6.870",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "19",
        "-6.811",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "19",
        "-6.751",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "19",
        "-6.688",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "19",
        "-6.623",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "19",
        "-6.556",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "19",
        "-6.487",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "19",
        "-6.417",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "19",
        "-6.344",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "19",
        "7.665",
        "12.925",
        "2.478",
        "1.853",
        "-0.058"
    ],
    [
        "-45",
        "19",
        "17.642",
        "11.291",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "19",
        "17.706",
        "11.567",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "19",
        "17.773",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "19",
        "17.840",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "19",
        "17.910",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "19",
        "17.981",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "19",
        "18.054",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "19",
        "18.128",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "19",
        "18.203",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "19",
        "18.280",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "19",
        "18.358",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "19",
        "18.438",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "19",
        "18.519",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "19",
        "18.601",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "19",
        "18.684",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "19",
        "18.769",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "19",
        "18.856",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "19",
        "18.943",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "19",
        "19.032",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "19",
        "19.121",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "19",
        "19.212",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "19",
        "19.304",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "19",
        "19.397",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "19",
        "19.491",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "19",
        "19.586",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "19",
        "19.682",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "19",
        "19.779",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "19",
        "19.876",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "19",
        "19.975",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "19",
        "20.075",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "19",
        "20.175",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "19",
        "20.276",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "19",
        "20.378",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "19",
        "20.482",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "19",
        "20.586",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "19",
        "20.689",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "19",
        "20.794",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "19",
        "20.900",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "19",
        "21.006",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "19",
        "21.113",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "19",
        "21.220",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "19",
        "21.327",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "19",
        "21.436",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "19",
        "21.544",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "19",
        "21.654",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "19",
        "21.763",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "19",
        "21.872",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "19",
        "21.982",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "19",
        "22.093",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "19",
        "22.203",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "19",
        "22.314",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "19",
        "22.425",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "19",
        "22.536",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "19",
        "22.646",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "19",
        "22.757",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "19",
        "22.869",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "19",
        "22.980",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "19",
        "23.091",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "19",
        "23.202",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "19",
        "23.313",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "19",
        "23.423",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "19",
        "23.534",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "19",
        "23.645",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "19",
        "23.755",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "19",
        "23.864",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "19",
        "23.974",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "19",
        "24.083",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "19",
        "24.192",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "19",
        "24.301",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "19",
        "24.408",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "19",
        "24.515",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "19",
        "24.623",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "19",
        "24.729",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "19",
        "24.835",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "19",
        "24.940",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "19",
        "25.044",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "19",
        "25.149",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "19",
        "25.251",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "19",
        "25.354",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "19",
        "25.456",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "19",
        "25.557",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "19",
        "25.657",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "19",
        "25.756",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "19",
        "25.854",
        "17.224",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "19",
        "25.952",
        "17.060",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "19",
        "34.547",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "19",
        "34.695",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "19",
        "34.844",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "19",
        "34.996",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "19",
        "35.150",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "19",
        "35.307",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "19",
        "35.467",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "19",
        "35.627",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "19",
        "35.791",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "19",
        "35.957",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "19",
        "36.125",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "19",
        "36.296",
        "30.137",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "19",
        "36.468",
        "30.495",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "19",
        "36.642",
        "30.843",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "19",
        "36.818",
        "31.180",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "19",
        "36.997",
        "31.510",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "19",
        "37.177",
        "31.829",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "19",
        "37.358",
        "32.138",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "19",
        "37.542",
        "32.439",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "19",
        "37.727",
        "32.728",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "19",
        "37.914",
        "33.009",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "19",
        "38.805",
        "32.904",
        "6.502",
        "10.305",
        "0.000"
    ],
    [
        "62",
        "19",
        "39.064",
        "33.033",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "19",
        "39.252",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "19",
        "39.441",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "19",
        "39.631",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "19",
        "39.821",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "19",
        "40.013",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "19",
        "40.204",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "19",
        "40.397",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "19",
        "40.589",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "19",
        "40.783",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "19",
        "40.976",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "19",
        "41.171",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "19",
        "41.365",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "19",
        "41.560",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "19",
        "41.755",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "19",
        "41.949",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "19",
        "42.143",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "19",
        "42.338",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "19",
        "42.534",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "19",
        "42.728",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "19",
        "42.922",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "19",
        "43.116",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "19",
        "43.310",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "19",
        "43.503",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "19",
        "43.696",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "19",
        "43.888",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "19",
        "44.080",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "19",
        "44.272",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "19",
        "44.462",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "19",
        "44.652",
        "33.334",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "19",
        "44.841",
        "33.189",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "19",
        "43.775",
        "31.883",
        "0.950",
        "0.864",
        "0.073"
    ],
    [
        "94",
        "19",
        "40.290",
        "28.386",
        "1.131",
        "1.112",
        "0.024"
    ],
    [
        "95",
        "19",
        "37.991",
        "23.307",
        "1.269",
        "1.281",
        "0.015"
    ],
    [
        "96",
        "19",
        "38.205",
        "12.726",
        "1.420",
        "1.339",
        "0.008"
    ],
    [
        "97",
        "19",
        "39.201",
        "0.236",
        "1.296",
        "1.324",
        "0.042"
    ],
    [
        "98",
        "19",
        "38.985",
        "-3.258",
        "1.194",
        "1.222",
        "0.064"
    ],
    [
        "99",
        "19",
        "38.718",
        "-3.907",
        "1.134",
        "1.096",
        "0.034"
    ],
    [
        "100",
        "19",
        "38.806",
        "-4.592",
        "0.913",
        "1.042",
        "-0.014"
    ],
    [
        "101",
        "19",
        "38.827",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "19",
        "38.796",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "19",
        "38.762",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "19",
        "38.724",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "19",
        "38.684",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "19",
        "38.640",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "19",
        "38.595",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "19",
        "38.546",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "19",
        "38.399",
        "-9.599",
        "0.445",
        "0.523",
        "0.012"
    ],
    [
        "110",
        "19",
        "38.042",
        "-10.173",
        "0.576",
        "0.666",
        "0.028"
    ],
    [
        "111",
        "19",
        "38.169",
        "-10.770",
        "0.659",
        "0.652",
        "-0.006"
    ],
    [
        "112",
        "19",
        "38.158",
        "-11.168",
        "0.585",
        "0.688",
        "-0.003"
    ],
    [
        "113",
        "19",
        "38.110",
        "-11.588",
        "0.584",
        "0.697",
        "0.007"
    ],
    [
        "114",
        "19",
        "38.093",
        "-12.008",
        "0.634",
        "0.641",
        "-0.016"
    ],
    [
        "115",
        "19",
        "38.120",
        "-12.440",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "19",
        "38.049",
        "-12.931",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "19",
        "37.974",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "19",
        "37.896",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "19",
        "37.816",
        "-14.380",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "19",
        "7.890",
        "-8.570",
        "8.658",
        "7.266",
        "0.006"
    ],
    [
        "121",
        "19",
        "-25.865",
        "6.902",
        "7.697",
        "8.098",
        "-0.104"
    ],
    [
        "122",
        "19",
        "-48.509",
        "31.663",
        "4.576",
        "5.545",
        "-0.139"
    ],
    [
        "123",
        "19",
        "-51.512",
        "32.825",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "19",
        "-51.328",
        "31.933",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "19",
        "-51.149",
        "31.031",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "19",
        "-50.976",
        "30.119",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "19",
        "-50.807",
        "29.199",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "19",
        "-50.644",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "19",
        "-50.486",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "19",
        "-50.333",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "19",
        "-50.186",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "19",
        "-50.044",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "19",
        "-49.908",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "19",
        "-49.777",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "19",
        "-49.652",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "19",
        "-49.532",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "19",
        "-49.418",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "19",
        "-49.310",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "19",
        "-49.208",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "19",
        "-49.110",
        "16.528",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "19",
        "-49.020",
        "15.509",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "19",
        "-48.934",
        "14.485",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "19",
        "-49.019",
        "13.408",
        "0.723",
        "0.711",
        "0.152"
    ],
    [
        "144",
        "19",
        "-50.941",
        "12.911",
        "3.800",
        "2.559",
        "0.067"
    ],
    [
        "145",
        "19",
        "-55.210",
        "15.292",
        "5.036",
        "3.501",
        "0.014"
    ],
    [
        "146",
        "19",
        "-60.831",
        "18.637",
        "5.655",
        "3.965",
        "-0.064"
    ],
    [
        "147",
        "19",
        "-63.960",
        "19.868",
        "6.439",
        "3.970",
        "-0.107"
    ],
    [
        "148",
        "19",
        "-69.010",
        "20.231",
        "1.419",
        "1.445",
        "0.030"
    ],
    [
        "149",
        "19",
        "-69.315",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "19",
        "-69.198",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "19",
        "-69.079",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "19",
        "-68.957",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "19",
        "-68.833",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "19",
        "-68.708",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "19",
        "-68.580",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "19",
        "-68.449",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "19",
        "-68.317",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "19",
        "-68.183",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "19",
        "-68.047",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "19",
        "-67.909",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "19",
        "-67.770",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "19",
        "-67.628",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "19",
        "-67.485",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "19",
        "-67.339",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "19",
        "-67.193",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "19",
        "-67.044",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "19",
        "-66.894",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "19",
        "-66.742",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "19",
        "-66.589",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "19",
        "-66.435",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "19",
        "-66.279",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "19",
        "-66.121",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "19",
        "-65.962",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "19",
        "-65.803",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "19",
        "-65.641",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "19",
        "-65.479",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "19",
        "-65.315",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "19",
        "-65.151",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "19",
        "-64.985",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "20",
        "-64.591",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "20",
        "-64.415",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "20",
        "-64.238",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "20",
        "-64.061",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "20",
        "-63.882",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "20",
        "-63.703",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "20",
        "-63.523",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "20",
        "-63.342",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "20",
        "-63.161",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "20",
        "-62.979",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "20",
        "-62.797",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "20",
        "-62.614",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "20",
        "-62.431",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "20",
        "-62.247",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "20",
        "-62.064",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "20",
        "-61.880",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "20",
        "-61.696",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "20",
        "-61.512",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "20",
        "-61.328",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "20",
        "-61.144",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "20",
        "-60.960",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "20",
        "-60.776",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "20",
        "-60.592",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "20",
        "-60.409",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "20",
        "-60.226",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "20",
        "-60.043",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "20",
        "-59.860",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "20",
        "-59.679",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "20",
        "-59.497",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "20",
        "-59.316",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "20",
        "-59.136",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "20",
        "-58.957",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "20",
        "-58.778",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "20",
        "-58.600",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "20",
        "-58.423",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "20",
        "-58.247",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "20",
        "-58.072",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "20",
        "-57.898",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "20",
        "-57.724",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "20",
        "-57.552",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "20",
        "-57.382",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "20",
        "-57.212",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "20",
        "-57.044",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "20",
        "-56.877",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "20",
        "-56.711",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "20",
        "-56.547",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "20",
        "-56.384",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "20",
        "-56.223",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "20",
        "-56.064",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "20",
        "-55.906",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "20",
        "-55.749",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "20",
        "-55.595",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "20",
        "-55.442",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "20",
        "-55.291",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "20",
        "-55.142",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "20",
        "-54.995",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "20",
        "-54.849",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "20",
        "-54.706",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "20",
        "-54.565",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "20",
        "-54.426",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "20",
        "-54.289",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "20",
        "-54.154",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "20",
        "-54.021",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "20",
        "-53.891",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "20",
        "-53.763",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "20",
        "-53.637",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "20",
        "-53.513",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "20",
        "-53.392",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "20",
        "-53.274",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "20",
        "-53.158",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "20",
        "-53.044",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "20",
        "-19.151",
        "6.451",
        "2.364",
        "3.108",
        "-0.020"
    ],
    [
        "-108",
        "20",
        "4.252",
        "-9.802",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "20",
        "4.218",
        "-1.575",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "20",
        "3.168",
        "4.600",
        "1.439",
        "3.328",
        "0.061"
    ],
    [
        "-105",
        "20",
        "-4.058",
        "-6.144",
        "6.072",
        "9.550",
        "0.080"
    ],
    [
        "-104",
        "20",
        "-7.156",
        "-8.028",
        "1.243",
        "3.213",
        "0.242"
    ],
    [
        "-103",
        "20",
        "-7.486",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "20",
        "-7.535",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "20",
        "-7.581",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "20",
        "-7.626",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "20",
        "-7.668",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "20",
        "-7.707",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "20",
        "-7.745",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "20",
        "-7.780",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "20",
        "-7.813",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "20",
        "-7.843",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "20",
        "-7.872",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "20",
        "-7.898",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "20",
        "-7.921",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "20",
        "-7.942",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "20",
        "-7.961",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "20",
        "-7.977",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "20",
        "-7.991",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "20",
        "-8.003",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "20",
        "-8.013",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "20",
        "-8.020",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "20",
        "-8.025",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "20",
        "-8.027",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "20",
        "-8.026",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "20",
        "-8.025",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "20",
        "-8.020",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "20",
        "-8.012",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "20",
        "-6.112",
        "2.144",
        "2.124",
        "1.487",
        "0.379"
    ],
    [
        "-76",
        "20",
        "-4.370",
        "3.022",
        "2.083",
        "1.497",
        "0.366"
    ],
    [
        "-75",
        "20",
        "-4.112",
        "3.867",
        "1.770",
        "1.363",
        "0.251"
    ],
    [
        "-74",
        "20",
        "-4.849",
        "3.767",
        "1.743",
        "1.413",
        "0.102"
    ],
    [
        "-73",
        "20",
        "-5.063",
        "4.083",
        "1.692",
        "1.482",
        "0.100"
    ],
    [
        "-72",
        "20",
        "-5.709",
        "4.374",
        "1.752",
        "1.474",
        "0.245"
    ],
    [
        "-71",
        "20",
        "-6.701",
        "3.731",
        "1.507",
        "1.213",
        "0.193"
    ],
    [
        "-70",
        "20",
        "-7.326",
        "4.860",
        "1.172",
        "1.194",
        "0.165"
    ],
    [
        "-69",
        "20",
        "-7.841",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "20",
        "-7.810",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "20",
        "-7.777",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "20",
        "-7.742",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "20",
        "-6.426",
        "7.084",
        "1.878",
        "1.646",
        "0.308"
    ],
    [
        "-64",
        "20",
        "-6.319",
        "7.679",
        "2.037",
        "1.648",
        "0.292"
    ],
    [
        "-63",
        "20",
        "-7.115",
        "7.812",
        "2.098",
        "1.485",
        "0.258"
    ],
    [
        "-62",
        "20",
        "-7.578",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "20",
        "-7.531",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "20",
        "-7.483",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "20",
        "-7.431",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "20",
        "-7.378",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "20",
        "-7.322",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "20",
        "-7.265",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "20",
        "-7.204",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "20",
        "-7.143",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "20",
        "-7.079",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "20",
        "-7.012",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "20",
        "-6.944",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "20",
        "-6.875",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "20",
        "-6.802",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "20",
        "-6.728",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "20",
        "-6.652",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "20",
        "-0.417",
        "12.678",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "20",
        "17.238",
        "11.291",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "20",
        "17.306",
        "11.567",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "20",
        "17.376",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "20",
        "17.447",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "20",
        "17.520",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "20",
        "17.595",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "20",
        "17.671",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "20",
        "17.749",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "20",
        "17.828",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "20",
        "17.909",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "20",
        "17.991",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "20",
        "18.075",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "20",
        "18.159",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "20",
        "18.246",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "20",
        "18.334",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "20",
        "18.423",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "20",
        "18.513",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "20",
        "18.606",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "20",
        "18.698",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "20",
        "18.792",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "20",
        "18.888",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "20",
        "18.984",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "20",
        "19.082",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "20",
        "19.181",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "20",
        "19.280",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "20",
        "19.381",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "20",
        "19.483",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "20",
        "19.586",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "20",
        "19.690",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "20",
        "19.794",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "20",
        "19.900",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "20",
        "20.006",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "20",
        "20.113",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "20",
        "20.222",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "20",
        "20.330",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "20",
        "20.440",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "20",
        "20.551",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "20",
        "20.661",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "20",
        "20.773",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "20",
        "20.885",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "20",
        "20.997",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "20",
        "21.111",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "20",
        "21.224",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "20",
        "21.338",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "20",
        "21.453",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "20",
        "21.568",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "20",
        "21.683",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "20",
        "21.798",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "20",
        "21.914",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "20",
        "22.030",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "20",
        "22.146",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "20",
        "22.262",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "20",
        "22.380",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "20",
        "22.496",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "20",
        "22.613",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "20",
        "22.729",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "20",
        "22.847",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "20",
        "22.963",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "20",
        "23.079",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "20",
        "23.196",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "20",
        "23.313",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "20",
        "23.429",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "20",
        "23.545",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "20",
        "23.660",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "20",
        "23.775",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "20",
        "23.890",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "20",
        "24.006",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "20",
        "24.119",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "20",
        "24.233",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "20",
        "24.346",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "20",
        "24.460",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "20",
        "24.572",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "20",
        "24.683",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "20",
        "24.795",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "20",
        "24.905",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "20",
        "25.015",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "20",
        "25.125",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "20",
        "25.233",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "20",
        "25.340",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "20",
        "25.447",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "20",
        "25.554",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "20",
        "25.659",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "20",
        "25.762",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "20",
        "26.617",
        "17.494",
        "2.656",
        "4.232",
        "0.307"
    ],
    [
        "39",
        "20",
        "33.701",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "20",
        "33.853",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "20",
        "34.008",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "20",
        "34.165",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "20",
        "34.324",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "20",
        "34.486",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "20",
        "34.651",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "20",
        "34.818",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "20",
        "34.988",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "20",
        "35.160",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "20",
        "35.334",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "20",
        "35.511",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "20",
        "35.689",
        "30.137",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "20",
        "35.871",
        "30.495",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "20",
        "36.054",
        "30.843",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "20",
        "36.239",
        "31.180",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "20",
        "36.426",
        "31.510",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "20",
        "36.615",
        "31.829",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "20",
        "36.806",
        "32.138",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "20",
        "36.999",
        "32.439",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "20",
        "37.193",
        "32.728",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "20",
        "37.389",
        "33.009",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "20",
        "38.232",
        "32.974",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "20",
        "38.654",
        "33.033",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "20",
        "38.852",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "20",
        "39.050",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "20",
        "39.250",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "20",
        "39.450",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "20",
        "39.650",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "20",
        "39.852",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "20",
        "40.055",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "20",
        "40.257",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "20",
        "40.460",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "20",
        "40.664",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "20",
        "40.868",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "20",
        "41.071",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "20",
        "41.276",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "20",
        "41.480",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "20",
        "41.685",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "20",
        "41.890",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "20",
        "42.094",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "20",
        "42.299",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "20",
        "42.503",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "20",
        "42.707",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "20",
        "42.911",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "20",
        "43.114",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "20",
        "43.317",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "20",
        "43.520",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "20",
        "43.723",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "20",
        "43.924",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "20",
        "44.124",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "20",
        "44.325",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "20",
        "44.524",
        "33.334",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "20",
        "44.723",
        "33.189",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "20",
        "42.480",
        "31.082",
        "1.063",
        "0.891",
        "0.069"
    ],
    [
        "94",
        "20",
        "38.749",
        "26.846",
        "1.160",
        "1.145",
        "0.026"
    ],
    [
        "95",
        "20",
        "36.816",
        "21.489",
        "1.256",
        "1.279",
        "0.023"
    ],
    [
        "96",
        "20",
        "36.810",
        "10.356",
        "1.380",
        "1.285",
        "0.004"
    ],
    [
        "97",
        "20",
        "37.675",
        "-1.605",
        "1.253",
        "1.213",
        "0.048"
    ],
    [
        "98",
        "20",
        "37.543",
        "-4.075",
        "1.200",
        "1.244",
        "0.061"
    ],
    [
        "99",
        "20",
        "37.712",
        "-4.422",
        "1.209",
        "1.188",
        "0.016"
    ],
    [
        "100",
        "20",
        "38.485",
        "-4.842",
        "0.970",
        "1.083",
        "-0.008"
    ],
    [
        "101",
        "20",
        "39.150",
        "-5.236",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "20",
        "39.117",
        "-5.767",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "20",
        "39.081",
        "-6.295",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "20",
        "39.042",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "20",
        "38.999",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "20",
        "38.954",
        "-7.867",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "20",
        "38.905",
        "-8.387",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "20",
        "38.853",
        "-8.904",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "20",
        "38.378",
        "-10.227",
        "0.676",
        "0.746",
        "-0.025"
    ],
    [
        "110",
        "20",
        "38.081",
        "-11.013",
        "0.725",
        "0.749",
        "-0.012"
    ],
    [
        "111",
        "20",
        "37.929",
        "-11.374",
        "0.801",
        "0.749",
        "0.002"
    ],
    [
        "112",
        "20",
        "37.846",
        "-11.629",
        "0.793",
        "0.823",
        "0.012"
    ],
    [
        "113",
        "20",
        "37.825",
        "-11.895",
        "0.823",
        "0.890",
        "0.006"
    ],
    [
        "114",
        "20",
        "37.863",
        "-12.254",
        "0.884",
        "0.842",
        "0.034"
    ],
    [
        "115",
        "20",
        "37.981",
        "-12.604",
        "0.746",
        "0.822",
        "0.040"
    ],
    [
        "116",
        "20",
        "38.212",
        "-12.933",
        "0.499",
        "0.558",
        "-0.047"
    ],
    [
        "117",
        "20",
        "38.253",
        "-13.418",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "20",
        "38.171",
        "-13.900",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "20",
        "38.086",
        "-14.380",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "20",
        "37.999",
        "-14.854",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "20",
        "-3.516",
        "2.027",
        "5.691",
        "6.395",
        "-0.202"
    ],
    [
        "122",
        "20",
        "-40.678",
        "30.065",
        "4.022",
        "4.128",
        "-0.169"
    ],
    [
        "123",
        "20",
        "-50.256",
        "32.825",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "20",
        "-50.063",
        "31.933",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "20",
        "-49.875",
        "31.031",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "20",
        "-49.692",
        "30.119",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "20",
        "-49.516",
        "29.199",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "20",
        "-49.344",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "20",
        "-49.178",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "20",
        "-49.018",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "20",
        "-48.863",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "20",
        "-48.714",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "20",
        "-48.571",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "20",
        "-48.433",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "20",
        "-48.302",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "20",
        "-48.177",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "20",
        "-48.057",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "20",
        "-47.943",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "20",
        "-47.835",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "20",
        "-47.734",
        "16.528",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "20",
        "-47.638",
        "15.509",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "20",
        "-47.548",
        "14.485",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "20",
        "-48.592",
        "13.107",
        "2.594",
        "2.163",
        "0.236"
    ],
    [
        "144",
        "20",
        "-51.187",
        "13.085",
        "4.149",
        "3.194",
        "0.147"
    ],
    [
        "145",
        "20",
        "-56.150",
        "16.123",
        "5.073",
        "4.091",
        "0.105"
    ],
    [
        "146",
        "20",
        "-61.761",
        "18.529",
        "5.716",
        "4.653",
        "0.095"
    ],
    [
        "147",
        "20",
        "-66.585",
        "19.846",
        "5.928",
        "3.891",
        "0.114"
    ],
    [
        "148",
        "20",
        "-69.436",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "20",
        "-69.315",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "20",
        "-69.192",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "20",
        "-69.067",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "20",
        "-68.939",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "20",
        "-68.809",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "20",
        "-68.677",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "20",
        "-68.542",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "20",
        "-68.406",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "20",
        "-68.267",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "20",
        "-68.126",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "20",
        "-67.983",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "20",
        "-67.838",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "20",
        "-67.691",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "20",
        "-67.543",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "20",
        "-67.392",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "20",
        "-67.239",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "20",
        "-67.085",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "20",
        "-66.929",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "20",
        "-66.772",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "20",
        "-66.612",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "20",
        "-66.451",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "20",
        "-66.289",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "20",
        "-66.125",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "20",
        "-65.960",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "20",
        "-65.793",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "20",
        "-65.625",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "20",
        "-65.456",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "20",
        "-65.285",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "20",
        "-65.113",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "20",
        "-64.940",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "20",
        "-64.766",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "21",
        "-64.344",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "21",
        "-64.160",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "21",
        "-63.974",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "21",
        "-63.788",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "21",
        "-63.601",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "21",
        "-63.413",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "21",
        "-63.225",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "21",
        "-63.035",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "21",
        "-62.845",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "21",
        "-62.655",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "21",
        "-62.464",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "21",
        "-62.272",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "21",
        "-62.081",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "21",
        "-61.888",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "21",
        "-61.696",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "21",
        "-61.503",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "21",
        "-61.311",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "21",
        "-61.118",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "21",
        "-60.925",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "21",
        "-60.732",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "21",
        "-60.539",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "21",
        "-60.346",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "21",
        "-60.154",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "21",
        "-59.962",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "21",
        "-59.770",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "21",
        "-59.578",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "21",
        "-59.387",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "21",
        "-59.197",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "21",
        "-59.007",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "21",
        "-58.817",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "21",
        "-58.629",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "21",
        "-58.440",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "21",
        "-58.253",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "21",
        "-58.067",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "21",
        "-57.881",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "21",
        "-57.697",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "21",
        "-57.513",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "21",
        "-57.331",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "21",
        "-57.149",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "21",
        "-56.969",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "21",
        "-56.790",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "21",
        "-56.612",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "21",
        "-56.436",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "21",
        "-56.261",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "21",
        "-56.088",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "21",
        "-55.915",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "21",
        "-55.745",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "21",
        "-55.576",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "21",
        "-55.409",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "21",
        "-55.243",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "21",
        "-55.080",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "21",
        "-54.918",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "21",
        "-54.758",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "21",
        "-54.599",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "21",
        "-54.443",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "21",
        "-54.289",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "21",
        "-54.137",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "21",
        "-53.987",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "21",
        "-53.839",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "21",
        "-53.693",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "21",
        "-53.549",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "21",
        "-53.408",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "21",
        "-53.269",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "21",
        "-53.132",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "21",
        "-52.998",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "21",
        "-52.866",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "21",
        "-52.737",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "21",
        "-52.610",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "21",
        "-52.486",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "21",
        "-52.364",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "21",
        "-52.245",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "21",
        "-39.766",
        "8.946",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "21",
        "-4.529",
        "-9.802",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "21",
        "-4.564",
        "-1.575",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "21",
        "-7.620",
        "-5.982",
        "3.832",
        "5.718",
        "-0.034"
    ],
    [
        "-105",
        "21",
        "-7.723",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "21",
        "-7.778",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "21",
        "-7.832",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "21",
        "-7.883",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "21",
        "-7.931",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "21",
        "-7.978",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "21",
        "-8.022",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "21",
        "-8.064",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "21",
        "-8.103",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "21",
        "-8.139",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "21",
        "-8.174",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "21",
        "-8.206",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "21",
        "-8.236",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "21",
        "-8.262",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "21",
        "-8.287",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "21",
        "-8.309",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "21",
        "-8.330",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "21",
        "-8.347",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "21",
        "-8.362",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "21",
        "-8.374",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "21",
        "-8.383",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "21",
        "-8.391",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "21",
        "-8.396",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "21",
        "-8.398",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "21",
        "-8.399",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "21",
        "-8.395",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "21",
        "-8.391",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "21",
        "-8.383",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "21",
        "-8.373",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "21",
        "-8.360",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "21",
        "-8.346",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "21",
        "-8.328",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "21",
        "-8.308",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "21",
        "-8.286",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "21",
        "-8.261",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "21",
        "-8.233",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "21",
        "-8.203",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "21",
        "-8.171",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "21",
        "-8.137",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "21",
        "-8.100",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "21",
        "-8.060",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "21",
        "-8.018",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "21",
        "-7.974",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "21",
        "-7.927",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "21",
        "-7.879",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "21",
        "-7.828",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "21",
        "-7.774",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "21",
        "-7.718",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "21",
        "-7.660",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "21",
        "-7.600",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "21",
        "-7.537",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "21",
        "-7.472",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "21",
        "-7.405",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "21",
        "-7.335",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "21",
        "-7.264",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "21",
        "-7.191",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "21",
        "-7.115",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "21",
        "-7.037",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "21",
        "-6.958",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "21",
        "-5.513",
        "12.437",
        "0.932",
        "0.989",
        "-0.012"
    ],
    [
        "-45",
        "21",
        "16.829",
        "11.291",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "21",
        "16.900",
        "11.567",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "21",
        "16.973",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "21",
        "17.049",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "21",
        "17.125",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "21",
        "17.203",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "21",
        "17.283",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "21",
        "17.364",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "21",
        "17.448",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "21",
        "17.532",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "21",
        "17.618",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "21",
        "17.707",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "21",
        "17.795",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "21",
        "17.886",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "21",
        "17.977",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "21",
        "18.071",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "21",
        "18.166",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "21",
        "18.262",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "21",
        "18.359",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "21",
        "18.458",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "21",
        "18.558",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "21",
        "18.659",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "21",
        "18.761",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "21",
        "18.865",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "21",
        "18.970",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "21",
        "19.075",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "21",
        "19.182",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "21",
        "19.289",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "21",
        "19.398",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "21",
        "19.508",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "21",
        "19.619",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "21",
        "19.731",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "21",
        "19.842",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "21",
        "19.955",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "21",
        "20.070",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "21",
        "20.185",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "21",
        "20.300",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "21",
        "20.417",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "21",
        "20.533",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "21",
        "20.651",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "21",
        "20.768",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "21",
        "20.887",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "21",
        "21.006",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "21",
        "21.125",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "21",
        "21.245",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "21",
        "21.366",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "21",
        "21.486",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "21",
        "21.608",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "21",
        "21.729",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "21",
        "21.851",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "21",
        "21.973",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "21",
        "22.094",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "21",
        "22.217",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "21",
        "22.339",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "21",
        "22.461",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "21",
        "22.583",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "21",
        "22.706",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "21",
        "22.828",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "21",
        "22.950",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "21",
        "23.073",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "21",
        "23.194",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "21",
        "23.316",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "21",
        "23.437",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "21",
        "23.558",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "21",
        "23.679",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "21",
        "23.800",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "21",
        "23.920",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "21",
        "24.040",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "21",
        "24.159",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "21",
        "24.277",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "21",
        "24.396",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "21",
        "24.514",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "21",
        "24.631",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "21",
        "24.747",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "21",
        "24.863",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "21",
        "24.978",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "21",
        "25.093",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "21",
        "25.206",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "21",
        "25.319",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "21",
        "25.431",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "21",
        "25.542",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "21",
        "25.652",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "21",
        "25.761",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "21",
        "30.613",
        "22.806",
        "12.016",
        "13.717",
        "0.237"
    ],
    [
        "39",
        "21",
        "32.990",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "21",
        "33.149",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "21",
        "33.310",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "21",
        "33.475",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "21",
        "33.642",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "21",
        "33.812",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "21",
        "33.984",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "21",
        "34.160",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "21",
        "34.338",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "21",
        "34.517",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "21",
        "34.700",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "21",
        "34.885",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "21",
        "35.072",
        "30.137",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "21",
        "35.262",
        "30.495",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "21",
        "35.454",
        "30.843",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "21",
        "35.648",
        "31.180",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "21",
        "35.844",
        "31.510",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "21",
        "36.042",
        "31.829",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "21",
        "36.242",
        "32.138",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "21",
        "36.444",
        "32.439",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "21",
        "36.648",
        "32.728",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "21",
        "36.854",
        "33.009",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "21",
        "37.060",
        "33.278",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "21",
        "37.724",
        "33.267",
        "9.710",
        "12.345",
        "0.055"
    ],
    [
        "63",
        "21",
        "38.440",
        "33.188",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "21",
        "38.648",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "21",
        "38.857",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "21",
        "39.067",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "21",
        "39.277",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "21",
        "39.488",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "21",
        "39.700",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "21",
        "39.912",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "21",
        "40.125",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "21",
        "40.338",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "21",
        "40.552",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "21",
        "40.766",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "21",
        "40.980",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "21",
        "41.194",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "21",
        "41.408",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "21",
        "41.623",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "21",
        "41.837",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "21",
        "42.051",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "21",
        "42.265",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "21",
        "42.479",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "21",
        "42.693",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "21",
        "42.906",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "21",
        "43.119",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "21",
        "43.332",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "21",
        "43.543",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "21",
        "43.754",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "21",
        "43.965",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "21",
        "44.174",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "21",
        "44.383",
        "33.334",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "21",
        "44.429",
        "33.015",
        "0.237",
        "0.327",
        "0.054"
    ],
    [
        "93",
        "21",
        "41.633",
        "30.139",
        "1.099",
        "0.938",
        "-0.002"
    ],
    [
        "94",
        "21",
        "38.318",
        "24.733",
        "1.029",
        "0.949",
        "0.041"
    ],
    [
        "95",
        "21",
        "36.466",
        "19.730",
        "1.184",
        "1.221",
        "0.012"
    ],
    [
        "96",
        "21",
        "35.361",
        "7.304",
        "1.272",
        "1.110",
        "0.025"
    ],
    [
        "97",
        "21",
        "35.491",
        "-3.799",
        "0.999",
        "0.760",
        "0.120"
    ],
    [
        "98",
        "21",
        "35.115",
        "-5.312",
        "1.140",
        "1.249",
        "0.003"
    ],
    [
        "99",
        "21",
        "35.402",
        "-5.867",
        "1.226",
        "1.213",
        "0.018"
    ],
    [
        "100",
        "21",
        "36.405",
        "-6.517",
        "1.056",
        "1.149",
        "0.080"
    ],
    [
        "101",
        "21",
        "37.918",
        "-6.847",
        "0.852",
        "1.033",
        "0.052"
    ],
    [
        "102",
        "21",
        "38.706",
        "-6.944",
        "0.941",
        "0.973",
        "-0.037"
    ],
    [
        "103",
        "21",
        "39.282",
        "-6.369",
        "0.244",
        "0.347",
        "0.000"
    ],
    [
        "104",
        "21",
        "39.347",
        "-6.821",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "21",
        "39.302",
        "-7.345",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "21",
        "39.223",
        "-9.241",
        "0.850",
        "1.005",
        "-0.084"
    ],
    [
        "107",
        "21",
        "38.570",
        "-10.978",
        "0.791",
        "0.812",
        "-0.026"
    ],
    [
        "108",
        "21",
        "38.139",
        "-10.769",
        "0.808",
        "0.801",
        "-0.029"
    ],
    [
        "109",
        "21",
        "37.889",
        "-11.054",
        "0.700",
        "0.736",
        "-0.018"
    ],
    [
        "110",
        "21",
        "37.660",
        "-11.552",
        "0.693",
        "0.728",
        "-0.007"
    ],
    [
        "111",
        "21",
        "37.474",
        "-11.904",
        "0.754",
        "0.707",
        "-0.006"
    ],
    [
        "112",
        "21",
        "37.362",
        "-12.166",
        "0.768",
        "0.809",
        "0.005"
    ],
    [
        "113",
        "21",
        "37.334",
        "-12.448",
        "0.831",
        "0.967",
        "0.010"
    ],
    [
        "114",
        "21",
        "37.387",
        "-12.711",
        "0.948",
        "0.952",
        "0.046"
    ],
    [
        "115",
        "21",
        "37.496",
        "-12.992",
        "0.912",
        "1.013",
        "0.082"
    ],
    [
        "116",
        "21",
        "37.575",
        "-13.272",
        "0.885",
        "0.965",
        "0.059"
    ],
    [
        "117",
        "21",
        "37.346",
        "-13.437",
        "0.983",
        "0.821",
        "-0.007"
    ],
    [
        "118",
        "21",
        "36.399",
        "-14.107",
        "1.514",
        "1.178",
        "-0.017"
    ],
    [
        "119",
        "21",
        "33.606",
        "-14.915",
        "2.529",
        "2.024",
        "0.035"
    ],
    [
        "120",
        "21",
        "24.865",
        "-14.341",
        "4.530",
        "3.281",
        "-0.147"
    ],
    [
        "121",
        "21",
        "1.304",
        "1.320",
        "5.161",
        "5.010",
        "-0.190"
    ],
    [
        "122",
        "21",
        "-38.521",
        "31.459",
        "3.673",
        "4.080",
        "-0.230"
    ],
    [
        "123",
        "21",
        "-48.986",
        "32.825",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "21",
        "-48.783",
        "31.933",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "21",
        "-48.586",
        "31.031",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "21",
        "-48.394",
        "30.119",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "21",
        "-48.209",
        "29.199",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "21",
        "-48.029",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "21",
        "-47.855",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "21",
        "-47.687",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "21",
        "-47.525",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "21",
        "-47.369",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "21",
        "-47.220",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "21",
        "-47.075",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "21",
        "-46.937",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "21",
        "-46.806",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "21",
        "-46.681",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "21",
        "-46.561",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "21",
        "-46.448",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "21",
        "-46.342",
        "16.528",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "21",
        "-46.242",
        "15.509",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "21",
        "-46.148",
        "14.485",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "21",
        "-49.083",
        "12.834",
        "3.889",
        "3.187",
        "0.245"
    ],
    [
        "144",
        "21",
        "-53.133",
        "13.573",
        "5.234",
        "3.964",
        "0.182"
    ],
    [
        "145",
        "21",
        "-58.605",
        "16.831",
        "5.335",
        "4.666",
        "0.109"
    ],
    [
        "146",
        "21",
        "-63.933",
        "18.854",
        "5.023",
        "4.630",
        "0.091"
    ],
    [
        "147",
        "21",
        "-69.544",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "21",
        "-69.420",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "21",
        "-69.294",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "21",
        "-69.165",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "21",
        "-69.034",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "21",
        "-68.900",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "21",
        "-68.764",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "21",
        "-68.625",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "21",
        "-68.484",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "21",
        "-68.341",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "21",
        "-68.196",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "21",
        "-68.048",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "21",
        "-67.898",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "21",
        "-67.746",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "21",
        "-67.593",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "21",
        "-67.437",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "21",
        "-67.279",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "21",
        "-67.119",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "21",
        "-66.957",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "21",
        "-66.794",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "21",
        "-66.629",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "21",
        "-66.462",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "21",
        "-66.293",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "21",
        "-66.123",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "21",
        "-65.951",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "21",
        "-65.778",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "21",
        "-65.603",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "21",
        "-65.427",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "21",
        "-65.250",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "21",
        "-65.071",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "21",
        "-64.891",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "21",
        "-64.710",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "21",
        "-64.528",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "22",
        "-64.078",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "22",
        "-63.885",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "22",
        "-63.691",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "22",
        "-63.496",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "22",
        "-63.301",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "22",
        "-63.104",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "22",
        "-62.907",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "22",
        "-62.709",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "22",
        "-62.511",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "22",
        "-62.312",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "22",
        "-62.112",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "22",
        "-61.912",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "22",
        "-61.711",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "22",
        "-61.511",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "22",
        "-61.309",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "22",
        "-61.108",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "22",
        "-60.907",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "22",
        "-60.705",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "22",
        "-60.503",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "22",
        "-60.302",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "22",
        "-60.100",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "22",
        "-59.899",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "22",
        "-59.698",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "22",
        "-59.497",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "22",
        "-59.296",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "22",
        "-59.096",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "22",
        "-58.896",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "22",
        "-58.697",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "22",
        "-58.498",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "22",
        "-58.300",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "22",
        "-58.103",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "22",
        "-57.906",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "22",
        "-57.711",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "22",
        "-57.516",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "22",
        "-57.322",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "22",
        "-57.129",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "22",
        "-56.937",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "22",
        "-56.746",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "22",
        "-56.557",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "22",
        "-56.368",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "22",
        "-56.181",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "22",
        "-55.995",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "22",
        "-55.811",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "22",
        "-55.628",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "22",
        "-55.447",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "22",
        "-55.267",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "22",
        "-55.089",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "22",
        "-54.912",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "22",
        "-54.737",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "22",
        "-54.564",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "22",
        "-54.393",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "22",
        "-54.224",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "22",
        "-54.057",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "22",
        "-53.891",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "22",
        "-53.728",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "22",
        "-53.567",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "22",
        "-53.408",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "22",
        "-53.251",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "22",
        "-53.096",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "22",
        "-52.944",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "22",
        "-52.793",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "22",
        "-52.646",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "22",
        "-52.500",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "22",
        "-52.358",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "22",
        "-52.217",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "22",
        "-52.080",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "22",
        "-51.944",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "22",
        "-51.812",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "22",
        "-51.682",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "22",
        "-51.555",
        "19.239",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "22",
        "-51.430",
        "18.815",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "22",
        "-41.434",
        "8.946",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "22",
        "-13.309",
        "-9.802",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "22",
        "-12.566",
        "-2.978",
        "2.867",
        "3.566",
        "-0.216"
    ],
    [
        "-106",
        "22",
        "-8.001",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "22",
        "-8.061",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "22",
        "-8.119",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "22",
        "-8.175",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "22",
        "-8.229",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "22",
        "-8.280",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "22",
        "-8.328",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "22",
        "-8.374",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "22",
        "-8.417",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "22",
        "-8.459",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "22",
        "-8.497",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "22",
        "-8.533",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "22",
        "-8.566",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "22",
        "-8.597",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "22",
        "-8.626",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "22",
        "-8.652",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "22",
        "-8.675",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "22",
        "-8.696",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "22",
        "-8.714",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "22",
        "-8.728",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "22",
        "-8.741",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "22",
        "-8.752",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "22",
        "-8.759",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "22",
        "-8.764",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "22",
        "-8.767",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "22",
        "-8.767",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "22",
        "-8.765",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "22",
        "-8.759",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "22",
        "-8.752",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "22",
        "-8.741",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "22",
        "-8.727",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "22",
        "-8.711",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "22",
        "-8.693",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "22",
        "-8.672",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "22",
        "-8.649",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "22",
        "-8.623",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "22",
        "-8.595",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "22",
        "-8.563",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "22",
        "-8.530",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "22",
        "-8.494",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "22",
        "-8.455",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "22",
        "-8.414",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "22",
        "-8.370",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "22",
        "-8.324",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "22",
        "-8.275",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "22",
        "-8.224",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "22",
        "-8.171",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "22",
        "-8.115",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "22",
        "-8.056",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "22",
        "-7.995",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "22",
        "-7.932",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "22",
        "-7.867",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "22",
        "-7.799",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "22",
        "-7.729",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "22",
        "-7.657",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "22",
        "-7.582",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "22",
        "-7.505",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "22",
        "-7.426",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "22",
        "-7.344",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "22",
        "-7.261",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "22",
        "-7.175",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "22",
        "16.415",
        "11.291",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "22",
        "16.490",
        "11.567",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "22",
        "16.566",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "22",
        "16.645",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "22",
        "16.724",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "22",
        "16.807",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "22",
        "16.890",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "22",
        "16.976",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "22",
        "17.062",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "22",
        "17.150",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "22",
        "17.241",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "22",
        "17.332",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "22",
        "17.425",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "22",
        "17.520",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "22",
        "17.616",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "22",
        "17.713",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "22",
        "17.813",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "22",
        "17.913",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "22",
        "18.015",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "22",
        "18.118",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "22",
        "18.223",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "22",
        "18.328",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "22",
        "18.435",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "22",
        "18.544",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "22",
        "18.652",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "22",
        "18.763",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "22",
        "18.875",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "22",
        "18.988",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "22",
        "19.101",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "22",
        "19.216",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "22",
        "19.331",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "22",
        "19.448",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "22",
        "19.565",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "22",
        "19.684",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "22",
        "19.803",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "22",
        "19.923",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "22",
        "20.043",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "22",
        "20.165",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "22",
        "20.287",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "22",
        "20.410",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "22",
        "20.533",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "22",
        "20.657",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "22",
        "20.782",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "22",
        "20.906",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "22",
        "21.032",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "22",
        "21.158",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "22",
        "21.284",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "22",
        "21.411",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "22",
        "21.537",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "22",
        "21.664",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "22",
        "21.792",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "22",
        "21.919",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "22",
        "22.047",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "22",
        "22.174",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "22",
        "22.302",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "22",
        "22.431",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "22",
        "22.558",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "22",
        "22.686",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "22",
        "22.814",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "22",
        "22.941",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "22",
        "23.069",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "22",
        "23.196",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "22",
        "23.322",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "22",
        "23.450",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "22",
        "23.576",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "22",
        "23.702",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "22",
        "23.827",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "22",
        "23.953",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "22",
        "24.077",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "22",
        "24.202",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "22",
        "24.325",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "22",
        "24.449",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "22",
        "24.570",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "22",
        "24.693",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "22",
        "24.814",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "22",
        "24.933",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "22",
        "25.053",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "22",
        "25.172",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "22",
        "25.290",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "22",
        "25.407",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "22",
        "25.523",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "22",
        "25.638",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "22",
        "25.753",
        "17.383",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "22",
        "30.324",
        "23.707",
        "16.477",
        "18.616",
        "0.097"
    ],
    [
        "39",
        "22",
        "32.267",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "22",
        "32.434",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "22",
        "32.602",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "22",
        "32.775",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "22",
        "32.949",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "22",
        "33.127",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "22",
        "33.307",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "22",
        "33.490",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "22",
        "33.676",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "22",
        "33.865",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "22",
        "34.056",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "22",
        "34.249",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "22",
        "34.444",
        "30.137",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "22",
        "34.643",
        "30.495",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "22",
        "34.844",
        "30.843",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "22",
        "35.046",
        "31.180",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "22",
        "35.251",
        "31.510",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "22",
        "35.458",
        "31.829",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "22",
        "35.667",
        "32.138",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "22",
        "35.878",
        "32.439",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "22",
        "36.092",
        "32.728",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "22",
        "36.306",
        "33.009",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "22",
        "36.523",
        "33.278",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "22",
        "36.749",
        "33.510",
        "1.036",
        "1.364",
        "-0.385"
    ],
    [
        "63",
        "22",
        "37.520",
        "33.200",
        "1.816",
        "1.834",
        "-0.235"
    ],
    [
        "64",
        "22",
        "38.233",
        "33.332",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "22",
        "38.451",
        "33.468",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "22",
        "38.671",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "22",
        "38.890",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "22",
        "39.112",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "22",
        "39.332",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "22",
        "39.555",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "22",
        "39.777",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "22",
        "40.000",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "22",
        "40.224",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "22",
        "40.447",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "22",
        "40.671",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "22",
        "40.894",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "22",
        "41.119",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "22",
        "41.343",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "22",
        "41.567",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "22",
        "41.791",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "22",
        "42.015",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "22",
        "42.239",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "22",
        "42.462",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "22",
        "42.685",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "22",
        "42.907",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "22",
        "43.129",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "22",
        "43.350",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "22",
        "43.571",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "22",
        "43.791",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "22",
        "44.010",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "22",
        "44.229",
        "33.334",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "22",
        "44.076",
        "32.957",
        "0.266",
        "0.325",
        "0.034"
    ],
    [
        "93",
        "22",
        "40.392",
        "29.305",
        "1.155",
        "1.006",
        "0.061"
    ],
    [
        "94",
        "22",
        "37.055",
        "24.828",
        "1.109",
        "1.095",
        "0.010"
    ],
    [
        "95",
        "22",
        "35.405",
        "17.970",
        "0.924",
        "0.818",
        "0.032"
    ],
    [
        "96",
        "22",
        "34.041",
        "7.719",
        "0.716",
        "0.408",
        "0.122"
    ],
    [
        "97",
        "22",
        "33.318",
        "-4.944",
        "0.983",
        "1.090",
        "0.027"
    ],
    [
        "98",
        "22",
        "33.290",
        "-6.490",
        "1.162",
        "1.281",
        "-0.012"
    ],
    [
        "99",
        "22",
        "33.349",
        "-7.720",
        "1.229",
        "1.218",
        "-0.002"
    ],
    [
        "100",
        "22",
        "34.094",
        "-8.803",
        "1.109",
        "1.208",
        "0.036"
    ],
    [
        "101",
        "22",
        "35.488",
        "-9.413",
        "1.069",
        "1.171",
        "0.029"
    ],
    [
        "102",
        "22",
        "36.895",
        "-9.167",
        "1.130",
        "1.117",
        "0.019"
    ],
    [
        "103",
        "22",
        "37.991",
        "-8.403",
        "1.003",
        "1.140",
        "0.020"
    ],
    [
        "104",
        "22",
        "38.995",
        "-8.456",
        "0.966",
        "1.108",
        "-0.024"
    ],
    [
        "105",
        "22",
        "39.792",
        "-9.886",
        "1.045",
        "1.055",
        "-0.064"
    ],
    [
        "106",
        "22",
        "39.513",
        "-10.798",
        "0.939",
        "1.067",
        "-0.080"
    ],
    [
        "107",
        "22",
        "38.134",
        "-11.077",
        "0.815",
        "0.922",
        "-0.023"
    ],
    [
        "108",
        "22",
        "37.182",
        "-11.663",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "22",
        "37.106",
        "-11.926",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "22",
        "37.026",
        "-12.185",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "22",
        "36.947",
        "-12.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "22",
        "36.864",
        "-12.691",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "22",
        "36.781",
        "-12.939",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "22",
        "36.695",
        "-13.182",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "22",
        "37.062",
        "-13.253",
        "0.906",
        "0.985",
        "0.058"
    ],
    [
        "116",
        "22",
        "36.974",
        "-13.435",
        "0.993",
        "1.061",
        "0.032"
    ],
    [
        "117",
        "22",
        "36.641",
        "-13.586",
        "1.151",
        "1.041",
        "-0.027"
    ],
    [
        "118",
        "22",
        "35.989",
        "-14.251",
        "1.375",
        "1.220",
        "-0.054"
    ],
    [
        "119",
        "22",
        "30.209",
        "-15.772",
        "2.407",
        "2.189",
        "0.039"
    ],
    [
        "120",
        "22",
        "14.638",
        "-13.448",
        "4.482",
        "3.825",
        "-0.313"
    ],
    [
        "121",
        "22",
        "-3.860",
        "0.518",
        "3.278",
        "3.458",
        "-0.440"
    ],
    [
        "122",
        "22",
        "-36.729",
        "32.077",
        "4.248",
        "4.783",
        "-0.399"
    ],
    [
        "123",
        "22",
        "-47.700",
        "32.825",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "22",
        "-47.487",
        "31.933",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "22",
        "-47.282",
        "31.031",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "22",
        "-47.082",
        "30.119",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "22",
        "-46.888",
        "29.199",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "22",
        "-46.700",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "22",
        "-46.519",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "22",
        "-46.342",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "22",
        "-46.173",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "22",
        "-46.010",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "22",
        "-45.853",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "22",
        "-45.703",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "22",
        "-45.559",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "22",
        "-45.421",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "22",
        "-45.290",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "22",
        "-45.165",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "22",
        "-45.048",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "22",
        "-44.936",
        "16.528",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "22",
        "-44.832",
        "15.509",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "22",
        "-46.178",
        "14.059",
        "3.527",
        "3.272",
        "0.361"
    ],
    [
        "143",
        "22",
        "-51.094",
        "12.542",
        "5.832",
        "4.474",
        "0.227"
    ],
    [
        "144",
        "22",
        "-56.798",
        "14.217",
        "6.939",
        "4.706",
        "0.166"
    ],
    [
        "145",
        "22",
        "-63.270",
        "17.652",
        "5.900",
        "4.763",
        "0.114"
    ],
    [
        "146",
        "22",
        "-68.005",
        "19.281",
        "3.937",
        "3.481",
        "0.088"
    ],
    [
        "147",
        "22",
        "-69.513",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "22",
        "-69.383",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "22",
        "-69.251",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "22",
        "-69.117",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "22",
        "-68.979",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "22",
        "-68.840",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "22",
        "-68.697",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "22",
        "-68.552",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "22",
        "-68.405",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "22",
        "-68.255",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "22",
        "-68.103",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "22",
        "-67.949",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "22",
        "-67.793",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "22",
        "-67.634",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "22",
        "-67.473",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "22",
        "-67.310",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "22",
        "-67.145",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "22",
        "-66.978",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "22",
        "-66.809",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "22",
        "-66.638",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "22",
        "-66.466",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "22",
        "-66.291",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "22",
        "-66.115",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "22",
        "-65.937",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "22",
        "-65.758",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "22",
        "-65.576",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "22",
        "-65.394",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "22",
        "-65.210",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "22",
        "-65.024",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "22",
        "-64.837",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "22",
        "-64.649",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "22",
        "-64.460",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "22",
        "-64.269",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "23",
        "-63.791",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "23",
        "-63.590",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "23",
        "-63.388",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "23",
        "-63.185",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "23",
        "-62.981",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "23",
        "-62.776",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "23",
        "-62.571",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "23",
        "-62.364",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "23",
        "-62.157",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "23",
        "-61.950",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "23",
        "-61.741",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "23",
        "-61.533",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "23",
        "-61.323",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "23",
        "-61.114",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "23",
        "-60.904",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "23",
        "-60.694",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "23",
        "-60.484",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "23",
        "-60.274",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "23",
        "-60.063",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "23",
        "-59.853",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "23",
        "-59.643",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "23",
        "-59.433",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "23",
        "-59.223",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "23",
        "-59.013",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "23",
        "-58.804",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "23",
        "-58.595",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "23",
        "-58.387",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "23",
        "-58.179",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "23",
        "-57.972",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "23",
        "-57.765",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "23",
        "-57.560",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "23",
        "-57.355",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "23",
        "-57.150",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "23",
        "-56.947",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "23",
        "-56.745",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "23",
        "-56.544",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "23",
        "-56.344",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "23",
        "-56.145",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "23",
        "-55.947",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "23",
        "-55.750",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "23",
        "-55.555",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "23",
        "-55.361",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "23",
        "-55.169",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "23",
        "-54.978",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "23",
        "-54.789",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "23",
        "-54.602",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "23",
        "-54.416",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "23",
        "-54.232",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "23",
        "-54.049",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "23",
        "-53.869",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "23",
        "-53.690",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "23",
        "-53.514",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "23",
        "-53.339",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "23",
        "-53.167",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "23",
        "-52.996",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "23",
        "-52.828",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "23",
        "-52.662",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "23",
        "-52.498",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "23",
        "-52.337",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "23",
        "-52.178",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "23",
        "-52.022",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "23",
        "-51.868",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "23",
        "-51.716",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "23",
        "-51.567",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "23",
        "-51.421",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "23",
        "-51.277",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "23",
        "-51.136",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "23",
        "-50.998",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "23",
        "-50.862",
        "19.656",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "23",
        "-50.057",
        "19.838",
        "2.490",
        "2.028",
        "0.144"
    ],
    [
        "-110",
        "23",
        "-47.704",
        "17.693",
        "1.525",
        "1.650",
        "0.130"
    ],
    [
        "-109",
        "23",
        "-30.977",
        "4.870",
        "2.815",
        "2.995",
        "-0.161"
    ],
    [
        "-108",
        "23",
        "-23.973",
        "-4.215",
        "3.502",
        "2.902",
        "-0.351"
    ],
    [
        "-107",
        "23",
        "-9.495",
        "-9.823",
        "0.793",
        "1.347",
        "-0.098"
    ],
    [
        "-106",
        "23",
        "-8.333",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "23",
        "-8.398",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "23",
        "-8.458",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "23",
        "-8.516",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "23",
        "-8.572",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "23",
        "-8.624",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "23",
        "-8.675",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "23",
        "-8.724",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "23",
        "-8.768",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "23",
        "-8.811",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "23",
        "-8.851",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "23",
        "-8.889",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "23",
        "-8.923",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "23",
        "-8.956",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "23",
        "-8.986",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "23",
        "-9.013",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "23",
        "-9.036",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "23",
        "-9.059",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "23",
        "-9.077",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "23",
        "-9.094",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "23",
        "-9.106",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "23",
        "-9.118",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "23",
        "-9.126",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "23",
        "-9.131",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "23",
        "-9.133",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "23",
        "-9.133",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "23",
        "-9.131",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "23",
        "-9.125",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "23",
        "-9.117",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "23",
        "-9.106",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "23",
        "-9.093",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "23",
        "-9.076",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "23",
        "-9.057",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "23",
        "-9.035",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "23",
        "-9.010",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "23",
        "-8.984",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "23",
        "-8.954",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "23",
        "-8.921",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "23",
        "-8.886",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "23",
        "-8.849",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "23",
        "-8.808",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "23",
        "-8.765",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "23",
        "-8.719",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "23",
        "-8.671",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "23",
        "-8.620",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "23",
        "-8.567",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "23",
        "-8.511",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "23",
        "-8.452",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "23",
        "-8.392",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "23",
        "-8.328",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "23",
        "-8.263",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "23",
        "-8.194",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "23",
        "-8.124",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "23",
        "-8.051",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "23",
        "-7.975",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "23",
        "-7.897",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "23",
        "-7.817",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "23",
        "-7.734",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "23",
        "-7.649",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "23",
        "-7.563",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "23",
        "-7.473",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "23",
        "-7.382",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "23",
        "16.075",
        "11.567",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "23",
        "16.154",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "23",
        "16.236",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "23",
        "16.319",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "23",
        "16.405",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "23",
        "16.492",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "23",
        "16.580",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "23",
        "16.671",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "23",
        "16.764",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "23",
        "16.857",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "23",
        "16.953",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "23",
        "17.050",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "23",
        "17.149",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "23",
        "17.249",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "23",
        "17.351",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "23",
        "17.454",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "23",
        "17.559",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "23",
        "17.665",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "23",
        "17.772",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "23",
        "17.882",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "23",
        "17.992",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "23",
        "18.103",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "23",
        "18.216",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "23",
        "18.330",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "23",
        "18.445",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "23",
        "18.562",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "23",
        "18.680",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "23",
        "18.797",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "23",
        "18.917",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "23",
        "19.038",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "23",
        "19.160",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "23",
        "19.282",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "23",
        "19.406",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "23",
        "19.530",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "23",
        "19.655",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "23",
        "19.781",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "23",
        "19.907",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "23",
        "20.035",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "23",
        "20.163",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "23",
        "20.291",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "23",
        "20.421",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "23",
        "20.551",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "23",
        "20.681",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "23",
        "20.812",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "23",
        "20.943",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "23",
        "21.075",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "23",
        "21.207",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "23",
        "21.339",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "23",
        "21.472",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "23",
        "21.605",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "23",
        "21.737",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "23",
        "21.871",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "23",
        "22.004",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "23",
        "22.137",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "23",
        "22.271",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "23",
        "22.404",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "23",
        "22.537",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "23",
        "22.670",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "23",
        "22.804",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "23",
        "22.936",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "23",
        "23.069",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "23",
        "23.201",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "23",
        "23.334",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "23",
        "23.465",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "23",
        "23.597",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "23",
        "23.728",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "23",
        "23.858",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "23",
        "23.989",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "23",
        "24.117",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "23",
        "24.247",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "23",
        "24.375",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "23",
        "24.503",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "23",
        "24.630",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "23",
        "24.756",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "23",
        "24.882",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "23",
        "25.006",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "23",
        "25.130",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "23",
        "25.254",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "23",
        "25.375",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "23",
        "25.496",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "23",
        "25.617",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "23",
        "27.313",
        "19.376",
        "19.089",
        "22.380",
        "0.058"
    ],
    [
        "38",
        "23",
        "31.365",
        "24.703",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "23",
        "31.536",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "23",
        "31.708",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "23",
        "31.885",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "23",
        "32.064",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "23",
        "32.247",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "23",
        "32.432",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "23",
        "32.620",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "23",
        "32.811",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "23",
        "33.005",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "23",
        "33.202",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "23",
        "33.400",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "23",
        "33.603",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "23",
        "33.806",
        "30.137",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "23",
        "34.013",
        "30.495",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "23",
        "34.222",
        "30.843",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "23",
        "34.434",
        "31.180",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "23",
        "34.647",
        "31.510",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "23",
        "34.863",
        "31.829",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "23",
        "35.082",
        "32.138",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "23",
        "35.301",
        "32.439",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "23",
        "35.525",
        "32.728",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "23",
        "35.748",
        "33.009",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "23",
        "35.974",
        "33.278",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "23",
        "36.202",
        "33.539",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "23",
        "36.431",
        "33.788",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "23",
        "37.176",
        "33.905",
        "0.943",
        "1.474",
        "-0.359"
    ],
    [
        "65",
        "23",
        "37.875",
        "33.578",
        "0.732",
        "0.988",
        "-0.313"
    ],
    [
        "66",
        "23",
        "38.263",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "23",
        "38.493",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "23",
        "38.723",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "23",
        "38.954",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "23",
        "39.185",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "23",
        "39.417",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "23",
        "39.650",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "23",
        "39.883",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "23",
        "40.116",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "23",
        "40.350",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "23",
        "40.583",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "23",
        "40.817",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "23",
        "41.051",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "23",
        "41.285",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "23",
        "41.518",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "23",
        "41.751",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "23",
        "41.985",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "23",
        "42.217",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "23",
        "42.450",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "23",
        "42.682",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "23",
        "42.914",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "23",
        "43.144",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "23",
        "43.375",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "23",
        "43.604",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "23",
        "43.833",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "23",
        "44.061",
        "33.334",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "23",
        "43.827",
        "32.980",
        "0.263",
        "0.330",
        "-0.010"
    ],
    [
        "93",
        "23",
        "39.458",
        "29.314",
        "1.186",
        "1.073",
        "-0.012"
    ],
    [
        "94",
        "23",
        "36.383",
        "25.130",
        "1.198",
        "1.235",
        "-0.009"
    ],
    [
        "95",
        "23",
        "34.742",
        "18.538",
        "1.235",
        "1.256",
        "0.018"
    ],
    [
        "96",
        "23",
        "33.747",
        "5.939",
        "1.343",
        "1.156",
        "-0.001"
    ],
    [
        "97",
        "23",
        "32.535",
        "-4.572",
        "1.236",
        "1.293",
        "-0.036"
    ],
    [
        "98",
        "23",
        "32.109",
        "-7.511",
        "1.203",
        "1.299",
        "-0.029"
    ],
    [
        "99",
        "23",
        "32.166",
        "-9.435",
        "1.220",
        "1.198",
        "-0.013"
    ],
    [
        "100",
        "23",
        "33.038",
        "-10.656",
        "1.098",
        "1.196",
        "-0.001"
    ],
    [
        "101",
        "23",
        "34.225",
        "-11.396",
        "1.032",
        "1.111",
        "-0.009"
    ],
    [
        "102",
        "23",
        "36.376",
        "-11.638",
        "1.136",
        "1.092",
        "0.016"
    ],
    [
        "103",
        "23",
        "38.330",
        "-11.093",
        "1.011",
        "1.063",
        "-0.023"
    ],
    [
        "104",
        "23",
        "39.815",
        "-11.194",
        "1.042",
        "1.139",
        "-0.010"
    ],
    [
        "105",
        "23",
        "39.695",
        "-11.406",
        "1.059",
        "1.045",
        "-0.069"
    ],
    [
        "106",
        "23",
        "38.328",
        "-11.198",
        "0.740",
        "0.915",
        "-0.074"
    ],
    [
        "107",
        "23",
        "37.518",
        "-11.259",
        "0.585",
        "0.685",
        "0.026"
    ],
    [
        "108",
        "23",
        "37.200",
        "-11.663",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "23",
        "37.120",
        "-11.926",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "23",
        "37.037",
        "-12.185",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "23",
        "36.954",
        "-12.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "23",
        "36.868",
        "-12.691",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "23",
        "36.780",
        "-12.939",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "23",
        "36.692",
        "-13.182",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "23",
        "36.918",
        "-13.332",
        "0.887",
        "0.968",
        "0.002"
    ],
    [
        "116",
        "23",
        "36.883",
        "-13.491",
        "0.991",
        "1.075",
        "-0.011"
    ],
    [
        "117",
        "23",
        "36.658",
        "-13.830",
        "1.098",
        "1.053",
        "-0.046"
    ],
    [
        "118",
        "23",
        "36.802",
        "-14.171",
        "1.099",
        "1.124",
        "-0.040"
    ],
    [
        "119",
        "23",
        "37.254",
        "-14.632",
        "1.293",
        "1.372",
        "-0.053"
    ],
    [
        "120",
        "23",
        "28.617",
        "-14.269",
        "1.878",
        "1.847",
        "-0.256"
    ],
    [
        "121",
        "23",
        "-8.836",
        "5.611",
        "3.613",
        "4.608",
        "-0.393"
    ],
    [
        "122",
        "23",
        "-23.458",
        "25.159",
        "6.138",
        "6.046",
        "-0.302"
    ],
    [
        "123",
        "23",
        "-24.505",
        "14.190",
        "7.222",
        "6.804",
        "-0.393"
    ],
    [
        "124",
        "23",
        "-30.297",
        "8.268",
        "6.359",
        "6.922",
        "-0.257"
    ],
    [
        "125",
        "23",
        "-32.131",
        "12.055",
        "5.935",
        "6.936",
        "-0.219"
    ],
    [
        "126",
        "23",
        "-38.271",
        "21.526",
        "5.142",
        "5.488",
        "-0.365"
    ],
    [
        "127",
        "23",
        "-45.553",
        "29.199",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "23",
        "-45.356",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "23",
        "-45.167",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "23",
        "-44.984",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "23",
        "-44.807",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "23",
        "-44.637",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "23",
        "-44.473",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "23",
        "-44.316",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "23",
        "-44.166",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "23",
        "-44.022",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "23",
        "-43.886",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "23",
        "-43.756",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "23",
        "-43.633",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "23",
        "-43.517",
        "16.528",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "23",
        "-43.407",
        "15.509",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "23",
        "-46.250",
        "13.959",
        "5.015",
        "4.345",
        "0.322"
    ],
    [
        "143",
        "23",
        "-54.517",
        "12.740",
        "7.421",
        "6.021",
        "0.190"
    ],
    [
        "144",
        "23",
        "-62.552",
        "15.484",
        "7.603",
        "5.067",
        "0.148"
    ],
    [
        "145",
        "23",
        "-69.177",
        "18.829",
        "1.707",
        "1.944",
        "0.079"
    ],
    [
        "146",
        "23",
        "-69.593",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "23",
        "-69.461",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "23",
        "-69.326",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "23",
        "-69.188",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "23",
        "-69.047",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "23",
        "-68.904",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "23",
        "-68.758",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "23",
        "-68.610",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "23",
        "-68.459",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "23",
        "-68.305",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "23",
        "-68.149",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "23",
        "-67.991",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "23",
        "-67.830",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "23",
        "-67.666",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "23",
        "-67.501",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "23",
        "-67.333",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "23",
        "-67.163",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "23",
        "-66.991",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "23",
        "-66.817",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "23",
        "-66.641",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "23",
        "-66.462",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "23",
        "-66.282",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "23",
        "-66.100",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "23",
        "-65.917",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "23",
        "-65.731",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "23",
        "-65.544",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "23",
        "-65.355",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "23",
        "-65.164",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "23",
        "-64.972",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "23",
        "-64.779",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "23",
        "-64.584",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "23",
        "-64.388",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "23",
        "-64.190",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "23",
        "-63.991",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "24",
        "-63.486",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "24",
        "-63.277",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "24",
        "-63.066",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "24",
        "-62.855",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "24",
        "-62.643",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "24",
        "-62.429",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "24",
        "-62.215",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "24",
        "-62.000",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "24",
        "-61.785",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "24",
        "-61.569",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "24",
        "-61.352",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "24",
        "-61.135",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "24",
        "-60.917",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "24",
        "-60.699",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "24",
        "-60.480",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "24",
        "-60.262",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "24",
        "-60.043",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "24",
        "-59.824",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "24",
        "-59.605",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "24",
        "-59.386",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "24",
        "-59.167",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "24",
        "-58.949",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "24",
        "-58.730",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "24",
        "-58.512",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "24",
        "-58.294",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "24",
        "-58.077",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "24",
        "-57.860",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "24",
        "-57.644",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "24",
        "-57.428",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "24",
        "-57.213",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "24",
        "-56.999",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "24",
        "-56.785",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "24",
        "-56.573",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "24",
        "-56.361",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "24",
        "-56.151",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "24",
        "-55.941",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "24",
        "-55.733",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "24",
        "-55.526",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "24",
        "-55.320",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "24",
        "-55.115",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "24",
        "-54.912",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "24",
        "-54.711",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "24",
        "-54.510",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "24",
        "-54.312",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "24",
        "-54.115",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "24",
        "-53.920",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "24",
        "-53.726",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "24",
        "-53.534",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "24",
        "-53.345",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "24",
        "-53.157",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "24",
        "-52.971",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "24",
        "-52.787",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "24",
        "-52.605",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "24",
        "-52.426",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "24",
        "-52.248",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "24",
        "-52.073",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "24",
        "-51.901",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "24",
        "-51.730",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "24",
        "-51.562",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "24",
        "-51.397",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "24",
        "-51.234",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "24",
        "-51.074",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "24",
        "-50.916",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "24",
        "-50.761",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "24",
        "-50.608",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "24",
        "-50.459",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "24",
        "-50.312",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "24",
        "-50.168",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "24",
        "-49.946",
        "19.692",
        "0.853",
        "1.174",
        "0.060"
    ],
    [
        "-111",
        "24",
        "-46.263",
        "17.886",
        "3.406",
        "2.575",
        "-0.071"
    ],
    [
        "-110",
        "24",
        "-38.861",
        "15.403",
        "2.342",
        "2.050",
        "0.226"
    ],
    [
        "-109",
        "24",
        "-23.083",
        "-0.158",
        "2.838",
        "2.873",
        "-0.312"
    ],
    [
        "-108",
        "24",
        "-8.525",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "24",
        "-8.596",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "24",
        "-8.664",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "24",
        "-8.731",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "24",
        "-8.794",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "24",
        "-8.854",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "24",
        "-8.912",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "24",
        "-8.967",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "24",
        "-9.020",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "24",
        "-9.069",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "24",
        "-9.117",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "24",
        "-9.161",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "24",
        "-9.204",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "24",
        "-9.242",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "24",
        "-9.279",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "24",
        "-9.312",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "24",
        "-9.343",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "24",
        "-9.371",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "24",
        "-9.396",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "24",
        "-9.419",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "24",
        "-9.438",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "24",
        "-9.455",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "24",
        "-9.469",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "24",
        "-9.480",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "24",
        "-9.488",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "24",
        "-9.494",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "24",
        "-9.497",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "24",
        "-9.497",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "24",
        "-9.494",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "24",
        "-9.488",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "24",
        "-9.479",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "24",
        "-9.468",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "24",
        "-9.454",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "24",
        "-9.437",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "24",
        "-9.417",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "24",
        "-9.394",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "24",
        "-9.369",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "24",
        "-9.340",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "24",
        "-9.310",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "24",
        "-9.276",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "24",
        "-9.240",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "24",
        "-9.200",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "24",
        "-9.158",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "24",
        "-9.113",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "24",
        "-9.065",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "24",
        "-9.015",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "24",
        "-8.963",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "24",
        "-8.907",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "24",
        "-8.850",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "24",
        "-8.788",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "24",
        "-8.725",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "24",
        "-8.659",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "24",
        "-8.590",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "24",
        "-8.519",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "24",
        "-8.445",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "24",
        "-8.369",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "24",
        "-8.291",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "24",
        "-8.210",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "24",
        "-8.127",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "24",
        "-8.040",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "24",
        "-7.952",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "24",
        "-7.861",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "24",
        "-7.196",
        "13.100",
        "4.395",
        "2.638",
        "-0.226"
    ],
    [
        "-45",
        "24",
        "5.214",
        "12.053",
        "5.230",
        "2.380",
        "-0.271"
    ],
    [
        "-44",
        "24",
        "15.654",
        "11.567",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "24",
        "15.737",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "24",
        "15.822",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "24",
        "15.909",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "24",
        "15.998",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "24",
        "16.089",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "24",
        "16.180",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "24",
        "16.275",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "24",
        "16.371",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "24",
        "16.469",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "24",
        "16.569",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "24",
        "16.669",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "24",
        "16.772",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "24",
        "16.876",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "24",
        "16.982",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "24",
        "17.090",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "24",
        "17.199",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "24",
        "17.310",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "24",
        "17.421",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "24",
        "17.535",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "24",
        "17.650",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "24",
        "17.766",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "24",
        "17.883",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "24",
        "18.002",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "24",
        "18.122",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "24",
        "18.244",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "24",
        "18.366",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "24",
        "18.488",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "24",
        "18.613",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "24",
        "18.739",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "24",
        "18.865",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "24",
        "18.993",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "24",
        "19.121",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "24",
        "19.251",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "24",
        "19.381",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "24",
        "19.512",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "24",
        "19.644",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "24",
        "19.777",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "24",
        "19.910",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "24",
        "20.044",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "24",
        "20.179",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "24",
        "20.313",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "24",
        "20.449",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "24",
        "20.585",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "24",
        "20.722",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "24",
        "20.860",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "24",
        "20.996",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "24",
        "21.134",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "24",
        "21.272",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "24",
        "21.410",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "24",
        "21.549",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "24",
        "21.687",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "24",
        "21.827",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "24",
        "21.965",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "24",
        "22.104",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "24",
        "22.242",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "24",
        "22.382",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "24",
        "22.520",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "24",
        "22.658",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "24",
        "22.797",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "24",
        "22.935",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "24",
        "23.073",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "24",
        "23.210",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "24",
        "23.348",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "24",
        "23.484",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "24",
        "23.620",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "24",
        "23.757",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "24",
        "23.892",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "24",
        "24.027",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "24",
        "24.161",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "24",
        "24.295",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "24",
        "24.428",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "24",
        "24.560",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "24",
        "24.691",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "24",
        "24.822",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "24",
        "24.952",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "24",
        "25.081",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "24",
        "25.209",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "24",
        "25.336",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "24",
        "25.462",
        "17.685",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "24",
        "25.587",
        "17.537",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "24",
        "30.081",
        "24.013",
        "6.107",
        "9.525",
        "0.038"
    ],
    [
        "38",
        "24",
        "30.617",
        "24.703",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "24",
        "30.793",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "24",
        "30.974",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "24",
        "31.157",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "24",
        "31.344",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "24",
        "31.534",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "24",
        "31.727",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "24",
        "31.922",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "24",
        "32.122",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "24",
        "32.323",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "24",
        "32.528",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "24",
        "32.736",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "24",
        "32.946",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "24",
        "33.158",
        "30.137",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "24",
        "33.373",
        "30.495",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "24",
        "33.591",
        "30.843",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "24",
        "33.811",
        "31.180",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "24",
        "34.033",
        "31.510",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "24",
        "34.259",
        "31.829",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "24",
        "34.485",
        "32.138",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "24",
        "34.714",
        "32.439",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "24",
        "34.946",
        "32.728",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "24",
        "35.180",
        "33.009",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "24",
        "35.414",
        "33.278",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "24",
        "35.652",
        "33.539",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "24",
        "35.891",
        "33.788",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "24",
        "36.042",
        "33.953",
        "0.838",
        "1.327",
        "-0.296"
    ],
    [
        "65",
        "24",
        "36.715",
        "34.555",
        "1.082",
        "1.678",
        "-0.389"
    ],
    [
        "66",
        "24",
        "37.845",
        "33.592",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "24",
        "38.083",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "24",
        "38.323",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "24",
        "38.563",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "24",
        "38.804",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "24",
        "39.046",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "24",
        "39.288",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "24",
        "39.530",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "24",
        "39.773",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "24",
        "40.016",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "24",
        "40.259",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "24",
        "40.502",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "24",
        "40.746",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "24",
        "40.989",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "24",
        "41.232",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "24",
        "41.475",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "24",
        "41.718",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "24",
        "41.960",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "24",
        "42.203",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "24",
        "42.444",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "24",
        "42.686",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "24",
        "42.926",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "24",
        "43.166",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "24",
        "43.404",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "24",
        "43.642",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "24",
        "43.880",
        "33.334",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "24",
        "43.167",
        "31.718",
        "0.862",
        "1.087",
        "-0.047"
    ],
    [
        "93",
        "24",
        "40.590",
        "28.570",
        "1.204",
        "1.216",
        "-0.105"
    ],
    [
        "94",
        "24",
        "37.453",
        "24.622",
        "1.229",
        "1.333",
        "-0.081"
    ],
    [
        "95",
        "24",
        "35.349",
        "18.823",
        "1.290",
        "1.369",
        "-0.055"
    ],
    [
        "96",
        "24",
        "33.129",
        "8.078",
        "1.404",
        "1.332",
        "-0.039"
    ],
    [
        "97",
        "24",
        "30.877",
        "-3.555",
        "1.279",
        "1.350",
        "-0.044"
    ],
    [
        "98",
        "24",
        "30.334",
        "-8.494",
        "1.176",
        "1.247",
        "-0.041"
    ],
    [
        "99",
        "24",
        "31.110",
        "-11.085",
        "1.191",
        "1.158",
        "-0.020"
    ],
    [
        "100",
        "24",
        "32.315",
        "-12.033",
        "1.020",
        "1.100",
        "-0.024"
    ],
    [
        "101",
        "24",
        "33.820",
        "-12.591",
        "0.956",
        "0.973",
        "0.048"
    ],
    [
        "102",
        "24",
        "36.492",
        "-14.609",
        "1.107",
        "1.049",
        "-0.006"
    ],
    [
        "103",
        "24",
        "39.027",
        "-14.104",
        "0.971",
        "0.975",
        "0.015"
    ],
    [
        "104",
        "24",
        "39.621",
        "-12.632",
        "1.003",
        "1.100",
        "-0.033"
    ],
    [
        "105",
        "24",
        "38.547",
        "-11.513",
        "0.900",
        "0.911",
        "-0.035"
    ],
    [
        "106",
        "24",
        "37.448",
        "-11.129",
        "0.174",
        "0.231",
        "0.066"
    ],
    [
        "107",
        "24",
        "37.288",
        "-11.396",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "24",
        "37.206",
        "-11.663",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "24",
        "37.122",
        "-11.926",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "24",
        "37.036",
        "-12.185",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "24",
        "36.950",
        "-12.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "24",
        "36.860",
        "-12.691",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "24",
        "36.769",
        "-12.939",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "24",
        "36.677",
        "-13.182",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "24",
        "36.841",
        "-13.303",
        "0.864",
        "0.956",
        "0.008"
    ],
    [
        "116",
        "24",
        "37.008",
        "-13.380",
        "0.956",
        "1.049",
        "-0.006"
    ],
    [
        "117",
        "24",
        "36.941",
        "-13.757",
        "1.010",
        "0.978",
        "0.014"
    ],
    [
        "118",
        "24",
        "37.206",
        "-14.326",
        "0.922",
        "1.006",
        "-0.069"
    ],
    [
        "119",
        "24",
        "37.160",
        "-13.914",
        "0.991",
        "1.036",
        "0.076"
    ],
    [
        "120",
        "24",
        "36.598",
        "-11.339",
        "1.255",
        "1.131",
        "-0.003"
    ],
    [
        "121",
        "24",
        "32.377",
        "-11.590",
        "2.762",
        "2.774",
        "-0.311"
    ],
    [
        "122",
        "24",
        "16.437",
        "0.006",
        "4.329",
        "6.505",
        "-0.404"
    ],
    [
        "123",
        "24",
        "13.962",
        "-21.191",
        "6.230",
        "7.236",
        "-0.440"
    ],
    [
        "124",
        "24",
        "17.097",
        "-36.514",
        "4.918",
        "5.345",
        "-0.237"
    ],
    [
        "125",
        "24",
        "-6.608",
        "-10.194",
        "6.184",
        "7.813",
        "-0.162"
    ],
    [
        "126",
        "24",
        "-21.676",
        "8.618",
        "6.111",
        "7.337",
        "-0.109"
    ],
    [
        "127",
        "24",
        "-32.673",
        "18.237",
        "4.898",
        "7.008",
        "-0.321"
    ],
    [
        "128",
        "24",
        "-43.999",
        "28.270",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "24",
        "-43.802",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "24",
        "-43.611",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "24",
        "-43.427",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "24",
        "-43.250",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "24",
        "-43.080",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "24",
        "-42.916",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "24",
        "-42.760",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "24",
        "-42.611",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "24",
        "-42.468",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "24",
        "-42.333",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "24",
        "-42.205",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "24",
        "-42.084",
        "16.528",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "24",
        "-41.971",
        "15.509",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "24",
        "-46.143",
        "14.442",
        "6.066",
        "5.373",
        "0.082"
    ],
    [
        "143",
        "24",
        "-57.678",
        "14.304",
        "8.157",
        "7.314",
        "0.069"
    ],
    [
        "144",
        "24",
        "-69.791",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "24",
        "-69.659",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "24",
        "-69.525",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "24",
        "-69.387",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "24",
        "-69.247",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "24",
        "-69.103",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "24",
        "-68.957",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "24",
        "-68.808",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "24",
        "-68.656",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "24",
        "-68.502",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "24",
        "-68.344",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "24",
        "-68.185",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "24",
        "-68.022",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "24",
        "-67.857",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "24",
        "-67.690",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "24",
        "-67.520",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "24",
        "-67.347",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "24",
        "-67.173",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "24",
        "-66.996",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "24",
        "-66.817",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "24",
        "-66.635",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "24",
        "-66.452",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "24",
        "-66.266",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "24",
        "-66.079",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "24",
        "-65.889",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "24",
        "-65.698",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "24",
        "-65.505",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "24",
        "-65.310",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "24",
        "-65.113",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "24",
        "-64.915",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "24",
        "-64.715",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "24",
        "-64.514",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "24",
        "-64.311",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "24",
        "-64.107",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "24",
        "-63.901",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "24",
        "-63.694",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "25",
        "-63.161",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "25",
        "-62.944",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "25",
        "-62.725",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "25",
        "-62.505",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "25",
        "-62.285",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "25",
        "-62.063",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "25",
        "-61.841",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "25",
        "-61.617",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "25",
        "-61.393",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "25",
        "-61.169",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "25",
        "-60.944",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "25",
        "-60.718",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "25",
        "-60.492",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "25",
        "-60.265",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "25",
        "-60.038",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "25",
        "-59.811",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "25",
        "-59.584",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "25",
        "-59.356",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "25",
        "-59.129",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "25",
        "-58.901",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "25",
        "-58.674",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "25",
        "-58.447",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "25",
        "-58.220",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "25",
        "-57.993",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "25",
        "-57.767",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "25",
        "-57.541",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "25",
        "-57.315",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "25",
        "-57.091",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "25",
        "-56.867",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "25",
        "-56.643",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "25",
        "-56.421",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "25",
        "-56.199",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "25",
        "-55.978",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "25",
        "-55.758",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "25",
        "-55.539",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "25",
        "-55.322",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "25",
        "-55.105",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "25",
        "-54.890",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "25",
        "-54.676",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "25",
        "-54.464",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "25",
        "-54.253",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "25",
        "-54.043",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "25",
        "-53.835",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "25",
        "-53.629",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "25",
        "-53.424",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "25",
        "-53.221",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "25",
        "-53.020",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "25",
        "-52.821",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "25",
        "-52.624",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "25",
        "-52.429",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "25",
        "-52.235",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "25",
        "-52.044",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "25",
        "-51.856",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "25",
        "-51.669",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "25",
        "-51.485",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "25",
        "-51.303",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "25",
        "-51.123",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "25",
        "-50.946",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "25",
        "-50.772",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "25",
        "-50.600",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "25",
        "-50.431",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "25",
        "-50.264",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "25",
        "-50.100",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "25",
        "-49.939",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "25",
        "-49.781",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "25",
        "-49.625",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "25",
        "-49.473",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "25",
        "-49.323",
        "20.068",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "25",
        "-46.404",
        "18.700",
        "3.455",
        "3.517",
        "-0.062"
    ],
    [
        "-111",
        "25",
        "-41.763",
        "14.966",
        "3.329",
        "2.816",
        "0.054"
    ],
    [
        "-110",
        "25",
        "-26.326",
        "3.701",
        "2.767",
        "2.850",
        "-0.232"
    ],
    [
        "-109",
        "25",
        "-11.176",
        "-8.788",
        "1.895",
        "1.786",
        "-0.076"
    ],
    [
        "-108",
        "25",
        "-8.848",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "25",
        "-8.922",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "25",
        "-8.992",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "25",
        "-9.061",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "25",
        "-9.126",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "25",
        "-9.190",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "25",
        "-9.250",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "25",
        "-9.307",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "25",
        "-9.362",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "25",
        "-9.413",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "25",
        "-9.462",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "25",
        "-9.509",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "25",
        "-9.552",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "25",
        "-9.593",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "25",
        "-9.631",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "25",
        "-9.665",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "25",
        "-9.697",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "25",
        "-9.727",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "25",
        "-9.753",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "25",
        "-9.776",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "25",
        "-9.796",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "25",
        "-9.814",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "25",
        "-9.829",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "25",
        "-9.840",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "25",
        "-9.850",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "25",
        "-9.855",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "25",
        "-9.858",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "25",
        "-9.858",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "25",
        "-9.854",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "25",
        "-9.849",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "25",
        "-9.840",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "25",
        "-9.828",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "25",
        "-9.813",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "25",
        "-9.795",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "25",
        "-9.775",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "25",
        "-9.751",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "25",
        "-9.724",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "25",
        "-9.696",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "25",
        "-9.663",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "25",
        "-9.628",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "25",
        "-9.590",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "25",
        "-9.549",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "25",
        "-9.505",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "25",
        "-9.459",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "25",
        "-9.410",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "25",
        "-9.357",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "25",
        "-9.302",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "25",
        "-9.245",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "25",
        "-9.184",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "25",
        "-9.121",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "25",
        "-9.056",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "25",
        "-8.987",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "25",
        "-8.916",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "25",
        "-8.842",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "25",
        "-8.765",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "25",
        "-8.686",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "25",
        "-8.605",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "25",
        "-8.520",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "25",
        "-8.433",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "25",
        "-8.345",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "25",
        "-8.253",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "25",
        "-8.159",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "25",
        "-8.062",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "25",
        "15.144",
        "11.291",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "25",
        "15.229",
        "11.567",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "25",
        "15.315",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "25",
        "15.403",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "25",
        "15.493",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "25",
        "15.586",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "25",
        "15.680",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "25",
        "15.777",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "25",
        "15.874",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "25",
        "15.974",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "25",
        "16.075",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "25",
        "16.178",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "25",
        "16.284",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "25",
        "16.391",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "25",
        "16.499",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "25",
        "16.609",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "25",
        "16.721",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "25",
        "16.834",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "25",
        "16.949",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "25",
        "17.065",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "25",
        "17.183",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "25",
        "17.302",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "25",
        "17.423",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "25",
        "17.546",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "25",
        "17.668",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "25",
        "17.793",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "25",
        "17.918",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "25",
        "18.046",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "25",
        "18.174",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "25",
        "18.304",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "25",
        "18.434",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "25",
        "18.566",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "25",
        "18.698",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "25",
        "18.832",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "25",
        "18.966",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "25",
        "19.102",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "25",
        "19.238",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "25",
        "19.375",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "25",
        "19.513",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "25",
        "19.651",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "25",
        "19.790",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "25",
        "19.930",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "25",
        "20.071",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "25",
        "20.211",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "25",
        "20.353",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "25",
        "20.495",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "25",
        "20.637",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "25",
        "20.780",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "25",
        "20.923",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "25",
        "21.066",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "25",
        "21.210",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "25",
        "21.353",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "25",
        "21.498",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "25",
        "21.642",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "25",
        "21.787",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "25",
        "21.930",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "25",
        "22.075",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "25",
        "22.218",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "25",
        "22.363",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "25",
        "22.507",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "25",
        "22.651",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "25",
        "22.794",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "25",
        "22.937",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "25",
        "23.080",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "25",
        "23.223",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "25",
        "23.364",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "25",
        "23.506",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "25",
        "23.647",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "25",
        "23.789",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "25",
        "23.928",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "25",
        "24.069",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "25",
        "24.207",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "25",
        "24.345",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "25",
        "24.482",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "25",
        "24.619",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "25",
        "24.754",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "25",
        "24.890",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "25",
        "25.024",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "25",
        "25.157",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "25",
        "25.288",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "25",
        "25.746",
        "17.902",
        "3.838",
        "6.921",
        "0.098"
    ],
    [
        "36",
        "25",
        "29.501",
        "23.746",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "25",
        "29.679",
        "24.229",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "25",
        "29.859",
        "24.703",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "25",
        "30.042",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "25",
        "30.230",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "25",
        "30.421",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "25",
        "30.615",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "25",
        "30.812",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "25",
        "31.013",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "25",
        "31.216",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "25",
        "31.423",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "25",
        "31.633",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "25",
        "31.844",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "25",
        "32.060",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "25",
        "32.278",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "25",
        "32.499",
        "30.137",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "25",
        "32.723",
        "30.495",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "25",
        "32.949",
        "30.843",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "25",
        "33.178",
        "31.180",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "25",
        "33.409",
        "31.510",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "25",
        "33.643",
        "31.829",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "25",
        "33.879",
        "32.138",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "25",
        "33.732",
        "32.193",
        "0.794",
        "1.175",
        "0.347"
    ],
    [
        "59",
        "25",
        "32.863",
        "29.596",
        "1.240",
        "2.045",
        "0.450"
    ],
    [
        "60",
        "25",
        "32.271",
        "23.523",
        "1.431",
        "2.416",
        "0.343"
    ],
    [
        "61",
        "25",
        "32.792",
        "16.089",
        "1.404",
        "2.220",
        "0.162"
    ],
    [
        "62",
        "25",
        "34.865",
        "13.955",
        "1.485",
        "2.708",
        "-0.058"
    ],
    [
        "63",
        "25",
        "36.769",
        "16.792",
        "1.583",
        "2.555",
        "-0.292"
    ],
    [
        "64",
        "25",
        "37.529",
        "24.172",
        "1.424",
        "2.230",
        "-0.409"
    ],
    [
        "65",
        "25",
        "37.663",
        "30.196",
        "1.278",
        "1.876",
        "-0.404"
    ],
    [
        "66",
        "25",
        "37.756",
        "32.967",
        "1.091",
        "1.397",
        "-0.299"
    ],
    [
        "67",
        "25",
        "37.662",
        "33.706",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "25",
        "37.911",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "25",
        "38.161",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "25",
        "38.412",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "25",
        "38.662",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "25",
        "38.913",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "25",
        "39.166",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "25",
        "39.418",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "25",
        "39.670",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "25",
        "39.923",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "25",
        "40.176",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "25",
        "40.429",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "25",
        "40.682",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "25",
        "40.934",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "25",
        "41.187",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "25",
        "41.439",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "25",
        "41.691",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "25",
        "41.943",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "25",
        "42.194",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "25",
        "42.444",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "25",
        "42.693",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "25",
        "42.942",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "25",
        "43.191",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "25",
        "43.438",
        "33.469",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "25",
        "43.437",
        "32.594",
        "0.690",
        "0.971",
        "-0.056"
    ],
    [
        "92",
        "25",
        "43.389",
        "29.967",
        "1.027",
        "1.432",
        "-0.147"
    ],
    [
        "93",
        "25",
        "42.590",
        "26.641",
        "1.262",
        "1.475",
        "-0.162"
    ],
    [
        "94",
        "25",
        "40.057",
        "23.189",
        "1.266",
        "1.499",
        "-0.147"
    ],
    [
        "95",
        "25",
        "36.988",
        "18.463",
        "1.306",
        "1.471",
        "-0.118"
    ],
    [
        "96",
        "25",
        "34.121",
        "9.478",
        "1.383",
        "1.391",
        "-0.071"
    ],
    [
        "97",
        "25",
        "31.751",
        "-1.746",
        "1.242",
        "1.350",
        "-0.039"
    ],
    [
        "98",
        "25",
        "30.733",
        "-8.897",
        "1.082",
        "1.163",
        "-0.024"
    ],
    [
        "99",
        "25",
        "30.907",
        "-13.290",
        "0.976",
        "0.889",
        "-0.021"
    ],
    [
        "100",
        "25",
        "31.661",
        "-14.013",
        "0.894",
        "1.013",
        "-0.008"
    ],
    [
        "101",
        "25",
        "34.153",
        "-15.467",
        "0.841",
        "0.962",
        "0.017"
    ],
    [
        "102",
        "25",
        "36.506",
        "-17.475",
        "1.048",
        "0.979",
        "-0.009"
    ],
    [
        "103",
        "25",
        "37.099",
        "-16.298",
        "0.807",
        "0.740",
        "-0.007"
    ],
    [
        "104",
        "25",
        "37.957",
        "-12.892",
        "0.963",
        "1.077",
        "-0.028"
    ],
    [
        "105",
        "25",
        "37.664",
        "-11.366",
        "0.760",
        "0.799",
        "-0.020"
    ],
    [
        "106",
        "25",
        "37.382",
        "-11.130",
        "0.150",
        "0.217",
        "-0.002"
    ],
    [
        "107",
        "25",
        "37.286",
        "-11.396",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "25",
        "37.201",
        "-11.663",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "25",
        "37.113",
        "-11.926",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "25",
        "37.025",
        "-12.185",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "25",
        "36.934",
        "-12.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "25",
        "36.841",
        "-12.691",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "25",
        "36.746",
        "-12.939",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "25",
        "36.651",
        "-13.182",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "25",
        "36.665",
        "-13.376",
        "0.655",
        "0.675",
        "0.015"
    ],
    [
        "116",
        "25",
        "36.884",
        "-13.394",
        "0.922",
        "1.023",
        "0.046"
    ],
    [
        "117",
        "25",
        "37.224",
        "-13.578",
        "0.985",
        "0.957",
        "-0.002"
    ],
    [
        "118",
        "25",
        "37.723",
        "-13.815",
        "0.854",
        "0.957",
        "0.035"
    ],
    [
        "119",
        "25",
        "36.882",
        "-13.449",
        "0.806",
        "0.842",
        "-0.035"
    ],
    [
        "120",
        "25",
        "35.719",
        "-12.287",
        "1.144",
        "1.063",
        "-0.008"
    ],
    [
        "121",
        "25",
        "34.879",
        "-10.391",
        "1.177",
        "1.085",
        "-0.092"
    ],
    [
        "122",
        "25",
        "38.964",
        "-20.582",
        "2.612",
        "4.154",
        "-0.203"
    ],
    [
        "123",
        "25",
        "34.052",
        "-30.280",
        "5.744",
        "7.029",
        "-0.288"
    ],
    [
        "124",
        "25",
        "24.307",
        "-32.641",
        "5.378",
        "7.672",
        "-0.170"
    ],
    [
        "125",
        "25",
        "16.617",
        "-26.540",
        "4.754",
        "5.931",
        "-0.094"
    ],
    [
        "126",
        "25",
        "-0.215",
        "-4.833",
        "5.999",
        "7.805",
        "-0.269"
    ],
    [
        "127",
        "25",
        "-15.403",
        "1.427",
        "5.869",
        "8.464",
        "-0.183"
    ],
    [
        "128",
        "25",
        "-33.242",
        "15.885",
        "4.020",
        "6.147",
        "-0.142"
    ],
    [
        "129",
        "25",
        "-42.423",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "25",
        "-42.225",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "25",
        "-42.034",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "25",
        "-41.850",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "25",
        "-41.673",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "25",
        "-41.503",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "25",
        "-41.341",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "25",
        "-41.186",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "25",
        "-41.038",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "25",
        "-40.897",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "25",
        "-40.765",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "25",
        "-40.638",
        "16.528",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "25",
        "-40.521",
        "15.509",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "25",
        "-44.917",
        "14.451",
        "6.424",
        "6.179",
        "-0.218"
    ],
    [
        "143",
        "25",
        "-57.985",
        "16.258",
        "8.682",
        "8.290",
        "-0.089"
    ],
    [
        "144",
        "25",
        "-69.712",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "25",
        "-69.576",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "25",
        "-69.436",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "25",
        "-69.293",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "25",
        "-69.147",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "25",
        "-68.998",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "25",
        "-68.846",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "25",
        "-68.691",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "25",
        "-68.533",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "25",
        "-68.373",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "25",
        "-68.209",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "25",
        "-68.043",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "25",
        "-67.874",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "25",
        "-67.703",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "25",
        "-67.529",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "25",
        "-67.352",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "25",
        "-67.173",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "25",
        "-66.992",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "25",
        "-66.808",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "25",
        "-66.622",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "25",
        "-66.433",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "25",
        "-66.243",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "25",
        "-66.050",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "25",
        "-65.855",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "25",
        "-65.658",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "25",
        "-65.460",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "25",
        "-65.259",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "25",
        "-65.056",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "25",
        "-64.852",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "25",
        "-64.646",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "25",
        "-64.438",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "25",
        "-64.229",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "25",
        "-64.018",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "25",
        "-63.806",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "25",
        "-63.592",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "25",
        "-63.377",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "26",
        "-62.817",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "26",
        "-62.591",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "26",
        "-62.365",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "26",
        "-62.137",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "26",
        "-61.908",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "26",
        "-61.678",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "26",
        "-61.447",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "26",
        "-61.216",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "26",
        "-60.983",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "26",
        "-60.750",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "26",
        "-60.517",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "26",
        "-60.283",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "26",
        "-60.048",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "26",
        "-59.813",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "26",
        "-59.578",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "26",
        "-59.342",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "26",
        "-59.106",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "26",
        "-58.870",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "26",
        "-58.634",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "26",
        "-58.398",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "26",
        "-58.162",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "26",
        "-57.927",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "26",
        "-57.691",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "26",
        "-57.456",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "26",
        "-57.221",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "26",
        "-56.987",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "26",
        "-56.753",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "26",
        "-56.520",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "26",
        "-56.288",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "26",
        "-56.056",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "26",
        "-55.825",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "26",
        "-55.595",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "26",
        "-55.366",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "26",
        "-55.138",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "26",
        "-54.911",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "26",
        "-54.685",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "26",
        "-54.461",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "26",
        "-54.238",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "26",
        "-54.016",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "26",
        "-53.795",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "26",
        "-53.576",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "26",
        "-53.359",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "26",
        "-53.143",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "26",
        "-52.929",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "26",
        "-52.717",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "26",
        "-52.506",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "26",
        "-52.298",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "26",
        "-52.091",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "26",
        "-51.887",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "26",
        "-51.684",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "26",
        "-51.484",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "26",
        "-51.286",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "26",
        "-51.090",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "26",
        "-50.897",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "26",
        "-50.705",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "26",
        "-50.517",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "26",
        "-50.331",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "26",
        "-50.147",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "26",
        "-49.966",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "26",
        "-49.788",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "26",
        "-49.612",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "26",
        "-49.439",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "26",
        "-49.269",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "26",
        "-49.102",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "26",
        "-48.938",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "26",
        "-48.777",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "26",
        "-48.618",
        "20.474",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "26",
        "-46.901",
        "18.847",
        "2.962",
        "3.401",
        "-0.145"
    ],
    [
        "-112",
        "26",
        "-42.722",
        "15.350",
        "3.065",
        "3.395",
        "-0.149"
    ],
    [
        "-111",
        "26",
        "-31.857",
        "8.375",
        "2.938",
        "2.557",
        "0.005"
    ],
    [
        "-110",
        "26",
        "-13.336",
        "-7.250",
        "2.205",
        "2.497",
        "-0.241"
    ],
    [
        "-109",
        "26",
        "-9.089",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "26",
        "-9.168",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "26",
        "-9.244",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "26",
        "-9.318",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "26",
        "-9.389",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "26",
        "-9.457",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "26",
        "-9.523",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "26",
        "-9.584",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "26",
        "-9.645",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "26",
        "-9.701",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "26",
        "-9.755",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "26",
        "-9.805",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "26",
        "-9.854",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "26",
        "-9.899",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "26",
        "-9.941",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "26",
        "-9.980",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "26",
        "-10.017",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "26",
        "-10.050",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "26",
        "-10.079",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "26",
        "-10.107",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "26",
        "-10.131",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "26",
        "-10.152",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "26",
        "-10.170",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "26",
        "-10.185",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "26",
        "-10.197",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "26",
        "-10.207",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "26",
        "-10.212",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "26",
        "-10.215",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "26",
        "-10.215",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "26",
        "-10.212",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "26",
        "-10.205",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "26",
        "-10.196",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "26",
        "-10.184",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "26",
        "-10.168",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "26",
        "-10.151",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "26",
        "-10.129",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "26",
        "-10.105",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "26",
        "-10.077",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "26",
        "-10.047",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "26",
        "-10.013",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "26",
        "-9.977",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "26",
        "-9.938",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "26",
        "-9.895",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "26",
        "-9.849",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "26",
        "-9.802",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "26",
        "-9.750",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "26",
        "-9.697",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "26",
        "-9.639",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "26",
        "-9.580",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "26",
        "-9.517",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "26",
        "-9.451",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "26",
        "-9.383",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "26",
        "-9.312",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "26",
        "-9.239",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "26",
        "-9.161",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "26",
        "-9.082",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "26",
        "-9.000",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "26",
        "-8.916",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "26",
        "-8.828",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "26",
        "-8.738",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "26",
        "-8.646",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "26",
        "-8.550",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "26",
        "-8.453",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "26",
        "-8.352",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "26",
        "2.613",
        "12.586",
        "4.086",
        "2.160",
        "-0.205"
    ],
    [
        "-44",
        "26",
        "14.799",
        "11.567",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "26",
        "14.888",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "26",
        "14.980",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "26",
        "15.074",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "26",
        "15.169",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "26",
        "15.267",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "26",
        "15.366",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "26",
        "15.468",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "26",
        "15.572",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "26",
        "15.677",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "26",
        "15.784",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "26",
        "15.893",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "26",
        "16.004",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "26",
        "16.116",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "26",
        "16.230",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "26",
        "16.347",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "26",
        "16.464",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "26",
        "16.584",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "26",
        "16.704",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "26",
        "16.826",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "26",
        "16.950",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "26",
        "17.075",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "26",
        "17.201",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "26",
        "17.329",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "26",
        "17.458",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "26",
        "17.589",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "26",
        "17.721",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "26",
        "17.854",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "26",
        "17.988",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "26",
        "18.124",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "26",
        "18.260",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "26",
        "18.397",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "26",
        "18.536",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "26",
        "18.676",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "26",
        "18.816",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "26",
        "18.958",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "26",
        "19.100",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "26",
        "19.242",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "26",
        "19.385",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "26",
        "19.530",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "26",
        "19.676",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "26",
        "19.821",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "26",
        "19.967",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "26",
        "20.114",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "26",
        "20.261",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "26",
        "20.409",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "26",
        "20.557",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "26",
        "20.705",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "26",
        "20.854",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "26",
        "21.003",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "26",
        "21.152",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "26",
        "21.301",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "26",
        "21.451",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "26",
        "21.601",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "26",
        "21.750",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "26",
        "21.900",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "26",
        "22.050",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "26",
        "22.199",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "26",
        "22.348",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "26",
        "22.498",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "26",
        "22.646",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "26",
        "22.795",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "26",
        "22.943",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "26",
        "23.091",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "26",
        "23.238",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "26",
        "23.385",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "26",
        "23.532",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "26",
        "23.678",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "26",
        "23.823",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "26",
        "23.968",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "26",
        "24.112",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "26",
        "24.255",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "26",
        "24.398",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "26",
        "24.539",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "26",
        "24.680",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "26",
        "24.820",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "26",
        "24.959",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "26",
        "25.097",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "26",
        "25.749",
        "18.251",
        "8.012",
        "11.249",
        "0.496"
    ],
    [
        "35",
        "26",
        "27.764",
        "21.837",
        "8.356",
        "12.489",
        "0.467"
    ],
    [
        "36",
        "26",
        "28.722",
        "23.746",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "26",
        "28.904",
        "24.229",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "26",
        "29.091",
        "24.703",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "26",
        "29.283",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "26",
        "29.477",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "26",
        "29.675",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "26",
        "29.876",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "26",
        "30.081",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "26",
        "30.289",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "26",
        "30.499",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "26",
        "30.714",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "26",
        "30.931",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "26",
        "31.152",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "26",
        "31.375",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "26",
        "31.602",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "26",
        "31.831",
        "30.137",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "26",
        "32.063",
        "30.495",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "26",
        "32.297",
        "30.843",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "26",
        "32.535",
        "31.180",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "26",
        "32.774",
        "31.510",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "26",
        "33.017",
        "31.829",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "26",
        "33.262",
        "32.138",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "26",
        "33.298",
        "29.736",
        "0.774",
        "1.094",
        "0.182"
    ],
    [
        "59",
        "26",
        "32.650",
        "22.229",
        "1.202",
        "1.944",
        "0.241"
    ],
    [
        "60",
        "26",
        "32.549",
        "16.042",
        "1.372",
        "2.050",
        "0.221"
    ],
    [
        "61",
        "26",
        "32.688",
        "11.571",
        "1.329",
        "2.175",
        "0.057"
    ],
    [
        "62",
        "26",
        "33.191",
        "8.616",
        "1.341",
        "2.068",
        "-0.114"
    ],
    [
        "63",
        "26",
        "34.312",
        "9.630",
        "1.430",
        "1.959",
        "-0.251"
    ],
    [
        "64",
        "26",
        "35.892",
        "15.171",
        "1.343",
        "1.962",
        "-0.276"
    ],
    [
        "65",
        "26",
        "37.402",
        "22.538",
        "1.283",
        "1.817",
        "-0.258"
    ],
    [
        "66",
        "26",
        "38.087",
        "28.529",
        "1.218",
        "1.495",
        "-0.212"
    ],
    [
        "67",
        "26",
        "37.943",
        "32.211",
        "0.873",
        "1.140",
        "-0.179"
    ],
    [
        "68",
        "26",
        "37.488",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "26",
        "37.747",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "26",
        "38.007",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "26",
        "38.267",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "26",
        "38.527",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "26",
        "38.789",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "26",
        "39.050",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "26",
        "39.313",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "26",
        "39.575",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "26",
        "39.837",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "26",
        "40.099",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "26",
        "40.361",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "26",
        "40.623",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "26",
        "40.886",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "26",
        "41.147",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "26",
        "41.409",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "26",
        "41.670",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "26",
        "41.930",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "26",
        "42.189",
        "33.904",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "26",
        "42.448",
        "33.811",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "26",
        "42.706",
        "33.707",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "26",
        "42.964",
        "33.593",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "26",
        "43.076",
        "32.696",
        "0.979",
        "1.496",
        "0.010"
    ],
    [
        "91",
        "26",
        "43.082",
        "30.696",
        "1.039",
        "1.762",
        "-0.032"
    ],
    [
        "92",
        "26",
        "43.635",
        "27.705",
        "1.181",
        "1.841",
        "-0.144"
    ],
    [
        "93",
        "26",
        "43.930",
        "24.445",
        "1.371",
        "1.814",
        "-0.209"
    ],
    [
        "94",
        "26",
        "43.079",
        "21.065",
        "1.350",
        "1.758",
        "-0.195"
    ],
    [
        "95",
        "26",
        "40.596",
        "16.890",
        "1.357",
        "1.642",
        "-0.155"
    ],
    [
        "96",
        "26",
        "37.905",
        "9.349",
        "1.384",
        "1.482",
        "-0.072"
    ],
    [
        "97",
        "26",
        "35.847",
        "-0.310",
        "1.226",
        "1.378",
        "-0.004"
    ],
    [
        "98",
        "26",
        "34.279",
        "-7.850",
        "1.118",
        "1.218",
        "0.030"
    ],
    [
        "99",
        "26",
        "32.758",
        "-13.512",
        "1.039",
        "0.998",
        "0.000"
    ],
    [
        "100",
        "26",
        "32.781",
        "-17.293",
        "0.784",
        "0.842",
        "0.057"
    ],
    [
        "101",
        "26",
        "35.789",
        "-18.821",
        "0.891",
        "0.971",
        "-0.012"
    ],
    [
        "102",
        "26",
        "36.904",
        "-18.296",
        "1.089",
        "1.060",
        "0.005"
    ],
    [
        "103",
        "26",
        "36.942",
        "-15.987",
        "1.003",
        "1.090",
        "-0.018"
    ],
    [
        "104",
        "26",
        "37.000",
        "-12.645",
        "0.948",
        "1.069",
        "-0.034"
    ],
    [
        "105",
        "26",
        "37.307",
        "-11.183",
        "0.719",
        "0.729",
        "0.000"
    ],
    [
        "106",
        "26",
        "37.359",
        "-11.126",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "26",
        "37.272",
        "-11.396",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "26",
        "37.184",
        "-11.663",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "26",
        "37.094",
        "-11.926",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "26",
        "37.002",
        "-12.185",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "26",
        "36.907",
        "-12.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "26",
        "36.812",
        "-12.691",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "26",
        "36.713",
        "-12.939",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "26",
        "36.613",
        "-13.182",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "26",
        "36.583",
        "-13.394",
        "0.637",
        "0.659",
        "0.002"
    ],
    [
        "116",
        "26",
        "36.734",
        "-13.549",
        "0.933",
        "1.031",
        "0.021"
    ],
    [
        "117",
        "26",
        "36.917",
        "-13.598",
        "1.049",
        "1.034",
        "0.015"
    ],
    [
        "118",
        "26",
        "36.989",
        "-13.291",
        "0.921",
        "1.013",
        "0.003"
    ],
    [
        "119",
        "26",
        "37.165",
        "-13.173",
        "0.896",
        "0.965",
        "0.021"
    ],
    [
        "120",
        "26",
        "35.999",
        "-13.171",
        "1.045",
        "0.855",
        "-0.008"
    ],
    [
        "121",
        "26",
        "35.508",
        "-13.033",
        "1.220",
        "1.177",
        "-0.033"
    ],
    [
        "122",
        "26",
        "35.443",
        "-13.626",
        "1.513",
        "1.538",
        "0.008"
    ],
    [
        "123",
        "26",
        "34.821",
        "-14.600",
        "2.259",
        "3.312",
        "0.084"
    ],
    [
        "124",
        "26",
        "29.979",
        "-14.990",
        "4.109",
        "6.829",
        "-0.130"
    ],
    [
        "125",
        "26",
        "23.204",
        "-13.852",
        "4.908",
        "7.669",
        "-0.110"
    ],
    [
        "126",
        "26",
        "19.428",
        "-15.568",
        "5.751",
        "7.490",
        "-0.158"
    ],
    [
        "127",
        "26",
        "18.833",
        "-23.820",
        "5.178",
        "6.054",
        "-0.216"
    ],
    [
        "128",
        "26",
        "20.690",
        "-29.137",
        "4.021",
        "5.009",
        "0.150"
    ],
    [
        "129",
        "26",
        "-18.071",
        "14.668",
        "7.163",
        "6.314",
        "-0.212"
    ],
    [
        "130",
        "26",
        "-40.826",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "26",
        "-40.629",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "26",
        "-40.437",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "26",
        "-40.254",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "26",
        "-40.077",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "26",
        "-39.909",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "26",
        "-39.748",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "26",
        "-39.595",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "26",
        "-39.448",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "26",
        "-39.311",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "26",
        "-39.180",
        "16.528",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "26",
        "-40.307",
        "14.911",
        "7.457",
        "6.053",
        "-0.390"
    ],
    [
        "142",
        "26",
        "-45.401",
        "11.048",
        "8.317",
        "7.274",
        "-0.273"
    ],
    [
        "143",
        "26",
        "-57.686",
        "16.393",
        "8.788",
        "8.216",
        "-0.176"
    ],
    [
        "144",
        "26",
        "-69.612",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "26",
        "-69.470",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "26",
        "-69.325",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "26",
        "-69.177",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "26",
        "-69.026",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "26",
        "-68.871",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "26",
        "-68.714",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "26",
        "-68.553",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "26",
        "-68.389",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "26",
        "-68.223",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "26",
        "-68.053",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "26",
        "-67.881",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "26",
        "-67.706",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "26",
        "-67.528",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "26",
        "-67.347",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "26",
        "-67.164",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "26",
        "-66.979",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "26",
        "-66.790",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "26",
        "-66.600",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "26",
        "-66.407",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "26",
        "-66.211",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "26",
        "-66.013",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "26",
        "-65.814",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "26",
        "-65.611",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "26",
        "-65.407",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "26",
        "-65.201",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "26",
        "-64.993",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "26",
        "-64.783",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "26",
        "-64.571",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "26",
        "-64.357",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "26",
        "-64.142",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "26",
        "-63.925",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "26",
        "-63.706",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "26",
        "-63.486",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "26",
        "-63.264",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "26",
        "-63.041",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "27",
        "-62.454",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "27",
        "-62.220",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "27",
        "-61.985",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "27",
        "-61.749",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "27",
        "-61.512",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "27",
        "-61.274",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "27",
        "-61.035",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "27",
        "-60.795",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "27",
        "-60.555",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "27",
        "-60.313",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "27",
        "-60.072",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "27",
        "-59.829",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "27",
        "-59.586",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "27",
        "-59.343",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "27",
        "-59.099",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "27",
        "-58.855",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "27",
        "-58.611",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "27",
        "-58.366",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "27",
        "-58.122",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "27",
        "-57.877",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "27",
        "-57.633",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "27",
        "-57.389",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "27",
        "-57.145",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "27",
        "-56.902",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "27",
        "-56.659",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "27",
        "-56.416",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "27",
        "-56.174",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "27",
        "-55.933",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "27",
        "-55.692",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "27",
        "-55.452",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "27",
        "-55.213",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "27",
        "-54.975",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "27",
        "-54.737",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "27",
        "-54.501",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "27",
        "-54.266",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "27",
        "-54.032",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "27",
        "-53.800",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "27",
        "-53.569",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "27",
        "-53.339",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "27",
        "-53.111",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "27",
        "-52.884",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "27",
        "-52.659",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "27",
        "-52.435",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "27",
        "-52.214",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "27",
        "-51.994",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "27",
        "-51.776",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "27",
        "-51.560",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "27",
        "-51.346",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "27",
        "-51.134",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "27",
        "-50.924",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "27",
        "-50.717",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "27",
        "-50.512",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "27",
        "-50.309",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "27",
        "-50.109",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "27",
        "-49.911",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "27",
        "-49.715",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "27",
        "-49.522",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "27",
        "-49.332",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "27",
        "-49.145",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "27",
        "-48.960",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "27",
        "-48.778",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "27",
        "-48.599",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "27",
        "-48.423",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "27",
        "-48.250",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "27",
        "-48.080",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "27",
        "-47.913",
        "20.873",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "27",
        "-46.252",
        "20.301",
        "2.595",
        "2.523",
        "-0.167"
    ],
    [
        "-113",
        "27",
        "-42.347",
        "17.212",
        "2.882",
        "3.302",
        "-0.224"
    ],
    [
        "-112",
        "27",
        "-35.512",
        "10.915",
        "2.276",
        "2.685",
        "0.063"
    ],
    [
        "-111",
        "27",
        "-13.965",
        "-8.317",
        "2.383",
        "2.132",
        "-0.200"
    ],
    [
        "-110",
        "27",
        "-9.318",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "27",
        "-9.403",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "27",
        "-9.485",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "27",
        "-9.565",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "27",
        "-9.641",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "27",
        "-9.714",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "27",
        "-9.784",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "27",
        "-9.852",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "27",
        "-9.917",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "27",
        "-9.979",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "27",
        "-10.037",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "27",
        "-10.093",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "27",
        "-10.146",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "27",
        "-10.195",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "27",
        "-10.241",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "27",
        "-10.286",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "27",
        "-10.326",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "27",
        "-10.364",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "27",
        "-10.398",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "27",
        "-10.429",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "27",
        "-10.457",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "27",
        "-10.482",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "27",
        "-10.504",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "27",
        "-10.523",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "27",
        "-10.538",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "27",
        "-10.551",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "27",
        "-10.561",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "27",
        "-10.567",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "27",
        "-10.570",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "27",
        "-10.569",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "27",
        "-10.566",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "27",
        "-10.560",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "27",
        "-10.550",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "27",
        "-10.537",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "27",
        "-10.522",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "27",
        "-10.503",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "27",
        "-10.481",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "27",
        "-10.455",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "27",
        "-10.427",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "27",
        "-10.395",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "27",
        "-10.361",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "27",
        "-10.323",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "27",
        "-10.282",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "27",
        "-10.239",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "27",
        "-10.192",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "27",
        "-10.141",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "27",
        "-10.089",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "27",
        "-10.033",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "27",
        "-9.974",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "27",
        "-9.911",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "27",
        "-9.846",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "27",
        "-9.779",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "27",
        "-9.708",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "27",
        "-9.635",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "27",
        "-9.558",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "27",
        "-9.479",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "27",
        "-9.396",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "27",
        "-9.311",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "27",
        "-9.223",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "27",
        "-9.133",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "27",
        "-9.040",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "27",
        "-8.944",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "27",
        "-8.845",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "27",
        "-8.744",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "27",
        "-8.641",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "27",
        "-8.534",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "27",
        "12.238",
        "12.365",
        "0.844",
        "0.985",
        "-0.049"
    ],
    [
        "-43",
        "27",
        "14.457",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "27",
        "14.551",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "27",
        "14.649",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "27",
        "14.748",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "27",
        "14.849",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "27",
        "14.953",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "27",
        "15.058",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "27",
        "15.164",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "27",
        "15.274",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "27",
        "15.385",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "27",
        "15.498",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "27",
        "15.612",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "27",
        "15.729",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "27",
        "15.847",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "27",
        "15.968",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "27",
        "16.089",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "27",
        "16.213",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "27",
        "16.337",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "27",
        "16.464",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "27",
        "16.592",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "27",
        "16.722",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "27",
        "16.853",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "27",
        "16.985",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "27",
        "17.119",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "27",
        "17.254",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "27",
        "17.391",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "27",
        "17.529",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "27",
        "17.667",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "27",
        "17.808",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "27",
        "17.949",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "27",
        "18.091",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "27",
        "18.235",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "27",
        "18.379",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "27",
        "18.525",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "27",
        "18.671",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "27",
        "18.819",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "27",
        "18.966",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "27",
        "19.115",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "27",
        "19.264",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "27",
        "19.415",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "27",
        "19.566",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "27",
        "19.717",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "27",
        "19.868",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "27",
        "20.021",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "27",
        "20.174",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "27",
        "20.328",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "27",
        "20.482",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "27",
        "20.636",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "27",
        "20.790",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "27",
        "20.945",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "27",
        "21.099",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "27",
        "21.253",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "27",
        "21.409",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "27",
        "21.564",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "27",
        "21.718",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "27",
        "21.873",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "27",
        "22.028",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "27",
        "22.183",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "27",
        "22.337",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "27",
        "22.491",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "27",
        "22.646",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "27",
        "22.798",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "27",
        "22.952",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "27",
        "23.105",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "27",
        "23.257",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "27",
        "23.409",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "27",
        "23.559",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "27",
        "23.710",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "27",
        "23.860",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "27",
        "24.010",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "27",
        "24.157",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "27",
        "24.305",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "27",
        "24.451",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "27",
        "24.597",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "27",
        "24.742",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "27",
        "24.887",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "27",
        "25.030",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "27",
        "25.690",
        "18.658",
        "7.609",
        "10.133",
        "0.592"
    ],
    [
        "35",
        "27",
        "27.460",
        "22.765",
        "7.934",
        "10.536",
        "0.594"
    ],
    [
        "36",
        "27",
        "27.932",
        "23.746",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "27",
        "28.122",
        "24.229",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "27",
        "28.316",
        "24.703",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "27",
        "28.514",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "27",
        "28.715",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "27",
        "28.920",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "27",
        "29.128",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "27",
        "29.340",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "27",
        "29.555",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "27",
        "29.774",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "27",
        "29.996",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "27",
        "30.221",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "27",
        "30.450",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "27",
        "30.681",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "27",
        "30.915",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "27",
        "31.152",
        "30.137",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "27",
        "31.392",
        "30.495",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "27",
        "31.521",
        "30.571",
        "0.644",
        "1.663",
        "0.036"
    ],
    [
        "54",
        "27",
        "31.082",
        "28.923",
        "2.087",
        "1.872",
        "0.008"
    ],
    [
        "55",
        "27",
        "32.157",
        "30.191",
        "3.237",
        "7.349",
        "0.030"
    ],
    [
        "56",
        "27",
        "33.479",
        "33.035",
        "2.989",
        "6.715",
        "0.119"
    ],
    [
        "57",
        "27",
        "34.593",
        "31.448",
        "1.301",
        "1.635",
        "0.295"
    ],
    [
        "58",
        "27",
        "35.891",
        "25.812",
        "1.252",
        "1.904",
        "0.081"
    ],
    [
        "59",
        "27",
        "36.124",
        "19.616",
        "1.341",
        "2.057",
        "0.103"
    ],
    [
        "60",
        "27",
        "35.760",
        "14.391",
        "1.520",
        "2.228",
        "0.153"
    ],
    [
        "61",
        "27",
        "34.775",
        "9.331",
        "1.537",
        "2.213",
        "0.083"
    ],
    [
        "62",
        "27",
        "32.545",
        "5.942",
        "1.258",
        "1.737",
        "-0.130"
    ],
    [
        "63",
        "27",
        "30.689",
        "6.029",
        "1.021",
        "1.256",
        "-0.186"
    ],
    [
        "64",
        "27",
        "31.019",
        "8.745",
        "1.030",
        "1.394",
        "-0.122"
    ],
    [
        "65",
        "27",
        "34.077",
        "15.864",
        "1.221",
        "1.534",
        "-0.020"
    ],
    [
        "66",
        "27",
        "36.406",
        "24.437",
        "1.322",
        "1.438",
        "-0.026"
    ],
    [
        "67",
        "27",
        "36.939",
        "30.920",
        "1.020",
        "1.184",
        "-0.018"
    ],
    [
        "68",
        "27",
        "37.053",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "27",
        "37.322",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "27",
        "37.591",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "27",
        "37.860",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "27",
        "38.130",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "27",
        "38.401",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "27",
        "38.672",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "27",
        "38.943",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "27",
        "39.215",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "27",
        "39.486",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "27",
        "39.758",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "27",
        "40.029",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "27",
        "40.300",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "27",
        "40.572",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "27",
        "40.842",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "27",
        "41.114",
        "34.123",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "27",
        "41.384",
        "34.060",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "27",
        "41.653",
        "33.988",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "27",
        "41.651",
        "33.345",
        "0.287",
        "0.621",
        "0.119"
    ],
    [
        "87",
        "27",
        "41.099",
        "27.166",
        "1.201",
        "2.020",
        "0.166"
    ],
    [
        "88",
        "27",
        "42.756",
        "25.779",
        "1.088",
        "2.053",
        "0.032"
    ],
    [
        "89",
        "27",
        "43.144",
        "26.151",
        "1.032",
        "1.996",
        "0.005"
    ],
    [
        "90",
        "27",
        "43.674",
        "22.651",
        "1.120",
        "1.982",
        "-0.085"
    ],
    [
        "91",
        "27",
        "44.969",
        "20.057",
        "1.152",
        "1.951",
        "-0.152"
    ],
    [
        "92",
        "27",
        "45.969",
        "17.473",
        "1.275",
        "2.022",
        "-0.167"
    ],
    [
        "93",
        "27",
        "46.501",
        "15.550",
        "1.466",
        "2.025",
        "-0.227"
    ],
    [
        "94",
        "27",
        "45.880",
        "15.637",
        "1.447",
        "1.963",
        "-0.205"
    ],
    [
        "95",
        "27",
        "44.519",
        "14.487",
        "1.434",
        "1.823",
        "-0.146"
    ],
    [
        "96",
        "27",
        "42.701",
        "7.837",
        "1.428",
        "1.613",
        "-0.033"
    ],
    [
        "97",
        "27",
        "40.424",
        "-0.707",
        "1.238",
        "1.448",
        "0.043"
    ],
    [
        "98",
        "27",
        "38.522",
        "-7.450",
        "1.133",
        "1.264",
        "0.069"
    ],
    [
        "99",
        "27",
        "36.965",
        "-13.295",
        "1.069",
        "1.044",
        "0.061"
    ],
    [
        "100",
        "27",
        "35.242",
        "-18.880",
        "0.822",
        "0.848",
        "0.047"
    ],
    [
        "101",
        "27",
        "36.525",
        "-19.963",
        "0.903",
        "0.983",
        "-0.062"
    ],
    [
        "102",
        "27",
        "37.454",
        "-18.557",
        "1.095",
        "1.059",
        "0.003"
    ],
    [
        "103",
        "27",
        "37.132",
        "-14.910",
        "1.009",
        "1.136",
        "-0.011"
    ],
    [
        "104",
        "27",
        "36.977",
        "-11.811",
        "0.852",
        "0.981",
        "-0.030"
    ],
    [
        "105",
        "27",
        "37.424",
        "-10.853",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "27",
        "37.337",
        "-11.126",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "27",
        "37.247",
        "-11.396",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "27",
        "37.156",
        "-11.663",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "27",
        "37.063",
        "-11.926",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "27",
        "36.967",
        "-12.185",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "27",
        "36.869",
        "-12.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "27",
        "36.770",
        "-12.691",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "27",
        "36.668",
        "-12.939",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "27",
        "36.565",
        "-13.182",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "27",
        "36.449",
        "-13.498",
        "0.622",
        "0.641",
        "-0.002"
    ],
    [
        "116",
        "27",
        "36.547",
        "-13.559",
        "0.938",
        "1.035",
        "0.015"
    ],
    [
        "117",
        "27",
        "36.529",
        "-13.613",
        "1.069",
        "1.056",
        "0.033"
    ],
    [
        "118",
        "27",
        "36.346",
        "-13.560",
        "0.946",
        "1.034",
        "0.060"
    ],
    [
        "119",
        "27",
        "36.157",
        "-13.276",
        "0.976",
        "1.050",
        "-0.042"
    ],
    [
        "120",
        "27",
        "35.758",
        "-13.367",
        "1.150",
        "1.086",
        "-0.068"
    ],
    [
        "121",
        "27",
        "35.402",
        "-13.652",
        "1.189",
        "1.205",
        "-0.081"
    ],
    [
        "122",
        "27",
        "35.145",
        "-13.868",
        "1.332",
        "1.341",
        "-0.085"
    ],
    [
        "123",
        "27",
        "34.765",
        "-13.851",
        "1.657",
        "1.653",
        "-0.014"
    ],
    [
        "124",
        "27",
        "34.271",
        "-13.637",
        "2.485",
        "3.833",
        "-0.041"
    ],
    [
        "125",
        "27",
        "33.636",
        "-14.488",
        "3.462",
        "5.394",
        "-0.018"
    ],
    [
        "126",
        "27",
        "32.660",
        "-17.630",
        "4.811",
        "6.225",
        "-0.008"
    ],
    [
        "127",
        "27",
        "29.496",
        "-25.554",
        "5.541",
        "6.271",
        "-0.108"
    ],
    [
        "128",
        "27",
        "27.416",
        "-29.321",
        "3.844",
        "3.628",
        "-0.102"
    ],
    [
        "129",
        "27",
        "-39.628",
        "27.331",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "27",
        "-39.415",
        "26.385",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "27",
        "-39.210",
        "25.431",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "27",
        "-39.012",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "27",
        "-38.822",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "27",
        "-38.640",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "27",
        "-38.465",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "27",
        "-38.298",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "27",
        "-38.140",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "27",
        "-37.989",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "27",
        "-37.846",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "27",
        "-37.710",
        "16.528",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "27",
        "-38.973",
        "14.193",
        "8.010",
        "5.723",
        "-0.289"
    ],
    [
        "142",
        "27",
        "-39.072",
        "10.464",
        "4.308",
        "4.240",
        "-0.228"
    ],
    [
        "143",
        "27",
        "-60.905",
        "16.136",
        "6.494",
        "6.157",
        "-0.195"
    ],
    [
        "144",
        "27",
        "-69.491",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "27",
        "-69.344",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "27",
        "-69.194",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "27",
        "-69.041",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "27",
        "-68.884",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "27",
        "-68.724",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "27",
        "-68.561",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "27",
        "-68.394",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "27",
        "-68.225",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "27",
        "-68.052",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "27",
        "-67.877",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "27",
        "-67.698",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "27",
        "-67.517",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "27",
        "-67.333",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "27",
        "-67.146",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "27",
        "-66.956",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "27",
        "-66.764",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "27",
        "-66.569",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "27",
        "-66.371",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "27",
        "-66.171",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "27",
        "-65.969",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "27",
        "-65.764",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "27",
        "-65.557",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "27",
        "-65.348",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "27",
        "-65.136",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "27",
        "-64.923",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "27",
        "-64.707",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "27",
        "-64.490",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "27",
        "-64.270",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "27",
        "-64.049",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "27",
        "-63.826",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "27",
        "-63.601",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "27",
        "-63.374",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "27",
        "-63.146",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "27",
        "-62.917",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "27",
        "-62.686",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "28",
        "-62.071",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "28",
        "-61.830",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "28",
        "-61.587",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "28",
        "-61.343",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "28",
        "-61.098",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "28",
        "-60.852",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "28",
        "-60.605",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "28",
        "-60.357",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "28",
        "-60.108",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "28",
        "-59.858",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "28",
        "-59.608",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "28",
        "-59.357",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "28",
        "-59.106",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "28",
        "-58.854",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "28",
        "-58.602",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "28",
        "-58.350",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "28",
        "-58.097",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "28",
        "-57.845",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "28",
        "-57.592",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "28",
        "-57.339",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "28",
        "-57.087",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "28",
        "-56.834",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "28",
        "-56.582",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "28",
        "-56.330",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "28",
        "-56.079",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "28",
        "-55.828",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "28",
        "-55.578",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "28",
        "-55.328",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "28",
        "-55.079",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "28",
        "-54.831",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "28",
        "-54.584",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "28",
        "-54.337",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "28",
        "-54.092",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "28",
        "-53.848",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "28",
        "-53.605",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "28",
        "-53.363",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "28",
        "-53.122",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "28",
        "-52.883",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "28",
        "-52.646",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "28",
        "-52.410",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "28",
        "-52.175",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "28",
        "-51.942",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "28",
        "-51.711",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "28",
        "-51.482",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "28",
        "-51.255",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "28",
        "-51.029",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "28",
        "-50.806",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "28",
        "-50.585",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "28",
        "-50.366",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "28",
        "-50.149",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "28",
        "-49.934",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "28",
        "-49.722",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "28",
        "-49.513",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "28",
        "-49.305",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "28",
        "-49.101",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "28",
        "-48.899",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "28",
        "-48.699",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "28",
        "-48.503",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "28",
        "-48.309",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "28",
        "-48.118",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "28",
        "-47.930",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "28",
        "-47.744",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "28",
        "-47.562",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "28",
        "-47.383",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "28",
        "-47.208",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "28",
        "-45.236",
        "21.336",
        "2.273",
        "2.628",
        "-0.022"
    ],
    [
        "-114",
        "28",
        "-41.569",
        "18.486",
        "3.105",
        "3.001",
        "-0.244"
    ],
    [
        "-113",
        "28",
        "-33.995",
        "10.913",
        "2.569",
        "2.899",
        "-0.245"
    ],
    [
        "-112",
        "28",
        "-13.570",
        "-9.460",
        "1.766",
        "1.884",
        "-0.124"
    ],
    [
        "-111",
        "28",
        "-9.536",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "28",
        "-9.627",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "28",
        "-9.715",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "28",
        "-9.799",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "28",
        "-9.881",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "28",
        "-9.960",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "28",
        "-10.036",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "28",
        "-10.109",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "28",
        "-10.178",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "28",
        "-10.246",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "28",
        "-10.310",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "28",
        "-10.370",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "28",
        "-10.428",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "28",
        "-10.483",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "28",
        "-10.533",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "28",
        "-10.582",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "28",
        "-10.627",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "28",
        "-10.669",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "28",
        "-10.708",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "28",
        "-10.743",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "28",
        "-10.776",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "28",
        "-10.804",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "28",
        "-10.831",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "28",
        "-10.853",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "28",
        "-10.873",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "28",
        "-10.889",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "28",
        "-10.901",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "28",
        "-10.911",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "28",
        "-10.918",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "28",
        "-10.921",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "28",
        "-10.921",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "28",
        "-10.917",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "28",
        "-10.911",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "28",
        "-10.901",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "28",
        "-10.888",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "28",
        "-10.872",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "28",
        "-10.852",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "28",
        "-10.829",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "28",
        "-10.803",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "28",
        "-10.774",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "28",
        "-10.741",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "28",
        "-10.705",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "28",
        "-10.666",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "28",
        "-10.623",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "28",
        "-10.579",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "28",
        "-10.530",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "28",
        "-10.478",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "28",
        "-10.423",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "28",
        "-10.365",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "28",
        "-10.304",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "28",
        "-10.240",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "28",
        "-10.173",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "28",
        "-10.103",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "28",
        "-10.030",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "28",
        "-9.954",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "28",
        "-9.875",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "28",
        "-9.792",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "28",
        "-9.707",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "28",
        "-9.620",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "28",
        "-9.529",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "28",
        "-9.435",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "28",
        "-9.339",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "28",
        "-9.240",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "28",
        "-9.137",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "28",
        "-9.033",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "28",
        "-8.926",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "28",
        "-8.816",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "28",
        "0.554",
        "12.113",
        "2.555",
        "1.919",
        "-0.187"
    ],
    [
        "-43",
        "28",
        "14.022",
        "11.841",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "28",
        "14.120",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "28",
        "14.220",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "28",
        "14.322",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "28",
        "14.427",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "28",
        "14.534",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "28",
        "14.643",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "28",
        "14.753",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "28",
        "14.867",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "28",
        "14.981",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "28",
        "15.098",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "28",
        "15.216",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "28",
        "15.337",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "28",
        "15.459",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "28",
        "15.583",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "28",
        "15.709",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "28",
        "15.836",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "28",
        "15.966",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "28",
        "16.097",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "28",
        "16.230",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "28",
        "16.363",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "28",
        "16.499",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "28",
        "16.636",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "28",
        "16.774",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "28",
        "16.914",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "28",
        "17.055",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "28",
        "17.197",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "28",
        "17.341",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "28",
        "17.486",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "28",
        "17.632",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "28",
        "17.780",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "28",
        "17.928",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "28",
        "18.077",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "28",
        "18.227",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "28",
        "18.379",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "28",
        "18.531",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "28",
        "18.685",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "28",
        "18.838",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "28",
        "18.992",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "28",
        "19.148",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "28",
        "19.304",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "28",
        "19.461",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "28",
        "19.618",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "28",
        "19.775",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "28",
        "19.933",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "28",
        "20.093",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "28",
        "20.252",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "28",
        "20.410",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "28",
        "20.570",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "28",
        "20.730",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "28",
        "20.890",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "28",
        "21.049",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "28",
        "21.210",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "28",
        "21.371",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "28",
        "21.531",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "28",
        "21.691",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "28",
        "21.851",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "28",
        "22.011",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "28",
        "22.170",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "28",
        "22.330",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "28",
        "22.489",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "28",
        "22.647",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "28",
        "22.806",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "28",
        "22.964",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "28",
        "23.121",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "28",
        "23.278",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "28",
        "23.435",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "28",
        "23.590",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "28",
        "23.745",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "28",
        "23.899",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "28",
        "24.053",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "28",
        "24.206",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "28",
        "24.357",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "28",
        "24.507",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "28",
        "24.658",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "28",
        "24.807",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "28",
        "24.954",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "28",
        "25.828",
        "19.707",
        "6.684",
        "8.300",
        "0.583"
    ],
    [
        "35",
        "28",
        "26.942",
        "23.257",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "28",
        "27.134",
        "23.746",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "28",
        "27.331",
        "24.229",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "28",
        "27.531",
        "24.703",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "28",
        "27.736",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "28",
        "27.944",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "28",
        "28.156",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "28",
        "28.371",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "28",
        "28.591",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "28",
        "28.813",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "28",
        "29.039",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "28",
        "29.269",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "28",
        "29.502",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "28",
        "29.737",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "28",
        "29.977",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "28",
        "30.219",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "28",
        "30.465",
        "30.137",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "28",
        "29.333",
        "30.324",
        "1.891",
        "2.368",
        "0.125"
    ],
    [
        "53",
        "28",
        "29.694",
        "27.433",
        "2.274",
        "4.011",
        "0.114"
    ],
    [
        "54",
        "28",
        "29.597",
        "23.028",
        "2.847",
        "5.618",
        "0.099"
    ],
    [
        "55",
        "28",
        "30.362",
        "22.689",
        "2.963",
        "6.387",
        "-0.095"
    ],
    [
        "56",
        "28",
        "32.275",
        "24.355",
        "2.976",
        "5.976",
        "0.115"
    ],
    [
        "57",
        "28",
        "34.162",
        "24.470",
        "4.063",
        "7.787",
        "0.195"
    ],
    [
        "58",
        "28",
        "36.588",
        "21.215",
        "3.809",
        "6.657",
        "0.362"
    ],
    [
        "59",
        "28",
        "38.354",
        "18.811",
        "3.250",
        "2.881",
        "0.003"
    ],
    [
        "60",
        "28",
        "35.689",
        "10.050",
        "3.185",
        "2.566",
        "-0.003"
    ],
    [
        "61",
        "28",
        "31.616",
        "8.265",
        "5.250",
        "5.496",
        "0.085"
    ],
    [
        "62",
        "28",
        "29.399",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "28",
        "29.441",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "28",
        "29.480",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "28",
        "29.705",
        "5.299",
        "0.331",
        "0.555",
        "0.073"
    ],
    [
        "66",
        "28",
        "32.989",
        "19.592",
        "1.438",
        "1.524",
        "0.055"
    ],
    [
        "67",
        "28",
        "35.557",
        "29.792",
        "1.144",
        "1.359",
        "0.034"
    ],
    [
        "68",
        "28",
        "36.607",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "28",
        "36.885",
        "33.903",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "28",
        "37.163",
        "33.987",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "28",
        "37.442",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "28",
        "37.721",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "28",
        "38.000",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "28",
        "38.281",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "28",
        "38.561",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "28",
        "38.842",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "28",
        "39.123",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "28",
        "39.403",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "28",
        "39.685",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "28",
        "39.966",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "28",
        "40.245",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "28",
        "40.526",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "28",
        "39.266",
        "29.549",
        "0.980",
        "1.469",
        "0.306"
    ],
    [
        "84",
        "28",
        "40.832",
        "32.003",
        "0.957",
        "1.095",
        "0.249"
    ],
    [
        "85",
        "28",
        "40.720",
        "28.160",
        "0.726",
        "1.115",
        "0.139"
    ],
    [
        "86",
        "28",
        "40.176",
        "24.308",
        "0.858",
        "1.256",
        "0.113"
    ],
    [
        "87",
        "28",
        "41.226",
        "23.029",
        "0.984",
        "1.146",
        "0.106"
    ],
    [
        "88",
        "28",
        "42.604",
        "21.300",
        "1.090",
        "1.747",
        "0.043"
    ],
    [
        "89",
        "28",
        "43.818",
        "19.135",
        "1.099",
        "1.779",
        "-0.022"
    ],
    [
        "90",
        "28",
        "44.842",
        "16.088",
        "1.183",
        "1.739",
        "-0.044"
    ],
    [
        "91",
        "28",
        "45.898",
        "13.141",
        "1.160",
        "1.773",
        "-0.076"
    ],
    [
        "92",
        "28",
        "47.070",
        "10.523",
        "1.244",
        "1.816",
        "-0.119"
    ],
    [
        "93",
        "28",
        "48.290",
        "8.002",
        "1.431",
        "1.856",
        "-0.179"
    ],
    [
        "94",
        "28",
        "48.754",
        "7.763",
        "1.437",
        "1.901",
        "-0.170"
    ],
    [
        "95",
        "28",
        "47.590",
        "8.540",
        "1.439",
        "1.822",
        "-0.096"
    ],
    [
        "96",
        "28",
        "44.802",
        "3.630",
        "1.435",
        "1.631",
        "0.010"
    ],
    [
        "97",
        "28",
        "42.876",
        "-3.269",
        "1.233",
        "1.456",
        "0.074"
    ],
    [
        "98",
        "28",
        "42.019",
        "-8.148",
        "1.132",
        "1.274",
        "0.096"
    ],
    [
        "99",
        "28",
        "41.207",
        "-12.698",
        "1.111",
        "1.088",
        "0.086"
    ],
    [
        "100",
        "28",
        "40.139",
        "-16.994",
        "0.983",
        "1.004",
        "0.010"
    ],
    [
        "101",
        "28",
        "39.348",
        "-19.503",
        "0.992",
        "1.063",
        "0.033"
    ],
    [
        "102",
        "28",
        "39.350",
        "-18.054",
        "1.035",
        "0.908",
        "-0.017"
    ],
    [
        "103",
        "28",
        "37.971",
        "-13.525",
        "1.035",
        "1.171",
        "-0.013"
    ],
    [
        "104",
        "28",
        "37.413",
        "-11.183",
        "0.853",
        "0.984",
        "-0.015"
    ],
    [
        "105",
        "28",
        "37.394",
        "-10.853",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "28",
        "37.303",
        "-11.126",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "28",
        "37.212",
        "-11.396",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "28",
        "37.117",
        "-11.663",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "28",
        "37.020",
        "-11.926",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "28",
        "36.922",
        "-12.185",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "28",
        "36.820",
        "-12.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "28",
        "36.718",
        "-12.691",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "28",
        "36.612",
        "-12.939",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "28",
        "36.505",
        "-13.182",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "28",
        "36.367",
        "-13.435",
        "0.635",
        "0.654",
        "0.001"
    ],
    [
        "116",
        "28",
        "36.271",
        "-13.632",
        "0.943",
        "1.039",
        "0.010"
    ],
    [
        "117",
        "28",
        "36.205",
        "-13.700",
        "1.090",
        "1.081",
        "0.020"
    ],
    [
        "118",
        "28",
        "35.964",
        "-13.683",
        "1.055",
        "1.140",
        "0.007"
    ],
    [
        "119",
        "28",
        "35.656",
        "-13.660",
        "1.082",
        "1.157",
        "-0.037"
    ],
    [
        "120",
        "28",
        "35.388",
        "-13.766",
        "1.179",
        "1.143",
        "-0.064"
    ],
    [
        "121",
        "28",
        "35.071",
        "-14.246",
        "1.144",
        "1.166",
        "-0.079"
    ],
    [
        "122",
        "28",
        "34.682",
        "-13.991",
        "1.262",
        "1.282",
        "-0.083"
    ],
    [
        "123",
        "28",
        "34.360",
        "-13.972",
        "1.454",
        "1.378",
        "-0.070"
    ],
    [
        "124",
        "28",
        "34.120",
        "-13.909",
        "1.806",
        "1.770",
        "-0.024"
    ],
    [
        "125",
        "28",
        "33.808",
        "-14.035",
        "2.517",
        "3.341",
        "-0.003"
    ],
    [
        "126",
        "28",
        "32.959",
        "-15.615",
        "3.776",
        "4.697",
        "0.050"
    ],
    [
        "127",
        "28",
        "30.574",
        "-20.462",
        "4.995",
        "6.078",
        "0.004"
    ],
    [
        "128",
        "28",
        "26.799",
        "-25.328",
        "5.940",
        "6.676",
        "-0.059"
    ],
    [
        "129",
        "28",
        "22.895",
        "-21.958",
        "4.849",
        "3.999",
        "-0.153"
    ],
    [
        "130",
        "28",
        "9.481",
        "-2.028",
        "5.827",
        "4.851",
        "-0.167"
    ],
    [
        "131",
        "28",
        "-28.780",
        "20.181",
        "5.496",
        "4.322",
        "-0.180"
    ],
    [
        "132",
        "28",
        "-37.575",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "28",
        "-37.379",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "28",
        "-37.190",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "28",
        "-37.010",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "28",
        "-36.837",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "28",
        "-36.673",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "28",
        "-36.517",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "28",
        "-36.369",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "28",
        "-36.425",
        "16.392",
        "1.698",
        "1.758",
        "-0.080"
    ],
    [
        "141",
        "28",
        "-38.946",
        "14.125",
        "8.319",
        "4.801",
        "-0.166"
    ],
    [
        "142",
        "28",
        "-44.917",
        "12.353",
        "9.258",
        "5.961",
        "-0.164"
    ],
    [
        "143",
        "28",
        "-61.070",
        "15.986",
        "7.417",
        "5.057",
        "-0.197"
    ],
    [
        "144",
        "28",
        "-69.349",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "28",
        "-69.197",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "28",
        "-69.042",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "28",
        "-68.883",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "28",
        "-68.721",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "28",
        "-68.555",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "28",
        "-68.387",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "28",
        "-68.214",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "28",
        "-68.039",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "28",
        "-67.861",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "28",
        "-67.679",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "28",
        "-67.495",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "28",
        "-67.307",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "28",
        "-67.117",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "28",
        "-66.923",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "28",
        "-66.727",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "28",
        "-66.528",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "28",
        "-66.327",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "28",
        "-66.122",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "28",
        "-65.916",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "28",
        "-65.706",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "28",
        "-65.495",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "28",
        "-65.281",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "28",
        "-65.064",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "28",
        "-64.845",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "28",
        "-64.625",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "28",
        "-64.402",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "28",
        "-64.177",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "28",
        "-63.950",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "28",
        "-63.721",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "28",
        "-63.490",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "28",
        "-63.258",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "28",
        "-63.024",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "28",
        "-62.788",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "28",
        "-62.550",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "28",
        "-62.312",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "29",
        "-61.670",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "29",
        "-61.421",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "29",
        "-61.170",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "29",
        "-60.918",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "29",
        "-60.665",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "29",
        "-60.411",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "29",
        "-60.155",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "29",
        "-59.899",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "29",
        "-59.642",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "29",
        "-59.385",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "29",
        "-59.126",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "29",
        "-58.867",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "29",
        "-58.608",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "29",
        "-58.348",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "29",
        "-58.088",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "29",
        "-57.827",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "29",
        "-57.566",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "29",
        "-57.305",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "29",
        "-57.044",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "29",
        "-56.783",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "29",
        "-56.522",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "29",
        "-56.262",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "29",
        "-56.001",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "29",
        "-55.741",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "29",
        "-55.482",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "29",
        "-55.223",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "29",
        "-54.964",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "29",
        "-54.706",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "29",
        "-54.449",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "29",
        "-54.193",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "29",
        "-53.938",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "29",
        "-53.683",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "29",
        "-53.430",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "29",
        "-53.178",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "29",
        "-52.927",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "29",
        "-52.677",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "29",
        "-52.429",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "29",
        "-52.182",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "29",
        "-51.937",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "29",
        "-51.693",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "29",
        "-51.451",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "29",
        "-51.210",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "29",
        "-50.972",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "29",
        "-50.735",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "29",
        "-50.500",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "29",
        "-50.268",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "29",
        "-50.037",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "29",
        "-49.808",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "29",
        "-49.582",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "29",
        "-49.358",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "29",
        "-49.137",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "29",
        "-48.918",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "29",
        "-48.701",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "29",
        "-48.487",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "29",
        "-48.276",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "29",
        "-48.067",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "29",
        "-47.861",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "29",
        "-47.658",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "29",
        "-47.458",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "29",
        "-47.261",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "29",
        "-47.066",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "29",
        "-46.875",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "29",
        "-46.687",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "29",
        "-46.502",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "29",
        "-46.321",
        "21.267",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "29",
        "-42.049",
        "19.472",
        "2.859",
        "3.265",
        "-0.248"
    ],
    [
        "-114",
        "29",
        "-34.998",
        "14.324",
        "2.997",
        "2.816",
        "-0.233"
    ],
    [
        "-113",
        "29",
        "-17.106",
        "-4.548",
        "2.090",
        "2.475",
        "-0.093"
    ],
    [
        "-112",
        "29",
        "-9.743",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "29",
        "-9.839",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "29",
        "-9.932",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "29",
        "-10.023",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "29",
        "-10.110",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "29",
        "-10.195",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "29",
        "-10.277",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "29",
        "-10.355",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "29",
        "-10.431",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "29",
        "-10.502",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "29",
        "-10.571",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "29",
        "-10.637",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "29",
        "-10.700",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "29",
        "-10.760",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "29",
        "-10.816",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "29",
        "-10.869",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "29",
        "-10.919",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "29",
        "-10.965",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "29",
        "-11.009",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "29",
        "-11.049",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "29",
        "-11.086",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "29",
        "-11.119",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "29",
        "-11.149",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "29",
        "-11.175",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "29",
        "-11.199",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "29",
        "-11.219",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "29",
        "-11.236",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "29",
        "-11.250",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "29",
        "-11.259",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "29",
        "-11.266",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "29",
        "-11.269",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "29",
        "-11.269",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "29",
        "-11.265",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "29",
        "-11.259",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "29",
        "-11.248",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "29",
        "-11.235",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "29",
        "-11.217",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "29",
        "-11.197",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "29",
        "-11.174",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "29",
        "-11.147",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "29",
        "-11.116",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "29",
        "-11.082",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "29",
        "-11.045",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "29",
        "-11.005",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "29",
        "-10.962",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "29",
        "-10.914",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "29",
        "-10.864",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "29",
        "-10.812",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "29",
        "-10.755",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "29",
        "-10.695",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "29",
        "-10.632",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "29",
        "-10.566",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "29",
        "-10.496",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "29",
        "-10.425",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "29",
        "-10.348",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "29",
        "-10.270",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "29",
        "-10.188",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "29",
        "-10.104",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "29",
        "-10.016",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "29",
        "-9.925",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "29",
        "-9.832",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "29",
        "-9.735",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "29",
        "-9.635",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "29",
        "-9.533",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "29",
        "-9.427",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "29",
        "-9.319",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "29",
        "-9.208",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "29",
        "-9.094",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "29",
        "-8.979",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "29",
        "8.031",
        "13.558",
        "2.365",
        "1.911",
        "-0.121"
    ],
    [
        "-42",
        "29",
        "13.683",
        "12.110",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "29",
        "13.787",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "29",
        "13.892",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "29",
        "14.000",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "29",
        "14.111",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "29",
        "14.223",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "29",
        "14.337",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "29",
        "14.454",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "29",
        "14.572",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "29",
        "14.693",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "29",
        "14.816",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "29",
        "14.940",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "29",
        "15.067",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "29",
        "15.195",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "29",
        "15.324",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "29",
        "15.456",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "29",
        "15.589",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "29",
        "15.725",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "29",
        "15.862",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "29",
        "16.000",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "29",
        "16.140",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "29",
        "16.281",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "29",
        "16.425",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "29",
        "16.569",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "29",
        "16.715",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "29",
        "16.862",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "29",
        "17.010",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "29",
        "17.159",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "29",
        "17.310",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "29",
        "17.463",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "29",
        "17.616",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "29",
        "17.770",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "29",
        "17.926",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "29",
        "18.081",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "29",
        "18.239",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "29",
        "18.397",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "29",
        "18.555",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "29",
        "18.715",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "29",
        "18.875",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "29",
        "19.037",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "29",
        "19.199",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "29",
        "19.361",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "29",
        "19.523",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "29",
        "19.687",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "29",
        "19.851",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "29",
        "20.015",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "29",
        "20.179",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "29",
        "20.344",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "29",
        "20.509",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "29",
        "20.674",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "29",
        "20.839",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "29",
        "21.005",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "29",
        "21.170",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "29",
        "21.336",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "29",
        "21.502",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "29",
        "21.667",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "29",
        "21.832",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "29",
        "21.997",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "29",
        "22.161",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "29",
        "22.325",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "29",
        "22.489",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "29",
        "22.653",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "29",
        "22.816",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "29",
        "22.979",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "29",
        "23.140",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "29",
        "23.302",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "29",
        "23.463",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "29",
        "23.623",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "29",
        "23.782",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "29",
        "23.940",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "29",
        "24.098",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "29",
        "24.255",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "29",
        "24.411",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "29",
        "24.565",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "29",
        "24.719",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "29",
        "24.688",
        "18.002",
        "3.256",
        "3.195",
        "0.516"
    ],
    [
        "34",
        "29",
        "24.905",
        "19.047",
        "3.787",
        "4.396",
        "0.439"
    ],
    [
        "35",
        "29",
        "26.137",
        "22.630",
        "2.736",
        "3.281",
        "0.446"
    ],
    [
        "36",
        "29",
        "26.329",
        "23.746",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "29",
        "26.532",
        "24.229",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "29",
        "26.739",
        "24.703",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "29",
        "26.950",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "29",
        "27.165",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "29",
        "27.383",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "29",
        "27.606",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "29",
        "27.832",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "29",
        "28.062",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "29",
        "28.295",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "29",
        "28.533",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "29",
        "28.773",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "29",
        "29.017",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "29",
        "29.264",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "29",
        "29.515",
        "29.771",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "29",
        "29.406",
        "28.895",
        "1.414",
        "1.526",
        "0.150"
    ],
    [
        "52",
        "29",
        "29.208",
        "29.030",
        "1.833",
        "2.081",
        "0.106"
    ],
    [
        "53",
        "29",
        "28.939",
        "21.569",
        "2.100",
        "3.238",
        "0.045"
    ],
    [
        "54",
        "29",
        "28.964",
        "18.636",
        "2.220",
        "3.483",
        "-0.079"
    ],
    [
        "55",
        "29",
        "29.273",
        "16.866",
        "2.198",
        "4.205",
        "-0.048"
    ],
    [
        "56",
        "29",
        "30.022",
        "18.164",
        "2.695",
        "5.468",
        "0.033"
    ],
    [
        "57",
        "29",
        "30.565",
        "19.401",
        "3.400",
        "6.125",
        "0.081"
    ],
    [
        "58",
        "29",
        "30.585",
        "18.593",
        "3.859",
        "6.507",
        "0.173"
    ],
    [
        "59",
        "29",
        "30.653",
        "15.059",
        "4.410",
        "6.192",
        "0.209"
    ],
    [
        "60",
        "29",
        "30.577",
        "10.310",
        "5.124",
        "5.184",
        "0.162"
    ],
    [
        "61",
        "29",
        "30.110",
        "6.937",
        "4.650",
        "4.457",
        "0.102"
    ],
    [
        "62",
        "29",
        "29.450",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "29",
        "29.493",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "29",
        "29.535",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "29",
        "28.166",
        "4.310",
        "1.297",
        "1.435",
        "-0.060"
    ],
    [
        "66",
        "29",
        "29.941",
        "13.131",
        "1.575",
        "1.596",
        "0.024"
    ],
    [
        "67",
        "29",
        "34.254",
        "26.754",
        "1.275",
        "1.608",
        "0.103"
    ],
    [
        "68",
        "29",
        "36.150",
        "33.810",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "29",
        "36.781",
        "32.841",
        "9.073",
        "12.051",
        "-0.356"
    ],
    [
        "70",
        "29",
        "36.826",
        "33.784",
        "2.201",
        "4.757",
        "-0.223"
    ],
    [
        "71",
        "29",
        "37.012",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "29",
        "37.300",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "29",
        "37.589",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "29",
        "37.878",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "29",
        "38.168",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "29",
        "38.458",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "29",
        "38.748",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "29",
        "39.038",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "29",
        "39.328",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "29",
        "38.612",
        "32.953",
        "0.959",
        "1.032",
        "0.317"
    ],
    [
        "81",
        "29",
        "38.660",
        "31.450",
        "1.138",
        "1.319",
        "0.361"
    ],
    [
        "82",
        "29",
        "37.278",
        "25.917",
        "1.118",
        "1.536",
        "0.291"
    ],
    [
        "83",
        "29",
        "37.081",
        "22.662",
        "1.029",
        "1.371",
        "0.222"
    ],
    [
        "84",
        "29",
        "38.265",
        "22.465",
        "1.103",
        "1.325",
        "0.153"
    ],
    [
        "85",
        "29",
        "38.711",
        "22.073",
        "1.018",
        "1.375",
        "0.130"
    ],
    [
        "86",
        "29",
        "39.640",
        "21.996",
        "1.024",
        "1.308",
        "0.162"
    ],
    [
        "87",
        "29",
        "41.013",
        "21.914",
        "1.055",
        "1.172",
        "0.058"
    ],
    [
        "88",
        "29",
        "43.231",
        "21.008",
        "0.956",
        "1.139",
        "0.000"
    ],
    [
        "89",
        "29",
        "44.086",
        "20.101",
        "0.848",
        "0.971",
        "0.079"
    ],
    [
        "90",
        "29",
        "45.743",
        "17.119",
        "1.039",
        "1.121",
        "-0.072"
    ],
    [
        "91",
        "29",
        "47.121",
        "13.482",
        "1.017",
        "1.281",
        "-0.068"
    ],
    [
        "92",
        "29",
        "47.629",
        "10.270",
        "1.129",
        "1.423",
        "-0.017"
    ],
    [
        "93",
        "29",
        "48.115",
        "7.587",
        "1.341",
        "1.537",
        "-0.092"
    ],
    [
        "94",
        "29",
        "48.219",
        "5.921",
        "1.345",
        "1.657",
        "-0.094"
    ],
    [
        "95",
        "29",
        "47.716",
        "4.463",
        "1.352",
        "1.637",
        "-0.042"
    ],
    [
        "96",
        "29",
        "46.263",
        "0.238",
        "1.352",
        "1.480",
        "0.027"
    ],
    [
        "97",
        "29",
        "45.392",
        "-4.979",
        "1.148",
        "1.325",
        "0.067"
    ],
    [
        "98",
        "29",
        "45.185",
        "-8.562",
        "1.070",
        "1.193",
        "0.076"
    ],
    [
        "99",
        "29",
        "44.615",
        "-12.214",
        "1.006",
        "0.999",
        "0.038"
    ],
    [
        "100",
        "29",
        "43.962",
        "-15.893",
        "0.962",
        "1.018",
        "0.025"
    ],
    [
        "101",
        "29",
        "42.599",
        "-18.674",
        "1.047",
        "1.183",
        "-0.005"
    ],
    [
        "102",
        "29",
        "41.054",
        "-16.905",
        "1.170",
        "1.133",
        "-0.015"
    ],
    [
        "103",
        "29",
        "38.932",
        "-12.056",
        "1.038",
        "1.192",
        "-0.027"
    ],
    [
        "104",
        "29",
        "37.638",
        "-10.697",
        "0.691",
        "0.755",
        "-0.043"
    ],
    [
        "105",
        "29",
        "37.352",
        "-10.853",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "29",
        "37.259",
        "-11.126",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "29",
        "37.164",
        "-11.396",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "29",
        "37.066",
        "-11.663",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "29",
        "36.966",
        "-11.926",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "29",
        "36.864",
        "-12.185",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "29",
        "36.760",
        "-12.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "29",
        "36.654",
        "-12.691",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "29",
        "36.545",
        "-12.939",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "29",
        "36.435",
        "-13.182",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "29",
        "36.306",
        "-13.416",
        "0.636",
        "0.656",
        "0.001"
    ],
    [
        "116",
        "29",
        "36.132",
        "-13.734",
        "0.951",
        "1.048",
        "0.001"
    ],
    [
        "117",
        "29",
        "35.964",
        "-13.931",
        "1.108",
        "1.103",
        "-0.004"
    ],
    [
        "118",
        "29",
        "35.718",
        "-13.923",
        "1.083",
        "1.172",
        "-0.022"
    ],
    [
        "119",
        "29",
        "35.358",
        "-13.942",
        "1.105",
        "1.186",
        "-0.047"
    ],
    [
        "120",
        "29",
        "34.975",
        "-13.967",
        "1.183",
        "1.162",
        "-0.061"
    ],
    [
        "121",
        "29",
        "34.560",
        "-13.978",
        "1.149",
        "1.206",
        "-0.069"
    ],
    [
        "122",
        "29",
        "34.194",
        "-14.028",
        "1.201",
        "1.235",
        "-0.061"
    ],
    [
        "123",
        "29",
        "33.914",
        "-14.023",
        "1.345",
        "1.267",
        "-0.054"
    ],
    [
        "124",
        "29",
        "33.691",
        "-13.961",
        "1.531",
        "1.454",
        "-0.080"
    ],
    [
        "125",
        "29",
        "33.501",
        "-13.924",
        "1.935",
        "2.186",
        "-0.014"
    ],
    [
        "126",
        "29",
        "33.068",
        "-14.538",
        "2.732",
        "3.417",
        "0.109"
    ],
    [
        "127",
        "29",
        "31.614",
        "-17.019",
        "3.833",
        "5.084",
        "0.091"
    ],
    [
        "128",
        "29",
        "28.239",
        "-20.527",
        "5.195",
        "6.541",
        "0.003"
    ],
    [
        "129",
        "29",
        "23.335",
        "-21.007",
        "6.965",
        "6.377",
        "-0.140"
    ],
    [
        "130",
        "29",
        "9.268",
        "-13.109",
        "7.179",
        "6.216",
        "-0.159"
    ],
    [
        "131",
        "29",
        "-11.581",
        "9.545",
        "7.368",
        "5.541",
        "-0.086"
    ],
    [
        "132",
        "29",
        "-36.127",
        "24.468",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "29",
        "-35.924",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "29",
        "-35.730",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "29",
        "-35.543",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "29",
        "-35.364",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "29",
        "-35.195",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "29",
        "-35.034",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "29",
        "-34.881",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "29",
        "-35.184",
        "16.331",
        "1.766",
        "1.508",
        "-0.003"
    ],
    [
        "141",
        "29",
        "-38.213",
        "13.364",
        "8.368",
        "4.282",
        "-0.025"
    ],
    [
        "142",
        "29",
        "-43.948",
        "12.558",
        "9.107",
        "5.221",
        "-0.082"
    ],
    [
        "143",
        "29",
        "-60.465",
        "16.153",
        "7.240",
        "4.188",
        "-0.108"
    ],
    [
        "144",
        "29",
        "-69.185",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "29",
        "-69.029",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "29",
        "-68.868",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "29",
        "-68.704",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "29",
        "-68.537",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "29",
        "-68.366",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "29",
        "-68.192",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "29",
        "-68.014",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "29",
        "-67.833",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "29",
        "-67.649",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "29",
        "-67.461",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "29",
        "-67.271",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "29",
        "-67.077",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "29",
        "-66.880",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "29",
        "-66.681",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "29",
        "-66.478",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "29",
        "-66.273",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "29",
        "-66.065",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "29",
        "-65.854",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "29",
        "-65.640",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "29",
        "-65.424",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "29",
        "-65.205",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "29",
        "-64.984",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "29",
        "-64.761",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "29",
        "-64.535",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "29",
        "-64.307",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "29",
        "-64.077",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "29",
        "-63.844",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "29",
        "-63.610",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "29",
        "-63.374",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "29",
        "-63.135",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "29",
        "-62.895",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "29",
        "-62.653",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "29",
        "-62.410",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "29",
        "-62.165",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "29",
        "-61.918",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "30",
        "-61.250",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "30",
        "-60.993",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "30",
        "-60.734",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "30",
        "-60.474",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "30",
        "-60.213",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "30",
        "-59.951",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "30",
        "-59.688",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "30",
        "-59.424",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "30",
        "-59.159",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "30",
        "-58.893",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "30",
        "-58.627",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "30",
        "-58.360",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "30",
        "-58.092",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "30",
        "-57.824",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "30",
        "-57.555",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "30",
        "-57.287",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "30",
        "-57.018",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "30",
        "-56.748",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "30",
        "-56.479",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "30",
        "-56.210",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "30",
        "-55.941",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "30",
        "-55.672",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "30",
        "-55.404",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "30",
        "-55.136",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "30",
        "-54.868",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "30",
        "-54.601",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "30",
        "-54.334",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "30",
        "-54.068",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "30",
        "-53.803",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "30",
        "-53.539",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "30",
        "-53.275",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "30",
        "-53.013",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "30",
        "-52.752",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "30",
        "-52.492",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "30",
        "-52.233",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "30",
        "-51.975",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "30",
        "-51.719",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "30",
        "-51.465",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "30",
        "-51.212",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "30",
        "-50.960",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "30",
        "-50.710",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "30",
        "-50.463",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "30",
        "-50.216",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "30",
        "-49.972",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "30",
        "-49.730",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "30",
        "-49.490",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "30",
        "-49.252",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "30",
        "-49.017",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "30",
        "-48.783",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "30",
        "-48.553",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "30",
        "-48.324",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "30",
        "-48.098",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "30",
        "-47.875",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "30",
        "-47.654",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "30",
        "-47.436",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "30",
        "-47.221",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "30",
        "-47.008",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "30",
        "-46.799",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "30",
        "-46.593",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "30",
        "-46.389",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "30",
        "-46.189",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "30",
        "-45.992",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "30",
        "-45.798",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "30",
        "-45.607",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "30",
        "-44.140",
        "19.785",
        "3.137",
        "3.587",
        "-0.094"
    ],
    [
        "-115",
        "30",
        "-38.533",
        "16.914",
        "3.010",
        "3.212",
        "-0.058"
    ],
    [
        "-114",
        "30",
        "-27.464",
        "6.035",
        "2.512",
        "2.269",
        "-0.178"
    ],
    [
        "-113",
        "30",
        "-9.937",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "30",
        "-10.039",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "30",
        "-10.139",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "30",
        "-10.236",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "30",
        "-10.328",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "30",
        "-10.419",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "30",
        "-10.506",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "30",
        "-10.590",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "30",
        "-10.671",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "30",
        "-10.748",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "30",
        "-10.823",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "30",
        "-10.894",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "30",
        "-10.962",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "30",
        "-11.027",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "30",
        "-11.088",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "30",
        "-11.147",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "30",
        "-11.202",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "30",
        "-11.253",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "30",
        "-11.300",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "30",
        "-11.345",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "30",
        "-11.386",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "30",
        "-11.425",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "30",
        "-11.459",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "30",
        "-11.490",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "30",
        "-11.517",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "30",
        "-11.541",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "30",
        "-11.562",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "30",
        "-11.580",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "30",
        "-11.593",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "30",
        "-11.603",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "30",
        "-11.610",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "30",
        "-11.613",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "30",
        "-11.613",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "30",
        "-11.610",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "30",
        "-11.603",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "30",
        "-11.592",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "30",
        "-11.578",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "30",
        "-11.560",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "30",
        "-11.540",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "30",
        "-11.515",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "30",
        "-11.488",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "30",
        "-11.456",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "30",
        "-11.421",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "30",
        "-11.383",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "30",
        "-11.341",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "30",
        "-11.296",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "30",
        "-11.248",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "30",
        "-11.196",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "30",
        "-11.142",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "30",
        "-11.084",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "30",
        "-11.021",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "30",
        "-10.957",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "30",
        "-10.889",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "30",
        "-10.817",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "30",
        "-10.742",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "30",
        "-10.664",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "30",
        "-10.583",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "30",
        "-10.499",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "30",
        "-10.411",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "30",
        "-10.321",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "30",
        "-10.227",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "30",
        "-10.131",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "30",
        "-10.031",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "30",
        "-9.928",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "30",
        "-9.823",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "30",
        "-9.714",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "30",
        "-9.603",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "30",
        "-9.488",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "30",
        "-9.371",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "30",
        "-9.251",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "30",
        "-7.646",
        "13.414",
        "0.827",
        "0.951",
        "-0.103"
    ],
    [
        "-42",
        "30",
        "5.788",
        "14.735",
        "3.128",
        "2.019",
        "-0.214"
    ],
    [
        "-41",
        "30",
        "13.348",
        "12.376",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "30",
        "13.458",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "30",
        "13.570",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "30",
        "13.683",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "30",
        "13.799",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "30",
        "13.917",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "30",
        "14.037",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "30",
        "14.160",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "30",
        "14.284",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "30",
        "14.410",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "30",
        "14.539",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "30",
        "14.669",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "30",
        "14.801",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "30",
        "14.935",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "30",
        "15.071",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "30",
        "15.209",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "30",
        "15.348",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "30",
        "15.489",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "30",
        "15.632",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "30",
        "15.776",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "30",
        "15.922",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "30",
        "16.069",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "30",
        "16.218",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "30",
        "16.369",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "30",
        "16.521",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "30",
        "16.674",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "30",
        "16.828",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "30",
        "16.983",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "30",
        "17.141",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "30",
        "17.298",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "30",
        "17.458",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "30",
        "17.617",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "30",
        "17.779",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "30",
        "17.941",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "30",
        "18.104",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "30",
        "18.267",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "30",
        "18.432",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "30",
        "18.597",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "30",
        "18.763",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "30",
        "18.931",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "30",
        "19.098",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "30",
        "19.265",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "30",
        "19.434",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "30",
        "19.603",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "30",
        "19.773",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "30",
        "19.942",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "30",
        "20.112",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "30",
        "20.282",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "30",
        "20.452",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "30",
        "20.623",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "30",
        "20.793",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "30",
        "20.965",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "30",
        "21.135",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "30",
        "21.305",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "30",
        "21.476",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "30",
        "21.646",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "30",
        "21.817",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "30",
        "21.986",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "30",
        "22.156",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "30",
        "22.324",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "30",
        "22.493",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "30",
        "22.661",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "30",
        "22.830",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "30",
        "22.996",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "30",
        "23.163",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "30",
        "23.328",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "30",
        "23.493",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "30",
        "23.657",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "30",
        "23.821",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "30",
        "23.983",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "30",
        "24.145",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "30",
        "24.306",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "30",
        "24.421",
        "18.297",
        "0.707",
        "0.743",
        "0.101"
    ],
    [
        "32",
        "30",
        "24.508",
        "18.142",
        "1.256",
        "1.143",
        "0.100"
    ],
    [
        "33",
        "30",
        "24.521",
        "17.933",
        "2.166",
        "1.976",
        "0.314"
    ],
    [
        "34",
        "30",
        "24.590",
        "18.305",
        "2.699",
        "3.072",
        "0.311"
    ],
    [
        "35",
        "30",
        "25.048",
        "21.823",
        "2.060",
        "2.383",
        "0.175"
    ],
    [
        "36",
        "30",
        "25.514",
        "23.746",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "30",
        "25.724",
        "24.229",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "30",
        "25.938",
        "24.703",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "30",
        "26.155",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "30",
        "26.377",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "30",
        "26.603",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "30",
        "26.832",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "30",
        "27.066",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "30",
        "27.302",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "30",
        "27.543",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "30",
        "27.788",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "30",
        "28.036",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "30",
        "28.288",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "30",
        "28.542",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "30",
        "28.683",
        "29.513",
        "2.135",
        "2.417",
        "0.327"
    ],
    [
        "51",
        "30",
        "28.733",
        "27.324",
        "2.491",
        "3.304",
        "0.295"
    ],
    [
        "52",
        "30",
        "29.089",
        "23.885",
        "2.188",
        "3.205",
        "0.162"
    ],
    [
        "53",
        "30",
        "29.630",
        "20.454",
        "1.872",
        "2.221",
        "0.019"
    ],
    [
        "54",
        "30",
        "29.784",
        "18.668",
        "1.571",
        "1.914",
        "-0.172"
    ],
    [
        "55",
        "30",
        "29.833",
        "21.305",
        "1.505",
        "2.248",
        "0.010"
    ],
    [
        "56",
        "30",
        "29.777",
        "19.728",
        "1.832",
        "3.143",
        "-0.001"
    ],
    [
        "57",
        "30",
        "29.895",
        "21.343",
        "2.193",
        "2.737",
        "0.070"
    ],
    [
        "58",
        "30",
        "29.833",
        "17.437",
        "2.589",
        "3.879",
        "0.202"
    ],
    [
        "59",
        "30",
        "29.842",
        "13.369",
        "2.878",
        "3.997",
        "0.228"
    ],
    [
        "60",
        "30",
        "29.639",
        "9.772",
        "3.052",
        "3.545",
        "0.223"
    ],
    [
        "61",
        "30",
        "29.463",
        "5.806",
        "0.677",
        "1.177",
        "0.223"
    ],
    [
        "62",
        "30",
        "29.494",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "30",
        "29.538",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "30",
        "29.580",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "30",
        "29.619",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "30",
        "29.657",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "30",
        "34.036",
        "21.003",
        "1.482",
        "1.766",
        "0.076"
    ],
    [
        "68",
        "30",
        "37.887",
        "27.560",
        "1.739",
        "1.720",
        "-0.150"
    ],
    [
        "69",
        "30",
        "38.679",
        "26.684",
        "3.152",
        "6.054",
        "0.037"
    ],
    [
        "70",
        "30",
        "37.385",
        "32.340",
        "10.003",
        "15.048",
        "-0.331"
    ],
    [
        "71",
        "30",
        "36.570",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "30",
        "36.868",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "30",
        "37.166",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "30",
        "37.464",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "30",
        "37.763",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "30",
        "38.062",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "30",
        "38.361",
        "34.279",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "30",
        "37.531",
        "33.824",
        "1.186",
        "1.001",
        "0.349"
    ],
    [
        "79",
        "30",
        "35.173",
        "28.854",
        "1.286",
        "1.404",
        "0.332"
    ],
    [
        "80",
        "30",
        "35.386",
        "28.889",
        "1.204",
        "1.080",
        "0.203"
    ],
    [
        "81",
        "30",
        "36.333",
        "24.581",
        "1.347",
        "1.534",
        "0.257"
    ],
    [
        "82",
        "30",
        "36.612",
        "21.740",
        "1.027",
        "1.052",
        "0.117"
    ],
    [
        "83",
        "30",
        "36.637",
        "22.155",
        "1.200",
        "1.461",
        "0.147"
    ],
    [
        "84",
        "30",
        "37.439",
        "21.705",
        "1.232",
        "1.352",
        "0.121"
    ],
    [
        "85",
        "30",
        "38.266",
        "20.987",
        "1.088",
        "1.261",
        "0.099"
    ],
    [
        "86",
        "30",
        "39.296",
        "20.511",
        "1.084",
        "1.255",
        "0.031"
    ],
    [
        "87",
        "30",
        "40.990",
        "20.688",
        "1.197",
        "1.250",
        "0.051"
    ],
    [
        "88",
        "30",
        "43.130",
        "20.748",
        "1.106",
        "1.261",
        "0.031"
    ],
    [
        "89",
        "30",
        "45.439",
        "18.940",
        "1.091",
        "1.236",
        "-0.023"
    ],
    [
        "90",
        "30",
        "47.071",
        "16.244",
        "1.122",
        "1.154",
        "-0.006"
    ],
    [
        "91",
        "30",
        "48.507",
        "14.043",
        "0.829",
        "0.881",
        "-0.002"
    ],
    [
        "92",
        "30",
        "49.267",
        "10.053",
        "1.103",
        "1.242",
        "-0.105"
    ],
    [
        "93",
        "30",
        "49.842",
        "6.677",
        "1.285",
        "1.313",
        "-0.045"
    ],
    [
        "94",
        "30",
        "50.038",
        "5.102",
        "1.285",
        "1.466",
        "-0.040"
    ],
    [
        "95",
        "30",
        "50.500",
        "3.273",
        "1.276",
        "1.457",
        "-0.001"
    ],
    [
        "96",
        "30",
        "50.167",
        "-0.388",
        "1.261",
        "1.319",
        "0.045"
    ],
    [
        "97",
        "30",
        "48.958",
        "-5.949",
        "0.997",
        "1.046",
        "0.033"
    ],
    [
        "98",
        "30",
        "48.382",
        "-8.344",
        "0.987",
        "1.056",
        "0.043"
    ],
    [
        "99",
        "30",
        "47.988",
        "-11.830",
        "1.018",
        "1.009",
        "-0.010"
    ],
    [
        "100",
        "30",
        "46.934",
        "-15.111",
        "0.991",
        "1.083",
        "-0.021"
    ],
    [
        "101",
        "30",
        "45.360",
        "-16.534",
        "1.056",
        "1.075",
        "-0.025"
    ],
    [
        "102",
        "30",
        "41.890",
        "-13.944",
        "1.130",
        "1.001",
        "-0.023"
    ],
    [
        "103",
        "30",
        "39.273",
        "-9.671",
        "0.993",
        "1.044",
        "-0.037"
    ],
    [
        "104",
        "30",
        "37.621",
        "-10.424",
        "0.719",
        "0.763",
        "-0.083"
    ],
    [
        "105",
        "30",
        "37.299",
        "-10.853",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "30",
        "37.203",
        "-11.126",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "30",
        "37.105",
        "-11.396",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "30",
        "37.004",
        "-11.663",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "30",
        "36.902",
        "-11.926",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "30",
        "36.797",
        "-12.185",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "30",
        "36.688",
        "-12.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "30",
        "36.579",
        "-12.691",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "30",
        "36.468",
        "-12.939",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "30",
        "36.353",
        "-13.182",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "30",
        "36.291",
        "-13.411",
        "0.642",
        "0.663",
        "0.007"
    ],
    [
        "116",
        "30",
        "36.224",
        "-13.841",
        "0.969",
        "1.070",
        "-0.011"
    ],
    [
        "117",
        "30",
        "35.910",
        "-14.250",
        "1.133",
        "1.130",
        "-0.021"
    ],
    [
        "118",
        "30",
        "35.461",
        "-14.309",
        "1.101",
        "1.194",
        "-0.037"
    ],
    [
        "119",
        "30",
        "35.027",
        "-14.173",
        "1.105",
        "1.191",
        "-0.056"
    ],
    [
        "120",
        "30",
        "34.556",
        "-14.108",
        "1.149",
        "1.134",
        "-0.081"
    ],
    [
        "121",
        "30",
        "34.107",
        "-14.107",
        "1.070",
        "1.141",
        "-0.059"
    ],
    [
        "122",
        "30",
        "33.770",
        "-14.131",
        "1.107",
        "1.160",
        "-0.002"
    ],
    [
        "123",
        "30",
        "33.489",
        "-14.084",
        "1.255",
        "1.175",
        "-0.036"
    ],
    [
        "124",
        "30",
        "33.272",
        "-13.993",
        "1.330",
        "1.272",
        "-0.086"
    ],
    [
        "125",
        "30",
        "33.109",
        "-13.893",
        "1.534",
        "1.562",
        "-0.058"
    ],
    [
        "126",
        "30",
        "32.869",
        "-13.943",
        "2.011",
        "2.357",
        "0.075"
    ],
    [
        "127",
        "30",
        "32.132",
        "-14.646",
        "2.909",
        "3.756",
        "0.085"
    ],
    [
        "128",
        "30",
        "29.995",
        "-16.182",
        "4.239",
        "5.406",
        "0.008"
    ],
    [
        "129",
        "30",
        "26.266",
        "-16.787",
        "6.032",
        "5.794",
        "-0.112"
    ],
    [
        "130",
        "30",
        "20.203",
        "-13.698",
        "5.598",
        "5.505",
        "-0.291"
    ],
    [
        "131",
        "30",
        "11.309",
        "-10.443",
        "6.312",
        "4.853",
        "-0.016"
    ],
    [
        "132",
        "30",
        "-13.841",
        "11.880",
        "7.540",
        "4.144",
        "-0.195"
    ],
    [
        "133",
        "30",
        "-34.458",
        "23.499",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "30",
        "-34.258",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "30",
        "-34.065",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "30",
        "-33.882",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "30",
        "-33.707",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "30",
        "-33.541",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "30",
        "-33.382",
        "17.541",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "30",
        "-33.530",
        "16.179",
        "1.685",
        "1.276",
        "-0.050"
    ],
    [
        "141",
        "30",
        "-36.390",
        "12.098",
        "8.012",
        "3.935",
        "-0.008"
    ],
    [
        "142",
        "30",
        "-44.402",
        "12.235",
        "8.522",
        "4.650",
        "-0.051"
    ],
    [
        "143",
        "30",
        "-61.660",
        "16.992",
        "6.659",
        "3.495",
        "0.040"
    ],
    [
        "144",
        "30",
        "-69.001",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "30",
        "-68.839",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "30",
        "-68.674",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "30",
        "-68.505",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "30",
        "-68.332",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "30",
        "-68.156",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "30",
        "-67.976",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "30",
        "-67.793",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "30",
        "-67.606",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "30",
        "-67.416",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "30",
        "-67.223",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "30",
        "-67.026",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "30",
        "-66.826",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "30",
        "-66.624",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "30",
        "-66.418",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "30",
        "-66.209",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "30",
        "-65.997",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "30",
        "-65.782",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "30",
        "-65.565",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "30",
        "-65.344",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "30",
        "-65.122",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "30",
        "-64.896",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "30",
        "-64.668",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "30",
        "-64.438",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "30",
        "-64.205",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "30",
        "-63.969",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "30",
        "-63.732",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "30",
        "-63.492",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "30",
        "-63.251",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "30",
        "-63.007",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "30",
        "-62.761",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "30",
        "-62.514",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "30",
        "-62.264",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "30",
        "-62.013",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "30",
        "-61.760",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "30",
        "-61.506",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "31",
        "-60.812",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "31",
        "-60.546",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "31",
        "-60.280",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "31",
        "-60.012",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "31",
        "-59.744",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "31",
        "-59.473",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "31",
        "-59.202",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "31",
        "-58.930",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "31",
        "-58.657",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "31",
        "-58.384",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "31",
        "-58.109",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "31",
        "-57.834",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "31",
        "-57.558",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "31",
        "-57.282",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "31",
        "-57.006",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "31",
        "-56.729",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "31",
        "-56.452",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "31",
        "-56.174",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "31",
        "-55.897",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "31",
        "-55.620",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "31",
        "-55.343",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "31",
        "-55.066",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "31",
        "-54.789",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "31",
        "-54.513",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "31",
        "-54.237",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "31",
        "-53.962",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "31",
        "-53.688",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "31",
        "-53.414",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "31",
        "-53.141",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "31",
        "-52.868",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "31",
        "-52.597",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "31",
        "-52.327",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "31",
        "-52.058",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "31",
        "-51.790",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "31",
        "-51.523",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "31",
        "-51.258",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "31",
        "-50.994",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "31",
        "-50.732",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "31",
        "-50.471",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "31",
        "-50.212",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "31",
        "-49.955",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "31",
        "-49.699",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "31",
        "-49.446",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "31",
        "-49.195",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "31",
        "-48.945",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "31",
        "-48.698",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "31",
        "-48.453",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "31",
        "-48.210",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "31",
        "-47.970",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "31",
        "-47.732",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "31",
        "-47.497",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "31",
        "-47.264",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "31",
        "-47.034",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "31",
        "-46.806",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "31",
        "-46.582",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "31",
        "-46.360",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "31",
        "-46.141",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "31",
        "-45.926",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "31",
        "-45.713",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "31",
        "-45.503",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "31",
        "-45.297",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "31",
        "-45.094",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "31",
        "-44.894",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "31",
        "-44.698",
        "21.653",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "31",
        "-41.541",
        "17.106",
        "0.986",
        "1.022",
        "0.053"
    ],
    [
        "-115",
        "31",
        "-40.219",
        "16.901",
        "0.915",
        "1.098",
        "0.122"
    ],
    [
        "-114",
        "31",
        "-17.524",
        "-5.666",
        "2.029",
        "1.788",
        "-0.033"
    ],
    [
        "-113",
        "31",
        "-10.228",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "31",
        "-10.333",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "31",
        "-10.435",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "31",
        "-10.535",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "31",
        "-10.631",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "31",
        "-10.724",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "31",
        "-10.813",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "31",
        "-10.900",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "31",
        "-10.984",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "31",
        "-11.063",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "31",
        "-11.140",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "31",
        "-11.214",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "31",
        "-11.283",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "31",
        "-11.350",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "31",
        "-11.413",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "31",
        "-11.474",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "31",
        "-11.530",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "31",
        "-11.583",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "31",
        "-11.632",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "31",
        "-11.678",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "31",
        "-11.720",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "31",
        "-11.760",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "31",
        "-11.795",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "31",
        "-11.827",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "31",
        "-11.856",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "31",
        "-11.880",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "31",
        "-11.901",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "31",
        "-11.919",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "31",
        "-11.933",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "31",
        "-11.944",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "31",
        "-11.951",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "31",
        "-11.954",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "31",
        "-11.954",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "31",
        "-11.950",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "31",
        "-11.943",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "31",
        "-11.933",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "31",
        "-11.918",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "31",
        "-11.899",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "31",
        "-11.878",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "31",
        "-11.853",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "31",
        "-11.824",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "31",
        "-11.793",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "31",
        "-11.756",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "31",
        "-11.717",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "31",
        "-11.674",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "31",
        "-11.628",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "31",
        "-11.578",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "31",
        "-11.525",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "31",
        "-11.469",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "31",
        "-11.408",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "31",
        "-11.345",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "31",
        "-11.278",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "31",
        "-11.208",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "31",
        "-11.134",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "31",
        "-11.057",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "31",
        "-10.977",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "31",
        "-10.893",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "31",
        "-10.806",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "31",
        "-10.716",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "31",
        "-10.623",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "31",
        "-10.527",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "31",
        "-10.427",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "31",
        "-10.324",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "31",
        "-10.219",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "31",
        "-10.110",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "31",
        "-9.998",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "31",
        "-9.883",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "31",
        "-9.766",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "31",
        "-9.644",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "31",
        "-9.521",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "31",
        "-9.395",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "31",
        "-9.265",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "31",
        "11.006",
        "12.963",
        "0.912",
        "1.076",
        "-0.184"
    ],
    [
        "-40",
        "31",
        "13.020",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "31",
        "13.135",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "31",
        "13.251",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "31",
        "13.371",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "31",
        "13.492",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "31",
        "13.617",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "31",
        "13.742",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "31",
        "13.870",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "31",
        "14.000",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "31",
        "14.133",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "31",
        "14.266",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "31",
        "14.403",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "31",
        "14.541",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "31",
        "14.681",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "31",
        "14.823",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "31",
        "14.966",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "31",
        "15.111",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "31",
        "15.258",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "31",
        "15.407",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "31",
        "15.558",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "31",
        "15.710",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "31",
        "15.863",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "31",
        "16.018",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "31",
        "16.174",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "31",
        "16.332",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "31",
        "16.491",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "31",
        "16.651",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "31",
        "16.813",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "31",
        "16.976",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "31",
        "17.139",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "31",
        "17.304",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "31",
        "17.470",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "31",
        "17.637",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "31",
        "17.805",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "31",
        "17.974",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "31",
        "18.144",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "31",
        "18.314",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "31",
        "18.485",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "31",
        "18.657",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "31",
        "18.829",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "31",
        "19.002",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "31",
        "19.175",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "31",
        "19.350",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "31",
        "19.524",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "31",
        "19.698",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "31",
        "19.873",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "31",
        "20.049",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "31",
        "20.224",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "31",
        "20.400",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "31",
        "20.576",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "31",
        "20.752",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "31",
        "20.928",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "31",
        "21.103",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "31",
        "21.279",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "31",
        "21.454",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "31",
        "21.630",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "31",
        "21.804",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "31",
        "21.979",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "31",
        "22.153",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "31",
        "22.327",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "31",
        "22.500",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "31",
        "22.673",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "31",
        "22.844",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "31",
        "23.016",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "31",
        "23.187",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "31",
        "23.357",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "31",
        "23.526",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "31",
        "23.694",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "31",
        "23.861",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "31",
        "24.028",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "31",
        "24.194",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "31",
        "24.358",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "31",
        "24.521",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "31",
        "24.684",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "31",
        "24.383",
        "17.969",
        "2.318",
        "2.435",
        "0.209"
    ],
    [
        "35",
        "31",
        "24.151",
        "20.814",
        "2.680",
        "2.696",
        "0.015"
    ],
    [
        "36",
        "31",
        "24.693",
        "23.746",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "31",
        "24.909",
        "24.229",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "31",
        "25.129",
        "24.703",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "31",
        "25.353",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "31",
        "25.582",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "31",
        "25.813",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "31",
        "26.050",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "31",
        "26.290",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "31",
        "26.535",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "31",
        "26.783",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "31",
        "27.035",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "31",
        "27.290",
        "28.618",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "31",
        "27.549",
        "29.012",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "31",
        "27.812",
        "29.395",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "31",
        "28.075",
        "27.646",
        "2.068",
        "3.336",
        "0.346"
    ],
    [
        "51",
        "31",
        "28.332",
        "24.984",
        "2.286",
        "3.256",
        "0.306"
    ],
    [
        "52",
        "31",
        "28.732",
        "21.681",
        "1.933",
        "2.701",
        "0.210"
    ],
    [
        "53",
        "31",
        "29.116",
        "20.002",
        "1.701",
        "2.049",
        "0.176"
    ],
    [
        "54",
        "31",
        "29.274",
        "19.922",
        "1.574",
        "1.674",
        "0.176"
    ],
    [
        "55",
        "31",
        "29.487",
        "19.605",
        "1.522",
        "1.830",
        "0.183"
    ],
    [
        "56",
        "31",
        "29.735",
        "19.153",
        "1.635",
        "2.148",
        "0.205"
    ],
    [
        "57",
        "31",
        "30.019",
        "18.443",
        "1.993",
        "2.379",
        "0.234"
    ],
    [
        "58",
        "31",
        "30.605",
        "15.750",
        "2.115",
        "2.942",
        "0.206"
    ],
    [
        "59",
        "31",
        "30.467",
        "12.470",
        "2.183",
        "3.070",
        "0.250"
    ],
    [
        "60",
        "31",
        "30.053",
        "9.166",
        "2.106",
        "2.766",
        "0.324"
    ],
    [
        "61",
        "31",
        "29.527",
        "5.695",
        "0.430",
        "0.786",
        "0.221"
    ],
    [
        "62",
        "31",
        "29.527",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "31",
        "29.573",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "31",
        "29.617",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "31",
        "29.657",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "31",
        "29.695",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "31",
        "32.901",
        "11.633",
        "1.028",
        "1.429",
        "0.117"
    ],
    [
        "68",
        "31",
        "39.487",
        "22.873",
        "1.568",
        "1.753",
        "-0.025"
    ],
    [
        "69",
        "31",
        "41.855",
        "24.079",
        "1.940",
        "2.018",
        "0.035"
    ],
    [
        "70",
        "31",
        "36.948",
        "32.527",
        "0.675",
        "2.430",
        "0.139"
    ],
    [
        "71",
        "31",
        "36.118",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "31",
        "36.424",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "31",
        "36.731",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "31",
        "37.039",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "31",
        "37.346",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "31",
        "37.654",
        "34.269",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "31",
        "34.220",
        "30.237",
        "1.439",
        "1.457",
        "0.483"
    ],
    [
        "78",
        "31",
        "30.672",
        "25.400",
        "1.716",
        "1.529",
        "0.437"
    ],
    [
        "79",
        "31",
        "30.221",
        "24.213",
        "1.410",
        "1.347",
        "0.210"
    ],
    [
        "80",
        "31",
        "31.936",
        "23.830",
        "1.572",
        "1.752",
        "0.202"
    ],
    [
        "81",
        "31",
        "34.151",
        "20.883",
        "1.618",
        "1.627",
        "0.188"
    ],
    [
        "82",
        "31",
        "34.794",
        "21.194",
        "1.407",
        "1.589",
        "0.116"
    ],
    [
        "83",
        "31",
        "35.985",
        "21.918",
        "1.324",
        "1.471",
        "0.060"
    ],
    [
        "84",
        "31",
        "37.466",
        "21.385",
        "1.319",
        "1.345",
        "0.060"
    ],
    [
        "85",
        "31",
        "38.586",
        "20.057",
        "1.177",
        "1.266",
        "0.046"
    ],
    [
        "86",
        "31",
        "39.448",
        "19.194",
        "1.210",
        "1.324",
        "0.020"
    ],
    [
        "87",
        "31",
        "41.093",
        "18.898",
        "1.311",
        "1.320",
        "0.004"
    ],
    [
        "88",
        "31",
        "44.517",
        "18.564",
        "1.252",
        "1.370",
        "0.013"
    ],
    [
        "89",
        "31",
        "47.706",
        "16.835",
        "1.238",
        "1.356",
        "-0.001"
    ],
    [
        "90",
        "31",
        "48.876",
        "14.391",
        "1.283",
        "1.278",
        "-0.013"
    ],
    [
        "91",
        "31",
        "50.425",
        "11.954",
        "1.186",
        "1.279",
        "-0.059"
    ],
    [
        "92",
        "31",
        "52.581",
        "8.866",
        "1.142",
        "1.204",
        "-0.073"
    ],
    [
        "93",
        "31",
        "53.951",
        "5.675",
        "1.270",
        "1.256",
        "-0.019"
    ],
    [
        "94",
        "31",
        "54.433",
        "3.648",
        "1.213",
        "1.320",
        "-0.013"
    ],
    [
        "95",
        "31",
        "54.123",
        "1.940",
        "1.201",
        "1.329",
        "0.036"
    ],
    [
        "96",
        "31",
        "53.318",
        "-0.438",
        "1.233",
        "1.248",
        "0.052"
    ],
    [
        "97",
        "31",
        "52.242",
        "-3.786",
        "1.094",
        "1.183",
        "0.044"
    ],
    [
        "98",
        "31",
        "51.609",
        "-7.452",
        "1.090",
        "1.157",
        "-0.004"
    ],
    [
        "99",
        "31",
        "50.619",
        "-10.754",
        "1.187",
        "1.151",
        "-0.005"
    ],
    [
        "100",
        "31",
        "48.481",
        "-13.007",
        "1.029",
        "1.132",
        "-0.025"
    ],
    [
        "101",
        "31",
        "44.943",
        "-12.696",
        "0.959",
        "1.061",
        "0.115"
    ],
    [
        "102",
        "31",
        "41.335",
        "-10.620",
        "1.185",
        "1.156",
        "-0.063"
    ],
    [
        "103",
        "31",
        "39.436",
        "-10.153",
        "0.962",
        "1.069",
        "-0.095"
    ],
    [
        "104",
        "31",
        "37.888",
        "-10.608",
        "0.674",
        "0.721",
        "-0.097"
    ],
    [
        "105",
        "31",
        "37.235",
        "-10.853",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "31",
        "37.136",
        "-11.126",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "31",
        "37.035",
        "-11.396",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "31",
        "36.931",
        "-11.663",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "31",
        "36.825",
        "-11.926",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "31",
        "36.717",
        "-12.185",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "31",
        "36.606",
        "-12.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "31",
        "36.493",
        "-12.691",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "31",
        "36.378",
        "-12.939",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "31",
        "36.260",
        "-13.182",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "31",
        "36.296",
        "-13.469",
        "0.678",
        "0.693",
        "-0.002"
    ],
    [
        "116",
        "31",
        "36.225",
        "-13.884",
        "1.018",
        "1.116",
        "-0.047"
    ],
    [
        "117",
        "31",
        "35.630",
        "-14.428",
        "1.171",
        "1.165",
        "-0.034"
    ],
    [
        "118",
        "31",
        "34.884",
        "-14.565",
        "1.120",
        "1.214",
        "-0.038"
    ],
    [
        "119",
        "31",
        "34.423",
        "-14.400",
        "1.099",
        "1.188",
        "-0.033"
    ],
    [
        "120",
        "31",
        "34.075",
        "-14.347",
        "1.101",
        "1.087",
        "-0.026"
    ],
    [
        "121",
        "31",
        "33.805",
        "-14.533",
        "0.548",
        "0.636",
        "-0.158"
    ],
    [
        "122",
        "31",
        "33.343",
        "-14.297",
        "1.003",
        "1.064",
        "-0.016"
    ],
    [
        "123",
        "31",
        "32.993",
        "-14.148",
        "1.142",
        "1.079",
        "-0.070"
    ],
    [
        "124",
        "31",
        "32.781",
        "-14.019",
        "1.147",
        "1.101",
        "-0.094"
    ],
    [
        "125",
        "31",
        "32.599",
        "-13.874",
        "1.260",
        "1.256",
        "-0.089"
    ],
    [
        "126",
        "31",
        "32.409",
        "-13.732",
        "1.565",
        "1.639",
        "-0.029"
    ],
    [
        "127",
        "31",
        "32.107",
        "-13.648",
        "2.156",
        "2.528",
        "0.005"
    ],
    [
        "128",
        "31",
        "31.209",
        "-13.741",
        "3.176",
        "3.802",
        "-0.017"
    ],
    [
        "129",
        "31",
        "29.160",
        "-14.117",
        "4.754",
        "4.275",
        "-0.179"
    ],
    [
        "130",
        "31",
        "28.445",
        "-16.429",
        "3.590",
        "3.845",
        "-0.102"
    ],
    [
        "131",
        "31",
        "30.516",
        "-21.686",
        "2.993",
        "2.690",
        "-0.025"
    ],
    [
        "132",
        "31",
        "2.093",
        "5.058",
        "5.987",
        "3.655",
        "-0.163"
    ],
    [
        "133",
        "31",
        "-29.806",
        "21.563",
        "1.361",
        "1.326",
        "-0.062"
    ],
    [
        "134",
        "31",
        "-32.775",
        "22.522",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "31",
        "-32.577",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "31",
        "-32.388",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "31",
        "-32.208",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "31",
        "-32.037",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "31",
        "-31.362",
        "15.772",
        "3.250",
        "2.587",
        "-0.182"
    ],
    [
        "140",
        "31",
        "-29.950",
        "12.481",
        "4.589",
        "3.203",
        "-0.220"
    ],
    [
        "141",
        "31",
        "-34.430",
        "9.984",
        "7.828",
        "3.810",
        "-0.063"
    ],
    [
        "142",
        "31",
        "-46.809",
        "11.568",
        "8.036",
        "4.365",
        "-0.065"
    ],
    [
        "143",
        "31",
        "-65.216",
        "17.418",
        "5.825",
        "3.254",
        "0.098"
    ],
    [
        "144",
        "31",
        "-68.795",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "31",
        "-68.629",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "31",
        "-68.458",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "31",
        "-68.284",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "31",
        "-68.106",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "31",
        "-67.925",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "31",
        "-67.740",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "31",
        "-67.551",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "31",
        "-67.359",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "31",
        "-67.163",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "31",
        "-66.964",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "31",
        "-66.761",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "31",
        "-66.555",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "31",
        "-66.347",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "31",
        "-66.134",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "31",
        "-65.919",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "31",
        "-65.701",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "31",
        "-65.480",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "31",
        "-65.256",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "31",
        "-65.029",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "31",
        "-64.799",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "31",
        "-64.567",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "31",
        "-64.332",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "31",
        "-64.095",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "31",
        "-63.855",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "31",
        "-63.613",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "31",
        "-63.368",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "31",
        "-63.121",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "31",
        "-62.872",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "31",
        "-62.621",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "31",
        "-62.368",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "31",
        "-62.113",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "31",
        "-61.856",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "31",
        "-61.597",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "31",
        "-61.337",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "31",
        "-61.075",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "32",
        "-60.354",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "32",
        "-60.082",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "32",
        "-59.808",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "32",
        "-59.532",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "32",
        "-59.255",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "32",
        "-58.978",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "32",
        "-58.699",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "32",
        "-58.419",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "32",
        "-58.138",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "32",
        "-57.856",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "32",
        "-57.574",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "32",
        "-57.291",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "32",
        "-57.007",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "32",
        "-56.723",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "32",
        "-56.438",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "32",
        "-56.154",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "32",
        "-55.869",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "32",
        "-55.583",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "32",
        "-55.298",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "32",
        "-55.013",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "32",
        "-54.728",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "32",
        "-54.443",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "32",
        "-54.158",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "32",
        "-53.874",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "32",
        "-53.590",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "32",
        "-53.307",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "32",
        "-53.025",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "32",
        "-52.743",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "32",
        "-52.462",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "32",
        "-52.182",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "32",
        "-51.902",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "32",
        "-51.624",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "32",
        "-51.348",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "32",
        "-51.072",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "32",
        "-50.798",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "32",
        "-50.525",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "32",
        "-50.253",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "32",
        "-49.983",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "32",
        "-49.715",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "32",
        "-49.449",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "32",
        "-49.184",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "32",
        "-48.921",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "32",
        "-48.660",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "32",
        "-48.402",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "32",
        "-48.145",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "32",
        "-47.891",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "32",
        "-47.639",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "32",
        "-47.389",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "32",
        "-47.142",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "32",
        "-46.897",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "32",
        "-46.655",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "32",
        "-46.415",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "32",
        "-46.179",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "32",
        "-45.945",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "32",
        "-45.714",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "32",
        "-45.485",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "32",
        "-45.260",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "32",
        "-45.038",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "32",
        "-44.820",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "32",
        "-44.604",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "32",
        "-44.392",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "32",
        "-44.183",
        "22.407",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "32",
        "-43.977",
        "22.033",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "32",
        "-39.723",
        "18.702",
        "1.042",
        "1.051",
        "0.010"
    ],
    [
        "-116",
        "32",
        "-38.433",
        "16.254",
        "0.900",
        "0.797",
        "-0.091"
    ],
    [
        "-115",
        "32",
        "-27.023",
        "5.890",
        "0.727",
        "0.807",
        "0.040"
    ],
    [
        "-114",
        "32",
        "-10.404",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "32",
        "-10.515",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "32",
        "-10.623",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "32",
        "-10.729",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "32",
        "-10.832",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "32",
        "-10.930",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "32",
        "-11.026",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "32",
        "-11.119",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "32",
        "-11.207",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "32",
        "-11.293",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "32",
        "-11.375",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "32",
        "-11.454",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "32",
        "-11.529",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "32",
        "-11.601",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "32",
        "-11.670",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "32",
        "-11.735",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "32",
        "-11.797",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "32",
        "-11.855",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "32",
        "-11.909",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "32",
        "-11.960",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "32",
        "-12.007",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "32",
        "-12.051",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "32",
        "-12.091",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "32",
        "-12.127",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "32",
        "-12.161",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "32",
        "-12.190",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "32",
        "-12.216",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "32",
        "-12.237",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "32",
        "-12.255",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "32",
        "-12.270",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "32",
        "-12.281",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "32",
        "-12.288",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "32",
        "-12.292",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "32",
        "-12.292",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "32",
        "-12.288",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "32",
        "-12.280",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "32",
        "-12.269",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "32",
        "-12.254",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "32",
        "-12.235",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "32",
        "-12.213",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "32",
        "-12.188",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "32",
        "-12.158",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "32",
        "-12.125",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "32",
        "-12.088",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "32",
        "-12.048",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "32",
        "-12.003",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "32",
        "-11.956",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "32",
        "-11.905",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "32",
        "-11.850",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "32",
        "-11.792",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "32",
        "-11.730",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "32",
        "-11.664",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "32",
        "-11.595",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "32",
        "-11.523",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "32",
        "-11.447",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "32",
        "-11.369",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "32",
        "-11.285",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "32",
        "-11.200",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "32",
        "-11.110",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "32",
        "-11.018",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "32",
        "-10.922",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "32",
        "-10.823",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "32",
        "-10.720",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "32",
        "-10.615",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "32",
        "-10.506",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "32",
        "-10.394",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "32",
        "-10.279",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "32",
        "-10.160",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "32",
        "-10.040",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "32",
        "-9.915",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "32",
        "-9.789",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "32",
        "-9.658",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "32",
        "-9.526",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "32",
        "-9.389",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "32",
        "12.577",
        "12.639",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "32",
        "12.695",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "32",
        "12.816",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "32",
        "12.939",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "32",
        "13.064",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "32",
        "13.191",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "32",
        "13.321",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "32",
        "13.453",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "32",
        "13.587",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "32",
        "13.723",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "32",
        "13.860",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "32",
        "14.001",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "32",
        "14.143",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "32",
        "14.287",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "32",
        "14.433",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "32",
        "14.581",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "32",
        "14.730",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "32",
        "14.881",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "32",
        "15.034",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "32",
        "15.188",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "32",
        "15.345",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "32",
        "15.503",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "32",
        "15.662",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "32",
        "15.823",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "32",
        "15.985",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "32",
        "16.149",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "32",
        "16.313",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "32",
        "16.480",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "32",
        "16.647",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "32",
        "16.816",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "32",
        "16.985",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "32",
        "17.156",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "32",
        "17.328",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "32",
        "17.501",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "32",
        "17.674",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "32",
        "17.849",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "32",
        "18.025",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "32",
        "18.201",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "32",
        "18.378",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "32",
        "18.554",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "32",
        "18.733",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "32",
        "18.911",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "32",
        "19.090",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "32",
        "19.270",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "32",
        "19.449",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "32",
        "19.629",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "32",
        "19.809",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "32",
        "19.990",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "32",
        "20.171",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "32",
        "20.352",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "32",
        "20.532",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "32",
        "20.713",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "32",
        "20.895",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "32",
        "21.075",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "32",
        "21.255",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "32",
        "21.436",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "32",
        "21.616",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "32",
        "21.795",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "32",
        "21.975",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "32",
        "22.153",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "32",
        "22.331",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "32",
        "22.509",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "32",
        "22.686",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "32",
        "22.863",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "32",
        "23.038",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "32",
        "23.214",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "32",
        "23.387",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "32",
        "23.560",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "32",
        "23.732",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "32",
        "23.904",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "32",
        "24.074",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "32",
        "24.243",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "32",
        "24.411",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "32",
        "24.578",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "32",
        "24.743",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "32",
        "24.975",
        "21.170",
        "1.315",
        "1.763",
        "0.195"
    ],
    [
        "36",
        "32",
        "23.864",
        "23.746",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "32",
        "24.086",
        "24.229",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "32",
        "24.313",
        "24.703",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "32",
        "24.543",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "32",
        "24.778",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "32",
        "25.017",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "32",
        "25.260",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "32",
        "25.507",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "32",
        "25.759",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "32",
        "26.014",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "32",
        "26.273",
        "28.216",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "32",
        "26.574",
        "28.643",
        "0.457",
        "0.874",
        "0.297"
    ],
    [
        "48",
        "32",
        "27.171",
        "28.772",
        "2.121",
        "2.733",
        "0.509"
    ],
    [
        "49",
        "32",
        "27.624",
        "27.392",
        "2.147",
        "3.424",
        "0.470"
    ],
    [
        "50",
        "32",
        "28.098",
        "25.144",
        "2.019",
        "3.299",
        "0.375"
    ],
    [
        "51",
        "32",
        "28.564",
        "22.494",
        "1.826",
        "2.287",
        "0.330"
    ],
    [
        "52",
        "32",
        "29.097",
        "20.711",
        "1.489",
        "2.151",
        "0.199"
    ],
    [
        "53",
        "32",
        "29.397",
        "20.048",
        "1.295",
        "1.609",
        "0.165"
    ],
    [
        "54",
        "32",
        "29.469",
        "20.079",
        "1.236",
        "1.251",
        "0.182"
    ],
    [
        "55",
        "32",
        "29.684",
        "19.126",
        "1.369",
        "1.595",
        "0.210"
    ],
    [
        "56",
        "32",
        "29.908",
        "18.324",
        "1.601",
        "1.957",
        "0.249"
    ],
    [
        "57",
        "32",
        "30.176",
        "16.794",
        "1.994",
        "2.306",
        "0.307"
    ],
    [
        "58",
        "32",
        "30.320",
        "14.245",
        "2.052",
        "2.761",
        "0.262"
    ],
    [
        "59",
        "32",
        "30.217",
        "11.514",
        "2.021",
        "2.767",
        "0.254"
    ],
    [
        "60",
        "32",
        "29.907",
        "8.469",
        "1.885",
        "2.448",
        "0.278"
    ],
    [
        "61",
        "32",
        "29.535",
        "5.631",
        "0.386",
        "0.724",
        "0.133"
    ],
    [
        "62",
        "32",
        "29.552",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "32",
        "29.600",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "32",
        "29.644",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "32",
        "29.685",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "32",
        "29.725",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "32",
        "31.082",
        "5.544",
        "0.944",
        "1.200",
        "0.054"
    ],
    [
        "68",
        "32",
        "37.050",
        "16.238",
        "1.420",
        "1.675",
        "0.068"
    ],
    [
        "69",
        "32",
        "39.334",
        "23.310",
        "1.488",
        "1.564",
        "0.101"
    ],
    [
        "70",
        "32",
        "36.072",
        "32.877",
        "0.364",
        "0.571",
        "0.200"
    ],
    [
        "71",
        "32",
        "35.654",
        "34.059",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "32",
        "35.970",
        "34.122",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "32",
        "36.286",
        "34.175",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "32",
        "36.602",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "32",
        "36.918",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "32",
        "36.389",
        "33.684",
        "0.978",
        "1.025",
        "0.288"
    ],
    [
        "77",
        "32",
        "30.877",
        "25.651",
        "1.501",
        "1.431",
        "0.340"
    ],
    [
        "78",
        "32",
        "29.272",
        "23.555",
        "1.901",
        "1.692",
        "0.349"
    ],
    [
        "79",
        "32",
        "28.719",
        "23.457",
        "1.713",
        "1.848",
        "0.121"
    ],
    [
        "80",
        "32",
        "30.804",
        "19.691",
        "1.638",
        "1.751",
        "0.141"
    ],
    [
        "81",
        "32",
        "32.946",
        "17.672",
        "1.734",
        "1.708",
        "0.106"
    ],
    [
        "82",
        "32",
        "34.943",
        "18.688",
        "1.554",
        "1.702",
        "0.055"
    ],
    [
        "83",
        "32",
        "37.584",
        "19.937",
        "1.452",
        "1.532",
        "0.023"
    ],
    [
        "84",
        "32",
        "39.192",
        "20.201",
        "1.421",
        "1.392",
        "0.030"
    ],
    [
        "85",
        "32",
        "39.698",
        "19.067",
        "1.309",
        "1.371",
        "0.026"
    ],
    [
        "86",
        "32",
        "40.501",
        "17.573",
        "1.298",
        "1.381",
        "0.014"
    ],
    [
        "87",
        "32",
        "42.703",
        "15.964",
        "1.377",
        "1.365",
        "0.004"
    ],
    [
        "88",
        "32",
        "45.787",
        "14.788",
        "1.266",
        "1.351",
        "-0.011"
    ],
    [
        "89",
        "32",
        "47.841",
        "13.796",
        "1.296",
        "1.416",
        "-0.012"
    ],
    [
        "90",
        "32",
        "49.188",
        "11.966",
        "1.330",
        "1.343",
        "-0.030"
    ],
    [
        "91",
        "32",
        "50.867",
        "9.549",
        "1.138",
        "1.282",
        "-0.039"
    ],
    [
        "92",
        "32",
        "52.501",
        "7.479",
        "0.988",
        "1.081",
        "0.064"
    ],
    [
        "93",
        "32",
        "53.717",
        "5.469",
        "1.179",
        "1.184",
        "0.020"
    ],
    [
        "94",
        "32",
        "53.860",
        "4.407",
        "0.997",
        "0.928",
        "-0.001"
    ],
    [
        "95",
        "32",
        "54.182",
        "1.878",
        "1.162",
        "1.256",
        "0.016"
    ],
    [
        "96",
        "32",
        "54.335",
        "-0.275",
        "1.231",
        "1.214",
        "0.014"
    ],
    [
        "97",
        "32",
        "54.226",
        "-2.664",
        "1.157",
        "1.233",
        "0.026"
    ],
    [
        "98",
        "32",
        "53.453",
        "-5.079",
        "1.155",
        "1.248",
        "0.045"
    ],
    [
        "99",
        "32",
        "50.738",
        "-6.837",
        "1.116",
        "1.076",
        "0.041"
    ],
    [
        "100",
        "32",
        "47.607",
        "-7.829",
        "1.116",
        "1.217",
        "0.060"
    ],
    [
        "101",
        "32",
        "43.495",
        "-7.372",
        "1.137",
        "1.237",
        "-0.051"
    ],
    [
        "102",
        "32",
        "41.649",
        "-8.271",
        "1.190",
        "1.169",
        "-0.056"
    ],
    [
        "103",
        "32",
        "40.552",
        "-9.948",
        "1.044",
        "1.142",
        "-0.065"
    ],
    [
        "104",
        "32",
        "38.897",
        "-10.834",
        "0.901",
        "1.018",
        "-0.074"
    ],
    [
        "105",
        "32",
        "37.160",
        "-10.853",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "32",
        "37.057",
        "-11.126",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "32",
        "36.954",
        "-11.396",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "32",
        "36.846",
        "-11.663",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "32",
        "36.738",
        "-11.926",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "32",
        "36.626",
        "-12.185",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "32",
        "36.512",
        "-12.439",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "32",
        "36.396",
        "-12.691",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "32",
        "36.305",
        "-12.967",
        "0.214",
        "0.308",
        "0.004"
    ],
    [
        "114",
        "32",
        "35.978",
        "-13.250",
        "0.884",
        "0.925",
        "-0.046"
    ],
    [
        "115",
        "32",
        "34.889",
        "-13.017",
        "0.981",
        "1.076",
        "-0.101"
    ],
    [
        "116",
        "32",
        "34.056",
        "-13.089",
        "1.084",
        "1.168",
        "-0.077"
    ],
    [
        "117",
        "32",
        "33.841",
        "-13.769",
        "1.200",
        "1.185",
        "-0.038"
    ],
    [
        "118",
        "32",
        "33.742",
        "-14.405",
        "1.131",
        "1.225",
        "-0.018"
    ],
    [
        "119",
        "32",
        "33.671",
        "-14.576",
        "1.087",
        "1.173",
        "0.001"
    ],
    [
        "120",
        "32",
        "33.459",
        "-14.559",
        "1.120",
        "1.113",
        "0.038"
    ],
    [
        "121",
        "32",
        "33.146",
        "-14.472",
        "1.006",
        "1.098",
        "0.011"
    ],
    [
        "122",
        "32",
        "32.752",
        "-14.319",
        "1.024",
        "1.103",
        "-0.106"
    ],
    [
        "123",
        "32",
        "32.424",
        "-14.178",
        "1.076",
        "1.012",
        "-0.113"
    ],
    [
        "124",
        "32",
        "32.192",
        "-14.041",
        "1.055",
        "1.033",
        "-0.103"
    ],
    [
        "125",
        "32",
        "31.973",
        "-13.871",
        "1.115",
        "1.111",
        "-0.099"
    ],
    [
        "126",
        "32",
        "31.757",
        "-13.660",
        "1.299",
        "1.271",
        "-0.076"
    ],
    [
        "127",
        "32",
        "31.507",
        "-13.416",
        "1.598",
        "1.702",
        "-0.053"
    ],
    [
        "128",
        "32",
        "31.181",
        "-12.838",
        "2.145",
        "2.381",
        "-0.037"
    ],
    [
        "129",
        "32",
        "29.726",
        "-12.440",
        "3.126",
        "2.647",
        "-0.078"
    ],
    [
        "130",
        "32",
        "26.205",
        "-14.062",
        "2.173",
        "2.141",
        "-0.124"
    ],
    [
        "131",
        "32",
        "23.995",
        "-19.003",
        "1.680",
        "1.757",
        "-0.082"
    ],
    [
        "132",
        "32",
        "9.665",
        "-1.162",
        "3.989",
        "2.798",
        "-0.097"
    ],
    [
        "133",
        "32",
        "-9.990",
        "12.088",
        "3.654",
        "2.798",
        "-0.020"
    ],
    [
        "134",
        "32",
        "-24.224",
        "16.369",
        "2.878",
        "2.309",
        "0.073"
    ],
    [
        "135",
        "32",
        "-31.079",
        "21.538",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "32",
        "-30.884",
        "20.548",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "32",
        "-30.699",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "32",
        "-30.523",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "32",
        "-26.908",
        "11.649",
        "3.632",
        "2.813",
        "-0.113"
    ],
    [
        "140",
        "32",
        "-24.691",
        "8.811",
        "4.596",
        "3.231",
        "-0.063"
    ],
    [
        "141",
        "32",
        "-32.921",
        "7.214",
        "7.756",
        "3.899",
        "-0.108"
    ],
    [
        "142",
        "32",
        "-49.310",
        "10.794",
        "7.880",
        "4.380",
        "-0.076"
    ],
    [
        "143",
        "32",
        "-68.736",
        "17.871",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "32",
        "-68.569",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "32",
        "-68.397",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "32",
        "-68.222",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "32",
        "-68.043",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "32",
        "-67.860",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "32",
        "-67.673",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "32",
        "-67.483",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "32",
        "-67.288",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "32",
        "-67.091",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "32",
        "-66.889",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "32",
        "-66.684",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "32",
        "-66.476",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "32",
        "-66.264",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "32",
        "-66.049",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "32",
        "-65.831",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "32",
        "-65.610",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "32",
        "-65.385",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "32",
        "-65.158",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "32",
        "-64.927",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "32",
        "-64.694",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "32",
        "-64.457",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "32",
        "-64.218",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "32",
        "-63.977",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "32",
        "-63.732",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "32",
        "-63.486",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "32",
        "-63.236",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "32",
        "-62.985",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "32",
        "-62.731",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "32",
        "-62.475",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "32",
        "-62.216",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "32",
        "-61.956",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "32",
        "-61.693",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "32",
        "-61.429",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "32",
        "-61.163",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "32",
        "-60.895",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "32",
        "-60.625",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "33",
        "-59.879",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "33",
        "-59.599",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "33",
        "-59.317",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "33",
        "-59.034",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "33",
        "-58.749",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "33",
        "-58.464",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "33",
        "-58.177",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "33",
        "-57.889",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "33",
        "-57.601",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "33",
        "-57.311",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "33",
        "-57.021",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "33",
        "-56.730",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "33",
        "-56.439",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "33",
        "-56.147",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "33",
        "-55.854",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "33",
        "-55.561",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "33",
        "-55.268",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "33",
        "-54.975",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "33",
        "-54.682",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "33",
        "-54.389",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "33",
        "-54.096",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "33",
        "-53.803",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "33",
        "-53.511",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "33",
        "-53.218",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "33",
        "-52.927",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "33",
        "-52.636",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "33",
        "-52.345",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "33",
        "-52.056",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "33",
        "-51.767",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "33",
        "-51.479",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "33",
        "-51.192",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "33",
        "-50.906",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "33",
        "-50.622",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "33",
        "-50.338",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "33",
        "-50.057",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "33",
        "-49.776",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "33",
        "-49.497",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "33",
        "-49.220",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "33",
        "-48.944",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "33",
        "-48.670",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "33",
        "-48.398",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "33",
        "-48.128",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "33",
        "-47.860",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "33",
        "-47.594",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "33",
        "-47.330",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "33",
        "-47.069",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "33",
        "-46.810",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "33",
        "-46.553",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "33",
        "-46.299",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "33",
        "-46.048",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "33",
        "-45.799",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "33",
        "-45.553",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "33",
        "-45.309",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "33",
        "-45.069",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "33",
        "-44.831",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "33",
        "-44.597",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "33",
        "-44.366",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "33",
        "-44.137",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "33",
        "-43.913",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "33",
        "-43.691",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "33",
        "-43.473",
        "22.773",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "33",
        "-41.931",
        "21.963",
        "0.457",
        "0.656",
        "0.029"
    ],
    [
        "-118",
        "33",
        "-39.602",
        "18.327",
        "0.597",
        "0.896",
        "0.027"
    ],
    [
        "-117",
        "33",
        "-37.401",
        "15.603",
        "0.473",
        "0.496",
        "0.009"
    ],
    [
        "-116",
        "33",
        "-32.440",
        "12.113",
        "0.310",
        "0.345",
        "-0.059"
    ],
    [
        "-115",
        "33",
        "-11.506",
        "-12.093",
        "0.627",
        "0.788",
        "0.094"
    ],
    [
        "-114",
        "33",
        "-10.684",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "33",
        "-10.800",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "33",
        "-10.911",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "33",
        "-11.019",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "33",
        "-11.124",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "33",
        "-11.226",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "33",
        "-11.325",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "33",
        "-11.419",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "33",
        "-11.511",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "33",
        "-11.599",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "33",
        "-11.683",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "33",
        "-11.764",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "33",
        "-11.841",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "33",
        "-11.916",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "33",
        "-11.986",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "33",
        "-12.053",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "33",
        "-12.116",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "33",
        "-12.176",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "33",
        "-12.232",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "33",
        "-12.284",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "33",
        "-12.333",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "33",
        "-12.378",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "33",
        "-12.419",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "33",
        "-12.457",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "33",
        "-12.490",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "33",
        "-12.521",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "33",
        "-12.547",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "33",
        "-12.569",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "33",
        "-12.588",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "33",
        "-12.602",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "33",
        "-12.614",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "33",
        "-12.622",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "33",
        "-12.625",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "33",
        "-12.625",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "33",
        "-12.621",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "33",
        "-12.614",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "33",
        "-12.601",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "33",
        "-12.586",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "33",
        "-12.567",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "33",
        "-12.545",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "33",
        "-12.518",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "33",
        "-12.487",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "33",
        "-12.454",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "33",
        "-12.416",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "33",
        "-12.374",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "33",
        "-12.329",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "33",
        "-12.280",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "33",
        "-12.227",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "33",
        "-12.171",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "33",
        "-12.112",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "33",
        "-12.048",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "33",
        "-11.981",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "33",
        "-11.910",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "33",
        "-11.835",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "33",
        "-11.758",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "33",
        "-11.676",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "33",
        "-11.591",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "33",
        "-11.503",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "33",
        "-11.412",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "33",
        "-11.316",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "33",
        "-11.217",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "33",
        "-11.115",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "33",
        "-11.010",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "33",
        "-10.902",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "33",
        "-10.790",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "33",
        "-10.675",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "33",
        "-10.557",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "33",
        "-10.435",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "33",
        "-10.311",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "33",
        "-10.183",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "33",
        "-10.052",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "33",
        "-9.919",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "33",
        "-9.782",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "33",
        "-9.643",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "33",
        "-7.416",
        "14.906",
        "1.338",
        "1.385",
        "-0.194"
    ],
    [
        "-39",
        "33",
        "12.253",
        "12.897",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "33",
        "12.376",
        "13.152",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "33",
        "12.502",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "33",
        "12.631",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "33",
        "12.762",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "33",
        "12.895",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "33",
        "13.031",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "33",
        "13.168",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "33",
        "13.308",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "33",
        "13.450",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "33",
        "13.594",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "33",
        "13.740",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "33",
        "13.888",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "33",
        "14.038",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "33",
        "14.190",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "33",
        "14.343",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "33",
        "14.499",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "33",
        "14.656",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "33",
        "14.815",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "33",
        "14.975",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "33",
        "15.138",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "33",
        "15.302",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "33",
        "15.467",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "33",
        "15.634",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "33",
        "15.801",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "33",
        "15.971",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "33",
        "16.142",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "33",
        "16.314",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "33",
        "16.487",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "33",
        "16.662",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "33",
        "16.837",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "33",
        "17.014",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "33",
        "17.192",
        "18.727",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "33",
        "17.370",
        "18.822",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "33",
        "17.549",
        "18.912",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "33",
        "17.730",
        "18.996",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "33",
        "17.910",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "33",
        "18.092",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "33",
        "18.275",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "33",
        "18.457",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "33",
        "18.641",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "33",
        "18.825",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "33",
        "19.009",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "33",
        "19.194",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "33",
        "19.379",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "33",
        "19.564",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "33",
        "19.751",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "33",
        "19.936",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "33",
        "20.121",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "33",
        "20.307",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "33",
        "20.493",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "33",
        "20.680",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "33",
        "20.865",
        "19.524",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "33",
        "21.050",
        "19.501",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "33",
        "21.235",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "33",
        "21.420",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "33",
        "21.605",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "33",
        "21.789",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "33",
        "21.973",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "33",
        "22.156",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "33",
        "22.339",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "33",
        "22.521",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "33",
        "22.703",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "33",
        "22.883",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "33",
        "23.063",
        "18.865",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "33",
        "23.241",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "33",
        "23.420",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "33",
        "23.596",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "33",
        "23.772",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "33",
        "23.947",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "33",
        "24.121",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "33",
        "24.294",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "33",
        "24.466",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "33",
        "24.636",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "33",
        "24.468",
        "19.185",
        "0.411",
        "0.455",
        "0.105"
    ],
    [
        "36",
        "33",
        "23.028",
        "23.746",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "33",
        "23.256",
        "24.229",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "33",
        "23.488",
        "24.703",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "33",
        "23.725",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "33",
        "23.967",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "33",
        "24.212",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "33",
        "24.462",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "33",
        "24.717",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "33",
        "24.976",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "33",
        "25.237",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "33",
        "25.727",
        "28.438",
        "1.398",
        "2.109",
        "0.403"
    ],
    [
        "47",
        "33",
        "26.754",
        "28.282",
        "2.176",
        "3.226",
        "0.526"
    ],
    [
        "48",
        "33",
        "27.727",
        "27.349",
        "2.344",
        "3.529",
        "0.446"
    ],
    [
        "49",
        "33",
        "28.646",
        "25.565",
        "2.078",
        "3.314",
        "0.389"
    ],
    [
        "50",
        "33",
        "29.349",
        "23.461",
        "1.836",
        "2.606",
        "0.386"
    ],
    [
        "51",
        "33",
        "29.839",
        "21.683",
        "1.625",
        "2.255",
        "0.339"
    ],
    [
        "52",
        "33",
        "30.100",
        "20.308",
        "1.342",
        "1.765",
        "0.291"
    ],
    [
        "53",
        "33",
        "30.099",
        "20.094",
        "1.247",
        "1.440",
        "0.253"
    ],
    [
        "54",
        "33",
        "30.205",
        "19.627",
        "1.270",
        "1.330",
        "0.173"
    ],
    [
        "55",
        "33",
        "30.375",
        "18.763",
        "1.346",
        "1.559",
        "0.173"
    ],
    [
        "56",
        "33",
        "30.549",
        "17.961",
        "1.489",
        "1.612",
        "0.214"
    ],
    [
        "57",
        "33",
        "30.422",
        "15.767",
        "1.925",
        "2.317",
        "0.331"
    ],
    [
        "58",
        "33",
        "30.209",
        "13.265",
        "2.022",
        "2.649",
        "0.262"
    ],
    [
        "59",
        "33",
        "29.971",
        "10.875",
        "1.984",
        "2.564",
        "0.218"
    ],
    [
        "60",
        "33",
        "29.737",
        "7.936",
        "1.824",
        "2.325",
        "0.196"
    ],
    [
        "61",
        "33",
        "29.538",
        "5.608",
        "0.373",
        "0.737",
        "0.093"
    ],
    [
        "62",
        "33",
        "29.568",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "33",
        "29.616",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "33",
        "29.662",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "33",
        "29.705",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "33",
        "29.745",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "33",
        "29.707",
        "3.964",
        "0.803",
        "0.938",
        "-0.015"
    ],
    [
        "68",
        "33",
        "32.348",
        "9.462",
        "1.165",
        "1.406",
        "0.165"
    ],
    [
        "69",
        "33",
        "35.186",
        "17.509",
        "1.538",
        "1.602",
        "0.197"
    ],
    [
        "70",
        "33",
        "35.991",
        "25.439",
        "2.944",
        "3.183",
        "-0.333"
    ],
    [
        "71",
        "33",
        "40.148",
        "25.099",
        "3.685",
        "2.773",
        "-0.094"
    ],
    [
        "72",
        "33",
        "37.752",
        "31.869",
        "1.814",
        "1.823",
        "-0.075"
    ],
    [
        "73",
        "33",
        "36.507",
        "33.310",
        "0.952",
        "1.095",
        "0.222"
    ],
    [
        "74",
        "33",
        "36.154",
        "34.217",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "33",
        "36.480",
        "34.248",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "33",
        "31.906",
        "28.393",
        "1.552",
        "1.510",
        "0.385"
    ],
    [
        "77",
        "33",
        "30.479",
        "24.483",
        "1.676",
        "1.653",
        "0.256"
    ],
    [
        "78",
        "33",
        "29.907",
        "24.352",
        "2.029",
        "1.924",
        "0.149"
    ],
    [
        "79",
        "33",
        "32.069",
        "21.436",
        "1.939",
        "2.079",
        "0.137"
    ],
    [
        "80",
        "33",
        "34.721",
        "16.495",
        "1.767",
        "1.853",
        "0.111"
    ],
    [
        "81",
        "33",
        "36.764",
        "14.474",
        "1.745",
        "1.804",
        "-0.068"
    ],
    [
        "82",
        "33",
        "39.102",
        "14.767",
        "1.600",
        "1.767",
        "-0.001"
    ],
    [
        "83",
        "33",
        "40.662",
        "16.573",
        "1.510",
        "1.570",
        "0.004"
    ],
    [
        "84",
        "33",
        "40.866",
        "17.918",
        "1.479",
        "1.414",
        "0.013"
    ],
    [
        "85",
        "33",
        "40.640",
        "17.571",
        "1.366",
        "1.390",
        "0.020"
    ],
    [
        "86",
        "33",
        "41.155",
        "16.230",
        "1.345",
        "1.391",
        "0.026"
    ],
    [
        "87",
        "33",
        "42.575",
        "14.564",
        "1.410",
        "1.375",
        "0.015"
    ],
    [
        "88",
        "33",
        "44.344",
        "12.904",
        "1.332",
        "1.423",
        "0.001"
    ],
    [
        "89",
        "33",
        "45.965",
        "11.650",
        "1.302",
        "1.413",
        "-0.007"
    ],
    [
        "90",
        "33",
        "47.578",
        "10.196",
        "1.314",
        "1.332",
        "-0.017"
    ],
    [
        "91",
        "33",
        "49.470",
        "8.431",
        "1.114",
        "1.242",
        "-0.004"
    ],
    [
        "92",
        "33",
        "51.408",
        "6.734",
        "0.883",
        "0.898",
        "0.066"
    ],
    [
        "93",
        "33",
        "52.714",
        "4.864",
        "1.175",
        "1.160",
        "0.029"
    ],
    [
        "94",
        "33",
        "53.771",
        "3.419",
        "1.152",
        "1.234",
        "-0.005"
    ],
    [
        "95",
        "33",
        "54.392",
        "1.832",
        "1.170",
        "1.250",
        "-0.007"
    ],
    [
        "96",
        "33",
        "54.583",
        "0.217",
        "1.229",
        "1.212",
        "0.023"
    ],
    [
        "97",
        "33",
        "53.916",
        "-1.283",
        "1.015",
        "1.044",
        "0.033"
    ],
    [
        "98",
        "33",
        "52.376",
        "-1.693",
        "1.182",
        "1.283",
        "0.027"
    ],
    [
        "99",
        "33",
        "49.915",
        "-2.133",
        "1.293",
        "1.285",
        "0.032"
    ],
    [
        "100",
        "33",
        "46.794",
        "-2.770",
        "1.213",
        "1.326",
        "0.004"
    ],
    [
        "101",
        "33",
        "44.595",
        "-4.666",
        "1.199",
        "1.302",
        "-0.008"
    ],
    [
        "102",
        "33",
        "42.995",
        "-6.765",
        "1.235",
        "1.227",
        "-0.017"
    ],
    [
        "103",
        "33",
        "40.934",
        "-8.356",
        "1.079",
        "1.180",
        "-0.005"
    ],
    [
        "104",
        "33",
        "38.713",
        "-9.961",
        "1.006",
        "1.095",
        "-0.024"
    ],
    [
        "105",
        "33",
        "37.532",
        "-11.267",
        "1.059",
        "1.047",
        "-0.050"
    ],
    [
        "106",
        "33",
        "37.740",
        "-11.429",
        "0.939",
        "1.063",
        "-0.062"
    ],
    [
        "107",
        "33",
        "37.749",
        "-11.408",
        "0.886",
        "1.006",
        "-0.017"
    ],
    [
        "108",
        "33",
        "37.506",
        "-11.684",
        "0.962",
        "0.945",
        "-0.018"
    ],
    [
        "109",
        "33",
        "37.413",
        "-12.058",
        "0.883",
        "0.966",
        "-0.016"
    ],
    [
        "110",
        "33",
        "37.412",
        "-12.401",
        "0.887",
        "0.975",
        "-0.011"
    ],
    [
        "111",
        "33",
        "37.279",
        "-12.789",
        "0.975",
        "0.959",
        "-0.015"
    ],
    [
        "112",
        "33",
        "37.049",
        "-13.293",
        "0.923",
        "1.039",
        "-0.029"
    ],
    [
        "113",
        "33",
        "36.238",
        "-13.366",
        "0.961",
        "1.082",
        "-0.059"
    ],
    [
        "114",
        "33",
        "34.557",
        "-12.420",
        "1.095",
        "1.096",
        "-0.070"
    ],
    [
        "115",
        "33",
        "33.271",
        "-12.102",
        "1.065",
        "1.156",
        "-0.083"
    ],
    [
        "116",
        "33",
        "33.038",
        "-12.632",
        "1.098",
        "1.166",
        "-0.070"
    ],
    [
        "117",
        "33",
        "33.263",
        "-13.151",
        "1.186",
        "1.162",
        "-0.044"
    ],
    [
        "118",
        "33",
        "33.265",
        "-14.007",
        "1.124",
        "1.207",
        "-0.018"
    ],
    [
        "119",
        "33",
        "33.117",
        "-14.696",
        "1.110",
        "1.197",
        "0.012"
    ],
    [
        "120",
        "33",
        "32.930",
        "-14.661",
        "1.154",
        "1.148",
        "0.017"
    ],
    [
        "121",
        "33",
        "32.581",
        "-14.471",
        "1.077",
        "1.165",
        "-0.012"
    ],
    [
        "122",
        "33",
        "32.172",
        "-14.314",
        "1.045",
        "1.128",
        "-0.055"
    ],
    [
        "123",
        "33",
        "31.841",
        "-14.184",
        "1.030",
        "0.973",
        "-0.089"
    ],
    [
        "124",
        "33",
        "31.563",
        "-14.054",
        "0.992",
        "0.997",
        "-0.097"
    ],
    [
        "125",
        "33",
        "31.270",
        "-13.887",
        "1.016",
        "1.036",
        "-0.097"
    ],
    [
        "126",
        "33",
        "30.955",
        "-13.645",
        "1.114",
        "1.095",
        "-0.081"
    ],
    [
        "127",
        "33",
        "30.576",
        "-13.310",
        "1.228",
        "1.275",
        "-0.056"
    ],
    [
        "128",
        "33",
        "29.970",
        "-12.710",
        "1.409",
        "1.582",
        "0.034"
    ],
    [
        "129",
        "33",
        "29.062",
        "-10.791",
        "1.640",
        "1.508",
        "-0.018"
    ],
    [
        "130",
        "33",
        "26.160",
        "-10.533",
        "1.348",
        "1.436",
        "-0.009"
    ],
    [
        "131",
        "33",
        "18.774",
        "-12.842",
        "1.352",
        "1.412",
        "-0.028"
    ],
    [
        "132",
        "33",
        "8.994",
        "-5.969",
        "2.323",
        "1.951",
        "0.032"
    ],
    [
        "133",
        "33",
        "-2.763",
        "5.471",
        "1.949",
        "1.615",
        "0.013"
    ],
    [
        "134",
        "33",
        "-13.895",
        "7.063",
        "2.649",
        "2.302",
        "0.195"
    ],
    [
        "135",
        "33",
        "-20.420",
        "3.797",
        "2.815",
        "2.406",
        "0.260"
    ],
    [
        "136",
        "33",
        "-25.319",
        "4.905",
        "2.204",
        "2.316",
        "0.234"
    ],
    [
        "137",
        "33",
        "-29.181",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "33",
        "-28.999",
        "18.549",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "33",
        "-18.112",
        "4.037",
        "3.464",
        "2.723",
        "-0.003"
    ],
    [
        "140",
        "33",
        "-18.235",
        "6.378",
        "3.674",
        "2.997",
        "0.006"
    ],
    [
        "141",
        "33",
        "-30.968",
        "4.572",
        "7.400",
        "4.034",
        "-0.142"
    ],
    [
        "142",
        "33",
        "-51.691",
        "10.363",
        "7.253",
        "4.525",
        "-0.107"
    ],
    [
        "143",
        "33",
        "-68.493",
        "17.871",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "33",
        "-68.321",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "33",
        "-68.145",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "33",
        "-67.965",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "33",
        "-67.781",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "33",
        "-67.593",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "33",
        "-67.401",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "33",
        "-67.205",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "33",
        "-67.005",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "33",
        "-66.802",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "33",
        "-66.595",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "33",
        "-66.385",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "33",
        "-66.170",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "33",
        "-65.953",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "33",
        "-65.732",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "33",
        "-65.508",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "33",
        "-65.280",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "33",
        "-65.049",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "33",
        "-64.815",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "33",
        "-64.579",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "33",
        "-64.339",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "33",
        "-64.096",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "33",
        "-63.850",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "33",
        "-63.602",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "33",
        "-63.351",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "33",
        "-63.097",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "33",
        "-62.841",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "33",
        "-62.582",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "33",
        "-62.321",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "33",
        "-62.058",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "33",
        "-61.792",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "33",
        "-61.525",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "33",
        "-61.255",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "33",
        "-60.983",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "33",
        "-60.710",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "33",
        "-60.435",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "33",
        "-60.157",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "34",
        "-59.385",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "34",
        "-59.097",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "34",
        "-58.808",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "34",
        "-58.517",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "34",
        "-58.225",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "34",
        "-57.932",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "34",
        "-57.638",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "34",
        "-57.342",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "34",
        "-57.046",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "34",
        "-56.749",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "34",
        "-56.451",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "34",
        "-56.152",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "34",
        "-55.853",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "34",
        "-55.553",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "34",
        "-55.253",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "34",
        "-54.952",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "34",
        "-54.651",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "34",
        "-54.350",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "34",
        "-54.049",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "34",
        "-53.748",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "34",
        "-53.448",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "34",
        "-53.147",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "34",
        "-52.847",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "34",
        "-52.547",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "34",
        "-52.247",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "34",
        "-51.948",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "34",
        "-51.650",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "34",
        "-51.353",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "34",
        "-51.056",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "34",
        "-50.761",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "34",
        "-50.466",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "34",
        "-50.173",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "34",
        "-49.881",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "34",
        "-49.590",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "34",
        "-49.300",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "34",
        "-49.012",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "34",
        "-48.726",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "34",
        "-48.441",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "34",
        "-48.158",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "34",
        "-47.877",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "34",
        "-47.598",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "34",
        "-47.320",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "34",
        "-47.045",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "34",
        "-46.772",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "34",
        "-46.501",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "34",
        "-46.233",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "34",
        "-45.967",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "34",
        "-45.703",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "34",
        "-45.442",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "34",
        "-45.184",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "34",
        "-44.929",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "34",
        "-44.676",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "34",
        "-44.426",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "34",
        "-44.179",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "34",
        "-43.935",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "34",
        "-43.695",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "34",
        "-43.457",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "34",
        "-43.223",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "34",
        "-42.992",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "34",
        "-42.765",
        "23.133",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "34",
        "-41.014",
        "20.977",
        "0.488",
        "0.529",
        "0.046"
    ],
    [
        "-119",
        "34",
        "-38.800",
        "19.139",
        "0.337",
        "0.416",
        "-0.067"
    ],
    [
        "-118",
        "34",
        "-36.570",
        "11.838",
        "0.163",
        "0.196",
        "0.024"
    ],
    [
        "-117",
        "34",
        "-27.888",
        "6.953",
        "0.302",
        "0.339",
        "-0.027"
    ],
    [
        "-116",
        "34",
        "-13.300",
        "-7.851",
        "0.384",
        "0.385",
        "-0.037"
    ],
    [
        "-115",
        "34",
        "-12.100",
        "-11.582",
        "0.551",
        "0.669",
        "0.073"
    ],
    [
        "-114",
        "34",
        "-10.962",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "34",
        "-11.080",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "34",
        "-11.194",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "34",
        "-11.306",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "34",
        "-11.414",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "34",
        "-11.518",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "34",
        "-11.619",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "34",
        "-11.717",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "34",
        "-11.810",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "34",
        "-11.901",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "34",
        "-11.988",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "34",
        "-12.071",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "34",
        "-12.151",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "34",
        "-12.226",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "34",
        "-12.299",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "34",
        "-12.368",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "34",
        "-12.432",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "34",
        "-12.493",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "34",
        "-12.552",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "34",
        "-12.605",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "34",
        "-12.655",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "34",
        "-12.701",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "34",
        "-12.744",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "34",
        "-12.782",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "34",
        "-12.817",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "34",
        "-12.847",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "34",
        "-12.875",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "34",
        "-12.897",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "34",
        "-12.917",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "34",
        "-12.932",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "34",
        "-12.944",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "34",
        "-12.951",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "34",
        "-12.955",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "34",
        "-12.955",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "34",
        "-12.950",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "34",
        "-12.943",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "34",
        "-12.931",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "34",
        "-12.916",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "34",
        "-12.896",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "34",
        "-12.872",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "34",
        "-12.845",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "34",
        "-12.814",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "34",
        "-12.779",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "34",
        "-12.740",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "34",
        "-12.697",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "34",
        "-12.651",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "34",
        "-12.600",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "34",
        "-12.547",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "34",
        "-12.489",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "34",
        "-12.427",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "34",
        "-12.362",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "34",
        "-12.293",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "34",
        "-12.220",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "34",
        "-12.144",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "34",
        "-12.064",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "34",
        "-11.980",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "34",
        "-11.894",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "34",
        "-11.803",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "34",
        "-11.709",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "34",
        "-11.611",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "34",
        "-11.510",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "34",
        "-11.405",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "34",
        "-11.297",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "34",
        "-11.185",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "34",
        "-11.071",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "34",
        "-10.953",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "34",
        "-10.831",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "34",
        "-10.707",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "34",
        "-10.578",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "34",
        "-10.447",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "34",
        "-10.313",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "34",
        "-10.176",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "34",
        "-10.036",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "34",
        "-9.892",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "34",
        "-9.747",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "34",
        "-9.597",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "34",
        "-9.445",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "34",
        "12.063",
        "13.402",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "34",
        "12.194",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "34",
        "12.329",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "34",
        "12.465",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "34",
        "12.604",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "34",
        "12.746",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "34",
        "12.890",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "34",
        "13.035",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "34",
        "13.183",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "34",
        "13.333",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "34",
        "13.485",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "34",
        "13.639",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "34",
        "13.795",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "34",
        "13.952",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "34",
        "14.113",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "34",
        "14.273",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "34",
        "14.437",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "34",
        "14.602",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "34",
        "14.769",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "34",
        "14.936",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "34",
        "15.106",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "34",
        "15.278",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "34",
        "15.450",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "34",
        "15.625",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "34",
        "15.800",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "34",
        "15.976",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "34",
        "16.154",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "34",
        "16.333",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "34",
        "16.514",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "34",
        "16.694",
        "18.626",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "34",
        "17.135",
        "19.774",
        "0.679",
        "0.786",
        "0.050"
    ],
    [
        "-6",
        "34",
        "17.267",
        "18.705",
        "4.872",
        "4.884",
        "-0.038"
    ],
    [
        "-5",
        "34",
        "17.329",
        "18.893",
        "4.041",
        "4.447",
        "-0.065"
    ],
    [
        "-4",
        "34",
        "17.452",
        "18.985",
        "2.720",
        "3.005",
        "0.059"
    ],
    [
        "-3",
        "34",
        "17.615",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "34",
        "17.802",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "34",
        "17.989",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "34",
        "18.177",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "34",
        "18.365",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "34",
        "18.554",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "34",
        "18.743",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "34",
        "18.932",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "34",
        "19.123",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "34",
        "19.313",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "34",
        "19.504",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "34",
        "19.694",
        "19.547",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "34",
        "19.885",
        "19.554",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "34",
        "20.076",
        "19.555",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "34",
        "20.267",
        "19.551",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "34",
        "20.458",
        "19.540",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "34",
        "20.719",
        "19.360",
        "0.612",
        "0.717",
        "-0.008"
    ],
    [
        "14",
        "34",
        "20.868",
        "19.457",
        "0.445",
        "0.495",
        "-0.003"
    ],
    [
        "15",
        "34",
        "21.029",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "34",
        "21.219",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "34",
        "21.408",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "34",
        "21.597",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "34",
        "21.786",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "34",
        "21.975",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "34",
        "22.162",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "34",
        "22.349",
        "19.109",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "34",
        "22.535",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "34",
        "22.720",
        "18.953",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "34",
        "22.890",
        "15.307",
        "1.804",
        "2.115",
        "0.172"
    ],
    [
        "26",
        "34",
        "23.088",
        "18.773",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "34",
        "23.271",
        "18.675",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "34",
        "23.452",
        "18.570",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "34",
        "23.634",
        "18.460",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "34",
        "23.813",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "34",
        "23.992",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "34",
        "24.169",
        "18.098",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "34",
        "24.345",
        "17.965",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "34",
        "24.519",
        "17.828",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "34",
        "24.475",
        "18.081",
        "1.030",
        "1.679",
        "0.263"
    ],
    [
        "36",
        "34",
        "22.184",
        "23.746",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "34",
        "22.418",
        "24.229",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "34",
        "22.657",
        "24.703",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "34",
        "22.901",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "34",
        "23.149",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "34",
        "23.401",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "34",
        "23.657",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "34",
        "23.918",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "34",
        "24.183",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "34",
        "24.453",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "34",
        "25.247",
        "27.516",
        "1.395",
        "2.204",
        "0.363"
    ],
    [
        "47",
        "34",
        "26.838",
        "26.549",
        "2.089",
        "3.588",
        "0.313"
    ],
    [
        "48",
        "34",
        "28.217",
        "25.093",
        "2.279",
        "3.441",
        "0.368"
    ],
    [
        "49",
        "34",
        "29.518",
        "23.815",
        "1.985",
        "2.991",
        "0.375"
    ],
    [
        "50",
        "34",
        "30.778",
        "22.744",
        "1.719",
        "2.436",
        "0.339"
    ],
    [
        "51",
        "34",
        "32.659",
        "21.055",
        "1.348",
        "1.611",
        "0.221"
    ],
    [
        "52",
        "34",
        "31.632",
        "20.291",
        "1.340",
        "1.662",
        "0.250"
    ],
    [
        "53",
        "34",
        "31.026",
        "19.975",
        "1.396",
        "1.580",
        "0.213"
    ],
    [
        "54",
        "34",
        "30.949",
        "19.233",
        "1.464",
        "1.563",
        "0.193"
    ],
    [
        "55",
        "34",
        "30.961",
        "18.383",
        "1.507",
        "1.730",
        "0.219"
    ],
    [
        "56",
        "34",
        "30.909",
        "17.304",
        "1.730",
        "1.817",
        "0.240"
    ],
    [
        "57",
        "34",
        "30.555",
        "14.930",
        "2.104",
        "2.395",
        "0.305"
    ],
    [
        "58",
        "34",
        "30.205",
        "12.539",
        "2.110",
        "2.569",
        "0.250"
    ],
    [
        "59",
        "34",
        "29.920",
        "10.410",
        "2.048",
        "2.376",
        "0.228"
    ],
    [
        "60",
        "34",
        "29.716",
        "7.434",
        "1.900",
        "2.305",
        "0.185"
    ],
    [
        "61",
        "34",
        "29.542",
        "5.593",
        "0.391",
        "0.733",
        "0.081"
    ],
    [
        "62",
        "34",
        "29.575",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "34",
        "29.625",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "34",
        "29.672",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "34",
        "29.716",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "34",
        "29.757",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "34",
        "29.795",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "34",
        "29.846",
        "3.889",
        "0.358",
        "0.419",
        "0.137"
    ],
    [
        "69",
        "34",
        "29.797",
        "13.007",
        "2.047",
        "2.021",
        "-0.356"
    ],
    [
        "70",
        "34",
        "32.633",
        "18.222",
        "2.067",
        "2.868",
        "-0.276"
    ],
    [
        "71",
        "34",
        "34.022",
        "23.254",
        "1.489",
        "1.670",
        "-0.018"
    ],
    [
        "72",
        "34",
        "34.039",
        "26.938",
        "1.382",
        "1.373",
        "0.220"
    ],
    [
        "73",
        "34",
        "33.735",
        "28.516",
        "1.285",
        "1.331",
        "0.272"
    ],
    [
        "74",
        "34",
        "32.328",
        "28.273",
        "1.358",
        "1.410",
        "0.310"
    ],
    [
        "75",
        "34",
        "30.840",
        "27.085",
        "1.708",
        "1.603",
        "0.348"
    ],
    [
        "76",
        "34",
        "30.697",
        "26.167",
        "1.902",
        "1.942",
        "0.289"
    ],
    [
        "77",
        "34",
        "30.748",
        "25.794",
        "2.104",
        "2.252",
        "0.166"
    ],
    [
        "78",
        "34",
        "32.244",
        "23.020",
        "2.293",
        "2.250",
        "0.067"
    ],
    [
        "79",
        "34",
        "35.299",
        "18.303",
        "2.184",
        "2.335",
        "0.029"
    ],
    [
        "80",
        "34",
        "36.657",
        "15.450",
        "2.147",
        "2.207",
        "0.037"
    ],
    [
        "81",
        "34",
        "37.897",
        "13.529",
        "2.082",
        "2.012",
        "-0.007"
    ],
    [
        "82",
        "34",
        "39.086",
        "13.949",
        "1.781",
        "1.902",
        "0.000"
    ],
    [
        "83",
        "34",
        "39.589",
        "15.518",
        "1.616",
        "1.651",
        "-0.002"
    ],
    [
        "84",
        "34",
        "39.859",
        "16.371",
        "1.540",
        "1.451",
        "0.014"
    ],
    [
        "85",
        "34",
        "40.458",
        "16.113",
        "1.402",
        "1.399",
        "0.031"
    ],
    [
        "86",
        "34",
        "41.339",
        "15.351",
        "1.368",
        "1.382",
        "0.034"
    ],
    [
        "87",
        "34",
        "42.355",
        "14.004",
        "1.416",
        "1.365",
        "0.022"
    ],
    [
        "88",
        "34",
        "43.379",
        "12.190",
        "1.336",
        "1.410",
        "0.015"
    ],
    [
        "89",
        "34",
        "44.581",
        "10.596",
        "1.304",
        "1.396",
        "0.011"
    ],
    [
        "90",
        "34",
        "45.910",
        "8.786",
        "1.318",
        "1.315",
        "0.010"
    ],
    [
        "91",
        "34",
        "47.488",
        "6.732",
        "1.155",
        "1.246",
        "0.048"
    ],
    [
        "92",
        "34",
        "49.525",
        "4.721",
        "0.957",
        "1.001",
        "0.005"
    ],
    [
        "93",
        "34",
        "51.175",
        "3.862",
        "1.129",
        "1.096",
        "-0.013"
    ],
    [
        "94",
        "34",
        "51.862",
        "3.398",
        "1.147",
        "1.238",
        "-0.001"
    ],
    [
        "95",
        "34",
        "51.997",
        "2.541",
        "1.176",
        "1.263",
        "0.004"
    ],
    [
        "96",
        "34",
        "51.885",
        "1.871",
        "1.251",
        "1.241",
        "0.027"
    ],
    [
        "97",
        "34",
        "51.133",
        "1.375",
        "1.179",
        "1.270",
        "0.001"
    ],
    [
        "98",
        "34",
        "49.458",
        "1.130",
        "1.176",
        "1.271",
        "-0.015"
    ],
    [
        "99",
        "34",
        "47.154",
        "0.456",
        "1.266",
        "1.273",
        "0.032"
    ],
    [
        "100",
        "34",
        "44.539",
        "-0.629",
        "1.201",
        "1.322",
        "0.014"
    ],
    [
        "101",
        "34",
        "41.898",
        "-2.196",
        "1.197",
        "1.308",
        "0.005"
    ],
    [
        "102",
        "34",
        "39.487",
        "-4.177",
        "1.245",
        "1.251",
        "0.007"
    ],
    [
        "103",
        "34",
        "37.875",
        "-6.466",
        "1.151",
        "1.239",
        "0.004"
    ],
    [
        "104",
        "34",
        "36.749",
        "-8.560",
        "1.128",
        "1.198",
        "-0.010"
    ],
    [
        "105",
        "34",
        "36.450",
        "-10.997",
        "1.183",
        "1.144",
        "-0.014"
    ],
    [
        "106",
        "34",
        "36.804",
        "-11.774",
        "1.076",
        "1.149",
        "0.001"
    ],
    [
        "107",
        "34",
        "36.904",
        "-11.024",
        "1.024",
        "1.094",
        "-0.025"
    ],
    [
        "108",
        "34",
        "36.329",
        "-11.276",
        "1.089",
        "1.061",
        "-0.037"
    ],
    [
        "109",
        "34",
        "35.984",
        "-12.146",
        "0.868",
        "0.943",
        "-0.096"
    ],
    [
        "110",
        "34",
        "35.557",
        "-12.531",
        "0.978",
        "1.080",
        "0.012"
    ],
    [
        "111",
        "34",
        "35.082",
        "-12.727",
        "1.117",
        "1.113",
        "-0.023"
    ],
    [
        "112",
        "34",
        "34.832",
        "-12.887",
        "1.060",
        "1.157",
        "-0.038"
    ],
    [
        "113",
        "34",
        "34.366",
        "-12.534",
        "1.058",
        "1.155",
        "-0.043"
    ],
    [
        "114",
        "34",
        "33.703",
        "-12.056",
        "1.134",
        "1.138",
        "-0.041"
    ],
    [
        "115",
        "34",
        "33.493",
        "-12.395",
        "1.062",
        "1.171",
        "-0.055"
    ],
    [
        "116",
        "34",
        "33.462",
        "-12.892",
        "1.049",
        "1.070",
        "-0.054"
    ],
    [
        "117",
        "34",
        "33.431",
        "-13.058",
        "1.107",
        "1.092",
        "-0.056"
    ],
    [
        "118",
        "34",
        "33.338",
        "-13.533",
        "1.010",
        "1.087",
        "-0.018"
    ],
    [
        "119",
        "34",
        "32.973",
        "-14.604",
        "1.043",
        "1.142",
        "0.030"
    ],
    [
        "120",
        "34",
        "32.536",
        "-14.697",
        "1.132",
        "1.127",
        "0.012"
    ],
    [
        "121",
        "34",
        "32.106",
        "-14.414",
        "1.061",
        "1.135",
        "-0.012"
    ],
    [
        "122",
        "34",
        "31.667",
        "-14.261",
        "1.026",
        "1.098",
        "-0.027"
    ],
    [
        "123",
        "34",
        "31.306",
        "-14.166",
        "1.001",
        "0.954",
        "-0.056"
    ],
    [
        "124",
        "34",
        "30.981",
        "-14.053",
        "0.948",
        "0.973",
        "-0.074"
    ],
    [
        "125",
        "34",
        "30.608",
        "-13.900",
        "0.950",
        "0.992",
        "-0.086"
    ],
    [
        "126",
        "34",
        "30.183",
        "-13.679",
        "1.001",
        "1.003",
        "-0.084"
    ],
    [
        "127",
        "34",
        "29.690",
        "-13.346",
        "1.017",
        "1.084",
        "-0.069"
    ],
    [
        "128",
        "34",
        "29.091",
        "-12.798",
        "1.109",
        "1.231",
        "0.001"
    ],
    [
        "129",
        "34",
        "28.345",
        "-11.560",
        "1.324",
        "1.337",
        "0.089"
    ],
    [
        "130",
        "34",
        "26.941",
        "-10.546",
        "1.451",
        "1.622",
        "0.106"
    ],
    [
        "131",
        "34",
        "23.862",
        "-9.417",
        "1.339",
        "1.510",
        "0.029"
    ],
    [
        "132",
        "34",
        "17.914",
        "-6.997",
        "1.567",
        "1.541",
        "0.093"
    ],
    [
        "133",
        "34",
        "13.245",
        "-1.706",
        "1.364",
        "1.422",
        "0.082"
    ],
    [
        "134",
        "34",
        "8.241",
        "-0.441",
        "1.087",
        "1.170",
        "0.048"
    ],
    [
        "135",
        "34",
        "6.472",
        "-5.935",
        "1.502",
        "1.444",
        "0.082"
    ],
    [
        "136",
        "34",
        "0.684",
        "-6.480",
        "1.318",
        "1.351",
        "0.074"
    ],
    [
        "137",
        "34",
        "-3.275",
        "1.777",
        "2.036",
        "1.977",
        "0.188"
    ],
    [
        "138",
        "34",
        "-3.768",
        "1.441",
        "2.649",
        "1.868",
        "0.125"
    ],
    [
        "139",
        "34",
        "-7.156",
        "-2.878",
        "2.449",
        "2.061",
        "-0.048"
    ],
    [
        "140",
        "34",
        "-12.302",
        "4.569",
        "3.350",
        "2.916",
        "-0.003"
    ],
    [
        "141",
        "34",
        "-25.026",
        "1.741",
        "6.553",
        "3.998",
        "-0.220"
    ],
    [
        "142",
        "34",
        "-49.493",
        "9.512",
        "6.303",
        "4.651",
        "-0.250"
    ],
    [
        "143",
        "34",
        "-68.230",
        "17.871",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "34",
        "-68.053",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "34",
        "-67.872",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "34",
        "-67.687",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "34",
        "-67.498",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "34",
        "-67.305",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "34",
        "-67.108",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "34",
        "-66.907",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "34",
        "-66.702",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "34",
        "-66.493",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "34",
        "-66.281",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "34",
        "-66.065",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "34",
        "-65.845",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "34",
        "-65.621",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "34",
        "-65.395",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "34",
        "-65.164",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "34",
        "-64.931",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "34",
        "-64.694",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "34",
        "-64.454",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "34",
        "-64.210",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "34",
        "-63.964",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "34",
        "-63.715",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "34",
        "-63.463",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "34",
        "-63.208",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "34",
        "-62.950",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "34",
        "-62.689",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "34",
        "-62.426",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "34",
        "-62.161",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "34",
        "-61.893",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "34",
        "-61.622",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "34",
        "-61.350",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "34",
        "-61.075",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "34",
        "-60.798",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "34",
        "-60.519",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "34",
        "-60.238",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "34",
        "-59.956",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "34",
        "-59.671",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "35",
        "-58.873",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "35",
        "-58.578",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "35",
        "-58.281",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "35",
        "-57.983",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "35",
        "-57.684",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "35",
        "-57.383",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "35",
        "-57.081",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "35",
        "-56.778",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "35",
        "-56.474",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "35",
        "-56.169",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "35",
        "-55.864",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "35",
        "-55.557",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "35",
        "-55.250",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "35",
        "-54.943",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "35",
        "-54.635",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "35",
        "-54.326",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "35",
        "-54.018",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "35",
        "-53.709",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "35",
        "-53.400",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "35",
        "-53.092",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "35",
        "-52.783",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "35",
        "-52.475",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "35",
        "-52.167",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "35",
        "-51.859",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "35",
        "-51.552",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "35",
        "-51.245",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "35",
        "-50.939",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "35",
        "-50.634",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "35",
        "-50.330",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "35",
        "-50.027",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "35",
        "-49.725",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "35",
        "-49.424",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "35",
        "-49.124",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "35",
        "-48.826",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "35",
        "-48.529",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "35",
        "-48.234",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "35",
        "-47.940",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "35",
        "-47.648",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "35",
        "-47.357",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "35",
        "-47.069",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "35",
        "-46.782",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "35",
        "-46.498",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "35",
        "-46.216",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "35",
        "-45.936",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "35",
        "-45.658",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "35",
        "-45.383",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "35",
        "-45.110",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "35",
        "-44.840",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "35",
        "-44.572",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "35",
        "-44.307",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "35",
        "-44.045",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "35",
        "-43.786",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "35",
        "-43.529",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "35",
        "-43.276",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "35",
        "-43.026",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "35",
        "-42.779",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "35",
        "-42.536",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "35",
        "-42.295",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "35",
        "-42.059",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "35",
        "-41.590",
        "22.771",
        "0.128",
        "0.217",
        "0.116"
    ],
    [
        "-120",
        "35",
        "-38.026",
        "16.701",
        "0.374",
        "0.382",
        "0.014"
    ],
    [
        "-119",
        "35",
        "-27.934",
        "2.605",
        "0.290",
        "0.363",
        "0.032"
    ],
    [
        "-118",
        "35",
        "-21.725",
        "1.358",
        "0.285",
        "0.332",
        "-0.040"
    ],
    [
        "-117",
        "35",
        "-19.800",
        "-3.223",
        "0.423",
        "0.435",
        "-0.019"
    ],
    [
        "-116",
        "35",
        "-14.800",
        "-12.432",
        "0.567",
        "0.687",
        "-0.035"
    ],
    [
        "-115",
        "35",
        "-12.756",
        "-11.766",
        "0.609",
        "0.767",
        "0.041"
    ],
    [
        "-114",
        "35",
        "-11.903",
        "-12.043",
        "0.783",
        "0.809",
        "-0.126"
    ],
    [
        "-113",
        "35",
        "-11.357",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "35",
        "-11.476",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "35",
        "-11.589",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "35",
        "-11.699",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "35",
        "-11.807",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "35",
        "-11.910",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "35",
        "-12.011",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "35",
        "-12.107",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "35",
        "-12.199",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "35",
        "-12.288",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "35",
        "-12.374",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "35",
        "-12.456",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "35",
        "-12.534",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "35",
        "-12.607",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "35",
        "-12.678",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "35",
        "-12.745",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "35",
        "-12.808",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "35",
        "-12.867",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "35",
        "-12.922",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "35",
        "-12.973",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "35",
        "-13.021",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "35",
        "-13.064",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "35",
        "-13.104",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "35",
        "-13.139",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "35",
        "-13.170",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "35",
        "-13.199",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "35",
        "-13.222",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "35",
        "-13.242",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "35",
        "-13.257",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "35",
        "-13.269",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "35",
        "-13.277",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "35",
        "-13.281",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "35",
        "-13.280",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "35",
        "-13.277",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "35",
        "-13.268",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "35",
        "-13.256",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "35",
        "-13.240",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "35",
        "-13.220",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "35",
        "-13.196",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "35",
        "-13.168",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "35",
        "-13.136",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "35",
        "-13.100",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "35",
        "-13.060",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "35",
        "-13.016",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "35",
        "-12.969",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "35",
        "-12.917",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "35",
        "-12.862",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "35",
        "-12.803",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "35",
        "-12.739",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "35",
        "-12.673",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "35",
        "-12.602",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "35",
        "-12.528",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "35",
        "-12.449",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "35",
        "-12.367",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "35",
        "-12.282",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "35",
        "-12.192",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "35",
        "-12.098",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "35",
        "-12.002",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "35",
        "-11.902",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "35",
        "-11.798",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "35",
        "-11.691",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "35",
        "-11.580",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "35",
        "-11.465",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "35",
        "-11.347",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "35",
        "-11.227",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "35",
        "-11.102",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "35",
        "-10.974",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "35",
        "-10.843",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "35",
        "-10.709",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "35",
        "-10.571",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "35",
        "-10.430",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "35",
        "-10.286",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "35",
        "-10.140",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "35",
        "-9.990",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "35",
        "-9.837",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "35",
        "-9.681",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "35",
        "-7.501",
        "16.596",
        "0.807",
        "1.316",
        "-0.018"
    ],
    [
        "-36",
        "35",
        "11.754",
        "13.649",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "35",
        "11.893",
        "13.892",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "35",
        "12.033",
        "14.130",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "35",
        "12.175",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "35",
        "12.320",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "35",
        "12.468",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "35",
        "12.617",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "35",
        "12.768",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "35",
        "12.922",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "35",
        "13.078",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "35",
        "13.235",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "35",
        "13.396",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "35",
        "13.558",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "35",
        "13.721",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "35",
        "13.887",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "35",
        "14.054",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "35",
        "14.223",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "35",
        "14.394",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "35",
        "14.567",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "35",
        "14.741",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "35",
        "14.916",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "35",
        "15.093",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "35",
        "15.272",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "35",
        "15.452",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "35",
        "15.633",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "35",
        "15.816",
        "18.289",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "35",
        "15.999",
        "18.406",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "35",
        "16.184",
        "18.519",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "35",
        "16.534",
        "18.557",
        "3.601",
        "4.317",
        "-0.239"
    ],
    [
        "-7",
        "35",
        "16.919",
        "18.640",
        "4.219",
        "4.608",
        "-0.161"
    ],
    [
        "-6",
        "35",
        "17.158",
        "18.925",
        "5.047",
        "4.939",
        "-0.095"
    ],
    [
        "-5",
        "35",
        "17.273",
        "18.848",
        "4.317",
        "4.659",
        "-0.005"
    ],
    [
        "-4",
        "35",
        "17.246",
        "18.914",
        "2.691",
        "3.039",
        "0.153"
    ],
    [
        "-3",
        "35",
        "17.314",
        "19.074",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "35",
        "17.505",
        "19.146",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "35",
        "17.698",
        "19.213",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "35",
        "17.890",
        "19.274",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "35",
        "18.083",
        "19.329",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "35",
        "18.277",
        "19.378",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "35",
        "18.471",
        "19.420",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "35",
        "18.666",
        "19.457",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "35",
        "18.861",
        "19.488",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "35",
        "19.056",
        "19.514",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "35",
        "19.252",
        "19.533",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "35",
        "19.517",
        "19.515",
        "0.158",
        "0.222",
        "0.014"
    ],
    [
        "9",
        "35",
        "20.114",
        "18.995",
        "0.706",
        "0.701",
        "-0.038"
    ],
    [
        "10",
        "35",
        "20.389",
        "18.887",
        "0.695",
        "0.744",
        "-0.027"
    ],
    [
        "11",
        "35",
        "20.545",
        "18.886",
        "0.701",
        "0.746",
        "-0.026"
    ],
    [
        "12",
        "35",
        "20.630",
        "18.962",
        "0.739",
        "0.712",
        "-0.034"
    ],
    [
        "13",
        "35",
        "20.710",
        "19.094",
        "0.680",
        "0.760",
        "-0.004"
    ],
    [
        "14",
        "35",
        "20.796",
        "19.296",
        "0.604",
        "0.703",
        "-0.027"
    ],
    [
        "15",
        "35",
        "20.816",
        "19.473",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "35",
        "21.011",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "35",
        "21.205",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "35",
        "21.399",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "35",
        "21.592",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "35",
        "21.786",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "35",
        "21.978",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "35",
        "20.770",
        "17.323",
        "1.416",
        "2.311",
        "0.173"
    ],
    [
        "23",
        "35",
        "15.810",
        "3.466",
        "1.995",
        "3.207",
        "0.274"
    ],
    [
        "24",
        "35",
        "8.772",
        "-11.015",
        "1.476",
        "1.524",
        "0.251"
    ],
    [
        "25",
        "35",
        "9.283",
        "-5.718",
        "2.306",
        "2.431",
        "0.317"
    ],
    [
        "26",
        "35",
        "9.931",
        "-9.970",
        "1.786",
        "1.812",
        "-0.060"
    ],
    [
        "27",
        "35",
        "17.109",
        "-4.819",
        "2.802",
        "2.479",
        "-0.211"
    ],
    [
        "28",
        "35",
        "23.740",
        "1.565",
        "2.798",
        "2.596",
        "-0.341"
    ],
    [
        "29",
        "35",
        "23.470",
        "12.280",
        "2.654",
        "2.529",
        "-0.450"
    ],
    [
        "30",
        "35",
        "23.672",
        "18.345",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "35",
        "23.855",
        "18.225",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "35",
        "17.488",
        "10.319",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "35",
        "21.100",
        "14.940",
        "3.628",
        "3.423",
        "0.489"
    ],
    [
        "34",
        "35",
        "22.180",
        "15.761",
        "3.703",
        "3.615",
        "0.414"
    ],
    [
        "35",
        "35",
        "21.199",
        "18.591",
        "4.430",
        "4.108",
        "0.332"
    ],
    [
        "36",
        "35",
        "21.689",
        "22.581",
        "4.453",
        "3.418",
        "0.369"
    ],
    [
        "37",
        "35",
        "21.575",
        "24.229",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "35",
        "21.819",
        "24.703",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "35",
        "22.069",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "35",
        "22.323",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "35",
        "22.582",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "35",
        "22.845",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "35",
        "23.113",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "35",
        "23.385",
        "27.386",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "35",
        "23.662",
        "27.806",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "35",
        "25.215",
        "24.880",
        "2.134",
        "3.564",
        "0.240"
    ],
    [
        "47",
        "35",
        "26.505",
        "23.096",
        "2.282",
        "3.484",
        "0.297"
    ],
    [
        "48",
        "35",
        "27.623",
        "22.075",
        "2.368",
        "3.062",
        "0.363"
    ],
    [
        "49",
        "35",
        "28.685",
        "21.314",
        "2.137",
        "2.694",
        "0.394"
    ],
    [
        "50",
        "35",
        "29.879",
        "20.231",
        "2.002",
        "2.319",
        "0.366"
    ],
    [
        "51",
        "35",
        "30.559",
        "19.755",
        "1.956",
        "1.972",
        "0.338"
    ],
    [
        "52",
        "35",
        "30.453",
        "20.108",
        "1.737",
        "1.851",
        "0.282"
    ],
    [
        "53",
        "35",
        "30.639",
        "18.902",
        "1.687",
        "1.915",
        "0.227"
    ],
    [
        "54",
        "35",
        "30.673",
        "18.238",
        "1.730",
        "1.864",
        "0.243"
    ],
    [
        "55",
        "35",
        "30.631",
        "17.390",
        "1.740",
        "2.143",
        "0.236"
    ],
    [
        "56",
        "35",
        "30.512",
        "16.032",
        "1.878",
        "2.342",
        "0.236"
    ],
    [
        "57",
        "35",
        "30.332",
        "13.850",
        "2.091",
        "2.524",
        "0.255"
    ],
    [
        "58",
        "35",
        "30.188",
        "11.794",
        "1.896",
        "2.439",
        "0.202"
    ],
    [
        "59",
        "35",
        "30.113",
        "9.552",
        "1.880",
        "2.274",
        "0.209"
    ],
    [
        "60",
        "35",
        "29.857",
        "6.844",
        "2.015",
        "2.276",
        "0.217"
    ],
    [
        "61",
        "35",
        "29.674",
        "5.648",
        "1.637",
        "1.717",
        "0.162"
    ],
    [
        "62",
        "35",
        "29.573",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "35",
        "29.624",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "35",
        "29.672",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "35",
        "29.717",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "35",
        "29.759",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "35",
        "29.798",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "35",
        "30.028",
        "3.746",
        "0.255",
        "0.418",
        "-0.337"
    ],
    [
        "69",
        "35",
        "31.103",
        "9.261",
        "1.375",
        "1.449",
        "-0.310"
    ],
    [
        "70",
        "35",
        "30.833",
        "15.575",
        "1.469",
        "1.672",
        "-0.213"
    ],
    [
        "71",
        "35",
        "30.649",
        "19.616",
        "1.211",
        "1.389",
        "0.086"
    ],
    [
        "72",
        "35",
        "30.530",
        "21.340",
        "1.297",
        "1.331",
        "0.155"
    ],
    [
        "73",
        "35",
        "30.370",
        "22.772",
        "1.349",
        "1.436",
        "0.203"
    ],
    [
        "74",
        "35",
        "30.080",
        "24.105",
        "1.544",
        "1.666",
        "0.217"
    ],
    [
        "75",
        "35",
        "29.929",
        "25.231",
        "1.973",
        "1.922",
        "0.192"
    ],
    [
        "76",
        "35",
        "30.698",
        "26.119",
        "2.179",
        "2.341",
        "0.107"
    ],
    [
        "77",
        "35",
        "32.432",
        "24.548",
        "2.408",
        "2.687",
        "0.038"
    ],
    [
        "78",
        "35",
        "34.241",
        "20.423",
        "2.526",
        "2.579",
        "0.007"
    ],
    [
        "79",
        "35",
        "33.792",
        "17.484",
        "2.092",
        "2.565",
        "-0.054"
    ],
    [
        "80",
        "35",
        "32.462",
        "16.475",
        "1.625",
        "2.189",
        "0.055"
    ],
    [
        "81",
        "35",
        "34.735",
        "13.890",
        "2.309",
        "2.195",
        "-0.001"
    ],
    [
        "82",
        "35",
        "37.543",
        "14.114",
        "2.062",
        "2.073",
        "0.020"
    ],
    [
        "83",
        "35",
        "38.555",
        "15.038",
        "1.823",
        "1.774",
        "0.032"
    ],
    [
        "84",
        "35",
        "38.948",
        "15.300",
        "1.665",
        "1.532",
        "0.044"
    ],
    [
        "85",
        "35",
        "39.871",
        "14.897",
        "1.468",
        "1.453",
        "0.064"
    ],
    [
        "86",
        "35",
        "40.979",
        "14.530",
        "1.413",
        "1.412",
        "0.043"
    ],
    [
        "87",
        "35",
        "41.800",
        "13.589",
        "1.445",
        "1.386",
        "0.021"
    ],
    [
        "88",
        "35",
        "42.361",
        "11.798",
        "1.355",
        "1.425",
        "0.016"
    ],
    [
        "89",
        "35",
        "43.132",
        "9.712",
        "1.318",
        "1.403",
        "0.014"
    ],
    [
        "90",
        "35",
        "44.195",
        "7.570",
        "1.330",
        "1.316",
        "0.020"
    ],
    [
        "91",
        "35",
        "45.328",
        "5.509",
        "1.199",
        "1.271",
        "0.048"
    ],
    [
        "92",
        "35",
        "46.229",
        "4.093",
        "1.126",
        "1.188",
        "0.045"
    ],
    [
        "93",
        "35",
        "46.473",
        "3.898",
        "1.094",
        "1.049",
        "-0.030"
    ],
    [
        "94",
        "35",
        "46.536",
        "4.026",
        "1.098",
        "1.174",
        "-0.030"
    ],
    [
        "95",
        "35",
        "46.300",
        "3.880",
        "1.129",
        "1.216",
        "0.008"
    ],
    [
        "96",
        "35",
        "45.455",
        "3.516",
        "1.216",
        "1.213",
        "0.037"
    ],
    [
        "97",
        "35",
        "44.559",
        "3.253",
        "1.144",
        "1.251",
        "0.039"
    ],
    [
        "98",
        "35",
        "44.077",
        "2.407",
        "1.051",
        "1.100",
        "0.056"
    ],
    [
        "99",
        "35",
        "41.960",
        "2.394",
        "1.208",
        "1.222",
        "0.013"
    ],
    [
        "100",
        "35",
        "40.461",
        "1.264",
        "1.173",
        "1.301",
        "-0.002"
    ],
    [
        "101",
        "35",
        "39.585",
        "-0.509",
        "1.173",
        "1.293",
        "-0.010"
    ],
    [
        "102",
        "35",
        "38.985",
        "-2.805",
        "1.226",
        "1.241",
        "0.006"
    ],
    [
        "103",
        "35",
        "38.645",
        "-5.654",
        "1.146",
        "1.238",
        "0.013"
    ],
    [
        "104",
        "35",
        "38.594",
        "-8.323",
        "1.138",
        "1.206",
        "0.021"
    ],
    [
        "105",
        "35",
        "38.162",
        "-10.377",
        "1.190",
        "1.128",
        "0.025"
    ],
    [
        "106",
        "35",
        "35.841",
        "-11.333",
        "1.115",
        "1.164",
        "0.031"
    ],
    [
        "107",
        "35",
        "33.723",
        "-10.792",
        "1.101",
        "1.144",
        "0.007"
    ],
    [
        "108",
        "35",
        "33.302",
        "-10.638",
        "1.079",
        "1.020",
        "0.016"
    ],
    [
        "109",
        "35",
        "32.579",
        "-10.945",
        "1.001",
        "1.059",
        "-0.003"
    ],
    [
        "110",
        "35",
        "32.413",
        "-11.443",
        "1.032",
        "1.105",
        "-0.080"
    ],
    [
        "111",
        "35",
        "32.847",
        "-12.027",
        "1.143",
        "1.137",
        "-0.053"
    ],
    [
        "112",
        "35",
        "33.239",
        "-12.415",
        "1.081",
        "1.173",
        "-0.040"
    ],
    [
        "113",
        "35",
        "33.504",
        "-12.313",
        "1.068",
        "1.159",
        "-0.031"
    ],
    [
        "114",
        "35",
        "33.733",
        "-12.530",
        "1.134",
        "1.138",
        "-0.027"
    ],
    [
        "115",
        "35",
        "33.711",
        "-13.080",
        "1.048",
        "1.162",
        "-0.020"
    ],
    [
        "116",
        "35",
        "33.581",
        "-13.241",
        "1.003",
        "1.040",
        "-0.017"
    ],
    [
        "117",
        "35",
        "33.426",
        "-13.433",
        "1.018",
        "1.010",
        "0.007"
    ],
    [
        "118",
        "35",
        "33.525",
        "-14.476",
        "0.802",
        "0.843",
        "-0.032"
    ],
    [
        "119",
        "35",
        "32.857",
        "-14.452",
        "0.971",
        "1.073",
        "-0.009"
    ],
    [
        "120",
        "35",
        "32.021",
        "-14.478",
        "1.043",
        "1.072",
        "0.006"
    ],
    [
        "121",
        "35",
        "31.593",
        "-14.288",
        "0.932",
        "1.012",
        "-0.012"
    ],
    [
        "122",
        "35",
        "31.258",
        "-14.204",
        "0.915",
        "0.981",
        "-0.018"
    ],
    [
        "123",
        "35",
        "30.906",
        "-14.142",
        "0.946",
        "0.933",
        "-0.032"
    ],
    [
        "124",
        "35",
        "30.529",
        "-14.041",
        "0.908",
        "0.957",
        "-0.050"
    ],
    [
        "125",
        "35",
        "30.108",
        "-13.910",
        "0.906",
        "0.968",
        "-0.069"
    ],
    [
        "126",
        "35",
        "29.637",
        "-13.755",
        "0.946",
        "0.950",
        "-0.080"
    ],
    [
        "127",
        "35",
        "29.131",
        "-13.531",
        "0.919",
        "0.977",
        "-0.078"
    ],
    [
        "128",
        "35",
        "28.601",
        "-13.098",
        "0.952",
        "1.042",
        "-0.034"
    ],
    [
        "129",
        "35",
        "27.973",
        "-12.296",
        "1.108",
        "1.151",
        "0.032"
    ],
    [
        "130",
        "35",
        "27.409",
        "-11.542",
        "1.269",
        "1.458",
        "0.070"
    ],
    [
        "131",
        "35",
        "26.479",
        "-10.826",
        "1.456",
        "1.606",
        "0.213"
    ],
    [
        "132",
        "35",
        "25.091",
        "-9.957",
        "1.500",
        "1.461",
        "0.132"
    ],
    [
        "133",
        "35",
        "23.236",
        "-9.361",
        "1.158",
        "1.316",
        "0.072"
    ],
    [
        "134",
        "35",
        "21.932",
        "-9.981",
        "1.056",
        "1.172",
        "0.066"
    ],
    [
        "135",
        "35",
        "18.904",
        "-9.706",
        "1.092",
        "1.008",
        "0.033"
    ],
    [
        "136",
        "35",
        "10.601",
        "-10.877",
        "0.940",
        "0.937",
        "0.031"
    ],
    [
        "137",
        "35",
        "3.430",
        "-6.656",
        "0.957",
        "0.960",
        "0.049"
    ],
    [
        "138",
        "35",
        "1.129",
        "-7.534",
        "1.064",
        "0.919",
        "0.026"
    ],
    [
        "139",
        "35",
        "-7.661",
        "-8.313",
        "0.877",
        "0.820",
        "0.024"
    ],
    [
        "140",
        "35",
        "-10.008",
        "3.388",
        "1.363",
        "1.445",
        "-0.013"
    ],
    [
        "141",
        "35",
        "-15.379",
        "-4.146",
        "5.529",
        "3.426",
        "-0.295"
    ],
    [
        "142",
        "35",
        "-37.475",
        "6.914",
        "6.421",
        "4.498",
        "-0.311"
    ],
    [
        "143",
        "35",
        "-62.516",
        "16.062",
        "4.272",
        "3.416",
        "-0.277"
    ],
    [
        "144",
        "35",
        "-67.764",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "35",
        "-67.579",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "35",
        "-67.389",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "35",
        "-67.195",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "35",
        "-66.997",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "35",
        "-66.795",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "35",
        "-66.589",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "35",
        "-66.378",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "35",
        "-66.164",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "35",
        "-65.946",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "35",
        "-65.725",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "35",
        "-65.499",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "35",
        "-65.270",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "35",
        "-65.037",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "35",
        "-64.801",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "35",
        "-64.561",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "35",
        "-64.318",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "35",
        "-64.072",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "35",
        "-63.823",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "35",
        "-63.570",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "35",
        "-63.314",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "35",
        "-63.056",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "35",
        "-62.794",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "35",
        "-62.530",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "35",
        "-62.262",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "35",
        "-61.993",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "35",
        "-61.720",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "35",
        "-61.445",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "35",
        "-61.168",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "35",
        "-60.888",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "35",
        "-60.607",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "35",
        "-60.323",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "35",
        "-60.036",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "35",
        "-59.748",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "35",
        "-59.458",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "35",
        "-59.167",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "36",
        "-58.343",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "36",
        "-58.041",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "36",
        "-57.737",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "36",
        "-57.431",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "36",
        "-57.124",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "36",
        "-56.816",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "36",
        "-56.507",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "36",
        "-56.196",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "36",
        "-55.885",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "36",
        "-55.572",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "36",
        "-55.259",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "36",
        "-54.945",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "36",
        "-54.631",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "36",
        "-54.315",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "36",
        "-54.000",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "36",
        "-53.684",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "36",
        "-53.368",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "36",
        "-53.051",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "36",
        "-52.735",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "36",
        "-52.418",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "36",
        "-52.102",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "36",
        "-51.786",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "36",
        "-51.471",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "36",
        "-51.155",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "36",
        "-50.841",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "36",
        "-50.526",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "36",
        "-50.213",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "36",
        "-49.900",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "36",
        "-49.589",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "36",
        "-49.278",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "36",
        "-48.969",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "36",
        "-48.660",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "36",
        "-48.353",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "36",
        "-48.047",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "36",
        "-47.743",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "36",
        "-47.440",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "36",
        "-47.139",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "36",
        "-46.840",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "36",
        "-46.542",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "36",
        "-46.247",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "36",
        "-45.953",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "36",
        "-45.662",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "36",
        "-45.372",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "36",
        "-45.085",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "36",
        "-44.801",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "36",
        "-44.519",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "36",
        "-44.239",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "36",
        "-43.962",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "36",
        "-43.688",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "36",
        "-43.416",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "36",
        "-43.148",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "36",
        "-42.882",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "36",
        "-42.620",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "36",
        "-42.360",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "36",
        "-42.104",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "36",
        "-41.851",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "36",
        "-41.601",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "36",
        "-41.355",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "36",
        "-41.112",
        "23.485",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "36",
        "-36.689",
        "20.331",
        "0.531",
        "0.631",
        "0.128"
    ],
    [
        "-120",
        "36",
        "-21.363",
        "-0.269",
        "0.533",
        "0.531",
        "-0.011"
    ],
    [
        "-119",
        "36",
        "-22.329",
        "-2.957",
        "0.572",
        "0.679",
        "0.132"
    ],
    [
        "-118",
        "36",
        "-20.306",
        "-3.916",
        "0.296",
        "0.305",
        "-0.006"
    ],
    [
        "-117",
        "36",
        "-15.751",
        "-8.954",
        "0.455",
        "0.491",
        "-0.014"
    ],
    [
        "-116",
        "36",
        "-14.388",
        "-12.076",
        "0.618",
        "0.692",
        "-0.068"
    ],
    [
        "-115",
        "36",
        "-13.822",
        "-12.279",
        "0.748",
        "0.826",
        "-0.049"
    ],
    [
        "-114",
        "36",
        "-12.890",
        "-12.284",
        "0.892",
        "0.932",
        "-0.103"
    ],
    [
        "-113",
        "36",
        "-12.203",
        "-11.928",
        "0.727",
        "0.911",
        "-0.049"
    ],
    [
        "-112",
        "36",
        "-11.850",
        "-11.524",
        "0.498",
        "0.630",
        "-0.024"
    ],
    [
        "-111",
        "36",
        "-11.869",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "36",
        "-11.983",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "36",
        "-12.092",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "36",
        "-12.199",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "36",
        "-12.301",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "36",
        "-12.399",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "36",
        "-12.495",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "36",
        "-12.586",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "36",
        "-12.673",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "36",
        "-12.757",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "36",
        "-12.837",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "36",
        "-12.913",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "36",
        "-12.985",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "36",
        "-13.054",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "36",
        "-13.117",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "36",
        "-13.178",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "36",
        "-13.235",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "36",
        "-13.287",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "36",
        "-13.335",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "36",
        "-13.381",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "36",
        "-13.420",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "36",
        "-13.457",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "36",
        "-13.490",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "36",
        "-13.518",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "36",
        "-13.542",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "36",
        "-13.563",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "36",
        "-13.578",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "36",
        "-13.591",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "36",
        "-13.599",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "36",
        "-13.602",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "36",
        "-13.602",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "36",
        "-13.598",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "36",
        "-13.590",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "36",
        "-13.577",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "36",
        "-13.561",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "36",
        "-13.541",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "36",
        "-13.516",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "36",
        "-13.487",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "36",
        "-13.454",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "36",
        "-13.418",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "36",
        "-13.377",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "36",
        "-13.332",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "36",
        "-13.283",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "36",
        "-13.230",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "36",
        "-13.173",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "36",
        "-13.112",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "36",
        "-13.047",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "36",
        "-12.979",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "36",
        "-12.907",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "36",
        "-12.830",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "36",
        "-12.750",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "36",
        "-12.667",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "36",
        "-12.578",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "36",
        "-12.486",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "36",
        "-12.392",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "36",
        "-12.292",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "36",
        "-12.190",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "36",
        "-12.083",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "36",
        "-11.973",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "36",
        "-11.859",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "36",
        "-11.742",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "36",
        "-11.622",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "36",
        "-11.497",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "36",
        "-11.369",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "36",
        "-11.239",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "36",
        "-11.104",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "36",
        "-10.967",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "36",
        "-10.825",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "36",
        "-10.681",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "36",
        "-10.534",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "36",
        "-10.384",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "36",
        "-10.230",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "36",
        "-10.073",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "36",
        "-9.913",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "36",
        "-9.750",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "36",
        "-9.585",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "36",
        "-9.417",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "36",
        "5.397",
        "13.698",
        "2.487",
        "2.708",
        "-0.235"
    ],
    [
        "-33",
        "36",
        "11.742",
        "14.363",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "36",
        "11.890",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "36",
        "12.041",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "36",
        "12.194",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "36",
        "12.350",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "36",
        "12.508",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "36",
        "12.667",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "36",
        "12.829",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "36",
        "12.993",
        "16.070",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "36",
        "13.158",
        "16.262",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "36",
        "13.327",
        "16.449",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "36",
        "13.497",
        "16.632",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "36",
        "13.668",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "36",
        "13.841",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "36",
        "14.016",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "36",
        "14.193",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "36",
        "14.371",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "36",
        "14.551",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "36",
        "14.733",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "36",
        "14.916",
        "17.901",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "36",
        "15.100",
        "18.036",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "36",
        "15.285",
        "18.166",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "36",
        "15.795",
        "18.329",
        "2.361",
        "3.285",
        "-0.040"
    ],
    [
        "-10",
        "36",
        "16.455",
        "18.146",
        "3.131",
        "4.614",
        "-0.139"
    ],
    [
        "-9",
        "36",
        "16.889",
        "18.118",
        "3.565",
        "4.414",
        "-0.329"
    ],
    [
        "-8",
        "36",
        "17.316",
        "18.145",
        "3.676",
        "4.586",
        "-0.323"
    ],
    [
        "-7",
        "36",
        "17.724",
        "18.172",
        "3.913",
        "4.426",
        "-0.261"
    ],
    [
        "-6",
        "36",
        "18.030",
        "18.350",
        "4.405",
        "4.130",
        "-0.206"
    ],
    [
        "-5",
        "36",
        "18.186",
        "18.439",
        "4.310",
        "4.353",
        "-0.061"
    ],
    [
        "-4",
        "36",
        "18.320",
        "18.421",
        "4.126",
        "3.565",
        "-0.073"
    ],
    [
        "-3",
        "36",
        "18.483",
        "18.286",
        "3.823",
        "2.668",
        "-0.100"
    ],
    [
        "-2",
        "36",
        "19.068",
        "18.183",
        "3.199",
        "2.010",
        "-0.398"
    ],
    [
        "-1",
        "36",
        "19.337",
        "18.289",
        "2.880",
        "1.986",
        "-0.449"
    ],
    [
        "0",
        "36",
        "19.456",
        "19.025",
        "0.810",
        "0.922",
        "-0.106"
    ],
    [
        "1",
        "36",
        "19.437",
        "18.437",
        "0.528",
        "0.637",
        "-0.080"
    ],
    [
        "2",
        "36",
        "19.244",
        "18.602",
        "0.554",
        "0.658",
        "-0.039"
    ],
    [
        "3",
        "36",
        "19.236",
        "18.742",
        "0.622",
        "0.636",
        "-0.050"
    ],
    [
        "4",
        "36",
        "19.429",
        "18.742",
        "0.585",
        "0.668",
        "-0.067"
    ],
    [
        "5",
        "36",
        "19.841",
        "18.565",
        "0.644",
        "0.715",
        "-0.055"
    ],
    [
        "6",
        "36",
        "20.210",
        "18.453",
        "0.725",
        "0.703",
        "-0.042"
    ],
    [
        "7",
        "36",
        "20.516",
        "18.401",
        "0.689",
        "0.733",
        "-0.043"
    ],
    [
        "8",
        "36",
        "20.802",
        "18.299",
        "0.707",
        "0.750",
        "-0.050"
    ],
    [
        "9",
        "36",
        "20.997",
        "18.202",
        "0.782",
        "0.764",
        "-0.044"
    ],
    [
        "10",
        "36",
        "21.059",
        "18.187",
        "0.753",
        "0.804",
        "-0.033"
    ],
    [
        "11",
        "36",
        "21.061",
        "18.235",
        "0.752",
        "0.801",
        "-0.032"
    ],
    [
        "12",
        "36",
        "21.009",
        "18.398",
        "0.776",
        "0.751",
        "-0.013"
    ],
    [
        "13",
        "36",
        "20.984",
        "18.650",
        "0.709",
        "0.783",
        "-0.012"
    ],
    [
        "14",
        "36",
        "20.942",
        "19.155",
        "0.640",
        "0.739",
        "0.003"
    ],
    [
        "15",
        "36",
        "20.853",
        "19.330",
        "0.610",
        "0.606",
        "-0.022"
    ],
    [
        "16",
        "36",
        "20.796",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "36",
        "20.996",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "36",
        "21.194",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "36",
        "21.393",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "36",
        "21.591",
        "19.243",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "36",
        "21.788",
        "19.178",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "36",
        "16.647",
        "0.236",
        "1.823",
        "3.271",
        "0.155"
    ],
    [
        "23",
        "36",
        "11.575",
        "-10.762",
        "1.529",
        "1.956",
        "0.164"
    ],
    [
        "24",
        "36",
        "9.865",
        "-10.555",
        "1.918",
        "2.481",
        "0.148"
    ],
    [
        "25",
        "36",
        "8.542",
        "-10.545",
        "1.694",
        "2.224",
        "0.072"
    ],
    [
        "26",
        "36",
        "10.417",
        "-13.144",
        "1.777",
        "1.871",
        "0.140"
    ],
    [
        "27",
        "36",
        "14.474",
        "-12.869",
        "1.991",
        "1.649",
        "-0.128"
    ],
    [
        "28",
        "36",
        "17.403",
        "-10.707",
        "1.457",
        "1.388",
        "-0.332"
    ],
    [
        "29",
        "36",
        "16.437",
        "2.856",
        "2.006",
        "2.188",
        "-0.411"
    ],
    [
        "30",
        "36",
        "17.772",
        "8.275",
        "2.111",
        "2.123",
        "-0.247"
    ],
    [
        "31",
        "36",
        "18.264",
        "11.651",
        "2.152",
        "2.628",
        "-0.224"
    ],
    [
        "32",
        "36",
        "15.231",
        "10.319",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "36",
        "15.347",
        "11.988",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "36",
        "15.477",
        "13.654",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "36",
        "16.007",
        "15.552",
        "0.981",
        "1.352",
        "0.147"
    ],
    [
        "36",
        "36",
        "20.789",
        "19.823",
        "1.738",
        "1.732",
        "-0.057"
    ],
    [
        "37",
        "36",
        "20.723",
        "24.229",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "36",
        "20.975",
        "24.703",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "36",
        "21.230",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "36",
        "21.491",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "36",
        "21.756",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "36",
        "22.026",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "36",
        "22.300",
        "26.959",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "36",
        "22.728",
        "27.310",
        "0.570",
        "1.062",
        "0.169"
    ],
    [
        "45",
        "36",
        "24.416",
        "24.389",
        "2.513",
        "3.359",
        "0.249"
    ],
    [
        "46",
        "36",
        "25.843",
        "22.294",
        "2.390",
        "3.266",
        "0.239"
    ],
    [
        "47",
        "36",
        "26.846",
        "21.752",
        "2.203",
        "2.775",
        "0.230"
    ],
    [
        "48",
        "36",
        "27.549",
        "22.302",
        "1.872",
        "1.786",
        "0.209"
    ],
    [
        "49",
        "36",
        "28.384",
        "19.888",
        "2.271",
        "2.400",
        "0.276"
    ],
    [
        "50",
        "36",
        "28.640",
        "19.063",
        "2.250",
        "2.277",
        "0.287"
    ],
    [
        "51",
        "36",
        "28.564",
        "19.592",
        "2.012",
        "1.805",
        "0.303"
    ],
    [
        "52",
        "36",
        "28.526",
        "17.826",
        "1.749",
        "2.012",
        "0.208"
    ],
    [
        "53",
        "36",
        "28.509",
        "16.434",
        "1.686",
        "1.985",
        "0.240"
    ],
    [
        "54",
        "36",
        "28.728",
        "15.860",
        "1.678",
        "2.229",
        "0.195"
    ],
    [
        "55",
        "36",
        "29.014",
        "15.126",
        "1.734",
        "2.460",
        "0.210"
    ],
    [
        "56",
        "36",
        "29.278",
        "14.001",
        "1.904",
        "2.685",
        "0.194"
    ],
    [
        "57",
        "36",
        "29.541",
        "12.267",
        "2.132",
        "2.605",
        "0.200"
    ],
    [
        "58",
        "36",
        "29.742",
        "10.834",
        "2.091",
        "2.183",
        "0.261"
    ],
    [
        "59",
        "36",
        "29.876",
        "7.777",
        "2.055",
        "2.346",
        "0.269"
    ],
    [
        "60",
        "36",
        "29.781",
        "6.237",
        "2.012",
        "2.173",
        "0.333"
    ],
    [
        "61",
        "36",
        "29.625",
        "5.571",
        "1.575",
        "1.544",
        "0.280"
    ],
    [
        "62",
        "36",
        "29.561",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "36",
        "29.614",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "36",
        "29.663",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "36",
        "29.710",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "36",
        "29.753",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "36",
        "29.793",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "36",
        "30.157",
        "4.976",
        "0.628",
        "0.715",
        "-0.021"
    ],
    [
        "69",
        "36",
        "30.501",
        "8.679",
        "0.859",
        "0.960",
        "-0.090"
    ],
    [
        "70",
        "36",
        "30.553",
        "13.443",
        "0.922",
        "1.155",
        "-0.026"
    ],
    [
        "71",
        "36",
        "30.181",
        "17.391",
        "0.989",
        "1.225",
        "0.049"
    ],
    [
        "72",
        "36",
        "29.867",
        "19.826",
        "1.133",
        "1.299",
        "0.069"
    ],
    [
        "73",
        "36",
        "29.660",
        "21.635",
        "1.341",
        "1.530",
        "0.099"
    ],
    [
        "74",
        "36",
        "29.483",
        "23.183",
        "1.643",
        "1.890",
        "0.071"
    ],
    [
        "75",
        "36",
        "29.249",
        "23.872",
        "2.155",
        "2.176",
        "0.032"
    ],
    [
        "76",
        "36",
        "31.637",
        "22.940",
        "2.312",
        "2.642",
        "-0.024"
    ],
    [
        "77",
        "36",
        "34.549",
        "23.146",
        "2.350",
        "2.602",
        "0.004"
    ],
    [
        "78",
        "36",
        "33.998",
        "20.662",
        "2.425",
        "2.757",
        "0.110"
    ],
    [
        "79",
        "36",
        "34.008",
        "18.480",
        "1.359",
        "2.192",
        "0.167"
    ],
    [
        "80",
        "36",
        "31.221",
        "16.478",
        "2.054",
        "2.120",
        "-0.072"
    ],
    [
        "81",
        "36",
        "29.523",
        "14.360",
        "2.322",
        "1.914",
        "-0.030"
    ],
    [
        "82",
        "36",
        "31.577",
        "13.701",
        "2.046",
        "1.848",
        "0.054"
    ],
    [
        "83",
        "36",
        "34.271",
        "13.815",
        "1.818",
        "1.719",
        "0.078"
    ],
    [
        "84",
        "36",
        "36.381",
        "13.837",
        "1.722",
        "1.525",
        "0.083"
    ],
    [
        "85",
        "36",
        "38.156",
        "13.718",
        "1.540",
        "1.498",
        "0.108"
    ],
    [
        "86",
        "36",
        "39.545",
        "13.551",
        "1.490",
        "1.492",
        "0.053"
    ],
    [
        "87",
        "36",
        "40.348",
        "12.916",
        "1.521",
        "1.483",
        "-0.008"
    ],
    [
        "88",
        "36",
        "40.863",
        "11.268",
        "1.406",
        "1.512",
        "-0.004"
    ],
    [
        "89",
        "36",
        "41.506",
        "9.040",
        "1.352",
        "1.466",
        "-0.008"
    ],
    [
        "90",
        "36",
        "42.354",
        "6.952",
        "1.352",
        "1.350",
        "0.008"
    ],
    [
        "91",
        "36",
        "43.203",
        "5.329",
        "1.210",
        "1.290",
        "0.045"
    ],
    [
        "92",
        "36",
        "43.532",
        "4.623",
        "1.128",
        "1.228",
        "0.025"
    ],
    [
        "93",
        "36",
        "43.348",
        "4.427",
        "1.117",
        "1.112",
        "0.046"
    ],
    [
        "94",
        "36",
        "42.516",
        "4.218",
        "0.992",
        "1.086",
        "-0.018"
    ],
    [
        "95",
        "36",
        "41.335",
        "4.424",
        "0.888",
        "0.989",
        "0.065"
    ],
    [
        "96",
        "36",
        "40.575",
        "3.299",
        "1.073",
        "1.072",
        "0.044"
    ],
    [
        "97",
        "36",
        "40.717",
        "2.964",
        "1.047",
        "1.176",
        "0.083"
    ],
    [
        "98",
        "36",
        "41.088",
        "3.101",
        "1.112",
        "1.225",
        "0.038"
    ],
    [
        "99",
        "36",
        "40.944",
        "2.156",
        "1.235",
        "1.285",
        "0.029"
    ],
    [
        "100",
        "36",
        "40.752",
        "0.450",
        "1.195",
        "1.346",
        "-0.005"
    ],
    [
        "101",
        "36",
        "40.807",
        "-1.593",
        "1.151",
        "1.273",
        "-0.057"
    ],
    [
        "102",
        "36",
        "41.233",
        "-3.525",
        "1.165",
        "1.202",
        "0.042"
    ],
    [
        "103",
        "36",
        "41.623",
        "-5.805",
        "1.067",
        "1.204",
        "0.047"
    ],
    [
        "104",
        "36",
        "41.014",
        "-8.317",
        "1.079",
        "1.157",
        "0.037"
    ],
    [
        "105",
        "36",
        "39.020",
        "-9.765",
        "1.196",
        "1.120",
        "0.055"
    ],
    [
        "106",
        "36",
        "35.017",
        "-10.353",
        "1.119",
        "1.157",
        "0.042"
    ],
    [
        "107",
        "36",
        "32.504",
        "-10.431",
        "1.064",
        "1.121",
        "0.015"
    ],
    [
        "108",
        "36",
        "32.297",
        "-10.339",
        "1.014",
        "0.947",
        "0.003"
    ],
    [
        "109",
        "36",
        "32.173",
        "-10.457",
        "1.010",
        "1.022",
        "-0.021"
    ],
    [
        "110",
        "36",
        "32.080",
        "-10.699",
        "1.052",
        "1.128",
        "-0.032"
    ],
    [
        "111",
        "36",
        "32.362",
        "-11.166",
        "1.131",
        "1.124",
        "-0.049"
    ],
    [
        "112",
        "36",
        "33.049",
        "-11.948",
        "1.059",
        "1.147",
        "-0.053"
    ],
    [
        "113",
        "36",
        "33.591",
        "-12.399",
        "1.025",
        "1.103",
        "-0.039"
    ],
    [
        "114",
        "36",
        "33.725",
        "-12.777",
        "1.104",
        "1.102",
        "0.001"
    ],
    [
        "115",
        "36",
        "33.522",
        "-13.495",
        "1.036",
        "1.136",
        "0.005"
    ],
    [
        "116",
        "36",
        "33.441",
        "-13.565",
        "1.001",
        "1.085",
        "0.013"
    ],
    [
        "117",
        "36",
        "33.393",
        "-13.525",
        "1.012",
        "0.996",
        "0.038"
    ],
    [
        "118",
        "36",
        "33.128",
        "-13.622",
        "0.906",
        "1.029",
        "0.044"
    ],
    [
        "119",
        "36",
        "32.220",
        "-13.618",
        "0.884",
        "0.976",
        "-0.005"
    ],
    [
        "120",
        "36",
        "31.617",
        "-14.061",
        "1.041",
        "1.037",
        "-0.018"
    ],
    [
        "121",
        "36",
        "31.282",
        "-14.175",
        "0.909",
        "1.013",
        "-0.013"
    ],
    [
        "122",
        "36",
        "30.978",
        "-14.194",
        "0.880",
        "0.926",
        "-0.018"
    ],
    [
        "123",
        "36",
        "30.634",
        "-14.138",
        "0.906",
        "0.905",
        "-0.012"
    ],
    [
        "124",
        "36",
        "30.224",
        "-14.023",
        "0.867",
        "0.942",
        "-0.032"
    ],
    [
        "125",
        "36",
        "29.789",
        "-13.916",
        "0.863",
        "0.951",
        "-0.054"
    ],
    [
        "126",
        "36",
        "29.337",
        "-13.853",
        "0.886",
        "0.907",
        "-0.071"
    ],
    [
        "127",
        "36",
        "28.897",
        "-13.917",
        "0.760",
        "0.844",
        "-0.093"
    ],
    [
        "128",
        "36",
        "28.441",
        "-13.571",
        "0.795",
        "0.900",
        "-0.010"
    ],
    [
        "129",
        "36",
        "27.899",
        "-13.054",
        "0.950",
        "1.003",
        "-0.058"
    ],
    [
        "130",
        "36",
        "27.591",
        "-12.964",
        "0.911",
        "1.146",
        "-0.088"
    ],
    [
        "131",
        "36",
        "28.054",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "36",
        "27.904",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "36",
        "27.752",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "36",
        "27.599",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "36",
        "27.445",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "36",
        "21.665",
        "-12.425",
        "0.883",
        "1.024",
        "-0.010"
    ],
    [
        "137",
        "36",
        "11.200",
        "-10.427",
        "1.146",
        "1.105",
        "0.014"
    ],
    [
        "138",
        "36",
        "1.902",
        "-7.649",
        "1.046",
        "0.805",
        "0.017"
    ],
    [
        "139",
        "36",
        "-0.051",
        "-9.528",
        "0.912",
        "1.020",
        "0.062"
    ],
    [
        "140",
        "36",
        "-2.258",
        "-9.624",
        "0.630",
        "0.607",
        "0.025"
    ],
    [
        "141",
        "36",
        "-3.915",
        "-9.788",
        "2.670",
        "2.338",
        "-0.186"
    ],
    [
        "142",
        "36",
        "-28.849",
        "2.520",
        "5.882",
        "4.141",
        "-0.399"
    ],
    [
        "143",
        "36",
        "-50.812",
        "11.550",
        "5.914",
        "4.119",
        "-0.348"
    ],
    [
        "144",
        "36",
        "-67.455",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "36",
        "-67.265",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "36",
        "-67.070",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "36",
        "-66.872",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "36",
        "-66.669",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "36",
        "-66.461",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "36",
        "-66.250",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "36",
        "-66.035",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "36",
        "-65.815",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "36",
        "-65.592",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "36",
        "-65.364",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "36",
        "-65.133",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "36",
        "-64.899",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "36",
        "-64.660",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "36",
        "-64.418",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "36",
        "-64.172",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "36",
        "-63.923",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "36",
        "-63.671",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "36",
        "-63.415",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "36",
        "-63.156",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "36",
        "-62.894",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "36",
        "-62.629",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "36",
        "-62.361",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "36",
        "-62.090",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "36",
        "-61.817",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "36",
        "-61.540",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "36",
        "-61.261",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "36",
        "-60.979",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "36",
        "-60.695",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "36",
        "-60.409",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "36",
        "-60.120",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "36",
        "-59.829",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "36",
        "-59.535",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "36",
        "-59.240",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "36",
        "-58.943",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "36",
        "-58.644",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "37",
        "-57.796",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "37",
        "-57.486",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "37",
        "-57.175",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "37",
        "-56.862",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "37",
        "-56.548",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "37",
        "-56.232",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "37",
        "-55.915",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "37",
        "-55.598",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "37",
        "-55.279",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "37",
        "-54.959",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "37",
        "-54.638",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "37",
        "-54.316",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "37",
        "-53.994",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "37",
        "-53.672",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "37",
        "-53.349",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "37",
        "-53.025",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "37",
        "-52.701",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "37",
        "-52.377",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "37",
        "-52.053",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "37",
        "-51.729",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "37",
        "-51.406",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "37",
        "-51.082",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "37",
        "-50.759",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "37",
        "-50.436",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "37",
        "-50.114",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "37",
        "-49.792",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "37",
        "-49.471",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "37",
        "-49.151",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "37",
        "-48.832",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "37",
        "-48.514",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "37",
        "-48.197",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "37",
        "-47.881",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "37",
        "-47.567",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "37",
        "-47.254",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "37",
        "-46.942",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "37",
        "-46.632",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "37",
        "-46.324",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "37",
        "-46.018",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "37",
        "-45.713",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "37",
        "-45.410",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "37",
        "-45.110",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "37",
        "-44.811",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "37",
        "-44.515",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "37",
        "-44.221",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "37",
        "-43.930",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "37",
        "-43.641",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "37",
        "-43.355",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "37",
        "-43.071",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "37",
        "-42.790",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "37",
        "-42.512",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "37",
        "-42.237",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "37",
        "-41.965",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "37",
        "-41.697",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "37",
        "-41.431",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "37",
        "-41.169",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "37",
        "-40.910",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "37",
        "-40.654",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "37",
        "-40.402",
        "23.831",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "37",
        "-36.676",
        "18.913",
        "0.388",
        "0.391",
        "0.028"
    ],
    [
        "-121",
        "37",
        "-24.337",
        "-2.318",
        "0.523",
        "0.636",
        "0.102"
    ],
    [
        "-120",
        "37",
        "-21.441",
        "-3.485",
        "0.861",
        "0.838",
        "0.098"
    ],
    [
        "-119",
        "37",
        "-20.389",
        "-3.847",
        "0.653",
        "0.694",
        "0.061"
    ],
    [
        "-118",
        "37",
        "-17.700",
        "-7.839",
        "0.374",
        "0.445",
        "0.076"
    ],
    [
        "-117",
        "37",
        "-14.783",
        "-11.496",
        "0.431",
        "0.404",
        "-0.021"
    ],
    [
        "-116",
        "37",
        "-14.051",
        "-12.303",
        "0.502",
        "0.483",
        "-0.110"
    ],
    [
        "-115",
        "37",
        "-14.097",
        "-12.316",
        "0.795",
        "0.828",
        "-0.084"
    ],
    [
        "-114",
        "37",
        "-13.683",
        "-12.271",
        "0.938",
        "0.939",
        "-0.138"
    ],
    [
        "-113",
        "37",
        "-13.153",
        "-11.872",
        "0.768",
        "0.940",
        "-0.061"
    ],
    [
        "-112",
        "37",
        "-12.404",
        "-11.500",
        "0.514",
        "0.606",
        "-0.047"
    ],
    [
        "-111",
        "37",
        "-12.146",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "37",
        "-12.262",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "37",
        "-12.373",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "37",
        "-12.482",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "37",
        "-12.587",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "37",
        "-12.689",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "37",
        "-12.786",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "37",
        "-12.879",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "37",
        "-12.968",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "37",
        "-13.055",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "37",
        "-13.136",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "37",
        "-13.214",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "37",
        "-13.288",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "37",
        "-13.358",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "37",
        "-13.424",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "37",
        "-13.485",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "37",
        "-13.543",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "37",
        "-13.597",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "37",
        "-13.647",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "37",
        "-13.693",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "37",
        "-13.734",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "37",
        "-13.771",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "37",
        "-13.805",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "37",
        "-13.833",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "37",
        "-13.859",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "37",
        "-13.879",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "37",
        "-13.895",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "37",
        "-13.908",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "37",
        "-13.916",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "37",
        "-13.920",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "37",
        "-13.920",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "37",
        "-13.916",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "37",
        "-13.907",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "37",
        "-13.894",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "37",
        "-13.878",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "37",
        "-13.856",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "37",
        "-13.831",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "37",
        "-13.802",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "37",
        "-13.768",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "37",
        "-13.730",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "37",
        "-13.689",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "37",
        "-13.642",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "37",
        "-13.593",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "37",
        "-13.539",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "37",
        "-13.481",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "37",
        "-13.418",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "37",
        "-13.352",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "37",
        "-13.282",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "37",
        "-13.208",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "37",
        "-13.129",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "37",
        "-13.048",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "37",
        "-12.962",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "37",
        "-12.872",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "37",
        "-12.778",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "37",
        "-12.680",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "37",
        "-12.579",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "37",
        "-12.473",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "37",
        "-12.365",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "37",
        "-12.252",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "37",
        "-12.135",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "37",
        "-12.016",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "37",
        "-11.892",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "37",
        "-11.765",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "37",
        "-11.634",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "37",
        "-11.501",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "37",
        "-11.362",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "37",
        "-11.222",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "37",
        "-11.077",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "37",
        "-10.930",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "37",
        "-10.778",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "37",
        "-10.624",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "37",
        "-10.467",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "37",
        "-10.306",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "37",
        "-10.143",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "37",
        "-9.976",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "37",
        "-9.807",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "37",
        "-9.635",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "37",
        "-9.459",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "37",
        "-0.586",
        "16.467",
        "3.133",
        "2.932",
        "-0.371"
    ],
    [
        "-32",
        "37",
        "11.457",
        "14.593",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "37",
        "11.612",
        "14.817",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "37",
        "11.768",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "37",
        "11.928",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "37",
        "12.089",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "37",
        "12.252",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "37",
        "12.418",
        "15.874",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "37",
        "12.715",
        "16.270",
        "0.712",
        "0.874",
        "0.269"
    ],
    [
        "-24",
        "37",
        "15.204",
        "16.626",
        "3.026",
        "2.331",
        "0.291"
    ],
    [
        "-23",
        "37",
        "15.428",
        "16.584",
        "3.473",
        "3.348",
        "0.107"
    ],
    [
        "-22",
        "37",
        "15.339",
        "16.767",
        "4.034",
        "4.549",
        "0.060"
    ],
    [
        "-21",
        "37",
        "13.276",
        "16.808",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "37",
        "13.454",
        "16.980",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "37",
        "13.633",
        "17.148",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "37",
        "13.814",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "37",
        "13.997",
        "17.465",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "37",
        "14.181",
        "17.615",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "37",
        "14.367",
        "17.761",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "37",
        "15.339",
        "18.067",
        "3.837",
        "4.472",
        "-0.010"
    ],
    [
        "-13",
        "37",
        "16.732",
        "17.876",
        "4.475",
        "5.605",
        "0.016"
    ],
    [
        "-12",
        "37",
        "17.482",
        "17.773",
        "4.532",
        "5.066",
        "0.005"
    ],
    [
        "-11",
        "37",
        "17.870",
        "17.581",
        "4.102",
        "5.304",
        "0.050"
    ],
    [
        "-10",
        "37",
        "18.243",
        "17.558",
        "4.074",
        "5.370",
        "-0.108"
    ],
    [
        "-9",
        "37",
        "18.826",
        "17.529",
        "3.888",
        "4.618",
        "-0.225"
    ],
    [
        "-8",
        "37",
        "19.152",
        "17.567",
        "3.421",
        "4.050",
        "-0.287"
    ],
    [
        "-7",
        "37",
        "19.359",
        "17.607",
        "3.524",
        "3.614",
        "-0.303"
    ],
    [
        "-6",
        "37",
        "19.618",
        "17.626",
        "4.109",
        "3.344",
        "-0.264"
    ],
    [
        "-5",
        "37",
        "19.841",
        "17.726",
        "3.940",
        "3.728",
        "-0.152"
    ],
    [
        "-4",
        "37",
        "20.102",
        "17.670",
        "3.674",
        "3.051",
        "-0.139"
    ],
    [
        "-3",
        "37",
        "20.528",
        "17.496",
        "3.610",
        "2.326",
        "-0.128"
    ],
    [
        "-2",
        "37",
        "21.047",
        "17.282",
        "0.851",
        "0.876",
        "-0.054"
    ],
    [
        "-1",
        "37",
        "21.254",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "37",
        "21.435",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "37",
        "21.614",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "37",
        "21.698",
        "17.169",
        "0.150",
        "0.228",
        "-0.024"
    ],
    [
        "3",
        "37",
        "21.378",
        "17.510",
        "0.616",
        "0.634",
        "-0.056"
    ],
    [
        "4",
        "37",
        "21.472",
        "17.477",
        "0.571",
        "0.669",
        "-0.052"
    ],
    [
        "5",
        "37",
        "21.649",
        "17.381",
        "0.574",
        "0.671",
        "-0.063"
    ],
    [
        "6",
        "37",
        "21.850",
        "17.296",
        "0.632",
        "0.644",
        "-0.046"
    ],
    [
        "7",
        "37",
        "22.046",
        "17.227",
        "0.577",
        "0.676",
        "-0.039"
    ],
    [
        "8",
        "37",
        "22.095",
        "17.268",
        "0.616",
        "0.709",
        "-0.048"
    ],
    [
        "9",
        "37",
        "21.965",
        "17.438",
        "0.747",
        "0.752",
        "-0.023"
    ],
    [
        "10",
        "37",
        "21.849",
        "17.503",
        "0.728",
        "0.794",
        "-0.035"
    ],
    [
        "11",
        "37",
        "21.756",
        "17.541",
        "0.719",
        "0.788",
        "-0.029"
    ],
    [
        "12",
        "37",
        "21.666",
        "17.678",
        "0.675",
        "0.657",
        "-0.038"
    ],
    [
        "13",
        "37",
        "21.542",
        "18.028",
        "0.711",
        "0.794",
        "-0.017"
    ],
    [
        "14",
        "37",
        "21.492",
        "18.465",
        "0.676",
        "0.786",
        "0.004"
    ],
    [
        "15",
        "37",
        "21.588",
        "19.031",
        "0.469",
        "0.443",
        "0.016"
    ],
    [
        "16",
        "37",
        "20.575",
        "19.439",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "37",
        "20.780",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "37",
        "20.983",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "37",
        "21.186",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "37",
        "17.128",
        "15.079",
        "1.527",
        "1.950",
        "0.007"
    ],
    [
        "21",
        "37",
        "10.841",
        "5.413",
        "1.782",
        "2.062",
        "0.048"
    ],
    [
        "22",
        "37",
        "6.926",
        "-10.365",
        "1.118",
        "1.498",
        "-0.007"
    ],
    [
        "23",
        "37",
        "8.977",
        "-10.101",
        "1.066",
        "1.469",
        "0.052"
    ],
    [
        "24",
        "37",
        "8.660",
        "-10.408",
        "1.312",
        "1.860",
        "-0.037"
    ],
    [
        "25",
        "37",
        "8.923",
        "-12.172",
        "1.089",
        "1.456",
        "-0.043"
    ],
    [
        "26",
        "37",
        "8.966",
        "-12.631",
        "1.149",
        "1.210",
        "0.070"
    ],
    [
        "27",
        "37",
        "9.391",
        "-11.740",
        "0.998",
        "0.902",
        "-0.116"
    ],
    [
        "28",
        "37",
        "10.789",
        "-8.867",
        "0.831",
        "0.882",
        "-0.147"
    ],
    [
        "29",
        "37",
        "10.228",
        "0.252",
        "1.181",
        "1.359",
        "-0.097"
    ],
    [
        "30",
        "37",
        "11.346",
        "5.094",
        "1.444",
        "1.499",
        "-0.222"
    ],
    [
        "31",
        "37",
        "13.075",
        "8.552",
        "0.284",
        "0.496",
        "-0.137"
    ],
    [
        "32",
        "37",
        "12.971",
        "10.319",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "37",
        "13.088",
        "11.988",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "37",
        "13.222",
        "13.654",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "37",
        "13.665",
        "15.587",
        "0.698",
        "1.047",
        "-0.020"
    ],
    [
        "36",
        "37",
        "15.141",
        "20.033",
        "1.707",
        "1.486",
        "0.126"
    ],
    [
        "37",
        "37",
        "17.310",
        "22.512",
        "1.614",
        "1.401",
        "0.112"
    ],
    [
        "38",
        "37",
        "20.123",
        "24.703",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "37",
        "20.386",
        "25.170",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "37",
        "20.652",
        "25.629",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "37",
        "20.924",
        "26.080",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "37",
        "21.200",
        "26.524",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "37",
        "22.285",
        "26.292",
        "2.018",
        "2.918",
        "0.289"
    ],
    [
        "44",
        "37",
        "23.942",
        "24.220",
        "2.355",
        "3.153",
        "0.250"
    ],
    [
        "45",
        "37",
        "25.771",
        "22.029",
        "2.506",
        "2.826",
        "0.231"
    ],
    [
        "46",
        "37",
        "26.782",
        "22.361",
        "1.393",
        "1.530",
        "0.234"
    ],
    [
        "47",
        "37",
        "27.571",
        "20.136",
        "2.086",
        "2.311",
        "0.110"
    ],
    [
        "48",
        "37",
        "27.942",
        "19.831",
        "2.470",
        "2.178",
        "0.065"
    ],
    [
        "49",
        "37",
        "28.166",
        "18.966",
        "2.401",
        "2.267",
        "0.102"
    ],
    [
        "50",
        "37",
        "27.738",
        "18.373",
        "2.342",
        "2.159",
        "0.199"
    ],
    [
        "51",
        "37",
        "27.341",
        "17.598",
        "2.224",
        "1.956",
        "0.287"
    ],
    [
        "52",
        "37",
        "27.202",
        "15.386",
        "1.730",
        "1.802",
        "0.264"
    ],
    [
        "53",
        "37",
        "27.408",
        "14.385",
        "1.584",
        "2.097",
        "0.201"
    ],
    [
        "54",
        "37",
        "27.683",
        "13.431",
        "1.370",
        "1.593",
        "0.125"
    ],
    [
        "55",
        "37",
        "28.072",
        "12.705",
        "1.541",
        "2.468",
        "0.063"
    ],
    [
        "56",
        "37",
        "28.530",
        "11.743",
        "1.789",
        "2.707",
        "0.082"
    ],
    [
        "57",
        "37",
        "28.926",
        "10.436",
        "2.033",
        "2.466",
        "0.110"
    ],
    [
        "58",
        "37",
        "29.311",
        "8.716",
        "1.917",
        "2.155",
        "0.211"
    ],
    [
        "59",
        "37",
        "29.550",
        "6.570",
        "1.707",
        "1.998",
        "0.204"
    ],
    [
        "60",
        "37",
        "29.425",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "37",
        "29.484",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "37",
        "29.541",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "37",
        "29.594",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "37",
        "29.645",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "37",
        "29.693",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "37",
        "29.738",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "37",
        "29.456",
        "4.529",
        "0.627",
        "0.710",
        "-0.029"
    ],
    [
        "68",
        "37",
        "29.016",
        "6.032",
        "0.739",
        "0.824",
        "-0.070"
    ],
    [
        "69",
        "37",
        "28.614",
        "8.928",
        "0.868",
        "0.941",
        "-0.074"
    ],
    [
        "70",
        "37",
        "28.368",
        "12.824",
        "0.932",
        "1.123",
        "-0.033"
    ],
    [
        "71",
        "37",
        "28.394",
        "16.520",
        "1.040",
        "1.235",
        "-0.030"
    ],
    [
        "72",
        "37",
        "28.561",
        "19.278",
        "1.260",
        "1.413",
        "-0.015"
    ],
    [
        "73",
        "37",
        "28.767",
        "21.677",
        "1.436",
        "1.674",
        "-0.016"
    ],
    [
        "74",
        "37",
        "29.056",
        "23.925",
        "1.702",
        "2.060",
        "-0.060"
    ],
    [
        "75",
        "37",
        "29.873",
        "22.989",
        "2.150",
        "2.174",
        "0.003"
    ],
    [
        "76",
        "37",
        "30.376",
        "19.540",
        "2.130",
        "2.574",
        "0.118"
    ],
    [
        "77",
        "37",
        "29.869",
        "20.863",
        "2.142",
        "2.253",
        "-0.019"
    ],
    [
        "78",
        "37",
        "29.244",
        "18.947",
        "2.200",
        "2.289",
        "0.052"
    ],
    [
        "79",
        "37",
        "28.392",
        "17.746",
        "1.736",
        "2.443",
        "0.074"
    ],
    [
        "80",
        "37",
        "28.165",
        "15.708",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "37",
        "28.323",
        "14.380",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "37",
        "28.467",
        "13.047",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "37",
        "28.597",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "37",
        "29.588",
        "10.777",
        "1.226",
        "1.272",
        "-0.055"
    ],
    [
        "85",
        "37",
        "31.728",
        "11.463",
        "1.273",
        "1.232",
        "0.141"
    ],
    [
        "86",
        "37",
        "34.435",
        "11.073",
        "1.448",
        "1.516",
        "0.023"
    ],
    [
        "87",
        "37",
        "36.513",
        "11.412",
        "1.572",
        "1.613",
        "-0.065"
    ],
    [
        "88",
        "37",
        "38.424",
        "10.493",
        "1.465",
        "1.657",
        "-0.040"
    ],
    [
        "89",
        "37",
        "39.831",
        "8.684",
        "1.393",
        "1.605",
        "-0.056"
    ],
    [
        "90",
        "37",
        "40.625",
        "6.856",
        "1.312",
        "1.372",
        "-0.053"
    ],
    [
        "91",
        "37",
        "41.387",
        "5.870",
        "1.204",
        "1.312",
        "0.063"
    ],
    [
        "92",
        "37",
        "41.838",
        "5.291",
        "1.167",
        "1.275",
        "0.035"
    ],
    [
        "93",
        "37",
        "41.704",
        "4.545",
        "0.952",
        "0.878",
        "0.055"
    ],
    [
        "94",
        "37",
        "41.113",
        "4.570",
        "1.008",
        "1.151",
        "0.055"
    ],
    [
        "95",
        "37",
        "40.361",
        "3.647",
        "0.975",
        "1.095",
        "0.014"
    ],
    [
        "96",
        "37",
        "40.180",
        "2.300",
        "1.093",
        "1.108",
        "0.066"
    ],
    [
        "97",
        "37",
        "39.873",
        "1.195",
        "1.049",
        "1.231",
        "0.027"
    ],
    [
        "98",
        "37",
        "40.192",
        "0.666",
        "1.072",
        "1.239",
        "0.051"
    ],
    [
        "99",
        "37",
        "40.887",
        "-0.531",
        "1.305",
        "1.436",
        "0.042"
    ],
    [
        "100",
        "37",
        "41.172",
        "-2.448",
        "1.219",
        "1.424",
        "0.024"
    ],
    [
        "101",
        "37",
        "40.792",
        "-4.024",
        "1.068",
        "1.287",
        "0.086"
    ],
    [
        "102",
        "37",
        "40.599",
        "-5.470",
        "1.096",
        "1.100",
        "-0.019"
    ],
    [
        "103",
        "37",
        "39.450",
        "-6.808",
        "1.083",
        "1.223",
        "0.048"
    ],
    [
        "104",
        "37",
        "37.714",
        "-7.684",
        "1.087",
        "1.169",
        "0.067"
    ],
    [
        "105",
        "37",
        "35.652",
        "-8.253",
        "1.156",
        "1.079",
        "0.043"
    ],
    [
        "106",
        "37",
        "33.412",
        "-8.932",
        "1.088",
        "1.138",
        "0.002"
    ],
    [
        "107",
        "37",
        "32.426",
        "-9.779",
        "1.025",
        "1.098",
        "0.005"
    ],
    [
        "108",
        "37",
        "32.398",
        "-10.200",
        "0.969",
        "0.918",
        "-0.002"
    ],
    [
        "109",
        "37",
        "32.337",
        "-10.424",
        "0.927",
        "0.925",
        "-0.015"
    ],
    [
        "110",
        "37",
        "32.293",
        "-10.673",
        "0.959",
        "1.053",
        "-0.009"
    ],
    [
        "111",
        "37",
        "32.400",
        "-10.966",
        "1.092",
        "1.090",
        "-0.027"
    ],
    [
        "112",
        "37",
        "33.018",
        "-11.686",
        "1.001",
        "1.091",
        "-0.053"
    ],
    [
        "113",
        "37",
        "33.932",
        "-12.591",
        "0.892",
        "0.949",
        "-0.036"
    ],
    [
        "114",
        "37",
        "33.754",
        "-12.826",
        "1.029",
        "1.012",
        "-0.009"
    ],
    [
        "115",
        "37",
        "33.267",
        "-13.427",
        "0.991",
        "1.091",
        "0.000"
    ],
    [
        "116",
        "37",
        "33.168",
        "-13.777",
        "0.944",
        "1.030",
        "0.005"
    ],
    [
        "117",
        "37",
        "33.052",
        "-13.453",
        "1.012",
        "1.002",
        "0.016"
    ],
    [
        "118",
        "37",
        "32.759",
        "-13.124",
        "0.949",
        "1.061",
        "0.013"
    ],
    [
        "119",
        "37",
        "32.083",
        "-13.395",
        "1.008",
        "1.118",
        "0.010"
    ],
    [
        "120",
        "37",
        "31.444",
        "-13.907",
        "1.082",
        "1.078",
        "-0.042"
    ],
    [
        "121",
        "37",
        "31.060",
        "-14.114",
        "0.918",
        "1.057",
        "-0.003"
    ],
    [
        "122",
        "37",
        "31.052",
        "-14.287",
        "0.770",
        "0.797",
        "-0.008"
    ],
    [
        "123",
        "37",
        "30.457",
        "-14.095",
        "0.845",
        "0.873",
        "-0.003"
    ],
    [
        "124",
        "37",
        "29.962",
        "-13.971",
        "0.827",
        "0.927",
        "-0.032"
    ],
    [
        "125",
        "37",
        "29.541",
        "-13.900",
        "0.819",
        "0.930",
        "-0.044"
    ],
    [
        "126",
        "37",
        "29.171",
        "-13.914",
        "0.824",
        "0.865",
        "-0.047"
    ],
    [
        "127",
        "37",
        "28.835",
        "-14.079",
        "0.655",
        "0.645",
        "-0.044"
    ],
    [
        "128",
        "37",
        "28.326",
        "-13.900",
        "0.720",
        "0.825",
        "-0.079"
    ],
    [
        "129",
        "37",
        "28.036",
        "-13.822",
        "0.751",
        "0.778",
        "-0.047"
    ],
    [
        "130",
        "37",
        "28.019",
        "-14.333",
        "0.186",
        "0.293",
        "-0.013"
    ],
    [
        "131",
        "37",
        "27.888",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "37",
        "27.734",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "37",
        "27.580",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "37",
        "27.423",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "37",
        "27.265",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "37",
        "27.106",
        "-15.257",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "37",
        "21.204",
        "-11.222",
        "1.169",
        "1.186",
        "-0.021"
    ],
    [
        "138",
        "37",
        "15.656",
        "-10.171",
        "1.355",
        "1.138",
        "-0.119"
    ],
    [
        "139",
        "37",
        "4.688",
        "-10.856",
        "1.413",
        "1.215",
        "-0.078"
    ],
    [
        "140",
        "37",
        "-1.538",
        "-11.999",
        "1.089",
        "1.156",
        "-0.054"
    ],
    [
        "141",
        "37",
        "-9.584",
        "-8.943",
        "2.037",
        "1.762",
        "-0.108"
    ],
    [
        "142",
        "37",
        "-20.355",
        "-1.913",
        "5.620",
        "3.775",
        "-0.412"
    ],
    [
        "143",
        "37",
        "-43.503",
        "6.811",
        "5.884",
        "4.141",
        "-0.445"
    ],
    [
        "144",
        "37",
        "-67.125",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "37",
        "-66.930",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "37",
        "-66.731",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "37",
        "-66.527",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "37",
        "-66.320",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "37",
        "-66.107",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "37",
        "-65.891",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "37",
        "-65.671",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "37",
        "-65.446",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "37",
        "-65.217",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "37",
        "-64.984",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "37",
        "-64.748",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "37",
        "-64.507",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "37",
        "-64.263",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "37",
        "-64.015",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "37",
        "-63.764",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "37",
        "-63.509",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "37",
        "-63.251",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "37",
        "-62.989",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "37",
        "-62.724",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "37",
        "-62.455",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "37",
        "-62.184",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "37",
        "-61.910",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "37",
        "-61.632",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "37",
        "-61.352",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "37",
        "-61.069",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "37",
        "-60.783",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "37",
        "-60.495",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "37",
        "-60.204",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "37",
        "-59.910",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "37",
        "-59.614",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "37",
        "-59.316",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "37",
        "-59.016",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "37",
        "-58.714",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "37",
        "-58.410",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "37",
        "-58.104",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "38",
        "-57.231",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "38",
        "-56.914",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "38",
        "-56.595",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "38",
        "-56.275",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "38",
        "-55.954",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "38",
        "-55.631",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "38",
        "-55.307",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "38",
        "-54.982",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "38",
        "-54.655",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "38",
        "-54.328",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "38",
        "-54.000",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "38",
        "-53.671",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "38",
        "-53.342",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "38",
        "-53.012",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "38",
        "-52.681",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "38",
        "-52.350",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "38",
        "-52.019",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "38",
        "-51.688",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "38",
        "-51.356",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "38",
        "-51.025",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "38",
        "-50.693",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "38",
        "-50.362",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "38",
        "-50.032",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "38",
        "-49.702",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "38",
        "-49.372",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "38",
        "-49.043",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "38",
        "-48.715",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "38",
        "-48.387",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "38",
        "-48.061",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "38",
        "-47.735",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "38",
        "-47.411",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "38",
        "-47.088",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "38",
        "-46.766",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "38",
        "-46.446",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "38",
        "-46.127",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "38",
        "-45.810",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "38",
        "-45.495",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "38",
        "-45.181",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "38",
        "-44.870",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "38",
        "-44.560",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "38",
        "-44.253",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "38",
        "-43.947",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "38",
        "-43.644",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "38",
        "-43.344",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "38",
        "-43.046",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "38",
        "-42.750",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "38",
        "-42.457",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "38",
        "-42.167",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "38",
        "-41.880",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "38",
        "-41.596",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "38",
        "-41.314",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "38",
        "-41.036",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "38",
        "-40.761",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "38",
        "-40.489",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "38",
        "-40.221",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "38",
        "-39.956",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "38",
        "-39.694",
        "24.169",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "38",
        "-37.174",
        "21.501",
        "0.380",
        "0.388",
        "0.003"
    ],
    [
        "-122",
        "38",
        "-24.084",
        "-1.480",
        "0.377",
        "0.342",
        "0.105"
    ],
    [
        "-121",
        "38",
        "-21.831",
        "-5.647",
        "0.612",
        "0.819",
        "-0.031"
    ],
    [
        "-120",
        "38",
        "-20.293",
        "-5.709",
        "0.713",
        "0.694",
        "0.042"
    ],
    [
        "-119",
        "38",
        "-18.711",
        "-4.674",
        "0.474",
        "0.469",
        "-0.045"
    ],
    [
        "-118",
        "38",
        "-14.970",
        "-9.826",
        "0.454",
        "0.438",
        "0.069"
    ],
    [
        "-117",
        "38",
        "-14.905",
        "-12.499",
        "0.578",
        "0.548",
        "0.030"
    ],
    [
        "-116",
        "38",
        "-14.901",
        "-12.363",
        "0.772",
        "0.741",
        "-0.080"
    ],
    [
        "-115",
        "38",
        "-14.662",
        "-12.205",
        "0.876",
        "0.928",
        "-0.087"
    ],
    [
        "-114",
        "38",
        "-14.409",
        "-12.096",
        "0.849",
        "0.793",
        "-0.095"
    ],
    [
        "-113",
        "38",
        "-13.935",
        "-11.970",
        "0.884",
        "0.968",
        "-0.054"
    ],
    [
        "-112",
        "38",
        "-12.912",
        "-11.529",
        "0.608",
        "0.666",
        "-0.005"
    ],
    [
        "-111",
        "38",
        "-12.417",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "38",
        "-12.537",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "38",
        "-12.651",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "38",
        "-12.763",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "38",
        "-12.870",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "38",
        "-12.974",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "38",
        "-13.073",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "38",
        "-13.169",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "38",
        "-13.260",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "38",
        "-13.348",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "38",
        "-13.431",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "38",
        "-13.511",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "38",
        "-13.587",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "38",
        "-13.658",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "38",
        "-13.726",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "38",
        "-13.789",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "38",
        "-13.848",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "38",
        "-13.903",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "38",
        "-13.954",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "38",
        "-14.001",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "38",
        "-14.043",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "38",
        "-14.081",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "38",
        "-14.115",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "38",
        "-14.144",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "38",
        "-14.170",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "38",
        "-14.191",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "38",
        "-14.208",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "38",
        "-14.221",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "38",
        "-14.229",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "38",
        "-14.233",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "38",
        "-14.233",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "38",
        "-14.229",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "38",
        "-14.220",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "38",
        "-14.207",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "38",
        "-14.190",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "38",
        "-14.168",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "38",
        "-14.142",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "38",
        "-14.112",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "38",
        "-14.078",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "38",
        "-14.039",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "38",
        "-13.996",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "38",
        "-13.949",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "38",
        "-13.898",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "38",
        "-13.844",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "38",
        "-13.784",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "38",
        "-13.720",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "38",
        "-13.652",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "38",
        "-13.581",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "38",
        "-13.505",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "38",
        "-13.425",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "38",
        "-13.341",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "38",
        "-13.252",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "38",
        "-13.160",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "38",
        "-13.065",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "38",
        "-12.965",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "38",
        "-12.861",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "38",
        "-12.753",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "38",
        "-12.642",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "38",
        "-12.527",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "38",
        "-12.408",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "38",
        "-12.285",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "38",
        "-12.159",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "38",
        "-12.029",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "38",
        "-11.895",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "38",
        "-11.757",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "38",
        "-11.617",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "38",
        "-11.472",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "38",
        "-11.325",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "38",
        "-11.174",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "38",
        "-11.020",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "38",
        "-10.862",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "38",
        "-10.701",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "38",
        "-10.536",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "38",
        "-10.369",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "38",
        "-10.199",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "38",
        "-10.025",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "38",
        "-9.849",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "38",
        "-9.670",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "38",
        "-9.487",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "38",
        "-9.302",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "38",
        "2.826",
        "17.158",
        "2.673",
        "3.062",
        "-0.221"
    ],
    [
        "-30",
        "38",
        "11.339",
        "15.038",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "38",
        "11.501",
        "15.254",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "38",
        "11.666",
        "15.466",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "38",
        "11.833",
        "15.671",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "38",
        "15.251",
        "16.576",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "38",
        "16.569",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "38",
        "16.751",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "38",
        "16.934",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "38",
        "17.117",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "38",
        "17.302",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "38",
        "17.486",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "38",
        "17.672",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "38",
        "17.857",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "38",
        "18.044",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "38",
        "18.230",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "38",
        "18.417",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "38",
        "18.604",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "38",
        "18.791",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "38",
        "18.979",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "38",
        "19.167",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "38",
        "19.354",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "38",
        "19.541",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "38",
        "19.729",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "38",
        "19.916",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "38",
        "20.104",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "38",
        "20.290",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "38",
        "20.477",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "38",
        "20.663",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "38",
        "20.848",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "38",
        "21.034",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "38",
        "21.219",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "38",
        "21.403",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "38",
        "21.586",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "38",
        "21.769",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "38",
        "21.952",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "38",
        "22.133",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "38",
        "22.313",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "38",
        "22.492",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "38",
        "22.642",
        "16.583",
        "0.146",
        "0.217",
        "-0.009"
    ],
    [
        "9",
        "38",
        "22.599",
        "16.783",
        "0.607",
        "0.646",
        "-0.055"
    ],
    [
        "10",
        "38",
        "22.651",
        "16.770",
        "0.565",
        "0.684",
        "-0.040"
    ],
    [
        "11",
        "38",
        "22.673",
        "16.726",
        "0.567",
        "0.682",
        "-0.049"
    ],
    [
        "12",
        "38",
        "22.516",
        "16.850",
        "0.621",
        "0.643",
        "-0.046"
    ],
    [
        "13",
        "38",
        "22.164",
        "17.308",
        "0.593",
        "0.688",
        "-0.041"
    ],
    [
        "14",
        "38",
        "22.103",
        "17.609",
        "0.654",
        "0.757",
        "0.008"
    ],
    [
        "15",
        "38",
        "21.721",
        "19.300",
        "0.610",
        "0.626",
        "0.033"
    ],
    [
        "16",
        "38",
        "20.555",
        "19.262",
        "0.153",
        "0.230",
        "0.056"
    ],
    [
        "17",
        "38",
        "20.557",
        "19.398",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "38",
        "20.765",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "38",
        "20.973",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "38",
        "20.149",
        "18.414",
        "0.300",
        "0.610",
        "0.029"
    ],
    [
        "21",
        "38",
        "13.760",
        "2.029",
        "1.252",
        "1.439",
        "0.001"
    ],
    [
        "22",
        "38",
        "10.470",
        "-8.267",
        "0.923",
        "1.281",
        "-0.144"
    ],
    [
        "23",
        "38",
        "9.071",
        "-7.380",
        "0.780",
        "0.859",
        "-0.116"
    ],
    [
        "24",
        "38",
        "10.265",
        "-9.555",
        "0.541",
        "0.581",
        "0.022"
    ],
    [
        "25",
        "38",
        "8.615",
        "-10.186",
        "0.812",
        "1.171",
        "-0.010"
    ],
    [
        "26",
        "38",
        "7.481",
        "-9.659",
        "0.801",
        "0.988",
        "-0.021"
    ],
    [
        "27",
        "38",
        "6.285",
        "-7.840",
        "0.754",
        "0.734",
        "0.002"
    ],
    [
        "28",
        "38",
        "5.317",
        "-2.125",
        "0.600",
        "0.691",
        "-0.076"
    ],
    [
        "29",
        "38",
        "4.569",
        "3.162",
        "0.634",
        "0.695",
        "-0.026"
    ],
    [
        "30",
        "38",
        "6.583",
        "5.767",
        "0.977",
        "1.113",
        "0.080"
    ],
    [
        "31",
        "38",
        "10.133",
        "8.534",
        "0.232",
        "0.410",
        "0.021"
    ],
    [
        "32",
        "38",
        "10.706",
        "10.319",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "38",
        "10.826",
        "11.988",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "38",
        "10.964",
        "13.654",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "38",
        "11.119",
        "15.316",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "38",
        "11.293",
        "16.972",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "38",
        "14.126",
        "19.954",
        "2.431",
        "2.183",
        "0.192"
    ],
    [
        "38",
        "38",
        "16.656",
        "23.029",
        "2.231",
        "2.093",
        "0.090"
    ],
    [
        "39",
        "38",
        "18.466",
        "24.865",
        "2.622",
        "2.358",
        "0.061"
    ],
    [
        "40",
        "38",
        "19.125",
        "24.645",
        "1.857",
        "1.859",
        "-0.064"
    ],
    [
        "41",
        "38",
        "20.024",
        "26.059",
        "1.980",
        "2.379",
        "-0.023"
    ],
    [
        "42",
        "38",
        "21.503",
        "24.737",
        "1.983",
        "2.202",
        "-0.005"
    ],
    [
        "43",
        "38",
        "23.669",
        "23.043",
        "1.772",
        "2.354",
        "0.044"
    ],
    [
        "44",
        "38",
        "25.593",
        "21.633",
        "1.813",
        "2.235",
        "0.232"
    ],
    [
        "45",
        "38",
        "27.607",
        "19.611",
        "1.996",
        "1.987",
        "0.204"
    ],
    [
        "46",
        "38",
        "28.835",
        "18.810",
        "1.981",
        "1.908",
        "0.131"
    ],
    [
        "47",
        "38",
        "29.057",
        "19.002",
        "2.133",
        "1.973",
        "0.015"
    ],
    [
        "48",
        "38",
        "28.628",
        "19.271",
        "2.493",
        "2.055",
        "-0.057"
    ],
    [
        "49",
        "38",
        "28.402",
        "18.463",
        "2.416",
        "2.255",
        "0.014"
    ],
    [
        "50",
        "38",
        "28.102",
        "17.729",
        "2.245",
        "2.156",
        "0.116"
    ],
    [
        "51",
        "38",
        "28.006",
        "16.386",
        "2.037",
        "1.963",
        "0.215"
    ],
    [
        "52",
        "38",
        "27.988",
        "14.539",
        "1.675",
        "1.966",
        "0.212"
    ],
    [
        "53",
        "38",
        "27.982",
        "12.996",
        "1.551",
        "2.041",
        "0.165"
    ],
    [
        "54",
        "38",
        "28.044",
        "11.753",
        "1.554",
        "2.184",
        "0.071"
    ],
    [
        "55",
        "38",
        "28.209",
        "10.694",
        "1.561",
        "2.427",
        "0.005"
    ],
    [
        "56",
        "38",
        "28.582",
        "9.543",
        "1.611",
        "2.460",
        "0.009"
    ],
    [
        "57",
        "38",
        "29.316",
        "8.930",
        "1.529",
        "1.741",
        "0.026"
    ],
    [
        "58",
        "38",
        "29.384",
        "6.565",
        "0.347",
        "0.736",
        "-0.100"
    ],
    [
        "59",
        "38",
        "29.328",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "38",
        "29.393",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "38",
        "29.454",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "38",
        "29.512",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "38",
        "29.567",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "38",
        "29.619",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "38",
        "29.668",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "38",
        "29.712",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "38",
        "28.957",
        "4.839",
        "0.663",
        "0.761",
        "-0.070"
    ],
    [
        "68",
        "38",
        "28.199",
        "6.261",
        "0.800",
        "0.898",
        "-0.068"
    ],
    [
        "69",
        "38",
        "27.250",
        "8.782",
        "1.025",
        "1.116",
        "-0.053"
    ],
    [
        "70",
        "38",
        "26.556",
        "12.361",
        "1.065",
        "1.296",
        "-0.070"
    ],
    [
        "71",
        "38",
        "26.353",
        "16.053",
        "1.155",
        "1.439",
        "-0.087"
    ],
    [
        "72",
        "38",
        "26.495",
        "19.270",
        "1.341",
        "1.573",
        "-0.094"
    ],
    [
        "73",
        "38",
        "26.819",
        "22.231",
        "1.477",
        "1.821",
        "-0.082"
    ],
    [
        "74",
        "38",
        "27.858",
        "24.764",
        "1.665",
        "2.111",
        "-0.042"
    ],
    [
        "75",
        "38",
        "33.414",
        "20.074",
        "1.170",
        "1.168",
        "0.158"
    ],
    [
        "76",
        "38",
        "31.737",
        "18.085",
        "1.848",
        "2.247",
        "0.024"
    ],
    [
        "77",
        "38",
        "29.800",
        "18.727",
        "1.681",
        "1.921",
        "-0.029"
    ],
    [
        "78",
        "38",
        "29.084",
        "18.349",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "38",
        "29.274",
        "17.031",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "38",
        "29.450",
        "15.708",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "38",
        "29.612",
        "14.380",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "38",
        "29.759",
        "13.047",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "38",
        "29.893",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "38",
        "30.011",
        "10.370",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "38",
        "30.115",
        "9.028",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "38",
        "30.539",
        "7.942",
        "0.239",
        "0.381",
        "-0.161"
    ],
    [
        "87",
        "38",
        "30.979",
        "8.031",
        "1.299",
        "1.526",
        "-0.161"
    ],
    [
        "88",
        "38",
        "32.302",
        "7.805",
        "1.363",
        "1.648",
        "-0.082"
    ],
    [
        "89",
        "38",
        "34.281",
        "7.273",
        "1.327",
        "1.704",
        "-0.112"
    ],
    [
        "90",
        "38",
        "36.352",
        "6.631",
        "1.303",
        "1.404",
        "-0.032"
    ],
    [
        "91",
        "38",
        "38.264",
        "5.961",
        "1.196",
        "1.318",
        "0.026"
    ],
    [
        "92",
        "38",
        "39.766",
        "5.586",
        "1.178",
        "1.263",
        "-0.019"
    ],
    [
        "93",
        "38",
        "40.989",
        "4.782",
        "1.283",
        "1.335",
        "0.022"
    ],
    [
        "94",
        "38",
        "41.031",
        "3.950",
        "1.167",
        "1.364",
        "0.035"
    ],
    [
        "95",
        "38",
        "40.145",
        "2.494",
        "1.002",
        "1.092",
        "0.077"
    ],
    [
        "96",
        "38",
        "39.829",
        "1.240",
        "1.155",
        "1.233",
        "0.028"
    ],
    [
        "97",
        "38",
        "39.677",
        "0.501",
        "1.208",
        "1.417",
        "0.059"
    ],
    [
        "98",
        "38",
        "39.819",
        "-0.528",
        "1.306",
        "1.486",
        "0.028"
    ],
    [
        "99",
        "38",
        "40.068",
        "-2.330",
        "1.418",
        "1.535",
        "0.040"
    ],
    [
        "100",
        "38",
        "39.735",
        "-4.747",
        "1.179",
        "1.292",
        "0.010"
    ],
    [
        "101",
        "38",
        "38.320",
        "-5.679",
        "1.088",
        "1.084",
        "0.023"
    ],
    [
        "102",
        "38",
        "36.010",
        "-6.411",
        "1.164",
        "1.181",
        "0.079"
    ],
    [
        "103",
        "38",
        "34.356",
        "-6.788",
        "0.997",
        "1.049",
        "-0.032"
    ],
    [
        "104",
        "38",
        "33.282",
        "-7.369",
        "1.101",
        "1.177",
        "0.026"
    ],
    [
        "105",
        "38",
        "32.870",
        "-7.584",
        "1.148",
        "1.103",
        "-0.002"
    ],
    [
        "106",
        "38",
        "32.710",
        "-8.174",
        "1.011",
        "1.058",
        "0.004"
    ],
    [
        "107",
        "38",
        "32.876",
        "-9.468",
        "0.980",
        "1.055",
        "0.018"
    ],
    [
        "108",
        "38",
        "32.691",
        "-9.956",
        "0.953",
        "0.928",
        "-0.017"
    ],
    [
        "109",
        "38",
        "32.597",
        "-10.333",
        "0.842",
        "0.867",
        "-0.016"
    ],
    [
        "110",
        "38",
        "32.568",
        "-10.733",
        "0.855",
        "0.910",
        "0.012"
    ],
    [
        "111",
        "38",
        "32.512",
        "-10.980",
        "1.024",
        "1.039",
        "-0.001"
    ],
    [
        "112",
        "38",
        "32.899",
        "-11.578",
        "0.942",
        "1.048",
        "0.003"
    ],
    [
        "113",
        "38",
        "33.462",
        "-12.227",
        "0.861",
        "0.975",
        "-0.088"
    ],
    [
        "114",
        "38",
        "33.384",
        "-12.016",
        "0.907",
        "0.887",
        "-0.042"
    ],
    [
        "115",
        "38",
        "33.160",
        "-12.842",
        "0.880",
        "1.004",
        "-0.012"
    ],
    [
        "116",
        "38",
        "32.984",
        "-13.330",
        "0.898",
        "1.008",
        "0.001"
    ],
    [
        "117",
        "38",
        "32.422",
        "-12.973",
        "0.969",
        "0.966",
        "0.025"
    ],
    [
        "118",
        "38",
        "32.290",
        "-12.551",
        "0.891",
        "1.002",
        "0.030"
    ],
    [
        "119",
        "38",
        "31.860",
        "-12.702",
        "0.999",
        "1.114",
        "-0.004"
    ],
    [
        "120",
        "38",
        "31.099",
        "-13.475",
        "1.050",
        "1.047",
        "0.037"
    ],
    [
        "121",
        "38",
        "30.813",
        "-13.882",
        "0.836",
        "0.865",
        "-0.044"
    ],
    [
        "122",
        "38",
        "30.427",
        "-14.213",
        "0.912",
        "1.018",
        "0.014"
    ],
    [
        "123",
        "38",
        "29.933",
        "-14.006",
        "0.895",
        "0.905",
        "-0.034"
    ],
    [
        "124",
        "38",
        "29.529",
        "-13.867",
        "0.818",
        "0.910",
        "-0.051"
    ],
    [
        "125",
        "38",
        "29.180",
        "-13.835",
        "0.793",
        "0.905",
        "-0.050"
    ],
    [
        "126",
        "38",
        "28.863",
        "-13.876",
        "0.804",
        "0.842",
        "-0.045"
    ],
    [
        "127",
        "38",
        "28.535",
        "-13.955",
        "0.720",
        "0.793",
        "-0.059"
    ],
    [
        "128",
        "38",
        "28.237",
        "-14.014",
        "0.642",
        "0.751",
        "-0.014"
    ],
    [
        "129",
        "38",
        "28.023",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "38",
        "27.869",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "38",
        "27.713",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "38",
        "27.557",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "38",
        "27.398",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "38",
        "27.238",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "38",
        "27.076",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "38",
        "26.913",
        "-15.257",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "38",
        "24.726",
        "-13.583",
        "1.470",
        "1.300",
        "-0.255"
    ],
    [
        "138",
        "38",
        "20.680",
        "-12.656",
        "1.970",
        "1.426",
        "-0.331"
    ],
    [
        "139",
        "38",
        "12.007",
        "-11.581",
        "1.380",
        "1.273",
        "-0.078"
    ],
    [
        "140",
        "38",
        "1.218",
        "-9.869",
        "1.438",
        "1.359",
        "-0.091"
    ],
    [
        "141",
        "38",
        "-10.691",
        "-8.097",
        "1.856",
        "1.650",
        "-0.157"
    ],
    [
        "142",
        "38",
        "-16.405",
        "-5.622",
        "5.343",
        "3.378",
        "-0.359"
    ],
    [
        "143",
        "38",
        "-35.364",
        "3.392",
        "6.392",
        "4.137",
        "-0.398"
    ],
    [
        "144",
        "38",
        "-66.774",
        "18.307",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "38",
        "-66.575",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "38",
        "-66.371",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "38",
        "-66.163",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "38",
        "-65.951",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "38",
        "-65.734",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "38",
        "-65.512",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "38",
        "-65.287",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "38",
        "-65.057",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "38",
        "-64.823",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "38",
        "-64.585",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "38",
        "-64.343",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "38",
        "-64.097",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "38",
        "-63.847",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "38",
        "-63.593",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "38",
        "-63.336",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "38",
        "-63.075",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "38",
        "-62.811",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "38",
        "-62.543",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "38",
        "-62.272",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "38",
        "-61.998",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "38",
        "-61.720",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "38",
        "-61.439",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "38",
        "-61.155",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "38",
        "-60.869",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "38",
        "-60.579",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "38",
        "-60.287",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "38",
        "-59.991",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "38",
        "-59.694",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "38",
        "-59.394",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "38",
        "-59.091",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "38",
        "-58.786",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "38",
        "-58.479",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "38",
        "-58.170",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "38",
        "-57.859",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "38",
        "-57.546",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "39",
        "-56.648",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "39",
        "-56.324",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "39",
        "-55.999",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "39",
        "-55.671",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "39",
        "-55.343",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "39",
        "-55.013",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "39",
        "-54.682",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "39",
        "-54.349",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "39",
        "-54.016",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "39",
        "-53.681",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "39",
        "-53.346",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "39",
        "-53.010",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "39",
        "-52.673",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "39",
        "-52.335",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "39",
        "-51.997",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "39",
        "-51.659",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "39",
        "-51.321",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "39",
        "-50.982",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "39",
        "-50.643",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "39",
        "-50.304",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "39",
        "-49.966",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "39",
        "-49.627",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "39",
        "-49.290",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "39",
        "-48.952",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "39",
        "-48.615",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "39",
        "-48.279",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "39",
        "-47.943",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "39",
        "-47.608",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "39",
        "-47.275",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "39",
        "-46.942",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "39",
        "-46.611",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "39",
        "-46.280",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "39",
        "-45.952",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "39",
        "-45.624",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "39",
        "-45.298",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "39",
        "-44.974",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "39",
        "-44.652",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "39",
        "-44.331",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "39",
        "-44.013",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "39",
        "-43.696",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "39",
        "-43.382",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "39",
        "-43.070",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "39",
        "-42.760",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "39",
        "-42.453",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "39",
        "-42.148",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "39",
        "-41.846",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "39",
        "-41.547",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "39",
        "-41.250",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "39",
        "-40.957",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "39",
        "-40.666",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "39",
        "-40.379",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "39",
        "-40.094",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "39",
        "-39.813",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "39",
        "-39.535",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "39",
        "-39.261",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "39",
        "-38.990",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "39",
        "-37.781",
        "23.226",
        "0.223",
        "0.384",
        "0.039"
    ],
    [
        "-123",
        "39",
        "-28.321",
        "5.943",
        "0.632",
        "0.504",
        "0.027"
    ],
    [
        "-122",
        "39",
        "-22.224",
        "-5.364",
        "0.664",
        "0.716",
        "-0.070"
    ],
    [
        "-121",
        "39",
        "-21.801",
        "-6.675",
        "0.750",
        "0.850",
        "0.017"
    ],
    [
        "-120",
        "39",
        "-22.257",
        "-6.922",
        "0.522",
        "0.487",
        "-0.040"
    ],
    [
        "-119",
        "39",
        "-18.191",
        "-8.386",
        "0.459",
        "0.472",
        "-0.007"
    ],
    [
        "-118",
        "39",
        "-15.286",
        "-11.163",
        "0.383",
        "0.369",
        "0.063"
    ],
    [
        "-117",
        "39",
        "-16.021",
        "-12.329",
        "0.664",
        "0.614",
        "-0.048"
    ],
    [
        "-116",
        "39",
        "-15.578",
        "-12.151",
        "0.870",
        "0.956",
        "-0.037"
    ],
    [
        "-115",
        "39",
        "-15.950",
        "-11.970",
        "0.691",
        "0.692",
        "-0.113"
    ],
    [
        "-114",
        "39",
        "-15.939",
        "-11.586",
        "0.760",
        "0.675",
        "-0.105"
    ],
    [
        "-113",
        "39",
        "-15.191",
        "-11.552",
        "0.736",
        "0.821",
        "-0.051"
    ],
    [
        "-112",
        "39",
        "-14.709",
        "-11.398",
        "0.629",
        "0.706",
        "0.038"
    ],
    [
        "-111",
        "39",
        "-13.893",
        "-11.089",
        "0.688",
        "0.639",
        "-0.019"
    ],
    [
        "-110",
        "39",
        "-12.930",
        "-10.827",
        "0.183",
        "0.330",
        "0.161"
    ],
    [
        "-109",
        "39",
        "-12.925",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "39",
        "-13.039",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "39",
        "-13.149",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "39",
        "-13.255",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "39",
        "-13.356",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "39",
        "-13.454",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "39",
        "-13.548",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "39",
        "-13.637",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "39",
        "-13.722",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "39",
        "-13.804",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "39",
        "-13.882",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "39",
        "-13.955",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "39",
        "-14.024",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "39",
        "-14.089",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "39",
        "-14.149",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "39",
        "-14.204",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "39",
        "-14.256",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "39",
        "-14.304",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "39",
        "-14.347",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "39",
        "-14.387",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "39",
        "-14.422",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "39",
        "-14.452",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "39",
        "-14.477",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "39",
        "-14.499",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "39",
        "-14.517",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "39",
        "-14.529",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "39",
        "-14.538",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "39",
        "-14.543",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "39",
        "-14.542",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "39",
        "-14.538",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "39",
        "-14.529",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "39",
        "-14.516",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "39",
        "-14.498",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "39",
        "-14.476",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "39",
        "-14.449",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "39",
        "-14.419",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "39",
        "-14.383",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "39",
        "-14.344",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "39",
        "-14.300",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "39",
        "-14.253",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "39",
        "-14.200",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "39",
        "-14.144",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "39",
        "-14.083",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "39",
        "-14.018",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "39",
        "-13.948",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "39",
        "-13.875",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "39",
        "-13.798",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "39",
        "-13.716",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "39",
        "-13.630",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "39",
        "-13.540",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "39",
        "-13.446",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "39",
        "-13.348",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "39",
        "-13.246",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "39",
        "-13.140",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "39",
        "-13.030",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "39",
        "-12.916",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "39",
        "-12.797",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "39",
        "-12.676",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "39",
        "-12.551",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "39",
        "-12.421",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "39",
        "-12.289",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "39",
        "-12.152",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "39",
        "-12.012",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "39",
        "-11.868",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "39",
        "-11.720",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "39",
        "-11.570",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "39",
        "-11.415",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "39",
        "-11.258",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "39",
        "-11.096",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "39",
        "-10.932",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "39",
        "-10.764",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "39",
        "-10.593",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "39",
        "-10.419",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "39",
        "-10.241",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "39",
        "-10.061",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "39",
        "-9.878",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "39",
        "-9.691",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "39",
        "-9.502",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "39",
        "-9.310",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "39",
        "-2.779",
        "17.553",
        "4.621",
        "3.781",
        "0.030"
    ],
    [
        "-29",
        "39",
        "10.900",
        "15.797",
        "3.773",
        "3.606",
        "0.262"
    ],
    [
        "-28",
        "39",
        "15.356",
        "16.653",
        "1.171",
        "1.132",
        "0.023"
    ],
    [
        "-27",
        "39",
        "15.874",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "39",
        "16.059",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "39",
        "16.243",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "39",
        "16.430",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "39",
        "16.617",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "39",
        "16.805",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "39",
        "16.993",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "39",
        "17.181",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "39",
        "17.371",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "39",
        "17.560",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "39",
        "17.751",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "39",
        "17.941",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "39",
        "18.132",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "39",
        "18.323",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "39",
        "18.515",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "39",
        "18.707",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "39",
        "18.898",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "39",
        "19.090",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "39",
        "19.282",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "39",
        "19.473",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "39",
        "19.666",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "39",
        "19.856",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "39",
        "20.048",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "39",
        "20.238",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "39",
        "20.429",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "39",
        "20.619",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "39",
        "20.808",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "39",
        "20.996",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "39",
        "21.185",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "39",
        "21.372",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "39",
        "21.559",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "39",
        "21.746",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "39",
        "21.931",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "39",
        "22.115",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "39",
        "22.298",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "39",
        "22.480",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "39",
        "22.662",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "39",
        "22.842",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "39",
        "23.021",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "39",
        "23.199",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "39",
        "23.376",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "39",
        "23.551",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "39",
        "23.725",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "39",
        "22.100",
        "18.266",
        "0.643",
        "0.792",
        "0.126"
    ],
    [
        "17",
        "39",
        "20.818",
        "19.224",
        "0.484",
        "0.552",
        "0.100"
    ],
    [
        "18",
        "39",
        "20.541",
        "19.353",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "39",
        "20.753",
        "19.300",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "39",
        "21.123",
        "19.306",
        "0.194",
        "0.307",
        "-0.153"
    ],
    [
        "21",
        "39",
        "18.478",
        "9.964",
        "0.846",
        "0.975",
        "-0.003"
    ],
    [
        "22",
        "39",
        "17.395",
        "4.866",
        "0.903",
        "1.277",
        "-0.007"
    ],
    [
        "23",
        "39",
        "17.438",
        "0.336",
        "0.690",
        "0.761",
        "0.017"
    ],
    [
        "24",
        "39",
        "14.091",
        "-5.524",
        "0.812",
        "0.946",
        "-0.050"
    ],
    [
        "25",
        "39",
        "10.830",
        "-5.461",
        "0.781",
        "1.025",
        "-0.055"
    ],
    [
        "26",
        "39",
        "7.708",
        "-4.195",
        "0.845",
        "1.060",
        "0.032"
    ],
    [
        "27",
        "39",
        "5.457",
        "-0.650",
        "0.825",
        "0.852",
        "0.045"
    ],
    [
        "28",
        "39",
        "3.434",
        "2.599",
        "0.601",
        "0.767",
        "0.014"
    ],
    [
        "29",
        "39",
        "3.027",
        "5.956",
        "0.648",
        "0.778",
        "0.009"
    ],
    [
        "30",
        "39",
        "4.265",
        "7.004",
        "1.064",
        "1.331",
        "0.063"
    ],
    [
        "31",
        "39",
        "7.860",
        "8.829",
        "0.248",
        "0.489",
        "-0.116"
    ],
    [
        "32",
        "39",
        "8.438",
        "10.319",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "39",
        "8.561",
        "11.988",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "39",
        "8.701",
        "13.654",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "39",
        "8.860",
        "15.316",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "39",
        "9.038",
        "16.972",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "39",
        "9.233",
        "18.625",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "39",
        "9.447",
        "20.270",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "39",
        "12.207",
        "22.452",
        "2.702",
        "2.777",
        "0.005"
    ],
    [
        "40",
        "39",
        "16.117",
        "23.409",
        "2.696",
        "3.222",
        "0.027"
    ],
    [
        "41",
        "39",
        "21.329",
        "21.120",
        "2.266",
        "2.710",
        "0.101"
    ],
    [
        "42",
        "39",
        "24.051",
        "21.571",
        "1.671",
        "1.645",
        "-0.178"
    ],
    [
        "43",
        "39",
        "25.658",
        "19.638",
        "1.130",
        "1.301",
        "0.065"
    ],
    [
        "44",
        "39",
        "28.318",
        "18.117",
        "1.190",
        "1.428",
        "-0.006"
    ],
    [
        "45",
        "39",
        "29.536",
        "17.508",
        "1.329",
        "1.293",
        "0.022"
    ],
    [
        "46",
        "39",
        "30.385",
        "17.531",
        "1.416",
        "1.350",
        "-0.010"
    ],
    [
        "47",
        "39",
        "31.344",
        "18.384",
        "1.666",
        "1.672",
        "-0.078"
    ],
    [
        "48",
        "39",
        "30.051",
        "19.606",
        "2.197",
        "1.844",
        "-0.056"
    ],
    [
        "49",
        "39",
        "29.630",
        "17.780",
        "2.248",
        "2.333",
        "-0.002"
    ],
    [
        "50",
        "39",
        "29.050",
        "16.690",
        "2.104",
        "2.306",
        "0.117"
    ],
    [
        "51",
        "39",
        "28.842",
        "14.923",
        "1.899",
        "2.178",
        "0.212"
    ],
    [
        "52",
        "39",
        "28.779",
        "12.926",
        "1.598",
        "2.179",
        "0.216"
    ],
    [
        "53",
        "39",
        "28.735",
        "11.225",
        "1.474",
        "2.224",
        "0.112"
    ],
    [
        "54",
        "39",
        "28.706",
        "9.845",
        "1.410",
        "2.228",
        "-0.015"
    ],
    [
        "55",
        "39",
        "28.779",
        "8.504",
        "1.217",
        "2.084",
        "-0.084"
    ],
    [
        "56",
        "39",
        "29.071",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "39",
        "29.146",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "39",
        "29.218",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "39",
        "29.287",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "39",
        "29.353",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "39",
        "29.415",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "39",
        "29.473",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "39",
        "29.530",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "39",
        "29.583",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "39",
        "29.632",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "39",
        "29.679",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "39",
        "28.958",
        "5.049",
        "0.585",
        "0.640",
        "-0.088"
    ],
    [
        "68",
        "39",
        "27.873",
        "5.701",
        "0.797",
        "0.935",
        "-0.092"
    ],
    [
        "69",
        "39",
        "26.480",
        "7.002",
        "1.042",
        "1.197",
        "-0.070"
    ],
    [
        "70",
        "39",
        "25.394",
        "9.214",
        "1.103",
        "1.401",
        "-0.094"
    ],
    [
        "71",
        "39",
        "25.124",
        "12.109",
        "1.195",
        "1.562",
        "-0.123"
    ],
    [
        "72",
        "39",
        "25.367",
        "14.940",
        "1.363",
        "1.689",
        "-0.132"
    ],
    [
        "73",
        "39",
        "25.955",
        "17.658",
        "1.423",
        "1.847",
        "-0.106"
    ],
    [
        "74",
        "39",
        "27.846",
        "19.067",
        "1.482",
        "1.897",
        "-0.044"
    ],
    [
        "75",
        "39",
        "30.936",
        "18.117",
        "1.674",
        "1.759",
        "0.012"
    ],
    [
        "76",
        "39",
        "32.298",
        "16.227",
        "1.366",
        "1.569",
        "0.101"
    ],
    [
        "77",
        "39",
        "30.746",
        "18.237",
        "1.371",
        "1.595",
        "0.160"
    ],
    [
        "78",
        "39",
        "30.353",
        "18.349",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "39",
        "30.547",
        "17.031",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "39",
        "30.726",
        "15.708",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "39",
        "30.892",
        "14.380",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "39",
        "31.042",
        "13.047",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "39",
        "31.179",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "39",
        "31.300",
        "10.370",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "39",
        "31.406",
        "9.028",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "39",
        "31.498",
        "7.681",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "39",
        "31.575",
        "6.333",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "39",
        "31.637",
        "4.983",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "39",
        "31.685",
        "3.631",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "39",
        "31.546",
        "4.500",
        "1.132",
        "1.374",
        "-0.153"
    ],
    [
        "91",
        "39",
        "33.403",
        "2.755",
        "1.192",
        "1.484",
        "0.007"
    ],
    [
        "92",
        "39",
        "35.227",
        "2.539",
        "1.198",
        "1.514",
        "-0.020"
    ],
    [
        "93",
        "39",
        "36.827",
        "1.213",
        "1.188",
        "1.347",
        "-0.099"
    ],
    [
        "94",
        "39",
        "37.738",
        "1.280",
        "1.182",
        "1.506",
        "-0.042"
    ],
    [
        "95",
        "39",
        "38.927",
        "1.096",
        "1.086",
        "1.274",
        "0.062"
    ],
    [
        "96",
        "39",
        "38.674",
        "0.222",
        "1.223",
        "1.373",
        "-0.004"
    ],
    [
        "97",
        "39",
        "37.601",
        "-0.620",
        "1.258",
        "1.496",
        "0.030"
    ],
    [
        "98",
        "39",
        "36.652",
        "-1.676",
        "1.298",
        "1.506",
        "0.037"
    ],
    [
        "99",
        "39",
        "35.750",
        "-3.916",
        "1.350",
        "1.423",
        "0.071"
    ],
    [
        "100",
        "39",
        "34.905",
        "-5.867",
        "1.104",
        "1.264",
        "0.050"
    ],
    [
        "101",
        "39",
        "34.101",
        "-7.187",
        "0.988",
        "0.983",
        "0.068"
    ],
    [
        "102",
        "39",
        "32.977",
        "-7.459",
        "1.187",
        "1.191",
        "0.041"
    ],
    [
        "103",
        "39",
        "32.519",
        "-7.780",
        "1.020",
        "1.062",
        "-0.026"
    ],
    [
        "104",
        "39",
        "32.392",
        "-7.738",
        "1.023",
        "1.014",
        "0.012"
    ],
    [
        "105",
        "39",
        "32.242",
        "-7.378",
        "1.058",
        "0.981",
        "0.010"
    ],
    [
        "106",
        "39",
        "32.437",
        "-8.041",
        "0.966",
        "1.024",
        "-0.021"
    ],
    [
        "107",
        "39",
        "32.659",
        "-9.040",
        "0.962",
        "1.041",
        "-0.037"
    ],
    [
        "108",
        "39",
        "32.388",
        "-9.473",
        "0.803",
        "0.772",
        "-0.019"
    ],
    [
        "109",
        "39",
        "32.392",
        "-10.187",
        "0.797",
        "0.860",
        "0.004"
    ],
    [
        "110",
        "39",
        "32.336",
        "-10.595",
        "0.816",
        "0.884",
        "-0.012"
    ],
    [
        "111",
        "39",
        "32.318",
        "-10.747",
        "0.928",
        "0.970",
        "0.002"
    ],
    [
        "112",
        "39",
        "32.477",
        "-10.971",
        "0.940",
        "1.062",
        "-0.032"
    ],
    [
        "113",
        "39",
        "32.707",
        "-11.685",
        "0.816",
        "0.923",
        "-0.024"
    ],
    [
        "114",
        "39",
        "33.273",
        "-12.406",
        "0.743",
        "0.724",
        "-0.031"
    ],
    [
        "115",
        "39",
        "33.386",
        "-12.478",
        "0.679",
        "0.731",
        "0.003"
    ],
    [
        "116",
        "39",
        "33.079",
        "-11.789",
        "0.618",
        "0.679",
        "-0.009"
    ],
    [
        "117",
        "39",
        "30.981",
        "-11.726",
        "0.806",
        "0.784",
        "-0.029"
    ],
    [
        "118",
        "39",
        "31.682",
        "-12.030",
        "0.829",
        "0.942",
        "0.006"
    ],
    [
        "119",
        "39",
        "31.807",
        "-12.109",
        "0.919",
        "1.043",
        "0.031"
    ],
    [
        "120",
        "39",
        "30.957",
        "-12.701",
        "1.045",
        "1.054",
        "0.056"
    ],
    [
        "121",
        "39",
        "30.512",
        "-14.537",
        "0.783",
        "0.898",
        "0.057"
    ],
    [
        "122",
        "39",
        "29.627",
        "-14.102",
        "0.917",
        "1.029",
        "-0.022"
    ],
    [
        "123",
        "39",
        "29.202",
        "-13.877",
        "0.931",
        "0.968",
        "-0.041"
    ],
    [
        "124",
        "39",
        "28.966",
        "-13.753",
        "0.809",
        "0.909",
        "-0.048"
    ],
    [
        "125",
        "39",
        "28.721",
        "-13.728",
        "0.763",
        "0.872",
        "-0.050"
    ],
    [
        "126",
        "39",
        "28.465",
        "-13.782",
        "0.744",
        "0.790",
        "-0.051"
    ],
    [
        "127",
        "39",
        "28.172",
        "-13.864",
        "0.163",
        "0.229",
        "0.012"
    ],
    [
        "128",
        "39",
        "28.001",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "39",
        "27.846",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "39",
        "27.690",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "39",
        "27.531",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "39",
        "27.371",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "39",
        "27.209",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "39",
        "27.045",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "39",
        "26.880",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "39",
        "26.713",
        "-15.257",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "39",
        "26.544",
        "-15.389",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "39",
        "26.374",
        "-15.517",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "39",
        "18.687",
        "-12.154",
        "2.131",
        "1.521",
        "-0.244"
    ],
    [
        "140",
        "39",
        "9.486",
        "-9.249",
        "1.297",
        "1.358",
        "-0.075"
    ],
    [
        "141",
        "39",
        "-2.785",
        "-6.141",
        "1.555",
        "1.444",
        "-0.117"
    ],
    [
        "142",
        "39",
        "-7.885",
        "-10.201",
        "3.668",
        "2.638",
        "-0.321"
    ],
    [
        "143",
        "39",
        "-31.958",
        "1.466",
        "6.614",
        "4.317",
        "-0.345"
    ],
    [
        "144",
        "39",
        "-54.580",
        "13.791",
        "6.616",
        "4.022",
        "-0.364"
    ],
    [
        "145",
        "39",
        "-66.200",
        "18.737",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "39",
        "-65.992",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "39",
        "-65.779",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "39",
        "-65.561",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "39",
        "-65.340",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "39",
        "-65.113",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "39",
        "-64.883",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "39",
        "-64.648",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "39",
        "-64.409",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "39",
        "-64.165",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "39",
        "-63.918",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "39",
        "-63.666",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "39",
        "-63.411",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "39",
        "-63.152",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "39",
        "-62.889",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "39",
        "-62.622",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "39",
        "-62.352",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "39",
        "-62.078",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "39",
        "-61.801",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "39",
        "-61.521",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "39",
        "-61.237",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "39",
        "-60.950",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "39",
        "-60.660",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "39",
        "-60.367",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "39",
        "-60.071",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "39",
        "-59.772",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "39",
        "-59.470",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "39",
        "-59.166",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "39",
        "-58.859",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "39",
        "-58.550",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "39",
        "-58.238",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "39",
        "-57.924",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "39",
        "-57.608",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "39",
        "-57.290",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "39",
        "-56.970",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "40",
        "-56.048",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "40",
        "-55.717",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "40",
        "-55.385",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "40",
        "-55.051",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "40",
        "-54.715",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "40",
        "-54.378",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "40",
        "-54.040",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "40",
        "-53.700",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "40",
        "-53.360",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "40",
        "-53.018",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "40",
        "-52.675",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "40",
        "-52.332",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "40",
        "-51.988",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "40",
        "-51.643",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "40",
        "-51.298",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "40",
        "-50.953",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "40",
        "-50.607",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "40",
        "-50.261",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "40",
        "-49.915",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "40",
        "-49.569",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "40",
        "-49.223",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "40",
        "-48.877",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "40",
        "-48.532",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "40",
        "-48.187",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "40",
        "-47.843",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "40",
        "-47.500",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "40",
        "-47.157",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "40",
        "-46.815",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "40",
        "-46.474",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "40",
        "-46.135",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "40",
        "-45.796",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "40",
        "-45.459",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "40",
        "-45.123",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "40",
        "-44.788",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "40",
        "-44.456",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "40",
        "-44.125",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "40",
        "-43.795",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "40",
        "-43.468",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "40",
        "-43.143",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "40",
        "-42.819",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "40",
        "-42.498",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "40",
        "-42.180",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "40",
        "-41.863",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "40",
        "-41.550",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "40",
        "-41.238",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "40",
        "-40.930",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "40",
        "-40.624",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "40",
        "-40.321",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "40",
        "-40.021",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "40",
        "-39.724",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "40",
        "-39.431",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "40",
        "-39.140",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "40",
        "-38.853",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "40",
        "-38.569",
        "25.138",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "40",
        "-38.289",
        "24.823",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "40",
        "-38.012",
        "24.499",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "40",
        "-32.722",
        "12.888",
        "1.407",
        "1.231",
        "0.019"
    ],
    [
        "-123",
        "40",
        "-20.755",
        "-4.882",
        "1.181",
        "1.034",
        "0.011"
    ],
    [
        "-122",
        "40",
        "-19.888",
        "-5.818",
        "0.920",
        "0.986",
        "0.069"
    ],
    [
        "-121",
        "40",
        "-20.468",
        "-6.738",
        "0.376",
        "0.333",
        "-0.006"
    ],
    [
        "-120",
        "40",
        "-19.815",
        "-8.428",
        "0.683",
        "0.587",
        "0.001"
    ],
    [
        "-119",
        "40",
        "-18.612",
        "-10.185",
        "0.570",
        "0.601",
        "0.053"
    ],
    [
        "-118",
        "40",
        "-17.164",
        "-11.448",
        "0.709",
        "0.810",
        "0.027"
    ],
    [
        "-117",
        "40",
        "-15.814",
        "-12.687",
        "0.913",
        "0.884",
        "-0.051"
    ],
    [
        "-116",
        "40",
        "-15.996",
        "-12.128",
        "0.603",
        "0.527",
        "-0.034"
    ],
    [
        "-115",
        "40",
        "-15.724",
        "-12.104",
        "0.927",
        "0.984",
        "-0.013"
    ],
    [
        "-114",
        "40",
        "-15.692",
        "-11.853",
        "1.053",
        "1.012",
        "0.005"
    ],
    [
        "-113",
        "40",
        "-15.250",
        "-11.633",
        "0.932",
        "0.993",
        "-0.048"
    ],
    [
        "-112",
        "40",
        "-14.795",
        "-11.284",
        "0.785",
        "0.759",
        "-0.041"
    ],
    [
        "-111",
        "40",
        "-14.083",
        "-10.922",
        "1.012",
        "0.980",
        "0.044"
    ],
    [
        "-110",
        "40",
        "-13.496",
        "-10.738",
        "0.975",
        "1.082",
        "-0.009"
    ],
    [
        "-109",
        "40",
        "-13.274",
        "-10.506",
        "0.719",
        "0.783",
        "0.017"
    ],
    [
        "-108",
        "40",
        "-13.311",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "40",
        "-13.423",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "40",
        "-13.531",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "40",
        "-13.635",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "40",
        "-13.736",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "40",
        "-13.831",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "40",
        "-13.922",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "40",
        "-14.010",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "40",
        "-14.093",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "40",
        "-14.172",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "40",
        "-14.247",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "40",
        "-14.317",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "40",
        "-14.383",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "40",
        "-14.445",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "40",
        "-14.502",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "40",
        "-14.555",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "40",
        "-14.604",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "40",
        "-14.648",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "40",
        "-14.688",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "40",
        "-14.723",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "40",
        "-14.754",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "40",
        "-14.781",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "40",
        "-14.803",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "40",
        "-14.820",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "40",
        "-14.834",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "40",
        "-14.842",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "40",
        "-14.847",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "40",
        "-14.847",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "40",
        "-14.842",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "40",
        "-14.833",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "40",
        "-14.820",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "40",
        "-14.801",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "40",
        "-14.779",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "40",
        "-14.752",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "40",
        "-14.720",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "40",
        "-14.685",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "40",
        "-14.644",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "40",
        "-14.600",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "40",
        "-14.551",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "40",
        "-14.498",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "40",
        "-14.439",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "40",
        "-14.378",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "40",
        "-14.312",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "40",
        "-14.240",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "40",
        "-14.166",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "40",
        "-14.086",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "40",
        "-14.003",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "40",
        "-13.915",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "40",
        "-13.823",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "40",
        "-13.727",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "40",
        "-13.627",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "40",
        "-13.522",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "40",
        "-13.414",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "40",
        "-13.302",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "40",
        "-13.185",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "40",
        "-13.065",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "40",
        "-12.941",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "40",
        "-12.813",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "40",
        "-12.681",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "40",
        "-12.545",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "40",
        "-12.406",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "40",
        "-12.262",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "40",
        "-12.116",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "40",
        "-11.965",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "40",
        "-11.810",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "40",
        "-11.652",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "40",
        "-11.492",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "40",
        "-11.327",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "40",
        "-11.159",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "40",
        "-10.988",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "40",
        "-10.813",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "40",
        "-10.635",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "40",
        "-10.454",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "40",
        "-10.269",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "40",
        "-10.082",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "40",
        "-9.892",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "40",
        "-9.698",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "40",
        "-9.502",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "40",
        "-9.303",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "40",
        "13.054",
        "16.634",
        "1.296",
        "1.248",
        "-0.062"
    ],
    [
        "-28",
        "40",
        "15.348",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "40",
        "15.536",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "40",
        "15.725",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "40",
        "15.914",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "40",
        "16.103",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "40",
        "16.294",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "40",
        "16.486",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "40",
        "16.678",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "40",
        "16.871",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "40",
        "17.065",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "40",
        "17.258",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "40",
        "17.453",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "40",
        "17.647",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "40",
        "17.843",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "40",
        "18.038",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "40",
        "18.234",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "40",
        "18.430",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "40",
        "18.625",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "40",
        "18.821",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "40",
        "19.017",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "40",
        "19.212",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "40",
        "19.408",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "40",
        "19.604",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "40",
        "19.799",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "40",
        "19.993",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "40",
        "20.188",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "40",
        "20.381",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "40",
        "20.575",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "40",
        "20.768",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "40",
        "20.961",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "40",
        "21.152",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "40",
        "21.343",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "40",
        "21.533",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "40",
        "21.723",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "40",
        "21.910",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "40",
        "22.098",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "40",
        "22.284",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "40",
        "22.469",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "40",
        "22.653",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "40",
        "22.836",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "40",
        "23.017",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "40",
        "23.198",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "40",
        "23.376",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "40",
        "23.554",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "40",
        "22.865",
        "18.198",
        "0.758",
        "0.943",
        "0.177"
    ],
    [
        "17",
        "40",
        "22.332",
        "19.138",
        "0.720",
        "0.754",
        "0.120"
    ],
    [
        "18",
        "40",
        "22.901",
        "19.236",
        "0.707",
        "0.710",
        "0.104"
    ],
    [
        "19",
        "40",
        "22.664",
        "18.143",
        "0.595",
        "0.750",
        "0.037"
    ],
    [
        "20",
        "40",
        "22.233",
        "16.478",
        "0.694",
        "0.835",
        "-0.054"
    ],
    [
        "21",
        "40",
        "22.390",
        "12.992",
        "0.832",
        "0.865",
        "-0.085"
    ],
    [
        "22",
        "40",
        "22.451",
        "9.498",
        "0.770",
        "0.977",
        "-0.039"
    ],
    [
        "23",
        "40",
        "22.317",
        "6.485",
        "0.736",
        "0.932",
        "-0.026"
    ],
    [
        "24",
        "40",
        "21.389",
        "3.841",
        "0.732",
        "0.773",
        "-0.010"
    ],
    [
        "25",
        "40",
        "15.007",
        "2.456",
        "0.830",
        "0.891",
        "0.100"
    ],
    [
        "26",
        "40",
        "8.772",
        "3.635",
        "0.816",
        "0.907",
        "0.024"
    ],
    [
        "27",
        "40",
        "6.366",
        "4.801",
        "0.881",
        "0.950",
        "0.053"
    ],
    [
        "28",
        "40",
        "4.935",
        "7.607",
        "0.597",
        "0.716",
        "0.073"
    ],
    [
        "29",
        "40",
        "4.146",
        "10.149",
        "0.809",
        "0.800",
        "0.074"
    ],
    [
        "30",
        "40",
        "4.874",
        "7.455",
        "0.981",
        "1.181",
        "0.081"
    ],
    [
        "31",
        "40",
        "5.706",
        "8.929",
        "0.339",
        "0.663",
        "0.102"
    ],
    [
        "32",
        "40",
        "6.167",
        "10.319",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "40",
        "6.293",
        "11.988",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "40",
        "6.436",
        "13.654",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "40",
        "6.599",
        "15.316",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "40",
        "6.780",
        "16.972",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "40",
        "6.979",
        "18.625",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "40",
        "7.951",
        "20.425",
        "0.701",
        "1.245",
        "-0.074"
    ],
    [
        "39",
        "40",
        "15.250",
        "16.820",
        "2.837",
        "2.685",
        "-0.072"
    ],
    [
        "40",
        "40",
        "22.567",
        "12.251",
        "2.078",
        "2.505",
        "0.173"
    ],
    [
        "41",
        "40",
        "25.423",
        "14.905",
        "1.708",
        "1.869",
        "0.029"
    ],
    [
        "42",
        "40",
        "27.283",
        "16.123",
        "1.729",
        "1.852",
        "0.075"
    ],
    [
        "43",
        "40",
        "29.056",
        "15.954",
        "1.065",
        "1.302",
        "-0.025"
    ],
    [
        "44",
        "40",
        "29.867",
        "16.511",
        "0.840",
        "0.906",
        "-0.108"
    ],
    [
        "45",
        "40",
        "30.204",
        "16.878",
        "0.850",
        "0.825",
        "0.061"
    ],
    [
        "46",
        "40",
        "30.563",
        "16.689",
        "1.074",
        "1.026",
        "-0.056"
    ],
    [
        "47",
        "40",
        "30.408",
        "16.871",
        "1.478",
        "1.473",
        "-0.078"
    ],
    [
        "48",
        "40",
        "31.416",
        "17.955",
        "1.964",
        "1.801",
        "-0.104"
    ],
    [
        "49",
        "40",
        "31.148",
        "16.720",
        "2.006",
        "2.305",
        "0.020"
    ],
    [
        "50",
        "40",
        "30.039",
        "14.842",
        "1.964",
        "2.392",
        "0.193"
    ],
    [
        "51",
        "40",
        "29.547",
        "12.531",
        "1.814",
        "2.312",
        "0.304"
    ],
    [
        "52",
        "40",
        "29.272",
        "10.370",
        "1.520",
        "2.257",
        "0.296"
    ],
    [
        "53",
        "40",
        "29.059",
        "8.999",
        "1.283",
        "2.264",
        "0.177"
    ],
    [
        "54",
        "40",
        "28.852",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "40",
        "28.936",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "40",
        "29.016",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "40",
        "29.091",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "40",
        "29.165",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "40",
        "29.235",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "40",
        "29.303",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "40",
        "29.366",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "40",
        "29.427",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "40",
        "29.484",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "40",
        "29.538",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "40",
        "29.589",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "40",
        "29.636",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "40",
        "29.099",
        "4.402",
        "0.648",
        "0.779",
        "-0.135"
    ],
    [
        "68",
        "40",
        "28.549",
        "4.598",
        "0.762",
        "0.903",
        "-0.123"
    ],
    [
        "69",
        "40",
        "27.667",
        "4.281",
        "1.014",
        "1.147",
        "-0.068"
    ],
    [
        "70",
        "40",
        "26.762",
        "4.052",
        "1.084",
        "1.354",
        "-0.101"
    ],
    [
        "71",
        "40",
        "26.226",
        "4.735",
        "1.173",
        "1.512",
        "-0.128"
    ],
    [
        "72",
        "40",
        "26.117",
        "6.048",
        "1.316",
        "1.606",
        "-0.130"
    ],
    [
        "73",
        "40",
        "26.328",
        "7.880",
        "1.310",
        "1.695",
        "-0.099"
    ],
    [
        "74",
        "40",
        "27.785",
        "10.204",
        "1.306",
        "1.648",
        "-0.024"
    ],
    [
        "75",
        "40",
        "30.666",
        "11.997",
        "1.221",
        "1.306",
        "0.014"
    ],
    [
        "76",
        "40",
        "30.790",
        "15.594",
        "1.155",
        "1.440",
        "-0.063"
    ],
    [
        "77",
        "40",
        "30.607",
        "16.427",
        "1.119",
        "1.376",
        "0.015"
    ],
    [
        "78",
        "40",
        "31.150",
        "16.915",
        "0.986",
        "1.107",
        "-0.046"
    ],
    [
        "79",
        "40",
        "31.904",
        "16.509",
        "0.759",
        "0.962",
        "-0.132"
    ],
    [
        "80",
        "40",
        "31.994",
        "15.708",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "40",
        "32.162",
        "14.380",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "40",
        "32.316",
        "13.047",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "40",
        "32.455",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "40",
        "32.579",
        "10.370",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "40",
        "32.688",
        "9.028",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "40",
        "32.781",
        "7.681",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "40",
        "32.861",
        "6.333",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "40",
        "32.924",
        "4.983",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "40",
        "32.972",
        "3.631",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "40",
        "33.005",
        "2.279",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "40",
        "32.904",
        "0.833",
        "0.756",
        "0.965",
        "-0.151"
    ],
    [
        "92",
        "40",
        "32.622",
        "-0.175",
        "1.159",
        "1.470",
        "-0.048"
    ],
    [
        "93",
        "40",
        "32.624",
        "-0.758",
        "1.312",
        "1.498",
        "-0.059"
    ],
    [
        "94",
        "40",
        "33.433",
        "-1.008",
        "1.085",
        "1.349",
        "-0.007"
    ],
    [
        "95",
        "40",
        "33.445",
        "-1.343",
        "1.074",
        "1.217",
        "0.023"
    ],
    [
        "96",
        "40",
        "33.540",
        "-2.951",
        "1.191",
        "1.313",
        "-0.002"
    ],
    [
        "97",
        "40",
        "33.487",
        "-3.226",
        "1.004",
        "1.011",
        "0.028"
    ],
    [
        "98",
        "40",
        "33.567",
        "-4.387",
        "0.952",
        "1.119",
        "0.084"
    ],
    [
        "99",
        "40",
        "33.466",
        "-6.298",
        "1.047",
        "1.066",
        "0.033"
    ],
    [
        "100",
        "40",
        "33.063",
        "-7.009",
        "1.074",
        "1.188",
        "0.001"
    ],
    [
        "101",
        "40",
        "32.657",
        "-7.454",
        "1.036",
        "1.089",
        "0.002"
    ],
    [
        "102",
        "40",
        "32.398",
        "-7.700",
        "0.996",
        "0.979",
        "-0.021"
    ],
    [
        "103",
        "40",
        "32.205",
        "-7.877",
        "0.918",
        "0.942",
        "-0.014"
    ],
    [
        "104",
        "40",
        "32.008",
        "-7.962",
        "0.930",
        "0.938",
        "-0.008"
    ],
    [
        "105",
        "40",
        "31.838",
        "-8.003",
        "1.053",
        "1.048",
        "-0.009"
    ],
    [
        "106",
        "40",
        "31.923",
        "-8.334",
        "0.977",
        "1.057",
        "-0.042"
    ],
    [
        "107",
        "40",
        "32.017",
        "-8.755",
        "0.821",
        "0.838",
        "-0.004"
    ],
    [
        "108",
        "40",
        "32.131",
        "-9.531",
        "0.981",
        "0.962",
        "0.001"
    ],
    [
        "109",
        "40",
        "32.090",
        "-9.983",
        "0.899",
        "0.977",
        "-0.027"
    ],
    [
        "110",
        "40",
        "31.807",
        "-10.391",
        "0.716",
        "0.717",
        "-0.051"
    ],
    [
        "111",
        "40",
        "31.617",
        "-10.491",
        "0.980",
        "0.980",
        "-0.033"
    ],
    [
        "112",
        "40",
        "31.562",
        "-10.455",
        "0.919",
        "1.037",
        "-0.005"
    ],
    [
        "113",
        "40",
        "31.983",
        "-10.812",
        "0.796",
        "0.884",
        "-0.057"
    ],
    [
        "114",
        "40",
        "32.200",
        "-11.457",
        "0.807",
        "0.804",
        "-0.063"
    ],
    [
        "115",
        "40",
        "31.715",
        "-11.839",
        "0.658",
        "0.757",
        "-0.020"
    ],
    [
        "116",
        "40",
        "31.417",
        "-11.400",
        "0.561",
        "0.603",
        "-0.041"
    ],
    [
        "117",
        "40",
        "31.634",
        "-11.266",
        "0.758",
        "0.752",
        "-0.003"
    ],
    [
        "118",
        "40",
        "30.728",
        "-11.818",
        "0.660",
        "0.744",
        "-0.036"
    ],
    [
        "119",
        "40",
        "30.485",
        "-11.336",
        "0.759",
        "0.884",
        "0.008"
    ],
    [
        "120",
        "40",
        "29.952",
        "-11.836",
        "0.811",
        "0.788",
        "0.040"
    ],
    [
        "121",
        "40",
        "29.812",
        "-12.669",
        "0.961",
        "1.070",
        "0.010"
    ],
    [
        "122",
        "40",
        "28.963",
        "-13.420",
        "0.953",
        "1.059",
        "-0.058"
    ],
    [
        "123",
        "40",
        "28.652",
        "-13.591",
        "0.929",
        "0.938",
        "-0.078"
    ],
    [
        "124",
        "40",
        "28.480",
        "-13.536",
        "0.731",
        "0.907",
        "-0.005"
    ],
    [
        "125",
        "40",
        "28.315",
        "-13.589",
        "0.653",
        "0.769",
        "-0.027"
    ],
    [
        "126",
        "40",
        "28.148",
        "-13.709",
        "0.631",
        "0.666",
        "-0.009"
    ],
    [
        "127",
        "40",
        "27.978",
        "-13.864",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "40",
        "27.821",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "40",
        "27.662",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "40",
        "27.502",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "40",
        "27.340",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "40",
        "27.176",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "40",
        "27.011",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "40",
        "26.843",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "40",
        "26.675",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "40",
        "26.505",
        "-15.257",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "40",
        "26.332",
        "-15.389",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "40",
        "26.158",
        "-15.517",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "40",
        "19.423",
        "-12.815",
        "2.445",
        "1.541",
        "-0.291"
    ],
    [
        "140",
        "40",
        "13.246",
        "-9.433",
        "1.366",
        "1.495",
        "-0.134"
    ],
    [
        "141",
        "40",
        "9.425",
        "-9.399",
        "1.673",
        "1.596",
        "-0.132"
    ],
    [
        "142",
        "40",
        "4.134",
        "-13.553",
        "2.648",
        "2.281",
        "-0.182"
    ],
    [
        "143",
        "40",
        "-22.440",
        "0.346",
        "6.375",
        "4.501",
        "-0.361"
    ],
    [
        "144",
        "40",
        "-47.472",
        "10.893",
        "7.561",
        "4.721",
        "-0.325"
    ],
    [
        "145",
        "40",
        "-64.516",
        "18.055",
        "1.652",
        "1.863",
        "-0.213"
    ],
    [
        "146",
        "40",
        "-65.592",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "40",
        "-65.374",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "40",
        "-65.152",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "40",
        "-64.926",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "40",
        "-64.695",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "40",
        "-64.459",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "40",
        "-64.219",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "40",
        "-63.975",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "40",
        "-63.726",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "40",
        "-63.474",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "40",
        "-63.217",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "40",
        "-62.956",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "40",
        "-62.691",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "40",
        "-62.423",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "40",
        "-62.150",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "40",
        "-61.874",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "40",
        "-61.595",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "40",
        "-61.312",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "40",
        "-61.025",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "40",
        "-60.735",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "40",
        "-60.442",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "40",
        "-60.146",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "40",
        "-59.846",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "40",
        "-59.544",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "40",
        "-59.239",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "40",
        "-58.931",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "40",
        "-58.620",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "40",
        "-58.306",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "40",
        "-57.991",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "40",
        "-57.672",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "40",
        "-57.352",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "40",
        "-57.029",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "40",
        "-56.704",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "40",
        "-56.377",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "41",
        "-55.431",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "41",
        "-55.093",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "41",
        "-54.754",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "41",
        "-54.413",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "41",
        "-54.071",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "41",
        "-53.727",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "41",
        "-53.381",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "41",
        "-53.035",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "41",
        "-52.687",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "41",
        "-52.338",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "41",
        "-51.989",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "41",
        "-51.638",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "41",
        "-51.287",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "41",
        "-50.935",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "41",
        "-50.583",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "41",
        "-50.230",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "41",
        "-49.877",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "41",
        "-49.524",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "41",
        "-49.171",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "41",
        "-48.818",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "41",
        "-48.465",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "41",
        "-48.112",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "41",
        "-47.760",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "41",
        "-47.408",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "41",
        "-47.057",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "41",
        "-46.706",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "41",
        "-46.356",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "41",
        "-46.008",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "41",
        "-45.660",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "41",
        "-45.313",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "41",
        "-44.967",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "41",
        "-44.623",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "41",
        "-44.280",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "41",
        "-43.939",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "41",
        "-43.599",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "41",
        "-43.261",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "41",
        "-42.925",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "41",
        "-42.591",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "41",
        "-42.259",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "41",
        "-41.929",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "41",
        "-41.602",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "41",
        "-41.276",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "41",
        "-40.954",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "41",
        "-40.633",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "41",
        "-40.316",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "41",
        "-40.001",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "41",
        "-39.689",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "41",
        "-39.379",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "41",
        "-39.073",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "41",
        "-38.770",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "41",
        "-38.470",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "41",
        "-38.174",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "41",
        "-37.881",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "41",
        "-36.664",
        "25.702",
        "9.114",
        "8.331",
        "0.063"
    ],
    [
        "-126",
        "41",
        "-32.147",
        "19.362",
        "8.809",
        "7.030",
        "0.031"
    ],
    [
        "-125",
        "41",
        "-19.163",
        "8.271",
        "4.716",
        "5.366",
        "-0.042"
    ],
    [
        "-124",
        "41",
        "-8.942",
        "0.324",
        "0.827",
        "0.853",
        "0.001"
    ],
    [
        "-123",
        "41",
        "-16.741",
        "-6.455",
        "1.534",
        "1.313",
        "-0.074"
    ],
    [
        "-122",
        "41",
        "-16.564",
        "-7.103",
        "1.118",
        "1.135",
        "0.134"
    ],
    [
        "-121",
        "41",
        "-16.926",
        "-8.270",
        "0.986",
        "0.997",
        "0.155"
    ],
    [
        "-120",
        "41",
        "-17.228",
        "-9.781",
        "0.996",
        "0.928",
        "0.020"
    ],
    [
        "-119",
        "41",
        "-16.839",
        "-10.957",
        "1.020",
        "1.081",
        "0.018"
    ],
    [
        "-118",
        "41",
        "-16.200",
        "-12.041",
        "1.099",
        "1.182",
        "-0.011"
    ],
    [
        "-117",
        "41",
        "-16.043",
        "-12.911",
        "1.150",
        "1.093",
        "0.029"
    ],
    [
        "-116",
        "41",
        "-16.704",
        "-12.254",
        "0.588",
        "0.723",
        "0.129"
    ],
    [
        "-115",
        "41",
        "-16.148",
        "-12.610",
        "0.909",
        "0.964",
        "-0.004"
    ],
    [
        "-114",
        "41",
        "-15.816",
        "-11.903",
        "1.033",
        "0.993",
        "0.027"
    ],
    [
        "-113",
        "41",
        "-15.481",
        "-11.553",
        "0.862",
        "0.898",
        "0.106"
    ],
    [
        "-112",
        "41",
        "-14.065",
        "-10.880",
        "0.511",
        "0.534",
        "0.042"
    ],
    [
        "-111",
        "41",
        "-13.582",
        "-10.918",
        "1.065",
        "0.952",
        "-0.050"
    ],
    [
        "-110",
        "41",
        "-13.532",
        "-10.806",
        "0.989",
        "1.029",
        "-0.018"
    ],
    [
        "-109",
        "41",
        "-13.462",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "41",
        "-13.580",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "41",
        "-13.695",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "41",
        "-13.805",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "41",
        "-13.911",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "41",
        "-14.012",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "41",
        "-14.110",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "41",
        "-14.204",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "41",
        "-14.293",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "41",
        "-14.378",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "41",
        "-14.458",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "41",
        "-14.535",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "41",
        "-14.606",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "41",
        "-14.674",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "41",
        "-14.736",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "41",
        "-14.795",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "41",
        "-14.849",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "41",
        "-14.899",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "41",
        "-14.945",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "41",
        "-14.985",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "41",
        "-15.021",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "41",
        "-15.052",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "41",
        "-15.079",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "41",
        "-15.102",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "41",
        "-15.120",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "41",
        "-15.133",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "41",
        "-15.142",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "41",
        "-15.147",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "41",
        "-15.147",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "41",
        "-15.142",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "41",
        "-15.132",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "41",
        "-15.119",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "41",
        "-15.101",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "41",
        "-15.077",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "41",
        "-15.050",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "41",
        "-15.018",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "41",
        "-14.982",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "41",
        "-14.940",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "41",
        "-14.895",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "41",
        "-14.845",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "41",
        "-14.790",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "41",
        "-14.732",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "41",
        "-14.668",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "41",
        "-14.600",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "41",
        "-14.528",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "41",
        "-14.451",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "41",
        "-14.371",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "41",
        "-14.286",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "41",
        "-14.195",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "41",
        "-14.102",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "41",
        "-14.004",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "41",
        "-13.902",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "41",
        "-13.795",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "41",
        "-13.685",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "41",
        "-13.571",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "41",
        "-13.451",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "41",
        "-13.329",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "41",
        "-13.202",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "41",
        "-13.071",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "41",
        "-12.937",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "41",
        "-12.797",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "41",
        "-12.655",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "41",
        "-12.509",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "41",
        "-12.359",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "41",
        "-12.205",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "41",
        "-12.047",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "41",
        "-11.887",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "41",
        "-11.722",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "41",
        "-11.555",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "41",
        "-11.382",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "41",
        "-11.208",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "41",
        "-11.029",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "41",
        "-10.848",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "41",
        "-10.663",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "41",
        "-10.475",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "41",
        "-10.284",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "41",
        "-10.089",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "41",
        "-9.892",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "41",
        "-9.692",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "41",
        "-9.489",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "41",
        "12.755",
        "16.567",
        "1.326",
        "1.151",
        "-0.165"
    ],
    [
        "-28",
        "41",
        "15.002",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "41",
        "15.193",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "41",
        "15.386",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "41",
        "15.579",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "41",
        "15.773",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "41",
        "15.967",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "41",
        "16.162",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "41",
        "16.359",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "41",
        "16.556",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "41",
        "16.753",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "41",
        "16.951",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "41",
        "17.149",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "41",
        "17.349",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "41",
        "17.548",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "41",
        "17.747",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "41",
        "17.946",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "41",
        "18.147",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "41",
        "18.346",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "41",
        "18.546",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "41",
        "18.746",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "41",
        "18.946",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "41",
        "19.145",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "41",
        "19.345",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "41",
        "19.544",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "41",
        "19.742",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "41",
        "19.941",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "41",
        "20.139",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "41",
        "20.337",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "41",
        "20.533",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "41",
        "20.729",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "41",
        "20.925",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "41",
        "21.120",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "41",
        "21.313",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "41",
        "21.506",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "41",
        "21.699",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "41",
        "21.891",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "41",
        "22.081",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "41",
        "22.269",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "41",
        "22.457",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "41",
        "22.644",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "41",
        "22.829",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "41",
        "23.013",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "41",
        "23.189",
        "15.956",
        "0.204",
        "0.334",
        "0.142"
    ],
    [
        "15",
        "41",
        "23.415",
        "16.685",
        "0.787",
        "0.825",
        "0.199"
    ],
    [
        "16",
        "41",
        "23.712",
        "18.326",
        "0.796",
        "0.955",
        "0.161"
    ],
    [
        "17",
        "41",
        "23.874",
        "18.763",
        "0.757",
        "0.840",
        "0.107"
    ],
    [
        "18",
        "41",
        "23.825",
        "18.255",
        "0.877",
        "0.873",
        "0.099"
    ],
    [
        "19",
        "41",
        "23.887",
        "17.361",
        "0.841",
        "0.889",
        "0.035"
    ],
    [
        "20",
        "41",
        "23.890",
        "15.684",
        "0.829",
        "0.893",
        "-0.021"
    ],
    [
        "21",
        "41",
        "23.984",
        "13.774",
        "0.839",
        "0.866",
        "-0.057"
    ],
    [
        "22",
        "41",
        "24.178",
        "11.720",
        "0.758",
        "0.880",
        "-0.069"
    ],
    [
        "23",
        "41",
        "24.414",
        "10.165",
        "0.702",
        "0.811",
        "-0.019"
    ],
    [
        "24",
        "41",
        "24.711",
        "9.286",
        "0.837",
        "0.864",
        "0.018"
    ],
    [
        "25",
        "41",
        "24.670",
        "9.984",
        "0.981",
        "1.159",
        "0.083"
    ],
    [
        "26",
        "41",
        "25.171",
        "9.341",
        "1.027",
        "1.180",
        "0.081"
    ],
    [
        "27",
        "41",
        "25.050",
        "13.314",
        "1.451",
        "1.595",
        "0.057"
    ],
    [
        "28",
        "41",
        "25.545",
        "13.113",
        "1.052",
        "1.106",
        "0.049"
    ],
    [
        "29",
        "41",
        "21.331",
        "13.900",
        "0.851",
        "0.846",
        "0.068"
    ],
    [
        "30",
        "41",
        "22.169",
        "13.720",
        "1.638",
        "1.467",
        "0.098"
    ],
    [
        "31",
        "41",
        "23.875",
        "9.178",
        "1.188",
        "1.463",
        "0.141"
    ],
    [
        "32",
        "41",
        "21.565",
        "13.237",
        "0.842",
        "0.810",
        "-0.094"
    ],
    [
        "33",
        "41",
        "21.647",
        "13.196",
        "2.108",
        "2.134",
        "0.156"
    ],
    [
        "34",
        "41",
        "19.618",
        "12.473",
        "2.579",
        "2.638",
        "0.079"
    ],
    [
        "35",
        "41",
        "20.216",
        "13.675",
        "2.677",
        "2.474",
        "-0.084"
    ],
    [
        "36",
        "41",
        "20.957",
        "13.865",
        "2.663",
        "2.253",
        "-0.091"
    ],
    [
        "37",
        "41",
        "22.460",
        "13.216",
        "2.560",
        "2.564",
        "-0.074"
    ],
    [
        "38",
        "41",
        "25.746",
        "11.648",
        "1.894",
        "2.259",
        "-0.034"
    ],
    [
        "39",
        "41",
        "27.156",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "41",
        "27.284",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "41",
        "27.410",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "41",
        "28.449",
        "12.923",
        "1.338",
        "1.415",
        "0.112"
    ],
    [
        "43",
        "41",
        "28.715",
        "14.103",
        "0.939",
        "1.215",
        "0.110"
    ],
    [
        "44",
        "41",
        "29.538",
        "14.816",
        "0.832",
        "0.931",
        "-0.001"
    ],
    [
        "45",
        "41",
        "30.189",
        "15.244",
        "0.797",
        "0.773",
        "-0.002"
    ],
    [
        "46",
        "41",
        "29.798",
        "14.852",
        "1.059",
        "1.027",
        "-0.060"
    ],
    [
        "47",
        "41",
        "29.214",
        "14.592",
        "1.379",
        "1.332",
        "-0.038"
    ],
    [
        "48",
        "41",
        "29.023",
        "13.965",
        "1.844",
        "1.651",
        "-0.027"
    ],
    [
        "49",
        "41",
        "29.109",
        "12.787",
        "1.706",
        "2.005",
        "0.095"
    ],
    [
        "50",
        "41",
        "29.214",
        "10.831",
        "1.596",
        "1.994",
        "0.343"
    ],
    [
        "51",
        "41",
        "28.513",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "41",
        "28.607",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "41",
        "28.697",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "41",
        "28.785",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "41",
        "28.870",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "41",
        "28.951",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "41",
        "29.029",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "41",
        "29.103",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "41",
        "29.175",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "41",
        "29.244",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "41",
        "29.309",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "41",
        "29.370",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "41",
        "29.429",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "41",
        "29.485",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "41",
        "29.536",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "41",
        "29.585",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "41",
        "29.629",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "41",
        "29.671",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "41",
        "29.207",
        "3.237",
        "0.886",
        "0.922",
        "-0.092"
    ],
    [
        "70",
        "41",
        "28.223",
        "3.152",
        "0.993",
        "1.178",
        "-0.090"
    ],
    [
        "71",
        "41",
        "26.786",
        "4.851",
        "1.073",
        "1.287",
        "-0.145"
    ],
    [
        "72",
        "41",
        "25.646",
        "6.546",
        "1.199",
        "1.352",
        "-0.116"
    ],
    [
        "73",
        "41",
        "25.941",
        "7.243",
        "1.172",
        "1.467",
        "-0.076"
    ],
    [
        "74",
        "41",
        "27.802",
        "6.993",
        "1.182",
        "1.495",
        "-0.080"
    ],
    [
        "75",
        "41",
        "29.664",
        "8.091",
        "1.206",
        "1.360",
        "-0.112"
    ],
    [
        "76",
        "41",
        "29.997",
        "11.376",
        "0.820",
        "0.970",
        "-0.007"
    ],
    [
        "77",
        "41",
        "30.330",
        "13.928",
        "1.003",
        "1.266",
        "-0.109"
    ],
    [
        "78",
        "41",
        "31.054",
        "15.145",
        "1.076",
        "1.283",
        "-0.153"
    ],
    [
        "79",
        "41",
        "32.510",
        "14.575",
        "0.792",
        "1.018",
        "-0.186"
    ],
    [
        "80",
        "41",
        "33.251",
        "15.708",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "41",
        "33.423",
        "14.380",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "41",
        "33.580",
        "13.047",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "41",
        "33.722",
        "11.711",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "41",
        "33.849",
        "10.370",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "41",
        "33.959",
        "9.028",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "41",
        "34.055",
        "7.681",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "41",
        "34.135",
        "6.333",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "41",
        "34.200",
        "4.983",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "41",
        "34.249",
        "3.631",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "41",
        "34.284",
        "2.279",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "41",
        "33.978",
        "1.226",
        "0.695",
        "0.771",
        "-0.091"
    ],
    [
        "92",
        "41",
        "33.287",
        "0.758",
        "1.071",
        "1.293",
        "-0.065"
    ],
    [
        "93",
        "41",
        "32.799",
        "-0.165",
        "1.236",
        "1.338",
        "-0.038"
    ],
    [
        "94",
        "41",
        "32.502",
        "-1.222",
        "1.198",
        "1.361",
        "0.019"
    ],
    [
        "95",
        "41",
        "32.451",
        "-2.467",
        "1.170",
        "1.321",
        "0.045"
    ],
    [
        "96",
        "41",
        "32.495",
        "-3.706",
        "1.165",
        "1.215",
        "0.006"
    ],
    [
        "97",
        "41",
        "32.576",
        "-4.785",
        "1.069",
        "1.214",
        "-0.016"
    ],
    [
        "98",
        "41",
        "32.683",
        "-5.806",
        "1.027",
        "1.098",
        "-0.002"
    ],
    [
        "99",
        "41",
        "32.726",
        "-6.551",
        "1.014",
        "1.009",
        "-0.014"
    ],
    [
        "100",
        "41",
        "32.638",
        "-7.004",
        "0.863",
        "0.930",
        "-0.026"
    ],
    [
        "101",
        "41",
        "32.435",
        "-7.433",
        "0.868",
        "0.934",
        "-0.023"
    ],
    [
        "102",
        "41",
        "32.199",
        "-7.770",
        "0.903",
        "0.901",
        "-0.026"
    ],
    [
        "103",
        "41",
        "31.953",
        "-8.017",
        "0.872",
        "0.904",
        "-0.030"
    ],
    [
        "104",
        "41",
        "31.713",
        "-8.201",
        "0.899",
        "0.912",
        "-0.032"
    ],
    [
        "105",
        "41",
        "31.396",
        "-8.350",
        "1.024",
        "1.018",
        "-0.029"
    ],
    [
        "106",
        "41",
        "31.104",
        "-8.557",
        "0.937",
        "1.036",
        "-0.024"
    ],
    [
        "107",
        "41",
        "31.123",
        "-9.099",
        "0.876",
        "0.992",
        "-0.024"
    ],
    [
        "108",
        "41",
        "31.224",
        "-9.449",
        "0.875",
        "0.860",
        "-0.041"
    ],
    [
        "109",
        "41",
        "31.126",
        "-9.599",
        "0.812",
        "0.945",
        "0.012"
    ],
    [
        "110",
        "41",
        "30.974",
        "-9.538",
        "0.789",
        "0.906",
        "-0.017"
    ],
    [
        "111",
        "41",
        "30.872",
        "-10.113",
        "0.878",
        "0.884",
        "-0.019"
    ],
    [
        "112",
        "41",
        "30.618",
        "-10.447",
        "0.808",
        "0.937",
        "-0.047"
    ],
    [
        "113",
        "41",
        "30.429",
        "-10.987",
        "0.720",
        "0.759",
        "-0.012"
    ],
    [
        "114",
        "41",
        "30.212",
        "-10.428",
        "0.747",
        "0.738",
        "-0.021"
    ],
    [
        "115",
        "41",
        "30.186",
        "-9.877",
        "0.707",
        "0.781",
        "-0.046"
    ],
    [
        "116",
        "41",
        "30.243",
        "-10.971",
        "0.665",
        "0.770",
        "-0.039"
    ],
    [
        "117",
        "41",
        "29.952",
        "-11.891",
        "0.818",
        "0.830",
        "-0.034"
    ],
    [
        "118",
        "41",
        "29.463",
        "-11.735",
        "0.605",
        "0.619",
        "-0.025"
    ],
    [
        "119",
        "41",
        "29.650",
        "-11.987",
        "0.695",
        "0.793",
        "0.016"
    ],
    [
        "120",
        "41",
        "29.844",
        "-12.290",
        "0.830",
        "0.842",
        "-0.033"
    ],
    [
        "121",
        "41",
        "29.264",
        "-12.267",
        "0.876",
        "0.999",
        "-0.024"
    ],
    [
        "122",
        "41",
        "28.783",
        "-12.710",
        "0.850",
        "0.953",
        "-0.010"
    ],
    [
        "123",
        "41",
        "28.410",
        "-13.133",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "41",
        "28.259",
        "-13.321",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "41",
        "28.104",
        "-13.506",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "41",
        "27.949",
        "-13.687",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "41",
        "27.791",
        "-13.864",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "41",
        "27.632",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "41",
        "27.470",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "41",
        "27.306",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "41",
        "27.141",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "41",
        "26.973",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "41",
        "26.805",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "41",
        "26.634",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "41",
        "26.462",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "41",
        "26.287",
        "-15.257",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "41",
        "26.112",
        "-15.389",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "41",
        "25.935",
        "-15.517",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "41",
        "18.542",
        "-13.570",
        "2.399",
        "1.542",
        "-0.255"
    ],
    [
        "140",
        "41",
        "13.099",
        "-11.518",
        "2.213",
        "1.883",
        "-0.113"
    ],
    [
        "141",
        "41",
        "10.766",
        "-12.778",
        "1.869",
        "1.818",
        "-0.151"
    ],
    [
        "142",
        "41",
        "4.589",
        "-11.018",
        "4.481",
        "3.586",
        "-0.311"
    ],
    [
        "143",
        "41",
        "-11.382",
        "-2.672",
        "6.318",
        "4.504",
        "-0.307"
    ],
    [
        "144",
        "41",
        "-31.685",
        "6.449",
        "8.145",
        "5.304",
        "-0.358"
    ],
    [
        "145",
        "41",
        "-49.953",
        "13.148",
        "7.054",
        "4.807",
        "-0.193"
    ],
    [
        "146",
        "41",
        "-65.172",
        "19.162",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "41",
        "-64.950",
        "19.581",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "41",
        "-64.723",
        "19.994",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "41",
        "-64.492",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "41",
        "-64.256",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "41",
        "-64.016",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "41",
        "-63.771",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "41",
        "-63.521",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "41",
        "-63.268",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "41",
        "-63.010",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "41",
        "-62.748",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "41",
        "-62.482",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "41",
        "-62.211",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "41",
        "-61.937",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "41",
        "-61.659",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "41",
        "-61.378",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "41",
        "-61.092",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "41",
        "-60.803",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "41",
        "-60.511",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "41",
        "-60.215",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "41",
        "-59.916",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "41",
        "-59.613",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "41",
        "-59.308",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "41",
        "-58.999",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "41",
        "-58.688",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "41",
        "-58.373",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "41",
        "-58.056",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "41",
        "-57.736",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "41",
        "-57.414",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "41",
        "-57.089",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "41",
        "-56.762",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "41",
        "-56.432",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "41",
        "-56.100",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "41",
        "-55.767",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "42",
        "-54.797",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "42",
        "-54.453",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "42",
        "-54.107",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "42",
        "-53.759",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "42",
        "-53.410",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "42",
        "-53.059",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "42",
        "-52.707",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "42",
        "-52.353",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "42",
        "-51.999",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "42",
        "-51.643",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "42",
        "-51.286",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "42",
        "-50.929",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "42",
        "-50.571",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "42",
        "-50.212",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "42",
        "-49.853",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "42",
        "-49.493",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "42",
        "-49.133",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "42",
        "-48.773",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "42",
        "-48.413",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "42",
        "-48.052",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "42",
        "-47.692",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "42",
        "-47.333",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "42",
        "-46.973",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "42",
        "-46.614",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "42",
        "-46.256",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "42",
        "-45.899",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "42",
        "-45.542",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "42",
        "-45.186",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "42",
        "-44.831",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "42",
        "-44.477",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "42",
        "-44.125",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "42",
        "-43.774",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "42",
        "-43.424",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "42",
        "-43.076",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "42",
        "-42.730",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "42",
        "-42.385",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "42",
        "-42.042",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "42",
        "-41.702",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "42",
        "-41.363",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "42",
        "-41.027",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "42",
        "-40.692",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "42",
        "-40.361",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "42",
        "-40.031",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "42",
        "-39.705",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "42",
        "-39.381",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "42",
        "-39.059",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "42",
        "-38.741",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "42",
        "-38.426",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "42",
        "-38.114",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "42",
        "-37.804",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "42",
        "-37.499",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "42",
        "-37.196",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "42",
        "-36.897",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "42",
        "-30.140",
        "22.571",
        "8.614",
        "8.169",
        "0.009"
    ],
    [
        "-126",
        "42",
        "-11.744",
        "11.512",
        "8.074",
        "6.134",
        "-0.133"
    ],
    [
        "-125",
        "42",
        "-4.662",
        "-4.438",
        "4.012",
        "4.678",
        "0.002"
    ],
    [
        "-124",
        "42",
        "-10.052",
        "-2.274",
        "0.883",
        "1.444",
        "0.020"
    ],
    [
        "-123",
        "42",
        "-12.877",
        "-6.779",
        "1.154",
        "1.035",
        "0.076"
    ],
    [
        "-122",
        "42",
        "-14.080",
        "-8.493",
        "1.012",
        "0.994",
        "-0.041"
    ],
    [
        "-121",
        "42",
        "-14.249",
        "-9.316",
        "1.160",
        "1.133",
        "0.098"
    ],
    [
        "-120",
        "42",
        "-14.406",
        "-9.811",
        "1.220",
        "1.137",
        "0.092"
    ],
    [
        "-119",
        "42",
        "-14.571",
        "-10.448",
        "0.911",
        "0.997",
        "0.051"
    ],
    [
        "-118",
        "42",
        "-15.164",
        "-11.613",
        "1.324",
        "1.364",
        "0.001"
    ],
    [
        "-117",
        "42",
        "-15.321",
        "-12.298",
        "1.505",
        "1.493",
        "0.049"
    ],
    [
        "-116",
        "42",
        "-15.353",
        "-12.572",
        "1.353",
        "1.410",
        "0.064"
    ],
    [
        "-115",
        "42",
        "-15.330",
        "-12.421",
        "1.371",
        "1.416",
        "0.073"
    ],
    [
        "-114",
        "42",
        "-15.143",
        "-12.100",
        "1.405",
        "1.269",
        "0.125"
    ],
    [
        "-113",
        "42",
        "-14.733",
        "-11.803",
        "1.262",
        "1.178",
        "0.133"
    ],
    [
        "-112",
        "42",
        "-14.155",
        "-11.303",
        "1.174",
        "1.047",
        "0.060"
    ],
    [
        "-111",
        "42",
        "-13.669",
        "-10.974",
        "1.149",
        "0.866",
        "0.008"
    ],
    [
        "-110",
        "42",
        "-13.599",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "42",
        "-13.723",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "42",
        "-13.844",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "42",
        "-13.961",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "42",
        "-14.073",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "42",
        "-14.182",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "42",
        "-14.285",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "42",
        "-14.385",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "42",
        "-14.481",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "42",
        "-14.571",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "42",
        "-14.658",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "42",
        "-14.740",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "42",
        "-14.817",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "42",
        "-14.891",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "42",
        "-14.960",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "42",
        "-15.023",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "42",
        "-15.084",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "42",
        "-15.138",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "42",
        "-15.190",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "42",
        "-15.236",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "42",
        "-15.277",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "42",
        "-15.314",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "42",
        "-15.346",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "42",
        "-15.373",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "42",
        "-15.397",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "42",
        "-15.415",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "42",
        "-15.429",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "42",
        "-15.438",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "42",
        "-15.442",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "42",
        "-15.442",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "42",
        "-15.438",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "42",
        "-15.428",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "42",
        "-15.414",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "42",
        "-15.395",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "42",
        "-15.371",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "42",
        "-15.344",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "42",
        "-15.311",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "42",
        "-15.274",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "42",
        "-15.231",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "42",
        "-15.185",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "42",
        "-15.134",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "42",
        "-15.078",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "42",
        "-15.019",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "42",
        "-14.954",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "42",
        "-14.885",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "42",
        "-14.811",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "42",
        "-14.733",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "42",
        "-14.650",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "42",
        "-14.563",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "42",
        "-14.473",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "42",
        "-14.377",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "42",
        "-14.276",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "42",
        "-14.172",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "42",
        "-14.064",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "42",
        "-13.951",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "42",
        "-13.834",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "42",
        "-13.713",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "42",
        "-13.588",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "42",
        "-13.458",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "42",
        "-13.325",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "42",
        "-13.187",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "42",
        "-13.046",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "42",
        "-12.901",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "42",
        "-12.752",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "42",
        "-12.599",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "42",
        "-12.443",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "42",
        "-12.281",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "42",
        "-12.117",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "42",
        "-11.950",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "42",
        "-11.778",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "42",
        "-11.603",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "42",
        "-11.425",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "42",
        "-11.243",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "42",
        "-11.058",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "42",
        "-10.869",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "42",
        "-10.678",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "42",
        "-10.482",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "42",
        "-10.284",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "42",
        "-10.083",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "42",
        "-9.879",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "42",
        "-9.672",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "42",
        "12.107",
        "16.703",
        "1.276",
        "1.131",
        "-0.239"
    ],
    [
        "-28",
        "42",
        "14.650",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "42",
        "14.846",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "42",
        "15.041",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "42",
        "15.238",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "42",
        "15.436",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "42",
        "15.635",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "42",
        "15.835",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "42",
        "16.034",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "42",
        "16.236",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "42",
        "16.436",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "42",
        "16.638",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "42",
        "16.841",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "42",
        "17.043",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "42",
        "17.247",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "42",
        "17.450",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "42",
        "17.654",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "42",
        "17.857",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "42",
        "18.062",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "42",
        "18.265",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "42",
        "18.469",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "42",
        "18.673",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "42",
        "18.876",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "42",
        "19.080",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "42",
        "19.283",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "42",
        "19.485",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "42",
        "19.688",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "42",
        "19.890",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "42",
        "20.092",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "42",
        "20.292",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "42",
        "20.492",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "42",
        "20.692",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "42",
        "20.890",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "42",
        "21.088",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "42",
        "21.285",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "42",
        "21.481",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "42",
        "21.676",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "42",
        "21.870",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "42",
        "22.063",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "42",
        "22.254",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "42",
        "22.445",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "42",
        "22.634",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "42",
        "22.773",
        "16.200",
        "0.606",
        "0.696",
        "0.236"
    ],
    [
        "14",
        "42",
        "23.144",
        "16.611",
        "0.864",
        "0.932",
        "0.211"
    ],
    [
        "15",
        "42",
        "23.428",
        "17.208",
        "0.909",
        "0.969",
        "0.178"
    ],
    [
        "16",
        "42",
        "23.670",
        "18.253",
        "0.794",
        "0.880",
        "0.117"
    ],
    [
        "17",
        "42",
        "23.696",
        "18.124",
        "0.849",
        "0.934",
        "0.114"
    ],
    [
        "18",
        "42",
        "23.807",
        "17.457",
        "0.901",
        "0.895",
        "0.100"
    ],
    [
        "19",
        "42",
        "23.989",
        "16.526",
        "0.849",
        "0.900",
        "0.050"
    ],
    [
        "20",
        "42",
        "24.197",
        "15.378",
        "0.825",
        "0.879",
        "0.005"
    ],
    [
        "21",
        "42",
        "24.376",
        "14.154",
        "0.823",
        "0.834",
        "-0.030"
    ],
    [
        "22",
        "42",
        "24.449",
        "12.960",
        "0.720",
        "0.813",
        "-0.054"
    ],
    [
        "23",
        "42",
        "24.432",
        "12.045",
        "0.661",
        "0.748",
        "-0.017"
    ],
    [
        "24",
        "42",
        "24.480",
        "11.618",
        "0.805",
        "0.799",
        "0.011"
    ],
    [
        "25",
        "42",
        "24.386",
        "11.698",
        "0.965",
        "0.936",
        "0.092"
    ],
    [
        "26",
        "42",
        "24.892",
        "12.975",
        "1.059",
        "1.341",
        "0.014"
    ],
    [
        "27",
        "42",
        "25.100",
        "13.192",
        "1.538",
        "1.839",
        "0.023"
    ],
    [
        "28",
        "42",
        "25.402",
        "13.214",
        "0.303",
        "0.515",
        "-0.099"
    ],
    [
        "29",
        "42",
        "25.595",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "42",
        "25.750",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "42",
        "25.904",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "42",
        "26.055",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "42",
        "26.205",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "42",
        "26.352",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "42",
        "26.495",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "42",
        "26.638",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "42",
        "26.777",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "42",
        "26.913",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "42",
        "27.047",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "42",
        "27.177",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "42",
        "27.306",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "42",
        "27.770",
        "11.611",
        "0.909",
        "0.960",
        "0.096"
    ],
    [
        "43",
        "42",
        "28.518",
        "12.720",
        "0.769",
        "1.007",
        "0.026"
    ],
    [
        "44",
        "42",
        "28.925",
        "12.759",
        "0.708",
        "0.856",
        "0.122"
    ],
    [
        "45",
        "42",
        "28.997",
        "13.036",
        "0.797",
        "0.819",
        "0.039"
    ],
    [
        "46",
        "42",
        "28.868",
        "12.699",
        "0.923",
        "0.958",
        "-0.003"
    ],
    [
        "47",
        "42",
        "28.553",
        "12.049",
        "1.076",
        "1.104",
        "0.001"
    ],
    [
        "48",
        "42",
        "28.318",
        "10.931",
        "1.322",
        "1.210",
        "0.004"
    ],
    [
        "49",
        "42",
        "28.268",
        "9.017",
        "0.337",
        "0.458",
        "0.115"
    ],
    [
        "50",
        "42",
        "28.332",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "42",
        "28.430",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "42",
        "28.527",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "42",
        "28.619",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "42",
        "28.708",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "42",
        "28.794",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "42",
        "28.877",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "42",
        "28.957",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "42",
        "29.034",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "42",
        "29.106",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "42",
        "29.176",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "42",
        "29.242",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "42",
        "29.306",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "42",
        "29.365",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "42",
        "29.421",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "42",
        "29.474",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "42",
        "29.524",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "42",
        "29.570",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "42",
        "29.612",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "42",
        "29.651",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "42",
        "29.082",
        "2.795",
        "0.679",
        "0.725",
        "-0.096"
    ],
    [
        "71",
        "42",
        "28.023",
        "3.921",
        "0.938",
        "1.110",
        "-0.134"
    ],
    [
        "72",
        "42",
        "28.139",
        "4.835",
        "0.996",
        "1.038",
        "-0.113"
    ],
    [
        "73",
        "42",
        "28.937",
        "4.940",
        "0.932",
        "1.019",
        "-0.106"
    ],
    [
        "74",
        "42",
        "29.955",
        "4.656",
        "0.984",
        "1.211",
        "-0.063"
    ],
    [
        "75",
        "42",
        "30.149",
        "5.146",
        "0.934",
        "1.007",
        "-0.092"
    ],
    [
        "76",
        "42",
        "30.691",
        "6.465",
        "0.797",
        "0.972",
        "-0.096"
    ],
    [
        "77",
        "42",
        "31.428",
        "7.495",
        "0.872",
        "1.092",
        "-0.096"
    ],
    [
        "78",
        "42",
        "32.343",
        "9.320",
        "0.914",
        "1.096",
        "-0.116"
    ],
    [
        "79",
        "42",
        "32.477",
        "10.729",
        "0.774",
        "1.009",
        "-0.088"
    ],
    [
        "80",
        "42",
        "33.286",
        "12.608",
        "0.927",
        "1.478",
        "-0.273"
    ],
    [
        "81",
        "42",
        "33.687",
        "13.525",
        "1.016",
        "1.482",
        "-0.327"
    ],
    [
        "82",
        "42",
        "33.650",
        "13.376",
        "0.895",
        "1.373",
        "-0.270"
    ],
    [
        "83",
        "42",
        "33.958",
        "12.459",
        "0.896",
        "1.406",
        "-0.275"
    ],
    [
        "84",
        "42",
        "34.533",
        "10.835",
        "0.920",
        "1.175",
        "-0.203"
    ],
    [
        "85",
        "42",
        "35.220",
        "9.028",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "42",
        "35.318",
        "7.681",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "42",
        "34.899",
        "6.789",
        "0.875",
        "0.986",
        "-0.081"
    ],
    [
        "88",
        "42",
        "34.661",
        "5.924",
        "0.887",
        "1.129",
        "-0.064"
    ],
    [
        "89",
        "42",
        "34.564",
        "4.854",
        "0.921",
        "1.140",
        "-0.023"
    ],
    [
        "90",
        "42",
        "34.409",
        "3.851",
        "1.026",
        "1.128",
        "-0.012"
    ],
    [
        "91",
        "42",
        "34.152",
        "2.940",
        "1.013",
        "1.203",
        "-0.075"
    ],
    [
        "92",
        "42",
        "33.889",
        "1.848",
        "1.077",
        "1.249",
        "-0.073"
    ],
    [
        "93",
        "42",
        "33.692",
        "0.565",
        "1.179",
        "1.251",
        "-0.038"
    ],
    [
        "94",
        "42",
        "33.602",
        "-0.824",
        "1.139",
        "1.287",
        "0.006"
    ],
    [
        "95",
        "42",
        "33.442",
        "-2.208",
        "1.150",
        "1.304",
        "0.039"
    ],
    [
        "96",
        "42",
        "33.300",
        "-3.602",
        "1.159",
        "1.209",
        "0.062"
    ],
    [
        "97",
        "42",
        "33.218",
        "-4.784",
        "0.990",
        "1.087",
        "0.022"
    ],
    [
        "98",
        "42",
        "33.114",
        "-5.735",
        "0.932",
        "1.007",
        "0.006"
    ],
    [
        "99",
        "42",
        "32.941",
        "-6.468",
        "0.930",
        "0.948",
        "-0.012"
    ],
    [
        "100",
        "42",
        "32.708",
        "-7.022",
        "0.873",
        "0.938",
        "-0.030"
    ],
    [
        "101",
        "42",
        "32.416",
        "-7.471",
        "0.857",
        "0.916",
        "-0.046"
    ],
    [
        "102",
        "42",
        "32.088",
        "-7.866",
        "0.880",
        "0.885",
        "-0.045"
    ],
    [
        "103",
        "42",
        "31.765",
        "-8.172",
        "0.832",
        "0.890",
        "-0.052"
    ],
    [
        "104",
        "42",
        "31.437",
        "-8.396",
        "0.825",
        "0.865",
        "-0.052"
    ],
    [
        "105",
        "42",
        "31.094",
        "-8.650",
        "0.877",
        "0.854",
        "-0.026"
    ],
    [
        "106",
        "42",
        "30.691",
        "-9.023",
        "0.819",
        "0.890",
        "-0.037"
    ],
    [
        "107",
        "42",
        "30.366",
        "-9.608",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "42",
        "30.252",
        "-9.854",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "42",
        "30.136",
        "-10.096",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "42",
        "30.016",
        "-10.335",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "42",
        "29.894",
        "-10.571",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "42",
        "29.770",
        "-10.804",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "42",
        "29.642",
        "-11.034",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "42",
        "29.512",
        "-11.259",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "42",
        "29.379",
        "-11.482",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "42",
        "29.244",
        "-11.702",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "42",
        "29.106",
        "-11.917",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "42",
        "28.966",
        "-12.129",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "42",
        "28.823",
        "-12.338",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "42",
        "28.677",
        "-12.542",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "42",
        "28.530",
        "-12.742",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "42",
        "28.379",
        "-12.940",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "42",
        "28.228",
        "-13.133",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "42",
        "28.073",
        "-13.321",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "42",
        "27.916",
        "-13.506",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "42",
        "27.757",
        "-13.687",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "42",
        "27.597",
        "-13.864",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "42",
        "27.433",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "42",
        "27.269",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "42",
        "27.101",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "42",
        "26.933",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "42",
        "26.763",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "42",
        "26.590",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "42",
        "26.416",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "42",
        "26.241",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "42",
        "26.063",
        "-15.257",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "42",
        "25.884",
        "-15.389",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "42",
        "25.703",
        "-15.517",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "42",
        "18.424",
        "-14.661",
        "2.024",
        "1.496",
        "-0.202"
    ],
    [
        "140",
        "42",
        "8.653",
        "-12.855",
        "1.519",
        "1.600",
        "-0.057"
    ],
    [
        "141",
        "42",
        "11.200",
        "-13.315",
        "2.071",
        "2.043",
        "-0.136"
    ],
    [
        "142",
        "42",
        "4.800",
        "-12.946",
        "3.731",
        "3.393",
        "-0.218"
    ],
    [
        "143",
        "42",
        "-3.428",
        "-8.930",
        "2.611",
        "2.497",
        "-0.072"
    ],
    [
        "144",
        "42",
        "-15.508",
        "-0.486",
        "7.265",
        "5.041",
        "-0.425"
    ],
    [
        "145",
        "42",
        "-27.354",
        "4.599",
        "7.817",
        "5.214",
        "-0.292"
    ],
    [
        "146",
        "42",
        "-39.740",
        "9.216",
        "8.035",
        "5.252",
        "-0.229"
    ],
    [
        "147",
        "42",
        "-52.720",
        "14.771",
        "7.832",
        "4.923",
        "-0.322"
    ],
    [
        "148",
        "42",
        "-63.485",
        "18.785",
        "1.505",
        "2.004",
        "-0.322"
    ],
    [
        "149",
        "42",
        "-64.039",
        "20.401",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "42",
        "-63.798",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "42",
        "-63.553",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "42",
        "-63.303",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "42",
        "-63.049",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "42",
        "-62.790",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "42",
        "-62.527",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "42",
        "-62.260",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "42",
        "-61.988",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "42",
        "-61.713",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "42",
        "-61.433",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "42",
        "-61.150",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "42",
        "-60.862",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "42",
        "-60.571",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "42",
        "-60.277",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "42",
        "-59.978",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "42",
        "-59.676",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "42",
        "-59.371",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "42",
        "-59.063",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "42",
        "-58.751",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "42",
        "-58.436",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "42",
        "-58.119",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "42",
        "-57.798",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "42",
        "-57.474",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "42",
        "-57.148",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "42",
        "-56.819",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "42",
        "-56.488",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "42",
        "-56.154",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "42",
        "-55.818",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "42",
        "-55.480",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "42",
        "-55.140",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "43",
        "-54.147",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "43",
        "-53.796",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "43",
        "-53.443",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "43",
        "-53.089",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "43",
        "-52.732",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "43",
        "-52.375",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "43",
        "-52.016",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "43",
        "-51.656",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "43",
        "-51.294",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "43",
        "-50.932",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "43",
        "-50.568",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "43",
        "-50.204",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "43",
        "-49.839",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "43",
        "-49.473",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "43",
        "-49.107",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "43",
        "-48.740",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "43",
        "-48.373",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "43",
        "-48.006",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "43",
        "-47.639",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "43",
        "-47.272",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "43",
        "-46.905",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "43",
        "-46.539",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "43",
        "-46.172",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "43",
        "-45.807",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "43",
        "-45.441",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "43",
        "-45.077",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "43",
        "-44.713",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "43",
        "-44.351",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "43",
        "-43.989",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "43",
        "-43.628",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "43",
        "-43.269",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "43",
        "-42.911",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "43",
        "-42.555",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "43",
        "-42.200",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "43",
        "-41.847",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "43",
        "-41.496",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "43",
        "-41.147",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "43",
        "-40.799",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "43",
        "-40.454",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "43",
        "-40.111",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "43",
        "-39.771",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "43",
        "-39.432",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "43",
        "-39.097",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "43",
        "-38.764",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "43",
        "-38.434",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "43",
        "-38.106",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "43",
        "-37.782",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "43",
        "-37.460",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "43",
        "-37.142",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "43",
        "-36.827",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "43",
        "-36.516",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "43",
        "-36.207",
        "25.747",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "43",
        "-35.903",
        "25.446",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "43",
        "-9.935",
        "11.664",
        "7.297",
        "6.856",
        "-0.183"
    ],
    [
        "-126",
        "43",
        "9.976",
        "0.894",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "43",
        "3.425",
        "-1.330",
        "1.197",
        "1.937",
        "0.259"
    ],
    [
        "-124",
        "43",
        "-8.869",
        "-6.139",
        "0.801",
        "0.750",
        "-0.053"
    ],
    [
        "-123",
        "43",
        "-10.961",
        "-8.015",
        "1.248",
        "1.081",
        "0.115"
    ],
    [
        "-122",
        "43",
        "-11.723",
        "-10.454",
        "0.909",
        "0.937",
        "-0.025"
    ],
    [
        "-121",
        "43",
        "-12.575",
        "-10.614",
        "0.950",
        "0.908",
        "0.115"
    ],
    [
        "-120",
        "43",
        "-13.376",
        "-10.843",
        "1.050",
        "1.005",
        "0.060"
    ],
    [
        "-119",
        "43",
        "-13.674",
        "-11.047",
        "1.598",
        "1.800",
        "0.039"
    ],
    [
        "-118",
        "43",
        "-14.366",
        "-11.850",
        "1.272",
        "1.229",
        "0.023"
    ],
    [
        "-117",
        "43",
        "-14.576",
        "-12.233",
        "1.702",
        "1.801",
        "0.020"
    ],
    [
        "-116",
        "43",
        "-14.763",
        "-12.377",
        "1.564",
        "1.784",
        "0.090"
    ],
    [
        "-115",
        "43",
        "-14.917",
        "-12.308",
        "1.445",
        "1.540",
        "0.150"
    ],
    [
        "-114",
        "43",
        "-15.035",
        "-12.130",
        "1.355",
        "1.221",
        "0.210"
    ],
    [
        "-113",
        "43",
        "-15.056",
        "-11.792",
        "1.063",
        "0.945",
        "0.193"
    ],
    [
        "-112",
        "43",
        "-14.475",
        "-11.464",
        "1.277",
        "1.170",
        "0.166"
    ],
    [
        "-111",
        "43",
        "-13.593",
        "-10.888",
        "1.056",
        "0.792",
        "0.068"
    ],
    [
        "-110",
        "43",
        "-13.854",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "43",
        "-13.981",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "43",
        "-14.104",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "43",
        "-14.223",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "43",
        "-14.337",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "43",
        "-14.448",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "43",
        "-14.554",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "43",
        "-14.655",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "43",
        "-14.752",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "43",
        "-14.845",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "43",
        "-14.934",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "43",
        "-15.017",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "43",
        "-15.096",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "43",
        "-15.171",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "43",
        "-15.241",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "43",
        "-15.306",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "43",
        "-15.367",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "43",
        "-15.423",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "43",
        "-15.475",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "43",
        "-15.522",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "43",
        "-15.564",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "43",
        "-15.602",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "43",
        "-15.635",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "43",
        "-15.663",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "43",
        "-15.686",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "43",
        "-15.705",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "43",
        "-15.719",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "43",
        "-15.729",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "43",
        "-15.733",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "43",
        "-15.733",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "43",
        "-15.728",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "43",
        "-15.718",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "43",
        "-15.704",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "43",
        "-15.685",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "43",
        "-15.661",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "43",
        "-15.633",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "43",
        "-15.600",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "43",
        "-15.562",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "43",
        "-15.519",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "43",
        "-15.471",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "43",
        "-15.419",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "43",
        "-15.363",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "43",
        "-15.302",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "43",
        "-15.235",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "43",
        "-15.165",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "43",
        "-15.090",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "43",
        "-15.011",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "43",
        "-14.926",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "43",
        "-14.838",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "43",
        "-14.744",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "43",
        "-14.647",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "43",
        "-14.545",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "43",
        "-14.439",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "43",
        "-14.329",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "43",
        "-14.213",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "43",
        "-14.094",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "43",
        "-13.971",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "43",
        "-13.843",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "43",
        "-13.711",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "43",
        "-13.575",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "43",
        "-13.435",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "43",
        "-13.291",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "43",
        "-13.143",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "43",
        "-12.991",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "43",
        "-12.835",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "43",
        "-12.675",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "43",
        "-12.512",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "43",
        "-12.344",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "43",
        "-12.173",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "43",
        "-11.999",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "43",
        "-11.820",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "43",
        "-11.638",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "43",
        "-11.453",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "43",
        "-11.264",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "43",
        "-11.072",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "43",
        "-10.877",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "43",
        "-10.677",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "43",
        "-10.476",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "43",
        "-10.271",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "43",
        "-10.063",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "43",
        "-9.851",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "43",
        "7.944",
        "16.957",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "43",
        "14.295",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "43",
        "14.494",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "43",
        "14.693",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "43",
        "14.894",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "43",
        "15.096",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "43",
        "15.298",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "43",
        "15.501",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "43",
        "15.705",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "43",
        "15.910",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "43",
        "16.116",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "43",
        "16.321",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "43",
        "16.527",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "43",
        "16.733",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "43",
        "16.941",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "43",
        "17.148",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "43",
        "17.355",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "43",
        "17.564",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "43",
        "17.771",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "43",
        "17.979",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "43",
        "18.186",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "43",
        "18.395",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "43",
        "18.602",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "43",
        "18.809",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "43",
        "19.016",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "43",
        "19.223",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "43",
        "19.429",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "43",
        "19.635",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "43",
        "19.840",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "43",
        "20.045",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "43",
        "20.249",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "43",
        "20.452",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "43",
        "20.655",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "43",
        "20.857",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "43",
        "21.057",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "43",
        "21.257",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "43",
        "21.456",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "43",
        "21.653",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "43",
        "21.850",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "43",
        "22.045",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "43",
        "22.239",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "43",
        "22.250",
        "16.379",
        "0.725",
        "0.725",
        "0.211"
    ],
    [
        "13",
        "43",
        "22.416",
        "16.416",
        "0.798",
        "0.926",
        "0.195"
    ],
    [
        "14",
        "43",
        "22.726",
        "16.625",
        "0.901",
        "0.994",
        "0.166"
    ],
    [
        "15",
        "43",
        "22.980",
        "17.130",
        "0.927",
        "0.956",
        "0.128"
    ],
    [
        "16",
        "43",
        "23.190",
        "17.851",
        "0.820",
        "0.902",
        "0.086"
    ],
    [
        "17",
        "43",
        "23.478",
        "17.628",
        "0.792",
        "0.862",
        "0.119"
    ],
    [
        "18",
        "43",
        "23.642",
        "16.733",
        "0.837",
        "0.842",
        "0.089"
    ],
    [
        "19",
        "43",
        "23.878",
        "15.906",
        "0.778",
        "0.829",
        "0.029"
    ],
    [
        "20",
        "43",
        "24.108",
        "15.190",
        "0.748",
        "0.792",
        "0.011"
    ],
    [
        "21",
        "43",
        "24.284",
        "14.538",
        "0.755",
        "0.761",
        "-0.002"
    ],
    [
        "22",
        "43",
        "24.377",
        "14.024",
        "0.640",
        "0.727",
        "-0.028"
    ],
    [
        "23",
        "43",
        "24.257",
        "13.568",
        "0.541",
        "0.610",
        "-0.011"
    ],
    [
        "24",
        "43",
        "24.650",
        "13.561",
        "0.693",
        "0.716",
        "0.020"
    ],
    [
        "25",
        "43",
        "25.328",
        "14.031",
        "0.717",
        "0.823",
        "0.109"
    ],
    [
        "26",
        "43",
        "25.274",
        "13.947",
        "1.068",
        "1.145",
        "0.178"
    ],
    [
        "27",
        "43",
        "25.478",
        "13.645",
        "1.357",
        "1.232",
        "0.147"
    ],
    [
        "28",
        "43",
        "25.526",
        "13.724",
        "0.286",
        "0.521",
        "0.036"
    ],
    [
        "29",
        "43",
        "25.449",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "43",
        "25.608",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "43",
        "25.765",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "43",
        "25.919",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "43",
        "26.071",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "43",
        "26.220",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "43",
        "26.368",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "43",
        "26.512",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "43",
        "26.653",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "43",
        "26.793",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "43",
        "26.929",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "43",
        "27.063",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "43",
        "27.193",
        "10.880",
        "0.170",
        "0.273",
        "-0.005"
    ],
    [
        "42",
        "43",
        "27.401",
        "10.874",
        "0.666",
        "0.755",
        "0.042"
    ],
    [
        "43",
        "43",
        "27.592",
        "10.565",
        "0.589",
        "0.798",
        "0.092"
    ],
    [
        "44",
        "43",
        "27.920",
        "10.601",
        "0.543",
        "0.724",
        "0.054"
    ],
    [
        "45",
        "43",
        "27.989",
        "10.847",
        "0.659",
        "0.754",
        "0.085"
    ],
    [
        "46",
        "43",
        "28.051",
        "10.584",
        "0.653",
        "0.792",
        "0.089"
    ],
    [
        "47",
        "43",
        "28.021",
        "9.759",
        "0.553",
        "0.605",
        "0.087"
    ],
    [
        "48",
        "43",
        "28.027",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "43",
        "28.135",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "43",
        "28.239",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "43",
        "28.339",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "43",
        "28.437",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "43",
        "28.532",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "43",
        "28.622",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "43",
        "28.711",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "43",
        "28.795",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "43",
        "28.876",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "43",
        "28.954",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "43",
        "29.028",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "43",
        "29.100",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "43",
        "29.167",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "43",
        "29.232",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "43",
        "29.293",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "43",
        "29.349",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "43",
        "29.403",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "43",
        "29.453",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "43",
        "29.501",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "43",
        "29.544",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "43",
        "29.583",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "43",
        "29.620",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "43",
        "29.652",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "43",
        "29.681",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "43",
        "29.905",
        "1.999",
        "0.668",
        "0.747",
        "-0.019"
    ],
    [
        "74",
        "43",
        "30.447",
        "1.870",
        "0.866",
        "1.086",
        "0.023"
    ],
    [
        "75",
        "43",
        "31.030",
        "1.693",
        "0.851",
        "0.931",
        "-0.119"
    ],
    [
        "76",
        "43",
        "31.102",
        "2.489",
        "0.803",
        "0.960",
        "-0.086"
    ],
    [
        "77",
        "43",
        "31.303",
        "2.595",
        "0.598",
        "0.667",
        "-0.064"
    ],
    [
        "78",
        "43",
        "32.297",
        "1.960",
        "0.874",
        "1.049",
        "-0.117"
    ],
    [
        "79",
        "43",
        "32.243",
        "3.409",
        "0.793",
        "1.025",
        "-0.128"
    ],
    [
        "80",
        "43",
        "33.179",
        "5.010",
        "1.033",
        "1.506",
        "-0.203"
    ],
    [
        "81",
        "43",
        "33.796",
        "6.017",
        "1.164",
        "1.626",
        "-0.226"
    ],
    [
        "82",
        "43",
        "34.202",
        "6.946",
        "1.107",
        "1.659",
        "-0.242"
    ],
    [
        "83",
        "43",
        "34.376",
        "7.708",
        "1.081",
        "1.600",
        "-0.246"
    ],
    [
        "84",
        "43",
        "34.208",
        "8.537",
        "1.080",
        "1.435",
        "-0.212"
    ],
    [
        "85",
        "43",
        "34.420",
        "8.808",
        "0.985",
        "1.480",
        "-0.144"
    ],
    [
        "86",
        "43",
        "34.673",
        "7.906",
        "0.984",
        "1.417",
        "-0.180"
    ],
    [
        "87",
        "43",
        "34.541",
        "7.112",
        "1.048",
        "1.334",
        "-0.171"
    ],
    [
        "88",
        "43",
        "34.440",
        "6.737",
        "0.988",
        "1.337",
        "-0.069"
    ],
    [
        "89",
        "43",
        "34.389",
        "6.182",
        "1.032",
        "1.332",
        "-0.022"
    ],
    [
        "90",
        "43",
        "34.524",
        "5.200",
        "1.141",
        "1.313",
        "-0.030"
    ],
    [
        "91",
        "43",
        "34.670",
        "3.891",
        "1.105",
        "1.322",
        "-0.034"
    ],
    [
        "92",
        "43",
        "34.754",
        "2.534",
        "1.120",
        "1.308",
        "-0.029"
    ],
    [
        "93",
        "43",
        "34.805",
        "0.953",
        "1.181",
        "1.263",
        "-0.007"
    ],
    [
        "94",
        "43",
        "34.729",
        "-0.671",
        "1.115",
        "1.261",
        "-0.010"
    ],
    [
        "95",
        "43",
        "34.556",
        "-2.009",
        "1.134",
        "1.275",
        "0.006"
    ],
    [
        "96",
        "43",
        "34.348",
        "-3.582",
        "1.160",
        "1.211",
        "0.016"
    ],
    [
        "97",
        "43",
        "34.040",
        "-4.813",
        "1.011",
        "1.079",
        "0.020"
    ],
    [
        "98",
        "43",
        "33.751",
        "-5.724",
        "0.967",
        "1.026",
        "0.004"
    ],
    [
        "99",
        "43",
        "33.421",
        "-6.437",
        "0.984",
        "0.981",
        "-0.011"
    ],
    [
        "100",
        "43",
        "33.028",
        "-7.010",
        "0.956",
        "1.009",
        "-0.012"
    ],
    [
        "101",
        "43",
        "32.538",
        "-7.415",
        "0.918",
        "0.921",
        "-0.050"
    ],
    [
        "102",
        "43",
        "32.068",
        "-7.993",
        "0.955",
        "0.927",
        "-0.050"
    ],
    [
        "103",
        "43",
        "31.559",
        "-8.359",
        "0.851",
        "0.932",
        "-0.057"
    ],
    [
        "104",
        "43",
        "31.114",
        "-8.486",
        "0.749",
        "0.798",
        "-0.050"
    ],
    [
        "105",
        "43",
        "30.791",
        "-8.781",
        "0.722",
        "0.713",
        "-0.001"
    ],
    [
        "106",
        "43",
        "30.487",
        "-9.222",
        "0.591",
        "0.682",
        "0.013"
    ],
    [
        "107",
        "43",
        "30.216",
        "-9.608",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "43",
        "30.100",
        "-9.854",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "43",
        "29.982",
        "-10.096",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "43",
        "29.860",
        "-10.335",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "43",
        "29.736",
        "-10.571",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "43",
        "29.608",
        "-10.804",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "43",
        "29.478",
        "-11.034",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "43",
        "29.346",
        "-11.259",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "43",
        "29.210",
        "-11.482",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "43",
        "29.073",
        "-11.702",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "43",
        "28.931",
        "-11.917",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "43",
        "28.789",
        "-12.129",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "43",
        "28.643",
        "-12.338",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "43",
        "28.495",
        "-12.542",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "43",
        "28.344",
        "-12.742",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "43",
        "28.191",
        "-12.940",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "43",
        "28.037",
        "-13.133",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "43",
        "27.879",
        "-13.321",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "43",
        "27.719",
        "-13.506",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "43",
        "27.557",
        "-13.687",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "43",
        "27.393",
        "-13.864",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "43",
        "27.227",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "43",
        "27.059",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "43",
        "26.889",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "43",
        "26.717",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "43",
        "26.544",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "43",
        "26.367",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "43",
        "26.191",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "43",
        "26.011",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "43",
        "25.831",
        "-15.257",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "43",
        "25.648",
        "-15.389",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "43",
        "25.464",
        "-15.517",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "43",
        "21.915",
        "-15.051",
        "2.195",
        "1.455",
        "-0.085"
    ],
    [
        "140",
        "43",
        "14.342",
        "-14.516",
        "2.215",
        "1.909",
        "-0.130"
    ],
    [
        "141",
        "43",
        "12.539",
        "-15.546",
        "1.865",
        "1.833",
        "-0.113"
    ],
    [
        "142",
        "43",
        "4.536",
        "-12.723",
        "2.073",
        "2.333",
        "-0.135"
    ],
    [
        "143",
        "43",
        "0.325",
        "-8.763",
        "2.350",
        "2.433",
        "-0.087"
    ],
    [
        "144",
        "43",
        "-3.816",
        "-6.086",
        "2.205",
        "2.431",
        "-0.145"
    ],
    [
        "145",
        "43",
        "-11.859",
        "-3.274",
        "2.295",
        "2.527",
        "-0.252"
    ],
    [
        "146",
        "43",
        "-19.028",
        "-1.234",
        "6.281",
        "4.473",
        "-0.244"
    ],
    [
        "147",
        "43",
        "-29.750",
        "5.247",
        "8.383",
        "5.827",
        "-0.443"
    ],
    [
        "148",
        "43",
        "-41.571",
        "10.428",
        "8.005",
        "5.495",
        "-0.374"
    ],
    [
        "149",
        "43",
        "-52.955",
        "14.394",
        "7.129",
        "5.273",
        "-0.435"
    ],
    [
        "150",
        "43",
        "-63.321",
        "20.801",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "43",
        "-63.071",
        "21.196",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "43",
        "-62.816",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "43",
        "-62.557",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "43",
        "-62.293",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "43",
        "-62.025",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "43",
        "-61.753",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "43",
        "-61.476",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "43",
        "-61.195",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "43",
        "-60.910",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "43",
        "-60.621",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "43",
        "-60.328",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "43",
        "-60.032",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "43",
        "-59.731",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "43",
        "-59.427",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "43",
        "-59.120",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "43",
        "-58.809",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "43",
        "-58.494",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "43",
        "-58.177",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "43",
        "-57.856",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "43",
        "-57.532",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "43",
        "-57.205",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "43",
        "-56.875",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "43",
        "-56.543",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "43",
        "-56.208",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "43",
        "-55.870",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "43",
        "-55.530",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "43",
        "-55.187",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "43",
        "-54.843",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "43",
        "-54.496",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "44",
        "-53.480",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "44",
        "-53.122",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "44",
        "-52.763",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "44",
        "-52.402",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "44",
        "-52.039",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "44",
        "-51.675",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "44",
        "-51.309",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "44",
        "-50.942",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "44",
        "-50.574",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "44",
        "-50.205",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "44",
        "-49.835",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "44",
        "-49.464",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "44",
        "-49.092",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "44",
        "-48.719",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "44",
        "-48.346",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "44",
        "-47.973",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "44",
        "-47.599",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "44",
        "-47.225",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "44",
        "-46.851",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "44",
        "-46.478",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "44",
        "-46.104",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "44",
        "-45.730",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "44",
        "-45.357",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "44",
        "-44.985",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "44",
        "-44.613",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "44",
        "-44.242",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "44",
        "-43.871",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "44",
        "-43.502",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "44",
        "-43.133",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "44",
        "-42.766",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "44",
        "-42.400",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "44",
        "-42.036",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "44",
        "-41.673",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "44",
        "-41.311",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "44",
        "-40.952",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "44",
        "-40.594",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "44",
        "-40.238",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "44",
        "-39.885",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "44",
        "-39.533",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "44",
        "-39.184",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "44",
        "-38.837",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "44",
        "-38.492",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "44",
        "-38.150",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "44",
        "-37.811",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "44",
        "-37.475",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "44",
        "-37.141",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "44",
        "-36.811",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "44",
        "-36.484",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "44",
        "-36.159",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "44",
        "-35.839",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "44",
        "-35.509",
        "25.955",
        "5.448",
        "5.707",
        "-0.003"
    ],
    [
        "-129",
        "44",
        "-24.495",
        "21.579",
        "6.601",
        "5.223",
        "-0.121"
    ],
    [
        "-128",
        "44",
        "9.664",
        "3.166",
        "1.544",
        "2.400",
        "0.057"
    ],
    [
        "-127",
        "44",
        "11.650",
        "2.251",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "44",
        "11.670",
        "0.894",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "44",
        "6.268",
        "-0.863",
        "1.166",
        "1.254",
        "-0.027"
    ],
    [
        "-124",
        "44",
        "-6.615",
        "-6.061",
        "0.845",
        "0.864",
        "0.023"
    ],
    [
        "-123",
        "44",
        "-8.744",
        "-8.716",
        "0.890",
        "0.813",
        "0.068"
    ],
    [
        "-122",
        "44",
        "-10.202",
        "-10.258",
        "0.979",
        "1.136",
        "0.089"
    ],
    [
        "-121",
        "44",
        "-11.819",
        "-10.560",
        "0.839",
        "0.983",
        "0.183"
    ],
    [
        "-120",
        "44",
        "-11.990",
        "-10.849",
        "1.761",
        "1.736",
        "0.036"
    ],
    [
        "-119",
        "44",
        "-12.552",
        "-11.429",
        "1.935",
        "2.134",
        "0.025"
    ],
    [
        "-118",
        "44",
        "-13.146",
        "-11.840",
        "1.993",
        "2.203",
        "0.026"
    ],
    [
        "-117",
        "44",
        "-13.714",
        "-12.128",
        "2.001",
        "2.087",
        "0.060"
    ],
    [
        "-116",
        "44",
        "-14.171",
        "-12.234",
        "1.665",
        "1.952",
        "0.133"
    ],
    [
        "-115",
        "44",
        "-14.500",
        "-12.214",
        "1.447",
        "1.573",
        "0.191"
    ],
    [
        "-114",
        "44",
        "-14.594",
        "-12.018",
        "1.495",
        "1.334",
        "0.277"
    ],
    [
        "-113",
        "44",
        "-14.579",
        "-11.768",
        "1.489",
        "1.346",
        "0.233"
    ],
    [
        "-112",
        "44",
        "-14.621",
        "-11.550",
        "1.485",
        "1.385",
        "0.168"
    ],
    [
        "-111",
        "44",
        "-15.218",
        "-11.465",
        "1.424",
        "1.156",
        "0.105"
    ],
    [
        "-110",
        "44",
        "-14.105",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "44",
        "-14.235",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "44",
        "-14.359",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "44",
        "-14.480",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "44",
        "-14.598",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "44",
        "-14.710",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "44",
        "-14.818",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "44",
        "-14.921",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "44",
        "-15.020",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "44",
        "-15.115",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "44",
        "-15.204",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "44",
        "-15.289",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "44",
        "-15.371",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "44",
        "-15.446",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "44",
        "-15.518",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "44",
        "-15.585",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "44",
        "-15.646",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "44",
        "-15.704",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "44",
        "-15.756",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "44",
        "-15.804",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "44",
        "-15.848",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "44",
        "-15.886",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "44",
        "-15.919",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "44",
        "-15.948",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "44",
        "-15.972",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "44",
        "-15.991",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "44",
        "-16.005",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "44",
        "-16.015",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "44",
        "-16.019",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "44",
        "-16.019",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "44",
        "-16.014",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "44",
        "-16.005",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "44",
        "-15.990",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "44",
        "-15.970",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "44",
        "-15.946",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "44",
        "-15.917",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "44",
        "-15.882",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "44",
        "-15.844",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "44",
        "-15.800",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "44",
        "-15.752",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "44",
        "-15.699",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "44",
        "-15.642",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "44",
        "-15.579",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "44",
        "-15.512",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "44",
        "-15.440",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "44",
        "-15.364",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "44",
        "-15.282",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "44",
        "-15.197",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "44",
        "-15.106",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "44",
        "-15.012",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "44",
        "-14.912",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "44",
        "-14.809",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "44",
        "-14.701",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "44",
        "-14.588",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "44",
        "-14.471",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "44",
        "-14.349",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "44",
        "-14.223",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "44",
        "-14.094",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "44",
        "-13.960",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "44",
        "-13.821",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "44",
        "-13.678",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "44",
        "-13.531",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "44",
        "-13.381",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "44",
        "-13.225",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "44",
        "-13.067",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "44",
        "-12.904",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "44",
        "-12.738",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "44",
        "-12.567",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "44",
        "-12.393",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "44",
        "-12.215",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "44",
        "-12.033",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "44",
        "-11.848",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "44",
        "-11.660",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "44",
        "-11.467",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "44",
        "-11.271",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "44",
        "-11.073",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "44",
        "-10.869",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "44",
        "-10.664",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "44",
        "-10.455",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "44",
        "-10.243",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "44",
        "-10.028",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "44",
        "-6.940",
        "17.868",
        "3.151",
        "2.323",
        "-0.360"
    ],
    [
        "-28",
        "44",
        "13.934",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "44",
        "14.137",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "44",
        "14.341",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "44",
        "14.545",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "44",
        "14.750",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "44",
        "14.956",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "44",
        "15.163",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "44",
        "15.371",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "44",
        "15.580",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "44",
        "15.789",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "44",
        "15.998",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "44",
        "16.208",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "44",
        "16.418",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "44",
        "16.630",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "44",
        "16.841",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "44",
        "17.052",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "44",
        "17.264",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "44",
        "17.476",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "44",
        "17.687",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "44",
        "17.899",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "44",
        "18.110",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "44",
        "18.322",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "44",
        "18.533",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "44",
        "18.743",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "44",
        "18.954",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "44",
        "19.164",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "44",
        "19.374",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "44",
        "19.583",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "44",
        "19.792",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "44",
        "19.999",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "44",
        "20.206",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "44",
        "20.412",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "44",
        "20.617",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "44",
        "20.822",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "44",
        "21.025",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "44",
        "21.328",
        "16.625",
        "0.431",
        "0.484",
        "0.090"
    ],
    [
        "8",
        "44",
        "21.427",
        "16.543",
        "0.449",
        "0.514",
        "0.099"
    ],
    [
        "9",
        "44",
        "21.629",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "44",
        "21.829",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "44",
        "22.158",
        "16.602",
        "0.666",
        "0.757",
        "0.060"
    ],
    [
        "12",
        "44",
        "22.342",
        "16.792",
        "0.830",
        "0.819",
        "0.094"
    ],
    [
        "13",
        "44",
        "22.385",
        "16.483",
        "0.836",
        "0.920",
        "0.096"
    ],
    [
        "14",
        "44",
        "22.511",
        "16.492",
        "0.843",
        "0.950",
        "0.092"
    ],
    [
        "15",
        "44",
        "22.596",
        "16.724",
        "0.836",
        "0.883",
        "0.059"
    ],
    [
        "16",
        "44",
        "22.838",
        "16.839",
        "0.777",
        "0.906",
        "0.052"
    ],
    [
        "17",
        "44",
        "23.086",
        "16.407",
        "0.740",
        "0.860",
        "0.041"
    ],
    [
        "18",
        "44",
        "23.364",
        "15.892",
        "0.712",
        "0.760",
        "0.051"
    ],
    [
        "19",
        "44",
        "23.565",
        "15.243",
        "0.162",
        "0.236",
        "0.035"
    ],
    [
        "20",
        "44",
        "23.737",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "44",
        "23.959",
        "14.802",
        "0.593",
        "0.614",
        "0.003"
    ],
    [
        "22",
        "44",
        "24.103",
        "14.708",
        "0.147",
        "0.203",
        "0.032"
    ],
    [
        "23",
        "44",
        "24.275",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "44",
        "24.451",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "44",
        "24.624",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "44",
        "24.795",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "44",
        "24.964",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "44",
        "25.132",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "44",
        "25.295",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "44",
        "25.457",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "44",
        "25.618",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "44",
        "25.775",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "44",
        "25.930",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "44",
        "26.082",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "44",
        "26.231",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "44",
        "26.378",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "44",
        "26.523",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "44",
        "26.665",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "44",
        "26.804",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "44",
        "26.940",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "44",
        "27.073",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "44",
        "27.204",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "44",
        "27.330",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "44",
        "27.455",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "44",
        "27.577",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "44",
        "27.695",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "44",
        "27.811",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "44",
        "27.923",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "44",
        "28.032",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "44",
        "28.137",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "44",
        "28.240",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "44",
        "28.339",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "44",
        "28.435",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "44",
        "28.529",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "44",
        "28.618",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "44",
        "28.704",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "44",
        "28.787",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "44",
        "28.866",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "44",
        "28.942",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "44",
        "29.015",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "44",
        "29.083",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "44",
        "29.149",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "44",
        "29.211",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "44",
        "29.269",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "44",
        "29.324",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "44",
        "29.375",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "44",
        "29.423",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "44",
        "29.467",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "44",
        "29.507",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "44",
        "29.544",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "44",
        "29.577",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "44",
        "29.607",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "44",
        "29.707",
        "1.928",
        "0.591",
        "0.666",
        "-0.025"
    ],
    [
        "74",
        "44",
        "29.870",
        "1.332",
        "0.718",
        "0.916",
        "-0.039"
    ],
    [
        "75",
        "44",
        "30.029",
        "1.697",
        "0.777",
        "0.880",
        "-0.067"
    ],
    [
        "76",
        "44",
        "30.341",
        "1.460",
        "0.719",
        "0.908",
        "-0.102"
    ],
    [
        "77",
        "44",
        "30.668",
        "0.786",
        "0.686",
        "0.824",
        "-0.040"
    ],
    [
        "78",
        "44",
        "31.048",
        "0.923",
        "0.856",
        "0.948",
        "-0.115"
    ],
    [
        "79",
        "44",
        "31.192",
        "1.760",
        "0.958",
        "1.209",
        "-0.149"
    ],
    [
        "80",
        "44",
        "31.744",
        "2.710",
        "1.007",
        "1.285",
        "-0.133"
    ],
    [
        "81",
        "44",
        "32.081",
        "4.098",
        "1.010",
        "1.132",
        "-0.071"
    ],
    [
        "82",
        "44",
        "33.201",
        "3.757",
        "1.080",
        "1.481",
        "-0.112"
    ],
    [
        "83",
        "44",
        "33.997",
        "3.746",
        "1.100",
        "1.538",
        "-0.133"
    ],
    [
        "84",
        "44",
        "34.551",
        "3.810",
        "1.157",
        "1.470",
        "-0.168"
    ],
    [
        "85",
        "44",
        "34.582",
        "4.175",
        "1.050",
        "1.402",
        "-0.144"
    ],
    [
        "86",
        "44",
        "34.403",
        "3.933",
        "1.048",
        "1.382",
        "-0.051"
    ],
    [
        "87",
        "44",
        "34.090",
        "4.274",
        "1.033",
        "1.171",
        "0.038"
    ],
    [
        "88",
        "44",
        "33.911",
        "4.184",
        "0.896",
        "0.997",
        "-0.178"
    ],
    [
        "89",
        "44",
        "34.602",
        "3.034",
        "1.084",
        "1.352",
        "-0.076"
    ],
    [
        "90",
        "44",
        "34.920",
        "2.522",
        "1.195",
        "1.380",
        "-0.045"
    ],
    [
        "91",
        "44",
        "35.054",
        "1.776",
        "1.156",
        "1.385",
        "-0.021"
    ],
    [
        "92",
        "44",
        "35.183",
        "0.833",
        "1.169",
        "1.370",
        "-0.002"
    ],
    [
        "93",
        "44",
        "35.346",
        "-0.240",
        "1.243",
        "1.341",
        "0.009"
    ],
    [
        "94",
        "44",
        "35.451",
        "-1.536",
        "1.188",
        "1.349",
        "0.011"
    ],
    [
        "95",
        "44",
        "35.418",
        "-2.966",
        "1.184",
        "1.314",
        "0.006"
    ],
    [
        "96",
        "44",
        "35.119",
        "-4.107",
        "1.228",
        "1.243",
        "0.012"
    ],
    [
        "97",
        "44",
        "34.622",
        "-4.967",
        "1.151",
        "1.208",
        "0.014"
    ],
    [
        "98",
        "44",
        "34.095",
        "-5.717",
        "1.132",
        "1.173",
        "0.000"
    ],
    [
        "99",
        "44",
        "33.564",
        "-6.378",
        "1.169",
        "1.128",
        "-0.009"
    ],
    [
        "100",
        "44",
        "32.996",
        "-6.935",
        "1.072",
        "1.130",
        "-0.025"
    ],
    [
        "101",
        "44",
        "32.345",
        "-7.550",
        "1.022",
        "1.087",
        "-0.024"
    ],
    [
        "102",
        "44",
        "31.632",
        "-8.266",
        "1.029",
        "1.011",
        "-0.053"
    ],
    [
        "103",
        "44",
        "30.934",
        "-8.615",
        "0.798",
        "0.916",
        "-0.022"
    ],
    [
        "104",
        "44",
        "30.393",
        "-8.855",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "44",
        "30.284",
        "-9.109",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "44",
        "30.172",
        "-9.360",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "44",
        "30.057",
        "-9.608",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "44",
        "29.939",
        "-9.854",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "44",
        "29.819",
        "-10.096",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "44",
        "29.694",
        "-10.335",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "44",
        "29.568",
        "-10.571",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "44",
        "29.437",
        "-10.804",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "44",
        "29.305",
        "-11.034",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "44",
        "29.170",
        "-11.259",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "44",
        "29.033",
        "-11.482",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "44",
        "28.892",
        "-11.702",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "44",
        "28.748",
        "-11.917",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "44",
        "28.603",
        "-12.129",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "44",
        "28.454",
        "-12.338",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "44",
        "28.304",
        "-12.542",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "44",
        "28.150",
        "-12.742",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "44",
        "27.995",
        "-12.940",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "44",
        "27.837",
        "-13.133",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "44",
        "27.677",
        "-13.321",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "44",
        "27.513",
        "-13.506",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "44",
        "27.349",
        "-13.687",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "44",
        "27.182",
        "-13.864",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "44",
        "27.013",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "44",
        "26.841",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "44",
        "26.668",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "44",
        "26.494",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "44",
        "26.316",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "44",
        "26.138",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "44",
        "25.957",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "44",
        "25.774",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "44",
        "25.590",
        "-15.257",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "44",
        "25.404",
        "-15.389",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "44",
        "25.216",
        "-15.517",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "44",
        "24.109",
        "-15.249",
        "1.661",
        "1.286",
        "-0.218"
    ],
    [
        "140",
        "44",
        "21.216",
        "-15.183",
        "2.550",
        "2.075",
        "-0.098"
    ],
    [
        "141",
        "44",
        "19.728",
        "-15.912",
        "3.016",
        "2.349",
        "-0.232"
    ],
    [
        "142",
        "44",
        "15.372",
        "-14.320",
        "1.859",
        "2.053",
        "-0.137"
    ],
    [
        "143",
        "44",
        "10.598",
        "-13.182",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "44",
        "10.435",
        "-13.742",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "44",
        "10.265",
        "-14.299",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "44",
        "-0.005",
        "-9.531",
        "4.568",
        "3.727",
        "-0.378"
    ],
    [
        "147",
        "44",
        "-10.353",
        "-4.101",
        "6.432",
        "5.199",
        "-0.543"
    ],
    [
        "148",
        "44",
        "-20.110",
        "0.465",
        "7.235",
        "5.449",
        "-0.486"
    ],
    [
        "149",
        "44",
        "-30.743",
        "4.878",
        "7.794",
        "5.698",
        "-0.488"
    ],
    [
        "150",
        "44",
        "-42.186",
        "10.319",
        "8.239",
        "5.874",
        "-0.563"
    ],
    [
        "151",
        "44",
        "-56.090",
        "17.080",
        "5.807",
        "4.697",
        "-0.403"
    ],
    [
        "152",
        "44",
        "-62.310",
        "21.583",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "44",
        "-62.046",
        "21.965",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "44",
        "-61.777",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "44",
        "-61.504",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "44",
        "-61.227",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "44",
        "-60.945",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "44",
        "-60.659",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "44",
        "-60.369",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "44",
        "-60.074",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "44",
        "-59.776",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "44",
        "-59.474",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "44",
        "-59.168",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "44",
        "-58.858",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "44",
        "-58.545",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "44",
        "-58.228",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "44",
        "-57.908",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "44",
        "-57.584",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "44",
        "-57.258",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "44",
        "-56.928",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "44",
        "-56.595",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "44",
        "-56.259",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "44",
        "-55.920",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "44",
        "-55.579",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "44",
        "-55.235",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "44",
        "-54.889",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "44",
        "-54.540",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "44",
        "-54.188",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "44",
        "-53.835",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "45",
        "-52.796",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "45",
        "-52.432",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "45",
        "-52.067",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "45",
        "-51.699",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "45",
        "-51.330",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "45",
        "-50.959",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "45",
        "-50.587",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "45",
        "-50.214",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "45",
        "-49.839",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "45",
        "-49.463",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "45",
        "-49.086",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "45",
        "-48.708",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "45",
        "-48.330",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "45",
        "-47.951",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "45",
        "-47.571",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "45",
        "-47.191",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "45",
        "-46.811",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "45",
        "-46.430",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "45",
        "-46.049",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "45",
        "-45.669",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "45",
        "-45.288",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "45",
        "-44.908",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "45",
        "-44.528",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "45",
        "-44.149",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "45",
        "-43.771",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "45",
        "-43.393",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "45",
        "-43.016",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "45",
        "-42.640",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "45",
        "-42.265",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "45",
        "-41.891",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "45",
        "-41.518",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "45",
        "-41.147",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "45",
        "-40.778",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "45",
        "-40.410",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "45",
        "-40.044",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "45",
        "-39.680",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "45",
        "-39.318",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "45",
        "-38.958",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "45",
        "-38.600",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "45",
        "-38.244",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "45",
        "-37.891",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "45",
        "-37.540",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "45",
        "-37.192",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "45",
        "-36.847",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "45",
        "-36.505",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "45",
        "-36.165",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "45",
        "-35.829",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "45",
        "-35.496",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "45",
        "-35.166",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "45",
        "-34.839",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "45",
        "-2.985",
        "12.895",
        "4.297",
        "4.999",
        "0.113"
    ],
    [
        "-129",
        "45",
        "13.250",
        "4.966",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "45",
        "13.303",
        "3.609",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "45",
        "13.340",
        "2.251",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "45",
        "13.359",
        "0.894",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "45",
        "9.606",
        "-0.435",
        "0.917",
        "1.122",
        "0.041"
    ],
    [
        "-124",
        "45",
        "-2.746",
        "-5.404",
        "0.652",
        "0.659",
        "-0.038"
    ],
    [
        "-123",
        "45",
        "-7.202",
        "-7.893",
        "0.690",
        "0.681",
        "-0.027"
    ],
    [
        "-122",
        "45",
        "-9.077",
        "-9.982",
        "1.130",
        "1.304",
        "0.018"
    ],
    [
        "-121",
        "45",
        "-10.093",
        "-11.310",
        "1.407",
        "1.520",
        "-0.014"
    ],
    [
        "-120",
        "45",
        "-10.833",
        "-11.361",
        "1.882",
        "1.905",
        "0.019"
    ],
    [
        "-119",
        "45",
        "-11.620",
        "-11.678",
        "2.017",
        "2.279",
        "0.019"
    ],
    [
        "-118",
        "45",
        "-12.333",
        "-11.935",
        "2.100",
        "2.374",
        "0.031"
    ],
    [
        "-117",
        "45",
        "-12.969",
        "-12.099",
        "2.151",
        "2.242",
        "0.072"
    ],
    [
        "-116",
        "45",
        "-13.455",
        "-12.155",
        "1.891",
        "2.089",
        "0.134"
    ],
    [
        "-115",
        "45",
        "-13.769",
        "-12.102",
        "1.785",
        "1.798",
        "0.187"
    ],
    [
        "-114",
        "45",
        "-13.959",
        "-11.943",
        "1.778",
        "1.623",
        "0.232"
    ],
    [
        "-113",
        "45",
        "-14.080",
        "-11.743",
        "1.660",
        "1.633",
        "0.185"
    ],
    [
        "-112",
        "45",
        "-14.022",
        "-11.484",
        "1.506",
        "1.542",
        "0.151"
    ],
    [
        "-111",
        "45",
        "-12.999",
        "-11.086",
        "1.147",
        "1.143",
        "0.117"
    ],
    [
        "-110",
        "45",
        "-14.201",
        "-10.678",
        "0.299",
        "0.477",
        "-0.073"
    ],
    [
        "-109",
        "45",
        "-14.483",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "45",
        "-14.611",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "45",
        "-14.734",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "45",
        "-14.854",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "45",
        "-14.968",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "45",
        "-15.078",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "45",
        "-15.183",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "45",
        "-15.283",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "45",
        "-15.379",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "45",
        "-15.471",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "45",
        "-15.558",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "45",
        "-15.640",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "45",
        "-15.718",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "45",
        "-15.790",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "45",
        "-15.858",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "45",
        "-15.921",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "45",
        "-15.979",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "45",
        "-16.033",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "45",
        "-16.082",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "45",
        "-16.125",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "45",
        "-16.165",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "45",
        "-16.198",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "45",
        "-16.228",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "45",
        "-16.252",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "45",
        "-16.271",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "45",
        "-16.286",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "45",
        "-16.295",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "45",
        "-16.300",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "45",
        "-16.300",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "45",
        "-16.295",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "45",
        "-16.285",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "45",
        "-16.270",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "45",
        "-16.251",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "45",
        "-16.225",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "45",
        "-16.196",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "45",
        "-16.161",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "45",
        "-16.122",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "45",
        "-16.077",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "45",
        "-16.028",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "45",
        "-15.975",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "45",
        "-15.916",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "45",
        "-15.852",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "45",
        "-15.784",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "45",
        "-15.711",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "45",
        "-15.633",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "45",
        "-15.551",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "45",
        "-15.463",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "45",
        "-15.372",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "45",
        "-15.275",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "45",
        "-15.174",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "45",
        "-15.068",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "45",
        "-14.958",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "45",
        "-14.844",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "45",
        "-14.724",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "45",
        "-14.601",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "45",
        "-14.473",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "45",
        "-14.340",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "45",
        "-14.204",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "45",
        "-14.062",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "45",
        "-13.918",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "45",
        "-13.768",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "45",
        "-13.615",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "45",
        "-13.457",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "45",
        "-13.296",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "45",
        "-13.130",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "45",
        "-12.960",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "45",
        "-12.786",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "45",
        "-12.609",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "45",
        "-12.428",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "45",
        "-12.244",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "45",
        "-12.054",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "45",
        "-11.863",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "45",
        "-11.667",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "45",
        "-11.468",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "45",
        "-11.264",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "45",
        "-11.058",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "45",
        "-10.849",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "45",
        "-10.637",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "45",
        "-10.421",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "45",
        "-10.202",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "45",
        "-9.980",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "45",
        "3.711",
        "18.272",
        "3.030",
        "2.142",
        "-0.347"
    ],
    [
        "-27",
        "45",
        "13.776",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "45",
        "13.984",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "45",
        "14.192",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "45",
        "14.400",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "45",
        "14.610",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "45",
        "14.821",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "45",
        "15.033",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "45",
        "15.245",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "45",
        "15.458",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "45",
        "15.671",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "45",
        "15.884",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "45",
        "16.099",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "45",
        "16.313",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "45",
        "16.529",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "45",
        "16.744",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "45",
        "16.960",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "45",
        "17.175",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "45",
        "17.390",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "45",
        "17.605",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "45",
        "17.820",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "45",
        "18.036",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "45",
        "18.251",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "45",
        "18.465",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "45",
        "18.679",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "45",
        "18.893",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "45",
        "19.107",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "45",
        "19.320",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "45",
        "19.532",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "45",
        "19.743",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "45",
        "19.955",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "45",
        "20.164",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "45",
        "20.373",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "45",
        "20.581",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "45",
        "20.788",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "45",
        "21.002",
        "16.651",
        "0.442",
        "0.500",
        "0.114"
    ],
    [
        "8",
        "45",
        "21.230",
        "16.536",
        "0.559",
        "0.672",
        "0.058"
    ],
    [
        "9",
        "45",
        "21.431",
        "16.457",
        "0.671",
        "0.675",
        "0.031"
    ],
    [
        "10",
        "45",
        "21.697",
        "16.413",
        "0.647",
        "0.715",
        "-0.011"
    ],
    [
        "11",
        "45",
        "22.078",
        "16.684",
        "0.676",
        "0.781",
        "-0.054"
    ],
    [
        "12",
        "45",
        "22.430",
        "17.106",
        "0.711",
        "0.723",
        "-0.017"
    ],
    [
        "13",
        "45",
        "22.307",
        "16.369",
        "0.709",
        "0.852",
        "0.045"
    ],
    [
        "14",
        "45",
        "22.440",
        "16.142",
        "0.702",
        "0.840",
        "0.034"
    ],
    [
        "15",
        "45",
        "22.667",
        "16.242",
        "0.735",
        "0.791",
        "0.015"
    ],
    [
        "16",
        "45",
        "22.761",
        "16.109",
        "0.684",
        "0.832",
        "-0.017"
    ],
    [
        "17",
        "45",
        "22.999",
        "15.775",
        "0.610",
        "0.750",
        "0.041"
    ],
    [
        "18",
        "45",
        "23.173",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "45",
        "23.362",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "45",
        "23.548",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "45",
        "23.734",
        "14.877",
        "0.559",
        "0.566",
        "-0.006"
    ],
    [
        "22",
        "45",
        "23.914",
        "14.720",
        "0.507",
        "0.610",
        "-0.022"
    ],
    [
        "23",
        "45",
        "24.096",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "45",
        "24.274",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "45",
        "24.452",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "45",
        "24.626",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "45",
        "24.797",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "45",
        "24.968",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "45",
        "25.135",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "45",
        "25.300",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "45",
        "25.462",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "45",
        "25.623",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "45",
        "25.780",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "45",
        "25.935",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "45",
        "26.088",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "45",
        "26.238",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "45",
        "26.384",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "45",
        "26.529",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "45",
        "26.670",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "45",
        "26.809",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "45",
        "26.944",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "45",
        "27.076",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "45",
        "27.206",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "45",
        "27.333",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "45",
        "27.456",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "45",
        "27.577",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "45",
        "27.695",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "45",
        "27.809",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "45",
        "27.920",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "45",
        "28.028",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "45",
        "28.132",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "45",
        "28.233",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "45",
        "28.331",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "45",
        "28.425",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "45",
        "28.517",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "45",
        "28.604",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "45",
        "28.688",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "45",
        "28.769",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "45",
        "28.846",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "45",
        "28.920",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "45",
        "28.990",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "45",
        "29.057",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "45",
        "29.120",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "45",
        "29.180",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "45",
        "29.235",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "45",
        "29.288",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "45",
        "29.335",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "45",
        "29.380",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "45",
        "29.422",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "45",
        "29.459",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "45",
        "29.493",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "45",
        "29.523",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "45",
        "29.550",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "45",
        "29.571",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "45",
        "29.591",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "45",
        "29.605",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "45",
        "29.732",
        "0.758",
        "0.641",
        "0.853",
        "-0.086"
    ],
    [
        "78",
        "45",
        "29.853",
        "0.579",
        "0.837",
        "0.918",
        "-0.050"
    ],
    [
        "79",
        "45",
        "29.933",
        "0.609",
        "0.770",
        "0.989",
        "-0.055"
    ],
    [
        "80",
        "45",
        "30.336",
        "1.249",
        "0.836",
        "1.071",
        "-0.009"
    ],
    [
        "81",
        "45",
        "31.235",
        "2.034",
        "1.048",
        "1.155",
        "-0.007"
    ],
    [
        "82",
        "45",
        "32.283",
        "2.394",
        "1.049",
        "1.274",
        "-0.045"
    ],
    [
        "83",
        "45",
        "33.224",
        "2.382",
        "1.082",
        "1.332",
        "-0.040"
    ],
    [
        "84",
        "45",
        "33.625",
        "2.544",
        "1.147",
        "1.325",
        "-0.031"
    ],
    [
        "85",
        "45",
        "33.879",
        "2.711",
        "1.076",
        "1.326",
        "-0.045"
    ],
    [
        "86",
        "45",
        "33.988",
        "2.665",
        "1.077",
        "1.307",
        "-0.039"
    ],
    [
        "87",
        "45",
        "34.072",
        "2.112",
        "1.152",
        "1.313",
        "-0.031"
    ],
    [
        "88",
        "45",
        "34.270",
        "1.537",
        "1.107",
        "1.353",
        "-0.058"
    ],
    [
        "89",
        "45",
        "34.443",
        "1.206",
        "1.127",
        "1.360",
        "-0.074"
    ],
    [
        "90",
        "45",
        "34.494",
        "1.054",
        "1.218",
        "1.348",
        "-0.046"
    ],
    [
        "91",
        "45",
        "34.635",
        "0.817",
        "1.190",
        "1.373",
        "-0.013"
    ],
    [
        "92",
        "45",
        "35.029",
        "0.241",
        "1.217",
        "1.383",
        "0.010"
    ],
    [
        "93",
        "45",
        "35.412",
        "-0.598",
        "1.307",
        "1.365",
        "0.020"
    ],
    [
        "94",
        "45",
        "35.192",
        "-2.005",
        "1.249",
        "1.377",
        "0.021"
    ],
    [
        "95",
        "45",
        "34.453",
        "-3.678",
        "1.235",
        "1.333",
        "0.007"
    ],
    [
        "96",
        "45",
        "33.658",
        "-4.452",
        "1.267",
        "1.257",
        "0.004"
    ],
    [
        "97",
        "45",
        "32.983",
        "-4.916",
        "1.181",
        "1.243",
        "0.007"
    ],
    [
        "98",
        "45",
        "32.399",
        "-5.471",
        "1.159",
        "1.220",
        "0.007"
    ],
    [
        "99",
        "45",
        "31.873",
        "-6.098",
        "1.190",
        "1.174",
        "0.004"
    ],
    [
        "100",
        "45",
        "31.377",
        "-6.804",
        "1.084",
        "1.156",
        "-0.001"
    ],
    [
        "101",
        "45",
        "30.924",
        "-7.570",
        "1.018",
        "1.093",
        "-0.024"
    ],
    [
        "102",
        "45",
        "30.581",
        "-8.225",
        "0.986",
        "0.965",
        "-0.009"
    ],
    [
        "103",
        "45",
        "30.345",
        "-8.593",
        "0.215",
        "0.272",
        "0.005"
    ],
    [
        "104",
        "45",
        "30.230",
        "-8.855",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "45",
        "30.120",
        "-9.109",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "45",
        "30.006",
        "-9.360",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "45",
        "29.889",
        "-9.608",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "45",
        "29.769",
        "-9.854",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "45",
        "29.646",
        "-10.096",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "45",
        "29.520",
        "-10.335",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "45",
        "29.390",
        "-10.571",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "45",
        "29.259",
        "-10.804",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "45",
        "29.124",
        "-11.034",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "45",
        "28.987",
        "-11.259",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "45",
        "28.846",
        "-11.482",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "45",
        "28.703",
        "-11.702",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "45",
        "28.557",
        "-11.917",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "45",
        "28.408",
        "-12.129",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "45",
        "28.257",
        "-12.338",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "45",
        "28.104",
        "-12.542",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "45",
        "27.948",
        "-12.742",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "45",
        "27.789",
        "-12.940",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "45",
        "27.628",
        "-13.133",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "45",
        "27.466",
        "-13.321",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "45",
        "27.300",
        "-13.506",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "45",
        "27.133",
        "-13.687",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "45",
        "26.962",
        "-13.864",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "45",
        "26.791",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "45",
        "26.616",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "45",
        "26.439",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "45",
        "26.262",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "45",
        "26.081",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "45",
        "25.899",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "45",
        "25.714",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "45",
        "25.529",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "45",
        "25.342",
        "-15.257",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "45",
        "25.153",
        "-15.389",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "45",
        "24.962",
        "-15.517",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "45",
        "24.769",
        "-15.639",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "45",
        "23.688",
        "-15.379",
        "2.150",
        "2.014",
        "-0.054"
    ],
    [
        "141",
        "45",
        "23.965",
        "-15.639",
        "2.198",
        "1.929",
        "-0.136"
    ],
    [
        "142",
        "45",
        "22.030",
        "-16.532",
        "1.772",
        "1.807",
        "-0.175"
    ],
    [
        "143",
        "45",
        "11.199",
        "-13.182",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "45",
        "11.034",
        "-13.742",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "45",
        "10.860",
        "-14.299",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "45",
        "10.539",
        "-14.586",
        "0.865",
        "1.163",
        "-0.379"
    ],
    [
        "147",
        "45",
        "6.447",
        "-11.758",
        "4.562",
        "3.629",
        "-0.559"
    ],
    [
        "148",
        "45",
        "-1.100",
        "-8.571",
        "5.449",
        "4.183",
        "-0.531"
    ],
    [
        "149",
        "45",
        "-9.940",
        "-5.058",
        "6.288",
        "4.748",
        "-0.525"
    ],
    [
        "150",
        "45",
        "-20.030",
        "-0.656",
        "7.431",
        "5.503",
        "-0.587"
    ],
    [
        "151",
        "45",
        "-31.979",
        "5.342",
        "7.519",
        "5.539",
        "-0.524"
    ],
    [
        "152",
        "45",
        "-44.525",
        "11.935",
        "7.296",
        "5.336",
        "-0.492"
    ],
    [
        "153",
        "45",
        "-57.549",
        "18.456",
        "5.932",
        "4.782",
        "-0.488"
    ],
    [
        "154",
        "45",
        "-61.243",
        "22.339",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "45",
        "-60.965",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "45",
        "-60.682",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "45",
        "-60.396",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "45",
        "-60.104",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "45",
        "-59.809",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "45",
        "-59.509",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "45",
        "-59.206",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "45",
        "-58.898",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "45",
        "-58.587",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "45",
        "-58.271",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "45",
        "-57.952",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "45",
        "-57.630",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "45",
        "-57.304",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "45",
        "-56.975",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "45",
        "-56.642",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "45",
        "-56.306",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "45",
        "-55.967",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "45",
        "-55.626",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "45",
        "-55.281",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "45",
        "-54.933",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "45",
        "-54.583",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "45",
        "-54.230",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "45",
        "-53.875",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "45",
        "-53.518",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "45",
        "-53.158",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "46",
        "-52.097",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "46",
        "-51.727",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "46",
        "-51.355",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "46",
        "-50.981",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "46",
        "-50.605",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "46",
        "-50.228",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "46",
        "-49.849",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "46",
        "-49.470",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "46",
        "-49.088",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "46",
        "-48.706",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "46",
        "-48.323",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "46",
        "-47.938",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "46",
        "-47.553",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "46",
        "-47.168",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "46",
        "-46.781",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "46",
        "-46.395",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "46",
        "-46.008",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "46",
        "-45.620",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "46",
        "-45.233",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "46",
        "-44.846",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "46",
        "-44.459",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "46",
        "-44.072",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "46",
        "-43.686",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "46",
        "-43.300",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "46",
        "-42.915",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "46",
        "-42.531",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "46",
        "-42.147",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "46",
        "-41.765",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "46",
        "-41.383",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "46",
        "-41.003",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "46",
        "-40.624",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "46",
        "-40.247",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "46",
        "-39.871",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "46",
        "-39.496",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "46",
        "-39.124",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "46",
        "-38.754",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "46",
        "-38.385",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "46",
        "-38.019",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "46",
        "-37.655",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "46",
        "-37.293",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "46",
        "-36.934",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "46",
        "-36.577",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "46",
        "-36.223",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "46",
        "-35.872",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "46",
        "-35.523",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "46",
        "-35.178",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "46",
        "-34.836",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "46",
        "-34.497",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "46",
        "-34.161",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "46",
        "-33.829",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "46",
        "-20.961",
        "20.927",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "46",
        "14.935",
        "4.966",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "46",
        "14.988",
        "3.609",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "46",
        "15.026",
        "2.251",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "46",
        "15.045",
        "0.894",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "46",
        "15.048",
        "-0.466",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "46",
        "-2.245",
        "-6.562",
        "0.675",
        "0.658",
        "0.025"
    ],
    [
        "-123",
        "46",
        "-7.323",
        "-7.815",
        "0.624",
        "0.579",
        "-0.072"
    ],
    [
        "-122",
        "46",
        "-8.795",
        "-9.550",
        "0.944",
        "1.027",
        "0.084"
    ],
    [
        "-121",
        "46",
        "-9.676",
        "-11.989",
        "0.949",
        "0.869",
        "-0.021"
    ],
    [
        "-120",
        "46",
        "-10.581",
        "-11.815",
        "1.723",
        "1.887",
        "0.007"
    ],
    [
        "-119",
        "46",
        "-11.165",
        "-11.962",
        "1.975",
        "2.309",
        "0.020"
    ],
    [
        "-118",
        "46",
        "-11.835",
        "-12.095",
        "2.128",
        "2.437",
        "0.028"
    ],
    [
        "-117",
        "46",
        "-12.487",
        "-12.175",
        "2.258",
        "2.346",
        "0.051"
    ],
    [
        "-116",
        "46",
        "-13.033",
        "-12.187",
        "2.061",
        "2.259",
        "0.087"
    ],
    [
        "-115",
        "46",
        "-13.452",
        "-12.123",
        "1.964",
        "2.074",
        "0.108"
    ],
    [
        "-114",
        "46",
        "-13.770",
        "-11.986",
        "1.938",
        "1.909",
        "0.124"
    ],
    [
        "-113",
        "46",
        "-14.038",
        "-11.803",
        "1.654",
        "1.834",
        "0.101"
    ],
    [
        "-112",
        "46",
        "-14.280",
        "-11.558",
        "1.102",
        "1.201",
        "0.059"
    ],
    [
        "-111",
        "46",
        "-14.455",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "46",
        "-14.594",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "46",
        "-14.729",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "46",
        "-14.858",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "46",
        "-14.983",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "46",
        "-15.104",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "46",
        "-15.220",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "46",
        "-15.333",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "46",
        "-15.440",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "46",
        "-15.542",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "46",
        "-15.639",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "46",
        "-15.733",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "46",
        "-15.821",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "46",
        "-15.904",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "46",
        "-15.983",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "46",
        "-16.057",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "46",
        "-16.126",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "46",
        "-16.191",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "46",
        "-16.250",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "46",
        "-16.304",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "46",
        "-16.354",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "46",
        "-16.398",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "46",
        "-16.439",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "46",
        "-16.473",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "46",
        "-16.502",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "46",
        "-16.527",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "46",
        "-16.547",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "46",
        "-16.561",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "46",
        "-16.572",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "46",
        "-16.576",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "46",
        "-16.576",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "46",
        "-16.571",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "46",
        "-16.560",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "46",
        "-16.545",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "46",
        "-16.525",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "46",
        "-16.500",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "46",
        "-16.471",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "46",
        "-16.435",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "46",
        "-16.395",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "46",
        "-16.349",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "46",
        "-16.300",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "46",
        "-16.245",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "46",
        "-16.185",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "46",
        "-16.121",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "46",
        "-16.052",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "46",
        "-15.977",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "46",
        "-15.898",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "46",
        "-15.813",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "46",
        "-15.725",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "46",
        "-15.632",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "46",
        "-15.533",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "46",
        "-15.430",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "46",
        "-15.323",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "46",
        "-15.211",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "46",
        "-15.094",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "46",
        "-14.974",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "46",
        "-14.847",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "46",
        "-14.717",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "46",
        "-14.583",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "46",
        "-14.444",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "46",
        "-14.300",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "46",
        "-14.152",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "46",
        "-14.000",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "46",
        "-13.844",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "46",
        "-13.684",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "46",
        "-13.519",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "46",
        "-13.350",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "46",
        "-13.179",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "46",
        "-13.002",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "46",
        "-12.821",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "46",
        "-12.637",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "46",
        "-12.449",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "46",
        "-12.257",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "46",
        "-12.062",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "46",
        "-11.863",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "46",
        "-11.660",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "46",
        "-11.453",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "46",
        "-11.244",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "46",
        "-11.031",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "46",
        "-10.815",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "46",
        "-10.595",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "46",
        "-10.372",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "46",
        "-10.147",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "46",
        "-7.869",
        "17.679",
        "0.906",
        "0.974",
        "-0.126"
    ],
    [
        "-27",
        "46",
        "13.411",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "46",
        "13.622",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "46",
        "13.833",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "46",
        "14.046",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "46",
        "14.260",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "46",
        "14.474",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "46",
        "14.690",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "46",
        "14.906",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "46",
        "15.122",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "46",
        "15.339",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "46",
        "15.556",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "46",
        "15.774",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "46",
        "15.993",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "46",
        "16.211",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "46",
        "16.430",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "46",
        "16.649",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "46",
        "16.868",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "46",
        "17.087",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "46",
        "17.307",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "46",
        "17.526",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "46",
        "17.744",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "46",
        "17.964",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "46",
        "18.181",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "46",
        "18.400",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "46",
        "18.617",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "46",
        "18.834",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "46",
        "19.051",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "46",
        "19.267",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "46",
        "19.482",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "46",
        "19.696",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "46",
        "19.910",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "46",
        "20.122",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "46",
        "20.334",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "46",
        "20.544",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "46",
        "20.754",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "46",
        "20.963",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "46",
        "21.170",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "46",
        "21.376",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "46",
        "21.581",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "46",
        "21.784",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "46",
        "22.014",
        "16.271",
        "0.574",
        "0.738",
        "-0.034"
    ],
    [
        "14",
        "46",
        "22.156",
        "15.919",
        "0.591",
        "0.746",
        "-0.016"
    ],
    [
        "15",
        "46",
        "22.363",
        "15.877",
        "0.653",
        "0.728",
        "0.010"
    ],
    [
        "16",
        "46",
        "22.612",
        "15.736",
        "0.574",
        "0.713",
        "0.056"
    ],
    [
        "17",
        "46",
        "22.777",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "46",
        "22.970",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "46",
        "23.162",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "46",
        "23.352",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "46",
        "23.540",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "46",
        "23.726",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "46",
        "23.910",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "46",
        "24.092",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "46",
        "24.271",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "46",
        "24.448",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "46",
        "24.623",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "46",
        "24.796",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "46",
        "24.967",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "46",
        "25.135",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "46",
        "25.300",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "46",
        "25.462",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "46",
        "25.623",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "46",
        "25.781",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "46",
        "25.935",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "46",
        "26.088",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "46",
        "26.237",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "46",
        "26.384",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "46",
        "26.528",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "46",
        "26.668",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "46",
        "26.807",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "46",
        "26.942",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "46",
        "27.074",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "46",
        "27.202",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "46",
        "27.328",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "46",
        "27.451",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "46",
        "27.571",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "46",
        "27.686",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "46",
        "27.800",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "46",
        "27.909",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "46",
        "28.015",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "46",
        "28.119",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "46",
        "28.218",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "46",
        "28.314",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "46",
        "28.407",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "46",
        "28.495",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "46",
        "28.581",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "46",
        "28.664",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "46",
        "28.742",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "46",
        "28.817",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "46",
        "28.889",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "46",
        "28.957",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "46",
        "29.020",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "46",
        "29.081",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "46",
        "29.137",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "46",
        "29.191",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "46",
        "29.240",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "46",
        "29.286",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "46",
        "29.328",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "46",
        "29.366",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "46",
        "29.400",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "46",
        "29.430",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "46",
        "29.458",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "46",
        "29.481",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "46",
        "29.499",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "46",
        "29.514",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "46",
        "29.526",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "46",
        "29.534",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "46",
        "29.538",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "46",
        "29.601",
        "-0.124",
        "0.213",
        "0.273",
        "-0.026"
    ],
    [
        "81",
        "46",
        "30.492",
        "0.290",
        "0.957",
        "0.975",
        "-0.004"
    ],
    [
        "82",
        "46",
        "31.543",
        "0.811",
        "0.988",
        "1.125",
        "0.024"
    ],
    [
        "83",
        "46",
        "32.193",
        "1.320",
        "1.040",
        "1.185",
        "0.023"
    ],
    [
        "84",
        "46",
        "32.565",
        "1.707",
        "1.130",
        "1.199",
        "0.022"
    ],
    [
        "85",
        "46",
        "32.864",
        "1.858",
        "1.087",
        "1.237",
        "0.022"
    ],
    [
        "86",
        "46",
        "33.137",
        "1.813",
        "1.103",
        "1.249",
        "0.010"
    ],
    [
        "87",
        "46",
        "33.347",
        "1.678",
        "1.180",
        "1.249",
        "-0.006"
    ],
    [
        "88",
        "46",
        "33.460",
        "1.572",
        "1.141",
        "1.293",
        "-0.017"
    ],
    [
        "89",
        "46",
        "33.553",
        "1.486",
        "1.159",
        "1.309",
        "-0.024"
    ],
    [
        "90",
        "46",
        "33.798",
        "1.185",
        "1.243",
        "1.300",
        "-0.015"
    ],
    [
        "91",
        "46",
        "34.306",
        "0.504",
        "1.219",
        "1.339",
        "0.005"
    ],
    [
        "92",
        "46",
        "34.775",
        "-0.171",
        "1.257",
        "1.366",
        "0.028"
    ],
    [
        "93",
        "46",
        "34.702",
        "-0.850",
        "1.357",
        "1.353",
        "0.037"
    ],
    [
        "94",
        "46",
        "33.946",
        "-2.862",
        "1.285",
        "1.370",
        "0.029"
    ],
    [
        "95",
        "46",
        "33.105",
        "-4.373",
        "1.241",
        "1.309",
        "0.000"
    ],
    [
        "96",
        "46",
        "32.463",
        "-4.544",
        "1.236",
        "1.202",
        "-0.006"
    ],
    [
        "97",
        "46",
        "31.897",
        "-4.834",
        "1.147",
        "1.199",
        "0.024"
    ],
    [
        "98",
        "46",
        "31.452",
        "-5.360",
        "1.141",
        "1.203",
        "0.015"
    ],
    [
        "99",
        "46",
        "31.084",
        "-6.018",
        "1.180",
        "1.169",
        "0.011"
    ],
    [
        "100",
        "46",
        "30.806",
        "-6.856",
        "1.083",
        "1.159",
        "0.008"
    ],
    [
        "101",
        "46",
        "30.574",
        "-7.720",
        "1.006",
        "1.082",
        "0.014"
    ],
    [
        "102",
        "46",
        "30.386",
        "-8.298",
        "0.970",
        "0.946",
        "-0.009"
    ],
    [
        "103",
        "46",
        "30.197",
        "-8.612",
        "0.212",
        "0.267",
        "0.007"
    ],
    [
        "104",
        "46",
        "30.059",
        "-8.855",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "46",
        "29.946",
        "-9.109",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "46",
        "29.830",
        "-9.360",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "46",
        "29.712",
        "-9.608",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "46",
        "29.589",
        "-9.854",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "46",
        "29.464",
        "-10.096",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "46",
        "29.336",
        "-10.335",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "46",
        "29.205",
        "-10.571",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "46",
        "29.070",
        "-10.804",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "46",
        "28.933",
        "-11.034",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "46",
        "28.793",
        "-11.259",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "46",
        "28.651",
        "-11.482",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "46",
        "28.505",
        "-11.702",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "46",
        "28.357",
        "-11.917",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "46",
        "28.206",
        "-12.129",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "46",
        "28.052",
        "-12.338",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "46",
        "27.896",
        "-12.542",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "46",
        "27.737",
        "-12.742",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "46",
        "27.576",
        "-12.940",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "46",
        "27.412",
        "-13.133",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "46",
        "27.246",
        "-13.321",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "46",
        "27.078",
        "-13.506",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "46",
        "26.907",
        "-13.687",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "46",
        "26.734",
        "-13.864",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "46",
        "26.559",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "46",
        "26.382",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "46",
        "26.203",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "46",
        "26.021",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "46",
        "25.838",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "46",
        "25.653",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "46",
        "25.465",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "46",
        "25.276",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "46",
        "25.085",
        "-15.257",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "46",
        "24.894",
        "-15.389",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "46",
        "24.700",
        "-15.517",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "46",
        "24.504",
        "-15.639",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "46",
        "24.306",
        "-15.757",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "46",
        "22.969",
        "-16.157",
        "2.119",
        "1.665",
        "-0.106"
    ],
    [
        "142",
        "46",
        "19.138",
        "-15.727",
        "1.787",
        "1.547",
        "-0.108"
    ],
    [
        "143",
        "46",
        "11.797",
        "-13.182",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "46",
        "11.628",
        "-13.742",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "46",
        "11.453",
        "-14.299",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "46",
        "11.270",
        "-14.850",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "46",
        "11.080",
        "-15.397",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "46",
        "9.543",
        "-14.554",
        "2.231",
        "2.203",
        "-0.617"
    ],
    [
        "149",
        "46",
        "4.831",
        "-12.432",
        "4.076",
        "3.107",
        "-0.536"
    ],
    [
        "150",
        "46",
        "-1.416",
        "-10.038",
        "5.346",
        "3.993",
        "-0.590"
    ],
    [
        "151",
        "46",
        "-10.252",
        "-6.104",
        "6.145",
        "4.584",
        "-0.549"
    ],
    [
        "152",
        "46",
        "-21.798",
        "-0.543",
        "6.929",
        "5.081",
        "-0.512"
    ],
    [
        "153",
        "46",
        "-35.704",
        "6.670",
        "7.910",
        "5.490",
        "-0.554"
    ],
    [
        "154",
        "46",
        "-49.981",
        "14.724",
        "7.162",
        "4.991",
        "-0.471"
    ],
    [
        "155",
        "46",
        "-60.407",
        "22.707",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "46",
        "-60.119",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "46",
        "-59.828",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "46",
        "-59.531",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "46",
        "-59.231",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "46",
        "-58.926",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "46",
        "-58.617",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "46",
        "-58.304",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "46",
        "-57.987",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "46",
        "-57.667",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "46",
        "-57.342",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "46",
        "-57.014",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "46",
        "-56.683",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "46",
        "-56.348",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "46",
        "-56.009",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "46",
        "-55.668",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "46",
        "-55.323",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "46",
        "-54.975",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "46",
        "-54.624",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "46",
        "-54.271",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "46",
        "-53.915",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "46",
        "-53.556",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "46",
        "-53.195",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "46",
        "-52.831",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "46",
        "-52.465",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "47",
        "-51.382",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "47",
        "-51.005",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "47",
        "-50.627",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "47",
        "-50.247",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "47",
        "-49.865",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "47",
        "-49.482",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "47",
        "-49.097",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "47",
        "-48.710",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "47",
        "-48.323",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "47",
        "-47.934",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "47",
        "-47.544",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "47",
        "-47.154",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "47",
        "-46.762",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "47",
        "-46.370",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "47",
        "-45.977",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "47",
        "-45.584",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "47",
        "-45.191",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "47",
        "-44.797",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "47",
        "-44.403",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "47",
        "-44.010",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "47",
        "-43.616",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "47",
        "-43.223",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "47",
        "-42.830",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "47",
        "-42.438",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "47",
        "-42.046",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "47",
        "-41.656",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "47",
        "-41.266",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "47",
        "-40.877",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "47",
        "-40.489",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "47",
        "-40.102",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "47",
        "-39.717",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "47",
        "-39.333",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "47",
        "-38.951",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "47",
        "-38.571",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "47",
        "-38.192",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "47",
        "-37.815",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "47",
        "-37.441",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "47",
        "-37.068",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "47",
        "-36.698",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "47",
        "-36.330",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "47",
        "-35.965",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "47",
        "-35.603",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "47",
        "-35.243",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "47",
        "-34.886",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "47",
        "-34.531",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "47",
        "-34.180",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "47",
        "-33.832",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "47",
        "-33.488",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "47",
        "-33.147",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "47",
        "-32.809",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "47",
        "-32.475",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "47",
        "16.614",
        "4.966",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "47",
        "16.669",
        "3.609",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "47",
        "16.707",
        "2.251",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "47",
        "16.726",
        "0.894",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "47",
        "12.497",
        "-1.562",
        "1.701",
        "1.813",
        "0.024"
    ],
    [
        "-124",
        "47",
        "-0.129",
        "-5.153",
        "0.659",
        "0.691",
        "-0.012"
    ],
    [
        "-123",
        "47",
        "-6.800",
        "-9.475",
        "0.707",
        "0.656",
        "0.008"
    ],
    [
        "-122",
        "47",
        "-8.589",
        "-10.319",
        "0.876",
        "0.855",
        "-0.022"
    ],
    [
        "-121",
        "47",
        "-8.557",
        "-11.217",
        "0.943",
        "1.174",
        "0.070"
    ],
    [
        "-120",
        "47",
        "-10.181",
        "-12.214",
        "1.586",
        "1.774",
        "0.045"
    ],
    [
        "-119",
        "47",
        "-10.901",
        "-12.260",
        "1.959",
        "2.315",
        "0.021"
    ],
    [
        "-118",
        "47",
        "-11.641",
        "-12.298",
        "2.155",
        "2.461",
        "0.017"
    ],
    [
        "-117",
        "47",
        "-12.332",
        "-12.302",
        "2.334",
        "2.412",
        "0.023"
    ],
    [
        "-116",
        "47",
        "-12.935",
        "-12.270",
        "2.185",
        "2.401",
        "0.033"
    ],
    [
        "-115",
        "47",
        "-13.445",
        "-12.192",
        "2.097",
        "2.290",
        "0.033"
    ],
    [
        "-114",
        "47",
        "-13.879",
        "-12.054",
        "2.048",
        "2.091",
        "0.031"
    ],
    [
        "-113",
        "47",
        "-14.257",
        "-11.847",
        "1.613",
        "1.856",
        "0.077"
    ],
    [
        "-112",
        "47",
        "-14.545",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "47",
        "-14.691",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "47",
        "-14.832",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "47",
        "-14.968",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "47",
        "-15.100",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "47",
        "-15.228",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "47",
        "-15.351",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "47",
        "-15.469",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "47",
        "-15.583",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "47",
        "-15.692",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "47",
        "-15.795",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "47",
        "-15.895",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "47",
        "-15.989",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "47",
        "-16.079",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "47",
        "-16.164",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "47",
        "-16.244",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "47",
        "-16.319",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "47",
        "-16.390",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "47",
        "-16.455",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "47",
        "-16.515",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "47",
        "-16.571",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "47",
        "-16.621",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "47",
        "-16.667",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "47",
        "-16.707",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "47",
        "-16.742",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "47",
        "-16.773",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "47",
        "-16.798",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "47",
        "-16.818",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "47",
        "-16.833",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "47",
        "-16.842",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "47",
        "-16.847",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "47",
        "-16.847",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "47",
        "-16.842",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "47",
        "-16.831",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "47",
        "-16.816",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "47",
        "-16.795",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "47",
        "-16.770",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "47",
        "-16.739",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "47",
        "-16.704",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "47",
        "-16.663",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "47",
        "-16.617",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "47",
        "-16.566",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "47",
        "-16.511",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "47",
        "-16.450",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "47",
        "-16.384",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "47",
        "-16.314",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "47",
        "-16.238",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "47",
        "-16.157",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "47",
        "-16.072",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "47",
        "-15.982",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "47",
        "-15.887",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "47",
        "-15.787",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "47",
        "-15.682",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "47",
        "-15.573",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "47",
        "-15.460",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "47",
        "-15.341",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "47",
        "-15.217",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "47",
        "-15.089",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "47",
        "-14.957",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "47",
        "-14.820",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "47",
        "-14.679",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "47",
        "-14.533",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "47",
        "-14.382",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "47",
        "-14.229",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "47",
        "-14.069",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "47",
        "-13.906",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "47",
        "-13.739",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "47",
        "-13.568",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "47",
        "-13.393",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "47",
        "-13.213",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "47",
        "-13.030",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "47",
        "-12.842",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "47",
        "-12.652",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "47",
        "-12.456",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "47",
        "-12.257",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "47",
        "-12.055",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "47",
        "-11.849",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "47",
        "-11.640",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "47",
        "-11.427",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "47",
        "-11.210",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "47",
        "-10.989",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "47",
        "-10.766",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "47",
        "-10.540",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "47",
        "-10.310",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "47",
        "-10.077",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "47",
        "13.042",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "47",
        "13.256",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "47",
        "13.472",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "47",
        "13.688",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "47",
        "13.905",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "47",
        "14.123",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "47",
        "14.342",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "47",
        "14.561",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "47",
        "14.781",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "47",
        "15.002",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "47",
        "15.223",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "47",
        "15.445",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "47",
        "15.667",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "47",
        "15.889",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "47",
        "16.111",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "47",
        "16.335",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "47",
        "16.558",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "47",
        "16.780",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "47",
        "17.003",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "47",
        "17.225",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "47",
        "17.448",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "47",
        "17.670",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "47",
        "17.892",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "47",
        "18.114",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "47",
        "18.335",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "47",
        "18.555",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "47",
        "18.776",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "47",
        "18.995",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "47",
        "19.214",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "47",
        "19.432",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "47",
        "19.649",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "47",
        "19.865",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "47",
        "20.081",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "47",
        "20.295",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "47",
        "20.508",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "47",
        "20.720",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "47",
        "20.930",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "47",
        "21.140",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "47",
        "21.348",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "47",
        "21.554",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "47",
        "21.760",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "47",
        "21.963",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "47",
        "22.165",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "47",
        "22.366",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "47",
        "22.564",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "47",
        "22.761",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "47",
        "22.956",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "47",
        "23.148",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "47",
        "23.340",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "47",
        "23.529",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "47",
        "23.716",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "47",
        "23.901",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "47",
        "24.083",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "47",
        "24.264",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "47",
        "24.442",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "47",
        "24.617",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "47",
        "24.791",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "47",
        "24.961",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "47",
        "25.130",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "47",
        "25.294",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "47",
        "25.458",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "47",
        "25.618",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "47",
        "25.776",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "47",
        "25.931",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "47",
        "26.083",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "47",
        "26.231",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "47",
        "26.378",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "47",
        "26.521",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "47",
        "26.662",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "47",
        "26.799",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "47",
        "26.933",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "47",
        "27.064",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "47",
        "27.192",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "47",
        "27.317",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "47",
        "27.438",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "47",
        "27.556",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "47",
        "27.671",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "47",
        "27.782",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "47",
        "27.890",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "47",
        "27.995",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "47",
        "28.096",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "47",
        "28.194",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "47",
        "28.288",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "47",
        "28.378",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "47",
        "28.466",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "47",
        "28.549",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "47",
        "28.630",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "47",
        "28.706",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "47",
        "28.778",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "47",
        "28.847",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "47",
        "28.912",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "47",
        "28.974",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "47",
        "29.031",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "47",
        "29.085",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "47",
        "29.135",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "47",
        "29.182",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "47",
        "29.224",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "47",
        "29.264",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "47",
        "29.298",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "47",
        "29.329",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "47",
        "29.356",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "47",
        "29.379",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "47",
        "29.399",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "47",
        "29.414",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "47",
        "29.426",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "47",
        "29.434",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "47",
        "29.438",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "47",
        "29.438",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "47",
        "29.434",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "47",
        "30.170",
        "-0.028",
        "0.857",
        "0.961",
        "0.013"
    ],
    [
        "83",
        "47",
        "30.640",
        "0.362",
        "0.931",
        "1.066",
        "0.033"
    ],
    [
        "84",
        "47",
        "31.180",
        "0.685",
        "1.065",
        "1.102",
        "0.072"
    ],
    [
        "85",
        "47",
        "31.654",
        "0.885",
        "1.061",
        "1.170",
        "0.068"
    ],
    [
        "86",
        "47",
        "31.963",
        "1.011",
        "1.103",
        "1.209",
        "0.039"
    ],
    [
        "87",
        "47",
        "32.150",
        "1.139",
        "1.189",
        "1.213",
        "0.016"
    ],
    [
        "88",
        "47",
        "32.461",
        "1.109",
        "1.148",
        "1.252",
        "0.005"
    ],
    [
        "89",
        "47",
        "33.011",
        "0.670",
        "1.165",
        "1.266",
        "0.002"
    ],
    [
        "90",
        "47",
        "33.613",
        "-0.007",
        "1.244",
        "1.252",
        "-0.005"
    ],
    [
        "91",
        "47",
        "33.961",
        "-0.352",
        "1.207",
        "1.279",
        "0.002"
    ],
    [
        "92",
        "47",
        "33.908",
        "-0.339",
        "1.249",
        "1.296",
        "0.068"
    ],
    [
        "93",
        "47",
        "33.440",
        "-1.777",
        "1.367",
        "1.318",
        "0.059"
    ],
    [
        "94",
        "47",
        "32.797",
        "-4.075",
        "1.286",
        "1.351",
        "0.040"
    ],
    [
        "95",
        "47",
        "32.386",
        "-4.671",
        "1.228",
        "1.279",
        "0.027"
    ],
    [
        "96",
        "47",
        "32.119",
        "-4.636",
        "1.195",
        "1.143",
        "0.008"
    ],
    [
        "97",
        "47",
        "31.853",
        "-4.988",
        "1.109",
        "1.146",
        "-0.012"
    ],
    [
        "98",
        "47",
        "31.478",
        "-5.593",
        "1.117",
        "1.171",
        "0.005"
    ],
    [
        "99",
        "47",
        "31.114",
        "-6.269",
        "1.165",
        "1.152",
        "0.010"
    ],
    [
        "100",
        "47",
        "30.834",
        "-6.979",
        "1.073",
        "1.149",
        "0.014"
    ],
    [
        "101",
        "47",
        "30.633",
        "-7.658",
        "1.010",
        "1.093",
        "0.008"
    ],
    [
        "102",
        "47",
        "30.403",
        "-8.239",
        "0.967",
        "0.951",
        "0.014"
    ],
    [
        "103",
        "47",
        "30.020",
        "-8.601",
        "0.212",
        "0.265",
        "0.004"
    ],
    [
        "104",
        "47",
        "29.879",
        "-8.855",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "47",
        "29.764",
        "-9.109",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "47",
        "29.646",
        "-9.360",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "47",
        "29.525",
        "-9.608",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "47",
        "29.401",
        "-9.854",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "47",
        "29.274",
        "-10.096",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "47",
        "29.143",
        "-10.335",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "47",
        "29.010",
        "-10.571",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "47",
        "28.873",
        "-10.804",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "47",
        "28.734",
        "-11.034",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "47",
        "28.592",
        "-11.259",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "47",
        "28.447",
        "-11.482",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "47",
        "28.299",
        "-11.702",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "47",
        "28.148",
        "-11.917",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "47",
        "27.995",
        "-12.129",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "47",
        "27.838",
        "-12.338",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "47",
        "27.679",
        "-12.542",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "47",
        "27.518",
        "-12.742",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "47",
        "27.354",
        "-12.940",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "47",
        "27.188",
        "-13.133",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "47",
        "27.019",
        "-13.321",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "47",
        "26.848",
        "-13.506",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "47",
        "26.675",
        "-13.687",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "47",
        "26.498",
        "-13.864",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "47",
        "26.320",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "47",
        "26.140",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "47",
        "25.958",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "47",
        "25.773",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "47",
        "25.586",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "47",
        "25.398",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "47",
        "25.208",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "47",
        "25.017",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "47",
        "24.822",
        "-15.257",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "47",
        "24.626",
        "-15.389",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "47",
        "24.429",
        "-15.517",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "47",
        "24.231",
        "-15.639",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "47",
        "24.030",
        "-15.757",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "47",
        "23.829",
        "-15.870",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "47",
        "20.429",
        "-15.279",
        "0.988",
        "0.815",
        "-0.107"
    ],
    [
        "143",
        "47",
        "12.392",
        "-13.182",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "47",
        "12.220",
        "-13.742",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "47",
        "12.041",
        "-14.299",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "47",
        "11.855",
        "-14.850",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "47",
        "11.662",
        "-15.397",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "47",
        "11.462",
        "-15.939",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "47",
        "10.573",
        "-15.728",
        "1.847",
        "1.615",
        "-0.501"
    ],
    [
        "150",
        "47",
        "8.526",
        "-15.259",
        "2.949",
        "2.253",
        "-0.565"
    ],
    [
        "151",
        "47",
        "4.482",
        "-13.829",
        "3.981",
        "2.989",
        "-0.544"
    ],
    [
        "152",
        "47",
        "-2.733",
        "-10.790",
        "5.188",
        "3.794",
        "-0.499"
    ],
    [
        "153",
        "47",
        "-14.208",
        "-5.246",
        "7.016",
        "4.768",
        "-0.552"
    ],
    [
        "154",
        "47",
        "-29.542",
        "2.903",
        "7.805",
        "5.197",
        "-0.478"
    ],
    [
        "155",
        "47",
        "-45.628",
        "12.800",
        "7.834",
        "5.217",
        "-0.416"
    ],
    [
        "156",
        "47",
        "-59.538",
        "23.068",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "47",
        "-59.241",
        "23.422",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "47",
        "-58.940",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "47",
        "-58.635",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "47",
        "-58.325",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "47",
        "-58.011",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "47",
        "-57.693",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "47",
        "-57.371",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "47",
        "-57.044",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "47",
        "-56.715",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "47",
        "-56.381",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "47",
        "-56.044",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "47",
        "-55.703",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "47",
        "-55.359",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "47",
        "-55.012",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "47",
        "-54.661",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "47",
        "-54.308",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "47",
        "-53.951",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "47",
        "-53.592",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "47",
        "-53.230",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "47",
        "-52.865",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "47",
        "-52.498",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "47",
        "-52.128",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "47",
        "-51.756",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "48",
        "-50.651",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "48",
        "-50.268",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "48",
        "-49.884",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "48",
        "-49.498",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "48",
        "-49.110",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "48",
        "-48.720",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "48",
        "-48.329",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "48",
        "-47.936",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "48",
        "-47.543",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "48",
        "-47.147",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "48",
        "-46.751",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "48",
        "-46.354",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "48",
        "-45.957",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "48",
        "-45.558",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "48",
        "-45.159",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "48",
        "-44.760",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "48",
        "-44.360",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "48",
        "-43.960",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "48",
        "-43.560",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "48",
        "-43.160",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "48",
        "-42.760",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "48",
        "-42.361",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "48",
        "-41.961",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "48",
        "-41.563",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "48",
        "-41.165",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "48",
        "-40.768",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "48",
        "-40.372",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "48",
        "-39.976",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "48",
        "-39.582",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "48",
        "-39.190",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "48",
        "-38.798",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "48",
        "-38.408",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "48",
        "-38.020",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "48",
        "-37.633",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "48",
        "-37.249",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "48",
        "-36.866",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "48",
        "-36.485",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "48",
        "-36.107",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "48",
        "-35.731",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "48",
        "-35.357",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "48",
        "-34.986",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "48",
        "-34.617",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "48",
        "-34.252",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "48",
        "-33.889",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "48",
        "-33.529",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "48",
        "-33.172",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "48",
        "-32.819",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "48",
        "-32.468",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "48",
        "-32.122",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "48",
        "-31.778",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "48",
        "-31.439",
        "26.039",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "48",
        "-1.046",
        "7.237",
        "5.975",
        "5.971",
        "0.035"
    ],
    [
        "-128",
        "48",
        "18.344",
        "3.609",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "48",
        "18.382",
        "2.251",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "48",
        "18.402",
        "0.894",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "48",
        "9.474",
        "-3.865",
        "2.062",
        "2.399",
        "-0.250"
    ],
    [
        "-124",
        "48",
        "-1.881",
        "-8.122",
        "2.321",
        "2.014",
        "-0.013"
    ],
    [
        "-123",
        "48",
        "-6.599",
        "-9.345",
        "1.780",
        "1.565",
        "0.153"
    ],
    [
        "-122",
        "48",
        "-8.722",
        "-11.726",
        "1.399",
        "1.374",
        "0.090"
    ],
    [
        "-121",
        "48",
        "-9.624",
        "-11.867",
        "1.667",
        "1.760",
        "0.104"
    ],
    [
        "-120",
        "48",
        "-10.363",
        "-12.330",
        "2.010",
        "1.999",
        "0.041"
    ],
    [
        "-119",
        "48",
        "-11.162",
        "-12.492",
        "2.083",
        "2.294",
        "0.020"
    ],
    [
        "-118",
        "48",
        "-11.921",
        "-12.488",
        "2.192",
        "2.437",
        "0.012"
    ],
    [
        "-117",
        "48",
        "-12.588",
        "-12.442",
        "2.354",
        "2.411",
        "0.006"
    ],
    [
        "-116",
        "48",
        "-13.173",
        "-12.370",
        "2.207",
        "2.431",
        "0.001"
    ],
    [
        "-115",
        "48",
        "-13.696",
        "-12.265",
        "2.100",
        "2.333",
        "-0.007"
    ],
    [
        "-114",
        "48",
        "-14.174",
        "-12.109",
        "1.956",
        "2.007",
        "0.009"
    ],
    [
        "-113",
        "48",
        "-14.597",
        "-11.900",
        "0.435",
        "0.590",
        "-0.027"
    ],
    [
        "-112",
        "48",
        "-14.774",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "48",
        "-14.922",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "48",
        "-15.065",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "48",
        "-15.204",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "48",
        "-15.338",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "48",
        "-15.467",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "48",
        "-15.592",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "48",
        "-15.713",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "48",
        "-15.828",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "48",
        "-15.939",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "48",
        "-16.044",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "48",
        "-16.146",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "48",
        "-16.241",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "48",
        "-16.333",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "48",
        "-16.419",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "48",
        "-16.500",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "48",
        "-16.577",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "48",
        "-16.648",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "48",
        "-16.715",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "48",
        "-16.776",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "48",
        "-16.832",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "48",
        "-16.884",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "48",
        "-16.929",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "48",
        "-16.971",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "48",
        "-17.006",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "48",
        "-17.037",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "48",
        "-17.062",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "48",
        "-17.083",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "48",
        "-17.098",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "48",
        "-17.108",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "48",
        "-17.113",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "48",
        "-17.113",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "48",
        "-17.107",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "48",
        "-17.097",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "48",
        "-17.082",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "48",
        "-17.061",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "48",
        "-17.035",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "48",
        "-17.004",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "48",
        "-16.967",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "48",
        "-16.926",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "48",
        "-16.879",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "48",
        "-16.827",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "48",
        "-16.771",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "48",
        "-16.709",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "48",
        "-16.642",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "48",
        "-16.571",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "48",
        "-16.494",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "48",
        "-16.412",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "48",
        "-16.326",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "48",
        "-16.234",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "48",
        "-16.138",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "48",
        "-16.035",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "48",
        "-15.930",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "48",
        "-15.818",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "48",
        "-15.703",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "48",
        "-15.582",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "48",
        "-15.457",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "48",
        "-15.327",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "48",
        "-15.193",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "48",
        "-15.053",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "48",
        "-14.909",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "48",
        "-14.761",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "48",
        "-14.609",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "48",
        "-14.452",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "48",
        "-14.290",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "48",
        "-14.125",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "48",
        "-13.956",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "48",
        "-13.781",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "48",
        "-13.603",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "48",
        "-13.420",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "48",
        "-13.234",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "48",
        "-13.044",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "48",
        "-12.850",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "48",
        "-12.651",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "48",
        "-12.449",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "48",
        "-12.243",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "48",
        "-12.034",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "48",
        "-11.821",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "48",
        "-11.605",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "48",
        "-11.385",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "48",
        "-11.161",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "48",
        "-10.935",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "48",
        "-10.704",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "48",
        "-10.471",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "48",
        "-8.298",
        "18.953",
        "0.937",
        "1.024",
        "0.005"
    ],
    [
        "-27",
        "48",
        "12.669",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "48",
        "12.887",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "48",
        "13.105",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "48",
        "13.326",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "48",
        "13.546",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "48",
        "13.767",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "48",
        "13.990",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "48",
        "14.213",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "48",
        "14.437",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "48",
        "14.660",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "48",
        "14.885",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "48",
        "15.111",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "48",
        "15.336",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "48",
        "15.562",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "48",
        "15.788",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "48",
        "16.014",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "48",
        "16.240",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "48",
        "16.467",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "48",
        "16.693",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "48",
        "16.920",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "48",
        "17.146",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "48",
        "17.372",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "48",
        "17.598",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "48",
        "17.823",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "48",
        "18.048",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "48",
        "18.272",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "48",
        "18.495",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "48",
        "18.719",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "48",
        "18.940",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "48",
        "19.162",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "48",
        "19.383",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "48",
        "19.603",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "48",
        "19.821",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "48",
        "20.039",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "48",
        "20.255",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "48",
        "20.470",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "48",
        "20.684",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "48",
        "20.897",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "48",
        "21.109",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "48",
        "21.319",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "48",
        "21.527",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "48",
        "21.734",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "48",
        "21.940",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "48",
        "22.143",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "48",
        "22.345",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "48",
        "22.545",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "48",
        "22.743",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "48",
        "22.939",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "48",
        "23.132",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "48",
        "23.325",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "48",
        "23.515",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "48",
        "23.703",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "48",
        "23.888",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "48",
        "24.072",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "48",
        "24.252",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "48",
        "24.430",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "48",
        "24.606",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "48",
        "24.780",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "48",
        "24.951",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "48",
        "25.120",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "48",
        "25.285",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "48",
        "25.447",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "48",
        "25.608",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "48",
        "25.765",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "48",
        "25.920",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "48",
        "26.071",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "48",
        "26.220",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "48",
        "26.366",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "48",
        "26.508",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "48",
        "26.647",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "48",
        "26.784",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "48",
        "26.917",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "48",
        "27.047",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "48",
        "27.173",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "48",
        "27.297",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "48",
        "27.417",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "48",
        "27.533",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "48",
        "27.647",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "48",
        "27.757",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "48",
        "27.863",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "48",
        "27.966",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "48",
        "28.065",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "48",
        "28.161",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "48",
        "28.253",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "48",
        "28.341",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "48",
        "28.426",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "48",
        "28.508",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "48",
        "28.585",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "48",
        "28.659",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "48",
        "28.729",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "48",
        "28.795",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "48",
        "28.857",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "48",
        "28.916",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "48",
        "28.971",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "48",
        "29.022",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "48",
        "29.069",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "48",
        "29.112",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "48",
        "29.151",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "48",
        "29.187",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "48",
        "29.218",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "48",
        "29.246",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "48",
        "29.270",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "48",
        "29.290",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "48",
        "29.305",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "48",
        "29.318",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "48",
        "29.325",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "48",
        "29.329",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "48",
        "29.329",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "48",
        "29.326",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "48",
        "29.318",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "48",
        "29.305",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "48",
        "29.745",
        "-0.933",
        "0.910",
        "0.856",
        "0.051"
    ],
    [
        "85",
        "48",
        "30.188",
        "-0.378",
        "1.015",
        "1.113",
        "0.060"
    ],
    [
        "86",
        "48",
        "30.584",
        "0.046",
        "1.092",
        "1.188",
        "0.043"
    ],
    [
        "87",
        "48",
        "31.165",
        "-0.043",
        "1.187",
        "1.194",
        "0.034"
    ],
    [
        "88",
        "48",
        "31.954",
        "-0.937",
        "1.100",
        "1.177",
        "0.022"
    ],
    [
        "89",
        "48",
        "32.576",
        "-0.908",
        "1.151",
        "1.224",
        "0.004"
    ],
    [
        "90",
        "48",
        "32.793",
        "-0.600",
        "1.230",
        "1.206",
        "0.022"
    ],
    [
        "91",
        "48",
        "32.622",
        "-0.237",
        "1.119",
        "1.163",
        "0.049"
    ],
    [
        "92",
        "48",
        "32.338",
        "-1.077",
        "1.041",
        "1.103",
        "-0.005"
    ],
    [
        "93",
        "48",
        "32.230",
        "-3.126",
        "1.330",
        "1.299",
        "0.021"
    ],
    [
        "94",
        "48",
        "32.085",
        "-4.484",
        "1.260",
        "1.332",
        "0.032"
    ],
    [
        "95",
        "48",
        "32.002",
        "-4.647",
        "1.219",
        "1.278",
        "0.028"
    ],
    [
        "96",
        "48",
        "31.899",
        "-4.856",
        "1.216",
        "1.179",
        "0.020"
    ],
    [
        "97",
        "48",
        "31.737",
        "-5.305",
        "1.106",
        "1.128",
        "-0.022"
    ],
    [
        "98",
        "48",
        "31.476",
        "-5.932",
        "1.098",
        "1.147",
        "-0.027"
    ],
    [
        "99",
        "48",
        "31.139",
        "-6.559",
        "1.141",
        "1.118",
        "0.013"
    ],
    [
        "100",
        "48",
        "30.786",
        "-7.037",
        "1.057",
        "1.132",
        "0.030"
    ],
    [
        "101",
        "48",
        "30.489",
        "-7.462",
        "1.009",
        "1.093",
        "0.039"
    ],
    [
        "102",
        "48",
        "30.219",
        "-7.894",
        "0.994",
        "0.996",
        "0.035"
    ],
    [
        "103",
        "48",
        "29.873",
        "-8.358",
        "0.784",
        "0.900",
        "0.003"
    ],
    [
        "104",
        "48",
        "29.689",
        "-8.855",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "48",
        "29.573",
        "-9.109",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "48",
        "29.453",
        "-9.360",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "48",
        "29.330",
        "-9.608",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "48",
        "29.204",
        "-9.854",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "48",
        "29.074",
        "-10.096",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "48",
        "28.942",
        "-10.335",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "48",
        "28.806",
        "-10.571",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "48",
        "28.667",
        "-10.804",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "48",
        "28.526",
        "-11.034",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "48",
        "28.382",
        "-11.259",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "48",
        "28.234",
        "-11.482",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "48",
        "28.084",
        "-11.702",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "48",
        "27.931",
        "-11.917",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "48",
        "27.775",
        "-12.129",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "48",
        "27.615",
        "-12.338",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "48",
        "27.455",
        "-12.542",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "48",
        "27.290",
        "-12.742",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "48",
        "27.123",
        "-12.940",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "48",
        "26.954",
        "-13.133",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "48",
        "26.783",
        "-13.321",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "48",
        "26.609",
        "-13.506",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "48",
        "26.433",
        "-13.687",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "48",
        "26.254",
        "-13.864",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "48",
        "26.073",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "48",
        "25.890",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "48",
        "25.705",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "48",
        "25.517",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "48",
        "25.328",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "48",
        "25.137",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "48",
        "24.943",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "48",
        "24.748",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "48",
        "24.551",
        "-15.257",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "48",
        "24.352",
        "-15.389",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "48",
        "24.152",
        "-15.517",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "48",
        "23.950",
        "-15.639",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "48",
        "23.747",
        "-15.757",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "48",
        "23.541",
        "-15.870",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "48",
        "20.655",
        "-15.226",
        "0.635",
        "0.567",
        "0.028"
    ],
    [
        "143",
        "48",
        "12.982",
        "-13.182",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "48",
        "12.808",
        "-13.742",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "48",
        "12.626",
        "-14.299",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "48",
        "12.437",
        "-14.850",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "48",
        "12.241",
        "-15.397",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "48",
        "12.038",
        "-15.939",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "48",
        "11.828",
        "-16.477",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "48",
        "11.610",
        "-17.010",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "48",
        "10.576",
        "-16.966",
        "1.965",
        "1.520",
        "-0.471"
    ],
    [
        "152",
        "48",
        "8.040",
        "-16.293",
        "3.050",
        "2.271",
        "-0.442"
    ],
    [
        "153",
        "48",
        "1.666",
        "-13.749",
        "5.006",
        "3.285",
        "-0.527"
    ],
    [
        "154",
        "48",
        "-10.938",
        "-7.406",
        "6.541",
        "4.293",
        "-0.484"
    ],
    [
        "155",
        "48",
        "-27.511",
        "1.458",
        "7.715",
        "5.162",
        "-0.430"
    ],
    [
        "156",
        "48",
        "-43.559",
        "11.305",
        "8.868",
        "5.602",
        "-0.482"
    ],
    [
        "157",
        "48",
        "-57.223",
        "22.152",
        "2.083",
        "2.020",
        "-0.340"
    ],
    [
        "158",
        "48",
        "-58.331",
        "23.768",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "48",
        "-58.021",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "48",
        "-57.706",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "48",
        "-57.387",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "48",
        "-57.063",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "48",
        "-56.736",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "48",
        "-56.405",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "48",
        "-56.070",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "48",
        "-55.731",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "48",
        "-55.388",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "48",
        "-55.042",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "48",
        "-54.692",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "48",
        "-54.340",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "48",
        "-53.983",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "48",
        "-53.624",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "48",
        "-53.262",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "48",
        "-52.897",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "48",
        "-52.529",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "48",
        "-52.158",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "48",
        "-51.785",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "48",
        "-51.409",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "48",
        "-51.031",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "49",
        "-49.904",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "49",
        "-49.516",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "49",
        "-49.126",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "49",
        "-48.733",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "49",
        "-48.339",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "49",
        "-47.944",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "49",
        "-47.546",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "49",
        "-47.148",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "49",
        "-46.748",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "49",
        "-46.347",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "49",
        "-45.944",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "49",
        "-45.541",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "49",
        "-45.137",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "49",
        "-44.733",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "49",
        "-44.327",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "49",
        "-43.922",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "49",
        "-43.516",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "49",
        "-43.110",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "49",
        "-42.703",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "49",
        "-42.297",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "49",
        "-41.891",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "49",
        "-41.485",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "49",
        "-41.080",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "49",
        "-40.675",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "49",
        "-40.271",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "49",
        "-39.868",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "49",
        "-39.465",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "49",
        "-39.064",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "49",
        "-38.664",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "49",
        "-38.265",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "49",
        "-37.867",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "49",
        "-37.471",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "49",
        "-37.077",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "49",
        "-36.684",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "49",
        "-36.294",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "49",
        "-35.905",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "49",
        "-35.518",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "49",
        "-35.134",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "49",
        "-34.752",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "49",
        "-34.373",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "49",
        "-33.996",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "49",
        "-33.621",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "49",
        "-33.250",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "49",
        "-32.881",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "49",
        "-32.516",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "49",
        "-32.154",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "49",
        "-31.795",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "49",
        "-31.439",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "49",
        "-31.087",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "49",
        "-30.738",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "49",
        "-27.745",
        "22.278",
        "1.767",
        "3.092",
        "-0.010"
    ],
    [
        "-129",
        "49",
        "-7.873",
        "-9.590",
        "7.977",
        "7.904",
        "0.144"
    ],
    [
        "-128",
        "49",
        "16.699",
        "2.533",
        "1.790",
        "2.945",
        "0.052"
    ],
    [
        "-127",
        "49",
        "20.052",
        "2.251",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "49",
        "0.427",
        "-7.636",
        "2.169",
        "2.279",
        "-0.324"
    ],
    [
        "-125",
        "49",
        "-5.126",
        "-8.558",
        "0.606",
        "0.755",
        "-0.095"
    ],
    [
        "-124",
        "49",
        "-7.823",
        "-10.848",
        "0.935",
        "1.068",
        "0.019"
    ],
    [
        "-123",
        "49",
        "-9.580",
        "-11.683",
        "1.166",
        "0.985",
        "0.014"
    ],
    [
        "-122",
        "49",
        "-10.362",
        "-12.802",
        "1.125",
        "1.063",
        "-0.012"
    ],
    [
        "-121",
        "49",
        "-11.001",
        "-12.865",
        "1.724",
        "1.989",
        "0.031"
    ],
    [
        "-120",
        "49",
        "-11.687",
        "-13.059",
        "1.751",
        "1.708",
        "-0.005"
    ],
    [
        "-119",
        "49",
        "-12.292",
        "-12.911",
        "1.793",
        "2.096",
        "0.074"
    ],
    [
        "-118",
        "49",
        "-12.766",
        "-12.781",
        "2.094",
        "2.338",
        "0.012"
    ],
    [
        "-117",
        "49",
        "-13.245",
        "-12.655",
        "2.274",
        "2.312",
        "0.000"
    ],
    [
        "-116",
        "49",
        "-13.720",
        "-12.521",
        "2.087",
        "2.324",
        "-0.013"
    ],
    [
        "-115",
        "49",
        "-14.184",
        "-12.381",
        "1.844",
        "2.100",
        "0.009"
    ],
    [
        "-114",
        "49",
        "-14.577",
        "-12.183",
        "1.723",
        "1.736",
        "0.013"
    ],
    [
        "-113",
        "49",
        "-14.844",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "49",
        "-14.998",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "49",
        "-15.148",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "49",
        "-15.295",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "49",
        "-15.434",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "49",
        "-15.571",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "49",
        "-15.703",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "49",
        "-15.830",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "49",
        "-15.951",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "49",
        "-16.069",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "49",
        "-16.181",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "49",
        "-16.289",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "49",
        "-16.391",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "49",
        "-16.489",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "49",
        "-16.582",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "49",
        "-16.669",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "49",
        "-16.752",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "49",
        "-16.829",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "49",
        "-16.902",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "49",
        "-16.969",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "49",
        "-17.032",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "49",
        "-17.088",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "49",
        "-17.140",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "49",
        "-17.187",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "49",
        "-17.229",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "49",
        "-17.265",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "49",
        "-17.296",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "49",
        "-17.323",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "49",
        "-17.344",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "49",
        "-17.358",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "49",
        "-17.369",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "49",
        "-17.374",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "49",
        "-17.374",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "49",
        "-17.369",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "49",
        "-17.358",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "49",
        "-17.341",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "49",
        "-17.320",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "49",
        "-17.294",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "49",
        "-17.263",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "49",
        "-17.226",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "49",
        "-17.184",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "49",
        "-17.136",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "49",
        "-17.084",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "49",
        "-17.026",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "49",
        "-16.964",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "49",
        "-16.896",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "49",
        "-16.823",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "49",
        "-16.745",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "49",
        "-16.662",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "49",
        "-16.574",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "49",
        "-16.481",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "49",
        "-16.383",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "49",
        "-16.280",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "49",
        "-16.172",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "49",
        "-16.059",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "49",
        "-15.942",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "49",
        "-15.819",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "49",
        "-15.692",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "49",
        "-15.560",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "49",
        "-15.423",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "49",
        "-15.283",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "49",
        "-15.136",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "49",
        "-14.986",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "49",
        "-14.831",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "49",
        "-14.672",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "49",
        "-14.507",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "49",
        "-14.339",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "49",
        "-14.167",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "49",
        "-13.990",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "49",
        "-13.809",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "49",
        "-13.623",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "49",
        "-13.434",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "49",
        "-13.241",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "49",
        "-13.043",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "49",
        "-12.842",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "49",
        "-12.638",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "49",
        "-12.428",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "49",
        "-12.216",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "49",
        "-11.999",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "49",
        "-11.780",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "49",
        "-11.556",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "49",
        "-11.329",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "49",
        "-11.099",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "49",
        "-10.865",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "49",
        "-10.628",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "49",
        "4.910",
        "16.250",
        "2.914",
        "2.089",
        "0.104"
    ],
    [
        "-27",
        "49",
        "12.292",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "49",
        "12.514",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "49",
        "12.735",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "49",
        "12.959",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "49",
        "13.182",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "49",
        "13.408",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "49",
        "13.633",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "49",
        "13.859",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "49",
        "14.086",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "49",
        "14.314",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "49",
        "14.543",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "49",
        "14.771",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "49",
        "15.001",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "49",
        "15.230",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "49",
        "15.460",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "49",
        "15.689",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "49",
        "15.919",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "49",
        "16.149",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "49",
        "16.379",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "49",
        "16.609",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "49",
        "16.838",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "49",
        "17.068",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "49",
        "17.297",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "49",
        "17.526",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "49",
        "17.754",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "49",
        "17.982",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "49",
        "18.209",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "49",
        "18.436",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "49",
        "18.661",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "49",
        "18.886",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "49",
        "19.111",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "49",
        "19.334",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "49",
        "19.556",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "49",
        "19.777",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "49",
        "19.996",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "49",
        "20.215",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "49",
        "20.433",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "49",
        "20.649",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "49",
        "20.863",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "49",
        "21.076",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "49",
        "21.288",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "49",
        "21.499",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "49",
        "21.707",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "49",
        "21.914",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "49",
        "22.119",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "49",
        "22.321",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "49",
        "22.522",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "49",
        "22.722",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "49",
        "22.918",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "49",
        "23.114",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "49",
        "23.306",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "49",
        "23.497",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "49",
        "23.686",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "49",
        "23.871",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "49",
        "24.055",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "49",
        "24.237",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "49",
        "24.416",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "49",
        "24.591",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "49",
        "24.765",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "49",
        "24.936",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "49",
        "25.105",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "49",
        "25.270",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "49",
        "25.432",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "49",
        "25.593",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "49",
        "25.749",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "49",
        "25.903",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "49",
        "26.054",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "49",
        "26.202",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "49",
        "26.347",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "49",
        "26.488",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "49",
        "26.627",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "49",
        "26.762",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "49",
        "26.894",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "49",
        "27.022",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "49",
        "27.147",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "49",
        "27.269",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "49",
        "27.388",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "49",
        "27.503",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "49",
        "27.615",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "49",
        "27.722",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "49",
        "27.827",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "49",
        "27.927",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "49",
        "28.025",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "49",
        "28.119",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "49",
        "28.209",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "49",
        "28.295",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "49",
        "28.377",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "49",
        "28.456",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "49",
        "28.530",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "49",
        "28.601",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "49",
        "28.669",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "49",
        "28.732",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "49",
        "28.791",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "49",
        "28.847",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "49",
        "28.899",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "49",
        "28.947",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "49",
        "28.991",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "49",
        "29.031",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "49",
        "29.067",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "49",
        "29.099",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "49",
        "29.127",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "49",
        "29.151",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "49",
        "29.171",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "49",
        "29.187",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "49",
        "29.200",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "49",
        "29.208",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "49",
        "29.211",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "49",
        "29.212",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "49",
        "29.208",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "49",
        "29.199",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "49",
        "29.188",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "49",
        "29.135",
        "-1.113",
        "0.872",
        "0.829",
        "0.012"
    ],
    [
        "85",
        "49",
        "29.313",
        "-0.921",
        "0.980",
        "1.081",
        "0.030"
    ],
    [
        "86",
        "49",
        "29.925",
        "-1.169",
        "1.069",
        "1.166",
        "0.034"
    ],
    [
        "87",
        "49",
        "30.668",
        "-1.607",
        "1.172",
        "1.180",
        "0.031"
    ],
    [
        "88",
        "49",
        "31.073",
        "-1.630",
        "1.124",
        "1.198",
        "0.020"
    ],
    [
        "89",
        "49",
        "31.176",
        "-1.076",
        "1.126",
        "1.168",
        "0.024"
    ],
    [
        "90",
        "49",
        "31.144",
        "-0.073",
        "1.132",
        "0.990",
        "0.037"
    ],
    [
        "91",
        "49",
        "31.229",
        "-0.185",
        "1.192",
        "1.231",
        "0.047"
    ],
    [
        "92",
        "49",
        "30.919",
        "-1.906",
        "1.234",
        "1.288",
        "0.032"
    ],
    [
        "93",
        "49",
        "30.645",
        "-3.674",
        "1.310",
        "1.284",
        "0.025"
    ],
    [
        "94",
        "49",
        "30.604",
        "-4.209",
        "1.222",
        "1.307",
        "0.020"
    ],
    [
        "95",
        "49",
        "30.572",
        "-4.468",
        "1.189",
        "1.265",
        "0.012"
    ],
    [
        "96",
        "49",
        "30.506",
        "-4.806",
        "1.208",
        "1.195",
        "0.016"
    ],
    [
        "97",
        "49",
        "30.475",
        "-5.303",
        "1.093",
        "1.177",
        "0.004"
    ],
    [
        "98",
        "49",
        "30.594",
        "-5.906",
        "0.902",
        "0.933",
        "0.017"
    ],
    [
        "99",
        "49",
        "30.429",
        "-6.687",
        "1.067",
        "1.018",
        "-0.004"
    ],
    [
        "100",
        "49",
        "30.319",
        "-7.020",
        "1.027",
        "1.102",
        "0.003"
    ],
    [
        "101",
        "49",
        "30.120",
        "-7.234",
        "0.993",
        "1.087",
        "0.020"
    ],
    [
        "102",
        "49",
        "29.779",
        "-7.500",
        "0.953",
        "0.978",
        "0.016"
    ],
    [
        "103",
        "49",
        "29.523",
        "-7.783",
        "0.758",
        "0.847",
        "0.029"
    ],
    [
        "104",
        "49",
        "29.424",
        "-8.522",
        "0.618",
        "0.737",
        "-0.030"
    ],
    [
        "105",
        "49",
        "29.373",
        "-9.109",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "49",
        "29.251",
        "-9.360",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "49",
        "29.126",
        "-9.608",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "49",
        "28.997",
        "-9.854",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "49",
        "28.866",
        "-10.096",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "49",
        "28.731",
        "-10.335",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "49",
        "28.594",
        "-10.571",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "49",
        "28.453",
        "-10.804",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "49",
        "28.310",
        "-11.034",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "49",
        "28.162",
        "-11.259",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "49",
        "28.012",
        "-11.482",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "49",
        "27.860",
        "-11.702",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "49",
        "27.704",
        "-11.917",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "49",
        "27.546",
        "-12.129",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "49",
        "27.385",
        "-12.338",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "49",
        "27.221",
        "-12.542",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "49",
        "27.054",
        "-12.742",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "49",
        "26.885",
        "-12.940",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "49",
        "26.714",
        "-13.133",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "49",
        "26.539",
        "-13.321",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "49",
        "26.362",
        "-13.506",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "49",
        "26.183",
        "-13.687",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "49",
        "26.002",
        "-13.864",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "49",
        "25.818",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "49",
        "25.632",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "49",
        "25.444",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "49",
        "25.254",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "49",
        "25.061",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "49",
        "24.867",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "49",
        "24.671",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "49",
        "24.473",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "49",
        "24.272",
        "-15.257",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "49",
        "24.071",
        "-15.389",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "49",
        "23.867",
        "-15.517",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "49",
        "23.662",
        "-15.639",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "49",
        "23.455",
        "-15.757",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "49",
        "23.246",
        "-15.870",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "49",
        "20.817",
        "-15.034",
        "0.524",
        "0.512",
        "0.126"
    ],
    [
        "143",
        "49",
        "15.178",
        "-13.646",
        "0.544",
        "0.526",
        "0.115"
    ],
    [
        "144",
        "49",
        "13.392",
        "-13.742",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "49",
        "13.207",
        "-14.299",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "49",
        "13.015",
        "-14.850",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "49",
        "12.815",
        "-15.397",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "49",
        "12.609",
        "-15.939",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "49",
        "12.396",
        "-16.477",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "49",
        "12.176",
        "-17.010",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "49",
        "11.948",
        "-17.537",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "49",
        "11.714",
        "-18.060",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "49",
        "11.472",
        "-18.575",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "49",
        "2.630",
        "-14.685",
        "4.547",
        "2.985",
        "-0.516"
    ],
    [
        "155",
        "49",
        "-9.876",
        "-8.707",
        "6.195",
        "4.266",
        "-0.471"
    ],
    [
        "156",
        "49",
        "-25.192",
        "-0.656",
        "8.220",
        "5.543",
        "-0.528"
    ],
    [
        "157",
        "49",
        "-39.928",
        "8.644",
        "8.207",
        "6.173",
        "-0.479"
    ],
    [
        "158",
        "49",
        "-53.338",
        "19.551",
        "5.913",
        "5.084",
        "-0.453"
    ],
    [
        "159",
        "49",
        "-57.389",
        "24.108",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "49",
        "-57.069",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "49",
        "-56.745",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "49",
        "-56.417",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "49",
        "-56.084",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "49",
        "-55.748",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "49",
        "-55.408",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "49",
        "-55.063",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "49",
        "-54.716",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "49",
        "-54.364",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "49",
        "-54.009",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "49",
        "-53.651",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "49",
        "-53.289",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "49",
        "-52.924",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "49",
        "-52.556",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "49",
        "-52.185",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "49",
        "-51.812",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "49",
        "-51.435",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "49",
        "-51.056",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "49",
        "-50.675",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "49",
        "-50.291",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "50",
        "-49.143",
        "29.401",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "50",
        "-48.749",
        "29.559",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "50",
        "-48.352",
        "29.708",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "50",
        "-47.954",
        "29.849",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "50",
        "-47.554",
        "29.980",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "50",
        "-47.153",
        "30.102",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "50",
        "-46.749",
        "30.214",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "50",
        "-46.345",
        "30.318",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "50",
        "-45.939",
        "30.412",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "50",
        "-45.532",
        "30.497",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "50",
        "-45.123",
        "30.573",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "50",
        "-44.714",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "50",
        "-44.304",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "50",
        "-43.893",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "50",
        "-43.482",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "50",
        "-43.070",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "50",
        "-42.658",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "50",
        "-42.246",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "50",
        "-41.834",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "50",
        "-41.421",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "50",
        "-41.009",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "50",
        "-40.597",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "50",
        "-40.186",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "50",
        "-39.775",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "50",
        "-39.365",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "50",
        "-38.955",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "50",
        "-38.547",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "50",
        "-38.140",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "50",
        "-37.733",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "50",
        "-37.328",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "50",
        "-36.925",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "50",
        "-36.523",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "50",
        "-36.123",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "50",
        "-35.724",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "50",
        "-35.328",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "50",
        "-34.933",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "50",
        "-34.541",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "50",
        "-34.151",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "50",
        "-33.763",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "50",
        "-33.378",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "50",
        "-32.995",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "50",
        "-32.615",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "50",
        "-32.238",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "50",
        "-31.864",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "50",
        "-31.493",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "50",
        "-31.126",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "50",
        "-30.761",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "50",
        "-30.400",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "50",
        "-30.043",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "50",
        "-29.689",
        "26.324",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "50",
        "-21.230",
        "5.902",
        "7.188",
        "9.438",
        "-0.071"
    ],
    [
        "-129",
        "50",
        "-12.469",
        "-11.838",
        "8.893",
        "9.269",
        "-0.132"
    ],
    [
        "-128",
        "50",
        "-12.179",
        "-17.234",
        "7.498",
        "8.274",
        "-0.099"
    ],
    [
        "-127",
        "50",
        "-11.583",
        "-10.675",
        "3.361",
        "2.751",
        "-0.233"
    ],
    [
        "-126",
        "50",
        "-9.026",
        "-13.393",
        "0.965",
        "0.909",
        "-0.035"
    ],
    [
        "-125",
        "50",
        "-9.749",
        "-12.444",
        "1.253",
        "1.145",
        "0.065"
    ],
    [
        "-124",
        "50",
        "-9.998",
        "-11.325",
        "2.276",
        "1.758",
        "-0.121"
    ],
    [
        "-123",
        "50",
        "-10.500",
        "-13.622",
        "1.310",
        "1.031",
        "-0.224"
    ],
    [
        "-122",
        "50",
        "-11.710",
        "-13.759",
        "1.757",
        "1.970",
        "0.036"
    ],
    [
        "-121",
        "50",
        "-12.722",
        "-13.848",
        "1.645",
        "1.921",
        "0.008"
    ],
    [
        "-120",
        "50",
        "-13.226",
        "-13.561",
        "1.724",
        "1.790",
        "0.025"
    ],
    [
        "-119",
        "50",
        "-13.573",
        "-13.453",
        "1.615",
        "1.946",
        "-0.023"
    ],
    [
        "-118",
        "50",
        "-13.857",
        "-13.256",
        "1.704",
        "2.014",
        "-0.013"
    ],
    [
        "-117",
        "50",
        "-14.119",
        "-13.003",
        "1.872",
        "1.952",
        "0.000"
    ],
    [
        "-116",
        "50",
        "-14.386",
        "-12.747",
        "1.682",
        "2.003",
        "0.015"
    ],
    [
        "-115",
        "50",
        "-14.683",
        "-12.538",
        "1.234",
        "1.348",
        "-0.007"
    ],
    [
        "-114",
        "50",
        "-14.900",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "50",
        "-15.061",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "50",
        "-15.219",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "50",
        "-15.371",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "50",
        "-15.518",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "50",
        "-15.662",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "50",
        "-15.800",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "50",
        "-15.933",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "50",
        "-16.061",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "50",
        "-16.185",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "50",
        "-16.304",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "50",
        "-16.418",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "50",
        "-16.528",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "50",
        "-16.632",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "50",
        "-16.731",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "50",
        "-16.825",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "50",
        "-16.914",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "50",
        "-16.998",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "50",
        "-17.077",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "50",
        "-17.150",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "50",
        "-17.219",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "50",
        "-17.281",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "50",
        "-17.340",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "50",
        "-17.393",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "50",
        "-17.440",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "50",
        "-17.483",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "50",
        "-17.519",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "50",
        "-17.551",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "50",
        "-17.578",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "50",
        "-17.598",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "50",
        "-17.614",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "50",
        "-17.624",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "50",
        "-17.629",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "50",
        "-17.629",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "50",
        "-17.624",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "50",
        "-17.613",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "50",
        "-17.596",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "50",
        "-17.575",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "50",
        "-17.548",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "50",
        "-17.516",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "50",
        "-17.479",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "50",
        "-17.436",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "50",
        "-17.389",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "50",
        "-17.335",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "50",
        "-17.277",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "50",
        "-17.213",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "50",
        "-17.144",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "50",
        "-17.070",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "50",
        "-16.991",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "50",
        "-16.907",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "50",
        "-16.817",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "50",
        "-16.723",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "50",
        "-16.624",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "50",
        "-16.519",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "50",
        "-16.409",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "50",
        "-16.295",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "50",
        "-16.175",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "50",
        "-16.051",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "50",
        "-15.922",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "50",
        "-15.788",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "50",
        "-15.649",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "50",
        "-15.506",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "50",
        "-15.358",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "50",
        "-15.205",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "50",
        "-15.048",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "50",
        "-14.887",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "50",
        "-14.720",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "50",
        "-14.549",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "50",
        "-14.374",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "50",
        "-14.194",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "50",
        "-14.011",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "50",
        "-13.823",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "50",
        "-13.630",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "50",
        "-13.434",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "50",
        "-13.234",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "50",
        "-13.030",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "50",
        "-12.822",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "50",
        "-12.610",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "50",
        "-12.394",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "50",
        "-12.175",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "50",
        "-11.951",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "50",
        "-11.724",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "50",
        "-11.494",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "50",
        "-11.260",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "50",
        "-11.023",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "50",
        "5.698",
        "16.957",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "50",
        "11.688",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "50",
        "11.912",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "50",
        "12.136",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "50",
        "12.362",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "50",
        "12.588",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "50",
        "12.815",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "50",
        "13.044",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "50",
        "13.272",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "50",
        "13.503",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "50",
        "13.733",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "50",
        "13.965",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "50",
        "14.196",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "50",
        "14.428",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "50",
        "14.660",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "50",
        "14.893",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "50",
        "15.127",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "50",
        "15.360",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "50",
        "15.593",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "50",
        "15.827",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "50",
        "16.060",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "50",
        "16.293",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "50",
        "16.526",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "50",
        "16.760",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "50",
        "16.991",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "50",
        "17.224",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "50",
        "17.455",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "50",
        "17.687",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "50",
        "17.917",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "50",
        "18.147",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "50",
        "18.376",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "50",
        "18.604",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "50",
        "18.832",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "50",
        "19.058",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "50",
        "19.284",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "50",
        "19.508",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "50",
        "19.731",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "50",
        "19.953",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "50",
        "20.174",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "50",
        "20.394",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "50",
        "20.612",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "50",
        "20.828",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "50",
        "21.043",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "50",
        "21.256",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "50",
        "21.468",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "50",
        "21.678",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "50",
        "21.886",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "50",
        "22.091",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "50",
        "22.296",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "50",
        "22.498",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "50",
        "22.698",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "50",
        "22.895",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "50",
        "23.092",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "50",
        "23.285",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "50",
        "23.476",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "50",
        "23.666",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "50",
        "23.851",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "50",
        "24.036",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "50",
        "24.217",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "50",
        "24.396",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "50",
        "24.572",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "50",
        "24.745",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "50",
        "24.916",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "50",
        "25.084",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "50",
        "25.249",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "50",
        "25.411",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "50",
        "25.570",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "50",
        "25.726",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "50",
        "25.880",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "50",
        "26.030",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "50",
        "26.177",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "50",
        "26.321",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "50",
        "26.461",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "50",
        "26.598",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "50",
        "26.732",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "50",
        "26.863",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "50",
        "26.990",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "50",
        "27.113",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "50",
        "27.234",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "50",
        "27.351",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "50",
        "27.464",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "50",
        "27.573",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "50",
        "27.680",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "50",
        "27.782",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "50",
        "27.880",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "50",
        "27.976",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "50",
        "28.067",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "50",
        "28.155",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "50",
        "28.238",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "50",
        "28.318",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "50",
        "28.394",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "50",
        "28.466",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "50",
        "28.534",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "50",
        "28.599",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "50",
        "28.659",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "50",
        "28.716",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "50",
        "28.768",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "50",
        "28.816",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "50",
        "28.861",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "50",
        "28.902",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "50",
        "28.938",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "50",
        "28.971",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "50",
        "28.999",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "50",
        "29.024",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "50",
        "29.044",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "50",
        "29.061",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "50",
        "29.073",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "50",
        "29.081",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "50",
        "29.086",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "50",
        "29.085",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "50",
        "29.081",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "50",
        "29.073",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "50",
        "29.060",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "50",
        "29.045",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "50",
        "29.080",
        "-1.733",
        "0.916",
        "0.998",
        "0.029"
    ],
    [
        "86",
        "50",
        "29.466",
        "-2.050",
        "1.028",
        "1.129",
        "0.017"
    ],
    [
        "87",
        "50",
        "29.660",
        "-1.966",
        "1.129",
        "1.146",
        "0.016"
    ],
    [
        "88",
        "50",
        "29.635",
        "-1.629",
        "1.053",
        "1.153",
        "0.027"
    ],
    [
        "89",
        "50",
        "29.546",
        "-1.151",
        "1.066",
        "1.117",
        "0.022"
    ],
    [
        "90",
        "50",
        "29.469",
        "-0.679",
        "1.188",
        "1.180",
        "0.023"
    ],
    [
        "91",
        "50",
        "29.281",
        "-1.231",
        "1.162",
        "1.235",
        "0.018"
    ],
    [
        "92",
        "50",
        "28.941",
        "-2.563",
        "1.072",
        "1.029",
        "0.029"
    ],
    [
        "93",
        "50",
        "28.614",
        "-3.529",
        "1.229",
        "1.225",
        "0.012"
    ],
    [
        "94",
        "50",
        "28.361",
        "-3.804",
        "1.163",
        "1.259",
        "0.018"
    ],
    [
        "95",
        "50",
        "28.181",
        "-4.178",
        "1.137",
        "1.227",
        "0.030"
    ],
    [
        "96",
        "50",
        "28.083",
        "-4.605",
        "1.160",
        "1.152",
        "0.022"
    ],
    [
        "97",
        "50",
        "28.061",
        "-5.252",
        "1.096",
        "1.177",
        "0.022"
    ],
    [
        "98",
        "50",
        "28.321",
        "-5.924",
        "1.064",
        "1.131",
        "0.005"
    ],
    [
        "99",
        "50",
        "28.923",
        "-6.664",
        "1.030",
        "0.966",
        "-0.006"
    ],
    [
        "100",
        "50",
        "29.458",
        "-6.906",
        "0.980",
        "1.040",
        "0.009"
    ],
    [
        "101",
        "50",
        "29.682",
        "-7.003",
        "0.939",
        "1.045",
        "-0.017"
    ],
    [
        "102",
        "50",
        "29.530",
        "-7.159",
        "0.878",
        "0.915",
        "0.007"
    ],
    [
        "103",
        "50",
        "29.469",
        "-7.811",
        "0.788",
        "0.890",
        "-0.019"
    ],
    [
        "104",
        "50",
        "29.377",
        "-8.453",
        "0.735",
        "0.883",
        "-0.032"
    ],
    [
        "105",
        "50",
        "29.207",
        "-9.006",
        "0.658",
        "0.723",
        "-0.052"
    ],
    [
        "106",
        "50",
        "29.040",
        "-9.360",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "50",
        "28.913",
        "-9.608",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "50",
        "28.782",
        "-9.854",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "50",
        "28.649",
        "-10.096",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "50",
        "28.512",
        "-10.335",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "50",
        "28.372",
        "-10.571",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "50",
        "28.229",
        "-10.804",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "50",
        "28.084",
        "-11.034",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "50",
        "27.935",
        "-11.259",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "50",
        "27.783",
        "-11.482",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "50",
        "27.628",
        "-11.702",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "50",
        "27.470",
        "-11.917",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "50",
        "27.309",
        "-12.129",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "50",
        "27.145",
        "-12.338",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "50",
        "26.979",
        "-12.542",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "50",
        "26.811",
        "-12.742",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "50",
        "26.639",
        "-12.940",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "50",
        "26.464",
        "-13.133",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "50",
        "26.288",
        "-13.321",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "50",
        "26.108",
        "-13.506",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "50",
        "25.927",
        "-13.687",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "50",
        "25.742",
        "-13.864",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "50",
        "25.556",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "50",
        "25.367",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "50",
        "25.176",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "50",
        "24.982",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "50",
        "24.787",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "50",
        "24.590",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "50",
        "24.390",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "50",
        "24.190",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "50",
        "23.987",
        "-15.257",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "50",
        "23.781",
        "-15.389",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "50",
        "23.575",
        "-15.517",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "50",
        "23.367",
        "-15.639",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "50",
        "23.157",
        "-15.757",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "50",
        "22.946",
        "-15.870",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "50",
        "20.615",
        "-15.382",
        "0.573",
        "0.536",
        "0.132"
    ],
    [
        "143",
        "50",
        "15.645",
        "-13.561",
        "0.562",
        "0.532",
        "0.135"
    ],
    [
        "144",
        "50",
        "13.972",
        "-13.742",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "50",
        "13.784",
        "-14.299",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "50",
        "13.590",
        "-14.850",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "50",
        "13.387",
        "-15.397",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "50",
        "13.177",
        "-15.939",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "50",
        "12.961",
        "-16.477",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "50",
        "12.737",
        "-17.010",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "50",
        "12.506",
        "-17.537",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "50",
        "12.268",
        "-18.060",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "50",
        "12.023",
        "-18.575",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "50",
        "11.395",
        "-18.740",
        "1.879",
        "1.673",
        "-0.477"
    ],
    [
        "155",
        "50",
        "4.700",
        "-16.245",
        "4.057",
        "2.883",
        "-0.490"
    ],
    [
        "156",
        "50",
        "-6.594",
        "-10.810",
        "6.380",
        "4.496",
        "-0.573"
    ],
    [
        "157",
        "50",
        "-20.745",
        "-2.887",
        "7.673",
        "5.749",
        "-0.519"
    ],
    [
        "158",
        "50",
        "-35.727",
        "7.063",
        "8.419",
        "6.671",
        "-0.477"
    ],
    [
        "159",
        "50",
        "-49.118",
        "17.553",
        "8.516",
        "6.617",
        "-0.549"
    ],
    [
        "160",
        "50",
        "-56.415",
        "24.440",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "50",
        "-56.086",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "50",
        "-55.753",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "50",
        "-55.416",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "50",
        "-55.074",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "50",
        "-54.729",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "50",
        "-54.379",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "50",
        "-54.026",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "50",
        "-53.669",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "50",
        "-53.309",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "50",
        "-52.945",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "50",
        "-52.578",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "50",
        "-52.208",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "50",
        "-51.834",
        "28.046",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "50",
        "-51.458",
        "28.266",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "50",
        "-51.079",
        "28.477",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "50",
        "-50.697",
        "28.679",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "50",
        "-50.312",
        "28.873",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "50",
        "-49.925",
        "29.058",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "50",
        "-49.535",
        "29.234",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "51",
        "-22.499",
        "0.759",
        "5.446",
        "8.689",
        "-0.548"
    ],
    [
        "-179",
        "51",
        "-21.922",
        "-0.057",
        "5.475",
        "8.480",
        "-0.571"
    ],
    [
        "-178",
        "51",
        "-22.174",
        "1.375",
        "5.736",
        "8.362",
        "-0.563"
    ],
    [
        "-177",
        "51",
        "-23.155",
        "3.628",
        "6.256",
        "8.360",
        "-0.570"
    ],
    [
        "-176",
        "51",
        "-24.464",
        "4.212",
        "6.256",
        "8.132",
        "-0.555"
    ],
    [
        "-175",
        "51",
        "-27.397",
        "8.620",
        "6.313",
        "8.010",
        "-0.556"
    ],
    [
        "-174",
        "51",
        "-30.588",
        "13.323",
        "6.453",
        "8.124",
        "-0.580"
    ],
    [
        "-173",
        "51",
        "-33.544",
        "17.054",
        "6.090",
        "7.889",
        "-0.595"
    ],
    [
        "-172",
        "51",
        "-36.957",
        "21.649",
        "5.477",
        "6.783",
        "-0.561"
    ],
    [
        "-171",
        "51",
        "-39.601",
        "23.839",
        "5.463",
        "6.588",
        "-0.557"
    ],
    [
        "-170",
        "51",
        "-42.133",
        "26.737",
        "4.728",
        "6.014",
        "-0.505"
    ],
    [
        "-169",
        "51",
        "-43.873",
        "30.639",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "51",
        "-43.457",
        "30.697",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "51",
        "-43.041",
        "30.744",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "51",
        "-42.624",
        "30.783",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "51",
        "-42.206",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "51",
        "-41.788",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "51",
        "-41.369",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "51",
        "-40.951",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "51",
        "-40.533",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "51",
        "-40.115",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "51",
        "-39.697",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "51",
        "-39.280",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "51",
        "-38.863",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "51",
        "-38.447",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "51",
        "-38.031",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "51",
        "-37.617",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "51",
        "-37.204",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "51",
        "-36.792",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "51",
        "-36.381",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "51",
        "-35.971",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "51",
        "-35.564",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "51",
        "-35.158",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "51",
        "-34.753",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "51",
        "-34.351",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "51",
        "-33.951",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "51",
        "-33.553",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "51",
        "-33.157",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "51",
        "-32.764",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "51",
        "-32.373",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "51",
        "-31.985",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "51",
        "-31.599",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "51",
        "-31.217",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "51",
        "-30.837",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "51",
        "-30.461",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "51",
        "-30.088",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "51",
        "-29.718",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "51",
        "-29.352",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "51",
        "-28.989",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "51",
        "-27.416",
        "24.839",
        "4.876",
        "6.342",
        "-0.088"
    ],
    [
        "-130",
        "51",
        "-16.933",
        "-4.715",
        "7.945",
        "10.505",
        "-0.151"
    ],
    [
        "-129",
        "51",
        "-12.504",
        "-17.083",
        "6.697",
        "7.526",
        "0.013"
    ],
    [
        "-128",
        "51",
        "-13.155",
        "-15.156",
        "2.618",
        "3.242",
        "-0.215"
    ],
    [
        "-127",
        "51",
        "-12.587",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "51",
        "-12.805",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "51",
        "-13.020",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "51",
        "-13.231",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "51",
        "-13.438",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "51",
        "-13.640",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "51",
        "-13.839",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "51",
        "-14.034",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "51",
        "-14.224",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "51",
        "-14.410",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "51",
        "-14.592",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "51",
        "-14.769",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "51",
        "-14.942",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "51",
        "-15.110",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "51",
        "-15.274",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "51",
        "-15.434",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "51",
        "-15.588",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "51",
        "-15.738",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "51",
        "-15.883",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "51",
        "-16.023",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "51",
        "-16.159",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "51",
        "-16.289",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "51",
        "-16.415",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "51",
        "-16.536",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "51",
        "-16.652",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "51",
        "-16.762",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "51",
        "-16.867",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "51",
        "-16.968",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "51",
        "-17.064",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "51",
        "-17.154",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "51",
        "-17.239",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "51",
        "-17.319",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "51",
        "-17.394",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "51",
        "-17.462",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "51",
        "-17.527",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "51",
        "-17.585",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "51",
        "-17.639",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "51",
        "-17.687",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "51",
        "-17.730",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "51",
        "-17.767",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "51",
        "-17.800",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "51",
        "-17.827",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "51",
        "-17.848",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "51",
        "-17.863",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "51",
        "-17.875",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "51",
        "-17.880",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "51",
        "-17.879",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "51",
        "-17.874",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "51",
        "-17.863",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "51",
        "-17.847",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "51",
        "-17.824",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "51",
        "-17.798",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "51",
        "-17.765",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "51",
        "-17.727",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "51",
        "-17.684",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "51",
        "-17.635",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "51",
        "-17.581",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "51",
        "-17.522",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "51",
        "-17.457",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "51",
        "-17.387",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "51",
        "-17.312",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "51",
        "-17.232",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "51",
        "-17.147",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "51",
        "-17.055",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "51",
        "-16.960",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "51",
        "-16.859",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "51",
        "-16.753",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "51",
        "-16.641",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "51",
        "-16.526",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "51",
        "-16.404",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "51",
        "-16.278",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "51",
        "-16.148",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "51",
        "-16.011",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "51",
        "-15.871",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "51",
        "-15.726",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "51",
        "-15.575",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "51",
        "-15.420",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "51",
        "-15.261",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "51",
        "-15.096",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "51",
        "-14.928",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "51",
        "-14.754",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "51",
        "-14.577",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "51",
        "-14.395",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "51",
        "-14.208",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "51",
        "-14.017",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "51",
        "-13.823",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "51",
        "-13.624",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "51",
        "-13.421",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "51",
        "-13.213",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "51",
        "-13.002",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "51",
        "-12.787",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "51",
        "-12.568",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "51",
        "-12.346",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "51",
        "-12.119",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "51",
        "-11.888",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "51",
        "-11.655",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "51",
        "-11.418",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "51",
        "-1.278",
        "16.667",
        "4.431",
        "2.861",
        "0.046"
    ],
    [
        "-29",
        "51",
        "11.076",
        "16.554",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "51",
        "11.301",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "51",
        "11.528",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "51",
        "11.755",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "51",
        "11.984",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "51",
        "12.213",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "51",
        "12.444",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "51",
        "12.676",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "51",
        "12.908",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "51",
        "13.142",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "51",
        "13.375",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "51",
        "13.610",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "51",
        "13.845",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "51",
        "14.080",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "51",
        "14.316",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "51",
        "14.552",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "51",
        "14.789",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "51",
        "15.025",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "51",
        "15.263",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "51",
        "15.500",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "51",
        "15.736",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "51",
        "15.972",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "51",
        "16.208",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "51",
        "16.446",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "51",
        "16.681",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "51",
        "16.916",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "51",
        "17.152",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "51",
        "17.386",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "51",
        "17.620",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "51",
        "17.853",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "51",
        "18.086",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "51",
        "18.317",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "51",
        "18.548",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "51",
        "18.777",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "51",
        "19.006",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "51",
        "19.234",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "51",
        "19.460",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "51",
        "19.685",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "51",
        "19.909",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "51",
        "20.132",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "51",
        "20.353",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "51",
        "20.573",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "51",
        "20.790",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "51",
        "21.007",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "51",
        "21.221",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "51",
        "21.434",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "51",
        "21.646",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "51",
        "21.855",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "51",
        "22.061",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "51",
        "22.267",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "51",
        "22.469",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "51",
        "22.671",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "51",
        "22.869",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "51",
        "23.065",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "51",
        "23.260",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "51",
        "23.452",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "51",
        "23.640",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "51",
        "23.827",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "51",
        "24.011",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "51",
        "24.192",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "51",
        "24.371",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "51",
        "24.547",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "51",
        "24.721",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "51",
        "24.891",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "51",
        "25.058",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "51",
        "25.223",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "51",
        "25.384",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "51",
        "25.543",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "51",
        "25.698",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "51",
        "25.850",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "51",
        "26.000",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "51",
        "26.145",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "51",
        "26.288",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "51",
        "26.427",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "51",
        "26.563",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "51",
        "26.695",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "51",
        "26.825",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "51",
        "26.950",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "51",
        "27.072",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "51",
        "27.190",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "51",
        "27.305",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "51",
        "27.417",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "51",
        "27.524",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "51",
        "27.628",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "51",
        "27.728",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "51",
        "27.824",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "51",
        "27.917",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "51",
        "28.006",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "51",
        "28.091",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "51",
        "28.171",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "51",
        "28.249",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "51",
        "28.321",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "51",
        "28.391",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "51",
        "28.456",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "51",
        "28.517",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "51",
        "28.575",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "51",
        "28.628",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "51",
        "28.677",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "51",
        "28.723",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "51",
        "28.764",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "51",
        "28.801",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "51",
        "28.834",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "51",
        "28.863",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "51",
        "28.888",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "51",
        "28.908",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "51",
        "28.925",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "51",
        "28.937",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "51",
        "28.945",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "51",
        "28.950",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "51",
        "28.950",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "51",
        "28.945",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "51",
        "28.938",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "51",
        "28.925",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "51",
        "28.908",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "51",
        "28.958",
        "-1.849",
        "0.848",
        "0.945",
        "-0.031"
    ],
    [
        "86",
        "51",
        "28.947",
        "-2.060",
        "0.925",
        "1.050",
        "-0.020"
    ],
    [
        "87",
        "51",
        "28.872",
        "-2.007",
        "1.025",
        "1.061",
        "0.008"
    ],
    [
        "88",
        "51",
        "28.763",
        "-1.859",
        "1.001",
        "1.103",
        "0.024"
    ],
    [
        "89",
        "51",
        "28.611",
        "-1.693",
        "1.029",
        "1.125",
        "0.008"
    ],
    [
        "90",
        "51",
        "28.423",
        "-1.682",
        "1.126",
        "1.138",
        "0.006"
    ],
    [
        "91",
        "51",
        "28.250",
        "-2.276",
        "1.081",
        "1.178",
        "0.020"
    ],
    [
        "92",
        "51",
        "28.208",
        "-3.060",
        "1.083",
        "1.183",
        "0.023"
    ],
    [
        "93",
        "51",
        "28.231",
        "-3.454",
        "1.138",
        "1.158",
        "0.009"
    ],
    [
        "94",
        "51",
        "28.187",
        "-3.817",
        "1.063",
        "1.169",
        "0.015"
    ],
    [
        "95",
        "51",
        "28.098",
        "-4.294",
        "1.059",
        "1.158",
        "0.023"
    ],
    [
        "96",
        "51",
        "27.992",
        "-4.830",
        "1.118",
        "1.128",
        "0.025"
    ],
    [
        "97",
        "51",
        "27.880",
        "-5.359",
        "1.054",
        "1.137",
        "0.025"
    ],
    [
        "98",
        "51",
        "28.021",
        "-5.884",
        "1.043",
        "1.118",
        "0.019"
    ],
    [
        "99",
        "51",
        "28.495",
        "-6.395",
        "1.093",
        "1.079",
        "-0.004"
    ],
    [
        "100",
        "51",
        "29.027",
        "-6.917",
        "0.999",
        "1.080",
        "-0.019"
    ],
    [
        "101",
        "51",
        "29.361",
        "-7.373",
        "0.968",
        "1.060",
        "-0.005"
    ],
    [
        "102",
        "51",
        "29.406",
        "-7.698",
        "1.006",
        "1.014",
        "-0.013"
    ],
    [
        "103",
        "51",
        "29.320",
        "-8.001",
        "0.913",
        "1.006",
        "-0.014"
    ],
    [
        "104",
        "51",
        "29.133",
        "-8.388",
        "0.887",
        "0.994",
        "-0.006"
    ],
    [
        "105",
        "51",
        "28.939",
        "-8.830",
        "0.919",
        "0.927",
        "0.006"
    ],
    [
        "106",
        "51",
        "28.811",
        "-9.341",
        "0.210",
        "0.256",
        "-0.031"
    ],
    [
        "107",
        "51",
        "28.690",
        "-9.608",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "51",
        "28.559",
        "-9.854",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "51",
        "28.423",
        "-10.096",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "51",
        "28.284",
        "-10.335",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "51",
        "28.142",
        "-10.571",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "51",
        "27.998",
        "-10.804",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "51",
        "27.850",
        "-11.034",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "51",
        "27.699",
        "-11.259",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "51",
        "27.544",
        "-11.482",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "51",
        "27.387",
        "-11.702",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "51",
        "27.227",
        "-11.917",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "51",
        "27.064",
        "-12.129",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "51",
        "26.898",
        "-12.338",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "51",
        "26.729",
        "-12.542",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "51",
        "26.558",
        "-12.742",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "51",
        "26.384",
        "-12.940",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "51",
        "26.207",
        "-13.133",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "51",
        "26.027",
        "-13.321",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "51",
        "25.845",
        "-13.506",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "51",
        "25.661",
        "-13.687",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "51",
        "25.474",
        "-13.864",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "51",
        "25.285",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "51",
        "25.093",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "51",
        "24.900",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "51",
        "24.704",
        "-14.527",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "51",
        "24.506",
        "-14.682",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "51",
        "24.306",
        "-14.833",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "51",
        "24.104",
        "-14.979",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "51",
        "23.899",
        "-15.121",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "51",
        "23.693",
        "-15.257",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "51",
        "23.485",
        "-15.389",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "51",
        "23.275",
        "-15.517",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "51",
        "23.064",
        "-15.639",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "51",
        "22.683",
        "-15.734",
        "0.205",
        "0.252",
        "0.019"
    ],
    [
        "141",
        "51",
        "20.940",
        "-15.141",
        "0.872",
        "0.771",
        "0.053"
    ],
    [
        "142",
        "51",
        "18.971",
        "-14.489",
        "0.826",
        "0.797",
        "0.080"
    ],
    [
        "143",
        "51",
        "16.829",
        "-13.987",
        "0.774",
        "0.752",
        "0.124"
    ],
    [
        "144",
        "51",
        "14.547",
        "-13.742",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "51",
        "14.357",
        "-14.299",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "51",
        "14.159",
        "-14.850",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "51",
        "13.954",
        "-15.397",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "51",
        "13.741",
        "-15.939",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "51",
        "13.521",
        "-16.477",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "51",
        "13.295",
        "-17.010",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "51",
        "13.061",
        "-17.537",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "51",
        "12.819",
        "-18.060",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "51",
        "12.570",
        "-18.575",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "51",
        "12.315",
        "-19.087",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "51",
        "12.053",
        "-19.592",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "51",
        "7.678",
        "-17.776",
        "3.919",
        "3.014",
        "-0.590"
    ],
    [
        "157",
        "51",
        "-3.505",
        "-11.358",
        "6.008",
        "4.543",
        "-0.571"
    ],
    [
        "158",
        "51",
        "-17.348",
        "-2.214",
        "7.537",
        "5.884",
        "-0.527"
    ],
    [
        "159",
        "51",
        "-32.350",
        "8.169",
        "8.979",
        "6.907",
        "-0.590"
    ],
    [
        "160",
        "51",
        "-46.098",
        "17.631",
        "7.560",
        "6.523",
        "-0.557"
    ],
    [
        "161",
        "51",
        "-55.411",
        "24.764",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "51",
        "-55.072",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "51",
        "-54.730",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "51",
        "-54.384",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "51",
        "-54.033",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "51",
        "-53.679",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "51",
        "-53.321",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "51",
        "-52.959",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "51",
        "-52.593",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "51",
        "-52.224",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "51",
        "-51.851",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "51",
        "-51.476",
        "27.818",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "51",
        "-51.232",
        "27.682",
        "1.431",
        "2.728",
        "0.456"
    ],
    [
        "174",
        "51",
        "-49.288",
        "25.972",
        "6.098",
        "9.306",
        "0.345"
    ],
    [
        "175",
        "51",
        "-43.364",
        "25.545",
        "5.557",
        "9.355",
        "0.049"
    ],
    [
        "176",
        "51",
        "-36.133",
        "22.069",
        "5.501",
        "9.415",
        "-0.229"
    ],
    [
        "177",
        "51",
        "-30.316",
        "16.010",
        "5.411",
        "9.300",
        "-0.370"
    ],
    [
        "178",
        "51",
        "-26.258",
        "8.870",
        "5.118",
        "9.272",
        "-0.454"
    ],
    [
        "179",
        "51",
        "-23.857",
        "3.699",
        "5.161",
        "8.941",
        "-0.516"
    ],
    [
        "-180",
        "52",
        "2.498",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "52",
        "2.186",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "52",
        "1.875",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "52",
        "1.564",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "52",
        "1.251",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "52",
        "0.565",
        "-21.631",
        "0.975",
        "2.320",
        "-0.346"
    ],
    [
        "-174",
        "52",
        "-3.343",
        "-17.967",
        "3.954",
        "4.980",
        "-0.302"
    ],
    [
        "-173",
        "52",
        "-7.796",
        "-11.386",
        "5.262",
        "7.054",
        "-0.498"
    ],
    [
        "-172",
        "52",
        "-12.510",
        "-6.992",
        "5.583",
        "7.064",
        "-0.576"
    ],
    [
        "-171",
        "52",
        "-17.446",
        "-0.976",
        "6.033",
        "7.905",
        "-0.608"
    ],
    [
        "-170",
        "52",
        "-22.457",
        "3.515",
        "6.215",
        "7.950",
        "-0.609"
    ],
    [
        "-169",
        "52",
        "-27.009",
        "9.720",
        "6.633",
        "8.603",
        "-0.593"
    ],
    [
        "-168",
        "52",
        "-30.341",
        "15.652",
        "7.669",
        "8.929",
        "-0.567"
    ],
    [
        "-167",
        "52",
        "-33.326",
        "20.767",
        "7.382",
        "8.833",
        "-0.458"
    ],
    [
        "-166",
        "52",
        "-38.544",
        "27.517",
        "5.377",
        "5.491",
        "-0.332"
    ],
    [
        "-165",
        "52",
        "-41.328",
        "30.812",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "52",
        "-40.904",
        "30.831",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "52",
        "-40.480",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "52",
        "-40.056",
        "30.842",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "52",
        "-39.632",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "52",
        "-39.208",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "52",
        "-38.784",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "52",
        "-38.361",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "52",
        "-37.939",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "52",
        "-37.517",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "52",
        "-37.096",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "52",
        "-36.675",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "52",
        "-36.256",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "52",
        "-35.838",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "52",
        "-35.422",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "52",
        "-35.007",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "52",
        "-34.593",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "52",
        "-34.182",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "52",
        "-33.772",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "52",
        "-33.364",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "52",
        "-32.958",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "52",
        "-32.554",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "52",
        "-32.153",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "52",
        "-31.754",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "52",
        "-31.358",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "52",
        "-30.964",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "52",
        "-30.574",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "52",
        "-30.186",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "52",
        "-29.801",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "52",
        "-29.419",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "52",
        "-29.041",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "52",
        "-28.666",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "52",
        "-28.295",
        "26.869",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "52",
        "-27.927",
        "26.600",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "52",
        "-22.285",
        "10.735",
        "5.079",
        "7.664",
        "-0.157"
    ],
    [
        "-130",
        "52",
        "-14.132",
        "-12.290",
        "7.688",
        "10.519",
        "-0.117"
    ],
    [
        "-129",
        "52",
        "-12.739",
        "-15.622",
        "7.126",
        "7.915",
        "-0.084"
    ],
    [
        "-128",
        "52",
        "-12.531",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "52",
        "-12.757",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "52",
        "-12.979",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "52",
        "-13.196",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "52",
        "-13.411",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "52",
        "-13.620",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "52",
        "-13.826",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "52",
        "-14.028",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "52",
        "-14.225",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "52",
        "-14.417",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "52",
        "-14.607",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "52",
        "-14.790",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "52",
        "-14.970",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "52",
        "-15.145",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "52",
        "-15.316",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "52",
        "-15.482",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "52",
        "-15.644",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "52",
        "-15.801",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "52",
        "-15.952",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "52",
        "-16.099",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "52",
        "-16.242",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "52",
        "-16.379",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "52",
        "-16.512",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "52",
        "-16.639",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "52",
        "-16.762",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "52",
        "-16.879",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "52",
        "-16.991",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "52",
        "-17.098",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "52",
        "-17.200",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "52",
        "-17.297",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "52",
        "-17.388",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "52",
        "-17.474",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "52",
        "-17.555",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "52",
        "-17.631",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "52",
        "-17.701",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "52",
        "-17.767",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "52",
        "-17.826",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "52",
        "-17.880",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "52",
        "-17.929",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "52",
        "-17.973",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "52",
        "-18.010",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "52",
        "-18.043",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "52",
        "-18.070",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "52",
        "-18.092",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "52",
        "-18.109",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "52",
        "-18.119",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "52",
        "-18.125",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "52",
        "-18.124",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "52",
        "-18.118",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "52",
        "-18.108",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "52",
        "-18.090",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "52",
        "-18.068",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "52",
        "-18.041",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "52",
        "-18.008",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "52",
        "-17.969",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "52",
        "-17.925",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "52",
        "-17.876",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "52",
        "-17.821",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "52",
        "-17.761",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "52",
        "-17.696",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "52",
        "-17.625",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "52",
        "-17.549",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "52",
        "-17.468",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "52",
        "-17.381",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "52",
        "-17.289",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "52",
        "-17.192",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "52",
        "-17.089",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "52",
        "-16.982",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "52",
        "-16.869",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "52",
        "-16.751",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "52",
        "-16.628",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "52",
        "-16.501",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "52",
        "-16.368",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "52",
        "-16.230",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "52",
        "-16.088",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "52",
        "-15.940",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "52",
        "-15.788",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "52",
        "-15.631",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "52",
        "-15.468",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "52",
        "-15.302",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "52",
        "-15.131",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "52",
        "-14.956",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "52",
        "-14.775",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "52",
        "-14.590",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "52",
        "-14.402",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "52",
        "-14.208",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "52",
        "-14.010",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "52",
        "-13.809",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "52",
        "-13.602",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "52",
        "-13.393",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "52",
        "-13.179",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "52",
        "-12.960",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "52",
        "-12.738",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "52",
        "-12.513",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "52",
        "-12.283",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "52",
        "-12.050",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "52",
        "-11.812",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "52",
        "-9.665",
        "17.819",
        "0.851",
        "1.242",
        "-0.019"
    ],
    [
        "-30",
        "52",
        "5.912",
        "16.606",
        "3.551",
        "2.895",
        "0.094"
    ],
    [
        "-29",
        "52",
        "10.682",
        "16.554",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "52",
        "10.910",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "52",
        "11.139",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "52",
        "11.371",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "52",
        "11.602",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "52",
        "11.835",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "52",
        "12.069",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "52",
        "12.304",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "52",
        "12.540",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "52",
        "12.776",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "52",
        "13.013",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "52",
        "13.251",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "52",
        "13.490",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "52",
        "13.728",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "52",
        "13.968",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "52",
        "14.206",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "52",
        "14.446",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "52",
        "14.686",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "52",
        "14.926",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "52",
        "15.167",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "52",
        "15.407",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "52",
        "15.647",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "52",
        "15.886",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "52",
        "16.126",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "52",
        "16.365",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "52",
        "16.604",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "52",
        "16.842",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "52",
        "17.080",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "52",
        "17.317",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "52",
        "17.554",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "52",
        "17.789",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "52",
        "18.025",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "52",
        "18.258",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "52",
        "18.492",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "52",
        "18.724",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "52",
        "18.954",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "52",
        "19.183",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "52",
        "19.412",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "52",
        "19.639",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "52",
        "19.865",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "52",
        "20.089",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "52",
        "20.311",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "52",
        "20.533",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "52",
        "20.752",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "52",
        "20.969",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "52",
        "21.185",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "52",
        "21.399",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "52",
        "21.611",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "52",
        "21.822",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "52",
        "22.030",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "52",
        "22.235",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "52",
        "22.439",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "52",
        "22.640",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "52",
        "22.839",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "52",
        "23.036",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "52",
        "23.230",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "52",
        "23.422",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "52",
        "23.611",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "52",
        "23.798",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "52",
        "23.981",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "52",
        "24.163",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "52",
        "24.341",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "52",
        "24.517",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "52",
        "24.689",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "52",
        "24.860",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "52",
        "25.027",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "52",
        "25.191",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "52",
        "25.351",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "52",
        "25.509",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "52",
        "25.663",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "52",
        "25.814",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "52",
        "25.961",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "52",
        "26.107",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "52",
        "26.247",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "52",
        "26.385",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "52",
        "26.520",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "52",
        "26.651",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "52",
        "26.778",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "52",
        "26.901",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "52",
        "27.021",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "52",
        "27.138",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "52",
        "27.251",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "52",
        "27.360",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "52",
        "27.465",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "52",
        "27.566",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "52",
        "27.664",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "52",
        "27.758",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "52",
        "27.848",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "52",
        "27.934",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "52",
        "28.017",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "52",
        "28.094",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "52",
        "28.168",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "52",
        "28.239",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "52",
        "28.305",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "52",
        "28.367",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "52",
        "28.426",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "52",
        "28.480",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "52",
        "28.530",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "52",
        "28.575",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "52",
        "28.617",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "52",
        "28.655",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "52",
        "28.688",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "52",
        "28.717",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "52",
        "28.742",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "52",
        "28.764",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "52",
        "28.780",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "52",
        "28.793",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "52",
        "28.802",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "52",
        "28.805",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "52",
        "28.806",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "52",
        "28.801",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "52",
        "28.793",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "52",
        "28.781",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "52",
        "28.764",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "52",
        "28.743",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "52",
        "28.718",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "52",
        "28.688",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "52",
        "28.596",
        "-2.444",
        "0.775",
        "0.928",
        "-0.017"
    ],
    [
        "89",
        "52",
        "28.523",
        "-2.593",
        "0.797",
        "0.977",
        "-0.020"
    ],
    [
        "90",
        "52",
        "28.469",
        "-2.781",
        "0.889",
        "0.965",
        "-0.009"
    ],
    [
        "91",
        "52",
        "28.438",
        "-3.060",
        "0.817",
        "0.999",
        "0.008"
    ],
    [
        "92",
        "52",
        "28.399",
        "-3.408",
        "0.817",
        "0.999",
        "0.008"
    ],
    [
        "93",
        "52",
        "28.348",
        "-3.827",
        "0.889",
        "0.966",
        "0.017"
    ],
    [
        "94",
        "52",
        "28.284",
        "-4.259",
        "0.804",
        "0.984",
        "0.026"
    ],
    [
        "95",
        "52",
        "28.203",
        "-4.659",
        "0.801",
        "0.978",
        "0.013"
    ],
    [
        "96",
        "52",
        "28.116",
        "-5.025",
        "0.880",
        "0.946",
        "0.017"
    ],
    [
        "97",
        "52",
        "28.001",
        "-5.368",
        "0.850",
        "0.973",
        "0.035"
    ],
    [
        "98",
        "52",
        "27.951",
        "-5.707",
        "0.894",
        "0.994",
        "-0.006"
    ],
    [
        "99",
        "52",
        "28.094",
        "-6.004",
        "0.912",
        "0.961",
        "-0.025"
    ],
    [
        "100",
        "52",
        "28.260",
        "-6.356",
        "0.795",
        "0.959",
        "0.013"
    ],
    [
        "101",
        "52",
        "28.267",
        "-6.793",
        "0.773",
        "0.933",
        "-0.006"
    ],
    [
        "102",
        "52",
        "28.076",
        "-7.135",
        "0.784",
        "0.833",
        "0.028"
    ],
    [
        "103",
        "52",
        "27.910",
        "-7.408",
        "0.722",
        "0.860",
        "-0.006"
    ],
    [
        "104",
        "52",
        "27.568",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "52",
        "27.467",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "52",
        "28.152",
        "-8.701",
        "0.839",
        "0.935",
        "-0.002"
    ],
    [
        "107",
        "52",
        "28.389",
        "-9.498",
        "0.628",
        "0.633",
        "-0.045"
    ],
    [
        "108",
        "52",
        "28.326",
        "-9.854",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "52",
        "28.189",
        "-10.096",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "52",
        "28.048",
        "-10.335",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "52",
        "27.905",
        "-10.571",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "52",
        "27.757",
        "-10.804",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "52",
        "27.608",
        "-11.034",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "52",
        "27.454",
        "-11.259",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "52",
        "27.297",
        "-11.482",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "52",
        "27.139",
        "-11.702",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "52",
        "26.975",
        "-11.917",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "52",
        "26.810",
        "-12.129",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "52",
        "26.643",
        "-12.338",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "52",
        "26.471",
        "-12.542",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "52",
        "26.297",
        "-12.742",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "52",
        "26.121",
        "-12.940",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "52",
        "25.941",
        "-13.133",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "52",
        "25.759",
        "-13.321",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "52",
        "25.575",
        "-13.506",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "52",
        "25.388",
        "-13.687",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "52",
        "25.199",
        "-13.864",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "52",
        "25.006",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "52",
        "24.813",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "52",
        "24.616",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "52",
        "24.415",
        "-14.526",
        "0.197",
        "0.254",
        "0.008"
    ],
    [
        "132",
        "52",
        "24.183",
        "-14.644",
        "0.906",
        "0.935",
        "0.016"
    ],
    [
        "133",
        "52",
        "23.935",
        "-14.751",
        "0.917",
        "1.014",
        "0.010"
    ],
    [
        "134",
        "52",
        "23.670",
        "-14.854",
        "0.934",
        "1.022",
        "-0.001"
    ],
    [
        "135",
        "52",
        "23.382",
        "-14.943",
        "0.982",
        "0.991",
        "-0.009"
    ],
    [
        "136",
        "52",
        "23.046",
        "-14.984",
        "0.873",
        "0.986",
        "-0.020"
    ],
    [
        "137",
        "52",
        "22.591",
        "-14.820",
        "0.585",
        "0.613",
        "0.003"
    ],
    [
        "138",
        "52",
        "22.239",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "52",
        "22.033",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "52",
        "21.825",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "52",
        "21.616",
        "-15.340",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "52",
        "20.369",
        "-15.119",
        "0.555",
        "0.545",
        "0.159"
    ],
    [
        "143",
        "52",
        "17.657",
        "-14.260",
        "0.749",
        "0.760",
        "0.157"
    ],
    [
        "144",
        "52",
        "15.118",
        "-13.742",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "52",
        "14.926",
        "-14.299",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "52",
        "14.725",
        "-14.850",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "52",
        "14.517",
        "-15.397",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "52",
        "14.302",
        "-15.939",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "52",
        "14.079",
        "-16.477",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "52",
        "13.848",
        "-17.010",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "52",
        "13.611",
        "-17.537",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "52",
        "13.366",
        "-18.060",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "52",
        "13.114",
        "-18.575",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "52",
        "12.855",
        "-19.087",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "52",
        "12.589",
        "-19.592",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "52",
        "12.316",
        "-20.091",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "52",
        "8.530",
        "-17.792",
        "3.020",
        "2.860",
        "-0.622"
    ],
    [
        "158",
        "52",
        "-2.678",
        "-9.377",
        "5.674",
        "4.454",
        "-0.544"
    ],
    [
        "159",
        "52",
        "-14.325",
        "-1.178",
        "7.771",
        "6.131",
        "-0.624"
    ],
    [
        "160",
        "52",
        "-27.795",
        "8.187",
        "8.072",
        "6.559",
        "-0.583"
    ],
    [
        "161",
        "52",
        "-41.331",
        "17.237",
        "7.549",
        "5.986",
        "-0.581"
    ],
    [
        "162",
        "52",
        "-54.375",
        "25.081",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "52",
        "-54.028",
        "25.391",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "52",
        "-53.677",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "52",
        "-53.321",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "52",
        "-52.962",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "52",
        "-52.599",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "52",
        "-52.232",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "52",
        "-51.861",
        "27.082",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "52",
        "-51.487",
        "27.336",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "52",
        "-51.109",
        "27.581",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "52",
        "-43.147",
        "18.564",
        "6.325",
        "7.571",
        "0.073"
    ],
    [
        "173",
        "52",
        "-32.324",
        "9.817",
        "7.140",
        "8.688",
        "0.017"
    ],
    [
        "174",
        "52",
        "-22.345",
        "4.611",
        "7.077",
        "9.784",
        "0.076"
    ],
    [
        "175",
        "52",
        "-16.180",
        "0.185",
        "5.682",
        "9.120",
        "-0.116"
    ],
    [
        "176",
        "52",
        "-8.564",
        "-5.371",
        "4.845",
        "8.709",
        "-0.194"
    ],
    [
        "177",
        "52",
        "-0.665",
        "-17.787",
        "3.939",
        "6.346",
        "-0.156"
    ],
    [
        "178",
        "52",
        "3.117",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "52",
        "2.808",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "53",
        "2.537",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "53",
        "2.222",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "53",
        "1.906",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "53",
        "1.590",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "53",
        "1.273",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "53",
        "0.955",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "53",
        "0.638",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "53",
        "0.320",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "53",
        "0.002",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "53",
        "-0.316",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "53",
        "-0.634",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "53",
        "-0.951",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "53",
        "-7.661",
        "-15.091",
        "6.951",
        "7.347",
        "-0.484"
    ],
    [
        "-167",
        "53",
        "-14.710",
        "-6.701",
        "7.448",
        "9.267",
        "-0.441"
    ],
    [
        "-166",
        "53",
        "-17.865",
        "1.173",
        "7.484",
        "9.459",
        "-0.411"
    ],
    [
        "-165",
        "53",
        "-23.035",
        "5.740",
        "7.628",
        "9.281",
        "-0.417"
    ],
    [
        "-164",
        "53",
        "-28.288",
        "12.944",
        "6.642",
        "9.108",
        "-0.494"
    ],
    [
        "-163",
        "53",
        "-32.848",
        "21.487",
        "5.879",
        "8.460",
        "-0.454"
    ],
    [
        "-162",
        "53",
        "-36.193",
        "26.532",
        "4.723",
        "7.824",
        "-0.397"
    ],
    [
        "-161",
        "53",
        "-38.719",
        "30.834",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "53",
        "-38.290",
        "30.816",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "53",
        "-37.860",
        "30.789",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "53",
        "-37.431",
        "30.752",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "53",
        "-37.003",
        "30.706",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "53",
        "-36.575",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "53",
        "-36.149",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "53",
        "-35.723",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "53",
        "-35.298",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "53",
        "-34.874",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "53",
        "-34.452",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "53",
        "-34.032",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "53",
        "-33.613",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "53",
        "-33.195",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "53",
        "-32.780",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "53",
        "-32.366",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "53",
        "-31.955",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "53",
        "-31.546",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "53",
        "-31.139",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "53",
        "-30.735",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "53",
        "-30.333",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "53",
        "-29.935",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "53",
        "-29.539",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "53",
        "-29.146",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "53",
        "-28.756",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "53",
        "-28.369",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "53",
        "-27.986",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "53",
        "-27.606",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "53",
        "-26.312",
        "28.026",
        "1.582",
        "2.871",
        "0.108"
    ],
    [
        "-132",
        "53",
        "-15.258",
        "-7.188",
        "1.928",
        "2.276",
        "0.100"
    ],
    [
        "-131",
        "53",
        "-17.317",
        "-8.794",
        "8.130",
        "10.997",
        "0.047"
    ],
    [
        "-130",
        "53",
        "-13.441",
        "-16.521",
        "5.635",
        "8.080",
        "-0.089"
    ],
    [
        "-129",
        "53",
        "-12.463",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "53",
        "-12.695",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "53",
        "-12.924",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "53",
        "-13.148",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "53",
        "-13.370",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "53",
        "-13.585",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "53",
        "-13.799",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "53",
        "-14.007",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "53",
        "-14.211",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "53",
        "-14.411",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "53",
        "-14.606",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "53",
        "-14.798",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "53",
        "-14.984",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "53",
        "-15.167",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "53",
        "-15.344",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "53",
        "-15.517",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "53",
        "-15.686",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "53",
        "-15.850",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "53",
        "-16.009",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "53",
        "-16.162",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "53",
        "-16.311",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "53",
        "-16.456",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "53",
        "-16.595",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "53",
        "-16.729",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "53",
        "-16.858",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "53",
        "-16.982",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "53",
        "-17.101",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "53",
        "-17.214",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "53",
        "-17.323",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "53",
        "-17.427",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "53",
        "-17.525",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "53",
        "-17.617",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "53",
        "-17.705",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "53",
        "-17.787",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "53",
        "-17.863",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "53",
        "-17.935",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "53",
        "-18.001",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "53",
        "-18.061",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "53",
        "-18.116",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "53",
        "-18.165",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "53",
        "-18.210",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "53",
        "-18.248",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "53",
        "-18.281",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "53",
        "-18.309",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "53",
        "-18.331",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "53",
        "-18.347",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "53",
        "-18.358",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "53",
        "-18.364",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "53",
        "-18.363",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "53",
        "-18.357",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "53",
        "-18.346",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "53",
        "-18.329",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "53",
        "-18.306",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "53",
        "-18.279",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "53",
        "-18.246",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "53",
        "-18.206",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "53",
        "-18.162",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "53",
        "-18.112",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "53",
        "-18.056",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "53",
        "-17.995",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "53",
        "-17.929",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "53",
        "-17.857",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "53",
        "-17.780",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "53",
        "-17.697",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "53",
        "-17.610",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "53",
        "-17.516",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "53",
        "-17.418",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "53",
        "-17.315",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "53",
        "-17.205",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "53",
        "-17.091",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "53",
        "-16.972",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "53",
        "-16.847",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "53",
        "-16.718",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "53",
        "-16.583",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "53",
        "-16.444",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "53",
        "-16.299",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "53",
        "-16.149",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "53",
        "-15.995",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "53",
        "-15.836",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "53",
        "-15.671",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "53",
        "-15.503",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "53",
        "-15.330",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "53",
        "-15.152",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "53",
        "-14.969",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "53",
        "-14.783",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "53",
        "-14.591",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "53",
        "-14.395",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "53",
        "-14.194",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "53",
        "-13.990",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "53",
        "-13.781",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "53",
        "-13.568",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "53",
        "-13.350",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "53",
        "-13.129",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "53",
        "-12.905",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "53",
        "-7.705",
        "17.214",
        "3.704",
        "2.417",
        "0.128"
    ],
    [
        "-34",
        "53",
        "9.149",
        "16.009",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "53",
        "9.373",
        "16.128",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "53",
        "9.598",
        "16.242",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "53",
        "9.826",
        "16.351",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "53",
        "10.055",
        "16.456",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "53",
        "10.285",
        "16.554",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "53",
        "10.516",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "53",
        "10.748",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "53",
        "10.982",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "53",
        "11.217",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "53",
        "11.453",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "53",
        "11.690",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "53",
        "11.928",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "53",
        "12.168",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "53",
        "12.407",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "53",
        "12.648",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "53",
        "12.888",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "53",
        "13.130",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "53",
        "13.372",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "53",
        "13.614",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "53",
        "13.857",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "53",
        "14.100",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "53",
        "14.344",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "53",
        "14.586",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "53",
        "14.830",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "53",
        "15.073",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "53",
        "15.316",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "53",
        "15.560",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "53",
        "15.802",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "53",
        "16.045",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "53",
        "16.286",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "53",
        "16.528",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "53",
        "16.769",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "53",
        "17.009",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "53",
        "17.250",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "53",
        "17.488",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "53",
        "17.726",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "53",
        "17.963",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "53",
        "18.199",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "53",
        "18.435",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "53",
        "18.668",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "53",
        "18.900",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "53",
        "19.132",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "53",
        "19.363",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "53",
        "19.591",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "53",
        "19.818",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "53",
        "20.044",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "53",
        "20.268",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "53",
        "20.490",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "53",
        "20.711",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "53",
        "20.930",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "53",
        "21.146",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "53",
        "21.361",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "53",
        "21.574",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "53",
        "21.785",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "53",
        "21.993",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "53",
        "22.199",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "53",
        "22.404",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "53",
        "22.605",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "53",
        "22.805",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "53",
        "23.002",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "53",
        "23.196",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "53",
        "23.388",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "53",
        "23.578",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "53",
        "23.764",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "53",
        "23.948",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "53",
        "24.128",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "53",
        "24.306",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "53",
        "24.482",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "53",
        "24.653",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "53",
        "24.822",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "53",
        "24.988",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "53",
        "25.151",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "53",
        "25.311",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "53",
        "25.468",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "53",
        "25.621",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "53",
        "25.771",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "53",
        "25.917",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "53",
        "26.060",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "53",
        "26.200",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "53",
        "26.336",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "53",
        "26.469",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "53",
        "26.597",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "53",
        "26.723",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "53",
        "26.844",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "53",
        "26.962",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "53",
        "27.076",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "53",
        "27.187",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "53",
        "27.294",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "53",
        "27.397",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "53",
        "27.496",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "53",
        "27.591",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "53",
        "27.682",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "53",
        "27.769",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "53",
        "27.852",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "53",
        "27.932",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "53",
        "28.007",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "53",
        "28.078",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "53",
        "28.145",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "53",
        "28.209",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "53",
        "28.267",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "53",
        "28.322",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "53",
        "28.373",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "53",
        "28.419",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "53",
        "28.462",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "53",
        "28.499",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "53",
        "28.533",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "53",
        "28.563",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "53",
        "28.589",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "53",
        "28.610",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "53",
        "28.627",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "53",
        "28.640",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "53",
        "28.648",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "53",
        "28.652",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "53",
        "28.653",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "53",
        "28.648",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "53",
        "28.640",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "53",
        "28.628",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "53",
        "28.610",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "53",
        "28.590",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "53",
        "28.564",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "53",
        "28.534",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "53",
        "28.501",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "53",
        "28.462",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "53",
        "28.420",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "53",
        "28.374",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "53",
        "28.323",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "53",
        "28.268",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "53",
        "28.209",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "53",
        "28.147",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "53",
        "28.080",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "53",
        "28.008",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "53",
        "27.934",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "53",
        "27.854",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "53",
        "27.771",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "53",
        "27.683",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "53",
        "27.593",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "53",
        "27.498",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "53",
        "27.399",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "53",
        "27.296",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "53",
        "27.156",
        "-7.750",
        "0.766",
        "0.910",
        "-0.059"
    ],
    [
        "107",
        "53",
        "27.406",
        "-8.554",
        "0.809",
        "0.955",
        "-0.052"
    ],
    [
        "108",
        "53",
        "27.644",
        "-9.280",
        "0.832",
        "0.848",
        "0.005"
    ],
    [
        "109",
        "53",
        "27.863",
        "-9.981",
        "0.776",
        "0.904",
        "-0.044"
    ],
    [
        "110",
        "53",
        "27.803",
        "-10.335",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "53",
        "27.658",
        "-10.571",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "53",
        "27.508",
        "-10.804",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "53",
        "27.356",
        "-11.034",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "53",
        "27.202",
        "-11.259",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "53",
        "27.043",
        "-11.482",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "53",
        "26.881",
        "-11.702",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "53",
        "26.717",
        "-11.917",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "53",
        "26.549",
        "-12.129",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "53",
        "26.378",
        "-12.338",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "53",
        "26.205",
        "-12.542",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "53",
        "26.029",
        "-12.742",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "53",
        "25.849",
        "-12.940",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "53",
        "25.668",
        "-13.133",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "53",
        "25.484",
        "-13.321",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "53",
        "25.297",
        "-13.506",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "53",
        "25.107",
        "-13.687",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "53",
        "24.915",
        "-13.864",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "53",
        "24.721",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "53",
        "24.524",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "53",
        "24.325",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "53",
        "24.117",
        "-14.523",
        "0.198",
        "0.253",
        "-0.001"
    ],
    [
        "132",
        "53",
        "23.833",
        "-14.565",
        "0.927",
        "0.972",
        "0.001"
    ],
    [
        "133",
        "53",
        "23.533",
        "-14.614",
        "0.966",
        "1.081",
        "-0.001"
    ],
    [
        "134",
        "53",
        "23.224",
        "-14.676",
        "0.993",
        "1.095",
        "-0.005"
    ],
    [
        "135",
        "53",
        "22.903",
        "-14.733",
        "1.029",
        "1.059",
        "-0.006"
    ],
    [
        "136",
        "53",
        "22.569",
        "-14.761",
        "0.895",
        "1.024",
        "-0.005"
    ],
    [
        "137",
        "53",
        "22.239",
        "-14.754",
        "0.574",
        "0.600",
        "0.000"
    ],
    [
        "138",
        "53",
        "21.997",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "53",
        "21.789",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "53",
        "21.578",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "53",
        "21.366",
        "-15.340",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "53",
        "20.318",
        "-15.271",
        "0.537",
        "0.529",
        "0.154"
    ],
    [
        "143",
        "53",
        "17.880",
        "-14.373",
        "0.736",
        "0.761",
        "0.144"
    ],
    [
        "144",
        "53",
        "15.685",
        "-13.742",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "53",
        "15.489",
        "-14.299",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "53",
        "15.286",
        "-14.850",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "53",
        "15.075",
        "-15.397",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "53",
        "14.857",
        "-15.939",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "53",
        "14.631",
        "-16.477",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "53",
        "14.398",
        "-17.010",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "53",
        "14.156",
        "-17.537",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "53",
        "13.909",
        "-18.060",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "53",
        "13.653",
        "-18.575",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "53",
        "13.391",
        "-19.087",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "53",
        "13.122",
        "-19.592",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "53",
        "12.845",
        "-20.091",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "53",
        "12.439",
        "-20.560",
        "1.823",
        "1.923",
        "-0.510"
    ],
    [
        "158",
        "53",
        "5.099",
        "-16.077",
        "3.012",
        "3.063",
        "-0.488"
    ],
    [
        "159",
        "53",
        "-4.746",
        "-8.338",
        "4.423",
        "4.093",
        "-0.586"
    ],
    [
        "160",
        "53",
        "-11.670",
        "-2.323",
        "6.984",
        "5.912",
        "-0.597"
    ],
    [
        "161",
        "53",
        "-24.005",
        "7.017",
        "7.754",
        "6.312",
        "-0.563"
    ],
    [
        "162",
        "53",
        "-36.959",
        "15.872",
        "8.489",
        "6.429",
        "-0.579"
    ],
    [
        "163",
        "53",
        "-48.555",
        "22.626",
        "6.819",
        "6.001",
        "-0.521"
    ],
    [
        "164",
        "53",
        "-52.953",
        "25.693",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "53",
        "-52.593",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "53",
        "-52.229",
        "26.273",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "53",
        "-51.861",
        "26.551",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "53",
        "-51.489",
        "26.820",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "53",
        "-47.140",
        "24.199",
        "4.555",
        "5.746",
        "-0.010"
    ],
    [
        "170",
        "53",
        "-34.508",
        "12.233",
        "5.813",
        "6.969",
        "-0.316"
    ],
    [
        "171",
        "53",
        "-21.432",
        "2.704",
        "6.683",
        "7.427",
        "-0.130"
    ],
    [
        "172",
        "53",
        "-12.135",
        "-5.560",
        "5.612",
        "7.162",
        "-0.053"
    ],
    [
        "173",
        "53",
        "-7.093",
        "-13.984",
        "3.781",
        "4.973",
        "0.117"
    ],
    [
        "174",
        "53",
        "4.406",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "53",
        "4.097",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "53",
        "3.787",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "53",
        "3.476",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "53",
        "3.164",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "53",
        "2.851",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "54",
        "2.575",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "54",
        "2.256",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "54",
        "1.935",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "54",
        "1.616",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "54",
        "1.294",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "54",
        "0.973",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "54",
        "0.651",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "54",
        "0.330",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "54",
        "0.007",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "54",
        "-0.315",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "54",
        "-0.636",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "54",
        "-0.959",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "54",
        "-1.618",
        "-22.606",
        "6.434",
        "5.708",
        "-0.522"
    ],
    [
        "-167",
        "54",
        "-1.601",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "54",
        "-1.923",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "54",
        "-2.243",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "54",
        "-9.568",
        "-13.528",
        "5.685",
        "7.818",
        "-0.433"
    ],
    [
        "-163",
        "54",
        "-13.389",
        "-6.014",
        "5.881",
        "9.385",
        "-0.436"
    ],
    [
        "-162",
        "54",
        "-17.495",
        "1.099",
        "6.146",
        "10.092",
        "-0.422"
    ],
    [
        "-161",
        "54",
        "-21.296",
        "6.847",
        "5.958",
        "10.484",
        "-0.375"
    ],
    [
        "-160",
        "54",
        "-24.792",
        "12.047",
        "6.051",
        "10.595",
        "-0.338"
    ],
    [
        "-159",
        "54",
        "-28.349",
        "17.142",
        "6.668",
        "10.521",
        "-0.363"
    ],
    [
        "-158",
        "54",
        "-32.309",
        "22.821",
        "6.159",
        "9.471",
        "-0.411"
    ],
    [
        "-157",
        "54",
        "-34.936",
        "28.571",
        "4.400",
        "6.213",
        "-0.555"
    ],
    [
        "-156",
        "54",
        "-35.623",
        "30.650",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "54",
        "-35.190",
        "30.586",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "54",
        "-34.759",
        "30.512",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "54",
        "-34.329",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "54",
        "-33.900",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "54",
        "-33.472",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "54",
        "-33.046",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "54",
        "-32.622",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "54",
        "-32.199",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "54",
        "-31.778",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "54",
        "-31.359",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "54",
        "-30.943",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "54",
        "-30.528",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "54",
        "-30.116",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "54",
        "-29.707",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "54",
        "-29.300",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "54",
        "-28.896",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "54",
        "-28.495",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "54",
        "-28.096",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "54",
        "-27.702",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "54",
        "-27.310",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "54",
        "-26.921",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "54",
        "-26.537",
        "27.129",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "54",
        "-18.718",
        "7.873",
        "7.247",
        "9.575",
        "0.063"
    ],
    [
        "-132",
        "54",
        "-9.827",
        "-9.194",
        "3.572",
        "5.164",
        "-0.137"
    ],
    [
        "-131",
        "54",
        "-11.214",
        "-14.421",
        "9.278",
        "12.690",
        "0.054"
    ],
    [
        "-130",
        "54",
        "-12.380",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "54",
        "-12.620",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "54",
        "-12.855",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "54",
        "-13.086",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "54",
        "-13.315",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "54",
        "-13.537",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "54",
        "-13.758",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "54",
        "-13.973",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "54",
        "-14.184",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "54",
        "-14.391",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "54",
        "-14.594",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "54",
        "-14.792",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "54",
        "-14.985",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "54",
        "-15.174",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "54",
        "-15.358",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "54",
        "-15.539",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "54",
        "-15.714",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "54",
        "-15.885",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "54",
        "-16.050",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "54",
        "-16.211",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "54",
        "-16.367",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "54",
        "-16.518",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "54",
        "-16.664",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "54",
        "-16.805",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "54",
        "-16.941",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "54",
        "-17.072",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "54",
        "-17.197",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "54",
        "-17.318",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "54",
        "-17.433",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "54",
        "-17.543",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "54",
        "-17.648",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "54",
        "-17.747",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "54",
        "-17.841",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "54",
        "-17.930",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "54",
        "-18.013",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "54",
        "-18.090",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "54",
        "-18.162",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "54",
        "-18.230",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "54",
        "-18.291",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "54",
        "-18.346",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "54",
        "-18.396",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "54",
        "-18.441",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "54",
        "-18.481",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "54",
        "-18.513",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "54",
        "-18.541",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "54",
        "-18.564",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "54",
        "-18.580",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "54",
        "-18.592",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "54",
        "-18.597",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "54",
        "-18.596",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "54",
        "-18.591",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "54",
        "-18.579",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "54",
        "-18.562",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "54",
        "-18.539",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "54",
        "-18.511",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "54",
        "-18.477",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "54",
        "-18.438",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "54",
        "-18.393",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "54",
        "-18.342",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "54",
        "-18.286",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "54",
        "-18.224",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "54",
        "-18.157",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "54",
        "-18.084",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "54",
        "-18.007",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "54",
        "-17.922",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "54",
        "-17.834",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "54",
        "-17.739",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "54",
        "-17.639",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "54",
        "-17.534",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "54",
        "-17.423",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "54",
        "-17.308",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "54",
        "-17.188",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "54",
        "-17.061",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "54",
        "-16.929",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "54",
        "-16.794",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "54",
        "-16.652",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "54",
        "-16.505",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "54",
        "-16.354",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "54",
        "-16.198",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "54",
        "-16.036",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "54",
        "-15.871",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "54",
        "-15.699",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "54",
        "-15.524",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "54",
        "-15.343",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "54",
        "-15.158",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "54",
        "-14.970",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "54",
        "-14.775",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "54",
        "-14.577",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "54",
        "-14.374",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "54",
        "-14.167",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "54",
        "-13.955",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "54",
        "-13.740",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "54",
        "-13.520",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "54",
        "-13.296",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "54",
        "-13.067",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "54",
        "6.328",
        "16.007",
        "1.401",
        "1.142",
        "0.083"
    ],
    [
        "-34",
        "54",
        "8.734",
        "16.009",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "54",
        "8.960",
        "16.128",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "54",
        "9.189",
        "16.242",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "54",
        "9.420",
        "16.351",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "54",
        "9.651",
        "16.456",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "54",
        "9.884",
        "16.554",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "54",
        "10.118",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "54",
        "10.354",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "54",
        "10.591",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "54",
        "10.829",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "54",
        "11.069",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "54",
        "11.308",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "54",
        "11.549",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "54",
        "11.791",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "54",
        "12.035",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "54",
        "12.278",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "54",
        "12.522",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "54",
        "12.767",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "54",
        "13.011",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "54",
        "13.257",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "54",
        "13.503",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "54",
        "13.749",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "54",
        "13.996",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "54",
        "14.242",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "54",
        "14.489",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "54",
        "14.735",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "54",
        "14.981",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "54",
        "15.228",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "54",
        "15.473",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "54",
        "15.719",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "54",
        "15.964",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "54",
        "16.209",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "54",
        "16.453",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "54",
        "16.696",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "54",
        "16.939",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "54",
        "17.181",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "54",
        "17.422",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "54",
        "17.663",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "54",
        "17.901",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "54",
        "18.139",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "54",
        "18.376",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "54",
        "18.612",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "54",
        "18.847",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "54",
        "19.080",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "54",
        "19.312",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "54",
        "19.542",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "54",
        "19.770",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "54",
        "19.997",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "54",
        "20.223",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "54",
        "20.446",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "54",
        "20.668",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "54",
        "20.887",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "54",
        "21.105",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "54",
        "21.320",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "54",
        "21.534",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "54",
        "21.745",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "54",
        "21.955",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "54",
        "22.161",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "54",
        "22.366",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "54",
        "22.567",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "54",
        "22.767",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "54",
        "22.964",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "54",
        "23.158",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "54",
        "23.350",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "54",
        "23.539",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "54",
        "23.724",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "54",
        "23.908",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "54",
        "24.088",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "54",
        "24.265",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "54",
        "24.439",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "54",
        "24.611",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "54",
        "24.779",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "54",
        "24.944",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "54",
        "25.106",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "54",
        "25.264",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "54",
        "25.419",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "54",
        "25.571",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "54",
        "25.719",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "54",
        "25.864",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "54",
        "26.006",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "54",
        "26.144",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "54",
        "26.278",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "54",
        "26.409",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "54",
        "26.536",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "54",
        "26.659",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "54",
        "26.779",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "54",
        "26.894",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "54",
        "27.006",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "54",
        "27.114",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "54",
        "27.219",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "54",
        "27.319",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "54",
        "27.416",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "54",
        "27.508",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "54",
        "27.596",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "54",
        "27.680",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "54",
        "27.760",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "54",
        "27.837",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "54",
        "27.909",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "54",
        "27.977",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "54",
        "28.041",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "54",
        "28.101",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "54",
        "28.156",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "54",
        "28.207",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "54",
        "28.254",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "54",
        "28.298",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "54",
        "28.336",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "54",
        "28.371",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "54",
        "28.400",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "54",
        "28.426",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "54",
        "28.447",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "54",
        "28.465",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "54",
        "28.478",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "54",
        "28.487",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "54",
        "28.491",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "54",
        "28.491",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "54",
        "28.487",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "54",
        "28.478",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "54",
        "28.465",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "54",
        "28.449",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "54",
        "28.427",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "54",
        "28.401",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "54",
        "28.371",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "54",
        "28.337",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "54",
        "28.298",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "54",
        "28.255",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "54",
        "28.208",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "54",
        "28.157",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "54",
        "28.102",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "54",
        "28.042",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "54",
        "27.978",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "54",
        "27.910",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "54",
        "27.838",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "54",
        "27.762",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "54",
        "27.682",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "54",
        "27.598",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "54",
        "27.510",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "54",
        "27.417",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "54",
        "27.321",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "54",
        "27.221",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "54",
        "27.117",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "54",
        "27.009",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "54",
        "26.896",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "54",
        "26.913",
        "-8.525",
        "0.852",
        "0.835",
        "-0.073"
    ],
    [
        "109",
        "54",
        "27.108",
        "-9.288",
        "0.847",
        "0.976",
        "0.005"
    ],
    [
        "110",
        "54",
        "27.284",
        "-9.924",
        "0.850",
        "0.935",
        "0.012"
    ],
    [
        "111",
        "54",
        "27.402",
        "-10.571",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "54",
        "27.252",
        "-10.804",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "54",
        "27.097",
        "-11.034",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "54",
        "26.940",
        "-11.259",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "54",
        "26.779",
        "-11.482",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "54",
        "26.615",
        "-11.702",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "54",
        "26.449",
        "-11.917",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "54",
        "26.280",
        "-12.129",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "54",
        "26.106",
        "-12.338",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "54",
        "25.931",
        "-12.542",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "54",
        "25.752",
        "-12.742",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "54",
        "25.571",
        "-12.940",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "54",
        "25.387",
        "-13.133",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "54",
        "25.200",
        "-13.321",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "54",
        "25.011",
        "-13.506",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "54",
        "24.819",
        "-13.687",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "54",
        "24.624",
        "-13.864",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "54",
        "24.427",
        "-14.036",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "54",
        "24.228",
        "-14.205",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "54",
        "24.026",
        "-14.368",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "54",
        "23.800",
        "-14.510",
        "0.203",
        "0.258",
        "0.021"
    ],
    [
        "132",
        "54",
        "23.423",
        "-14.398",
        "0.939",
        "0.983",
        "-0.027"
    ],
    [
        "133",
        "54",
        "23.106",
        "-14.418",
        "0.965",
        "1.073",
        "-0.023"
    ],
    [
        "134",
        "54",
        "22.804",
        "-14.483",
        "0.979",
        "1.075",
        "-0.017"
    ],
    [
        "135",
        "54",
        "22.513",
        "-14.561",
        "1.008",
        "1.027",
        "-0.013"
    ],
    [
        "136",
        "54",
        "22.232",
        "-14.642",
        "0.873",
        "0.989",
        "0.005"
    ],
    [
        "137",
        "54",
        "21.972",
        "-14.733",
        "0.568",
        "0.594",
        "0.008"
    ],
    [
        "138",
        "54",
        "21.750",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "54",
        "21.538",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "54",
        "21.325",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "54",
        "21.109",
        "-15.340",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "54",
        "20.164",
        "-15.280",
        "0.535",
        "0.540",
        "0.151"
    ],
    [
        "143",
        "54",
        "18.038",
        "-14.526",
        "0.737",
        "0.791",
        "0.125"
    ],
    [
        "144",
        "54",
        "16.247",
        "-13.742",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "54",
        "16.048",
        "-14.299",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "54",
        "15.843",
        "-14.850",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "54",
        "15.630",
        "-15.397",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "54",
        "15.408",
        "-15.939",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "54",
        "15.179",
        "-16.477",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "54",
        "14.943",
        "-17.010",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "54",
        "14.699",
        "-17.537",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "54",
        "14.448",
        "-18.060",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "54",
        "14.189",
        "-18.575",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "54",
        "13.923",
        "-19.087",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "54",
        "13.650",
        "-19.592",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "54",
        "13.370",
        "-20.091",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "54",
        "13.082",
        "-20.583",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "54",
        "12.682",
        "-20.969",
        "0.726",
        "0.923",
        "-0.403"
    ],
    [
        "159",
        "54",
        "7.993",
        "-17.242",
        "4.013",
        "3.389",
        "-0.585"
    ],
    [
        "160",
        "54",
        "-0.806",
        "-11.068",
        "5.251",
        "4.650",
        "-0.570"
    ],
    [
        "161",
        "54",
        "-11.193",
        "-3.122",
        "6.655",
        "5.881",
        "-0.558"
    ],
    [
        "162",
        "54",
        "-23.360",
        "5.883",
        "8.403",
        "7.068",
        "-0.577"
    ],
    [
        "163",
        "54",
        "-35.741",
        "14.144",
        "8.326",
        "7.707",
        "-0.498"
    ],
    [
        "164",
        "54",
        "-47.681",
        "22.303",
        "5.983",
        "5.210",
        "-0.508"
    ],
    [
        "165",
        "54",
        "-51.849",
        "25.987",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "54",
        "-50.304",
        "25.059",
        "3.663",
        "3.956",
        "-0.021"
    ],
    [
        "167",
        "54",
        "-41.325",
        "16.994",
        "5.445",
        "5.922",
        "-0.280"
    ],
    [
        "168",
        "54",
        "-26.057",
        "5.286",
        "6.717",
        "6.205",
        "-0.391"
    ],
    [
        "169",
        "54",
        "-12.480",
        "-4.363",
        "5.920",
        "6.567",
        "-0.376"
    ],
    [
        "170",
        "54",
        "-0.758",
        "-15.888",
        "4.697",
        "6.014",
        "-0.078"
    ],
    [
        "171",
        "54",
        "4.041",
        "-20.689",
        "4.331",
        "5.084",
        "0.094"
    ],
    [
        "172",
        "54",
        "5.089",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "54",
        "4.779",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "54",
        "4.468",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "54",
        "4.155",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "54",
        "3.842",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "54",
        "3.526",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "54",
        "3.210",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "54",
        "2.893",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "55",
        "2.612",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "55",
        "2.289",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "55",
        "1.965",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "55",
        "1.641",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "55",
        "1.316",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "55",
        "0.991",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "55",
        "0.665",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "55",
        "0.339",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "55",
        "0.013",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "55",
        "-0.314",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "55",
        "-0.639",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "55",
        "-0.966",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "55",
        "-1.291",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "55",
        "-1.617",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "55",
        "-1.941",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "55",
        "-2.266",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "55",
        "-2.590",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "55",
        "-4.791",
        "-21.620",
        "0.639",
        "0.907",
        "-0.527"
    ],
    [
        "-162",
        "55",
        "-9.282",
        "-15.027",
        "4.459",
        "6.847",
        "-0.341"
    ],
    [
        "-161",
        "55",
        "-9.954",
        "-13.695",
        "5.104",
        "8.197",
        "-0.445"
    ],
    [
        "-160",
        "55",
        "-12.897",
        "-8.719",
        "5.844",
        "9.571",
        "-0.467"
    ],
    [
        "-159",
        "55",
        "-16.378",
        "-3.195",
        "6.614",
        "10.309",
        "-0.473"
    ],
    [
        "-158",
        "55",
        "-20.808",
        "4.558",
        "6.891",
        "10.423",
        "-0.451"
    ],
    [
        "-157",
        "55",
        "-25.694",
        "14.423",
        "7.008",
        "9.971",
        "-0.469"
    ],
    [
        "-156",
        "55",
        "-29.359",
        "22.406",
        "6.894",
        "9.458",
        "-0.523"
    ],
    [
        "-155",
        "55",
        "-31.534",
        "26.146",
        "5.472",
        "8.376",
        "-0.510"
    ],
    [
        "-154",
        "55",
        "-33.197",
        "29.657",
        "2.885",
        "4.423",
        "-0.438"
    ],
    [
        "-153",
        "55",
        "-33.349",
        "30.428",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "55",
        "-32.915",
        "30.336",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "55",
        "-32.482",
        "30.234",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "55",
        "-32.050",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "55",
        "-31.621",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "55",
        "-31.193",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "55",
        "-30.766",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "55",
        "-30.342",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "55",
        "-29.921",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "55",
        "-29.501",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "55",
        "-29.084",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "55",
        "-28.669",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "55",
        "-28.257",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "55",
        "-27.848",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "55",
        "-27.442",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "55",
        "-27.039",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "55",
        "-26.639",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "55",
        "-26.242",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "55",
        "-25.849",
        "27.381",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "55",
        "-19.532",
        "11.003",
        "7.186",
        "9.481",
        "-0.089"
    ],
    [
        "-133",
        "55",
        "-12.284",
        "-7.319",
        "8.437",
        "11.145",
        "-0.080"
    ],
    [
        "-132",
        "55",
        "-10.257",
        "-12.428",
        "8.291",
        "9.987",
        "-0.064"
    ],
    [
        "-131",
        "55",
        "-12.191",
        "-16.697",
        "1.400",
        "4.158",
        "0.289"
    ],
    [
        "-130",
        "55",
        "-12.531",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "55",
        "-12.772",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "55",
        "-13.011",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "55",
        "-13.246",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "55",
        "-13.476",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "55",
        "-13.702",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "55",
        "-13.924",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "55",
        "-14.143",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "55",
        "-14.357",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "55",
        "-14.565",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "55",
        "-14.771",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "55",
        "-14.971",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "55",
        "-15.168",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "55",
        "-15.359",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "55",
        "-15.547",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "55",
        "-15.728",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "55",
        "-15.906",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "55",
        "-16.079",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "55",
        "-16.246",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "55",
        "-16.409",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "55",
        "-16.567",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "55",
        "-16.720",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "55",
        "-16.868",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "55",
        "-17.011",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "55",
        "-17.148",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "55",
        "-17.281",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "55",
        "-17.407",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "55",
        "-17.530",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "55",
        "-17.647",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "55",
        "-17.758",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "55",
        "-17.864",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "55",
        "-17.964",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "55",
        "-18.060",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "55",
        "-18.149",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "55",
        "-18.233",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "55",
        "-18.312",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "55",
        "-18.385",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "55",
        "-18.452",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "55",
        "-18.515",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "55",
        "-18.571",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "55",
        "-18.622",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "55",
        "-18.667",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "55",
        "-18.707",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "55",
        "-18.740",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "55",
        "-18.769",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "55",
        "-18.791",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "55",
        "-18.808",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "55",
        "-18.819",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "55",
        "-18.825",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "55",
        "-18.824",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "55",
        "-18.819",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "55",
        "-18.806",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "55",
        "-18.790",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "55",
        "-18.766",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "55",
        "-18.738",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "55",
        "-18.703",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "55",
        "-18.663",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "55",
        "-18.618",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "55",
        "-18.567",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "55",
        "-18.510",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "55",
        "-18.448",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "55",
        "-18.379",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "55",
        "-18.305",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "55",
        "-18.227",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "55",
        "-18.142",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "55",
        "-18.051",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "55",
        "-17.956",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "55",
        "-17.855",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "55",
        "-17.749",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "55",
        "-17.637",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "55",
        "-17.520",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "55",
        "-17.397",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "55",
        "-17.270",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "55",
        "-17.137",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "55",
        "-16.998",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "55",
        "-16.856",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "55",
        "-16.707",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "55",
        "-16.554",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "55",
        "-16.395",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "55",
        "-16.233",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "55",
        "-16.064",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "55",
        "-15.891",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "55",
        "-15.714",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "55",
        "-15.530",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "55",
        "-15.343",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "55",
        "-15.152",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "55",
        "-14.955",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "55",
        "-14.754",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "55",
        "-14.549",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "55",
        "-14.339",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "55",
        "-14.125",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "55",
        "-13.906",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "55",
        "-13.683",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "55",
        "-13.457",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "55",
        "-13.226",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "55",
        "2.623",
        "16.060",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "55",
        "8.316",
        "16.009",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "55",
        "8.545",
        "16.128",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "55",
        "8.777",
        "16.242",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "55",
        "9.009",
        "16.351",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "55",
        "9.244",
        "16.456",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "55",
        "9.481",
        "16.554",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "55",
        "9.718",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "55",
        "9.957",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "55",
        "10.196",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "55",
        "10.438",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "55",
        "10.680",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "55",
        "10.922",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "55",
        "11.167",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "55",
        "11.412",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "55",
        "11.658",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "55",
        "11.904",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "55",
        "12.152",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "55",
        "12.399",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "55",
        "12.648",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "55",
        "12.896",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "55",
        "13.145",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "55",
        "13.394",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "55",
        "13.643",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "55",
        "13.893",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "55",
        "14.143",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "55",
        "14.393",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "55",
        "14.642",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "55",
        "14.891",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "55",
        "15.140",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "55",
        "15.389",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "55",
        "15.636",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "55",
        "15.885",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "55",
        "16.132",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "55",
        "16.378",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "55",
        "16.624",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "55",
        "16.870",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "55",
        "17.113",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "55",
        "17.356",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "55",
        "17.598",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "55",
        "17.840",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "55",
        "18.080",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "55",
        "18.318",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "55",
        "18.556",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "55",
        "18.791",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "55",
        "19.026",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "55",
        "19.259",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "55",
        "19.491",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "55",
        "19.721",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "55",
        "19.949",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "55",
        "20.174",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "55",
        "20.399",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "55",
        "20.622",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "55",
        "20.842",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "55",
        "21.060",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "55",
        "21.276",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "55",
        "21.490",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "55",
        "21.702",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "55",
        "21.912",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "55",
        "22.118",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "55",
        "22.322",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "55",
        "22.525",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "55",
        "22.724",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "55",
        "22.921",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "55",
        "23.115",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "55",
        "23.306",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "55",
        "23.494",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "55",
        "23.680",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "55",
        "23.862",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "55",
        "24.042",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "55",
        "24.218",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "55",
        "24.392",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "55",
        "24.562",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "55",
        "24.729",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "55",
        "24.893",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "55",
        "25.054",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "55",
        "25.211",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "55",
        "25.364",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "55",
        "25.515",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "55",
        "25.661",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "55",
        "25.805",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "55",
        "25.944",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "55",
        "26.080",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "55",
        "26.213",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "55",
        "26.341",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "55",
        "26.466",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "55",
        "26.587",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "55",
        "26.704",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "55",
        "26.817",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "55",
        "26.927",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "55",
        "27.033",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "55",
        "27.134",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "55",
        "27.232",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "55",
        "27.325",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "55",
        "27.414",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "55",
        "27.500",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "55",
        "27.581",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "55",
        "27.658",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "55",
        "27.731",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "55",
        "27.800",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "55",
        "27.865",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "55",
        "27.925",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "55",
        "27.982",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "55",
        "28.034",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "55",
        "28.081",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "55",
        "28.124",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "55",
        "28.164",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "55",
        "28.199",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "55",
        "28.229",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "55",
        "28.255",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "55",
        "28.276",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "55",
        "28.294",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "55",
        "28.307",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "55",
        "28.316",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "55",
        "28.321",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "55",
        "28.320",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "55",
        "28.316",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "55",
        "28.308",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "55",
        "28.295",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "55",
        "28.278",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "55",
        "28.256",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "55",
        "28.230",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "55",
        "28.199",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "55",
        "28.165",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "55",
        "28.125",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "55",
        "28.082",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "55",
        "28.035",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "55",
        "27.983",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "55",
        "27.927",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "55",
        "27.866",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "55",
        "27.802",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "55",
        "27.733",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "55",
        "27.659",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "55",
        "27.583",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "55",
        "27.501",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "55",
        "27.416",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "55",
        "27.327",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "55",
        "27.233",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "55",
        "27.136",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "55",
        "27.035",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "55",
        "26.929",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "55",
        "26.820",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "55",
        "26.706",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "55",
        "26.590",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "55",
        "26.473",
        "-8.636",
        "0.613",
        "0.608",
        "-0.023"
    ],
    [
        "110",
        "55",
        "26.510",
        "-9.164",
        "0.895",
        "0.989",
        "0.011"
    ],
    [
        "111",
        "55",
        "26.687",
        "-9.819",
        "0.938",
        "0.963",
        "0.033"
    ],
    [
        "112",
        "55",
        "26.727",
        "-10.363",
        "0.807",
        "0.967",
        "-0.010"
    ],
    [
        "113",
        "55",
        "26.681",
        "-10.799",
        "0.789",
        "0.947",
        "-0.018"
    ],
    [
        "114",
        "55",
        "26.616",
        "-11.185",
        "0.818",
        "0.796",
        "-0.041"
    ],
    [
        "115",
        "55",
        "26.508",
        "-11.482",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "55",
        "26.343",
        "-11.702",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "55",
        "26.174",
        "-11.917",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "55",
        "26.002",
        "-12.129",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "55",
        "25.826",
        "-12.338",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "55",
        "25.649",
        "-12.542",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "55",
        "25.468",
        "-12.742",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "55",
        "25.285",
        "-12.940",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "55",
        "25.098",
        "-13.133",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "55",
        "24.909",
        "-13.321",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "55",
        "24.714",
        "-13.502",
        "0.196",
        "0.250",
        "0.011"
    ],
    [
        "126",
        "55",
        "24.479",
        "-13.563",
        "0.907",
        "0.948",
        "0.007"
    ],
    [
        "127",
        "55",
        "24.236",
        "-13.658",
        "0.923",
        "1.023",
        "-0.002"
    ],
    [
        "128",
        "55",
        "23.991",
        "-13.768",
        "0.938",
        "1.019",
        "-0.016"
    ],
    [
        "129",
        "55",
        "23.739",
        "-13.874",
        "0.986",
        "0.979",
        "-0.023"
    ],
    [
        "130",
        "55",
        "23.466",
        "-13.963",
        "0.914",
        "0.979",
        "-0.028"
    ],
    [
        "131",
        "55",
        "23.181",
        "-14.033",
        "0.908",
        "0.979",
        "-0.031"
    ],
    [
        "132",
        "55",
        "22.898",
        "-14.103",
        "0.954",
        "0.967",
        "-0.035"
    ],
    [
        "133",
        "55",
        "22.630",
        "-14.180",
        "0.844",
        "0.952",
        "0.007"
    ],
    [
        "134",
        "55",
        "22.378",
        "-14.295",
        "0.791",
        "0.928",
        "-0.006"
    ],
    [
        "135",
        "55",
        "22.142",
        "-14.420",
        "0.794",
        "0.808",
        "0.017"
    ],
    [
        "136",
        "55",
        "21.916",
        "-14.556",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "55",
        "21.706",
        "-14.722",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "55",
        "21.495",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "55",
        "21.281",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "55",
        "21.064",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "55",
        "20.846",
        "-15.340",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "55",
        "20.239",
        "-15.325",
        "0.544",
        "0.565",
        "0.132"
    ],
    [
        "143",
        "55",
        "18.918",
        "-14.845",
        "0.760",
        "0.865",
        "0.062"
    ],
    [
        "144",
        "55",
        "17.603",
        "-14.282",
        "0.822",
        "0.795",
        "0.057"
    ],
    [
        "145",
        "55",
        "16.603",
        "-14.299",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "55",
        "16.395",
        "-14.850",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "55",
        "16.178",
        "-15.397",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "55",
        "15.954",
        "-15.939",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "55",
        "15.722",
        "-16.477",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "55",
        "15.483",
        "-17.010",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "55",
        "15.236",
        "-17.537",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "55",
        "14.982",
        "-18.060",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "55",
        "14.720",
        "-18.575",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "55",
        "14.451",
        "-19.087",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "55",
        "14.174",
        "-19.592",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "55",
        "13.890",
        "-20.091",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "55",
        "13.599",
        "-20.583",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "55",
        "13.302",
        "-21.071",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "55",
        "12.997",
        "-21.551",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "55",
        "10.112",
        "-19.764",
        "2.966",
        "2.734",
        "-0.571"
    ],
    [
        "161",
        "55",
        "0.657",
        "-12.898",
        "5.478",
        "4.982",
        "-0.568"
    ],
    [
        "162",
        "55",
        "-9.616",
        "-5.796",
        "7.606",
        "6.860",
        "-0.601"
    ],
    [
        "163",
        "55",
        "-19.983",
        "1.164",
        "8.121",
        "8.189",
        "-0.550"
    ],
    [
        "164",
        "55",
        "-27.203",
        "5.597",
        "8.141",
        "8.637",
        "-0.497"
    ],
    [
        "165",
        "55",
        "-26.433",
        "3.436",
        "7.894",
        "7.873",
        "-0.430"
    ],
    [
        "166",
        "55",
        "-17.053",
        "-3.420",
        "6.856",
        "6.819",
        "-0.465"
    ],
    [
        "167",
        "55",
        "-3.684",
        "-11.736",
        "5.754",
        "5.999",
        "-0.361"
    ],
    [
        "168",
        "55",
        "5.791",
        "-19.584",
        "4.673",
        "4.600",
        "0.077"
    ],
    [
        "169",
        "55",
        "6.089",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "55",
        "5.781",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "55",
        "5.470",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "55",
        "5.158",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "55",
        "4.844",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "55",
        "4.529",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "55",
        "4.213",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "55",
        "3.895",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "55",
        "3.576",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "55",
        "3.256",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "55",
        "2.934",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "56",
        "2.648",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "56",
        "2.322",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "56",
        "1.994",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "56",
        "1.666",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "56",
        "1.336",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "56",
        "1.007",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "56",
        "0.678",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "56",
        "0.347",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "56",
        "0.018",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "56",
        "-0.312",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "56",
        "-0.643",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "56",
        "-0.972",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "56",
        "-1.302",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "56",
        "-1.632",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "56",
        "-1.960",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "56",
        "-2.288",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "56",
        "-2.616",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "56",
        "-2.943",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "56",
        "-3.268",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "56",
        "-3.594",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "56",
        "-3.918",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "56",
        "-4.240",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "56",
        "-9.265",
        "-15.133",
        "5.437",
        "8.629",
        "-0.436"
    ],
    [
        "-157",
        "56",
        "-14.450",
        "-4.634",
        "6.105",
        "10.175",
        "-0.423"
    ],
    [
        "-156",
        "56",
        "-18.980",
        "10.755",
        "5.410",
        "7.508",
        "-0.390"
    ],
    [
        "-155",
        "56",
        "-23.382",
        "17.429",
        "4.753",
        "8.171",
        "-0.367"
    ],
    [
        "-154",
        "56",
        "-26.994",
        "20.665",
        "4.265",
        "7.109",
        "-0.419"
    ],
    [
        "-153",
        "56",
        "-29.292",
        "24.697",
        "3.173",
        "4.988",
        "-0.478"
    ],
    [
        "-152",
        "56",
        "-30.554",
        "28.040",
        "1.917",
        "2.946",
        "-0.496"
    ],
    [
        "-151",
        "56",
        "-31.213",
        "29.817",
        "0.872",
        "1.221",
        "-0.420"
    ],
    [
        "-150",
        "56",
        "-31.045",
        "30.123",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "56",
        "-30.610",
        "30.002",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "56",
        "-30.177",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "56",
        "-29.746",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "56",
        "-29.316",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "56",
        "-28.889",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "56",
        "-28.465",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "56",
        "-28.043",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "56",
        "-27.623",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "56",
        "-27.206",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "56",
        "-26.792",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "56",
        "-26.381",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "56",
        "-25.973",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "56",
        "-25.568",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "56",
        "-25.167",
        "27.625",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "56",
        "-18.475",
        "11.009",
        "8.425",
        "8.908",
        "0.019"
    ],
    [
        "-134",
        "56",
        "-12.782",
        "-7.125",
        "9.395",
        "11.525",
        "-0.045"
    ],
    [
        "-133",
        "56",
        "-11.460",
        "-15.998",
        "6.428",
        "7.901",
        "-0.106"
    ],
    [
        "-132",
        "56",
        "-12.175",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "56",
        "-12.427",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "56",
        "-12.676",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "56",
        "-12.922",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "56",
        "-13.163",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "56",
        "-13.400",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "56",
        "-13.634",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "56",
        "-13.863",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "56",
        "-14.088",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "56",
        "-14.309",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "56",
        "-14.524",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "56",
        "-14.737",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "56",
        "-14.944",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "56",
        "-15.147",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "56",
        "-15.345",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "56",
        "-15.539",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "56",
        "-15.729",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "56",
        "-15.913",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "56",
        "-16.093",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "56",
        "-16.267",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "56",
        "-16.437",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "56",
        "-16.602",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "56",
        "-16.761",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "56",
        "-16.916",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "56",
        "-17.066",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "56",
        "-17.211",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "56",
        "-17.350",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "56",
        "-17.484",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "56",
        "-17.613",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "56",
        "-17.736",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "56",
        "-17.855",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "56",
        "-17.967",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "56",
        "-18.075",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "56",
        "-18.176",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "56",
        "-18.272",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "56",
        "-18.363",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "56",
        "-18.449",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "56",
        "-18.528",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "56",
        "-18.602",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "56",
        "-18.670",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "56",
        "-18.733",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "56",
        "-18.790",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "56",
        "-18.841",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "56",
        "-18.887",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "56",
        "-18.927",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "56",
        "-18.961",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "56",
        "-18.990",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "56",
        "-19.013",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "56",
        "-19.030",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "56",
        "-19.041",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "56",
        "-19.047",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "56",
        "-19.046",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "56",
        "-19.041",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "56",
        "-19.029",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "56",
        "-19.011",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "56",
        "-18.988",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "56",
        "-18.959",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "56",
        "-18.924",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "56",
        "-18.883",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "56",
        "-18.838",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "56",
        "-18.785",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "56",
        "-18.728",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "56",
        "-18.665",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "56",
        "-18.596",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "56",
        "-18.521",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "56",
        "-18.441",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "56",
        "-18.355",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "56",
        "-18.264",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "56",
        "-18.167",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "56",
        "-18.066",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "56",
        "-17.957",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "56",
        "-17.845",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "56",
        "-17.726",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "56",
        "-17.603",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "56",
        "-17.473",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "56",
        "-17.339",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "56",
        "-17.199",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "56",
        "-17.054",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "56",
        "-16.904",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "56",
        "-16.749",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "56",
        "-16.589",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "56",
        "-16.423",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "56",
        "-16.252",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "56",
        "-16.078",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "56",
        "-15.898",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "56",
        "-15.713",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "56",
        "-15.523",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "56",
        "-15.329",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "56",
        "-15.130",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "56",
        "-14.927",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "56",
        "-14.719",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "56",
        "-14.506",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "56",
        "-14.290",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "56",
        "-14.069",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "56",
        "-13.844",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "56",
        "-13.614",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "56",
        "-13.381",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "56",
        "-13.143",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "56",
        "7.895",
        "16.009",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "56",
        "8.128",
        "16.128",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "56",
        "8.362",
        "16.242",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "56",
        "8.598",
        "16.351",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "56",
        "8.835",
        "16.456",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "56",
        "9.074",
        "16.554",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "56",
        "9.315",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "56",
        "9.556",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "56",
        "9.799",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "56",
        "10.042",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "56",
        "10.288",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "56",
        "10.534",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "56",
        "10.781",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "56",
        "11.029",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "56",
        "11.278",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "56",
        "11.527",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "56",
        "11.777",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "56",
        "12.028",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "56",
        "12.279",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "56",
        "12.530",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "56",
        "12.783",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "56",
        "13.035",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "56",
        "13.288",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "56",
        "13.540",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "56",
        "13.792",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "56",
        "14.045",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "56",
        "14.298",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "56",
        "14.550",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "56",
        "14.802",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "56",
        "15.054",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "56",
        "15.304",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "56",
        "15.555",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "56",
        "15.806",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "56",
        "16.055",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "56",
        "16.304",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "56",
        "16.551",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "56",
        "16.799",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "56",
        "17.045",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "56",
        "17.290",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "56",
        "17.534",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "56",
        "17.777",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "56",
        "18.019",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "56",
        "18.259",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "56",
        "18.498",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "56",
        "18.735",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "56",
        "18.971",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "56",
        "19.205",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "56",
        "19.438",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "56",
        "19.669",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "56",
        "19.897",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "56",
        "20.124",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "56",
        "20.350",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "56",
        "20.573",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "56",
        "20.793",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "56",
        "21.012",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "56",
        "21.229",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "56",
        "21.443",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "56",
        "21.655",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "56",
        "21.865",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "56",
        "22.072",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "56",
        "22.276",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "56",
        "22.478",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "56",
        "22.676",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "56",
        "22.873",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "56",
        "23.066",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "56",
        "23.257",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "56",
        "23.445",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "56",
        "23.630",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "56",
        "23.812",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "56",
        "23.990",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "56",
        "24.165",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "56",
        "24.338",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "56",
        "24.507",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "56",
        "24.672",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "56",
        "24.835",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "56",
        "24.994",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "56",
        "25.149",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "56",
        "25.302",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "56",
        "25.450",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "56",
        "25.595",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "56",
        "25.737",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "56",
        "25.874",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "56",
        "26.008",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "56",
        "26.138",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "56",
        "26.265",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "56",
        "26.387",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "56",
        "26.506",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "56",
        "26.621",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "56",
        "26.731",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "56",
        "26.838",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "56",
        "26.940",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "56",
        "27.039",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "56",
        "27.134",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "56",
        "27.224",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "56",
        "27.311",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "56",
        "27.393",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "56",
        "27.472",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "56",
        "27.546",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "56",
        "27.615",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "56",
        "27.680",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "56",
        "27.741",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "56",
        "27.799",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "56",
        "27.851",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "56",
        "27.899",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "56",
        "27.943",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "56",
        "27.983",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "56",
        "28.018",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "56",
        "28.049",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "56",
        "28.075",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "56",
        "28.097",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "56",
        "28.115",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "56",
        "28.128",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "56",
        "28.137",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "56",
        "28.141",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "56",
        "28.142",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "56",
        "28.137",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "56",
        "28.128",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "56",
        "28.115",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "56",
        "28.098",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "56",
        "28.076",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "56",
        "28.049",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "56",
        "28.019",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "56",
        "27.983",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "56",
        "27.944",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "56",
        "27.900",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "56",
        "27.852",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "56",
        "27.800",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "56",
        "27.743",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "56",
        "27.682",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "56",
        "27.616",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "56",
        "27.546",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "56",
        "27.473",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "56",
        "27.395",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "56",
        "27.313",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "56",
        "27.226",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "56",
        "27.136",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "56",
        "27.042",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "56",
        "26.943",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "56",
        "26.840",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "56",
        "26.733",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "56",
        "26.623",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "56",
        "26.508",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "56",
        "26.390",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "56",
        "26.249",
        "-8.581",
        "0.562",
        "0.594",
        "-0.098"
    ],
    [
        "110",
        "56",
        "26.096",
        "-8.835",
        "0.785",
        "0.942",
        "-0.043"
    ],
    [
        "111",
        "56",
        "26.054",
        "-9.220",
        "0.881",
        "0.938",
        "-0.016"
    ],
    [
        "112",
        "56",
        "26.100",
        "-9.737",
        "0.804",
        "0.961",
        "-0.003"
    ],
    [
        "113",
        "56",
        "26.015",
        "-10.109",
        "0.795",
        "0.951",
        "-0.002"
    ],
    [
        "114",
        "56",
        "25.929",
        "-10.448",
        "0.854",
        "0.909",
        "0.047"
    ],
    [
        "115",
        "56",
        "25.840",
        "-10.744",
        "0.769",
        "0.930",
        "0.011"
    ],
    [
        "116",
        "56",
        "25.669",
        "-10.972",
        "0.776",
        "0.943",
        "-0.004"
    ],
    [
        "117",
        "56",
        "25.504",
        "-11.292",
        "0.858",
        "0.926",
        "-0.010"
    ],
    [
        "118",
        "56",
        "25.460",
        "-11.743",
        "0.789",
        "0.955",
        "-0.002"
    ],
    [
        "119",
        "56",
        "25.440",
        "-12.185",
        "0.797",
        "0.960",
        "0.002"
    ],
    [
        "120",
        "56",
        "25.353",
        "-12.507",
        "0.876",
        "0.939",
        "-0.008"
    ],
    [
        "121",
        "56",
        "25.181",
        "-12.687",
        "0.792",
        "0.958",
        "-0.013"
    ],
    [
        "122",
        "56",
        "24.951",
        "-12.783",
        "0.761",
        "0.910",
        "-0.058"
    ],
    [
        "123",
        "56",
        "24.720",
        "-12.913",
        "0.802",
        "0.836",
        "-0.082"
    ],
    [
        "124",
        "56",
        "24.493",
        "-13.095",
        "0.797",
        "0.902",
        "-0.051"
    ],
    [
        "125",
        "56",
        "24.245",
        "-13.235",
        "0.875",
        "0.984",
        "-0.010"
    ],
    [
        "126",
        "56",
        "23.982",
        "-13.299",
        "1.025",
        "1.066",
        "-0.042"
    ],
    [
        "127",
        "56",
        "23.731",
        "-13.371",
        "0.994",
        "1.106",
        "-0.038"
    ],
    [
        "128",
        "56",
        "23.465",
        "-13.440",
        "0.953",
        "1.051",
        "-0.031"
    ],
    [
        "129",
        "56",
        "23.188",
        "-13.499",
        "0.899",
        "0.927",
        "-0.006"
    ],
    [
        "130",
        "56",
        "22.890",
        "-13.483",
        "0.202",
        "0.261",
        "-0.008"
    ],
    [
        "131",
        "56",
        "22.681",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "56",
        "22.481",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "56",
        "22.279",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "56",
        "22.075",
        "-14.211",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "56",
        "21.868",
        "-14.386",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "56",
        "21.659",
        "-14.556",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "56",
        "21.447",
        "-14.722",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "56",
        "21.234",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "56",
        "21.017",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "56",
        "20.798",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "56",
        "20.577",
        "-15.340",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "56",
        "20.354",
        "-15.484",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "56",
        "19.960",
        "-15.564",
        "0.215",
        "0.276",
        "0.041"
    ],
    [
        "144",
        "56",
        "18.180",
        "-14.420",
        "0.866",
        "0.867",
        "0.027"
    ],
    [
        "145",
        "56",
        "17.152",
        "-14.299",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "56",
        "16.941",
        "-14.850",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "56",
        "16.723",
        "-15.397",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "56",
        "16.496",
        "-15.939",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "56",
        "16.261",
        "-16.477",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "56",
        "16.018",
        "-17.010",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "56",
        "15.769",
        "-17.537",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "56",
        "15.512",
        "-18.060",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "56",
        "15.246",
        "-18.575",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "56",
        "14.973",
        "-19.087",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "56",
        "14.694",
        "-19.592",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "56",
        "14.406",
        "-20.091",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "56",
        "14.113",
        "-20.583",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "56",
        "13.811",
        "-21.071",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "56",
        "13.503",
        "-21.551",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "56",
        "13.188",
        "-22.024",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "56",
        "10.139",
        "-20.353",
        "3.788",
        "3.712",
        "-0.568"
    ],
    [
        "162",
        "56",
        "4.245",
        "-17.171",
        "6.120",
        "5.685",
        "-0.596"
    ],
    [
        "163",
        "56",
        "-0.439",
        "-14.788",
        "6.840",
        "6.976",
        "-0.581"
    ],
    [
        "164",
        "56",
        "-0.613",
        "-15.261",
        "6.721",
        "7.078",
        "-0.576"
    ],
    [
        "165",
        "56",
        "4.289",
        "-19.814",
        "5.518",
        "5.477",
        "-0.285"
    ],
    [
        "166",
        "56",
        "7.092",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "56",
        "6.785",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "56",
        "6.477",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "56",
        "6.167",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "56",
        "5.855",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "56",
        "5.540",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "56",
        "5.225",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "56",
        "4.907",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "56",
        "4.589",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "56",
        "4.268",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "56",
        "3.947",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "56",
        "3.624",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "56",
        "3.299",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "56",
        "2.975",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "57",
        "2.685",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "57",
        "2.354",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "57",
        "2.022",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "57",
        "1.690",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "57",
        "1.358",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "57",
        "1.024",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "57",
        "0.691",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "57",
        "0.357",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "57",
        "0.023",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "57",
        "-0.311",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "57",
        "-0.645",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "57",
        "-0.979",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "57",
        "-1.312",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "57",
        "-1.645",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "57",
        "-1.978",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "57",
        "-2.310",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "57",
        "-2.641",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "57",
        "-2.972",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "57",
        "-3.302",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "57",
        "-3.631",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "57",
        "-3.959",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "57",
        "-4.285",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "57",
        "-4.610",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "57",
        "-5.459",
        "-20.981",
        "1.156",
        "2.910",
        "-0.312"
    ],
    [
        "-156",
        "57",
        "-10.237",
        "-9.076",
        "5.538",
        "10.523",
        "-0.360"
    ],
    [
        "-155",
        "57",
        "-13.345",
        "-3.008",
        "4.922",
        "9.556",
        "-0.296"
    ],
    [
        "-154",
        "57",
        "-17.545",
        "2.504",
        "2.362",
        "3.973",
        "-0.279"
    ],
    [
        "-153",
        "57",
        "-21.210",
        "9.081",
        "3.321",
        "5.171",
        "-0.340"
    ],
    [
        "-152",
        "57",
        "-26.283",
        "19.780",
        "2.252",
        "3.263",
        "-0.340"
    ],
    [
        "-151",
        "57",
        "-28.188",
        "24.966",
        "1.578",
        "2.161",
        "-0.208"
    ],
    [
        "-150",
        "57",
        "-28.971",
        "26.916",
        "1.233",
        "1.615",
        "-0.013"
    ],
    [
        "-149",
        "57",
        "-29.030",
        "27.975",
        "0.935",
        "1.316",
        "0.038"
    ],
    [
        "-148",
        "57",
        "-29.152",
        "29.873",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "57",
        "-28.716",
        "29.735",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "57",
        "-28.281",
        "29.587",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "57",
        "-27.850",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "57",
        "-27.420",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "57",
        "-26.993",
        "29.090",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "57",
        "-26.568",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "57",
        "-26.147",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "57",
        "-25.728",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "57",
        "-25.312",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "57",
        "-24.899",
        "28.086",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "57",
        "-24.490",
        "27.860",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "57",
        "-21.276",
        "9.422",
        "7.777",
        "7.930",
        "0.349"
    ],
    [
        "-135",
        "57",
        "-14.630",
        "-10.104",
        "10.998",
        "10.693",
        "0.106"
    ],
    [
        "-134",
        "57",
        "-12.117",
        "-15.141",
        "11.687",
        "11.752",
        "0.181"
    ],
    [
        "-133",
        "57",
        "-12.052",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "57",
        "-12.312",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "57",
        "-12.567",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "57",
        "-12.819",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "57",
        "-13.067",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "57",
        "-13.311",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "57",
        "-13.551",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "57",
        "-13.786",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "57",
        "-14.018",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "57",
        "-14.246",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "57",
        "-14.470",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "57",
        "-14.689",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "57",
        "-14.902",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "57",
        "-15.112",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "57",
        "-15.318",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "57",
        "-15.518",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "57",
        "-15.715",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "57",
        "-15.907",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "57",
        "-16.093",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "57",
        "-16.275",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "57",
        "-16.451",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "57",
        "-16.623",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "57",
        "-16.789",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "57",
        "-16.952",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "57",
        "-17.108",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "57",
        "-17.260",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "57",
        "-17.406",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "57",
        "-17.546",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "57",
        "-17.683",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "57",
        "-17.813",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "57",
        "-17.938",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "57",
        "-18.056",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "57",
        "-18.171",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "57",
        "-18.279",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "57",
        "-18.383",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "57",
        "-18.480",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "57",
        "-18.572",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "57",
        "-18.657",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "57",
        "-18.738",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "57",
        "-18.813",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "57",
        "-18.882",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "57",
        "-18.946",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "57",
        "-19.003",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "57",
        "-19.055",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "57",
        "-19.101",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "57",
        "-19.142",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "57",
        "-19.177",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "57",
        "-19.206",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "57",
        "-19.229",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "57",
        "-19.246",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "57",
        "-19.257",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "57",
        "-19.263",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "57",
        "-19.263",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "57",
        "-19.256",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "57",
        "-19.244",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "57",
        "-19.227",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "57",
        "-19.203",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "57",
        "-19.174",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "57",
        "-19.139",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "57",
        "-19.098",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "57",
        "-19.051",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "57",
        "-18.999",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "57",
        "-18.940",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "57",
        "-18.876",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "57",
        "-18.807",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "57",
        "-18.732",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "57",
        "-18.651",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "57",
        "-18.564",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "57",
        "-18.471",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "57",
        "-18.374",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "57",
        "-18.270",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "57",
        "-18.161",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "57",
        "-18.047",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "57",
        "-17.927",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "57",
        "-17.802",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "57",
        "-17.671",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "57",
        "-17.535",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "57",
        "-17.394",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "57",
        "-17.247",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "57",
        "-17.095",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "57",
        "-16.939",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "57",
        "-16.776",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "57",
        "-16.609",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "57",
        "-16.437",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "57",
        "-16.260",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "57",
        "-16.078",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "57",
        "-15.891",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "57",
        "-15.699",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "57",
        "-15.502",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "57",
        "-15.302",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "57",
        "-15.096",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "57",
        "-14.885",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "57",
        "-14.670",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "57",
        "-14.450",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "57",
        "-14.228",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "57",
        "-14.000",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "57",
        "-13.767",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "57",
        "-13.531",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "57",
        "-13.291",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "57",
        "-11.059",
        "16.492",
        "1.048",
        "0.936",
        "-0.241"
    ],
    [
        "-33",
        "57",
        "6.588",
        "16.039",
        "3.757",
        "2.482",
        "-0.455"
    ],
    [
        "-32",
        "57",
        "7.945",
        "16.242",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "57",
        "8.183",
        "16.351",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "57",
        "8.423",
        "16.456",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "57",
        "8.665",
        "16.554",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "57",
        "8.908",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "57",
        "9.152",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "57",
        "9.398",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "57",
        "9.645",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "57",
        "9.892",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "57",
        "10.142",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "57",
        "10.392",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "57",
        "10.642",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "57",
        "10.894",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "57",
        "11.147",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "57",
        "11.400",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "57",
        "11.653",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "57",
        "11.908",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "57",
        "12.161",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "57",
        "12.417",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "57",
        "12.672",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "57",
        "12.928",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "57",
        "13.183",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "57",
        "13.438",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "57",
        "13.694",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "57",
        "13.949",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "57",
        "14.205",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "57",
        "14.459",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "57",
        "14.714",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "57",
        "14.968",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "57",
        "15.222",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "57",
        "15.475",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "57",
        "15.727",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "57",
        "15.979",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "57",
        "16.229",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "57",
        "16.480",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "57",
        "16.729",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "57",
        "16.977",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "57",
        "17.223",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "57",
        "17.469",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "57",
        "17.714",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "57",
        "17.956",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "57",
        "18.198",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "57",
        "18.438",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "57",
        "18.676",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "57",
        "18.913",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "57",
        "19.149",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "57",
        "19.382",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "57",
        "19.614",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "57",
        "19.843",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "57",
        "20.071",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "57",
        "20.298",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "57",
        "20.520",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "57",
        "20.742",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "57",
        "20.961",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "57",
        "21.178",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "57",
        "21.392",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "57",
        "21.604",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "57",
        "21.814",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "57",
        "22.020",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "57",
        "22.224",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "57",
        "22.426",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "57",
        "22.624",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "57",
        "22.820",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "57",
        "23.013",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "57",
        "23.202",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "57",
        "23.390",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "57",
        "23.574",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "57",
        "23.754",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "57",
        "23.931",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "57",
        "24.106",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "57",
        "24.277",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "57",
        "24.445",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "57",
        "24.609",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "57",
        "24.770",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "57",
        "24.928",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "57",
        "25.081",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "57",
        "25.231",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "57",
        "25.378",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "57",
        "25.520",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "57",
        "25.660",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "57",
        "25.796",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "57",
        "25.928",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "57",
        "26.055",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "57",
        "26.179",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "57",
        "26.299",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "57",
        "26.415",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "57",
        "26.527",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "57",
        "26.635",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "57",
        "26.739",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "57",
        "26.839",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "57",
        "26.935",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "57",
        "27.027",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "57",
        "27.114",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "57",
        "27.197",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "57",
        "27.276",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "57",
        "27.351",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "57",
        "27.421",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "57",
        "27.488",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "57",
        "27.549",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "57",
        "27.607",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "57",
        "27.660",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "57",
        "27.709",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "57",
        "27.753",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "57",
        "27.793",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "57",
        "27.829",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "57",
        "27.860",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "57",
        "27.887",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "57",
        "27.909",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "57",
        "27.927",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "57",
        "27.940",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "57",
        "27.950",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "57",
        "27.954",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "57",
        "27.954",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "57",
        "27.950",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "57",
        "27.940",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "57",
        "27.927",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "57",
        "27.910",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "57",
        "27.888",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "57",
        "27.861",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "57",
        "27.829",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "57",
        "27.794",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "57",
        "27.754",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "57",
        "27.709",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "57",
        "27.661",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "57",
        "27.608",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "57",
        "27.550",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "57",
        "27.489",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "57",
        "27.423",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "57",
        "27.352",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "57",
        "27.278",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "57",
        "27.198",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "57",
        "27.115",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "57",
        "27.029",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "57",
        "26.937",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "57",
        "26.841",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "57",
        "26.741",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "57",
        "26.638",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "57",
        "26.529",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "57",
        "26.417",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "57",
        "26.301",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "57",
        "26.182",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "57",
        "26.058",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "57",
        "25.930",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "57",
        "25.799",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "57",
        "25.662",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "57",
        "25.524",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "57",
        "25.381",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "57",
        "25.234",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "57",
        "25.084",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "57",
        "24.931",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "57",
        "24.785",
        "-10.910",
        "0.613",
        "0.603",
        "-0.013"
    ],
    [
        "119",
        "57",
        "24.716",
        "-11.309",
        "0.825",
        "0.950",
        "-0.024"
    ],
    [
        "120",
        "57",
        "24.707",
        "-11.821",
        "0.882",
        "0.945",
        "-0.013"
    ],
    [
        "121",
        "57",
        "24.561",
        "-12.178",
        "0.803",
        "0.962",
        "0.000"
    ],
    [
        "122",
        "57",
        "24.308",
        "-12.220",
        "0.792",
        "0.946",
        "-0.015"
    ],
    [
        "123",
        "57",
        "24.086",
        "-12.336",
        "0.848",
        "0.876",
        "-0.075"
    ],
    [
        "124",
        "57",
        "23.878",
        "-12.559",
        "0.874",
        "0.981",
        "-0.034"
    ],
    [
        "125",
        "57",
        "23.673",
        "-12.783",
        "0.962",
        "1.101",
        "-0.009"
    ],
    [
        "126",
        "57",
        "23.467",
        "-12.956",
        "1.088",
        "1.137",
        "-0.009"
    ],
    [
        "127",
        "57",
        "23.244",
        "-13.042",
        "0.915",
        "1.061",
        "-0.003"
    ],
    [
        "128",
        "57",
        "23.017",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "57",
        "22.824",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "57",
        "22.628",
        "-13.469",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "57",
        "22.430",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "57",
        "22.228",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "57",
        "22.024",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "57",
        "21.817",
        "-14.211",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "57",
        "21.608",
        "-14.386",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "57",
        "21.396",
        "-14.556",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "57",
        "21.182",
        "-14.722",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "57",
        "20.965",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "57",
        "20.746",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "57",
        "20.525",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "57",
        "20.301",
        "-15.340",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "57",
        "20.076",
        "-15.484",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "57",
        "19.745",
        "-15.578",
        "0.238",
        "0.302",
        "0.022"
    ],
    [
        "144",
        "57",
        "18.643",
        "-14.852",
        "1.030",
        "1.094",
        "0.010"
    ],
    [
        "145",
        "57",
        "17.977",
        "-14.616",
        "0.910",
        "1.071",
        "-0.008"
    ],
    [
        "146",
        "57",
        "17.483",
        "-14.850",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "57",
        "17.261",
        "-15.397",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "57",
        "17.032",
        "-15.939",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "57",
        "16.795",
        "-16.477",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "57",
        "16.550",
        "-17.010",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "57",
        "16.296",
        "-17.537",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "57",
        "16.037",
        "-18.060",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "57",
        "15.768",
        "-18.575",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "57",
        "15.493",
        "-19.087",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "57",
        "15.209",
        "-19.592",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "57",
        "14.919",
        "-20.091",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "57",
        "14.621",
        "-20.583",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "57",
        "14.317",
        "-21.071",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "57",
        "14.004",
        "-21.551",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "57",
        "13.686",
        "-22.024",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "57",
        "13.360",
        "-22.492",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "57",
        "11.688",
        "-22.198",
        "3.825",
        "3.414",
        "-0.510"
    ],
    [
        "163",
        "57",
        "9.835",
        "-21.858",
        "4.290",
        "4.621",
        "-0.493"
    ],
    [
        "164",
        "57",
        "8.280",
        "-21.213",
        "2.936",
        "3.042",
        "-0.326"
    ],
    [
        "165",
        "57",
        "7.487",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "57",
        "7.180",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "57",
        "6.869",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "57",
        "6.558",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "57",
        "6.244",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "57",
        "5.928",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "57",
        "5.611",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "57",
        "5.291",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "57",
        "4.970",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "57",
        "4.647",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "57",
        "4.323",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "57",
        "3.998",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "57",
        "3.671",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "57",
        "3.343",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "57",
        "3.014",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "58",
        "2.719",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "58",
        "2.385",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "58",
        "2.050",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "58",
        "1.714",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "58",
        "1.378",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "58",
        "1.040",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "58",
        "0.704",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "58",
        "0.366",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "58",
        "0.028",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "58",
        "-0.309",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "58",
        "-0.647",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "58",
        "-0.984",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "58",
        "-1.321",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "58",
        "-1.659",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "58",
        "-1.995",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "58",
        "-2.330",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "58",
        "-2.666",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "58",
        "-3.000",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "58",
        "-3.333",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "58",
        "-3.666",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "58",
        "-3.998",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "58",
        "-4.328",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "58",
        "-4.657",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "58",
        "-5.072",
        "-21.626",
        "1.140",
        "2.756",
        "-0.157"
    ],
    [
        "-156",
        "58",
        "-6.133",
        "-15.845",
        "5.639",
        "9.363",
        "-0.216"
    ],
    [
        "-155",
        "58",
        "-7.015",
        "-14.242",
        "3.586",
        "7.287",
        "-0.239"
    ],
    [
        "-154",
        "58",
        "-14.105",
        "-6.334",
        "5.446",
        "8.624",
        "-0.207"
    ],
    [
        "-153",
        "58",
        "-21.099",
        "1.042",
        "4.659",
        "6.552",
        "-0.249"
    ],
    [
        "-152",
        "58",
        "-23.926",
        "5.760",
        "2.587",
        "3.158",
        "-0.244"
    ],
    [
        "-151",
        "58",
        "-26.745",
        "18.576",
        "1.824",
        "2.270",
        "-0.162"
    ],
    [
        "-150",
        "58",
        "-27.299",
        "21.708",
        "1.521",
        "1.804",
        "0.003"
    ],
    [
        "-149",
        "58",
        "-26.343",
        "21.219",
        "1.357",
        "1.738",
        "0.049"
    ],
    [
        "-148",
        "58",
        "-25.366",
        "21.077",
        "1.321",
        "1.762",
        "-0.021"
    ],
    [
        "-147",
        "58",
        "-24.908",
        "22.664",
        "1.394",
        "1.738",
        "-0.087"
    ],
    [
        "-146",
        "58",
        "-24.975",
        "25.713",
        "1.325",
        "1.636",
        "-0.095"
    ],
    [
        "-145",
        "58",
        "-25.441",
        "28.568",
        "1.264",
        "1.398",
        "-0.063"
    ],
    [
        "-144",
        "58",
        "-25.489",
        "28.931",
        "1.196",
        "1.213",
        "-0.035"
    ],
    [
        "-143",
        "58",
        "-25.483",
        "28.167",
        "0.977",
        "1.322",
        "0.024"
    ],
    [
        "-142",
        "58",
        "-25.506",
        "28.907",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "58",
        "-25.079",
        "28.715",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "58",
        "-24.656",
        "28.514",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "58",
        "-24.235",
        "28.305",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "58",
        "-23.275",
        "25.018",
        "7.947",
        "7.701",
        "0.328"
    ],
    [
        "-137",
        "58",
        "-19.789",
        "14.104",
        "10.068",
        "8.976",
        "0.423"
    ],
    [
        "-136",
        "58",
        "-13.312",
        "-9.147",
        "10.857",
        "10.574",
        "0.324"
    ],
    [
        "-135",
        "58",
        "-11.369",
        "-15.165",
        "12.891",
        "10.282",
        "0.404"
    ],
    [
        "-134",
        "58",
        "-11.863",
        "-17.967",
        "3.266",
        "3.597",
        "0.212"
    ],
    [
        "-133",
        "58",
        "-12.182",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "58",
        "-12.444",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "58",
        "-12.703",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "58",
        "-12.957",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "58",
        "-13.208",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "58",
        "-13.455",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "58",
        "-13.698",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "58",
        "-13.936",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "58",
        "-14.171",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "58",
        "-14.401",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "58",
        "-14.626",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "58",
        "-14.847",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "58",
        "-15.064",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "58",
        "-15.276",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "58",
        "-15.484",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "58",
        "-15.687",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "58",
        "-15.886",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "58",
        "-16.079",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "58",
        "-16.267",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "58",
        "-16.451",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "58",
        "-16.630",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "58",
        "-16.804",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "58",
        "-16.973",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "58",
        "-17.136",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "58",
        "-17.295",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "58",
        "-17.448",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "58",
        "-17.595",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "58",
        "-17.737",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "58",
        "-17.875",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "58",
        "-18.007",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "58",
        "-18.133",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "58",
        "-18.254",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "58",
        "-18.369",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "58",
        "-18.479",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "58",
        "-18.583",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "58",
        "-18.681",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "58",
        "-18.774",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "58",
        "-18.861",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "58",
        "-18.942",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "58",
        "-19.019",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "58",
        "-19.088",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "58",
        "-19.153",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "58",
        "-19.211",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "58",
        "-19.264",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "58",
        "-19.310",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "58",
        "-19.351",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "58",
        "-19.386",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "58",
        "-19.416",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "58",
        "-19.438",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "58",
        "-19.456",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "58",
        "-19.468",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "58",
        "-19.473",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "58",
        "-19.473",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "58",
        "-19.467",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "58",
        "-19.455",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "58",
        "-19.437",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "58",
        "-19.413",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "58",
        "-19.384",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "58",
        "-19.348",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "58",
        "-19.306",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "58",
        "-19.260",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "58",
        "-19.206",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "58",
        "-19.147",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "58",
        "-19.083",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "58",
        "-19.013",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "58",
        "-18.936",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "58",
        "-18.854",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "58",
        "-18.767",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "58",
        "-18.673",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "58",
        "-18.575",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "58",
        "-18.469",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "58",
        "-18.360",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "58",
        "-18.244",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "58",
        "-18.122",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "58",
        "-17.995",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "58",
        "-17.863",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "58",
        "-17.726",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "58",
        "-17.583",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "58",
        "-17.435",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "58",
        "-17.281",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "58",
        "-17.123",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "58",
        "-16.958",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "58",
        "-16.789",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "58",
        "-16.616",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "58",
        "-16.436",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "58",
        "-16.252",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "58",
        "-16.064",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "58",
        "-15.869",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "58",
        "-15.671",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "58",
        "-15.467",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "58",
        "-15.259",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "58",
        "-15.047",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "58",
        "-14.829",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "58",
        "-14.607",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "58",
        "-14.381",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "58",
        "-14.151",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "58",
        "-13.916",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "58",
        "-13.677",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "58",
        "-13.434",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "58",
        "-13.187",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "58",
        "-12.936",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "58",
        "5.661",
        "16.701",
        "0.995",
        "1.040",
        "-0.371"
    ],
    [
        "-31",
        "58",
        "7.766",
        "16.351",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "58",
        "8.009",
        "16.456",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "58",
        "8.253",
        "16.554",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "58",
        "8.499",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "58",
        "8.746",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "58",
        "8.994",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "58",
        "9.244",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "58",
        "9.495",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "58",
        "9.746",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "58",
        "9.999",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "58",
        "10.252",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "58",
        "10.507",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "58",
        "10.762",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "58",
        "11.018",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "58",
        "11.275",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "58",
        "11.531",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "58",
        "11.789",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "58",
        "12.047",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "58",
        "12.305",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "58",
        "12.563",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "58",
        "12.821",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "58",
        "13.080",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "58",
        "13.338",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "58",
        "13.596",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "58",
        "13.855",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "58",
        "14.112",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "58",
        "14.370",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "58",
        "14.627",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "58",
        "14.883",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "58",
        "15.139",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "58",
        "15.394",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "58",
        "15.649",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "58",
        "15.903",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "58",
        "16.155",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "58",
        "16.407",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "58",
        "16.658",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "58",
        "16.907",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "58",
        "17.156",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "58",
        "17.403",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "58",
        "17.648",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "58",
        "17.893",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "58",
        "18.135",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "58",
        "18.377",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "58",
        "18.616",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "58",
        "18.855",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "58",
        "19.090",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "58",
        "19.324",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "58",
        "19.557",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "58",
        "19.787",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "58",
        "20.015",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "58",
        "20.242",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "58",
        "20.466",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "58",
        "20.687",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "58",
        "20.906",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "58",
        "21.122",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "58",
        "21.337",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "58",
        "21.548",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "58",
        "21.758",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "58",
        "21.964",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "58",
        "22.167",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "58",
        "22.368",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "58",
        "22.566",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "58",
        "22.761",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "58",
        "22.953",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "58",
        "23.142",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "58",
        "23.328",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "58",
        "23.511",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "58",
        "23.691",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "58",
        "23.867",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "58",
        "24.040",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "58",
        "24.209",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "58",
        "24.376",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "58",
        "24.538",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "58",
        "24.697",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "58",
        "24.853",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "58",
        "25.005",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "58",
        "25.153",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "58",
        "25.298",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "58",
        "25.438",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "58",
        "25.575",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "58",
        "25.709",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "58",
        "25.837",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "58",
        "25.963",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "58",
        "26.084",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "58",
        "26.202",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "58",
        "26.315",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "58",
        "26.424",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "58",
        "26.530",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "58",
        "26.631",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "58",
        "26.727",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "58",
        "26.820",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "58",
        "26.909",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "58",
        "26.992",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "58",
        "27.072",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "58",
        "27.148",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "58",
        "27.219",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "58",
        "27.286",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "58",
        "27.348",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "58",
        "27.406",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "58",
        "27.460",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "58",
        "27.510",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "58",
        "27.555",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "58",
        "27.596",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "58",
        "27.632",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "58",
        "27.663",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "58",
        "27.690",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "58",
        "27.712",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "58",
        "27.731",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "58",
        "27.744",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "58",
        "27.753",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "58",
        "27.758",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "58",
        "27.758",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "58",
        "27.753",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "58",
        "27.744",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "58",
        "27.731",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "58",
        "27.713",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "58",
        "27.690",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "58",
        "27.664",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "58",
        "27.633",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "58",
        "27.596",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "58",
        "27.555",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "58",
        "27.511",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "58",
        "27.461",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "58",
        "27.408",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "58",
        "27.350",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "58",
        "27.288",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "58",
        "27.221",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "58",
        "27.150",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "58",
        "27.074",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "58",
        "26.994",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "58",
        "26.910",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "58",
        "26.822",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "58",
        "26.729",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "58",
        "26.632",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "58",
        "26.531",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "58",
        "26.427",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "58",
        "26.317",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "58",
        "26.204",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "58",
        "26.087",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "58",
        "25.965",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "58",
        "25.840",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "58",
        "25.711",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "58",
        "25.578",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "58",
        "25.441",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "58",
        "25.300",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "58",
        "25.156",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "58",
        "25.008",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "58",
        "24.856",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "58",
        "24.700",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "58",
        "24.542",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "58",
        "24.379",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "58",
        "24.213",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "58",
        "24.044",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "58",
        "23.870",
        "-11.792",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "58",
        "23.695",
        "-12.015",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "58",
        "23.515",
        "-12.234",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "58",
        "23.332",
        "-12.450",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "58",
        "23.146",
        "-12.662",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "58",
        "22.957",
        "-12.870",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "58",
        "22.765",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "58",
        "22.570",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "58",
        "22.373",
        "-13.469",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "58",
        "22.171",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "58",
        "21.968",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "58",
        "21.762",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "58",
        "21.553",
        "-14.211",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "58",
        "21.341",
        "-14.386",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "58",
        "21.126",
        "-14.556",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "58",
        "20.910",
        "-14.722",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "58",
        "20.691",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "58",
        "20.469",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "58",
        "20.246",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "58",
        "20.020",
        "-15.340",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "58",
        "19.792",
        "-15.484",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "58",
        "19.508",
        "-15.601",
        "0.252",
        "0.318",
        "0.012"
    ],
    [
        "144",
        "58",
        "18.830",
        "-15.325",
        "1.143",
        "1.230",
        "0.026"
    ],
    [
        "145",
        "58",
        "18.349",
        "-15.223",
        "1.045",
        "1.242",
        "0.050"
    ],
    [
        "146",
        "58",
        "18.045",
        "-15.356",
        "0.966",
        "1.081",
        "0.008"
    ],
    [
        "147",
        "58",
        "17.800",
        "-15.711",
        "0.945",
        "0.939",
        "-0.007"
    ],
    [
        "148",
        "58",
        "17.574",
        "-16.116",
        "0.851",
        "0.910",
        "-0.037"
    ],
    [
        "149",
        "58",
        "17.336",
        "-16.499",
        "0.576",
        "0.663",
        "0.074"
    ],
    [
        "150",
        "58",
        "17.075",
        "-17.010",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "58",
        "16.820",
        "-17.537",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "58",
        "16.557",
        "-18.060",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "58",
        "16.285",
        "-18.575",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "58",
        "16.007",
        "-19.087",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "58",
        "15.720",
        "-19.592",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "58",
        "15.427",
        "-20.091",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "58",
        "15.125",
        "-20.583",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "58",
        "14.817",
        "-21.071",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "58",
        "14.502",
        "-21.551",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "58",
        "14.179",
        "-22.024",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "58",
        "13.850",
        "-22.492",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "58",
        "12.651",
        "-22.313",
        "1.942",
        "1.818",
        "-0.377"
    ],
    [
        "163",
        "58",
        "10.603",
        "-21.655",
        "2.240",
        "2.540",
        "-0.363"
    ],
    [
        "164",
        "58",
        "8.447",
        "-21.009",
        "1.487",
        "1.659",
        "-0.153"
    ],
    [
        "165",
        "58",
        "7.575",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "58",
        "7.265",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "58",
        "6.952",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "58",
        "6.636",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "58",
        "6.319",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "58",
        "5.999",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "58",
        "5.678",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "58",
        "5.355",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "58",
        "5.030",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "58",
        "4.704",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "58",
        "4.376",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "58",
        "4.047",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "58",
        "3.717",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "58",
        "3.386",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "58",
        "3.053",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "59",
        "2.753",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "59",
        "2.415",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "59",
        "2.077",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "59",
        "1.737",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "59",
        "1.397",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "59",
        "1.056",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "59",
        "0.715",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "59",
        "0.375",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "59",
        "0.034",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "59",
        "-0.308",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "59",
        "-0.649",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "59",
        "-0.990",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "59",
        "-1.331",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "59",
        "-1.672",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "59",
        "-2.011",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "59",
        "-2.351",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "59",
        "-2.690",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "59",
        "-3.027",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "59",
        "-3.365",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "59",
        "-3.700",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "59",
        "-4.035",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "59",
        "-4.369",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "59",
        "-4.702",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "59",
        "-5.033",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "59",
        "-5.362",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "59",
        "-8.824",
        "-18.753",
        "5.986",
        "7.815",
        "-0.061"
    ],
    [
        "-154",
        "59",
        "-16.449",
        "-7.575",
        "8.252",
        "10.778",
        "-0.107"
    ],
    [
        "-153",
        "59",
        "-21.760",
        "1.505",
        "6.608",
        "7.929",
        "-0.111"
    ],
    [
        "-152",
        "59",
        "-24.919",
        "8.875",
        "3.688",
        "4.642",
        "-0.105"
    ],
    [
        "-151",
        "59",
        "-25.563",
        "13.289",
        "2.338",
        "2.530",
        "-0.074"
    ],
    [
        "-150",
        "59",
        "-24.702",
        "13.325",
        "1.790",
        "1.897",
        "-0.008"
    ],
    [
        "-149",
        "59",
        "-23.584",
        "12.523",
        "1.517",
        "1.817",
        "-0.004"
    ],
    [
        "-148",
        "59",
        "-23.367",
        "14.072",
        "1.401",
        "1.838",
        "-0.050"
    ],
    [
        "-147",
        "59",
        "-23.722",
        "17.353",
        "1.325",
        "1.797",
        "-0.099"
    ],
    [
        "-146",
        "59",
        "-24.562",
        "22.496",
        "1.028",
        "1.596",
        "-0.121"
    ],
    [
        "-145",
        "59",
        "-25.745",
        "29.430",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "59",
        "-25.306",
        "29.265",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "59",
        "-24.422",
        "25.405",
        "1.109",
        "1.612",
        "0.048"
    ],
    [
        "-142",
        "59",
        "-24.423",
        "24.583",
        "1.709",
        "2.224",
        "0.223"
    ],
    [
        "-141",
        "59",
        "-25.072",
        "22.011",
        "3.279",
        "4.152",
        "0.365"
    ],
    [
        "-140",
        "59",
        "-25.474",
        "19.407",
        "5.725",
        "6.647",
        "0.374"
    ],
    [
        "-139",
        "59",
        "-24.807",
        "17.845",
        "7.820",
        "8.414",
        "0.373"
    ],
    [
        "-138",
        "59",
        "-22.984",
        "0.777",
        "10.247",
        "9.240",
        "0.436"
    ],
    [
        "-137",
        "59",
        "-18.932",
        "-12.432",
        "11.032",
        "10.762",
        "0.427"
    ],
    [
        "-136",
        "59",
        "-15.350",
        "-17.862",
        "11.083",
        "9.248",
        "0.523"
    ],
    [
        "-135",
        "59",
        "-11.766",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "59",
        "-12.038",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "59",
        "-12.308",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "59",
        "-12.573",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "59",
        "-12.834",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "59",
        "-13.092",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "59",
        "-13.345",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "59",
        "-13.595",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "59",
        "-13.840",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "59",
        "-14.081",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "59",
        "-14.318",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "59",
        "-14.551",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "59",
        "-14.778",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "59",
        "-15.003",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "59",
        "-15.222",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "59",
        "-15.436",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "59",
        "-15.646",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "59",
        "-15.851",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "59",
        "-16.051",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "59",
        "-16.247",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "59",
        "-16.438",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "59",
        "-16.624",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "59",
        "-16.804",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "59",
        "-16.980",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "59",
        "-17.150",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "59",
        "-17.316",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "59",
        "-17.475",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "59",
        "-17.631",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "59",
        "-17.779",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "59",
        "-17.924",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "59",
        "-18.063",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "59",
        "-18.196",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "59",
        "-18.323",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "59",
        "-18.445",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "59",
        "-18.562",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "59",
        "-18.672",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "59",
        "-18.777",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "59",
        "-18.877",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "59",
        "-18.971",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "59",
        "-19.059",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "59",
        "-19.141",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "59",
        "-19.218",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "59",
        "-19.289",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "59",
        "-19.353",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "59",
        "-19.412",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "59",
        "-19.466",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "59",
        "-19.513",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "59",
        "-19.555",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "59",
        "-19.589",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "59",
        "-19.619",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "59",
        "-19.643",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "59",
        "-19.661",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "59",
        "-19.672",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "59",
        "-19.678",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "59",
        "-19.677",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "59",
        "-19.671",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "59",
        "-19.659",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "59",
        "-19.642",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "59",
        "-19.618",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "59",
        "-19.587",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "59",
        "-19.551",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "59",
        "-19.509",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "59",
        "-19.461",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "59",
        "-19.408",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "59",
        "-19.349",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "59",
        "-19.283",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "59",
        "-19.212",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "59",
        "-19.135",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "59",
        "-19.052",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "59",
        "-18.963",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "59",
        "-18.869",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "59",
        "-18.769",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "59",
        "-18.663",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "59",
        "-18.552",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "59",
        "-18.435",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "59",
        "-18.312",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "59",
        "-18.184",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "59",
        "-18.050",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "59",
        "-17.912",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "59",
        "-17.768",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "59",
        "-17.618",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "59",
        "-17.463",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "59",
        "-17.302",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "59",
        "-17.136",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "59",
        "-16.965",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "59",
        "-16.789",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "59",
        "-16.608",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "59",
        "-16.422",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "59",
        "-16.231",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "59",
        "-16.035",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "59",
        "-15.835",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "59",
        "-15.629",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "59",
        "-15.419",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "59",
        "-15.204",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "59",
        "-14.984",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "59",
        "-14.760",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "59",
        "-14.531",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "59",
        "-14.298",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "59",
        "-14.061",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "59",
        "-13.819",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "59",
        "-13.573",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "59",
        "-13.324",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "59",
        "-13.070",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "59",
        "-12.813",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "59",
        "-7.392",
        "17.287",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "59",
        "7.592",
        "16.456",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "59",
        "7.839",
        "16.554",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "59",
        "8.088",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "59",
        "8.337",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "59",
        "8.587",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "59",
        "8.841",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "59",
        "9.094",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "59",
        "9.348",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "59",
        "9.603",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "59",
        "9.860",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "59",
        "10.117",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "59",
        "10.375",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "59",
        "10.634",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "59",
        "10.893",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "59",
        "11.152",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "59",
        "11.413",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "59",
        "11.673",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "59",
        "11.934",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "59",
        "12.196",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "59",
        "12.456",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "59",
        "12.717",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "59",
        "12.979",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "59",
        "13.240",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "59",
        "13.500",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "59",
        "13.761",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "59",
        "14.022",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "59",
        "14.281",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "59",
        "14.540",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "59",
        "14.799",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "59",
        "15.057",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "59",
        "15.313",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "59",
        "15.570",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "59",
        "15.826",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "59",
        "16.081",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "59",
        "16.334",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "59",
        "16.586",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "59",
        "16.837",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "59",
        "17.087",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "59",
        "17.335",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "59",
        "17.583",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "59",
        "17.828",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "59",
        "18.072",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "59",
        "18.314",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "59",
        "18.555",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "59",
        "18.792",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "59",
        "19.030",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "59",
        "19.264",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "59",
        "19.497",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "59",
        "19.728",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "59",
        "19.956",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "59",
        "20.182",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "59",
        "20.406",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "59",
        "20.628",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "59",
        "20.846",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "59",
        "21.063",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "59",
        "21.277",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "59",
        "21.488",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "59",
        "21.697",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "59",
        "21.903",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "59",
        "22.106",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "59",
        "22.306",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "59",
        "22.503",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "59",
        "22.698",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "59",
        "22.889",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "59",
        "23.076",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "59",
        "23.260",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "59",
        "23.442",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "59",
        "23.621",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "59",
        "23.795",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "59",
        "23.967",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "59",
        "24.135",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "59",
        "24.299",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "59",
        "24.460",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "59",
        "24.618",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "59",
        "24.771",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "59",
        "24.920",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "59",
        "25.066",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "59",
        "25.208",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "59",
        "25.347",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "59",
        "25.482",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "59",
        "25.613",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "59",
        "25.739",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "59",
        "25.862",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "59",
        "25.980",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "59",
        "26.095",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "59",
        "26.205",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "59",
        "26.311",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "59",
        "26.413",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "59",
        "26.511",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "59",
        "26.606",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "59",
        "26.694",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "59",
        "26.779",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "59",
        "26.861",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "59",
        "26.937",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "59",
        "27.009",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "59",
        "27.077",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "59",
        "27.140",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "59",
        "27.198",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "59",
        "27.253",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "59",
        "27.303",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "59",
        "27.348",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "59",
        "27.389",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "59",
        "27.425",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "59",
        "27.457",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "59",
        "27.485",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "59",
        "27.508",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "59",
        "27.525",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "59",
        "27.539",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "59",
        "27.549",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "59",
        "27.553",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "59",
        "27.553",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "59",
        "27.549",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "59",
        "27.540",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "59",
        "27.526",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "59",
        "27.508",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "59",
        "27.485",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "59",
        "27.458",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "59",
        "27.427",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "59",
        "27.390",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "59",
        "27.349",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "59",
        "27.304",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "59",
        "27.254",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "59",
        "27.199",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "59",
        "27.141",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "59",
        "27.078",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "59",
        "27.010",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "59",
        "26.939",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "59",
        "26.862",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "59",
        "26.781",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "59",
        "26.696",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "59",
        "26.607",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "59",
        "26.513",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "59",
        "26.415",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "59",
        "26.313",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "59",
        "26.208",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "59",
        "26.098",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "59",
        "25.983",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "59",
        "25.864",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "59",
        "25.741",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "59",
        "25.615",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "59",
        "25.485",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "59",
        "25.350",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "59",
        "25.212",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "59",
        "25.069",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "59",
        "24.924",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "59",
        "24.773",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "59",
        "24.620",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "59",
        "24.463",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "59",
        "24.303",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "59",
        "24.138",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "59",
        "23.970",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "59",
        "23.799",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "59",
        "23.624",
        "-11.792",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "59",
        "23.446",
        "-12.015",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "59",
        "23.264",
        "-12.234",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "59",
        "23.080",
        "-12.450",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "59",
        "22.893",
        "-12.662",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "59",
        "22.701",
        "-12.870",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "59",
        "22.507",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "59",
        "22.310",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "59",
        "22.110",
        "-13.469",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "59",
        "21.907",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "59",
        "21.702",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "59",
        "21.493",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "59",
        "21.282",
        "-14.211",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "59",
        "21.067",
        "-14.386",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "59",
        "20.851",
        "-14.556",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "59",
        "20.632",
        "-14.722",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "59",
        "20.411",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "59",
        "20.187",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "59",
        "19.960",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "59",
        "19.732",
        "-15.340",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "59",
        "19.502",
        "-15.484",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "59",
        "19.242",
        "-15.616",
        "0.257",
        "0.322",
        "0.006"
    ],
    [
        "144",
        "59",
        "18.787",
        "-15.659",
        "1.184",
        "1.273",
        "0.021"
    ],
    [
        "145",
        "59",
        "18.447",
        "-15.802",
        "1.163",
        "1.347",
        "0.040"
    ],
    [
        "146",
        "59",
        "18.190",
        "-16.032",
        "1.128",
        "1.265",
        "0.044"
    ],
    [
        "147",
        "59",
        "17.972",
        "-16.311",
        "1.099",
        "1.154",
        "0.054"
    ],
    [
        "148",
        "59",
        "17.769",
        "-16.619",
        "0.965",
        "1.109",
        "0.056"
    ],
    [
        "149",
        "59",
        "17.691",
        "-16.686",
        "0.619",
        "0.718",
        "0.063"
    ],
    [
        "150",
        "59",
        "17.596",
        "-17.010",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "59",
        "17.337",
        "-17.537",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "59",
        "17.071",
        "-18.060",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "59",
        "16.797",
        "-18.575",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "59",
        "16.515",
        "-19.087",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "59",
        "16.226",
        "-19.592",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "59",
        "15.930",
        "-20.091",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "59",
        "15.626",
        "-20.583",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "59",
        "15.109",
        "-21.036",
        "0.829",
        "0.832",
        "0.006"
    ],
    [
        "159",
        "59",
        "14.356",
        "-21.243",
        "1.059",
        "0.949",
        "0.003"
    ],
    [
        "160",
        "59",
        "13.433",
        "-21.277",
        "1.103",
        "1.072",
        "-0.065"
    ],
    [
        "161",
        "59",
        "12.397",
        "-21.161",
        "1.200",
        "1.218",
        "-0.139"
    ],
    [
        "162",
        "59",
        "11.212",
        "-20.965",
        "1.369",
        "1.375",
        "-0.182"
    ],
    [
        "163",
        "59",
        "9.970",
        "-20.833",
        "1.315",
        "1.516",
        "-0.196"
    ],
    [
        "164",
        "59",
        "8.810",
        "-20.857",
        "1.196",
        "1.489",
        "-0.141"
    ],
    [
        "165",
        "59",
        "7.923",
        "-21.009",
        "0.977",
        "1.053",
        "-0.218"
    ],
    [
        "166",
        "59",
        "7.348",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "59",
        "7.031",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "59",
        "6.712",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "59",
        "6.392",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "59",
        "6.069",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "59",
        "5.744",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "59",
        "5.418",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "59",
        "5.090",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "59",
        "4.760",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "59",
        "4.428",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "59",
        "4.096",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "59",
        "3.762",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "59",
        "3.427",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "59",
        "3.091",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "60",
        "2.786",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "60",
        "2.445",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "60",
        "2.103",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "60",
        "1.760",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "60",
        "1.416",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "60",
        "1.072",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "60",
        "0.728",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "60",
        "0.384",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "60",
        "0.039",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "60",
        "-0.306",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "60",
        "-0.650",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "60",
        "-0.996",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "60",
        "-1.340",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "60",
        "-1.684",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "60",
        "-2.027",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "60",
        "-2.370",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "60",
        "-2.713",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "60",
        "-3.053",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "60",
        "-3.394",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "60",
        "-3.734",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "60",
        "-4.072",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "60",
        "-4.410",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "60",
        "-4.745",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "60",
        "-5.080",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "60",
        "-5.413",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "60",
        "-7.740",
        "-19.857",
        "7.428",
        "8.342",
        "-0.023"
    ],
    [
        "-154",
        "60",
        "-13.280",
        "-10.549",
        "9.721",
        "11.803",
        "-0.031"
    ],
    [
        "-153",
        "60",
        "-16.522",
        "-4.979",
        "7.541",
        "8.462",
        "-0.038"
    ],
    [
        "-152",
        "60",
        "-17.401",
        "-2.323",
        "4.039",
        "4.854",
        "-0.027"
    ],
    [
        "-151",
        "60",
        "-17.843",
        "1.695",
        "2.481",
        "2.546",
        "0.044"
    ],
    [
        "-150",
        "60",
        "-19.202",
        "3.920",
        "1.861",
        "1.884",
        "0.087"
    ],
    [
        "-149",
        "60",
        "-20.500",
        "5.752",
        "1.536",
        "1.805",
        "0.043"
    ],
    [
        "-148",
        "60",
        "-21.290",
        "8.793",
        "1.362",
        "1.754",
        "-0.027"
    ],
    [
        "-147",
        "60",
        "-21.733",
        "12.765",
        "1.218",
        "1.469",
        "-0.015"
    ],
    [
        "-146",
        "60",
        "-21.853",
        "15.796",
        "1.212",
        "1.567",
        "0.084"
    ],
    [
        "-145",
        "60",
        "-21.559",
        "18.856",
        "1.320",
        "1.614",
        "0.006"
    ],
    [
        "-144",
        "60",
        "-21.050",
        "19.511",
        "1.429",
        "1.576",
        "0.018"
    ],
    [
        "-143",
        "60",
        "-21.009",
        "17.452",
        "1.446",
        "1.737",
        "0.105"
    ],
    [
        "-142",
        "60",
        "-22.224",
        "12.318",
        "1.763",
        "2.489",
        "0.259"
    ],
    [
        "-141",
        "60",
        "-23.392",
        "4.884",
        "3.092",
        "4.793",
        "0.384"
    ],
    [
        "-140",
        "60",
        "-24.024",
        "2.263",
        "4.597",
        "6.659",
        "0.374"
    ],
    [
        "-139",
        "60",
        "-24.779",
        "-7.876",
        "5.876",
        "6.136",
        "0.320"
    ],
    [
        "-138",
        "60",
        "-21.360",
        "-16.859",
        "8.845",
        "10.345",
        "0.465"
    ],
    [
        "-137",
        "60",
        "-17.273",
        "-20.203",
        "9.005",
        "9.476",
        "0.496"
    ],
    [
        "-136",
        "60",
        "-11.604",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "60",
        "-11.883",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "60",
        "-12.159",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "60",
        "-12.430",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "60",
        "-12.698",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "60",
        "-12.962",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "60",
        "-13.222",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "60",
        "-13.479",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "60",
        "-13.730",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "60",
        "-13.978",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "60",
        "-14.222",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "60",
        "-14.461",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "60",
        "-14.696",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "60",
        "-14.926",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "60",
        "-15.153",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "60",
        "-15.374",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "60",
        "-15.591",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "60",
        "-15.803",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "60",
        "-16.010",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "60",
        "-16.213",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "60",
        "-16.411",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "60",
        "-16.603",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "60",
        "-16.791",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "60",
        "-16.973",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "60",
        "-17.150",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "60",
        "-17.323",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "60",
        "-17.489",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "60",
        "-17.651",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "60",
        "-17.808",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "60",
        "-17.959",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "60",
        "-18.104",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "60",
        "-18.244",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "60",
        "-18.378",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "60",
        "-18.507",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "60",
        "-18.631",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "60",
        "-18.748",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "60",
        "-18.861",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "60",
        "-18.967",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "60",
        "-19.067",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "60",
        "-19.163",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "60",
        "-19.251",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "60",
        "-19.334",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "60",
        "-19.412",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "60",
        "-19.483",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "60",
        "-19.549",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "60",
        "-19.609",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "60",
        "-19.662",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "60",
        "-19.710",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "60",
        "-19.752",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "60",
        "-19.787",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "60",
        "-19.817",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "60",
        "-19.841",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "60",
        "-19.859",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "60",
        "-19.871",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "60",
        "-19.876",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "60",
        "-19.877",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "60",
        "-19.870",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "60",
        "-19.857",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "60",
        "-19.839",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "60",
        "-19.815",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "60",
        "-19.784",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "60",
        "-19.748",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "60",
        "-19.706",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "60",
        "-19.658",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "60",
        "-19.604",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "60",
        "-19.544",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "60",
        "-19.477",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "60",
        "-19.405",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "60",
        "-19.328",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "60",
        "-19.244",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "60",
        "-19.155",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "60",
        "-19.059",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "60",
        "-18.958",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "60",
        "-18.851",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "60",
        "-18.738",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "60",
        "-18.621",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "60",
        "-18.497",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "60",
        "-18.367",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "60",
        "-18.232",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "60",
        "-18.092",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "60",
        "-17.946",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "60",
        "-17.794",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "60",
        "-17.638",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "60",
        "-17.476",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "60",
        "-17.309",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "60",
        "-17.136",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "60",
        "-16.958",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "60",
        "-16.775",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "60",
        "-16.587",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "60",
        "-16.394",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "60",
        "-16.196",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "60",
        "-15.993",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "60",
        "-15.786",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "60",
        "-15.573",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "60",
        "-15.355",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "60",
        "-15.134",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "60",
        "-14.907",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "60",
        "-14.677",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "60",
        "-14.441",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "60",
        "-14.201",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "60",
        "-13.957",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "60",
        "-13.709",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "60",
        "-13.456",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "60",
        "-13.200",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "60",
        "-12.940",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "60",
        "-12.675",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "60",
        "-12.408",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "60",
        "6.376",
        "16.654",
        "0.702",
        "1.335",
        "-0.399"
    ],
    [
        "-28",
        "60",
        "7.673",
        "16.649",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "60",
        "7.925",
        "16.738",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "60",
        "8.179",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "60",
        "8.434",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "60",
        "8.690",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "60",
        "8.947",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "60",
        "9.205",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "60",
        "9.464",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "60",
        "9.724",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "60",
        "9.985",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "60",
        "10.246",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "60",
        "10.507",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "60",
        "10.770",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "60",
        "11.033",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "60",
        "11.297",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "60",
        "11.559",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "60",
        "11.823",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "60",
        "12.088",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "60",
        "12.351",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "60",
        "12.615",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "60",
        "12.879",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "60",
        "13.143",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "60",
        "13.406",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "60",
        "13.668",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "60",
        "13.931",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "60",
        "14.193",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "60",
        "14.454",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "60",
        "14.715",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "60",
        "14.975",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "60",
        "15.233",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "60",
        "15.492",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "60",
        "15.749",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "60",
        "16.005",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "60",
        "16.260",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "60",
        "16.513",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "60",
        "16.765",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "60",
        "17.017",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "60",
        "17.266",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "60",
        "17.515",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "60",
        "17.761",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "60",
        "18.005",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "60",
        "18.248",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "60",
        "18.489",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "60",
        "18.729",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "60",
        "18.966",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "60",
        "19.201",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "60",
        "19.433",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "60",
        "19.664",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "60",
        "19.893",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "60",
        "20.120",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "60",
        "20.343",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "60",
        "20.564",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "60",
        "20.783",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "60",
        "21.000",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "60",
        "21.213",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "60",
        "21.423",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "60",
        "21.632",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "60",
        "21.836",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "60",
        "22.039",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "60",
        "22.238",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "60",
        "22.434",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "60",
        "22.627",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "60",
        "22.817",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "60",
        "23.003",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "60",
        "23.187",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "60",
        "23.367",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "60",
        "23.543",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "60",
        "23.717",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "60",
        "23.886",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "60",
        "24.052",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "60",
        "24.215",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "60",
        "24.374",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "60",
        "24.529",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "60",
        "24.680",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "60",
        "24.828",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "60",
        "24.972",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "60",
        "25.112",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "60",
        "25.248",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "60",
        "25.379",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "60",
        "25.508",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "60",
        "25.632",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "60",
        "25.751",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "60",
        "25.867",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "60",
        "25.978",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "60",
        "26.086",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "60",
        "26.189",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "60",
        "26.288",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "60",
        "26.383",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "60",
        "26.473",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "60",
        "26.559",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "60",
        "26.640",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "60",
        "26.717",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "60",
        "26.790",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "60",
        "26.858",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "60",
        "26.923",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "60",
        "26.981",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "60",
        "27.037",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "60",
        "27.087",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "60",
        "27.133",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "60",
        "27.174",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "60",
        "27.211",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "60",
        "27.244",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "60",
        "27.271",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "60",
        "27.294",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "60",
        "27.312",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "60",
        "27.327",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "60",
        "27.336",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "60",
        "27.340",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "60",
        "27.341",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "60",
        "27.335",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "60",
        "27.326",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "60",
        "27.313",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "60",
        "27.294",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "60",
        "27.272",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "60",
        "27.244",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "60",
        "27.211",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "60",
        "27.175",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "60",
        "27.134",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "60",
        "27.088",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "60",
        "27.038",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "60",
        "26.983",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "60",
        "26.924",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "60",
        "26.860",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "60",
        "26.791",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "60",
        "26.719",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "60",
        "26.642",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "60",
        "26.560",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "60",
        "26.475",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "60",
        "26.384",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "60",
        "26.290",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "60",
        "26.191",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "60",
        "26.088",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "60",
        "25.980",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "60",
        "25.869",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "60",
        "25.753",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "60",
        "25.633",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "60",
        "25.510",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "60",
        "25.382",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "60",
        "25.251",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "60",
        "25.114",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "60",
        "24.974",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "60",
        "24.831",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "60",
        "24.683",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "60",
        "24.532",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "60",
        "24.377",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "60",
        "24.218",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "60",
        "24.056",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "60",
        "23.890",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "60",
        "23.720",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "60",
        "23.547",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "60",
        "23.370",
        "-11.792",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "60",
        "23.190",
        "-12.015",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "60",
        "23.007",
        "-12.234",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "60",
        "22.821",
        "-12.450",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "60",
        "22.631",
        "-12.662",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "60",
        "22.438",
        "-12.870",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "60",
        "22.242",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "60",
        "22.043",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "60",
        "21.841",
        "-13.469",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "60",
        "21.636",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "60",
        "21.428",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "60",
        "21.217",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "60",
        "21.004",
        "-14.211",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "60",
        "20.788",
        "-14.386",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "60",
        "20.569",
        "-14.556",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "60",
        "20.347",
        "-14.722",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "60",
        "20.124",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "60",
        "19.898",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "60",
        "19.669",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "60",
        "19.439",
        "-15.340",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "60",
        "19.206",
        "-15.484",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "60",
        "18.956",
        "-15.627",
        "0.258",
        "0.320",
        "0.004"
    ],
    [
        "144",
        "60",
        "18.601",
        "-15.895",
        "1.197",
        "1.289",
        "0.018"
    ],
    [
        "145",
        "60",
        "18.308",
        "-16.198",
        "1.207",
        "1.394",
        "0.035"
    ],
    [
        "146",
        "60",
        "18.052",
        "-16.514",
        "1.211",
        "1.353",
        "0.039"
    ],
    [
        "147",
        "60",
        "17.817",
        "-16.828",
        "1.238",
        "1.281",
        "0.044"
    ],
    [
        "148",
        "60",
        "17.583",
        "-17.200",
        "1.195",
        "1.271",
        "0.028"
    ],
    [
        "149",
        "60",
        "17.360",
        "-17.788",
        "1.194",
        "1.278",
        "0.022"
    ],
    [
        "150",
        "60",
        "17.068",
        "-19.120",
        "1.230",
        "1.252",
        "0.027"
    ],
    [
        "151",
        "60",
        "16.562",
        "-20.819",
        "1.123",
        "1.208",
        "-0.014"
    ],
    [
        "152",
        "60",
        "16.631",
        "-19.405",
        "1.035",
        "1.176",
        "0.012"
    ],
    [
        "153",
        "60",
        "17.305",
        "-18.575",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "60",
        "17.020",
        "-19.087",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "60",
        "16.727",
        "-19.592",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "60",
        "16.427",
        "-20.091",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "60",
        "16.120",
        "-20.583",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "60",
        "14.813",
        "-20.594",
        "0.892",
        "0.932",
        "-0.046"
    ],
    [
        "159",
        "60",
        "13.631",
        "-20.599",
        "1.123",
        "1.036",
        "-0.031"
    ],
    [
        "160",
        "60",
        "12.567",
        "-20.681",
        "1.156",
        "1.114",
        "-0.034"
    ],
    [
        "161",
        "60",
        "11.571",
        "-20.744",
        "1.192",
        "1.179",
        "-0.042"
    ],
    [
        "162",
        "60",
        "10.630",
        "-20.766",
        "1.259",
        "1.243",
        "-0.060"
    ],
    [
        "163",
        "60",
        "9.757",
        "-20.774",
        "1.177",
        "1.316",
        "-0.102"
    ],
    [
        "164",
        "60",
        "8.963",
        "-20.831",
        "1.112",
        "1.281",
        "-0.135"
    ],
    [
        "165",
        "60",
        "8.250",
        "-20.964",
        "1.042",
        "1.087",
        "-0.203"
    ],
    [
        "166",
        "60",
        "7.629",
        "-21.095",
        "0.812",
        "0.867",
        "-0.215"
    ],
    [
        "167",
        "60",
        "7.109",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "60",
        "6.786",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "60",
        "6.462",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "60",
        "6.137",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "60",
        "5.809",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "60",
        "5.478",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "60",
        "5.147",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "60",
        "4.814",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "60",
        "4.479",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "60",
        "4.143",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "60",
        "3.806",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "60",
        "3.467",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "60",
        "3.127",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "61",
        "2.819",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "61",
        "2.474",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "61",
        "2.128",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "61",
        "1.782",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "61",
        "1.436",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "61",
        "1.088",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "61",
        "0.741",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "61",
        "0.393",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "61",
        "0.044",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "61",
        "-0.304",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "61",
        "-0.653",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "61",
        "-1.001",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "61",
        "-1.349",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "61",
        "-1.696",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "61",
        "-2.043",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "61",
        "-2.389",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "61",
        "-2.734",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "61",
        "-3.080",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "61",
        "-3.424",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "61",
        "-3.766",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "61",
        "-4.108",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "61",
        "-4.448",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "61",
        "-4.788",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "61",
        "-5.125",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "61",
        "-5.462",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "61",
        "-6.999",
        "-21.048",
        "6.969",
        "7.451",
        "-0.033"
    ],
    [
        "-154",
        "61",
        "-9.943",
        "-17.597",
        "9.014",
        "10.652",
        "-0.004"
    ],
    [
        "-153",
        "61",
        "-10.884",
        "-17.732",
        "6.889",
        "7.595",
        "-0.004"
    ],
    [
        "-152",
        "61",
        "-11.164",
        "-21.139",
        "3.401",
        "4.246",
        "0.066"
    ],
    [
        "-151",
        "61",
        "-14.675",
        "-12.933",
        "2.015",
        "2.175",
        "0.071"
    ],
    [
        "-150",
        "61",
        "-18.899",
        "-3.209",
        "1.762",
        "1.841",
        "0.084"
    ],
    [
        "-149",
        "61",
        "-19.806",
        "-0.819",
        "1.523",
        "1.790",
        "0.071"
    ],
    [
        "-148",
        "61",
        "-19.944",
        "1.600",
        "1.410",
        "1.764",
        "0.031"
    ],
    [
        "-147",
        "61",
        "-19.541",
        "4.121",
        "1.371",
        "1.693",
        "-0.040"
    ],
    [
        "-146",
        "61",
        "-18.886",
        "7.128",
        "1.246",
        "1.329",
        "-0.074"
    ],
    [
        "-145",
        "61",
        "-17.311",
        "7.354",
        "1.324",
        "1.608",
        "-0.015"
    ],
    [
        "-144",
        "61",
        "-15.327",
        "5.393",
        "1.423",
        "1.681",
        "0.058"
    ],
    [
        "-143",
        "61",
        "-14.055",
        "0.765",
        "1.465",
        "1.656",
        "0.196"
    ],
    [
        "-142",
        "61",
        "-14.141",
        "-4.408",
        "1.831",
        "2.536",
        "0.311"
    ],
    [
        "-141",
        "61",
        "-14.757",
        "-9.210",
        "2.913",
        "4.440",
        "0.447"
    ],
    [
        "-140",
        "61",
        "-15.172",
        "-14.603",
        "4.311",
        "6.833",
        "0.481"
    ],
    [
        "-139",
        "61",
        "-13.960",
        "-23.016",
        "4.160",
        "6.716",
        "0.421"
    ],
    [
        "-138",
        "61",
        "-11.142",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "61",
        "-11.429",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "61",
        "-11.715",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "61",
        "-11.996",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "61",
        "-12.274",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "61",
        "-12.549",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "61",
        "-12.819",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "61",
        "-13.086",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "61",
        "-13.348",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "61",
        "-13.607",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "61",
        "-13.861",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "61",
        "-14.112",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "61",
        "-14.358",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "61",
        "-14.599",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "61",
        "-14.837",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "61",
        "-15.069",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "61",
        "-15.298",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "61",
        "-15.522",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "61",
        "-15.741",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "61",
        "-15.955",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "61",
        "-16.164",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "61",
        "-16.369",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "61",
        "-16.568",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "61",
        "-16.762",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "61",
        "-16.953",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "61",
        "-17.136",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "61",
        "-17.316",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "61",
        "-17.489",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "61",
        "-17.658",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "61",
        "-17.822",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "61",
        "-17.980",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "61",
        "-18.132",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "61",
        "-18.279",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "61",
        "-18.420",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "61",
        "-18.556",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "61",
        "-18.687",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "61",
        "-18.811",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "61",
        "-18.930",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "61",
        "-19.043",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "61",
        "-19.151",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "61",
        "-19.252",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "61",
        "-19.348",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "61",
        "-19.438",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "61",
        "-19.521",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "61",
        "-19.600",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "61",
        "-19.672",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "61",
        "-19.738",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "61",
        "-19.798",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "61",
        "-19.853",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "61",
        "-19.900",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "61",
        "-19.943",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "61",
        "-19.979",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "61",
        "-20.009",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "61",
        "-20.033",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "61",
        "-20.051",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "61",
        "-20.063",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "61",
        "-20.069",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "61",
        "-20.069",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "61",
        "-20.063",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "61",
        "-20.049",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "61",
        "-20.032",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "61",
        "-20.007",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "61",
        "-19.976",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "61",
        "-19.940",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "61",
        "-19.897",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "61",
        "-19.848",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "61",
        "-19.793",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "61",
        "-19.732",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "61",
        "-19.666",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "61",
        "-19.593",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "61",
        "-19.515",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "61",
        "-19.430",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "61",
        "-19.340",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "61",
        "-19.243",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "61",
        "-19.141",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "61",
        "-19.034",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "61",
        "-18.920",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "61",
        "-18.800",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "61",
        "-18.675",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "61",
        "-18.545",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "61",
        "-18.409",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "61",
        "-18.267",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "61",
        "-18.119",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "61",
        "-17.967",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "61",
        "-17.808",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "61",
        "-17.644",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "61",
        "-17.476",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "61",
        "-17.301",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "61",
        "-17.121",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "61",
        "-16.937",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "61",
        "-16.746",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "61",
        "-16.552",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "61",
        "-16.351",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "61",
        "-16.147",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "61",
        "-15.937",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "61",
        "-15.722",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "61",
        "-15.503",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "61",
        "-15.279",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "61",
        "-15.051",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "61",
        "-14.818",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "61",
        "-14.579",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "61",
        "-14.338",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "61",
        "-14.091",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "61",
        "-13.841",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "61",
        "-13.586",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "61",
        "-13.327",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "61",
        "-13.064",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "61",
        "-12.797",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "61",
        "-12.526",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "61",
        "-12.251",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "61",
        "-4.926",
        "18.182",
        "2.849",
        "4.710",
        "-0.420"
    ],
    [
        "-27",
        "61",
        "5.477",
        "15.890",
        "2.741",
        "4.183",
        "-0.502"
    ],
    [
        "-26",
        "61",
        "7.768",
        "16.822",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "61",
        "8.025",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "61",
        "8.284",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "61",
        "8.543",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "61",
        "8.804",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "61",
        "9.065",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "61",
        "9.328",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "61",
        "9.591",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "61",
        "9.855",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "61",
        "10.119",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "61",
        "10.385",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "61",
        "10.650",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "61",
        "10.916",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "61",
        "11.182",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "61",
        "11.449",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "61",
        "11.714",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "61",
        "11.981",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "61",
        "12.248",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "61",
        "12.514",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "61",
        "12.780",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "61",
        "13.046",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "61",
        "13.312",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "61",
        "13.576",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "61",
        "13.841",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "61",
        "14.105",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "61",
        "14.368",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "61",
        "14.630",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "61",
        "14.892",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "61",
        "15.153",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "61",
        "15.412",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "61",
        "15.671",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "61",
        "15.928",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "61",
        "16.185",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "61",
        "16.440",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "61",
        "16.693",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "61",
        "16.945",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "61",
        "17.195",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "61",
        "17.444",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "61",
        "17.691",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "61",
        "17.937",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "61",
        "18.180",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "61",
        "18.422",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "61",
        "18.662",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "61",
        "18.899",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "61",
        "19.134",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "61",
        "19.367",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "61",
        "19.598",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "61",
        "19.827",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "61",
        "20.053",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "61",
        "20.276",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "61",
        "20.496",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "61",
        "20.715",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "61",
        "20.931",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "61",
        "21.144",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "61",
        "21.354",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "61",
        "21.560",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "61",
        "21.765",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "61",
        "21.966",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "61",
        "22.164",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "61",
        "22.359",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "61",
        "22.551",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "61",
        "22.740",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "61",
        "22.925",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "61",
        "23.106",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "61",
        "23.285",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "61",
        "23.460",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "61",
        "23.631",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "61",
        "23.798",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "61",
        "23.963",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "61",
        "24.123",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "61",
        "24.279",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "61",
        "24.433",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "61",
        "24.581",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "61",
        "24.727",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "61",
        "24.868",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "61",
        "25.005",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "61",
        "25.139",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "61",
        "25.268",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "61",
        "25.394",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "61",
        "25.514",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "61",
        "25.631",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "61",
        "25.744",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "61",
        "25.852",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "61",
        "25.956",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "61",
        "26.056",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "61",
        "26.152",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "61",
        "26.242",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "61",
        "26.330",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "61",
        "26.412",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "61",
        "26.490",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "61",
        "26.563",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "61",
        "26.633",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "61",
        "26.697",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "61",
        "26.757",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "61",
        "26.813",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "61",
        "26.864",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "61",
        "26.910",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "61",
        "26.951",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "61",
        "26.989",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "61",
        "27.021",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "61",
        "27.049",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "61",
        "27.072",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "61",
        "27.091",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "61",
        "27.105",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "61",
        "27.114",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "61",
        "27.119",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "61",
        "27.119",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "61",
        "27.114",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "61",
        "27.105",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "61",
        "27.091",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "61",
        "27.073",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "61",
        "27.049",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "61",
        "27.021",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "61",
        "26.989",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "61",
        "26.952",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "61",
        "26.910",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "61",
        "26.864",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "61",
        "26.814",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "61",
        "26.758",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "61",
        "26.699",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "61",
        "26.634",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "61",
        "26.565",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "61",
        "26.492",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "61",
        "26.414",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "61",
        "26.332",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "61",
        "26.244",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "61",
        "26.154",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "61",
        "26.058",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "61",
        "25.958",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "61",
        "25.854",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "61",
        "25.746",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "61",
        "25.633",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "61",
        "25.517",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "61",
        "25.396",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "61",
        "25.270",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "61",
        "25.142",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "61",
        "25.008",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "61",
        "24.871",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "61",
        "24.729",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "61",
        "24.585",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "61",
        "24.436",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "61",
        "24.283",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "61",
        "24.127",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "61",
        "23.965",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "61",
        "23.802",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "61",
        "23.635",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "61",
        "23.463",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "61",
        "23.288",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "61",
        "23.110",
        "-11.792",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "61",
        "22.928",
        "-12.015",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "61",
        "22.743",
        "-12.234",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "61",
        "22.554",
        "-12.450",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "61",
        "22.363",
        "-12.662",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "61",
        "22.169",
        "-12.870",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "61",
        "21.970",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "61",
        "21.769",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "61",
        "21.565",
        "-13.469",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "61",
        "21.358",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "61",
        "21.148",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "61",
        "20.935",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "61",
        "20.720",
        "-14.211",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "61",
        "20.501",
        "-14.386",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "61",
        "20.280",
        "-14.556",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "61",
        "20.057",
        "-14.722",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "61",
        "19.831",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "61",
        "19.602",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "61",
        "19.371",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "61",
        "19.139",
        "-15.340",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "61",
        "18.904",
        "-15.484",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "61",
        "18.653",
        "-15.634",
        "0.258",
        "0.317",
        "0.002"
    ],
    [
        "144",
        "61",
        "18.301",
        "-16.062",
        "1.215",
        "1.304",
        "0.009"
    ],
    [
        "145",
        "61",
        "17.982",
        "-16.499",
        "1.262",
        "1.438",
        "0.015"
    ],
    [
        "146",
        "61",
        "17.684",
        "-16.941",
        "1.304",
        "1.434",
        "0.010"
    ],
    [
        "147",
        "61",
        "17.410",
        "-17.389",
        "1.370",
        "1.386",
        "0.009"
    ],
    [
        "148",
        "61",
        "17.184",
        "-17.793",
        "1.333",
        "1.400",
        "0.009"
    ],
    [
        "149",
        "61",
        "16.906",
        "-18.282",
        "1.343",
        "1.423",
        "0.006"
    ],
    [
        "150",
        "61",
        "16.571",
        "-18.924",
        "1.402",
        "1.409",
        "0.011"
    ],
    [
        "151",
        "61",
        "16.141",
        "-19.455",
        "1.292",
        "1.382",
        "0.015"
    ],
    [
        "152",
        "61",
        "15.791",
        "-19.772",
        "1.194",
        "1.252",
        "0.023"
    ],
    [
        "153",
        "61",
        "15.665",
        "-19.688",
        "1.146",
        "1.117",
        "0.031"
    ],
    [
        "154",
        "61",
        "15.436",
        "-19.517",
        "1.036",
        "1.059",
        "-0.018"
    ],
    [
        "155",
        "61",
        "15.011",
        "-19.428",
        "1.028",
        "1.047",
        "-0.047"
    ],
    [
        "156",
        "61",
        "14.402",
        "-19.496",
        "1.105",
        "1.058",
        "-0.039"
    ],
    [
        "157",
        "61",
        "13.713",
        "-19.685",
        "1.087",
        "1.090",
        "-0.048"
    ],
    [
        "158",
        "61",
        "12.961",
        "-19.881",
        "1.129",
        "1.096",
        "-0.060"
    ],
    [
        "159",
        "61",
        "12.283",
        "-20.106",
        "1.198",
        "1.080",
        "-0.036"
    ],
    [
        "160",
        "61",
        "11.602",
        "-20.314",
        "1.191",
        "1.129",
        "-0.021"
    ],
    [
        "161",
        "61",
        "10.900",
        "-20.480",
        "1.221",
        "1.199",
        "-0.016"
    ],
    [
        "162",
        "61",
        "10.196",
        "-20.609",
        "1.311",
        "1.265",
        "-0.020"
    ],
    [
        "163",
        "61",
        "9.520",
        "-20.726",
        "1.256",
        "1.332",
        "-0.055"
    ],
    [
        "164",
        "61",
        "8.903",
        "-20.850",
        "1.221",
        "1.295",
        "-0.096"
    ],
    [
        "165",
        "61",
        "8.355",
        "-20.976",
        "1.203",
        "1.158",
        "-0.146"
    ],
    [
        "166",
        "61",
        "7.864",
        "-21.104",
        "1.065",
        "1.021",
        "-0.159"
    ],
    [
        "167",
        "61",
        "7.404",
        "-21.232",
        "0.979",
        "0.917",
        "-0.172"
    ],
    [
        "168",
        "61",
        "6.981",
        "-21.371",
        "0.938",
        "0.892",
        "-0.216"
    ],
    [
        "169",
        "61",
        "6.578",
        "-21.512",
        "0.731",
        "0.851",
        "-0.218"
    ],
    [
        "170",
        "61",
        "6.202",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "61",
        "5.871",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "61",
        "5.538",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "61",
        "5.203",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "61",
        "4.867",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "61",
        "4.529",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "61",
        "4.189",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "61",
        "3.848",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "61",
        "3.506",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "61",
        "3.163",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "62",
        "2.851",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "62",
        "2.503",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "62",
        "2.154",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "62",
        "1.804",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "62",
        "1.453",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "62",
        "1.103",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "62",
        "0.752",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "62",
        "0.401",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "62",
        "0.050",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "62",
        "-0.302",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "62",
        "-0.654",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "62",
        "-1.005",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "62",
        "-1.356",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "62",
        "-1.707",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "62",
        "-2.058",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "62",
        "-2.407",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "62",
        "-2.756",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "62",
        "-3.104",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "62",
        "-3.452",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "62",
        "-3.797",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "62",
        "-4.142",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "62",
        "-4.487",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "62",
        "-4.829",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "62",
        "-5.170",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "62",
        "-5.509",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "62",
        "-6.643",
        "-21.474",
        "5.322",
        "5.761",
        "-0.022"
    ],
    [
        "-154",
        "62",
        "-9.532",
        "-19.970",
        "6.540",
        "7.655",
        "-0.006"
    ],
    [
        "-153",
        "62",
        "-12.620",
        "-17.657",
        "4.901",
        "5.208",
        "-0.021"
    ],
    [
        "-152",
        "62",
        "-15.591",
        "-14.536",
        "2.901",
        "3.406",
        "0.039"
    ],
    [
        "-151",
        "62",
        "-17.654",
        "-11.476",
        "2.079",
        "2.173",
        "0.096"
    ],
    [
        "-150",
        "62",
        "-17.811",
        "-9.288",
        "1.757",
        "1.791",
        "0.094"
    ],
    [
        "-149",
        "62",
        "-17.633",
        "-8.165",
        "1.538",
        "1.780",
        "0.031"
    ],
    [
        "-148",
        "62",
        "-16.877",
        "-7.145",
        "1.410",
        "1.785",
        "-0.037"
    ],
    [
        "-147",
        "62",
        "-15.671",
        "-6.749",
        "1.329",
        "1.730",
        "-0.084"
    ],
    [
        "-146",
        "62",
        "-14.167",
        "-8.050",
        "1.151",
        "1.521",
        "-0.098"
    ],
    [
        "-145",
        "62",
        "-12.695",
        "-9.737",
        "1.070",
        "1.387",
        "-0.004"
    ],
    [
        "-144",
        "62",
        "-11.696",
        "-9.471",
        "1.168",
        "1.597",
        "0.014"
    ],
    [
        "-143",
        "62",
        "-10.589",
        "-11.303",
        "1.212",
        "1.802",
        "0.160"
    ],
    [
        "-142",
        "62",
        "-10.286",
        "-14.912",
        "1.509",
        "2.256",
        "0.351"
    ],
    [
        "-141",
        "62",
        "-10.658",
        "-18.617",
        "1.865",
        "2.892",
        "0.399"
    ],
    [
        "-140",
        "62",
        "-10.649",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "62",
        "-10.948",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "62",
        "-11.242",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "62",
        "-11.534",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "62",
        "-11.822",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "62",
        "-12.106",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "62",
        "-12.387",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "62",
        "-12.664",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "62",
        "-12.937",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "62",
        "-13.206",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "62",
        "-13.470",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "62",
        "-13.732",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "62",
        "-13.989",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "62",
        "-14.241",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "62",
        "-14.490",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "62",
        "-14.734",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "62",
        "-14.973",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "62",
        "-15.208",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "62",
        "-15.439",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "62",
        "-15.664",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "62",
        "-15.886",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "62",
        "-16.103",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "62",
        "-16.313",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "62",
        "-16.519",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "62",
        "-16.722",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "62",
        "-16.918",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "62",
        "-17.109",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "62",
        "-17.295",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "62",
        "-17.476",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "62",
        "-17.651",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "62",
        "-17.822",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "62",
        "-17.986",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "62",
        "-18.145",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "62",
        "-18.300",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "62",
        "-18.448",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "62",
        "-18.591",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "62",
        "-18.728",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "62",
        "-18.859",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "62",
        "-18.985",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "62",
        "-19.105",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "62",
        "-19.219",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "62",
        "-19.328",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "62",
        "-19.431",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "62",
        "-19.527",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "62",
        "-19.618",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "62",
        "-19.703",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "62",
        "-19.782",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "62",
        "-19.854",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "62",
        "-19.922",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "62",
        "-19.982",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "62",
        "-20.037",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "62",
        "-20.085",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "62",
        "-20.128",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "62",
        "-20.164",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "62",
        "-20.195",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "62",
        "-20.219",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "62",
        "-20.237",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "62",
        "-20.249",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "62",
        "-20.255",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "62",
        "-20.255",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "62",
        "-20.249",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "62",
        "-20.236",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "62",
        "-20.218",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "62",
        "-20.192",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "62",
        "-20.162",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "62",
        "-20.125",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "62",
        "-20.082",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "62",
        "-20.033",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "62",
        "-19.977",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "62",
        "-19.915",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "62",
        "-19.848",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "62",
        "-19.775",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "62",
        "-19.696",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "62",
        "-19.610",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "62",
        "-19.519",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "62",
        "-19.422",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "62",
        "-19.319",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "62",
        "-19.210",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "62",
        "-19.095",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "62",
        "-18.975",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "62",
        "-18.848",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "62",
        "-18.716",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "62",
        "-18.579",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "62",
        "-18.436",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "62",
        "-18.287",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "62",
        "-18.133",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "62",
        "-17.973",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "62",
        "-17.808",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "62",
        "-17.637",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "62",
        "-17.461",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "62",
        "-17.280",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "62",
        "-17.094",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "62",
        "-16.901",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "62",
        "-16.704",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "62",
        "-16.503",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "62",
        "-16.296",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "62",
        "-16.085",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "62",
        "-15.867",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "62",
        "-15.646",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "62",
        "-15.420",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "62",
        "-15.189",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "62",
        "-14.954",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "62",
        "-14.713",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "62",
        "-14.469",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "62",
        "-14.220",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "62",
        "-13.967",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "62",
        "-13.710",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "62",
        "-13.449",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "62",
        "-13.183",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "62",
        "-12.914",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "62",
        "-12.641",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "62",
        "-12.363",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "62",
        "-12.082",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "62",
        "-11.797",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "62",
        "-1.372",
        "17.458",
        "2.798",
        "2.751",
        "-0.347"
    ],
    [
        "-25",
        "62",
        "7.614",
        "16.901",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "62",
        "7.874",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "62",
        "8.137",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "62",
        "8.400",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "62",
        "8.664",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "62",
        "8.929",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "62",
        "9.194",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "62",
        "9.461",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "62",
        "9.728",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "62",
        "9.996",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "62",
        "10.264",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "62",
        "10.532",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "62",
        "10.801",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "62",
        "11.070",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "62",
        "11.338",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "62",
        "11.607",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "62",
        "11.877",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "62",
        "12.145",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "62",
        "12.414",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "62",
        "12.683",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "62",
        "12.950",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "62",
        "13.218",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "62",
        "13.485",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "62",
        "13.751",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "62",
        "14.017",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "62",
        "14.282",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "62",
        "14.546",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "62",
        "14.809",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "62",
        "15.071",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "62",
        "15.332",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "62",
        "15.592",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "62",
        "15.851",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "62",
        "16.108",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "62",
        "16.364",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "62",
        "16.619",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "62",
        "16.871",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "62",
        "17.123",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "62",
        "17.372",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "62",
        "17.620",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "62",
        "17.866",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "62",
        "18.109",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "62",
        "18.351",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "62",
        "18.591",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "62",
        "18.829",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "62",
        "19.063",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "62",
        "19.296",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "62",
        "19.528",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "62",
        "19.755",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "62",
        "19.981",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "62",
        "20.204",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "62",
        "20.424",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "62",
        "20.642",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "62",
        "20.857",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "62",
        "21.070",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "62",
        "21.278",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "62",
        "21.485",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "62",
        "21.688",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "62",
        "21.887",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "62",
        "22.084",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "62",
        "22.278",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "62",
        "22.468",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "62",
        "22.655",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "62",
        "22.838",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "62",
        "23.018",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "62",
        "23.195",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "62",
        "23.368",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "62",
        "23.537",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "62",
        "23.703",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "62",
        "23.865",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "62",
        "24.023",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "62",
        "24.178",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "62",
        "24.329",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "62",
        "24.474",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "62",
        "24.617",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "62",
        "24.756",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "62",
        "24.890",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "62",
        "25.021",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "62",
        "25.147",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "62",
        "25.269",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "62",
        "25.387",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "62",
        "25.501",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "62",
        "25.611",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "62",
        "25.716",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "62",
        "25.816",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "62",
        "25.913",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "62",
        "26.005",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "62",
        "26.092",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "62",
        "26.176",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "62",
        "26.254",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "62",
        "26.329",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "62",
        "26.398",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "62",
        "26.463",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "62",
        "26.524",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "62",
        "26.580",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "62",
        "26.631",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "62",
        "26.678",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "62",
        "26.720",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "62",
        "26.758",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "62",
        "26.791",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "62",
        "26.819",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "62",
        "26.842",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "62",
        "26.862",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "62",
        "26.875",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "62",
        "26.885",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "62",
        "26.890",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "62",
        "26.889",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "62",
        "26.885",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "62",
        "26.875",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "62",
        "26.862",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "62",
        "26.843",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "62",
        "26.820",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "62",
        "26.791",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "62",
        "26.759",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "62",
        "26.721",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "62",
        "26.679",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "62",
        "26.632",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "62",
        "26.581",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "62",
        "26.525",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "62",
        "26.465",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "62",
        "26.400",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "62",
        "26.330",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "62",
        "26.256",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "62",
        "26.178",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "62",
        "26.095",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "62",
        "26.007",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "62",
        "25.915",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "62",
        "25.819",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "62",
        "25.718",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "62",
        "25.612",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "62",
        "25.503",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "62",
        "25.390",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "62",
        "25.271",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "62",
        "25.149",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "62",
        "25.024",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "62",
        "24.893",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "62",
        "24.758",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "62",
        "24.620",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "62",
        "24.478",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "62",
        "24.331",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "62",
        "24.180",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "62",
        "24.026",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "62",
        "23.869",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "62",
        "23.706",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "62",
        "23.541",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "62",
        "23.371",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "62",
        "23.198",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "62",
        "23.022",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "62",
        "22.843",
        "-11.792",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "62",
        "22.659",
        "-12.015",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "62",
        "22.472",
        "-12.234",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "62",
        "22.281",
        "-12.450",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "62",
        "22.089",
        "-12.662",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "62",
        "21.892",
        "-12.870",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "62",
        "21.691",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "62",
        "21.488",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "62",
        "21.282",
        "-13.469",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "62",
        "21.073",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "62",
        "20.861",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "62",
        "20.646",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "62",
        "20.429",
        "-14.211",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "62",
        "20.209",
        "-14.386",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "62",
        "19.986",
        "-14.556",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "62",
        "19.760",
        "-14.722",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "62",
        "19.532",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "62",
        "19.301",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "62",
        "19.069",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "62",
        "18.833",
        "-15.340",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "62",
        "18.596",
        "-15.484",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "62",
        "18.339",
        "-15.638",
        "0.258",
        "0.313",
        "0.000"
    ],
    [
        "144",
        "62",
        "17.933",
        "-16.164",
        "1.222",
        "1.312",
        "0.003"
    ],
    [
        "145",
        "62",
        "17.541",
        "-16.691",
        "1.287",
        "1.470",
        "0.006"
    ],
    [
        "146",
        "62",
        "17.150",
        "-17.250",
        "1.327",
        "1.490",
        "0.003"
    ],
    [
        "147",
        "62",
        "16.739",
        "-18.044",
        "1.350",
        "1.388",
        "0.002"
    ],
    [
        "148",
        "62",
        "16.319",
        "-18.364",
        "1.308",
        "1.422",
        "-0.019"
    ],
    [
        "149",
        "62",
        "15.905",
        "-18.494",
        "1.360",
        "1.470",
        "-0.006"
    ],
    [
        "150",
        "62",
        "15.453",
        "-18.803",
        "1.417",
        "1.438",
        "-0.005"
    ],
    [
        "151",
        "62",
        "14.963",
        "-19.134",
        "1.304",
        "1.380",
        "-0.011"
    ],
    [
        "152",
        "62",
        "14.419",
        "-19.371",
        "1.222",
        "1.241",
        "-0.034"
    ],
    [
        "153",
        "62",
        "13.784",
        "-19.446",
        "1.163",
        "1.095",
        "-0.048"
    ],
    [
        "154",
        "62",
        "13.215",
        "-19.332",
        "1.044",
        "1.045",
        "-0.063"
    ],
    [
        "155",
        "62",
        "12.687",
        "-19.145",
        "1.021",
        "1.046",
        "-0.033"
    ],
    [
        "156",
        "62",
        "12.221",
        "-19.123",
        "1.092",
        "1.071",
        "-0.006"
    ],
    [
        "157",
        "62",
        "11.789",
        "-19.323",
        "1.074",
        "1.112",
        "-0.016"
    ],
    [
        "158",
        "62",
        "11.370",
        "-19.624",
        "1.098",
        "1.090",
        "-0.027"
    ],
    [
        "159",
        "62",
        "10.948",
        "-19.903",
        "1.147",
        "1.038",
        "-0.029"
    ],
    [
        "160",
        "62",
        "10.510",
        "-20.127",
        "1.132",
        "1.081",
        "-0.024"
    ],
    [
        "161",
        "62",
        "10.042",
        "-20.305",
        "1.162",
        "1.173",
        "-0.012"
    ],
    [
        "162",
        "62",
        "9.551",
        "-20.460",
        "1.266",
        "1.249",
        "-0.005"
    ],
    [
        "163",
        "62",
        "9.054",
        "-20.620",
        "1.211",
        "1.311",
        "-0.027"
    ],
    [
        "164",
        "62",
        "8.566",
        "-20.790",
        "1.136",
        "1.253",
        "-0.065"
    ],
    [
        "165",
        "62",
        "8.116",
        "-20.943",
        "1.087",
        "1.081",
        "-0.087"
    ],
    [
        "166",
        "62",
        "7.716",
        "-21.089",
        "0.910",
        "0.930",
        "-0.116"
    ],
    [
        "167",
        "62",
        "7.355",
        "-21.227",
        "0.868",
        "0.840",
        "-0.126"
    ],
    [
        "168",
        "62",
        "6.998",
        "-21.367",
        "0.882",
        "0.822",
        "-0.165"
    ],
    [
        "169",
        "62",
        "6.634",
        "-21.507",
        "0.731",
        "0.789",
        "-0.158"
    ],
    [
        "170",
        "62",
        "6.266",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "62",
        "5.931",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "62",
        "5.595",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "62",
        "5.257",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "62",
        "4.917",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "62",
        "4.576",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "62",
        "4.234",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "62",
        "3.889",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "62",
        "3.545",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "62",
        "3.198",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "63",
        "2.882",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "63",
        "2.531",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "63",
        "2.178",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "63",
        "1.825",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "63",
        "1.472",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "63",
        "1.118",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "63",
        "0.764",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "63",
        "0.408",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "63",
        "0.055",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "63",
        "-0.301",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "63",
        "-0.655",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "63",
        "-1.010",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "63",
        "-1.364",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "63",
        "-1.718",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "63",
        "-2.072",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "63",
        "-2.424",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "63",
        "-2.776",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "63",
        "-3.128",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "63",
        "-3.478",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "63",
        "-3.828",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "63",
        "-4.175",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "63",
        "-4.522",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "63",
        "-4.868",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "63",
        "-5.212",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "63",
        "-5.555",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "63",
        "-5.896",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "63",
        "-6.235",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "63",
        "-6.573",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "63",
        "-10.394",
        "-17.447",
        "1.771",
        "2.124",
        "0.047"
    ],
    [
        "-151",
        "63",
        "-11.752",
        "-15.089",
        "1.623",
        "1.803",
        "0.067"
    ],
    [
        "-150",
        "63",
        "-12.312",
        "-14.020",
        "1.534",
        "1.692",
        "0.030"
    ],
    [
        "-149",
        "63",
        "-12.328",
        "-14.046",
        "1.401",
        "1.709",
        "-0.019"
    ],
    [
        "-148",
        "63",
        "-11.936",
        "-14.304",
        "1.297",
        "1.707",
        "-0.049"
    ],
    [
        "-147",
        "63",
        "-11.387",
        "-14.661",
        "1.207",
        "1.660",
        "-0.047"
    ],
    [
        "-146",
        "63",
        "-10.935",
        "-15.319",
        "0.991",
        "1.445",
        "-0.074"
    ],
    [
        "-145",
        "63",
        "-10.587",
        "-16.201",
        "0.858",
        "1.311",
        "-0.080"
    ],
    [
        "-144",
        "63",
        "-10.078",
        "-18.496",
        "0.806",
        "1.130",
        "-0.097"
    ],
    [
        "-143",
        "63",
        "-9.821",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "63",
        "-10.131",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "63",
        "-10.438",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "63",
        "-10.742",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "63",
        "-11.043",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "63",
        "-11.340",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "63",
        "-11.634",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "63",
        "-11.924",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "63",
        "-12.212",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "63",
        "-12.495",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "63",
        "-12.774",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "63",
        "-13.050",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "63",
        "-13.322",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "63",
        "-13.588",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "63",
        "-13.853",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "63",
        "-14.112",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "63",
        "-14.366",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "63",
        "-14.617",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "63",
        "-14.863",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "63",
        "-15.105",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "63",
        "-15.343",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "63",
        "-15.575",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "63",
        "-15.803",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "63",
        "-16.026",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "63",
        "-16.244",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "63",
        "-16.458",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "63",
        "-16.666",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "63",
        "-16.869",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "63",
        "-17.067",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "63",
        "-17.260",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "63",
        "-17.448",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "63",
        "-17.631",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "63",
        "-17.807",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "63",
        "-17.980",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "63",
        "-18.146",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "63",
        "-18.307",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "63",
        "-18.462",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "63",
        "-18.612",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "63",
        "-18.756",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "63",
        "-18.894",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "63",
        "-19.027",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "63",
        "-19.154",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "63",
        "-19.275",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "63",
        "-19.390",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "63",
        "-19.500",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "63",
        "-19.603",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "63",
        "-19.700",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "63",
        "-19.792",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "63",
        "-19.878",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "63",
        "-19.957",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "63",
        "-20.031",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "63",
        "-20.098",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "63",
        "-20.160",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "63",
        "-20.215",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "63",
        "-20.264",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "63",
        "-20.307",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "63",
        "-20.344",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "63",
        "-20.374",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "63",
        "-20.399",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "63",
        "-20.417",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "63",
        "-20.429",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "63",
        "-20.435",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "63",
        "-20.435",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "63",
        "-20.429",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "63",
        "-20.416",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "63",
        "-20.397",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "63",
        "-20.372",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "63",
        "-20.341",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "63",
        "-20.304",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "63",
        "-20.261",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "63",
        "-20.210",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "63",
        "-20.155",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "63",
        "-20.093",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "63",
        "-20.025",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "63",
        "-19.951",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "63",
        "-19.871",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "63",
        "-19.784",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "63",
        "-19.693",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "63",
        "-19.594",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "63",
        "-19.490",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "63",
        "-19.381",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "63",
        "-19.265",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "63",
        "-19.143",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "63",
        "-19.016",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "63",
        "-18.883",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "63",
        "-18.744",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "63",
        "-18.600",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "63",
        "-18.449",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "63",
        "-18.294",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "63",
        "-18.132",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "63",
        "-17.966",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "63",
        "-17.793",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "63",
        "-17.615",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "63",
        "-17.433",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "63",
        "-17.245",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "63",
        "-17.051",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "63",
        "-16.852",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "63",
        "-16.649",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "63",
        "-16.440",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "63",
        "-16.226",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "63",
        "-16.007",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "63",
        "-15.784",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "63",
        "-15.556",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "63",
        "-15.323",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "63",
        "-15.085",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "63",
        "-14.844",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "63",
        "-14.597",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "63",
        "-14.345",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "63",
        "-14.090",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "63",
        "-13.830",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "63",
        "-13.566",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "63",
        "-13.299",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "63",
        "-13.027",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "63",
        "-12.751",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "63",
        "-12.471",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "63",
        "-12.188",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "63",
        "-11.900",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "63",
        "-11.610",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "63",
        "-10.174",
        "19.132",
        "0.769",
        "1.095",
        "-0.395"
    ],
    [
        "-24",
        "63",
        "7.463",
        "16.975",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "63",
        "7.728",
        "17.043",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "63",
        "7.994",
        "17.107",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "63",
        "8.260",
        "17.165",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "63",
        "8.527",
        "17.218",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "63",
        "8.795",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "63",
        "9.065",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "63",
        "9.334",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "63",
        "9.604",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "63",
        "9.874",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "63",
        "10.145",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "63",
        "10.416",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "63",
        "10.687",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "63",
        "10.959",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "63",
        "11.231",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "63",
        "11.502",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "63",
        "11.773",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "63",
        "12.045",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "63",
        "12.315",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "63",
        "12.586",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "63",
        "12.855",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "63",
        "13.125",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "63",
        "13.394",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "63",
        "13.662",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "63",
        "13.929",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "63",
        "14.196",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "63",
        "14.462",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "63",
        "14.726",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "63",
        "14.989",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "63",
        "15.251",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "63",
        "15.512",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "63",
        "15.773",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "63",
        "16.030",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "63",
        "16.287",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "63",
        "16.542",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "63",
        "16.796",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "63",
        "17.047",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "63",
        "17.297",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "63",
        "17.546",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "63",
        "17.792",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "63",
        "18.036",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "63",
        "18.277",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "63",
        "18.517",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "63",
        "18.755",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "63",
        "18.989",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "63",
        "19.222",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "63",
        "19.453",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "63",
        "19.680",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "63",
        "19.906",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "63",
        "20.128",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "63",
        "20.348",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "63",
        "20.565",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "63",
        "20.778",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "63",
        "20.989",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "63",
        "21.197",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "63",
        "21.403",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "63",
        "21.604",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "63",
        "21.802",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "63",
        "21.998",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "63",
        "22.190",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "63",
        "22.379",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "63",
        "22.564",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "63",
        "22.745",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "63",
        "22.924",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "63",
        "23.098",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "63",
        "23.269",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "63",
        "23.436",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "63",
        "23.600",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "63",
        "23.759",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "63",
        "23.915",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "63",
        "24.067",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "63",
        "24.215",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "63",
        "24.359",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "63",
        "24.499",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "63",
        "24.635",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "63",
        "24.766",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "63",
        "24.894",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "63",
        "25.016",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "63",
        "25.136",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "63",
        "25.250",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "63",
        "25.361",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "63",
        "25.467",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "63",
        "25.569",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "63",
        "25.666",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "63",
        "25.759",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "63",
        "25.847",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "63",
        "25.932",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "63",
        "26.011",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "63",
        "26.086",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "63",
        "26.157",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "63",
        "26.222",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "63",
        "26.283",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "63",
        "26.340",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "63",
        "26.391",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "63",
        "26.439",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "63",
        "26.481",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "63",
        "26.519",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "63",
        "26.552",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "63",
        "26.580",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "63",
        "26.604",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "63",
        "26.623",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "63",
        "26.638",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "63",
        "26.647",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "63",
        "26.652",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "63",
        "26.651",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "63",
        "26.647",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "63",
        "26.638",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "63",
        "26.624",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "63",
        "26.605",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "63",
        "26.581",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "63",
        "26.552",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "63",
        "26.520",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "63",
        "26.482",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "63",
        "26.439",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "63",
        "26.392",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "63",
        "26.340",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "63",
        "26.284",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "63",
        "26.223",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "63",
        "26.158",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "63",
        "26.087",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "63",
        "26.012",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "63",
        "25.933",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "63",
        "25.849",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "63",
        "25.761",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "63",
        "25.668",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "63",
        "25.571",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "63",
        "25.470",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "63",
        "25.363",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "63",
        "25.253",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "63",
        "25.138",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "63",
        "25.020",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "63",
        "24.896",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "63",
        "24.769",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "63",
        "24.637",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "63",
        "24.501",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "63",
        "24.362",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "63",
        "24.217",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "63",
        "24.070",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "63",
        "23.918",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "63",
        "23.762",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "63",
        "23.602",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "63",
        "23.440",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "63",
        "23.272",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "63",
        "23.102",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "63",
        "22.927",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "63",
        "22.749",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "63",
        "22.567",
        "-11.792",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "63",
        "22.383",
        "-12.015",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "63",
        "22.194",
        "-12.234",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "63",
        "22.002",
        "-12.450",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "63",
        "21.806",
        "-12.662",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "63",
        "21.608",
        "-12.870",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "63",
        "21.406",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "63",
        "21.201",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "63",
        "20.993",
        "-13.469",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "63",
        "20.783",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "63",
        "20.568",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "63",
        "20.352",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "63",
        "20.132",
        "-14.211",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "63",
        "19.910",
        "-14.386",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "63",
        "19.684",
        "-14.556",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "63",
        "19.457",
        "-14.722",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "63",
        "19.228",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "63",
        "18.995",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "63",
        "18.759",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "63",
        "18.522",
        "-15.340",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "63",
        "18.283",
        "-15.484",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "63",
        "18.015",
        "-15.644",
        "0.254",
        "0.306",
        "-0.010"
    ],
    [
        "144",
        "63",
        "17.523",
        "-16.199",
        "1.218",
        "1.310",
        "0.003"
    ],
    [
        "145",
        "63",
        "17.031",
        "-16.726",
        "1.297",
        "1.480",
        "0.003"
    ],
    [
        "146",
        "63",
        "16.510",
        "-17.226",
        "1.354",
        "1.521",
        "0.001"
    ],
    [
        "147",
        "63",
        "15.947",
        "-17.694",
        "1.416",
        "1.486",
        "0.002"
    ],
    [
        "148",
        "63",
        "15.294",
        "-18.128",
        "1.272",
        "1.383",
        "0.001"
    ],
    [
        "149",
        "63",
        "14.686",
        "-18.325",
        "1.303",
        "1.448",
        "-0.036"
    ],
    [
        "150",
        "63",
        "14.027",
        "-18.468",
        "1.321",
        "1.378",
        "-0.034"
    ],
    [
        "151",
        "63",
        "13.310",
        "-18.545",
        "1.133",
        "1.253",
        "0.006"
    ],
    [
        "152",
        "63",
        "12.558",
        "-18.658",
        "0.936",
        "1.047",
        "0.018"
    ],
    [
        "153",
        "63",
        "11.684",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "63",
        "11.393",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "63",
        "11.099",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "63",
        "10.801",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "63",
        "10.501",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "63",
        "10.196",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "63",
        "9.889",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "63",
        "9.578",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "63",
        "9.265",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "63",
        "8.948",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "63",
        "8.630",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "63",
        "8.308",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "63",
        "7.984",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "63",
        "7.657",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "63",
        "7.328",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "63",
        "6.997",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "63",
        "6.663",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "63",
        "6.327",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "63",
        "5.990",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "63",
        "5.651",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "63",
        "5.309",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "63",
        "4.967",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "63",
        "4.622",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "63",
        "4.277",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "63",
        "3.929",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "63",
        "3.582",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "63",
        "3.232",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "64",
        "2.911",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "64",
        "2.558",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "64",
        "2.201",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "64",
        "1.846",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "64",
        "1.489",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "64",
        "1.132",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "64",
        "0.775",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "64",
        "0.417",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "64",
        "0.059",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "64",
        "-0.299",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "64",
        "-0.656",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "64",
        "-1.014",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "64",
        "-1.372",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "64",
        "-1.729",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "64",
        "-2.085",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "64",
        "-2.441",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "64",
        "-2.796",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "64",
        "-3.150",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "64",
        "-3.504",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "64",
        "-3.856",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "64",
        "-4.208",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "64",
        "-4.557",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "64",
        "-4.906",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "64",
        "-5.254",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "64",
        "-5.598",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "64",
        "-5.943",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "64",
        "-6.285",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "64",
        "-6.625",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "64",
        "-7.721",
        "-19.522",
        "1.130",
        "1.542",
        "0.069"
    ],
    [
        "-151",
        "64",
        "-8.303",
        "-17.858",
        "1.241",
        "1.590",
        "0.042"
    ],
    [
        "-150",
        "64",
        "-8.646",
        "-17.292",
        "1.295",
        "1.584",
        "-0.004"
    ],
    [
        "-149",
        "64",
        "-8.852",
        "-17.709",
        "1.225",
        "1.559",
        "-0.053"
    ],
    [
        "-148",
        "64",
        "-8.944",
        "-18.350",
        "1.154",
        "1.495",
        "-0.077"
    ],
    [
        "-147",
        "64",
        "-8.987",
        "-18.957",
        "1.067",
        "1.413",
        "-0.064"
    ],
    [
        "-146",
        "64",
        "-9.021",
        "-19.613",
        "0.827",
        "1.299",
        "-0.001"
    ],
    [
        "-145",
        "64",
        "-9.268",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "64",
        "-9.586",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "64",
        "-9.902",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "64",
        "-10.215",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "64",
        "-10.525",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "64",
        "-10.831",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "64",
        "-11.134",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "64",
        "-11.435",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "64",
        "-11.731",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "64",
        "-12.024",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "64",
        "-12.313",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "64",
        "-12.599",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "64",
        "-12.881",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "64",
        "-13.159",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "64",
        "-13.433",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "64",
        "-13.703",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "64",
        "-13.969",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "64",
        "-14.230",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "64",
        "-14.488",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "64",
        "-14.741",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "64",
        "-14.989",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "64",
        "-15.233",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "64",
        "-15.472",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "64",
        "-15.707",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "64",
        "-15.937",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "64",
        "-16.162",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "64",
        "-16.381",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "64",
        "-16.597",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "64",
        "-16.807",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "64",
        "-17.012",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "64",
        "-17.212",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "64",
        "-17.407",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "64",
        "-17.596",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "64",
        "-17.780",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "64",
        "-17.959",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "64",
        "-18.133",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "64",
        "-18.300",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "64",
        "-18.462",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "64",
        "-18.619",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "64",
        "-18.769",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "64",
        "-18.915",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "64",
        "-19.055",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "64",
        "-19.188",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "64",
        "-19.317",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "64",
        "-19.439",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "64",
        "-19.555",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "64",
        "-19.665",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "64",
        "-19.770",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "64",
        "-19.868",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "64",
        "-19.961",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "64",
        "-20.047",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "64",
        "-20.127",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "64",
        "-20.201",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "64",
        "-20.269",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "64",
        "-20.331",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "64",
        "-20.386",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "64",
        "-20.436",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "64",
        "-20.480",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "64",
        "-20.517",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "64",
        "-20.547",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "64",
        "-20.573",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "64",
        "-20.591",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "64",
        "-20.603",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "64",
        "-20.610",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "64",
        "-20.609",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "64",
        "-20.602",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "64",
        "-20.589",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "64",
        "-20.571",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "64",
        "-20.545",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "64",
        "-20.514",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "64",
        "-20.476",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "64",
        "-20.433",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "64",
        "-20.383",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "64",
        "-20.326",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "64",
        "-20.264",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "64",
        "-20.196",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "64",
        "-20.120",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "64",
        "-20.040",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "64",
        "-19.953",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "64",
        "-19.860",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "64",
        "-19.762",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "64",
        "-19.656",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "64",
        "-19.545",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "64",
        "-19.428",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "64",
        "-19.306",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "64",
        "-19.178",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "64",
        "-19.043",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "64",
        "-18.903",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "64",
        "-18.758",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "64",
        "-18.606",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "64",
        "-18.449",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "64",
        "-18.286",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "64",
        "-18.118",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "64",
        "-17.944",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "64",
        "-17.765",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "64",
        "-17.580",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "64",
        "-17.391",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "64",
        "-17.195",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "64",
        "-16.995",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "64",
        "-16.789",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "64",
        "-16.579",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "64",
        "-16.364",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "64",
        "-16.142",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "64",
        "-15.918",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "64",
        "-15.687",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "64",
        "-15.452",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "64",
        "-15.213",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "64",
        "-14.969",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "64",
        "-14.720",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "64",
        "-14.466",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "64",
        "-14.209",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "64",
        "-13.946",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "64",
        "-13.680",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "64",
        "-13.411",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "64",
        "-13.136",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "64",
        "-12.858",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "64",
        "-12.576",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "64",
        "-12.289",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "64",
        "-12.000",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "64",
        "-11.707",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "64",
        "-11.410",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "64",
        "-11.110",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "64",
        "-10.806",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "64",
        "-5.020",
        "19.835",
        "2.022",
        "1.183",
        "0.023"
    ],
    [
        "-21",
        "64",
        "0.786",
        "17.962",
        "3.135",
        "4.118",
        "-0.102"
    ],
    [
        "-20",
        "64",
        "4.216",
        "17.662",
        "2.760",
        "5.033",
        "-0.056"
    ],
    [
        "-19",
        "64",
        "8.393",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "64",
        "8.665",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "64",
        "8.936",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "64",
        "9.208",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "64",
        "9.482",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "64",
        "9.755",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "64",
        "10.028",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "64",
        "10.302",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "64",
        "10.575",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "64",
        "10.850",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "64",
        "11.124",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "64",
        "11.397",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "64",
        "11.670",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "64",
        "11.944",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "64",
        "12.216",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "64",
        "12.489",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "64",
        "12.761",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "64",
        "13.032",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "64",
        "13.302",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "64",
        "13.572",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "64",
        "13.841",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "64",
        "14.109",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "64",
        "14.376",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "64",
        "14.641",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "64",
        "14.906",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "64",
        "15.169",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "64",
        "15.431",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "64",
        "15.692",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "64",
        "15.950",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "64",
        "16.208",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "64",
        "16.464",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "64",
        "16.718",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "64",
        "16.970",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "64",
        "17.219",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "64",
        "17.468",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "64",
        "17.714",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "64",
        "17.958",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "64",
        "18.200",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "64",
        "18.439",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "64",
        "18.677",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "64",
        "18.912",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "64",
        "19.144",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "64",
        "19.374",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "64",
        "19.601",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "64",
        "19.825",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "64",
        "20.046",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "64",
        "20.265",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "64",
        "20.482",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "64",
        "20.694",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "64",
        "20.904",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "64",
        "21.110",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "64",
        "21.314",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "64",
        "21.514",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "64",
        "21.712",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "64",
        "21.905",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "64",
        "22.096",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "64",
        "22.282",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "64",
        "22.465",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "64",
        "22.645",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "64",
        "22.821",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "64",
        "22.994",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "64",
        "23.162",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "64",
        "23.327",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "64",
        "23.488",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "64",
        "23.646",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "64",
        "23.798",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "64",
        "23.948",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "64",
        "24.093",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "64",
        "24.234",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "64",
        "24.370",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "64",
        "24.503",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "64",
        "24.633",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "64",
        "24.757",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "64",
        "24.877",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "64",
        "24.992",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "64",
        "25.104",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "64",
        "25.211",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "64",
        "25.314",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "64",
        "25.412",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "64",
        "25.505",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "64",
        "25.595",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "64",
        "25.679",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "64",
        "25.759",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "64",
        "25.835",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "64",
        "25.906",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "64",
        "25.972",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "64",
        "26.034",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "64",
        "26.091",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "64",
        "26.143",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "64",
        "26.191",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "64",
        "26.234",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "64",
        "26.272",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "64",
        "26.305",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "64",
        "26.334",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "64",
        "26.358",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "64",
        "26.378",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "64",
        "26.392",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "64",
        "26.401",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "64",
        "26.406",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "64",
        "26.406",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "64",
        "26.401",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "64",
        "26.392",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "64",
        "26.378",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "64",
        "26.358",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "64",
        "26.334",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "64",
        "26.306",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "64",
        "26.272",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "64",
        "26.234",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "64",
        "26.192",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "64",
        "26.144",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "64",
        "26.092",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "64",
        "26.035",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "64",
        "25.973",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "64",
        "25.908",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "64",
        "25.836",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "64",
        "25.761",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "64",
        "25.681",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "64",
        "25.597",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "64",
        "25.507",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "64",
        "25.413",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "64",
        "25.316",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "64",
        "25.213",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "64",
        "25.106",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "64",
        "24.995",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "64",
        "24.879",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "64",
        "24.759",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "64",
        "24.635",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "64",
        "24.506",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "64",
        "24.374",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "64",
        "24.237",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "64",
        "24.095",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "64",
        "23.950",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "64",
        "23.802",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "64",
        "23.649",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "64",
        "23.491",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "64",
        "23.330",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "64",
        "23.165",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "64",
        "22.997",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "64",
        "22.825",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "64",
        "22.649",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "64",
        "22.469",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "64",
        "22.286",
        "-11.792",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "64",
        "22.099",
        "-12.015",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "64",
        "21.909",
        "-12.234",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "64",
        "21.715",
        "-12.450",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "64",
        "21.518",
        "-12.662",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "64",
        "21.318",
        "-12.870",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "64",
        "21.115",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "64",
        "20.908",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "64",
        "20.698",
        "-13.469",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "64",
        "20.485",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "64",
        "20.270",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "64",
        "20.051",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "64",
        "19.829",
        "-14.211",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "64",
        "19.605",
        "-14.386",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "64",
        "19.378",
        "-14.556",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "64",
        "19.149",
        "-14.722",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "64",
        "18.916",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "64",
        "18.682",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "64",
        "18.444",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "64",
        "18.205",
        "-15.340",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "64",
        "17.963",
        "-15.484",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "64",
        "17.685",
        "-15.640",
        "0.254",
        "0.305",
        "-0.002"
    ],
    [
        "144",
        "64",
        "17.116",
        "-16.167",
        "1.211",
        "1.303",
        "-0.002"
    ],
    [
        "145",
        "64",
        "16.519",
        "-16.651",
        "1.291",
        "1.471",
        "-0.005"
    ],
    [
        "146",
        "64",
        "15.893",
        "-17.066",
        "1.350",
        "1.515",
        "-0.009"
    ],
    [
        "147",
        "64",
        "15.232",
        "-17.418",
        "1.421",
        "1.494",
        "-0.015"
    ],
    [
        "148",
        "64",
        "14.534",
        "-17.698",
        "1.325",
        "1.473",
        "-0.026"
    ],
    [
        "149",
        "64",
        "13.787",
        "-17.884",
        "1.227",
        "1.387",
        "-0.030"
    ],
    [
        "150",
        "64",
        "13.073",
        "-17.978",
        "1.172",
        "1.234",
        "0.020"
    ],
    [
        "151",
        "64",
        "12.398",
        "-18.104",
        "0.250",
        "0.311",
        "-0.012"
    ],
    [
        "152",
        "64",
        "12.081",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "64",
        "11.790",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "64",
        "11.498",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "64",
        "11.200",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "64",
        "10.900",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "64",
        "10.597",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "64",
        "10.290",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "64",
        "9.980",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "64",
        "9.666",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "64",
        "9.350",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "64",
        "9.031",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "64",
        "8.710",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "64",
        "8.385",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "64",
        "8.058",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "64",
        "7.729",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "64",
        "7.396",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "64",
        "7.063",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "64",
        "6.726",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "64",
        "6.388",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "64",
        "6.047",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "64",
        "5.705",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "64",
        "5.361",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "64",
        "5.015",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "64",
        "4.668",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "64",
        "4.319",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "64",
        "3.969",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "64",
        "3.618",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "64",
        "3.265",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "65",
        "2.940",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "65",
        "2.583",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "65",
        "2.224",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "65",
        "1.866",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "65",
        "1.507",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "65",
        "1.146",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "65",
        "0.786",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "65",
        "0.426",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "65",
        "0.064",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "65",
        "-0.296",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "65",
        "-0.657",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "65",
        "-1.018",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "65",
        "-1.378",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "65",
        "-1.738",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "65",
        "-2.097",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "65",
        "-2.456",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "65",
        "-2.815",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "65",
        "-3.172",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "65",
        "-3.528",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "65",
        "-3.884",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "65",
        "-4.238",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "65",
        "-4.591",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "65",
        "-4.943",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "65",
        "-5.292",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "65",
        "-5.641",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "65",
        "-5.988",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "65",
        "-6.333",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "65",
        "-6.744",
        "-21.473",
        "0.840",
        "1.115",
        "0.143"
    ],
    [
        "-152",
        "65",
        "-7.128",
        "-20.679",
        "1.031",
        "1.296",
        "0.065"
    ],
    [
        "-151",
        "65",
        "-7.366",
        "-19.836",
        "1.121",
        "1.384",
        "0.024"
    ],
    [
        "-150",
        "65",
        "-7.514",
        "-19.345",
        "1.169",
        "1.465",
        "0.019"
    ],
    [
        "-149",
        "65",
        "-7.737",
        "-19.564",
        "1.036",
        "1.407",
        "-0.013"
    ],
    [
        "-148",
        "65",
        "-8.107",
        "-20.570",
        "0.767",
        "1.081",
        "-0.006"
    ],
    [
        "-147",
        "65",
        "-8.689",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "65",
        "-9.016",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "65",
        "-9.340",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "65",
        "-9.662",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "65",
        "-9.980",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "65",
        "-10.296",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "65",
        "-10.608",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "65",
        "-10.917",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "65",
        "-11.223",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "65",
        "-11.526",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "65",
        "-11.824",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "65",
        "-12.120",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "65",
        "-12.412",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "65",
        "-12.700",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "65",
        "-12.984",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "65",
        "-13.264",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "65",
        "-13.541",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "65",
        "-13.813",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "65",
        "-14.081",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "65",
        "-14.345",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "65",
        "-14.604",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "65",
        "-14.859",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "65",
        "-15.110",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "65",
        "-15.356",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "65",
        "-15.597",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "65",
        "-15.833",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "65",
        "-16.065",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "65",
        "-16.292",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "65",
        "-16.514",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "65",
        "-16.731",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "65",
        "-16.943",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "65",
        "-17.149",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "65",
        "-17.351",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "65",
        "-17.547",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "65",
        "-17.738",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "65",
        "-17.924",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "65",
        "-18.105",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "65",
        "-18.280",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "65",
        "-18.448",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "65",
        "-18.612",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "65",
        "-18.769",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "65",
        "-18.922",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "65",
        "-19.069",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "65",
        "-19.209",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "65",
        "-19.345",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "65",
        "-19.473",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "65",
        "-19.597",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "65",
        "-19.714",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "65",
        "-19.825",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "65",
        "-19.930",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "65",
        "-20.029",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "65",
        "-20.123",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "65",
        "-20.210",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "65",
        "-20.290",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "65",
        "-20.366",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "65",
        "-20.434",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "65",
        "-20.497",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "65",
        "-20.552",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "65",
        "-20.603",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "65",
        "-20.647",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "65",
        "-20.684",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "65",
        "-20.715",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "65",
        "-20.740",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "65",
        "-20.758",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "65",
        "-20.771",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "65",
        "-20.777",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "65",
        "-20.777",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "65",
        "-20.770",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "65",
        "-20.757",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "65",
        "-20.738",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "65",
        "-20.713",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "65",
        "-20.681",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "65",
        "-20.643",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "65",
        "-20.599",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "65",
        "-20.549",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "65",
        "-20.492",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "65",
        "-20.428",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "65",
        "-20.360",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "65",
        "-20.284",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "65",
        "-20.203",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "65",
        "-20.115",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "65",
        "-20.022",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "65",
        "-19.921",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "65",
        "-19.816",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "65",
        "-19.704",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "65",
        "-19.586",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "65",
        "-19.462",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "65",
        "-19.333",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "65",
        "-19.198",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "65",
        "-19.056",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "65",
        "-18.910",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "65",
        "-18.757",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "65",
        "-18.598",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "65",
        "-18.434",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "65",
        "-18.265",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "65",
        "-18.090",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "65",
        "-17.909",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "65",
        "-17.723",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "65",
        "-17.531",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "65",
        "-17.335",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "65",
        "-17.132",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "65",
        "-16.925",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "65",
        "-16.713",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "65",
        "-16.495",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "65",
        "-16.273",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "65",
        "-16.045",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "65",
        "-15.814",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "65",
        "-15.577",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "65",
        "-15.335",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "65",
        "-15.089",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "65",
        "-14.838",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "65",
        "-14.583",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "65",
        "-14.323",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "65",
        "-14.058",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "65",
        "-13.791",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "65",
        "-13.518",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "65",
        "-13.241",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "65",
        "-12.960",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "65",
        "-12.676",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "65",
        "-12.388",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "65",
        "-12.096",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "65",
        "-11.799",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "65",
        "-11.501",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "65",
        "-11.198",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "65",
        "-10.891",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "65",
        "-10.582",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "65",
        "-10.269",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "65",
        "-9.953",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "65",
        "-5.457",
        "17.602",
        "3.233",
        "5.831",
        "0.055"
    ],
    [
        "-18",
        "65",
        "0.558",
        "17.244",
        "3.760",
        "5.236",
        "0.151"
    ],
    [
        "-17",
        "65",
        "7.656",
        "17.315",
        "0.871",
        "1.182",
        "0.199"
    ],
    [
        "-16",
        "65",
        "8.811",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "65",
        "9.086",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "65",
        "9.362",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "65",
        "9.637",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "65",
        "9.913",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "65",
        "10.190",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "65",
        "10.465",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "65",
        "10.742",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "65",
        "11.018",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "65",
        "11.294",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "65",
        "11.569",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "65",
        "11.844",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "65",
        "12.119",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "65",
        "12.393",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "65",
        "12.666",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "65",
        "12.939",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "65",
        "13.211",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "65",
        "13.482",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "65",
        "13.753",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "65",
        "14.021",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "65",
        "14.289",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "65",
        "14.556",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "65",
        "14.821",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "65",
        "15.085",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "65",
        "15.348",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "65",
        "15.609",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "65",
        "15.869",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "65",
        "16.127",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "65",
        "16.383",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "65",
        "16.637",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "65",
        "16.889",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "65",
        "17.139",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "65",
        "17.388",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "65",
        "17.634",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "65",
        "17.878",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "65",
        "18.119",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "65",
        "18.359",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "65",
        "18.595",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "65",
        "18.830",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "65",
        "19.060",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "65",
        "19.290",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "65",
        "19.516",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "65",
        "19.740",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "65",
        "19.961",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "65",
        "20.178",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "65",
        "20.392",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "65",
        "20.604",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "65",
        "20.812",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "65",
        "21.018",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "65",
        "21.220",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "65",
        "21.418",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "65",
        "21.614",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "65",
        "21.806",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "65",
        "21.994",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "65",
        "22.179",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "65",
        "22.360",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "65",
        "22.537",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "65",
        "22.711",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "65",
        "22.882",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "65",
        "23.047",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "65",
        "23.209",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "65",
        "23.368",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "65",
        "23.522",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "65",
        "23.673",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "65",
        "23.819",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "65",
        "23.962",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "65",
        "24.100",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "65",
        "24.234",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "65",
        "24.363",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "65",
        "24.489",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "65",
        "24.610",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "65",
        "24.727",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "65",
        "24.839",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "65",
        "24.947",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "65",
        "25.051",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "65",
        "25.149",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "65",
        "25.244",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "65",
        "25.334",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "65",
        "25.419",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "65",
        "25.500",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "65",
        "25.576",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "65",
        "25.648",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "65",
        "25.715",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "65",
        "25.777",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "65",
        "25.834",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "65",
        "25.888",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "65",
        "25.936",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "65",
        "25.978",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "65",
        "26.017",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "65",
        "26.050",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "65",
        "26.079",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "65",
        "26.104",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "65",
        "26.122",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "65",
        "26.138",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "65",
        "26.147",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "65",
        "26.152",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "65",
        "26.152",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "65",
        "26.147",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "65",
        "26.137",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "65",
        "26.123",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "65",
        "26.104",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "65",
        "26.080",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "65",
        "26.051",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "65",
        "26.018",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "65",
        "25.979",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "65",
        "25.936",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "65",
        "25.888",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "65",
        "25.836",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "65",
        "25.779",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "65",
        "25.717",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "65",
        "25.650",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "65",
        "25.578",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "65",
        "25.502",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "65",
        "25.421",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "65",
        "25.336",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "65",
        "25.246",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "65",
        "25.152",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "65",
        "25.053",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "65",
        "24.949",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "65",
        "24.842",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "65",
        "24.729",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "65",
        "24.613",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "65",
        "24.492",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "65",
        "24.367",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "65",
        "24.237",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "65",
        "24.102",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "65",
        "23.965",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "65",
        "23.822",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "65",
        "23.676",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "65",
        "23.525",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "65",
        "23.372",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "65",
        "23.213",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "65",
        "23.051",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "65",
        "22.885",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "65",
        "22.715",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "65",
        "22.541",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "65",
        "22.363",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "65",
        "22.182",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "65",
        "21.997",
        "-11.792",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "65",
        "21.809",
        "-12.015",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "65",
        "21.618",
        "-12.234",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "65",
        "21.422",
        "-12.450",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "65",
        "21.224",
        "-12.662",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "65",
        "21.022",
        "-12.870",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "65",
        "20.817",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "65",
        "20.608",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "65",
        "20.397",
        "-13.469",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "65",
        "20.182",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "65",
        "19.965",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "65",
        "19.744",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "65",
        "19.520",
        "-14.211",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "65",
        "19.294",
        "-14.386",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "65",
        "19.065",
        "-14.556",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "65",
        "18.834",
        "-14.722",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "65",
        "18.599",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "65",
        "18.363",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "65",
        "18.124",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "65",
        "17.883",
        "-15.340",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "65",
        "17.639",
        "-15.484",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "65",
        "17.353",
        "-15.641",
        "0.248",
        "0.298",
        "-0.005"
    ],
    [
        "144",
        "65",
        "16.703",
        "-16.130",
        "1.184",
        "1.281",
        "-0.010"
    ],
    [
        "145",
        "65",
        "16.017",
        "-16.561",
        "1.257",
        "1.435",
        "-0.017"
    ],
    [
        "146",
        "65",
        "15.313",
        "-16.908",
        "1.297",
        "1.456",
        "-0.024"
    ],
    [
        "147",
        "65",
        "14.595",
        "-17.189",
        "1.327",
        "1.403",
        "-0.020"
    ],
    [
        "148",
        "65",
        "13.898",
        "-17.409",
        "1.146",
        "1.320",
        "-0.006"
    ],
    [
        "149",
        "65",
        "13.209",
        "-17.606",
        "0.761",
        "0.784",
        "0.006"
    ],
    [
        "150",
        "65",
        "12.759",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "65",
        "12.474",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "65",
        "12.186",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "65",
        "11.894",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "65",
        "11.598",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "65",
        "11.299",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "65",
        "10.996",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "65",
        "10.689",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "65",
        "10.381",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "65",
        "10.067",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "65",
        "9.752",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "65",
        "9.433",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "65",
        "9.112",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "65",
        "8.787",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "65",
        "8.460",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "65",
        "8.130",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "65",
        "7.798",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "65",
        "7.463",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "65",
        "7.126",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "65",
        "6.787",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "65",
        "6.446",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "65",
        "6.102",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "65",
        "5.757",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "65",
        "5.410",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "65",
        "5.061",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "65",
        "4.711",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "65",
        "4.359",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "65",
        "4.006",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "65",
        "3.653",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "65",
        "3.297",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "66",
        "2.969",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "66",
        "2.609",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "66",
        "2.247",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "66",
        "1.885",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "66",
        "1.523",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "66",
        "1.160",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "66",
        "0.797",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "66",
        "0.433",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "66",
        "0.070",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "66",
        "-0.294",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "66",
        "-0.658",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "66",
        "-1.021",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "66",
        "-1.385",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "66",
        "-1.747",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "66",
        "-2.110",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "66",
        "-2.471",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "66",
        "-2.832",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "66",
        "-3.192",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "66",
        "-3.552",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "66",
        "-3.910",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "66",
        "-4.267",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "66",
        "-4.623",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "66",
        "-4.978",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "66",
        "-5.330",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "66",
        "-5.682",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "66",
        "-6.031",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "66",
        "-6.379",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "66",
        "-6.724",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "66",
        "-7.069",
        "-21.500",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "66",
        "-7.410",
        "-21.363",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "66",
        "-7.628",
        "-20.772",
        "0.798",
        "1.161",
        "0.135"
    ],
    [
        "-149",
        "66",
        "-7.912",
        "-20.434",
        "0.849",
        "1.276",
        "0.068"
    ],
    [
        "-148",
        "66",
        "-8.355",
        "-20.721",
        "0.537",
        "0.797",
        "0.077"
    ],
    [
        "-147",
        "66",
        "-8.754",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "66",
        "-9.083",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "66",
        "-9.411",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "66",
        "-9.735",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "66",
        "-10.055",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "66",
        "-10.373",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "66",
        "-10.689",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "66",
        "-11.000",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "66",
        "-11.308",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "66",
        "-11.613",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "66",
        "-11.915",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "66",
        "-12.212",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "66",
        "-12.507",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "66",
        "-12.796",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "66",
        "-13.083",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "66",
        "-13.366",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "66",
        "-13.645",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "66",
        "-13.918",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "66",
        "-14.188",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "66",
        "-14.454",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "66",
        "-14.716",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "66",
        "-14.974",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "66",
        "-15.225",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "66",
        "-15.473",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "66",
        "-15.717",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "66",
        "-15.955",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "66",
        "-16.189",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "66",
        "-16.418",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "66",
        "-16.642",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "66",
        "-16.860",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "66",
        "-17.073",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "66",
        "-17.282",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "66",
        "-17.485",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "66",
        "-17.683",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "66",
        "-17.875",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "66",
        "-18.062",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "66",
        "-18.244",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "66",
        "-18.421",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "66",
        "-18.591",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "66",
        "-18.756",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "66",
        "-18.915",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "66",
        "-19.068",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "66",
        "-19.217",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "66",
        "-19.359",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "66",
        "-19.495",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "66",
        "-19.624",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "66",
        "-19.749",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "66",
        "-19.866",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "66",
        "-19.978",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "66",
        "-20.085",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "66",
        "-20.185",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "66",
        "-20.279",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "66",
        "-20.367",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "66",
        "-20.448",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "66",
        "-20.523",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "66",
        "-20.592",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "66",
        "-20.655",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "66",
        "-20.713",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "66",
        "-20.762",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "66",
        "-20.807",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "66",
        "-20.845",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "66",
        "-20.876",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "66",
        "-20.901",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "66",
        "-20.920",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "66",
        "-20.932",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "66",
        "-20.938",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "66",
        "-20.938",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "66",
        "-20.931",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "66",
        "-20.918",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "66",
        "-20.899",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "66",
        "-20.873",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "66",
        "-20.841",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "66",
        "-20.803",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "66",
        "-20.759",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "66",
        "-20.708",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "66",
        "-20.651",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "66",
        "-20.588",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "66",
        "-20.518",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "66",
        "-20.442",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "66",
        "-20.359",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "66",
        "-20.271",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "66",
        "-20.177",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "66",
        "-20.076",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "66",
        "-19.970",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "66",
        "-19.857",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "66",
        "-19.739",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "66",
        "-19.614",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "66",
        "-19.483",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "66",
        "-19.346",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "66",
        "-19.204",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "66",
        "-19.056",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "66",
        "-18.902",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "66",
        "-18.743",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "66",
        "-18.577",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "66",
        "-18.406",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "66",
        "-18.230",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "66",
        "-18.047",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "66",
        "-17.860",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "66",
        "-17.666",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "66",
        "-17.468",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "66",
        "-17.265",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "66",
        "-17.055",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "66",
        "-16.842",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "66",
        "-16.622",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "66",
        "-16.399",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "66",
        "-16.169",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "66",
        "-15.936",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "66",
        "-15.697",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "66",
        "-15.453",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "66",
        "-15.204",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "66",
        "-14.952",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "66",
        "-14.694",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "66",
        "-14.432",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "66",
        "-14.167",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "66",
        "-13.895",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "66",
        "-13.621",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "66",
        "-13.342",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "66",
        "-13.059",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "66",
        "-12.772",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "66",
        "-12.482",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "66",
        "-12.188",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "66",
        "-11.890",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "66",
        "-11.588",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "66",
        "-11.283",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "66",
        "-10.973",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "66",
        "-10.662",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "66",
        "-10.346",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "66",
        "-10.028",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "66",
        "-9.708",
        "20.245",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "66",
        "-2.473",
        "20.162",
        "3.932",
        "4.107",
        "0.161"
    ],
    [
        "-17",
        "66",
        "5.612",
        "18.633",
        "3.380",
        "3.957",
        "0.223"
    ],
    [
        "-16",
        "66",
        "8.411",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "66",
        "8.688",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "66",
        "8.966",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "66",
        "9.244",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "66",
        "9.522",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "66",
        "9.800",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "66",
        "10.079",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "66",
        "10.357",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "66",
        "10.635",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "66",
        "10.913",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "66",
        "11.191",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "66",
        "11.468",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "66",
        "11.745",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "66",
        "12.021",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "66",
        "12.297",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "66",
        "12.571",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "66",
        "12.846",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "66",
        "13.119",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "66",
        "13.391",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "66",
        "13.662",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "66",
        "13.932",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "66",
        "14.202",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "66",
        "14.470",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "66",
        "14.735",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "66",
        "15.000",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "66",
        "15.263",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "66",
        "15.524",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "66",
        "15.784",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "66",
        "16.042",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "66",
        "16.300",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "66",
        "16.553",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "66",
        "16.806",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "66",
        "17.056",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "66",
        "17.304",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "66",
        "17.550",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "66",
        "17.793",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "66",
        "18.034",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "66",
        "18.273",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "66",
        "18.509",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "66",
        "18.742",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "66",
        "18.973",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "66",
        "19.201",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "66",
        "19.427",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "66",
        "19.649",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "66",
        "19.868",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "66",
        "20.085",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "66",
        "20.297",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "66",
        "20.508",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "66",
        "20.715",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "66",
        "20.919",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "66",
        "21.119",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "66",
        "21.315",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "66",
        "21.509",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "66",
        "21.699",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "66",
        "21.886",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "66",
        "22.068",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "66",
        "22.247",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "66",
        "22.422",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "66",
        "22.593",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "66",
        "22.761",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "66",
        "22.925",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "66",
        "23.084",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "66",
        "23.240",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "66",
        "23.391",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "66",
        "23.539",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "66",
        "23.682",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "66",
        "23.822",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "66",
        "23.957",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "66",
        "24.088",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "66",
        "24.213",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "66",
        "24.336",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "66",
        "24.454",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "66",
        "24.567",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "66",
        "24.675",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "66",
        "24.780",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "66",
        "24.879",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "66",
        "24.975",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "66",
        "25.066",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "66",
        "25.152",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "66",
        "25.234",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "66",
        "25.310",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "66",
        "25.382",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "66",
        "25.450",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "66",
        "25.512",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "66",
        "25.570",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "66",
        "25.623",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "66",
        "25.671",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "66",
        "25.715",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "66",
        "25.754",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "66",
        "25.787",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "66",
        "25.817",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "66",
        "25.841",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "66",
        "25.861",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "66",
        "25.875",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "66",
        "25.886",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "66",
        "25.890",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "66",
        "25.890",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "66",
        "25.885",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "66",
        "25.876",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "66",
        "25.861",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "66",
        "25.841",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "66",
        "25.818",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "66",
        "25.789",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "66",
        "25.755",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "66",
        "25.716",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "66",
        "25.673",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "66",
        "25.624",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "66",
        "25.571",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "66",
        "25.513",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "66",
        "25.450",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "66",
        "25.384",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "66",
        "25.311",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "66",
        "25.235",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "66",
        "25.154",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "66",
        "25.067",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "66",
        "24.977",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "66",
        "24.881",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "66",
        "24.782",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "66",
        "24.678",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "66",
        "24.569",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "66",
        "24.456",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "66",
        "24.338",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "66",
        "24.216",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "66",
        "24.090",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "66",
        "23.959",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "66",
        "23.824",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "66",
        "23.685",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "66",
        "23.542",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "66",
        "23.395",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "66",
        "23.243",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "66",
        "23.088",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "66",
        "22.928",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "66",
        "22.764",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "66",
        "22.597",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "66",
        "22.425",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "66",
        "22.250",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "66",
        "22.072",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "66",
        "21.889",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "66",
        "21.703",
        "-11.792",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "66",
        "21.512",
        "-12.015",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "66",
        "21.319",
        "-12.234",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "66",
        "21.122",
        "-12.450",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "66",
        "20.922",
        "-12.662",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "66",
        "20.719",
        "-12.870",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "66",
        "20.512",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "66",
        "20.302",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "66",
        "20.089",
        "-13.469",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "66",
        "19.873",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "66",
        "19.653",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "66",
        "19.431",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "66",
        "19.206",
        "-14.211",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "66",
        "18.978",
        "-14.386",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "66",
        "18.747",
        "-14.556",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "66",
        "18.514",
        "-14.722",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "66",
        "18.278",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "66",
        "18.039",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "66",
        "17.798",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "66",
        "17.554",
        "-15.340",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "66",
        "17.309",
        "-15.484",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "66",
        "16.994",
        "-15.654",
        "0.234",
        "0.294",
        "-0.003"
    ],
    [
        "144",
        "66",
        "16.132",
        "-16.123",
        "1.118",
        "1.237",
        "-0.033"
    ],
    [
        "145",
        "66",
        "15.280",
        "-16.489",
        "1.114",
        "1.306",
        "-0.021"
    ],
    [
        "146",
        "66",
        "14.525",
        "-16.799",
        "1.061",
        "1.225",
        "-0.003"
    ],
    [
        "147",
        "66",
        "13.702",
        "-17.098",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "66",
        "13.427",
        "-17.359",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "66",
        "13.149",
        "-17.614",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "66",
        "12.865",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "66",
        "12.579",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "66",
        "12.288",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "66",
        "11.993",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "66",
        "11.695",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "66",
        "11.393",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "66",
        "11.088",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "66",
        "10.780",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "66",
        "10.468",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "66",
        "10.153",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "66",
        "9.835",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "66",
        "9.513",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "66",
        "9.189",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "66",
        "8.862",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "66",
        "8.532",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "66",
        "8.199",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "66",
        "7.865",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "66",
        "7.527",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "66",
        "7.187",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "66",
        "6.846",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "66",
        "6.502",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "66",
        "6.156",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "66",
        "5.808",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "66",
        "5.458",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "66",
        "5.107",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "66",
        "4.753",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "66",
        "4.399",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "66",
        "4.044",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "66",
        "3.687",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "66",
        "3.328",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "67",
        "2.996",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "67",
        "2.632",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "67",
        "2.268",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "67",
        "1.904",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "67",
        "1.539",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "67",
        "1.173",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "67",
        "0.808",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "67",
        "0.441",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "67",
        "0.075",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "67",
        "-0.292",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "67",
        "-0.658",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "67",
        "-1.025",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "67",
        "-1.391",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "67",
        "-1.756",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "67",
        "-2.121",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "67",
        "-2.485",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "67",
        "-2.849",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "67",
        "-3.213",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "67",
        "-3.574",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "67",
        "-3.936",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "67",
        "-4.296",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "67",
        "-4.654",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "67",
        "-5.010",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "67",
        "-5.367",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "67",
        "-5.720",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "67",
        "-6.073",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "67",
        "-6.423",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "67",
        "-6.771",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "67",
        "-7.118",
        "-21.500",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "67",
        "-7.462",
        "-21.363",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "67",
        "-7.804",
        "-21.220",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "67",
        "-8.144",
        "-21.072",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "67",
        "-8.481",
        "-20.915",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "67",
        "-8.816",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "67",
        "-9.149",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "67",
        "-9.477",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "67",
        "-9.804",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "67",
        "-10.127",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "67",
        "-10.447",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "67",
        "-10.765",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "67",
        "-11.079",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "67",
        "-11.390",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "67",
        "-11.697",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "67",
        "-12.001",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "67",
        "-12.301",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "67",
        "-12.597",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "67",
        "-12.889",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "67",
        "-13.179",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "67",
        "-13.463",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "67",
        "-13.744",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "67",
        "-14.020",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "67",
        "-14.292",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "67",
        "-14.560",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "67",
        "-14.824",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "67",
        "-15.082",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "67",
        "-15.337",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "67",
        "-15.587",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "67",
        "-15.832",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "67",
        "-16.072",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "67",
        "-16.308",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "67",
        "-16.538",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "67",
        "-16.763",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "67",
        "-16.984",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "67",
        "-17.199",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "67",
        "-17.409",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "67",
        "-17.614",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "67",
        "-17.813",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "67",
        "-18.007",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "67",
        "-18.196",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "67",
        "-18.379",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "67",
        "-18.556",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "67",
        "-18.728",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "67",
        "-18.894",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "67",
        "-19.054",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "67",
        "-19.210",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "67",
        "-19.358",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "67",
        "-19.501",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "67",
        "-19.638",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "67",
        "-19.770",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "67",
        "-19.895",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "67",
        "-20.013",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "67",
        "-20.127",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "67",
        "-20.234",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "67",
        "-20.335",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "67",
        "-20.428",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "67",
        "-20.517",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "67",
        "-20.600",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "67",
        "-20.675",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "67",
        "-20.745",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "67",
        "-20.808",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "67",
        "-20.865",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "67",
        "-20.917",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "67",
        "-20.960",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "67",
        "-20.998",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "67",
        "-21.031",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "67",
        "-21.055",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "67",
        "-21.074",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "67",
        "-21.087",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "67",
        "-21.093",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "67",
        "-21.093",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "67",
        "-21.086",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "67",
        "-21.073",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "67",
        "-21.054",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "67",
        "-21.028",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "67",
        "-20.995",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "67",
        "-20.958",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "67",
        "-20.912",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "67",
        "-20.861",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "67",
        "-20.803",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "67",
        "-20.739",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "67",
        "-20.670",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "67",
        "-20.593",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "67",
        "-20.510",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "67",
        "-20.421",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "67",
        "-20.326",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "67",
        "-20.225",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "67",
        "-20.118",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "67",
        "-20.003",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "67",
        "-19.884",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "67",
        "-19.759",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "67",
        "-19.627",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "67",
        "-19.489",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "67",
        "-19.346",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "67",
        "-19.197",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "67",
        "-19.042",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "67",
        "-18.881",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "67",
        "-18.714",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "67",
        "-18.542",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "67",
        "-18.364",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "67",
        "-18.181",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "67",
        "-17.991",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "67",
        "-17.796",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "67",
        "-17.597",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "67",
        "-17.392",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "67",
        "-17.181",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "67",
        "-16.966",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "67",
        "-16.745",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "67",
        "-16.519",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "67",
        "-16.288",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "67",
        "-16.053",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "67",
        "-15.812",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "67",
        "-15.566",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "67",
        "-15.316",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "67",
        "-15.061",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "67",
        "-14.801",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "67",
        "-14.538",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "67",
        "-14.270",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "67",
        "-13.997",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "67",
        "-13.721",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "67",
        "-13.439",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "67",
        "-13.154",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "67",
        "-12.865",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "67",
        "-12.572",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "67",
        "-12.276",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "67",
        "-11.976",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "67",
        "-11.672",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "67",
        "-11.363",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "67",
        "-11.053",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "67",
        "-10.739",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "67",
        "-10.421",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "67",
        "-10.101",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "67",
        "-9.777",
        "20.245",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "67",
        "7.451",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "67",
        "7.729",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "67",
        "8.008",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "67",
        "8.288",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "67",
        "8.567",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "67",
        "8.848",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "67",
        "9.128",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "67",
        "9.408",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "67",
        "9.689",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "67",
        "9.969",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "67",
        "10.250",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "67",
        "10.529",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "67",
        "10.809",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "67",
        "11.089",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "67",
        "11.368",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "67",
        "11.646",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "67",
        "11.924",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "67",
        "12.201",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "67",
        "12.477",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "67",
        "12.752",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "67",
        "13.027",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "67",
        "13.299",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "67",
        "13.572",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "67",
        "13.843",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "67",
        "14.113",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "67",
        "14.380",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "67",
        "14.648",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "67",
        "14.912",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "67",
        "15.177",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "67",
        "15.438",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "67",
        "15.698",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "67",
        "15.957",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "67",
        "16.212",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "67",
        "16.467",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "67",
        "16.718",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "67",
        "16.969",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "67",
        "17.216",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "67",
        "17.462",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "67",
        "17.705",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "67",
        "17.945",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "67",
        "18.184",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "67",
        "18.418",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "67",
        "18.651",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "67",
        "18.881",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "67",
        "19.107",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "67",
        "19.332",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "67",
        "19.553",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "67",
        "19.771",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "67",
        "19.985",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "67",
        "20.197",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "67",
        "20.405",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "67",
        "20.610",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "67",
        "20.813",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "67",
        "21.011",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "67",
        "21.206",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "67",
        "21.397",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "67",
        "21.585",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "67",
        "21.769",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "67",
        "21.949",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "67",
        "22.125",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "67",
        "22.298",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "67",
        "22.467",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "67",
        "22.632",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "67",
        "22.793",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "67",
        "22.950",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "67",
        "23.103",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "67",
        "23.251",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "67",
        "23.396",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "67",
        "23.537",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "67",
        "23.673",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "67",
        "23.804",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "67",
        "23.931",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "67",
        "24.055",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "67",
        "24.173",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "67",
        "24.287",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "67",
        "24.397",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "67",
        "24.502",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "67",
        "24.602",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "67",
        "24.698",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "67",
        "24.790",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "67",
        "24.877",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "67",
        "24.959",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "67",
        "25.036",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "67",
        "25.108",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "67",
        "25.176",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "67",
        "25.239",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "67",
        "25.297",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "67",
        "25.351",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "67",
        "25.400",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "67",
        "25.444",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "67",
        "25.483",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "67",
        "25.518",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "67",
        "25.547",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "67",
        "25.571",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "67",
        "25.591",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "67",
        "25.605",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "67",
        "25.616",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "67",
        "25.621",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "67",
        "25.621",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "67",
        "25.616",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "67",
        "25.606",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "67",
        "25.591",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "67",
        "25.572",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "67",
        "25.547",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "67",
        "25.518",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "67",
        "25.484",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "67",
        "25.445",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "67",
        "25.401",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "67",
        "25.352",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "67",
        "25.299",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "67",
        "25.240",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "67",
        "25.177",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "67",
        "25.110",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "67",
        "25.038",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "67",
        "24.960",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "67",
        "24.878",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "67",
        "24.792",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "67",
        "24.700",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "67",
        "24.605",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "67",
        "24.503",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "67",
        "24.399",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "67",
        "24.289",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "67",
        "24.176",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "67",
        "24.057",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "67",
        "23.934",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "67",
        "23.806",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "67",
        "23.674",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "67",
        "23.539",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "67",
        "23.399",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "67",
        "23.254",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "67",
        "23.106",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "67",
        "22.953",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "67",
        "22.796",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "67",
        "22.636",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "67",
        "22.471",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "67",
        "22.302",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "67",
        "22.129",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "67",
        "21.953",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "67",
        "21.773",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "67",
        "21.589",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "67",
        "21.401",
        "-11.792",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "67",
        "21.209",
        "-12.015",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "67",
        "21.015",
        "-12.234",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "67",
        "20.816",
        "-12.450",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "67",
        "20.615",
        "-12.662",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "67",
        "20.410",
        "-12.870",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "67",
        "20.201",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "67",
        "19.990",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "67",
        "19.775",
        "-13.469",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "67",
        "19.557",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "67",
        "19.336",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "67",
        "19.112",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "67",
        "18.886",
        "-14.211",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "67",
        "18.656",
        "-14.386",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "67",
        "18.423",
        "-14.556",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "67",
        "18.187",
        "-14.722",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "67",
        "17.949",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "67",
        "17.710",
        "-15.040",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "67",
        "17.467",
        "-15.193",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "67",
        "17.003",
        "-15.399",
        "0.683",
        "0.837",
        "-0.034"
    ],
    [
        "142",
        "67",
        "16.455",
        "-15.636",
        "0.826",
        "0.985",
        "0.019"
    ],
    [
        "143",
        "67",
        "15.829",
        "-15.833",
        "0.901",
        "1.074",
        "0.021"
    ],
    [
        "144",
        "67",
        "15.063",
        "-16.189",
        "0.937",
        "0.988",
        "-0.011"
    ],
    [
        "145",
        "67",
        "14.351",
        "-16.561",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "67",
        "14.084",
        "-16.832",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "67",
        "13.811",
        "-17.098",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "67",
        "13.534",
        "-17.359",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "67",
        "13.253",
        "-17.614",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "67",
        "12.968",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "67",
        "12.679",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "67",
        "12.386",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "67",
        "12.089",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "67",
        "11.789",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "67",
        "11.485",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "67",
        "11.178",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "67",
        "10.866",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "67",
        "10.552",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "67",
        "10.235",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "67",
        "9.914",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "67",
        "9.590",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "67",
        "9.263",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "67",
        "8.934",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "67",
        "8.601",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "67",
        "8.266",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "67",
        "7.929",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "67",
        "7.590",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "67",
        "7.247",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "67",
        "6.902",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "67",
        "6.555",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "67",
        "6.208",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "67",
        "5.857",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "67",
        "5.504",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "67",
        "5.150",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "67",
        "4.795",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "67",
        "4.437",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "67",
        "4.079",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "67",
        "3.718",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "67",
        "3.358",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "68",
        "3.022",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "68",
        "2.656",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "68",
        "2.290",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "68",
        "1.923",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "68",
        "1.555",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "68",
        "1.187",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "68",
        "0.818",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "68",
        "0.449",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "68",
        "0.080",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "68",
        "-0.289",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "68",
        "-0.659",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "68",
        "-1.028",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "68",
        "-1.396",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "68",
        "-1.764",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "68",
        "-2.132",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "68",
        "-2.499",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "68",
        "-2.865",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "68",
        "-3.231",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "68",
        "-3.596",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "68",
        "-3.959",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "68",
        "-4.322",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "68",
        "-4.683",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "68",
        "-5.043",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "68",
        "-5.401",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "68",
        "-5.757",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "68",
        "-6.112",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "68",
        "-6.465",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "68",
        "-6.816",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "68",
        "-7.164",
        "-21.500",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "68",
        "-7.511",
        "-21.363",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "68",
        "-7.856",
        "-21.220",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "68",
        "-8.198",
        "-21.072",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "68",
        "-8.538",
        "-20.915",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "68",
        "-8.876",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "68",
        "-9.210",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "68",
        "-9.541",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "68",
        "-9.870",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "68",
        "-10.196",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "68",
        "-10.519",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "68",
        "-10.839",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "68",
        "-11.154",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "68",
        "-11.468",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "68",
        "-11.777",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "68",
        "-12.084",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "68",
        "-12.386",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "68",
        "-12.684",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "68",
        "-12.979",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "68",
        "-13.270",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "68",
        "-13.556",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "68",
        "-13.839",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "68",
        "-14.117",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "68",
        "-14.391",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "68",
        "-14.661",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "68",
        "-14.927",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "68",
        "-15.187",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "68",
        "-15.444",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "68",
        "-15.696",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "68",
        "-15.942",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "68",
        "-16.184",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "68",
        "-16.421",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "68",
        "-16.654",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "68",
        "-16.880",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "68",
        "-17.102",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "68",
        "-17.319",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "68",
        "-17.531",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "68",
        "-17.737",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "68",
        "-17.938",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "68",
        "-18.133",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "68",
        "-18.324",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "68",
        "-18.508",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "68",
        "-18.686",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "68",
        "-18.859",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "68",
        "-19.027",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "68",
        "-19.188",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "68",
        "-19.344",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "68",
        "-19.494",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "68",
        "-19.638",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "68",
        "-19.776",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "68",
        "-19.908",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "68",
        "-20.034",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "68",
        "-20.154",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "68",
        "-20.268",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "68",
        "-20.375",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "68",
        "-20.478",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "68",
        "-20.573",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "68",
        "-20.661",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "68",
        "-20.744",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "68",
        "-20.821",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "68",
        "-20.891",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "68",
        "-20.955",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "68",
        "-21.012",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "68",
        "-21.063",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "68",
        "-21.108",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "68",
        "-21.146",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "68",
        "-21.179",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "68",
        "-21.204",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "68",
        "-21.223",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "68",
        "-21.235",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "68",
        "-21.242",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "68",
        "-21.242",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "68",
        "-21.234",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "68",
        "-21.222",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "68",
        "-21.202",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "68",
        "-21.176",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "68",
        "-21.143",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "68",
        "-21.105",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "68",
        "-21.059",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "68",
        "-21.007",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "68",
        "-20.950",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "68",
        "-20.886",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "68",
        "-20.814",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "68",
        "-20.737",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "68",
        "-20.654",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "68",
        "-20.565",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "68",
        "-20.469",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "68",
        "-20.367",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "68",
        "-20.258",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "68",
        "-20.145",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "68",
        "-20.023",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "68",
        "-19.897",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "68",
        "-19.765",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "68",
        "-19.626",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "68",
        "-19.481",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "68",
        "-19.332",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "68",
        "-19.175",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "68",
        "-19.013",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "68",
        "-18.845",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "68",
        "-18.672",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "68",
        "-18.492",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "68",
        "-18.307",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "68",
        "-18.117",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "68",
        "-17.922",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "68",
        "-17.720",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "68",
        "-17.513",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "68",
        "-17.301",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "68",
        "-17.084",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "68",
        "-16.862",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "68",
        "-16.634",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "68",
        "-16.402",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "68",
        "-16.164",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "68",
        "-15.922",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "68",
        "-15.674",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "68",
        "-15.423",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "68",
        "-15.166",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "68",
        "-14.905",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "68",
        "-14.639",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "68",
        "-14.368",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "68",
        "-14.094",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "68",
        "-13.815",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "68",
        "-13.532",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "68",
        "-13.245",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "68",
        "-12.954",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "68",
        "-12.659",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "68",
        "-12.361",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "68",
        "-12.058",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "68",
        "-11.752",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "68",
        "-11.442",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "68",
        "-11.128",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "68",
        "-10.812",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "68",
        "-10.492",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "68",
        "-10.169",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "68",
        "-9.075",
        "20.003",
        "1.255",
        "1.110",
        "-0.403"
    ],
    [
        "-18",
        "68",
        "7.041",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "68",
        "7.322",
        "17.345",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "68",
        "7.603",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "68",
        "7.884",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "68",
        "8.166",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "68",
        "8.448",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "68",
        "8.730",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "68",
        "9.013",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "68",
        "9.295",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "68",
        "9.577",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "68",
        "9.860",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "68",
        "10.142",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "68",
        "10.424",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "68",
        "10.705",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "68",
        "10.986",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "68",
        "11.266",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "68",
        "11.547",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "68",
        "11.826",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "68",
        "12.103",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "68",
        "12.381",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "68",
        "12.658",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "68",
        "12.933",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "68",
        "13.207",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "68",
        "13.480",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "68",
        "13.752",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "68",
        "14.022",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "68",
        "14.290",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "68",
        "14.558",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "68",
        "14.823",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "68",
        "15.086",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "68",
        "15.349",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "68",
        "15.609",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "68",
        "15.867",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "68",
        "16.123",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "68",
        "16.377",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "68",
        "16.629",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "68",
        "16.878",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "68",
        "17.125",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "68",
        "17.370",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "68",
        "17.612",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "68",
        "17.851",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "68",
        "18.088",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "68",
        "18.323",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "68",
        "18.554",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "68",
        "18.783",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "68",
        "19.009",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "68",
        "19.231",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "68",
        "19.451",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "68",
        "19.668",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "68",
        "19.880",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "68",
        "20.091",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "68",
        "20.297",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "68",
        "20.500",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "68",
        "20.700",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "68",
        "20.896",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "68",
        "21.089",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "68",
        "21.278",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "68",
        "21.463",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "68",
        "21.645",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "68",
        "21.823",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "68",
        "21.997",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "68",
        "22.167",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "68",
        "22.333",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "68",
        "22.495",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "68",
        "22.653",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "68",
        "22.807",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "68",
        "22.957",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "68",
        "23.102",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "68",
        "23.244",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "68",
        "23.381",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "68",
        "23.513",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "68",
        "23.641",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "68",
        "23.765",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "68",
        "23.885",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "68",
        "24.000",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "68",
        "24.111",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "68",
        "24.216",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "68",
        "24.318",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "68",
        "24.414",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "68",
        "24.506",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "68",
        "24.593",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "68",
        "24.677",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "68",
        "24.754",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "68",
        "24.827",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "68",
        "24.896",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "68",
        "24.959",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "68",
        "25.018",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "68",
        "25.072",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "68",
        "25.121",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "68",
        "25.166",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "68",
        "25.205",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "68",
        "25.239",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "68",
        "25.269",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "68",
        "25.293",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "68",
        "25.313",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "68",
        "25.328",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "68",
        "25.338",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "68",
        "25.343",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "68",
        "25.343",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "68",
        "25.338",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "68",
        "25.328",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "68",
        "25.313",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "68",
        "25.293",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "68",
        "25.270",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "68",
        "25.240",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "68",
        "25.205",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "68",
        "25.166",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "68",
        "25.122",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "68",
        "25.073",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "68",
        "25.019",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "68",
        "24.961",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "68",
        "24.897",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "68",
        "24.828",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "68",
        "24.755",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "68",
        "24.678",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "68",
        "24.596",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "68",
        "24.508",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "68",
        "24.416",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "68",
        "24.319",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "68",
        "24.218",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "68",
        "24.112",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "68",
        "24.002",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "68",
        "23.888",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "68",
        "23.768",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "68",
        "23.644",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "68",
        "23.516",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "68",
        "23.383",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "68",
        "23.246",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "68",
        "23.105",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "68",
        "22.960",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "68",
        "22.810",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "68",
        "22.656",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "68",
        "22.498",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "68",
        "22.336",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "68",
        "22.170",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "68",
        "22.000",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "68",
        "21.826",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "68",
        "21.648",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "68",
        "21.467",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "68",
        "21.282",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "68",
        "21.093",
        "-11.792",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "68",
        "20.900",
        "-12.015",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "68",
        "20.704",
        "-12.234",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "68",
        "20.504",
        "-12.450",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "68",
        "20.301",
        "-12.662",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "68",
        "20.095",
        "-12.870",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "68",
        "19.885",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "68",
        "19.672",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "68",
        "19.455",
        "-13.469",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "68",
        "19.235",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "68",
        "19.012",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "68",
        "18.788",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "68",
        "18.559",
        "-14.211",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "68",
        "18.328",
        "-14.386",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "68",
        "18.093",
        "-14.556",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "68",
        "17.857",
        "-14.722",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "68",
        "17.616",
        "-14.884",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "68",
        "16.998",
        "-15.099",
        "0.813",
        "0.983",
        "0.030"
    ],
    [
        "140",
        "68",
        "16.492",
        "-15.273",
        "0.814",
        "1.000",
        "0.039"
    ],
    [
        "141",
        "68",
        "16.025",
        "-15.426",
        "0.845",
        "0.931",
        "0.034"
    ],
    [
        "142",
        "68",
        "15.508",
        "-15.678",
        "0.710",
        "0.868",
        "-0.026"
    ],
    [
        "143",
        "68",
        "14.987",
        "-16.002",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "68",
        "14.726",
        "-16.284",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "68",
        "14.460",
        "-16.561",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "68",
        "14.190",
        "-16.832",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "68",
        "13.915",
        "-17.098",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "68",
        "13.637",
        "-17.359",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "68",
        "13.354",
        "-17.614",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "68",
        "13.067",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "68",
        "12.776",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "68",
        "12.481",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "68",
        "12.181",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "68",
        "11.879",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "68",
        "11.573",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "68",
        "11.264",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "68",
        "10.950",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "68",
        "10.634",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "68",
        "10.313",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "68",
        "9.991",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "68",
        "9.664",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "68",
        "9.335",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "68",
        "9.004",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "68",
        "8.669",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "68",
        "8.331",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "68",
        "7.991",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "68",
        "7.648",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "68",
        "7.304",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "68",
        "6.957",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "68",
        "6.608",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "68",
        "6.257",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "68",
        "5.903",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "68",
        "5.548",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "68",
        "5.192",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "68",
        "4.834",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "68",
        "4.474",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "68",
        "4.113",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "68",
        "3.750",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "68",
        "3.387",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "69",
        "3.048",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "69",
        "2.679",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "69",
        "2.310",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "69",
        "1.940",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "69",
        "1.570",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "69",
        "1.199",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "69",
        "0.828",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "69",
        "0.456",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "69",
        "0.085",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "69",
        "-0.286",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "69",
        "-0.659",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "69",
        "-1.030",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "69",
        "-1.402",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "69",
        "-1.772",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "69",
        "-2.143",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "69",
        "-2.512",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "69",
        "-2.881",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "69",
        "-3.249",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "69",
        "-3.617",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "69",
        "-3.982",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "69",
        "-4.347",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "69",
        "-4.711",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "69",
        "-5.073",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "69",
        "-5.434",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "69",
        "-5.792",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "69",
        "-6.150",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "69",
        "-6.505",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "69",
        "-6.859",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "69",
        "-7.210",
        "-21.500",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "69",
        "-7.559",
        "-21.363",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "69",
        "-7.906",
        "-21.220",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "69",
        "-8.251",
        "-21.072",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "69",
        "-8.593",
        "-20.915",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "69",
        "-8.932",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "69",
        "-9.269",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "69",
        "-9.603",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "69",
        "-9.934",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "69",
        "-10.262",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "69",
        "-10.587",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "69",
        "-10.908",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "69",
        "-11.227",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "69",
        "-11.542",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "69",
        "-11.854",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "69",
        "-12.162",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "69",
        "-12.467",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "69",
        "-12.767",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "69",
        "-13.064",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "69",
        "-13.357",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "69",
        "-13.645",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "69",
        "-13.930",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "69",
        "-14.210",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "69",
        "-14.486",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "69",
        "-14.758",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "69",
        "-15.025",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "69",
        "-15.288",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "69",
        "-15.546",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "69",
        "-15.799",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "69",
        "-16.048",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "69",
        "-16.291",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "69",
        "-16.530",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "69",
        "-16.763",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "69",
        "-16.992",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "69",
        "-17.216",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "69",
        "-17.434",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "69",
        "-17.648",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "69",
        "-17.855",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "69",
        "-18.058",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "69",
        "-18.254",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "69",
        "-18.446",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "69",
        "-18.631",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "69",
        "-18.811",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "69",
        "-18.985",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "69",
        "-19.154",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "69",
        "-19.317",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "69",
        "-19.473",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "69",
        "-19.625",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "69",
        "-19.770",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "69",
        "-19.908",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "69",
        "-20.041",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "69",
        "-20.168",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "69",
        "-20.289",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "69",
        "-20.403",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "69",
        "-20.512",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "69",
        "-20.614",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "69",
        "-20.710",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "69",
        "-20.800",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "69",
        "-20.883",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "69",
        "-20.960",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "69",
        "-21.030",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "69",
        "-21.095",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "69",
        "-21.153",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "69",
        "-21.204",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "69",
        "-21.249",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "69",
        "-21.288",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "69",
        "-21.320",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "69",
        "-21.346",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "69",
        "-21.365",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "69",
        "-21.378",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "69",
        "-21.384",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "69",
        "-21.384",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "69",
        "-21.377",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "69",
        "-21.364",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "69",
        "-21.343",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "69",
        "-21.318",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "69",
        "-21.285",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "69",
        "-21.246",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "69",
        "-21.201",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "69",
        "-21.148",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "69",
        "-21.090",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "69",
        "-21.025",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "69",
        "-20.954",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "69",
        "-20.876",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "69",
        "-20.793",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "69",
        "-20.702",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "69",
        "-20.605",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "69",
        "-20.503",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "69",
        "-20.394",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "69",
        "-20.279",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "69",
        "-20.157",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "69",
        "-20.030",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "69",
        "-19.897",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "69",
        "-19.757",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "69",
        "-19.611",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "69",
        "-19.461",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "69",
        "-19.303",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "69",
        "-19.139",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "69",
        "-18.971",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "69",
        "-18.796",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "69",
        "-18.615",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "69",
        "-18.429",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "69",
        "-18.238",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "69",
        "-18.041",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "69",
        "-17.838",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "69",
        "-17.630",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "69",
        "-17.416",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "69",
        "-17.198",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "69",
        "-16.973",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "69",
        "-16.744",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "69",
        "-16.511",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "69",
        "-16.271",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "69",
        "-16.027",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "69",
        "-15.778",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "69",
        "-15.525",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "69",
        "-15.266",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "69",
        "-15.003",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "69",
        "-14.735",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "69",
        "-14.464",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "69",
        "-14.187",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "69",
        "-13.906",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "69",
        "-13.621",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "69",
        "-13.333",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "69",
        "-13.039",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "69",
        "-12.742",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "69",
        "-12.441",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "69",
        "-12.136",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "69",
        "-11.828",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "69",
        "-11.516",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "69",
        "-11.201",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "69",
        "-10.882",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "69",
        "-10.560",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "69",
        "-10.235",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "69",
        "-9.907",
        "20.245",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "69",
        "-9.575",
        "20.425",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "69",
        "-9.241",
        "20.599",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "69",
        "5.059",
        "17.328",
        "2.437",
        "2.131",
        "-0.502"
    ],
    [
        "-15",
        "69",
        "7.479",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "69",
        "7.762",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "69",
        "8.046",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "69",
        "8.331",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "69",
        "8.615",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "69",
        "8.899",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "69",
        "9.184",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "69",
        "9.468",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "69",
        "9.752",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "69",
        "10.036",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "69",
        "10.319",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "69",
        "10.602",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "69",
        "10.885",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "69",
        "11.166",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "69",
        "11.447",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "69",
        "11.727",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "69",
        "12.007",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "69",
        "12.285",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "69",
        "12.562",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "69",
        "12.838",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "69",
        "13.113",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "69",
        "13.386",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "69",
        "13.658",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "69",
        "13.928",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "69",
        "14.197",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "69",
        "14.465",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "69",
        "14.731",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "69",
        "14.994",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "69",
        "15.257",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "69",
        "15.516",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "69",
        "15.774",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "69",
        "16.029",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "69",
        "16.283",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "69",
        "16.534",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "69",
        "16.783",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "69",
        "17.030",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "69",
        "17.274",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "69",
        "17.514",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "69",
        "17.754",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "69",
        "17.989",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "69",
        "18.222",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "69",
        "18.452",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "69",
        "18.679",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "69",
        "18.903",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "69",
        "19.125",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "69",
        "19.342",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "69",
        "19.557",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "69",
        "19.769",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "69",
        "19.977",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "69",
        "20.182",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "69",
        "20.383",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "69",
        "20.581",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "69",
        "20.774",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "69",
        "20.965",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "69",
        "21.151",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "69",
        "21.334",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "69",
        "21.513",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "69",
        "21.689",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "69",
        "21.860",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "69",
        "22.027",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "69",
        "22.191",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "69",
        "22.349",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "69",
        "22.505",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "69",
        "22.655",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "69",
        "22.802",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "69",
        "22.944",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "69",
        "23.082",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "69",
        "23.215",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "69",
        "23.345",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "69",
        "23.469",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "69",
        "23.589",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "69",
        "23.706",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "69",
        "23.817",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "69",
        "23.923",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "69",
        "24.025",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "69",
        "24.122",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "69",
        "24.215",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "69",
        "24.303",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "69",
        "24.386",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "69",
        "24.464",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "69",
        "24.538",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "69",
        "24.607",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "69",
        "24.671",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "69",
        "24.730",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "69",
        "24.784",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "69",
        "24.834",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "69",
        "24.878",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "69",
        "24.918",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "69",
        "24.953",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "69",
        "24.983",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "69",
        "25.007",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "69",
        "25.027",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "69",
        "25.043",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "69",
        "25.053",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "69",
        "25.057",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "69",
        "25.058",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "69",
        "25.053",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "69",
        "25.043",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "69",
        "25.028",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "69",
        "25.008",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "69",
        "24.984",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "69",
        "24.954",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "69",
        "24.919",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "69",
        "24.879",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "69",
        "24.835",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "69",
        "24.786",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "69",
        "24.732",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "69",
        "24.673",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "69",
        "24.609",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "69",
        "24.540",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "69",
        "24.467",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "69",
        "24.387",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "69",
        "24.305",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "69",
        "24.217",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "69",
        "24.124",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "69",
        "24.027",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "69",
        "23.926",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "69",
        "23.818",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "69",
        "23.708",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "69",
        "23.592",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "69",
        "23.472",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "69",
        "23.347",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "69",
        "23.218",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "69",
        "23.085",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "69",
        "22.947",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "69",
        "22.805",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "69",
        "22.658",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "69",
        "22.507",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "69",
        "22.352",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "69",
        "22.193",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "69",
        "22.031",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "69",
        "21.863",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "69",
        "21.692",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "69",
        "21.517",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "69",
        "21.338",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "69",
        "21.155",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "69",
        "20.968",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "69",
        "20.778",
        "-11.792",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "69",
        "20.584",
        "-12.015",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "69",
        "20.387",
        "-12.234",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "69",
        "20.185",
        "-12.450",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "69",
        "19.981",
        "-12.662",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "69",
        "19.773",
        "-12.870",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "69",
        "19.562",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "69",
        "19.347",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "69",
        "19.129",
        "-13.469",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "69",
        "18.908",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "69",
        "18.684",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "69",
        "18.457",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "69",
        "18.227",
        "-14.211",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "69",
        "17.994",
        "-14.386",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "69",
        "17.758",
        "-14.556",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "69",
        "17.488",
        "-14.728",
        "0.231",
        "0.285",
        "0.018"
    ],
    [
        "138",
        "69",
        "16.931",
        "-14.873",
        "1.026",
        "1.138",
        "0.024"
    ],
    [
        "139",
        "69",
        "16.455",
        "-15.006",
        "0.878",
        "1.118",
        "0.026"
    ],
    [
        "140",
        "69",
        "16.011",
        "-15.180",
        "0.751",
        "0.942",
        "0.022"
    ],
    [
        "141",
        "69",
        "15.607",
        "-15.425",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "69",
        "15.354",
        "-15.717",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "69",
        "15.095",
        "-16.002",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "69",
        "14.832",
        "-16.284",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "69",
        "14.565",
        "-16.561",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "69",
        "14.292",
        "-16.832",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "69",
        "14.016",
        "-17.098",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "69",
        "13.736",
        "-17.359",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "69",
        "13.451",
        "-17.614",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "69",
        "13.161",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "69",
        "12.869",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "69",
        "12.571",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "69",
        "12.270",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "69",
        "11.966",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "69",
        "11.657",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "69",
        "11.346",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "69",
        "11.029",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "69",
        "10.711",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "69",
        "10.389",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "69",
        "10.064",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "69",
        "9.735",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "69",
        "9.405",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "69",
        "9.070",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "69",
        "8.733",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "69",
        "8.393",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "69",
        "8.051",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "69",
        "7.706",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "69",
        "7.359",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "69",
        "7.010",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "69",
        "6.658",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "69",
        "6.305",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "69",
        "5.949",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "69",
        "5.591",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "69",
        "5.232",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "69",
        "4.871",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "69",
        "4.509",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "69",
        "4.146",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "69",
        "3.781",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "69",
        "3.415",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "70",
        "3.072",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "70",
        "2.701",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "70",
        "2.329",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "70",
        "1.958",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "70",
        "1.584",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "70",
        "1.212",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "70",
        "0.838",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "70",
        "0.464",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "70",
        "0.090",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "70",
        "-0.284",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "70",
        "-0.659",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "70",
        "-1.033",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "70",
        "-1.406",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "70",
        "-1.779",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "70",
        "-2.152",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "70",
        "-2.524",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "70",
        "-2.896",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "70",
        "-3.266",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "70",
        "-3.635",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "70",
        "-4.004",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "70",
        "-4.371",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "70",
        "-4.737",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "70",
        "-5.102",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "70",
        "-5.465",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "70",
        "-5.826",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "70",
        "-6.185",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "70",
        "-6.543",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "70",
        "-6.899",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "70",
        "-7.252",
        "-21.500",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "70",
        "-7.604",
        "-21.363",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "70",
        "-7.954",
        "-21.220",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "70",
        "-8.300",
        "-21.072",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "70",
        "-8.645",
        "-20.915",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "70",
        "-8.986",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "70",
        "-9.325",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "70",
        "-9.661",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "70",
        "-9.995",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "70",
        "-10.325",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "70",
        "-10.652",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "70",
        "-10.975",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "70",
        "-11.296",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "70",
        "-11.613",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "70",
        "-11.928",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "70",
        "-12.237",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "70",
        "-12.544",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "70",
        "-12.846",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "70",
        "-13.145",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "70",
        "-13.440",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "70",
        "-13.731",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "70",
        "-14.017",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "70",
        "-14.299",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "70",
        "-14.576",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "70",
        "-14.851",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "70",
        "-15.119",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "70",
        "-15.383",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "70",
        "-15.644",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "70",
        "-15.899",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "70",
        "-16.148",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "70",
        "-16.394",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "70",
        "-16.634",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "70",
        "-16.869",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "70",
        "-17.100",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "70",
        "-17.324",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "70",
        "-17.544",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "70",
        "-17.758",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "70",
        "-17.967",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "70",
        "-18.171",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "70",
        "-18.369",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "70",
        "-18.561",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "70",
        "-18.748",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "70",
        "-18.929",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "70",
        "-19.105",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "70",
        "-19.274",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "70",
        "-19.438",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "70",
        "-19.596",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "70",
        "-19.749",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "70",
        "-19.894",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "70",
        "-20.034",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "70",
        "-20.168",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "70",
        "-20.296",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "70",
        "-20.417",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "70",
        "-20.532",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "70",
        "-20.642",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "70",
        "-20.744",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "70",
        "-20.841",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "70",
        "-20.932",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "70",
        "-21.015",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "70",
        "-21.092",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "70",
        "-21.164",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "70",
        "-21.228",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "70",
        "-21.287",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "70",
        "-21.338",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "70",
        "-21.384",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "70",
        "-21.422",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "70",
        "-21.455",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "70",
        "-21.481",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "70",
        "-21.500",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "70",
        "-21.513",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "70",
        "-21.519",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "70",
        "-21.519",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "70",
        "-21.512",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "70",
        "-21.499",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "70",
        "-21.479",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "70",
        "-21.453",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "70",
        "-21.420",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "70",
        "-21.380",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "70",
        "-21.335",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "70",
        "-21.282",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "70",
        "-21.223",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "70",
        "-21.158",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "70",
        "-21.086",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "70",
        "-21.008",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "70",
        "-20.924",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "70",
        "-20.833",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "70",
        "-20.736",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "70",
        "-20.633",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "70",
        "-20.523",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "70",
        "-20.407",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "70",
        "-20.285",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "70",
        "-20.156",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "70",
        "-20.023",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "70",
        "-19.882",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "70",
        "-19.735",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "70",
        "-19.584",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "70",
        "-19.425",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "70",
        "-19.260",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "70",
        "-19.091",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "70",
        "-18.915",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "70",
        "-18.733",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "70",
        "-18.546",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "70",
        "-18.352",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "70",
        "-18.154",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "70",
        "-17.950",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "70",
        "-17.740",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "70",
        "-17.525",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "70",
        "-17.305",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "70",
        "-17.080",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "70",
        "-16.850",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "70",
        "-16.614",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "70",
        "-16.374",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "70",
        "-16.128",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "70",
        "-15.877",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "70",
        "-15.622",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "70",
        "-15.362",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "70",
        "-15.097",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "70",
        "-14.827",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "70",
        "-14.554",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "70",
        "-14.276",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "70",
        "-13.993",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "70",
        "-13.706",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "70",
        "-13.415",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "70",
        "-13.120",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "70",
        "-12.821",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "70",
        "-12.519",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "70",
        "-12.212",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "70",
        "-11.901",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "70",
        "-11.588",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "70",
        "-11.270",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "70",
        "-10.949",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "70",
        "-10.624",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "70",
        "-10.298",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "70",
        "-9.967",
        "20.245",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "70",
        "-9.633",
        "20.425",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "70",
        "-9.297",
        "20.599",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "70",
        "-8.052",
        "21.003",
        "0.979",
        "0.932",
        "-0.338"
    ],
    [
        "-15",
        "70",
        "7.071",
        "17.403",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "70",
        "7.356",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "70",
        "7.642",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "70",
        "7.928",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "70",
        "8.214",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "70",
        "8.501",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "70",
        "8.787",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "70",
        "9.073",
        "17.441",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "70",
        "9.359",
        "17.425",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "70",
        "9.644",
        "17.404",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "70",
        "9.930",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "70",
        "10.214",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "70",
        "10.498",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "70",
        "10.782",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "70",
        "11.065",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "70",
        "11.347",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "70",
        "11.628",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "70",
        "11.908",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "70",
        "12.187",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "70",
        "12.465",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "70",
        "12.741",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "70",
        "13.017",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "70",
        "13.291",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "70",
        "13.563",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "70",
        "13.834",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "70",
        "14.103",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "70",
        "14.370",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "70",
        "14.636",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "70",
        "14.899",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "70",
        "15.160",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "70",
        "15.420",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "70",
        "15.678",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "70",
        "15.933",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "70",
        "16.185",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "70",
        "16.436",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "70",
        "16.684",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "70",
        "16.930",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "70",
        "17.172",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "70",
        "17.412",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "70",
        "17.650",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "70",
        "17.885",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "70",
        "18.116",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "70",
        "18.345",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "70",
        "18.571",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "70",
        "18.793",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "70",
        "19.012",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "70",
        "19.229",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "70",
        "19.441",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "70",
        "19.651",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "70",
        "19.857",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "70",
        "20.060",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "70",
        "20.258",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "70",
        "20.454",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "70",
        "20.645",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "70",
        "20.833",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "70",
        "21.017",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "70",
        "21.197",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "70",
        "21.374",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "70",
        "21.546",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "70",
        "21.714",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "70",
        "21.878",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "70",
        "22.039",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "70",
        "22.194",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "70",
        "22.347",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "70",
        "22.494",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "70",
        "22.637",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "70",
        "22.776",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "70",
        "22.911",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "70",
        "23.040",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "70",
        "23.166",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "70",
        "23.287",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "70",
        "23.404",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "70",
        "23.515",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "70",
        "23.623",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "70",
        "23.726",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "70",
        "23.823",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "70",
        "23.917",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "70",
        "24.005",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "70",
        "24.089",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "70",
        "24.168",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "70",
        "24.242",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "70",
        "24.312",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "70",
        "24.376",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "70",
        "24.435",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "70",
        "24.490",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "70",
        "24.540",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "70",
        "24.584",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "70",
        "24.624",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "70",
        "24.659",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "70",
        "24.690",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "70",
        "24.715",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "70",
        "24.735",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "70",
        "24.750",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "70",
        "24.759",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "70",
        "24.764",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "70",
        "24.765",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "70",
        "24.760",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "70",
        "24.750",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "70",
        "24.735",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "70",
        "24.715",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "70",
        "24.690",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "70",
        "24.661",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "70",
        "24.626",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "70",
        "24.586",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "70",
        "24.540",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "70",
        "24.491",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "70",
        "24.437",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "70",
        "24.377",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "70",
        "24.313",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "70",
        "24.244",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "70",
        "24.169",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "70",
        "24.091",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "70",
        "24.007",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "70",
        "23.918",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "70",
        "23.825",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "70",
        "23.727",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "70",
        "23.624",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "70",
        "23.518",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "70",
        "23.406",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "70",
        "23.290",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "70",
        "23.169",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "70",
        "23.043",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "70",
        "22.913",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "70",
        "22.779",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "70",
        "22.640",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "70",
        "22.497",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "70",
        "22.349",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "70",
        "22.198",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "70",
        "22.042",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "70",
        "21.882",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "70",
        "21.718",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "70",
        "21.550",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "70",
        "21.377",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "70",
        "21.201",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "70",
        "21.021",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "70",
        "20.837",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "70",
        "20.649",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "70",
        "20.458",
        "-11.792",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "70",
        "20.262",
        "-12.015",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "70",
        "20.063",
        "-12.234",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "70",
        "19.861",
        "-12.450",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "70",
        "19.655",
        "-12.662",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "70",
        "19.445",
        "-12.870",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "70",
        "19.232",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "70",
        "19.017",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "70",
        "18.798",
        "-13.469",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "70",
        "18.574",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "70",
        "18.349",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "70",
        "18.121",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "70",
        "17.890",
        "-14.211",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "70",
        "17.654",
        "-14.386",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "70",
        "17.367",
        "-14.521",
        "0.713",
        "0.720",
        "-0.001"
    ],
    [
        "137",
        "70",
        "17.000",
        "-14.587",
        "1.036",
        "1.225",
        "-0.011"
    ],
    [
        "138",
        "70",
        "16.609",
        "-14.694",
        "1.048",
        "1.182",
        "-0.006"
    ],
    [
        "139",
        "70",
        "16.224",
        "-14.835",
        "0.218",
        "0.268",
        "0.019"
    ],
    [
        "140",
        "70",
        "15.964",
        "-15.129",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "70",
        "15.714",
        "-15.425",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "70",
        "15.458",
        "-15.717",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "70",
        "15.199",
        "-16.002",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "70",
        "14.933",
        "-16.284",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "70",
        "14.664",
        "-16.561",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "70",
        "14.390",
        "-16.832",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "70",
        "14.112",
        "-17.098",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "70",
        "13.829",
        "-17.359",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "70",
        "13.543",
        "-17.614",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "70",
        "13.251",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "70",
        "12.957",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "70",
        "12.658",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "70",
        "12.355",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "70",
        "12.048",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "70",
        "11.738",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "70",
        "11.424",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "70",
        "11.107",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "70",
        "10.786",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "70",
        "10.462",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "70",
        "10.134",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "70",
        "9.803",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "70",
        "9.470",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "70",
        "9.134",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "70",
        "8.795",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "70",
        "8.453",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "70",
        "8.108",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "70",
        "7.761",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "70",
        "7.412",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "70",
        "7.060",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "70",
        "6.706",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "70",
        "6.350",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "70",
        "5.992",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "70",
        "5.632",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "70",
        "5.271",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "70",
        "4.908",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "70",
        "4.543",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "70",
        "4.177",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "70",
        "3.810",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "70",
        "3.441",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "71",
        "3.096",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "71",
        "2.722",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "71",
        "2.349",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "71",
        "1.974",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "71",
        "1.599",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "71",
        "1.224",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "71",
        "0.848",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "71",
        "0.471",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "71",
        "0.095",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "71",
        "-0.281",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "71",
        "-0.658",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "71",
        "-1.034",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "71",
        "-1.410",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "71",
        "-1.786",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "71",
        "-2.160",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "71",
        "-2.535",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "71",
        "-2.909",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "71",
        "-3.282",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "71",
        "-3.654",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "71",
        "-4.024",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "71",
        "-4.394",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "71",
        "-4.762",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "71",
        "-5.129",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "71",
        "-5.494",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "71",
        "-5.858",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "71",
        "-6.219",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "71",
        "-6.579",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "71",
        "-6.937",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "71",
        "-7.293",
        "-21.500",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "71",
        "-7.647",
        "-21.363",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "71",
        "-7.998",
        "-21.220",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "71",
        "-8.347",
        "-21.072",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "71",
        "-8.694",
        "-20.915",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "71",
        "-9.037",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "71",
        "-9.379",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "71",
        "-9.717",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "71",
        "-10.052",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "71",
        "-10.385",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "71",
        "-10.713",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "71",
        "-11.040",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "71",
        "-11.363",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "71",
        "-11.681",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "71",
        "-11.996",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "71",
        "-12.309",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "71",
        "-12.617",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "71",
        "-12.921",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "71",
        "-13.222",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "71",
        "-13.519",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "71",
        "-13.811",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "71",
        "-14.099",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "71",
        "-14.383",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "71",
        "-14.662",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "71",
        "-14.938",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "71",
        "-15.209",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "71",
        "-15.474",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "71",
        "-15.735",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "71",
        "-15.992",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "71",
        "-16.244",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "71",
        "-16.490",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "71",
        "-16.733",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "71",
        "-16.969",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "71",
        "-17.201",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "71",
        "-17.427",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "71",
        "-17.648",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "71",
        "-17.864",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "71",
        "-18.074",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "71",
        "-18.279",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "71",
        "-18.478",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "71",
        "-18.673",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "71",
        "-18.860",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "71",
        "-19.043",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "71",
        "-19.219",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "71",
        "-19.390",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "71",
        "-19.554",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "71",
        "-19.713",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "71",
        "-19.866",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "71",
        "-20.013",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "71",
        "-20.154",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "71",
        "-20.289",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "71",
        "-20.417",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "71",
        "-20.540",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "71",
        "-20.656",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "71",
        "-20.765",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "71",
        "-20.869",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "71",
        "-20.966",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "71",
        "-21.057",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "71",
        "-21.141",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "71",
        "-21.219",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "71",
        "-21.290",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "71",
        "-21.356",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "71",
        "-21.415",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "71",
        "-21.466",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "71",
        "-21.512",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "71",
        "-21.552",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "71",
        "-21.584",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "71",
        "-21.609",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "71",
        "-21.629",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "71",
        "-21.642",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "71",
        "-21.649",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "71",
        "-21.648",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "71",
        "-21.642",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "71",
        "-21.628",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "71",
        "-21.608",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "71",
        "-21.582",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "71",
        "-21.548",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "71",
        "-21.509",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "71",
        "-21.462",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "71",
        "-21.410",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "71",
        "-21.351",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "71",
        "-21.285",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "71",
        "-21.213",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "71",
        "-21.135",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "71",
        "-21.049",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "71",
        "-20.958",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "71",
        "-20.860",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "71",
        "-20.756",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "71",
        "-20.646",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "71",
        "-20.529",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "71",
        "-20.406",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "71",
        "-20.277",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "71",
        "-20.142",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "71",
        "-20.001",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "71",
        "-19.853",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "71",
        "-19.700",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "71",
        "-19.541",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "71",
        "-19.376",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "71",
        "-19.204",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "71",
        "-19.027",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "71",
        "-18.845",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "71",
        "-18.656",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "71",
        "-18.462",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "71",
        "-18.262",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "71",
        "-18.057",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "71",
        "-17.846",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "71",
        "-17.630",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "71",
        "-17.409",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "71",
        "-17.182",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "71",
        "-16.950",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "71",
        "-16.713",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "71",
        "-16.470",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "71",
        "-16.223",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "71",
        "-15.971",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "71",
        "-15.714",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "71",
        "-15.452",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "71",
        "-15.186",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "71",
        "-14.915",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "71",
        "-14.639",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "71",
        "-14.360",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "71",
        "-14.075",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "71",
        "-13.787",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "71",
        "-13.493",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "71",
        "-13.197",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "71",
        "-12.896",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "71",
        "-12.591",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "71",
        "-12.283",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "71",
        "-11.971",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "71",
        "-11.654",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "71",
        "-11.335",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "71",
        "-11.012",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "71",
        "-10.686",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "71",
        "-10.357",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "71",
        "-10.024",
        "20.245",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "71",
        "-9.688",
        "20.425",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "71",
        "-9.350",
        "20.599",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "71",
        "-9.009",
        "20.767",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "71",
        "-8.665",
        "20.928",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "71",
        "-8.318",
        "21.084",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "71",
        "-4.027",
        "20.249",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "71",
        "7.523",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "71",
        "7.811",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "71",
        "8.099",
        "17.457",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "71",
        "8.387",
        "17.452",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "71",
        "6.572",
        "18.010",
        "2.513",
        "2.833",
        "0.005"
    ],
    [
        "-7",
        "71",
        "5.784",
        "18.792",
        "3.162",
        "2.942",
        "-0.135"
    ],
    [
        "-6",
        "71",
        "7.254",
        "18.352",
        "3.115",
        "3.291",
        "-0.121"
    ],
    [
        "-5",
        "71",
        "9.537",
        "17.377",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "71",
        "9.823",
        "17.346",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "71",
        "10.110",
        "17.309",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "71",
        "10.396",
        "17.266",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "71",
        "10.680",
        "17.219",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "71",
        "10.964",
        "17.166",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "71",
        "11.246",
        "17.108",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "71",
        "11.528",
        "17.045",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "71",
        "11.809",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "71",
        "12.088",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "71",
        "12.366",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "71",
        "12.644",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "71",
        "12.919",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "71",
        "13.193",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "71",
        "13.465",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "71",
        "13.737",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "71",
        "14.005",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "71",
        "14.272",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "71",
        "14.537",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "71",
        "14.801",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "71",
        "15.061",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "71",
        "15.321",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "71",
        "15.578",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "71",
        "15.832",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "71",
        "16.084",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "71",
        "16.333",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "71",
        "16.580",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "71",
        "16.825",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "71",
        "17.066",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "71",
        "17.305",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "71",
        "17.542",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "71",
        "17.774",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "71",
        "18.005",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "71",
        "18.231",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "71",
        "18.455",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "71",
        "18.676",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "71",
        "18.893",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "71",
        "19.108",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "71",
        "19.318",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "71",
        "19.526",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "71",
        "19.730",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "71",
        "19.930",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "71",
        "20.126",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "71",
        "20.319",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "71",
        "20.508",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "71",
        "20.694",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "71",
        "20.875",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "71",
        "21.052",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "71",
        "21.225",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "71",
        "21.395",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "71",
        "21.560",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "71",
        "21.722",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "71",
        "21.878",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "71",
        "22.031",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "71",
        "22.179",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "71",
        "22.323",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "71",
        "22.463",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "71",
        "22.598",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "71",
        "22.729",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "71",
        "22.856",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "71",
        "22.978",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "71",
        "23.095",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "71",
        "23.207",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "71",
        "23.316",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "71",
        "23.419",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "71",
        "23.517",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "71",
        "23.611",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "71",
        "23.700",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "71",
        "23.784",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "71",
        "23.864",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "71",
        "23.938",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "71",
        "24.008",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "71",
        "24.073",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "71",
        "24.133",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "71",
        "24.188",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "71",
        "24.238",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "71",
        "24.283",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "71",
        "24.323",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "71",
        "24.359",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "71",
        "24.388",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "71",
        "24.413",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "71",
        "24.434",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "71",
        "24.449",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "71",
        "24.459",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "71",
        "24.464",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "71",
        "24.464",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "71",
        "24.459",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "71",
        "24.449",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "71",
        "24.434",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "71",
        "24.415",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "71",
        "24.389",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "71",
        "24.359",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "71",
        "24.324",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "71",
        "24.283",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "71",
        "24.239",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "71",
        "24.189",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "71",
        "24.134",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "71",
        "24.074",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "71",
        "24.010",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "71",
        "23.940",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "71",
        "23.866",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "71",
        "23.786",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "71",
        "23.702",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "71",
        "23.613",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "71",
        "23.519",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "71",
        "23.421",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "71",
        "23.317",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "71",
        "23.209",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "71",
        "23.097",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "71",
        "22.980",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "71",
        "22.858",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "71",
        "22.732",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "71",
        "22.601",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "71",
        "22.466",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "71",
        "22.326",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "71",
        "22.182",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "71",
        "22.034",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "71",
        "21.882",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "71",
        "21.724",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "71",
        "21.564",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "71",
        "21.398",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "71",
        "21.229",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "71",
        "21.056",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "71",
        "20.878",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "71",
        "20.697",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "71",
        "20.512",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "71",
        "20.323",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "71",
        "20.131",
        "-11.792",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "71",
        "19.934",
        "-12.015",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "71",
        "19.733",
        "-12.234",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "71",
        "19.530",
        "-12.450",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "71",
        "19.323",
        "-12.662",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "71",
        "19.112",
        "-12.870",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "71",
        "18.898",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "71",
        "18.681",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "71",
        "18.459",
        "-13.469",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "71",
        "18.236",
        "-13.661",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "71",
        "18.009",
        "-13.848",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "71",
        "17.779",
        "-14.032",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "71",
        "17.578",
        "-14.098",
        "0.975",
        "1.031",
        "0.007"
    ],
    [
        "135",
        "71",
        "17.344",
        "-14.167",
        "1.151",
        "1.188",
        "-0.035"
    ],
    [
        "136",
        "71",
        "17.089",
        "-14.241",
        "1.069",
        "1.192",
        "-0.041"
    ],
    [
        "137",
        "71",
        "16.810",
        "-14.279",
        "0.688",
        "0.674",
        "0.006"
    ],
    [
        "138",
        "71",
        "16.557",
        "-14.524",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "71",
        "16.316",
        "-14.829",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "71",
        "16.068",
        "-15.129",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "71",
        "15.816",
        "-15.425",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "71",
        "15.559",
        "-15.717",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "71",
        "15.297",
        "-16.002",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "71",
        "15.031",
        "-16.284",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "71",
        "14.760",
        "-16.561",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "71",
        "14.484",
        "-16.832",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "71",
        "14.204",
        "-17.098",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "71",
        "13.919",
        "-17.359",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "71",
        "13.631",
        "-17.614",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "71",
        "13.339",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "71",
        "13.041",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "71",
        "12.741",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "71",
        "12.436",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "71",
        "12.127",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "71",
        "11.815",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "71",
        "11.500",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "71",
        "11.180",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "71",
        "10.857",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "71",
        "10.531",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "71",
        "10.201",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "71",
        "9.869",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "71",
        "9.533",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "71",
        "9.195",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "71",
        "8.853",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "71",
        "8.510",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "71",
        "8.163",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "71",
        "7.814",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "71",
        "7.462",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "71",
        "7.108",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "71",
        "6.752",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "71",
        "6.394",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "71",
        "6.033",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "71",
        "5.671",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "71",
        "5.308",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "71",
        "4.943",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "71",
        "4.576",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "71",
        "4.208",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "71",
        "3.838",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "71",
        "3.467",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "72",
        "3.117",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "72",
        "2.743",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "72",
        "2.367",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "72",
        "1.990",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "72",
        "1.613",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "72",
        "1.235",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "72",
        "0.857",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "72",
        "0.478",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "72",
        "0.100",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "72",
        "-0.279",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "72",
        "-0.657",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "72",
        "-1.036",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "72",
        "-1.414",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "72",
        "-1.792",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "72",
        "-2.169",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "72",
        "-2.545",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "72",
        "-2.922",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "72",
        "-3.297",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "72",
        "-3.671",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "72",
        "-4.043",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "72",
        "-4.415",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "72",
        "-4.785",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "72",
        "-5.154",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "72",
        "-5.521",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "72",
        "-5.887",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "72",
        "-6.252",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "72",
        "-6.614",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "72",
        "-6.974",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "72",
        "-7.332",
        "-21.500",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "72",
        "-7.687",
        "-21.363",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "72",
        "-8.041",
        "-21.220",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "72",
        "-8.392",
        "-21.072",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "72",
        "-8.740",
        "-20.915",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "72",
        "-9.086",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "72",
        "-9.429",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "72",
        "-9.769",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "72",
        "-10.107",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "72",
        "-10.441",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "72",
        "-10.772",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "72",
        "-11.100",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "72",
        "-11.424",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "72",
        "-11.745",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "72",
        "-12.062",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "72",
        "-12.376",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "72",
        "-12.686",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "72",
        "-12.993",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "72",
        "-13.294",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "72",
        "-13.593",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "72",
        "-13.888",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "72",
        "-14.177",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "72",
        "-14.463",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "72",
        "-14.744",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "72",
        "-15.021",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "72",
        "-15.293",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "72",
        "-15.560",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "72",
        "-15.823",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "72",
        "-16.081",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "72",
        "-16.335",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "72",
        "-16.583",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "72",
        "-16.826",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "72",
        "-17.064",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "72",
        "-17.297",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "72",
        "-17.525",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "72",
        "-17.748",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "72",
        "-17.965",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "72",
        "-18.176",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "72",
        "-18.382",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "72",
        "-18.582",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "72",
        "-18.777",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "72",
        "-18.967",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "72",
        "-19.150",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "72",
        "-19.327",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "72",
        "-19.498",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "72",
        "-19.664",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "72",
        "-19.824",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "72",
        "-19.978",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "72",
        "-20.126",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "72",
        "-20.267",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "72",
        "-20.403",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "72",
        "-20.532",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "72",
        "-20.656",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "72",
        "-20.771",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "72",
        "-20.882",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "72",
        "-20.986",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "72",
        "-21.084",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "72",
        "-21.175",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "72",
        "-21.261",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "72",
        "-21.339",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "72",
        "-21.411",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "72",
        "-21.476",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "72",
        "-21.535",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "72",
        "-21.588",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "72",
        "-21.634",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "72",
        "-21.673",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "72",
        "-21.706",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "72",
        "-21.732",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "72",
        "-21.752",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "72",
        "-21.764",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "72",
        "-21.771",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "72",
        "-21.771",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "72",
        "-21.764",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "72",
        "-21.750",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "72",
        "-21.730",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "72",
        "-21.703",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "72",
        "-21.670",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "72",
        "-21.630",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "72",
        "-21.584",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "72",
        "-21.530",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "72",
        "-21.471",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "72",
        "-21.406",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "72",
        "-21.333",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "72",
        "-21.254",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "72",
        "-21.168",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "72",
        "-21.076",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "72",
        "-20.978",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "72",
        "-20.874",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "72",
        "-20.762",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "72",
        "-20.645",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "72",
        "-20.521",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "72",
        "-20.391",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "72",
        "-20.256",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "72",
        "-20.113",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "72",
        "-19.965",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "72",
        "-19.811",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "72",
        "-19.650",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "72",
        "-19.484",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "72",
        "-19.313",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "72",
        "-19.135",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "72",
        "-18.951",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "72",
        "-18.761",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "72",
        "-18.566",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "72",
        "-18.365",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "72",
        "-18.159",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "72",
        "-17.946",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "72",
        "-17.729",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "72",
        "-17.506",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "72",
        "-17.278",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "72",
        "-17.045",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "72",
        "-16.806",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "72",
        "-16.562",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "72",
        "-16.314",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "72",
        "-16.060",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "72",
        "-15.801",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "72",
        "-15.538",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "72",
        "-15.271",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "72",
        "-14.998",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "72",
        "-14.721",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "72",
        "-14.439",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "72",
        "-14.154",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "72",
        "-13.863",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "72",
        "-13.569",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "72",
        "-13.269",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "72",
        "-12.967",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "72",
        "-12.661",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "72",
        "-12.351",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "72",
        "-12.036",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "72",
        "-11.718",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "72",
        "-11.397",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "72",
        "-11.073",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "72",
        "-10.744",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "72",
        "-10.413",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "72",
        "-10.079",
        "20.245",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "72",
        "-9.741",
        "20.425",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "72",
        "-9.400",
        "20.599",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "72",
        "-9.057",
        "20.767",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "72",
        "-8.711",
        "20.928",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "72",
        "-8.363",
        "21.084",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "72",
        "-8.011",
        "21.232",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "72",
        "-7.657",
        "21.376",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "72",
        "-7.302",
        "21.511",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "72",
        "-6.943",
        "21.640",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "72",
        "-6.583",
        "21.763",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "72",
        "-6.221",
        "21.879",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "72",
        "-5.856",
        "21.989",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "72",
        "-5.491",
        "22.091",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "72",
        "-5.124",
        "22.187",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "72",
        "-4.754",
        "22.277",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "72",
        "-4.384",
        "22.359",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "72",
        "-4.013",
        "22.435",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "72",
        "-3.089",
        "22.071",
        "0.609",
        "0.883",
        "-0.540"
    ],
    [
        "0",
        "72",
        "2.024",
        "20.098",
        "2.857",
        "3.061",
        "-0.487"
    ],
    [
        "1",
        "72",
        "5.742",
        "19.603",
        "2.721",
        "2.756",
        "-0.417"
    ],
    [
        "2",
        "72",
        "9.760",
        "18.188",
        "1.634",
        "2.330",
        "-0.484"
    ],
    [
        "3",
        "72",
        "11.426",
        "16.976",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "72",
        "11.708",
        "16.902",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "72",
        "11.988",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "72",
        "12.266",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "72",
        "12.543",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "72",
        "12.819",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "72",
        "13.094",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "72",
        "13.366",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "72",
        "13.636",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "72",
        "13.905",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "72",
        "14.172",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "72",
        "14.436",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "72",
        "14.699",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "72",
        "14.959",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "72",
        "15.217",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "72",
        "15.473",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "72",
        "15.727",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "72",
        "15.978",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "72",
        "16.226",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "72",
        "16.473",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "72",
        "16.716",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "72",
        "16.955",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "72",
        "17.193",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "72",
        "17.427",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "72",
        "17.659",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "72",
        "17.887",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "72",
        "18.112",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "72",
        "18.334",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "72",
        "18.553",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "72",
        "18.768",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "72",
        "18.980",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "72",
        "19.189",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "72",
        "19.394",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "72",
        "19.595",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "72",
        "19.793",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "72",
        "19.986",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "72",
        "20.177",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "72",
        "20.363",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "72",
        "20.546",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "72",
        "20.725",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "72",
        "20.898",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "72",
        "21.069",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "72",
        "21.235",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "72",
        "21.398",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "72",
        "21.556",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "72",
        "21.709",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "72",
        "21.858",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "72",
        "22.003",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "72",
        "22.143",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "72",
        "22.279",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "72",
        "22.411",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "72",
        "22.539",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "72",
        "22.660",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "72",
        "22.778",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "72",
        "22.892",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "72",
        "23.000",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "72",
        "23.104",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "72",
        "23.203",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "72",
        "23.298",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "72",
        "23.388",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "72",
        "23.472",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "72",
        "23.552",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "72",
        "23.627",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "72",
        "23.698",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "72",
        "23.762",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "72",
        "23.823",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "72",
        "23.879",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "72",
        "23.929",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "72",
        "23.974",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "72",
        "24.015",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "72",
        "24.049",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "72",
        "24.080",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "72",
        "24.106",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "72",
        "24.126",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "72",
        "24.141",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "72",
        "24.151",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "72",
        "24.156",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "72",
        "24.156",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "72",
        "24.152",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "72",
        "24.142",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "72",
        "24.126",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "72",
        "24.106",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "72",
        "24.081",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "72",
        "24.050",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "72",
        "24.015",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "72",
        "23.975",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "72",
        "23.930",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "72",
        "23.880",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "72",
        "23.824",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "72",
        "23.764",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "72",
        "23.699",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "72",
        "23.628",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "72",
        "23.553",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "72",
        "23.474",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "72",
        "23.390",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "72",
        "23.300",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "72",
        "23.205",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "72",
        "23.106",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "72",
        "23.003",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "72",
        "22.895",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "72",
        "22.781",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "72",
        "22.664",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "72",
        "22.541",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "72",
        "22.414",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "72",
        "22.283",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "72",
        "22.147",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "72",
        "22.006",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "72",
        "21.861",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "72",
        "21.712",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "72",
        "21.559",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "72",
        "21.401",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "72",
        "21.238",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "72",
        "21.072",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "72",
        "20.902",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "72",
        "20.728",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "72",
        "20.550",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "72",
        "20.367",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "72",
        "20.181",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "72",
        "19.991",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "72",
        "19.797",
        "-11.792",
        "0",
        "0",
        "0"
    ],
    [
        "123",
        "72",
        "19.599",
        "-12.015",
        "0",
        "0",
        "0"
    ],
    [
        "124",
        "72",
        "19.398",
        "-12.234",
        "0",
        "0",
        "0"
    ],
    [
        "125",
        "72",
        "19.193",
        "-12.450",
        "0",
        "0",
        "0"
    ],
    [
        "126",
        "72",
        "18.985",
        "-12.662",
        "0",
        "0",
        "0"
    ],
    [
        "127",
        "72",
        "18.773",
        "-12.870",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "72",
        "18.557",
        "-13.073",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "72",
        "18.339",
        "-13.274",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "72",
        "18.159",
        "-13.410",
        "0.679",
        "0.673",
        "-0.005"
    ],
    [
        "131",
        "72",
        "18.012",
        "-13.470",
        "1.047",
        "1.152",
        "-0.018"
    ],
    [
        "132",
        "72",
        "17.837",
        "-13.577",
        "1.183",
        "1.171",
        "-0.031"
    ],
    [
        "133",
        "72",
        "17.646",
        "-13.704",
        "1.132",
        "1.147",
        "-0.071"
    ],
    [
        "134",
        "72",
        "17.459",
        "-13.828",
        "1.160",
        "1.138",
        "-0.083"
    ],
    [
        "135",
        "72",
        "17.271",
        "-13.973",
        "1.188",
        "1.110",
        "-0.071"
    ],
    [
        "136",
        "72",
        "17.076",
        "-14.119",
        "1.038",
        "1.057",
        "-0.050"
    ],
    [
        "137",
        "72",
        "16.881",
        "-14.251",
        "0.652",
        "0.595",
        "-0.027"
    ],
    [
        "138",
        "72",
        "16.658",
        "-14.524",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "72",
        "16.415",
        "-14.829",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "72",
        "16.166",
        "-15.129",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "72",
        "15.913",
        "-15.425",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "72",
        "15.655",
        "-15.717",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "72",
        "15.391",
        "-16.002",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "72",
        "15.123",
        "-16.284",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "72",
        "14.850",
        "-16.561",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "72",
        "14.573",
        "-16.832",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "72",
        "14.291",
        "-17.098",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "72",
        "14.006",
        "-17.359",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "72",
        "13.715",
        "-17.614",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "72",
        "13.421",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "72",
        "13.123",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "72",
        "12.820",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "72",
        "12.513",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "72",
        "12.203",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "72",
        "11.889",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "72",
        "11.571",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "72",
        "11.250",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "72",
        "10.925",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "72",
        "10.597",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "72",
        "10.265",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "72",
        "9.931",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "72",
        "9.594",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "72",
        "9.253",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "72",
        "8.909",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "72",
        "8.563",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "72",
        "8.215",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "72",
        "7.864",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "72",
        "7.511",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "72",
        "7.154",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "72",
        "6.796",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "72",
        "6.435",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "72",
        "6.073",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "72",
        "5.710",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "72",
        "5.343",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "72",
        "4.976",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "72",
        "4.607",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "72",
        "4.236",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "72",
        "3.865",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "72",
        "3.492",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "73",
        "3.139",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "73",
        "2.762",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "73",
        "2.384",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "73",
        "2.006",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "73",
        "1.626",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "73",
        "1.247",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "73",
        "0.866",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "73",
        "0.485",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "73",
        "0.105",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "73",
        "-0.276",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "73",
        "-0.657",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "73",
        "-1.037",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "73",
        "-1.417",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "73",
        "-1.798",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "73",
        "-2.177",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "73",
        "-2.555",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "73",
        "-2.934",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "73",
        "-3.310",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "73",
        "-3.686",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "73",
        "-4.061",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "73",
        "-4.435",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "73",
        "-4.808",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "73",
        "-5.178",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "73",
        "-5.548",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "73",
        "-5.915",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "73",
        "-6.281",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "73",
        "-6.645",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "73",
        "-7.008",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "73",
        "-7.368",
        "-21.500",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "73",
        "-7.725",
        "-21.363",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "73",
        "-8.080",
        "-21.220",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "73",
        "-8.434",
        "-21.072",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "73",
        "-8.784",
        "-20.915",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "73",
        "-9.131",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "73",
        "-9.476",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "73",
        "-9.819",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "73",
        "-10.158",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "73",
        "-10.494",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "73",
        "-10.827",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "73",
        "-11.156",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "73",
        "-11.483",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "73",
        "-11.805",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "73",
        "-12.125",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "73",
        "-12.441",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "73",
        "-12.752",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "73",
        "-13.060",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "73",
        "-13.364",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "73",
        "-13.664",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "73",
        "-13.960",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "73",
        "-14.251",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "73",
        "-14.538",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "73",
        "-14.821",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "73",
        "-15.100",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "73",
        "-15.373",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "73",
        "-15.642",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "73",
        "-15.906",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "73",
        "-16.166",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "73",
        "-16.420",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "73",
        "-16.671",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "73",
        "-16.914",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "73",
        "-17.154",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "73",
        "-17.388",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "73",
        "-17.617",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "73",
        "-17.841",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "73",
        "-18.059",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "73",
        "-18.272",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "73",
        "-18.479",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "73",
        "-18.681",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "73",
        "-18.876",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "73",
        "-19.066",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "73",
        "-19.251",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "73",
        "-19.429",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "73",
        "-19.602",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "73",
        "-19.768",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "73",
        "-19.930",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "73",
        "-20.084",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "73",
        "-20.232",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "73",
        "-20.375",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "73",
        "-20.511",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "73",
        "-20.641",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "73",
        "-20.764",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "73",
        "-20.882",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "73",
        "-20.994",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "73",
        "-21.098",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "73",
        "-21.196",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "73",
        "-21.288",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "73",
        "-21.374",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "73",
        "-21.452",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "73",
        "-21.525",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "73",
        "-21.590",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "73",
        "-21.649",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "73",
        "-21.702",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "73",
        "-21.748",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "73",
        "-21.788",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "73",
        "-21.821",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "73",
        "-21.847",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "73",
        "-21.867",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "73",
        "-21.880",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "73",
        "-21.886",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "73",
        "-21.886",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "73",
        "-21.880",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "73",
        "-21.866",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "73",
        "-21.845",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "73",
        "-21.819",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "73",
        "-21.785",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "73",
        "-21.745",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "73",
        "-21.699",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "73",
        "-21.645",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "73",
        "-21.585",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "73",
        "-21.519",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "73",
        "-21.446",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "73",
        "-21.366",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "73",
        "-21.280",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "73",
        "-21.188",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "73",
        "-21.089",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "73",
        "-20.984",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "73",
        "-20.872",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "73",
        "-20.755",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "73",
        "-20.631",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "73",
        "-20.500",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "73",
        "-20.363",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "73",
        "-20.220",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "73",
        "-20.071",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "73",
        "-19.916",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "73",
        "-19.755",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "73",
        "-19.588",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "73",
        "-19.415",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "73",
        "-19.235",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "73",
        "-19.051",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "73",
        "-18.860",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "73",
        "-18.663",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "73",
        "-18.462",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "73",
        "-18.254",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "73",
        "-18.041",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "73",
        "-17.822",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "73",
        "-17.598",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "73",
        "-17.369",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "73",
        "-17.134",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "73",
        "-16.895",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "73",
        "-16.649",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "73",
        "-16.399",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "73",
        "-16.144",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "73",
        "-15.884",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "73",
        "-15.620",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "73",
        "-15.351",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "73",
        "-15.076",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "73",
        "-14.797",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "73",
        "-14.514",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "73",
        "-14.227",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "73",
        "-13.935",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "73",
        "-13.639",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "73",
        "-13.339",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "73",
        "-13.034",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "73",
        "-12.727",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "73",
        "-12.414",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "73",
        "-12.098",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "73",
        "-11.778",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "73",
        "-11.455",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "73",
        "-11.129",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "73",
        "-10.800",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "73",
        "-10.466",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "73",
        "-10.129",
        "20.245",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "73",
        "-9.790",
        "20.425",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "73",
        "-9.448",
        "20.599",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "73",
        "-9.103",
        "20.767",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "73",
        "-8.755",
        "20.928",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "73",
        "-8.404",
        "21.084",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "73",
        "-8.051",
        "21.232",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "73",
        "-7.696",
        "21.376",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "73",
        "-7.337",
        "21.511",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "73",
        "-6.978",
        "21.640",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "73",
        "-6.615",
        "21.763",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "73",
        "-6.251",
        "21.879",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "73",
        "-5.884",
        "21.989",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "73",
        "-5.517",
        "22.091",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "73",
        "-5.147",
        "22.187",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "73",
        "-4.776",
        "22.277",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "73",
        "-4.404",
        "22.359",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "73",
        "-4.030",
        "22.435",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "73",
        "-3.655",
        "22.504",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "73",
        "-3.279",
        "22.566",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "73",
        "-2.902",
        "22.621",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "73",
        "-2.523",
        "22.670",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "73",
        "-2.145",
        "22.710",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "73",
        "-1.766",
        "22.745",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "73",
        "2.623",
        "20.271",
        "2.310",
        "2.832",
        "-0.437"
    ],
    [
        "6",
        "73",
        "8.118",
        "18.829",
        "2.579",
        "3.120",
        "-0.487"
    ],
    [
        "7",
        "73",
        "12.165",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "73",
        "12.442",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "73",
        "12.716",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "73",
        "12.991",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "73",
        "13.263",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "73",
        "13.533",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "73",
        "13.801",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "73",
        "14.068",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "73",
        "14.332",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "73",
        "14.593",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "73",
        "14.853",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "73",
        "15.111",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "73",
        "15.365",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "73",
        "15.618",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "73",
        "15.867",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "73",
        "16.115",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "73",
        "16.359",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "73",
        "16.601",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "73",
        "16.839",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "73",
        "17.075",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "73",
        "17.307",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "73",
        "17.537",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "73",
        "17.764",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "73",
        "17.987",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "73",
        "18.207",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "73",
        "18.424",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "73",
        "18.637",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "73",
        "18.846",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "73",
        "19.053",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "73",
        "19.255",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "73",
        "19.454",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "73",
        "19.649",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "73",
        "19.840",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "73",
        "20.027",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "73",
        "20.211",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "73",
        "20.390",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "73",
        "20.565",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "73",
        "20.736",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "73",
        "20.903",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "73",
        "21.066",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "73",
        "21.226",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "73",
        "21.380",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "73",
        "21.530",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "73",
        "21.676",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "73",
        "21.817",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "73",
        "21.954",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "73",
        "22.086",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "73",
        "22.214",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "73",
        "22.338",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "73",
        "22.456",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "73",
        "22.570",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "73",
        "22.679",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "73",
        "22.783",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "73",
        "22.883",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "73",
        "22.978",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "73",
        "23.068",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "73",
        "23.153",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "73",
        "23.233",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "73",
        "23.310",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "73",
        "23.380",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "73",
        "23.445",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "73",
        "23.506",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "73",
        "23.562",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "73",
        "23.612",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "73",
        "23.658",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "73",
        "23.699",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "73",
        "23.734",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "73",
        "23.764",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "73",
        "23.790",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "73",
        "23.811",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "73",
        "23.826",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "73",
        "23.836",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "73",
        "23.842",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "73",
        "23.841",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "73",
        "23.836",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "73",
        "23.826",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "73",
        "23.811",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "73",
        "23.791",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "73",
        "23.765",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "73",
        "23.734",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "73",
        "23.699",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "73",
        "23.659",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "73",
        "23.613",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "73",
        "23.563",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "73",
        "23.507",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "73",
        "23.447",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "73",
        "23.381",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "73",
        "23.311",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "73",
        "23.235",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "73",
        "23.155",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "73",
        "23.070",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "73",
        "22.980",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "73",
        "22.885",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "73",
        "22.785",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "73",
        "22.681",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "73",
        "22.572",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "73",
        "22.458",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "73",
        "22.340",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "73",
        "22.217",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "73",
        "22.089",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "73",
        "21.956",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "73",
        "21.820",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "73",
        "21.679",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "73",
        "21.533",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "73",
        "21.383",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "73",
        "21.229",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "73",
        "21.070",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "73",
        "20.907",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "73",
        "20.740",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "73",
        "20.569",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "73",
        "20.394",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "73",
        "20.214",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "73",
        "20.031",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "73",
        "19.844",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "73",
        "19.653",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "73",
        "19.461",
        "-11.791",
        "0.146",
        "0.191",
        "0.180"
    ],
    [
        "123",
        "73",
        "19.299",
        "-11.976",
        "0.750",
        "0.854",
        "0.211"
    ],
    [
        "124",
        "73",
        "19.143",
        "-12.138",
        "0.886",
        "1.031",
        "0.177"
    ],
    [
        "125",
        "73",
        "18.994",
        "-12.275",
        "1.036",
        "1.196",
        "0.133"
    ],
    [
        "126",
        "73",
        "18.841",
        "-12.411",
        "1.203",
        "1.304",
        "0.042"
    ],
    [
        "127",
        "73",
        "18.692",
        "-12.540",
        "1.240",
        "1.372",
        "0.008"
    ],
    [
        "128",
        "73",
        "18.539",
        "-12.670",
        "1.281",
        "1.372",
        "-0.014"
    ],
    [
        "129",
        "73",
        "18.377",
        "-12.807",
        "1.340",
        "1.331",
        "-0.038"
    ],
    [
        "130",
        "73",
        "18.208",
        "-12.948",
        "1.289",
        "1.289",
        "-0.067"
    ],
    [
        "131",
        "73",
        "18.035",
        "-13.095",
        "1.278",
        "1.234",
        "-0.078"
    ],
    [
        "132",
        "73",
        "17.860",
        "-13.252",
        "1.300",
        "1.167",
        "-0.089"
    ],
    [
        "133",
        "73",
        "17.686",
        "-13.427",
        "1.212",
        "1.091",
        "-0.095"
    ],
    [
        "134",
        "73",
        "17.510",
        "-13.621",
        "1.158",
        "1.017",
        "-0.094"
    ],
    [
        "135",
        "73",
        "17.330",
        "-13.830",
        "1.143",
        "0.962",
        "-0.089"
    ],
    [
        "136",
        "73",
        "17.149",
        "-14.038",
        "0.980",
        "0.898",
        "-0.069"
    ],
    [
        "137",
        "73",
        "16.973",
        "-14.240",
        "0.617",
        "0.515",
        "-0.052"
    ],
    [
        "138",
        "73",
        "16.755",
        "-14.524",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "73",
        "16.510",
        "-14.829",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "73",
        "16.260",
        "-15.129",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "73",
        "16.005",
        "-15.425",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "73",
        "15.745",
        "-15.717",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "73",
        "15.480",
        "-16.002",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "73",
        "15.211",
        "-16.284",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "73",
        "14.936",
        "-16.561",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "73",
        "14.658",
        "-16.832",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "73",
        "14.375",
        "-17.098",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "73",
        "14.087",
        "-17.359",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "73",
        "13.795",
        "-17.614",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "73",
        "13.499",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "73",
        "13.199",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "73",
        "12.895",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "73",
        "12.586",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "73",
        "12.275",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "73",
        "11.959",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "73",
        "11.639",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "73",
        "11.316",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "73",
        "10.990",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "73",
        "10.660",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "73",
        "10.326",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "73",
        "9.990",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "73",
        "9.651",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "73",
        "9.308",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "73",
        "8.964",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "73",
        "8.615",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "73",
        "8.265",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "73",
        "7.912",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "73",
        "7.556",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "73",
        "7.198",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "73",
        "6.838",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "73",
        "6.476",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "73",
        "6.112",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "73",
        "5.745",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "73",
        "5.377",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "73",
        "5.008",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "73",
        "4.637",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "73",
        "4.264",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "73",
        "3.890",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "73",
        "3.516",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "74",
        "3.159",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "74",
        "2.781",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "74",
        "2.401",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "74",
        "2.020",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "74",
        "1.639",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "74",
        "1.257",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "74",
        "0.875",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "74",
        "0.493",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "74",
        "0.110",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "74",
        "-0.273",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "74",
        "-0.656",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "74",
        "-1.038",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "74",
        "-1.421",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "74",
        "-1.802",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "74",
        "-2.183",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "74",
        "-2.564",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "74",
        "-2.944",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "74",
        "-3.323",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "74",
        "-3.702",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "74",
        "-4.078",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "74",
        "-4.454",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "74",
        "-4.828",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "74",
        "-5.201",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "74",
        "-5.572",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "74",
        "-5.942",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "74",
        "-6.310",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "74",
        "-6.675",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "74",
        "-7.039",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "74",
        "-7.402",
        "-21.500",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "74",
        "-7.761",
        "-21.363",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "74",
        "-8.118",
        "-21.220",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "74",
        "-8.473",
        "-21.072",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "74",
        "-8.825",
        "-20.915",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "74",
        "-9.174",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "74",
        "-9.522",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "74",
        "-9.866",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "74",
        "-10.206",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "74",
        "-10.544",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "74",
        "-10.879",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "74",
        "-11.210",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "74",
        "-11.538",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "74",
        "-11.862",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "74",
        "-12.183",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "74",
        "-12.501",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "74",
        "-12.814",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "74",
        "-13.123",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "74",
        "-13.429",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "74",
        "-13.731",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "74",
        "-14.028",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "74",
        "-14.321",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "74",
        "-14.609",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "74",
        "-14.893",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "74",
        "-15.173",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "74",
        "-15.449",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "74",
        "-15.719",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "74",
        "-15.985",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "74",
        "-16.245",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "74",
        "-16.501",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "74",
        "-16.752",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "74",
        "-16.998",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "74",
        "-17.239",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "74",
        "-17.474",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "74",
        "-17.704",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "74",
        "-17.929",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "74",
        "-18.148",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "74",
        "-18.362",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "74",
        "-18.570",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "74",
        "-18.773",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "74",
        "-18.970",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "74",
        "-19.160",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "74",
        "-19.346",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "74",
        "-19.526",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "74",
        "-19.699",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "74",
        "-19.867",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "74",
        "-20.028",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "74",
        "-20.183",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "74",
        "-20.333",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "74",
        "-20.476",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "74",
        "-20.613",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "74",
        "-20.744",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "74",
        "-20.868",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "74",
        "-20.986",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "74",
        "-21.097",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "74",
        "-21.203",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "74",
        "-21.302",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "74",
        "-21.394",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "74",
        "-21.480",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "74",
        "-21.559",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "74",
        "-21.632",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "74",
        "-21.698",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "74",
        "-21.758",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "74",
        "-21.811",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "74",
        "-21.857",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "74",
        "-21.897",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "74",
        "-21.930",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "74",
        "-21.957",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "74",
        "-21.976",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "74",
        "-21.989",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "74",
        "-21.995",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "74",
        "-21.995",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "74",
        "-21.989",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "74",
        "-21.975",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "74",
        "-21.955",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "74",
        "-21.927",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "74",
        "-21.894",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "74",
        "-21.853",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "74",
        "-21.806",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "74",
        "-21.753",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "74",
        "-21.693",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "74",
        "-21.626",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "74",
        "-21.553",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "74",
        "-21.473",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "74",
        "-21.387",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "74",
        "-21.294",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "74",
        "-21.194",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "74",
        "-21.088",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "74",
        "-20.977",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "74",
        "-20.858",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "74",
        "-20.733",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "74",
        "-20.602",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "74",
        "-20.464",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "74",
        "-20.321",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "74",
        "-20.171",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "74",
        "-20.015",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "74",
        "-19.853",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "74",
        "-19.685",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "74",
        "-19.511",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "74",
        "-19.331",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "74",
        "-19.145",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "74",
        "-18.954",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "74",
        "-18.757",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "74",
        "-18.553",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "74",
        "-18.345",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "74",
        "-18.130",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "74",
        "-17.911",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "74",
        "-17.685",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "74",
        "-17.454",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "74",
        "-17.218",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "74",
        "-16.977",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "74",
        "-16.731",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "74",
        "-16.480",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "74",
        "-16.224",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "74",
        "-15.963",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "74",
        "-15.697",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "74",
        "-15.425",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "74",
        "-15.150",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "74",
        "-14.870",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "74",
        "-14.586",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "74",
        "-14.296",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "74",
        "-14.003",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "74",
        "-13.705",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "74",
        "-13.404",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "74",
        "-13.097",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "74",
        "-12.788",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "74",
        "-12.474",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "74",
        "-12.156",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "74",
        "-11.835",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "74",
        "-11.510",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "74",
        "-11.182",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "74",
        "-10.851",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "74",
        "-10.516",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "74",
        "-10.178",
        "20.245",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "74",
        "-9.837",
        "20.425",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "74",
        "-9.492",
        "20.599",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "74",
        "-9.146",
        "20.767",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "74",
        "-8.795",
        "20.928",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "74",
        "-8.444",
        "21.084",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "74",
        "-8.088",
        "21.232",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "74",
        "-7.731",
        "21.376",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "74",
        "-7.371",
        "21.511",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "74",
        "-7.009",
        "21.640",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "74",
        "-6.645",
        "21.763",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "74",
        "-6.279",
        "21.879",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "74",
        "-5.911",
        "21.989",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "74",
        "-5.541",
        "22.091",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "74",
        "-5.170",
        "22.187",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "74",
        "-4.797",
        "22.277",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "74",
        "-4.422",
        "22.359",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "74",
        "-4.047",
        "22.435",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "74",
        "-3.670",
        "22.504",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "74",
        "-3.292",
        "22.566",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "74",
        "-2.912",
        "22.621",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "74",
        "-2.533",
        "22.670",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "74",
        "-2.152",
        "22.710",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "74",
        "-1.770",
        "22.745",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "74",
        "-1.388",
        "22.772",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "74",
        "-1.006",
        "22.794",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "74",
        "-0.624",
        "22.807",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "74",
        "-0.241",
        "22.814",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "74",
        "7.183",
        "18.948",
        "4.332",
        "2.777",
        "-0.533"
    ],
    [
        "10",
        "74",
        "11.911",
        "16.586",
        "3.711",
        "2.445",
        "-0.414"
    ],
    [
        "11",
        "74",
        "12.885",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "74",
        "13.157",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "74",
        "13.427",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "74",
        "13.694",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "74",
        "13.960",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "74",
        "14.223",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "74",
        "14.484",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "74",
        "14.742",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "74",
        "14.999",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "74",
        "15.253",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "74",
        "15.504",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "74",
        "15.752",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "74",
        "15.998",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "74",
        "16.240",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "74",
        "16.481",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "74",
        "16.717",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "74",
        "16.951",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "74",
        "17.182",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "74",
        "17.410",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "74",
        "17.634",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "74",
        "17.855",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "74",
        "18.073",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "74",
        "18.287",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "74",
        "18.498",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "74",
        "18.705",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "74",
        "18.909",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "74",
        "19.108",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "74",
        "19.305",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "74",
        "19.496",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "74",
        "19.684",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "74",
        "19.869",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "74",
        "20.050",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "74",
        "20.226",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "74",
        "20.398",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "74",
        "20.566",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "74",
        "20.730",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "74",
        "20.889",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "74",
        "21.045",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "74",
        "21.196",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "74",
        "21.342",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "74",
        "21.484",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "74",
        "21.622",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "74",
        "21.755",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "74",
        "21.883",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "74",
        "22.007",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "74",
        "22.126",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "74",
        "22.240",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "74",
        "22.351",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "74",
        "22.455",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "74",
        "22.555",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "74",
        "22.651",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "74",
        "22.742",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "74",
        "22.827",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "74",
        "22.908",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "74",
        "22.984",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "74",
        "23.055",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "74",
        "23.121",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "74",
        "23.181",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "74",
        "23.238",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "74",
        "23.289",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "74",
        "23.335",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "74",
        "23.375",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "74",
        "23.411",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "74",
        "23.441",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "74",
        "23.467",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "74",
        "23.488",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "74",
        "23.503",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "74",
        "23.513",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "74",
        "23.519",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "74",
        "23.518",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "74",
        "23.513",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "74",
        "23.503",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "74",
        "23.488",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "74",
        "23.467",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "74",
        "23.442",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "74",
        "23.412",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "74",
        "23.376",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "74",
        "23.336",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "74",
        "23.289",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "74",
        "23.239",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "74",
        "23.183",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "74",
        "23.122",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "74",
        "23.057",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "74",
        "22.986",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "74",
        "22.909",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "74",
        "22.829",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "74",
        "22.743",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "74",
        "22.653",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "74",
        "22.558",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "74",
        "22.457",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "74",
        "22.352",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "74",
        "22.242",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "74",
        "22.128",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "74",
        "22.010",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "74",
        "21.886",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "74",
        "21.758",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "74",
        "21.624",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "74",
        "21.487",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "74",
        "21.345",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "74",
        "21.199",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "74",
        "21.048",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "74",
        "20.893",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "74",
        "20.733",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "74",
        "20.570",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "74",
        "20.402",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "74",
        "20.229",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "74",
        "20.053",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "74",
        "19.873",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "74",
        "19.688",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "74",
        "19.500",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "74",
        "19.309",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "74",
        "19.122",
        "-11.786",
        "0.162",
        "0.181",
        "0.192"
    ],
    [
        "123",
        "74",
        "19.031",
        "-11.872",
        "0.825",
        "0.885",
        "0.243"
    ],
    [
        "124",
        "74",
        "18.934",
        "-11.960",
        "0.969",
        "1.086",
        "0.187"
    ],
    [
        "125",
        "74",
        "18.831",
        "-12.055",
        "1.110",
        "1.247",
        "0.121"
    ],
    [
        "126",
        "74",
        "18.726",
        "-12.155",
        "1.275",
        "1.358",
        "0.059"
    ],
    [
        "127",
        "74",
        "18.613",
        "-12.257",
        "1.299",
        "1.415",
        "0.017"
    ],
    [
        "128",
        "74",
        "18.491",
        "-12.365",
        "1.328",
        "1.399",
        "-0.015"
    ],
    [
        "129",
        "74",
        "18.357",
        "-12.495",
        "1.367",
        "1.322",
        "-0.045"
    ],
    [
        "130",
        "74",
        "18.207",
        "-12.655",
        "1.295",
        "1.224",
        "-0.064"
    ],
    [
        "131",
        "74",
        "18.045",
        "-12.845",
        "1.262",
        "1.131",
        "-0.072"
    ],
    [
        "132",
        "74",
        "17.880",
        "-13.051",
        "1.264",
        "1.050",
        "-0.077"
    ],
    [
        "133",
        "74",
        "17.722",
        "-13.266",
        "1.164",
        "0.975",
        "-0.070"
    ],
    [
        "134",
        "74",
        "17.572",
        "-13.487",
        "1.083",
        "0.893",
        "-0.073"
    ],
    [
        "135",
        "74",
        "17.431",
        "-13.706",
        "1.000",
        "0.770",
        "-0.075"
    ],
    [
        "136",
        "74",
        "17.310",
        "-13.910",
        "0.208",
        "0.178",
        "-0.040"
    ],
    [
        "137",
        "74",
        "17.087",
        "-14.216",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "74",
        "16.846",
        "-14.524",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "74",
        "16.600",
        "-14.829",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "74",
        "16.348",
        "-15.129",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "74",
        "16.092",
        "-15.425",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "74",
        "15.831",
        "-15.717",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "74",
        "15.565",
        "-16.002",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "74",
        "15.294",
        "-16.284",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "74",
        "15.018",
        "-16.561",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "74",
        "14.738",
        "-16.832",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "74",
        "14.453",
        "-17.098",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "74",
        "14.164",
        "-17.359",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "74",
        "13.871",
        "-17.614",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "74",
        "13.574",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "74",
        "13.272",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "74",
        "12.966",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "74",
        "12.656",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "74",
        "12.343",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "74",
        "12.025",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "74",
        "11.704",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "74",
        "11.379",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "74",
        "11.051",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "74",
        "10.719",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "74",
        "10.385",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "74",
        "10.046",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "74",
        "9.706",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "74",
        "9.361",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "74",
        "9.014",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "74",
        "8.664",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "74",
        "8.311",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "74",
        "7.957",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "74",
        "7.599",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "74",
        "7.239",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "74",
        "6.878",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "74",
        "6.514",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "74",
        "6.147",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "74",
        "5.780",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "74",
        "5.409",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "74",
        "5.038",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "74",
        "4.665",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "74",
        "4.291",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "74",
        "3.915",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "74",
        "3.538",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "75",
        "3.180",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "75",
        "2.799",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "75",
        "2.417",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "75",
        "2.035",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "75",
        "1.651",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "75",
        "1.267",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "75",
        "0.884",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "75",
        "0.499",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "75",
        "0.115",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "75",
        "-0.271",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "75",
        "-0.655",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "75",
        "-1.039",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "75",
        "-1.423",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "75",
        "-1.807",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "75",
        "-2.190",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "75",
        "-2.572",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "75",
        "-2.954",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "75",
        "-3.335",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "75",
        "-3.715",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "75",
        "-4.094",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "75",
        "-4.471",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "75",
        "-4.847",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "75",
        "-5.222",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "75",
        "-5.595",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "75",
        "-5.967",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "75",
        "-6.336",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "75",
        "-6.703",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "75",
        "-7.070",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "75",
        "-7.433",
        "-21.500",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "75",
        "-7.794",
        "-21.363",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "75",
        "-8.153",
        "-21.220",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "75",
        "-8.509",
        "-21.072",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "75",
        "-8.864",
        "-20.915",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "75",
        "-9.215",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "75",
        "-9.563",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "75",
        "-9.909",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "75",
        "-10.252",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "75",
        "-10.591",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "75",
        "-10.927",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "75",
        "-11.260",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "75",
        "-11.590",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "75",
        "-11.916",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "75",
        "-12.238",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "75",
        "-12.557",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "75",
        "-12.871",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "75",
        "-13.183",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "75",
        "-13.490",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "75",
        "-13.793",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "75",
        "-14.091",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "75",
        "-14.386",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "75",
        "-14.676",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "75",
        "-14.961",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "75",
        "-15.243",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "75",
        "-15.519",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "75",
        "-15.791",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "75",
        "-16.057",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "75",
        "-16.320",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "75",
        "-16.578",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "75",
        "-16.829",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "75",
        "-17.076",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "75",
        "-17.318",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "75",
        "-17.555",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "75",
        "-17.785",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "75",
        "-18.012",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "75",
        "-18.232",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "75",
        "-18.447",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "75",
        "-18.657",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "75",
        "-18.860",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "75",
        "-19.058",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "75",
        "-19.250",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "75",
        "-19.436",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "75",
        "-19.616",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "75",
        "-19.790",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "75",
        "-19.958",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "75",
        "-20.121",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "75",
        "-20.277",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "75",
        "-20.427",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "75",
        "-20.571",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "75",
        "-20.709",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "75",
        "-20.840",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "75",
        "-20.965",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "75",
        "-21.083",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "75",
        "-21.196",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "75",
        "-21.301",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "75",
        "-21.401",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "75",
        "-21.494",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "75",
        "-21.579",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "75",
        "-21.659",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "75",
        "-21.733",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "75",
        "-21.799",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "75",
        "-21.859",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "75",
        "-21.912",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "75",
        "-21.959",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "75",
        "-21.999",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "75",
        "-22.032",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "75",
        "-22.058",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "75",
        "-22.078",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "75",
        "-22.091",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "75",
        "-22.098",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "75",
        "-22.097",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "75",
        "-22.091",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "75",
        "-22.077",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "75",
        "-22.057",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "75",
        "-22.030",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "75",
        "-21.995",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "75",
        "-21.955",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "75",
        "-21.908",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "75",
        "-21.855",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "75",
        "-21.794",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "75",
        "-21.727",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "75",
        "-21.653",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "75",
        "-21.573",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "75",
        "-21.486",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "75",
        "-21.393",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "75",
        "-21.293",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "75",
        "-21.187",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "75",
        "-21.074",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "75",
        "-20.955",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "75",
        "-20.829",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "75",
        "-20.698",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "75",
        "-20.559",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "75",
        "-20.415",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "75",
        "-20.264",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "75",
        "-20.108",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "75",
        "-19.945",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "75",
        "-19.776",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "75",
        "-19.602",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "75",
        "-19.420",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "75",
        "-19.234",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "75",
        "-19.042",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "75",
        "-18.843",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "75",
        "-18.639",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "75",
        "-18.429",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "75",
        "-18.214",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "75",
        "-17.993",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "75",
        "-17.766",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "75",
        "-17.535",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "75",
        "-17.298",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "75",
        "-17.055",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "75",
        "-16.808",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "75",
        "-16.555",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "75",
        "-16.298",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "75",
        "-16.036",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "75",
        "-15.768",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "75",
        "-15.496",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "75",
        "-15.220",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "75",
        "-14.938",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "75",
        "-14.652",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "75",
        "-14.361",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "75",
        "-14.067",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "75",
        "-13.767",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "75",
        "-13.464",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "75",
        "-13.157",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "75",
        "-12.846",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "75",
        "-12.530",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "75",
        "-12.211",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "75",
        "-11.889",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "75",
        "-11.563",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "75",
        "-11.233",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "75",
        "-10.899",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "75",
        "-10.562",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "75",
        "-10.223",
        "20.245",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "75",
        "-9.880",
        "20.425",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "75",
        "-9.535",
        "20.599",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "75",
        "-9.185",
        "20.767",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "75",
        "-8.834",
        "20.928",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "75",
        "-8.480",
        "21.084",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "75",
        "-8.124",
        "21.232",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "75",
        "-7.765",
        "21.376",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "75",
        "-7.403",
        "21.511",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "75",
        "-7.039",
        "21.640",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "75",
        "-6.673",
        "21.763",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "75",
        "-6.305",
        "21.879",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "75",
        "-5.936",
        "21.989",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "75",
        "-5.564",
        "22.091",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "75",
        "-5.191",
        "22.187",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "75",
        "-4.815",
        "22.277",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "75",
        "-4.439",
        "22.359",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "75",
        "-4.062",
        "22.435",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "75",
        "-3.683",
        "22.504",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "75",
        "-3.303",
        "22.566",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "75",
        "-2.922",
        "22.621",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "75",
        "-2.540",
        "22.670",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "75",
        "-2.158",
        "22.710",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "75",
        "-1.774",
        "22.745",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "75",
        "-1.391",
        "22.772",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "75",
        "-1.007",
        "22.794",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "75",
        "-0.622",
        "22.807",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "75",
        "0.470",
        "22.550",
        "1.070",
        "0.915",
        "-0.477"
    ],
    [
        "9",
        "75",
        "7.041",
        "19.070",
        "4.176",
        "2.906",
        "-0.561"
    ],
    [
        "10",
        "75",
        "12.229",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "75",
        "12.504",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "75",
        "12.777",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "75",
        "13.048",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "75",
        "13.317",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "75",
        "13.584",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "75",
        "13.848",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "75",
        "14.110",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "75",
        "14.370",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "75",
        "14.628",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "75",
        "14.883",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "75",
        "15.135",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "75",
        "15.384",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "75",
        "15.631",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "75",
        "15.875",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "75",
        "16.117",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "75",
        "16.355",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "75",
        "16.589",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "75",
        "16.822",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "75",
        "17.051",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "75",
        "17.276",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "75",
        "17.498",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "75",
        "17.717",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "75",
        "17.932",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "75",
        "18.144",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "75",
        "18.352",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "75",
        "18.556",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "75",
        "18.758",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "75",
        "18.955",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "75",
        "19.147",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "75",
        "19.336",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "75",
        "19.522",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "75",
        "19.703",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "75",
        "19.881",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "75",
        "20.053",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "75",
        "20.222",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "75",
        "20.387",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "75",
        "20.548",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "75",
        "20.703",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "75",
        "20.855",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "75",
        "21.002",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "75",
        "21.145",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "75",
        "21.283",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "75",
        "21.417",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "75",
        "21.546",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "75",
        "21.670",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "75",
        "21.790",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "75",
        "21.905",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "75",
        "22.015",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "75",
        "22.120",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "75",
        "22.221",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "75",
        "22.317",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "75",
        "22.408",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "75",
        "22.495",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "75",
        "22.576",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "75",
        "22.652",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "75",
        "22.723",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "75",
        "22.789",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "75",
        "22.850",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "75",
        "22.907",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "75",
        "22.958",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "75",
        "23.004",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "75",
        "23.045",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "75",
        "23.081",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "75",
        "23.112",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "75",
        "23.138",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "75",
        "23.157",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "75",
        "23.173",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "75",
        "23.183",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "75",
        "23.188",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "75",
        "23.189",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "75",
        "23.184",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "75",
        "23.174",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "75",
        "23.159",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "75",
        "23.137",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "75",
        "23.112",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "75",
        "23.082",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "75",
        "23.046",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "75",
        "23.005",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "75",
        "22.959",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "75",
        "22.908",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "75",
        "22.852",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "75",
        "22.791",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "75",
        "22.724",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "75",
        "22.653",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "75",
        "22.577",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "75",
        "22.496",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "75",
        "22.410",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "75",
        "22.319",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "75",
        "22.224",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "75",
        "22.123",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "75",
        "22.017",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "75",
        "21.907",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "75",
        "21.792",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "75",
        "21.672",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "75",
        "21.548",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "75",
        "21.419",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "75",
        "21.285",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "75",
        "21.147",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "75",
        "21.004",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "75",
        "20.858",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "75",
        "20.706",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "75",
        "20.551",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "75",
        "20.390",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "75",
        "20.226",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "75",
        "20.056",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "75",
        "19.884",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "75",
        "19.706",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "75",
        "19.526",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "75",
        "19.340",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "75",
        "19.151",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "75",
        "18.958",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "122",
        "75",
        "18.793",
        "-11.769",
        "0.187",
        "0.181",
        "0.244"
    ],
    [
        "123",
        "75",
        "18.843",
        "-11.718",
        "0.930",
        "0.860",
        "0.229"
    ],
    [
        "124",
        "75",
        "18.819",
        "-11.748",
        "1.044",
        "1.041",
        "0.163"
    ],
    [
        "125",
        "75",
        "18.765",
        "-11.822",
        "1.154",
        "1.179",
        "0.109"
    ],
    [
        "126",
        "75",
        "18.689",
        "-11.917",
        "1.287",
        "1.284",
        "0.057"
    ],
    [
        "127",
        "75",
        "18.597",
        "-12.019",
        "1.294",
        "1.344",
        "0.020"
    ],
    [
        "128",
        "75",
        "18.490",
        "-12.131",
        "1.312",
        "1.337",
        "-0.008"
    ],
    [
        "129",
        "75",
        "18.367",
        "-12.271",
        "1.342",
        "1.263",
        "-0.035"
    ],
    [
        "130",
        "75",
        "18.226",
        "-12.454",
        "1.263",
        "1.156",
        "-0.047"
    ],
    [
        "131",
        "75",
        "18.071",
        "-12.675",
        "1.222",
        "1.054",
        "-0.046"
    ],
    [
        "132",
        "75",
        "17.915",
        "-12.918",
        "1.218",
        "0.972",
        "-0.034"
    ],
    [
        "133",
        "75",
        "17.767",
        "-13.169",
        "1.117",
        "0.903",
        "0.002"
    ],
    [
        "134",
        "75",
        "17.633",
        "-13.421",
        "1.037",
        "0.832",
        "0.028"
    ],
    [
        "135",
        "75",
        "17.508",
        "-13.667",
        "0.956",
        "0.716",
        "0.050"
    ],
    [
        "136",
        "75",
        "17.398",
        "-13.906",
        "0.199",
        "0.165",
        "0.042"
    ],
    [
        "137",
        "75",
        "17.175",
        "-14.216",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "75",
        "16.932",
        "-14.524",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "75",
        "16.685",
        "-14.829",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "75",
        "16.432",
        "-15.129",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "75",
        "16.174",
        "-15.425",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "75",
        "15.912",
        "-15.717",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "75",
        "15.645",
        "-16.002",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "75",
        "15.373",
        "-16.284",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "75",
        "15.096",
        "-16.561",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "75",
        "14.815",
        "-16.832",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "75",
        "14.528",
        "-17.098",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "75",
        "14.238",
        "-17.359",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "75",
        "13.943",
        "-17.614",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "75",
        "13.644",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "75",
        "13.341",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "75",
        "13.033",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "75",
        "12.722",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "75",
        "12.407",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "75",
        "12.088",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "75",
        "11.765",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "75",
        "11.439",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "75",
        "11.109",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "75",
        "10.776",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "75",
        "10.439",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "75",
        "10.100",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "75",
        "9.757",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "75",
        "9.411",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "75",
        "9.062",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "75",
        "8.710",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "75",
        "8.357",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "75",
        "8.000",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "75",
        "7.640",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "75",
        "7.280",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "75",
        "6.915",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "75",
        "6.549",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "75",
        "6.181",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "75",
        "5.812",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "75",
        "5.441",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "75",
        "5.067",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "75",
        "4.692",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "75",
        "4.316",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "75",
        "3.938",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "75",
        "3.560",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "76",
        "3.198",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "76",
        "2.816",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "76",
        "2.432",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "76",
        "2.048",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "76",
        "1.663",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "76",
        "1.277",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "76",
        "0.892",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "76",
        "0.505",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "76",
        "0.120",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "76",
        "-0.267",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "76",
        "-0.653",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "76",
        "-1.039",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "76",
        "-1.425",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "76",
        "-1.811",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "76",
        "-2.196",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "76",
        "-2.580",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "76",
        "-2.963",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "76",
        "-3.346",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "76",
        "-3.727",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "76",
        "-4.107",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "76",
        "-4.487",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "76",
        "-4.865",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "76",
        "-5.241",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "76",
        "-5.616",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "76",
        "-5.989",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "76",
        "-6.360",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "76",
        "-6.730",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "76",
        "-7.097",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "76",
        "-7.462",
        "-21.500",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "76",
        "-7.825",
        "-21.363",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "76",
        "-8.186",
        "-21.220",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "76",
        "-8.544",
        "-21.072",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "76",
        "-8.899",
        "-20.915",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "76",
        "-9.252",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "76",
        "-9.602",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "76",
        "-9.949",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "76",
        "-10.293",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "76",
        "-10.635",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "76",
        "-10.972",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "76",
        "-11.307",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "76",
        "-11.637",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "76",
        "-11.965",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "76",
        "-12.289",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "76",
        "-12.609",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "76",
        "-12.925",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "76",
        "-13.238",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "76",
        "-13.546",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "76",
        "-13.851",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "76",
        "-14.151",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "76",
        "-14.447",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "76",
        "-14.738",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "76",
        "-15.025",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "76",
        "-15.307",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "76",
        "-15.585",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "76",
        "-15.858",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "76",
        "-16.126",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "76",
        "-16.389",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "76",
        "-16.648",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "76",
        "-16.901",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "76",
        "-17.149",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "76",
        "-17.392",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "76",
        "-17.629",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "76",
        "-17.862",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "76",
        "-18.089",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "76",
        "-18.311",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "76",
        "-18.526",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "76",
        "-18.737",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "76",
        "-18.940",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "76",
        "-19.140",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "76",
        "-19.333",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "76",
        "-19.519",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "76",
        "-19.700",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "76",
        "-19.876",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "76",
        "-20.044",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "76",
        "-20.208",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "76",
        "-20.364",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "76",
        "-20.515",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "76",
        "-20.660",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "76",
        "-20.798",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "76",
        "-20.930",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "76",
        "-21.055",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "76",
        "-21.175",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "76",
        "-21.287",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "76",
        "-21.393",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "76",
        "-21.493",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "76",
        "-21.586",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "76",
        "-21.673",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "76",
        "-21.753",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "76",
        "-21.827",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "76",
        "-21.894",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "76",
        "-21.953",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "76",
        "-22.007",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "76",
        "-22.054",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "76",
        "-22.094",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "76",
        "-22.127",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "76",
        "-22.154",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "76",
        "-22.174",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "76",
        "-22.187",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "76",
        "-22.193",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "76",
        "-22.193",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "76",
        "-22.186",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "76",
        "-22.173",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "76",
        "-22.152",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "76",
        "-22.125",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "76",
        "-22.091",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "76",
        "-22.051",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "76",
        "-22.003",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "76",
        "-21.949",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "76",
        "-21.888",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "76",
        "-21.821",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "76",
        "-21.746",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "76",
        "-21.666",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "76",
        "-21.579",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "76",
        "-21.485",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "76",
        "-21.385",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "76",
        "-21.278",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "76",
        "-21.165",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "76",
        "-21.046",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "76",
        "-20.920",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "76",
        "-20.786",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "76",
        "-20.648",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "76",
        "-20.503",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "76",
        "-20.351",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "76",
        "-20.195",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "76",
        "-20.031",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "76",
        "-19.861",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "76",
        "-19.686",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "76",
        "-19.504",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "76",
        "-19.317",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "76",
        "-19.123",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "76",
        "-18.924",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "76",
        "-18.719",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "76",
        "-18.508",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "76",
        "-18.292",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "76",
        "-18.070",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "76",
        "-17.843",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "76",
        "-17.610",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "76",
        "-17.372",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "76",
        "-17.129",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "76",
        "-16.880",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "76",
        "-16.626",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "76",
        "-16.367",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "76",
        "-16.104",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "76",
        "-15.835",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "76",
        "-15.562",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "76",
        "-15.283",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "76",
        "-15.001",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "76",
        "-14.713",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "76",
        "-14.422",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "76",
        "-14.126",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "76",
        "-13.825",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "76",
        "-13.521",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "76",
        "-13.212",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "76",
        "-12.899",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "76",
        "-12.582",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "76",
        "-12.263",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "76",
        "-11.938",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "76",
        "-11.610",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "76",
        "-11.278",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "76",
        "-10.944",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "76",
        "-10.606",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "76",
        "-10.265",
        "20.245",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "76",
        "-9.921",
        "20.425",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "76",
        "-9.573",
        "20.599",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "76",
        "-9.223",
        "20.767",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "76",
        "-8.870",
        "20.928",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "76",
        "-8.514",
        "21.084",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "76",
        "-8.156",
        "21.232",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "76",
        "-7.795",
        "21.376",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "76",
        "-7.431",
        "21.511",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "76",
        "-7.066",
        "21.640",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "76",
        "-6.699",
        "21.763",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "76",
        "-6.329",
        "21.879",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "76",
        "-5.958",
        "21.989",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "76",
        "-5.584",
        "22.091",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "76",
        "-5.210",
        "22.187",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "76",
        "-4.834",
        "22.277",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "76",
        "-4.455",
        "22.359",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "76",
        "-4.075",
        "22.435",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "76",
        "-3.695",
        "22.504",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "76",
        "-3.314",
        "22.566",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "76",
        "-2.931",
        "22.621",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "76",
        "-2.548",
        "22.670",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "76",
        "-2.163",
        "22.710",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "76",
        "-1.779",
        "22.745",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "76",
        "-1.393",
        "22.772",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "76",
        "-1.007",
        "22.794",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "76",
        "0.963",
        "21.977",
        "3.151",
        "3.464",
        "-0.464"
    ],
    [
        "8",
        "76",
        "3.833",
        "20.844",
        "3.957",
        "3.792",
        "-0.496"
    ],
    [
        "9",
        "76",
        "8.275",
        "18.209",
        "3.950",
        "3.220",
        "-0.529"
    ],
    [
        "10",
        "76",
        "11.843",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "76",
        "12.119",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "76",
        "12.394",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "76",
        "12.665",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "76",
        "12.935",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "76",
        "13.204",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "76",
        "13.469",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "76",
        "13.733",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "76",
        "13.993",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "76",
        "14.252",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "76",
        "14.508",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "76",
        "14.761",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "76",
        "15.012",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "76",
        "15.261",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "76",
        "15.505",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "76",
        "15.748",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "76",
        "15.987",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "76",
        "16.223",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "76",
        "16.456",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "76",
        "16.686",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "76",
        "16.913",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "76",
        "17.136",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "76",
        "17.356",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "76",
        "17.572",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "76",
        "17.785",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "76",
        "17.993",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "76",
        "18.199",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "76",
        "18.401",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "76",
        "18.599",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "76",
        "18.792",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "76",
        "18.982",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "76",
        "19.168",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "76",
        "19.351",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "76",
        "19.529",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "76",
        "19.702",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "76",
        "19.872",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "76",
        "20.038",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "76",
        "20.198",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "76",
        "20.355",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "76",
        "20.508",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "76",
        "20.655",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "76",
        "20.799",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "76",
        "20.937",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "76",
        "21.072",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "76",
        "21.202",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "76",
        "21.326",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "76",
        "21.447",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "76",
        "21.563",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "76",
        "21.673",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "76",
        "21.779",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "76",
        "21.881",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "76",
        "21.977",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "76",
        "22.068",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "76",
        "22.155",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "76",
        "22.236",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "76",
        "22.312",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "76",
        "22.384",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "76",
        "22.451",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "76",
        "22.512",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "76",
        "22.568",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "76",
        "22.620",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "76",
        "22.666",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "76",
        "22.707",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "76",
        "22.743",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "76",
        "22.774",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "76",
        "22.800",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "76",
        "22.821",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "76",
        "22.837",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "76",
        "22.847",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "76",
        "22.852",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "76",
        "22.852",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "76",
        "22.847",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "76",
        "22.837",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "76",
        "22.821",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "76",
        "22.801",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "76",
        "22.775",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "76",
        "22.745",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "76",
        "22.709",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "76",
        "22.667",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "76",
        "22.620",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "76",
        "22.569",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "76",
        "22.514",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "76",
        "22.452",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "76",
        "22.385",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "76",
        "22.314",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "76",
        "22.238",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "76",
        "22.156",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "76",
        "22.070",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "76",
        "21.978",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "76",
        "21.883",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "76",
        "21.781",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "76",
        "21.676",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "76",
        "21.564",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "76",
        "21.449",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "76",
        "21.329",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "76",
        "21.203",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "76",
        "21.074",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "76",
        "20.940",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "76",
        "20.802",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "76",
        "20.658",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "76",
        "20.510",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "76",
        "20.358",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "76",
        "20.201",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "76",
        "20.040",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "76",
        "19.875",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "76",
        "19.706",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "76",
        "19.532",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "76",
        "19.354",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "76",
        "19.172",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "76",
        "18.986",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "76",
        "18.796",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "76",
        "18.682",
        "-11.501",
        "0.547",
        "0.459",
        "0.216"
    ],
    [
        "122",
        "76",
        "18.737",
        "-11.448",
        "0.902",
        "0.822",
        "0.176"
    ],
    [
        "123",
        "76",
        "18.780",
        "-11.429",
        "1.063",
        "0.918",
        "0.160"
    ],
    [
        "124",
        "76",
        "18.808",
        "-11.450",
        "1.098",
        "0.985",
        "0.112"
    ],
    [
        "125",
        "76",
        "18.804",
        "-11.520",
        "1.158",
        "1.061",
        "0.077"
    ],
    [
        "126",
        "76",
        "18.761",
        "-11.633",
        "1.253",
        "1.137",
        "0.040"
    ],
    [
        "127",
        "76",
        "18.690",
        "-11.768",
        "1.245",
        "1.202",
        "0.010"
    ],
    [
        "128",
        "76",
        "18.603",
        "-11.918",
        "1.254",
        "1.234",
        "-0.011"
    ],
    [
        "129",
        "76",
        "18.504",
        "-12.075",
        "1.277",
        "1.207",
        "-0.039"
    ],
    [
        "130",
        "76",
        "18.398",
        "-12.261",
        "1.177",
        "1.123",
        "-0.059"
    ],
    [
        "131",
        "76",
        "18.281",
        "-12.487",
        "1.088",
        "1.003",
        "-0.064"
    ],
    [
        "132",
        "76",
        "18.134",
        "-12.758",
        "1.079",
        "0.903",
        "-0.022"
    ],
    [
        "133",
        "76",
        "17.994",
        "-13.042",
        "0.947",
        "0.807",
        "0.030"
    ],
    [
        "134",
        "76",
        "17.833",
        "-13.342",
        "0.897",
        "0.756",
        "0.077"
    ],
    [
        "135",
        "76",
        "17.660",
        "-13.633",
        "0.878",
        "0.686",
        "0.134"
    ],
    [
        "136",
        "76",
        "17.488",
        "-13.905",
        "0.190",
        "0.161",
        "0.125"
    ],
    [
        "137",
        "76",
        "17.256",
        "-14.216",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "76",
        "17.013",
        "-14.524",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "76",
        "16.765",
        "-14.829",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "76",
        "16.511",
        "-15.129",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "76",
        "16.252",
        "-15.425",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "76",
        "15.988",
        "-15.717",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "76",
        "15.720",
        "-16.002",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "76",
        "15.447",
        "-16.284",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "76",
        "15.168",
        "-16.561",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "76",
        "14.885",
        "-16.832",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "76",
        "14.598",
        "-17.098",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "76",
        "14.306",
        "-17.359",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "76",
        "14.010",
        "-17.614",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "76",
        "13.710",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "76",
        "13.405",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "76",
        "13.097",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "76",
        "12.784",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "76",
        "12.467",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "76",
        "12.146",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "76",
        "11.823",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "76",
        "11.495",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "76",
        "11.163",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "76",
        "10.829",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "76",
        "10.491",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "76",
        "10.149",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "76",
        "9.805",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "76",
        "9.458",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "76",
        "9.107",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "76",
        "8.754",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "76",
        "8.399",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "76",
        "8.041",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "76",
        "7.680",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "76",
        "7.316",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "76",
        "6.951",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "76",
        "6.583",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "76",
        "6.214",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "76",
        "5.842",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "76",
        "5.469",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "76",
        "5.094",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "76",
        "4.717",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "76",
        "4.340",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "76",
        "3.960",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "76",
        "3.580",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "77",
        "3.216",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "77",
        "2.832",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "77",
        "2.447",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "77",
        "2.061",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "77",
        "1.675",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "77",
        "1.288",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "77",
        "0.900",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "77",
        "0.512",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "77",
        "0.124",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "77",
        "-0.263",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "77",
        "-0.652",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "77",
        "-1.040",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "77",
        "-1.427",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "77",
        "-1.814",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "77",
        "-2.200",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "77",
        "-2.587",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "77",
        "-2.972",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "77",
        "-3.355",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "77",
        "-3.739",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "77",
        "-4.120",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "77",
        "-4.501",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "77",
        "-4.881",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "77",
        "-5.259",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "77",
        "-5.635",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "77",
        "-6.010",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "77",
        "-6.383",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "77",
        "-6.753",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "77",
        "-7.122",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "77",
        "-7.489",
        "-21.500",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "77",
        "-7.853",
        "-21.363",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "77",
        "-8.216",
        "-21.220",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "77",
        "-8.576",
        "-21.072",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "77",
        "-8.933",
        "-20.915",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "77",
        "-9.287",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "77",
        "-9.639",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "77",
        "-9.987",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "77",
        "-10.333",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "77",
        "-10.675",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "77",
        "-11.014",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "77",
        "-11.350",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "77",
        "-11.683",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "77",
        "-12.011",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "77",
        "-12.337",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "77",
        "-12.658",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "77",
        "-12.975",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "77",
        "-13.290",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "77",
        "-13.599",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "77",
        "-13.905",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "77",
        "-14.206",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "77",
        "-14.503",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "77",
        "-14.795",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "77",
        "-15.084",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "77",
        "-15.367",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "77",
        "-15.646",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "77",
        "-15.921",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "77",
        "-16.189",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "77",
        "-16.454",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "77",
        "-16.713",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "77",
        "-16.968",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "77",
        "-17.216",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "77",
        "-17.461",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "77",
        "-17.700",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "77",
        "-17.933",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "77",
        "-18.161",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "77",
        "-18.383",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "77",
        "-18.599",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "77",
        "-18.811",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "77",
        "-19.016",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "77",
        "-19.215",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "77",
        "-19.409",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "77",
        "-19.597",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "77",
        "-19.778",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "77",
        "-19.955",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "77",
        "-20.125",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "77",
        "-20.288",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "77",
        "-20.446",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "77",
        "-20.597",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "77",
        "-20.742",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "77",
        "-20.881",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "77",
        "-21.013",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "77",
        "-21.139",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "77",
        "-21.259",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "77",
        "-21.373",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "77",
        "-21.479",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "77",
        "-21.579",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "77",
        "-21.672",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "77",
        "-21.760",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "77",
        "-21.840",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "77",
        "-21.914",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "77",
        "-21.981",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "77",
        "-22.042",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "77",
        "-22.096",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "77",
        "-22.142",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "77",
        "-22.183",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "77",
        "-22.216",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "77",
        "-22.243",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "77",
        "-22.262",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "77",
        "-22.276",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "77",
        "-22.282",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "77",
        "-22.283",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "77",
        "-22.275",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "77",
        "-22.261",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "77",
        "-22.240",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "77",
        "-22.214",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "77",
        "-22.179",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "77",
        "-22.138",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "77",
        "-22.091",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "77",
        "-22.037",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "77",
        "-21.975",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "77",
        "-21.908",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "77",
        "-21.834",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "77",
        "-21.753",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "77",
        "-21.665",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "77",
        "-21.571",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "77",
        "-21.470",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "77",
        "-21.363",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "77",
        "-21.250",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "77",
        "-21.130",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "77",
        "-21.003",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "77",
        "-20.869",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "77",
        "-20.730",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "77",
        "-20.585",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "77",
        "-20.433",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "77",
        "-20.275",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "77",
        "-20.110",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "77",
        "-19.940",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "77",
        "-19.764",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "77",
        "-19.581",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "77",
        "-19.393",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "77",
        "-19.199",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "77",
        "-18.999",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "77",
        "-18.793",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "77",
        "-18.582",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "77",
        "-18.364",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "77",
        "-18.142",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "77",
        "-17.913",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "77",
        "-17.680",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "77",
        "-17.440",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "77",
        "-17.197",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "77",
        "-16.946",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "77",
        "-16.692",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "77",
        "-16.432",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "77",
        "-16.167",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "77",
        "-15.897",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "77",
        "-15.623",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "77",
        "-15.344",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "77",
        "-15.060",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "77",
        "-14.771",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "77",
        "-14.478",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "77",
        "-14.180",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "77",
        "-13.879",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "77",
        "-13.573",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "77",
        "-13.264",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "77",
        "-12.949",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "77",
        "-12.632",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "77",
        "-12.310",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "77",
        "-11.984",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "77",
        "-11.655",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "77",
        "-11.322",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "77",
        "-10.986",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "77",
        "-10.646",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "77",
        "-10.304",
        "20.245",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "77",
        "-9.958",
        "20.425",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "77",
        "-9.609",
        "20.599",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "77",
        "-9.257",
        "20.767",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "77",
        "-8.903",
        "20.928",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "77",
        "-8.546",
        "21.084",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "77",
        "-8.186",
        "21.232",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "77",
        "-7.823",
        "21.376",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "77",
        "-7.458",
        "21.511",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "77",
        "-7.092",
        "21.640",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "77",
        "-6.723",
        "21.763",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "77",
        "-6.352",
        "21.879",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "77",
        "-5.979",
        "21.989",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "77",
        "-5.604",
        "22.091",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "77",
        "-5.227",
        "22.187",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "77",
        "-4.849",
        "22.277",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "77",
        "-4.470",
        "22.359",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "77",
        "-4.088",
        "22.435",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "77",
        "-3.707",
        "22.504",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "77",
        "-3.324",
        "22.566",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "77",
        "-2.939",
        "22.621",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "77",
        "-2.554",
        "22.670",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "77",
        "-2.168",
        "22.710",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "77",
        "-1.781",
        "22.745",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "77",
        "-1.395",
        "22.772",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "77",
        "-1.007",
        "22.794",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "77",
        "-0.620",
        "22.807",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "77",
        "0.600",
        "22.593",
        "0.797",
        "0.964",
        "-0.371"
    ],
    [
        "9",
        "77",
        "7.995",
        "18.327",
        "3.229",
        "3.290",
        "-0.456"
    ],
    [
        "10",
        "77",
        "11.453",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "77",
        "11.730",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "77",
        "12.006",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "77",
        "12.279",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "77",
        "12.550",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "77",
        "12.819",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "77",
        "13.086",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "77",
        "13.351",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "77",
        "13.612",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "77",
        "13.873",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "77",
        "14.130",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "77",
        "14.384",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "77",
        "14.636",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "77",
        "14.885",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "77",
        "15.131",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "77",
        "15.374",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "77",
        "15.614",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "77",
        "15.852",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "77",
        "16.085",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "77",
        "16.316",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "77",
        "16.544",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "77",
        "16.768",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "77",
        "16.988",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "77",
        "17.206",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "77",
        "17.419",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "77",
        "17.629",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "77",
        "17.835",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "77",
        "18.038",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "77",
        "18.237",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "77",
        "18.432",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "77",
        "18.622",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "77",
        "18.809",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "77",
        "18.993",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "77",
        "19.171",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "77",
        "19.345",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "77",
        "19.515",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "77",
        "19.682",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "77",
        "19.844",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "77",
        "20.001",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "77",
        "20.154",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "77",
        "20.303",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "77",
        "20.446",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "77",
        "20.586",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "77",
        "20.721",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "77",
        "20.851",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "77",
        "20.976",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "77",
        "21.097",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "77",
        "21.213",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "77",
        "21.324",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "77",
        "21.431",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "77",
        "21.533",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "77",
        "21.630",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "77",
        "21.721",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "77",
        "21.808",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "77",
        "21.889",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "77",
        "21.967",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "77",
        "22.038",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "77",
        "22.106",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "77",
        "22.167",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "77",
        "22.224",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "77",
        "22.275",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "77",
        "22.322",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "77",
        "22.363",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "77",
        "22.400",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "77",
        "22.431",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "77",
        "22.456",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "77",
        "22.478",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "77",
        "22.493",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "77",
        "22.503",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "77",
        "22.509",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "77",
        "22.509",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "77",
        "22.503",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "77",
        "22.493",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "77",
        "22.478",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "77",
        "22.457",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "77",
        "22.431",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "77",
        "22.400",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "77",
        "22.364",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "77",
        "22.323",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "77",
        "22.276",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "77",
        "22.224",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "77",
        "22.168",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "77",
        "22.107",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "77",
        "22.039",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "77",
        "21.968",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "77",
        "21.891",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "77",
        "21.809",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "77",
        "21.723",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "77",
        "21.631",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "77",
        "21.534",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "77",
        "21.432",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "77",
        "21.327",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "77",
        "21.215",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "77",
        "21.100",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "77",
        "20.979",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "77",
        "20.853",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "77",
        "20.724",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "77",
        "20.589",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "77",
        "20.449",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "77",
        "20.305",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "77",
        "20.157",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "77",
        "20.004",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "77",
        "19.847",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "77",
        "19.685",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "77",
        "19.519",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "77",
        "19.349",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "77",
        "19.174",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "77",
        "18.995",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "77",
        "18.813",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "77",
        "18.626",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "77",
        "18.435",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "77",
        "18.344",
        "-11.480",
        "0.565",
        "0.488",
        "0.145"
    ],
    [
        "122",
        "77",
        "18.480",
        "-11.309",
        "0.927",
        "0.872",
        "0.118"
    ],
    [
        "123",
        "77",
        "18.621",
        "-11.196",
        "1.069",
        "0.920",
        "0.111"
    ],
    [
        "124",
        "77",
        "18.761",
        "-11.142",
        "1.071",
        "0.922",
        "0.066"
    ],
    [
        "125",
        "77",
        "18.883",
        "-11.155",
        "1.086",
        "0.929",
        "0.025"
    ],
    [
        "126",
        "77",
        "18.965",
        "-11.244",
        "1.124",
        "0.946",
        "-0.014"
    ],
    [
        "127",
        "77",
        "18.983",
        "-11.402",
        "1.052",
        "0.971",
        "-0.025"
    ],
    [
        "128",
        "77",
        "18.904",
        "-11.632",
        "1.083",
        "1.057",
        "-0.010"
    ],
    [
        "129",
        "77",
        "18.814",
        "-11.851",
        "1.114",
        "1.101",
        "-0.011"
    ],
    [
        "130",
        "77",
        "18.698",
        "-12.093",
        "1.029",
        "1.070",
        "-0.009"
    ],
    [
        "131",
        "77",
        "18.583",
        "-12.345",
        "0.899",
        "0.897",
        "-0.015"
    ],
    [
        "132",
        "77",
        "18.474",
        "-12.606",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "77",
        "18.257",
        "-12.936",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "77",
        "18.034",
        "-13.262",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "77",
        "17.806",
        "-13.584",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "77",
        "17.572",
        "-13.902",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "77",
        "17.333",
        "-14.216",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "77",
        "17.089",
        "-14.524",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "77",
        "16.839",
        "-14.829",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "77",
        "16.585",
        "-15.129",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "77",
        "16.325",
        "-15.425",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "77",
        "16.060",
        "-15.717",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "77",
        "15.790",
        "-16.002",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "77",
        "15.515",
        "-16.284",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "77",
        "15.236",
        "-16.561",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "77",
        "14.953",
        "-16.832",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "77",
        "14.664",
        "-17.098",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "77",
        "14.371",
        "-17.359",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "77",
        "14.073",
        "-17.614",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "77",
        "13.771",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "77",
        "13.466",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "77",
        "13.156",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "77",
        "12.842",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "77",
        "12.524",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "77",
        "12.202",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "77",
        "11.877",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "77",
        "11.548",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "77",
        "11.215",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "77",
        "10.878",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "77",
        "10.539",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "77",
        "10.196",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "77",
        "9.850",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "77",
        "9.501",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "77",
        "9.150",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "77",
        "8.795",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "77",
        "8.439",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "77",
        "8.079",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "77",
        "7.716",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "77",
        "7.351",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "77",
        "6.985",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "77",
        "6.616",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "77",
        "6.245",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "77",
        "5.872",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "77",
        "5.497",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "77",
        "5.120",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "77",
        "4.742",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "77",
        "4.362",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "77",
        "3.981",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "77",
        "3.600",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "78",
        "3.232",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "78",
        "2.847",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "78",
        "2.461",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "78",
        "2.073",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "78",
        "1.685",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "78",
        "1.297",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "78",
        "0.908",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "78",
        "0.518",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "78",
        "0.129",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "78",
        "-0.260",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "78",
        "-0.650",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "78",
        "-1.040",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "78",
        "-1.428",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "78",
        "-1.817",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "78",
        "-2.204",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "78",
        "-2.592",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "78",
        "-2.978",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "78",
        "-3.364",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "78",
        "-3.748",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "78",
        "-4.132",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "78",
        "-4.515",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "78",
        "-4.896",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "78",
        "-5.274",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "78",
        "-5.653",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "78",
        "-6.029",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "78",
        "-6.403",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "78",
        "-6.776",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "78",
        "-7.146",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "78",
        "-7.514",
        "-21.500",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "78",
        "-7.880",
        "-21.363",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "78",
        "-8.244",
        "-21.220",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "78",
        "-8.604",
        "-21.072",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "78",
        "-8.963",
        "-20.915",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "78",
        "-9.319",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "78",
        "-9.671",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "78",
        "-10.021",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "78",
        "-10.368",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "78",
        "-10.712",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "78",
        "-11.052",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "78",
        "-11.389",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "78",
        "-11.724",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "78",
        "-12.054",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "78",
        "-12.380",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "78",
        "-12.703",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "78",
        "-13.021",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "78",
        "-13.337",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "78",
        "-13.647",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "78",
        "-13.954",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "78",
        "-14.257",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "78",
        "-14.555",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "78",
        "-14.848",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "78",
        "-15.138",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "78",
        "-15.422",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "78",
        "-15.702",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "78",
        "-15.978",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "78",
        "-16.248",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "78",
        "-16.513",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "78",
        "-16.774",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "78",
        "-17.029",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "78",
        "-17.279",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "78",
        "-17.524",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "78",
        "-17.763",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "78",
        "-17.998",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "78",
        "-18.226",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "78",
        "-18.450",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "78",
        "-18.667",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "78",
        "-18.879",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "78",
        "-19.085",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "78",
        "-19.286",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "78",
        "-19.480",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "78",
        "-19.669",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "78",
        "-19.852",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "78",
        "-20.028",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "78",
        "-20.199",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "78",
        "-20.363",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "78",
        "-20.521",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "78",
        "-20.673",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "78",
        "-20.818",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "78",
        "-20.957",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "78",
        "-21.090",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "78",
        "-21.217",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "78",
        "-21.338",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "78",
        "-21.451",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "78",
        "-21.558",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "78",
        "-21.659",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "78",
        "-21.752",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "78",
        "-21.840",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "78",
        "-21.921",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "78",
        "-21.995",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "78",
        "-22.062",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "78",
        "-22.122",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "78",
        "-22.177",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "78",
        "-22.224",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "78",
        "-22.264",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "78",
        "-22.297",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "78",
        "-22.325",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "78",
        "-22.345",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "78",
        "-22.358",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "78",
        "-22.364",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "78",
        "-22.364",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "78",
        "-22.357",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "78",
        "-22.343",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "78",
        "-22.323",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "78",
        "-22.295",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "78",
        "-22.261",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "78",
        "-22.220",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "78",
        "-22.172",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "78",
        "-22.118",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "78",
        "-22.056",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "78",
        "-21.989",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "78",
        "-21.914",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "78",
        "-21.833",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "78",
        "-21.745",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "78",
        "-21.651",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "78",
        "-21.549",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "78",
        "-21.441",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "78",
        "-21.328",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "78",
        "-21.207",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "78",
        "-21.080",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "78",
        "-20.947",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "78",
        "-20.806",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "78",
        "-20.660",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "78",
        "-20.507",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "78",
        "-20.349",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "78",
        "-20.184",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "78",
        "-20.013",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "78",
        "-19.836",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "78",
        "-19.653",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "78",
        "-19.464",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "78",
        "-19.269",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "78",
        "-19.069",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "78",
        "-18.862",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "78",
        "-18.649",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "78",
        "-18.431",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "78",
        "-18.208",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "78",
        "-17.978",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "78",
        "-17.744",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "78",
        "-17.504",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "78",
        "-17.259",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "78",
        "-17.008",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "78",
        "-16.753",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "78",
        "-16.492",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "78",
        "-16.225",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "78",
        "-15.955",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "78",
        "-15.680",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "78",
        "-15.399",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "78",
        "-15.114",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "78",
        "-14.824",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "78",
        "-14.530",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "78",
        "-14.232",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "78",
        "-13.928",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "78",
        "-13.622",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "78",
        "-13.310",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "78",
        "-12.995",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "78",
        "-12.676",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "78",
        "-12.352",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "78",
        "-12.026",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "78",
        "-11.696",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "78",
        "-11.362",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "78",
        "-11.024",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "78",
        "-10.684",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "78",
        "-10.339",
        "20.245",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "78",
        "-9.992",
        "20.425",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "78",
        "-9.642",
        "20.599",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "78",
        "-9.289",
        "20.767",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "78",
        "-8.933",
        "20.928",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "78",
        "-8.575",
        "21.084",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "78",
        "-8.213",
        "21.232",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "78",
        "-7.849",
        "21.376",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "78",
        "-7.483",
        "21.511",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "78",
        "-7.115",
        "21.640",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "78",
        "-6.744",
        "21.763",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "78",
        "-6.372",
        "21.879",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "78",
        "-5.997",
        "21.989",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "78",
        "-5.621",
        "22.091",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "78",
        "-5.243",
        "22.187",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "78",
        "-4.864",
        "22.277",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "78",
        "-4.482",
        "22.359",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "78",
        "-4.100",
        "22.435",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "78",
        "-3.716",
        "22.504",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "78",
        "-3.332",
        "22.566",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "78",
        "-2.946",
        "22.621",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "78",
        "-2.559",
        "22.670",
        "0",
        "0",
        "0"
    ],
    [
        "3",
        "78",
        "-2.173",
        "22.710",
        "0",
        "0",
        "0"
    ],
    [
        "4",
        "78",
        "-1.784",
        "22.745",
        "0",
        "0",
        "0"
    ],
    [
        "5",
        "78",
        "-1.396",
        "22.772",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "78",
        "-1.007",
        "22.794",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "78",
        "-0.618",
        "22.807",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "78",
        "2.553",
        "21.192",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "78",
        "10.780",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "78",
        "11.060",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "78",
        "11.338",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "78",
        "11.615",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "78",
        "11.889",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "78",
        "12.161",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "78",
        "12.431",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "78",
        "12.699",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "78",
        "12.964",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "78",
        "13.228",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "78",
        "13.488",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "78",
        "13.746",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "78",
        "14.002",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "78",
        "14.255",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "78",
        "14.504",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "78",
        "14.752",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "78",
        "14.996",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "78",
        "15.237",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "78",
        "15.475",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "78",
        "15.710",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "78",
        "15.941",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "78",
        "16.170",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "78",
        "16.395",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "78",
        "16.617",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "78",
        "16.835",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "78",
        "17.049",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "78",
        "17.260",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "78",
        "17.467",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "78",
        "17.670",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "78",
        "17.870",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "78",
        "18.065",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "78",
        "18.257",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "78",
        "18.444",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "78",
        "18.628",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "78",
        "18.807",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "78",
        "18.983",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "78",
        "19.153",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "78",
        "19.320",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "78",
        "19.483",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "78",
        "19.641",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "78",
        "19.794",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "78",
        "19.943",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "78",
        "20.088",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "78",
        "20.228",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "78",
        "20.363",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "78",
        "20.493",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "78",
        "20.619",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "78",
        "20.741",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "78",
        "20.857",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "78",
        "20.969",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "78",
        "21.076",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "78",
        "21.178",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "78",
        "21.275",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "78",
        "21.368",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "78",
        "21.455",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "78",
        "21.536",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "78",
        "21.614",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "78",
        "21.686",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "78",
        "21.754",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "78",
        "21.815",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "78",
        "21.872",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "78",
        "21.924",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "78",
        "21.970",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "78",
        "22.012",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "78",
        "22.048",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "78",
        "22.080",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "78",
        "22.106",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "78",
        "22.127",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "78",
        "22.142",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "78",
        "22.153",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "78",
        "22.158",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "78",
        "22.158",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "78",
        "22.153",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "78",
        "22.143",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "78",
        "22.127",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "78",
        "22.106",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "78",
        "22.080",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "78",
        "22.049",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "78",
        "22.012",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "78",
        "21.971",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "78",
        "21.925",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "78",
        "21.873",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "78",
        "21.816",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "78",
        "21.754",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "78",
        "21.687",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "78",
        "21.616",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "78",
        "21.539",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "78",
        "21.456",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "78",
        "21.369",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "78",
        "21.277",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "78",
        "21.181",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "78",
        "21.078",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "78",
        "20.971",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "78",
        "20.860",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "78",
        "20.744",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "78",
        "20.622",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "78",
        "20.496",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "78",
        "20.366",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "78",
        "20.231",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "78",
        "20.091",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "78",
        "19.946",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "78",
        "19.797",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "78",
        "19.643",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "78",
        "19.486",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "78",
        "19.323",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "78",
        "19.157",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "78",
        "18.986",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "78",
        "18.811",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "78",
        "18.632",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "78",
        "18.448",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "78",
        "18.260",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "78",
        "18.069",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "78",
        "18.001",
        "-11.466",
        "0.574",
        "0.518",
        "0.089"
    ],
    [
        "122",
        "78",
        "18.215",
        "-11.214",
        "0.933",
        "0.931",
        "0.092"
    ],
    [
        "123",
        "78",
        "18.448",
        "-11.035",
        "1.049",
        "0.939",
        "0.113"
    ],
    [
        "124",
        "78",
        "18.703",
        "-10.915",
        "1.016",
        "0.883",
        "0.097"
    ],
    [
        "125",
        "78",
        "18.967",
        "-10.854",
        "0.978",
        "0.819",
        "0.077"
    ],
    [
        "126",
        "78",
        "19.218",
        "-10.864",
        "0.921",
        "0.712",
        "0.066"
    ],
    [
        "127",
        "78",
        "19.494",
        "-10.921",
        "0.196",
        "0.161",
        "0.041"
    ],
    [
        "128",
        "78",
        "19.365",
        "-11.248",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "78",
        "19.170",
        "-11.594",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "78",
        "18.969",
        "-11.935",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "78",
        "18.762",
        "-12.272",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "78",
        "18.551",
        "-12.606",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "78",
        "18.332",
        "-12.936",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "78",
        "18.108",
        "-13.262",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "78",
        "17.880",
        "-13.584",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "78",
        "17.645",
        "-13.902",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "78",
        "17.405",
        "-14.216",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "78",
        "17.159",
        "-14.524",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "78",
        "16.909",
        "-14.829",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "78",
        "16.653",
        "-15.129",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "78",
        "16.392",
        "-15.425",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "78",
        "16.127",
        "-15.717",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "78",
        "15.855",
        "-16.002",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "78",
        "15.580",
        "-16.284",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "78",
        "15.299",
        "-16.561",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "78",
        "15.015",
        "-16.832",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "78",
        "14.725",
        "-17.098",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "78",
        "14.431",
        "-17.359",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "78",
        "14.132",
        "-17.614",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "78",
        "13.830",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "78",
        "13.522",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "78",
        "13.211",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "78",
        "12.896",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "78",
        "12.576",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "78",
        "12.254",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "78",
        "11.927",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "78",
        "11.596",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "78",
        "11.262",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "78",
        "10.925",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "78",
        "10.584",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "78",
        "10.240",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "78",
        "9.893",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "78",
        "9.543",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "78",
        "9.190",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "78",
        "8.834",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "78",
        "8.475",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "78",
        "8.114",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "78",
        "7.751",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "78",
        "7.385",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "78",
        "7.016",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "78",
        "6.645",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "78",
        "6.273",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "78",
        "5.899",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "78",
        "5.522",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "78",
        "5.144",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "78",
        "4.765",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "78",
        "4.384",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "78",
        "4.001",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "78",
        "3.618",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "79",
        "3.249",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "79",
        "2.862",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "79",
        "2.473",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "79",
        "2.085",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "79",
        "1.695",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "79",
        "1.306",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "79",
        "0.915",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "79",
        "0.524",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "79",
        "0.133",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "79",
        "-0.257",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "79",
        "-0.648",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "79",
        "-1.038",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "79",
        "-1.429",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "79",
        "-1.819",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "79",
        "-2.209",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "79",
        "-2.597",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "79",
        "-2.985",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "79",
        "-3.371",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "79",
        "-3.758",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "79",
        "-4.143",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "79",
        "-4.527",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "79",
        "-4.909",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "79",
        "-5.290",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "79",
        "-5.668",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "79",
        "-6.047",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "79",
        "-6.422",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "79",
        "-6.796",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "79",
        "-7.168",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "79",
        "-7.537",
        "-21.500",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "79",
        "-7.904",
        "-21.363",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "79",
        "-8.268",
        "-21.220",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "79",
        "-8.631",
        "-21.072",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "79",
        "-8.990",
        "-20.915",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "79",
        "-9.347",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "79",
        "-9.701",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "79",
        "-10.053",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "79",
        "-10.401",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "79",
        "-10.746",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "79",
        "-11.087",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "79",
        "-11.426",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "79",
        "-11.761",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "79",
        "-12.093",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "79",
        "-12.419",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "79",
        "-12.743",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "79",
        "-13.064",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "79",
        "-13.380",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "79",
        "-13.692",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "79",
        "-13.999",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "79",
        "-14.303",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "79",
        "-14.603",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "79",
        "-14.897",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "79",
        "-15.188",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "79",
        "-15.473",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "79",
        "-15.754",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "79",
        "-16.031",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "79",
        "-16.301",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "79",
        "-16.568",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "79",
        "-16.829",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "79",
        "-17.086",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "79",
        "-17.336",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "79",
        "-17.582",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "79",
        "-17.823",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "79",
        "-18.057",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "79",
        "-18.287",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "79",
        "-18.511",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "79",
        "-18.729",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "79",
        "-18.942",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "79",
        "-19.149",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "79",
        "-19.350",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "79",
        "-19.545",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "79",
        "-19.734",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "79",
        "-19.918",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "79",
        "-20.095",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "79",
        "-20.266",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "79",
        "-20.431",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "79",
        "-20.590",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "79",
        "-20.742",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "79",
        "-20.888",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "79",
        "-21.029",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "79",
        "-21.162",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "79",
        "-21.289",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "79",
        "-21.409",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "79",
        "-21.523",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "79",
        "-21.631",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "79",
        "-21.731",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "79",
        "-21.826",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "79",
        "-21.913",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "79",
        "-21.994",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "79",
        "-22.068",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "79",
        "-22.136",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "79",
        "-22.197",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "79",
        "-22.251",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "79",
        "-22.299",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "79",
        "-22.339",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "79",
        "-22.372",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "79",
        "-22.400",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "79",
        "-22.420",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "79",
        "-22.433",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "79",
        "-22.440",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "79",
        "-22.439",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "79",
        "-22.433",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "79",
        "-22.419",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "79",
        "-22.398",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "79",
        "-22.371",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "79",
        "-22.336",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "79",
        "-22.295",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "79",
        "-22.247",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "79",
        "-22.192",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "79",
        "-22.130",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "79",
        "-22.063",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "79",
        "-21.987",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "79",
        "-21.906",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "79",
        "-21.818",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "79",
        "-21.723",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "79",
        "-21.622",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "79",
        "-21.513",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "79",
        "-21.399",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "79",
        "-21.278",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "79",
        "-21.151",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "79",
        "-21.017",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "79",
        "-20.876",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "79",
        "-20.730",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "79",
        "-20.577",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "79",
        "-20.417",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "79",
        "-20.252",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "79",
        "-20.080",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "79",
        "-19.903",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "79",
        "-19.719",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "79",
        "-19.529",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "79",
        "-19.334",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "79",
        "-19.132",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "79",
        "-18.925",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "79",
        "-18.712",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "79",
        "-18.492",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "79",
        "-18.268",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "79",
        "-18.038",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "79",
        "-17.803",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "79",
        "-17.562",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "79",
        "-17.316",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "79",
        "-17.064",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "79",
        "-16.807",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "79",
        "-16.545",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "79",
        "-16.279",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "79",
        "-16.008",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "79",
        "-15.731",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "79",
        "-15.449",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "79",
        "-15.164",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "79",
        "-14.872",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "79",
        "-14.578",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "79",
        "-14.278",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "79",
        "-13.974",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "79",
        "-13.666",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "79",
        "-13.354",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "79",
        "-13.037",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "79",
        "-12.716",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "79",
        "-12.392",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "79",
        "-12.064",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "79",
        "-11.733",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "79",
        "-11.398",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "79",
        "-11.059",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "79",
        "-10.717",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "79",
        "-10.372",
        "20.245",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "79",
        "-10.023",
        "20.425",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "79",
        "-9.672",
        "20.599",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "79",
        "-9.317",
        "20.767",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "79",
        "-8.960",
        "20.928",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "79",
        "-8.601",
        "21.084",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "79",
        "-8.238",
        "21.232",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "79",
        "-7.873",
        "21.376",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "79",
        "-7.506",
        "21.511",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "79",
        "-7.136",
        "21.640",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "79",
        "-6.764",
        "21.763",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "79",
        "-6.390",
        "21.879",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "79",
        "-6.015",
        "21.989",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "79",
        "-5.637",
        "22.091",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "79",
        "-5.257",
        "22.187",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "79",
        "-4.877",
        "22.277",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "79",
        "-4.494",
        "22.359",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "79",
        "-4.110",
        "22.435",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "79",
        "-3.726",
        "22.504",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "79",
        "-3.340",
        "22.566",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "79",
        "-2.952",
        "22.621",
        "0",
        "0",
        "0"
    ],
    [
        "2",
        "79",
        "-2.167",
        "23.029",
        "2.507",
        "2.229",
        "-0.113"
    ],
    [
        "3",
        "79",
        "-0.943",
        "23.291",
        "3.223",
        "2.758",
        "-0.133"
    ],
    [
        "4",
        "79",
        "1.247",
        "22.356",
        "3.227",
        "2.857",
        "-0.203"
    ],
    [
        "5",
        "79",
        "4.824",
        "19.431",
        "3.000",
        "2.579",
        "-0.271"
    ],
    [
        "6",
        "79",
        "9.528",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "79",
        "9.815",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "79",
        "10.099",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "79",
        "10.382",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "79",
        "10.662",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "79",
        "10.942",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "79",
        "11.220",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "79",
        "11.495",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "79",
        "11.768",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "79",
        "12.039",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "79",
        "12.308",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "79",
        "12.574",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "79",
        "12.839",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "79",
        "13.100",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "79",
        "13.359",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "79",
        "13.615",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "79",
        "13.869",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "79",
        "14.120",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "79",
        "14.368",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "79",
        "14.614",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "79",
        "14.855",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "79",
        "15.094",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "79",
        "15.330",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "79",
        "15.563",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "79",
        "15.792",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "79",
        "16.018",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "79",
        "16.240",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "79",
        "16.458",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "79",
        "16.674",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "79",
        "16.885",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "79",
        "17.093",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "79",
        "17.297",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "79",
        "17.497",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "79",
        "17.693",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "79",
        "17.886",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "79",
        "18.074",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "79",
        "18.258",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "79",
        "18.438",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "79",
        "18.614",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "79",
        "18.785",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "79",
        "18.952",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "79",
        "19.116",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "79",
        "19.275",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "79",
        "19.428",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "79",
        "19.578",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "79",
        "19.723",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "79",
        "19.864",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "79",
        "19.999",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "79",
        "20.130",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "79",
        "20.257",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "79",
        "20.379",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "79",
        "20.496",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "79",
        "20.607",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "79",
        "20.715",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "79",
        "20.818",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "79",
        "20.915",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "79",
        "21.007",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "79",
        "21.095",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "79",
        "21.177",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "79",
        "21.255",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "79",
        "21.327",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "79",
        "21.395",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "79",
        "21.456",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "79",
        "21.514",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "79",
        "21.566",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "79",
        "21.612",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "79",
        "21.654",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "79",
        "21.690",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "79",
        "21.722",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "79",
        "21.748",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "79",
        "21.769",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "79",
        "21.785",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "79",
        "21.795",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "79",
        "21.800",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "79",
        "21.800",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "79",
        "21.796",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "79",
        "21.785",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "79",
        "21.769",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "79",
        "21.749",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "79",
        "21.722",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "79",
        "21.692",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "79",
        "21.655",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "79",
        "21.613",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "79",
        "21.567",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "79",
        "21.515",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "79",
        "21.458",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "79",
        "21.395",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "79",
        "21.329",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "79",
        "21.256",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "79",
        "21.178",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "79",
        "21.097",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "79",
        "21.009",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "79",
        "20.916",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "79",
        "20.819",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "79",
        "20.717",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "79",
        "20.610",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "79",
        "20.498",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "79",
        "20.381",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "79",
        "20.260",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "79",
        "20.133",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "79",
        "20.002",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "79",
        "19.866",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "79",
        "19.726",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "79",
        "19.581",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "79",
        "19.431",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "79",
        "19.278",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "79",
        "19.119",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "79",
        "18.956",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "79",
        "18.788",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "79",
        "18.617",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "79",
        "18.442",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "79",
        "18.262",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "79",
        "18.077",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "79",
        "17.889",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "79",
        "17.698",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "79",
        "17.668",
        "-11.469",
        "0.575",
        "0.524",
        "0.075"
    ],
    [
        "122",
        "79",
        "17.981",
        "-11.229",
        "0.923",
        "0.957",
        "0.105"
    ],
    [
        "123",
        "79",
        "18.311",
        "-11.044",
        "1.022",
        "0.957",
        "0.161"
    ],
    [
        "124",
        "79",
        "18.673",
        "-10.897",
        "0.963",
        "0.879",
        "0.182"
    ],
    [
        "125",
        "79",
        "19.065",
        "-10.789",
        "0.885",
        "0.776",
        "0.185"
    ],
    [
        "126",
        "79",
        "19.470",
        "-10.726",
        "0.747",
        "0.564",
        "0.206"
    ],
    [
        "127",
        "79",
        "19.628",
        "-10.901",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "79",
        "19.438",
        "-11.248",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "79",
        "19.242",
        "-11.594",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "79",
        "19.041",
        "-11.935",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "79",
        "18.833",
        "-12.272",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "79",
        "18.620",
        "-12.606",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "79",
        "18.402",
        "-12.936",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "79",
        "18.177",
        "-13.262",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "79",
        "17.947",
        "-13.584",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "79",
        "17.711",
        "-13.902",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "79",
        "17.471",
        "-14.216",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "79",
        "17.224",
        "-14.524",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "79",
        "16.974",
        "-14.829",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "79",
        "16.717",
        "-15.129",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "79",
        "16.455",
        "-15.425",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "79",
        "16.188",
        "-15.717",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "79",
        "15.916",
        "-16.002",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "79",
        "15.640",
        "-16.284",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "79",
        "15.359",
        "-16.561",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "79",
        "15.072",
        "-16.832",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "79",
        "14.782",
        "-17.098",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "79",
        "14.486",
        "-17.359",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "79",
        "14.187",
        "-17.614",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "79",
        "13.883",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "79",
        "13.575",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "79",
        "13.262",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "79",
        "12.946",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "79",
        "12.626",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "79",
        "12.302",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "79",
        "11.974",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "79",
        "11.642",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "79",
        "11.306",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "79",
        "10.969",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "79",
        "10.626",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "79",
        "10.281",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "79",
        "9.932",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "79",
        "9.581",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "79",
        "9.227",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "79",
        "8.870",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "79",
        "8.509",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "79",
        "8.147",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "79",
        "7.782",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "79",
        "7.415",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "79",
        "7.045",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "79",
        "6.673",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "79",
        "6.299",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "79",
        "5.924",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "79",
        "5.546",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "79",
        "5.167",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "79",
        "4.786",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "79",
        "4.404",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "79",
        "4.020",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "79",
        "3.634",
        "-22.504",
        "0",
        "0",
        "0"
    ],
    [
        "-180",
        "80",
        "3.263",
        "-22.566",
        "0",
        "0",
        "0"
    ],
    [
        "-179",
        "80",
        "2.875",
        "-22.621",
        "0",
        "0",
        "0"
    ],
    [
        "-178",
        "80",
        "2.485",
        "-22.670",
        "0",
        "0",
        "0"
    ],
    [
        "-177",
        "80",
        "2.095",
        "-22.710",
        "0",
        "0",
        "0"
    ],
    [
        "-176",
        "80",
        "1.705",
        "-22.745",
        "0",
        "0",
        "0"
    ],
    [
        "-175",
        "80",
        "1.314",
        "-22.772",
        "0",
        "0",
        "0"
    ],
    [
        "-174",
        "80",
        "0.922",
        "-22.794",
        "0",
        "0",
        "0"
    ],
    [
        "-173",
        "80",
        "0.530",
        "-22.807",
        "0",
        "0",
        "0"
    ],
    [
        "-172",
        "80",
        "0.138",
        "-22.814",
        "0",
        "0",
        "0"
    ],
    [
        "-171",
        "80",
        "-0.254",
        "-22.813",
        "0",
        "0",
        "0"
    ],
    [
        "-170",
        "80",
        "-0.646",
        "-22.806",
        "0",
        "0",
        "0"
    ],
    [
        "-169",
        "80",
        "-1.038",
        "-22.792",
        "0",
        "0",
        "0"
    ],
    [
        "-168",
        "80",
        "-1.429",
        "-22.770",
        "0",
        "0",
        "0"
    ],
    [
        "-167",
        "80",
        "-1.820",
        "-22.743",
        "0",
        "0",
        "0"
    ],
    [
        "-166",
        "80",
        "-2.211",
        "-22.707",
        "0",
        "0",
        "0"
    ],
    [
        "-165",
        "80",
        "-2.601",
        "-22.665",
        "0",
        "0",
        "0"
    ],
    [
        "-164",
        "80",
        "-2.990",
        "-22.617",
        "0",
        "0",
        "0"
    ],
    [
        "-163",
        "80",
        "-3.378",
        "-22.561",
        "0",
        "0",
        "0"
    ],
    [
        "-162",
        "80",
        "-3.766",
        "-22.499",
        "0",
        "0",
        "0"
    ],
    [
        "-161",
        "80",
        "-4.152",
        "-22.429",
        "0",
        "0",
        "0"
    ],
    [
        "-160",
        "80",
        "-4.537",
        "-22.353",
        "0",
        "0",
        "0"
    ],
    [
        "-159",
        "80",
        "-4.921",
        "-22.270",
        "0",
        "0",
        "0"
    ],
    [
        "-158",
        "80",
        "-5.303",
        "-22.180",
        "0",
        "0",
        "0"
    ],
    [
        "-157",
        "80",
        "-5.683",
        "-22.083",
        "0",
        "0",
        "0"
    ],
    [
        "-156",
        "80",
        "-6.061",
        "-21.981",
        "0",
        "0",
        "0"
    ],
    [
        "-155",
        "80",
        "-6.438",
        "-21.870",
        "0",
        "0",
        "0"
    ],
    [
        "-154",
        "80",
        "-6.813",
        "-21.753",
        "0",
        "0",
        "0"
    ],
    [
        "-153",
        "80",
        "-7.186",
        "-21.630",
        "0",
        "0",
        "0"
    ],
    [
        "-152",
        "80",
        "-7.557",
        "-21.500",
        "0",
        "0",
        "0"
    ],
    [
        "-151",
        "80",
        "-7.925",
        "-21.363",
        "0",
        "0",
        "0"
    ],
    [
        "-150",
        "80",
        "-8.291",
        "-21.220",
        "0",
        "0",
        "0"
    ],
    [
        "-149",
        "80",
        "-8.655",
        "-21.072",
        "0",
        "0",
        "0"
    ],
    [
        "-148",
        "80",
        "-9.015",
        "-20.915",
        "0",
        "0",
        "0"
    ],
    [
        "-147",
        "80",
        "-9.373",
        "-20.753",
        "0",
        "0",
        "0"
    ],
    [
        "-146",
        "80",
        "-9.729",
        "-20.585",
        "0",
        "0",
        "0"
    ],
    [
        "-145",
        "80",
        "-10.081",
        "-20.410",
        "0",
        "0",
        "0"
    ],
    [
        "-144",
        "80",
        "-10.431",
        "-20.229",
        "0",
        "0",
        "0"
    ],
    [
        "-143",
        "80",
        "-10.776",
        "-20.042",
        "0",
        "0",
        "0"
    ],
    [
        "-142",
        "80",
        "-11.120",
        "-19.848",
        "0",
        "0",
        "0"
    ],
    [
        "-141",
        "80",
        "-11.458",
        "-19.649",
        "0",
        "0",
        "0"
    ],
    [
        "-140",
        "80",
        "-11.795",
        "-19.444",
        "0",
        "0",
        "0"
    ],
    [
        "-139",
        "80",
        "-12.127",
        "-19.232",
        "0",
        "0",
        "0"
    ],
    [
        "-138",
        "80",
        "-12.456",
        "-19.016",
        "0",
        "0",
        "0"
    ],
    [
        "-137",
        "80",
        "-12.781",
        "-18.792",
        "0",
        "0",
        "0"
    ],
    [
        "-136",
        "80",
        "-13.102",
        "-18.563",
        "0",
        "0",
        "0"
    ],
    [
        "-135",
        "80",
        "-13.419",
        "-18.329",
        "0",
        "0",
        "0"
    ],
    [
        "-134",
        "80",
        "-13.732",
        "-18.090",
        "0",
        "0",
        "0"
    ],
    [
        "-133",
        "80",
        "-14.041",
        "-17.844",
        "0",
        "0",
        "0"
    ],
    [
        "-132",
        "80",
        "-14.345",
        "-17.594",
        "0",
        "0",
        "0"
    ],
    [
        "-131",
        "80",
        "-14.645",
        "-17.337",
        "0",
        "0",
        "0"
    ],
    [
        "-130",
        "80",
        "-14.941",
        "-17.076",
        "0",
        "0",
        "0"
    ],
    [
        "-129",
        "80",
        "-15.232",
        "-16.809",
        "0",
        "0",
        "0"
    ],
    [
        "-128",
        "80",
        "-15.519",
        "-16.538",
        "0",
        "0",
        "0"
    ],
    [
        "-127",
        "80",
        "-15.800",
        "-16.261",
        "0",
        "0",
        "0"
    ],
    [
        "-126",
        "80",
        "-16.078",
        "-15.978",
        "0",
        "0",
        "0"
    ],
    [
        "-125",
        "80",
        "-16.350",
        "-15.693",
        "0",
        "0",
        "0"
    ],
    [
        "-124",
        "80",
        "-16.617",
        "-15.400",
        "0",
        "0",
        "0"
    ],
    [
        "-123",
        "80",
        "-16.879",
        "-15.104",
        "0",
        "0",
        "0"
    ],
    [
        "-122",
        "80",
        "-17.137",
        "-14.804",
        "0",
        "0",
        "0"
    ],
    [
        "-121",
        "80",
        "-17.388",
        "-14.498",
        "0",
        "0",
        "0"
    ],
    [
        "-120",
        "80",
        "-17.635",
        "-14.189",
        "0",
        "0",
        "0"
    ],
    [
        "-119",
        "80",
        "-17.876",
        "-13.875",
        "0",
        "0",
        "0"
    ],
    [
        "-118",
        "80",
        "-18.112",
        "-13.557",
        "0",
        "0",
        "0"
    ],
    [
        "-117",
        "80",
        "-18.342",
        "-13.235",
        "0",
        "0",
        "0"
    ],
    [
        "-116",
        "80",
        "-18.567",
        "-12.908",
        "0",
        "0",
        "0"
    ],
    [
        "-115",
        "80",
        "-18.786",
        "-12.579",
        "0",
        "0",
        "0"
    ],
    [
        "-114",
        "80",
        "-19.000",
        "-12.244",
        "0",
        "0",
        "0"
    ],
    [
        "-113",
        "80",
        "-19.207",
        "-11.907",
        "0",
        "0",
        "0"
    ],
    [
        "-112",
        "80",
        "-19.409",
        "-11.565",
        "0",
        "0",
        "0"
    ],
    [
        "-111",
        "80",
        "-19.604",
        "-11.219",
        "0",
        "0",
        "0"
    ],
    [
        "-110",
        "80",
        "-19.794",
        "-10.871",
        "0",
        "0",
        "0"
    ],
    [
        "-109",
        "80",
        "-19.978",
        "-10.519",
        "0",
        "0",
        "0"
    ],
    [
        "-108",
        "80",
        "-20.155",
        "-10.164",
        "0",
        "0",
        "0"
    ],
    [
        "-107",
        "80",
        "-20.328",
        "-9.807",
        "0",
        "0",
        "0"
    ],
    [
        "-106",
        "80",
        "-20.492",
        "-9.446",
        "0",
        "0",
        "0"
    ],
    [
        "-105",
        "80",
        "-20.652",
        "-9.082",
        "0",
        "0",
        "0"
    ],
    [
        "-104",
        "80",
        "-20.805",
        "-8.716",
        "0",
        "0",
        "0"
    ],
    [
        "-103",
        "80",
        "-20.952",
        "-8.346",
        "0",
        "0",
        "0"
    ],
    [
        "-102",
        "80",
        "-21.092",
        "-7.974",
        "0",
        "0",
        "0"
    ],
    [
        "-101",
        "80",
        "-21.226",
        "-7.600",
        "0",
        "0",
        "0"
    ],
    [
        "-100",
        "80",
        "-21.353",
        "-7.224",
        "0",
        "0",
        "0"
    ],
    [
        "-99",
        "80",
        "-21.474",
        "-6.845",
        "0",
        "0",
        "0"
    ],
    [
        "-98",
        "80",
        "-21.589",
        "-6.463",
        "0",
        "0",
        "0"
    ],
    [
        "-97",
        "80",
        "-21.696",
        "-6.081",
        "0",
        "0",
        "0"
    ],
    [
        "-96",
        "80",
        "-21.798",
        "-5.696",
        "0",
        "0",
        "0"
    ],
    [
        "-95",
        "80",
        "-21.892",
        "-5.310",
        "0",
        "0",
        "0"
    ],
    [
        "-94",
        "80",
        "-21.980",
        "-4.921",
        "0",
        "0",
        "0"
    ],
    [
        "-93",
        "80",
        "-22.062",
        "-4.533",
        "0",
        "0",
        "0"
    ],
    [
        "-92",
        "80",
        "-22.136",
        "-4.141",
        "0",
        "0",
        "0"
    ],
    [
        "-91",
        "80",
        "-22.203",
        "-3.749",
        "0",
        "0",
        "0"
    ],
    [
        "-90",
        "80",
        "-22.265",
        "-3.356",
        "0",
        "0",
        "0"
    ],
    [
        "-89",
        "80",
        "-22.319",
        "-2.961",
        "0",
        "0",
        "0"
    ],
    [
        "-88",
        "80",
        "-22.367",
        "-2.566",
        "0",
        "0",
        "0"
    ],
    [
        "-87",
        "80",
        "-22.407",
        "-2.170",
        "0",
        "0",
        "0"
    ],
    [
        "-86",
        "80",
        "-22.441",
        "-1.773",
        "0",
        "0",
        "0"
    ],
    [
        "-85",
        "80",
        "-22.469",
        "-1.376",
        "0",
        "0",
        "0"
    ],
    [
        "-84",
        "80",
        "-22.489",
        "-0.979",
        "0",
        "0",
        "0"
    ],
    [
        "-83",
        "80",
        "-22.502",
        "-0.580",
        "0",
        "0",
        "0"
    ],
    [
        "-82",
        "80",
        "-22.509",
        "-0.182",
        "0",
        "0",
        "0"
    ],
    [
        "-81",
        "80",
        "-22.508",
        "0.215",
        "0",
        "0",
        "0"
    ],
    [
        "-80",
        "80",
        "-22.501",
        "0.613",
        "0",
        "0",
        "0"
    ],
    [
        "-79",
        "80",
        "-22.487",
        "1.012",
        "0",
        "0",
        "0"
    ],
    [
        "-78",
        "80",
        "-22.466",
        "1.409",
        "0",
        "0",
        "0"
    ],
    [
        "-77",
        "80",
        "-22.439",
        "1.807",
        "0",
        "0",
        "0"
    ],
    [
        "-76",
        "80",
        "-22.404",
        "2.203",
        "0",
        "0",
        "0"
    ],
    [
        "-75",
        "80",
        "-22.363",
        "2.599",
        "0",
        "0",
        "0"
    ],
    [
        "-74",
        "80",
        "-22.315",
        "2.994",
        "0",
        "0",
        "0"
    ],
    [
        "-73",
        "80",
        "-22.260",
        "3.388",
        "0",
        "0",
        "0"
    ],
    [
        "-72",
        "80",
        "-22.198",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "-71",
        "80",
        "-22.130",
        "4.174",
        "0",
        "0",
        "0"
    ],
    [
        "-70",
        "80",
        "-22.055",
        "4.565",
        "0",
        "0",
        "0"
    ],
    [
        "-69",
        "80",
        "-21.973",
        "4.954",
        "0",
        "0",
        "0"
    ],
    [
        "-68",
        "80",
        "-21.884",
        "5.342",
        "0",
        "0",
        "0"
    ],
    [
        "-67",
        "80",
        "-21.789",
        "5.729",
        "0",
        "0",
        "0"
    ],
    [
        "-66",
        "80",
        "-21.688",
        "6.113",
        "0",
        "0",
        "0"
    ],
    [
        "-65",
        "80",
        "-21.579",
        "6.496",
        "0",
        "0",
        "0"
    ],
    [
        "-64",
        "80",
        "-21.465",
        "6.877",
        "0",
        "0",
        "0"
    ],
    [
        "-63",
        "80",
        "-21.343",
        "7.255",
        "0",
        "0",
        "0"
    ],
    [
        "-62",
        "80",
        "-21.215",
        "7.631",
        "0",
        "0",
        "0"
    ],
    [
        "-61",
        "80",
        "-21.080",
        "8.005",
        "0",
        "0",
        "0"
    ],
    [
        "-60",
        "80",
        "-20.940",
        "8.377",
        "0",
        "0",
        "0"
    ],
    [
        "-59",
        "80",
        "-20.792",
        "8.746",
        "0",
        "0",
        "0"
    ],
    [
        "-58",
        "80",
        "-20.639",
        "9.113",
        "0",
        "0",
        "0"
    ],
    [
        "-57",
        "80",
        "-20.479",
        "9.476",
        "0",
        "0",
        "0"
    ],
    [
        "-56",
        "80",
        "-20.313",
        "9.837",
        "0",
        "0",
        "0"
    ],
    [
        "-55",
        "80",
        "-20.142",
        "10.195",
        "0",
        "0",
        "0"
    ],
    [
        "-54",
        "80",
        "-19.963",
        "10.549",
        "0",
        "0",
        "0"
    ],
    [
        "-53",
        "80",
        "-19.778",
        "10.901",
        "0",
        "0",
        "0"
    ],
    [
        "-52",
        "80",
        "-19.588",
        "11.248",
        "0",
        "0",
        "0"
    ],
    [
        "-51",
        "80",
        "-19.392",
        "11.594",
        "0",
        "0",
        "0"
    ],
    [
        "-50",
        "80",
        "-19.190",
        "11.935",
        "0",
        "0",
        "0"
    ],
    [
        "-49",
        "80",
        "-18.981",
        "12.272",
        "0",
        "0",
        "0"
    ],
    [
        "-48",
        "80",
        "-18.768",
        "12.606",
        "0",
        "0",
        "0"
    ],
    [
        "-47",
        "80",
        "-18.548",
        "12.936",
        "0",
        "0",
        "0"
    ],
    [
        "-46",
        "80",
        "-18.323",
        "13.262",
        "0",
        "0",
        "0"
    ],
    [
        "-45",
        "80",
        "-18.093",
        "13.584",
        "0",
        "0",
        "0"
    ],
    [
        "-44",
        "80",
        "-17.856",
        "13.902",
        "0",
        "0",
        "0"
    ],
    [
        "-43",
        "80",
        "-17.614",
        "14.216",
        "0",
        "0",
        "0"
    ],
    [
        "-42",
        "80",
        "-17.368",
        "14.524",
        "0",
        "0",
        "0"
    ],
    [
        "-41",
        "80",
        "-17.115",
        "14.829",
        "0",
        "0",
        "0"
    ],
    [
        "-40",
        "80",
        "-16.858",
        "15.129",
        "0",
        "0",
        "0"
    ],
    [
        "-39",
        "80",
        "-16.595",
        "15.425",
        "0",
        "0",
        "0"
    ],
    [
        "-38",
        "80",
        "-16.328",
        "15.717",
        "0",
        "0",
        "0"
    ],
    [
        "-37",
        "80",
        "-16.055",
        "16.002",
        "0",
        "0",
        "0"
    ],
    [
        "-36",
        "80",
        "-15.777",
        "16.284",
        "0",
        "0",
        "0"
    ],
    [
        "-35",
        "80",
        "-15.496",
        "16.561",
        "0",
        "0",
        "0"
    ],
    [
        "-34",
        "80",
        "-15.208",
        "16.832",
        "0",
        "0",
        "0"
    ],
    [
        "-33",
        "80",
        "-14.916",
        "17.098",
        "0",
        "0",
        "0"
    ],
    [
        "-32",
        "80",
        "-14.620",
        "17.359",
        "0",
        "0",
        "0"
    ],
    [
        "-31",
        "80",
        "-14.320",
        "17.614",
        "0",
        "0",
        "0"
    ],
    [
        "-30",
        "80",
        "-14.015",
        "17.865",
        "0",
        "0",
        "0"
    ],
    [
        "-29",
        "80",
        "-13.706",
        "18.109",
        "0",
        "0",
        "0"
    ],
    [
        "-28",
        "80",
        "-13.392",
        "18.350",
        "0",
        "0",
        "0"
    ],
    [
        "-27",
        "80",
        "-13.076",
        "18.583",
        "0",
        "0",
        "0"
    ],
    [
        "-26",
        "80",
        "-12.753",
        "18.811",
        "0",
        "0",
        "0"
    ],
    [
        "-25",
        "80",
        "-12.428",
        "19.034",
        "0",
        "0",
        "0"
    ],
    [
        "-24",
        "80",
        "-12.100",
        "19.250",
        "0",
        "0",
        "0"
    ],
    [
        "-23",
        "80",
        "-11.766",
        "19.461",
        "0",
        "0",
        "0"
    ],
    [
        "-22",
        "80",
        "-11.431",
        "19.666",
        "0",
        "0",
        "0"
    ],
    [
        "-21",
        "80",
        "-11.090",
        "19.864",
        "0",
        "0",
        "0"
    ],
    [
        "-20",
        "80",
        "-10.748",
        "20.057",
        "0",
        "0",
        "0"
    ],
    [
        "-19",
        "80",
        "-10.402",
        "20.245",
        "0",
        "0",
        "0"
    ],
    [
        "-18",
        "80",
        "-10.052",
        "20.425",
        "0",
        "0",
        "0"
    ],
    [
        "-17",
        "80",
        "-9.699",
        "20.599",
        "0",
        "0",
        "0"
    ],
    [
        "-16",
        "80",
        "-9.344",
        "20.767",
        "0",
        "0",
        "0"
    ],
    [
        "-15",
        "80",
        "-8.985",
        "20.928",
        "0",
        "0",
        "0"
    ],
    [
        "-14",
        "80",
        "-8.624",
        "21.084",
        "0",
        "0",
        "0"
    ],
    [
        "-13",
        "80",
        "-8.261",
        "21.232",
        "0",
        "0",
        "0"
    ],
    [
        "-12",
        "80",
        "-7.895",
        "21.376",
        "0",
        "0",
        "0"
    ],
    [
        "-11",
        "80",
        "-7.526",
        "21.511",
        "0",
        "0",
        "0"
    ],
    [
        "-10",
        "80",
        "-7.155",
        "21.640",
        "0",
        "0",
        "0"
    ],
    [
        "-9",
        "80",
        "-6.782",
        "21.763",
        "0",
        "0",
        "0"
    ],
    [
        "-8",
        "80",
        "-6.407",
        "21.879",
        "0",
        "0",
        "0"
    ],
    [
        "-7",
        "80",
        "-6.030",
        "21.989",
        "0",
        "0",
        "0"
    ],
    [
        "-6",
        "80",
        "-5.651",
        "22.091",
        "0",
        "0",
        "0"
    ],
    [
        "-5",
        "80",
        "-5.270",
        "22.187",
        "0",
        "0",
        "0"
    ],
    [
        "-4",
        "80",
        "-4.889",
        "22.277",
        "0",
        "0",
        "0"
    ],
    [
        "-3",
        "80",
        "-4.504",
        "22.359",
        "0",
        "0",
        "0"
    ],
    [
        "-2",
        "80",
        "-4.120",
        "22.435",
        "0",
        "0",
        "0"
    ],
    [
        "-1",
        "80",
        "-3.733",
        "22.504",
        "0",
        "0",
        "0"
    ],
    [
        "0",
        "80",
        "-3.346",
        "22.566",
        "0",
        "0",
        "0"
    ],
    [
        "1",
        "80",
        "-1.423",
        "21.900",
        "4.389",
        "2.671",
        "0.027"
    ],
    [
        "2",
        "80",
        "1.800",
        "18.472",
        "5.457",
        "5.187",
        "-0.315"
    ],
    [
        "3",
        "80",
        "4.546",
        "15.941",
        "6.187",
        "4.941",
        "-0.116"
    ],
    [
        "4",
        "80",
        "6.488",
        "15.825",
        "5.605",
        "3.976",
        "0.197"
    ],
    [
        "5",
        "80",
        "8.837",
        "16.823",
        "0",
        "0",
        "0"
    ],
    [
        "6",
        "80",
        "9.125",
        "16.740",
        "0",
        "0",
        "0"
    ],
    [
        "7",
        "80",
        "9.412",
        "16.651",
        "0",
        "0",
        "0"
    ],
    [
        "8",
        "80",
        "9.697",
        "16.557",
        "0",
        "0",
        "0"
    ],
    [
        "9",
        "80",
        "9.981",
        "16.458",
        "0",
        "0",
        "0"
    ],
    [
        "10",
        "80",
        "10.263",
        "16.353",
        "0",
        "0",
        "0"
    ],
    [
        "11",
        "80",
        "10.543",
        "16.245",
        "0",
        "0",
        "0"
    ],
    [
        "12",
        "80",
        "10.821",
        "16.130",
        "0",
        "0",
        "0"
    ],
    [
        "13",
        "80",
        "11.097",
        "16.011",
        "0",
        "0",
        "0"
    ],
    [
        "14",
        "80",
        "11.372",
        "15.887",
        "0",
        "0",
        "0"
    ],
    [
        "15",
        "80",
        "11.643",
        "15.758",
        "0",
        "0",
        "0"
    ],
    [
        "16",
        "80",
        "11.914",
        "15.625",
        "0",
        "0",
        "0"
    ],
    [
        "17",
        "80",
        "12.180",
        "15.486",
        "0",
        "0",
        "0"
    ],
    [
        "18",
        "80",
        "12.445",
        "15.344",
        "0",
        "0",
        "0"
    ],
    [
        "19",
        "80",
        "12.708",
        "15.196",
        "0",
        "0",
        "0"
    ],
    [
        "20",
        "80",
        "12.968",
        "15.044",
        "0",
        "0",
        "0"
    ],
    [
        "21",
        "80",
        "13.225",
        "14.887",
        "0",
        "0",
        "0"
    ],
    [
        "22",
        "80",
        "13.480",
        "14.725",
        "0",
        "0",
        "0"
    ],
    [
        "23",
        "80",
        "13.731",
        "14.559",
        "0",
        "0",
        "0"
    ],
    [
        "24",
        "80",
        "13.981",
        "14.390",
        "0",
        "0",
        "0"
    ],
    [
        "25",
        "80",
        "14.226",
        "14.214",
        "0",
        "0",
        "0"
    ],
    [
        "26",
        "80",
        "14.469",
        "14.035",
        "0",
        "0",
        "0"
    ],
    [
        "27",
        "80",
        "14.708",
        "13.852",
        "0",
        "0",
        "0"
    ],
    [
        "28",
        "80",
        "14.945",
        "13.664",
        "0",
        "0",
        "0"
    ],
    [
        "29",
        "80",
        "15.178",
        "13.473",
        "0",
        "0",
        "0"
    ],
    [
        "30",
        "80",
        "15.408",
        "13.277",
        "0",
        "0",
        "0"
    ],
    [
        "31",
        "80",
        "15.634",
        "13.078",
        "0",
        "0",
        "0"
    ],
    [
        "32",
        "80",
        "15.858",
        "12.873",
        "0",
        "0",
        "0"
    ],
    [
        "33",
        "80",
        "16.077",
        "12.665",
        "0",
        "0",
        "0"
    ],
    [
        "34",
        "80",
        "16.293",
        "12.454",
        "0",
        "0",
        "0"
    ],
    [
        "35",
        "80",
        "16.505",
        "12.239",
        "0",
        "0",
        "0"
    ],
    [
        "36",
        "80",
        "16.714",
        "12.019",
        "0",
        "0",
        "0"
    ],
    [
        "37",
        "80",
        "16.918",
        "11.797",
        "0",
        "0",
        "0"
    ],
    [
        "38",
        "80",
        "17.119",
        "11.570",
        "0",
        "0",
        "0"
    ],
    [
        "39",
        "80",
        "17.316",
        "11.340",
        "0",
        "0",
        "0"
    ],
    [
        "40",
        "80",
        "17.509",
        "11.107",
        "0",
        "0",
        "0"
    ],
    [
        "41",
        "80",
        "17.698",
        "10.870",
        "0",
        "0",
        "0"
    ],
    [
        "42",
        "80",
        "17.883",
        "10.631",
        "0",
        "0",
        "0"
    ],
    [
        "43",
        "80",
        "18.063",
        "10.386",
        "0",
        "0",
        "0"
    ],
    [
        "44",
        "80",
        "18.239",
        "10.140",
        "0",
        "0",
        "0"
    ],
    [
        "45",
        "80",
        "18.412",
        "9.891",
        "0",
        "0",
        "0"
    ],
    [
        "46",
        "80",
        "18.580",
        "9.638",
        "0",
        "0",
        "0"
    ],
    [
        "47",
        "80",
        "18.743",
        "9.382",
        "0",
        "0",
        "0"
    ],
    [
        "48",
        "80",
        "18.902",
        "9.125",
        "0",
        "0",
        "0"
    ],
    [
        "49",
        "80",
        "19.057",
        "8.863",
        "0",
        "0",
        "0"
    ],
    [
        "50",
        "80",
        "19.207",
        "8.600",
        "0",
        "0",
        "0"
    ],
    [
        "51",
        "80",
        "19.353",
        "8.333",
        "0",
        "0",
        "0"
    ],
    [
        "52",
        "80",
        "19.494",
        "8.064",
        "0",
        "0",
        "0"
    ],
    [
        "53",
        "80",
        "19.630",
        "7.793",
        "0",
        "0",
        "0"
    ],
    [
        "54",
        "80",
        "19.761",
        "7.518",
        "0",
        "0",
        "0"
    ],
    [
        "55",
        "80",
        "19.888",
        "7.243",
        "0",
        "0",
        "0"
    ],
    [
        "56",
        "80",
        "20.010",
        "6.964",
        "0",
        "0",
        "0"
    ],
    [
        "57",
        "80",
        "20.128",
        "6.683",
        "0",
        "0",
        "0"
    ],
    [
        "58",
        "80",
        "20.240",
        "6.401",
        "0",
        "0",
        "0"
    ],
    [
        "59",
        "80",
        "20.347",
        "6.116",
        "0",
        "0",
        "0"
    ],
    [
        "60",
        "80",
        "20.450",
        "5.831",
        "0",
        "0",
        "0"
    ],
    [
        "61",
        "80",
        "20.548",
        "5.542",
        "0",
        "0",
        "0"
    ],
    [
        "62",
        "80",
        "20.641",
        "5.253",
        "0",
        "0",
        "0"
    ],
    [
        "63",
        "80",
        "20.728",
        "4.961",
        "0",
        "0",
        "0"
    ],
    [
        "64",
        "80",
        "20.811",
        "4.668",
        "0",
        "0",
        "0"
    ],
    [
        "65",
        "80",
        "20.889",
        "4.375",
        "0",
        "0",
        "0"
    ],
    [
        "66",
        "80",
        "20.962",
        "4.078",
        "0",
        "0",
        "0"
    ],
    [
        "67",
        "80",
        "21.029",
        "3.782",
        "0",
        "0",
        "0"
    ],
    [
        "68",
        "80",
        "21.091",
        "3.484",
        "0",
        "0",
        "0"
    ],
    [
        "69",
        "80",
        "21.149",
        "3.184",
        "0",
        "0",
        "0"
    ],
    [
        "70",
        "80",
        "21.201",
        "2.884",
        "0",
        "0",
        "0"
    ],
    [
        "71",
        "80",
        "21.248",
        "2.583",
        "0",
        "0",
        "0"
    ],
    [
        "72",
        "80",
        "21.290",
        "2.281",
        "0",
        "0",
        "0"
    ],
    [
        "73",
        "80",
        "21.326",
        "1.979",
        "0",
        "0",
        "0"
    ],
    [
        "74",
        "80",
        "21.358",
        "1.677",
        "0",
        "0",
        "0"
    ],
    [
        "75",
        "80",
        "21.384",
        "1.373",
        "0",
        "0",
        "0"
    ],
    [
        "76",
        "80",
        "21.405",
        "1.069",
        "0",
        "0",
        "0"
    ],
    [
        "77",
        "80",
        "21.421",
        "0.765",
        "0",
        "0",
        "0"
    ],
    [
        "78",
        "80",
        "21.431",
        "0.460",
        "0",
        "0",
        "0"
    ],
    [
        "79",
        "80",
        "21.436",
        "0.156",
        "0",
        "0",
        "0"
    ],
    [
        "80",
        "80",
        "21.437",
        "-0.149",
        "0",
        "0",
        "0"
    ],
    [
        "81",
        "80",
        "21.431",
        "-0.454",
        "0",
        "0",
        "0"
    ],
    [
        "82",
        "80",
        "21.421",
        "-0.759",
        "0",
        "0",
        "0"
    ],
    [
        "83",
        "80",
        "21.405",
        "-1.063",
        "0",
        "0",
        "0"
    ],
    [
        "84",
        "80",
        "21.384",
        "-1.367",
        "0",
        "0",
        "0"
    ],
    [
        "85",
        "80",
        "21.358",
        "-1.670",
        "0",
        "0",
        "0"
    ],
    [
        "86",
        "80",
        "21.327",
        "-1.974",
        "0",
        "0",
        "0"
    ],
    [
        "87",
        "80",
        "21.291",
        "-2.275",
        "0",
        "0",
        "0"
    ],
    [
        "88",
        "80",
        "21.249",
        "-2.578",
        "0",
        "0",
        "0"
    ],
    [
        "89",
        "80",
        "21.201",
        "-2.878",
        "0",
        "0",
        "0"
    ],
    [
        "90",
        "80",
        "21.150",
        "-3.178",
        "0",
        "0",
        "0"
    ],
    [
        "91",
        "80",
        "21.093",
        "-3.478",
        "0",
        "0",
        "0"
    ],
    [
        "92",
        "80",
        "21.030",
        "-3.776",
        "0",
        "0",
        "0"
    ],
    [
        "93",
        "80",
        "20.963",
        "-4.073",
        "0",
        "0",
        "0"
    ],
    [
        "94",
        "80",
        "20.890",
        "-4.368",
        "0",
        "0",
        "0"
    ],
    [
        "95",
        "80",
        "20.813",
        "-4.663",
        "0",
        "0",
        "0"
    ],
    [
        "96",
        "80",
        "20.730",
        "-4.955",
        "0",
        "0",
        "0"
    ],
    [
        "97",
        "80",
        "20.642",
        "-5.247",
        "0",
        "0",
        "0"
    ],
    [
        "98",
        "80",
        "20.550",
        "-5.537",
        "0",
        "0",
        "0"
    ],
    [
        "99",
        "80",
        "20.452",
        "-5.824",
        "0",
        "0",
        "0"
    ],
    [
        "100",
        "80",
        "20.350",
        "-6.111",
        "0",
        "0",
        "0"
    ],
    [
        "101",
        "80",
        "20.242",
        "-6.395",
        "0",
        "0",
        "0"
    ],
    [
        "102",
        "80",
        "20.130",
        "-6.678",
        "0",
        "0",
        "0"
    ],
    [
        "103",
        "80",
        "20.013",
        "-6.959",
        "0",
        "0",
        "0"
    ],
    [
        "104",
        "80",
        "19.890",
        "-7.237",
        "0",
        "0",
        "0"
    ],
    [
        "105",
        "80",
        "19.764",
        "-7.513",
        "0",
        "0",
        "0"
    ],
    [
        "106",
        "80",
        "19.632",
        "-7.787",
        "0",
        "0",
        "0"
    ],
    [
        "107",
        "80",
        "19.496",
        "-8.058",
        "0",
        "0",
        "0"
    ],
    [
        "108",
        "80",
        "19.355",
        "-8.328",
        "0",
        "0",
        "0"
    ],
    [
        "109",
        "80",
        "19.210",
        "-8.594",
        "0",
        "0",
        "0"
    ],
    [
        "110",
        "80",
        "19.059",
        "-8.858",
        "0",
        "0",
        "0"
    ],
    [
        "111",
        "80",
        "18.905",
        "-9.119",
        "0",
        "0",
        "0"
    ],
    [
        "112",
        "80",
        "18.746",
        "-9.378",
        "0",
        "0",
        "0"
    ],
    [
        "113",
        "80",
        "18.583",
        "-9.634",
        "0",
        "0",
        "0"
    ],
    [
        "114",
        "80",
        "18.415",
        "-9.886",
        "0",
        "0",
        "0"
    ],
    [
        "115",
        "80",
        "18.243",
        "-10.136",
        "0",
        "0",
        "0"
    ],
    [
        "116",
        "80",
        "18.067",
        "-10.382",
        "0",
        "0",
        "0"
    ],
    [
        "117",
        "80",
        "17.886",
        "-10.626",
        "0",
        "0",
        "0"
    ],
    [
        "118",
        "80",
        "17.701",
        "-10.865",
        "0",
        "0",
        "0"
    ],
    [
        "119",
        "80",
        "17.513",
        "-11.102",
        "0",
        "0",
        "0"
    ],
    [
        "120",
        "80",
        "17.320",
        "-11.336",
        "0",
        "0",
        "0"
    ],
    [
        "121",
        "80",
        "17.190",
        "-11.523",
        "1.410",
        "0.853",
        "0.243"
    ],
    [
        "122",
        "80",
        "17.364",
        "-11.356",
        "1.947",
        "1.651",
        "-0.032"
    ],
    [
        "123",
        "80",
        "17.762",
        "-11.173",
        "2.077",
        "1.604",
        "0.163"
    ],
    [
        "124",
        "80",
        "18.237",
        "-11.001",
        "1.870",
        "1.513",
        "0.239"
    ],
    [
        "125",
        "80",
        "18.766",
        "-10.855",
        "1.736",
        "1.364",
        "0.182"
    ],
    [
        "126",
        "80",
        "19.391",
        "-10.741",
        "1.630",
        "0.938",
        "0.309"
    ],
    [
        "127",
        "80",
        "19.696",
        "-10.901",
        "0",
        "0",
        "0"
    ],
    [
        "128",
        "80",
        "19.505",
        "-11.248",
        "0",
        "0",
        "0"
    ],
    [
        "129",
        "80",
        "19.309",
        "-11.594",
        "0",
        "0",
        "0"
    ],
    [
        "130",
        "80",
        "19.107",
        "-11.935",
        "0",
        "0",
        "0"
    ],
    [
        "131",
        "80",
        "18.899",
        "-12.272",
        "0",
        "0",
        "0"
    ],
    [
        "132",
        "80",
        "18.685",
        "-12.606",
        "0",
        "0",
        "0"
    ],
    [
        "133",
        "80",
        "18.466",
        "-12.936",
        "0",
        "0",
        "0"
    ],
    [
        "134",
        "80",
        "18.240",
        "-13.262",
        "0",
        "0",
        "0"
    ],
    [
        "135",
        "80",
        "18.009",
        "-13.584",
        "0",
        "0",
        "0"
    ],
    [
        "136",
        "80",
        "17.773",
        "-13.902",
        "0",
        "0",
        "0"
    ],
    [
        "137",
        "80",
        "17.532",
        "-14.216",
        "0",
        "0",
        "0"
    ],
    [
        "138",
        "80",
        "17.285",
        "-14.524",
        "0",
        "0",
        "0"
    ],
    [
        "139",
        "80",
        "17.032",
        "-14.829",
        "0",
        "0",
        "0"
    ],
    [
        "140",
        "80",
        "16.775",
        "-15.129",
        "0",
        "0",
        "0"
    ],
    [
        "141",
        "80",
        "16.513",
        "-15.425",
        "0",
        "0",
        "0"
    ],
    [
        "142",
        "80",
        "16.244",
        "-15.717",
        "0",
        "0",
        "0"
    ],
    [
        "143",
        "80",
        "15.972",
        "-16.002",
        "0",
        "0",
        "0"
    ],
    [
        "144",
        "80",
        "15.695",
        "-16.284",
        "0",
        "0",
        "0"
    ],
    [
        "145",
        "80",
        "15.412",
        "-16.561",
        "0",
        "0",
        "0"
    ],
    [
        "146",
        "80",
        "15.126",
        "-16.832",
        "0",
        "0",
        "0"
    ],
    [
        "147",
        "80",
        "14.834",
        "-17.098",
        "0",
        "0",
        "0"
    ],
    [
        "148",
        "80",
        "14.538",
        "-17.359",
        "0",
        "0",
        "0"
    ],
    [
        "149",
        "80",
        "14.237",
        "-17.614",
        "0",
        "0",
        "0"
    ],
    [
        "150",
        "80",
        "13.932",
        "-17.865",
        "0",
        "0",
        "0"
    ],
    [
        "151",
        "80",
        "13.623",
        "-18.109",
        "0",
        "0",
        "0"
    ],
    [
        "152",
        "80",
        "13.310",
        "-18.350",
        "0",
        "0",
        "0"
    ],
    [
        "153",
        "80",
        "12.992",
        "-18.583",
        "0",
        "0",
        "0"
    ],
    [
        "154",
        "80",
        "12.671",
        "-18.811",
        "0",
        "0",
        "0"
    ],
    [
        "155",
        "80",
        "12.346",
        "-19.034",
        "0",
        "0",
        "0"
    ],
    [
        "156",
        "80",
        "12.016",
        "-19.250",
        "0",
        "0",
        "0"
    ],
    [
        "157",
        "80",
        "11.684",
        "-19.461",
        "0",
        "0",
        "0"
    ],
    [
        "158",
        "80",
        "11.347",
        "-19.666",
        "0",
        "0",
        "0"
    ],
    [
        "159",
        "80",
        "11.008",
        "-19.864",
        "0",
        "0",
        "0"
    ],
    [
        "160",
        "80",
        "10.665",
        "-20.057",
        "0",
        "0",
        "0"
    ],
    [
        "161",
        "80",
        "10.319",
        "-20.245",
        "0",
        "0",
        "0"
    ],
    [
        "162",
        "80",
        "9.969",
        "-20.425",
        "0",
        "0",
        "0"
    ],
    [
        "163",
        "80",
        "9.616",
        "-20.599",
        "0",
        "0",
        "0"
    ],
    [
        "164",
        "80",
        "9.261",
        "-20.767",
        "0",
        "0",
        "0"
    ],
    [
        "165",
        "80",
        "8.902",
        "-20.928",
        "0",
        "0",
        "0"
    ],
    [
        "166",
        "80",
        "8.541",
        "-21.084",
        "0",
        "0",
        "0"
    ],
    [
        "167",
        "80",
        "8.177",
        "-21.232",
        "0",
        "0",
        "0"
    ],
    [
        "168",
        "80",
        "7.812",
        "-21.376",
        "0",
        "0",
        "0"
    ],
    [
        "169",
        "80",
        "7.443",
        "-21.511",
        "0",
        "0",
        "0"
    ],
    [
        "170",
        "80",
        "7.072",
        "-21.640",
        "0",
        "0",
        "0"
    ],
    [
        "171",
        "80",
        "6.699",
        "-21.763",
        "0",
        "0",
        "0"
    ],
    [
        "172",
        "80",
        "6.324",
        "-21.879",
        "0",
        "0",
        "0"
    ],
    [
        "173",
        "80",
        "5.947",
        "-21.989",
        "0",
        "0",
        "0"
    ],
    [
        "174",
        "80",
        "5.568",
        "-22.091",
        "0",
        "0",
        "0"
    ],
    [
        "175",
        "80",
        "5.188",
        "-22.187",
        "0",
        "0",
        "0"
    ],
    [
        "176",
        "80",
        "4.806",
        "-22.277",
        "0",
        "0",
        "0"
    ],
    [
        "177",
        "80",
        "4.422",
        "-22.359",
        "0",
        "0",
        "0"
    ],
    [
        "178",
        "80",
        "4.036",
        "-22.435",
        "0",
        "0",
        "0"
    ],
    [
        "179",
        "80",
        "3.650",
        "-22.504",
        "0",
        "0",
        "0"
    ]
]