import React from "react";

const ToolTip1 = ( {text, position}) => {
  return (
    <section class="bg-gray-2  dark:bg-dark ">
      <div className=" container mx-auto">
        <div className="mx-4 flex flex-wrap justify-end">
          {/* <TooltipItem position="top" tooltipsText="ToolTip Text">
            ToolTip On Top
          </TooltipItem>

          <TooltipItem position="bottom" tooltipsText="ToolTip Text">
            ToolTip On Bottom
          </TooltipItem> */}

          <TooltipItem position={position} tooltipsText={text}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
    <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
  </svg>
          </TooltipItem>

          {/* <TooltipItem position="left" tooltipsText="ToolTip Text">
            ToolTip On Left
          </TooltipItem> */}
        </div>
      </div>
    </section>
  );
};

export default ToolTip1;

const TooltipItem = ({ children, tooltipsText, position }) => {
  return (
    <div className="  ">
      <div className="">
        <div className=" relative inline-block">
          <button className="inline-flex rounded bg-primary peer  text-base font-semibold text-slate-400 ">
            {children}
          </button>
          <div
            className={` ${
              (position === "right" &&
                `absolute left-full w-32 top-1/2 z-20 ml-3 -translate-y-1/2 whitespace-nowrap rounded bg-black px-4 py-[6px] text-sm font-semibold text-white opacity-0 group-hover:opacity-100`) ||
              (position === "top" &&
                `absolute bottom-full left-1/2 z-20 mb-3 -translate-x-1/2 whitespace-nowrap rounded bg-black px-4 py-[6px] text-sm font-semibold text-white opacity-0 group-hover:opacity-100`) ||
              (position === "left" &&
                `absolute right-full w-[200px] text-wrap top-1/2 z-20 mr-3 -translate-y-1/2 whitespace-nowrap rounded-xl bg-slate-800 px-4 py-[6px] text-[10px]  text-white opacity-0 peer-hover:opacity-100`) ||
              (position === "bottom" &&
                `absolute left-1/2 top-full z-20 mt-3 -translate-x-1/2 whitespace-nowrap rounded bg-black px-4 py-[6px] text-sm font-semibold text-white opacity-0 group-hover:opacity-100`)
            }`}
          >
            <span
              className={` ${
                (position === "right" &&
                  `absolute left-[-3px] top-1/2 -z-10 h-2 w-2 -translate-y-1/2 rotate-45 rounded-sm bg-black`) ||
                (position === "top" &&
                  `absolute bottom-[-3px] left-1/2 -z-10 h-2 w-2 -translate-x-1/2 rotate-45 rounded-sm bg-black`) ||
                (position === "left" &&
                  `absolute right-[-3px] top-1/2 -z-10 h-2 w-2 -translate-y-1/2 rotate-45 rounded-sm bg-slate-800`) ||
                (position === "bottom" &&
                  `absolute left-1/2 top-[-3px] -z-10 h-2 w-2 -translate-x-1/2 rotate-45 rounded-sm bg-black`)
              } `}
            ></span>
            <span className=" w-10">
            {tooltipsText}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
