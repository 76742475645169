import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
// import LargeChartHeader from './LargeChartHeader';
import { format } from 'date-fns';
import { getPollutantsYearWiseData } from '../Actions/ChartActions';
// import { getAnalyticsByMonth } from '../../Actions/DashboardActions';

const DualAreaChart = ({selectedPollutant, marker, serverData, loading }) => {

  const numericMonth = format(new Date(), 'MM');
  const currentYear = format(new Date(), 'yyyy');
  const [data, setData] = useState()
  // const [loading, setLoading] = useState(true)       

  const [chartData, setChartData] = useState({
    series: [
    //   { name: 'NO₂', data: [3, 5, 1, 6, 6] },
    //   { name: 'CO', data: [3, 2, 6, 4, 6] },
      { name: 'SO₂', data: [5, 3, 2, 6, 2] },
    //   { name: 'PM₁₀', data: [176, 288, 289, 150, 100] },
    //   { name: 'PM₂.₅', data: [6, 4, 1, 3, 1] },
    //   { name: 'O₃', data: [ 3, 5, 2, 1, 6] },
    ],
    categories: [2020, 2021, 2022, 2023, 2024]
  });

  const [loader, setLoader] = useState(true);

  const [monthYear, setMonthYear] = useState(
    {
      month: numericMonth,
      year: currentYear
    }
  )

  const pollutants = [
    { display: 'AQI', value: 'AQI' },
    { display: 'SO₂', value: 'SO2' },
    { display: 'CO', value: 'CO' },
    { display: 'O₃', value: 'O3' },
    { display: 'PM₁₀', value: 'PM10' },
    { display: 'PM₂.₅', value: 'PM2.5' },
  ]

  const getPollutantDisplay = () =>{
    return  pollutants.find((k)=>k.value == selectedPollutant)

   }


   useEffect(()=>{
    getYearWiseData()
   },[marker])


   const getYearWiseData = async () => {
    setLoader(true);
    try {
      const response = await getPollutantsYearWiseData(marker, selectedPollutant);
      console.log("Response Data:", response.data);
  
      if (response && response.data) {
        const pollutantKey = Object.keys(response.data)[0];
        const pollutantData = response.data[pollutantKey];
  
        if (!pollutantData || !Array.isArray(pollutantData)) {
          console.error("Invalid data format:", pollutantData);
          setChartData({ series: [], categories: [] });
          return;
        }
  
        const n = pollutants.find((ele) => ele.value === pollutantKey);
        const d = {
          name: n?.display,
          data: pollutantData,
        };
        console.log("Processed Data:", d);
  
        setChartData({
          series: [d],
          categories: [2020, 2021, 2022, 2023, 2024], // Use real years/categories if applicable
        });
      } else {
        console.error("No response data available.");
        setChartData({ series: [], categories: [] });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setChartData({ series: [], categories: [] });
    } finally {
      setLoader(false);
    }
  };



  //  const getYearWiseData = async () =>{
  //   setLoader(true)
  //   const response = await getPollutantsYearWiseData(marker, selectedPollutant);
  //   // console.log("yearly data of chart", response)
  //   const n = pollutants.find((ele)=>ele.value == Object.keys(response.data)[0])
  //   const d = {
  //     name: n?.display,
  //     data: response.data[n?.value]
  //   }
  //   console.log("yearly data of chart", d, Object.keys(response.data)[0])
  //   setChartData(d)

  //   setLoader(false)
  //  }

//   useEffect(() => {
//     if (serverData) {
//       setData(serverData)
//     }
//   }, [serverData])

//   useEffect(() => {
//     if (loading) {
//       setLoader(loading)
//     }else{
//       setLoader(false)
//     }
//   }, [loading])

//   useEffect(() => {
//     if (data && data.length > 0) {
//       const benignLinks = [];
//       const maliciousLinks = [];
//       const days = [];

//       // Loop through the server data and extract benign_links, malicious_links, and day
//       data.forEach((d) => {
//         benignLinks.push(d.benign_links);
//         maliciousLinks.push(d.malicious_links);
//         days.push(d.day); // Use the formatted day (e.g., "September 01")
//       });

//       // Set the processed data into the chart state
//       setChartData({
//         series: [
//           { name: 'Benign Links', data: benignLinks },
//           { name: 'Malicious Links', data: maliciousLinks }
//         ],
//         categories: days
//       });

//     }

//   }, [data]);
//   console.log("benign vs malicious", chartData)
  const options = {
    chart: {
      type: 'area',
      height: 300,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
      //   sparkline: {
      //     enabled: true
      //   }
    },
    fill: {
      type: ['solid'],
      opacity: 0,
    //   gradient: {
    //     opacityFrom: [0.6, 0],
    //     opacityTo: [0, 0.6]
    //   }
    },
    // stroke: {
    //   // show:false,
    //   curve: 'smooth',
    //   width: [3, 1] // Thicker line for better visibility
    // },
    xaxis: {
      type: 'date',
      categories: chartData.categories,
      labels: {
        format: 'YYYY',
        style: {
          colors: '#a8a8a8',
          fontSize: '10px'
        }
      },
      //   tickAmount: 5 // Limit the number of x-axis ticks
    },

    yaxis: {
      labels: {
        style: {
          colors: '#a8a8a8',
          fontSize: '10px'
        },
        formatter: (val) => {
          if (val >= 1000) {
            return (val / 1000).toFixed(1) + 'k'; // Convert large values to k (like 5k)
          }
          return val;
        }
      },
      tickAmount: 5 // Limit the number of y-axis ticks
    },
    tooltip: {
      enabled: true
    },
    grid: {
      borderColor: '#E5E7E8',
      strokeDashArray: 9
    },
    // markers: {
    //   size: 5, // Marker size
    //   colors: ['#1E90FF', '#FF6347'], // Blue and red for each marker
    //   strokeColors: '#fff',
    //   strokeWidth: 2,
    //   hover: {
    //     size: 7
    //   }
    // },
    colors: ['#0E5FD9', '#F7CFCF', '#5FCFCF', '#b7c97e', '#f1d4aa', '#c55b5b'], // Apply colors globally
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shadeIntensity: 1,
    //     opacityFrom: 0.9,
    //     opacityTo: 0.6,
    //   }
    // },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false
    }
  };

 

  // const series = [
  //   {
  //     name: 'Series 1',
  //     data: [
  //       { x: '2023-11-01', y: 3500 },
  //       { x: '2023-11-02', y: 4000 },
  //       { x: '2023-11-03', y: 3700 },
  //       { x: '2023-11-04', y: 3600 },
  //       { x: '2023-11-05', y: 3000 },
  //       { x: '2023-11-06', y: 2900 },
  //       { x: '2023-11-07', y: 4000 },
  //       { x: '2023-11-08', y: 3000 },
  //       { x: '2023-11-09', y: 3500 },
  //       { x: '2023-11-10', y: 4500 },
  //       { x: '2023-11-11', y: 4200 },
  //       { x: '2023-11-12', y: 4000 },
  //       { x: '2023-11-13', y: 4500 },
  //       { x: '2023-11-14', y: 5000 },
  //     ],
  //     // s
  //   },
  //   {
  //     name: 'Series 2',
  //     data: [
  //       { x: '2023-11-01', y: 2000 },
  //       { x: '2023-11-02', y: 2500 },
  //       { x: '2023-11-03', y: 1900 },
  //       { x: '2023-11-04', y: 1500 },
  //       { x: '2023-11-05', y: 1800 },
  //       { x: '2023-11-06', y: 1300 },
  //       { x: '2023-11-07', y: 1600 },
  //       { x: '2023-11-08', y: 1800 },
  //       { x: '2023-11-09', y: 2000 },
  //       { x: '2023-11-10', y: 2300 },
  //       { x: '2023-11-11', y: 2500 },
  //       { x: '2023-11-12', y: 2200 },
  //       { x: '2023-11-13', y: 2800 },
  //       { x: '2023-11-14', y: 3200 },
  //     ],
  //   }
  // ];

  const changeMonthYear = async (m , y ) =>{
    setLoader(true);
    
    // const response = await getAnalyticsByMonth(m, y );
    // if (response.status){
    //   setData(response.data)
    // }
    // setLoader(false);
  }

  return (
    <div>
       {/* <LargeChartHeader header={'Benign Vs Malicious'} changeMonthYear={changeMonthYear} /> */}
      {loader ?
        (<>
          <div class="flex justify-center items-center h-[200px]">
            <div class="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid "></div>
          </div>
        </>)
        :
        (<>
         
          <div className=' px-3 py-2'>  <Chart options={options} series={chartData.series}  type="area" height={250} /> </div>
        </>)
      }
    </div>
  );
};

export default DualAreaChart;
