import React, { useEffect, useState, useRef } from 'react';
import SidePanel from './Components/SidePanel';
import MainLayout from './Components/MainLayout';
import * as turf from '@turf/turf';
import { getCurrentTimeFromS3, getPollutantsWithinPolygon, getVehicalsWithinPolygon, getVehicalData, getPollutants } from './Actions/userAction'
import Login from './Components/login/Login';
import LoginPage from './Components/login/LoginPage';
import { getItem } from './Components/login/storageService';


function App() {
  const l = getItem('loggedIn')
  const [selectedPollutant, setSelectedPollutant] = useState('O3');
  const [time, setTime] = useState('0000hrs');
  const [currentTime, setCurrentTime] = useState('0000hrs')

  const [marker, setMarker] = useState(
    { longitude: 54.54127039856698, latitude: 24.474911093572814 }
  );

  const drawRef = useRef();
  const mapref = useRef();

  const [startDrawMode, setStartDrawMode] = useState(false);
  const [polygonMode, setPolygonMode] = useState(null);
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [map, setMap] = useState(null);
  const [draw, setDraw] = useState(null);
  const [area, setArea] = useState(null);
  const [loggedIn, setLoggedIn] = useState(l)

  const [initialPollutionData, setInitialPollutionData] = useState({});
  const [pollutionData, setPollutionData] = useState({
      "AQI": 0,
      "CO": 0,
      "O3": 0,
      "PM10": 0,
      "PM2.5": 0,
      "SO2": 0
  })

  const [pollutantPolygonAverage, setPollutantPolygonAverage] = useState()
  const [vehicalPolygonAverage, setVehicalPolygonAverage] = useState()
  // const [showPollutionData, setShowPollutionData] = useState()
  const [loading, setLoading] = useState(true)

  const [polygonData, setPolygonData] = useState()

  const [aggregateVehicleData, setAggregateVehicleData] = useState(0)

  const handleDrawCreate =  (e) => {
    if (polygonMode) {
      if (drawRef.current) {
       
        drawRef.current.deleteAll();
        setPollutionData(initialPollutionData)
      }
    }

    let vertices = e.features[0].geometry.coordinates[0];
    console.log(vertices)

    const polygon = turf.polygon([vertices]);
    setPolygonMode(true)

    console.log("polygon", polygon)
    setArea(turf.area(polygon))
    getPollutantsFromPolygon(polygon.geometry);
    // getVehicalsFromPolygon(polygon.geometry);
    setPolygonData(polygon.geometry);
  };

  useEffect(() => {
    
    if (polygonMode) {
      console.log("time changed", time)
      getPollutantsFromPolygon(polygonData);
      // getVehicalsFromPolygon(polygonData);
    }

  }, [])


  const getPollutantsFromPolygon = async (polygon) => {
    setLoading(true)
    const pollutant_average = await getPollutantsWithinPolygon(polygon, time)
    if (pollutant_average) {
      setPollutantPolygonAverage(pollutant_average)
      // setShowPollutionData(pollutant_average)
      setPollutionData(pollutant_average)
    } else {
      console.log("failed to fetch pollutants within pollygon")
    }
    setLoading(false)
  }

  const getVehicalsFromPolygon = async (polygon) => {
    const vehical_total_within_polygon = await getVehicalsWithinPolygon(polygon, time)
    if (vehical_total_within_polygon?.total_count) {
      setVehicalPolygonAverage(vehical_total_within_polygon?.total_count)
    } else {
      console.log("failed to fetch vehicals within pollygon")
    }
  }




  useEffect(() => {
    getCurrentTime()
  }, [])

  const getCurrentTime = async () => {
    const response = await getCurrentTimeFromS3();
    if (response?.time) {
      setCurrentTime(response?.time)
      setTime(response?.time)
    }
    else {
      console.log("unable to fetch current time ")
    }
  }



  useEffect(() => {

    if (map && draw) {
      map.on('draw.create', handleDrawCreate);
      map.on('draw.update', handleDrawCreate);
      map.on('draw.delete', handleDrawDelete); 
    }

    return () => {
      if (map && draw) {
        // map.off('draw.create', handleDrawCreate);
        // map.off('draw.update', handleDrawUpdate);
        // map.off('draw.delete', handleDrawDelete); 
      }
    };
  }, [map, draw]);

  useEffect(() => {
    // vehicalData()
  }, [time])

  // const vehicalData = async () => {
  //   //hard-coded
  //   const response = await getVehicalData(time)
  //   if (response?.status) {
  //     console.log('vehical data', response)
  //     setAggregateVehicleData(response?.total_count)
  //     // setDes(`${response?.total_count} Cars`)
  //   }
  // }

  useEffect(() => {
    getAllpollutionData();
  }, [time, marker])

  const getAllpollutionData = async () => {
    setLoading(true)
    const response = await getPollutants(marker?.latitude, marker?.longitude, time)
    //    console.log('response', response, selectedPollutant,  response[selectedPollutant])
    if (response?.status) {

      setPollutionData(response.data)
      setInitialPollutionData(response.data)
    }
    else {
      console.log('error while fetching pollution data')
    }
    setLoading(false)
  }


  const handleDrawDelete = () => {

    if (drawRef.current) {

      drawRef.current.deleteAll();
      setDeleteToggle(!deleteToggle)
      //setPolygonShown(false)  not used
      setPolygonMode(false)
      setStartDrawMode(false)
      setPollutionData(initialPollutionData)

    }

  }



  return (
    loggedIn?
   ( <div className=" relative">
      <div className=' min-w-[365px] fixed z-[999999] h-screen'>
        <SidePanel
        mapref= {mapref}
        loading = {loading}
          pollutionData={pollutionData}
          aggregateVehicleData={aggregateVehicleData}
          time={time}
          selectedPollutant={selectedPollutant}
          marker={marker}
          setMarker={setMarker} />
      </div>
      <div className=''>
        <MainLayout
          setSelectedPollutant={setSelectedPollutant}
          selectedPollutant={selectedPollutant}
           mapref={mapref}
          aggregateVehicleData={aggregateVehicleData}
          initialPollutionData={initialPollutionData}
          vehicalPolygonAverage={vehicalPolygonAverage}
          marker={marker}
          currentTime={currentTime}
          //selectedPollutant={selectedPollutant}
          setMarker={setMarker}
          time={time}
          setTime={setTime}
          setStartDrawMode={setStartDrawMode}
          startDrawMode={startDrawMode}
          polygonMode={polygonMode}
          setPolygonMode={setPolygonMode}
          handleDrawDelete={handleDrawDelete}
          drawRef={drawRef}
          map={map}
          setMap={setMap}
          draw={draw}
          area={area}
          setDraw={setDraw}
        /></div>
    </div>):
    <div className=' w-screen h-screen '>
    <LoginPage loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
    </div>
  );
}

export default App;
