import { deleteApiWrapper, getApiWrapper, postApiWrapper, putApiWrapper } from "./token-wrapper-function";


//ecosat
const base_Url = 'https://ecosat.hiigv3ia7agco.eu-north-1.cs.amazonlightsail.com/'

const base_local = 'http://127.0.0.1:8080'


export const getPollutantsYearWiseData = async(marker, pollutant) => {
    const response = await getApiWrapper(`${base_Url}/get-yearly-pollutants?lat=${marker?.latitude}&lng=${marker?.longitude}&pollutant=${pollutant}`); // Add the URL to fetch pollutants
    return response;
  }