import React from 'react'

function CardDragIcon() {
    return (
        <svg width="15" height="12" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.98134 14.7944C3.0756 14.7944 3.96268 14.0059 3.96268 13.0332C3.96268 12.0605 3.0756 11.272 1.98134 11.272C0.887076 11.272 0 12.0605 0 13.0332C0 14.0059 0.887076 14.7944 1.98134 14.7944Z" fill="#1A4C68" />
            <path d="M8.71767 9.15845C9.81193 9.15845 10.699 8.36992 10.699 7.39722C10.699 6.42452 9.81193 5.63599 8.71767 5.63599C7.6234 5.63599 6.73633 6.42452 6.73633 7.39722C6.73633 8.36992 7.6234 9.15845 8.71767 9.15845Z" fill="#1A4C68" />
            <path d="M8.71767 3.52246C9.81193 3.52246 10.699 2.73393 10.699 1.76123C10.699 0.78853 9.81193 0 8.71767 0C7.6234 0 6.73633 0.78853 6.73633 1.76123C6.73633 2.73393 7.6234 3.52246 8.71767 3.52246Z" fill="#1A4C68" />
            <path d="M1.98134 3.52246C3.0756 3.52246 3.96268 2.73393 3.96268 1.76123C3.96268 0.78853 3.0756 0 1.98134 0C0.887076 0 0 0.78853 0 1.76123C0 2.73393 0.887076 3.52246 1.98134 3.52246Z" fill="#1A4C68" />
            <path d="M1.98134 9.15845C3.0756 9.15845 3.96268 8.36992 3.96268 7.39722C3.96268 6.42452 3.0756 5.63599 1.98134 5.63599C0.887076 5.63599 0 6.42452 0 7.39722C0 8.36992 0.887076 9.15845 1.98134 9.15845Z" fill="#1A4C68" />
            <path d="M8.71767 14.7944C9.81193 14.7944 10.699 14.0059 10.699 13.0332C10.699 12.0605 9.81193 11.272 8.71767 11.272C7.6234 11.272 6.73633 12.0605 6.73633 13.0332C6.73633 14.0059 7.6234 14.7944 8.71767 14.7944Z" fill="#1A4C68" />
            <path d="M15.455 14.7944C16.5492 14.7944 17.4363 14.0059 17.4363 13.0332C17.4363 12.0605 16.5492 11.272 15.455 11.272C14.3607 11.272 13.4736 12.0605 13.4736 13.0332C13.4736 14.0059 14.3607 14.7944 15.455 14.7944Z" fill="#1A4C68" />
            <path d="M15.455 3.52246C16.5492 3.52246 17.4363 2.73393 17.4363 1.76123C17.4363 0.78853 16.5492 0 15.455 0C14.3607 0 13.4736 0.78853 13.4736 1.76123C13.4736 2.73393 14.3607 3.52246 15.455 3.52246Z" fill="#1A4C68" />
            <path d="M15.455 9.15845C16.5492 9.15845 17.4363 8.36992 17.4363 7.39722C17.4363 6.42452 16.5492 5.63599 15.455 5.63599C14.3607 5.63599 13.4736 6.42452 13.4736 7.39722C13.4736 8.36992 14.3607 9.15845 15.455 9.15845Z" fill="#1A4C68" />
        </svg>

    )
}

export default CardDragIcon