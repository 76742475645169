import React, { useEffect, useReducer, useRef, useState } from 'react'
import CircularProgress from '../utils/CircularBar'
import Card from '../utils/Card'
//import Geocoder from 'react-map-gl-geocoder';
// import GeocoderControl from './Geocoder'
// import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import CustomGeocoder from './CustomGeocoder';
import DummySvg from '../utils/DummySvg';
import CautionIcon from '../utils/CautionIcon';
import HelperText from '../utils/HelperText';
import YardsSvg from '../utils/YardsSvg';
import TreeIcon from '../utils/TreeIcon';
import { getPollutants, getReverseGeocode, getTopPollutantCity, getVehicalData } from '../Actions/userAction';
import ToolTip1 from './Tooltip';
import DualAreaChart from './Chart';
// import './geocoder.css'
// import CustomGeocoder from './Geocoder';


const TOKEN = "pk.eyJ1Ijoic2luZ2h2aXNoYW4wMDI5IiwiYSI6ImNsdTVwNDdtajE0OGgya254bW1qcGxuc2oifQ.O5Mw_RmNEyFN6N4eZucRWw"; // Set your mapbox token here


function SidePanel(props) {
    const { selectedPollutant, time, marker, setMarker, aggregateVehicleData, pollutionData, loading } = props
    const [des, setDes] = useState(`${aggregateVehicleData} Cars`)

    // const [loading, setLoading] = useState(false)
    const [mostEffectedAreas, setMostEffectedAreas] = useState([])
    const area_ref = useRef(null)
    //const [pollutionData, setPollutionData] = useState({})

    useEffect(() => {
        setDes(`${aggregateVehicleData} Cars`)
    }, [aggregateVehicleData])

    // const pollutants = {
    //     'CO2': 126.543,
    //     'PM2.5': 345,
    //     'HC': 230,
    //     "CO": 450,
    //     'NOx': 320,
    //     'PM10': 390

    // }






    useEffect(() => {
        getCity();
    }, [time, selectedPollutant])

    const getCity = async () => {
        const response = await getTopPollutantCity(time, selectedPollutant);
        const cities = []
        console.log("response for city", response)
        // setMostEffectedAreas(response)
        response?.map(async (item, index) => {
            const p1 = await getReverseGeocode(item?.coordinates[1], item?.coordinates[0])
            cities.push({ name: p1?.features[0]?.properties?.name_preferred })
            setMostEffectedAreas(cities)
        })


    }

    // const mostEffectedAreas = [
    //     {
    //         name: 'Union Square',
    //         pollution: 300
    //     },
    //     {
    //         name: 'Nob Hill',
    //         pollution: 200
    //     },
    //     {
    //         name: 'Japantown',
    //         pollution: 100
    //     }
    // ]

    function invokeText() {
        const k = "It is an indicator for reporting the air quality in a specific area. It tells you how clean or polluted the air is, and what health effects are associated with it. It is calculated from the highest concentration of the five pollutants: CO, NO2, SO2, O3, and PM10.";
        return k;
    }


    function invokeAQIText() {
        console.log("pollution", pollutionData)
        if (pollutionData['AQI'] <= 50) {
            return cautionIndex['Good']
        }

        if (50 < pollutionData['AQI'] && pollutionData['AQI'] <= 100) {
            return cautionIndex['Moderate']
        }

        if (100 < pollutionData['AQI'] && pollutionData['AQI'] <= 150) {
            return cautionIndex['Unhealthy_for_Sensitive_Groups']
        }

        if (150 < pollutionData['AQI'] && pollutionData['AQI'] <= 200) {
            return cautionIndex['Unhealthy']
        }

        if (200 < pollutionData['AQI'] && pollutionData['AQI'] <= 300) {
            return cautionIndex['Very_Unhealthy']
        }

        if (300 < pollutionData['AQI'] && pollutionData['AQI'] <= 500) {
            return cautionIndex['Hazardous']
        }

        return cautionIndex['Good']
        // const k = 'Air quality is satisfactory, and air pollution poses no risk on the population'
        // return k
    }


    const cautionIndex = {
        'Good': {
            description: 'Air quality is satisfactory, and air pollution poses no risk to the population.',
            params: [
                {
                    image: 'outdoorRun.png',
                    des: 'Enjoy outdoor activities'
                },
                {
                    image: 'open-window.png',
                    des: 'Open doors and windows and let fresh air in'
                }
            ]
        },
        'Moderate': {
            description: 'Air quality is acceptable for the majority of the population.',
            params: [
                {
                    image: 'outdoorRun.png',
                    des: 'Enjoy outdoor activities'
                },
                {
                    image: 'reduceOutdoor.png',
                    des: 'Highly sensitive people should reduce prolonged or intense outdoor activities'
                },
                {
                    image: 'open-window.png',
                    des: 'Open doors and windows and let fresh air in'
                }
            ]
        },
        'Unhealthy_for_Sensitive_Groups': {
            description: 'Sensitive individuals may experience health effects.',
            params: [
                {
                    image: 'outdoorRun.png',
                    des: 'General population can enjoy outdoor activities'
                },
                {
                    image: 'reduceOutdoor.png',
                    des: 'Sensitive individuals should reduce prolonged or intense outdoor activities'
                },
                {
                    image: 'coughing.png',
                    des: 'Highly sensitive people could experience signs of coughing, shortness of breath, or unusual fatigue.'
                },
                {
                    image: 'medical.png',
                    des: 'Asthma patients: follow medical recommendations'
                }
            ]
        },
        'Unhealthy': {
            description: 'Greater proportion of the public may be affected.',
            params: [
                {
                    image: 'reduceOutdoor.png',
                    des: 'Everyone should reduce prolonged or intense outdoor activities'
                },
                {
                    image: 'dnRun.png',
                    des: 'Sensitive individuals should avoid prolonged or intense outdoor activities'
                },
                {
                    image: 'maskedPerson.png',
                    des: 'Sensitive individuals are recommended to wear a mask if they stay outdoors for long periods'
                }
            ]
        },
        'Very_Unhealthy': {
            description: 'Everyone may experience health effects.',
            params: [
                {
                    image: 'reduceOutdoor.png',
                    des: 'Reduce prolonged or intense outdoor activities'
                },
                {
                    image: 'dnRun.png',
                    des: 'Sensitive individuals should avoid outdoor activities'
                },
                {
                    image: 'close-window.png',
                    des: 'Everyone is advised to stay indoors, especially sensitive individuals'
                }
            ]
        },
        'Hazardous': {
            description: 'Entire population is expected to be affected.',
            params: [
                {
                    image: 'dnRun.png',
                    des: 'Avoid outdoor activities'
                },
                {
                    image: 'close-window.png',
                    des: 'Stay indoors with windows and doors closed'
                },
                {
                    image: 'air-purifier.png',
                    des: 'Use air purifiers if available'
                },
                {
                    image: 'medical.png',
                    des: 'Follow health guidelines and seek medical advice if symptoms occur'
                }
            ]
        }
    }

    const getPollutantFormat = (p) => {
        if (p == 'PM10') {
            return <div>PM<sub>10</sub></div>
        }
        if (p == 'PM2.5') {
            return <div>PM<sub>2.5</sub></div>
        }

        if (p == 'SO2') {
            return <div>SO<sub>2</sub></div>
        }

        if (p == 'O3') {
            return <div>O<sub>3</sub></div>
        }

        if (p == 'NO2') {
            return <div>NO<sub>2</sub></div>
        }
        return p
    }




    return (
        <div
            className=' w-full bg-[#142C3A] h-full  top-0 left-0 z-50 shadow-lg flex  flex-col '
        >
            <div className='flex flex-col  mt-15  w-full p-4 gap-7 h-full overflow-y-auto '>
                <div className=' flex justify-center'>
                    <CustomGeocoder mapref={props.mapref} setMarker={setMarker} onViewPortChange={props.onViewPortChange} />
                </div>
                <Card>
                    {!loading ? (
                        <>
                            <div className=' flex justify-between px-4 py-2 w-[333px] items-center'>
                                <div className=' text-white text-[13px] text-center'>
                                    {invokeAQIText().description}
                                </div>
                                <div className=' justify-center flex flex-col items-center  w-[100px]'>
                                    <DummySvg loading={loading} value={pollutionData['AQI'] <= 0 ? 0 : pollutionData['AQI']} maxVal={500} showText="value" des={'AQI'} />
                                    {/* <div className=' font-sans text-white'>Moderate Air Quality</div> */}
                                </div>
                            </div>
                            <div className=' flex w-[333px] flex-wrap gap-4 justify-evenly pt-4'>
                                {invokeAQIText().params.map((item) => {
                                    return (
                                        <div className='w-[138px] flex items-center flex-col justify-center text-center'>
                                            <img src={item.image} className='w-8 h-8 rounded-full' />
                                            <div className=' text-white text-[13px]'>
                                                {item.des}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            </>
                    )
                        :
                        (
                            <div class="flex justify-center items-center h-[200px]">
                                <div class="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid "></div>
                            </div>
                        )
                    }
                            <div className=' text-white select-none mt-2'>
                                <ToolTip1
                                    text={invokeText()}
                                    position='left' />
                            </div>
                        
                </Card>


                <div className=' flex gap-1 justify-evenly'>
                    {
                        Object.keys(pollutionData).map((item) => {
                            if (item != 'AQI')
                                return (<div className=' text-[13px] w-[51px] text-center text-white'>
                                    <div className=''>
                                        <div className=' overflow-hidden rounded-lg bg-[#224255] '>
                                            <div className=' bg-[#444d4f] px-1 py-[6px] border border-[#545e60] rounded-t-lg '>
                                                {getPollutantFormat(item)}
                                            </div>
                                            <div className=' '>
                                                {!loading ?
                                                    <div className='pt-2'> {pollutionData[item] <= 0 ? 0 : pollutionData[item].toFixed(1)}</div>
                                                    :
                                                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600 mt-2 ml-2" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                                    </svg>
                                                }
                                                <div className=' py-1  text-[10px]'>µg/m³</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>)
                        })
                    }
                </div>


                <Card>
                    <DualAreaChart selectedPollutant={selectedPollutant} marker={marker} />
                </Card>

                {/* <Card>
                   <div className=' w-[300px]'>
                    {invokeAQIText()}
                    
                   </div>
                </Card> */}

                {/* <Card>
                    <div className=' py-5'>
                        <div className=' flex flex-row justify-evenly'>
                            <div className=' w-[138px] h-38 '>
                                <DummySvg loading={loading} value={pollutionData['NO2'] <= 0 ? 0 : pollutionData['NO2']} maxVal={150} showText='NO2' main='NO' sub='2' des={'NO2'} unit={'µg/m3'} />
                                 <HelperText />  //comment this
                            </div>
                            <div className=' w-[138px] h-38 '>
                                <DummySvg showText='truck' des="NA" /> //comment this
                                <DummySvg loading={loading} value={pollutionData['CO'] <= 0 ? 0 : pollutionData['CO']} maxVal={1} showText='CO' des={'CO'} unit={'µg/m3'} />
                                <HelperText />
                            </div>
                        </div>
                        <div>
                            <div className=' flex flex-row justify-evenly'>
                                <div className=' w-[138px] h-38 '>
                                    <DummySvg showText='lorry' des="NA" />  //comment this
                                    <DummySvg loading={loading} value={pollutionData['SO2'] <= 0 ? 0 : pollutionData['SO2']} maxVal={150} showText='SO2' main='SO' sub='2' des={'SO2'} unit={'µg/m3'} />
                                    <HelperText />   //comment this
                                </div>
                                <div className=' w-[138px] h-38 '>
                                    <DummySvg showText='factory' des="NA" />  //comment this
                                    <DummySvg loading={loading} value={pollutionData['O3'] <= 0 ? 0 : pollutionData['O3']} maxVal={120} showText='O3' main='O' sub='3' des={'O3'} unit={'µg/m3'} />
                                    <HelperText />   //comment this
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className=' flex flex-row justify-evenly'>
                                <div className=' w-[138px] h-38 '>
                                    <DummySvg showText='lorry' des="NA" />   //comment this
                                    <DummySvg loading={loading} value={pollutionData['PM10'] <= 0 ? 0 : pollutionData['PM10']} maxVal={500} showText='PM' main='PM' sub='10' des={'PM10'} unit={'µg/m3'} />
                                    <HelperText />    //comment this
                                </div>
                                <div className=' w-[138px] h-38 '>
                                    <DummySvg showText='factory' des="NA" />  //comment this
                                    <DummySvg loading={loading} value={pollutionData['PM2.5'] <= 0 ? 0 : pollutionData['PM2.5']} maxVal={500} showText='PM' main='PM' sub='2.5' des={'PM2.5'} unit={'µg/m3'} />
                                    <HelperText />   //comment this
                                </div>
                            </div>
                        </div>
                    </div>
                </Card> */}

                {/* <Card>
                    <div className='px-10 py-1'>
                        <div className=' text-white text-[12px] py-3'>What Am I Breathing Right Now</div>
                        {
                            Object.keys(pollutionData).map((pollutant, index) => (
                                <div key={index} className='flex  items-center first:py-0 last:pb-9  py-3 gap-3 last:border-0 border-b border-[#1A4C68]'>

                                    <div className=' text-white text-xs flex justify-between w-full'>
                                        <div>  {pollutant} </div>
                                        <div> {parseFloat(pollutionData[pollutant].toFixed(2))} </div>
                                    </div>

                                </div>
                            ))
                        }

                    </div>
                </Card> */}

                <Card>
                    <div className=' px-10 py-1'>
                        <div className=' text-white  py-3'>Most Affected Areas</div>
                        {
                            mostEffectedAreas?.map((area, index) => (
                                <div key={index} className='flex  items-center first:py-0 last:pb-9  py-3 gap-3 last:border-0 border-b border-[#1A4C68]'>
                                    {console.log("area from map", area.name)}
                                    <CautionIcon />
                                    <div className=' text-white text-xs'>{area.name}</div>

                                </div>
                            ))
                        }
                    </div>
                </Card>

                {/* <Card>
                    <div className=' flex flex-col justify-center items-center'>
                        <DummySvg value={33} maxVal={100} showText='value' des="Average" />
                        <div className=' w-44 h-12'> <HelperText CO2='3 tons' NOX='3 tons' large={true} /></div>
                    </div>
                </Card> */}


                <Card>
                    <div className=' flex  justify-between px-10 gap-2 items-end py-3'>
                        <div className='  mb-5'>
                            <YardsSvg text={"NA Yards"} />
                        </div>
                        <div className='  mb-5'>
                            <TreeIcon value={"NA Counts"} />
                        </div>
                    </div>
                </Card>


            </div>
        </div>
    )
}

export default SidePanel